import {
  LOAD_MASTERDATA_USER,
  CREATE_MASTERDATA_USER,
  LOAD_DETAILMASTERDATA_USER,
  LOAD_DETAILMASTERDATA_FOR_EDIT,
  UPDATE_MASTERDATA_USER,
  EXPORT_MASTERDATA_USER,
  IMPORT_USER,
  REMOVE_LISTS,
} from './actions'
const initialState = {
  lists: [],
  permiss: [],
  total: 1,
  loading: false,
  detailMasterDataUserloading: false,
  loadDetailMasterDataForEdit: false,
  loadDetailPermissionForEdit: false,
  check: true,
  check1: true,
  permiss: true,
  draw: -1,
  create_status: null,
  update_status: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_MASTERDATA_USER.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
        check: true,
      }

    case LOAD_MASTERDATA_USER.SUCCESS:
      const { page, pageSize } = action.payload

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * pageSize
      }

      return {
        ...state,
        lists: action.payload.lists.data,
        check: false,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_DETAILMASTERDATA_USER.REQUEST:
      const { detailMasterDataUserloading } = action.payload
      return {
        ...state,
        detailMasterDataUserloading: detailMasterDataUserloading,
        check: true,
        loading: loading,
      }

    case LOAD_DETAILMASTERDATA_USER.SUCCESS:
      return {
        ...state,
        lists: action.payload.lists.data,
        check: false,
        loading: false,
      }

    case CREATE_MASTERDATA_USER.SUCCESS:
      const { res } = action.payload
      return {
        ...state,
        loading: false,
        create_status: res.email,
      }

    case LOAD_DETAILMASTERDATA_FOR_EDIT.REQUEST:
      const { loadDetailMasterDataForEdit } = action.payload
      return {
        ...state,
        loadDetailMasterDataForEdit: loadDetailMasterDataForEdit,
      }

    case LOAD_DETAILMASTERDATA_FOR_EDIT.SUCCESS:
      return {
        lists: action.payload.lists,
        // lists: action.payload.lists[0],
        loadDetailMasterDataForEdit: false,
        check1: false,
      }

    case UPDATE_MASTERDATA_USER.REQUEST:
      return {
        ...state,
        check1: true,
      }

    case UPDATE_MASTERDATA_USER.SUCCESS:
      return {
        ...state,
        loading: false,
        update_status: action.payload.res.email,
      }

    case EXPORT_MASTERDATA_USER.REQUEST:
      return {
        ...state,
        check: true,
      }

    case EXPORT_MASTERDATA_USER.SUCCESS:
      return {
        ...state,
        check: false,
      }

    case IMPORT_USER.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case IMPORT_USER.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case IMPORT_USER.FALSE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
