import { createTypes, createAction } from 'lib/action'

const LOAD_PARKING = createTypes('parking', 'load')
const LOAD_PARKING_PREVIEW = createTypes('parkingpreview', 'load')
const LOAD_VEHICLE_PARKING = createTypes('vehicle_parking', 'load')
const LOAD_VEHICLE_TYPE_PARKING = createTypes('vehicletype_parking', 'load')

const loadParking = {
  request: (data, loading) =>
    createAction(LOAD_PARKING.REQUEST, { data, loading }),
  success: data => createAction(LOAD_PARKING.SUCCESS, { data }),
  failure: () => createAction(LOAD_PARKING.FAILURE),
}

const loadParkingpreview = {
  request: (data, loading) =>
    createAction(LOAD_PARKING_PREVIEW.REQUEST, { data, loading }),
  success: data => createAction(LOAD_PARKING_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_PARKING_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_PARKING.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_PARKING.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_PARKING.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_PARKING.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_PARKING.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_PARKING.FAILURE),
}

export {
  loadParking,
  LOAD_PARKING,
  loadParkingpreview,
  LOAD_PARKING_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_PARKING,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_PARKING,
}
