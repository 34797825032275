import { createTypes, createAction } from 'lib/action'

const LOAD_SCORESUMMARY = createTypes('scoresummary', 'load')
const LOAD_SCORESUMMARY_PREVIEW = createTypes('scoresummarypreview', 'load')
const LOAD_VEHICLE_SCORESUMMARY = createTypes('vehicle_scoresummary', 'load')

const loadScoresummary = {
  request: (data, loading) =>
    createAction(LOAD_SCORESUMMARY.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SCORESUMMARY.SUCCESS, { data }),
  failure: () => createAction(LOAD_SCORESUMMARY.FAILURE),
}

const loadScoresummarypreview = {
  request: (data, loading) =>
    createAction(LOAD_SCORESUMMARY_PREVIEW.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SCORESUMMARY_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_SCORESUMMARY_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_SCORESUMMARY.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_SCORESUMMARY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_SCORESUMMARY.FAILURE),
}

export {
  loadScoresummary,
  LOAD_SCORESUMMARY,
  loadScoresummarypreview,
  LOAD_SCORESUMMARY_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_SCORESUMMARY,
}
