import { call } from "redux-saga/effects";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`;

const headers = token => {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
};

const headersUpload = token => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
        }
    }
}

const fetcher = {
    post: (url, params, token) => {
        return call(axios.post, `${baseUrl}/${url}`, params, headers(token));
    },
    get: (url, token) => {
        return call(axios.get, `${baseUrl}/${url}`, headers(token));
    },
    patch: (url, params, token) => {
        return call(axios.patch, `${baseUrl}/${url}`, params, headers(token));
    },
    delete: (url, token) => {
        return call(axios.delete, `${baseUrl}/${url}`, headers(token));
    },
    download: (params) => {
        return window.location.assign(`${baseUrl}/download/${params}`);
    },
    upload: (url, params, token) => {
        return call(axios.post, `${baseUrl}/${url}`, params, headersUpload(token));
    }
};



const getErrorMsg = error => {
    const { response } = error;
    if (typeof response === "undefined") {
        return error.message
    }

    if (typeof response.data.msg !== "undefined") {
        if (typeof response.data.msg === 'object') {
            let msg = Object.keys(response.data.msg).map(x => response.data.msg[x])
            let errorMsg = ""
            if (typeof msg === 'object') {
                msg.map(data => {
                    if (typeof data === 'object') {
                        errorMsg = Object.keys(data).map(x => data[x])
                    } else {
                        errorMsg += (errorMsg === "" ? "" : " ,") + data
                    }
                    return true
                })
            } else {
                errorMsg = msg
            }
            return errorMsg
        }
        return response.data.msg;
    }
    return error.message + " : " + response.statusText;
};

const handleError = error => {
    const { response } = error;
    if (response.status === 401 || response.status === 403) {
        localStorage.clear();
        sessionStorage.clear();
        alert(getErrorMsg(error));
        window.location.href = "/signin";
    }
};

const logErrorMsg = error => {
    console.log("errorType", typeof error);
    console.log("error", Object.assign({}, error));
    console.log("getOwnPropertyNames", Object.getOwnPropertyNames(error));
    console.log("stackProperty", Object.getOwnPropertyDescriptor(error, "stack"));
    console.log(
        "messageProperty",
        Object.getOwnPropertyDescriptor(error, "message")
    );
    console.log("stackEnumerable", error.propertyIsEnumerable("stack"));
    console.log("messageEnumerable", error.propertyIsEnumerable("message"));
    console.log("message", error.message);
};


export { fetcher, logErrorMsg, getErrorMsg, handleError };
