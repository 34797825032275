import { combineReducers } from 'redux'

// etc
import auth from 'modules/auth/reducer'
import oauth from 'modules/oauth/reducer'
import ui from 'modules/ui/reducer'

// delivery management
import tripState from 'modules/delivery-management/trip/reducer'
import deliverytripState from 'modules/delivery-management/delivery-trip/reducer'

// setting
import policyState from 'modules/setting/policy/reducer'
import linenotify from 'modules/setting/linenotify/reducer'

// master data
import customerMasterDataState from 'modules/master-data/customer/reducer'
import usergroupMasterDataState from 'modules/master-data/usergroup/reducer'
import pullVehicleSink from 'modules/master-data/pullvehiclesink/reducer'
import permissionMasterDataState from 'modules/master-data/permission/reducer'
import datavisibilityMasterDataState from 'modules/master-data/datavisibility/reducer'
import placeMasterData from 'modules/master-data/facility/reducer'
import userMaster from 'modules/master-data/user/reducer'
import dataVehicleGroup from 'modules/master-data/vehicleGroup/reducer'
import routeMasterData from 'modules/master-data/route/reducer'
import vehicle from 'modules/master-data/vehicle/reducer'
import vehicleSharingMasterDataState from 'modules/master-data/vehicle-sharing/reducer'
import verification from 'modules/master-data/vehicle/reducer'
import deviceUser from 'modules/master-data/deviceuser/reducer'
// import permissionMasterDataState from "modules/master-data/permission/reducer";
import locationgroup from 'modules/master-data/locationgroup/reducer'
import sitegroup from 'modules/master-data/sitegroup/reducer'
import nearbyLocation from 'modules/master-data/nearbylocation/reducer'
import zone from 'modules/master-data/zone/reducer'
import refuel from 'modules/master-data/refuel/reducer'
import partner from 'modules/master-data/partner/reducer'
import locationSharing from 'modules/master-data/locations-sharing/reducer'
import parttype from 'modules/master-data/parttype/reducer'
import partgroup from 'modules/master-data/partgroup/reducer'
import managementMaintenance from 'modules/master-data/maintenance/reducer'
import expanseInsurance from 'modules/master-data/insurance/reducers'
import company from 'modules/master-data/company/reducer'
import uom from 'modules/master-data/uom/reducer'
import product from 'modules/master-data/product/reducer'
import material from 'modules/master-data/material/reducers'
import vehicleType from 'modules/master-data/vehicleType/reducer'
import subMaterial from 'modules/master-data/sub_material/reducers'
import isupplyMultidrop from 'modules/master-data/isupply-multidrop/reducers'
import isuppltSampleImage from 'modules/master-data/format-sample-image/reducers'

// operation job
import deliveryitemMasterDataState from 'modules/operate-job/delivery-item/reducer'
import deliveryitemDeleteHistoryMasterDataState from 'modules/operate-job/delivery-item-delete-history/reducer'
import gpsCertificationRequestState from 'modules/operate-job/gps-certification/reducer'

// booking
import bookingPoolState from 'modules/booking-pool/reducer'

//help
import servicerepairdevice from 'modules/help/servicerepairdevice/reducer'
import servicemovedevice from 'modules/help/servicemovedevice/reducer'
import serviceinstalldevice from 'modules/help/serviceeinstalldevice/reducer'
import maintenancedevice from 'modules/help/maintenancedevice/reducer'

// dashboard
import monitoringState from 'modules/monitoring/reducer'
import videoMonitoring from 'modules/monitoring/video/reducer'
import honeytoaststream from 'modules/monitoring/honeytoaststream/reducer'
import cashierState from 'modules/cashier/reducer'
import mdvrdownloadfile from 'modules/monitoring/mdvrdownloadfile/reducer'
import honeytoastdownloadfile from 'modules/monitoring/honeytoastdownloadfile/reducer'
import honeytoastplayback from 'modules/monitoring/honeytoastplayback/reducer'

//management
import downloadcertificate from 'modules/management/downloadcertificate/reducer'
import contactmanagement from 'modules/management/contactmanagement/reducer'

// report
import alarmAlertByPlateno from 'modules/reports/alarmalertbyplateno/reducer'
import alarmAlertByPlatenoOnyear from 'modules/reports/alarmalertbyplatenoonyear/reducer'
import alarmAlertByDriver from 'modules/reports/alarmalertbydriver/reducer'
import alarmAlertFrequencyByDriver from 'modules/reports/alarmalertfrequencybydriver/reducer'
import alarmAlertByDurationtime from 'modules/reports/alarmalertbydurationtime/reducer'
import drivingBehavior from 'modules/reports/drivingbehavior/reducer'
import drivingLicensescanBehavior from 'modules/reports/drivinglicensescanbehavior/reducer'
import separateVehicleStatus from 'modules/reports/separatevehiclestatus/reducer'
import compareVehicleScco from 'modules/reports/comparevehiclescco/reducer'
import alertDriverLicense from 'modules/reports/alertdriverlicense/reducer'
import parkingAreaOutside from 'modules/reports/parkingareaoutside/reducer'
//import truckusage from "modules/reports/MoveReport/Truckusage/reducer";
//import drivingdistance from "modules/reports/MoveReport/drivingdistance/reducer";
import parking from 'modules/reports/parking/reducer'
import drivinglicensescanlog from 'modules/reports/drivinglicensescanlog/reducer'
import speedoverlimitsummary from 'modules/reports/speedoverlimitsummary/reducer'
import drivinglog from 'modules/reports/drivinglog/reducer'
import event from 'modules/reports/event/reducer'
import summarykpi from 'modules/reports/summarykpi/reducer'
import loginhistory from 'modules/reports/loginhistory/reducer'
import gpsstatus from 'modules/reports/gpsstatus/reducer'
import trackingdata from 'modules/reports/trackingdata/reducer'
import truckusage from 'modules/reports/truckusage/reducer'
import drivingdistance from 'modules/reports/drivingdistance/reducer'
import temperature from 'modules/reports/temperature/reducer'
import maintenance from 'modules/reports/maintenance/reducer'
import distfromlastmaintenance from 'modules/reports/distfromlastmaintenance/reducer'
import truckcurrentlocation from 'modules/reports/truckcurrentlocation/reducer'
import driverinformation from 'modules/reports/driverinformation/reducer'
import numberofdaysworked from 'modules/reports/numberofdaysworked/reducer'
import dozeoffrisk from 'modules/reports/dozeoffrisk/reducer'
import delivery from 'modules/reports/delivery/reducer'
import triptemperature from 'modules/reports/triptemperature/reducer'
import tripsumbyveh from 'modules/reports/tripsumbyveh/reducer'
import daily from 'modules/reports/daily/reducer'
import velocitysummary from 'modules/reports/velocitysummary/reducer'
import truckengineon from 'modules/reports/truckengineon/reducer'
import truckengineoff from 'modules/reports/truckengineoff/reducer'
import trucknotuse from 'modules/reports/trucknotuse/reducer'
import drivinghour from 'modules/reports/drivinghour/reducer'
import tripsummary from 'modules/reports/tripsummary/reducer'
import drivingoverlimit from 'modules/reports/drivingoverlimit/reducer'
import tripsumbylocation from 'modules/reports/tripsumbylocation/reducer'
import insurance from 'modules/reports/insurance/reducer'
import vehicleperformanceconclude from 'modules/reports/vehicleperformanceconclude/reducer'
import reportrefuel from 'modules/reports/refuel/reducer'
import riskbehaviour from 'modules/reports/riskbehaviour/reducer'
import vehicleinarea from 'modules/reports/vehicleinarea/reducer'
import maintenancedeviceconclude from 'modules/reports/maintenancedeviceconclude/reducer'
import vehicleinformationdlt from 'modules/reports/vehicleinformationdlt/reducer'
import behaviordriving from 'modules/reports/behaviordriving/reducer'
import behaviordrivingbycompany from 'modules/reports/behaviordrivingbycompany/reducer'
import scoresummary from 'modules/reports/scoresummary/reducer'
import rmcconcretetrip from 'modules/reports/rmcconcretetrip/reducer'
import summaryVehicleUsage from 'modules/reports/summary-vehicle-usage/reducer'
import unauthorizeunload from 'modules/reports/unauthorizeunload/reducer'
import autoMoveSite from 'modules/reports/automovesite/reducer'
import vehicleSharingSystemAvailableAccount from 'modules/master-data/vehicle-sharing-system-avaliable-account/reducer.js'
import dailydrivingsummaryreport from 'modules/reports/daily-driving-summary-report/reducers'
import gpslostcontactreport from 'modules/reports/gps-lost-contact/reducers'
import gpsDisconnectOnTripReport from 'modules/reports/gpsdisconnectontrip/reducers'
import fueluse from 'modules/reports/fueluse/reducer'

import CpacPolicy from 'modules/GPS-Dashborad/gps-policy/reducer'
import CpacRequestCertificate from 'modules/GPS-Dashborad/requestcertificate/reducers'
import IsupplyRequestMaterial from 'modules/master-data/request-material/reducer'
import IsupplyRequestDelivery from 'modules/monitoring/isupply-delivery/reducers'
import IsupplyManagQueue from 'modules/management/isupply-manage-queue/reducers'
import IsupplyVehicleBlacklist from 'modules/management/isupply_vehicle_blacklist/reducers'
import IsupplyDriverBlacklist from 'modules/management/isupply_driver_blacklist/reducers'
import IsupplyPlantMiningContract from 'modules/master-data/isupply_plant_mining_contract/reducers'
import TruckStatusDashboard from 'modules/tracking_status_dashboard/tracking_status/reducers'
import SmartDispatching from 'modules/smartdispatching/reducers'
import IsupplyDashboard from 'modules/monitoring/isupply_dashboard/reducers'
import IsupplyDeliveryReport from 'modules/reports/isupply-delivery-report/reducers'
import IsupplyManageVehicle from 'modules/management/isupply-manage-vehicle/reducers'
import CpacPrecastFoor from 'modules/management/cpac-precast-transportation-information-floor/reducers'
import CpacPrecastWall from 'modules/management/cpac-precast-transportation-information-wall/reducers'
import CpacPrecastClose from 'modules/management/cpac-sales-close-information/reducers'
import CpacDashBoardPrecast from 'modules/monitoring/cpac-precast/reducers'
import CpacPrecastReport from 'modules/reports/cpac-precast/reducers'
import IsupplyVehicleStatusDailyJob from 'modules/monitoring/isupply-vehicle-status-daily-jobs/reducers'

import tripbyengineoffon from 'modules/reports/tripbyengineoffon/reducer'
import schedulereport from 'modules/reports/schedulereport/reducer'
import fuelRemainRate from 'modules/reports/fuelremainrate/reducer'
import routemaster from 'modules/delivery-management/route-master/reducer'
import isupplyOverviewPerformance from 'modules/monitoring/isupply_overiew_performance_dashboard/reducers'
import actionLog from 'modules/monitoring/action-log/reducer'
import isupplyWeightHistory from 'modules/reports/isupply-weighing-history/reducers'
import isupplyBookingKeyCdas from 'modules/master-data/booking-key-cdas/reducers'
import trucksPickupCard from 'modules/master-data/trucks-pickup-card/reducers'
import isupplyBookingOrder from 'modules/master-data/isupply-booking-order/reducers'
import isupplyBookingDashboard from 'modules/monitoring/isupply_booking_dashboard/reducers'
import isupplySaleorder from 'modules/master-data/isupply-saleorder/reducer'
import vehicleContract from 'modules/master-data/vehicle-contract/reducer'
import vehicleVisibilityPermissionGeneralData from 'modules/management/vehicleVisibilityPermissionGeneralData/reducer'
import vehicleVisibilityPermission from 'modules/management/vehicleVisibilityPermission/reducer'

import MonitorGPS from 'modules/monitoring/monitor-gps/reducer'

import replay from 'modules/monitoring/replaynew/reducer'

export default combineReducers({
  vehicleVisibilityPermissionGeneralData,
  vehicleVisibilityPermission,
  vehicleContract,
  tripbyengineoffon,
  schedulereport,
  fuelRemainRate,
  routemaster,
  auth,
  ui,
  bookingPoolState,
  monitoringState,
  cashierState,
  customerMasterDataState,
  alarmAlertByPlateno,
  gpsCertificationRequestState,
  alarmAlertByDriver,
  alarmAlertByDurationtime,
  drivingBehavior,
  drivingLicensescanBehavior,
  alarmAlertByPlatenoOnyear,
  separateVehicleStatus,
  compareVehicleScco,
  alertDriverLicense,
  parkingAreaOutside,
  usergroupMasterDataState,
  deliveryitemMasterDataState,
  deliveryitemDeleteHistoryMasterDataState,
  pullVehicleSink,
  parking,
  summarykpi,
  datavisibilityMasterDataState,
  placeMasterData,
  userMaster,
  dataVehicleGroup,
  routeMasterData,
  replay,
  vehicle,
  loginhistory,
  gpsstatus,
  trackingdata,
  vehicleSharingMasterDataState,
  videoMonitoring,
  drivingdistance,
  drivinglicensescanlog,
  speedoverlimitsummary,
  drivinglog,
  event,
  permissionMasterDataState,
  alarmAlertFrequencyByDriver,
  verification,
  deviceUser,
  locationgroup,
  sitegroup,
  nearbyLocation,
  zone,
  refuel,
  truckusage,
  temperature,
  maintenance,
  distfromlastmaintenance,
  truckcurrentlocation,
  driverinformation,
  numberofdaysworked,
  dozeoffrisk,
  delivery,
  triptemperature,
  tripsumbyveh,
  daily,
  velocitysummary,
  truckengineon,
  truckengineoff,
  trucknotuse,
  drivinghour,
  tripState,
  tripsummary,
  drivingoverlimit,
  tripsumbylocation,
  insurance,
  vehicleperformanceconclude,
  reportrefuel,
  riskbehaviour,
  partner,
  locationSharing,
  vehicleinarea,
  maintenancedeviceconclude,
  downloadcertificate,
  parttype,
  partgroup,
  policyState,
  managementMaintenance,
  // policyState
  contactmanagement,
  mdvrdownloadfile,
  servicerepairdevice,
  deliverytripState,
  expanseInsurance,
  vehicleinformationdlt,
  company,
  servicemovedevice,
  honeytoaststream,
  serviceinstalldevice,
  honeytoastdownloadfile,
  honeytoastplayback,
  CpacPolicy,
  behaviordriving,
  behaviordrivingbycompany,
  scoresummary,
  CpacRequestCertificate,
  maintenancedevice,
  IsupplyRequestMaterial,
  IsupplyRequestDelivery,
  IsupplyManagQueue,
  IsupplyVehicleBlacklist,
  IsupplyDriverBlacklist,
  IsupplyPlantMiningContract,
  TruckStatusDashboard,
  SmartDispatching,
  IsupplyDashboard,
  IsupplyDeliveryReport,
  IsupplyManageVehicle,
  CpacPrecastFoor,
  CpacPrecastWall,
  CpacPrecastClose,
  CpacDashBoardPrecast,
  CpacPrecastReport,
  IsupplyVehicleStatusDailyJob,
  isupplyOverviewPerformance,
  uom,
  product,
  rmcconcretetrip,
  summaryVehicleUsage,
  unauthorizeunload,
  autoMoveSite,
  vehicleSharingSystemAvailableAccount,
  dailydrivingsummaryreport,
  gpslostcontactreport,
  fueluse,
  actionLog,
  linenotify,
  material,
  gpsDisconnectOnTripReport,
  isupplyWeightHistory,
  vehicleType,
  isupplyBookingKeyCdas,
  subMaterial,
  isupplyMultidrop,
  isuppltSampleImage,
  trucksPickupCard,
  isupplyBookingOrder,
  isupplyBookingDashboard,
  isupplySaleorder,
  MonitorGPS,
  oauth,
})
