import { Modal, Button, Row, Col, Icon, List } from 'antd'
import React, { useState } from 'react'
import LangContext from 'modules/shared/context/langContext'

const Notification = ({ showModal, onClose, infoSearch }) => {
  return (
    <Modal
      title={
        <div style={{ textAlign: 'center', color: '#172B4D' }}>
          <LangContext.Consumer>{i18n => i18n.d.detail}</LangContext.Consumer>
        </div>
      }
      centered
      visible={showModal}
      onCancel={() => {
        onClose()
      }}
      width={450}
      className="modal-edit-delete-route-smart-dispatching"
      footer={false}
      style={{ textAlign: 'center', color: '#172B4D' }}
    >
      <p>{infoSearch.location_code}</p>
      <p>{infoSearch.location_name}</p>
    </Modal>
  )
}

export default Notification
