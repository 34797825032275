import { createTypes, createAction } from 'lib/action'

const LOAD_MASTERDATA_PLACE = createTypes('masterdataplace', 'load')
const CREATE_MASTERDATAPLACE = createTypes('masterdataplace', 'create')
const LOAD_DETAILMASTERDATA_PLACE = createTypes('detailmasterdataplace', 'load')
const UPDATE_DETAILMASTERDATA_PLACE = createTypes(
  'detailmasterdataplace',
  'update',
)
const IMPORT_LOCATION = createTypes('importlocation', 'create')
const IMPORT_MULTIPLE_LOCATION = createTypes('importmultiplelocation', 'create')
const CREATE_SALES_CLOSE = createTypes('createsalescolse', 'create')
const EXPORT_MASTERDATA_PLACE = createTypes('exportmasterdataplace', 'load')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadMasterDataPlace = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_PLACE.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_MASTERDATA_PLACE.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_MASTERDATA_PLACE.FAITLURE),
}

const loadDetailMasterDataPlace = {
  request: (data, loading) =>
    createAction(LOAD_DETAILMASTERDATA_PLACE.REQUEST, { data, loading }),
  success: value =>
    createAction(LOAD_DETAILMASTERDATA_PLACE.SUCCESS, { value }),
  failure: () => createAction(LOAD_DETAILMASTERDATA_PLACE.FAITLURE),
}

const createMasterDataPlace = {
  request: (data, loading) =>
    createAction(CREATE_MASTERDATAPLACE.REQUEST, { data, loading }),
  success: res => createAction(CREATE_MASTERDATAPLACE.SUCCESS, { res }),
  failure: () => createAction(CREATE_MASTERDATAPLACE.FAILURE),
}

const upDateDetailMasterDataPlace = {
  request: (data, loading) =>
    createAction(UPDATE_DETAILMASTERDATA_PLACE.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_DETAILMASTERDATA_PLACE.SUCCESS, { res }),
  failure: () => createAction(UPDATE_DETAILMASTERDATA_PLACE.FAILURE),
}

const createSalesClose = {
  request: (data, loading) =>
    createAction(CREATE_SALES_CLOSE.REQUEST, { data, loading }),
  success: res => createAction(CREATE_SALES_CLOSE.SUCCESS, { res }),
  failure: () => createAction(CREATE_SALES_CLOSE.FAITLURE),
}

const exportMasterDataPlace = {
  request: (data, loading) =>
    createAction(EXPORT_MASTERDATA_PLACE.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_MASTERDATA_PLACE.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_MASTERDATA_PLACE.FAITLURE),
}

const importLocation = {
  request: (data, loading) =>
    createAction(IMPORT_LOCATION.REQUEST, { data, loading }),
  success: res => createAction(IMPORT_LOCATION.SUCCESS, { res }),
  failure: () => createAction(IMPORT_LOCATION.FAITLURE),
}

const importMultipleLocation = {
  request: (data, loading) =>
    createAction(IMPORT_MULTIPLE_LOCATION.REQUEST, { data, loading }),
  success: res => createAction(IMPORT_MULTIPLE_LOCATION.SUCCESS, { res }),
  failure: () => createAction(IMPORT_MULTIPLE_LOCATION.FAITLURE),
}

export {
  LOAD_MASTERDATA_PLACE,
  loadMasterDataPlace,
  loadDetailMasterDataPlace,
  LOAD_DETAILMASTERDATA_PLACE,
  CREATE_SALES_CLOSE,
  createSalesClose,
  CREATE_MASTERDATAPLACE,
  createMasterDataPlace,
  upDateDetailMasterDataPlace,
  UPDATE_DETAILMASTERDATA_PLACE,
  EXPORT_MASTERDATA_PLACE,
  exportMasterDataPlace,
  IMPORT_LOCATION,
  importLocation,
  IMPORT_MULTIPLE_LOCATION,
  importMultipleLocation,
  REMOVE_LISTS,
  removeLists,
}
