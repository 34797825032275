import { createTypes, createAction } from 'lib/action'
const LOAD_DRIVER_BLACKLIST = createTypes('loaddriverblacklist', 'load')
const CREATE_DRIVER_BLACKLIST = createTypes('createdriver', 'create')
const LOAD_DRIVER_BLACKLIST_DETAIL = createTypes(
  'loaddriverblacklistdetail',
  'load',
)
const UPDATE_DRIVER_BLACKLIST = createTypes('updatedriverblacklist', 'update')
const EXPORT_DRIVER_BLACKLIST = createTypes('exportdriverblacklist', 'load')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadDriverBlacklist = {
  request: (data, loading) =>
    createAction(LOAD_DRIVER_BLACKLIST.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_DRIVER_BLACKLIST.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_DRIVER_BLACKLIST.FAITLURE),
}

const createDriverBlacklist = {
  request: (data, loading) =>
    createAction(CREATE_DRIVER_BLACKLIST.REQUEST, { data, loading }),
  success: res => createAction(CREATE_DRIVER_BLACKLIST.SUCCESS, { res }),
  failure: () => createAction(CREATE_DRIVER_BLACKLIST.FAILURE),
}

const loadDriverBlacklistDetail = {
  request: (data, loading) =>
    createAction(LOAD_DRIVER_BLACKLIST_DETAIL.REQUEST, { data, loading }),
  success: lists =>
    createAction(LOAD_DRIVER_BLACKLIST_DETAIL.SUCCESS, { lists }),
  failure: () => createAction(LOAD_DRIVER_BLACKLIST_DETAIL.FAILURE),
}

const updateDriverBlacklist = {
  request: (data, loading) =>
    createAction(UPDATE_DRIVER_BLACKLIST.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_DRIVER_BLACKLIST.SUCCESS, { res }),
  failure: () => createAction(UPDATE_DRIVER_BLACKLIST.FAILURE),
}

const exportIsupplyDriverBlacklist = {
  request: (data, loading) =>
    createAction(EXPORT_DRIVER_BLACKLIST.REQUEST, { data, loading }),
  success: res => createAction(EXPORT_DRIVER_BLACKLIST.SUCCESS, { res }),
  failure: () => createAction(EXPORT_DRIVER_BLACKLIST.FAILURE),
}

export {
  LOAD_DRIVER_BLACKLIST,
  loadDriverBlacklist,
  CREATE_DRIVER_BLACKLIST,
  createDriverBlacklist,
  LOAD_DRIVER_BLACKLIST_DETAIL,
  loadDriverBlacklistDetail,
  UPDATE_DRIVER_BLACKLIST,
  updateDriverBlacklist,
  EXPORT_DRIVER_BLACKLIST,
  exportIsupplyDriverBlacklist,
  REMOVE_LISTS,
  removeLists,
}
