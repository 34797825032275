import {
  LOAD_SCORESUMMARY,
  LOAD_SCORESUMMARY_PREVIEW,
  LOAD_VEHICLE_SCORESUMMARY,
} from './actions'

const initialState = {
  vehicleLists: [],
  scoresummaryLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SCORESUMMARY.REQUEST:
    case LOAD_SCORESUMMARY_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        scoresummaryLoading: loading,
      }
    case LOAD_SCORESUMMARY.SUCCESS:
    case LOAD_SCORESUMMARY_PREVIEW.SUCCESS:
      return {
        ...state,
        scoresummaryLoading: false,
      }
    case LOAD_VEHICLE_SCORESUMMARY.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        scoresummaryLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_SCORESUMMARY.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        scoresummaryLoading: false,
      }
    default:
      return state
  }
}
