import {
  LOAD_TRUCKENGINEON,
  LOAD_TRUCKENGINEON_PREVIEW,
  LOAD_VEHICLE_TRUCKENGINEON,
  LOAD_VEHICLE_TYPE_TRUCKENGINEON,
} from './actions'

const initialState = {
  truckengineonUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  truckengineonLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRUCKENGINEON.REQUEST:
    case LOAD_TRUCKENGINEON_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        truckengineonLoading: loading,
      }
    case LOAD_TRUCKENGINEON.SUCCESS:
    case LOAD_TRUCKENGINEON_PREVIEW.SUCCESS:
      return {
        ...state,
        truckengineonUrl: action.payload.data.data,
        truckengineonLoading: false,
      }
    case LOAD_VEHICLE_TRUCKENGINEON.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        truckengineonLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_TRUCKENGINEON.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        truckengineonLoading: false,
      }
    case LOAD_VEHICLE_TYPE_TRUCKENGINEON.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
