import { createTypes, createAction } from "lib/action";
const CREATE_SALES_CLOSE  = createTypes("createsalescolse", "create");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
      type: REMOVE_LISTS
    };
};

const createSalesClose = {
  request: (data, loading) =>
      createAction(CREATE_SALES_CLOSE.REQUEST, { data, loading}),
  success: res => createAction(CREATE_SALES_CLOSE.SUCCESS, { res }),
      failure: () => createAction(CREATE_SALES_CLOSE.FAITLURE)
};

export {
    CREATE_SALES_CLOSE, createSalesClose,
    REMOVE_LISTS, removeLists
};
