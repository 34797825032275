import {
    LOAD_DATA_FOR_VEHICLE_STATUS_DAILY_JOBE,
    LOADDATA_VEHICLE_STATUS_FOR_DETAIL,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    draw: -1,
    loading: false,
    loadingForDetail: false,
    checkLoaddatafordetail: true,
    listsDataForDetail: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;
        
        
        case LOAD_DATA_FOR_VEHICLE_STATUS_DAILY_JOBE.REQUEST:
            const { loading } = action.payload;
                return {
                    ...state,
                    loading: loading,
                };

        case LOAD_DATA_FOR_VEHICLE_STATUS_DAILY_JOBE.SUCCESS:
            return {
                ...state,
                lists: action.payload.lists,
                loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case LOADDATA_VEHICLE_STATUS_FOR_DETAIL.REQUEST:
            return {
                ...state,
                loadingForDetail: action.payload.data.loading,
                checkLoaddatafordetail: true,
            }

        case LOADDATA_VEHICLE_STATUS_FOR_DETAIL.SUCCESS:
            return {
                ...state,
                listsDataForDetail: action.payload.lists.data,
                loadingForDetail: false,
                checkLoaddatafordetail: false,
            };


        default:
            return state;
    }
}