import React, { useState, useEffect } from 'react'
import { Modal, Input, message } from 'antd'
import LangContext from 'modules/shared/context/langContext'
import axios from 'axios'

function RemarkModal(props) {
  const [remark, setRemark] = useState('')

  const handleOk = () => {
    if (props.Id === '') {
      message.error('ไม่มีค่า ID ที่ต้องการแก้ไข')
      return
    }

    if (remark.length > 500) {
      message.error('หมายเหตุ (Vendor) ต้องไม่เกิน 500 ตัวอักษร')
      return
    }

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice/updatevenderremark`,
        {
          id: props.Id,
          vendor_remark: remark,
        },
        {
          headers: { Authorization: `Bearer ${props.accessToken}` },
        },
      )
      .then(function(res) {
        if (res.data.status === 'success') {
          message.success('แก้ไขสำเร็จ')
          props.handleCancel()
          props.reLoad()
        }
      })
  }

  const handleRemarkChange = e => {
    setRemark(e.target.value)
  }

  useEffect(() => {
    if (props.value === null) {
      setRemark('')
    } else {
      setRemark(props.value)
    }
  }, [props.value])

  return (
    <Modal
      title="แก้ไขหมายเหตุ (Vendor)"
      visible={props.visible}
      onCancel={props.handleCancel}
      onOk={handleOk}
      destroyOnClose={true}
      okText={
        <LangContext.Consumer>{i18n => i18n.s.save}</LangContext.Consumer>
      }
      cancelText={
        <LangContext.Consumer>{i18n => i18n.c.cancel}</LangContext.Consumer>
      }
    >
      <Input.TextArea rows={4} onChange={handleRemarkChange} value={remark} />
    </Modal>
  )
}

export default RemarkModal
