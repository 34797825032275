import React, { Fragment } from 'react'
import { appendScript } from 'lib/useScript'
export class Index extends React.Component {
  mapRef = React.createRef()

  state = {
    // The map instance to use during cleanup
    map: null,
    maps: null,
    heatmapLayer: null,
    allMarker: null,
    mapMount: false,
    loadScriptSuccess: false,
    loadScript2Success: false,
    loadScript3Success: false,
    vehicleMarker: null,
    marker: null,
  }

  componentDidMount() {
    this.setState(
      {
        map: null,
        maps: null,
        heatmapLayer: null,
        allMarker: null,
        mapMount: false,
        loadScriptSuccess: false,
        loadScript2Success: false,
        loadScript3Success: false,
        vehicleMarker: null,
        marker: null,
      },
      () => {
        let self = this
        appendScript(
          'script',
          `${process.env.REACT_APP_LONGDO_MAP_ENDPOINT}/map2/?key=` +
          this.props.auth.profile.longdo_map_key,
          self,
          null,
          'loadScriptSuccess',
        )
      },
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.loadScriptSuccess != nextState.loadScriptSuccess &&
      nextState.loadScriptSuccess == true
    ) {
      // start load script heatmap
      let self = this
      appendScript(
        'script',
        '/js/heatmap.js',
        self,
        () =>
          appendScript(
            'script',
            '/js/longdomap-heatmap.js',
            self,
            null,
            'loadScript3Success',
          ),
        'loadScript2Success',
      )
      // end load script heatmap
      this.initMap()
    }

    if (
      this.state.loadScript2Success &&
      this.state.loadScript3Success &&
      this.state.loadScriptSuccess &&
      this.props.markers.length != nextProps.markers.length
    ) {
      this.createMarker(nextProps, nextState)
    }

    return true
  }

  componentDidUpdate(prevProps) {
    const { lat, lng } = this.props.eventMarkerLatLng
    const prev = prevProps.eventMarkerLatLng

    if ((lat !== prev.lat || lng !== prev.lng) && lng !== '' && lng !== '') {
      let marker = new window.longdo.Marker(
        {
          lon: parseFloat(lng),
          lat: parseFloat(lat),
        },
        {
          icon: {
            url:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAElklEQVRoge2aT6gVZRjGf8/lIiIuRERcRNCcjXfIEImWIfeARIvCVhEFroKWLQxahASJRIRJRkZk5MZLixCzoptzkBZCFnETnbPxDtKihchFREQuh/O0mJl75pz778yfIy16Lx/zLM687/N83zPvzDdzRcORtMMnjWcx+yTtBvq27wB/SeoEUfxPk/XURJKkHQI8b/weZhaYEmANijiFPWBe6P0giq81Ubu2gMXZcJvEZ4YjBaID0gbJGBVxX3AaczToxMt16tcSkLTDHcDPwHO4kG18fBl4OYjih1U5VBaw2A6nsX+ROOiMmIB1caHgCL4odDiI4n4VHtNVBQjeMRzEazK/b/Sj5JtGU8A+zIuIbcDoSryEeBM4U5FH+Uja4W7jRcz2IUsAQmeQ3w2i7r3iOYvtcBdwEvv1NVLelXgqiLoPynKZKntCxvWI0HZJrPxJgI4h3holD9CK4rvAGxKfSOnvC2OX0atVuFQSIDhse8XLtgGuSv4giOJ1z2tFMZijwI3iuZmjDlfhUlpAMjszbbw/971xbsSTQdTd9EIMOt0ecAo7F57neTZpz5SlU/4itrRLsBUYuoIMV0qk+TW13FDsJM37qAyf8hZyKnrUQsJLJbIsjVoo41J6QksLEH5g6KP0zpp2T2G0p0SaPThrXIM8y5ScfaiyAtJ94YfCSNnAgF8YN4Xxofy8Qp47QRT3ytIpLSC9YyoxeetX1kV0NGmHWzc7f7Edbse8nV6/mYQU3yrLBareB+zrIPBKcWz22v48aYfr5kza4RbwN0hP5MLTEMCNKlwqCQB+H7VQijmC+SmZndmbPWLnxElmZ/ZjIsErK78vWMjwWxUilZ6FBFcHD2TCK2YSlg8BNwULSTuMnU7S00bPgLPfAzZSjkH46mMTgLQALGF2DnWSAZ4yHEAcwNnGJtvhFG2zguXE6HYVKpUsFERxT3B5tYXWwWyC0Xxrg0eQxgWk4R+KXWhdvLrbrMLA91VZVBZgdAmrlxMpdqQhzDq2GeD7QKcqj8oCWlG8JNGpbSFzKYji0nfg2gKyOF/XQuDzdQjUE2BfMH6Ys7azVlTEkLVZr4XvAPN1KNQSEHS692RdWGOHNTwKu7ZhzFzQ6dZ6rVLXQhi+Suc0n9URnG1a7GGcHvV13fq1BUi+Ar41sI0ZwmSCNIyNr0ks1K1fW0AQdftCX5a2EPpio/3zYxMAYHzW+FGKx7GQl4C5Jmo3IqAVde8K5sa1kKSzdV4nFqMRAQBGp8a0UA/4tKm6jQkALwCdMSz0XRDFfzdVtTEBragL8NGmFrI/bqomNLoCYDwv6foGFroiqZEPG3k0KqCVvpn7cAMLnWiidRajUQFZfItJVlkI/pBU67lnrWhcQBDFPUknVlvIx5uefZjMCgCcA24XLHQddHEShSYiIIjiZeD4wEIcCzrVPiFtFpNaAYBzkm5J+lMwkdmfeCTt8LWkHY79zrRKVP7IN2bMGSZinf/jvxKN/K8EsCUb09nIm0N+7BeOvWwsZ6NWNCFgK7CD8h2tD9yjwleZYjS1ApCuQP6da6MVyFeh9uwD/Asypo/TAASupgAAAABJRU5ErkJggg==',
            offset: { x: 24, y: 40 },
          },
          draggable: false,
        },
      )

      this.state.map.Overlays.remove(this.state.marker)

      this.state.map.Overlays.add(marker)
      this.state.map.location(
        {
          lon: parseFloat(lng),
          lat: parseFloat(lat),
        },
        true,
      )
      this.state.map.zoom(18, true)
      this.setState({ marker: marker })
    }
  }

  createMarker = (nextProps, nextState) => {
    try {
      var data = {
        max: 10,
        data: nextProps.markers,
      }

      if (typeof window.HeatmapOverlay != 'undefined') {
        var cfg = {
          radius: 10,
          maxOpacity: 0.5,
          scaleRadius: false,
          useLocalExtrema: true,
        }
        let heatmapLayer = new window.HeatmapOverlay(cfg)
        heatmapLayer.setData(data)
        this.state.map && this.state.map.Layers.clear()
        nextState.map && nextState.map.Layers.clear()
        nextState.map && nextState.map.Layers.add(heatmapLayer)
      }
    } catch (error) {
      console.log('error.getMessage ', error)
    }
  }

  //   initMap = () => {
  //     let maps = window.longdo
  //     let self = this

  //     let map = null

  //     map = new maps.Map({
  //       placeholder: document.getElementById(this.props.refId),
  //       zoom: 16,
  //     })
  //     window.addEventListener('resize', () => {
  //       map.resize()
  //     })
  //     map.Ui.DPad.visible(false) // เครื่องมือเลื่อนแผนที่ (อยู่บริเวณมุมบนซ้าย)
  //     map.Ui.Zoombar.visible(false) // แถบซูม (อยู่บริเวณมุมบนซ้าย)
  //     map.Ui.Geolocation.visible(false) // ปุ่มบอกตำแหน่งปัจจุบัน (อยู่บริเวณมุมบนซ้าย)
  //     map.Ui.Toolbar.visible(false) // แถบเครื่องมือ (อยู่บริเวณมุมบนซ้าย)
  //     map.Ui.LayerSelector.visible(false) // ตัวเลือกรูปแบบแผนที (อยู่บริเวณมุมบนขวา)
  //     map.Ui.Crosshair.visible(false) // สัญลักษณ์แสดงจุดกึ่งกลาง (ตรงกลางแผนที่)
  //     map.Layers.setBase(maps.Layers.NORMAL)
  //     map.zoom(true, true)
  //     map.Layers.externalOptions({
  //       googleQuery: `key=${this.props.auth.profile.google_map_key}`,
  //     })
  //     map.Layers.setBase(maps.Layers.GOOGLE_ROADMAP)

  //     map.Event.bind('ready', function() {
  //       // When map is ready to use

  //       let { maxLat, maxLon, minLat, minLon } = map.bound()

  //       let resObj = {
  //         nw: { lat: maxLat, lng: minLon },
  //         se: { lat: minLat, lng: maxLon },
  //       }

  //       let zoom = map.zoom()
  //       let center = map.location()

  //       self.props.setMapBound({
  //         bounds: resObj,
  //         center: { lat: center.lat, lng: center.lon },
  //         zoom: zoom,
  //       })

  //       self.props.handleApiLoaded(map, maps)
  //       self.setState(
  //         {
  //           map: map,
  //           maps: maps,
  //         },
  //         () => {
  //           map.location({ lon: 100.7165, lat: 13.8309 })
  //           map.zoom(6, true)
  //         },
  //       )
  //     })
  //   }

  initMap = () => {
    let maps = window.longdo
    let self = this

    let map = null

    map = new maps.Map({
      placeholder: document.getElementById(this.props.refId),
      zoom: 5,
    })
    window.addEventListener('resize', () => {
      map.resize()
    })
    map.Ui.Fullscreen.visible(true)
    map.Ui.DPad.visible(false) // เครื่องมือเลื่อนแผนที่ (อยู่บริเวณมุมบนซ้าย)
    map.Ui.Zoombar.visible(false) // แถบซูม (อยู่บริเวณมุมบนซ้าย)
    map.Ui.Geolocation.visible(false) // ปุ่มบอกตำแหน่งปัจจุบัน (อยู่บริเวณมุมบนซ้าย)
    map.Ui.Toolbar.visible(false) // แถบเครื่องมือ (อยู่บริเวณมุมบนซ้าย)
    map.Ui.LayerSelector.visible(false) // ตัวเลือกรูปแบบแผนที (อยู่บริเวณมุมบนขวา)
    map.Ui.Crosshair.visible(false) // สัญลักษณ์แสดงจุดกึ่งกลาง (ตรงกลางแผนที่)
    //map.Layers.setBase(maps.Layers.NORMAL);
    map.zoom(6, true)

    map.Layers.externalOptions({
      //   googleQuery: `key=AIzaSyCBh7O-7raiK3L0zOguKR1uAryIwBCQFA4`,
      googleQuery: `key=${this.props.auth.profile.google_map_key}`,
    })

    map.Layers.setBase(maps.Layers.GOOGLE_ROADMAP)
    map.Ui.Mouse.enableInertia(false)
    map.Ui.Keyboard.enable(false)

    map.Event.bind('drag', () => {
      map.Ui.Mouse.enableWheel(false)
      map.Ui.Mouse.enableClick(false)
    })
    map.Event.bind('drop', () => {
      map.Ui.Mouse.enableWheel(true)
      map.Ui.Mouse.enableClick(true)
    })
    map.Event.bind('zoom', () => {
      map.Ui.Mouse.enableClick(false)
      map.Ui.Mouse.enableDrag(false)
      map.Ui.Mouse.overlayDoubleClick(false)
      setTimeout(function () {
        map.Ui.Mouse.enableClick(true)
        map.Ui.Mouse.enableDrag(true)
        map.Ui.Mouse.overlayDoubleClick(true)
      }, 1200)
    })
    let allMarker = []

    this.setState({ map: map })
    map.Event.bind('ready', function () {
      self.setState({
        map: map,
        maps: maps,
      })
      self.props.handleApiLoaded(map, maps)
    })
  }

  componentWillUnmount() {
    // Cleanup after the map to avoid memory leaks when this component exits the page
    // this.state.map && this.state.map.dispose();
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>
  }
}

export default Index
