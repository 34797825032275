import {
  LOAD_SERVICEMOVEDEVICE_HISTORY,
  LOAD_SERVICEMOVEDEVICE,
  LOAD_VEHICLETYPE,
  LOAD_MAINTENANCETYPE,
  LOAD_MAINTENANCESTATUS,
  LOAD_VEHICLELISTS,
  LOAD_VEHICLELISTSBYID,
  LOAD_PROVINCE,
  LOAD_AMPHUR,
  CREATE,
  REMOVE_LISTS,
} from './actions'
const initialState = {
  Historylists: [],
  Hitorytotal: 1,
  lists: [],
  VehicleTyle: [],
  MaintenanceTyle: [],
  MaintenanceStatus: [],
  vehicleLists: [],
  vehicleByIDLists: [],
  ProvinceLists: [],
  AmphurLists: [],
  total: 1,
  loading: false,
  Historyloading: false,
  check: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_SERVICEMOVEDEVICE.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        loading: loading,
      }

    case LOAD_SERVICEMOVEDEVICE.SUCCESS:
      const { page } = action.payload

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10
      }

      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_SERVICEMOVEDEVICE_HISTORY.REQUEST:
      return {
        ...state,
        Historyloading: action.payload.loading,
      }

    case LOAD_SERVICEMOVEDEVICE_HISTORY.SUCCESS:
      for (let i = 0; i < action.payload.Historylists.data.length; i++) {
        action.payload.Historylists.data[i].rowNumber = i + 1
        if (action.payload.page > 1)
          action.payload.Historylists.data[i].rowNumber =
            action.payload.Historylists.data[i].rowNumber +
            (action.payload.page - 1) * 10
      }

      return {
        ...state,
        Historylists: action.payload.Historylists.data,
        Hitorytotal:
          action.payload.Historylists.total - 1 <= 0
            ? 1
            : action.payload.Historylists.total <= 0
            ? 1
            : action.payload.Historylists.total,
        Historyloading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_VEHICLETYPE.SUCCESS:
      return {
        ...state,
        VehicleTyle: action.payload.VehicleTyle,
      }

    case LOAD_MAINTENANCETYPE.SUCCESS:
      return {
        ...state,
        MaintenanceTyle: action.payload.MaintenanceTyle,
      }

    case LOAD_MAINTENANCESTATUS.SUCCESS:
      return {
        ...state,
        MaintenanceStatus: action.payload.MaintenanceStatus,
      }

    case LOAD_VEHICLELISTS.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.vehicleLists.data,
      }

    case LOAD_VEHICLELISTSBYID.SUCCESS:
      return {
        ...state,
        vehicleByIDLists: action.payload.vehicleByIDLists.data,
      }

    case LOAD_PROVINCE.SUCCESS:
      return {
        ...state,
        ProvinceLists: action.payload.ProvinceLists.data,
      }

    case LOAD_AMPHUR.SUCCESS:
      return {
        ...state,
        AmphurLists: action.payload.AmphurLists.data,
      }

    case CREATE.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
