import React from 'react'
import { Icon, Collapse, Row, Col } from 'antd'
import LangContext from 'modules/shared/context/langContext'

const { Panel } = Collapse
const NeighborhoodInformation = ({
  handleGetIdForEditOrDelete,
  handleMarkerPopup,
  handleShowNearestModal,
  allDataFromSearch,
  openNotification,
  handleGotoLocation,
  handleGetLocationFromSearch,
  longdoMapLocationList,
}) => {
  return (
    <div style={{ zIndex: '13', position: "absolute" }}>
      <LangContext.Consumer>
        {i18n => (
          <Collapse
            defaultActiveKey={['1']}
            onChange={e => {
              // console.log('collapse onchange', e)
            }}
            bordered={false}
            expandIconPosition="right"
            style={{
              width: '400px',
              backgroundColor: 'white',
              borderRadius: '10px',
              borderBottom: '0px',
            }}
            expandIcon={({ isActive }) => (
              <Icon
                type="down"
                rotate={isActive ? 180 : 0}
                style={{
                  backgroundColor: '#F53C56',
                  color: 'white',
                  padding: '3px',
                  borderRadius: '5px',
                  fontWeight: 800,
                  fontSize: '15px',
                }}
              />
            )}
          >
            <Panel
              header={
                <span
                  style={{
                    color: '#F53C56',
                    fontWeight: 800,
                  }}
                >
                  {i18n.n.neighborhoodInformation}
                </span>
              }
              key="1"
            >
              <div className="scrollbar" id="style-1">
                <Collapse
                  onChange={e => {
                    //   console.log('collapse onchange', e)
                  }}
                  bordered={false}
                  expandIconPosition="right"
                  style={{
                    backgroundColor: 'white',
                  }}
                  expandIcon={({ isActive }) => (
                    <Icon
                      type="down"
                      rotate={isActive ? 180 : 0}
                      style={{
                        color: '#172B4D',
                        fontWeight: 800,
                        fontSize: '14px',
                        marginLeft: '16px',
                      }}
                    />
                  )}
                  className="sub-collapse-search"
                  defaultActiveKey={["6"]}
                >
                  {/* แพลนต์ปูน */}
                  {typeof allDataFromSearch.data != 'undefined' &&
                    allDataFromSearch.data.plant.no_filter_bounds.length > 0 && (
                      <Panel
                        className="sub-panel-search"
                        header={
                          <div
                            style={{
                              color: '#172B4D',
                              fontWeight: 800,
                              paddingLeft: 0,
                              marginBottom: '2px',
                              marginTop: '2px',
                            }}
                          >
                            <img
                              src="/img/plant.png"
                              style={{ width: '25px', marginTop: '-13px' }}
                            />
                            {'  '}
                            <span>{i18n.p.plantConcrete}</span>
                          </div>
                        }
                        key="1"
                        style={{
                          paddingLeft: 0,
                          borderBottom: '1px solid #E8E8E8',
                          width: '97%',
                        }}
                      >
                        <Row>
                          {allDataFromSearch.data.plant.no_filter_bounds.map(
                            item => (
                              <>
                                <Col span={24} align="left">
                                  <div
                                    onClick={() => {
                                      handleGotoLocation(item, 'แพลนต์ปูน')
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <span style={{ color: '#172B4D' }}>
                                      {item.location_name}
                                    </span>{' '}
                                    <span style={{ color: '#D6D6D6' }}>
                                      {i18n.f.factoryCode}: {item.location_code}
                                    </span>
                                    <p style={{ color: '#D6D6D6' }}>
                                      {i18n.s.section}:{' '}
                                      {item.section_name != null
                                        ? item.section_name
                                        : '-'}
                                    </p>
                                  </div>
                                </Col>
                              </>
                            ),
                          )}
                        </Row>
                      </Panel>
                    )}
                  {/* เส้นทางรถโม่เล็ก */}
                  {typeof allDataFromSearch.data != 'undefined' &&
                    allDataFromSearch.data.small_truck_route.no_filter_bounds
                      .length > 0 && (
                      <Panel
                        className="sub-panel-search"
                        header={
                          <div
                            style={{
                              color: '#172B4D',
                              fontWeight: 800,
                              paddingLeft: 0,
                              marginBottom: '2px',
                              marginTop: '2px',
                            }}
                          >
                            <img
                              src="/img/Vehicle.png"
                              style={{ width: '25px', marginTop: '-13px' }}
                            />
                            {'  '}
                            <span>{i18n.s.smallTruckRoute}</span>
                          </div>
                        }
                        key="2"
                        style={{
                          paddingLeft: 0,
                          borderBottom: '1px solid #E8E8E8',
                          width: '97%',
                        }}
                      >
                        <Row>
                          {allDataFromSearch.data.small_truck_route.no_filter_bounds.map(
                            item => (
                              <>
                                <Col span={20} align="left">
                                  <p
                                    className="select__hover__neighborhood__information white__space__word__wrap"
                                    onClick={() => {
                                      handleGotoLocation(item, 'เส้นทางรถโม่เล็ก')
                                    }}
                                  >
                                    {item.route_name}
                                  </p>{' '}
                                </Col>
                                <Col span={4} align="right">
                                  <Icon
                                    onClick={() => {
                                      openNotification({
                                        code: item.route_name,
                                        name: item.remark,
                                      })
                                    }}
                                    type="info-circle"
                                    style={{
                                      color: '#F53C56',
                                      fontSize: '16px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Col>
                              </>
                            ),
                          )}
                        </Row>
                      </Panel>
                    )}
                  {/* เส้นทางรถโม่เล็กห้ามเข้า */}
                  {typeof allDataFromSearch.data != 'undefined' &&
                    allDataFromSearch.data.no_small_truck_route.no_filter_bounds
                      .length > 0 && (
                      <Panel
                        className="sub-panel-search"
                        header={
                          <div
                            style={{
                              color: '#172B4D',
                              fontWeight: 800,
                              paddingLeft: 0,
                              marginBottom: '2px',
                              marginTop: '2px',
                            }}
                          >
                            <img
                              src="/img/do-not-entry.png"
                              style={{ width: '25px', marginTop: '-13px' }}
                            />
                            {'  '}
                            <span>{i18n.s.smallTruckRouteDoNotEnter}</span>
                          </div>
                        }
                        key="3"
                        style={{
                          paddingLeft: 0,
                          borderBottom: '1px solid #E8E8E8',
                          width: '97%',
                        }}
                      >
                        <Row>
                          {allDataFromSearch.data.no_small_truck_route.no_filter_bounds.map(
                            item => (
                              <>
                                <Col span={20} align="left">
                                  <p
                                    className="select__hover__neighborhood__information white__space__word__wrap"
                                    onClick={() => {
                                      handleGotoLocation(
                                        item,
                                        'เส้นทางรถโม่เล็กห้ามเข้า',
                                      )
                                    }}
                                  >
                                    {item.route_name}
                                  </p>{' '}
                                </Col>
                                <Col span={4} align="right">
                                  <Icon
                                    onClick={() => {
                                      openNotification({
                                        code: item.route_name,
                                        name: item.remark,
                                      })
                                    }}
                                    type="info-circle"
                                    style={{
                                      color: '#F53C56',
                                      fontSize: '16px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Col>
                              </>
                            ),
                          )}
                        </Row>
                      </Panel>
                    )}
                  {/* หน่วยงานรถโม่เล็ก */}
                  {typeof allDataFromSearch.data != 'undefined' &&
                    allDataFromSearch.data.small_truck_agency.no_filter_bounds
                      .length > 0 && (
                      <Panel
                        className="sub-panel-search"
                        header={
                          <div
                            style={{
                              color: '#172B4D',
                              fontWeight: 800,
                              paddingLeft: 0,
                              marginBottom: '2px',
                              marginTop: '2px',
                            }}
                          >
                            <img
                              src="/img/small-truck.png"
                              style={{ width: '25px', marginTop: '-13px' }}
                            />
                            {'  '}
                            <span>{i18n.s.siteSmallTruck}</span>
                          </div>
                        }
                        key="4"
                        style={{
                          paddingLeft: 0,
                          borderBottom: '1px solid #E8E8E8',
                          width: '97%',
                        }}
                      >
                        <Row justify="space-around" align="middle">
                          {allDataFromSearch.data.small_truck_agency.no_filter_bounds.map(
                            item => (
                              <>
                                <Col span={20} align="left">
                                  <p
                                    className="select__hover__neighborhood__information white__space__word__wrap"
                                    onClick={() => {
                                      handleGotoLocation(
                                        item,
                                        'หน่วยงานรถโม่เล็ก',
                                      )
                                    }}
                                  >
                                    {item.location_name}
                                  </p>{' '}
                                </Col>
                                <Col span={2} align="right">
                                  <img
                                    src={'/img/Direct red.png'}
                                    style={{
                                      cursor: 'pointer',
                                      marginTop: '-4px',
                                    }}
                                    onClick={() => {
                                      handleGetLocationFromSearch({
                                        lat: item.lat,
                                        lng: item.lng,
                                        name: item.location_name,
                                      })
                                    }}
                                  />
                                </Col>
                                <Col span={2} align="right">
                                  <Icon
                                    onClick={() => {
                                      openNotification({
                                        code: item.location_code,
                                        name: item.location_name,
                                      })
                                    }}
                                    type="info-circle"
                                    style={{
                                      color: '#F53C56',
                                      fontSize: '16px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Col>
                              </>
                            ),
                          )}
                        </Row>
                      </Panel>
                    )}
                  {/* หน่วยงานรถโม่ใหญ่ */}
                  {typeof allDataFromSearch.data != 'undefined' &&
                    allDataFromSearch.data.big_truck_agency.no_filter_bounds
                      .length > 0 && (
                      <Panel
                        className="sub-panel-search"
                        header={
                          <div
                            style={{
                              color: '#172B4D',
                              fontWeight: 800,
                              paddingLeft: 0,
                              marginBottom: '2px',
                              marginTop: '2px',
                            }}
                          >
                            <img
                              src="/img/big-truck.png"
                              style={{ width: '25px', marginTop: '-13px' }}
                            />
                            {'  '}
                            <span>{i18n.s.siteLargeTruck}</span>
                          </div>
                        }
                        key="5"
                        style={{
                          paddingLeft: 0,
                          borderBottom: '1px solid #E8E8E8',
                          width: '97%',
                        }}
                      >
                        <Row>
                          {allDataFromSearch.data.big_truck_agency.no_filter_bounds.map(
                            item => (
                              <>
                                <Col span={20} align="left">
                                  <p
                                    className="select__hover__neighborhood__information white__space__word__wrap"
                                    onClick={() => {
                                      handleGotoLocation(
                                        item,
                                        'หน่วยงานรถโม่ใหญ่',
                                      )
                                    }}
                                  >
                                    {item.location_name}
                                  </p>{' '}
                                </Col>
                                <Col span={2} align="right">
                                  <img
                                    src={'/img/Direct red.png'}
                                    style={{
                                      cursor: 'pointer',
                                      marginTop: '-4px',
                                    }}
                                    onClick={() => {
                                      handleGetLocationFromSearch({
                                        lat: item.lat,
                                        lng: item.lng,
                                        name: item.location_name,
                                      })
                                    }}
                                  />
                                </Col>
                                <Col span={2} align="right">
                                  <Icon
                                    onClick={() => {
                                      openNotification({
                                        code: item.location_code,
                                        name: item.location_name,
                                      })
                                    }}
                                    type="info-circle"
                                    style={{
                                      color: '#F53C56',
                                      fontSize: '16px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Col>
                              </>
                            ),
                          )}
                        </Row>
                      </Panel>
                    )}

                  {/*  ข้อมูลสถานที่จาก Longdo map */}
                  {longdoMapLocationList.length > 0 && (
                    <Panel
                      className="sub-panel-search"
                      header={
                        <div
                          style={{
                            color: '#172B4D',
                            fontWeight: 800,
                            paddingLeft: 0,
                            marginBottom: '2px',
                            marginTop: '2px',
                          }}
                        >
                          <img
                            src="/img/nav-map.png"
                            style={{ width: '25px', marginTop: '-13px' }}
                          />
                          {'  '}
                          สถานที่จาก  Longdo map
                        </div>
                      }
                      key="6"
                      style={{
                        paddingLeft: 0,
                        borderBottom: '1px solid #E8E8E8',
                        width: '97%',
                      }}
                    >
                      <Row>
                        {longdoMapLocationList.map(item => (
                          <Col span={24} align="left">
                            <p
                              className="select__hover__neighborhood__information white__space__word__wrap"
                              onClick={() => {
                                handleGotoLocation(item, 'longdomapLocation')
                              }}
                            >
                              {item.name}
                            </p>
                          </Col>
                        ))}
                      </Row>
                    </Panel>
                  )}
                </Collapse>
              </div>
            </Panel>
          </Collapse>
        )}
      </LangContext.Consumer>
    </div>
  )
}

export default NeighborhoodInformation
