import React, { Component } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import * as actions from "../actions";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import { helper_convertDateformat } from "../../../../lib/helper";
import {
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Tag,
  Spin,
  Modal,
  Popconfirm,
  Badge,
  Icon,
  Tooltip,
  Drawer,
  Select,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Filter from "./Filter";
const columnStyle = { fontSize: "12px", fontWeight: "400" };

class Index extends Component {
  state = {
    visible: false,
    page: 1,
    pageSize: 10,
    searchName: "",
    orderBy: "id",
    orderType: "asc",
    filterObj: {
      plate_no: "",
      code: "",
      device_user: "",
      vehicle_type: "",
      maintenance_status: "",
      type: "",
      location_code: "",
      zone: ""
    }
  };



  showDrawer = () => {
    this.setState({
      visible: true
    });
  };



  onCloseSearch = resetForm => {
    this.setState({
      visible: false
    });
  };

  filterServicerepairdevice = values => {
    const { plate_no, code, device_user, vehicle_type, maintenance_status, type, location_code, zone } = values;

    this.setState({
      filterObj: {
        plate_no: plate_no,
        code: code,
        device_user: device_user,
        vehicle_type: vehicle_type,
        maintenance_status: maintenance_status,
        type: type,
        location_code: location_code,
        zone: zone,
      }
    });
    this.props.loadServicerepairdevice(
      {
        page: this.state.page,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: {
          plate_no: plate_no,
          code: code,
          device_user: device_user,
          vehicle_type: vehicle_type,
          maintenance_status: maintenance_status,
          type: type,
          location_code: location_code,
          zone: zone,
        }
      },
      true
    );
  };

  componentDidMount() {
    this.props.loadServicerepairdevice(this.state, true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataResponse } = this.props.servicerepairdevice;

    if (dataResponse.action_type == "create") {
      if (dataResponse.status == "success") {
        message.info("สำเร็จ");
      } else if (dataResponse.status == "fail") {
        message.info("ไม่สำเร็จ");
      }
      this.props.clearDataResponse();
    }

  }


  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      page: pagination.current
    });

    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = this.state.orderBy;
      orderType = this.state.orderType;
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc"
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadServicerepairdevice(
      {
        page: pagination.current,
        pageSize: this.state.pageSize,
        searchName: "",
        orderBy: orderBy,
        orderType: orderType,
        filterObj: {
          plate_no: this.state.filterObj.plate_no,
          code: this.state.filterObj.code,
          device_user: this.state.filterObj.device_user,
          vehicle_type: this.state.filterObj.vehicle_type,
          maintenance_status: this.state.filterObj.maintenance_status,
          type: this.state.filterObj.type,
          location_code: this.state.filterObj.location_code,
          zone: this.state.filterObj.zone,

        }
      },
      true
    );
  };

  render() {

    const columns = [
      {
        title: "#",
        dataIndex: "row_number",
        width: 60,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>,
        dataIndex: "plate_no",
        sorter: true,
        width: 150,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.v.vehicleCode}</LangContext.Consumer>,
        dataIndex: "code",
        sorter: true,
        width: 150,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.d.driverName}</LangContext.Consumer>,
        dataIndex: "device_user",
        sorter: true,
        width: 200,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.v.vehicle_type}</LangContext.Consumer>,
        dataIndex: "vehicle_type",
        sorter: true,
        width: 200,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.a.appointment_date}</LangContext.Consumer>,
        dataIndex: "appointment_date",
        sorter: true,
        width: 150,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.g.gps_update}</LangContext.Consumer>,
        dataIndex: "gps_active_at",
        sorter: true,
        width: 150,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.l.lastest_signal}</LangContext.Consumer>,
        dataIndex: "latest_pos_update_at",
        sorter: true,
        width: 150,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.m.maintenance_type}</LangContext.Consumer>,
        dataIndex: "type_name_th",
        sorter: true,
        width: 150,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.m.maintenance_status}</LangContext.Consumer>,
        dataIndex: "",
        sorter: false,
        render(text, record, index) {
          let message = "";
          let link_path = "";
          let link_check = false;
          let color_message = "";
          if (record.lost_device == 0) {

            if (record.device_code != "-") {
              if (record.process_type === null) {
                link_path = "/servicerepairdevice/create/" + record.id + "/" + record.companies_id;
                message = "แจ้งซ่อม/ถอด";
                link_check = true;
                color_message = "green";
              } else if (record.process_type == "o") {
                message = record.status_name_th;
                color_message = "orange";
              } else {
                message = record.status_name_th;
                color_message = "red";
              }

            } else {
              message = "ยังไม่ได้ติดตั้ง";
              color_message = "red";
            }
          } else if (record.online == 0) {
            message = "อุปกรณ์หาย";
            color_message = "red";
          }

          return {
            props: {
              style: columnStyle
            },
            children: (
              (link_check == true ? <a
                href={link_path}
                style={{ padding: "0px 15px 0px 5px" }}
              >
                {<span style={{ color: color_message }}>{message}</span>}

              </a > : < span style={{ color: color_message }}>{message}</span>)

            )
          };
        }
        ,
        width: 150
      },
      {
        title: <LangContext.Consumer>{i18n => i18n.h.history_servicerepairdevice}</LangContext.Consumer>,
        dataIndex: "",
        sorter: false,
        width: 90,
        render(text, record, index) {

          return {
            props: {
              style: columnStyle
            },
            children:
              <a
                href={"/servicerepairdevice/historys/" + record.id}
                style={{ padding: "0px 15px 0px 5px" }}
              >
                <Icon type="file-text" />
              </a >


          };
        }
        ,
        width: "8%"
      },
    ];

    return (
      <div>
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
            <Col span={22}>
              <span style={{ color: "red" }}> *หมายเหตุ:กรณีที่อุปกรณ์หายให้ติดต่อกับฝ่ายขาย</span>

            </Col>

            <Col span={2}>
              <Button icon="filter" size="small" onClick={this.showDrawer}>
                <LangContext.Consumer>{i18n => i18n.f.filter}</LangContext.Consumer>
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            columns={columns}
            rowKey={record => record.id}
            dataSource={this.props.servicerepairdevice.servicerepairdeviceLists}
            pagination={{
              pageSize: this.state.pageSize,
              total: this.props.servicerepairdevice.servicerepairdeviceTotal,
              current: this.state.page
            }}
            loading={this.props.servicerepairdevice.servicerepairdeviceLoading}
            onChange={this.handleTableChange}
          />

          <div>
            <LangContext.Consumer>
              {
                i18n => <Drawer
                  title={i18n.s.search}
                  width={350}
                  onClose={this.onCloseSearch}
                  visible={this.state.visible}
                >
                  <Filter
                    OnClose={this.onCloseSearch}
                    onSubmit={this.filterServicerepairdevice}
                    loadMaintenancestatus={this.props.loadMaintenancestatus}
                    loadMaintenancetype={this.props.loadMaintenancetype}
                    loadVehicletype={this.props.loadVehicletype}
                    loadZone={this.props.loadZone}
                    maintenancetypeLists={this.props.servicerepairdevice.maintenancetypeLists}
                    zoneLists={this.props.servicerepairdevice.zoneLists}
                    maintenancestatusLists={this.props.servicerepairdevice.maintenancestatusLists}
                    vehicletypeLists={this.props.servicerepairdevice.vehicletypeLists}
                  />
                </Drawer>
              }
            </LangContext.Consumer>
          </div>
        </GeneralStyledContent>
      </div>
    );
  }
}

const mapStateToProps = ({ servicerepairdevice, auth: { profile } }) => ({
  servicerepairdevice,
  profile
});

const mapDispatchToProps = {
  loadServicerepairdevice: actions.loadServicerepairdevice.request,
  loadMaintenancestatus: actions.loadMaintenancestatus.request,
  loadMaintenancetype: actions.loadMaintenancetype.request,
  loadZone: actions.loadZone.request,
  loadVehicletype: actions.loadVehicletype.request,
  clearDataResponse: actions.clearDataResponse
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
