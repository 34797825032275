import React, { Component } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Button, Form, Input, Row, Col, Select } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
const FormItem = Form.Item
const Options = Select.Option

export default class Filter extends Component {
  render() {
    const { OnClose, onSubmit, reportLists } = this.props
    return (
      <div>
        <Formik
          initialValues={{
            list_report_schedules_id: '',
          }}
          validationSchema={yup.object().shape({
            // name: yup.string.required("กรุณาเลือกช่วงวันที่"),
            // code: yup.string.required("กรุณาเลือกรถ")
          })}
          onSubmit={values => {
            const loading = true
            onSubmit(values, loading)
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => (
            <Row>
              <LangContext.Consumer>
                {i18n => (
                  <Form onSubmit={handleSubmit}>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.r.report}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.list_report_schedules_id &&
                        errors.list_report_schedules_id &&
                        'error'
                      }
                      help={
                        touched.list_report_schedules_id &&
                        errors.list_report_schedules_id
                      }
                    >
                      <Select
                        //style={{ width: "100%" }}
                        onChange={value =>
                          setFieldValue('list_report_schedules_id', value)
                        }
                        value={values.list_report_schedules_id}
                      >
                        <Options value="">
                          <LangContext.Consumer>
                            {i18n => i18n.a.all}
                          </LangContext.Consumer>
                        </Options>
                        {reportLists.map(item => (
                          <Options key={item.id} value={item.id}>
                            <LangContext.Consumer>
                              {i18n => i18n.scheduleReport[item.matching_name]}
                            </LangContext.Consumer>
                          </Options>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem>
                      <Row gutter={24}>
                        <Col span={3}>
                          <Button
                            // onClick={OnClose.bind(null, resetForm)}
                            onClick={OnClose}
                            // style={{ paddingLeft: 5 }}
                          >
                            {i18n.c.cancel}
                          </Button>
                        </Col>
                        <Col span={1} />
                        <Col span={3}>
                          <Button htmlType="submit" type="primary">
                            {i18n.a.apply}
                          </Button>
                        </Col>
                      </Row>
                    </FormItem>
                  </Form>
                )}
              </LangContext.Consumer>
            </Row>
          )}
        />
      </div>
    )
  }
}
