import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Pagination,
  Spin,
  Popconfirm,
  Tooltip,
  Icon,
} from "antd";
import axios from "axios";

import IndexFilterDrawer from "./IndexFilterDrawer";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { helper_convertDateformat } from "../../../../lib/helper";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { loadPolicy, removeLists } from "../actions";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import history from "../../../../lib/history";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilter: false,
      filterVisible: false,
      expand: false,
      currentPage: 1,
      page: 1,
      pageSize: 10,
      searchName: "",
      visible: false,
      cancelReason: "",
      selectedJob: "",
      orderBy: "created_at",
      orderType: "desc",
      filterObj: {
        FilterObjName: "",
        FilterObjType: "",
        FilterObjSubType: "",
        FilterObjCategory: "",
        FilterObjCriticalLevel: "",
      },
    };

    this.columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.n.name}</LangContext.Consumer>
        ),
        dataIndex: "name",
        width: 200,
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.t.type}</LangContext.Consumer>
        ),
        dataIndex: "type",
        // width: 230,
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {
                  <LangContext.Consumer>
                    {(i18n) => i18n.variable[text]}
                  </LangContext.Consumer>
                }
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.s.subType}
          </LangContext.Consumer>
        ),
        dataIndex: "sub_type",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {
                  <LangContext.Consumer>
                    {(i18n) => i18n.variable[text]}
                  </LangContext.Consumer>
                }
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.category}
          </LangContext.Consumer>
        ),
        dataIndex: "category",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {
                  <LangContext.Consumer>
                    {(i18n) => i18n.variable[text]}
                  </LangContext.Consumer>
                }
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.criticalLevel}
          </LangContext.Consumer>
        ),
        dataIndex: "critical_level",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {
                  <LangContext.Consumer>
                    {(i18n) => i18n.variable[text]}
                  </LangContext.Consumer>
                }
              </div>
            ),
          };
        },
      },

      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        key: "action_btn",
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.edit}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Link
                  to={`/policy/edit/${record.policy_id}`}
                  // disabled={disabled}
                >
                  <Icon type="edit" />
                </Link>
              </Tooltip>

              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.delete}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Popconfirm
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                    </LangContext.Consumer>
                  }
                  onConfirm={() => {
                    let self = this;
                    self.deletePolicy(record.policy_id);
                  }}
                >
                  <a
                    href="javascript:;"
                    style={{ padding: "0px 15px 0px 5px" }}
                  >
                    <Icon type="delete" />
                  </a>
                </Popconfirm>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.loadData(true);
  }

  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      this.setState({
        orderBy: "created_at",
        orderType: "asc",
      });
      orderBy = "created_at";
      orderType = "asc";
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc",
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadPolicy(
      {
        companyID: this.props.auth.profile.company_id,
        vehicleVisibility: this.props.auth.profile.vehicle_visibility,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        job_status: "<= 20",
        orderBy: orderBy,
        orderType: orderType,
        filterObj: this.state.filterObj,
      },
      true
    );
  };

  loadData = (loading) => {
    this.props.loadPolicy(
      {
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        companyID: this.props.auth.profile.company_id,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: this.state.filterObj,
      },
      loading
    );
  };

  handleSearch = (value) => {
    if (value != this.state.searchName) {
      this.props.loadPolicy(
        {
          companyID: this.props.auth.profile.company_id,
          page: 1,
          pageSize: 10,
          searchName: value,
          orderBy: this.state.orderBy,
          orderType: this.state.orderType,
          filterObj: this.state.filterObj,
        },
        true
      );
      this.setState({
        searchName: value,
        currentPage: 1,
      });
    }
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadPolicy(
      {
        companyID: this.props.auth.profile.company_id,
        page: page,
        pageSize: pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: this.state.filterObj,
      },
      true
    );
  };

  deletePolicy = (id) => {
    let self = this;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/policy/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        self.loadData(true);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  showFilterDrawer = () => {
    this.setState({
      filterVisible: true,
    });
  };

  onFilterDrawerClose = () => {
    this.setState({
      filterVisible: false,
    });
  };

  onFilterButtonClick = (e) => {
    this.loadData(true);
  };

  handleFilterObjName = (e) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        FilterObjPolicyName: e.target.value,
      },
      isFilter: e.target.value != null && e.target.value != "",
    });
  };

  handleFilterObjType = (e) => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilterObjType: e },
      isFilter: e != null && e != "",
    });
  };

  handleFilterObjSubType = (e) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        FilterObjSubType: e,
      },
      isFilter: e != null && e != "",
    });
  };

  handleFilterObjCategory = (e) => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilterObjCategory: e },
      isFilter: e != null && e != "",
    });
  };

  handleFilterObjCriticalLevel = (e) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        FilterObjCriticalLevel: e,
      },
      isFilter: e != null && e != "",
    });
  };

  render() {
    const { total, loading } = this.props.policyState;
    const columns = this.columns.map((col) => col);

    return (
      <AuthorizeComponent {...this.props} matching_name="policy">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
            <Col span={18}>
              <Link to="/policy/create/">
                <Button type="primary" icon="plus" size="small">
                  {" "}
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.add}
                  </LangContext.Consumer>
                </Button>
              </Link>
            </Col>
            <Col span={4} />
            <Col span={2}>
              <Badge dot={this.state.isFilter} align="right">
                <Button
                  icon="filter"
                  size="small"
                  align="right"
                  onClick={this.showFilterDrawer}
                >
                  <span></span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.filter}
                  </LangContext.Consumer>
                </Button>
              </Badge>

              <IndexFilterDrawer
                onClose={this.onFilterDrawerClose}
                visible={this.state.filterVisible}
                onFilterButtonClick={this.onFilterButtonClick}
                filterObj={this.state.filterObj}
                handleFilterObjName={this.handleFilterObjName}
                handleFilterObjType={this.handleFilterObjType}
                handleFilterObjSubType={this.handleFilterObjSubType}
                handleFilterObjCriticalLevel={this.handleFilterObjCriticalLevel}
              />
            </Col>
          </Row>

          <Row>
            <Spin spinning={loading}>
              <Table
                bordered
                rowKey="policy_id"
                columns={columns}
                dataSource={this.props.policyState.lists}
                size="small"
                pagination={false}
                onChange={this.handleTableChange}
              />
            </Spin>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col pan={24} align="right">
              <Pagination
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={total}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(({ policyState, auth }) => ({ policyState, auth }), {
  loadPolicy: loadPolicy.request,
  removeLists,
})(Index);
