import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import FavoriteFloatingIcon from "../../shared/components/FavoriteFloatingIcon";
import { createFavoriteMenu } from "../../auth/actions";

class AuthorizeComponent extends Component {
  render() {
    const { permissions } = this.props.auth.profile;

    let matching_name = "";
    let can_view = "can_view";
    let can_add = "can_add";
    let can_edit = "can_edit";
    let can_delete = "can_delete";
    let can_special_1 = "can_special_1";
    let can_special_2 = "can_special_2";
    let can_special_3 = "can_special_3";
    let can_special_4 = "can_special_4";

    let canAccess = permissions.find((ele) => {
      // return ele.matching_name == this.props.matching_name && ele.can_view == 1;

      if (typeof this.props.auth_privilege_key_name != "undefined") {
        let auth_privilege_key_name = this.props.auth_privilege_key_name;

        for (let i = 0; i < auth_privilege_key_name.length; i++) {
          if (
            ele.matching_name == this.props.matching_name &&
            can_view == auth_privilege_key_name[i] &&
            ele.can_view == 1
          ) {
            // can_view
            return (
              ele.matching_name == this.props.matching_name && ele.can_view == 1
            );
            break;
          } else if (
            ele.matching_name == this.props.matching_name &&
            can_add == auth_privilege_key_name[i] &&
            ele.can_add == 1 &&
            ele.can_add == 1
          ) {
            // can_add
            return (
              ele.matching_name == this.props.matching_name && ele.can_add == 1
            );
            break;
          } else if (
            ele.matching_name == this.props.matching_name &&
            can_edit == auth_privilege_key_name[i] &&
            ele.can_edit == 1
          ) {
            // can_edit
            return (
              ele.matching_name == this.props.matching_name && ele.can_edit == 1
            );
            break;
          } else if (
            ele.matching_name == this.props.matching_name &&
            can_delete == auth_privilege_key_name[i] &&
            ele.can_delete == 1
          ) {
            // can_delete
            return (
              ele.matching_name == this.props.matching_name &&
              ele.can_delete == 1
            );
            break;
          } else if (
            ele.matching_name == this.props.matching_name &&
            can_special_1 == auth_privilege_key_name[i] &&
            ele.can_special_1 == 1
          ) {
            // can_special_1
            return (
              ele.matching_name == this.props.matching_name &&
              ele.can_special_1 == 1
            );
            break;
          } else if (
            ele.matching_name == this.props.matching_name &&
            can_special_2 == auth_privilege_key_name[i] &&
            ele.can_special_2 == 1
          ) {
            // can_special_2
            return (
              ele.matching_name == this.props.matching_name &&
              ele.can_special_2 == 1
            );
            break;
          } else if (
            ele.matching_name == this.props.matching_name &&
            can_special_3 == auth_privilege_key_name[i] &&
            ele.can_special_3 == 1
          ) {
            // can_special_3
            return (
              ele.matching_name == this.props.matching_name &&
              ele.can_special_3 == 1
            );
            break;
          } else if (
            ele.matching_name == this.props.matching_name &&
            can_special_4 == auth_privilege_key_name[i] &&
            ele.can_special_4 == 1
          ) {
            // can_special_4
            return (
              ele.matching_name == this.props.matching_name &&
              ele.can_special_4 == 1
            );
            break;
          } else {
            return (
              ele.matching_name == this.props.matching_name && ele.can_view == 1
            );
            break;
          }
        }
      } else {
        return (
          ele.matching_name == this.props.matching_name && ele.can_view == 1
        );
      }
    });

    if (this.props.matching_name == "") {
      canAccess = true;
    } else if (typeof canAccess == "undefined") {
      canAccess = false;
    } else {
      canAccess = true;
    }

    if (!canAccess) {
      return (
        <Redirect
          to={{
            pathname: "/",
            data: { msg: "คุณไม่มีสิทธิ์เข้าหน้านี้" },
          }}
        />
      );
    }
    return (
      <Fragment>
        <FavoriteFloatingIcon
          {...this.props}
          createFavoriteMenu={this.props.createFavoriteMenu}
        ></FavoriteFloatingIcon>
        {this.props.children}
      </Fragment>
    );
  }
}

export default connect(({ auth }) => ({ auth }), {
  createFavoriteMenu: createFavoriteMenu.request,
})(AuthorizeComponent);
