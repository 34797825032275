import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadNotificationCount(action) {
  const { apiName, val, seen, offset, limit } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${
        process.env.REACT_APP_NOTIFICATION_CENTER_ENDPOINT
      }/notification/${apiName}?seen=${seen}&id=${val}&offset=${offset}&limit=${limit}`
    );
    yield put(actions.loadNotificationCount.success(res.data.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadNotificationCount.failure());
  }
}

// function* updateBookingStatus(action) {
//   const {
//     data: { device_id, job_id, status },
//     token
//   } = action.payload;
//   // const company_id =
//   try {
//     const res = yield call(
//       axios.post,
//       `http://booking-api/api/updatebookingdevice`,
//       {
//         device_id,
//         job_id,
//         status
//       },
//       { headers: { Authorization: `Bearer ${token}` } }
//     );
//     yield put(actions.updateBookingStatus.success(res.data));
//   } catch (err) {
//     console.log(err.message);
//     yield put(actions.updateBookingStatus.failure());
//   }
// }

export default function* watchUIState() {
  yield all([
    takeEvery(actions.LOAD_NOTIFICATION_COUNT.REQUEST, loadNotificationCount)
  ]);
}

export { loadNotificationCount };
