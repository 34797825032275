import { createTypes, createAction } from 'lib/action'

const LOAD_TRUCK_STATUS_DASHBOARD = createTypes(
    'loadTruckStatusDashboard',
    'load'
)
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
    return {
        type: REMOVE_LISTS,
    }
}

const loadTruckStatusDashboard = {
    request: (data, loading, cb) =>
        createAction(LOAD_TRUCK_STATUS_DASHBOARD.REQUEST, { data, loading, cb }),
    success: (lists) =>
        createAction(LOAD_TRUCK_STATUS_DASHBOARD.SUCCESS, { lists }),
    failure: () => createAction(LOAD_TRUCK_STATUS_DASHBOARD.FAITLURE),
}

export {
    LOAD_TRUCK_STATUS_DASHBOARD,
    loadTruckStatusDashboard,
    removeLists,
    REMOVE_LISTS
}