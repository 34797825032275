import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Button, Form, Input, Row, Col, Spin, Checkbox, Breadcrumb } from 'antd'
import { css } from '@emotion/css'

//import { checkPermissions } from "../../../../lib/helper";
import { StyledSearchForm } from '../../../../styled/common-styled'
import FormDivider from '../../../shared/components/FormDivider'
import axios from 'axios'

const FormItem = Form.Item

const formItemLabel = css({
  '.ant-form-item-label': {
    marginBottom: '15px',
  },
})

export default class Forms extends Component {
  state = {
    token_id: '',
    line_token: [],
    notify_type: [],
    data_notify_type: [],
    department_name: '',
    division_name: '',
    section_name: '',
  }

  componentDidMount() {
    this.getNotifyType()
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      linenotify: { dataLineNotify },
    } = nextProps

    if (this.props.linenotify.dataLineNotify !== dataLineNotify) {
      this.setState({
        line_token: nextProps.linenotify.dataLineNotify.line_token,
        notify_type: nextProps.linenotify.dataLineNotify.notify_type,
        notify_type_name: nextProps.linenotify.dataLineNotify.notify_type_name,
        department_name: nextProps.linenotify.dataLineNotify.department_name,
        division_name: nextProps.linenotify.dataLineNotify.division_name,
        section_name: nextProps.linenotify.dataLineNotify.section_name,
      })
    }
    return true
  }

  getNotifyType = () => {
    let self = this
    let company_id = this.props.auth.profile.company_id
    let token = this.props.auth.accessToken
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getnotifytype`,
        {
          company_id,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then(function(response) {
        if (typeof response.data.data !== 'undefined') {
          let data_notify_type = response.data.data.map(d => {
            return {
              id: d.id,
              name: d.name_key,
            }
          })
          self.setState({ data_notify_type })
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  render() {
    const { linenotify, onSubmit, auth, id } = this.props
    const { linenotifyLoading } = linenotify
    const {
      data_notify_type,
      department_name,
      division_name,
      section_name,
    } = this.state

    return (
      <Spin spinning={linenotifyLoading}>
        <Row>
          <StyledSearchForm>
            <Formik
              enableReinitialize={true}
              initialValues={{
                line_token: this.state.line_token,
                notify_type: this.state.notify_type,
              }}
              validate={values => {
                let errors = {}
                let line_token = values.line_token.filter(e => e.value != '')
                if (line_token.length != values.notify_type.length)
                  errors.line_token = 'Require Line Notify'
                return errors
              }}
              validationSchema={yup.object().shape({})}
              onSubmit={(values, { resetForm }) => {
                const loading = true
                onSubmit(values, loading)
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
                resetForm,
              }) => (
                <Row>
                  <LangContext.Consumer>
                    {i18n => (
                      <Form onSubmit={handleSubmit}>
                        <FormDivider>
                          <LangContext.Consumer>
                            {i18n => i18n.l.linenotify}
                          </LangContext.Consumer>
                        </FormDivider>

                        <Row style={{ marginBottom: '15px' }} gutter={[24, 24]}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Breadcrumb separator=">">
                              <Breadcrumb.Item>
                                {department_name}
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>{division_name}</Breadcrumb.Item>
                              <Breadcrumb.Item>{section_name}</Breadcrumb.Item>
                            </Breadcrumb>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <FormItem
                              className={formItemLabel}
                              required={true}
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                              label={
                                <LangContext.Consumer>
                                  {i18n => i18n.n.notify_type}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.notify_type &&
                                errors.notify_type &&
                                'warning'
                              }
                              help={touched.notify_type && errors.notify_type}
                            >
                              <FormItem
                                validateStatus={
                                  touched.line_token &&
                                  errors.line_token &&
                                  'warning'
                                }
                                help={touched.line_token && errors.line_token}
                              >
                                <Checkbox.Group
                                  style={{ width: '100%' }}
                                  onChange={value => {
                                    setFieldValue('notify_type', value)
                                  }}
                                  value={values.notify_type}
                                >
                                  {data_notify_type.map((v, index) => (
                                    <Row gutter={[16, 24]}>
                                      <Col span={6}>
                                        <Checkbox
                                          value={v.id}
                                          onChange={value => {
                                            let items = values.line_token
                                            let item = items.findIndex(
                                              e => value.target.value === e.id,
                                            )

                                            if (item > -1) items.splice(item, 1)
                                          }}
                                        >
                                          {
                                            <LangContext.Consumer>
                                              {i18n => i18n.notifytype[v.name]}
                                            </LangContext.Consumer>
                                          }
                                        </Checkbox>
                                      </Col>

                                      <Col span={12}>
                                        <Input
                                          disabled={
                                            !values.notify_type.includes(v.id)
                                          }
                                          type="Text"
                                          name="line_token"
                                          autoComplete="off"
                                          placeholder={i18n.l.line_token}
                                          value={
                                            values.line_token.find(
                                              e => e.id === v.id,
                                            )
                                              ? values.line_token.find(
                                                  e => e.id === v.id,
                                                ).value
                                              : null
                                          }
                                          onChange={e => {
                                            // 1. Make a shallow copy of the items
                                            let items = [...values.line_token]
                                            // 2. Make a shallow copy of the item you want to mutate
                                            const item = items.findIndex(
                                              e => e.id === v.id,
                                            )
                                            // 3. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                                            if (item === -1)
                                              items.push({
                                                id: v.id,
                                                value: e.target.value,
                                              })
                                            else
                                              items[item] = {
                                                id: v.id,
                                                value: e.target.value,
                                              }
                                            // 4. Set the state to our new copy
                                            setFieldValue('line_token', items)
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </Col>
                                    </Row>
                                  ))}
                                </Checkbox.Group>
                              </FormItem>
                            </FormItem>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: '20px', marginBottom: '5px' }}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <Form.Item>
                              <Button
                                type="defualt"
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                <Link to="/linenotify">{i18n.b.back}</Link>
                              </Button>

                              <Button type="primary" htmlType="submit">
                                {i18n.s.save}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </LangContext.Consumer>
                </Row>
              )}
            />
          </StyledSearchForm>
        </Row>
      </Spin>
    )
  }
}
