import {
  LOAD_MASTERDATA_VEHICLE_CONTRACT,
  UPDATE_INSTALLATION_STATUS_VEHICLE_CONTRACT,
  UPDATE_USAGE_STATUS_VEHICLE_CONTRACT,
  UPDATE_REMARK_VEHICLE_CONTRACT,
  LOAD_MASTERDATA_VEHICLE_CONTRACT_BY_ID,
  IMPORT_VEHICLE_CONTRACT,
  UPDATE_VEHICLE_CONTRACT_BY_VEHICLE_ID,
  LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT,
  LOAD_PROVINCE_VEHICLE_CONTRACT,
  SET_INITIAL_STATE_IN_REDUX,
} from './actions'

const importMsg = {
  msg: null,
  data: [],
  status: null,
}

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  dataForm: {},
  GPSVendorLists: [],
  provinceLists: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MASTERDATA_VEHICLE_CONTRACT.REQUEST: {
      const { loading } = action.payload.data
      return {
        ...state,
        msg: null,
        data: [],
        status: null,
        loading,
      }
    }

    case LOAD_MASTERDATA_VEHICLE_CONTRACT.SUCCESS:
      if (action.payload.lists.type == 'csv') {
        return {
          ...state,
          loading: false,
        }
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total: action.payload.lists.total,
        loading: false,
      }

    case LOAD_MASTERDATA_VEHICLE_CONTRACT_BY_ID.REQUEST: {
      const { loading } = action.payload.data
      return {
        ...state,
        loading,
      }
    }

    case LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.REQUEST:
      return {
        ...state,
        // loading: true,
      }

    case LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.SUCCESS:
      return {
        ...state,
        // loading: false,
        GPSVendorLists: action.payload.data.data,
      }

    case LOAD_MASTERDATA_VEHICLE_CONTRACT_BY_ID.SUCCESS:
      return {
        ...state,
        dataForm: action.payload.data.data,
        loading: false,
      }

    case UPDATE_INSTALLATION_STATUS_VEHICLE_CONTRACT.REQUEST: {
      const { loading } = action.payload.data
      return {
        ...state,
        ...importMsg,
        loading,
      }
    }

    case UPDATE_INSTALLATION_STATUS_VEHICLE_CONTRACT.SUCCESS:
      return {
        ...state,
        // loading: false,
      }

    case UPDATE_USAGE_STATUS_VEHICLE_CONTRACT.REQUEST: {
      const { loading } = action.payload.data
      return {
        ...state,
        ...importMsg,
        loading,
      }
    }

    case UPDATE_USAGE_STATUS_VEHICLE_CONTRACT.SUCCESS:
      return {
        ...state,
        // loading: false,
      }

    case UPDATE_REMARK_VEHICLE_CONTRACT.REQUEST: {
      const { loading } = action.payload
      return {
        ...state,
        ...importMsg,
        loading,
      }
    }

    case UPDATE_REMARK_VEHICLE_CONTRACT.SUCCESS:
      return {
        ...state,
        // loading: false,
      }
    case IMPORT_VEHICLE_CONTRACT.REQUEST:
      return {
        ...state,
        ...importMsg,
        loading: true,
      }
    case IMPORT_VEHICLE_CONTRACT.SUCCESS:
      let {
        data: { msg, data, status },
      } = action.payload
      return {
        ...state,
        msg,
        data,
        status,
        loading: false,
      }
    case IMPORT_VEHICLE_CONTRACT.FAILURE:
      return {
        ...state,
        msg: null,
        data: [],
        status: null,
        loading: false,
      }
    case UPDATE_VEHICLE_CONTRACT_BY_VEHICLE_ID.REQUEST: {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }

    case UPDATE_VEHICLE_CONTRACT_BY_VEHICLE_ID.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_PROVINCE_VEHICLE_CONTRACT.REQUEST: {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }

    case LOAD_PROVINCE_VEHICLE_CONTRACT.SUCCESS:
      return {
        ...state,
        provinceLists: action.payload.data.data,
      }

    case SET_INITIAL_STATE_IN_REDUX.REQUEST: {
      return {
        msg: null,
        data: [],
        status: null,
        lists: [],
        total: 1,
        loading: false,
        dataForm: {},
        GPSVendorLists: [],
        provinceLists: [],
      }
    }

    case SET_INITIAL_STATE_IN_REDUX.SUCCESS:
      return {
        msg: null,
        data: [],
        status: null,
        lists: [],
        total: 1,
        loading: false,
        dataForm: {},
        GPSVendorLists: [],
        provinceLists: [],
      }

    default:
      return state
  }
}
