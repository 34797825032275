import React, { Component } from 'react'
import {
  TrackingStatusStyledContent,
  GeneralStyledContent,
} from '../../../../styled/common-styled'
import axios from 'axios'
import {
  Row,
  Col,
  Card,
  Table,
  Select,
  DatePicker,
  Input,
  Dropdown,
  Menu,
  Form,
  Icon,
  Button,
  AutoComplete,
  Spin,
  Empty,
} from 'antd'
import moment from 'moment'
import Breadcrumb from '../../../shared/components/Breadcrumb'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { debounce } from 'debounce'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'

const { Option } = Select
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']
const { Search } = Input
const { RangePicker } = DatePicker
class IndexNew extends Component {
  state = {
    divisionLoading: false,
    division: [],
    searchData: [],
    departmentLoading: false,
    department: [],
    sectionLoading: false,
    section: [],
    plant: [],
    plantLoading: [],
    partner: 'all',
    selectedDivision: null,
    selectedDepartment: ['All'],
    getIdValueDepartment: [],
    getIdValueSection: [],
    getIdValuePlant: [],
    selectedSection: ['All'],
    selectedPlant: ['All'],
    pageLoading: false,
    pickupSearchString: '',
    loading: false,
    searchPickup: [],
    filterSelection: 'all',
    startTime: null,
    endTime: null,
    validationStartTime: false,
    validationEndTime: false,
    validationSearch: false,
    validationDivision: false,
    departmentSelectMode: 'multiple',
    dataTable: [],
    disabledDepartment: false,
    open: false,
    value: [],
    onPreview: false,
    onExport: false,
    showTableAfteronPreview: false,
    showEndDate: true,
    endOpen: false,
  }
  queryPlaceToAutocomplete(val) {
    this.setState(
      {
        loading: true,
        searchData: [],
      },
      () => {
        fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsite`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.props.auth.accessToken}`,
          },
          body: JSON.stringify({
            input_site: `${val}`,
          }),
        })
          .then(response => response.json())
          .then(data => {
            this.setState({
              searchData: data,
              loading: false,
            })
          })
      },
    )
  }

  loadPreview = val => {
    this.setState(
      {
        onPreview: true,
      },
      () => {
        let validateStartAt = this.handleValidateInput(this.state.startTime)
        let validateEndAt = this.handleValidateInput(this.state.endTime)
        let validateSearchPickup = this.handleValidateInput(
          this.state.searchPickup,
        )
        let validateSelectedDivision = this.handleValidateInput(
          this.state.selectedDivision,
        )
        if (
          validateStartAt === false &&
          validateEndAt === false &&
          validateSearchPickup === false &&
          validateSelectedDivision === false
        ) {
          let self = this

          if (val === 'preview') {
            const filter = {
              start_at: this.state.startTime.format('YYYY-MM-DD 00:00:00'),
              end_at: this.state.endTime.format('YYYY-MM-DD 23:59:59'),
              site_ids: this.state.searchPickup.map(i => Number(i.key)),
              division_id: this.state.selectedDivision,
              department_id: this.state.getIdValueDepartment.includes('All')
                ? []
                : this.state.getIdValueDepartment,
              section_id: this.state.getIdValueSection.includes('All')
                ? []
                : this.state.getIdValueSection,
              plant_id: this.state.getIdValuePlant.includes('All')
                ? []
                : this.state.getIdValuePlant,
              company_id: this.props.auth.profile.company_id,
              data_type: val,
              display: this.state.filterSelection,
            }

            const header = {
              headers: {
                Authorization: `Bearer ${this.props.auth.accessToken}`,
              },
            }

            window.open('/report/distancereport/preview', '_blank')
            localStorage.setItem(
              'distancereport-filter',
              JSON.stringify(filter),
            )
            localStorage.setItem(
              'distancereport-header',
              JSON.stringify(header),
            )
          } else {
            fetch(
              `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/truckdeliverydistance`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  // Authorization: `Bearer ${this.props.auth.accessToken}`,
                },
                body: JSON.stringify({
                  start_at: this.state.startTime.format('YYYY-MM-DD 00:00:00'),
                  end_at: this.state.endTime.format('YYYY-MM-DD 23:59:59'),
                  site_ids: this.state.searchPickup.map(i => Number(i.key)),
                  division_id: this.state.selectedDivision,
                  department_id: this.state.getIdValueDepartment.includes('All')
                    ? []
                    : this.state.getIdValueDepartment,
                  section_id: this.state.getIdValueSection.includes('All')
                    ? []
                    : this.state.getIdValueSection,
                  plant_id: this.state.getIdValuePlant.includes('All')
                    ? []
                    : this.state.getIdValuePlant,
                  company_id: this.props.auth.profile.company_id,
                  data_type: val,
                  display: this.state.filterSelection,
                  // "start_at": "2021-10-01",
                  // "end_at": "2021-10-30",
                  // "site_ids": [882089, 931807, 575705],
                  // "division_id": 7,
                  // "department_id": this.state.selectedDepartment.includes('All') ? [] : this.state.selectedDepartment,
                  // "section_id": this.state.selectedSection.includes('All') ? [] : this.state.selectedSection,
                  // "plant_id": this.state.selectedPlant.includes('All') ? [] : this.state.selectedPlant,
                  // "company_id": 54,
                  // "data_type": val,
                  // "display": "all"
                }),
              },
            )
              .then(response => response.json())
              .then(data => {
                if (val === 'preview')
                  self.setState({
                    dataTable: data,
                    showTableAfteronPreview: true,
                  })
                if (val === 'report') {
                  this.setState(
                    {
                      // showTableAfteronPreview: false
                    },
                    () => {
                      window.location.assign(`${data.result}`)
                    },
                  )
                }
              })
          }
        }
      },
    )
  }
  // เลือกกิจการ
  loadDivision = () => {
    let self = this
    let divisionVisibility = []
    this.setState({ divisionLoading: true })
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      divisionVisibility = orgChart.divisions.map(ele => ele.divisionNo)
    }
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        division_visibility: divisionVisibility,
        comp: [2, 3, 4, 5, 6],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState({
          divisionLoading: false,
          division: data,
        })
      })
  }
  // เลือกฝ่ายส่วน
  loadDepartment = cb => {
    let self = this
    let departmentVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      departmentVisibility = orgChart.departments.map(ele => ele)
    }
    this.setState({ departmentLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        department_visibility: departmentVisibility,
        division: [this.state.selectedDivision],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            departmentLoading: false,
            department: data,
            // selectedSection: [],
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }
  // เลือกแผนก
  loadSection = cb => {
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    this.setState({ sectionLoading: true })

    let departmentID = [this.state.getIdValueDepartment.toString()]

    if (this.state.getIdValueDepartment.includes('All')) {
      departmentID = this.state.department.map(x => x.id.toString())
    }
    if (this.state.getIdValueDepartment.length === 0) {
      departmentID = this.state.department.map(x => x.id.toString())
    }
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        section_visibility: sectionVisibility,
        department: departmentID,
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            sectionLoading: false,
            section: data,
            // selectedSection: [],
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }
  // เลือกโรงงาน
  loadPlant = cb => {
    let self = this
    let plantVisibility = []
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    plantVisibility = self.props.auth.profile.location_visibility
    this.setState({ plantLoading: true })

    let sectionID = [this.state.getIdValueSection.toString()]
    if (this.state.getIdValueSection.includes('All')) {
      sectionID = this.state.section.map(x => x.id.toString())
    }
    if (this.state.getIdValueSection.length === 0) {
      sectionID = this.state.section.map(x => x.id.toString())
    }
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantforconcretedashboard`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          location_visibility: plantVisibility,
          section_visibility: sectionVisibility,
          section: sectionID,
          relate_company: `${this.props.auth.profile.relate_company}`,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            plant: data,
            plantLoading: false,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  componentDidMount() {
    this.loadDivision()
  }
  handleValidateInput = value => {
    if (this.state.onPreview == false) return false
    if (value === null || value == '' || value.length == 0) {
      return true
    }
    return false
  }

  disabledStartDate = startTime => {
    const { endTime } = this.state
    if (!endTime) {
      return startTime && startTime > moment().endOf('day')
    }
    // return startTime.valueOf() > endTime.valueOf()
    return (
      startTime &&
      (startTime > moment().endOf('day') ||
        startTime.valueOf() >= endTime.valueOf())
    )
  }
  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true })
    }
  }
  disabledEndDate = endTime => {
    // const { startTime } = this.state
    // if (!endTime || !startTime) {
    //   return false
    // }
    // return endTime.valueOf() <= startTime.valueOf()
    return endTime && endTime > moment().endOf('day')
  }

  handleEndOpenChange = open => {
    this.setState({ endOpen: open })
  }

  onChange = (dates, dateStrings) => {
    console.log('From: ', dates[0], ', to: ', dates[1])
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
  }

  render() {
    const { searchData } = this.state
    return (
      <AuthorizeComponent {...this.props} matching_name="distancereport">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
            <Col span={10}>
              <div className="header__text">
                <label
                  style={{ fontSize: '1.125rem', margin: '0px 0px 14px 10px' }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.d.distancereport}
                  </LangContext.Consumer>
                </label>
              </div>
              <br />
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
            {/* <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: '0px 0px 14px 10px' }}
              />
            </Col> */}
          </Row>
          <Form>
            <Row gutter={[48, 0]} type="flex" justify="center">
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <Row>
                  <Col xs={11} sm={11} md={11} lg={11} xl={11} align="left">
                    <Form.Item
                      validateStatus={
                        this.handleValidateInput(this.state.startTime)
                          ? 'error'
                          : null
                      }
                      help={
                        this.handleValidateInput(this.state.startTime) ? (
                          <LangContext.Consumer>
                            {i18n => i18n.c.chooseDatePls}
                          </LangContext.Consumer>
                        ) : null
                      }
                    >
                      <span>
                        <LangContext.Consumer>
                          {i18n => i18n.d.dpReleaseDate}
                        </LangContext.Consumer>
                      </span>
                      <br />
                      <DatePicker
                        style={{ width: '100%' }}
                        disabledDate={this.disabledStartDate}
                        // showTime
                        format={dateFormatList}
                        value={this.state.startTime}
                        placeholder="Start"
                        onChange={dateString => {
                          this.setState({
                            startTime: dateString,
                          })
                        }}
                        onOpenChange={this.handleStartOpenChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    align="center"
                    style={{ marginTop: '27px' }}
                  >
                    <br />
                    <LangContext.Consumer>
                      {i18n => i18n.dashboard_summary.to}
                    </LangContext.Consumer>
                  </Col>
                  <Col xs={11} sm={11} md={11} lg={11} xl={11} align="right">
                    <Form.Item
                      validateStatus={
                        this.handleValidateInput(this.state.endTime)
                          ? 'error'
                          : null
                      }
                      help={
                        this.handleValidateInput(this.state.endTime) ? (
                          <LangContext.Consumer>
                            {i18n => i18n.c.chooseDatePls}
                          </LangContext.Consumer>
                        ) : null
                      }
                    >
                      <br />
                      <DatePicker
                        style={{ width: '100%' }}
                        disabledDate={this.disabledEndDate}
                        // showTime
                        format={dateFormatList}
                        value={this.state.endTime}
                        placeholder="End"
                        onChange={dateString => {
                          this.setState({
                            endTime: dateString,
                          })
                        }}
                        // open={this.state.endOpen}
                        onOpenChange={this.handleEndOpenChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <span>
                  <LangContext.Consumer>
                    {i18n => i18n.s.site}
                  </LangContext.Consumer>
                </span>
                <div></div>
                <Form.Item
                  validateStatus={
                    this.handleValidateInput(this.state.searchPickup)
                      ? 'error'
                      : null
                  }
                  help={
                    this.handleValidateInput(this.state.searchPickup) ? (
                      <LangContext.Consumer>
                        {i18n => i18n.c.chooseSitePls}
                      </LangContext.Consumer>
                    ) : null
                  }
                >
                  <div style={{ borderRadius: '20px' }}>
                    <Select
                      allowClear={true}
                      mode="multiple"
                      labelInValue
                      value={this.state.searchPickup}
                      placeholder="Search..."
                      notFoundContent={<Spin spinning={this.state.loading} />}
                      filterOption={false}
                      style={{ width: '100%' }}
                      onSearch={debounce(value => {
                        this.queryPlaceToAutocomplete(value)
                      }, 1500)}
                      onChange={value => {
                        this.setState(
                          {
                            searchPickup: value,
                            searchData: [],
                            loading: false,
                          },
                          () => {
                            if (this.state.searchPickup.length == 0) {
                              this.setState({
                                validationSearch: true,
                              })
                            } else {
                              this.setState({
                                validationSearch: false,
                              })
                            }
                          },
                        )
                      }}
                    >
                      {/* {this.state.children} */}
                      {searchData.map(({ id, name, code }) => (
                        <Option key={id}>
                          {`(${code})`}
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <Form.Item
                  validateStatus={
                    this.handleValidateInput(this.state.selectedDivision)
                      ? 'error'
                      : null
                  }
                  help={
                    this.handleValidateInput(this.state.selectedDivision) ? (
                      <LangContext.Consumer>
                        {i18n => i18n.c.chooseBusinessPls}
                      </LangContext.Consumer>
                    ) : null
                  }
                >
                  <span>
                    <LangContext.Consumer>
                      {i18n => i18n.d.division}
                    </LangContext.Consumer>
                  </span>
                  <br />
                  <Select
                    placeholder={
                      <LangContext.Consumer>
                        {i18n => i18n.c.chooseBusinessPls}
                      </LangContext.Consumer>
                    }
                    style={{ width: '100%' }}
                    onChange={e => {
                      this.setState(
                        {
                          selectedDivision: e,
                          selectedDepartment: ['All'],
                          selectedSection: ['All'],
                          selectedPlant: ['All'],
                          validationDivision: false,
                        },
                        e => {
                          this.loadDepartment(() =>
                            this.loadSection(() => this.loadPlant()),
                          )
                        },
                      )
                    }}
                  >
                    {this.state.division.map(ele => (
                      <Option value={ele.id}>{ele.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <Form.Item>
                  <span>
                    <LangContext.Consumer>
                      {i18n => i18n.d.department}
                    </LangContext.Consumer>
                  </span>
                  <br />
                  <Select
                    allowClear={true}
                    style={{ width: '100%' }}
                    mode="multiple"
                    // defaultValue={['All']}
                    value={this.state.selectedDepartment}
                    onChange={(value, key) => {
                      //   console.log(
                      //     'seclected Value',
                      //     value,
                      //     key.map(x => x.key),
                      //   )
                      let id = value
                      let mapId = key.map(a => a.key)
                      let getId = key.map(a => a.key)
                      //   console.log('mapId', mapId)
                      if (value.includes('All')) {
                        if (value[0] === 'All' && value.length > 1) {
                          id = value.filter((x, i) => i !== 0)
                        } else if (value[value.length - 1] === 'All') {
                          id = ['All']
                        }
                      }
                      if (mapId.includes('All')) {
                        if (mapId[0] === 'All' && mapId.length > 1) {
                          getId = mapId.filter((x, i) => i !== 0)
                        } else if (mapId[mapId.length - 1] === 'All') {
                          getId = ['All']
                        }
                      }
                      this.setState(
                        {
                          getIdValueDepartment: getId.includes('All')
                            ? getId
                            : getId.map(Number),
                          selectedDepartment: id,
                          selectedSection: ['All'],
                          selectedPlant: ['All'],
                        },
                        e => {
                          // console.log('getIdselectedDepartment', this.state.selectedDepartment.includes('All') ? [] : this.state.selectedDepartment)
                          // console.log('getId', this.state.getIdValueDepartment)
                          this.loadSection(() => this.loadPlant())
                        },
                      )
                    }}
                  >
                    <Option value={'All'} label="All" key={'All'}>
                      <LangContext.Consumer>
                        {i18n => i18n.a.all}
                      </LangContext.Consumer>
                    </Option>
                    {this.state.department.map(ele => (
                      <Option value={ele.name} key={ele.id}>
                        {ele.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <Form.Item>
                  <span>
                    <LangContext.Consumer>
                      {i18n => i18n.s.section}
                    </LangContext.Consumer>
                  </span>
                  <br />
                  <Select
                    allowClear={true}
                    style={{ width: '100%' }}
                    mode="multiple"
                    // defaultValue={['All']}
                    value={this.state.selectedSection}
                    onChange={(value, key) => {
                      //   console.log(
                      //     'seclected Value',
                      //     value,
                      //     key.map(x => x.key),
                      //   )
                      let id = value
                      let mapId = key.map(a => a.key)
                      let getId = key.map(a => a.key)
                      //   console.log('mapId', mapId)
                      if (value.includes('All')) {
                        if (value[0] === 'All' && value.length > 1) {
                          id = value.filter((x, i) => i !== 0)
                        } else if (value[value.length - 1] === 'All') {
                          id = ['All']
                        }
                      }
                      if (mapId.includes('All')) {
                        if (mapId[0] === 'All' && mapId.length > 1) {
                          getId = mapId.filter((x, i) => i !== 0)
                        } else if (mapId[mapId.length - 1] === 'All') {
                          getId = ['All']
                        }
                      }
                      this.setState(
                        {
                          getIdValueSection: getId.includes('All')
                            ? getId
                            : getId.map(Number),
                          selectedSection: id,
                          selectedPlant: ['All'],
                        },
                        e => {
                          // console.log('selectedDepartment', this.state.selectedSection)
                          this.loadPlant()
                        },
                      )
                    }}
                  >
                    <Option value={'All'} label="All" key={'All'}>
                      <LangContext.Consumer>
                        {i18n => i18n.a.all}
                      </LangContext.Consumer>
                    </Option>
                    {this.state.section.map(ele => (
                      <Option value={ele.name} key={ele.id}>
                        {ele.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>

              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <Form.Item>
                  <span>
                    <LangContext.Consumer>
                      {i18n => i18n.p.plants}
                    </LangContext.Consumer>
                  </span>
                  <br />
                  <Select
                    allowClear={true}
                    style={{ width: '100%' }}
                    mode="multiple"
                    // defaultValue={['All']}
                    value={this.state.selectedPlant}
                    onChange={(value, key) => {
                      // console.log('seclected Value', value, key.map(x => x.key))
                      let id = value
                      let mapId = key.map(a => a.key)
                      let getId = key.map(a => a.key)
                      // console.log('mapId', mapId)
                      if (value.includes('All')) {
                        if (value[0] === 'All' && value.length > 1) {
                          id = value.filter((x, i) => i !== 0)
                        } else if (value[value.length - 1] === 'All') {
                          id = ['All']
                        }
                      }
                      if (mapId.includes('All')) {
                        if (mapId[0] === 'All' && mapId.length > 1) {
                          getId = mapId.filter((x, i) => i !== 0)
                        } else if (mapId[mapId.length - 1] === 'All') {
                          getId = ['All']
                        }
                      }
                      this.setState(
                        {
                          getIdValuePlant: getId.includes('All')
                            ? getId
                            : getId.map(Number),
                          selectedPlant: id,
                        },
                        e => {
                          // console.log('selectedPlant', this.state.selectedPlant)
                          this.loadPlant()
                        },
                      )
                    }}
                    // loading={this.state.plantLoading}
                    maxTagTextLength={40}
                    maxTagCount={10}
                  >
                    <Option value={'All'} label="All" key={'All'}>
                      <LangContext.Consumer>
                        {i18n => i18n.a.all}
                      </LangContext.Consumer>
                    </Option>
                    {this.state.plant.map(ele => (
                      <Option value={ele.name} key={ele.id}>
                        {ele.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>

              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <span>
                  {' '}
                  <LangContext.Consumer>
                    {i18n => i18n.s.setType}
                  </LangContext.Consumer>
                </span>
                <div></div>
                <Form.Item>
                  <Select
                    defaultValue="all"
                    style={{ width: '100%' }}
                    onChange={value => {
                      this.setState(
                        {
                          filterSelection: value,
                        },
                        () => {},
                      )
                    }}
                  >
                    <Option value="all" key={0}>
                      <LangContext.Consumer>
                        {i18n => i18n.a.all}
                      </LangContext.Consumer>
                    </Option>
                    <Option value="duration" key={1}>
                      <LangContext.Consumer>
                        {i18n => i18n.t.time}
                      </LangContext.Consumer>
                    </Option>
                    <Option value="distance" key={2}>
                      <LangContext.Consumer>
                        {i18n => i18n.k.km}
                      </LangContext.Consumer>
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                <Button type="defualt" block>
                  <Link to="/">
                    <LangContext.Consumer>
                      {i18n => i18n.c.cancel}
                    </LangContext.Consumer>
                  </Link>
                </Button>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                <Button
                  type="defualt"
                  block
                  onClick={() => {
                    this.loadPreview('preview')
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.p.preview}
                  </LangContext.Consumer>
                </Button>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    this.loadPreview('report')
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.r.report}
                  </LangContext.Consumer>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.state.showTableAfteronPreview ? (
                  <div className="scrolling__table">
                    {this.state.dataTable.length > 0 ? (
                      <table
                        className="tr__td__style"
                        style={{ width: '100%' }}
                      >
                        <thead
                          style={{
                            backgroundColor: '#ff8f8f',
                            color: 'black',
                            fontSize: '16px',
                          }}
                        >
                          <tr>
                            <th className="border__right__style" rowspan="3">
                              รหัสหน่วยงาน
                            </th>
                            <th className="border__right__style" rowspan="3">
                              หน่วยงาน
                            </th>
                            <th className="border__right__style" rowspan="3">
                              โรงงานจัดส่ง
                            </th>
                            <th className="border__right__style" rowspan="3">
                              ยอดผลิต
                            </th>
                            <th
                              className="border__right__bottom__style"
                              colSpan="6"
                            >
                              ระยะขนส่ง Bluenet
                            </th>
                            <th
                              className="border__right__bottom__style"
                              colSpan="7"
                            >
                              ระยะ GPS{' '}
                            </th>
                            <th style={{ fontWeight: 'lighter' }} rowspan="3">
                              ผลต่างระยะทาง (กม.) User/GPS{' '}
                            </th>
                          </tr>
                          <tr>
                            <th className="border__right__style" rowspan="2">
                              SPD1
                            </th>
                            <th
                              className="border__right__bottom__style"
                              colSpan="2"
                            >
                              เดินทางไป
                            </th>
                            <th
                              className="border__right__bottom__style"
                              colSpan="2"
                            >
                              เดินทางกลับ
                            </th>
                            <th className="border__right__style" rowspan="2">
                              รวม(กม.)
                            </th>
                            <th
                              className="border__right__bottom__style"
                              colSpan="2"
                            >
                              เดินทางไป
                            </th>
                            <th
                              className="border__right__bottom__style"
                              colSpan="2"
                            >
                              เดินทางกลับ
                            </th>
                            <th className="border__right__style" rowspan="2">
                              รวม(กม.)
                            </th>
                            <th className="border__right__style" rowspan="2">
                              รวมหาร2
                            </th>
                            <th className="border__right__style" rowspan="2">
                              ระยะทางในไซต์
                            </th>
                          </tr>
                          <tr>
                            <th className="border__right__style">กม.</th>
                            <th className="border__right__style">นาที</th>
                            <th className="border__right__style">กม.</th>
                            <th className="border__right__style">นาที</th>
                            <th className="border__right__style">กม.</th>
                            <th className="border__right__style">นาที</th>
                            <th className="border__right__style">กม.</th>
                            <th className="border__right__style">นาที</th>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: 'white' }}>
                          {this.state.dataTable.length > 0 &&
                            this.state.dataTable.map(item => {
                              let plant =
                                item.plant_list.length != 0
                                  ? item.plant_list[0]
                                  : {
                                      plant_name: '',
                                      total_volume: '',
                                      spd1: '',
                                      site_to_plant_km: '',
                                      site_to_plant_min: '',
                                      plant_to_site_km: '',
                                      plant_to_site_min: '',
                                      total_distance: '',
                                      gps_site_to_plant_km: '',
                                      gps_site_to_plant_min: '',
                                      gps_plant_to_site_km: '',
                                      gps_plant_to_site_min: '',
                                      gps_totol_distance: '',
                                      gps_totol_distance_avg: '',
                                      site_distance: '',
                                      distance_diff: '',
                                    }
                              return (
                                <>
                                  <tr>
                                    <td
                                      className="border__right__td__style"
                                      rowspan={item.plant_list.length}
                                    >
                                      {item.site_code}
                                    </td>
                                    <td
                                      className="border__right__td__style"
                                      rowspan={item.plant_list.length}
                                    >
                                      {item.site_name}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.plant_name}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.total_volume}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.spd1}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.plant_to_site_km}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.plant_to_site_min}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.site_to_plant_km}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.site_to_plant_min}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.total_distance}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_plant_to_site_km}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_plant_to_site_min}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_site_to_plant_km}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_site_to_plant_min}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_totol_distance}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_totol_distance_avg}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.site_distance}
                                    </td>
                                    <td className="border__bottom__td__style">
                                      {plant.distance_diff}
                                    </td>
                                  </tr>
                                  {item.plant_list.map((plant, i) => {
                                    if (i == 0) {
                                      return null
                                    }
                                    return (
                                      <tr>
                                        <td className="border__right__td__style">
                                          {plant.plant_name}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.total_volume}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.spd1}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.site_to_plant_km}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.site_to_plant_min}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.plant_to_site_km}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.plant_to_site_min}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.total_distance}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.gps_site_to_plant_km}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.gps_site_to_plant_min}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.gps_plant_to_site_km}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.gps_plant_to_site_min}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.gps_totol_distance}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.gps_totol_distance_avg}
                                        </td>
                                        <td className="border__right__td__style">
                                          {plant.site_distance}
                                        </td>
                                        <td className="border__bottom__td__style">
                                          {plant.distance_diff}
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    ) : (
                      <div
                        style={{
                          backgroundColor: 'white',
                          height: '240px',
                          paddingTop: '60px',
                        }}
                      >
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </Col>
            </Row>{' '}
          </Form>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ auth }) => ({
    auth,
  }),
  {},
)(IndexNew)
