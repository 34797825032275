import React, { Component } from 'react'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import * as actions from '../actions'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from './../../../shared/components/Breadcrumb'
import { helper_convertDateformat } from '../../../../lib/helper'
import { checkPermissions } from '../../../../lib/helper'
import { getlocalstorage } from './../../../../constants/local_storage'
import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import {
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
  Icon,
  Tooltip,
  Drawer,
  Modal,
} from 'antd'
import moment from 'moment'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Filter from './Filter'
import FormTrip from './FormTrip'
import '../../../../styled/fix-line-lost.css'
const columnStyle = { fontSize: '12px', fontWeight: '400' }

class Index extends Component {
  state = {
    loadingTrip: false,
    visibletrip: false,
    route_master_id: 0,
    visible: false,
    page: 1,
    pageSize: 10,
    orderBy: 'r.updated_at',
    orderType: 'desc',
    filterObj: {
      name: '',
    },
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onCloseSearch = resetForm => {
    this.setState({
      visible: false,
    })
  }

  filterRoutemaster = values => {
    const { name } = values

    this.setState({
      filterObj: {
        name,
      },
    })
    this.props.loadRoutemaster(
      {
        page: this.state.page,
        pageSize: this.state.pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: {
          name,
        },
      },
      true,
    )
  }

  componentDidMount() {
    this.props.loadRoutemaster(this.state, true)
    this.props.loadVehicle()
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      page: pagination.current,
    })

    let orderBy = ''
    let orderType = ''

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = this.state.orderBy
      orderType = this.state.orderType
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == 'descend' ? 'desc' : 'asc',
      })
      orderBy = sorter.columnKey
      orderType = sorter.order == 'descend' ? 'desc' : 'asc'
    }

    this.props.loadRoutemaster(
      {
        page: pagination.current,
        pageSize: this.state.pageSize,
        searchName: '',
        orderBy: orderBy,
        orderType: orderType,
        filterObj: {
          name: this.state.filterObj.name,
        },
      },
      true,
    )
  }

  deleteRoutemaster = id => {
    let self = this

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function(response) {
        self.props.loadRoutemaster(
          {
            page: 1,
            pageSize: self.state.pageSize,
            orderBy: self.state.orderBy,
            orderType: self.state.orderType,
            filterObj: {
              name: self.state.filterObj.name,
            },
          },
          true,
        )
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  showModalGenerateTrip = id => {
    this.setState({
      visibletrip: true,
      route_master_id: id,
    })
  }

  handleOk = (values, callbackClearTrip) => {
    this.setState({
      loadingTrip: true,
    })
    const ACCESSTOKEN = localStorage.getItem('access-token')
    const user_id = getlocalstorage(localStorage.getItem('profile'), 'user_id')

    const user_name = getlocalstorage(
      localStorage.getItem('profile'),
      'username',
    )
    const company_id = getlocalstorage(
      localStorage.getItem('profile'),
      'company_id',
    )
    if (this.state.route_master_id != 0) {
      const { vehicle_id, trip_code, start_trip_date } = values
      const new_start_trip_date = moment(start_trip_date['_d']).format(
        'YYYY-MM-DD HH:mm',
      )

      let self = this
      fetch(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/createtrip`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ACCESSTOKEN}`,
          },
          body: JSON.stringify({
            company_id,
            user_id,
            vehicle_id,
            trip_code,
            start_trip_date: new_start_trip_date,
            user_name,
            route_master_id: self.state.route_master_id,
          }),
        },
      )
        .then(response => response.json())
        .then(res => {
          if (res.status === 'success') {
            Modal.success({
              content: (
                <LangContext.Consumer>
                  {i18n => i18n.s.success}
                </LangContext.Consumer>
              ),
            })
          } else {
            Modal.error({
              content: (
                <LangContext.Consumer>
                  {i18n => i18n.f.fail}
                </LangContext.Consumer>
              ),
            })
          }

          this.setState({
            visibletrip: false,
            loadingTrip: false,
          })

          callbackClearTrip()
        })
    }
  }

  handleCancel = e => {
    this.setState({
      visibletrip: false,
      route_master_id: 0,
    })
  }

  render() {
    const columns = [
      {
        title: <div className="regular-bold-14">#</div>,
        className: 'column-text-center',
        dataIndex: 'row_number',
        width: '5%',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.r.route_master_name}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: 'name',
        sorter: true,
        key: 'name',
        width: '45%',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.c.createdAt}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'created_at',
        sorter: true,
        key: 'created_at',
        width: '15%',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {helper_convertDateformat(
                  text,
                  'YYYY-MM-DD HH:mm:ss',
                  'DD/MM/YYYY HH:mm',
                )}
              </div>
            ),
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>{i18n => i18n.a.action}</LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'action',
        key: 'action',
        sorter: false,
        width: '8%',
        render: (text, record, index) => {
          return (
            <div>
              <LangContext.Consumer>
                {i18n => (
                  <Tooltip
                    placement="left"
                    title={i18n.t.trip}
                    arrowPointAtCenter
                    style={{
                      padding: '0px 1px 0px 0px',
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                  >
                    <a
                      // type="link"
                      // size="small"
                      onClick={() => this.showModalGenerateTrip(record.id)}
                      style={{ padding: '0px 5px 0px 5px' }}
                    >
                      <Icon type="retweet" />
                    </a>
                  </Tooltip>
                )}
              </LangContext.Consumer>

              <LangContext.Consumer>
                {i18n => (
                  <Tooltip
                    placement="left"
                    title={i18n.e.edit}
                    arrowPointAtCenter
                    style={{
                      padding: '0px 1px 0px 0px',
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                  >
                    <Link
                      to={`/routemaster/edit/${record.id}`}
                      style={{ padding: '0px 5px 0px 5px' }}
                    >
                      <Icon type="edit" />
                    </Link>
                  </Tooltip>
                )}
              </LangContext.Consumer>

              <LangContext.Consumer>
                {i18n => (
                  <Tooltip
                    placement="left"
                    title={i18n.d.delete}
                    arrowPointAtCenter
                    style={{
                      padding: '0px 1px 0px 0px',
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                  >
                    <Popconfirm
                      title={i18n.c.confirmToDeleteThisItem}
                      onConfirm={() => {
                        let self = this
                        self.deleteRoutemaster(record.id)
                      }}
                      cancelText={i18n.c.cancel}
                      okText={i18n.o.ok}
                    >
                      <a href="#" style={{ padding: '0px 5px 0px 5px' }}>
                        <Icon type="delete" />
                      </a>
                    </Popconfirm>
                  </Tooltip>
                )}
              </LangContext.Consumer>
            </div>
          )
        },
        // width: "5%"
      },
    ]

    return (
      <AuthorizeComponent {...this.props} matching_name="routemaster">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              {/* <Breadcrumb
                                match={this.props.match}
                                style={{ margin: "0px 0px 14px 10px" }}
                            /> */}
              <div className="header__text">
                <LangContext.Consumer>
                  {i18n => i18n.r.routemaster}
                </LangContext.Consumer>
              </div>
            </Col>
          </Row>
          <Row gutter={24} type="flex" justify="end" style={{ height: '38px' }}>
            <Col span={22}>
              <Link to="/routemaster/create/">
                <Button type="primary" icon="plus" size="small">
                  <span></span>
                  <LangContext.Consumer>
                    {i18n => i18n.n.new}
                  </LangContext.Consumer>
                </Button>
              </Link>
            </Col>

            <Col span={2}>
              <Button icon="filter" size="small" onClick={this.showDrawer}>
                <LangContext.Consumer>
                  {i18n => i18n.f.filter}
                </LangContext.Consumer>
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            //size="small"
            columns={columns}
            rowKey={record => record.id}
            dataSource={this.props.routemaster.routemasterLists}
            pagination={{
              pageSize: this.state.pageSize,
              total: this.props.routemaster.routemasterTotal,
              current: this.state.page,
            }}
            loading={this.props.routemaster.routemasterLoading}
            onChange={this.handleTableChange}
          />
          <FormTrip
            loading={this.state.loadingTrip}
            visible={this.state.visibletrip}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            vehicleLists={this.props.routemaster.vehicleLists}
            route_master_id={this.state.route_master_id}
            auth={this.props.auth}
          />

          <div>
            <LangContext.Consumer>
              {i18n => (
                <Drawer
                  title={i18n.s.search}
                  width={450}
                  onClose={this.onCloseSearch}
                  visible={this.state.visible}
                >
                  <Filter
                    OnClose={this.onCloseSearch}
                    onSubmit={this.filterRoutemaster}
                    reportLists={this.props.routemaster.reportLists}
                  />
                </Drawer>
              )}
            </LangContext.Consumer>
          </div>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ routemaster, auth }) => ({
  routemaster,
  auth,
})

const mapDispatchToProps = {
  loadRoutemaster: actions.loadRoutemaster.request,
  loadVehicle: actions.loadVehicle.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
