import React, { Component } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import DriverMasterData from './DriverMasterData/Index'
import LocationMasterData from './LocationMasterData/Index'
import MaterialMasterData from './MaterialMasterData/Index'
import PrecastMasterData from './PreCastMasterData/Index'
import VehicleMasterData from './VehicleMasterData/Index'

class MasterData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      driverDetail: false,
      locationDetail: false,
      materialDetail: false,
      precastDetail: false,
      vehicleDetail: false,
      colorSelection: '',
    }
  }
  handleDriver = () => {
    this.setState(
      {
        driverDetail: true,
        locationDetail: false,
        materialDetail: false,
        precastDetail: false,
        vehicleDetail: false,
        colorSelection: 'พนักงานขับขี่',
      },
      () => {
        this.props.getBreadcrumbSubText('พนักงานขับขี่')
      },
    )
  }

  handleLocation = () => {
    this.setState(
      {
        driverDetail: false,
        locationDetail: true,
        materialDetail: false,
        precastDetail: false,
        vehicleDetail: false,
        colorSelection: 'สถานที่',
      },
      () => {
        this.props.getBreadcrumbSubText('สถานที่')
      },
    )
  }

  handleMaterial = () => {
    this.setState(
      {
        driverDetail: false,
        locationDetail: false,
        materialDetail: true,
        precastDetail: false,
        vehicleDetail: false,
        colorSelection: 'วัตถุดิบ',
      },
      () => {
        this.props.getBreadcrumbSubText('วัตถุดิบ')
      },
    )
  }

  handlePrecast = () => {
    this.setState(
      {
        driverDetail: false,
        locationDetail: false,
        materialDetail: false,
        precastDetail: true,
        vehicleDetail: false,
        colorSelection: 'Precast',
      },
      () => {
        this.props.getBreadcrumbSubText('Precast')
      },
    )
  }

  handleVehicle = () => {
    this.setState(
      {
        driverDetail: false,
        locationDetail: false,
        materialDetail: false,
        precastDetail: false,
        vehicleDetail: true,
        colorSelection: 'พาหนะ',
      },
      () => {
        this.props.getBreadcrumbSubText('พาหนะ')
      },
    )
  }

  render() {
    const {
      checkMenuVisibility,
      web_endpoint,
      permissions,
      checkSubMenuVisibility,
      masterdataLocationObj,
      masterdataVehicleObj,
      masterdataChauffeurObj,
      masterdataPrecastObj,
      masterdataMaterialObj,
    } = this.props
    return (
      <>
        {/* สินค้า */}
        {checkMenuVisibility('', web_endpoint, 'product', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/product">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.p.product}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* บริษัท */}
        {checkMenuVisibility('', web_endpoint, 'company', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/company">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.c.company}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับจัดการข้อมูลบริษัท
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.c.company}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* หน่วยนับ (UOM) */}
        {checkMenuVisibility('undefined', web_endpoint, 'uom', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/uom">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.u.uom}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* ลูกค้า */}
        {checkMenuVisibility('', web_endpoint, 'customer', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/customer">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.c.customer}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* Permission */}
        {checkMenuVisibility('', web_endpoint, 'permission', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/permission">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">Permission</p>
              </Card>
            </Link>
          </Col>
        )}
        {/* maintenance */}
        {checkMenuVisibility('', web_endpoint, 'maintenance', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/maintenance">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.m.maintenance}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* refuel */}
        {checkMenuVisibility('', web_endpoint, 'reFuelTitle', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/refuel">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.r.reFuelTitle}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* สัญญาการขนส่ง */}
        {checkMenuVisibility(
          'isupply',
          web_endpoint,
          'plantMiningContract',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/delivery-contract">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.p.plantMiningContract}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับจัดการข้อมูลสัญญาการขนส่ง
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.p.plantMiningContract}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* Interface CDAS */}
        {checkMenuVisibility(
          'isupply',
          web_endpoint,
          'isupplySystemKey',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/interface-key-cdas">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.i.isupplySystemKey}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับจัดการข้อมูลรหัสสำหรับเชื่อมกับระบบ CDAS
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.i.isupplySystemKey}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* สถานที่่ */}
        {checkSubMenuVisibility(masterdataLocationObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleLocation()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'สถานที่' && 'red',
                  color: this.state.colorSelection === 'สถานที่' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color: this.state.colorSelection === 'สถานที่' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.l.location}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* พาหนะ */}
        {checkSubMenuVisibility(masterdataVehicleObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleVehicle()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'พาหนะ' && 'red',
                  color: this.state.colorSelection === 'พาหนะ' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color: this.state.colorSelection === 'พาหนะ' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.v.vehicle}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* พนักงานขับขี่ */}
        {checkSubMenuVisibility(masterdataChauffeurObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleDriver()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'พนักงานขับขี่' && 'red',
                  color:
                    this.state.colorSelection === 'พนักงานขับขี่' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color:
                      this.state.colorSelection === 'พนักงานขับขี่' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.c.chauffeur}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* Precast */}
        {checkSubMenuVisibility(masterdataPrecastObj) ? (
          <Col span={4}>
            <div onClick={() => this.handlePrecast()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'Precast' && 'red',
                  color: this.state.colorSelection === 'Precast' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color: this.state.colorSelection === 'Precast' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.vehicleType.pre_cast}
                  </LangContext.Consumer>{' '}
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* วัตถุดิบ */}
        {checkSubMenuVisibility(masterdataMaterialObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleMaterial()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'วัตถุดิบ' && 'red',
                  color: this.state.colorSelection === 'วัตถุดิบ' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color: this.state.colorSelection === 'วัตถุดิบ' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.m.material}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}

        {this.state.locationDetail ? (
          <LocationMasterData
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.vehicleDetail ? (
          <VehicleMasterData
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.driverDetail ? (
          <DriverMasterData
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.precastDetail ? (
          <PrecastMasterData
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.materialDetail ? (
          <MaterialMasterData
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
      </>
    )
  }
}

export default MasterData
