import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Table,
  message,
  Tooltip,
} from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import difference from 'lodash/difference'
import BasicFilterReport from '../../basicFilterReport/Index'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
        }

        return (
          <Table
            scroll={{ y: 200, x: 250 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 100 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      event_date_start: '',
      event_date_end: '',
      vehicleList: [],
      vehicleSelect: [],
      selectedKeys: [],
      clickMenu: '',
      // vehicltypeID: ""
    }
    this.leftTableColumns = [
      {
        dataIndex: 'name',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemLeft}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Left',
      },
    ]

    this.rightTableColumns = [
      {
        dataIndex: 'name',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemSelected}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Right',
      },
    ]
    this.Leftbtn = null
    this.Rightbtn = null
  }

  componentDidMount() {
    const { call } = this.props

    if (call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehicle } = this.props
    setFieldValue('vehicle_type_id', value)
    loadVehicle(value, true)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleList } = nextProps
    const vehlist = []
    vehicleList.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )
    //console.log(vehlist);
    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleMenuClick = (setFieldValue, handleSubmit) => {
    // if (e.key == "excel") {
    setFieldValue('type_file', 'excel', false)
    // } else if (e.key == "pdf") {
    //   setFieldValue("type_file", "pdf", false);
    // }
    handleSubmit()
  }

  handleClicReportkPreview = values => {
    const COMPANY_ID = this.props.auth.company_id

    const date_start = moment(values.eventdate[0]['_d']).format(
      'YYYY-MM-DD 00:00:00',
    )
    const date_end = moment(values.eventdate[1]['_d']).format(
      'YYYY-MM-DD 23:59:59',
    )

    const filter = {
      date_start,
      date_end,
      list_vehicle_id: values.list_vehicle_id,
      company_id: COMPANY_ID,
      vehicle_type_id: `${values.vehicle_type_id}`,
      vehicle_status: values.vehicle_status_id,
      type_file: 'preview',
    }

    window.open('/report/separatevehiclestatus/preview', '_blank')
    localStorage.setItem(
      'separatevehiclestatusreport-filter',
      JSON.stringify(filter),
    )
  }

  render() {
    const {
      vehicleTypeLists,
      separatevehiclestatusLoading,
      onSubmit,
    } = this.props
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <LangContext.Consumer>
        {i18n => (
          <div>
            <Spin spinning={separatevehiclestatusLoading}>
              <Formik
                initialValues={{
                  vehicle_type_id: '',
                  vehicle_status_id: '',
                  eventdate: [
                    moment('00:00', 'HH:mm'),
                    moment('23:59', 'HH:mm'),
                  ],
                  list_vehicle_id: this.state.vehicleSelect,
                  division: this.props.selectedDivision,
                  department: this.props.selectedDepartment,
                  section: this.props.selectedSection,
                  plant: this.props.selectedPlant,
                  vehicleGroup: this.props.selectedVehicleGroup,
                }}
                validate={values => {
                  let errors = {}
                  if (values.eventdate.length > 0) {
                    const date_start = moment(values.eventdate[0]['_d'])
                    const date_end = moment(values.eventdate[1]['_d'])
                    let day_diff = date_end.diff(date_start, 'months')
                    if (day_diff > 0) {
                      errors.eventdate = 'ช่วงวันที่ที่เลือกไม่ควรเกิน 31 วัน'
                    }
                  }
                  if (values.list_vehicle_id.length > 0) {
                    const threshold = 500

                    if (values.list_vehicle_id.length > threshold) {
                      errors.list_vehicle_id = 'เลือกรถได้ไม่เกิน 500 คัน'
                    }
                  }

                  return errors
                }}
                validationSchema={yup.object().shape({
                  eventdate: yup.array().required('กรุณาเลือกช่วงวันที่'),
                  list_vehicle_id: yup
                    .array()
                    .required(i18n.p.pleaseChooseVehicle),
                })}
                onSubmit={values => {
                  //console.log(values);
                  const loading = true
                  if (this.state.clickMenu === 'preview') {
                    this.handleClicReportkPreview(values)
                  } else {
                    onSubmit(values, loading)
                  }
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                }) => (
                  <Row type="flex" justify="center">
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        {i18n.d.dailyCarStatusReport}
                      </div>
                      <BasicFilterReport
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                        division={this.props.division}
                        updateSelectDivision={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectDivision(e, values)
                          })
                        }}
                        department={this.props.department}
                        updateSelectDepartment={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectDepartment(e, values)
                          })
                        }}
                        section={this.props.section}
                        updateSelectSection={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectSection(e, values)
                          })
                        }}
                        plant={this.props.plant}
                        updateSelectPlant={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectPlant(e, values)
                          })
                        }}
                        vehicleGroup={this.props.vehicleGroup}
                        updateSelectVehicleGroup={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectVehicleGroup(e, values)
                          })
                        }}
                      />
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        required="true"
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'error'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div>
                          <TableTransfer
                            dataSource={this.props.vehicleList}
                            targetKeys={values.list_vehicle_id}
                            showSearch
                            onChange={targetKeys => {
                              if (targetKeys.length > 500) {
                                message.error('เลือกรถได้ไม่เกิน 500 คัน')
                              } else {
                                setFieldValue('list_vehicle_id', targetKeys)
                              }
                            }}
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                            leftColumns={this.leftTableColumns}
                            rightColumns={this.rightTableColumns}
                            onSelectChange={this.handleSelectChange}
                          />
                        </div>
                      </FormItem>

                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicleStatus}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.alarmalert_type_id &&
                          errors.alarmalert_type_id &&
                          'warning'
                        }
                        help={
                          touched.alarmalert_type_id &&
                          errors.alarmalert_type_id
                        }
                      >
                        <Select
                          //showSearch
                          // style={{ width: 200 }}
                          onChange={value =>
                            setFieldValue('vehicle_status_id', value)
                          }
                          value={values.vehicle_status_id}
                        >
                          <Option value="">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          <Option value="travel">
                            <LangContext.Consumer>
                              {i18n => i18n.r.running}
                            </LangContext.Consumer>
                          </Option>
                          <Option value="park_engine_on">
                            <LangContext.Consumer>
                              {i18n => i18n.s.stopWithEngineOn}
                            </LangContext.Consumer>
                          </Option>
                          <Option value="park_engine_off">
                            <LangContext.Consumer>
                              {i18n => i18n.e.engineOff}
                            </LangContext.Consumer>
                          </Option>
                        </Select>
                      </FormItem>

                      {this.props.call !== 'schedule' ? (
                        <Form.Item
                          required={true}
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.d.dateRange}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.eventdate && errors.eventdate && 'warning'
                          }
                          help={touched.eventdate && errors.eventdate}
                        >
                          <RangePicker
                            disabledDate={disabledDate}
                            onChange={value =>
                              setFieldValue('eventdate', value)
                            }
                            format="DD/MM/YYYY"
                            defaultValue={[
                              moment('00:00', 'HH:mm'),
                              moment('23:59', 'HH:mm'),
                            ]}
                          />
                        </Form.Item>
                      ) : (
                        ''
                      )}

                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call == 'schedule' ? (
                            <Col span={8} style={{ marginTop: '10px' }}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            <div>
                              {' '}
                              <Col span={8}>
                                <Button type="defualt" block>
                                  <Link to="/">
                                    <LangContext.Consumer>
                                      {i18n => i18n.c.cancel}
                                    </LangContext.Consumer>
                                  </Link>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  block
                                  onClick={() =>
                                    this.setState({
                                      clickMenu: 'preview',
                                    })
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.preview}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    this.setState({
                                      clickMenu: 'report',
                                    })
                                    this.handleMenuClick(
                                      setFieldValue,
                                      handleSubmit,
                                    )
                                  }}
                                  block
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.r.report}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                            </div>
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  </Row>
                )}
              />
            </Spin>
          </div>
        )}
      </LangContext.Consumer>
    )
  }
}
