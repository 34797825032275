import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Row,
  Col,
  Spin,
  Form,
  Select,
  Icon,
  DatePicker,
  Checkbox,
  Dropdown,
  Menu,
} from 'antd'
import { ResponsivePie } from '@nivo/pie'
import moment from 'moment'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import LangContext from 'modules/shared/context/langContext'
import {
  loadSafetyInfo,
  loadVehicleTypeMasterData,
  loadVehicleGroupMasterData,
  saveNoteSafetyInfo,
  removeLists,
} from '../../actions'
import {
  SafetyDashboardStyledContent,
  SafetyPanel,
  StyledRow,
} from '../../../../styled/common-styled'
import LongdoMap from './LongdoMap'
import { ExpandableTable } from './ExpandableTable'
import axios from 'axios'
import { Link } from 'react-router-dom'

const { RangePicker } = DatePicker
const ButtonGroup = Button.Group

const MyResponsivePie = ({ i18n, data /* see data tab */ }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
    innerRadius={0.5}
    padAngle={0.7}
    fit={false}
    cornerRadius={0}
    colors={['#c91c1c', '#00BF06', '#ffd619', '#848484', '#108ee9', '#722ed1']}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    radialLabel={d => d.label}
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={6}
    radialLabelsTextColor="#333333"
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={16}
    radialLabelsLinkHorizontalLength={24}
    radialLabelsLinkStrokeWidth={1}
    radialLabelsLinkColor={{ from: 'color' }}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor="#333333"
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    showInLegend={true}
    enableRadialLabels={false}
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        translateY: 56,
        itemWidth: 110,
        itemHeight: 18,
        itemTextColor: '#999',
        symbolSize: 16,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ]}
  />
)

const { Option } = Select
const initialSelectedEvent = {
  event_id: null,
  plate_no: null,
  action: null,
  lat: 13.604403,
  lng: 101.359439,
}

export class Index extends Component {
  static defaultProps = {
    center: {
      lat: 13.873479,
      lng: 100.922861,
    },
    zoom: 5.8,
  }

  state = {
    loading: false,
    detailHeight: window.innerHeight / 2.9,
    panelWidth: 575,
    latest_config_change: new Date().getTime(),
    map: null,
    maps: null,
    mapAPI: { trafficLayer: null },
    mapOptions: null,
    selectedEvent: initialSelectedEvent,
    plant: [],
    allPartner: [],
    selectedPlant: ['all'],
    getIdValuePlant: ['all'],
    only_has_dp: true,
    start_at: moment().format('YYYY-MM-DD 00:00:00'),
    end_at: moment().format('YYYY-MM-DD 23:59:59'),
    isParkingSideRoadCheck: true,
    isSpeedOverCheck: true,
    isDrivingMoreThan4Check: true,
    isDrivingMoreThan10Check: true,
    // isAggressiveTurnWithoutLightCheck: true,
    isAggressiveAccelerationAndImmediatelyBreakCheck: true,
    divisionLoading: false,
    division: [],
    departmentLoading: false,
    department: [],
    sectionLoading: false,
    section: [],
    partner: 'all',
    selectNote: '-1',
    selectedDivision: 'all',
    selectedDepartment: 'all',
    selectedSection: 'all',
    pageLoading: false,
    eventMarkerLatLng: {
      lat: '',
      lng: '',
    },
    note: [
      { id: '1', name: 'มีหมายเหตุ' },
      { id: '0', name: 'ไม่มีหมายเหตุ' },
    ],
  }

  handlePinEventMarker = (lat, lng) => {
    this.setState({
      eventMarkerLatLng: {
        lat: lat,
        lng: lng,
      },
    })
  }

  setMapBound = value => {
    this.setState({ mapOptions: { ...this.state.mapOptions, ...value } })
  }

  loadPartner = cb => {
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpartnersafetydashboard`,
      {
        //fetch(`http://booking-api-88:8080/api/getconcreteshippinginfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          company_id: self.props.auth.profile.company_id,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            allPartner: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }
  // เลือกกิจการ
  loadDivision = cb => {
    let self = this
    let divisionVisibility = []

    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      divisionVisibility = orgChart.divisions.map(ele => ele.divisionNo)
    }
    this.setState({ divisionLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        division_visibility: divisionVisibility,
        comp: [2, 3, 4, 5, 6],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            divisionLoading: false,
            division: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  // เลือกฝ่ายส่วน
  loadDepartment = cb => {
    let self = this
    let departmentVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      departmentVisibility = orgChart.departments.map(ele => ele)
    }
    this.setState({ departmentLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        department_visibility: departmentVisibility,
        division: [this.state.selectedDivision],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            departmentLoading: false,
            department: data,
            // selectedSection: [],
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }
  // เลือกแผนก
  loadSection = cb => {
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    this.setState({ sectionLoading: true })

    let departmentID = [this.state.selectedDepartment.toString()]

    if (this.state.selectedDepartment === 'all') {
      departmentID = this.state.department.map(x => x.id.toString())
    }
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        section_visibility: sectionVisibility,
        department: departmentID,
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            sectionLoading: false,
            section: data,
            // selectedSection: [],
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }
  // เลือกโรงงาน
  loadPlant = cb => {
    let self = this
    let plantVisibility = []
    let sectionVisibility = []

    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }

    plantVisibility = self.props.auth.profile.location_visibility

    this.setState({ plantLoading: true })

    let sectionID = [this.state.selectedSection.toString()]

    if (this.state.selectedSection === 'all') {
      sectionID = this.state.section.map(x => x.id.toString())
    }

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantforconcretedashboard`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          location_visibility: plantVisibility,
          section_visibility: sectionVisibility,
          section: sectionID,
          relate_company: `${this.props.auth.profile.relate_company}`,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            plant: data,
            plantLoading: false,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  onTimeRangeChange = (d1, d2) => {
    this.setState({ start_at: d1, end_at: d2 })
  }

  handdleCheck = (key, value) => this.setState({ [key]: value })

  handleApiLoaded = (map, maps, platform = null, mapLayer = null) => {
    if (typeof maps == 'undefined' || maps == null) window.location.reload()

    this.setState({
      map: map,
      maps: maps,
      mapAPI:
        typeof maps.TrafficLayer != 'undefined'
          ? {
              ...this.state.mapAPI,
              trafficLayer: new maps.TrafficLayer(),
            }
          : null,
      mapLayer: mapLayer,
      mapPlatform: platform,
    })
  }

  loadData = () => {
    this.props.loadSafetyInfo({
      partner: this.state.partner,
      division: this.state.selectedDivision,
      department: this.state.selectedDepartment,
      section: this.state.selectedSection,
      start_at: this.state.start_at,
      end_at: this.state.end_at,
      plant_ids:
        this.state.getIdValuePlant.length == 0
          ? 'all'
          : this.state.getIdValuePlant.toString(),
      only_has_dp: this.state.only_has_dp,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
      note: this.state.selectNote,
      cb: () => false,
    })
  }

  onRemoveLists = () => {
    this.props.removeLists()
  }

  componentDidMount() {
    this.loadPartner()
    this.loadDivision(() =>
      this.loadDepartment(() => this.loadSection(() => this.loadPlant())),
    )

    this.loadData()

    this.loadInterval = setInterval(() => {
      this.loadData()
    }, 1000 * 300)
  }

  componentWillUnmount() {
    clearInterval(this.loadInterval)
    this.onRemoveLists()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.monitoringState.loadedDateTime !==
        nextProps.monitoringState.loadedDateTime ||
      this.props.monitoringState.loading !==
        nextProps.monitoringState.loading ||
      this.state.FilterVisible != nextState.FilterVisible
    ) {
      return true
    }
    return true
  }

  exportSafetyEventReport = () => {
    const { safetyInfoList } = this.props.monitoringState
    const list_vehicle_id = []
    const vendor_name =
      this.state.partner === 'all' ? '' : safetyInfoList[0].partner
    const company_id = this.props.auth.profile.company_id
    const user_id = this.props.auth.profile.id
    const date_start = moment(this.state.start_at).format('YYYY-MM-DD HH:mm:00')
    const date_end = moment(this.state.end_at).format('YYYY-MM-DD HH:mm:59')

    const partner = this.state.partner.toString()
    const division = this.state.selectedDivision.toString()
    const department = this.state.selectedDepartment.toString()
    const section = this.state.selectedSection.toString()
    const note = this.state.selectNote

    this.setState({
      pageLoading: true,
    })

    for (let loop = 0; loop < safetyInfoList.length; loop++) {
      //check duplicate value
      if (
        list_vehicle_id.findIndex(
          element => element === safetyInfoList[loop].vehicleID,
        ) === -1
      )
        list_vehicle_id.push(safetyInfoList[loop].vehicleID)
    }

    fetch(`${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/safetyevent`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        vendor_name,
        date_start,
        date_end,
        list_vehicle_id,
        company_id,
        vehicle_type_id: '',
        user_id,
        type_file: 'excel',
        event: 0,
        event_name: 'ทั้งหมด',
        event_type: 'feed',
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        partner,
        division,
        department,
        section,
        note,
        only_dp: this.state.only_has_dp,

        plant:
          this.state.getIdValuePlant.length == 0
            ? 'all'
            : this.state.getIdValuePlant.map(x => x.toString()),
      }),
    })
      .then(response => response.json())
      .then(res => {
        this.setState({
          pageLoading: false,
        })
        window.location = res.result
      })
  }

  loadPlantDivision = async () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.props.auth.accessToken}`,
    }

    let departmentVisibility = []
    if (this.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(this.props.auth.profile.org_chart)
      departmentVisibility = orgChart.departments.map(ele => ele)
    }

    let sectionVisibility = []
    if (this.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(this.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }

    let plantVisibility = this.props.auth.profile.location_visibility

    const department = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`,
      {
        department_visibility: departmentVisibility,
        division: [this.state.selectedDivision],
      },
      { headers },
    )

    let depID = department.data.map(x => x.id.toString())

    const section = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsection`,
      {
        section_visibility: sectionVisibility,
        department: depID,
      },
      { headers },
    )

    let sectionID = section.data.map(x => x.id.toString())

    const plant = await axios.post(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantforconcretedashboard`,
      {
        location_visibility: plantVisibility,
        section_visibility: sectionVisibility,
        section: sectionID,
        relate_company: `${this.props.auth.profile.relate_company}`,
      },
      { headers },
    )

    this.setState({ plant: plant.data })
  }

  handleMapTypeSatellite = e => {
    this.setState({
      isMenuMapTypeClick: 'satellite',
    })
    this.state.map.Layers.setBase(this.state.maps.Layers.GOOGLE_HYBRID)
  }

  handleMapTypeTerrain = e => {
    this.setState({
      isMenuMapTypeClick: 'terrain',
    })
    this.state.map.Layers.setBase(this.state.maps.Layers.GOOGLE_ROADMAP)
  }

  onSaveNote = e => {
    this.props.saveNoteSafetyInfo({
      token: this.props.auth.accessToken,
      ...e,
      loadData: this.loadData,
    })
  }

  render() {
    let windowHeight = 400

    if (
      typeof window.innerHeight != 'undefined' ||
      window.innerHeight != null
    ) {
      windowHeight = window.innerHeight
    }

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    }

    const safetyData =
      this.props.monitoringState.safetyInfoList != null
        ? this.props.monitoringState.safetyInfoList.map(ele => {
            return {
              eventType: ele.eventType,
              lat: parseFloat(ele.startLat),
              lon: parseFloat(ele.startLng),
              value: 5,
            }
          })
        : []

    const pieData = [
      {
        id: 'parking',
        label: (
          <LangContext.Consumer>
            {i18n => i18n.p.parkingOnRoadSide}
          </LangContext.Consumer>
        ),
        value:
          this.props.monitoringState.safetyInfoList != null
            ? this.props.monitoringState.safetyInfoList.filter(
                ele => ele.eventType == 3000,
              ).length
            : 0,
      },
      {
        id: 'speed_over',
        label: (
          <LangContext.Consumer>
            {i18n => i18n.s.speedOverLimit}
          </LangContext.Consumer>
        ),
        value:
          this.props.monitoringState.safetyInfoList != null
            ? this.props.monitoringState.safetyInfoList.filter(
                ele => ele.eventType == 1001,
              ).length
            : 0,
      },
      {
        id: 'drving_morethan4',
        label: (
          <LangContext.Consumer>
            {i18n => i18n.d.driveContinuouslyForMoreThan4Hours}
          </LangContext.Consumer>
        ),
        value:
          this.props.monitoringState.safetyInfoList != null
            ? this.props.monitoringState.safetyInfoList.filter(
                ele => ele.eventType == 7002 && ele.alarmType == 'Continuous',
              ).length
            : 0,
      },
      {
        id: 'drving_morethan10',
        label: (
          <LangContext.Consumer>
            {i18n => i18n.d.driveContinuouslyForMoreThan10HoursDay}
          </LangContext.Consumer>
        ),
        value:
          this.props.monitoringState.safetyInfoList != null
            ? this.props.monitoringState.safetyInfoList.filter(
                ele => ele.eventType == 7002 && ele.alarmType == 'Accumulate',
              ).length
            : 0,
      },

      {
        id: '910',
        label: (
          <LangContext.Consumer>
            {i18n => i18n.a.aggressiveAccelerationAndImmediatelyBreak}
          </LangContext.Consumer>
        ),
        value:
          this.props.monitoringState.safetyInfoList != null
            ? this.props.monitoringState.safetyInfoList.filter(
                ele => ele.eventType == 1002 || ele.eventType == 2050,
              ).length
            : 0,
      },
    ]

    const heatmapData = safetyData.filter(ele => {
      if (!this.state.isSpeedOverCheck && ele.eventType == 1001) {
        return false
      }

      if (!this.state.isParkingSideRoadCheck && ele.eventType == 3000) {
        return false
      }

      if (
        !this.state.isDrivingMoreThan4Check &&
        ele.eventType == 7002 &&
        ele.alarmType == 'Continuous'
      ) {
        return false
      }

      if (
        !this.state.isDrivingMoreThan10Check &&
        ele.eventType == 7002 &&
        ele.eventType == 'Accumulate'
      ) {
        return false
      }

      if (
        (!this.state.isAggressiveAccelerationAndImmediatelyBreakCheck &&
          ele.eventType == 1002) ||
        ele.eventType == 2050
      ) {
        return false
      }

      return true
    })

    const disabledSubmit =
      moment(this.state.end_at).diff(moment(this.state.start_at), 'days') > 30

    const mapType = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={this.handleMapTypeSatellite}
          style={{
            color:
              this.state.isMenuMapTypeClick === 'satellite'
                ? 'rgb(22, 146, 255)'
                : '',
          }}
        >
          <LangContext.Consumer>
            {i18n => i18n.s.satellite}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={this.handleMapTypeTerrain}
          style={{
            color:
              this.state.isMenuMapTypeClick === 'terrain'
                ? 'rgb(22, 146, 255)'
                : '',
          }}
        >
          <LangContext.Consumer>{i18n => i18n.m.map}</LangContext.Consumer>
        </Menu.Item>
      </Menu>
    )
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }

    return (
      <AuthorizeComponent {...this.props} matching_name="SafetyDashboard">
        <SafetyDashboardStyledContent>
          <Spin spinning={this.props.monitoringState.loading}>
            <Spin spinning={this.state.pageLoading}>
              <Form {...formItemLayout} className="semi-bold-16">
                <Row style={{ margin: '20px 0px 10px' }} gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div
                      className="header__text"
                      style={{ margin: '0.5rem 0px 25px 10rem' }}
                    >
                      <LangContext.Consumer>
                        {i18n => i18n.s.safetyDashboard}
                      </LangContext.Consumer>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.c.companyPartner}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        style={{ width: '100%' }}
                        showSearch={true}
                        defaultValue="all"
                        onChange={e => this.setState({ partner: e })}
                        filterOption={(val, options) => {
                          const { children } = options.props
                          if (typeof children != 'string') return false
                          return (
                            children.toLowerCase().indexOf(val.toLowerCase()) >=
                            0
                          )
                        }}
                      >
                        <Option value="all">
                          <LangContext.Consumer>
                            {i18n => i18n.a.all}
                          </LangContext.Consumer>
                        </Option>
                        {this.state.allPartner.map(ele => (
                          <Option value={ele.id}>{ele.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ left: '-100px' }}
                  >
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.division}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        style={{ width: '100%' }}
                        defaultValue="all"
                        onChange={e =>
                          this.setState(
                            {
                              selectedDivision: e,
                              selectedDepartment: 'all',
                              selectedSection: 'all',
                              selectedPlant: ['all'],
                            },
                            e => {
                              this.loadDepartment(() =>
                                this.loadSection(() => this.loadPlant()),
                              )
                            },
                          )
                        }
                      >
                        <Option value="all">
                          <LangContext.Consumer>
                            {i18n => i18n.a.all}
                          </LangContext.Consumer>
                        </Option>
                        {this.state.division.map(ele => (
                          <Option value={ele.id}>{ele.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.department}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        style={{ width: '100%' }}
                        defaultValue="all"
                        onChange={e =>
                          this.setState(
                            {
                              selectedDepartment: e,
                              selectedSection: 'all',
                              selectedPlant: ['all'],
                            },
                            e => {
                              this.loadSection(() => this.loadPlant())
                            },
                          )
                        }
                        value={this.state.selectedDepartment}
                      >
                        <Option value={'all'} label="all" key={'all'}>
                          <LangContext.Consumer>
                            {i18n => i18n.a.all}
                          </LangContext.Consumer>
                        </Option>
                        {this.state.department.map(ele => (
                          <Option value={ele.id}>{ele.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ left: '-100px' }}
                  >
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.s.section}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        style={{ width: '100%' }}
                        defaultValue="all"
                        onChange={e =>
                          this.setState(
                            {
                              selectedSection: e,
                              selectedPlant: ['all'],
                            },
                            () => {
                              this.loadPlant()
                            },
                          )
                        }
                        value={this.state.selectedSection}
                      >
                        <Option value="all">
                          <LangContext.Consumer>
                            {i18n => i18n.a.all}
                          </LangContext.Consumer>
                        </Option>
                        {this.state.section.map(ele => (
                          <Option value={ele.id}>{ele.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.p.plants}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        style={{ width: '100%' }}
                        defaultValue="all"
                        mode="multiple"
                        onChange={(value, key) => {
                          let val = value
                          let mapId = key.map(a => a.key)
                          let getId = key.map(a => a.key)
                          if (value.length > 1 && value.includes('all')) {
                            val = value.filter(
                              (x, i) => (x === 'all' && i === 0) === false,
                            )
                          }

                          if (value.length === 0) {
                            val = 'all'
                          }

                          if (val.length > 1 && value.includes('all')) {
                            val = value.filter(x => x === 'all')
                          }

                          if (mapId.includes('all')) {
                            if (mapId[0] === 'all' && mapId.length > 1) {
                              getId = mapId.filter((x, i) => i !== 0)
                            } else if (mapId[mapId.length - 1] === 'all') {
                              getId = ['all']
                            }
                          }

                          this.setState({
                            getIdValuePlant: getId.includes('all')
                              ? getId
                              : getId.map(Number),
                            selectedPlant: val,
                          })
                        }}
                        value={this.state.selectedPlant}
                      >
                        <Option value={'all'} label="all" key={'all'}>
                          <LangContext.Consumer>
                            {i18n => i18n.a.all}
                          </LangContext.Consumer>
                        </Option>

                        {this.state.plant.map(ele => (
                          <Option value={ele.name} key={ele.id}>
                            {ele.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ left: '-100px' }}
                  >
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.n.note}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        style={{ width: '100%' }}
                        defaultValue="all"
                        onChange={e =>
                          this.setState({
                            selectNote: e,
                          })
                        }
                        value={this.state.selectNote}
                      >
                        <Option value="-1">
                          <LangContext.Consumer>
                            {i18n => i18n.a.all}
                          </LangContext.Consumer>
                        </Option>
                        {this.state.note.map(ele => (
                          <Option value={ele.id}>{ele.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.t.timeRange}
                        </LangContext.Consumer>
                      }
                      validateStatus={disabledSubmit ? 'warning' : undefined}
                      help={
                        disabledSubmit ? (
                          <LangContext.Consumer>
                            {i18n => i18n.o.over1Month}
                          </LangContext.Consumer>
                        ) : (
                          undefined
                        )
                      }
                    >
                      <LangContext.Consumer>
                        {i18n => (
                          <RangePicker
                            disabledDate={disabledDate}
                            showTime={{ format: 'HH:mm' }}
                            allowClear={false}
                            onChange={value => {
                              this.onTimeRangeChange(
                                moment(value[0]['_d']).format(
                                  'YYYY-MM-DD HH:mm:ss',
                                ),
                                moment(value[1]['_d']).format(
                                  'YYYY-MM-DD HH:mm:ss',
                                ),
                              )
                            }}
                            format="DD/MM/YYYY HH:mm"
                            placeholder={['Start Time', 'End Time']}
                            defaultValue={[
                              moment('00:00', 'HH:mm'),
                              moment('23:59', 'HH:mm'),
                            ]}
                            style={{ width: '100%' }}
                          />
                        )}
                      </LangContext.Consumer>
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ left: '-100px' }}
                  >
                    <Form.Item label={' '} colon={false}>
                      <Checkbox
                        onChange={e => {
                          this.setState({
                            only_has_dp: e.target.checked,
                          })
                        }}
                        defaultChecked={true}
                      >
                        <LangContext.Consumer>
                          {i18n => i18n.o.only_dps}
                        </LangContext.Consumer>
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Col offset={2} xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Button
                        type="primary"
                        block
                        style={{ marginTop: '3px' }}
                        onClick={this.loadData}
                        disabled={disabledSubmit}
                      >
                        <LangContext.Consumer>
                          {i18n => i18n.s.searchNormal}
                        </LangContext.Consumer>
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Button
                        type="primary"
                        style={{ marginTop: '3px', marginLeft: '15px' }}
                        onClick={e => this.exportSafetyEventReport()}
                        disabled={
                          disabledSubmit &&
                          this.props.monitoringState.safetyInfoList.length > 0
                        }
                      >
                        <Icon type="download" />{' '}
                        <LangContext.Consumer>
                          {i18n => i18n.s.safetyEventReport}
                        </LangContext.Consumer>
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Form>
              <br />
              <Row gutter={[32, 32]}>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <ExpandableTable
                    list={this.props.monitoringState.safetyInfoList}
                    onSaveNote={e => this.onSaveNote(e)}
                    onRowClick={this.handlePinEventMarker}
                  />
                </Col>

                <Col xs={24} sm={24} md={9} lg={9} xl={9} align="center">
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{
                      height: '170px',
                      margin: '0px 0px 30px 0px',
                    }}
                  >
                    <Row>
                      <div className="semi-bold-16">
                        <LangContext.Consumer>
                          {i18n => i18n.o.overviewNotificationTypeHeatMap}
                        </LangContext.Consumer>
                      </div>
                    </Row>
                    <Row>
                      <LongdoMap
                        refId={'map'}
                        auth={this.props.auth}
                        handleApiLoaded={this.handleApiLoaded}
                        setMapBound={this.setMapBound}
                        markers={heatmapData}
                        eventMarkerLatLng={this.state.eventMarkerLatLng}
                      >
                        <div
                          id="map"
                          style={{
                            width: '100%',
                            height: `${window.innerHeight - 170}px`,
                          }}
                        ></div>
                        <div
                          style={{
                            position: 'absolute',
                            marginTop: '4px',
                            top: '0px',
                            zIndex: '12',
                            marginLeft: '4px',
                          }}
                        >
                          {this.state.eventMarkerLatLng.lat &&
                          this.state.eventMarkerLatLng.lng ? (
                            <Link
                              to={`/location/create?lat=${this.state.eventMarkerLatLng.lat}&lng=${this.state.eventMarkerLatLng.lng}`}
                            >
                              <Button type="primary">
                                <LangContext.Consumer>
                                  {i18n => i18n.a.addLocation}
                                </LangContext.Consumer>
                              </Button>
                            </Link>
                          ) : (
                            <Button disabled type="primary">
                              <LangContext.Consumer>
                                {i18n => i18n.a.add}
                              </LangContext.Consumer>
                              <LangContext.Consumer>
                                {i18n => i18n.l.location}
                              </LangContext.Consumer>
                            </Button>
                          )}
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            marginTop: '4px',
                            top: '0px',
                            right: '35px',
                            zIndex: '12',
                          }}
                        >
                          <ButtonGroup>
                            <Dropdown
                              className="edge-element"
                              overlay={mapType}
                            >
                              <Button>
                                <LangContext.Consumer>
                                  {i18n => i18n.m.mapview}
                                </LangContext.Consumer>{' '}
                                <Icon type="down" />
                              </Button>
                            </Dropdown>
                          </ButtonGroup>
                        </div>
                      </LongdoMap>
                    </Row>
                  </Col>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{
                    height: '190px',
                    margin: '0px 0px 30px 0px',
                  }}
                  align="center"
                >
                  <Row>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      style={{
                        height: '190px',
                        margin: '0px 0px 30px 0px',
                      }}
                    >
                      <div className="semi-bold-16">
                        <LangContext.Consumer>
                          {i18n => i18n.n.notificationTypeEvent}
                        </LangContext.Consumer>
                      </div>
                      <SafetyPanel height={210}>
                        <MyResponsivePie data={pieData} />
                      </SafetyPanel>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      style={{
                        margin: '30px 0px 0px 0px',
                      }}
                      align="left"
                    >
                      <SafetyPanel height={185}>
                        <StyledRow>
                          <Checkbox
                            onChange={e =>
                              this.handdleCheck(
                                'isParkingSideRoadCheck',
                                e.target.checked,
                              )
                            }
                            className="safety-checkbox-text"
                            defaultChecked={true}
                          >
                            <span className="regular-bold-14">
                              <LangContext.Consumer>
                                {i18n => i18n.p.parkingOnRoadSide}
                              </LangContext.Consumer>
                            </span>
                          </Checkbox>
                        </StyledRow>
                        <StyledRow>
                          <Checkbox
                            onChange={e =>
                              this.handdleCheck(
                                'isSpeedOverCheck',
                                e.target.checked,
                              )
                            }
                            className="safety-checkbox-text"
                            defaultChecked={true}
                          >
                            <span className="regular-bold-14">
                              <LangContext.Consumer>
                                {i18n => i18n.s.speedOverLimit}
                              </LangContext.Consumer>
                            </span>
                          </Checkbox>
                        </StyledRow>
                        <StyledRow>
                          <Checkbox
                            onChange={e =>
                              this.handdleCheck(
                                'isDrivingMoreThan4Check',
                                e.target.checked,
                              )
                            }
                            className="safety-checkbox-text"
                            defaultChecked={true}
                          >
                            <span className="regular-bold-14">
                              <LangContext.Consumer>
                                {i18n =>
                                  i18n.d.driveContinuouslyForMoreThan4Hours
                                }
                              </LangContext.Consumer>
                            </span>
                          </Checkbox>
                        </StyledRow>
                        <StyledRow>
                          <Checkbox
                            onChange={e =>
                              this.handdleCheck(
                                'isDrivingMoreThan10Check',
                                e.target.checked,
                              )
                            }
                            className="safety-checkbox-text"
                            defaultChecked={true}
                          >
                            <span className="regular-bold-14">
                              <LangContext.Consumer>
                                {i18n =>
                                  i18n.d.driveContinuouslyForMoreThan10HoursDay
                                }
                              </LangContext.Consumer>
                            </span>
                          </Checkbox>
                        </StyledRow>
                        <StyledRow>
                          <Checkbox
                            onChange={e =>
                              this.handdleCheck(
                                'isAggressiveAccelerationAndImmediatelyBreakCheck',
                                e.target.checked,
                              )
                            }
                            className="safety-checkbox-text"
                            defaultChecked={true}
                          >
                            <span className="regular-bold-14">
                              <LangContext.Consumer>
                                {i18n =>
                                  i18n.a
                                    .aggressiveAccelerationAndImmediatelyBreak
                                }
                              </LangContext.Consumer>
                            </span>
                          </Checkbox>
                        </StyledRow>
                      </SafetyPanel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Spin>
          </Spin>
        </SafetyDashboardStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ monitoringState, auth, auth: { profile } }) => ({
    monitoringState,
    auth,
    profile,
  }),
  {
    loadSafetyInfo: loadSafetyInfo.request,
    loadVehicleTypeMasterData: loadVehicleTypeMasterData.request,
    loadVehicleGroupMasterData: loadVehicleGroupMasterData.request,
    saveNoteSafetyInfo: saveNoteSafetyInfo.request,
    removeLists,
  },
)(Index)
