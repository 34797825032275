import { createTypes, createAction } from 'lib/action'

const LOAD_DATAVISIBILITY_SELECTED_VEHICLEGROUP = createTypes(
  'datavisibilityselectedvehiclegroupdata',
  'load',
)

const LOAD_DATAVISIBILITY_SELECTED_VEHICLE = createTypes(
  'datavisibilityselectedvehicledata',
  'load',
)

const LOAD_DATAVISIBILITY_SELECTED_LOCATIONGROUP = createTypes(
  'datavisibilityselectedlocationgroupdata',
  'load',
)

const LOAD_DATAVISIBILITY_SELECTED_LOCATION = createTypes(
  'datavisibilityselectedlocationdata',
  'load',
)

const LOAD_DATAVISIBILITY_SELECTED_USER = createTypes(
  'datavisibilityselecteduserdata',
  'load',
)

const LOAD_DATAVISIBILITY_SELECTED_MATERIAL = createTypes(
  'datavisibilityselectedmaterialdata',
  'load',
)

const REMOVE_LISTS = `REMOVE_LISTS`
const CHANGE_SELECTEDVEHICLEGROUP = `CHANGE_SELECTEDVEHICLEGROUP`
const CHANGE_SELECTEDVEHICLE = `CHANGE_SELECTEDVEHICLE`
const CHANGE_SELECTEDLOCATIONGROUP = `CHANGE_SELECTEDLOCATIONGROUP`
const CHANGE_SELECTEDLOCATION = `CHANGE_SELECTEDLOCATION`
const CHANGE_SELECTEDUSER = `CHANGE_SELECTEDUSER`
const CHANGE_SELECTEDMATERIAL = `CHANGE_SELECTEDMATERIAL`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

function changeSelectedVehicleGroup(data) {
  return {
    type: CHANGE_SELECTEDVEHICLEGROUP,
    payload: data,
  }
}

function changeSelectedVehicle(data) {
  return {
    type: CHANGE_SELECTEDVEHICLE,
    payload: data,
  }
}

function changeSelectedLocationGroup(data) {
  return {
    type: CHANGE_SELECTEDLOCATIONGROUP,
    payload: data,
  }
}

function changeSelectedLocation(data) {
  return {
    type: CHANGE_SELECTEDLOCATION,
    payload: data,
  }
}

function changeSelectedUser(data) {
  return {
    type: CHANGE_SELECTEDUSER,
    payload: data,
  }
}

function changeSelectedMaterial(data) {
  return {
    type: CHANGE_SELECTEDMATERIAL,
    payload: data,
  }
}

const loadDatavisibilitySelectedVehicleGroup = {
  request: data =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_VEHICLEGROUP.REQUEST, { data }),
  success: res =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_VEHICLEGROUP.SUCCESS, { res }),
  failure: () =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_VEHICLEGROUP.FAILURE),
}

const loadDatavisibilitySelectedVehicle = {
  request: data =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_VEHICLE.REQUEST, { data }),
  success: res =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_VEHICLE.SUCCESS, { res }),
  failure: () => createAction(LOAD_DATAVISIBILITY_SELECTED_VEHICLE.FAILURE),
}

const loadDatavisibilitySelectedLocationGroup = {
  request: data =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_LOCATIONGROUP.REQUEST, { data }),
  success: res =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_LOCATIONGROUP.SUCCESS, { res }),
  failure: () =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_LOCATIONGROUP.FAILURE),
}

const loadDatavisibilitySelectedLocation = {
  request: data =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_LOCATION.REQUEST, { data }),
  success: res =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_LOCATION.SUCCESS, { res }),
  failure: () => createAction(LOAD_DATAVISIBILITY_SELECTED_LOCATION.FAILURE),
}

const loadDatavisibilitySelectedUser = {
  request: data =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_USER.REQUEST, { data }),
  success: res =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_USER.SUCCESS, { res }),
  failure: () => createAction(LOAD_DATAVISIBILITY_SELECTED_USER.FAILURE),
}

const loadDatavisibilitySelectedMaterial = {
  request: data =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_MATERIAL.REQUEST, { data }),
  success: res =>
    createAction(LOAD_DATAVISIBILITY_SELECTED_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(LOAD_DATAVISIBILITY_SELECTED_MATERIAL.FAILURE),
}

export {
  LOAD_DATAVISIBILITY_SELECTED_VEHICLEGROUP,
  loadDatavisibilitySelectedVehicleGroup,
  LOAD_DATAVISIBILITY_SELECTED_VEHICLE,
  loadDatavisibilitySelectedVehicle,
  CHANGE_SELECTEDVEHICLEGROUP,
  changeSelectedVehicleGroup,
  CHANGE_SELECTEDVEHICLE,
  changeSelectedVehicle,
  LOAD_DATAVISIBILITY_SELECTED_LOCATIONGROUP,
  loadDatavisibilitySelectedLocationGroup,
  CHANGE_SELECTEDLOCATIONGROUP,
  changeSelectedLocationGroup,
  LOAD_DATAVISIBILITY_SELECTED_LOCATION,
  loadDatavisibilitySelectedLocation,
  CHANGE_SELECTEDLOCATION,
  changeSelectedLocation,
  LOAD_DATAVISIBILITY_SELECTED_USER,
  loadDatavisibilitySelectedUser,
  LOAD_DATAVISIBILITY_SELECTED_MATERIAL,
  loadDatavisibilitySelectedMaterial,
  CHANGE_SELECTEDUSER,
  changeSelectedUser,
  CHANGE_SELECTEDMATERIAL,
  changeSelectedMaterial,
}
