import React, { Component, Fragment } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Index extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        <div className="divider__web__content">
          <Divider />
        </div>

        {/* วิดีโอเรียลไทม์ MDVR */}
        {checkMenuVisibility('', web_endpoint, 'video', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/video">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.v.video}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแสดงวิดีโอ แบบเรียลไทม์
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.v.video}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* ดาวน์โหลดวิดีโอย้อนหลัง MDVR */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'mdvrdownloadfile',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/mdvrdownloadfile">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.mdvrdownloadfile}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับดาวน์โหลดวิดีโอ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.mdvrdownloadfile}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Index
