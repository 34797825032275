import React, { Component } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import MonitoringHoneyToast from './Monitoring Honey Toast/Index'
import MonitoringMDVR from './Monitoring MDVR/Index'

class Monitoring extends Component {
  constructor(props) {
    super(props)
    this.state = {
      honeyToastDetail: false,
      mdvrDetail: false,
      colorSelection: '',
    }
  }
  handleHoneyToast = () => {
    this.setState(
      {
        honeyToastDetail: true,
        mdvrDetail: false,
        colorSelection: 'Honey Toast',
      },
      () => {
        this.props.getBreadcrumbSubText('Honey Toast')
      },
    )
  }

  handleMDVR = () => {
    this.setState(
      {
        honeyToastDetail: false,
        mdvrDetail: true,
        colorSelection: 'MDVR',
      },
      () => {
        this.props.getBreadcrumbSubText('MDVR')
      },
    )
  }

  render() {
    const {
      checkMenuVisibility,
      web_endpoint,
      permissions,
      checkSubMenuVisibility,
      monitoringHoneyToastObj,
      monitoringMDVRObj,
    } = this.props
    return (
      <>
        {/* ติดตามยานพาหนะ */}
        {checkMenuVisibility('', web_endpoint, 'tracking', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/tracking">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.tracking}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับติดตามตำแหน่งปัจจุบันของพาหนะ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <span className="color__text__card__web__content">
                    <LangContext.Consumer>
                      {i18n => i18n.t.tracking}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* เส้นทางเดินทางย้อนหลัง */}
        {checkMenuVisibility('', web_endpoint, 'replay', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/replay">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.r.replay}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับตรวจสอบเส้นทางการเดินทางย้อนหลังของพาหนะ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <span className="color__text__card__web__content">
                    <LangContext.Consumer>
                      {i18n => i18n.r.replay}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปแดชบอร์ด */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'summary_dashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/summary_dashboard">
              <Card className="card__web__content">
                <p className="text__card__web__content">
                  <span className="color__text__card__web__content">
                    <LangContext.Consumer>
                      {i18n => i18n.s.SafetyDashboard}
                    </LangContext.Consumer>
                  </span>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* booking_overview_dashboard */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'booking_overview_dashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/booking_overview">
              <Card className="card__web__content">
                <p className="text__card__web__content">
                  <span className="color__text__card__web__content">
                    <LangContext.Consumer>
                      {i18n => i18n.b.bookingOverview}
                    </LangContext.Consumer>
                  </span>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* cashing_overview_dahsboar */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'cashing_overview_dahsboar',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/cashing_overview">
              <Card className="card__web__content">
                <p className="text__card__web__content">
                  <span className="color__text__card__web__content">
                    <LangContext.Consumer>
                      {i18n => i18n.e.earningOverview}
                    </LangContext.Consumer>
                  </span>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* Honey Toast SubMenu */}
        {checkSubMenuVisibility(monitoringHoneyToastObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleHoneyToast()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'Honey Toast' && 'red',
                  color: this.state.colorSelection === 'Honey Toast' && 'white',
                }}
              >
                <p className="text_one_line_card_web_content">
                  <span
                    className="color__text__card__web__content"
                    style={{
                      color:
                        this.state.colorSelection === 'Honey Toast' && 'white',
                    }}
                  >
                    <LangContext.Consumer>
                      {i18n => i18n.h.honeyToast}
                    </LangContext.Consumer>
                  </span>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}

        {/* MDVR Submenu */}
        {checkSubMenuVisibility(monitoringMDVRObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleMDVR()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'MDVR' && 'red',
                  color: this.state.colorSelection === 'MDVR' && 'white',
                }}
              >
                <p className="text_one_line_card_web_content">
                  <span
                    className="color__text__card__web__content"
                    style={{
                      color: this.state.colorSelection === 'MDVR' && 'white',
                    }}
                  >
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.m.mdvr}
                    </LangContext.Consumer>
                  </span>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}

        {this.state.honeyToastDetail ? (
          <MonitoringHoneyToast
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.mdvrDetail ? (
          <MonitoringMDVR
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
      </>
    )
  }
}

export default Monitoring
