import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Spin, Col, Button, Row } from 'antd'
import axios from 'axios'
import * as actions from '../actions'

import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext from 'modules/shared/context/langContext'
import { withRouter } from 'react-router-dom'

class Previewtable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataPreview: [],
      loadPreview: false,
      filter: '',
      header: '',
    }

    this.columns = [
      //   ลำดับที่
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.n.noReport}</LangContext.Consumer>
        ),
        dataIndex: 'row',
        key: 'row',
        width: '100px',
        align: 'center',
        render: (text, record, index) => {
          return <div>{text}</div>
        },
      },
      //   ทะเบียน
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>
        ),
        dataIndex: 'PlateNo',
        key: 'PlateNo',
        width: '100px',
        align: 'center',
      },
      //   ชื่อ - นามสกุล
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.f.fullname}</LangContext.Consumer>
        ),
        dataIndex: 'DriverName',
        key: 'DriverName',
        width: '100px',
        align: 'center',
      },
      //   ระยะเวลารวม
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.t.totalDurationFuelUsage}
          </LangContext.Consumer>
        ),
        dataIndex: 'TotalDriving',
        key: 'TotalDriving',
        width: '100px',
        align: 'center',
      },
      //   ระยะเวลาติดเครื่องรวม
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.m.mixedEngineTime}
          </LangContext.Consumer>
        ),
        dataIndex: 'TotalDrivingTime',
        key: 'TotalDrivingTime',
        width: '100px',
        align: 'center',
      },
      //   ระยะทางที่รถวิ่ง (กม.)
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.t.totalDistance}
          </LangContext.Consumer>
        ),
        dataIndex: 'TotalDistance',
        key: 'TotalDistance',
        width: '100px',
        align: 'center',
      },
      //   เวลาที่จอดติดเครื่อง
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.p.parkingEngineOn}
          </LangContext.Consumer>
        ),
        dataIndex: 'TotalEngineIdleTime',
        key: 'TotalEngineIdleTime',
        width: '100px',
        align: 'center',
      },
      //   ความเร็วสูงสุดที่ใช้
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.m.maxUsedSpeed}
          </LangContext.Consumer>
        ),
        dataIndex: 'MaxGpsSpeed',
        key: 'MaxGpsSpeed',
        width: '100px',
        align: 'center',
      },
      //   เวลาที่ขับเร็วสูงสุด
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.t.timeToDriveAtMaximumSpeed}
          </LangContext.Consumer>
        ),
        dataIndex: 'MaxGpsSpeedDatetime',
        key: 'MaxGpsSpeedDatetime',
        width: '100px',
        align: 'center',
        render: (text, record, index) => {
          return <div>{!!text ? text : '-'}</div>
        },
      },
      //   จำนวนครั้งวิ่งเกินความเร็ว
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.n.numberOfTimesRunningOverSpeed}
          </LangContext.Consumer>
        ),
        dataIndex: 'CountSpeedOverPolicy',
        key: 'CountSpeedOverPolicy',
        width: '100px',
        align: 'center',
      },
      //   เริ่มต้น
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.s.start}</LangContext.Consumer>
        ),
        children: [
          //   น้ำมันคงเหลือ
          {
            title: (
              <div>
                <LangContext.Consumer>
                  {i18n => i18n.f.fuelremainrateReport}
                </LangContext.Consumer>{' '}
                (%)
              </div>
            ),
            dataIndex: 'StartFuelRemainingPercen',
            key: 'StartFuelRemainingPercen',
            width: '100px',
            align: 'center',
          },
          //   น้ำมันคงเหลือ
          {
            title: (
              <LangContext.Consumer>
                {i18n => i18n.f.fuelRate}
              </LangContext.Consumer>
            ),
            dataIndex: 'StartFuelRemaining',
            key: 'StartFuelRemaining',
            width: '100px',
            align: 'center',
          },
        ],
      },
      //   เติมน้ำมัน (ลิตร)
      {
        title: (
          <div>
            <LangContext.Consumer>
              {i18n => i18n.event.refuel}
            </LangContext.Consumer>
            (<LangContext.Consumer>{i18n => i18n.l.litre}</LangContext.Consumer>
            )
          </div>
        ),
        dataIndex: '',
        key: '',
        width: '100px',
        align: 'center',
        render: (text, record, index) => {
          return <div>-</div>
        },
      },
      //   สิ้นสุด
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.e.end}</LangContext.Consumer>
        ),
        children: [
          //   น้ำมันคงเหลือ
          {
            title: (
              <div>
                <LangContext.Consumer>
                  {i18n => i18n.f.fuelremainrateReport}
                </LangContext.Consumer>{' '}
                (%)
              </div>
            ),
            dataIndex: 'EndFuelRemainingPercen',
            key: 'EndFuelRemainingPercen',
            width: '100px',
            align: 'center',
          },
          //   น้ำมันคงเหลือ
          {
            title: (
              <LangContext.Consumer>
                {i18n => i18n.f.fuelRate}
              </LangContext.Consumer>
            ),
            dataIndex: 'EndFuelRemaining',
            key: 'EndFuelRemaining',
            width: '100px',
            align: 'center',
          },
        ],
      },
      //   ใช้น้ำมันทั้งหมด
      {
        title: (
          <div>
            <LangContext.Consumer>
              {i18n => i18n.u.useAllFuel}
            </LangContext.Consumer>{' '}
            (<LangContext.Consumer>{i18n => i18n.l.litre}</LangContext.Consumer>
            )
          </div>
        ),
        dataIndex: 'TotalFuelUsage',
        key: 'TotalFuelUsage',
        width: '100px',
        align: 'center',
      },
      //   ค่าเฉลี่ย(กม./ลิตร)
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.a.averageKMperLitre}
          </LangContext.Consumer>
        ),
        dataIndex: 'AverageKmL',
        key: 'AverageKmL',
        width: '150px',
        align: 'center',
        render: (text, record, index) => {
          return <div>{!!text ? parseFloat(text).toFixed(2) : '-'}</div>
        },
      },

      //   ค่าเฉลี่ย(ลิตร/ชม.)
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.a.averageKMperHours}
          </LangContext.Consumer>
        ),
        dataIndex: 'AverageLHr',
        key: 'AverageLHr',
        width: '100px',
        align: 'center',
        render: (text, record, index) => {
          return <div>{!!text ? parseFloat(text).toFixed(2) : '-'}</div>
        },
      },
    ]
  }

  handleMenuClick = () => {
    this.fetchTruckUsage(this.state.filter, this.state.header, 'excel')
  }

  componentDidMount() {
    if (
      localStorage.getItem('summaryvehicleusagereport-filter') != null &&
      localStorage.getItem('summaryvehicleusagereport-header') != null
    ) {
      let filter = localStorage.getItem('summaryvehicleusagereport-filter')
      let header = localStorage.getItem('summaryvehicleusagereport-header')
      this.fetchTruckUsage(JSON.parse(filter), JSON.parse(header), 'preview')
    }
  }

  fetchTruckUsage = (filter, header, type) => {
    filter['type_file'] = type
    let self = this
    this.setState({
      filter,
      header,
      loadPreview: true,
    })
    axios
      .post(
        `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/new-truckusage`,
        filter,
        header,
      )
      .then(function(response) {
        let data = []
        if (typeof response.data !== 'undefined' && type !== 'excel') {
          for (var key in response.data) {
            data = [...data, response.data[key]]
          }
          self.setState({
            dataPreview: type == 'excel' ? this.state.dataPreview : data,
            loadPreview: false,
          })
        } else {
          self.setState({
            loadPreview: false,
          })
          window.location.assign(response.data.result)
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  componentWillUnmount() {
    localStorage.removeItem('summaryvehicleusagereport-filter')
    localStorage.removeItem('summaryvehicleusagereport-header')
  }

  render() {
    const { loading } = this.props.summaryVehicleUsage
    const columns = this.columns.map(col => col)

    return (
      <AuthorizeComponent {...this.props} matching_name="concreteTrip">
        <Spin spinning={this.state.loadPreview}>
          <GeneralStyledContent>
            <Row
              gutter={24}
              type="flex"
              justify="end"
              style={{ height: '38px' }}
            >
              <Col xs={9} sm={4} md={3} lg={2} xl={2}>
                <Button
                  type="primary"
                  block
                  onClick={() => this.handleMenuClick()}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.r.report}
                  </LangContext.Consumer>
                </Button>
              </Col>
            </Row>

            <Table
              scroll={{ x: 1500, y: `${window.innerHeight - 300}px` }}
              dataSource={this.state.dataPreview}
              columns={columns}
              bordered
            />
          </GeneralStyledContent>
        </Spin>
      </AuthorizeComponent>
    )
  }
}

export default withRouter(
  connect(
    ({ summaryVehicleUsage, auth, auth: { profile } }) => ({
      summaryVehicleUsage,
      auth,
      profile,
    }),
    {
      exportSummaryVehicleUsage: actions.exportSummaryVehicleUsage.request,
    },
  )(Previewtable),
)
