import React, { Component } from 'react'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import * as actions from '../actions'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from './../../../shared/components/Breadcrumb'
import { checkPermissions } from '../../../../lib/helper'
import '../../../../styled/fix-line-lost.css'

import {
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
  Icon,
  Tooltip,
  Drawer,
  message,
  Pagination,
} from 'antd'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Filter from './Filter'
const columnStyle = { fontSize: '12px', fontWeight: '400' }

class Index extends Component {
  state = {
    visible: false,
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    orderType: 'asc',
    filterObj: {
      name: '',
      line_token: '',
    },
    company_id: this.props.auth.profile.company_id,
    token: this.props.auth.accessToken,

    loading: false,
    divisionLoading: false,
    division: [],
    departmentLoading: false,
    department: [],
    sectionLoading: false,
    section: [],
    selectedDivision: 'all',
    selectedDepartment: 'all',
    selectedSection: 'all',
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onCloseSearch = resetForm => {
    this.setState({
      visible: false,
    })
  }

  filterLineNotify = values => {
    const { name, line_token, division, department, section } = values

    this.setState({
      filterObj: { name, line_token },
    })
    this.props.loadLineNotify(
      {
        page: this.state.page,
        pageSize: this.state.pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: { name, line_token },
        company_id: this.props.auth.profile.company_id,
        token: this.props.auth.accessToken,
        selectedDivision: division,
        selectedDepartment: department,
        selectedSection: section,
      },
      true,
    )
  }

  componentDidMount() {
    this.loadDivision()
    this.loadDepartment()
    this.loadSection()
    this.props.loadLineNotify(this.state, true)
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataResponse } = this.props.linenotify

    if (
      dataResponse.action_type === 'create' ||
      dataResponse.action_type === 'update'
    ) {
      if (dataResponse.status === 'success') {
        message.success(i18n[this.props.auth.lang]['s']['success'])
      } else if (dataResponse.status === 'fail') {
        message.error(i18n[this.props.auth.lang]['f']['fail'])
      }
      this.props.clearDataResponse()
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      page: pagination.current,
      pageSize: pagination.pageSize,
    })

    let orderBy = ''
    let orderType = ''

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = this.state.orderBy
      orderType = this.state.orderType
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == 'descend' ? 'desc' : 'asc',
      })
      orderBy = sorter.columnKey
      orderType = sorter.order == 'descend' ? 'desc' : 'asc'
    }

    this.props.loadLineNotify(
      {
        page: pagination.current,
        pageSize: pagination.pageSize,
        searchName: '',
        orderBy: orderBy,
        orderType: orderType,
        filterObj: {
          name: this.state.filterObj.name,
          line_token: this.state.filterObj.line_token,
        },
        company_id: this.props.auth.profile.company_id,
        token: this.props.auth.accessToken,
        selectedDivision: this.state.selectedDivision,
        selectedDepartment: this.state.selectedDepartment,
        selectedSection: this.state.selectedSection,
      },
      true,
    )
  }

  deleteLineNotify = id => {
    let self = this

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/linenotify/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function(response) {
        self.props.loadLineNotify(
          {
            page: 1,
            pageSize: self.state.pageSize,
            orderBy: self.state.orderBy,
            orderType: self.state.orderType,
            filterObj: {
              name: self.state.filterObj.name,
              line_token: self.state.filterObj.line_token,
            },
            company_id: self.props.auth.profile.company_id,
            token: self.props.auth.accessToken,
            selectedDivision: this.state.selectedDivision,
            selectedDepartment: this.state.selectedDepartment,
            selectedSection: this.state.selectedSection,
          },
          true,
        )
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  loadDivision = cb => {
    this.setState({ loading: true })
    let self = this
    let divisionVisibility = []

    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      divisionVisibility = orgChart.divisions.map(ele => ele.divisionNo)
    }
    this.setState({ divisionLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        division_visibility: divisionVisibility,
        comp: [2, 3, 4, 5, 6],
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            divisionLoading: false,
            division: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadDepartment = cb => {
    this.setState({ loading: true })
    let self = this
    let departmentVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      departmentVisibility = orgChart.departments.map(ele => ele)
    }
    this.setState({ departmentLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        department_visibility: departmentVisibility,
        division: [this.state.selectedDivision],
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            departmentLoading: false,
            department: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadSection = () => {
    this.setState({ loading: true })
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    this.setState({ sectionLoading: true })

    let departmentID = [this.state.selectedDepartment.toString()]

    if (this.state.selectedDepartment === 'all') {
      departmentID = this.state.department.map(x => x.id.toString())
    }

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        section_visibility: sectionVisibility,
        department: departmentID,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState({
          sectionLoading: false,
          section: data,
          loading: false,
        })
      })
  }

  updateSelectDivision = e =>
    this.setState(
      {
        selectedDivision: e,
        selectedDepartment: 'all',
        selectedSection: 'all',
      },
      e => {
        this.loadDepartment(() => this.loadSection())
      },
    )

  updateSelectDepartment = e =>
    this.setState(
      {
        selectedDepartment: e,
        selectedSection: 'all',
      },
      e => {
        this.loadSection()
      },
    )

  updateSelectSection = e => this.setState({ selectedSection: e })

  render() {
    const columns = [
      {
        title: <div className="regular-bold-14">#</div>,
        dataIndex: 'row_number',
        width: '3%',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div className="regular-bold-14">{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.d.department_code}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: 'department_code',
        sorter: true,
        key: 'department_code',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div className="regular-bold-14">{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.d.department}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: 'department_name',
        sorter: true,
        key: 'department_name',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div className="regular-bold-14">{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.d.division_code}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: 'division_code',
        sorter: true,
        key: 'division_code',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div className="regular-bold-14">{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.d.division}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: 'division_name',
        sorter: true,
        key: 'division_name',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div className="regular-bold-14">{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.s.section_code}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: 'section_code',
        sorter: true,
        key: 'section_code',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div className="regular-bold-14">{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.s.section}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: 'section_name',
        sorter: true,
        key: 'section_name',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div className="regular-bold-14">{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.l.line_token}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: 'line_token',
        sorter: true,
        key: 'line_token',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div className="regular-bold-14">{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.n.notificationTypeEvent}
            </LangContext.Consumer>
          </div>
        ),
        dataIndex: 'event',
        sorter: true,
        key: 'event',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div className="regular-bold-14">{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>{i18n => i18n.a.action}</LangContext.Consumer>
          </div>
        ),
        dataIndex: 'action',
        key: 'action',
        sorter: false,
        render: (text, record, index) => {
          return (
            <div>
              {checkPermissions(
                this.props.auth.profile.permissions,
                'linenotify',
                'can_view',
              ) == true ? (
                <Tooltip
                  mouseEnterDelay={1.2}
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.d.detail}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: '0px 1px 0px 0px',
                    marginLeft: '5px',
                    marginRight: '5px',
                  }}
                >
                  <Link
                    to={`/linenotify/detail/${record.id}`}
                    style={{ padding: '0px 5px 0px 5px' }}
                  >
                    <Icon type="file-text" />
                  </Link>
                </Tooltip>
              ) : (
                ''
              )}

              {checkPermissions(
                this.props.auth.profile.permissions,
                'linenotify',
                'can_edit',
              ) === true ? (
                <Tooltip
                  placement="left"
                  mouseEnterDelay={1.2}
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.e.edit}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                >
                  <Link
                    to={`/linenotify/edit/${record.id}`}
                    style={{ padding: '0px 5px 0px 5px' }}
                  >
                    <Icon type="edit" />
                  </Link>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
    ]

    return (
      <div>
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <div className="header__text">
                <LangContext.Consumer>
                  {i18n => i18n.l.linenotify}
                </LangContext.Consumer>
              </div>
            </Col>
          </Row>
          <Row gutter={24} type="flex" justify="end" style={{ height: '38px' }}>
            <Col span={22}></Col>

            <Col span={2}>
              <Button icon="filter" size="small" onClick={this.showDrawer}>
                <LangContext.Consumer>
                  {i18n => i18n.f.filter}
                </LangContext.Consumer>
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            rowKey={record => record.id}
            dataSource={this.props.linenotify.linenotifyLists}
            pagination={{
              pageSize: this.state.pageSize,
              total: this.props.linenotify.linenotifyTotal,
              current: this.state.page,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50'],
            }}
            onChange={this.handleTableChange}
            loading={this.props.linenotify.linenotifyLoading}
          />

          <div>
            <LangContext.Consumer>
              {i18n => (
                <Drawer
                  title={i18n.s.search}
                  width={450}
                  onClose={this.onCloseSearch}
                  visible={this.state.visible}
                >
                  <Filter
                    OnClose={this.onCloseSearch}
                    onSubmit={this.filterLineNotify}
                    loading={this.state.loading}
                    reportLists={this.props.linenotify.reportLists}
                    division={this.state.division}
                    selectedDivision={this.state.selectedDivision}
                    updateSelectDivision={this.updateSelectDivision}
                    department={this.state.department}
                    selectedDepartment={this.state.selectedDepartment}
                    updateSelectDepartment={this.updateSelectDepartment}
                    section={this.state.section}
                    selectedSection={this.state.selectedSection}
                    updateSelectSection={this.updateSelectSection}
                    auth={this.props.auth}
                  />
                </Drawer>
              )}
            </LangContext.Consumer>
          </div>
        </GeneralStyledContent>
      </div>
    )
  }
}

const mapStateToProps = ({ linenotify, auth }) => ({
  linenotify,
  auth,
})

const mapDispatchToProps = {
  loadLineNotify: actions.loadLineNotify.request,
  clearDataResponse: actions.clearDataResponse,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
