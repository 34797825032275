import React, { Component } from 'react'
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  AutoComplete,
  Input,
  DatePicker,
  Spin,
} from 'antd'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import moment from 'moment'

import LangContext, { i18n } from 'modules/shared/context/langContext'

const FormItem = Form.Item
const OptionAuto = AutoComplete.Option
const format = 'HH:mm'
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
class FormTrip extends Component {
  state = {
    platenoList: [],
    codeList: [],
    vehicle_id: '',
    trip_code: '',
    plate_no: '',
    vehicle_code: '',
    start_trip_date: moment('00:00', 'HH:mm'),
  }

  handleClick = (value, setFieldValue, handleSubmit) => {
    setFieldValue('trip_code', this.state.trip_code, false)
    setFieldValue('start_trip_date', this.state.start_trip_date, false)
    setFieldValue('vehicle_id', this.state.vehicle_id, false)
    setFieldValue('plate_no', this.state.plate_no, false)
    setFieldValue('vehicle_code', this.state.vehicle_code, false)

    handleSubmit()
  }

  handleCancelClick = () => {
    this.setState({
      vehicle_id: '',
      trip_code: '',
      plate_no: '',
      vehicle_code: '',
      start_trip_date: moment('00:00', 'HH:mm'),
    })

    this.props.onCancel()
  }

  changeVehicle = (value, option, setFieldValue) => {
    this.setState({ vehicle_id: value })
    this.setState({ plate_no: option.props.children })
    this.setState({ vehicle_code: option.props.code })
  }

  selectVehicle = (value, option, setFieldValue) => {
    this.setState({ vehicle_id: value })
    this.setState({ plate_no: option.props.children })
    this.setState({ vehicle_code: option.props.code })
  }

  changeVehicleCode = (value, option, setFieldValue) => {
    this.setState({ vehicle_id: value })
    this.setState({ plate_no: option.props.plate_no })
    this.setState({ vehicle_code: option.props.children })
  }

  selectVehicleCode = (value, option, setFieldValue) => {
    this.setState({ vehicle_id: value })
    this.setState({ plate_no: option.props.plate_no })
    this.setState({ vehicle_code: option.props.children })
  }

  changeStartTripdate = value => {
    this.setState({ start_trip_date: value })
  }

  changeTripCode = value => {
    this.setState({ trip_code: value })
  }

  clearTrip = () => {
    this.setState({
      vehicle_id: '',
      trip_code: '',
      plate_no: '',
      vehicle_code: '',
      start_trip_date: moment('00:00', 'HH:mm'),
    })
  }

  validateTripCode = value => {
    let warning
    if (!value) {
      warning = `${i18n.trip_import.trip_code_require}`
    } else {
      let self = this

      fetch(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/checktripcode`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.props.auth.accessToken}`,
          },
          body: JSON.stringify({
            company_id: self.props.auth.profile.company_id,
            trip_code: value.value,
          }),
        },
      )
        .then(response => response.json())
        .then(res => {
          if (res.status == 'true') {
            warning = 'รหัสทริปนี้มีอยู่แล้วในระบบ'
          }
        })
    }
    return warning
  }

  render() {
    const {
      visible,
      onOk,
      onCancel,
      route_master_id,
      vehicleLists,
    } = this.props

    const platenolists = vehicleLists.map(v => (
      <OptionAuto key={v.id} code={v.code}>
        {v.plate_no}
      </OptionAuto>
    ))

    const codelists = vehicleLists.map(v => (
      <OptionAuto key={v.id} plate_no={v.plate_no}>
        {v.code}
      </OptionAuto>
    ))

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    }

    let form_name = (
      <LangContext.Consumer>{i18n => i18n.c.create_trip}</LangContext.Consumer>
    )
    const { auth } = this.props
    return (
      <div>
        <div>
          <LangContext.Consumer>
            {i18n => (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  vehicle_code: this.state.vehicle_id,
                  plate_no: this.state.vehicle_id,
                  trip_code: this.state.trip_code,
                  start_trip_date: this.state.start_trip_date,
                }}
                validationSchema={yup.object().shape({
                  vehicle_code: yup
                    .string()
                    .required(`${i18n.p.pleaseSelectVehicleCode}`),
                  plate_no: yup
                    .string()
                    .required(`${i18n.p.pleaseSelectVehicleRegistration}`),
                  //trip_code: yup.string().required("กรุณากรอกรหัสทริป"),

                  trip_code: yup
                    .string()
                    .required(`${i18n.trip_import.trip_code_require}`)
                    .test(
                      'checktripcode',
                      `${i18n.trip_import.TripCodeAlreadyExistsSystem}`,
                      function(value) {
                        return new Promise((resolve, reject) => {
                          fetch(
                            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/checktripcode`,
                            {
                              method: 'POST',
                              headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${auth.accessToken}`,
                              },
                              body: JSON.stringify({
                                company_id: auth.profile.company_id,
                                trip_code: value,
                              }),
                            },
                          )
                            .then(response => response.json())
                            .then(res => {
                              if (res.status === 'true') {
                                resolve(false)
                              }
                              resolve(true)
                            })
                        })
                      },
                    ),
                })}
                validate={values => {
                  let errors = {}

                  return errors
                }}
                onSubmit={values => {
                  onOk(values, this.clearTrip)
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                  resetForm,
                }) => (
                  <Modal
                    style={{ top: 20 }}
                    title={form_name}
                    visible={visible}
                    onOk={value =>
                      this.handleClick(value, setFieldValue, handleSubmit)
                    }
                    onCancel={this.handleCancelClick}
                    okText={
                      <LangContext.Consumer>
                        {i18n => i18n.o.okay}
                      </LangContext.Consumer>
                    }
                    cancelText={
                      <LangContext.Consumer>
                        {i18n => i18n.c.cancel}
                      </LangContext.Consumer>
                    }
                    width={600}
                  >
                    <Spin spinning={this.props.loading}>
                      <Form onSubmit={handleSubmit} layout="horizontal">
                        <FormItem
                          {...formItemLayout}
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.t.tripCode}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.trip_code && errors.trip_code && 'error'
                          }
                          help={touched.trip_code && errors.trip_code}
                        >
                          <Input
                            name="trip_code"
                            onChange={value =>
                              this.changeTripCode(value.target.value)
                            }
                            //onChange={value => setFieldValue("trip_code", value.target.value)}
                            // onChange={handleChange}

                            onBlur={handleBlur}
                            value={values.trip_code}
                          />
                        </FormItem>

                        <FormItem
                          {...formItemLayout}
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.p.plateNo}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.plate_no && errors.plate_no && 'error'
                          }
                          help={touched.plate_no && errors.plate_no}
                        >
                          <AutoComplete
                            dataSource={platenolists}
                            // style={{ width: 400 }}
                            optionLabelProp="children"
                            filterOption={true}
                            optionFilterProp="children"
                            value={this.state.plate_no}
                            onChange={(value, option) =>
                              this.changeVehicle(value, option, setFieldValue)
                            }
                            onSelect={(value, option) =>
                              this.selectVehicle(value, option, setFieldValue)
                            }
                          />
                        </FormItem>
                        <FormItem
                          {...formItemLayout}
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.v.vehicleCode}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.vehicle_code &&
                            errors.vehicle_code &&
                            'error'
                          }
                          help={touched.vehicle_code && errors.vehicle_code}
                        >
                          <AutoComplete
                            dataSource={codelists}
                            //style={{ width: 400 }}
                            optionLabelProp="children"
                            filterOption={true}
                            optionFilterProp="children"
                            value={this.state.vehicle_code}
                            onChange={(value, option) =>
                              this.changeVehicleCode(
                                value,
                                option,
                                setFieldValue,
                              )
                            }
                            onSelect={(value, option) =>
                              this.selectVehicleCode(
                                value,
                                option,
                                setFieldValue,
                              )
                            }
                          />
                        </FormItem>
                        <Form.Item
                          {...formItemLayout}
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.s.start_deliver_at}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.start_trip_date &&
                            errors.start_trip_date &&
                            'error'
                          }
                          help={
                            touched.start_trip_date && errors.start_trip_date
                          }
                        >
                          <DatePicker
                            showTime={{ format: 'HH:mm' }}
                            format="DD/MM/YYYY HH:mm"
                            onChange={value => this.changeStartTripdate(value)}
                            //onOk={value => setFieldValue("start_trip_date", value)}
                            // defaultValue={moment('00:00', 'HH:mm')}
                            value={this.state.start_trip_date}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Form>
                    </Spin>
                  </Modal>
                )}
              />
            )}
          </LangContext.Consumer>
        </div>
      </div>
    )
  }
}

export default FormTrip
