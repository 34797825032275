import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col
} from "antd";

import * as actions from "../actions";
import Form from "./Forms";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";


class Edit extends Component {
    componentDidMount() {
        this.props.getMaintenancedevice(this.props.match.params.id);
    }

    edit = (values, loading) => {
        this.props.updateMaintenancedevice(values, this.props.match.params.id, this.props.history, loading);

    };

    render() {
        return (
            <GeneralStyledContent>
                <Row gutter={24}>
                    <Col span={24}>
                        <Breadcrumb
                            match={this.props.match}
                            style={{ margin: "0px 0px 14px 10px" }}
                        />
                    </Col>
                </Row>
                <Form
                    onSubmit={this.edit}
                    maintenancedevice={this.props.maintenancedevice}
                    loadVehicleMaintenancedevice={this.props.loadVehicleMaintenancedevice}
                    loadMaintenancestatus={this.props.loadMaintenancestatus}
                    loadTypeproblem={this.props.loadTypeproblem}
                    id={this.props.match.params.id}
                    auth={this.props.auth}
                />
            </GeneralStyledContent>
        );
    }
}

const mapStateToProps = ({
    maintenancedevice, auth
}) => ({
    maintenancedevice, auth
});

const mapDispatchToProps = {
    updateMaintenancedevice: actions.updateMaintenancedevice.request,
    getMaintenancedevice: actions.getMaintenancedeviceByID.request,
    loadVehicleMaintenancedevice: actions.loadVehicleMaintenancedevice.request,
    loadMaintenancestatus: actions.loadMaintenancestatus.request,
    loadTypeproblem: actions.loadTypeproblem.request
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Edit);
