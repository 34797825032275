import {
  LOAD_DRIVINGBEHAVIOR,
  LOAD_DRIVINGBEHAVIOR_PREVIEW,
  LOAD_VEHICLE_DRIVINGBEHAVIOR,
  LOAD_VEHICLE_TYPE_DRIVINGBEHAVIOR,
} from './actions'

const initialState = {
  drivingbehaviorUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  drivingbehaviorLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DRIVINGBEHAVIOR.REQUEST:
    case LOAD_DRIVINGBEHAVIOR_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        drivingbehaviorLoading: loading,
      }
    case LOAD_DRIVINGBEHAVIOR.SUCCESS:
    case LOAD_DRIVINGBEHAVIOR_PREVIEW.SUCCESS:
      return {
        ...state,
        drivingbehaviorUrl: action.payload.data.data,
        drivingbehaviorLoading: false,
      }
    case LOAD_VEHICLE_DRIVINGBEHAVIOR.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        drivingbehaviorLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_DRIVINGBEHAVIOR.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        drivingbehaviorLoading: false,
      }
    case LOAD_VEHICLE_TYPE_DRIVINGBEHAVIOR.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
