import React, { Component } from 'react'
import { Row, Col, Select, Form, Button, Spin } from 'antd'
import moment from 'moment'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { debounce } from 'debounce'

const { Option } = Select
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']

class FormDistanceReport extends Component {
  state = {
    divisionLoading: false,
    division: [],
    searchData: [],
    departmentLoading: false,
    department: [],
    sectionLoading: false,
    section: [],
    plant: [],
    plantLoading: [],
    partner: 'all',
    selectedDivision: null,
    selectedDepartment: ['All'],
    getIdValueDepartment: [],
    getIdValueSection: [],
    getIdValuePlant: [],
    selectedSection: ['All'],
    selectedPlant: ['All'],
    pageLoading: false,
    pickupSearchString: '',
    loading: false,
    searchPickup: [],
    filterSelection: 'all',
    startTime: moment(),
    endTime: moment(),
    validationStartTime: false,
    validationEndTime: false,
    validationSearch: false,
    validationDivision: false,
    departmentSelectMode: 'multiple',
    dataTable: [],
    disabledDepartment: false,
    open: false,
    value: [],
    onPreview: false,
    onExport: false,
    showTableAfteronPreview: false,
    showEndDate: true,
    endOpen: false,
  }
  queryPlaceToAutocomplete(val) {
    this.setState(
      {
        loading: true,
        searchData: [],
      },
      () => {
        fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsite`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.props.auth.accessToken}`,
          },
          body: JSON.stringify({
            input_site: `${val}`,
          }),
        })
          .then(response => response.json())
          .then(data => {
            this.setState({
              searchData: data,
              loading: false,
            })
          })
      },
    )
  }

  loadPreview = val => {
    this.setState(
      {
        onPreview: true,
      },
      () => {
        let validateStartAt = this.handleValidateInput(this.state.startTime)
        let validateEndAt = this.handleValidateInput(this.state.endTime)
        let validateSearchPickup = this.handleValidateInput(
          this.state.searchPickup,
        )
        let validateSelectedDivision = this.handleValidateInput(
          this.state.selectedDivision,
        )
        if (
          validateStartAt === false &&
          validateEndAt === false &&
          validateSearchPickup === false &&
          validateSelectedDivision === false
        ) {
          this.props.onSubmit({
            start_at: this.state.startTime.format('YYYY-MM-DD 00:00:00'),
            end_at: this.state.endTime.format('YYYY-MM-DD 23:59:59'),
            site_ids: this.state.searchPickup.map(i => Number(i.key)),
            division_id: this.state.selectedDivision,
            department_id: this.state.getIdValueDepartment.includes('All')
              ? []
              : this.state.getIdValueDepartment,
            section_id: this.state.getIdValueSection.includes('All')
              ? []
              : this.state.getIdValueSection,
            plant_id: this.state.getIdValuePlant.includes('All')
              ? []
              : this.state.getIdValuePlant,
            company_id: this.props.auth.profile.company_id,
            data_type: val,
            display: this.state.filterSelection,
          })
        }
      },
    )
  }
  // เลือกกิจการ
  loadDivision = () => {
    let self = this
    let divisionVisibility = []
    this.setState({ divisionLoading: true })
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      divisionVisibility = orgChart.divisions.map(ele => ele.divisionNo)
    }
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        division_visibility: divisionVisibility,
        comp: [2, 3, 4, 5, 6],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState({
          divisionLoading: false,
          division: data,
        })
      })
  }
  // เลือกฝ่ายส่วน
  loadDepartment = cb => {
    let self = this
    let departmentVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      departmentVisibility = orgChart.departments.map(ele => ele)
    }
    this.setState({ departmentLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        department_visibility: departmentVisibility,
        division: [this.state.selectedDivision],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            departmentLoading: false,
            department: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }
  // เลือกแผนก
  loadSection = cb => {
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    this.setState({ sectionLoading: true })

    let departmentID = [this.state.getIdValueDepartment.toString()]

    if (this.state.getIdValueDepartment.includes('All')) {
      departmentID = this.state.department.map(x => x.id.toString())
    }
    if (this.state.getIdValueDepartment.length === 0) {
      departmentID = this.state.department.map(x => x.id.toString())
    }
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        section_visibility: sectionVisibility,
        department: departmentID,
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            sectionLoading: false,
            section: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }
  // เลือกโรงงาน
  loadPlant = cb => {
    let self = this
    let plantVisibility = []
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    plantVisibility = self.props.auth.profile.location_visibility
    this.setState({ plantLoading: true })

    let sectionID = [this.state.getIdValueSection.toString()]
    if (this.state.getIdValueSection.includes('All')) {
      sectionID = this.state.section.map(x => x.id.toString())
    }
    if (this.state.getIdValueSection.length === 0) {
      sectionID = this.state.section.map(x => x.id.toString())
    }
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantforconcretedashboard`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          location_visibility: plantVisibility,
          section_visibility: sectionVisibility,
          section: sectionID,
          relate_company: `${this.props.auth.profile.relate_company}`,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            plant: data,
            plantLoading: false,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  componentDidMount() {
    this.loadDivision()
  }
  handleValidateInput = value => {
    if (this.state.onPreview == false) return false
    if (value === null || value == '' || value.length == 0) {
      return true
    }
    return false
  }

  disabledStartDate = startTime => {
    const { endTime } = this.state
    if (!endTime) {
      return startTime && startTime > moment().endOf('day')
    }
    return (
      startTime &&
      (startTime > moment().endOf('day') ||
        startTime.valueOf() >= endTime.valueOf())
    )
  }
  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true })
    }
  }
  disabledEndDate = endTime => {
    return endTime && endTime > moment().endOf('day')
  }

  handleEndOpenChange = open => {
    this.setState({ endOpen: open })
  }

  onChange = (dates, dateStrings) => {
    console.log('From: ', dates[0], ', to: ', dates[1])
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
  }
  render() {
    const { searchData } = this.state

    return (
      <Form>
        <Row gutter={[48, 0]} type="flex" justify="center">
          <Col span={16} align="center">
            <span>
              <LangContext.Consumer>{i18n => i18n.s.site}</LangContext.Consumer>
            </span>
            <div></div>
            <Form.Item
              validateStatus={
                this.handleValidateInput(this.state.searchPickup)
                  ? 'error'
                  : null
              }
              help={
                this.handleValidateInput(this.state.searchPickup) ? (
                  <LangContext.Consumer>
                    {i18n => i18n.c.chooseSitePls}
                  </LangContext.Consumer>
                ) : null
              }
            >
              <div style={{ borderRadius: '20px' }}>
                <Select
                  allowClear={true}
                  mode="multiple"
                  labelInValue
                  value={this.state.searchPickup}
                  placeholder="Search..."
                  notFoundContent={<Spin spinning={this.state.loading} />}
                  filterOption={false}
                  style={{ width: '100%' }}
                  onSearch={debounce(value => {
                    this.queryPlaceToAutocomplete(value)
                  }, 1500)}
                  onChange={value => {
                    this.setState(
                      {
                        searchPickup: value,
                        searchData: [],
                        loading: false,
                      },
                      () => {
                        if (this.state.searchPickup.length == 0) {
                          this.setState({
                            validationSearch: true,
                          })
                        } else {
                          this.setState({
                            validationSearch: false,
                          })
                        }
                      },
                    )
                  }}
                >
                  {searchData.map(({ id, name, code }) => (
                    <Option key={id}>
                      {`(${code})`}
                      {name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={16} align="center">
            <Form.Item
              validateStatus={
                this.handleValidateInput(this.state.selectedDivision)
                  ? 'error'
                  : null
              }
              help={
                this.handleValidateInput(this.state.selectedDivision) ? (
                  <LangContext.Consumer>
                    {i18n => i18n.c.chooseBusinessPls}
                  </LangContext.Consumer>
                ) : null
              }
            >
              <span>
                <LangContext.Consumer>
                  {i18n => i18n.d.division}
                </LangContext.Consumer>
              </span>
              <br />
              <Select
                placeholder={
                  <LangContext.Consumer>
                    {i18n => i18n.c.chooseBusinessPls}
                  </LangContext.Consumer>
                }
                style={{ width: '100%' }}
                onChange={e => {
                  this.setState(
                    {
                      selectedDivision: e,
                      selectedDepartment: ['All'],
                      selectedSection: ['All'],
                      selectedPlant: ['All'],
                      validationDivision: false,
                    },
                    e => {
                      this.loadDepartment(() =>
                        this.loadSection(() => this.loadPlant()),
                      )
                    },
                  )
                }}
              >
                {this.state.division.map(ele => (
                  <Option value={ele.id}>{ele.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16} align="center">
            <Form.Item>
              <span>
                <LangContext.Consumer>
                  {i18n => i18n.d.department}
                </LangContext.Consumer>
              </span>
              <br />
              <Select
                allowClear={true}
                style={{ width: '100%' }}
                mode="multiple"
                value={this.state.selectedDepartment}
                onChange={(value, key) => {
                  let id = value
                  let mapId = key.map(a => a.key)
                  let getId = key.map(a => a.key)
                  if (value.includes('All')) {
                    if (value[0] === 'All' && value.length > 1) {
                      id = value.filter((x, i) => i !== 0)
                    } else if (value[value.length - 1] === 'All') {
                      id = ['All']
                    }
                  }
                  if (mapId.includes('All')) {
                    if (mapId[0] === 'All' && mapId.length > 1) {
                      getId = mapId.filter((x, i) => i !== 0)
                    } else if (mapId[mapId.length - 1] === 'All') {
                      getId = ['All']
                    }
                  }
                  this.setState(
                    {
                      getIdValueDepartment: getId.includes('All')
                        ? getId
                        : getId.map(Number),
                      selectedDepartment: id,
                      selectedSection: ['All'],
                      selectedPlant: ['All'],
                    },
                    e => {
                      this.loadSection(() => this.loadPlant())
                    },
                  )
                }}
              >
                <Option value={'All'} label="All" key={'All'}>
                  <LangContext.Consumer>
                    {i18n => i18n.a.all}
                  </LangContext.Consumer>
                </Option>
                {this.state.department.map(ele => (
                  <Option value={ele.name} key={ele.id}>
                    {ele.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16} align="center">
            <Form.Item>
              <span>
                <LangContext.Consumer>
                  {i18n => i18n.s.section}
                </LangContext.Consumer>
              </span>
              <br />
              <Select
                allowClear={true}
                style={{ width: '100%' }}
                mode="multiple"
                value={this.state.selectedSection}
                onChange={(value, key) => {
                  let id = value
                  let mapId = key.map(a => a.key)
                  let getId = key.map(a => a.key)
                  if (value.includes('All')) {
                    if (value[0] === 'All' && value.length > 1) {
                      id = value.filter((x, i) => i !== 0)
                    } else if (value[value.length - 1] === 'All') {
                      id = ['All']
                    }
                  }
                  if (mapId.includes('All')) {
                    if (mapId[0] === 'All' && mapId.length > 1) {
                      getId = mapId.filter((x, i) => i !== 0)
                    } else if (mapId[mapId.length - 1] === 'All') {
                      getId = ['All']
                    }
                  }
                  this.setState(
                    {
                      getIdValueSection: getId.includes('All')
                        ? getId
                        : getId.map(Number),
                      selectedSection: id,
                      selectedPlant: ['All'],
                    },
                    e => {
                      this.loadPlant()
                    },
                  )
                }}
              >
                <Option value={'All'} label="All" key={'All'}>
                  <LangContext.Consumer>
                    {i18n => i18n.a.all}
                  </LangContext.Consumer>
                </Option>
                {this.state.section.map(ele => (
                  <Option value={ele.name} key={ele.id}>
                    {ele.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={16} align="center">
            <Form.Item>
              <span>
                <LangContext.Consumer>
                  {i18n => i18n.p.plants}
                </LangContext.Consumer>
              </span>
              <br />
              <Select
                allowClear={true}
                style={{ width: '100%' }}
                mode="multiple"
                value={this.state.selectedPlant}
                onChange={(value, key) => {
                  let id = value
                  let mapId = key.map(a => a.key)
                  let getId = key.map(a => a.key)
                  // console.log('mapId', mapId)
                  if (value.includes('All')) {
                    if (value[0] === 'All' && value.length > 1) {
                      id = value.filter((x, i) => i !== 0)
                    } else if (value[value.length - 1] === 'All') {
                      id = ['All']
                    }
                  }
                  if (mapId.includes('All')) {
                    if (mapId[0] === 'All' && mapId.length > 1) {
                      getId = mapId.filter((x, i) => i !== 0)
                    } else if (mapId[mapId.length - 1] === 'All') {
                      getId = ['All']
                    }
                  }
                  this.setState(
                    {
                      getIdValuePlant: getId.includes('All')
                        ? getId
                        : getId.map(Number),
                      selectedPlant: id,
                    },
                    e => {
                      this.loadPlant()
                    },
                  )
                }}
                maxTagTextLength={40}
                maxTagCount={10}
              >
                <Option value={'All'} label="All" key={'All'}>
                  <LangContext.Consumer>
                    {i18n => i18n.a.all}
                  </LangContext.Consumer>
                </Option>
                {this.state.plant.map(ele => (
                  <Option value={ele.name} key={ele.id}>
                    {ele.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={16} align="center">
            <span>
              {' '}
              <LangContext.Consumer>
                {i18n => i18n.s.setType}
              </LangContext.Consumer>
            </span>
            <div></div>
            <Form.Item>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={value => {
                  this.setState(
                    {
                      filterSelection: value,
                    },
                    () => {},
                  )
                }}
              >
                <Option value="all" key={0}>
                  <LangContext.Consumer>
                    {i18n => i18n.a.all}
                  </LangContext.Consumer>
                </Option>
                <Option value="duration" key={1}>
                  <LangContext.Consumer>
                    {i18n => i18n.t.time}
                  </LangContext.Consumer>
                </Option>
                <Option value="distance" key={2}>
                  <LangContext.Consumer>
                    {i18n => i18n.k.km}
                  </LangContext.Consumer>
                </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={16} align="center">
            <Button
              type="primary"
              block
              onClick={() => {
                this.loadPreview('report')
              }}
            >
              <LangContext.Consumer>{i18n => i18n.s.save}</LangContext.Consumer>
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default FormDistanceReport
