import React, { Component } from 'react'
import { Menu, Divider } from 'antd'
import { autoGenKey } from '../../../lib/helper'

const { SubMenu } = Menu

const checkMenuVisibility = (list, permissions) => {
  //   console.log('list permissions', permissions)
  //   console.log('list ', list)
  if (
    typeof permissions == 'undefined' ||
    typeof permissions == 'string' ||
    permissions == []
  )
    return true

  let hideMenu = true
  for (let i = 0; i < list.length; i++) {
    let menu = list[i]

    let canAccess = permissions.find(ele => {
      return ele.matching_name == menu && ele.can_view == 1
    })

    if (typeof canAccess != 'undefined') {
      hideMenu = false
    }
  }

  return hideMenu
}

const keepArray = []

export class AutorizeMenu extends Component {
  render() {
    let { permissions, children } = this.props
    // console.log('childrenchildren', children)
    let haveChild = false
    let isSubmenu = false

    if (typeof children.length == 'number') {
      children.forEach(ele => {
        if (typeof ele.props.children.length == 'number') haveChild = true
      })
    } else {
      if (typeof children.props.children.length == 'number') haveChild = true
      if (
        typeof children.type.isMenuItem != 'undefined' &&
        children.type.isMenuItem
      )
        isSubmenu = true
      children = [children]
    }

    if (!haveChild && !isSubmenu) {
      return (
        <Menu
          mode="inline"
          onClick={({ item, key, keyPath, selectedKeys, domEvent }) => {
            this.props.handleActiveMenu(item.props.matching_name)
          }}
          openKeys={this.props.openKeysMenu}
          onOpenChange={this.props.handleonOpenChange}
        >
          <SubMenu
            className="ant-menu-submenu-focus"
            popupClassName="popup-menu-custom scroll-style"
            style={{
              display:
                checkMenuVisibility(
                  //   children.map(ele => ele.props.matching_name),

                  children.map(ele => {
                    if (
                      ele.props.web_use != 'undefined' &&
                      ele.props.web_use != '' &&
                      ele.props.web_use == ele.props.web_endpoint
                    ) {
                      return ele.props.matching_name
                    } else if (
                      ele.props.web_use != 'undefined' &&
                      ele.props.web_use == ''
                    ) {
                      return ele.props.matching_name
                    } else {
                      return ''
                    }
                  }),
                  permissions,
                ) && 'none',
            }}
            key={this.props.subkey}
            title={this.props.title}
          >
            {children.filter(ele => {
              let menu = ele.props.matching_name
              let canAccess = null
              if (
                ele.props.web_use != 'undefined' &&
                ele.props.web_use != '' &&
                ele.props.web_use == ele.props.web_endpoint
              ) {
                canAccess = permissions.find(ele => {
                  return ele.matching_name == menu && ele.can_view == 1
                })
              } else if (
                ele.props.web_use != 'undefined' &&
                ele.props.web_use == ''
              ) {
                canAccess = permissions.find(ele => {
                  return ele.matching_name == menu && ele.can_view == 1
                })
              }
              return canAccess
            })}
          </SubMenu>
        </Menu>
      )
    } else {
      let resultArr = []
      children.forEach(ele => {
        if (typeof ele.props.children.length == 'number') {
          let result = ele.props.children.map(ele1 => {
            if (
              ele1.props.web_use != 'undefined' &&
              ele1.props.web_use != '' &&
              ele1.props.web_use == ele1.props.web_endpoint
            ) {
              return ele1.props.matching_name
            } else if (
              ele1.props.web_use != 'undefined' &&
              ele1.props.web_use == ''
            ) {
              return ele1.props.matching_name
            }
          })
          result.map(ele => {
            if (typeof ele != 'undefined') {
              resultArr.push(ele)
            }
          })
        } else {
          if (
            typeof ele.props.children.type.isMenuItem != 'undefined' &&
            ele.props.children.type.isMenuItem
          ) {
            if (
              typeof ele.props.children.props.web_use != 'undefined' &&
              ele.props.children.props.web_use != '' &&
              ele.props.children.props.web_use ==
                ele.props.children.props.web_endpoint
            ) {
            } else if (
              typeof ele.props.children.props.web_use != 'undefined' &&
              ele.props.children.props.web_use == ''
            ) {
              resultArr.push(ele.props.children.props.matching_name)
            }
          } else {
            if (
              typeof ele.props.web_use != 'undefined' &&
              ele.props.web_use != '' &&
              ele.props.web_use == ele.props.web_endpoint
            ) {
              resultArr.push(ele.props.matching_name)
            } else if (
              typeof ele.props.web_use != 'undefined' &&
              ele.props.web_use == ''
            ) {
              resultArr.push(ele.props.matching_name)
            }
          }
        }
      })

      let resultChildren = children.filter(ele => {
        let subChildren = ele.props.children
        if (typeof subChildren.length != 'number') {
          if (
            typeof ele.props.children.type.isMenuItem != 'undefined' &&
            ele.props.children.type.isMenuItem
          ) {
            subChildren = [subChildren]
          } else {
            subChildren = [ele]
          }
        }
        let res = false

        subChildren.forEach(ele1 => {
          let canAccess = false

          permissions.forEach(ele2 => {
            if (
              ele2.matching_name == ele1.props.matching_name &&
              ele2.can_view == 1
            ) {
              if (
                typeof ele1.props.web_use != 'undefined' &&
                ele1.props.web_use != '' &&
                ele1.props.web_use == ele1.props.web_endpoint
              ) {
                canAccess = true
                return
              } else if (
                typeof ele1.props.web_use != 'undefined' &&
                ele1.props.web_use == ''
              ) {
                canAccess = true
                return
              } else {
                canAccess = false
                return
              }
            }
          })

          if (canAccess) {
            res = true
            return
          }
        })

        return res
      })

      resultChildren = resultChildren.map(ele => {
        let subChildren = ele.props.children
        if (typeof subChildren.length != 'number') {
          if (typeof ele.type.isSubMenu != 'undefined' && ele.type.isSubMenu) {
            subChildren = [subChildren]
          } else if (
            typeof ele.type.isMenuItem != 'undefined' &&
            ele.type.isMenuItem
          ) {
            subChildren = [ele]
          }
        }

        subChildren = subChildren.filter(ele1 => {
          let find = permissions.find(
            ele2 =>
              ele2.matching_name == ele1.props.matching_name &&
              ele2.can_view == 1,
          )

          if (typeof find != 'undefined') {
            if (
              typeof ele1.props.web_use != 'undefined' &&
              ele1.props.web_use != '' &&
              ele1.props.web_use == ele1.props.web_endpoint
            ) {
              return true
            } else if (
              typeof ele1.props.web_use != 'undefined' &&
              ele1.props.web_use == ''
            ) {
              return true
            } else {
              return false
            }
          }
          return false
        })

        return {
          ...ele,
          props: {
            ...ele.props,
            children: subChildren,
          },
        }
      })

      return (
        <Menu
          mode="inline"
          onClick={({ item, key, keyPath, selectedKeys, domEvent }) => {
            this.props.handleActiveMenu(item.props.matching_name)
          }}
          openKeys={this.props.openKeysMenu}
          onOpenChange={this.props.handleonOpenChange}
        >
          <SubMenu
            popupClassName="popup-menu-custom-set-width"
            style={{
              display: checkMenuVisibility(resultArr, permissions) && 'none',
            }}
            key={this.props.subkey}
            title={this.props.title}
          >
            {resultChildren.map(ele => {
              if (
                typeof ele.type.isSubMenu != 'undefined' &&
                ele.type.isSubMenu
              ) {
                return ele
              } else if (
                typeof ele.type.isMenuItem != 'undefined' &&
                ele.type.isMenuItem
              ) {
                return ele.props.children
              }
            })}
          </SubMenu>
        </Menu>
      )
    }
  }
}

export default { AutorizeMenu }
