import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions'
import Forms from './Forms'
import { Row, Col } from 'antd'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'

class Index extends Component {
  loadBehaviordrivingbycompany = (values, loading) => {
    values = {
      ...values,
      type_file: 'excel',
    }
    this.props.loadBehaviordrivingbycompany(values, loading)
  }

  loadVehicle = (partnerID, loading_vehicle) => {
    this.props.loadVehicle(partnerID, loading_vehicle)
  }

  loadPartner = () => {
    this.props.loadPartner()
  }

  render() {
    const {
      vehicleLists,
      companypartnerLists,
      behaviordrivingLoading,
    } = this.props.behaviordrivingbycompany
    return (
      <AuthorizeComponent
        {...this.props}
        matching_name="BehaviordrivingbycompanyReport"
      >
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}></Col>
          </Row>
          <Forms
            onSubmit={this.loadBehaviordrivingbycompany}
            vehicleLists={vehicleLists}
            behaviordrivingLoading={behaviordrivingLoading}
            loadVehicle={this.loadVehicle}
            loadPartner={this.loadPartner}
            companypartnerLists={companypartnerLists}
            auth={this.props.profile}
          />
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ behaviordrivingbycompany, auth: { profile } }) => ({
  behaviordrivingbycompany,
  profile,
})

const mapDispatchToProps = {
  loadBehaviordrivingbycompany: actions.loadBehaviordrivingbycompany.request,
  loadVehicle: actions.loadVehicle.request,
  loadPartner: actions.loadPartner.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
