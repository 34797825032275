import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
} from './../../../constants/local_storage'

function* loadDataDashborad(action) {
  const {
    data: {
      start_date,
      end_date,
      location_visibility,
      is_vendor,
      is_admin,
      company_id,
      group_by,
      filterObj,
      accesstoken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getDataIsuppyDashBorad-new`,
      {
        start_date,
        end_date,
        location_visibility,
        is_vendor,
        is_admin,
        company_id,
        filterObj,
        group_by,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadDataDashborad.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDataDashborad.failure())
  }
}

function* loadDataDashborad2(action) {
  const {
    data: {
      start_date,
      end_date,
      location_visibility,
      material_visibility,
      exactly_location,
      mill_location_list,
      plant_location_list,
      is_vendor,
      is_admin,
      company_id,
      group_by,
      filterObj,
      page,
      pageSize,
      is_saleorder_page,
      accesstoken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getDataIsuppyDashBorad-new-new`,
      {
        start_date,
        end_date,
        location_visibility,
        material_visibility,
        exactly_location,
        mill_location_list,
        plant_location_list,
        is_vendor,
        is_admin,
        company_id,
        filterObj,
        group_by,
        page,
        pageSize,
        is_saleorder_page,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadDataDashborad2.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDataDashborad2.failure())
  }
}

function* loadDataDashboradInterval(action) {
  const {
    data: {
      start_date,
      end_date,
      location_visibility,
      material_visibility,
      exactly_location,
      mill_location_list,
      plant_location_list,
      is_vendor,
      is_admin,
      company_id,
      group_by,
      filterObj,
      page,
      pageSize,
      is_saleorder_page,
      accesstoken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getDataIsuppyDashBorad-new-new`,
      {
        start_date,
        end_date,
        location_visibility,
        material_visibility,
        exactly_location,
        mill_location_list,
        plant_location_list,
        is_vendor,
        is_admin,
        company_id,
        filterObj,
        group_by,
        page,
        pageSize,
        is_saleorder_page,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadDataDashboradInterval.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDataDashboradInterval.failure())
  }
}

function* loadOngoing(action) {
  const {
    data: {
      location_visibility,
      is_vendor,
      page,
      pageSize,
      filterObj,
      company_id,
      accesstoken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/dashboard/ongoing`,
      {
        location_visibility,
        company_id,
        is_vendor,
        page,
        pageSize,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadOngoing.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadOngoing.failure())
  }
}

function* loadSuccess(action) {
  const {
    data: {
      location_visibility,
      is_vendor,
      page,
      pageSize,
      filterObj,
      company_id,
      accesstoken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/dashboard/success`,
      {
        location_visibility,
        company_id,
        is_vendor,
        page,
        pageSize,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadSuccess.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadSuccess.failure())
  }
}

function* loadAvailable(action) {
  const {
    data: {
      location_visibility,
      is_vendor,
      page,
      pageSize,
      filterObj,
      company_id,
      accesstoken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/dashboard/available`,
      {
        location_visibility,
        company_id,
        is_vendor,
        page,
        pageSize,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadAvailable.success(res.data, page))
  } catch (err) {
    yield put(actions.loadAvailable.failure())
  }
}

function* loadWorkALL(action) {
  const {
    data: {
      location_visibility,
      is_vendor,
      page,
      pageSize,
      filterObj,
      company_id,
      accesstoken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/dashboard/all`,
      {
        location_visibility,
        company_id,
        is_vendor,
        page,
        pageSize,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadWorkALL.success(res.data, page))
  } catch (err) {
    yield put(actions.loadWorkALL.failure())
  }
}

function* loadAvailableForEdit(action) {
  const {
    data: { id, accesstoken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/getfor/edit/${id}`,
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    yield put(actions.loadAvailableForEdit.success(res.data))
  } catch (err) {
    yield put(actions.loadAvailableForEdit.failure())
  }
}

function* updateDataTricket(action) {
  const {
    data: {
      id,
      do_number,
      delivery_date,
      delivery_at,
      queue_number,
      queue_at,
      material_id,
      isupply_material_name,
      quantity,
      driver_id,
      driver_name,
      vehicles_id,
      plate_no,
      vehicle_type_name,
      origin_locations_id,
      origin_locations_name,
      dest_locations_id,
      dest_locations_name,
      weight_in,
      weight_in_at,
      weight_out,
      weight_out_at,
      seal_at,
      depart_at,
      deliver_at,
      arrive_at,
      contract_shipping_rate,
      shipping_rate,
      sub_material_code,
      card_no,
      accesstoken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/edit/ticket`,
      {
        id,
        do_number,
        delivery_date,
        delivery_at,
        queue_number,
        queue_at,
        material_id,
        isupply_material_name,
        quantity,
        driver_id,
        driver_name,
        vehicles_id,
        plate_no,
        vehicle_type_name,
        origin_locations_id,
        origin_locations_name,
        dest_locations_id,
        dest_locations_name,
        weight_in,
        weight_in_at,
        weight_out,
        weight_out_at,
        seal_at,
        depart_at,
        deliver_at,
        arrive_at,
        contract_shipping_rate,
        shipping_rate,
        sub_material_code,
        card_no,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.updateDataTricket.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateDataTricket.failure())
  }
}

function* loadDataVehicleForView(action) {
  const {
    data: { id },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/dashboard/get/detail-vehicle/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadDataVehicleForView.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDataVehicleForView.failure())
  }
}

function* reCalculatorAreaNotCovered(action) {
  const {
    data: { id, accesstoken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/re-calculator-area-not-covered`,
      {
        id,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.reCalculatorAreaNotCovered.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.reCalculatorAreaNotCovered.failure())
  }
}

function* calculatorDeliveryOutsideTheArea(action) {
  const {
    data: { id, accesstoken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/calculator-delivery-outsidethearea`,
      {
        id,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.calculatorDeliveryOutsideTheArea.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.calculatorDeliveryOutsideTheArea.failure())
  }
}

function* createLogRequestChangeDestLocation(action) {
  const {
    data: {
      id,
      do_number,
      isupply_do_id,
      previous_isupply_do_id,
      location_id,
      location_name,
      previous_location_id,
      previous_location_name,
      type,
      request_by,
      request_name_by,
      request_at,
      request_note,
      approve_by,
      approve_name_by,
      approve_at,
      approve_note,
      status,
      accesstoken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/create-log-request-change-dest-location`,
      {
        id,
        do_number,
        isupply_do_id,
        previous_isupply_do_id,
        location_id,
        location_name,
        previous_location_id,
        previous_location_name,
        type,
        request_by,
        request_name_by,
        request_at,
        request_note,
        approve_by,
        approve_name_by,
        approve_at,
        approve_note,
        status,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.createLogRequestChangeDestLocation.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createLogRequestChangeDestLocation.failure())
  }
}

function* isupplyDashboardCloseWork(action) {
  const {
    data: { isupply_do_id, problem, comment, close_by, accesstoken },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-dashboard-close-work`,
      {
        isupply_do_id,
        problem,
        comment,
        close_by,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.isupplyDashboardCloseWork.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.isupplyDashboardCloseWork.failure())
  }
}

export default function* watchIsupplyDashboard() {
  yield all([
    takeEvery(actions.LOAD_ONGOING.REQUEST, loadOngoing),
    takeEvery(actions.LOAD_SUCCESS.REQUEST, loadSuccess),
    takeEvery(actions.LOAD_AVALIABLE.REQUEST, loadAvailable),
    takeEvery(actions.LOAD_ALL.REQUEST, loadWorkALL),
    takeEvery(actions.LOAD_AVALIABLE_FOR_EDIT.REQUEST, loadAvailableForEdit),
    takeEvery(actions.UPDATE_DATA_TRICKET.REQUEST, updateDataTricket),
    takeEvery(
      actions.LOAD_DATA_VEHICLE_FOR_VIEW.REQUEST,
      loadDataVehicleForView,
    ),
    takeEvery(actions.NEW_LOAD_DATA_DASHBORAD.REQUEST, loadDataDashborad),
    takeEvery(actions.NEW_LOAD_DATA_DASHBORAD2.REQUEST, loadDataDashborad2),
    takeEvery(
      actions.RECALCULATOR_AREA_NOT_COVERED.REQUEST,
      reCalculatorAreaNotCovered,
    ),
    takeEvery(
      actions.CALCULATOR_DELIVERRY_OUTSIDE_THE_AREA.REQUEST,
      calculatorDeliveryOutsideTheArea,
    ),
    takeEvery(
      actions.CREATE_LOG_REQUEST_CHANGE_DEST_LOCATION.REQUEST,
      createLogRequestChangeDestLocation,
    ),
    takeEvery(
      actions.NEW_LOAD_DATA_DASHBORAD_INTERVAL.REQUEST,
      loadDataDashboradInterval,
    ),
    takeEvery(
      actions.ISUPPLY_DASHBOARD_CLOSE_WORK.REQUEST,
      isupplyDashboardCloseWork,
    ),
  ])
}

export {
  loadOngoing,
  loadSuccess,
  loadAvailable,
  loadWorkALL,
  loadAvailableForEdit,
  loadDataVehicleForView,
  updateDataTricket,
  loadDataDashborad,
  loadDataDashborad2,
  loadDataDashboradInterval,
  reCalculatorAreaNotCovered,
  calculatorDeliveryOutsideTheArea,
  createLogRequestChangeDestLocation,
  isupplyDashboardCloseWork,
}
