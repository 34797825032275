import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import difference from 'lodash/difference'
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Transfer,
  Checkbox,
  Dropdown,
  Menu,
  Icon,
  TimePicker,
  InputNumber,
  Table,
  Tooltip,
} from 'antd'

import LangContext, { i18n } from 'modules/shared/context/langContext'
import BasicFilterReport from '../../basicFilterReport/Index'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
const format = 'HH:mm'
const InputGroup = Input.Group

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
        }

        return (
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 50 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    clickMenu: '',
  }

  leftTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <div className="regular-bold-14">
          <LangContext.Consumer>{i18n => i18n.i.itemLeft}</LangContext.Consumer>
        </div>
      ),
      posiction: 'Left',
    },
  ]

  rightTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <div className="regular-bold-14">
          <LangContext.Consumer>
            {i18n => i18n.i.itemSelected}
          </LangContext.Consumer>
        </div>
      ),
      posiction: 'Right',
    },
  ]

  componentDidMount() {
    const { call } = this.props

    if (call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    setFieldValue('vehicle_type_id', value)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleList } = nextProps
    const vehlist = []
    vehicleList.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    if (targetKeys.length > 50) {
      message.error('เลือกรถได้ไม่เกิน 50 คัน')
    } else {
      setFieldValue('list_vehicle_id', targetKeys)
      this.setState({ vehicleSelect: targetKeys })
    }
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleClicReportkPreview = values => {
    const COMPANY_ID = this.props.auth.company_id
    const VEHICLE_VISIBILITY = this.props.auth.vehicle_visibility
    const USER_ID = this.props.auth.id

    const date_start =
      moment(values.eventdate[0]['_d']).format('YYYY-MM-DD') + ' 00:00:00'
    const date_end =
      moment(values.eventdate[1]['_d']).format('YYYY-MM-DD') + ' 23:59:00'

    const engine_on_time_start_at_new = moment(
      values.engine_on_time_start_at,
    ).format('HH:mm')
    const engine_on_time_end_at_new = moment(
      values.engine_on_time_end_at,
    ).format('HH:mm')

    const filter = {
      date_start,
      date_end,
      list_vehicle_id: values.list_vehicle_id,
      company_id: COMPANY_ID,
      vehicle_type_id: values.vehicle_type_id,
      user_id: USER_ID,
      type_file: 'preview',
      vehicle_visibility: VEHICLE_VISIBILITY,
      engine_on_time_start_at: engine_on_time_start_at_new,
      engine_on_time_end_at: engine_on_time_end_at_new,
      max_speed: 0,
    }

    window.open('/report/truckengineon/preview', '_blank')
    localStorage.setItem('truckengineonreport-filter', JSON.stringify(filter))
  }

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      truckengineonLoading,
      onSubmit,
    } = this.props
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }

    return (
      <div>
        <Spin spinning={truckengineonLoading}>
          <LangContext.Consumer>
            {i18n => (
              <Formik
                initialValues={{
                  vehicle_type_id: '',
                  eventdate: [
                    moment('00:00', 'HH:mm'),
                    moment('23:59', 'HH:mm'),
                  ],
                  list_vehicle_id: [],
                  engine_on_time_start_at: moment('00:00', format),
                  engine_on_time_end_at: moment('23:59', format),
                  max_speed: 0,
                  include_engine_off: 0,
                  division: this.props.selectedDivision,
                  department: this.props.selectedDepartment,
                  section: this.props.selectedSection,
                  plant: this.props.selectedPlant,
                  vehicleGroup: this.props.selectedVehicleGroup,
                }}
                validate={values => {
                  let errors = {}
                  if (values.eventdate.length > 0) {
                    const date_start = moment(values.eventdate[0]['_d'])
                    const date_end = moment(values.eventdate[1]['_d'])
                    let day_diff = date_end.diff(date_start, 'days') + 1
                    if (day_diff > 3) {
                      errors.eventdate = ''`${i18n.d.dateRangeNotBeMoreThan3Days}`
                    }
                  }

                  if (values.list_vehicle_id.length > 50) {
                    errors.list_vehicle_id = (
                      <LangContext.Consumer>
                        {i18n => i18n.o.over50}
                      </LangContext.Consumer>
                    )
                  }

                  return errors
                }}
                validationSchema={yup.object().shape({
                  eventdate: yup
                    .array()
                    .required(`${i18n.p.pleaseChooseDateRange}`),
                  list_vehicle_id: yup
                    .array()
                    .required(`${i18n.p.pleaseChooseVehicle}`),
                  engine_on_time_start_at: yup
                    .string()
                    .required(`${i18n.p.PleaseEnterInitialStartTime}`)
                    .nullable(),
                  engine_on_time_end_at: yup
                    .string()
                    .required(`${i18n.p.PleaseEnterInitialEndTime}`)
                    .nullable(),
                })}
                onSubmit={values => {
                  const loading = true
                  if (this.state.clickMenu === 'preview') {
                    this.handleClicReportkPreview(values)
                  } else {
                    onSubmit(values, loading)
                  }
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                }) => (
                  <Row type="flex" justify="center">
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        {' '}
                        <LangContext.Consumer>
                          {i18n => i18n.t.TruckengineonReport}
                        </LangContext.Consumer>
                      </div>
                      <BasicFilterReport
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                        division={this.props.division}
                        updateSelectDivision={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectDivision(e, values)
                          })
                        }}
                        department={this.props.department}
                        updateSelectDepartment={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectDepartment(e, values)
                          })
                        }}
                        section={this.props.section}
                        updateSelectSection={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectSection(e, values)
                          })
                        }}
                        plant={this.props.plant}
                        updateSelectPlant={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectPlant(e, values)
                          })
                        }}
                        vehicleGroup={this.props.vehicleGroup}
                        updateSelectVehicleGroup={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectVehicleGroup(e, values)
                          })
                        }}
                      />
                      <FormItem
                        required={true}
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'warning'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div style={{ width: '750px' }}>
                          <TableTransfer
                            dataSource={this.props.vehicleList}
                            targetKeys={this.state.vehicleSelect}
                            showSearch
                            selectedKeys={this.state.selectedKeys}
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                            onSelectChange={this.handleSelectChange}
                            onChange={value =>
                              this.tranferChange(value, setFieldValue)
                            }
                            leftColumns={this.leftTableColumns}
                            rightColumns={this.rightTableColumns}
                          />
                        </div>
                      </FormItem>

                      {this.props.call !== 'schedule' ? (
                        <>
                          {' '}
                          <Row>
                            <Col span={7}>
                              <Form.Item
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.e.engine_on_time_start_at}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.engine_on_time_start_at &&
                                  errors.engine_on_time_start_at &&
                                  'warning'
                                }
                                help={
                                  touched.engine_on_time_start_at &&
                                  errors.engine_on_time_start_at
                                }
                              >
                                <TimePicker
                                  onChange={value =>
                                    setFieldValue(
                                      'engine_on_time_start_at',
                                      value,
                                    )
                                  }
                                  defaultValue={moment('00:00', format)}
                                  format={format}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.e.engine_on_time_end_at}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.engine_on_time_end_at &&
                                  errors.engine_on_time_end_at &&
                                  'warning'
                                }
                                help={
                                  touched.engine_on_time_start_at &&
                                  errors.engine_on_time_end_at
                                }
                              >
                                <TimePicker
                                  onChange={value =>
                                    setFieldValue(
                                      'engine_on_time_end_at',
                                      value,
                                    )
                                  }
                                  defaultValue={moment('23:59', format)}
                                  format={format}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item
                            label={
                              <LangContext.Consumer>
                                {i18n => i18n.d.dateRange}
                              </LangContext.Consumer>
                            }
                            validateStatus={
                              touched.eventdate && errors.eventdate && 'warning'
                            }
                            help={touched.eventdate && errors.eventdate}
                          >
                            <RangePicker
                              disabledDate={disabledDate}
                              onChange={value =>
                                setFieldValue('eventdate', value)
                              }
                              format="DD/MM/YYYY"
                              defaultValue={[
                                moment('00:00', 'HH:mm'),
                                moment('23:59', 'HH:mm'),
                              ]}
                            />
                          </Form.Item>
                        </>
                      ) : (
                        ''
                      )}
                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call == 'schedule' ? (
                            <Col span={8} style={{ marginTop: '10px' }}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            <>
                              {' '}
                              <Col span={8}>
                                <Button type="defualt" block>
                                  <Link to="/">
                                    <LangContext.Consumer>
                                      {i18n => i18n.c.cancel}
                                    </LangContext.Consumer>
                                  </Link>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  block
                                  onClick={() =>
                                    this.setState({
                                      clickMenu: 'preview',
                                    })
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.preview}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  onClick={() => {
                                    this.setState({
                                      clickMenu: 'report',
                                    })
                                  }}
                                  block
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.r.report}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                            </>
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  </Row>
                )}
              />
            )}
          </LangContext.Consumer>
        </Spin>
      </div>
    )
  }
}
