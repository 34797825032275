import { createTypes, createAction } from 'lib/action'

const LOAD_BEHAVIORDRIVING = createTypes('behaviordriving', 'load')
const LOAD_BEHAVIORDRIVING_PREVIEW = createTypes(
  'behaviordrivingpreview',
  'load',
)
const LOAD_VEHICLE_BEHAVIORDRIVING = createTypes(
  'vehicle_behaviordriving',
  'load',
)

const loadBehaviordriving = {
  request: (data, loading) =>
    createAction(LOAD_BEHAVIORDRIVING.REQUEST, { data, loading }),
  success: data => createAction(LOAD_BEHAVIORDRIVING.SUCCESS, { data }),
  failure: () => createAction(LOAD_BEHAVIORDRIVING.FAILURE),
}

const loadBehaviordrivingpreview = {
  request: (data, loading) =>
    createAction(LOAD_BEHAVIORDRIVING_PREVIEW.REQUEST, { data, loading }),
  success: data => createAction(LOAD_BEHAVIORDRIVING_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_BEHAVIORDRIVING_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_BEHAVIORDRIVING.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_BEHAVIORDRIVING.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_BEHAVIORDRIVING.FAILURE),
}

export {
  loadBehaviordriving,
  LOAD_BEHAVIORDRIVING,
  loadBehaviordrivingpreview,
  LOAD_BEHAVIORDRIVING_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_BEHAVIORDRIVING,
}
