import {
  LOAD_VEHICLEPERFORMANCECONCLUDE,
  LOAD_VEHICLEPERFORMANCECONCLUDE_PREVIEW,
  LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE,
  LOAD_VEHICLE_TYPE_VEHICLEPERFORMANCECONCLUDE,
} from './actions'

const initialState = {
  vehicleperformanceconcludeUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  vehicleperformanceconcludeLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VEHICLEPERFORMANCECONCLUDE.REQUEST:
    case LOAD_VEHICLEPERFORMANCECONCLUDE_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        vehicleperformanceconcludeLoading: loading,
      }
    case LOAD_VEHICLEPERFORMANCECONCLUDE.SUCCESS:
    case LOAD_VEHICLEPERFORMANCECONCLUDE_PREVIEW.SUCCESS:
      return {
        ...state,
        vehicleperformanceconcludekUrl: action.payload.data.data,
        vehicleperformanceconcludeLoading: false,
      }
    case LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        vehicleperformanceconcludeLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        vehicleperformanceconcludeLoading: false,
      }
    case LOAD_VEHICLE_TYPE_VEHICLEPERFORMANCECONCLUDE.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
