import { createTypes, createAction } from 'lib/action'
const LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_GENERAL_DATA = createTypes(
  'vehiclevisibilitypermissiongeneraldata',
  'load',
)

const loadManagementVehicleVisibilityPermissionGeneralData = {
  request: (data, loading) => {
    return createAction(
      LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_GENERAL_DATA.REQUEST,
      {
        data,
        loading,
      },
    )
  },
  success: (data, page) => {
    return createAction(
      LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_GENERAL_DATA.SUCCESS,
      {
        data,
        page,
      },
    )
  },
  failure: () =>
    createAction(
      LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_GENERAL_DATA.FAITLURE,
    ),
}

export {
  loadManagementVehicleVisibilityPermissionGeneralData,
  LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_GENERAL_DATA,
}
