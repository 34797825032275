import React from 'react'
import { Form, Select } from 'antd'
import LangContext from 'modules/shared/context/langContext'

const Option = Select.Option

const Index = ({
  values,
  setFieldValue,
  touched,
  errors,

  division,
  updateSelectDivision,
  department,
  updateSelectDepartment,
  section,
  updateSelectSection,
  plant,
  updateSelectPlant,
  vehicleGroup,
  updateSelectVehicleGroup,
  hasVehicleGroup,
  hasPlant,
}) => {
  return (
    <LangContext.Consumer>
      {i18n => (
        <>
          <Form.Item
            label={
              <LangContext.Consumer>
                {i18n => i18n.d.division}
              </LangContext.Consumer>
            }
          >
            <Select
              name="division"
              id="division"
              style={{ width: '100%' }}
              value={values.division}
              onChange={e => {
                // console.log(e)
                setFieldValue('division', e)
                setFieldValue('department', 'all')
                setFieldValue('section', 'all')
                setFieldValue('plant', ['all'])
                setFieldValue('list_plant_id', ['all'])
                setFieldValue('list_vehicle_id', [])
                updateSelectDivision(e)
              }}
            >
              <Option value="all">
                <LangContext.Consumer>
                  {i18n => i18n.a.all}
                </LangContext.Consumer>
              </Option>
              {division.map(ele => (
                <Option value={ele.id}>{ele.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <LangContext.Consumer>
                {i18n => i18n.d.department}
              </LangContext.Consumer>
            }
          >
            <Select
              name="department"
              id="department"
              value={values.department}
              onChange={e => {
                setFieldValue('department', e)
                setFieldValue('section', 'all')
                setFieldValue('plant', ['all'])
                setFieldValue('list_plant_id', ['all'])
                setFieldValue('list_vehicle_id', [])
                updateSelectDepartment(e)
              }}
              style={{ width: '100%' }}
              defaultValue="all"
            >
              <Option value="all">
                <LangContext.Consumer>
                  {i18n => i18n.a.all}
                </LangContext.Consumer>
              </Option>
              {department.map(ele => (
                <Option value={ele.id}>{ele.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <LangContext.Consumer>
                {i18n => i18n.s.section}
              </LangContext.Consumer>
            }
          >
            <Select
              name="section"
              id="section"
              value={values.section}
              onChange={e => {
                setFieldValue('section', e)
                setFieldValue('list_vehicle_id', [])
                setFieldValue('plant', ['all'])
                setFieldValue('list_plant_id', ['all'])
                updateSelectSection(e)
              }}
              style={{ width: '100%' }}
              defaultValue="all"
            >
              <Option value="all">
                <LangContext.Consumer>
                  {i18n => i18n.a.all}
                </LangContext.Consumer>
              </Option>
              {section.map(ele => (
                <Option value={ele.id}>{ele.name}</Option>
              ))}
            </Select>
          </Form.Item>
          {typeof hasPlant == 'undefined' && (
            <Form.Item
              label={
                <LangContext.Consumer>
                  {i18n => i18n.p.plants}
                </LangContext.Consumer>
              }
            >
              <Select
                style={{ width: '100%' }}
                defaultValue="all"
                mode="multiple"
                onChange={e => {
                  let val = e
                  if (e.length > 1 && e.includes('all')) {
                    val = e.filter((x, i) => (x === 'all' && i === 0) === false)
                  }

                  if (e.length === 0) {
                    val = ['all']
                  }

                  if (val.length > 1 && e.includes('all')) {
                    val = e.filter(x => x === 'all')
                  }

                  setFieldValue('list_vehicle_id', [])
                  setFieldValue('plant', val)
                  setFieldValue('list_plant_id', val)
                  updateSelectPlant(val)
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={values.plant}
              >
                <Option value="all">
                  <LangContext.Consumer>
                    {i18n => i18n.a.all}
                  </LangContext.Consumer>
                </Option>
                {plant.map(ele => (
                  <Option value={`${ele.id}`} key={ele.id}>
                    {ele.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {typeof hasVehicleGroup == 'undefined' && (
            <Form.Item
              label={
                <LangContext.Consumer>
                  {i18n => i18n.v.vehicleGroup}
                </LangContext.Consumer>
              }
              validateStatus={
                touched.vehicle_type_id && errors.vehicle_type_id && 'error'
              }
              help={touched.vehicle_type_id && errors.vehicle_type_id}
            >
              <Select
                name="vehicleGroup"
                id="vehicleGroup"
                onChange={e => {
                  setFieldValue('list_vehicle_id', [])
                  setFieldValue('vehicleGroup', e)
                  updateSelectVehicleGroup(e)
                }}
                defaultValue="all"
                value={values.vehicleGroup}
              >
                <Option value="all">
                  <LangContext.Consumer>
                    {i18n => i18n.a.all}
                  </LangContext.Consumer>
                </Option>
                {vehicleGroup.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </>
      )}
    </LangContext.Consumer>
  )
}

export default Index
