import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import { getlocalstorage } from './../../../constants/local_storage'

function* loadMasterVehicleContract(action) {
  const {
    data: {
      page,
      pageSize,
      orderType,
      orderBy,
      vehicle_visibility,
      accessToken,
      type,
      filterObj,
    },
    loading,
    cb,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contract/vehicle`,
      {
        page,
        pageSize,
        orderType,
        orderBy,
        vehicle_visibility,
        type,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb()

    if (type == 'csv') {
      yield put(actions.loadMasterVehicleContract.success(res.data, page))
      window.location.assign(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
      )
    } else {
      yield put(actions.loadMasterVehicleContract.success(res.data, page))
    }
  } catch (err) {
    console.log(err.message)
    cb()
    yield put(actions.loadMasterVehicleContract.failure())
  }
}

function* updateInstallationStatusVehicleContract(action) {
  const {
    data: { installationStatus, vehicleId, accessToken },
    cb,
  } = action.payload

  try {
    const res = yield call(
      axios.put,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contract/vehicle/installation-status/${vehicleId}`,
      {
        installationStatus,
        matching_name: 'vehicle-contract',
        action_name: 'edit',
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb()
    yield put(
      actions.updateInstallationStatusVehicleContract.success(
        res.data.status,
        res.data.msg,
      ),
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateInstallationStatusVehicleContract.failure())
  }
}

function* updateUsageStatusVehicleContract(action) {
  const {
    data: { usageStatus, vehicleId, accessToken },
    cb,
  } = action.payload

  try {
    const res = yield call(
      axios.put,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contract/vehicle/usage-status/${vehicleId}`,
      {
        usageStatus,
        matching_name: 'vehicle-contract',
        action_name: 'edit',
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb()
    yield put(
      actions.updateInstallationStatusVehicleContract.success(
        res.data.status,
        res.data.msg,
      ),
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateInstallationStatusVehicleContract.failure())
  }
}

function* updateRemarkVehicleContract(action) {
  const {
    data: { remark, vehicleId, accessToken },
    cb,
  } = action.payload

  try {
    const res = yield call(
      axios.put,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contract/vehicle/remark/${vehicleId}`,
      {
        remark,
        matching_name: 'vehicle-contract',
        action_name: 'edit',
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb()
    yield put(
      actions.updateRemarkVehicleContract.success(
        res.data.status,
        res.data.msg,
      ),
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateRemarkVehicleContract.failure())
  }
}

function* loadMasterVehicleContractById(action) {
  const {
    data: { vehicleId, accessToken },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contract/vehicle/${vehicleId}`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadMasterVehicleContractById.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterVehicleContractById.failure())
  }
}

function* importVehicleContract(action) {
  const {
    file: { file, vehicle_visibility, accessToken },
    cb,
  } = action.payload
  try {
    const formData = new FormData()
    formData.append('files', file)
    formData.append('vehicle_visibility', vehicle_visibility)
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contract/vehicle/import`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    cb()
    yield put(actions.importVehicleContract.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.importVehicleContract.failure())
  }
}

function* updateVehicleContractById(action) {
  const {
    data: { dataForEdit, vehicleId, accessToken },
    cb,
  } = action.payload

  try {
    const res = yield call(
      axios.put,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contract/vehicle/${vehicleId}`,
      dataForEdit,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb(res.data)
    yield put(
      actions.updateVehicleContractById.success(res.data.status, res.data.msg),
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateVehicleContractById.failure())
  }
}

function* loadGpsVendor(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvendor`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadGpsVendor.success(res.data))
  } catch (err) {
    yield put(actions.loadGpsVendor.failure())
  }
}

function* loadProvinceForVehicleContract(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getprovincesforvehicle`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadProvinceForVehicleContract.success(res.data))
  } catch (err) {
    yield put(actions.loadProvinceForVehicleContract.failure())
  }
}

export default function* watchVehicleContract() {
  yield all([
    takeEvery(
      actions.LOAD_MASTERDATA_VEHICLE_CONTRACT.REQUEST,
      loadMasterVehicleContract,
    ),

    takeEvery(
      actions.UPDATE_INSTALLATION_STATUS_VEHICLE_CONTRACT.REQUEST,
      updateInstallationStatusVehicleContract,
    ),
    takeEvery(
      actions.UPDATE_USAGE_STATUS_VEHICLE_CONTRACT.REQUEST,
      updateUsageStatusVehicleContract,
    ),
    takeEvery(
      actions.UPDATE_REMARK_VEHICLE_CONTRACT.REQUEST,
      updateRemarkVehicleContract,
    ),
    takeEvery(
      actions.LOAD_MASTERDATA_VEHICLE_CONTRACT_BY_ID.REQUEST,
      loadMasterVehicleContractById,
    ),
    takeEvery(actions.IMPORT_VEHICLE_CONTRACT.REQUEST, importVehicleContract),
    takeEvery(
      actions.UPDATE_VEHICLE_CONTRACT_BY_VEHICLE_ID.REQUEST,
      updateVehicleContractById,
    ),
    takeEvery(
      actions.LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.REQUEST,
      loadGpsVendor,
    ),
    takeEvery(
      actions.LOAD_PROVINCE_VEHICLE_CONTRACT.REQUEST,
      loadProvinceForVehicleContract,
    ),
  ])
}

export {
  loadMasterVehicleContract,
  loadGpsVendor,
  loadProvinceForVehicleContract,
  updateInstallationStatusVehicleContract,
  updateUsageStatusVehicleContract,
  updateRemarkVehicleContract,
  loadMasterVehicleContractById,
  importVehicleContract,
  updateVehicleContractById,
}
