import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import difference from 'lodash/difference'
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Transfer,
  Checkbox,
  Dropdown,
  Menu,
  Icon,
  Table,
  Tooltip,
} from 'antd'

import LangContext, { i18n } from 'modules/shared/context/langContext'
import BasicFilterReport from '../../basicFilterReport/Index'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
        }

        return (
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 50 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

export default class Forms extends Component {
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    data: [],
    loadDataPreview: false,
  }

  leftTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <div className="regular-bold-14">
          <LangContext.Consumer>{i18n => i18n.i.itemLeft}</LangContext.Consumer>
        </div>
      ),
      posiction: 'Left',
    },
  ]

  rightTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <div className="regular-bold-14">
          <LangContext.Consumer>
            {i18n => i18n.i.itemSelected}
          </LangContext.Consumer>
        </div>
      ),
      posiction: 'Right',
    },
  ]

  componentDidMount() {
    const { call } = this.props

    if (call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    setFieldValue('vehicle_type_id', value)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleList } = nextProps
    const vehlist = []
    vehicleList.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    if (targetKeys.length > 50) {
      message.error('เลือกรถได้ไม่เกิน 50 คัน')
    } else {
      setFieldValue('list_vehicle_id', targetKeys)
      this.setState({ vehicleSelect: targetKeys })
    }
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleMenuClick = (setFieldValue, handleSubmit, typeFile) => {
    setFieldValue('type_file', typeFile, false)
    handleSubmit()
  }

  handleClicReportkPreview = values => {
    let self = this
    let data = []
    let companyID = this.props.auth.profile.company_id
    const date_start = moment(values.eventdate[0]['_d']).format(
      'YYYY-MM-DD HH:mm:ss',
    )
    const date_end = moment(values.eventdate[1]['_d']).format(
      'YYYY-MM-DD HH:mm:ss',
    )

    const filter = {
      date_start,
      date_end,
      list_vehicle_id: values.list_vehicle_id,
      company_id: companyID,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
      type_file: 'preview',
    }

    const header = {
      headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
    }

    window.open('/report/tripbyengineoffon/preview', '_blank')
    localStorage.setItem(
      'tripbyengineoffonreport-filter',
      JSON.stringify(filter),
    )
    localStorage.setItem(
      'tripbyengineoffonreport-header',
      JSON.stringify(header),
    )
  }

  render() {
    const { vehicleTypeLists, tripbyengineoffonLoading, onSubmit } = this.props
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <Spin spinning={tripbyengineoffonLoading}>
          <Spin spinning={this.state.loadDataPreview}>
            <LangContext.Consumer>
              {i18n => (
                <Formik
                  initialValues={{
                    vehicle_type_id: '',
                    eventdate: [
                      moment('00:00:00', 'HH:mm:ss'),
                      moment('23:59:59', 'HH:mm:ss'),
                    ],
                    list_vehicle_id: [],
                    division: this.props.selectedDivision,
                    department: this.props.selectedDepartment,
                    section: this.props.selectedSection,
                    plant: this.props.selectedPlant,
                    vehicleGroup: this.props.selectedVehicleGroup,
                  }}
                  validate={values => {
                    let errors = {}
                    if (values.eventdate.length > 0) {
                      const date_start = moment(values.eventdate[0]['_d'])
                      const date_end = moment(values.eventdate[1]['_d'])
                      let day_diff = date_end.diff(date_start, 'days') + 1
                      if (day_diff > 3) {
                        errors.eventdate = `${i18n.d.dateRangeNotBeMoreThan3Days}`
                      }
                    }

                    if (values.list_vehicle_id.length > 50) {
                      errors.list_vehicle_id = (
                        <LangContext.Consumer>
                          {i18n => i18n.o.over50}
                        </LangContext.Consumer>
                      )
                    }

                    return errors
                  }}
                  validationSchema={yup.object().shape({
                    eventdate: yup
                      .array()
                      .required(`${i18n.p.pleaseChooseDateRange}`),
                    list_vehicle_id: yup
                      .array()
                      .required(`${i18n.p.pleaseChooseVehicle}`),
                  })}
                  onSubmit={values => {
                    if (values.type_file === 'preview') {
                      this.handleClicReportkPreview(values)
                    } else {
                      const loading = true
                      onSubmit(values, loading)
                    }
                  }}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleFocus,
                    setFieldValue,
                    isValidating,
                  }) => (
                    <Row type="flex" justify="center">
                      <Form onSubmit={handleSubmit}>
                        <div className="semi-bold-16">
                          {' '}
                          <LangContext.Consumer>
                            {i18n => i18n.t.TripbyengineoffonReport}
                          </LangContext.Consumer>
                        </div>
                        <BasicFilterReport
                          setFieldValue={setFieldValue}
                          values={values}
                          touched={touched}
                          errors={errors}
                          division={this.props.division}
                          updateSelectDivision={e => {
                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('list_vehicle_id', [])
                              this.props.updateSelectDivision(e, values)
                            })
                          }}
                          department={this.props.department}
                          updateSelectDepartment={e => {
                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('list_vehicle_id', [])
                              this.props.updateSelectDepartment(e, values)
                            })
                          }}
                          section={this.props.section}
                          updateSelectSection={e => {
                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('list_vehicle_id', [])
                              this.props.updateSelectSection(e, values)
                            })
                          }}
                          plant={this.props.plant}
                          updateSelectPlant={e => {
                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('list_vehicle_id', [])
                              this.props.updateSelectPlant(e, values)
                            })
                          }}
                          vehicleGroup={this.props.vehicleGroup}
                          updateSelectVehicleGroup={e => {
                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('list_vehicle_id', [])
                              this.props.updateSelectVehicleGroup(e, values)
                            })
                          }}
                        />
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.v.vehicle}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.list_vehicle_id &&
                            errors.list_vehicle_id &&
                            'warning'
                          }
                          help={
                            touched.list_vehicle_id && errors.list_vehicle_id
                          }
                        >
                          <div style={{ width: '750px' }}>
                            <TableTransfer
                              dataSource={this.props.vehicleList}
                              targetKeys={this.state.vehicleSelect}
                              selectedKeys={this.state.selectedKeys}
                              showSearch
                              filterOption={(inputValue, item) => {
                                return (
                                  item.name
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                                )
                              }}
                              onSelectChange={this.handleSelectChange}
                              onChange={value =>
                                this.tranferChange(value, setFieldValue)
                              }
                              leftColumns={this.leftTableColumns}
                              rightColumns={this.rightTableColumns}
                            />
                          </div>
                        </FormItem>
                        {this.props.call !== 'schedule' ? (
                          <Form.Item
                            label={
                              <LangContext.Consumer>
                                {i18n => i18n.d.dateRange}
                              </LangContext.Consumer>
                            }
                            validateStatus={
                              touched.eventdate && errors.eventdate && 'warning'
                            }
                            help={touched.eventdate && errors.eventdate}
                            required={true}
                          >
                            <RangePicker
                              disabledDate={disabledDate}
                              showTime={{ format: 'HH:mm:ss' }}
                              onChange={value =>
                                setFieldValue('eventdate', value)
                              }
                              format="DD/MM/YYYY HH:mm:ss"
                              placeholder={['Start Time', 'End Time']}
                              defaultValue={[
                                moment('00:00:00', 'HH:mm:ss'),
                                moment('23:59:59', 'HH:mm:ss'),
                              ]}
                            />
                          </Form.Item>
                        ) : (
                          ''
                        )}
                        <FormItem>
                          <Row gutter={24}>
                            {this.props.call == 'schedule' ? (
                              <Col span={8} style={{ marginTop: '10px' }}>
                                <Button type="primary" htmlType="submit" block>
                                  <LangContext.Consumer>
                                    {i18n => i18n.s.save}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                            ) : (
                              <div>
                                {' '}
                                <Col span={8}>
                                  <Button type="defualt" block>
                                    <Link to="/">
                                      <LangContext.Consumer>
                                        {i18n => i18n.c.cancel}
                                      </LangContext.Consumer>
                                    </Link>
                                  </Button>
                                </Col>
                                <Col span={8}>
                                  <Button
                                    type="primary"
                                    onClick={() =>
                                      this.handleMenuClick(
                                        setFieldValue,
                                        handleSubmit,
                                        'preview',
                                      )
                                    }
                                    block
                                  >
                                    <LangContext.Consumer>
                                      {i18n => i18n.p.preview}
                                    </LangContext.Consumer>
                                  </Button>
                                </Col>
                                <Col span={8}>
                                  <Button
                                    type="primary"
                                    onClick={() =>
                                      this.handleMenuClick(
                                        setFieldValue,
                                        handleSubmit,
                                        'excel',
                                      )
                                    }
                                    block
                                  >
                                    <LangContext.Consumer>
                                      {i18n => i18n.r.report}
                                    </LangContext.Consumer>
                                  </Button>
                                </Col>
                              </div>
                            )}
                          </Row>
                        </FormItem>
                      </Form>
                    </Row>
                  )}
                />
              )}
            </LangContext.Consumer>
          </Spin>
        </Spin>
      </div>
    )
  }
}
