import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Table,
  message,
  Tooltip,
} from 'antd'

import LangContext, { i18n } from 'modules/shared/context/langContext'
import difference from 'lodash/difference'
import BasicFilterReport from '../../basicFilterReport/Index'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
        }

        return (
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 15 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    vehicleSelectedKeys: [],
    selectedKeys: [],
    clickMenu: '',
    search_by: 'vehicle_group',
  }

  leftTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <LangContext.Consumer>{i18n => i18n.i.itemLeft}</LangContext.Consumer>
      ),
      posiction: 'Left',
    },
  ]

  rightTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <LangContext.Consumer>
          {i18n => i18n.i.itemSelected}
        </LangContext.Consumer>
      ),
      posiction: 'Right',
    },
  ]

  componentDidMount() {
    const {
      loadVehicleIsNotBusiness,
      loadVehicleType,
      loadPlantIsNotBusiness,
      call,
    } = this.props

    if (call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
        })
      }
    }

    loadVehicleIsNotBusiness('', false)
    loadVehicleType()
    loadPlantIsNotBusiness()
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  onVehicleTypeChange = (value, vehicle_type_name, setFieldValue) => {
    const { loadVehicleIsNotBusiness } = this.props
    setFieldValue('vehicle_type_id', value)
    setFieldValue('vehicle_type_name', vehicle_type_name)
    loadVehicleIsNotBusiness(value, true)
    this.setState({
      vehicleSelect: [],
      vehicleList: [],
      vehicleSelectedKeys: [],
      selectedKeys: [],
    })
    setFieldValue('list_vehicle_id', [])
  }

  onPlantChange = (value, plant_name, setFieldValue) => {
    const { loadVehicleByPlantID } = this.props
    setFieldValue('plant_id', value)
    setFieldValue('plant_name', plant_name)
    loadVehicleByPlantID(value, true)
    this.setState({
      vehicleSelect: [],
      vehicleList: [],
      vehicleSelectedKeys: [],
      selectedKeys: [],
    })
    setFieldValue('list_vehicle_id', [])
  }

  onBusinessChange = (value, business_name, setFieldValue) => {
    setFieldValue('business_id', value)
    setFieldValue('business_name', business_name)
    this.setState({
      vehicleSelect: [],
      vehicleList: [],
      vehicleSelectedKeys: [],
      selectedKeys: [],
    })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleLists } = nextProps
    const vehlist = []
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    if (targetKeys.length > 15) {
      message.error('เลือกรถได้ไม่เกิน 15 คัน')
    } else {
      setFieldValue('list_vehicle_id', targetKeys)
      this.setState({ vehicleSelect: targetKeys })
    }
  }

  onSearchByChange = (value, setFieldValue) => {
    const { loadVehicleIsNotBusiness, loadVehicleByPlantID } = this.props
    setFieldValue('search_by', value)
    if (value == 'vehicle_group') {
      this.setState({ search_by: value }, () => {
        setFieldValue('vehicle_type_id', '')
        loadVehicleIsNotBusiness('', false)
      })
    } else if (value == 'plant') {
      this.setState({ search_by: value }, () => {
        setFieldValue('plant_id', '')
        loadVehicleByPlantID('', false)
      })
    } else if (value == 'business') {
      this.setState({ search_by: value }, () => {
        setFieldValue('business_id', '')
      })
    }

    setFieldValue('list_vehicle_id', [])
    this.setState({
      vehicleList: [],
      selectedKeys: [],
      vehicleSelectedKeys: [],
    })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleClicReportkPreview = values => {
    const filter = {
      search_by: values.search_by,
      search_value:
        values.search_by === 'vehicle_group'
          ? values.vehicle_type_name
          : values.search_by === 'plant'
          ? values.plant_name
          : values.business_name,
      start_date: values.start_date,
      end_date: values.end_date,
      vehicle_group: values.vehicle_type_name,
      vehicle_list: values.list_vehicle_id,
      company_id: this.props.auth.profile.company_id,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
      accessToken: this.props.auth.accessToken,
      type_file: 'preview',
    }
    window.open('/report/driving-summary/preview', '_blank')
    localStorage.setItem(
      'dailydrivingsummaryreport-filter',
      JSON.stringify(filter),
    )
  }

  render() {
    const {
      vehicleTypeLists,
      loading,
      plantLists,
      businessList,
      onSubmit,
    } = this.props
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <Spin
          spinning={
            this.state.search_by == 'business'
              ? this.props.businessLoading
              : loading
          }
        >
          <LangContext.Consumer>
            {i18n => (
              <Formik
                initialValues={{
                  start_date: moment().format('YYYY-MM-DD 00:00:00'),
                  end_date: moment().format('YYYY-MM-DD 23:59:59'),
                  vehicle_type_name: '',
                  vehicle_type_id: '',
                  plant_name: '',
                  plant_id: '',
                  business_name: '',
                  business_id: '',
                  eventdate: [
                    moment().format('YYYY-MM-DD 00:00:00'),
                    moment().format('YYYY-MM-DD 23:59:59'),
                  ],
                  list_vehicle_id: this.props.dataedit
                    ? JSON.parse(this.props.dataedit).vehicle_list
                    : [],
                  search_by: 'vehicle_group',
                  division: this.props.selectedDivision,
                  department: this.props.selectedDepartment,
                  section: this.props.selectedSection,
                  plant: this.props.selectedPlant,
                  vehicleGroup: this.props.selectedVehicleGroup,
                }}
                validate={values => {
                  let errors = {}
                  if (values.eventdate.length > 0) {
                    const date_start = moment(values.eventdate[0]['_d'])
                    const date_end = moment(values.eventdate[1]['_d'])
                    let day_diff = date_end.diff(date_start, 'days') + 1
                    if (day_diff > 31) {
                      errors.eventdate = `${i18n.o.over1Month}`
                    }
                  }

                  if (values.list_vehicle_id.length < 1) {
                    errors.list_vehicle_id = `${i18n.p.pleaseSpecifyVehicle}`
                  }
                  if (values.list_vehicle_id.length > 15) {
                    errors.list_vehicle_id = `เลือกรถได้ไม่เกิน 15 คัน`
                  }
                  return errors
                }}
                validationSchema={yup.object().shape({
                  eventdate: yup
                    .array()
                    .required(`${i18n.p.pleaseChooseDateRange}`),
                })}
                onSubmit={values => {
                  if (this.state.clickMenu === 'preview') {
                    this.handleClicReportkPreview(values)
                  } else {
                    onSubmit(values, true)
                  }
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                }) => (
                  <Row type="flex" justify="center">
                    <LangContext.Consumer>
                      {i18n => (
                        <Form onSubmit={handleSubmit}>
                          <div className="semi-bold-16">
                            <LangContext.Consumer>
                              {i18n => i18n.d.drivingSummary}
                            </LangContext.Consumer>
                          </div>
                          <FormItem label={i18n.s.searchBy}>
                            <Select
                              onChange={value => {
                                this.onSearchByChange(value, setFieldValue)
                              }}
                              value={values.search_by}
                            >
                              <Option value="vehicle_group">
                                {i18n.v.vehicleGroup}
                              </Option>
                              <Option value="plant">{i18n.p.plant}</Option>
                              <Option value="business">
                                {i18n.b.business}
                              </Option>
                            </Select>
                          </FormItem>

                          {values.search_by == 'vehicle_group' ? (
                            <FormItem label={i18n.v.vehicleGroup}>
                              <Select
                                defaultValue={values.vehicle_type_name}
                                onChange={(value, key) => {
                                  this.onVehicleTypeChange(
                                    value,
                                    key.key,
                                    setFieldValue,
                                  )
                                }}
                                value={values.vehicle_type_id}
                              >
                                <Option key="" value="">
                                  <LangContext.Consumer>
                                    {i18n => i18n.a.all}
                                  </LangContext.Consumer>
                                </Option>
                                {vehicleTypeLists.map(item => (
                                  <Option key={item.name} value={item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </FormItem>
                          ) : (
                            ''
                          )}

                          {values.search_by == 'plant' ? (
                            <FormItem label={i18n.p.plant}>
                              <Select
                                // defaultValue="ทั้งหมด"
                                defaultValue={values.plant_name}
                                onChange={(value, key) => {
                                  this.onPlantChange(
                                    value,
                                    key.key,
                                    setFieldValue,
                                  )
                                }}
                                value={values.plant_id}
                              >
                                <Option key="" value="">
                                  <LangContext.Consumer>
                                    {i18n => i18n.a.all}
                                  </LangContext.Consumer>
                                </Option>
                                {plantLists.map(item => (
                                  <Option key={item.name} value={item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </FormItem>
                          ) : (
                            ''
                          )}

                          {values.search_by == 'business' ? (
                            <BasicFilterReport
                              setFieldValue={setFieldValue}
                              values={values}
                              touched={touched}
                              errors={errors}
                              division={this.props.division}
                              updateSelectDivision={e => {
                                this.setState({ vehicleSelect: [] }, () => {
                                  setFieldValue('list_vehicle_id', [])
                                  this.props.updateSelectDivision(e, values)
                                })
                              }}
                              department={this.props.department}
                              updateSelectDepartment={e => {
                                this.setState({ vehicleSelect: [] }, () => {
                                  setFieldValue('list_vehicle_id', [])
                                  this.props.updateSelectDepartment(e, values)
                                })
                              }}
                              section={this.props.section}
                              updateSelectSection={e => {
                                this.setState({ vehicleSelect: [] }, () => {
                                  setFieldValue('list_vehicle_id', [])
                                  this.props.updateSelectSection(e, values)
                                })
                              }}
                              plant={this.props.plant}
                              updateSelectPlant={e => {
                                this.setState({ vehicleSelect: [] }, () => {
                                  setFieldValue('list_vehicle_id', [])
                                  this.props.updateSelectPlant(e, values)
                                })
                              }}
                              vehicleGroup={this.props.vehicleGroup}
                              updateSelectVehicleGroup={e => {
                                this.setState({ vehicleSelect: [] }, () => {
                                  setFieldValue('list_vehicle_id', [])
                                  this.props.updateSelectVehicleGroup(e, values)
                                })
                              }}
                              hasVehicleGroup={false}
                            />
                          ) : (
                            ''
                          )}

                          <FormItem
                            label={i18n.v.vehicle}
                            required={true}
                            validateStatus={
                              touched.list_vehicle_id &&
                              errors.list_vehicle_id &&
                              'warning'
                            }
                            help={
                              touched.list_vehicle_id && errors.list_vehicle_id
                            }
                          >
                            <div style={{ width: '750px' }}>
                              <TableTransfer
                                dataSource={
                                  values.search_by == 'business'
                                    ? this.props.vehicleList
                                    : this.state.vehicleList
                                }
                                targetKeys={values.list_vehicle_id}
                                showSearch
                                filterOption={(inputValue, item) => {
                                  return (
                                    item.name
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) !== -1
                                  )
                                }}
                                onSelectChange={this.handleSelectChange}
                                onChange={targetKeys => {
                                  this.tranferChange(targetKeys, setFieldValue)
                                }}
                                leftColumns={[
                                  {
                                    dataIndex: 'name',
                                    title: (
                                      <div className="regular-bold-14">
                                        <LangContext.Consumer>
                                          {i18n => i18n.i.itemLeft}
                                        </LangContext.Consumer>
                                      </div>
                                    ),
                                    posiction: 'Left',
                                  },
                                ]}
                                rightColumns={[
                                  {
                                    dataIndex: 'name',
                                    title: (
                                      <div className="regular-bold-14">
                                        <LangContext.Consumer>
                                          {i18n => i18n.i.itemSelected}
                                        </LangContext.Consumer>
                                      </div>
                                    ),
                                    posiction: 'Right',
                                  },
                                ]}
                              />
                            </div>
                          </FormItem>
                          {this.props.call !== 'schedule' ? (
                            <Form.Item
                              label={i18n.d.dateRange}
                              required={true}
                              validateStatus={
                                touched.eventdate &&
                                errors.eventdate &&
                                'warning'
                              }
                              help={touched.eventdate && errors.eventdate}
                            >
                              <RangePicker
                                // showTime={{ format: "HH:mm" }}
                                disabledDate={disabledDate}
                                format="DD/MM/YYYY"
                                defaultValue={[
                                  moment(values.start_date, 'DD/MM/YYYY'),
                                  moment(values.end_date, 'DD/MM/YYYY'),
                                ]}
                                value={[
                                  moment(values.start_date),
                                  moment(values.end_date),
                                ]}
                                onChange={value => {
                                  if (value.length > 0) {
                                    setFieldValue('eventdate', value)
                                    setFieldValue(
                                      'start_date',
                                      moment(value[0]).format(
                                        'YYYY-MM-DD 00:00:00',
                                      ),
                                    )
                                    setFieldValue(
                                      'end_date',
                                      moment(value[1]).format(
                                        'YYYY-MM-DD 23:59:59',
                                      ),
                                    )
                                  } else {
                                    setFieldValue(
                                      'start_date',
                                      moment().format('YYYY-MM-DD 00:00:00'),
                                    )
                                    setFieldValue(
                                      'end_date',
                                      moment().format('YYYY-MM-DD 23:59:00'),
                                    )
                                    setFieldValue('eventdate', [
                                      moment().format('YYYY-MM-DD 00:00:00'),
                                      moment().format('YYYY-MM-DD 23:59:00'),
                                    ])
                                  }
                                }}
                              />
                            </Form.Item>
                          ) : (
                            ''
                          )}

                          <FormItem>
                            <Row gutter={24}>
                              {this.props.call == 'schedule' ? (
                                <Col span={8} style={{ marginTop: '10px' }}>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    block
                                  >
                                    <LangContext.Consumer>
                                      {i18n => i18n.s.save}
                                    </LangContext.Consumer>
                                  </Button>
                                </Col>
                              ) : (
                                <>
                                  <Col span={8}>
                                    <Button type="defualt" block>
                                      <Link to="/">
                                        <LangContext.Consumer>
                                          {i18n => i18n.c.cancel}
                                        </LangContext.Consumer>
                                      </Link>
                                    </Button>
                                  </Col>
                                  <Col span={8}>
                                    <Button
                                      type="primary"
                                      block
                                      htmlType="submit"
                                      onClick={() =>
                                        this.setState({ clickMenu: 'preview' })
                                      }
                                    >
                                      <LangContext.Consumer>
                                        {i18n => i18n.p.preview}
                                      </LangContext.Consumer>
                                    </Button>
                                  </Col>
                                  <Col span={8}>
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      block
                                      onClick={() =>
                                        this.setState({ clickMenu: 'report' })
                                      }
                                    >
                                      <LangContext.Consumer>
                                        {i18n => i18n.r.report}
                                      </LangContext.Consumer>
                                    </Button>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </FormItem>
                        </Form>
                      )}
                    </LangContext.Consumer>
                  </Row>
                )}
              />
            )}
          </LangContext.Consumer>
        </Spin>
      </div>
    )
  }
}
