import { createTypes, createAction } from "lib/action";
const LOAD_DATA_PRECAST = createTypes("loaddataprecast", "load");
const LOAD_DATA_VEHICLE_FOR_VIEW = createTypes("loaddataprecastvehicle", "load");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}


const loadDataPrecast = {
    request: (data, loading) =>
        createAction(LOAD_DATA_PRECAST.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_DATA_PRECAST.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_DATA_PRECAST.FAITLURE)  
};

const loadDataVehicleForView = {
    request: (data, loading) =>
        createAction(LOAD_DATA_VEHICLE_FOR_VIEW.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(LOAD_DATA_VEHICLE_FOR_VIEW.SUCCESS, {lists}),
    failure: () => createAction(LOAD_DATA_VEHICLE_FOR_VIEW.FAILURE)
};


export {
    LOAD_DATA_PRECAST, loadDataPrecast,
    LOAD_DATA_VEHICLE_FOR_VIEW, loadDataVehicleForView,
    REMOVE_LISTS, removeLists
};