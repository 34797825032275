import {
  LOAD_BEHAVIORDRIVINGBYCOMPANY,
  LOAD_BEHAVIORDRIVINGBYCOMPANY_PREVIEW,
  LOAD_VEHICLE_BEHAVIORDRIVINGBYCOMPANY,
  LOAD_COMPANY_PARTNER,
} from './actions'

const initialState = {
  vehicleLists: [],
  companypartnerLists: [],
  behaviordrivingLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BEHAVIORDRIVINGBYCOMPANY.REQUEST:
    case LOAD_BEHAVIORDRIVINGBYCOMPANY_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        behaviordrivingLoading: loading,
      }
    case LOAD_BEHAVIORDRIVINGBYCOMPANY.SUCCESS:
    case LOAD_BEHAVIORDRIVINGBYCOMPANY_PREVIEW.SUCCESS:
      return {
        ...state,
        behaviordrivingLoading: false,
      }
    case LOAD_VEHICLE_BEHAVIORDRIVINGBYCOMPANY.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        behaviordrivingLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_BEHAVIORDRIVINGBYCOMPANY.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        behaviordrivingLoading: false,
      }
    case LOAD_COMPANY_PARTNER.SUCCESS:
      return {
        ...state,
        companypartnerLists: action.payload.data.data,
      }
    default:
      return state
  }
}
