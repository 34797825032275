import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
import * as actions from './actions'

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadVehicleType.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleType.failure())
  }
}

function* loadPlant(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const location_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'location_visibility',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantbyvehicleid`,
      {
        company_id: COMPANY_ID,
        vehicle_visibility: vehicle_visibility,
        location_visibility: location_visibility,
        relate_company: `${this.props.auth.profile.relate_company}`,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadPlant.success(res.data))
  } catch (err) {
    yield put(actions.loadPlant.failure())
  }
}

function* loadBusiness(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getbusinessbyvehicleid`,
      {
        company_id: COMPANY_ID,
        vehicle_visibility: vehicle_visibility,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadBusiness.success(res.data))
  } catch (err) {
    yield put(actions.loadBusiness.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )
  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadVehicleByPlantID(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )
  const { plantID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebyplantid`,
      {
        companyID: COMPANY_ID,
        plant_id: plantID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicleByPlantID.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleByPlantID.failure())
  }
}

function* loadVehicleByBusinessID(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )
  const { businessID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebybusinessid`,
      {
        companyID: COMPANY_ID,
        business_id: businessID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicleByBusinessID.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleByBusinessID.failure())
  }
}

function* exportDailySummalyreport(action) {
  const {
    data: {
      search_by,
      search_value,
      start_date,
      end_date,
      vehicle_type_name,
      list_vehicle_id,
      company_id,
      vehicle_visibility,
      accesstoken,
      type_file,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/driving-summary`,
      {
        search_by,
        search_value,
        start_date: start_date,
        end_date: end_date,
        vehicle_group: vehicle_type_name,
        vehicle_list: list_vehicle_id,
        company_id: company_id,
        vehicle_visibility: vehicle_visibility,
        type_file,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.exportDailySummalyreport.success(res.data))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportDailySummalyreport.failure())
  }
}

export default function* watchDailyDrivingDailySummaryReport() {
  yield all([
    takeEvery(actions.LOAD_VEHICLE_DAILY_SUMMARY_REPORT.REQUEST, loadVehicle),
    takeEvery(
      actions.LOAD_VEHICLE_TYPE_DAILY_SUMMARY_REPORT.REQUEST,
      loadVehicleType,
    ),
    takeEvery(
      actions.EXPORT_DAILY_SUMMARY_REPORT_REPORT.REQUEST,
      exportDailySummalyreport,
    ),
    takeEvery(actions.LOAD_PLANT_DAILY_SUMMARY_REPORT.REQUEST, loadPlant),
    takeEvery(
      actions.LOAD_VEHICLE_BY_PLANT_ID_DAILY_SUMMARY_REPORT.REQUEST,
      loadVehicleByPlantID,
    ),
    takeEvery(actions.LOAD_BUSINESS_DAILY_SUMMARY_REPORT.REQUEST, loadBusiness),
    takeEvery(
      actions.LOAD_VEHICLE_BY_BUSINESS_ID_DAILY_SUMMARY_REPORT.REQUEST,
      loadVehicleByBusinessID,
    ),
  ])
}
export {
  loadVehicleType,
  loadVehicle,
  exportDailySummalyreport,
  loadPlant,
  loadVehicleByPlantID,
  loadBusiness,
  loadVehicleByBusinessID,
}
