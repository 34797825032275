import { createTypes, createAction } from 'lib/action'
import create from 'antd/lib/icon/IconFont'
const LOAD_COMPANY = createTypes('masterdataloadcompany', 'load')
const LOAD_COMPANY_DETAIL = createTypes('masterdataloadcompanydetail', 'load')
const CREATE_COMPANY = createTypes('createmasterdatacompany', 'create')
const UPDATE_COMPANY = createTypes('updatemasterdatacompany', 'update')
const EXPORT_COMPANY = createTypes('exportmasterdatacompany', 'load')
const IMPORT_COMPANY = createTypes('importmasterdatacompany', 'load')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadCompany = {
  request: (data, loading) =>
    createAction(LOAD_COMPANY.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_COMPANY.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_COMPANY.FAITLURE),
}

const loadCompanyDetail = {
  request: (data, loading) =>
    createAction(LOAD_COMPANY_DETAIL.REQUEST, { data, loading }),
  success: lists => createAction(LOAD_COMPANY_DETAIL.SUCCESS, { lists }),
  failure: () => createAction(LOAD_COMPANY_DETAIL.FAILURE),
}

const createCompany = {
  request: (data, loading) =>
    createAction(CREATE_COMPANY.REQUEST, { data, loading }),
  success: res => createAction(CREATE_COMPANY.SUCCESS, { res }),
  failure: () => createAction(CREATE_COMPANY.FAILURE),
}

const updateCompany = {
  request: (data, loading) =>
    createAction(UPDATE_COMPANY.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_COMPANY.SUCCESS, { res }),
  failure: () => createAction(UPDATE_COMPANY.FAILURE),
}

const exportCompany = {
  request: (data, loading) =>
    createAction(EXPORT_COMPANY.REQUEST, { data, loading }),
  success: lists => createAction(EXPORT_COMPANY.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_COMPANY.FAITLURE),
}

const importCompany = {
  request: (data, loading) =>
    createAction(IMPORT_COMPANY.REQUEST, { data, loading }),
  success: lists => createAction(IMPORT_COMPANY.SUCCESS, { lists }),
  failure: () => createAction(IMPORT_COMPANY.FAITLURE),
}

export {
  LOAD_COMPANY,
  loadCompany,
  LOAD_COMPANY_DETAIL,
  loadCompanyDetail,
  CREATE_COMPANY,
  createCompany,
  UPDATE_COMPANY,
  updateCompany,
  EXPORT_COMPANY,
  exportCompany,
  importCompany,
  IMPORT_COMPANY,
  REMOVE_LISTS,
  removeLists,
}
