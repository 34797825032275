import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";


function* loadPrecastReport(action) {
    const {
        data: {
          location_visibility,
          filterObj,
          company_id,
          accesstoken
        }
    } = action.payload;

    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-trips-precast_for-report`,
            {
                company_id,
                location_visibility,
                filterObj
            },
            { headers: { Authorization: `Bearer ${accesstoken}` } }
        );
    
            yield put(actions.loadPrecastReport.success(res.data));
      } catch (err) {
            console.log(err.message);
            yield put(actions.loadPrecastReport.failure());
      }
}


function* exportPrecastReport(action) {
    const {
        data: {
          location_visibility,
          filterObj,
          company_id,
          accesstoken
        }
    } = action.payload;
    try {
        const res = yield call(
          axios.post,
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-precast-report`,
          {
            location_visibility,
            company_id,
            filterObj
          },
          { headers: { Authorization: `Bearer ${accesstoken}` } }
        );
    
        yield put(actions.exportPrecastReport.success(res.data));
        window.location.assign(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`
        );
      } catch (err) {
        console.log(err.message);
        yield put(actions.exportPrecastReport.failure());
      }
}

export default function* watchPreCastReport() {
    yield all([
      takeEvery(actions.LOAD_RRECAST_REPORT.REQUEST, loadPrecastReport),
      takeEvery(actions.EXPORT_PRECAST_REPORT.REQUEST, exportPrecastReport),
    ]);
}

export {
    loadPrecastReport,
    exportPrecastReport
};
