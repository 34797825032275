import React from 'react'
import { Row, Col, Popconfirm } from 'antd'

const onConfirmLocationInfoMarker = (lat, lng) => {
  window.open(`http://www.google.com/maps?layer=c&cbll=${lat},${lng}`, '_blank')
}

const LocationInfoMarkerLongdoMap = ({ title, lat, lng, zoomLevel }) => (
  <Row>
    <Col span={24}>
      {title !== '' && (
        <Popconfirm
          title="เปิดGoogle Street View ?"
          placement="topLeft"
          okText="Yes"
          cancelText="No"
          onConfirm={() => {
            onConfirmLocationInfoMarker(lat, lng)
          }}
        >
          <a href="#">
            <div>
              <div
                style={{
                  zIndex: -2,
                  width: '160px',
                  borderRadius: '1px',
                  padding: '7px 15px 7px 15px',
                  display: 'inline-flex',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transform: 'translate(-50%, -180%)',
                  color: '#fff',
                  backgroundColor: '#4f2078',
                  position: 'relative',
                  left: 0,
                  top: 80,
                  bottom: 0,
                  minHeight: '35px',
                  border: '2px solid #c483f0',
                  fontSize: 11,
                  marginLeft: '15px',
                  borderRadius: '15px',
                }}
              >
                <span>{title}</span>
                <div
                  style={{
                    position: 'absolute',
                    left: 70,
                    bottom: -15,
                    zIndex: -2,
                    width: 0,
                    height: 0,
                    borderLeft: '7px solid transparent',
                    borderRight: '7px solid transparent',
                    borderTop: '12px solid #4f2078',
                    marginLeft: '15px',
                  }}
                />
              </div>
            </div>
          </a>
        </Popconfirm>
      )}
    </Col>
  </Row>
)

export default LocationInfoMarkerLongdoMap
