const data = {
  vehicleList: [
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
  ],
  vehicleNotification: [
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
    {
      vehicle_code: 8774,
      vehicle_plate_no: '71-8811',
      dataforreplay: '50851|-|904|44430',
    },
  ],
  fuelCostDate: '22-11-2022',
  updateAt: '22-11-2022',
}

export { data }
