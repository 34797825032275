import React, { Component, Fragment } from 'react'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Button, Row, Col, Icon, Spin, Radio, Select } from 'antd'
import { ResponsiveLine } from '@nivo/line'

import { StyledWidget } from '../../../../styled/common-styled'

const ButtonGroup = Button.Group
const { Option } = Select
const fakeData = [
  {
    id: 'รถ 10 ล้อ ',
    data: [
      {
        id: 'jan',
        label: 'jan',
        x: 'jan',
        y: 6,
        month: '1',
      },
      {
        id: 'feb',
        label: 'feb',
        x: 'feb',
        y: 18,
        month: '2',
      },
      {
        id: 'mar',
        label: 'mar',
        x: 'mar',
        y: 29,
        month: '3',
      },
      {
        id: 'apr',
        label: 'apr',
        x: 'apr',
        y: 0,
        month: '4',
        cantfind: true,
      },
      {
        id: 'may',
        label: 'may',
        x: 'may',
        y: 0,
        month: '5',
        cantfind: true,
      },
      {
        id: 'jun',
        label: 'jun',
        x: 'jun',
        y: 0,
        month: '6',
        cantfind: true,
      },
      {
        id: 'jul',
        label: 'jul',
        x: 'jul',
        y: 0,
        month: '7',
        cantfind: true,
      },
      {
        id: 'aug',
        label: 'aug',
        x: 'aug',
        y: 0,
        month: '8',
        cantfind: true,
      },
      {
        id: 'sep',
        label: 'sep',
        x: 'sep',
        y: 0,
        month: '9',
        cantfind: true,
      },
      {
        id: 'oct',
        label: 'oct',
        x: 'oct',
        y: 0,
        month: '10',
        cantfind: true,
      },
      {
        id: 'nov',
        label: 'nov',
        x: 'nov',
        y: 0,
        month: '11',
        cantfind: true,
      },
      {
        id: 'dec',
        label: 'dec',
        x: 'dec',
        y: 0,
        month: '12',
        cantfind: true,
      },
    ],
  },
  {
    id: 'รถเทเลอร์ ',
    data: [
      {
        id: 'jan',
        label: 'jan',
        x: 'jan',
        y: 0,
        month: '1',
        cantfind: true,
      },
      {
        id: 'feb',
        label: 'feb',
        x: 'feb',
        y: 117,
        month: '2',
      },
      {
        id: 'mar',
        label: 'mar',
        x: 'mar',
        y: 301,
        month: '3',
      },
      {
        id: 'apr',
        label: 'apr',
        x: 'apr',
        y: 387,
        month: '4',
      },
      {
        id: 'may',
        label: 'may',
        x: 'may',
        y: 12,
        month: '5',
      },
      {
        id: 'jun',
        label: 'jun',
        x: 'jun',
        y: 148,
        month: '6',
      },
      {
        id: 'jul',
        label: 'jul',
        x: 'jul',
        y: 26,
        month: '7',
      },
      {
        id: 'aug',
        label: 'aug',
        x: 'aug',
        y: 231,
        month: '8',
      },
      {
        id: 'sep',
        label: 'sep',
        x: 'sep',
        y: 0,
        month: '9',
        cantfind: true,
      },
      {
        id: 'oct',
        label: 'oct',
        x: 'oct',
        y: 0,
        month: '10',
        cantfind: true,
      },
      {
        id: 'nov',
        label: 'nov',
        x: 'nov',
        y: 0,
        month: '11',
        cantfind: true,
      },
      {
        id: 'dec',
        label: 'dec',
        x: 'dec',
        y: 0,
        month: '12',
        cantfind: true,
      },
    ],
  },
  {
    id: 'รถ Safety ',
    data: [
      {
        id: 'jan',
        label: 'jan',
        x: 'jan',
        y: 0,
        month: '1',
        cantfind: true,
      },
      {
        id: 'feb',
        label: 'feb',
        x: 'feb',
        y: 5,
        month: '2',
      },
      {
        id: 'mar',
        label: 'mar',
        x: 'mar',
        y: 0,
        month: '3',
        cantfind: true,
      },
      {
        id: 'apr',
        label: 'apr',
        x: 'apr',
        y: 1,
        month: '4',
      },
      {
        id: 'may',
        label: 'may',
        x: 'may',
        y: 0,
        month: '5',
        cantfind: true,
      },
      {
        id: 'jun',
        label: 'jun',
        x: 'jun',
        y: 2,
        month: '6',
      },
      {
        id: 'jul',
        label: 'jul',
        x: 'jul',
        y: 3,
        month: '7',
      },
      {
        id: 'aug',
        label: 'aug',
        x: 'aug',
        y: 0,
        month: '8',
        cantfind: true,
      },
      {
        id: 'sep',
        label: 'sep',
        x: 'sep',
        y: 0,
        month: '9',
        cantfind: true,
      },
      {
        id: 'oct',
        label: 'oct',
        x: 'oct',
        y: 0,
        month: '10',
        cantfind: true,
      },
      {
        id: 'nov',
        label: 'nov',
        x: 'nov',
        y: 0,
        month: '11',
        cantfind: true,
      },
      {
        id: 'dec',
        label: 'dec',
        x: 'dec',
        y: 0,
        month: '12',
        cantfind: true,
      },
    ],
  },
]

const Chart = ({ demo, data, changeChartType, chartType }) => (
  <ResponsiveLine
    tooltip={({ point }) => {
      return (
        <div
          style={{
            background: 'white',
            padding: '9px 12px',
            border: '1px solid #ccc',
          }}
        >
          <div>
            {point.serieId} : <strong>{point.data.yFormatted}</strong>
          </div>
        </div>
      )
    }}
    data={data}
    margin={
      demo
        ? { top: 30, right: 15, bottom: 50, left: 35 }
        : { top: 30, right: 180, bottom: 50, left: 45 }
    }
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      stacked: false,
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: (
        <LangContext.Consumer>{i18n => i18n.m.month}</LangContext.Consumer>
      ),
      legendOffset: 36,
      legendPosition: 'middle',
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: (
        <LangContext.Consumer>{i18n => i18n.a.amount}</LangContext.Consumer>
      ),
      legendOffset: -37,
      legendPosition: 'middle',
    }}
    colors={{ scheme: 'paired' }}
    pointSize={2}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ theme: 'background' }}
    pointLabel="y"
    pointLabelYOffset={-12}
    useMesh={true}
    legends={
      demo
        ? []
        : [
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 90,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 10,
              itemOpacity: 0.9,
              symbolSize: 8,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]
    }
  />
)

export class SpeedOverLimitByVehicleGroupWidget extends Component {
  state = {
    chartType: 'type',
    loading: false,
    data: [],
    dpType: 'none-dp',
  }
  onSortDpChange = val => {
    this.setState(
      {
        dpType: val,
        data: [],
      },
      () => {
        this.loadData(true, this.state.chartType, val)
      },
    )
  }

  loadData = (loading, chartType, dpType) => {
    if (loading) this.setState({ loading: true })
    this.fetch(this, chartType, dpType)
  }

  fetch = (self, chartType, dpType) => {
    let keepDp = true
    if (dpType === 'none-dp') {
      keepDp = false
    }
    let formData = new FormData()
    formData.append('only_has_dp', keepDp)
    formData.append('chart_type', chartType)
    formData.append('company_id', self.props.auth.profile.company_id)
    formData.append(
      'vehicle_visibility',
      self.props.auth.profile.vehicle_visibility,
    )
    fetch(
      `${process.env.REACT_APP_GO_V2_API_ENDPOINT}/summary/speed_over_limit/vehicle_type`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          //   "Content-Type": "application/json",
          Authorization: `Bearer ${self.props.auth.accessToken}`,
        },
        body: formData,
      },
    )
      .then(response => response.json())
      .then(res => {
        if (Array.isArray(res)) {
          self.setState({
            loading: false,
            data: res,
          })
        }
      })
      .catch(function() {
        self.setState({
          loading: false,
          data: [],
        })
      })
  }

  componentDidMount() {
    if (!this.props.demo) {
      this.loadData(true, this.state.chartType, this.state.dpType)
      this.setState(
        {
          data: [],
        },
        () => {
          this.interval = setInterval(
            () => this.loadData(false, this.state.chartType, this.state.dpType),
            1000 * 60 * 60,
          )
        },
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    if (this.props.demo)
      return (
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ height: '90%', width: '100%' }}
        >
          <span className="semi-bold-16">
            <LangContext.Consumer>
              {i18n => i18n.s.speedOverLimitByVehicleGroup}
            </LangContext.Consumer>
          </span>

          <Chart
            demo={this.props.demo}
            data={fakeData}
            chartType={this.state.chartType}
          />
        </Col>
      )
    else
      return (
        <StyledWidget>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ height: '95%' }}
          >
            <Spin
              spinning={this.state.loading}
              style={{
                textAlign: 'center',
                display: 'block',
                marginTop: '15%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span className="semi-bold-16">
                    <LangContext.Consumer>
                      {this.state.chartType == 'group'
                        ? i18n => i18n.s.speedOverLimitByVehicleGroup
                        : i18n => i18n.s.speedOverLimitByVehicleType}
                    </LangContext.Consumer>
                  </span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                  <Select
                    size="small"
                    defaultValue="none-dp"
                    style={{ width: 132, marginRight: '8px' }}
                    onChange={this.onSortDpChange}
                  >
                    <Option
                      value="none-dp"
                      style={{ backgroundColor: 'white' }}
                    >
                      <small>
                        <LangContext.Consumer>
                          {i18n => i18n.d.dpNotCounting}
                        </LangContext.Consumer>
                      </small>
                    </Option>
                    <Option value="dp" style={{ backgroundColor: 'white' }}>
                      <small>
                        <LangContext.Consumer>
                          {i18n => i18n.d.dpCounting}
                        </LangContext.Consumer>
                      </small>
                    </Option>
                  </Select>
                </Col>
              </Row>
            </Spin>
            <Chart
              demo={this.props.demo}
              data={this.state.data.map(ele => ({
                ...ele,
                data: ele.data.map(ele2 => ({
                  x: ele2.x,
                  y: ele2.y,
                  id: ele2.x,
                  label: ele2.x,
                  month: ele2.month,
                })),
              }))}
              chartType={this.state.chartType}
            />
          </Col>
        </StyledWidget>
      )
  }
}

export default SpeedOverLimitByVehicleGroupWidget
