import { createTypes, createAction } from 'lib/action'

const LOAD_ALARMALERTBYPLATENO = createTypes('alarmalertbyplateno', 'load')
const LOAD_ALARMALERTBYPLATENO_PREVIEW = createTypes(
  'alarmalertbyplatenopreview',
  'load',
)
const LOAD_VEHICLE_ALARMALERTBYPLATENO = createTypes(
  'vehicle_alarmalertbyplateno',
  'load',
)
const LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO = createTypes(
  'vehicletype_alarmalertbyplateno',
  'load',
)

const loadAlarmAlertByPlateno = {
  request: (data, loading) =>
    createAction(LOAD_ALARMALERTBYPLATENO.REQUEST, { data, loading }),
  success: data => createAction(LOAD_ALARMALERTBYPLATENO.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARMALERTBYPLATENO.FAILURE),
}

const loadAlarmAlertByPlatenopreview = {
  request: (data, loading) =>
    createAction(LOAD_ALARMALERTBYPLATENO_PREVIEW.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_ALARMALERTBYPLATENO_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARMALERTBYPLATENO_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_ALARMALERTBYPLATENO.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_ALARMALERTBYPLATENO.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_ALARMALERTBYPLATENO.FAILURE),
}

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO.FAILURE),
}

export {
  loadAlarmAlertByPlateno,
  LOAD_ALARMALERTBYPLATENO,
  loadAlarmAlertByPlatenopreview,
  LOAD_ALARMALERTBYPLATENO_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_ALARMALERTBYPLATENO,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO,
}
