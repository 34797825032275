import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadUom(action) {
  const {
    data: { page, pageSize, orderBy, orderType, filterObj, token, company_id },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/uom`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    yield put(actions.loadUom.success(res.data))
  } catch (err) {
    yield put(actions.loadUom.failure())
  }
}

function* createUom(action) {
  const {
    data: { name, description, token, company_id },
    history,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/uom/create`,
      {
        name,
        description,
        company_id,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    yield put(actions.createUom.success(res.data))
    history.push('/uom')
  } catch (err) {
    yield put(actions.createUom.failure())
  }
}

function* updateUom(action) {
  const {
    data: { name, description, token, company_id },
    id,
    history,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/uom/update`,
      {
        name,
        description,
        company_id,
        id,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    yield put(actions.updateUom.success(res.data))
    history.push('/uom')
  } catch (err) {
    yield put(actions.updateUom.failure())
  }
}

function* getUomByID(action) {
  const { id, token } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/uom/${id}`,
      { headers: { Authorization: `Bearer ${token}` } },
    )

    yield put(actions.getUomByID.success(res.data))
  } catch (err) {
    yield put(actions.getUomByID.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_UOM.REQUEST, loadUom),
    takeEvery(actions.CREATE_UOM.REQUEST, createUom),
    takeEvery(actions.UPDATE_UOM.REQUEST, updateUom),
    takeEvery(actions.GET_UOM.REQUEST, getUomByID),
  ])
}

export { loadUom, createUom, updateUom, getUomByID }
