import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadDeviceUser(action) {
  const {
    data: {
      page,
      pageSize,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      limit_export,
      type,
      file_value_pdf,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceuser`,
      {
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        limit_export,
        type,
        file_value_pdf,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadDeviceUser.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDeviceUser.failure())
  }
}

function* exportDeviceUser(action) {
  const {
    data: {
      page,
      pageSize,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      limit_export,
      type,
      file_value_pdf,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceuser`,
      {
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        limit_export,
        type,
        file_value_pdf,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportDeviceUser.success(res.data, page, pageSize))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportDeviceUser.failure())
  }
}

function* createDeviceUser(action) {
  const {
    data: {
      company_id,
      first_name,
      employee_id,
      personal_id,
      issued_date,
      expire_date,
      address,
      last_name,
      full_name,
      date_of_birth,
      phone_number,
      join_date,
      contractor,
      accounts,
      start_valid_date,
      end_valid_date,
      plant_factory,
      rfid,
      driving_license_number,
      driving_license_expire_date,
      plant_factory_id,
      accessToken,
      location_blacklist,
      image,
      salary,
    },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (image != '') {
    image.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('company_id', company_id)
  formData.append('employee_id', employee_id)
  formData.append('personal_id', personal_id)
  formData.append('issued_date', issued_date)
  formData.append('expire_date', expire_date)
  formData.append('address', address)
  formData.append('last_name', last_name)
  formData.append('full_name', full_name)
  formData.append('first_name', first_name)
  formData.append('date_of_birth', date_of_birth)
  formData.append('phone_number', phone_number)
  formData.append('join_date', join_date)
  formData.append('contractor', contractor)
  formData.append('accounts', accounts)
  formData.append('start_valid_date', start_valid_date)
  formData.append('end_valid_date', end_valid_date)
  formData.append('plant_factory', plant_factory)
  formData.append('plant_factory_id', plant_factory_id)
  formData.append('rfid', rfid)
  formData.append('driving_license_number', driving_license_number)
  formData.append('driving_license_expire_date', driving_license_expire_date)
  formData.append('driving_license_expi', driving_license_expire_date)
  formData.append('location_blacklist', location_blacklist)
  formData.append('salary', salary)
  formData.append('action_name', 'add')
  formData.append('matching_name', 'device-user')

  //   formData.append("images", uploadFile);

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/adddeviceuser`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    loading(res.data)
    yield put(actions.createDeviceUser.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createDeviceUser.failure())
  }
}

function* updateDeviceUser(action) {
  const {
    data: {
      device_user_id,
      company_id,
      first_name,
      employee_id,
      personal_id,
      issued_date,
      expire_date,
      address,
      last_name,
      full_name,
      date_of_birth,
      phone_number,
      join_date,
      contractor,
      accounts,
      start_valid_date,
      end_valid_date,
      plant_factory,
      rfid,
      driving_license_number,
      driving_license_expire_date,
      image,
      plant_factory_id,
      check_image,
      base64,
      location_blacklist,
      salary,
      accessToken,
    },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (image != '') {
    image.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('device_user_id', device_user_id)
  formData.append('files', uploadFile)
  formData.append('company_id', company_id)
  formData.append('employee_id', employee_id)
  formData.append('personal_id', personal_id)
  formData.append('issued_date', issued_date)
  formData.append('expire_date', expire_date)
  formData.append('address', address)
  formData.append('last_name', last_name)
  formData.append('full_name', full_name)
  formData.append('first_name', first_name)
  formData.append('date_of_birth', date_of_birth)
  formData.append('phone_number', phone_number)
  formData.append('join_date', join_date)
  formData.append('contractor', contractor)
  formData.append('accounts', accounts)
  formData.append('start_valid_date', start_valid_date)
  formData.append('end_valid_date', end_valid_date)
  formData.append('plant_factory', plant_factory)
  formData.append('plant_factory_id', plant_factory_id)
  formData.append('rfid', rfid)
  formData.append('driving_license_number', driving_license_number)
  formData.append('driving_license_expire_date', driving_license_expire_date)
  formData.append('driving_license_expi', driving_license_expire_date)
  formData.append('check_image', check_image)
  formData.append('base64', base64)
  formData.append('location_blacklist', location_blacklist)
  formData.append('salary', salary)
  formData.append('action_name', 'edit')
  formData.append('matching_name', 'device-user')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updatedrivinglicense`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateDeviceUser.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateDeviceUser.failure())
  }
}

function* loadAutocompleteLocation(action) {
  const {
    data: { url, companyID, accessToken, queryString },
    callback,
  } = action.payload
  try {
    let query_string = queryString.trim()
    let string = query_string.length > 0 ? query_string : null

    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${string}`,

      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    callback(true)
    yield put(actions.loadAutocompleteLocation.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadAutocompleteLocation.failure())
  }
}

function* loadDeviceuserDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceuser/detali`,
      {
        id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadDeviceuserDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDeviceuserDetail.failure())
  }
}

function* loadDatadrivinglicans(action) {
  const {
    data: { id, accessToken, page, pageSize },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/drivinglicense`,
      {
        id,
        page,
        pageSize,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadDatadrivinglicans.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDatadrivinglicans.failure())
  }
}

function* loadDatadrivingtranining(action) {
  const {
    data: { id, accessToken, page, pageSize },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/drivingtraining`,
      {
        id,
        page,
        pageSize,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadDatadrivingtranining.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDatadrivingtranining.failure())
  }
}

export default function* watchDeviceUser() {
  yield all([
    takeEvery(actions.LOAD_DEVICE_USER.REQUEST, loadDeviceUser),
    takeEvery(actions.CREATE_DEVICE_USER.REQUEST, createDeviceUser),
    takeEvery(actions.LOAD_DEVICE_USER_DETAIL.REQUEST, loadDeviceuserDetail),
    takeEvery(actions.LOAD_DRIVEING_LICENSEL.REQUEST, loadDatadrivinglicans),
    takeEvery(actions.LOAD_DRIVEING_TRAINING.REQUEST, loadDatadrivingtranining),
    takeEvery(actions.UPDATE_DEVICE_USER.REQUEST, updateDeviceUser),
    takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocompleteLocation),
    takeEvery(actions.EXPORT_DEVICE_USER.REQUEST, exportDeviceUser),
  ])
}

export {
  loadDeviceUser,
  createDeviceUser,
  loadDeviceuserDetail,
  loadDatadrivinglicans,
  loadDatadrivingtranining,
  updateDeviceUser,
  loadAutocompleteLocation,
  exportDeviceUser,
}
