import { createTypes, createAction } from 'lib/action'
const LOAD_MASTERDATA_VEHICLE_CONTRACT = createTypes('vehiclecontract', 'load')
const LOAD_MASTERDATA_VEHICLE_CONTRACT_BY_ID = createTypes(
  'vehiclecontractbyid',
  'load',
)
const UPDATE_INSTALLATION_STATUS_VEHICLE_CONTRACT = createTypes(
  'installationstatusvehiclecontract',
  'update',
)
const UPDATE_USAGE_STATUS_VEHICLE_CONTRACT = createTypes(
  'usagestatusvehiclecontract',
  'update',
)

const UPDATE_REMARK_VEHICLE_CONTRACT = createTypes(
  'remarkvehiclecontract',
  'update',
)
const IMPORT_VEHICLE_CONTRACT = createTypes('importVehicleContract', 'import')

const UPDATE_VEHICLE_CONTRACT_BY_VEHICLE_ID = createTypes(
  'vehiclecontractbyvehicleid',
  'update',
)
const LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT = createTypes(
  'gps_vendor_vehicle_contract',
  'load',
)

const LOAD_PROVINCE_VEHICLE_CONTRACT = createTypes(
  'province_vehicle_contract',
  'load',
)

const SET_INITIAL_STATE_IN_REDUX = createTypes(
  'clear_initial_state_in_redux',
  'clear',
)

const clearStateInRedux = {
  request: () => {
    return createAction(SET_INITIAL_STATE_IN_REDUX.REQUEST, {})
  },
  success: () => {
    return createAction(SET_INITIAL_STATE_IN_REDUX.SUCCESS, {})
  },
  failure: () => createAction(SET_INITIAL_STATE_IN_REDUX.FAITLURE),
}

const loadMasterVehicleContract = {
  request: (data, loading, cb) => {
    return createAction(LOAD_MASTERDATA_VEHICLE_CONTRACT.REQUEST, {
      data,
      loading,
      cb,
    })
  },
  success: (lists, page) => {
    return createAction(LOAD_MASTERDATA_VEHICLE_CONTRACT.SUCCESS, {
      lists,
      page,
    })
  },
  failure: () => createAction(LOAD_MASTERDATA_VEHICLE_CONTRACT.FAITLURE),
}

const loadMasterVehicleContractById = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_VEHICLE_CONTRACT_BY_ID.REQUEST, {
      data,
      loading,
    }),
  success: data =>
    createAction(LOAD_MASTERDATA_VEHICLE_CONTRACT_BY_ID.SUCCESS, {
      data,
    }),
  failure: () => createAction(LOAD_MASTERDATA_VEHICLE_CONTRACT_BY_ID.FAITLURE),
}

const updateInstallationStatusVehicleContract = {
  request: (data, loading, cb) => {
    return createAction(UPDATE_INSTALLATION_STATUS_VEHICLE_CONTRACT.REQUEST, {
      data,
      loading,
      cb,
    })
  },
  success: (status, msg) => {
    return createAction(UPDATE_INSTALLATION_STATUS_VEHICLE_CONTRACT.SUCCESS, {
      status,
      msg,
    })
  },
  failure: () =>
    createAction(UPDATE_INSTALLATION_STATUS_VEHICLE_CONTRACT.FAITLURE),
}

const updateUsageStatusVehicleContract = {
  request: (data, loading, cb) => {
    return createAction(UPDATE_USAGE_STATUS_VEHICLE_CONTRACT.REQUEST, {
      data,
      loading,
      cb,
    })
  },
  success: (status, msg) => {
    return createAction(UPDATE_USAGE_STATUS_VEHICLE_CONTRACT.SUCCESS, {
      status,
      msg,
    })
  },
  failure: () => createAction(UPDATE_USAGE_STATUS_VEHICLE_CONTRACT.FAITLURE),
}

const updateRemarkVehicleContract = {
  request: (data, loading, cb) => {
    return createAction(UPDATE_REMARK_VEHICLE_CONTRACT.REQUEST, {
      data,
      loading,
      cb,
    })
  },
  success: (status, msg) => {
    return createAction(UPDATE_REMARK_VEHICLE_CONTRACT.SUCCESS, {
      status,
      msg,
    })
  },
  failure: () => createAction(UPDATE_REMARK_VEHICLE_CONTRACT.FAITLURE),
}

const updateVehicleContractById = {
  request: (data, loading, cb) => {
    return createAction(UPDATE_VEHICLE_CONTRACT_BY_VEHICLE_ID.REQUEST, {
      data,
      loading,
      cb,
    })
  },
  success: (status, msg) => {
    return createAction(UPDATE_VEHICLE_CONTRACT_BY_VEHICLE_ID.SUCCESS, {
      status,
      msg,
    })
  },
  failure: () => createAction(UPDATE_VEHICLE_CONTRACT_BY_VEHICLE_ID.FAITLURE),
}

const importVehicleContract = {
  request: (file, loading, cb) =>
    createAction(IMPORT_VEHICLE_CONTRACT.REQUEST, {
      file,
      loading,
      cb,
    }),
  success: data => createAction(IMPORT_VEHICLE_CONTRACT.SUCCESS, { data }),
  failure: err => createAction(IMPORT_VEHICLE_CONTRACT.FAILURE, { err }),
}

const loadGpsVendor = {
  request: () =>
    createAction(LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.REQUEST, {}),
  success: data =>
    createAction(LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.SUCCESS, { data }),
  failure: () => createAction(LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.FAILURE),
}

const loadProvinceForVehicleContract = {
  request: () => createAction(LOAD_PROVINCE_VEHICLE_CONTRACT.REQUEST, {}),
  success: data =>
    createAction(LOAD_PROVINCE_VEHICLE_CONTRACT.SUCCESS, { data }),
  failure: () => createAction(LOAD_PROVINCE_VEHICLE_CONTRACT.FAILURE),
}

export {
  loadMasterVehicleContract,
  LOAD_MASTERDATA_VEHICLE_CONTRACT,
  updateInstallationStatusVehicleContract,
  UPDATE_INSTALLATION_STATUS_VEHICLE_CONTRACT,
  updateUsageStatusVehicleContract,
  UPDATE_USAGE_STATUS_VEHICLE_CONTRACT,
  updateRemarkVehicleContract,
  UPDATE_REMARK_VEHICLE_CONTRACT,
  loadMasterVehicleContractById,
  LOAD_MASTERDATA_VEHICLE_CONTRACT_BY_ID,
  importVehicleContract,
  IMPORT_VEHICLE_CONTRACT,
  updateVehicleContractById,
  UPDATE_VEHICLE_CONTRACT_BY_VEHICLE_ID,
  LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT,
  loadGpsVendor,
  LOAD_PROVINCE_VEHICLE_CONTRACT,
  loadProvinceForVehicleContract,
  SET_INITIAL_STATE_IN_REDUX,
  clearStateInRedux,
}
