import {
  LOAD_MASTERDATA_ROUTE,
  LOAD_LOCATION,
  CREATE_MASTERDATA_ROUTE,
  LOAD_DETAIL_MASTERDATA_ROUTE,
  UPDATE_MASTERDATA_ROUTE,
  LOAD_AUTOCOMPLETE,
  SELECTED_AUTOCOMPLETE,
  REMOVE_LISTS,
} from './actions'
const initialState = {
  lists: [],
  autoComplete: [],
  total: 1,
  loading: false,
  detailMasterDataUserloading: false,
  loadDetailMasterData: false,
  location: false,
  check: true,
  draw: -1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_MASTERDATA_ROUTE.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
        // check: true
      }

    case LOAD_MASTERDATA_ROUTE.SUCCESS:
      const { page } = action.payload

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10
      }

      return {
        ...state,
        lists: action.payload.lists.data,
        // check: false,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_LOCATION.REQUEST:
      const { location } = action.payload
      return {
        ...state,
        location: location,
      }

    case LOAD_LOCATION.SUCCESS:
      return {
        ...state,
        lists: action.payload.lists.data,
        location: false,
        check: false,
      }

    case CREATE_MASTERDATA_ROUTE.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_DETAIL_MASTERDATA_ROUTE.REQUEST:
      const { loadDetailMasterData } = action.payload
      return {
        ...state,
        loadDetailMasterData: loadDetailMasterData,
        check: true,
      }

    case LOAD_DETAIL_MASTERDATA_ROUTE.SUCCESS:
      return {
        ...state,
        lists: action.payload.lists,
        loadDetailMasterData: false,
        check: false,
      }

    case UPDATE_MASTERDATA_ROUTE.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_AUTOCOMPLETE.SUCCESS:
      return {
        ...state,
        autoComplete: action.payload,
      }

    case SELECTED_AUTOCOMPLETE:
      const { id, lists, selectedName, cb } = action.payload

      let selected = lists.filter(function(ele) {
        return ele.id == id
      })[0]
      cb(selected)
      return { ...state, [selectedName]: selected, autoComplete: [] }

    default:
      return state
  }
}
