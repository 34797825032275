import {
  LOAD_ISUPPLY_BOOKING_DASHBOARD_LIST_MONTH,
  LOAD_ISUPPLY_BOOKING_ORDER_BY_ID,
  GET_ISUPPLY_VEHICLE_BY_DATE,
  UPDATE_ISUPPLY_BOOKING_STATUS,
  UPDATE_ISUPPLY_CONFIRM_STATUS,
  UPDATE_ISUPPLY_CANCEL_STATUS,
  LOAD_ALL_DATA_WEEK,
  LOAD_ALL_DATA_DAY,
  UPDATE_VEHICLE_STATUS_ON_OFF,
  UPDATE_VEHICLE_STATUS_BY_DATE,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  lists: [],
  loading: false,
  check: true,
  lists_month: [],
  count_booking_month: [],
  loading_month: false,
  check_month: true,

  check_booking_order_by_id: true,
  booking_order_by_id: [],

  check_remain_vehicle: true,
  remain_vehicle_by_date: [],
  count_remain: 0,

  all_data_week: [],
  check_load_all_data_week: true,

  all_data_day: [],
  check_load_all_data_day: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_ISUPPLY_BOOKING_DASHBOARD_LIST_MONTH.REQUEST:
      return {
        ...state,
        lists_month: [],
        count_booking_month: [],
        loading_month: true,
        check_month: true,
      }
    case LOAD_ISUPPLY_BOOKING_DASHBOARD_LIST_MONTH.SUCCESS:
      return {
        ...state,
        lists_month: action.payload.lists.event,
        count_booking_month: action.payload.lists.count,
        loading_month: false,
        check_month: false,
      }
    case LOAD_ISUPPLY_BOOKING_ORDER_BY_ID.REQUEST:
      return {
        ...state,
        booking_order_by_id: [],
        check_booking_order_by_id: false,
      }
    case LOAD_ISUPPLY_BOOKING_ORDER_BY_ID.SUCCESS:
      return {
        ...state,
        booking_order_by_id: action.payload.lists.data,
        check_booking_order_by_id: true,
      }
    case GET_ISUPPLY_VEHICLE_BY_DATE.REQUEST:
      return {
        ...state,
        remain_vehicle_by_date: [],
        count_remain: 0,
        check_remain_vehicle: false,
      }
    case GET_ISUPPLY_VEHICLE_BY_DATE.SUCCESS:
      return {
        ...state,
        remain_vehicle_by_date: action.payload.lists.data,
        count_remain: action.payload.lists.total,
        check_remain_vehicle: true,
      }
    case UPDATE_ISUPPLY_BOOKING_STATUS.REQUEST:
      return {
        ...state,
      }
    case UPDATE_ISUPPLY_BOOKING_STATUS.SUCCESS:
      return {
        ...state,
      }
    case UPDATE_ISUPPLY_CONFIRM_STATUS.REQUEST:
      return {
        ...state,
      }
    case UPDATE_ISUPPLY_CONFIRM_STATUS.SUCCESS:
      return {
        ...state,
      }
    case UPDATE_ISUPPLY_CANCEL_STATUS.REQUEST:
      return {
        ...state,
      }
    case UPDATE_ISUPPLY_CANCEL_STATUS.SUCCESS:
      return {
        ...state,
      }

    case LOAD_ALL_DATA_WEEK.REQUEST:
      return {
        ...state,
        all_data_week: [],
        loading: true,
        check_load_all_data_week: true,
      }
    case LOAD_ALL_DATA_WEEK.SUCCESS:
      return {
        ...state,
        loading: false,
        all_data_week: action.payload.lists,
        check_load_all_data_week: false,
      }

    case LOAD_ALL_DATA_DAY.REQUEST:
      return {
        ...state,
        all_data_day: [],
        loading: true,
        check_load_all_data_day: true,
      }
    case LOAD_ALL_DATA_DAY.SUCCESS:
      return {
        ...state,
        loading: false,
        all_data_day: action.payload.lists,
        check_load_all_data_day: false,
      }

    case UPDATE_VEHICLE_STATUS_ON_OFF.REQUEST:
      return {
        ...state,
      }
    case UPDATE_VEHICLE_STATUS_ON_OFF.SUCCESS:
      return {
        ...state,
      }
    case UPDATE_VEHICLE_STATUS_BY_DATE.REQUEST:
      return {
        ...state,
      }
    case UPDATE_VEHICLE_STATUS_BY_DATE.SUCCESS:
      return {
        ...state,
      }
    default:
      return state
  }
}
