import {
  LOAD_TRIPSUMBYLOCATION,
  LOAD_TRIPSUMBYLOCATION_PREVIEW,
  LOAD_LOCATION_TRIPSUMBYLOCATION,
} from './actions'

const initialState = {
  tripsumbylocationUrl: '',
  locationLists: [],
  tripsumbylocationLoading: false,
  loadingLocationName: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRIPSUMBYLOCATION.REQUEST:
    case LOAD_TRIPSUMBYLOCATION_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        tripsumbylocationLoading: loading,
      }
    case LOAD_TRIPSUMBYLOCATION.SUCCESS:
    case LOAD_TRIPSUMBYLOCATION_PREVIEW.SUCCESS:
      return {
        ...state,
        tripsumbylocationUrl: action.payload.data.data,
        tripsumbylocationLoading: false,
      }
    case LOAD_LOCATION_TRIPSUMBYLOCATION.REQUEST:
      return {
        ...state,
        tripsumbylocationLoading: false,
        loadingLocationName: true,
      }
    case LOAD_LOCATION_TRIPSUMBYLOCATION.SUCCESS:
      return {
        ...state,
        locationLists: action.payload.data.data,
        tripsumbylocationLoading: false,
      }
    default:
      return state
  }
}
