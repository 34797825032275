import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
} from './../../../constants/local_storage'
import * as actions from './actions'

function* loadLocationSharingTo(action) {
  const {
    data: { page, pageSize, companyID, filterObj, orderBy, orderType },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsharelocation`,
      {
        page,
        pageSize,
        companyID,
        filterObj,
        orderBy,
        orderType,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.loadLocationSharingTo.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadLocationSharingTo.failure())
  }
}

function* loadLocationSharingFrom(action) {
  const {
    data: { page, pageSize, companyID, filterObj, orderBy, orderType },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsharefromlocation`,
      {
        page,
        pageSize,
        companyID,
        filterObj,
        orderBy,
        orderType,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.loadLocationSharingFrom.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadLocationSharingFrom.failure())
  }
}

function* createShareLocation(action) {
  const {
    data: { companyID, share_to_companies_id, location_id, accessToken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/sharelocation/create`,
      {
        companyID,
        share_to_companies_id,
        location_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createShareLocation.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createShareLocation.failure())
  }
}

function* exportLocationSharingTo(action) {
  const {
    data: {
      page,
      pageSize,
      companyID,
      filterObj,
      orderBy,
      orderType,
      type,
      file_value_pdf,
      limit_export,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsharelocation`,
      {
        page,
        pageSize,
        companyID,
        filterObj,
        orderBy,
        orderType,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.exportLocationSharingTo.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    yield put(actions.exportLocationSharingTo.failure())
  }
}

export default function* watchLocationSharing() {
  yield all([
    takeEvery(actions.LOAD_LOCATIONSHARING_TO.REQUEST, loadLocationSharingTo),
    takeEvery(
      actions.LOAD_LOCATIONSHARING_FROM.REQUEST,
      loadLocationSharingFrom,
    ),
    takeEvery(actions.CREATE_LOCATIONSHARING.REQUEST, createShareLocation),
    takeEvery(
      actions.EXPORT_LOCATIONSHARING_TO.REQUEST,
      exportLocationSharingTo,
    ),
  ])
}
export { loadLocationSharingTo, loadLocationSharingFrom, createShareLocation }
