import React from 'react'
import { Button } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'

const TOURSTEPS = [
  {
    selector: '',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep1}</LangContext.Consumer>
      </h4>
    ),
    // style: {
    //   backgroundColor: "black",
    //   color: "white"
    // }
  },
  {
    selector: '.tree-section-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep2}</LangContext.Consumer>
      </h4>
    ),
    // style: {
    //   backgroundColor: "black",
    //   color: "white"
    // }
  },
  {
    selector: '.detail-section-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep3}</LangContext.Consumer>
      </h4>
    ),
    // style: {
    //   backgroundColor: "black",
    //   color: "white"
    // }
  },
  {
    selector: '.root-node-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep4}</LangContext.Consumer>
      </h4>
    ),
    // style: {
    //   backgroundColor: "black",
    //   color: "white"
    // }
  },
  {
    selector: '.root-add-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep5}</LangContext.Consumer>
      </h4>
    ),
  },
  {
    selector: '.children-list-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep6}</LangContext.Consumer>
      </h4>
    ),
  },
  {
    selector: '.rst__moveHandle',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep7}</LangContext.Consumer>
      </h4>
    ),
  },
  {
    selector: '.remove-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep8}</LangContext.Consumer>
      </h4>
    ),
  },
  {
    selector: '.add-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep9}</LangContext.Consumer>
      </h4>
    ),
  },
  {
    selector: '.select-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep10}</LangContext.Consumer>
      </h4>
    ),
  },
  {
    selector: '.detail-name-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep11}</LangContext.Consumer>{' '}
        <Button
          className="select-step"
          type="default"
          size="small"
          icon="double-right"
          style={{
            marginLeft: '5px',
          }}
        ></Button>
      </h4>
    ),
  },
  {
    selector: '.select-detail-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep12}</LangContext.Consumer>
      </h4>
    ),
  },
  {
    selector: '.submit-step',
    content: (
      <h4>
        <LangContext.Consumer>{i18n => i18n.t.tourStep13}</LangContext.Consumer>
      </h4>
    ),
  },
]
export { TOURSTEPS }
