// etc
import watchUIState from 'modules/ui/sagas'
import watchAuthState from 'modules/auth/sagas'
import watchOauthLogin from 'modules/oauth/sagas'

// master data
import watchCustomerMasterDataState from 'modules/master-data/customer/sagas'
import watchUsergroupMasterDataState from 'modules/master-data/usergroup/sagas'
import watchVehiclepullsink from 'modules/master-data/pullvehiclesink/sagas'
import watchPermissionMasterDataState from 'modules/master-data/permission/sagas'
import watchDatavisibilityMasterDataState from 'modules/master-data/datavisibility/sagas'
import watchPlaceMasterData from 'modules/master-data/facility/sagas'
import watchUserMasterData from 'modules/master-data/user/sagas'
import watchDataVehicleGroup from 'modules/master-data/vehicleGroup/sagas'
import watchRouteMasterData from 'modules/master-data/route/sagas'
import watchVehicle from 'modules/master-data/vehicle/sagas'
import watchVehicleContract from 'modules/master-data/vehicle-contract/sagas'
import watchVehiclevisibilityPermissionGeneralData from 'modules/management/vehicleVisibilityPermissionGeneralData/sagas'
import watchVehiclevisibilityPermission from 'modules/management/vehicleVisibilityPermission/sagas'
import watchVerify from 'modules/master-data/verifyemail/sagas'
import watchDeviceUser from 'modules/master-data/deviceuser/sagas'
import watchVehicleSharingMasterDataState from 'modules/master-data/vehicle-sharing/sagas'
import watchUom from 'modules/master-data/uom/sagas'
import watchProduct from 'modules/master-data/product/sagas'
// import watchPermissionMasterDataState from "modules/master-data/permission/sagas";
import watchLocationGroup from 'modules/master-data/locationgroup/sagas'
import watchSiteGroup from 'modules/master-data/sitegroup/sagas'
import watchNearbyLocation from 'modules/master-data/nearbylocation/sagas'
import watchZone from 'modules/master-data/zone/sagas'
import watchRefuel from 'modules/master-data/refuel/sagas'
import watchPartner from 'modules/master-data/partner/sagas'
import watchLocationSharing from 'modules/master-data/locations-sharing/sagas'
import watchPartType from 'modules/master-data/parttype/sagas'
import watchPartGroup from 'modules/master-data/partgroup/sagas'
import watchManagementMaintenance from 'modules/master-data/maintenance/sagas'
import watchExpenseInsurance from 'modules/master-data/insurance/sagas'
import watchCompany from 'modules/master-data/company/sagas'
import watchMaterial from 'modules/master-data/material/sagas'
import watchVehicleType from 'modules/master-data/vehicleType/sagas'
import watchIsupplyMultidrop from 'modules/master-data/isupply-multidrop/sagas'
import watchIsupplySampleImage from 'modules/master-data/format-sample-image/sagas'

// operation job
import watchGPSCertificationRequestState from 'modules/operate-job/gps-certification/sagas'
import watchDeliveryItemMasterDataState from 'modules/operate-job/delivery-item/sagas'
import watchDeliveryItemDeleteHistoryMasterDataState from 'modules/operate-job/delivery-item-delete-history/sagas'

// help
import watchServicerepairdevice from 'modules/help/servicerepairdevice/sagas'
import watchServiceMoveDevice from 'modules/help/servicemovedevice/sagas'
import watchServiceInstallDevice from 'modules/help/serviceeinstalldevice/sagas'
import watchMaintenancedevice from 'modules/help/maintenancedevice/sagas'
import watchSubMaterial from 'modules/master-data/sub_material/sagas'

// booking
import watchBookingPoolState from 'modules/booking-pool/sagas'

// dashboard
import watchMonitoringState from 'modules/monitoring/sagas'
import watchVideoMonitoring from 'modules/monitoring/video/sagas'
import watchHoneytoaststream from 'modules/monitoring/honeytoaststream/sagas'
import watchCashierState from 'modules/cashier/sagas'
import watchMdvrdownloadfile from 'modules/monitoring/mdvrdownloadfile/sagas'
import watchHoneytoastdownloadfile from 'modules/monitoring/honeytoastdownloadfile/sagas'
import watchHoneytoastplayback from 'modules/monitoring/honeytoastplayback/sagas'

//management
import watchDownloadcertificate from 'modules/management/downloadcertificate/sagas'
import watchContactmanagement from 'modules/management/contactmanagement/sagas'

// report
import watchAlarmAlertByPlateno from 'modules/reports/alarmalertbyplateno/sagas'
import watchAlarmAlertByPlatenoOnyear from 'modules/reports/alarmalertbyplatenoonyear/sagas'
import watchAlarmAlertByDriver from 'modules/reports/alarmalertbydriver/sagas'
import watchAlarmAlertByDurationtime from 'modules/reports/alarmalertbydurationtime/sagas'
import watchDrivingbehavior from 'modules/reports/drivingbehavior/sagas'
import watchDrivinglicensescanbehavior from 'modules/reports/drivinglicensescanbehavior/sagas'
import watchSeparatevehiclestatus from 'modules/reports/separatevehiclestatus/sagas'
import watchAlarmAlertFrequencyByDriver from 'modules/reports/alarmalertfrequencybydriver/sagas'
import watchComparevehiclescco from 'modules/reports/comparevehiclescco/sagas'
import watchAlertDriverLicense from 'modules/reports/alertdriverlicense/sagas'
import watchParkingareaoutside from 'modules/reports/parkingareaoutside/sagas'
import watchParking from 'modules/reports/parking/sagas'
import watchDrivinglicensescanlog from 'modules/reports/drivinglicensescanlog/sagas'
import watchSpeedoverlimitsummary from 'modules/reports/speedoverlimitsummary/sagas'
import watchDrivinglog from 'modules/reports/drivinglog/sagas'
import watchEvent from 'modules/reports/event/sagas'
import watchSummarykpi from 'modules/reports/summarykpi/sagas'
import watchLoginhistory from 'modules/reports/loginhistory/sagas'
import watchGpsstatus from 'modules/reports/gpsstatus/sagas'
import watchTrackingdata from 'modules/reports/trackingdata/sagas'
import watchTruckusage from 'modules/reports/truckusage/sagas'
import watchDrivingdistance from 'modules/reports/drivingdistance/sagas'
import watchTemperature from 'modules/reports/temperature/sagas'
import watchMaintenance from 'modules/reports/maintenance/sagas'
import watchDistfromlastmaintenance from 'modules/reports/distfromlastmaintenance/sagas'
import watchTruckcurrentlocation from 'modules/reports/truckcurrentlocation/sagas'
import watchDriverinformation from 'modules/reports/driverinformation/sagas'
import watchNumberofdaysworked from 'modules/reports/numberofdaysworked/sagas'
import watchDozeoffrisk from 'modules/reports/dozeoffrisk/sagas'
import watchDelivery from 'modules/reports/delivery/sagas'
import watchTriptemperature from 'modules/reports/triptemperature/sagas'
import watchTripsumbyveh from 'modules/reports/tripsumbyveh/sagas'
import watchDaily from 'modules/reports/daily/sagas'
import watchVelocitysummary from 'modules/reports/velocitysummary/sagas'
import watchTruckengineon from 'modules/reports/truckengineon/sagas'
import watchTruckengineoff from 'modules/reports/truckengineoff/sagas'
import watchTrucknotuse from 'modules/reports/trucknotuse/sagas'
import watchDrivinghour from 'modules/reports/drivinghour/sagas'
import watchBehaviordriving from 'modules/reports/behaviordriving/sagas'
import watchBehaviordrivingbycompany from 'modules/reports/behaviordrivingbycompany/sagas'
import watchScoresummary from 'modules/reports/scoresummary/sagas'
import watchFueluse from 'modules/reports/fueluse/sagas'

import watchTripState from 'modules/delivery-management/trip/sagas'
import watchDeliveryTripState from 'modules/delivery-management/delivery-trip/sagas'

import watchTripsummary from 'modules/reports/tripsummary/sagas'
import watchDrivingoverlimit from 'modules/reports/drivingoverlimit/sagas'
import watchTripsumbylocation from 'modules/reports/tripsumbylocation/sagas'
import watchInsurance from 'modules/reports/insurance/sagas'
import watchVehicleperformanceconclude from 'modules/reports/vehicleperformanceconclude/sagas'
import watchReportRefuel from 'modules/reports/refuel/sagas'
import watchRiskbehaviour from 'modules/reports/riskbehaviour/sagas'
import watchVehicleinarea from 'modules/reports/vehicleinarea/sagas'
import watchMaintenancedeviceconclude from 'modules/reports/maintenancedeviceconclude/sagas'
import watchVehicleinformationdlt from 'modules/reports/vehicleinformationdlt/sagas'
import watchRmcconcretetrip from 'modules/reports/rmcconcretetrip/sagas'
import watchSummaryVehicleUsage from 'modules/reports/summary-vehicle-usage/sagas'
import watchunauthorizeunload from 'modules/reports/unauthorizeunload/sagas'
import watchAutoMoveSite from 'modules/reports/automovesite/sagas'
import watchVehicleSharingSystemAvaliableAccount from 'modules/master-data/vehicle-sharing-system-avaliable-account/sagas.js'

import watchDailyDrivingDailySummaryReport from 'modules/reports/daily-driving-summary-report/sagas'
import watchGPSlostcontact from 'modules/reports/gps-lost-contact/sagas'
import watchGPSDisconnectOnTrip from 'modules/reports/gpsdisconnectontrip/sagas'

import watchPolicyState from 'modules/setting/policy/sagas'
import watchLineNotify from 'modules/setting/linenotify/sagas'

import watchCpacPolicy from 'modules/GPS-Dashborad/gps-policy/sagas'
import watchCpacRequestCertificate from 'modules/GPS-Dashborad/requestcertificate/sagas'

import watchRequestMaterial from 'modules/master-data/request-material/sagas'
import watchIsupplyDelivery from 'modules/monitoring/isupply-delivery/sagas'
import watchManagQueue from 'modules/management/isupply-manage-queue/sagas'
import watchDailyRequestMaterial from 'modules/monitoring/request-material-to-day/sagas'
import watchVehicleBlacklist from 'modules/management/isupply_vehicle_blacklist/sagas'
import watchDriverBlacklist from 'modules/management/isupply_driver_blacklist/sagas'
import watchPlantMiningContract from 'modules/master-data/isupply_plant_mining_contract/sagas'
import watchTruckStatusDashBoard from 'modules/tracking_status_dashboard/tracking_status/sagas.js'
import watchSmartDispatching from 'modules/smartdispatching/sagas.js'
import watchIsupplyDashboard from 'modules/monitoring/isupply_dashboard/sagas'
import watchIsupplyDeliveryReport from 'modules/reports/isupply-delivery-report/sagas'
import watchIsupplyManagVehicle from 'modules/management/isupply-manage-vehicle/sagas'
import watchCpacPrecastFloor from 'modules/management/cpac-precast-transportation-information-floor/sagas'
import watchCpacPrecastWall from 'modules/management/cpac-precast-transportation-information-wall/sagas'
import watchCpacPrecastClose from 'modules/management/cpac-sales-close-information/sagas'
import watchDashBoardPrecast from 'modules/monitoring/cpac-precast/sagas'
import watchPreCastReport from 'modules/reports/cpac-precast/sagas'
import watchIsupplyVehicleStatusDailyJob from 'modules/monitoring/isupply-vehicle-status-daily-jobs/sagas'

import tripbyengineoffon from 'modules/reports/tripbyengineoffon/sagas'
import schedulereport from 'modules/reports/schedulereport/sagas'
import watchFuelremainrate from 'modules/reports/fuelremainrate/sagas'
import watchRoutemasterState from 'modules/delivery-management/route-master/sagas'
import watchIsupplyOverviewPerformance from 'modules/monitoring/isupply_overiew_performance_dashboard/sagas'
import watchActionLog from 'modules/monitoring/action-log/sagas'
import watchIsupplyWeightHistory from 'modules/reports/isupply-weighing-history/sagas'
import watchIsupplyBookingKeyCdas from 'modules/master-data/booking-key-cdas/sagas'
import watchTruckPickupCard from 'modules/master-data/trucks-pickup-card/sagas'
import watchIspplyBookingOrder from 'modules/master-data/isupply-booking-order/sagas'
import watchIspplyBookingDashboard from 'modules/monitoring/isupply_booking_dashboard/sagas'
import watchIsupplySaleorder from 'modules/master-data/isupply-saleorder/sagas'

import watchMonitorGPS from 'modules/monitoring/monitor-gps/sagas'

import { fork, all } from 'redux-saga/effects'

export default function* rootSagas() {
  yield all([
    fork(watchUIState),
    fork(watchBookingPoolState),
    fork(watchAuthState),
    fork(watchMonitoringState),
    fork(watchCashierState),
    fork(watchCustomerMasterDataState),
    fork(watchAlarmAlertByPlateno),
    fork(watchAlarmAlertByPlatenoOnyear),
    fork(watchGPSCertificationRequestState),
    fork(watchAlarmAlertByDriver),
    fork(watchAlarmAlertByDurationtime),
    fork(watchDrivingbehavior),
    fork(watchDrivinglicensescanbehavior),
    fork(watchSeparatevehiclestatus),
    fork(watchComparevehiclescco),
    fork(watchAlertDriverLicense),
    fork(watchParkingareaoutside),
    fork(watchUsergroupMasterDataState),
    fork(watchDeliveryItemMasterDataState),
    fork(watchDeliveryItemDeleteHistoryMasterDataState),
    fork(watchVehiclepullsink),
    fork(watchSpeedoverlimitsummary),
    fork(watchSummarykpi),
    fork(watchPermissionMasterDataState),
    fork(watchDatavisibilityMasterDataState),
    fork(watchPlaceMasterData),
    fork(watchUserMasterData),
    fork(watchDataVehicleGroup),
    fork(watchRouteMasterData),
    fork(watchVehicleContract),
    fork(watchVehiclevisibilityPermissionGeneralData),
    fork(watchVehiclevisibilityPermission),
    fork(watchVehicle),
    fork(watchVehicleSharingMasterDataState),
    fork(watchAlarmAlertFrequencyByDriver),
    fork(watchParking),
    fork(watchLoginhistory),
    fork(watchGpsstatus),
    fork(watchTrackingdata),
    fork(watchVideoMonitoring),
    fork(watchTruckusage),
    fork(watchDrivingdistance),
    fork(watchDrivinglicensescanlog),
    fork(watchDrivinglog),
    fork(watchEvent),
    fork(watchVerify),
    fork(watchDeviceUser),
    fork(watchLocationGroup),
    fork(watchSiteGroup),
    fork(watchNearbyLocation),
    fork(watchZone),
    fork(watchRefuel),
    fork(watchTemperature),
    fork(watchMaintenance),
    fork(watchDistfromlastmaintenance),
    fork(watchTruckcurrentlocation),
    fork(watchDriverinformation),
    fork(watchNumberofdaysworked),
    fork(watchDozeoffrisk),
    fork(watchDelivery),
    fork(watchTriptemperature),
    fork(watchTripsumbyveh),
    fork(watchDaily),
    fork(watchVelocitysummary),
    fork(watchTruckengineon),
    fork(watchTruckengineoff),
    fork(watchTrucknotuse),
    fork(watchDrivinghour),
    fork(watchTripsummary),
    fork(watchDrivingoverlimit),
    fork(watchTripsumbylocation),
    fork(watchInsurance),
    fork(watchVehicleperformanceconclude),
    fork(watchReportRefuel),
    fork(watchRiskbehaviour),
    fork(watchPartner),
    fork(watchLocationSharing),
    fork(watchVehicleinarea),
    fork(watchMaintenancedeviceconclude),
    fork(watchDownloadcertificate),
    fork(watchPolicyState),
    fork(watchTripState),
    fork(watchPartType),
    fork(watchPartGroup),
    fork(watchManagementMaintenance),
    fork(watchContactmanagement),
    fork(watchMdvrdownloadfile),
    fork(watchServicerepairdevice),
    fork(watchDeliveryTripState),
    fork(watchExpenseInsurance),
    fork(watchVehicleinformationdlt),
    fork(watchCompany),
    fork(watchServiceMoveDevice),
    fork(watchHoneytoaststream),
    fork(watchServiceInstallDevice),
    fork(watchHoneytoastdownloadfile),
    fork(watchHoneytoastplayback),
    fork(watchCpacPolicy),
    fork(watchBehaviordriving),
    fork(watchBehaviordrivingbycompany),
    fork(watchScoresummary),
    fork(watchCpacRequestCertificate),
    fork(watchMaintenancedevice),
    fork(watchRequestMaterial),
    fork(watchIsupplyDelivery),
    fork(watchManagQueue),
    fork(watchDailyRequestMaterial),
    fork(watchVehicleBlacklist),
    fork(watchDriverBlacklist),
    fork(watchPlantMiningContract),
    fork(watchTruckStatusDashBoard),
    fork(watchSmartDispatching),
    fork(watchIsupplyDashboard),
    fork(watchIsupplyDeliveryReport),
    fork(watchIsupplyManagVehicle),
    fork(watchCpacPrecastFloor),
    fork(watchCpacPrecastWall),
    fork(watchCpacPrecastClose),
    fork(watchDashBoardPrecast),
    fork(watchPreCastReport),
    fork(watchIsupplyVehicleStatusDailyJob),
    fork(tripbyengineoffon),
    fork(schedulereport),
    fork(watchFuelremainrate),
    fork(watchRoutemasterState),
    fork(watchIsupplyOverviewPerformance),
    fork(watchUom),
    fork(watchProduct),
    fork(watchRmcconcretetrip),
    fork(watchSummaryVehicleUsage),
    fork(watchDailyDrivingDailySummaryReport),
    fork(watchGPSlostcontact),
    fork(watchFueluse),
    fork(watchActionLog),
    fork(watchLineNotify),
    fork(watchMaterial),
    fork(watchGPSDisconnectOnTrip),
    fork(watchIsupplyWeightHistory),
    fork(watchVehicleType),
    fork(watchIsupplyBookingKeyCdas),
    fork(watchSubMaterial),
    fork(watchIsupplyMultidrop),
    fork(watchIsupplySampleImage),
    fork(watchTruckPickupCard),
    fork(watchIspplyBookingOrder),
    fork(watchIspplyBookingDashboard),
    fork(watchIsupplySaleorder),
    fork(watchunauthorizeunload),
    fork(watchAutoMoveSite),
    fork(watchVehicleSharingSystemAvaliableAccount),
    fork(watchMonitorGPS),
    fork(watchOauthLogin),
  ])
}
