import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
} from './../../../constants/local_storage'

function* loadDriverBlacklist(action) {
  const {
    data: {
      companyID,
      page,
      pageSize,
      orderBy,
      orderType,
      location_visibility,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get/driver-blacklist`,
      {
        companyID,
        page,
        pageSize,
        orderBy,
        orderType,
        location_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadDriverBlacklist.success(res, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDriverBlacklist.failure())
  }
}

function* createDriverBlacklist(action) {
  const {
    data: { location_id, driver },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/creact/driver-blacklist`,
      {
        location_id,
        driver,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    loading(res.data)
    yield put(actions.createDriverBlacklist.success(res.data))
  } catch (err) {
    yield put(actions.createDriverBlacklist.failure())
  }
}

function* loadDriverBlacklistDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get-list/driver-blacklist/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadDriverBlacklistDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDriverBlacklistDetail.failure())
  }
}

function* updateDriverBlacklist(action) {
  const {
    data: { origi_location_id, location_id, driver },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/update/driver-blacklist`,
      {
        origi_location_id,
        location_id,
        driver,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    loading(res.data)
    yield put(actions.updateDriverBlacklist.success(res.data))
  } catch (err) {
    yield put(actions.updateDriverBlacklist.failure())
  }
}

function* exportIsupplyDriverBlacklist(action) {
  const {
    data: {
      companyID,
      page,
      pageSize,
      orderBy,
      orderType,
      location_visibility,
      type,
      file_value_pdf,
      limit_export,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get/driver-blacklist`,
      {
        companyID,
        page,
        pageSize,
        orderBy,
        orderType,
        location_visibility,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.exportIsupplyDriverBlacklist.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    yield put(actions.exportIsupplyDriverBlacklist.failure())
  }
}

export default function* watchDriverBlacklist() {
  yield all([
    takeEvery(actions.LOAD_DRIVER_BLACKLIST.REQUEST, loadDriverBlacklist),
    takeEvery(actions.CREATE_DRIVER_BLACKLIST.REQUEST, createDriverBlacklist),
    takeEvery(
      actions.LOAD_DRIVER_BLACKLIST_DETAIL.REQUEST,
      loadDriverBlacklistDetail,
    ),
    takeEvery(actions.UPDATE_DRIVER_BLACKLIST.REQUEST, updateDriverBlacklist),
    takeEvery(
      actions.EXPORT_DRIVER_BLACKLIST.REQUEST,
      exportIsupplyDriverBlacklist,
    ),
  ])
}
export {
  loadDriverBlacklist,
  createDriverBlacklist,
  loadDriverBlacklistDetail,
  updateDriverBlacklist,
}
