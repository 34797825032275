import {
  LOAD_MASTERDATA_SITEGROUP,
  CREATE_MASTERDATA_SITEGROUP,
  UPDATE_MASTERDATA_SITEGROUP,
  EXPORT_MASTERDATA_SITEGROUP,
} from './actions'

const intialState = {
  lists: [],
  total: 1,
  loading: false,
  draw: -1,
}

export default (state = intialState, action) => {
  switch (action.type) {
    case LOAD_MASTERDATA_SITEGROUP.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
      }

    case LOAD_MASTERDATA_SITEGROUP.SUCCESS:
      return {
        ...state,
        lists: action.payload.lists.data,
        total_rows: action.payload.lists.total,
        loading: false,
      }

    case CREATE_MASTERDATA_SITEGROUP.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_MASTERDATA_SITEGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_MASTERDATA_SITEGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case EXPORT_MASTERDATA_SITEGROUP.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_MASTERDATA_SITEGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
