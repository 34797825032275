import { createTypes, createAction } from 'lib/action'
const LOAD_MULTIDROP = createTypes('loadMultidrop', 'load')
const LOAD_ISUPPLY_MULTIDROP_GET_ISUPPLY_DO = createTypes(
  'loadisupplymultidropgetisupplydo',
  'load',
)
const CREATE_ISUPPLY_MULTIDROPL = createTypes(
  'createisupplymultidrop',
  'create',
)
const UPDATE_ISUPPLY_MULTIDROPL = createTypes(
  'createisupplymultidrop',
  'update',
)
const LOAD_MULTIDROP_FOR_EDIT = createTypes('loadMultidropforedit', 'load')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadMultidrop = {
  request: (data, loading) =>
    createAction(LOAD_MULTIDROP.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_MULTIDROP.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_MULTIDROP.FAITLURE),
}

const loadIsupplyMultidropGetIsupplyDo = {
  request: (data, loading) =>
    createAction(LOAD_ISUPPLY_MULTIDROP_GET_ISUPPLY_DO.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(LOAD_ISUPPLY_MULTIDROP_GET_ISUPPLY_DO.SUCCESS, { lists }),
  failure: () => createAction(LOAD_ISUPPLY_MULTIDROP_GET_ISUPPLY_DO.FAITLURE),
}

const createisupplyMultidrop = {
  request: (data, loading) =>
    createAction(CREATE_ISUPPLY_MULTIDROPL.REQUEST, { data, loading }),
  success: res => createAction(CREATE_ISUPPLY_MULTIDROPL.SUCCESS, { res }),
  failure: () => createAction(CREATE_ISUPPLY_MULTIDROPL.FAITLURE),
}

const loadIsupplyMultidropGetIsupplyDoForEdit = {
  request: (data, loading, cb) =>
    createAction(LOAD_MULTIDROP_FOR_EDIT.REQUEST, { data, loading, cb }),
  success: listsEdit =>
    createAction(LOAD_MULTIDROP_FOR_EDIT.SUCCESS, { listsEdit }),
  failure: () => createAction(LOAD_MULTIDROP_FOR_EDIT.FAITLURE),
}

const updateIsupplyMultidrop = {
  request: (data, loading) =>
    createAction(UPDATE_ISUPPLY_MULTIDROPL.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_ISUPPLY_MULTIDROPL.SUCCESS, { res }),
  failure: () => createAction(UPDATE_ISUPPLY_MULTIDROPL.FAITLURE),
}

export {
  REMOVE_LISTS,
  removeLists,
  LOAD_ISUPPLY_MULTIDROP_GET_ISUPPLY_DO,
  loadIsupplyMultidropGetIsupplyDo,
  CREATE_ISUPPLY_MULTIDROPL,
  createisupplyMultidrop,
  LOAD_MULTIDROP,
  loadMultidrop,
  LOAD_MULTIDROP_FOR_EDIT,
  loadIsupplyMultidropGetIsupplyDoForEdit,
  UPDATE_ISUPPLY_MULTIDROPL,
  updateIsupplyMultidrop,
}
