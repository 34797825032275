import {
  LOAD_DOZEOFFRISK,
  LOAD_VEHICLE_DOZEOFFRISK,
  LOAD_VEHICLE_TYPE_DOZEOFFRISK,
} from './actions'

const initialState = {
  dozeoffriskUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  dozeoffriskLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DOZEOFFRISK.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        dozeoffriskLoading: loading,
      }
    case LOAD_DOZEOFFRISK.SUCCESS:
      return {
        ...state,
        dozeoffriskUrl: action.payload.data.data,
        dozeoffriskLoading: false,
      }
    case LOAD_VEHICLE_DOZEOFFRISK.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        dozeoffriskLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_DOZEOFFRISK.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        dozeoffriskLoading: false,
      }
    case LOAD_VEHICLE_TYPE_DOZEOFFRISK.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
