import React, { Component, Fragment } from 'react'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Button, Row, Col, Icon, Spin, Select } from 'antd'
import { ResponsiveBar } from '@nivo/bar'

import { StyledWidget } from '../../../../styled/common-styled'

const ButtonGroup = Button.Group
const { Option } = Select
const fakeData = [
  {
    id: 'Jan',
    month: 'Jan',
    label: 'Jan',
    value: 89,
  },
  {
    id: 'Feb',
    month: 'Feb',
    label: 'Feb',
    value: 110,
  },
  {
    id: 'Mar',
    month: 'Mar',
    label: 'Mar',
    value: 100,
  },
  {
    id: 'Apr',
    month: 'Apr',
    label: 'Apr',
    value: 134,
  },
  {
    id: 'May',
    month: 'May',
    label: 'May',
    value: 65,
  },
  {
    id: 'Jun',
    month: 'Jun',
    label: 'Jun',
    value: 78,
  },
  {
    id: 'Jul',
    month: 'Jul',
    label: 'Jul',
    value: 55,
  },
  {
    id: 'Aug',
    month: 'Aug',
    label: 'Aug',
    value: 40,
  },
  {
    id: 'Sep',
    month: 'Sep',
    label: 'Sep',
    value: 38,
  },
  {
    id: 'Oct',
    month: 'Oct',
    label: 'Oct',
    value: 46,
  },
  {
    id: 'Nov',
    month: 'Nov',
    label: 'Nov',
    value: 69,
  },
  {
    id: 'Dec',
    month: 'Dec',
    label: 'Dec',
    value: 0,
  },
]

const Chart = ({ yearlyData, monthlyData, changeChartType, chartType }) => (
  <ResponsiveBar
    onClick={changeChartType}
    data={chartType == 'yearly' ? yearlyData : monthlyData}
    keys={['value']}
    indexBy="label"
    margin={{ top: 20, right: 30, bottom: 50, left: 45 }}
    padding={0.3}
    colors={['#379AEC', '#37DF26', '#E0E52E', '#E52E3F']}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend:
        chartType == 'yearly' ? (
          <LangContext.Consumer>{i18n => i18n.m.month}</LangContext.Consumer>
        ) : (
          <LangContext.Consumer>{i18n => i18n.d.day}</LangContext.Consumer>
        ),
      legendPosition: 'middle',
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: (
        <LangContext.Consumer>{i18n => i18n.a.amount}</LangContext.Consumer>
      ),
      legendPosition: 'middle',
      legendOffset: -37,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 2.5]] }}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
)

export class SpeedOverlimitWidget extends Component {
  state = {
    chartType: 'yearly',
    loading: false,
    data: [],
    yearlyData: [],
    monthlyData: [],
    dpType: 'none-dp',
  }

  onSortDpChange = val => {
    this.setState(
      {
        dpType: val,
        data: [],
      },
      () => {
        this.loadData(true, this.state.chartType, val)
      },
    )
  }

  sortDay(a, b) {
    if (a.data < b.data) {
      return -1
    }
    if (a.data > b.data) {
      return 1
    }
    return 0
  }

  sortMonth(a, b) {
    if (a.index < b.index) {
      return -1
    }
    if (a.index > b.index) {
      return 1
    }
    return 0
  }

  switchChartType = (loading, chartType) => {
    if (chartType != 'yearly') {
      let monthly = this.state.data.find((ele, index) => index == chartType - 1)

      monthly = monthly.data
      monthly = monthly.sort(this.sortDay)
      monthly = monthly.map((ele, index) => {
        return {
          id: ele.date,
          label: ele.date,
          month: ele.date,
          value: ele.count,
        }
      })
      this.setState({ monthlyData: monthly })
    }
  }

  loadData = (loading, chartType, dpType) => {
    if (loading) this.setState({ loading: true })

    this.fetch(this, chartType, dpType)
  }

  fetch = (self, chartType, dpType) => {
    let keepDp = true
    if (dpType === 'none-dp') {
      keepDp = false
    }
    let formData = new FormData()
    formData.append('only_has_dp', keepDp)
    formData.append('user_id', self.props.auth.profile.id)
    formData.append('company_id', self.props.auth.profile.company_id)
    formData.append(
      'vehicle_visibility',
      self.props.auth.profile.vehicle_visibility,
    )
    fetch(
      `${process.env.REACT_APP_GO_V2_API_ENDPOINT}/summary/speed_over_limit`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',

          Authorization: `Bearer ${self.props.auth.accessToken}`,
        },
        body: formData,
      },
    )
      .then(response => response.json())
      .then(res => {
        if (Array.isArray(res)) {
          self.setState({
            loading: false,
            data: res.sort(this.sortMonth),
            yearlyData: res
              .map((ele, index) => {
                let amount = 0
                const reducer = (accumulator, currentValue) =>
                  accumulator + currentValue.count

                amount = ele.data.reduce(reducer, 0)

                return {
                  id: ele.label,
                  label: ele.label,
                  month: index + 1,
                  value: amount,
                }
              })
              .sort(this.sortMonth),
          })
        }
      })
      .catch(function() {
        self.setState({
          loading: false,
          data: [],
        })
      })
  }
  componentDidMount() {
    if (!this.props.demo) {
      this.loadData(true, this.state.chartType, this.state.dpType)
      this.interval = setInterval(
        () => this.loadData(false, this.state.chartType, this.state.dpType),
        1000 * 60 * 60,
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    if (this.props.demo)
      return (
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ height: '90%', width: '100%' }}
        >
          <span className="semi-bold-16">
            <LangContext.Consumer>
              {i18n => i18n.s.speedOverLimit}
            </LangContext.Consumer>
          </span>

          <Chart
            yearlyData={fakeData}
            monthlyData={[]}
            chartType={this.state.chartType}
          />
        </Col>
      )
    else
      return (
        <StyledWidget>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right"></Col>
          </Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ height: '95%' }}
          >
            <Spin
              spinning={this.state.loading}
              style={{
                textAlign: 'center',
                display: 'block',
                marginTop: '15%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span className="semi-bold-16">
                    <LangContext.Consumer>
                      {i18n => i18n.s.speedOverLimitTime}
                    </LangContext.Consumer>
                    {this.state.chartType != 'yearly' &&
                      ` : ${this.state.chartType}`}{' '}
                  </span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                  {this.state.chartType != 'yearly' ? (
                    <ButtonGroup size="small">
                      <Button
                        onClick={e => {
                          this.setState({ chartType: 'yearly' })
                          this.loadData(true, 'yearly', this.state.dpType)
                        }}
                      >
                        <Icon type="arrow-left" />{' '}
                        <LangContext.Consumer>
                          {i18n => i18n.b.back}
                        </LangContext.Consumer>
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <Select
                      size="small"
                      defaultValue="none-dp"
                      style={{ width: 132, marginRight: '8px' }}
                      onChange={this.onSortDpChange}
                    >
                      <Option
                        value="none-dp"
                        style={{ backgroundColor: 'white' }}
                        // className='hover-red'
                      >
                        <small>ไม่นับ DP</small>
                      </Option>
                      <Option
                        value="dp"
                        style={{ backgroundColor: 'white' }}
                        // className='hover-red'
                      >
                        <small>นับ DP</small>
                      </Option>
                    </Select>
                  )}
                </Col>
              </Row>
            </Spin>

            <Chart
              yearlyData={this.state.yearlyData}
              monthlyData={this.state.monthlyData}
              changeChartType={(e, i) => {
                if (isNaN(e.data.label)) {
                  this.setState({ chartType: e.data.label })
                  this.switchChartType(true, e.data.month)
                }
              }}
              chartType={this.state.chartType}
            />
          </Col>
        </StyledWidget>
      )
  }
}

export default SpeedOverlimitWidget
