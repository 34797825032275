import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Tag,
  Spin,
  Modal,
  Popconfirm,
  Badge,
  Icon,
  Tooltip,
  Drawer,
  Select,
  DatePicker,
  message,
} from 'antd'

import Filter from './Filter'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import * as actions from '../actions'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from './../../../shared/components/Breadcrumb'
import { helper_convertDateformat } from '../../../../lib/helper'

const columnStyle = { fontSize: '12px', fontWeight: '400' }

class Index extends Component {
  state = {
    visible: false,
  }

  componentWillUnmount() {
    this.props.clearMdvr()
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onCloseSearch = resetForm => {
    this.setState({
      visible: false,
    })
  }

  filterMdvrdownloadfile = values => {
    this.props.loadMdvrdownloadfile(values, true)
  }

  loadVehicle = () => {
    const { loadVehicle } = this.props
    loadVehicle()
  }

  render() {
    const columns = [
      {
        title: <div className="regular-bold-14">#</div>,
        className: 'column-text-center',
        dataIndex: 'row_number',
        width: '4%',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.p.plateNo}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'plate_no',
        sorter: false,
        // render: name => `${name.first} ${name.last}`,
        width: '15%',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.t.time_period}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'time',
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
        width: '20%',
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.v.video_type}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'video_type',
        sorter: false,
        // render: name => `${name.first} ${name.last}`,
        width: '12%',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.c.channel}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'channel',
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
        width: '10%',
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.f.file_location}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'file_location',
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
        width: '10%',
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.f.file_size}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'size',
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
        width: '10%',
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.d.download}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: '',
        sorter: false,
        render: (text, record, index) => {
          return (
            <div>
              <a
                href={record.path_download}
                download={record.file_name}
                target="_blank"
                style={{ padding: '0px 15px 0px 5px' }}
              >
                <Icon type="download" />
              </a>
            </div>
          )
        },
        width: '10%',
      },
    ]

    return (
      <div>
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              {/* <Breadcrumb
                                match={this.props.match}
                                style={{ margin: "0px 0px 14px 10px" }}
                            /> */}
              <div className="semi-bold-16" style={{ color: 'black' }}>
                <LangContext.Consumer>
                  {i18n => i18n.d.mdvrdownloadfile}
                </LangContext.Consumer>
              </div>
            </Col>
          </Row>
          <Row gutter={24} type="flex" justify="end" style={{ height: '38px' }}>
            <Col span={22}></Col>

            <Col span={2}>
              <Button icon="filter" size="small" onClick={this.showDrawer}>
                <LangContext.Consumer>
                  {i18n => i18n.f.filter}
                </LangContext.Consumer>
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            columns={columns}
            rowKey={record => record.row_number}
            dataSource={this.props.mdvrdownloadfile.mdvrdownloadfileLists}
            pagination={false}
            loading={this.props.mdvrdownloadfile.mdvrdownloadfileLoading}
            // onChange={this.handleTableChange}
          />

          <div>
            <LangContext.Consumer>
              {i18n => (
                <Drawer
                  title={i18n.s.search}
                  width={400}
                  onClose={this.onCloseSearch}
                  visible={this.state.visible}
                >
                  <Filter
                    OnClose={this.onCloseSearch}
                    onSubmit={this.filterMdvrdownloadfile}
                    vehicleLists={this.props.mdvrdownloadfile.vehicleLists}
                    loadVehicle={this.loadVehicle}
                  />
                </Drawer>
              )}
            </LangContext.Consumer>
          </div>
        </GeneralStyledContent>
      </div>
    )
  }
}

const mapStateToProps = ({ mdvrdownloadfile, auth: { profile } }) => ({
  mdvrdownloadfile,
  profile,
})

const mapDispatchToProps = {
  loadMdvrdownloadfile: actions.loadMdvrdownloadfile.request,
  loadVehicle: actions.loadVehicle.request,
  clearMdvr: actions.clearMdvr,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
