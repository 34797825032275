import { LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_GENERAL_DATA } from './actions'

const initialState = { data: [], total: 1, loading: false }

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_GENERAL_DATA.REQUEST: {
      const { loading } = action.payload.data
      return {
        ...state,
        loading,
      }
    }

    case LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_GENERAL_DATA.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        loading: false,
        total: action.payload.data.total,
      }

    default:
      return state
  }
}
