import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadSaleorder(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      filterObj,
      company_id,
      is_admin,
      is_vendor,
      location_visibility,
      exactly_location,
      mill_location_list,
      plant_location_list,
      material_visibility,
      type,
      limit_export,
      file_value_csv,
      accessToken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-saleorder-list`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id,
        is_admin,
        is_vendor,
        location_visibility,
        exactly_location,
        mill_location_list,
        plant_location_list,
        material_visibility,
        type,
        limit_export,
        file_value_csv,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadSaleorder.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadSaleorder.failure())
  }
}

function* exportSaleorder(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      filterObj,
      company_id,
      is_admin,
      is_vendor,
      location_visibility,
      exactly_location,
      mill_location_list,
      plant_location_list,
      material_visibility,
      type,
      limit_export,
      file_value_csv,
      accessToken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-saleorder-list`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id,
        is_admin,
        is_vendor,
        location_visibility,
        exactly_location,
        mill_location_list,
        plant_location_list,
        material_visibility,
        type,
        limit_export,
        file_value_csv,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportSaleorder.success(res.data, page, pageSize))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportSaleorder.failure())
  }
}

export default function* watchIsupplySaleorder() {
  yield all([
    takeEvery(actions.LOAD_SALEORDER.REQUEST, loadSaleorder),
    takeEvery(actions.EXPORT_SALEORDER.REQUEST, exportSaleorder),
  ])
}

export { loadSaleorder, exportSaleorder }
