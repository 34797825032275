import create from 'antd/lib/icon/IconFont'
import { createTypes, createAction } from 'lib/action'

const LOAD_DIVISION = createTypes('load_division', 'load')
const LOAD_DEPARTMENT = createTypes('load_department', 'load')
const LOAD_SECTION = createTypes('load_section', 'load')
const LOAD_PLANT = createTypes('load_plant', 'load')
const LOAD_VEHICLE_GROUP = createTypes('load_vehicle_group', 'load')
const LOAD_VEHICLE = createTypes('load_vehicle', 'load')

const loadDivision = {
  request: (division_visibility, comp, company_id, successLoading) =>
    createAction(LOAD_DIVISION.REQUEST, {
      division_visibility,
      comp,
      company_id,
      successLoading,
    }),
  success: data => createAction(LOAD_DIVISION.SUCCESS, { data }),
  failure: () => createAction(LOAD_DIVISION.FAILURE),
}

const loadDepartment = {
  request: (department_visibility, division, company_id, successLoading) =>
    createAction(LOAD_DEPARTMENT.REQUEST, {
      department_visibility,
      division,
      company_id,
      successLoading,
    }),
  success: data => createAction(LOAD_DEPARTMENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_DEPARTMENT.FAILURE),
}

const loadSection = {
  request: (section_visibility, department, company_id, successLoading) =>
    createAction(LOAD_SECTION.REQUEST, {
      section_visibility,
      department,
      company_id,
      successLoading,
    }),
  success: data => createAction(LOAD_SECTION.SUCCESS, { data }),
  failure: () => createAction(LOAD_SECTION.FAILURE),
}

const loadPlant = {
  request: (
    location_visibility,
    section_visibility,
    section,
    relate_company,
    successLoading,
  ) =>
    createAction(LOAD_PLANT.REQUEST, {
      location_visibility,
      section_visibility,
      section,
      relate_company,
      successLoading,
    }),
  success: data => createAction(LOAD_PLANT.SUCCESS, { data }),
  failure: () => createAction(LOAD_PLANT.FAILURE),
}

const loadVehicleGroup = {
  request: (comp, successLoading) =>
    createAction(LOAD_VEHICLE_GROUP.REQUEST, {
      comp,
      successLoading,
    }),
  success: data => createAction(LOAD_VEHICLE_GROUP.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_GROUP.FAILURE),
}

const loadVehicle = {
  request: (
    vehicle_group,
    section_visibility,
    division,
    department,
    section,
    plant,
    vehicle_visibility,
    partner,
  ) =>
    createAction(LOAD_VEHICLE.REQUEST, {
      vehicle_group,
      section_visibility,
      division,
      department,
      section,
      plant,
      vehicle_visibility,
      partner,
    }),
  success: data => createAction(LOAD_VEHICLE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE.FAILURE),
}

export {
  LOAD_DIVISION,
  loadDivision,
  LOAD_DEPARTMENT,
  loadDepartment,
  LOAD_SECTION,
  loadSection,
  LOAD_PLANT,
  loadPlant,
  LOAD_VEHICLE_GROUP,
  loadVehicleGroup,
  LOAD_VEHICLE,
  loadVehicle,
}
