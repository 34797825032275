import { createTypes, createAction } from 'lib/action'

const LOAD_BEHAVIORDRIVINGBYCOMPANY = createTypes(
  'behaviordrivingbycompany',
  'load',
)
const LOAD_BEHAVIORDRIVINGBYCOMPANY_PREVIEW = createTypes(
  'behaviordrivingbycompanypreview',
  'load',
)
const LOAD_VEHICLE_BEHAVIORDRIVINGBYCOMPANY = createTypes(
  'vehicle_behaviordrivingbycompany',
  'load',
)

const LOAD_COMPANY_PARTNER = createTypes('company_partner', 'load')

const loadBehaviordrivingbycompany = {
  request: (data, loading) =>
    createAction(LOAD_BEHAVIORDRIVINGBYCOMPANY.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_BEHAVIORDRIVINGBYCOMPANY.SUCCESS, { data }),
  failure: () => createAction(LOAD_BEHAVIORDRIVINGBYCOMPANY.FAILURE),
}

const loadBehaviordrivingbycompanypreview = {
  request: (data, loading) =>
    createAction(LOAD_BEHAVIORDRIVINGBYCOMPANY_PREVIEW.REQUEST, {
      data,
      loading,
    }),
  success: data =>
    createAction(LOAD_BEHAVIORDRIVINGBYCOMPANY_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_BEHAVIORDRIVINGBYCOMPANY_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (partnerID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_BEHAVIORDRIVINGBYCOMPANY.REQUEST, {
      partnerID,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_BEHAVIORDRIVINGBYCOMPANY.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_BEHAVIORDRIVINGBYCOMPANY.FAILURE),
}

const loadPartner = {
  request: () => createAction(LOAD_COMPANY_PARTNER.REQUEST, {}),
  success: data => createAction(LOAD_COMPANY_PARTNER.SUCCESS, { data }),
  failure: () => createAction(LOAD_COMPANY_PARTNER.FAILURE),
}

export {
  loadBehaviordrivingbycompany,
  LOAD_BEHAVIORDRIVINGBYCOMPANY,
  loadBehaviordrivingbycompanypreview,
  LOAD_BEHAVIORDRIVINGBYCOMPANY_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_BEHAVIORDRIVINGBYCOMPANY,
  LOAD_COMPANY_PARTNER,
  loadPartner,
}
