import { createTypes, createAction } from "lib/action";

const LOAD_SUMMARYKPI = createTypes("summarykpi", "load");
const LOAD_SUMMARYKPI_PREVIEW = createTypes("summarykpi_preview", "load");
const LOAD_VEHICLE_SUMMARYKPI = createTypes(
  "vehicle_summarykpi",
  "load"
);
const LOAD_VEHICLE_TYPE_SUMMARYKPI = createTypes("vehicletype_summarykpi", "load");

const loadSummarykpi = {
  request: (data, loading) =>
    createAction(LOAD_SUMMARYKPI.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SUMMARYKPI.SUCCESS, { data }),
  failure: () => createAction(LOAD_SUMMARYKPI.FAILURE)
};

const loadSummarykpipreview = {
  request: (data, preview_loading) =>
    createAction(LOAD_SUMMARYKPI_PREVIEW.REQUEST, { data, preview_loading }),
  success: data => createAction(LOAD_SUMMARYKPI_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_SUMMARYKPI_PREVIEW.FAILURE)
};

const loadVehicle = {
  request: (loading_vehicle) =>
    createAction(LOAD_VEHICLE_SUMMARYKPI.REQUEST, {
      loading_vehicle
    }),
  success: data =>
    createAction(LOAD_VEHICLE_SUMMARYKPI.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_SUMMARYKPI.FAILURE)
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_SUMMARYKPI.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_SUMMARYKPI.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_SUMMARYKPI.FAILURE)
};

export {
  loadSummarykpi,
  LOAD_SUMMARYKPI,
  loadSummarykpipreview,
  LOAD_SUMMARYKPI_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_SUMMARYKPI,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_SUMMARYKPI
};
