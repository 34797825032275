import {
  LOAD_MASTERDATA_VEHICLE_TYPE,
  LOAD_MASTERDATA_VEHICLE_TYPE_DETAIL,
  UPDATE_MASTERDATA_VEHICLE_TYPE_DETAIL,
  EXPORT_MASTERDATA_VEHICLE_TYPE,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  lists: [],
  data: {
    id: '',
    vehicle_types_name: '',
    name_key: '',
    material_list: [],
    max_weight: 0,
    truck_type_no: '',
  },
  total: 1,
  loading: false,
  draw: -1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_MASTERDATA_VEHICLE_TYPE.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        loading: loading,
      }

    case LOAD_MASTERDATA_VEHICLE_TYPE.SUCCESS:
      const { page, pageSize } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * pageSize
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_MASTERDATA_VEHICLE_TYPE_DETAIL.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOAD_MASTERDATA_VEHICLE_TYPE_DETAIL.SUCCESS:
      return {
        ...state,
        data: action.payload.lists.data,
        loading: false,
      }

    case UPDATE_MASTERDATA_VEHICLE_TYPE_DETAIL.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_MASTERDATA_VEHICLE_TYPE_DETAIL.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case EXPORT_MASTERDATA_VEHICLE_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_MASTERDATA_VEHICLE_TYPE.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
