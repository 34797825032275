import { createTypes, createAction } from "lib/action";
const LOAD_ACTION_LOG = createTypes("actionlog", "load");
const LOAD_ACTION_LOG_BY_ENTITY = createTypes("actionlogbyentity", "load");
const LOAD_ACTION_LOG_DETAIL = createTypes("actionlogdetail", "load");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadActionLog = {
    request: (data, loading) =>
        createAction(LOAD_ACTION_LOG.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_ACTION_LOG.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_ACTION_LOG.FAITLURE)  
};

const loadActionLogDetail = {
    request: (data, loading) =>
        createAction(LOAD_ACTION_LOG_DETAIL.REQUEST, {data, loading}),
    success: (lists) =>
        createAction(LOAD_ACTION_LOG_DETAIL.SUCCESS, {lists}),
    failure: () => createAction(LOAD_ACTION_LOG_DETAIL.FAITLURE)
};

const loadActionLogByEntity = {
    request: (data, loading) =>
        createAction(LOAD_ACTION_LOG_BY_ENTITY.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(LOAD_ACTION_LOG_BY_ENTITY.SUCCESS, {lists}),
    failure: () => createAction(LOAD_ACTION_LOG_BY_ENTITY.FAITLURE)  
};

export {
    LOAD_ACTION_LOG, loadActionLog,
    LOAD_ACTION_LOG_BY_ENTITY, loadActionLogByEntity,
    LOAD_ACTION_LOG_DETAIL, loadActionLogDetail,
    REMOVE_LISTS, removeLists
}