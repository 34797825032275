import {
  LOAD_ISUPPLYWEIGHTHISTORY_REPORT,
  EXPORT_ISUPPLYWEIGHTHISTOR_REPORT,
  LOADDATABYDNNUMBER_ISUPPLYWEIGHTHISTOR_REPORT,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  listsDataForDetail: [],
  loadingForDetail: false,
  checkLoaddatafordetail: true,
  draw: -1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_ISUPPLYWEIGHTHISTORY_REPORT.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
      }

    case EXPORT_ISUPPLYWEIGHTHISTOR_REPORT.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOADDATABYDNNUMBER_ISUPPLYWEIGHTHISTOR_REPORT.REQUEST:
      return {
        ...state,
        loadingForDetail: action.payload.data.loading,
        checkLoaddatafordetail: true,
      }

    case LOAD_ISUPPLYWEIGHTHISTORY_REPORT.SUCCESS:
      return {
        ...state,
        lists: action.payload.lists.data,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case EXPORT_ISUPPLYWEIGHTHISTOR_REPORT.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOADDATABYDNNUMBER_ISUPPLYWEIGHTHISTOR_REPORT.SUCCESS:
      return {
        ...state,
        listsDataForDetail: action.payload.lists.data,
        loadingForDetail: false,
        checkLoaddatafordetail: false,
      }

    default:
      return state
  }
}
