import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadManagementVehicleVisibilityPermissionGeneralData(action) {
  const {
    data: {
      fleetlinkVehicleNumber,
      fleetlinkVehiclePlateNo,
      terminusVehicleNumber,
      terminusVehiclePlateNo,
      filterType,
      auth,
      page,
      pageSize,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-visibility`,
      {
        fleetlinkVehicleNumber,
        fleetlinkVehiclePlateNo,
        terminusVehicleNumber,
        terminusVehiclePlateNo,
        filterType,
        page,
        pageSize,
      },
      { headers: { Authorization: `Bearer ${auth}` } },
    )
    yield put(
      actions.loadManagementVehicleVisibilityPermissionGeneralData.success(
        res.data,
        page,
      ),
    )
  } catch (err) {
    console.log(err.message)
    yield put(
      actions.loadManagementVehicleVisibilityPermissionGeneralData.failure(),
    )
  }
}

export default function* watchVehiclevisibilityPermissionGeneralData() {
  yield all([
    takeEvery(
      actions.LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_GENERAL_DATA
        .REQUEST,
      loadManagementVehicleVisibilityPermissionGeneralData,
    ),
  ])
}

export { loadManagementVehicleVisibilityPermissionGeneralData }
