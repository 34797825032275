import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadLocationgroup(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      location_visibility,
      location_group_visibility,
      accessToken,
      filterObj,
      relate_company,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/locationgroup`,
      {
        page,
        pageSize,
        searchName,
        companyID,
        orderBy,
        orderType,
        location_visibility,
        location_group_visibility,
        filterObj,
        relate_company,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadLocationgroup.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadLocationgroup.failure())
  }
}

function* createMasterDataLocationGroup(action) {
  const {
    data: {
      companyID,
      name,
      location_group_type_id,
      locations_id,
      dataVisibilityNode,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/locationgroup/create`,
      {
        companyID,
        name,
        location_group_type_id,
        locations_id,
        dataVisibilityNode,
        matching_name: 'location-group',
        action_name: 'add',
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createMasterDataLocationGroup.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createMasterDataLocationGroup.failure())
  }
}

function* updateMasterDataLocationGroup(action) {
  const {
    data: {
      companyID,
      location_group_id,
      name,
      location_group_type_id,
      locations_id,
      accessToken,
      dataVisibilityNode,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/locationgroup/update`,
      {
        companyID,
        location_group_id,
        name,
        location_group_type_id,
        locations_id,
        dataVisibilityNode,
        matching_name: 'location-group',
        action_name: 'edit',
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateMasterDataLocationGroup.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateMasterDataLocationGroup.failure())
  }
}

function* exportLocationgroup(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      location_visibility,
      location_group_visibility,
      accessToken,
      filterObj,
      type,
      file_value_pdf,
      limit_export,
      relate_company,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/locationgroup`,
      {
        page,
        pageSize,
        searchName,
        companyID,
        orderBy,
        orderType,
        location_visibility,
        location_group_visibility,
        accessToken,
        filterObj,
        type,
        file_value_pdf,
        limit_export,
        relate_company,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportLocationgroup.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    yield put(actions.exportLocationgroup.failure())
  }
}

export default function* watchLocationGroup() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_LOCATIONGROUP.REQUEST, loadLocationgroup),
    takeEvery(
      actions.CREATE_MASTERDATA_LOCATIONGROUP.REQUEST,
      createMasterDataLocationGroup,
    ),
    takeEvery(
      actions.UPDATE_MASTERDATA_LOCATIONGROUP.REQUEST,
      updateMasterDataLocationGroup,
    ),
    takeEvery(
      actions.EXPORT_MASTERDATA_LOCATIONGROUP.REQUEST,
      exportLocationgroup,
    ),
  ])
}
export {
  loadLocationgroup,
  createMasterDataLocationGroup,
  updateMasterDataLocationGroup,
  exportLocationgroup,
}
