import axios from 'axios'
import { all, put, call, takeEvery } from 'redux-saga/effects'
import * as actions from './actions'

function* loadDivision(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const {
    division_visibility,
    comp,
    company_id,
    successLoading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/division`,
      {
        division_visibility: division_visibility,
        comp: comp,
        company_id: company_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadDivision.success(res.data))
    successLoading()
  } catch (err) {
    yield put(actions.loadDivision.failure())
  }
}

function* loadDepartment(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const company_id = JSON.parse(localStorage.getItem('profile')).company_id
  const { department_visibility, division, successLoading } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`,
      JSON.stringify({
        department_visibility: department_visibility,
        division: [division],
        company_id,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.loadDepartment.success(res.data))
    successLoading()
  } catch (err) {
    yield put(actions.loadDepartment.failure())
  }
}

function* loadSection(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const company_id = JSON.parse(localStorage.getItem('profile')).company_id

  const { section_visibility, department, successLoading } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/section`,
      JSON.stringify({
        section_visibility: section_visibility,
        department: department,
        company_id,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.loadSection.success(res.data))
    successLoading()
  } catch (err) {
    yield put(actions.loadSection.failure())
  }
}

function* loadPlant(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  const {
    location_visibility,
    section_visibility,
    section,
    relate_company,
    successLoading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/plant`,
      JSON.stringify({
        location_visibility: location_visibility,
        section_visibility: section_visibility,
        section: section,
        relate_company: relate_company,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.loadPlant.success(res.data))
    successLoading()
  } catch (err) {
    yield put(actions.loadPlant.failure())
  }
}
function* loadVehicleGroup(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  const { comp, successLoading } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      JSON.stringify({
        company_id: comp,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.loadVehicleGroup.success(res.data.data))
    successLoading()
  } catch (err) {
    yield put(actions.loadVehicleGroup.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const {
    vehicle_group,
    section_visibility,
    division,
    department,
    section,
    plant,
    vehicle_visibility,
    partner,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclesbydivisionbranch`,
      JSON.stringify({
        vehicle_group: vehicle_group,
        section_visibility: section_visibility,
        division: division,
        department: department,
        section: section,
        plant: plant,
        vehicle_visibility: vehicle_visibility,
        partner: partner,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.loadVehicle.success(res.data.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadTruckSharingType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/cpac-truck-sharing-type`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadTruckSharingType.success(res.data.data))
  } catch (err) {
    yield put(actions.loadTruckSharingType.failure())
  }
}

function* loadTruckSharingCurrentLocation(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { locationVisibility } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/truck-sharing-current-location`,
      JSON.stringify({}),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.loadTruckSharingCurrentLocation.success(res.data.data))
  } catch (err) {
    yield put(actions.loadTruckSharingCurrentLocation.failure())
  }
}

function* loadTruckSharingAvailableAccount(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { page, pageSize, filterObj, vehicleVisibility } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account`,
      JSON.stringify({
        page: page,
        pageSize: pageSize,
        filterObj: filterObj,
        vehicleVisibility: vehicleVisibility,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.loadTruckSharingAvailableAccount.success(res.data.data))
  } catch (err) {
    yield put(actions.loadTruckSharingAvailableAccount.failure())
  }
}

function* updateTruckSharingType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id, type } = action.payload
  try {
    const res = yield call(
      axios.patch,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/cpac-truck-sharing-type/${id}`,
      JSON.stringify({
        cpac_truck_sharing_type_id: type,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.updateTruckSharingType.success(res.data))
  } catch (err) {
    yield put(actions.updateTruckSharingType.failure())
  }
}

function* updateTruckSharingCurrentLocation(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id, type } = action.payload
  try {
    const res = yield call(
      axios.patch,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/truck-sharing-current-location/${id}`,
      JSON.stringify({
        truck_sharing_current_location_id: type,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.updateTruckSharingCurrentLocation.success(res.data))
  } catch (err) {
    yield put(actions.updateTruckSharingCurrentLocation.failure())
  }
}

function* updateTruckSharingAvailable(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id, type } = action.payload
  try {
    const res = yield call(
      axios.patch,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/truck-sharing-is-avaliable/${id.id}`,
      JSON.stringify({
        truck_sharing_is_avaliable: type,
        truck_sharing_current_location_id: id.truck_sharing_current_location_id,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.updateTruckSharingAvailable.success(res.data))
  } catch (err) {
    yield put(actions.updateTruckSharingAvailable.failure(err))
  }
}

function* updateDriverMobilePhone(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id, phone } = action.payload
  try {
    const res = yield call(
      axios.patch,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/driver-mobile-phone/${id}`,
      JSON.stringify({
        mobile_phone: phone,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.updateDriverMobilePhone.success(res.data))
  } catch (err) {
    yield put(actions.updateDriverMobilePhone.failure(err))
  }
}

function* updateTruckSharingContractPhone(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id, contractPhone } = action.payload
  try {
    const res = yield call(
      axios.patch,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/truck-sharing-contact-phone/${id}`,
      JSON.stringify({
        truck_sharing_contact_phone: contractPhone,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.updateTruckSharingContractPhone.success(res.data))
  } catch (err) {
    yield put(actions.updateTruckSharingContractPhone.failure(err))
  }
}

function* loadTruckSharingAvailableAccountReq(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { page, pageSize, filterObj } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/request`,
      JSON.stringify({
        page,
        pageSize,
        filterObj,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(
      actions.loadTruckSharingAvailableAccountReq.success(res.data.data),
    )
  } catch (err) {
    yield put(actions.loadTruckSharingAvailableAccountReq.failure())
  }
}

function* loadTruckSharingAvailableAccountReqToCreate(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const {
    vehicleId,
    destination,
    userName,
    contactPhone,
    dateStart,
    dateEnd,
    period,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/request/create`,
      JSON.stringify({
        vehicleId,
        destination,
        userName,
        contactPhone,
        dateStart,
        dateEnd,
        period,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(
      actions.loadTruckSharingAvailableAccountReqToCreate.success(res.data),
    )
  } catch (err) {
    yield put(actions.loadTruckSharingAvailableAccountReqToCreate.failure(err))
  }
}

function* loadTruckSharingAvailableAccountRequestToUse(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { page, pageSize, vehicle_visibility } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/appove`,
      JSON.stringify({
        page,
        pageSize,
        vehicleVisibility: vehicle_visibility,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(
      actions.loadTruckSharingAvailableAccountRequestToUse.success(
        res.data.data,
      ),
    )
  } catch (err) {
    yield put(actions.loadTruckSharingAvailableAccountRequestToUse.failure())
  }
}

function* loadTruckSharingAvailableAccountAppove(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { page, pageSize, vehicle_visibility } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/appove`,
      JSON.stringify({
        page,
        pageSize,
        vehicleVisibility: vehicle_visibility,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(
      actions.loadTruckSharingAvailableAccountAppove.success(res.data.data),
    )
  } catch (err) {
    yield put(actions.loadTruckSharingAvailableAccountAppove.failure())
  }
}

function* loadTruckSharingAvailableAccountAppovePatch(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { reserves } = action.payload
  try {
    const res = yield call(
      axios.patch,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/appove`,
      JSON.stringify({
        reserves,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(
      actions.loadTruckSharingAvailableAccountAppovePatch.success(res.data),
    )
  } catch (err) {
    yield put(actions.loadTruckSharingAvailableAccountAppovePatch.failure(err))
  }
}

function* loadTruckSharingAvailableAccountHistory(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { page, pageSize, filterObj, vehicle_visibility } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/history`,
      JSON.stringify({
        page,
        pageSize,
        filterObj,
        vehicleVisibility: vehicle_visibility,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(
      actions.loadTruckSharingAvailableAccountHistory.success(res.data.data),
    )
  } catch (err) {
    yield put(actions.loadTruckSharingAvailableAccountHistory.failure())
  }
}

function* deleteTruckSharingById(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id } = action.payload
  try {
    const res = yield call(
      axios.delete,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/history/cancel/${id}`,
      {
        headers: {
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.deleteTruckSharingById.success(res.data))
  } catch (err) {
    yield put(actions.deleteTruckSharingById.failure())
  }
}

function* updateRowCpacTruckSharing(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id, obj } = action.payload
  try {
    const res = yield call(
      axios.patch,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/cpac-truck-sharing/${id}`,
      JSON.stringify(obj),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )
    yield put(actions.updateRowCpacTruckSharing.success(res.data))
  } catch (err) {
    yield put(actions.updateRowCpacTruckSharing.failure(err))
  }
}

function* getCpacTruckSharingVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/cpac-truck-sharing-vehicle-type`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.getCpacTruckSharingVehicleType.success(res.data.data))
  } catch (err) {
    yield put(actions.getCpacTruckSharingVehicleType.failure())
  }
}

function* getTruckSharingLevel(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/truck-shariing-level`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.getTruckSharingLevel.success(res.data.data))
  } catch (err) {
    yield put(actions.getTruckSharingLevel.failure())
  }
}

export default function* watchVehicleSharingSystemAvaliableAccount() {
  yield all([takeEvery(actions.LOAD_DIVISION.REQUEST, loadDivision)])
  yield all([takeEvery(actions.LOAD_DEPARTMENT.REQUEST, loadDepartment)])
  yield all([takeEvery(actions.LOAD_SECTION.REQUEST, loadSection)])
  yield all([takeEvery(actions.LOAD_PLANT.REQUEST, loadPlant)])
  yield all([takeEvery(actions.LOAD_VEHICLE_GROUP.REQUEST, loadVehicleGroup)])
  yield all([takeEvery(actions.LOAD_VEHICLE.REQUEST, loadVehicle)])
  yield all([
    takeEvery(actions.LOAD_TRUCK_SHARING_TYPE.REQUEST, loadTruckSharingType),
  ])
  yield all([
    takeEvery(
      actions.LOAD_TRUCK_SHARING_CURRUNT_LOCATION.REQUEST,
      loadTruckSharingCurrentLocation,
    ),
  ])
  yield all([
    takeEvery(
      actions.LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT.REQUEST,
      loadTruckSharingAvailableAccount,
    ),
  ])
  yield all([
    takeEvery(
      actions.LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ.REQUEST,
      loadTruckSharingAvailableAccountReq,
    ),
  ])

  yield all([
    takeEvery(
      actions.UPDATE_TRUCK_SHARING_TYPE.REQUEST,
      updateTruckSharingType,
    ),
  ])
  yield all([
    takeEvery(
      actions.UPDATE_TRUCK_SHARING_CURRUNT_LOCATION.REQUEST,
      updateTruckSharingCurrentLocation,
    ),
  ])
  yield all([
    takeEvery(
      actions.UPDATE_TRUCK_SHARING_AVAILABLE.REQUEST,
      updateTruckSharingAvailable,
    ),
  ])
  yield all([
    takeEvery(
      actions.UPDATE_DRIVER_MOBILE_PHONE.REQUEST,
      updateDriverMobilePhone,
    ),
  ])

  yield all([
    takeEvery(
      actions.UPDATE_ROW_CPAC_TRUCK_SHARING.REQUEST,
      updateRowCpacTruckSharing,
    ),
  ])

  yield all([
    takeEvery(
      actions.UPDATE_CONTRACT_PHONE.REQUEST,
      updateTruckSharingContractPhone,
    ),
  ])
  yield all([
    takeEvery(
      actions.LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ_CREATE.REQUEST,
      loadTruckSharingAvailableAccountReqToCreate,
    ),
  ])

  yield all([
    takeEvery(
      actions.LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE.REQUEST,
      loadTruckSharingAvailableAccountAppove,
    ),
  ])

  yield all([
    takeEvery(
      actions.LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE_PATCH.REQUEST,
      loadTruckSharingAvailableAccountAppovePatch,
    ),
  ])

  yield all([
    takeEvery(
      actions.LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQUEST_TO_USE.REQUEST,
      loadTruckSharingAvailableAccountRequestToUse,
    ),
  ])

  yield all([
    takeEvery(
      actions.LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_HISTORY.REQUEST,
      loadTruckSharingAvailableAccountHistory,
    ),
  ])

  yield all([
    takeEvery(actions.DELETE_TRUCK_SHARING.REQUEST, deleteTruckSharingById),
  ])

  yield all([
    takeEvery(
      actions.GET_CPAC_TRUCK_SHARING_VEHICLE_TYPE.REQUEST,
      getCpacTruckSharingVehicleType,
    ),
  ])

  yield all([
    takeEvery(actions.GET_TRUCK_SHARING_LEVEL.REQUEST, getTruckSharingLevel),
  ])
}
export {
  updateTruckSharingType,
  updateTruckSharingCurrentLocation,
  updateTruckSharingAvailable,
  updateDriverMobilePhone,
  updateRowCpacTruckSharing,
  updateTruckSharingContractPhone,
  loadTruckSharingAvailableAccount,
  loadTruckSharingAvailableAccountReq,
  loadTruckSharingAvailableAccountReqToCreate,
  loadTruckSharingCurrentLocation,
  loadTruckSharingType,
  loadDivision,
  loadDepartment,
  loadSection,
  loadPlant,
  loadVehicleGroup,
  loadVehicle,
  loadTruckSharingAvailableAccountAppove,
  loadTruckSharingAvailableAccountAppovePatch,
  loadTruckSharingAvailableAccountHistory,
  loadTruckSharingAvailableAccountRequestToUse,
  getCpacTruckSharingVehicleType,
  getTruckSharingLevel,
}
