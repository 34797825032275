import React from "react";
import { Switch, Route } from "react-router-dom";
import Maintenancedevice from "./Index";
import Add from "./Add";
import Edit from "./Edit";
export default () => (
  <Switch>
    <Route exact path="/maintenancedevice" component={Maintenancedevice} />
    <Route path="/maintenancedevice/create" component={Add} />
    <Route path="/maintenancedevice/edit/:id" component={Edit} />
  </Switch>
);
