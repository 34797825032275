import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadSitegroup(action) {
  const {
    data: { page, pageSize, orderBy, orderType, accessToken, filter },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/site-group`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filter,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadSitegroup.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadSitegroup.failure())
  }
}

function* createMasterDataSiteGroup(action) {
  const {
    data: {
      companies_id,
      name,
      section_id,
      location_id,
      geo_fence,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/site-group/create`,
      {
        companies_id,
        name,
        section_id,
        location_id,
        geo_fence,
        matching_name: 'site-group',
        action_name: 'add',
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createMasterDataSiteGroup.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createMasterDataSiteGroup.failure())
  }
}

function* updateMasterDataSiteGroup(action) {
  const {
    data: {
      id,
      companies_id,
      name,
      section_id,
      location_id,
      geo_fence,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.put,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/site-group/edit/${id}`,
      {
        companies_id,
        name,
        section_id,
        location_id,
        geo_fence,
        matching_name: 'site-group',
        action_name: 'edit',
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateMasterDataSiteGroup.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateMasterDataSiteGroup.failure())
  }
}

function* exportSitegroup(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      filter,
      type,
      file_value_pdf,
      limit_export,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/site-group`,
      {
        page,
        page,
        pageSize,
        orderBy,
        orderType,
        accessToken,
        filter,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportSitegroup.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    yield put(actions.exportSitegroup.failure())
  }
}

export default function* watchSiteGroup() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_SITEGROUP.REQUEST, loadSitegroup),
    takeEvery(
      actions.CREATE_MASTERDATA_SITEGROUP.REQUEST,
      createMasterDataSiteGroup,
    ),
    takeEvery(
      actions.UPDATE_MASTERDATA_SITEGROUP.REQUEST,
      updateMasterDataSiteGroup,
    ),
    takeEvery(actions.EXPORT_MASTERDATA_SITEGROUP.REQUEST, exportSitegroup),
  ])
}

export {
  loadSitegroup,
  createMasterDataSiteGroup,
  updateMasterDataSiteGroup,
  exportSitegroup,
}
