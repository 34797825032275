import { createTypes, createAction } from 'lib/action'
const LOAD_MASTERDATA_MATERIAL = createTypes('loadmasterdatamaterial', 'load')
const CREATE_MATERIAL = createTypes('creatematerial', 'create')
const UPDATE_MATERIAL = createTypes('updatematerial', 'update')
const LOAD_MATERIAL = createTypes('loadmaterial', 'load')
const DELETE_SUB_MATERIAL = createTypes('deletesubmaterial', 'delete')
const CREATE_SUB_MATERIAL = createTypes('createsubmaterial', 'create')
const UPDATE_SUB_MATERIAL = createTypes('updatesubmaterial', 'create')
const EXPORT_MATERIAL = createTypes('exportmaterial', 'load')
const IMPORT_MATERIAL = createTypes('importmaterial', 'create')

const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadMasterDataMaterial = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_MATERIAL.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_MASTERDATA_MATERIAL.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_MASTERDATA_MATERIAL.FAITLURE),
}

const loadMaterial = {
  request: (data, loading) =>
    createAction(LOAD_MATERIAL.REQUEST, { data, loading }),
  success: listsEdit => createAction(LOAD_MATERIAL.SUCCESS, { listsEdit }),
  failure: () => createAction(LOAD_MATERIAL.FAITLURE),
}

const createMaterial = {
  request: (data, loading) =>
    createAction(CREATE_MATERIAL.REQUEST, { data, loading }),
  success: res => createAction(CREATE_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(CREATE_MATERIAL.FAITLURE),
}

const updateMaterial = {
  request: (data, loading) =>
    createAction(UPDATE_MATERIAL.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(UPDATE_MATERIAL.FAITLURE),
}

const deleteSubMaterial = {
  request: (data, loading) =>
    createAction(DELETE_SUB_MATERIAL.REQUEST, { data, loading }),
  success: res => createAction(DELETE_SUB_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(DELETE_SUB_MATERIAL.FAITLURE),
}

const createSubMaterial = {
  request: (data, loading) =>
    createAction(CREATE_SUB_MATERIAL.REQUEST, { data, loading }),
  success: res => createAction(CREATE_SUB_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(CREATE_SUB_MATERIAL.FAITLURE),
}

const updateSubMaterial = {
  request: (data, loading) =>
    createAction(UPDATE_SUB_MATERIAL.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_SUB_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(UPDATE_SUB_MATERIAL.FAITLURE),
}

const exportMaterial = {
  request: (data, loading) =>
    createAction(EXPORT_MATERIAL.REQUEST, { data, loading }),
  success: (lists, page) => createAction(EXPORT_MATERIAL.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_MATERIAL.FAITLURE),
}

const importtMaterial = {
  request: (data, loading) =>
    createAction(IMPORT_MATERIAL.REQUEST, { data, loading }),
  success: res => createAction(IMPORT_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(IMPORT_MATERIAL.FAITLURE),
}

export {
  REMOVE_LISTS,
  removeLists,
  LOAD_MASTERDATA_MATERIAL,
  loadMasterDataMaterial,
  CREATE_MATERIAL,
  createMaterial,
  UPDATE_MATERIAL,
  updateMaterial,
  LOAD_MATERIAL,
  loadMaterial,
  deleteSubMaterial,
  DELETE_SUB_MATERIAL,
  createSubMaterial,
  CREATE_SUB_MATERIAL,
  updateSubMaterial,
  UPDATE_SUB_MATERIAL,
  importtMaterial,
  IMPORT_MATERIAL,
  exportMaterial,
  EXPORT_MATERIAL,
}
