import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadMasterDataMaterial(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      company_id,
      filterObj,
      type,
      file_value_pdf,
      limit_export,
      material_visibility,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-masterdata-material`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        company_id,
        filterObj,
        type,
        file_value_pdf,
        limit_export,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadMasterDataMaterial.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterDataMaterial.failure())
  }
}

function* exportMaterial(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      company_id,
      filterObj,
      type,
      file_value_pdf,
      limit_export,
      material_visibility,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-masterdata-material`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        company_id,
        filterObj,
        type,
        file_value_pdf,
        limit_export,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportMaterial.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportMaterial.failure())
  }
}

function* createMaterial(action) {
  const {
    data: {
      id,
      code,
      name,
      unit,
      list_sub_material,
      type_group,
      vehicle_type_list,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/create-masterdata-material`,
      {
        id,
        code,
        name,
        unit,
        list_sub_material,
        type_group,
        vehicle_type_list,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createMaterial.success(res.data))
  } catch (err) {
    yield put(actions.createMaterial.failure())
  }
}

function* updateMaterial(action) {
  const {
    data: { id, code, name, unit, type_group, vehicle_type_list, accessToken },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/update-masterdata-material`,
      {
        id,
        code,
        name,
        unit,
        type_group,
        vehicle_type_list,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateMaterial.success(res.data))
  } catch (err) {
    yield put(actions.updateMaterial.failure())
  }
}

function* loadMaterial(action) {
  const {
    data: { id, accessToken },
  } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-detail-masterdata-material/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadMaterial.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMaterial.failure())
  }
}

function* deleteSubMaterial(action) {
  const {
    data: { id, accessToken },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/delete-sub-masterdata-material`,
      {
        id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.deleteSubMaterial.success(res.data))
  } catch (err) {
    yield put(actions.deleteSubMaterial.failure())
  }
}

function* createSubMaterial(action) {
  const {
    data: {
      isupply_material_id,
      code,
      name,
      material_type,
      mill_list,
      mill_id,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/insert-sub-masterdata-material`,
      {
        isupply_material_id,
        code,
        name,
        material_type,
        mill_list,
        mill_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createSubMaterial.success(res.data))
  } catch (err) {
    yield put(actions.createSubMaterial.failure())
  }
}

function* updateSubMaterial(action) {
  const {
    data: {
      isupply_material_id,
      isupply_sub_material_id,
      code,
      name,
      material_type,
      mill_list,
      mill_id,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/update-sub-masterdata-material`,
      {
        isupply_material_id,
        isupply_sub_material_id,
        code,
        name,
        material_type,
        mill_list,
        mill_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateSubMaterial.success(res.data))
  } catch (err) {
    yield put(actions.updateSubMaterial.failure())
  }
}

function* importtMaterial(action) {
  const {
    data: { files, accessToken, typeImport },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('typeImport', typeImport)
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdata-material-import-excel`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    loading(res.data)
    yield put(actions.importtMaterial.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.importtMaterial.failure())
  }
}

export default function* watchMaterial() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_MATERIAL.REQUEST, loadMasterDataMaterial),
    takeEvery(actions.CREATE_MATERIAL.REQUEST, createMaterial),
    takeEvery(actions.UPDATE_MATERIAL.REQUEST, updateMaterial),
    takeEvery(actions.LOAD_MATERIAL.REQUEST, loadMaterial),
    takeEvery(actions.DELETE_SUB_MATERIAL.REQUEST, deleteSubMaterial),
    takeEvery(actions.CREATE_SUB_MATERIAL.REQUEST, createSubMaterial),
    takeEvery(actions.UPDATE_SUB_MATERIAL.REQUEST, updateSubMaterial),
    takeEvery(actions.EXPORT_MATERIAL.REQUEST, exportMaterial),
    takeEvery(actions.IMPORT_MATERIAL.REQUEST, importtMaterial),
    importtMaterial,
  ])
}
export {
  loadMasterDataMaterial,
  createMaterial,
  updateMaterial,
  loadMaterial,
  deleteSubMaterial,
  createSubMaterial,
  updateSubMaterial,
  exportMaterial,
  importtMaterial,
}
