import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'

import * as actions from './actions'
import moment from 'moment'

function* loadRoutemaster(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const company_id = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const {
    data: { page, pageSize, orderBy, orderType, filterObj },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadRoutemaster.success(res.data))
  } catch (err) {
    yield put(actions.loadRoutemaster.failure())
  }
}

function* createRoutemaster(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  const company_id = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const {
    data: { name },
    history,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/create`,
      {
        name,
        company_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.createRoutemaster.success(res.data))
    //console.log(res.data)
    history.push('/routemaster/edit/' + res.data.id)
    //console.log("success")
  } catch (err) {
    yield put(actions.createRoutemaster.failure())
  }
}

function* updateRoutemaster(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  const {
    data: { name },
    id,
    history,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/update`,
      {
        name,
        id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.updateRoutemaster.success(res.data))
    history.push('/routemaster/edit/' + res.data.id)
  } catch (err) {
    yield put(actions.updateRoutemaster.failure())
  }
}

function* getRoutemasterByID(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.getRoutemasterByID.success(res.data))
  } catch (err) {
    yield put(actions.getRoutemasterByID.failure())
  }
}

function* loadRoutemasterdetail(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  const { id } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/detail`,
      {
        route_master_id: id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadRoutemasterdetail.success(res.data))
  } catch (err) {
    yield put(actions.loadRoutemasterdetail.failure())
  }
}

function* createRoutemasterdetail(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  const {
    data: {
      location_code,
      dest_location,
      date_entry,
      date_out,
      time_entry,
      time_out,
    },
    route_master_id,
  } = action.payload

  const new_time_entry = moment(time_entry).format('HH:mm')
  const new_time_out = moment(time_out).format('HH:mm')
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/detail/create`,
      {
        location_code,
        location_id: dest_location,
        date_entry,
        date_out,
        time_entry: new_time_entry,
        time_out: new_time_out,
        route_master_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.createRoutemasterdetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createRoutemasterdetail.failure())
  }
}

function* updateRoutemasterdetail(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  const {
    data: {
      location_code,
      dest_location,
      date_entry,
      date_out,
      time_entry,
      time_out,
    },
    route_master_detail_id,
    route_master_id,
  } = action.payload

  const new_time_entry = moment(time_entry).format('HH:mm')
  const new_time_out = moment(time_out).format('HH:mm')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/detail/update`,
      {
        location_code,
        location_id: dest_location,
        date_entry,
        date_out,
        time_entry: new_time_entry,
        time_out: new_time_out,
        route_master_detail_id,
        route_master_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.updateRoutemasterdetail.success(res.data))
  } catch (err) {
    yield put(actions.updateRoutemasterdetail.failure())
  }
}

function* getRoutemasterdetailByID(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/detail/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.getRoutemasterdetailByID.success(res.data))
  } catch (err) {
    yield put(actions.getRoutemasterdetailByID.failure())
  }
}

function* loadLocation(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const location_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'location_visibility',
  )

  const { data } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`,
      {
        company_id: COMPANY_ID,
        location_visibility: location_visibility,
        name: data,
        relate_company: `${this.props.auth.profile.relate_company}`,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadLocation.success(res.data))
  } catch (err) {
    yield put(actions.loadLocation.failure())
  }
}

function* loadLocationcode(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const location_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'location_visibility',
  )

  const { data } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`,
      {
        company_id: COMPANY_ID,
        location_visibility: location_visibility,
        code: data,
        relate_company: `${this.props.auth.profile.relate_company}`,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadLocationcode.success(res.data))
  } catch (err) {
    yield put(actions.loadLocationcode.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: '',
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_ROUTEMASTER.REQUEST, loadRoutemaster),
    takeEvery(actions.CREATE_ROUTEMASTER.REQUEST, createRoutemaster),
    takeEvery(actions.UPDATE_ROUTEMASTER.REQUEST, updateRoutemaster),
    takeEvery(actions.GET_ROUTEMASTER.REQUEST, getRoutemasterByID),
    takeEvery(actions.LOAD_ROUTEMASTERDETAIL.REQUEST, loadRoutemasterdetail),
    takeEvery(
      actions.CREATE_ROUTEMASTERDETAIL.REQUEST,
      createRoutemasterdetail,
    ),
    takeEvery(
      actions.UPDATE_ROUTEMASTERDETAIL.REQUEST,
      updateRoutemasterdetail,
    ),
    takeEvery(actions.GET_ROUTEMASTERDETAIL.REQUEST, getRoutemasterdetailByID),
    takeEvery(actions.LOAD_LOCATION_ROUTEMASTER.REQUEST, loadLocation),
    takeEvery(actions.LOAD_LOCATIONCODE_ROUTEMASTER.REQUEST, loadLocationcode),
    takeEvery(actions.LOAD_VEHICLE_ROUTEMASTER.REQUEST, loadVehicle),
  ])
}

export {
  loadRoutemaster,
  createRoutemaster,
  updateRoutemaster,
  getRoutemasterByID,
  loadRoutemasterdetail,
  createRoutemasterdetail,
  updateRoutemasterdetail,
  getRoutemasterdetailByID,
  loadLocation,
  loadLocationcode,
  loadVehicle,
}
