import { createTypes, createAction } from 'lib/action'

const LOAD_DRIVINGLICENSESCANBEHAVIOR = createTypes(
  'drivinglicensescanbehavior',
  'load',
)
const LOAD_DRIVINGLICENSESCANBEHAVIOR_PREVIEW = createTypes(
  'drivinglicensescanbehavior_preview',
  'load',
)
const LOAD_VEHICLE_DRIVINGLICENSESCANBEHAVIOR = createTypes(
  'vehicle_drivinglicensescanbehavior',
  'load',
)
const LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANBEHAVIOR = createTypes(
  'vehicle_type_drivinglicensescanbehavior',
  'load',
)

const loadDrivinglicensescanbehavior = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGLICENSESCANBEHAVIOR.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_DRIVINGLICENSESCANBEHAVIOR.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGLICENSESCANBEHAVIOR.FAILURE),
}

const loadDrivinglicensescanbehaviorpreview = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGLICENSESCANBEHAVIOR_PREVIEW.REQUEST, {
      data,
      loading,
    }),
  success: data =>
    createAction(LOAD_DRIVINGLICENSESCANBEHAVIOR_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGLICENSESCANBEHAVIOR_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DRIVINGLICENSESCANBEHAVIOR.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_DRIVINGLICENSESCANBEHAVIOR.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DRIVINGLICENSESCANBEHAVIOR.FAILURE),
}

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANBEHAVIOR.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANBEHAVIOR.SUCCESS, {
      data,
    }),
  failure: () =>
    createAction(LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANBEHAVIOR.FAILURE),
}

export {
  loadDrivinglicensescanbehavior,
  LOAD_DRIVINGLICENSESCANBEHAVIOR,
  loadDrivinglicensescanbehaviorpreview,
  LOAD_DRIVINGLICENSESCANBEHAVIOR_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_DRIVINGLICENSESCANBEHAVIOR,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANBEHAVIOR,
}
