import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Formik } from 'formik'
import * as yup from 'yup'
import { StyledSearchForm } from '../../../../styled/common-styled'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import { Link, Redirect } from 'react-router-dom'
import Breadcrumb from '../../../shared/components/Breadcrumb'
// import FillINFormAutocomPlateCompany from "./FillINFormAutocomPlateCompany";
import axios from 'axios'
import debounce from 'lodash/debounce'
// import { createShareLocation, removeLists } from "../actions";
import {
  Menu,
  Dropdown,
  Button,
  Row,
  Col,
  Input,
  Icon,
  Modal,
  Table,
  Drawer,
  Divider,
  AutoComplete,
  Tooltip,
  Transfer,
  Form,
  Select,
  Collapse,
  Spin,
  Pagination,
  message,
} from 'antd'
import difference from 'lodash/difference'
import FormDivider from '../../../shared/components/FormDivider'
const { Option } = Select
const { confirm } = Modal
const { Item } = Form

const TableTransfer = ({
  leftColumns,
  rightColumns,
  location_is_selected,
  unselected,
  currentPageLeft,
  total,
  loading,
  loading_right,
  handlePageChange,
  ...restProps
}) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns

      const rowSelection = {
        getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key)
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys)
          onItemSelectAll(diffKeys, selected)
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected)
        },
        selectedRowKeys: listSelectedKeys,
      }

      return (
        <div>
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            loading={
              direction === 'left' && loading
                ? true
                : direction === 'right' && loading_right
            }
            dataSource={
              columns[0].posiction === 'Right'
                ? location_is_selected
                : unselected
            }
            size="small"
            pagination={
              columns[0].posiction === 'Right'
                ? { defaultPageSize: 200 }
                : false
            } // เเสดง pagin เฉพาะ กล่องด้าน ขวา
            // pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
          {columns[0].posiction === 'Left' ? ( // เเสดง pageina เฉพาะ กล่องด้าน ซ้าย
            <div>
              <Row type="flex" justify="end" style={{ padding: '15px' }}>
                <Col span={24} align="right">
                  <Pagination
                    defaultPageSize={200}
                    current={currentPageLeft}
                    size="small"
                    total={
                      columns[0].posiction === 'Right'
                        ? location_is_selected.length
                        : total
                    }
                    onChange={e => handlePageChange(e)}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            ''
          )}
        </div>
      )
    }}
  </Transfer>
)

export class FromLocationTransfer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPageLeft: 1,
      pageLeft: 1,
      pageSizeLeft: 100,
      totalLeft: 0,
      add_share_location: {
        company_id: '',
        location_id: [],
      },
      unselected: [],
      company_id: [],
      leftTableColumns: [
        {
          dataIndex: 'title',
          title: 'Location Name Column Left',
          posiction: 'Left',
        },
      ],
      rightTableColumns: [
        {
          dataIndex: 'title',
          title: 'Location Name Column Right',
          posiction: 'Right',
        },
      ],
      location_is_selected: [],
      default_location_is_selected: [],
      id_location_is_selected: [],
      back_up_id_location_is_selected: [],
      keyword_left: '',
      keyword_right: '',
      search_first: true,
      loading: false,
      loading_right: false,
    }
  }

  componentDidMount() {
    let self = this
    const id = this.props.auth.profile.company_id

    // load location for tabletransfer
    // axios
    //   .post(
    //     `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/sharinglocation/getlocation/all`,
    //     {
    //       companyID: this.props.auth.profile.company_id,
    //       page: this.state.pageLeft,
    //       pageSize: this.state.pageSizeLeft,
    //       id_location_is_selected: this.state.id_location_is_selected,
    //       keyword_left: this.state.keyword_left
    //     },
    //     {
    //       headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
    //     }
    //   )
    //   .then(function(response) {
    //     if (typeof response.data.data !== "undefined") {
    //       let locationAPI = response.data.data.map(location => {
    //         return { key: location.location_id, title: location.location_name };
    //       });

    //       self.setState({
    //         // totalLeft: response.data.total
    //         totalLeft:
    //         response.data.total - 1 <= 0
    //         ? 1
    //         : response.data.total <= 0
    //         ? 1
    //         : response.data.total,
    //       });

    //       if (locationAPI.length != 0) {
    //         self.setState({
    //           unselected: locationAPI
    //         });
    //       }
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
    // end load location for tabletransfer

    //load company
    // axios
    //   .get(
    //     `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/sharinglocation/getcompany/${id}`,
    //     {
    //       headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
    //     }
    //   )
    //   .then(function(response) {
    //     if (typeof response.data.data !== "undefined") {
    //       let companyAPI = response.data.data.map(company => {
    //         return { key: company.company_id, value: company.companies_name };
    //       });

    //       if (companyAPI.length != 0) {
    //         self.setState({
    //           company: companyAPI
    //         });
    //       }
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
    //End load company
  }

  //   componentWillUnmount() {
  //     this.onRemoveLists();
  //   }

  //   onRemoveLists = () => {
  //     this.props.removeLists();
  //   };

  showConfirm = values => {
    let self = this
    confirm({
      title: (
        <LangContext.Consumer>
          {i18n => i18n.d.doYouWantToAddTheseItems}
        </LangContext.Consumer>
      ),
      content: (
        <LangContext.Consumer>
          {i18n => i18n.c.confirmToAddThisItem}
        </LangContext.Consumer>
      ),
      okText: <LangContext.Consumer>{i18n => i18n.o.ok}</LangContext.Consumer>,
      cancelText: (
        <LangContext.Consumer>{i18n => i18n.c.cancel}</LangContext.Consumer>
      ),
      onOk() {
        self.handleSubmit(values)
      },
      onCancel() {},
    })
  }

  handleSubmit = values => {
    let self = this

    // this.props.createShareLocation(
    //   {
    //     companyID: this.props.auth.profile.company_id,
    //     share_to_companies_id: values.company_id,
    //     location_id: values.location_id,
    //     accessToken: this.props.auth.accessToken
    //   },
    //   function(res) {
    //     if (res.status == "fail" && res.errors) {
    //       message.error("create fail");
    //     } else {
    //       message.success("create success");
    //       self.props.history.push(`/sharelocation`);
    //     }
    //   }
    // );
  }

  // setup location is selected
  handleSetupLocationIsSelected = data => {
    let data_location_is_selected = []

    if (data.length == 0) {
      // uselect ทั้งหมด
      this.state.location_is_selected.splice(
        0,
        this.state.location_is_selected.length,
      )
      this.state.id_location_is_selected.splice(
        0,
        this.state.id_location_is_selected.length,
      )
    } else {
      data.map(select => {
        let value = this.state.unselected.find(ele => {
          return ele.key == select
        })

        if (value == '') {
        } else if (typeof value == 'undefined') {
        } else {
          data_location_is_selected.push(value)
        }
      })

      let new_is_select_location = this.state.default_location_is_selected.concat(
        data_location_is_selected,
      )
      let final_value = []

      this.state.id_location_is_selected.splice(
        0,
        this.state.id_location_is_selected.length,
      )

      data.map(select1 => {
        let dat = new_is_select_location.find(ele1 => {
          return ele1.key == select1
        })

        if (dat == '') {
        } else if (typeof dat == 'undefined') {
        } else {
          final_value.push(dat)
          this.state.id_location_is_selected.push(dat.key)
        }
      })

      this.state.location_is_selected.splice(
        0,
        this.state.location_is_selected.length,
      )
      this.state.default_location_is_selected.splice(
        0,
        this.state.default_location_is_selected.length,
      )

      this.setState({
        location_is_selected: final_value,
        default_location_is_selected: final_value,
      })
    }

    this.handleLoadingLocation()
  }
  // end setup location is seletced

  handleLoadingLocation() {
    let self = this
    const id = this.props.auth.profile.company_id

    this.setState({ loading: true })

    // load location for tabletransfer
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/sharinglocation/getlocation/all`,
        {
          companyID: this.props.auth.profile.company_id,
          page: this.state.pageLeft,
          pageSize: this.state.pageSizeLeft,
          id_location_is_selected: this.state.id_location_is_selected,
          keyword_left: this.state.keyword_left,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function(response) {
        if (typeof response.data.data !== 'undefined') {
          let locationAPI = response.data.data.map(location => {
            return { key: location.location_id, title: location.location_name }
          })

          self.setState({
            // totalLeft: response.data.total,
            totalLeft:
              response.data.total - 1 <= 0
                ? 1
                : response.data.total <= 0
                ? 1
                : response.data.total,
            loading: false,
          })

          self.setState({
            unselected: locationAPI,
          })
        } else {
          self.setState({
            unselected: [],
            // totalLeft: "0",
            totalLeft:
              response.data.total - 1 <= 0
                ? 1
                : response.data.total <= 0
                ? 1
                : response.data.total,
          })

          self.setState({
            loading: false,
          })
        }
      })
      .catch(function(error) {
        console.log(error)
      })
    // end load location for tabletransfer
  }

  handleSearch = (side, value) => {
    if (side === 'left') {
      this.handleSearchDataLeft(value)
    }

    if (side === 'right') {
      this.handleSearchDataRight(value)

      if (
        this.state.back_up_id_location_is_selected.length == 0 &&
        value != ''
      ) {
        let location_is_selected = this.state.location_is_selected
        this.setState({
          back_up_id_location_is_selected: location_is_selected,
        })
      }

      if (
        this.state.back_up_id_location_is_selected.length > 0 &&
        value == ''
      ) {
        let back_up_id_location_is_selected = this.state
          .back_up_id_location_is_selected
        this.setState({
          location_is_selected: back_up_id_location_is_selected,
        })
      }
    }
  }

  handleSearchDataLeft = debounce(async value => {
    this.setState(
      { keyword_left: value, loading: true },
      this.getDataSearchDataLeft,
    )
  })

  getDataSearchDataLeft = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/sharinglocation/getlocation/all`,
        {
          companyID: this.props.auth.profile.company_id,
          page: this.state.pageLeft,
          pageSize: this.state.pageSizeLeft,
          id_location_is_selected: this.state.id_location_is_selected,
          keyword_left: this.state.keyword_left,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )

      this.state.unselected.splice(0, this.state.unselected.length)

      let locationAPI = res.data.data.map(location => {
        return { key: location.location_id, title: location.location_name }
      })

      this.setState({
        // totalLeft: res.data.total,
        totalLeft:
          res.data.total - 1 <= 0
            ? 1
            : res.data.total <= 0
            ? 1
            : res.data.total,
        unselected: locationAPI,
        loading: false,
      })
    } catch (error) {
      console.error(error)
    }
  }

  handleSearchDataRight = debounce(async value => {
    this.setState(
      { keyword_right: value, loading_right: true },
      this.getDataSearchDataRight,
    )
  }, 750)

  getDataSearchDataRight = async () => {
    let data_search = []

    if (this.state.keyword_right != '' && this.state.search_first) {
      this.state.location_is_selected.map(
        data => {
          if (
            data.title
              .toLowerCase()
              .indexOf(this.state.keyword_right.toLowerCase()) >= 0
          ) {
            data_search.push(data)
          }
        },
        this.setState({
          loading_right: false,
          location_is_selected: data_search,
          search_first: false,
        }),
      )
    } else {
      this.state.back_up_id_location_is_selected.map(
        data => {
          if (
            data.title
              .toLowerCase()
              .indexOf(this.state.keyword_right.toLowerCase()) >= 0
          ) {
            data_search.push(data)
          }
        },
        this.setState({
          loading_right: false,
          location_is_selected: data_search,
          search_first: false,
        }),
      )
    }
  }

  render() {
    return (
      <Row>
        <TableTransfer
          titles={[
            <LangContext.Consumer>
              {i18n => i18n.i.itemLeft}
            </LangContext.Consumer>,
            <LangContext.Consumer>
              {i18n => i18n.i.itemSelected}
            </LangContext.Consumer>,
          ]}
          name="location"
          disabled={this.props.disabled}
          dataSource={this.props.dataSource}
          targetKeys={this.props.targetKeys}
          location_is_selected={this.props.location_is_selected}
          unselected={this.props.dataSource}
          currentPageLeft={this.props.currentPageLeft}
          loading={this.props.loading}
          loading_right={this.props.loading_right}
          handlePageChange={this.props.handlePageChange}
          showSearch
          onSearch={this.props.onSearch}
          // targetKeys={values.location_id}
          onChange={(targetKeys, direction, moveKeys) => {
            // setFieldValue("location_id", targetKeys);
            this.props.onChange(
              targetKeys,
              direction,
              moveKeys,
              'location_id',
              'changeSelectedLocation',
              'selectedLocation',
              'removedSelectedLocation',
              'updatedLocation',
            )
          }}
          leftColumns={this.state.leftTableColumns}
          rightColumns={this.state.rightTableColumns}
          total={this.props.total}
          render={item => item.title}
        />
      </Row>
    )
  }
}
export default connect(
  ({ datavisibilityMasterDataState, auth, auth: { profile } }) => ({
    datavisibilityMasterDataState,
    auth,
    profile,
  }),
  {
    // createShareLocation: createShareLocation.request,
    // removeLists
  },
)(FromLocationTransfer)
