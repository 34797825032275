import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import history from '../../lib/history'

const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value)
    })
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key)
    })
  },
}

function* checkLogin(action) {
  const {
    credentials: {
      username,
      password,
      loginType,
      userType,
      jwt,
      truckNo,
      cb,
      token,
      code,
    },
  } = action.payload
  try {
    let url = ''
    let body = null

    if (!!action.payload.credentials.token) {
      url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/auth/login-by-token`
      body = { token: action.payload.credentials.token }
    } else {
      url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/auth/checkuser`
      if (loginType == 'bluenet-via-url' && code) {
        url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/auth/login-by-code`
      } else if (loginType == 'bluenet-via-url') {
        url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/auth/checkuser-bluenet-via-url`
      } else if (loginType == 'bluenet') {
        url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/bluenet/curl-authen`
        if (userType == 'New')
          url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/auth/checkuser-by-pattern`
      }

      body = {
        username,
        password,
        loginType,
        userType,
        code,
        jwt,
        appId: 1,
      }

      if (code) {
        body.code = code
      }
    }

    const res = yield call(axios.post, url, body)
    let {
      data: {
        token,
        permissions,
        vehicle_list,
        location_list,
        material_list,
        exactly_location,
        mill_location_list,
        plant_location_list,
        vehicle_group_list,
        location_group_list,
        config,
        data_visibility_node,
        org_chart_vehicle_visibility,
        org_chart,
        user_history_logon_id,
        relate_company,
      },
    } = res
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let deToken = JSON.parse(window.atob(base64))

    yield call(() =>
      actions.storeCredentials(
        token,
        deToken,
        permissions,
        vehicle_list,
        location_list,
        material_list,
        exactly_location,
        mill_location_list,
        plant_location_list,
        vehicle_group_list,
        location_group_list,
        config,
        data_visibility_node,
        org_chart_vehicle_visibility,
        org_chart,
        user_history_logon_id,
        relate_company,
      ),
    )
    yield put(
      actions.checkLogin.success(
        token,
        deToken,
        permissions,
        vehicle_list,
        location_list,
        material_list,
        exactly_location,
        mill_location_list,
        plant_location_list,
        vehicle_group_list,
        location_group_list,
        config,
        data_visibility_node,
        org_chart_vehicle_visibility,
        org_chart,
        user_history_logon_id,
        relate_company,
        cb,
      ),
    )

    // loginType == "bluenet-via-url" && cb();
  } catch (err) {
    if (err.message.indexOf('400') !== -1)
      yield put(
        actions.checkLogin.failure('error', 'Invalid username or password!'),
      )
    else if (err.message.indexOf('401') !== -1)
      yield put(actions.checkLogin.failure('error', 'Account expired!'))
    else if (err.message.indexOf('503') !== -1)
      yield put(actions.checkLogin.failure('error', 'Service Unavailable!'))
    else if (err.message.indexOf('502') !== -1)
      yield put(actions.checkLogin.failure('error', 'Bad Gateway!'))
    else if (err.message.indexOf('504') !== -1)
      yield put(actions.checkLogin.failure('error', 'Gateway Timeout!'))
    else if (err.message.indexOf('505') !== -1)
      yield put(
        actions.checkLogin.failure('error', 'Token wrong or Token expired!'),
      )
    else yield put(actions.checkLogin.failure('error', 'Something went wrong!'))
  }
}

function* loadFavoriteMenu(action) {
  const {
    data: { userId, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/favorite/get?user_id=${userId}`,
      // `http://booking-api/api/customer`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadFavoriteMenu.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadFavoriteMenu.failure())
  }
}

function* createFavoriteMenu(action) {
  const {
    data: { matchingName, path, userId, accessToken },
    cb,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/favorite/add`,
      {
        matchingName,
        path,
        userId,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb()
    yield put(actions.createFavoriteMenu.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createFavoriteMenu.failure())
  }
}

function* deleteFavoriteMenu(action) {
  const {
    data: { userId, favId, accessToken },
    cb,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/favorite/delete`,
      {
        fav_id: favId,
        user_id: userId,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb()
    yield put(actions.deleteFavoriteMenu.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.deleteFavoriteMenu.failure())
  }
}

export default function* watchAuthState() {
  yield all([
    takeEvery(actions.CHECK_LOGIN.REQUEST, checkLogin),
    takeEvery(actions.LOAD_FAVORITE_MENU.REQUEST, loadFavoriteMenu),
    takeEvery(actions.CREATE_FAVORITE_MENU.REQUEST, createFavoriteMenu),
    takeEvery(actions.DELETE_FAVORITE_MENU.REQUEST, deleteFavoriteMenu),
  ])
}

export { checkLogin, loadFavoriteMenu, createFavoriteMenu, deleteFavoriteMenu }
