import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Tag,
  Spin,
  Modal,
  Popconfirm,
  Badge,
  Icon,
  Tooltip,
  Drawer,
  Select,
  DatePicker,
  message,
} from 'antd'

import Filter from './Filter'
import Formmail from './Formmail'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import * as actions from '../actions'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from './../../../shared/components/Breadcrumb'
import { helper_convertDateformat } from '../../../../lib/helper'

const columnStyle = { fontSize: '12px', fontWeight: '400' }

class Index extends Component {
  state = {
    visible: false,
    visibleModal: false,
    page: 1,
    pageSize: 20,
    orderBy: 'plate_no',
    orderType: 'asc',
    filterObj: {
      plate_no: '',
      code: '',
    },
    selectedRowKeys: [], // Check here to configure the default column
  }

  showModal = () => {
    this.setState({
      visibleModal: true,
    })
  }

  handleOk = (values, loading) => {
    this.props.createContactmanagement(values, loading)
    this.setState({
      visibleModal: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visibleModal: false,
    })
  }

  componentDidMount() {
    this.props.loadContactmanagement(this.state, true)
  }

  onSelectChange = selectedRowKeys => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys })
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onCloseSearch = resetForm => {
    this.setState({
      visible: false,
    })
  }

  filterContactmanagement = values => {
    const { plate_no, code } = values
    this.setState({
      filterObj: {
        plate_no: plate_no,
        code: code,
      },
    })
    this.props.loadContactmanagement(
      {
        page: this.state.page,
        pageSize: this.state.pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: {
          plate_no: plate_no,
          code: code,
        },
      },
      true,
    )
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      page: pagination.current,
    })

    let orderBy = ''
    let orderType = ''

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = this.state.orderBy
      orderType = this.state.orderType
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == 'descend' ? 'desc' : 'asc',
      })
      orderBy = sorter.columnKey
      orderType = sorter.order == 'descend' ? 'desc' : 'asc'
    }

    this.props.loadContactmanagement(
      {
        page: pagination.current,
        pageSize: this.state.pageSize,
        orderBy: orderBy,
        orderType: orderType,
        filterObj: {
          plate_no: this.state.filterObj.plate_no,
          code: this.state.filterObj.code,
        },
      },
      true,
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataResponse } = this.props.contactmanagement
    if (dataResponse.status == 'success') {
      message.info('สำเร็จ')
      this.props.clearDataResponse()
    } else if (dataResponse.status == 'fail') {
      message.info('ไม่สำเร็จ')
      this.props.clearDataResponse()
    }
  }

  render() {
    const { selectedRowKeys } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    }

    const columns = [
      {
        title: '#',
        dataIndex: 'row_number',
        width: '5%',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.v.vehicleCode}
          </LangContext.Consumer>
        ),
        dataIndex: 'code',
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
        width: '22%',
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>
        ),
        dataIndex: 'plate_no',
        sorter: true,
        // render: name => `${name.first} ${name.last}`,
        width: '22%',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },

      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.s.simNumber}
          </LangContext.Consumer>
        ),
        dataIndex: 'sim_no',
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
        width: '22%',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.p.phoneNumber}
          </LangContext.Consumer>
        ),
        dataIndex: '',
        sorter: false,
        render: (text, record, index) => {
          return (
            <div>
              <LangContext.Consumer>
                {i18n => (
                  <a
                    // target="_blank"
                    href={`tel:` + record.phone_number}
                    style={{ padding: '0px 15px 0px 5px' }}
                  >
                    {record.phone_number}
                  </a>
                )}
              </LangContext.Consumer>
            </div>
          )
        },
        width: '22%',
      },
    ]

    return (
      <div>
        <Spin
          spinning={this.props.contactmanagement.contactmanagementcreateLoading}
        >
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: '0px 0px 14px 10px' }}
                />
              </Col>
            </Row>
            <Row
              gutter={24}
              type="flex"
              justify="end"
              style={{ height: '38px' }}
            >
              <Col span={22}>
                <Button icon="mail" size="small" onClick={this.showModal}>
                  <LangContext.Consumer>
                    {i18n => i18n.s.send_email}
                  </LangContext.Consumer>
                </Button>
              </Col>

              <Col span={2}>
                <Button icon="filter" size="small" onClick={this.showDrawer}>
                  <LangContext.Consumer>
                    {i18n => i18n.f.filter}
                  </LangContext.Consumer>
                </Button>
              </Col>
            </Row>
            <Table
              bordered
              size="small"
              rowSelection={rowSelection}
              columns={columns}
              rowKey={record => record.id}
              dataSource={this.props.contactmanagement.contactmanagementLists}
              pagination={{
                pageSize: this.state.pageSize,
                total: this.props.contactmanagement.contactmanagementTotal,
                current: this.state.page,
              }}
              loading={this.props.contactmanagement.contactmanagementLoading}
              onChange={this.handleTableChange}
            />

            <div>
              <LangContext.Consumer>
                {i18n => (
                  <Drawer
                    title={i18n.s.search}
                    width={260}
                    onClose={this.onCloseSearch}
                    visible={this.state.visible}
                  >
                    <Filter
                      OnClose={this.onCloseSearch}
                      onSubmit={this.filterContactmanagement}
                    />
                  </Drawer>
                )}
              </LangContext.Consumer>
            </div>

            <Formmail
              visible={this.state.visibleModal}
              onOk={this.handleOk}
              vehicleLists={this.state.selectedRowKeys}
              onCancel={this.handleCancel}
            />
          </GeneralStyledContent>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = ({ contactmanagement, auth: { profile } }) => ({
  contactmanagement,
  profile,
})

const mapDispatchToProps = {
  loadContactmanagement: actions.loadContactmanagement.request,
  createContactmanagement: actions.createContactmanagement.request,
  clearDataResponse: actions.clearDataResponse,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
