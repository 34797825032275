import {
  LOAD_DEPARTMENT,
  LOAD_DIVISION,
  LOAD_PLANT,
  LOAD_SECTION,
  LOAD_VEHICLE_GROUP,
  LOAD_VEHICLE,
} from './actions'
const initialState = {
  divisionLists: [],
  departmentLists: [],
  sectionLists: [],
  plantLists: [],
  vehicleGroupLists: [],
  vehicleLists: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DIVISION.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOAD_DIVISION.SUCCESS:
      return {
        ...state,
        divisionLists: action.payload.data,
        loading: false,
      }
    case LOAD_DEPARTMENT.REQUEST:
      return { ...state, loading: true }
    case LOAD_DEPARTMENT.SUCCESS:
      return { ...state, departmentLists: action.payload.data, loading: false }
    case LOAD_SECTION.REQUEST:
      return { ...state, loading: true }
    case LOAD_SECTION.SUCCESS:
      return { ...state, sectionLists: action.payload.data, loading: false }
    case LOAD_PLANT.REQUEST:
      return { ...state, loading: true }
    case LOAD_PLANT.SUCCESS:
      return { ...state, plantLists: action.payload.data, loading: false }
    case LOAD_VEHICLE_GROUP.REQUEST:
      return { ...state, loading: true }
    case LOAD_VEHICLE_GROUP.SUCCESS:
      return {
        ...state,
        vehicleGroupLists: action.payload.data,
        loading: false,
      }
    case LOAD_VEHICLE.REQUEST:
      return { ...state, loading: true }
    case LOAD_VEHICLE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data,
        loading: false,
      }
    default:
      return state
  }
}
