import { createTypes, createAction } from 'lib/action'

const LOAD_FUELUSE = createTypes('fueluse', 'load')
const LOAD_FUELUSE_PREVIEW = createTypes('fuelusepreview', 'load')
const LOAD_VEHICLE_FUELUSE = createTypes('vehicle_fueluse', 'load')
const LOAD_VEHICLE_TYPE_FUELUSE = createTypes('vehicletype_fueluse', 'load')

const loadFueluse = {
  request: (data, loading) =>
    createAction(LOAD_FUELUSE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_FUELUSE.SUCCESS, { data }),
  failure: () => createAction(LOAD_FUELUSE.FAILURE),
}

const loadFuelusepreview = {
  request: (data, loading) =>
    createAction(LOAD_FUELUSE_PREVIEW.REQUEST, { data, loading }),
  success: data => createAction(LOAD_FUELUSE_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_FUELUSE_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_FUELUSE.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_FUELUSE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_FUELUSE.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_FUELUSE.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_FUELUSE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_FUELUSE.FAILURE),
}

export {
  loadFueluse,
  LOAD_FUELUSE,
  loadFuelusepreview,
  LOAD_FUELUSE_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_FUELUSE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_FUELUSE,
}
