import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Table,
  message,
  Tooltip,
} from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import difference from 'lodash/difference'
import BasicFilterReport from '../../basicFilterReport/Index'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
        }

        return (
          <Table
            scroll={{ y: 200, x: 250 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 100 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)
export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      event_date_start: '',
      event_date_end: '',
      vehicleList: [],
      vehicleSelect: [],
      selectedKeys: [],
      dataPreview: [],
      loadPreview: false,
    }
    this.Leftbtn = null
    this.Rightbtn = null
    this.leftTableColumns = [
      {
        dataIndex: 'name',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemLeft}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Left',
      },
    ]

    this.rightTableColumns = [
      {
        dataIndex: 'name',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemSelected}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Right',
      },
    ]
  }

  componentDidMount() {
    const { call } = this.props

    if (call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    setFieldValue('vehicle_type_id', value)
    this.setState({ vehicleSelect: [], selectedKeys: [], selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleList } = nextProps
    const vehlist = []
    vehicleList.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleMenuClick = (setFieldValue, handleSubmit, typeFile) => {
    setFieldValue('type_file', typeFile, false)
    handleSubmit()
  }

  handleClicReportkPreview = values => {
    let company_id = this.props.auth.profile.company_id
    let vehicle_visibility = this.props.auth.profile.vehicle_visibility
    let user_id = this.props.auth.profile.id
    const date_start = moment(values.eventdate[0]['_d']).format(
      'YYYY-MM-DD 00:00:00',
    )
    const date_end = moment(values.eventdate[1]['_d']).format(
      'YYYY-MM-DD 23:59:59',
    )

    const filter = {
      date_start,
      date_end,
      list_vehicle_id: values.list_vehicle_id,
      company_id,
      vehicle_type_id: `${values.vehicle_type_id}`,
      user_id,
      type_file: 'preview',
      vehicle_visibility,
    }

    const header = {
      headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
    }

    window.open('/report/vehicleperformanceconclude/preview', '_blank')
    localStorage.setItem(
      'vehicleperformanceconcludereport-filter',
      JSON.stringify(filter),
    )
    localStorage.setItem(
      'vehicleperformanceconcludereport-header',
      JSON.stringify(header),
    )
  }

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      vehicleperformanceconcludeLoading,
      onSubmit,
    } = this.props

    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }

    return (
      <div>
        <Spin spinning={vehicleperformanceconcludeLoading}>
          <Spin spinning={this.state.loadPreview}>
            <LangContext.Consumer>
              {i18n => (
                <Formik
                  initialValues={{
                    vehicle_type_id: '',
                    eventdate: [
                      moment('00:00', 'HH:mm'),
                      moment('23:59', 'HH:mm'),
                    ],
                    list_vehicle_id: this.props.dataedit
                      ? JSON.parse(this.props.dataedit).list_vehicle_id
                      : [],
                    division: this.props.selectedDivision,
                    department: this.props.selectedDepartment,
                    section: this.props.selectedSection,
                    plant: this.props.selectedPlant,
                    vehicleGroup: this.props.selectedVehicleGroup,
                  }}
                  validate={values => {
                    let errors = {}
                    if (values.eventdate.length > 0) {
                      const date_start = moment(values.eventdate[0]['_d'])
                      const date_end = moment(values.eventdate[1]['_d'])
                      let day_diff = date_end.diff(date_start, 'days')
                      if (day_diff > 31) {
                        errors.eventdate = `${i18n.o.over1Month}`
                      }
                    }

                    if (values.list_vehicle_id.length > 0) {
                      const threshold = 500

                      if (values.list_vehicle_id.length > threshold) {
                        errors.list_vehicle_id = `เลือกรถได้ไม่เกิน 500 คัน`
                      }
                    }

                    return errors
                  }}
                  validationSchema={yup.object().shape({
                    eventdate: yup
                      .array()
                      .required(`${i18n.p.pleaseChooseDateRange}`),
                    list_vehicle_id: yup
                      .array()
                      .required(`${i18n.p.pleaseChooseVehicle}`),
                  })}
                  onSubmit={values => {
                    if (values.type_file === 'preview') {
                      this.handleClicReportkPreview(values)
                    } else {
                      const loading = true
                      onSubmit(values, loading)
                    }
                  }}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleFocus,
                    setFieldValue,
                    isValidating,
                  }) => (
                    <>
                      <Row type="flex" justify="center">
                        <Form onSubmit={handleSubmit}>
                          <div className="semi-bold-16">
                            {' '}
                            <LangContext.Consumer>
                              {i18n => i18n.v.VehicleperformanceconcludeReport}
                            </LangContext.Consumer>
                          </div>
                          <BasicFilterReport
                            setFieldValue={setFieldValue}
                            values={values}
                            touched={touched}
                            errors={errors}
                            division={this.props.division}
                            updateSelectDivision={e => {
                              this.setState({ vehicleSelect: [] }, () => {
                                setFieldValue('list_vehicle_id', [])
                                this.props.updateSelectDivision(e, values)
                              })
                            }}
                            department={this.props.department}
                            updateSelectDepartment={e => {
                              this.setState({ vehicleSelect: [] }, () => {
                                setFieldValue('list_vehicle_id', [])
                                this.props.updateSelectDepartment(e, values)
                              })
                            }}
                            section={this.props.section}
                            updateSelectSection={e => {
                              this.setState({ vehicleSelect: [] }, () => {
                                setFieldValue('list_vehicle_id', [])
                                this.props.updateSelectSection(e, values)
                              })
                            }}
                            plant={this.props.plant}
                            updateSelectPlant={e => {
                              this.setState({ vehicleSelect: [] }, () => {
                                setFieldValue('list_vehicle_id', [])
                                this.props.updateSelectPlant(e, values)
                              })
                            }}
                            vehicleGroup={this.props.vehicleGroup}
                            updateSelectVehicleGroup={e => {
                              this.setState({ vehicleSelect: [] }, () => {
                                setFieldValue('list_vehicle_id', [])
                                this.props.updateSelectVehicleGroup(e, values)
                              })
                            }}
                          />
                          <FormItem
                            required={true}
                            label={
                              <LangContext.Consumer>
                                {i18n => i18n.v.vehicle}
                              </LangContext.Consumer>
                            }
                            validateStatus={
                              touched.list_vehicle_id &&
                              errors.list_vehicle_id &&
                              'warning'
                            }
                            help={
                              touched.list_vehicle_id && errors.list_vehicle_id
                            }
                          >
                            <div>
                              <TableTransfer
                                dataSource={this.props.vehicleList}
                                targetKeys={values.list_vehicle_id}
                                selectedKeys={this.state.selectedKeys}
                                showSearch
                                onSelectChange={this.handleSelectChange}
                                onChange={targetKeys => {
                                  if (targetKeys.length > 500) {
                                    message.error('เลือกรถได้ไม่เกิน 500 คัน')
                                  } else {
                                    setFieldValue('list_vehicle_id', targetKeys)
                                  }
                                }}
                                filterOption={(inputValue, item) => {
                                  return (
                                    item.name
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) !== -1
                                  )
                                }}
                                leftColumns={this.leftTableColumns}
                                rightColumns={this.rightTableColumns}
                              />
                            </div>
                          </FormItem>
                          {this.props.call !== 'schedule' ? (
                            <Form.Item
                              label={
                                <LangContext.Consumer>
                                  {i18n => i18n.d.dateRange}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.eventdate &&
                                errors.eventdate &&
                                'warning'
                              }
                              help={touched.eventdate && errors.eventdate}
                            >
                              <RangePicker
                                disabledDate={disabledDate}
                                onChange={value =>
                                  setFieldValue('eventdate', value)
                                }
                                format="DD/MM/YYYY"
                                defaultValue={[
                                  moment('00:00', 'HH:mm'),
                                  moment('23:59', 'HH:mm'),
                                ]}
                              />
                            </Form.Item>
                          ) : (
                            ''
                          )}

                          <FormItem>
                            <Row gutter={24}>
                              {this.props.call == 'schedule' ? (
                                <Col span={8} style={{ marginTop: '10px' }}>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    block
                                  >
                                    <LangContext.Consumer>
                                      {i18n => i18n.s.save}
                                    </LangContext.Consumer>
                                  </Button>
                                </Col>
                              ) : (
                                <>
                                  <Col span={8}>
                                    <Button type="defualt" block>
                                      <Link to="/">
                                        <LangContext.Consumer>
                                          {i18n => i18n.c.cancel}
                                        </LangContext.Consumer>
                                      </Link>
                                    </Button>
                                  </Col>
                                  <Col span={8}>
                                    <Button
                                      type="primary"
                                      onClick={() =>
                                        this.handleMenuClick(
                                          setFieldValue,
                                          handleSubmit,
                                          'preview',
                                        )
                                      }
                                      block
                                    >
                                      <LangContext.Consumer>
                                        {i18n => i18n.p.preview}
                                      </LangContext.Consumer>
                                    </Button>
                                  </Col>
                                  <Col span={8}>
                                    <Button
                                      type="primary"
                                      onClick={() =>
                                        this.handleMenuClick(
                                          setFieldValue,
                                          handleSubmit,
                                          'excel',
                                        )
                                      }
                                      block
                                    >
                                      <LangContext.Consumer>
                                        {i18n => i18n.r.report}
                                      </LangContext.Consumer>
                                    </Button>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </FormItem>
                        </Form>
                      </Row>
                    </>
                  )}
                />
              )}
            </LangContext.Consumer>
          </Spin>
        </Spin>
      </div>
    )
  }
}
