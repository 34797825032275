import { createTypes, createAction } from 'lib/action'
const LOAD_MASTERDATA_VEHICLE = createTypes('masterdatavehicle', 'load')
const LOAD_MASTERDATA_DRIVER = createTypes('masterdatadriver', 'load')

const LOAD_VEHICLE = createTypes('vehicle', 'load')
const LOAD_AUTOCOMPLETE = createTypes('autocomplete', 'load')
const SELECTED_AUTOCOMPLETE = createTypes('autocomplete', 'selected')

const LOAD_AUTOCOMPLETE_DRIVER_NAME = createTypes('autocompleteDriver', 'load')
const SELECTED_AUTOCOMPLETE_DRIVER_NAME = createTypes(
  'autocompleteDriver',
  'selected',
)

const LOAD_AUTOCOMPLETE_DRIVER1 = createTypes('autocompleteDriver1', 'load')
const SELECTED_AUTOCOMPLETE_DRIVER1 = createTypes(
  'autocompleteDriver1',
  'selected',
)

const LOAD_AUTOCOMPLETE_DRIVER2 = createTypes('autocompleteDriver2', 'load')
const SELECTED_AUTOCOMPLETE_DRIVER2 = createTypes(
  'autocompleteDriver2',
  'selected',
)

const LOAD_AUTOCOMPLETE_DRIVER_CODE = createTypes(
  'autocompleteDriverCode',
  'load',
)
const SELECTED_AUTOCOMPLETE_DRIVER_CODE = createTypes(
  'autocompleteDriverCode',
  'selected',
)

const LOAD_VISIBILITY_PARENT = createTypes('loadVisibilityParent', 'load')
const LOAD_VISIBILITY_CHILDREN = createTypes('loadVisibilityChildren', 'load')

const CREATE_VEHICLE = createTypes('vehicle', 'cerate')
const MATCH_VEHICLE = createTypes('matching', 'cerate')
const UNMATCH_VEHICLE = createTypes('unmatching', 'update')

const UPDATE_MASTERDATA_VEHICLE = createTypes('masterdatavehicle', 'update')

const LOAD_VEHICLE_FOR_EDIT = createTypes('vehicleforedit', 'load')

const LOAD_DRIVER_HISTORY = createTypes('driverhistory', 'load')
const LOAD_VEHICLE_PART = createTypes('part', 'load')
const EXPORT_MASTERDATA_VEHICLE = createTypes('exportmasterdatavehicle', 'load')
const CREATE_VISIBILITY = createTypes('createTypes', 'create')
const EDIT_VISIBILITY = createTypes('editVisibility', 'edit')
const LOAD_VISIBILITY_BY_VEHICLE = createTypes(
  'loadVisibilityByVihicle',
  'load',
)
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadMasterVehicle = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_VEHICLE.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_MASTERDATA_VEHICLE.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_MASTERDATA_VEHICLE.FAITLURE),
}

const loadDriverFormapping = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_DRIVER.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_MASTERDATA_DRIVER.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_MASTERDATA_DRIVER.FAITLURE),
}

const loadVehicle = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLE.REQUEST, { data, loading }),
  success: (lists, page) => createAction(LOAD_VEHICLE.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_VEHICLE.FAITLURE),
}

const loadAutocompleteLocation = {
  request: (data, callback) =>
    createAction(LOAD_AUTOCOMPLETE.REQUEST, { data, callback }),
  success: lists => createAction(LOAD_AUTOCOMPLETE.SUCCESS, { lists }),
  failure: () => createAction(LOAD_AUTOCOMPLETE.FAILURE),
}

function selectedAutocompleteLocation(id, lists, selectedName, cb) {
  return {
    type: SELECTED_AUTOCOMPLETE,
    payload: { id, lists, selectedName, cb },
  }
}

const loadAutocompleteDrivername = {
  request: (data, callback) =>
    createAction(LOAD_AUTOCOMPLETE_DRIVER_NAME.REQUEST, { data, callback }),
  success: lists =>
    createAction(LOAD_AUTOCOMPLETE_DRIVER_NAME.SUCCESS, { lists }),
  failure: () => createAction(LOAD_AUTOCOMPLETE_DRIVER_NAME.FAILURE),
}

function selectedAutocompleteDriverName(id, lists, selectedName, cb) {
  return {
    type: SELECTED_AUTOCOMPLETE_DRIVER_NAME,
    payload: { id, lists, selectedName, cb },
  }
}

const loadAutocompleteDriver1 = {
  request: (data, callback) =>
    createAction(LOAD_AUTOCOMPLETE_DRIVER1.REQUEST, { data, callback }),
  success: lists => createAction(LOAD_AUTOCOMPLETE_DRIVER1.SUCCESS, { lists }),
  failure: () => createAction(LOAD_AUTOCOMPLETE_DRIVER1.FAILURE),
}

function selectedAutocompleteDriver1(id, lists, selectedName, cb) {
  return {
    type: SELECTED_AUTOCOMPLETE_DRIVER1,
    payload: { id, lists, selectedName, cb },
  }
}

const loadAutocompleteDriver2 = {
  request: (data, callback) =>
    createAction(LOAD_AUTOCOMPLETE_DRIVER2.REQUEST, { data, callback }),
  success: lists => createAction(LOAD_AUTOCOMPLETE_DRIVER2.SUCCESS, { lists }),
  failure: () => createAction(LOAD_AUTOCOMPLETE_DRIVER2.FAILURE),
}

function selectedAutocompleteDriver2(id, lists, selectedName, cb) {
  return {
    type: SELECTED_AUTOCOMPLETE_DRIVER2,
    payload: { id, lists, selectedName, cb },
  }
}

const loadAutocompleteDriverCode = {
  request: (url, companyID, accessToken, queryString) =>
    createAction(LOAD_AUTOCOMPLETE_DRIVER_CODE.REQUEST, {
      url,
      companyID,
      accessToken,
      queryString,
    }),
  success: lists =>
    createAction(LOAD_AUTOCOMPLETE_DRIVER_CODE.SUCCESS, { lists }),
  failure: () => createAction(LOAD_AUTOCOMPLETE_DRIVER_CODE.FAILURE),
}

function selectedAutocompleteDriverCode(id, lists, selectedName, cb) {
  return {
    type: SELECTED_AUTOCOMPLETE_DRIVER_CODE,
    payload: { id, lists, selectedName, cb },
  }
}

const createVehicle = {
  request: (data, loading) =>
    createAction(CREATE_VEHICLE.REQUEST, { data, loading }),
  success: res => createAction(CREATE_VEHICLE.SUCCESS, { res }),
  failure: () => createAction(CREATE_VEHICLE.FAILURE),
}

const matchVehicle = {
  request: (data, loading) =>
    createAction(MATCH_VEHICLE.REQUEST, { data, loading }),
  success: res => createAction(MATCH_VEHICLE.SUCCESS, { res }),
  failure: () => createAction(MATCH_VEHICLE.FAILURE),
}

const unmatchVehicle = {
  request: (data, loading) =>
    createAction(UNMATCH_VEHICLE.REQUEST, { data, loading }),
  success: res => createAction(UNMATCH_VEHICLE.SUCCESS, { res }),
  failure: () => createAction(UNMATCH_VEHICLE.FAILURE),
}

const loadVehicleForEdit = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLE_FOR_EDIT.REQUEST, { data, loading }),
  success: listsVehicle =>
    createAction(LOAD_VEHICLE_FOR_EDIT.SUCCESS, { listsVehicle }),
  failure: () => createAction(LOAD_VEHICLE_FOR_EDIT.FAITLURE),
}

const loadDriverHistory = {
  request: (data, loading) =>
    createAction(LOAD_DRIVER_HISTORY.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_DRIVER_HISTORY.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_DRIVER_HISTORY.FAITLURE),
}

const loadVehiclePart = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLE_PART.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_VEHICLE_PART.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_VEHICLE_PART.FAITLURE),
}

const updateMasterDataVehicle = {
  request: (data, loading) =>
    createAction(UPDATE_MASTERDATA_VEHICLE.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_MASTERDATA_VEHICLE.SUCCESS, { res }),
  failure: () => createAction(UPDATE_MASTERDATA_VEHICLE.FAILURE),
}

const exportMasterVehicle = {
  request: (data, loading) =>
    createAction(EXPORT_MASTERDATA_VEHICLE.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_MASTERDATA_VEHICLE.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_MASTERDATA_VEHICLE.FAITLURE),
}

const loadVisibilityParent = {
  request: (data, callback) =>
    createAction(LOAD_VISIBILITY_PARENT.REQUEST, { data, callback }),
  success: data => createAction(LOAD_VISIBILITY_PARENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VISIBILITY_PARENT.FAITLURE),
}

const loadVisibilityChildren = {
  request: (data, callback) =>
    createAction(LOAD_VISIBILITY_CHILDREN.REQUEST, { data, callback }),
  success: data => createAction(LOAD_VISIBILITY_CHILDREN.SUCCESS, { data }),
  failure: () => createAction(LOAD_VISIBILITY_CHILDREN.FAITLURE),
}

const createVisibility = {
  request: (data, callback) =>
    createAction(CREATE_VISIBILITY.REQUEST, { data, callback }),
  success: data => createAction(CREATE_VISIBILITY.SUCCESS, { data }),
  failure: () => createAction(CREATE_VISIBILITY.FAITLURE),
}

const editVisibility = {
  request: (data, callback) =>
    createAction(EDIT_VISIBILITY.REQUEST, { data, callback }),
  success: data => createAction(EDIT_VISIBILITY.SUCCESS, { data }),
  failure: () => createAction(EDIT_VISIBILITY.FAITLURE),
}

const loadVisibilityByVehicle = {
  request: (data, callback) =>
    createAction(LOAD_VISIBILITY_BY_VEHICLE.REQUEST, { data, callback }),
  success: data => createAction(LOAD_VISIBILITY_BY_VEHICLE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VISIBILITY_BY_VEHICLE.FAITLURE),
}

export {
  loadMasterVehicle,
  LOAD_MASTERDATA_VEHICLE,
  loadVehicle,
  LOAD_VEHICLE,
  loadAutocompleteLocation,
  LOAD_AUTOCOMPLETE,
  selectedAutocompleteLocation,
  SELECTED_AUTOCOMPLETE,
  loadAutocompleteDrivername,
  LOAD_AUTOCOMPLETE_DRIVER_NAME,
  selectedAutocompleteDriverName,
  SELECTED_AUTOCOMPLETE_DRIVER_NAME,
  loadAutocompleteDriver1,
  LOAD_AUTOCOMPLETE_DRIVER1,
  selectedAutocompleteDriver1,
  SELECTED_AUTOCOMPLETE_DRIVER1,
  loadAutocompleteDriver2,
  LOAD_AUTOCOMPLETE_DRIVER2,
  selectedAutocompleteDriver2,
  SELECTED_AUTOCOMPLETE_DRIVER2,
  loadAutocompleteDriverCode,
  LOAD_AUTOCOMPLETE_DRIVER_CODE,
  selectedAutocompleteDriverCode,
  SELECTED_AUTOCOMPLETE_DRIVER_CODE,
  createVehicle,
  CREATE_VEHICLE,
  loadDriverFormapping,
  LOAD_MASTERDATA_DRIVER,
  matchVehicle,
  MATCH_VEHICLE,
  unmatchVehicle,
  UNMATCH_VEHICLE,
  loadVehicleForEdit,
  LOAD_VEHICLE_FOR_EDIT,
  updateMasterDataVehicle,
  UPDATE_MASTERDATA_VEHICLE,
  LOAD_DRIVER_HISTORY,
  loadDriverHistory,
  LOAD_VEHICLE_PART,
  loadVehiclePart,
  EXPORT_MASTERDATA_VEHICLE,
  exportMasterVehicle,
  loadVisibilityParent,
  LOAD_VISIBILITY_PARENT,
  loadVisibilityChildren,
  LOAD_VISIBILITY_CHILDREN,
  CREATE_VISIBILITY,
  createVisibility,
  EDIT_VISIBILITY,
  editVisibility,
  LOAD_VISIBILITY_BY_VEHICLE,
  loadVisibilityByVehicle,
  REMOVE_LISTS,
  removeLists,
}
