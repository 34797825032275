import React, { Component, Fragment } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Index extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        <div className="divider__web__content">
          <Divider />
        </div>
        {/* สรุปค่าบริการ GPS ทั้งหมด */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'gpsVendorPayingSummaryReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/gpsvendorpayingsummaryreport">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.g.gpsVendorPayingSummaryReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลสรุปประสิทธิภาพของ GPS แต่ละคัน
                          โดยแสดงสถานะการชำระค่าบริการแต่ละเดือนตามเงื่อนไขที่กำหนด
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปเรียกเก็บเงินตามผู้รับเหมา */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'partnerPerformReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/partnerperformreport">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.p.partnerPerformReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลการเรียกเก็บค่าบริการจากผู้รับเหมา
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปค่าบริการตาม GPS Vendor */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'gpsVendorPayingReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/gpsvendorpayingreport">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.g.gpsVendorPayingReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานสรุปค่าบริการของ GPS Vendor
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Index
