import { Modal, Button, Row, Col, Icon, List } from 'antd'
import React, { useState } from 'react'
import LangContext from 'modules/shared/context/langContext'

const ListNearestModal = ({
  showModal,
  onClose,
  chooseRoute,
  nearByLocationList,
}) => {
  const [itemToShow, setItemToShow] = useState(5)
  const [expanded, setExpanded] = useState(false)

  const handleSeeMore = () => {
    if (itemToShow === 5) {
      setItemToShow(nearByLocationList.length)
      setExpanded(true)
    } else {
      setItemToShow(5)
      setExpanded(false)
    }
  }
  return (
    <Modal
      title={
        <div style={{ textAlign: 'center', padding: '8px' }}>
          <LangContext.Consumer>
            {i18n => i18n.n.nearestPlant}
          </LangContext.Consumer>
        </div>
      }
      centered
      visible={showModal}
      onCancel={() => {
        onClose()
        setItemToShow(5)
        setExpanded(false)
      }}
      //   maskClosable={false}
      width={450}
      footer={[
        <div
          onClick={() => {
            handleSeeMore()
          }}
          style={{
            textAlign: 'center',
            color: 'red',
            cursor: 'pointer',
            padding: '16px',
          }}
        >
          <LangContext.Consumer>
            {i18n => i18n.s.showMorePlantInformation}
          </LangContext.Consumer>{' '}
          <Icon type={expanded ? 'up' : 'down'} />
        </div>,
      ]}
      className="modal-edit-delete-route-smart-dispatching"
    >
      <div
        style={{
          maxHeight: expanded ? '450px' : '400px',
          overflow: expanded ? 'auto' : '',
        }}
        id="style-1"
      >
        <Row gutter={[0, 16]} type="flex" justify="space-around" align="middle">
          {nearByLocationList.slice(0, itemToShow).map(item => (
            <>
              <Col span={16} align="left">
                <span style={{ color: '#172B4D' }}>{item.location_name}</span>
                <br />
                <span style={{ color: '#D6D6D6' }}>
                  <LangContext.Consumer>
                    {i18n => i18n.f.factoryCode}
                  </LangContext.Consumer>
                  : {item.location_code + item.location_code}
                </span>{' '}
                <span style={{ color: '#D6D6D6' }}>
                  <LangContext.Consumer>
                    {i18n => i18n.s.section}
                  </LangContext.Consumer>
                  : {item.section_name != null ? item.section_name : '-'}
                </span>
              </Col>
              <Col span={8} align="right">
                <Button
                  type="primary"
                  onClick={() => {
                    chooseRoute({
                      locationCode: item.location_code,
                      locationName: item.location_name,
                    })
                    setItemToShow(5)
                    setExpanded(false)
                    onClose()
                  }}
                  style={{ marginRight: 10 }}
                >
                  <img src="/img/Direct.png" />{' '}
                  <LangContext.Consumer>
                    {i18n => i18n.d.direction}
                  </LangContext.Consumer>
                </Button>
              </Col>
            </>
          ))}
        </Row>
      </div>
    </Modal>
  )
}

export default ListNearestModal
