import moment from 'moment'
import { calcCrowDistance } from '../../lib/helper'
let trackingAdapter = data => {
    return data.map(ele => {
        let directionDegree = Math.abs(ele.direction_degree - 359)

        if (directionDegree + 90 > 359) directionDegree = directionDegree + 90 - 359
        else directionDegree = directionDegree + 90

        if (ele.latest_pos_update_at != '-' && ele.latest_pos_update_at != '')
            ele.latest_pos_update_at =
                moment
                    .utc()
                    .local()
                    .diff(
                        moment(ele.latest_pos_update_at, 'YYYY-MM-DD HH:mm:ss')
                            .utc()
                            .local(),
                        'minutes',
                    ) <= 30
                    ? 'now'
                    : moment(ele.latest_pos_update_at)
                        //   .add(7, "hours")
                        .format('YYYY-MM-DD HH:mm:ss')

        if (ele.gps_active_at != '-' && ele.gps_active_at != '')
            ele.gps_active_at =
                moment
                    .utc()
                    .local()
                    .diff(
                        moment(ele.gps_active_at, 'YYYY-MM-DD HH:mm:ss')
                            .utc()
                            .local(),
                        'minutes',
                    ) <= 30
                    ? 'now'
                    : moment(ele.gps_active_at)
                        //   .add(7, "hours")
                        .format('YYYY-MM-DD HH:mm:ss')
        if (ele.gps_image_active_at != '-' && ele.gps_image_active_at != '')
            ele.gps_image_active_at =
                moment
                    .utc()
                    .local()
                    .diff(
                        moment(ele.gps_image_active_at, 'YYYY-MM-DD HH:mm:ss')
                            .utc()
                            .local(),
                        'minutes',
                    ) <= 30
                    ? 'now'
                    : moment(ele.gps_image_active_at)
                        //   .add(7, "hours")
                        .format('YYYY-MM-DD HH:mm:ss')

        return {
            ...ele,
            type: 'device',
            vehicle_tel: '',
            engine: ele.engine ? '0' : '1',
            engine_status: ele.engine ? 'on' : 'off',
            lat: ele.lat,
            lng: ele.lng,
            latest_lat: ele.lat,
            latest_lng: ele.lng,
            directionDegree: Math.ceil(directionDegree),
            has_vtg: '-',
            vtg_x: '-',
            vtg_y: '-',
            velocity: ele.speed == '-' || ele.speed == 0 ? 0 : Math.round(ele.speed),
            latest_pos_update_at: ele.latest_pos_update_at,
            gps_active_at: ele.gps_active_at,
            gps_image_active_at: ele.gps_image_active_at,
        }
    })
}

let replayAdapter = data => {
    let tempLat = 0
    let tempLng = 0
    let tempDirectionDegree = -1
    let tempEngineStatus = false
    let tempAccDistance = 0
    let sumDistance = 0

    data = data.filter(ele => ele.lat != 0 && ele.lng != 0)
    data = data
        .map(ele => {
            if (ele.created_at != '-' && ele.created_at != '') {
                ele.created_at = moment(ele.created_at)
                    .add(7, 'hours')
                    .format('YYYY-MM-DD HH:mm:ss')
            }

            let lengthInMeters = 0
            let latDiff = 0
            let lngDiff = 0

            let directionDegree = Math.abs(ele.directionDegree - 359)

            if (directionDegree + 90 > 359)
                directionDegree = directionDegree + 90 - 359
            else directionDegree = directionDegree + 90

            // ไม่ใช่วนลูปรอบแรก
            if (tempLat != null && tempLat != 0) {
                // รอบที่แล้ว และ รอบนี้ติดเครื่อง
                if (
                    tempEngineStatus == (typeof ele.engineOn != 'undefined') ||
                    ele.isfixed == false
                )
                    lengthInMeters = Math.ceil(
                        calcCrowDistance(tempLat, tempLng, ele.lat, ele.lng),
                    )

                // console.log(lengthInMeters, ele.created_at);
                latDiff = parseFloat(Math.abs(ele.lat - tempLat))
                lngDiff = parseFloat(Math.abs(ele.lng - tempLng))
            }

            if (ele.acc_distance > tempAccDistance && tempAccDistance > 0) {
                sumDistance += parseFloat(ele.acc_distance - tempAccDistance)
            }


            tempLat = ele.lat
            tempLng = ele.lng
            tempDirectionDegree = ele.directionDegree
            tempEngineStatus = typeof ele.engineOn != 'undefined'
            tempAccDistance = ele.acc_distance

            //   if (
            //     tempEngineStatus == false &&
            //     (typeof ele.engineOn != 'undefined') == false &&
            //     tempLat != 0 &&
            //     (latDiff >= 0.01 || lngDiff >= 0.01)
            //   ) {
            //     ele.lat = tempLat
            //     ele.lng = tempLng
            //     ele.directionDegree = tempDirectionDegree
            //     ele.acc_distance = tempAccDistance
            //   } else if (tempLat != 0 && (latDiff >= 0.1 || lngDiff >= 0.1)) {
            //     ele.lat = tempLat
            //     ele.lng = tempLng
            //     ele.directionDegree = tempDirectionDegree
            //     ele.acc_distance = tempAccDistance
            //   } else {
            //     if (tempAccDistance < ele.acc_distance) {
            //       sumDistance += parseFloat(ele.acc_distance - tempAccDistance)
            //     }

            //     tempLat = ele.lat
            //     tempLng = ele.lng
            //     tempDirectionDegree = ele.directionDegree
            //     tempEngineStatus = typeof ele.engineOn != 'undefined'
            //     tempAccDistance = ele.acc_distance
            //   }

            return {
                ...ele,
                created_at: ele.created_at,
                distance_from_prev: lengthInMeters,
                acc_distance: sumDistance,
                directionDegree: Math.ceil(directionDegree),
            }
        })
        .filter(ele => {
            return ele.distance_from_prev < 100 || ele.speed < 120
        })

    return data
}

let parkingAdapter = data => {
    return data.map(ele => {
        if (ele.startAt != '') {
            ele.startAt =
                moment
                    .utc()
                    .diff(moment.utc(ele.startAt, 'YYYY-MM-DD HH:mm:ss'), 'minutes') <= 10
                    ? 'now'
                    : moment(ele.startAt)
                        .add(7, 'hours')
                        .format('YYYY-MM-DD HH:mm:ss')
        }

        return {
            ...ele,
            startAt: ele.startAt,
        }
    })
}

let concreteDashboardPendingJobAdapter = data => {
    let soList = []
    let pendingList = []
    let pendingSoList = []
    let pendingTickets = []
    Object.entries(data).map((ele, index) => {
        let salesOrder = ele[1].sales_orders
        soList = Object.entries(salesOrder).map((ele, index) => ele)

        pendingTickets = soList.map((ele6, index) => {
            return Object.entries(ele6[1].tickets)
                .map((ele2, index) => {
                    return {
                        ...ele2[1],
                        card_name: ele[1].card_name,
                        ticket_code: ele[1].code,
                    }
                })
                .filter((ele3, index) => ele3.state == 'pending')
        })

        pendingList = pendingTickets.filter((ele4, index) => ele4.length > 0)

        pendingList.map((ele5, index) => pendingSoList.push(...ele5))
    })

    // console.log(pendingSoList);
    return pendingSoList
}

function calcDistance(lat1, lat2, lon1, lon2) {
    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 = (lon1 * Math.PI) / 180
    lon2 = (lon2 * Math.PI) / 180
    lat1 = (lat1 * Math.PI) / 180
    lat2 = (lat2 * Math.PI) / 180

    // Haversine formula
    let dlon = lon2 - lon1
    let dlat = lat2 - lat1
    let a =
        Math.pow(Math.sin(dlat / 2), 2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2)

    let c = 2 * Math.asin(Math.sqrt(a))

    // Radius of earth in kilometers. Use 3956
    // for miles 6371
    let r = 3956

    // calculate the result
    return c * r
}

export {
    trackingAdapter,
    replayAdapter,
    concreteDashboardPendingJobAdapter,
    parkingAdapter,
    calcDistance,
}
