import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  AutoComplete,
  Icon,
  Select,
} from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { Option, OptGroup } = AutoComplete
export default class Filter extends Component {
  state = {
    platenolists: [],
  }

  changeVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue('plate_no', option.props.children)
    setFieldValue('imei', option.props.label)
  }

  selectVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue('plate_no', option.props.children)
    setFieldValue('imei', option.props.label)
  }

  render() {
    const { OnClose, onSubmit, vehicleLists } = this.props
    const platenolists = vehicleLists.map(group => (
      <OptGroup
        key={group.title}
        label={
          <span style={{ color: 'blue' }}>
            <LangContext.Consumer>
              {i18n => i18n.o[group.title]}
            </LangContext.Consumer>
          </span>
        }
      >
        {group.children.map(opt => (
          <Option
            key={opt.id}
            label={opt.imei}
            disabled={group.title === 'offline' ? true : false}
          >
            {opt.plate_no}{' '}
            {group.title === 'online' ? (
              <Icon type="car" theme="twoTone" twoToneColor="#00BF06" />
            ) : (
              <Icon type="car" theme="twoTone" twoToneColor="#c91c1c" />
            )}
          </Option>
        ))}
      </OptGroup>
    ))
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <LangContext.Consumer>
          {i18n => (
            <Formik
              initialValues={{
                plate_no: '',
                date: moment(),
                camera_position: 'front',
              }}
              validate={values => {
                // let errors = {};
                // return errors;
              }}
              validationSchema={yup.object().shape({
                plate_no: yup
                  .string()
                  .required(i18n.p.pleaseSelectVehicleRegistration),
                date: yup
                  .string()
                  .required(i18n.c.chooseDatePls)
                  .nullable(),
              })}
              onSubmit={values => {
                const loading = true
                onSubmit(values, loading)
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
                resetForm,
              }) => (
                <div>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col
                        span={2}
                        style={{
                          textAlign: 'right',
                          top: '10px',
                          marginRight: '5px',
                        }}
                      >
                        {
                          <span className="regular-bold-14">
                            <LangContext.Consumer>
                              {i18n => i18n.p.plateNoVehicleCode}
                            </LangContext.Consumer>
                          </span>
                        }
                        <span style={{ color: 'red' }}>*</span>
                      </Col>
                      <Col span={4}>
                        <FormItem
                          validateStatus={
                            touched.plate_no && errors.plate_no && 'error'
                          }
                          help={touched.plate_no && errors.plate_no}
                        >
                          <AutoComplete
                            dataSource={platenolists}
                            style={{ width: '100%' }}
                            optionLabelProp="children"
                            filterOption={true}
                            optionFilterProp="children"
                            // placeholder="ค้นหาทะเบียน"

                            value={values.plate_no}
                            onChange={(value, option) =>
                              this.changeVehiclePlateno(
                                value,
                                option,
                                setFieldValue,
                              )
                            }
                            onSelect={(value, option) =>
                              this.selectVehiclePlateno(
                                value,
                                option,
                                setFieldValue,
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col
                        span={1}
                        style={{
                          textAlign: 'right',
                          top: '10px',
                          marginRight: '5px',
                        }}
                      >
                        {
                          <span className="regular-bold-14">
                            <LangContext.Consumer>
                              {i18n => i18n.d.date}
                            </LangContext.Consumer>
                          </span>
                        }
                        <span style={{ color: 'red' }}>*</span>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          required={true}
                          validateStatus={
                            touched.date && errors.date && 'error'
                          }
                          help={touched.date && errors.date}
                        >
                          <DatePicker
                            disabledDate={disabledDate}
                            format="DD/MM/YYYY"
                            onChange={value => setFieldValue('date', value)}
                            value={values.date}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        span={2}
                        style={{
                          textAlign: 'right',
                          top: '10px',
                          marginRight: '5px',
                        }}
                      >
                        {
                          <span className="regular-bold-14">
                            <LangContext.Consumer>
                              {i18n => i18n.c.camera_position}
                            </LangContext.Consumer>
                          </span>
                        }
                        <span style={{ color: 'red' }}>*</span>
                      </Col>
                      <Col span={4}>
                        <FormItem
                          validateStatus={
                            touched.camera_position &&
                            errors.camera_position &&
                            'error'
                          }
                          help={
                            touched.camera_position && errors.camera_position
                          }
                        >
                          <Select
                            //showSearch
                            // style={{ width: 200 }}
                            onChange={value =>
                              setFieldValue('camera_position', value)
                            }
                            value={values.camera_position}
                          >
                            <Option value="front">
                              <LangContext.Consumer>
                                {i18n => i18n.f.front}
                              </LangContext.Consumer>
                            </Option>
                            <Option value="back">
                              <LangContext.Consumer>
                                {i18n => i18n.c.camera_back}
                              </LangContext.Consumer>
                            </Option>

                            <Option value="extra">
                              <LangContext.Consumer>
                                {i18n => i18n.c.camera_extra}
                              </LangContext.Consumer>
                            </Option>
                          </Select>
                        </FormItem>
                      </Col>

                      <Col span={3} style={{ marginLeft: '10px' }}>
                        <FormItem>
                          <Button htmlType="submit" type="primary">
                            <LangContext.Consumer>
                              {i18n => i18n.a.apply}
                            </LangContext.Consumer>
                          </Button>
                        </FormItem>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            />
          )}
        </LangContext.Consumer>
      </div>
    )
  }
}
