import {
  LOAD_LINENOTIFY,
  CREATE_LINENOTIFY,
  UPDATE_LINENOTIFY,
  CLEAR_LINENOTIFY,
  GET_LINENOTIFY,
  CLEAR_RESPONSE,
} from './actions'

const initialState = {
  linenotifyLists: [],
  linenotifyLoading: false,
  linenotifyTotal: 0,
  dataResponse: { status: '', msg: '', id: '', action_type: '' },
  check_linenotify: true,
  dataLineNotify: {
    id: '',
    line_token: '',
    notify_type: [],
    notify_type_name: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LINENOTIFY.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        linenotifyLoading: loading,
      }
    case LOAD_LINENOTIFY.SUCCESS:
      return {
        ...state,
        linenotifyLists: action.payload.data.data,
        linenotifyLoading: false,
        linenotifyTotal: action.payload.data.total,
      }
    case CREATE_LINENOTIFY.REQUEST:
      const { loading: loading2 } = action.payload
      return {
        ...state,
        linenotifyLoading: loading2,
      }
    case CREATE_LINENOTIFY.SUCCESS:
      return {
        ...state,
        dataResponse: {
          status: action.payload.data.status,
          msg: action.payload.data.msg,
          action_type: action.payload.data.action_type,
        },
        linenotifyLoading: false,
      }
    case UPDATE_LINENOTIFY.REQUEST:
      const { loading: loading3 } = action.payload
      return {
        ...state,
        linenotifyLoading: loading3,
      }
    case UPDATE_LINENOTIFY.SUCCESS:
      return {
        ...state,
        dataResponse: {
          status: action.payload.data.status,
          msg: action.payload.data.msg,
          action_type: action.payload.data.action_type,
        },
        linenotifyLoading: false,
      }

    case GET_LINENOTIFY.REQUEST:
      return {
        ...state,
        check_linenotify: true,
      }
    case GET_LINENOTIFY.SUCCESS:
      return {
        ...state,
        dataLineNotify: action.payload.data.data,
        linenotifyLoading: false,
        check_linenotify: false,
      }

    case CLEAR_RESPONSE:
      return {
        ...state,
        dataResponse: { status: '', msg: '', id: '', action_type: '' },
      }
    case CLEAR_LINENOTIFY:
      return {
        ...state,
        dataLineNotify: {
          id: '',
          line_token: '',
          notify_type: [],
          notify_type_name: [],
        },
      }
    default:
      return state
  }
}
