import {
  LOAD_ALARMALERTBYDRIVER,
  LOAD_ALARMALERTBYDRIVER_PREVIEW,
  LOAD_DRIVER_ALARMALERTBYDRIVER,
} from './actions'

const initialState = {
  alarmalertbydriverUrl: '',
  driverLists: [],
  alarmalertbydriverLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALARMALERTBYDRIVER.REQUEST:
    case LOAD_ALARMALERTBYDRIVER_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        alarmalertbydriverLoading: loading,
      }
    case LOAD_ALARMALERTBYDRIVER.SUCCESS:
    case LOAD_ALARMALERTBYDRIVER_PREVIEW.SUCCESS:
      return {
        ...state,
        alarmalertbydriverUrl: action.payload.data.data,
        alarmalertbydriverLoading: false,
      }
    case LOAD_DRIVER_ALARMALERTBYDRIVER.SUCCESS:
      //console.log(action.payload.data.data);
      return {
        ...state,
        driverLists: action.payload.data.data,
      }
    default:
      return state
  }
}
