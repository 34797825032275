import {
  MONITOR_GPS_TABLE_LOG,
  MONITOR_GPS_CHART_LOG,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  // *ดึงข้อมูล table log
  check_table_log: false,
  table_log: [],

  // *ดึงข้อมูล chart log
  check_chart_log: false,
  chart_log: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    // *ดึงข้อมูล table log
    case MONITOR_GPS_TABLE_LOG.REQUEST:
      return {
        ...state,
        check_table_log: true,
        table_log: [],
      }
    case MONITOR_GPS_TABLE_LOG.SUCCESS:
      return {
        ...state,
        table_log: action.payload.lists || [],
        check_table_log: false,
      }

    case MONITOR_GPS_CHART_LOG.REQUEST:
      return {
        ...state,
        check_chart_log: true,
        chart_log: [],
      }
    case MONITOR_GPS_CHART_LOG.SUCCESS:
      return {
        ...state,
        chart_log: action.payload.lists || [],
        check_chart_log: false,
      }

    default:
      return state
  }
}
