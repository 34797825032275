const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const regularDrawerSize = document.body.clientWidth / 3;

const askForSoundPlayingText =
  "This page detect Re-loading according to media policy we need you to Enable sound playing.";

const speedRangeMarks = {
  0: "0",
  20: "20",
  40: "40",
  60: "60",
  80: "80",
  100: "100",
  120: "120",
  140: "140",
  160: "160",
  180: "180",
  200: "200+"
};

const timeRangeMarks = {
  0: "0",
  20: "20",
  40: "40",
  60: "60",
  90: "90",
  120: "120",
  150: "150",
  180: "180",
  210: "210",
  240: "240+"
};

export {
  regularDrawerSize,
  phoneRegExp,
  speedRangeMarks,
  timeRangeMarks,
  askForSoundPlayingText
};
