import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
//import { push } from "connected-react-router";

import * as actions from './actions'
import moment from 'moment'
import { timeZone } from '../../../lib/helper'

function* loadEvent(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const {
    data: {
      list_vehicle_id,
      vehicle_type_id,
      eventdate,
      type_file,
      event_id,
      event_type,
    },
  } = action.payload
  const date_start = moment(eventdate[0]['_d']).format('YYYY-MM-DD HH:mm')
  const date_end = moment(eventdate[1]['_d']).format('YYYY-MM-DD HH:mm')
  const event_name = event_id.label
  const event = event_id.key

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_LOGIN}/service/report/event`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id: COMPANY_ID,
        vehicle_type_id,
        user_id: USER_ID,
        type_file,
        event,
        event_name,
        event_type,
        vehicle_visibility,
      },
      //  { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    )

    yield put(actions.loadEvent.success(res.data))
    window.location = res.data.data
  } catch (err) {
    yield put(actions.loadEvent.failure())
  }
}

function* loadSafetyEvent(action) {
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: {
      vendor_name,
      list_vehicle_id,
      vehicle_type_id,
      eventdate,
      type_file,
      event,
      only_dp,
      partner,
      division,
      department,
      section,
      plant,
    },
  } = action.payload

  const date_start = timeZone(
    moment(eventdate[0]['_d']).format('YYYY-MM-DD HH:mm'),
    'start',
  )
  const date_end = timeZone(
    moment(eventdate[1]['_d']).format('YYYY-MM-DD HH:mm'),
    'end',
  )
  const event_name = ''

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/safetyevent`,
      //'http://localhost/reports/go/safetyevent',
      {
        vendor_name,
        date_start,
        date_end,
        list_vehicle_id: [],
        company_id: COMPANY_ID,
        vehicle_type_id,
        user_id: USER_ID,
        type_file,
        event,
        event_name,
        vehicle_visibility: list_vehicle_id.join(','),
        division: division.toString(),
        department: department.toString(),
        section: section.toString(),
        partner: partner.toString(),
        plant,
        only_dp,
      },
      //  { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    )

    yield put(actions.loadEvent.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadEvent.failure())
  }
}

function* loadSafetyEventpreview(action) {
  const {
    data: {
      vendor_name,
      vehicle_type_id,
      date_start,
      date_end,
      type_file,
      event,
      only_dp,
      partner,
      division,
      department,
      section,
      plant,
      company_id,
      user_id,
      event_name,
      vehicle_visibility,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/safetyevent`,
      {
        vendor_name,
        date_start,
        date_end,
        list_vehicle_id: [],
        company_id,
        vehicle_type_id,
        user_id,
        type_file,
        event,
        event_name,
        vehicle_visibility,
        division,
        department,
        section,
        partner,
        plant,
        only_dp,
      },
    )

    yield put(actions.loadEvent.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadEvent.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadVehicleByVendorID(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const { vehicletypeID, vendor } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebyvendorid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        vendor_id: vendor,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicleByVendorID.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleByVendorID.failure())
  }
}

function* loadVehiclebygroupid(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehiclebygroupid.success(res.data))
  } catch (err) {
    yield put(actions.loadVehiclebygroupid.failure())
  }
}

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadVehicleType.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleType.failure())
  }
}

function* loadVehicleVendor(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebyvendor`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadVehicleVendor.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleVendor.failure())
  }
}

function* loadPolicy(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpolicy`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadPolicy.success(res.data))
  } catch (err) {
    yield put(actions.loadPolicy.failure())
  }
}

function* loadEvents(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getevent`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadEvents.success(res.data))
  } catch (err) {
    yield put(actions.loadEvents.failure())
  }
}

function* loadVehiclebypolicy(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const { policyID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicleinpolicy`,
      {
        companyID: COMPANY_ID,
        policy_id: policyID,
        vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehiclebypolicy.success(res.data))
  } catch (err) {
    yield put(actions.loadVehiclebypolicy.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_EVENT.REQUEST, loadEvent),
    takeEvery(actions.LOAD_SAFETY_EVENT.REQUEST, loadSafetyEvent),
    takeEvery(
      actions.LOAD_SAFETY_EVENT_PREVIEW.REQUEST,
      loadSafetyEventpreview,
    ),
    takeEvery(actions.LOAD_VEHICLE_EVENT.REQUEST, loadVehicle),
    takeEvery(actions.LOAD_VEHICLE_BY_VENDOR.REQUEST, loadVehicleByVendorID),
    takeEvery(actions.LOAD_VEHICLE_TYPE_EVENT.REQUEST, loadVehicleType),
    takeEvery(actions.LOAD_VEHICLE_VENDOR_EVENT.REQUEST, loadVehicleVendor),
    takeEvery(actions.LOAD_POLICY_EVENT.REQUEST, loadPolicy),
    takeEvery(actions.LOAD_EVENT_EVENT.REQUEST, loadEvents),
    takeEvery(
      actions.LOAD_VEHICLE_BY_POLICY_EVENT.REQUEST,
      loadVehiclebypolicy,
    ),
    takeEvery(
      actions.LOAD_VEHICLE_EVENT_BY_GROUP.REQUEST,
      loadVehiclebygroupid,
    ),
  ])
}

export {
  loadEvent,
  loadVehicle,
  loadVehicleByVendorID,
  loadVehicleType,
  loadVehicleVendor,
  loadPolicy,
  loadEvents,
  loadVehiclebypolicy,
  loadVehiclebygroupid,
  loadSafetyEvent,
  loadSafetyEventpreview,
}
