import {
    LOAD_RRECAST_REPORT,
    EXPORT_PRECAST_REPORT,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    total: 1,
    loading: false,
    draw: -1,
    report_lists: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_RRECAST_REPORT.REQUEST:
            const { loading } = action.payload;
            return {
                ...state,
                loading: loading,
                check: true
            };

        case LOAD_RRECAST_REPORT.SUCCESS:        
            return {
                ...state,
                lists: action.payload.lists.data,
                report_lists:      action.payload.lists.Report,
                check: false,
                loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case EXPORT_PRECAST_REPORT.REQUEST:
            return {
                ...state,
                loading: true,
            };
    
        case EXPORT_PRECAST_REPORT.SUCCESS:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}