import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
import { timeZone } from './../../../lib/helper'

import * as actions from './actions'
import moment from 'moment'

function* loadNumberofdaysworked(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: {
      list_vehicle_id,
      op_of_amount_date,
      amount_date,
      distance_more_than,
      vehicle_type_id,
      eventdate,
      type_file,
    },
  } = action.payload
  // const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD");
  // const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD");

  const date_start = moment(eventdate[0]).format('YYYY-MM-DD 00:00:00')
  const date_end = moment(eventdate[1]).format('YYYY-MM-DD 23:59:59')

  console.log('sage', eventdate)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/numberofdaysworked`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id: COMPANY_ID,
        vehicle_type_id: `${vehicle_type_id}`,
        op_of_amount_date,
        user_id: USER_ID,
        type_file,
        amount_date,
        distance_more_than,
        vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadNumberofdaysworked.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadNumberofdaysworked.failure())
  }
}

function* loadNumberofdaysworkedpreview(action) {
  const {
    data: {
      date_start,
      date_end,
      list_vehicle_id,
      company_id,
      vehicle_type_id,
      op_of_amount_date,
      user_id,
      type_file,
      amount_date,
      distance_more_than,
      vehicle_visibility,
      header,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/numberofdaysworked`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id,
        vehicle_type_id,
        op_of_amount_date,
        user_id,
        type_file,
        amount_date,
        distance_more_than,
        vehicle_visibility,
      },
      { headers: header },
    )
    yield put(actions.loadNumberofdaysworkedpreview.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadNumberofdaysworkedpreview.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadVehicleType.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleType.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_NUMBEROFDAYSWORKED.REQUEST, loadNumberofdaysworked),
    takeEvery(
      actions.LOAD_NUMBEROFDAYSWORKED_PREVIEW.REQUEST,
      loadNumberofdaysworkedpreview,
    ),
    takeEvery(actions.LOAD_VEHICLE_NUMBEROFDAYSWORKED.REQUEST, loadVehicle),
    takeEvery(
      actions.LOAD_VEHICLE_TYPE_NUMBEROFDAYSWORKED.REQUEST,
      loadVehicleType,
    ),
  ])
}

export {
  loadNumberofdaysworked,
  loadNumberofdaysworkedpreview,
  loadVehicle,
  loadVehicleType,
}
