import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Transfer,
  Checkbox,
  Dropdown,
  Icon,
  Menu,
} from 'antd'

import LangContext, { i18n } from 'modules/shared/context/langContext'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Options = Select.Option
const { Option, OptGroup } = Select

class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    defaultSelect: {
      key: '',
      label: (
        <LangContext.Consumer>
          {i18n => i18n.s.select_event}
        </LangContext.Consumer>
      ),
    },
    eventType: 'policy',
  }

  componentDidMount() {
    const {
      loadVehicle,
      loadVehicleType,
      loadEvents,
      loadPolicy,
      loadVehiclebypolicy,
    } = this.props
    loadVehicle('', false)
    loadVehicleType()
    loadPolicy()
    loadEvents()
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
    //console.log("didmount");
    //loadVehiclebypolicy(this.state.defaultSelect.key, true);
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehiclebygroupid } = this.props
    setFieldValue('vehicle_type_id', value)
    loadVehiclebygroupid(value, true)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  onPolicyChange = (value, e, setFieldValue) => {
    const { loadVehiclebypolicy, loadVehicle } = this.props
    setFieldValue('event_id', value)
    if (e.props.type == 'policy') {
      loadVehiclebypolicy(value.key, true)
    } else {
      loadVehicle('', true)
    }

    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    this.setState({ eventType: e.props.type })
  }

  componentWillReceiveProps = nextProps => {
    const {
      vehicleLists,
      vehiclebygroupLists,
      policyLists,
      eventLoading,
    } = nextProps
    //console.log(nextProps);
    const vehlist = []
    const vehiclelists = []
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    vehicleLists.map(item => vehiclelists.push(item.id))
    this.setState({ vehicleList: vehlist })

    const vehlistselect = []
    vehiclebygroupLists.map(item => vehlistselect.push(item.id))

    var sameVehicle = []
    for (var i in vehlistselect) {
      if (vehiclelists.indexOf(vehlistselect[i]) > -1) {
        sameVehicle.push(vehlistselect[i])
      }
    }

    if (sameVehicle.length > 0) {
      this.setState({ vehicleSelect: sameVehicle })
    }
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    if (e.key == 'excel') {
      setFieldValue('type_file', 'excel', false)
    } else if (e.key == 'pdf') {
      setFieldValue('type_file', 'pdf', false)
    }
    setFieldValue('list_vehicle_id', this.state.vehicleSelect, false)
    setFieldValue('event_type', this.state.eventType, false)
    handleSubmit()
  }

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      policyLists,
      eventLists,
      vehiclebypolicyLists,
      eventLoading,
      onSubmit,
    } = this.props
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <Spin spinning={eventLoading}>
          <LangContext.Consumer>
            {i18n => (
              <Formik
                initialValues={{
                  vehicle_type_id: '',
                  eventdate: [
                    moment('00:00', 'HH:mm'),
                    moment('23:59', 'HH:mm'),
                  ],
                  list_vehicle_id: [],
                  event_id: this.state.defaultSelect,
                }}
                validate={values => {
                  let errors = {}
                  if (values.eventdate.length > 0) {
                    const date_start = moment(values.eventdate[0]['_d'])
                    const date_end = moment(values.eventdate[1]['_d'])
                    let day_diff = date_end.diff(date_start, 'days')
                    if (day_diff > 31) {
                      errors.eventdate = `${i18n.o.over1Month}`
                    }
                  }

                  if (this.state.vehicleSelect.length == 0) {
                    errors.list_vehicle_id = `${i18n.p.pleaseChooseVehicle}`
                  }
                  if (values.event_id.key == '') {
                    errors.event_id = `${i18n.p.pleaseChooseEvent}`
                  }

                  return errors
                }}
                validationSchema={yup.object().shape({
                  eventdate: yup
                    .array()
                    .required(`${i18n.p.pleaseChooseDateRange}`),
                })}
                onSubmit={values => {
                  const loading = true
                  onSubmit(values, loading)
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                }) => (
                  <Row type="flex" justify="center">
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        <LangContext.Consumer>
                          {i18n => i18n.e.EventReport}
                        </LangContext.Consumer>
                      </div>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.e.event}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.event_id && errors.event_id && 'error'
                        }
                        help={touched.event_id && errors.event_id}
                      >
                        <Select
                          labelInValue={true}
                          onChange={(value, e) =>
                            this.onPolicyChange(value, e, setFieldValue)
                          }
                          value={values.event_id}
                        >
                          <OptGroup label="นโยบาย">
                            {policyLists.map((policy, i) => (
                              <Option
                                key={'policy_' + policy.id}
                                type="policy"
                                value={policy.id}
                              >
                                {policy.name}
                              </Option>
                            ))}
                          </OptGroup>
                          <OptGroup label="เหตุการณ์">
                            {eventLists.map((event, i) => (
                              <Option
                                key={'event_' + event.id}
                                type="feed"
                                value={event.id}
                              >
                                {event.name}
                              </Option>
                            ))}
                          </OptGroup>
                        </Select>
                      </FormItem>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicleGroup}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.vehicle_type_id &&
                          errors.vehicle_type_id &&
                          'error'
                        }
                        help={touched.vehicle_type_id && errors.vehicle_type_id}
                      >
                        <Select
                          onChange={value =>
                            this.onVehicleTypeChange(value, setFieldValue)
                          }
                          value={values.vehicle_type_id}
                        >
                          <Options value="">
                            {' '}
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Options>
                          {vehicleTypeLists.map(item => (
                            <Options key={item.id} value={item.id}>
                              {item.name}
                            </Options>
                          ))}
                        </Select>
                      </FormItem>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'error'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div id="finding-btn">
                          <Transfer
                            titles={[
                              <LangContext.Consumer>
                                {i18n => i18n.i.itemLeft}
                              </LangContext.Consumer>,
                              <LangContext.Consumer>
                                {i18n => i18n.i.itemSelected}
                              </LangContext.Consumer>,
                            ]}
                            dataSource={this.state.vehicleList}
                            showSearch
                            listStyle={{
                              width: 250,
                              height: 300,
                            }}
                            selectedKeys={this.state.selectedKeys}
                            operations={['>>', '<<']}
                            targetKeys={this.state.vehicleSelect}
                            onSelectChange={this.handleSelectChange}
                            onChange={value =>
                              this.tranferChange(value, setFieldValue)
                            }
                            //onChange={this.tranferChange}
                            render={item => `${item.name}`}
                            footer={this.renderFooter}
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                          />
                        </div>
                      </FormItem>

                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.d.dateRange}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.eventdate && errors.eventdate && 'error'
                        }
                        help={touched.eventdate && errors.eventdate}
                      >
                        <RangePicker
                          disabledDate={disabledDate}
                          showTime={{ format: 'HH:mm' }}
                          onChange={value => setFieldValue('eventdate', value)}
                          format="DD/MM/YYYY HH:mm"
                          placeholder={['Start Time', 'End Time']}
                          defaultValue={[
                            moment('00:00', 'HH:mm'),
                            moment('23:59', 'HH:mm'),
                          ]}
                        />
                      </Form.Item>

                      <FormItem>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Button type="defualt" block>
                              <Link to="/">
                                <LangContext.Consumer>
                                  {i18n => i18n.c.cancel}
                                </LangContext.Consumer>
                              </Link>
                            </Button>
                          </Col>
                          <Col span={12}>
                            <Dropdown
                              overlay={
                                <Menu
                                  onClick={value =>
                                    this.handleMenuClick(
                                      value,
                                      setFieldValue,
                                      handleSubmit,
                                    )
                                  }
                                >
                                  <Menu.Item key="excel">
                                    <Icon type="file-excel" />{' '}
                                    <LangContext.Consumer>
                                      {i18n => i18n.e.excel}
                                    </LangContext.Consumer>
                                  </Menu.Item>
                                  <Menu.Item key="pdf">
                                    <Icon type="file-pdf" />{' '}
                                    <LangContext.Consumer>
                                      {i18n => i18n.p.pdf}
                                    </LangContext.Consumer>
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <Button type="primary" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.r.report}
                                </LangContext.Consumer>{' '}
                                <Icon type="down" />
                              </Button>
                            </Dropdown>
                          </Col>
                        </Row>
                      </FormItem>
                    </Form>
                  </Row>
                )}
              />
            )}
          </LangContext.Consumer>
        </Spin>
      </div>
    )
  }
}

export default Forms
