import { createTypes, createAction } from 'lib/action'
const LOAD_VEHICLE_BLACKLIST = createTypes('loadvehicleblacklist', 'load')
const CREATE_VEHICLE_BLACKLIST = createTypes('createvehicle', 'create')
const LOAD_VEHICLE_BLACKLIST_DETAIL = createTypes(
  'loadvehicleblacklistdetail',
  'load',
)
const EXPORT_VEHICLE_BLACKLIST = createTypes('exportvehicleblacklist', 'load')
const UPDATE_VEHICLE_BLACKLIST = createTypes('updatevehicleblacklist', 'update')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadVehicleBlacklist = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLE_BLACKLIST.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_VEHICLE_BLACKLIST.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_VEHICLE_BLACKLIST.FAITLURE),
}

const createVehicleBlacklist = {
  request: (data, loading) =>
    createAction(CREATE_VEHICLE_BLACKLIST.REQUEST, { data, loading }),
  success: res => createAction(CREATE_VEHICLE_BLACKLIST.SUCCESS, { res }),
  failure: () => createAction(CREATE_VEHICLE_BLACKLIST.FAILURE),
}

const loadVehicleBlacklistDetail = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLE_BLACKLIST_DETAIL.REQUEST, { data, loading }),
  success: lists =>
    createAction(LOAD_VEHICLE_BLACKLIST_DETAIL.SUCCESS, { lists }),
  failure: () => createAction(LOAD_VEHICLE_BLACKLIST_DETAIL.FAILURE),
}

const updateVehicleBlacklist = {
  request: (data, loading) =>
    createAction(UPDATE_VEHICLE_BLACKLIST.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_VEHICLE_BLACKLIST.SUCCESS, { res }),
  failure: () => createAction(UPDATE_VEHICLE_BLACKLIST.FAILURE),
}

const exportIsupplyVehicleBlacklist = {
  request: (data, loading) =>
    createAction(EXPORT_VEHICLE_BLACKLIST.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_VEHICLE_BLACKLIST.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_VEHICLE_BLACKLIST.FAITLURE),
}

export {
  LOAD_VEHICLE_BLACKLIST,
  loadVehicleBlacklist,
  CREATE_VEHICLE_BLACKLIST,
  createVehicleBlacklist,
  LOAD_VEHICLE_BLACKLIST_DETAIL,
  loadVehicleBlacklistDetail,
  UPDATE_VEHICLE_BLACKLIST,
  updateVehicleBlacklist,
  EXPORT_VEHICLE_BLACKLIST,
  exportIsupplyVehicleBlacklist,
  REMOVE_LISTS,
  removeLists,
}
