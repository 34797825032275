import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'

import * as actions from './actions'
import moment from 'moment'

function* loadTripsumbylocation(action) {
  const {
    data: {
      first_dest,
      second_dest,
      eventdate,
      list_vehicle_id,
      type_file,
      company_id,
      user_id,
    },
  } = action.payload

  const date_start = moment(eventdate[0]['_d']).format('YYYY-MM-DD HH:mm')
  const date_end = moment(eventdate[1]['_d']).format('YYYY-MM-DD HH:mm')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/tripsumbylocation`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id,
        user_id,
        type_file,
        first_dest,
        second_dest,
      },
    )

    yield put(actions.loadTripsumbylocation.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadTripsumbylocation.failure())
  }
}

function* loadTripsumbylocationpreview(action) {
  const {
    data: {
      first_dest,
      second_dest,
      date_start,
      date_end,
      list_vehicle_id,
      type_file,
      company_id,
      user_id,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/tripsumbylocation`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id,
        user_id,
        type_file,
        first_dest,
        second_dest,
      },
    )

    yield put(actions.loadTripsumbylocationpreview.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadTripsumbylocationpreview.failure())
  }
}

function* loadLocation(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const location_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'location_visibility',
  )

  const { data } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`,
      {
        company_id: COMPANY_ID,
        location_visibility: location_visibility,
        name: data,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadLocation.success(res.data))
  } catch (err) {
    yield put(actions.loadLocation.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_TRIPSUMBYLOCATION.REQUEST, loadTripsumbylocation),
    takeEvery(
      actions.LOAD_TRIPSUMBYLOCATION_PREVIEW.REQUEST,
      loadTripsumbylocationpreview,
    ),
    takeEvery(actions.LOAD_LOCATION_TRIPSUMBYLOCATION.REQUEST, loadLocation),
  ])
}

export { loadTripsumbylocation, loadLocation, loadTripsumbylocationpreview }
