import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  AutoComplete,
  Icon,
  Select,
} from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { Option, OptGroup } = AutoComplete
export default class Filter extends Component {
  state = {
    platenolists: [],
  }

  changeVehiclePlateno = (value, option, setFieldValue) => {
    //   console.log("value:",value)
    setFieldValue('plate_no', option.props.children)
    setFieldValue('imei', option.props.label)
  }

  selectVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue('plate_no', option.props.children)
    setFieldValue('imei', option.props.label)
  }

  render() {
    const { OnClose, onSubmit, vehicleLists } = this.props
    const platenolists = vehicleLists.map(group => (
      <OptGroup
        key={group.title}
        label={
          <span style={{ color: 'blue' }}>
            <LangContext.Consumer>
              {i18n => i18n.o[group.title]}
            </LangContext.Consumer>
          </span>
        }
      >
        {group.children.map(opt => (
          <Option
            key={opt.id}
            label={opt.imei}
            disabled={group.title === 'offline' ? true : false}
          >
            {opt.plate_no}{' '}
            {group.title === 'online' ? (
              <Icon type="car" theme="twoTone" twoToneColor="#00BF06" />
            ) : (
              <Icon type="car" theme="twoTone" twoToneColor="#c91c1c" />
            )}
          </Option>
        ))}
      </OptGroup>
    ))
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <LangContext.Consumer>
          {i18n => (
            <Formik
              initialValues={{
                plate_no: '',
                eventdate: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('23:59:59', 'HH:mm:ss'),
                ],
                camera_position: '',
              }}
              validate={values => {
                let errors = {}
                if (values.eventdate.length > 0) {
                  const date_start = moment(values.eventdate[0]['_d'])
                  const date_end = moment(values.eventdate[1]['_d'])
                  let day_diff = date_end.diff(date_start, 'days') + 1
                  if (day_diff > 1) {
                    errors.eventdate = i18n.o.over1day
                    //console.log("ddd");
                  }
                }
                //console.log("error:",errors)
                return errors
              }}
              validationSchema={yup.object().shape({
                plate_no: yup
                  .string()
                  .required(i18n.p.pleaseSelectVehicleRegistration),
                eventdate: yup.array().required(i18n.p.pleaseChooseDateRange),
              })}
              onSubmit={values => {
                const loading = true
                onSubmit(values, loading)
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
                resetForm,
              }) => (
                <Row type="flex">
                  <Form onSubmit={handleSubmit}>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.p.plateNoVehicleCode}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.plate_no && errors.plate_no && 'error'
                      }
                      help={touched.plate_no && errors.plate_no}
                    >
                      <AutoComplete
                        dataSource={platenolists}
                        style={{ width: '100%' }}
                        optionLabelProp="children"
                        filterOption={true}
                        optionFilterProp="children"
                        // placeholder="ค้นหาทะเบียน"
                        value={values.plate_no}
                        onChange={(value, option) =>
                          this.changeVehiclePlateno(
                            value,
                            option,
                            setFieldValue,
                          )
                        }
                        onSelect={(value, option) =>
                          this.selectVehiclePlateno(
                            value,
                            option,
                            setFieldValue,
                          )
                        }
                      />
                    </FormItem>

                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.c.camera_position}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.camera_position &&
                        errors.camera_position &&
                        'error'
                      }
                      help={touched.camera_position && errors.camera_position}
                    >
                      <Select
                        //showSearch
                        // style={{ width: 200 }}
                        onChange={value =>
                          setFieldValue('camera_position', value)
                        }
                        value={values.camera_position}
                      >
                        <Option value="">
                          <LangContext.Consumer>
                            {i18n => i18n.a.all}
                          </LangContext.Consumer>
                        </Option>

                        <Option value="front">
                          <LangContext.Consumer>
                            {i18n => i18n.f.front}
                          </LangContext.Consumer>
                        </Option>
                        <Option value="back">
                          <LangContext.Consumer>
                            {i18n => i18n.c.camera_back}
                          </LangContext.Consumer>
                        </Option>

                        <Option value="extra">
                          <LangContext.Consumer>
                            {i18n => i18n.c.camera_extra}
                          </LangContext.Consumer>
                        </Option>
                      </Select>
                    </FormItem>

                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.dateRange}
                        </LangContext.Consumer>
                      }
                      validateStatus={errors.eventdate && 'error'}
                      help={errors.eventdate}
                    >
                      <RangePicker
                        disabledDate={disabledDate}
                        showTime={{ format: 'HH:mm:ss' }}
                        onChange={value => setFieldValue('eventdate', value)}
                        format="DD/MM/YYYY HH:mm:ss"
                        placeholder={['Start Time', 'End Time']}
                        defaultValue={[
                          moment('00:00:00', 'HH:mm:ss'),
                          moment('23:59:59', 'HH:mm:ss'),
                        ]}
                      />
                    </Form.Item>

                    <FormItem>
                      <Row gutter={24}>
                        <Col span={3}>
                          <Button onClick={OnClose} style={{ paddingLeft: 14 }}>
                            <LangContext.Consumer>
                              {i18n => i18n.c.cancel}
                            </LangContext.Consumer>
                          </Button>
                        </Col>
                        <Col span={2} />
                        <Col span={3}>
                          <Button htmlType="submit" type="primary">
                            <LangContext.Consumer>
                              {i18n => i18n.a.apply}
                            </LangContext.Consumer>
                          </Button>
                        </Col>
                      </Row>
                    </FormItem>
                  </Form>
                </Row>
              )}
            />
          )}
        </LangContext.Consumer>
      </div>
    )
  }
}
