import React, { Component } from 'react'
import { Row, Col, Collapse, Card, Icon, Breadcrumb, Divider } from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Index extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        <div className="divider__web__content">
          <Divider />
        </div>
        {/* ทริป */}
        {checkMenuVisibility('', web_endpoint, 'trip', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/trip">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.t.trip}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* แผนการจัดส่ง */}
        {checkMenuVisibility('', web_endpoint, 'routemaster', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/routemaster">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.r.routemaster}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* delivery_trip */}
        {checkMenuVisibility('', web_endpoint, 'delivery_trip', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/deliverytrip">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.deliverytrip}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Index
