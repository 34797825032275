import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
} from './../../../constants/local_storage'

function* loadVehicleBlacklist(action) {
  const {
    data: {
      companyID,
      page,
      pageSize,
      orderBy,
      orderType,
      location_visibility,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get-list/vehicle-blacklist`,
      {
        companyID,
        page,
        pageSize,
        orderBy,
        orderType,
        location_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicleBlacklist.success(res, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadVehicleBlacklist.failure())
  }
}

function* createVehicleBlacklist(action) {
  const {
    data: { location_id, vehicle },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/creact/location-blacklist`,
      {
        location_id,
        vehicle,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    loading(res.data)
    yield put(actions.createVehicleBlacklist.success(res.data))
  } catch (err) {
    yield put(actions.createVehicleBlacklist.failure())
  }
}

function* loadVehicleBlacklistDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get-list/vehicle-blacklist/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadVehicleBlacklistDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadVehicleBlacklistDetail.failure())
  }
}

function* updateVehicleBlacklist(action) {
  const {
    data: { origi_location_id, location_id, vehicle },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/update/vehicle-blacklist`,
      {
        origi_location_id,
        location_id,
        vehicle,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    loading(res.data)
    yield put(actions.updateVehicleBlacklist.success(res.data))
  } catch (err) {
    yield put(actions.updateVehicleBlacklist.failure())
  }
}

function* exportIsupplyVehicleBlacklist(action) {
  const {
    data: {
      companyID,
      page,
      pageSize,
      orderBy,
      orderType,
      location_visibility,
      type,
      file_value_pdf,
      limit_export,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get-list/vehicle-blacklist`,
      {
        companyID,
        page,
        pageSize,
        orderBy,
        orderType,
        location_visibility,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.exportIsupplyVehicleBlacklist.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    yield put(actions.exportIsupplyVehicleBlacklist.failure())
  }
}

export default function* watchVehicleBlacklist() {
  yield all([
    takeEvery(actions.CREATE_VEHICLE_BLACKLIST.REQUEST, createVehicleBlacklist),
    takeEvery(actions.LOAD_VEHICLE_BLACKLIST.REQUEST, loadVehicleBlacklist),
    takeEvery(
      actions.LOAD_VEHICLE_BLACKLIST_DETAIL.REQUEST,
      loadVehicleBlacklistDetail,
    ),
    takeEvery(actions.UPDATE_VEHICLE_BLACKLIST.REQUEST, updateVehicleBlacklist),
    takeEvery(
      actions.EXPORT_VEHICLE_BLACKLIST.REQUEST,
      exportIsupplyVehicleBlacklist,
    ),
  ])
}
export {
  loadVehicleBlacklist,
  createVehicleBlacklist,
  loadVehicleBlacklistDetail,
  updateVehicleBlacklist,
  exportIsupplyVehicleBlacklist,
}
