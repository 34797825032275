import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
} from './../../../constants/local_storage'

function* loadDataPrecast(action) {
  const {
    data: {
      location_visibility,
      is_vendor,
      page,
      pageSize,
      filterObj,
      company,
      relate_company,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-trips-precast`,
      {
        location_visibility,
        company_id: company,
        page,
        pageSize,
        filterObj,
        relate_company,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.loadDataPrecast.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDataPrecast.failure())
  }
}

function* loadDataVehicleForView(action) {
  const {
    data: { id },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/dashboard/get/detail-vehicle/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadDataVehicleForView.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDataVehicleForView.failure())
  }
}

export default function* watchDashBoardPrecast() {
  yield all([
    takeEvery(actions.LOAD_DATA_PRECAST.REQUEST, loadDataPrecast),
    takeEvery(
      actions.LOAD_DATA_VEHICLE_FOR_VIEW.REQUEST,
      loadDataVehicleForView,
    ),
  ])
}

export { loadDataPrecast, loadDataVehicleForView }
