import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Index from './Index'
import Preview from './Components/Previewtable'

export default () => (
  <Switch>
    <Route exact path="/report/summary-fuel-usage" component={Index} />
    <Route
      exact
      path="/report/summary-fuel-usage/preview"
      component={Preview}
    />
  </Switch>
)
