import { createTypes, createAction } from 'lib/action'
const LOAD_DATA_FOR_MANAG_VEHICLE = createTypes(
  'loaddataformanagevehicle',
  'load',
)
const LOAD_DATA_REQUEST_MATERIAL = createTypes('datarequestmaterial', 'load')
const ASSIGN_WORK_FROM_VEHICLE = createTypes('assignworkformvehicle', 'update')
const ASSIGN_WORK_FROM_PLANT = createTypes('assignworkformplant', 'update')
const MATCH_VEHICLE_TO_JOB = createTypes('managvehicle', 'update')
const UNMATCH_VEHICLE_TO_JOB = createTypes('unmanagvehicle', 'update')
const LOADDATA_ASSIGNWORK_VEHICLE_STATUS_FOR_DETAIL = createTypes(
  'loaddataassignworkvehiclestatusfordetail',
  'load',
)
// * เพิ่มรถเข้ากองงาน
const ASSIGN_WORK_SALE_ORDER_FROM_VEHICLE = createTypes(
  'assignworksaleorderformvehicle',
  'update',
)
const ASSIGN_WORK_SALE_ORDER_FROM_PLANT = createTypes(
  'assignworksaleorderformplant',
  'update',
)

const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadDataForManagVehicle = {
  request: (data, loading) =>
    createAction(LOAD_DATA_FOR_MANAG_VEHICLE.REQUEST, { data, loading }),
  success: lists =>
    createAction(LOAD_DATA_FOR_MANAG_VEHICLE.SUCCESS, { lists }),
  failure: () => createAction(LOAD_DATA_FOR_MANAG_VEHICLE.FAITLURE),
}

const loadRequestMaterial = {
  request: (data, loading) =>
    createAction(LOAD_DATA_REQUEST_MATERIAL.REQUEST, { data, loading }),
  success: lists => createAction(LOAD_DATA_REQUEST_MATERIAL.SUCCESS, { lists }),
  failure: () => createAction(LOAD_DATA_REQUEST_MATERIAL.FAITLURE),
}

const assignWorkFormVehicle = {
  request: (data, loading) =>
    createAction(ASSIGN_WORK_FROM_VEHICLE.REQUEST, { data, loading }),
  success: res => createAction(ASSIGN_WORK_FROM_VEHICLE.SUCCESS, { res }),
  failure: () => createAction(ASSIGN_WORK_FROM_VEHICLE.FAILURE),
}
// * เพิ่มรถเข้ากองงาน
const assignWorkSaleOrderFormVehicle = {
  request: (data, loading) =>
    createAction(ASSIGN_WORK_SALE_ORDER_FROM_VEHICLE.REQUEST, {
      data,
      loading,
    }),
  success: res =>
    createAction(ASSIGN_WORK_SALE_ORDER_FROM_VEHICLE.SUCCESS, { res }),
  failure: () => createAction(ASSIGN_WORK_SALE_ORDER_FROM_VEHICLE.FAILURE),
}
// * เพิ่มรถเข้ากองงาน
const assignWorkSaleOrderFormPlant = {
  request: (data, loading) =>
    createAction(ASSIGN_WORK_SALE_ORDER_FROM_PLANT.REQUEST, {
      data,
      loading,
    }),
  success: res =>
    createAction(ASSIGN_WORK_SALE_ORDER_FROM_PLANT.SUCCESS, { res }),
  failure: () => createAction(ASSIGN_WORK_SALE_ORDER_FROM_PLANT.FAILURE),
}

const assignWorkFormPlant = {
  request: (data, loading) =>
    createAction(ASSIGN_WORK_FROM_PLANT.REQUEST, { data, loading }),
  success: res => createAction(ASSIGN_WORK_FROM_PLANT.SUCCESS, { res }),
  failure: () => createAction(ASSIGN_WORK_FROM_PLANT.FAILURE),
}

const matchVehicletoJob = {
  request: (data, loading) =>
    createAction(MATCH_VEHICLE_TO_JOB.REQUEST, { data, loading }),
  success: res => createAction(MATCH_VEHICLE_TO_JOB.SUCCESS, { res }),
  failure: () => createAction(MATCH_VEHICLE_TO_JOB.FAILURE),
}

const unMatchVehicletoJob = {
  request: (data, loading) =>
    createAction(UNMATCH_VEHICLE_TO_JOB.REQUEST, { data, loading }),
  success: res => createAction(UNMATCH_VEHICLE_TO_JOB.SUCCESS, { res }),
  failure: () => createAction(UNMATCH_VEHICLE_TO_JOB.FAILURE),
}

const loadAssignWorkVehicleStatusForDetail = {
  request: (data, loading) =>
    createAction(LOADDATA_ASSIGNWORK_VEHICLE_STATUS_FOR_DETAIL.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(LOADDATA_ASSIGNWORK_VEHICLE_STATUS_FOR_DETAIL.SUCCESS, {
      lists,
    }),
  failure: () =>
    createAction(LOADDATA_ASSIGNWORK_VEHICLE_STATUS_FOR_DETAIL.FAILURE),
}

export {
  LOAD_DATA_FOR_MANAG_VEHICLE,
  loadDataForManagVehicle,
  LOAD_DATA_REQUEST_MATERIAL,
  loadRequestMaterial,
  ASSIGN_WORK_FROM_VEHICLE,
  assignWorkFormVehicle,
  ASSIGN_WORK_FROM_PLANT,
  assignWorkFormPlant,
  MATCH_VEHICLE_TO_JOB,
  matchVehicletoJob,
  UNMATCH_VEHICLE_TO_JOB,
  unMatchVehicletoJob,
  LOADDATA_ASSIGNWORK_VEHICLE_STATUS_FOR_DETAIL,
  loadAssignWorkVehicleStatusForDetail,
  ASSIGN_WORK_SALE_ORDER_FROM_VEHICLE,
  assignWorkSaleOrderFormVehicle,
  ASSIGN_WORK_SALE_ORDER_FROM_PLANT,
  assignWorkSaleOrderFormPlant,
  REMOVE_LISTS,
  removeLists,
}
