import {
  CREATE_VEHICLESHARING,
  LOAD_VEHICLESHARING_TO,
  LOAD_VEHICLESHARING_FROM,
  REMOVE_LISTS,
  LOAD_VEHICLESHARING_TO_DETAIL,
  EXPORT_VEHICLESHARING_FROM,
  EXPORT_VEHICLESHARING_TO,
} from './actions'

const initialState = {
  sharedToLists: [],
  sharedFromLists: [],
  sharedToTotal: 1,
  sharedFromTotal: 1,
  loading: false,
  detailLoading: false,
  draw: -1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState
    case LOAD_VEHICLESHARING_TO.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
      }
    case LOAD_VEHICLESHARING_TO.SUCCESS:
      const { page, pageSize } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * pageSize
      }
      return {
        ...state,
        sharedToLists: action.payload.lists.data,
        sharedToTotal:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }
    case LOAD_VEHICLESHARING_FROM.REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case LOAD_VEHICLESHARING_FROM.SUCCESS:
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (action.payload.page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber +
            (action.payload.page - 1) * action.payload.pageSize
      }

      return {
        ...state,
        sharedFromLists: action.payload.lists.data,
        sharedFromTotal:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }
    case LOAD_VEHICLESHARING_TO_DETAIL.REQUEST:
      return {
        ...state,
        detailLoading: true,
      }
    case LOAD_VEHICLESHARING_TO_DETAIL.SUCCESS:
      return {
        ...state,
        selectedPermission: action.payload.vehiclesharing.data,
        detailLoading: false,
      }

    case CREATE_VEHICLESHARING.REQUEST:
      return {
        ...state,
        detailLoading: true,
      }

    case CREATE_VEHICLESHARING.SUCCESS:
      const { res } = action.payload
      return {
        ...state,
        selectedPermission: {
          ...state.selectedPermission,
          permission_id: res.id,
        },
        permission_id: res.id,
        detailLoading: false,
      }

    case EXPORT_VEHICLESHARING_TO.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case EXPORT_VEHICLESHARING_TO.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case EXPORT_VEHICLESHARING_FROM.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case EXPORT_VEHICLESHARING_FROM.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
