import { createTypes, createAction } from "lib/action";
const LOAD_POLICY = createTypes("loadpolicy", "load");
const LOAD_POLICY_TYPE = createTypes("loadpolicytype", "load");
const LOAD_VEHICLE = createTypes(
  "loadvehicle",
  "load"
);
const CREATE_POLICY = createTypes("createpolicy", "create");
const UPDATE_POLICY = createTypes("updatepolicy", "update")
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
      type: REMOVE_LISTS
    };
}

const loadPolicy = {
    request: (data, loading) =>
      createAction(LOAD_POLICY.REQUEST, { data, loading}),
    success: (Policylists, page) =>
      createAction(LOAD_POLICY.SUCCESS, {Policylists, page}),
    failure: () => createAction(LOAD_POLICY.FAITLURE)  
};

const loadPolicyType = {
  request: (data, loading) =>
    createAction(LOAD_POLICY_TYPE.REQUEST, { data, loading}),
  success: (PolicyTypelists) =>
    createAction(LOAD_POLICY_TYPE.SUCCESS, {PolicyTypelists}),
  failure: () => createAction(LOAD_POLICY_TYPE.FAITLURE)  
};

const loadVehicle = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLE.REQUEST, {data, loading}),
  success: (Vehiclelists) =>
    createAction(LOAD_VEHICLE.SUCCESS, { Vehiclelists }),
  failure: () => createAction(LOAD_VEHICLE.FAILURE)
};


const createPolicy = {
  request: (data, loading) =>
      createAction(CREATE_POLICY.REQUEST, { data, loading}),
  success: res => createAction(CREATE_POLICY.SUCCESS, { res }),
      failure: () => createAction(CREATE_POLICY.FAILURE)
};

const updatePolicy = {
  request: (data, loading) =>
      createAction(UPDATE_POLICY.REQUEST, { data, loading}),
  success: res => createAction(UPDATE_POLICY.SUCCESS, { res }),
      failure: () => createAction(UPDATE_POLICY.FAILURE)
};

export {
    LOAD_POLICY, loadPolicy,
    LOAD_POLICY_TYPE, loadPolicyType,
    LOAD_VEHICLE,loadVehicle,
    CREATE_POLICY,createPolicy,
    UPDATE_POLICY,updatePolicy,
    REMOVE_LISTS,removeLists
  };