export const appendScript = (
  tag,
  scriptToAppend,
  self,
  cb = null,
  stateName = "loadScriptSuccess"
) => {
  const script = document.createElement(tag);
  script.src = scriptToAppend;
  //script.async = true;
  script.onload = () => {
    if (self !== null) {
      self.setState({ [stateName]: true });
    }
    if (cb !== null) {
      cb();
    }
  };
  document.body.appendChild(script);
};

export const removeScript = (scriptToRemove) => {
  let allsuspects = document.getElementsByTagName("script");
  for (let i = allsuspects.length; i >= 0; i--) {
    let rx = new RegExp(scriptToRemove, "g");
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute("src") !== null &&
      allsuspects[i].getAttribute("src").match(rx)
    ) {
      allsuspects[i].parentNode.removeChild(allsuspects[i]);
    }
  }
};
