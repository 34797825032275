import { createTypes, createAction } from 'lib/action'

const LOAD_TRIPSUMBYLOCATION = createTypes('tripsumbylocation', 'load')
const LOAD_TRIPSUMBYLOCATION_PREVIEW = createTypes(
  'tripsumbylocationpreview',
  'load',
)
const LOAD_LOCATION_TRIPSUMBYLOCATION = createTypes(
  'location_tripsumbylocation',
  'load',
)

const loadTripsumbylocation = {
  request: (data, loading) =>
    createAction(LOAD_TRIPSUMBYLOCATION.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRIPSUMBYLOCATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRIPSUMBYLOCATION.FAILURE),
}

const loadTripsumbylocationpreview = {
  request: (data, loading) =>
    createAction(LOAD_TRIPSUMBYLOCATION_PREVIEW.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_TRIPSUMBYLOCATION_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRIPSUMBYLOCATION_PREVIEW.FAILURE),
}

const loadLocation = {
  request: data =>
    createAction(LOAD_LOCATION_TRIPSUMBYLOCATION.REQUEST, { data }),
  success: data =>
    createAction(LOAD_LOCATION_TRIPSUMBYLOCATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_LOCATION_TRIPSUMBYLOCATION.FAILURE),
}

export {
  loadTripsumbylocation,
  LOAD_TRIPSUMBYLOCATION,
  loadTripsumbylocationpreview,
  LOAD_TRIPSUMBYLOCATION_PREVIEW,
  loadLocation,
  LOAD_LOCATION_TRIPSUMBYLOCATION,
}
