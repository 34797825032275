import {
  CREATE_TRIP,
  UPDATE_TRIP,
  LOAD_TRIP,
  REMOVE_LISTS,
  LOAD_TRIP_DETAIL,
  IMPORT_TRIP,
} from './actions'
const msg = {
  success_msg: '',
  err_msg: '',
}
const initialState = {
  lists: [],
  total: 1,
  loading: false,
  importloading: false,
  shouldReload: false,
  detailLoading: false,
  draw: -1,
  selectedTrip: {
    page_action: null,
    trip_id: null,
    trip_code: '',
    vehicle_id: '',
    vehicle_id_text: '',
    driver_id: '',
    driver_id_text: '',
    plant_id: '',
    plant_id_text: '',
    site_id: '',
    site_id_text: '',
    start_deliver_at: null,
    trip_type: '3',
    load_start_at: null,
    load_amount: '',
    do_list: [],
  },
  ...msg,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState
    case LOAD_TRIP.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
      }
    case LOAD_TRIP.SUCCESS:
      const { page } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }
    case LOAD_TRIP_DETAIL.REQUEST:
      return {
        ...state,
        detailLoading: true,
      }
    case LOAD_TRIP_DETAIL.SUCCESS:
      return {
        ...state,
        selectedTrip: action.payload.trip.data,
        detailLoading: false,
      }
    case CREATE_TRIP.SUCCESS:
      const { res } = action.payload
      return {
        ...state,
        selectedTrip: { ...res.values.data },
        trip_id: res.trip_id,
        detailLoading: false,
      }
    case UPDATE_TRIP.REQUEST:
      return {
        ...state,
        detailLoading: true,
      }
    case UPDATE_TRIP.SUCCESS:
      return {
        ...state,
        detailLoading: false,
      }
    case IMPORT_TRIP.REQUEST:
      return {
        ...state,
        ...msg,
        importloading: true,
        shouldReload: false,
      }
    case IMPORT_TRIP.SUCCESS:
      return {
        ...state,
        success_msg: 'import_success',
        importloading: false,
        shouldReload: true,
      }
    case IMPORT_TRIP.FAILURE:
      return {
        ...state,
        err_msg: action.payload.err,
        importloading: false,
        shouldReload: false,
      }
    default:
      return state
  }
}
