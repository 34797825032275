import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Row, Col, Form, Drawer, Input, Button } from 'antd'
import { Link, withRouter } from 'react-router-dom'

export class FilterDrawer extends Component {
  render() {
    return (
      <Drawer
        title={
          <LangContext.Consumer>{i18n => i18n.f.filter}</LangContext.Consumer>
        }
        width={520}
        placement="right"
        onClose={e => this.props.onClose('showFilterDrawer')}
        maskClosable={true}
        visible={this.props.showFilterDrawer}
        width={380}
      >
        <Form>
          <Form.Item
            label={
              <LangContext.Consumer>
                {i18n => i18n.t.truck_id}
              </LangContext.Consumer>
            }
          >
            <Input onChange={this.props.handdleVehicleCodeChange} />
          </Form.Item>

          <Form.Item
            label={
              <LangContext.Consumer>
                {i18n => i18n.p.plateNo}
              </LangContext.Consumer>
            }
          >
            <Input onChange={this.props.handdlePlateNoChange} />
          </Form.Item>
          <br />
          <br />
          <Button
            style={{
              marginRight: 8,
            }}
            onClick={this.props.onClose}
          >
            <LangContext.Consumer>{i18n => i18n.c.cancel}</LangContext.Consumer>
          </Button>

          <Button type="primary" onClick={this.props.applySearchButtonClick}>
            <LangContext.Consumer>{i18n => i18n.a.apply}</LangContext.Consumer>
          </Button>
        </Form>
      </Drawer>
    )
  }
}

export default withRouter(
  connect(
    ({ monitoringState, auth, auth: { profile } }) => ({
      monitoringState,
      auth,
      profile,
    }),
    {},
  )(FilterDrawer),
)
