import { createTypes, createAction } from 'lib/action'
const LOAD_MASTERDATA_VEHICLE_TYPE = createTypes(
  'masterdatavehicletype',
  'load',
)
const LOAD_MASTERDATA_VEHICLE_TYPE_DETAIL = createTypes(
  'masterdatavehicletypedetail',
  'load',
)

const UPDATE_MASTERDATA_VEHICLE_TYPE_DETAIL = createTypes(
  'updatemasterdatavehicletype',
  'update',
)

const EXPORT_MASTERDATA_VEHICLE_TYPE = createTypes(
  'exportmasterdatavehicletype',
  'load',
)
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadMasterVehicleType = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_VEHICLE_TYPE.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_MASTERDATA_VEHICLE_TYPE.SUCCESS, {
      lists,
      page,
      pageSize,
    }),
  failure: () => createAction(LOAD_MASTERDATA_VEHICLE_TYPE.FAITLURE),
}

const loadMasterVehicleTypeDetail = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_VEHICLE_TYPE_DETAIL.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(LOAD_MASTERDATA_VEHICLE_TYPE_DETAIL.SUCCESS, { lists }),
  failure: () => createAction(LOAD_MASTERDATA_VEHICLE_TYPE_DETAIL.FAITLURE),
}

const updateMasterVehicleType = {
  request: (data, loading) =>
    createAction(UPDATE_MASTERDATA_VEHICLE_TYPE_DETAIL.REQUEST, {
      data,
      loading,
    }),
  success: res =>
    createAction(UPDATE_MASTERDATA_VEHICLE_TYPE_DETAIL.SUCCESS, { res }),
  failure: () => createAction(UPDATE_MASTERDATA_VEHICLE_TYPE_DETAIL.FAITLURE),
}

const exportMasterVehicleType = {
  request: (data, loading) =>
    createAction(EXPORT_MASTERDATA_VEHICLE_TYPE.REQUEST, {
      data,
      loading,
    }),
  success: res => createAction(EXPORT_MASTERDATA_VEHICLE_TYPE.SUCCESS, { res }),
  failure: () => createAction(EXPORT_MASTERDATA_VEHICLE_TYPE.FAITLURE),
}

export {
  LOAD_MASTERDATA_VEHICLE_TYPE,
  loadMasterVehicleType,
  LOAD_MASTERDATA_VEHICLE_TYPE_DETAIL,
  loadMasterVehicleTypeDetail,
  UPDATE_MASTERDATA_VEHICLE_TYPE_DETAIL,
  updateMasterVehicleType,
  EXPORT_MASTERDATA_VEHICLE_TYPE,
  exportMasterVehicleType,
  REMOVE_LISTS,
  removeLists,
}
