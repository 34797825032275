import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
import * as actions from './actions'

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )
  const { gps_vendor_id } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclegpsvendor`,
      {
        companyID: COMPANY_ID,
        vendor_id: gps_vendor_id,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadGpsVendor(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgps-vendor`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadGpsVendor.success(res.data))
  } catch (err) {
    yield put(actions.loadGpsVendor.failure())
  }
}

function* exportGPSLostConTracteport(action) {
  const {
    data: {
      list_vehicle_id,
      company_id,
      vehicle_visibility,
      accesstoken,
      type_file,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/gps-lostcontract`,
      {
        vehicle_list: list_vehicle_id,
        company_id: company_id,
        vehicle_visibility: vehicle_visibility,
        type_file,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.exportGPSLostConTracteport.success(res.data))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportGPSLostConTracteport.failure())
  }
}

export default function* watchGPSlostcontact() {
  yield all([
    takeEvery(
      actions.LOAD_VEHICLE_GPS_LOST_CONTACT_REPORT.REQUEST,
      loadVehicle,
    ),
    takeEvery(
      actions.LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.REQUEST,
      loadGpsVendor,
    ),
    takeEvery(
      actions.EXPORT_GPS_LOST_CONTACT_REPORT.REQUEST,
      exportGPSLostConTracteport,
    ),
  ])
}
export { loadVehicle, loadGpsVendor, exportGPSLostConTracteport }
