import { createTypes, createAction } from 'lib/action'
const LOAD_PLANT_MINING_CONTRACT = createTypes(
  'loadplantminingcontract',
  'load',
)
const CREATE_PLANT_MINING_CONTRACT = createTypes(
  'createplantminingcontract',
  'create',
)
const LOAD_PLANT_MINING_CONTRACT_DETAIL = createTypes(
  'plantminingcontractdetail',
  'load',
)
const UPDATE_PLANT_MINING_CONTRACT = createTypes(
  'updateplantminingcontract',
  'update',
)
const UPLOAD_PLANT_MINING_CONTRACT = createTypes(
  'uploadplantminingcontract',
  'upload',
)

const EXPORT_PLANT_MINING_CONTRACT = createTypes(
  'exportplantminingcontract',
  'load',
)

const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadPlantMiningContract = {
  request: (data, loading) =>
    createAction(LOAD_PLANT_MINING_CONTRACT.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_PLANT_MINING_CONTRACT.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_PLANT_MINING_CONTRACT.FAITLURE),
}

const createPlantMiningContract = {
  request: (data, loading) =>
    createAction(CREATE_PLANT_MINING_CONTRACT.REQUEST, { data, loading }),
  success: res => createAction(CREATE_PLANT_MINING_CONTRACT.SUCCESS, { res }),
  failure: () => createAction(CREATE_PLANT_MINING_CONTRACT.FAITLURE),
}

const loadPlantMiningContractDetail = {
  request: (data, loading) =>
    createAction(LOAD_PLANT_MINING_CONTRACT_DETAIL.REQUEST, { data, loading }),
  success: listsEdit =>
    createAction(LOAD_PLANT_MINING_CONTRACT_DETAIL.SUCCESS, { listsEdit }),
  failure: () => createAction(LOAD_PLANT_MINING_CONTRACT_DETAIL.FAITLURE),
}

const updatePlantMiningContractDetail = {
  request: (data, loading) =>
    createAction(UPDATE_PLANT_MINING_CONTRACT.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_PLANT_MINING_CONTRACT.SUCCESS, { res }),
  failure: () => createAction(UPDATE_PLANT_MINING_CONTRACT.FAITLURE),
}

const uploadPlantMiningContract = {
  request: (data, loading) =>
    createAction(UPLOAD_PLANT_MINING_CONTRACT.REQUEST, { data, loading }),
  success: res => createAction(UPLOAD_PLANT_MINING_CONTRACT.SUCCESS, { res }),
  failure: () => createAction(UPLOAD_PLANT_MINING_CONTRACT.FAITLURE),
}

const exportPlantMiningContract = {
  request: (data, loading) =>
    createAction(EXPORT_PLANT_MINING_CONTRACT.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_PLANT_MINING_CONTRACT.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_PLANT_MINING_CONTRACT.FAITLURE),
}

export {
  LOAD_PLANT_MINING_CONTRACT,
  loadPlantMiningContract,
  CREATE_PLANT_MINING_CONTRACT,
  createPlantMiningContract,
  LOAD_PLANT_MINING_CONTRACT_DETAIL,
  loadPlantMiningContractDetail,
  UPDATE_PLANT_MINING_CONTRACT,
  updatePlantMiningContractDetail,
  UPLOAD_PLANT_MINING_CONTRACT,
  uploadPlantMiningContract,
  EXPORT_PLANT_MINING_CONTRACT,
  exportPlantMiningContract,
  REMOVE_LISTS,
  removeLists,
}
