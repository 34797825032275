import {
  LOAD_ALARMALERTBYDURATIONTIME,
  LOAD_VEHICLE_ALARMALERTBYDURATIONTIME,
  LOAD_VEHICLE_TYPE_ALARMALERTBYDURATIONTIME,
} from './actions'

const initialState = {
  alarmalertbydurationtimeUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  alarmalertbydurationtimeLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALARMALERTBYDURATIONTIME.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        alarmalertbydurationtimeLoading: loading,
      }
    case LOAD_ALARMALERTBYDURATIONTIME.SUCCESS:
      return {
        ...state,
        alarmalertbydurationtimeUrl: action.payload.data.data,
        alarmalertbydurationtimeLoading: false,
      }
    case LOAD_VEHICLE_ALARMALERTBYDURATIONTIME.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        alarmalertbydurationtimeLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_ALARMALERTBYDURATIONTIME.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        alarmalertbydurationtimeLoading: false,
        //  loading: false
      }
    case LOAD_VEHICLE_TYPE_ALARMALERTBYDURATIONTIME.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
