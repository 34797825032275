import { createTypes, createAction } from "lib/action";
const LOAD_REQUEST_DELIVERY_REPORT = createTypes("isupplydeliveryreport", "load");
const EXPORT_REPORT = createTypes("exportreport", "load");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadRequestDeliveryReport = {
    request: (data, loading) =>
        createAction(LOAD_REQUEST_DELIVERY_REPORT.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_REQUEST_DELIVERY_REPORT.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_REQUEST_DELIVERY_REPORT.FAITLURE)  
};

const exportReport = {
    request: (data, loading) =>
        createAction(EXPORT_REPORT.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(EXPORT_REPORT.SUCCESS, {lists}),
    failure: () => createAction(EXPORT_REPORT.FAITLURE)  
};

export {
    LOAD_REQUEST_DELIVERY_REPORT, loadRequestDeliveryReport,
    EXPORT_REPORT, exportReport,
    REMOVE_LISTS, removeLists
};