import React, { Component, Fragment } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Index extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        <div className="divider__web__content">
          <Divider />
        </div>
        {/* การเติมน้ำมัน */}
        {checkMenuVisibility('', web_endpoint, 'RefuelReport', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/refuel">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.r.RefuelReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* น้ำมันคงเหลือ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'fuelremainrateReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/fuelremainrate">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.f.fuelremainrateReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลน้ำมันคงเหลือตามที่ GPS
                          คำนวณได้ในแต่ละวัน
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* FueluseReport */}
        {checkMenuVisibility('', web_endpoint, 'FueluseReport', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/fueluse">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.f.FueluseReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลปริมาณการใช้น้ำมันของพาหนะ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.f.FueluseReport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Index
