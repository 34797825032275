import { createTypes, createAction } from "lib/action";
const LOAD_SERVICEINSTALLDEVICE = createTypes("loadserviceinstall", "load");
const LOAD_PROVINCE = createTypes("loadprovince", "load");
const LOAD_AMPHUR = createTypes("loadamphur", "load");
const CREATE = createTypes("create", "create");
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadServiceInstallDevice = {
    request: (data, loading) =>
        createAction(LOAD_SERVICEINSTALLDEVICE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_SERVICEINSTALLDEVICE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_SERVICEINSTALLDEVICE.FAITLURE)  
};

const loadProvince = {
    request: (data, loading) =>
        createAction(LOAD_PROVINCE.REQUEST, { data, loading}),
    success: (ProvinceLists) =>
        createAction(LOAD_PROVINCE.SUCCESS, {ProvinceLists}),
    failure: () => createAction(LOAD_PROVINCE.FAITLURE) 
};

const loadAmphur = {
    request: (data, loading) =>
        createAction(LOAD_AMPHUR.REQUEST, { data, loading}),
    success: (AmphurLists) =>
        createAction(LOAD_AMPHUR.SUCCESS, {AmphurLists}),
    failure: () => createAction(LOAD_AMPHUR.FAITLURE) 
};

const create = {
    request: (data, loading) =>
        createAction(CREATE.REQUEST, { data, loading}),
    success: res => createAction(CREATE.SUCCESS, { res }),
        failure: () => createAction(CREATE.FAILURE)
};

export {
    LOAD_SERVICEINSTALLDEVICE, loadServiceInstallDevice,
    LOAD_PROVINCE, loadProvince,
    LOAD_AMPHUR, loadAmphur,
    CREATE, create,
    REMOVE_LISTS, removeLists
};