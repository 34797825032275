import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadMasterUser(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      is_super,
      type,
      file_value_pdf,
      limit_export,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataUser`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        is_super,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadMasterUser.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterUser.failure())
  }
}

function* exportMasterUser(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      is_super,
      type,
      file_value_pdf,
      limit_export,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataUser`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        is_super,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.exportMasterUser.success(res.data, page, pageSize))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportMasterUser.failure())
  }
}

function* createMasterUser(action) {
  const {
    data: {
      company_id,
      title,
      code,
      firstname,
      lastname,
      password,
      username,
      email,
      phone,
      is_admin,
      is_vendor,
      active,
      start_at,
      expired_at,
      created_by,
      permissions,
      matching_name,
      action_name,
      image,
      accessToken,
      dataVisibilityNode,
    },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (image != '') {
    image.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('company_id', company_id)
  formData.append('title', title)
  formData.append('code', code)
  formData.append('firstname', firstname)
  formData.append('lastname', lastname)
  formData.append('password', password)
  formData.append('username', username)
  formData.append('email', email)
  formData.append('phone', phone)
  formData.append('is_admin', is_admin)
  formData.append('is_vendor', is_vendor)
  formData.append('active', active)
  formData.append('start_at', start_at)
  formData.append('expired_at', expired_at)
  formData.append('created_by', created_by)
  formData.append('permissions', permissions)
  formData.append('matching_name', matching_name)
  formData.append('action_name', action_name)
  formData.append('accessToken', accessToken)
  formData.append('dataVisibilityNode', dataVisibilityNode)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataUser/add`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createMasterUser.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createMasterUser.failure())
  }
}

function* loadDetailMasterUser(action) {
  const {
    data: { id, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/user/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadDetailMasterUser.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDetailMasterUser.failure())
  }
}

function* loadDetailMasterDataForEdit(action) {
  const {
    data: { id, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatauser/detail/edit/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadDetailMasterDataForEdit.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDetailMasterDataForEdit.failure())
  }
}

function* updateMasterDataUser(action) {
  const {
    data: {
      id_m_user,
      company_id,
      code,
      title,
      firstname,
      lastname,
      username,
      password,
      email,
      is_admin,
      is_vendor,
      active,
      start_at,
      expired_at,
      created_by,
      permissions,
      phone,
      dataVisibility,
      matching_name,
      image,
      check_image,
      base64,
      isupply_profile_id,
      action_name,
      accessToken,
    },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (image != '') {
    image.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('id_m_user', id_m_user)
  formData.append('files', uploadFile)
  formData.append('company_id', company_id)
  formData.append('title', title)
  formData.append('code', code)
  formData.append('firstname', firstname)
  formData.append('lastname', lastname)
  formData.append('username', username)
  formData.append('password', password)
  formData.append('email', email)
  formData.append('phone', phone)
  formData.append('is_admin', is_admin)
  formData.append('is_vendor', is_vendor)
  formData.append('active', active)
  formData.append('start_at', start_at)
  formData.append('expired_at', expired_at)
  formData.append('created_by', created_by)
  formData.append('permissions', permissions)
  formData.append('dataVisibility', dataVisibility)
  formData.append('matching_name', matching_name)
  formData.append('action_name', action_name)
  formData.append('isupply_profile_id', isupply_profile_id)
  formData.append('check_image', check_image)
  formData.append('base64', base64)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatauser/edit`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateMasterDataUser.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateMasterDataUser.failure())
  }
}

function* importUser(action) {
  const {
    data: {
      files,
      typeImport,
      company_id,
      active,
      is_admin,
      is_vendor,
      start_at,
      expired_at,
      user_group,
      datavisibility,
      created_by,
      updated_by,
      accessToken,
    },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('typeImport', typeImport)
  formData.append('company_id', company_id)
  formData.append('active', active)
  formData.append('is_admin', is_admin)
  formData.append('is_vendor', is_vendor)
  formData.append('start_at', start_at)
  formData.append('expired_at', expired_at)
  formData.append('user_group', user_group)
  formData.append('datavisibility', datavisibility)
  formData.append('created_by', created_by)
  formData.append('updated_by', updated_by)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatauser/import`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    loading(res.data)
    yield put(actions.importUser.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.importUser.failure())
  }
}

export default function* watchUserMasterData() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_USER.REQUEST, loadMasterUser),
    takeEvery(actions.CREATE_MASTERDATA_USER.REQUEST, createMasterUser),
    takeEvery(actions.LOAD_DETAILMASTERDATA_USER.REQUEST, loadDetailMasterUser),
    takeEvery(
      actions.LOAD_DETAILMASTERDATA_FOR_EDIT.REQUEST,
      loadDetailMasterDataForEdit,
    ),
    takeEvery(actions.UPDATE_MASTERDATA_USER.REQUEST, updateMasterDataUser),
    takeEvery(actions.EXPORT_MASTERDATA_USER.REQUEST, exportMasterUser),
    takeEvery(actions.IMPORT_USER.REQUEST, importUser),
  ])
}

export {
  loadMasterUser,
  createMasterUser,
  loadDetailMasterUser,
  loadDetailMasterDataForEdit,
  updateMasterDataUser,
  exportMasterUser,
  importUser,
}
