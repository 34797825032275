import { createTypes, createAction } from 'lib/action'

const LOAD_LOCATIONSHARING_TO = createTypes(
  'locationsharingtomasterdata',
  'load',
)
const LOAD_LOCATIONSHARING_FROM = createTypes(
  'locationsharingfrommasterdata',
  'load',
)
const CREATE_LOCATIONSHARING = createTypes('createsharelocation', 'create')
const EXPORT_LOCATIONSHARING_TO = createTypes(
  'exportlocationsharingtomasterdata',
  'load',
)
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadLocationSharingTo = {
  request: (data, loading) =>
    createAction(LOAD_LOCATIONSHARING_TO.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_LOCATIONSHARING_TO.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_LOCATIONSHARING_TO.FAILURE),
}

const loadLocationSharingFrom = {
  request: (data, loading) =>
    createAction(LOAD_LOCATIONSHARING_FROM.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_LOCATIONSHARING_FROM.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_LOCATIONSHARING_FROM.FAILURE),
}

const createShareLocation = {
  request: (data, loading) =>
    createAction(CREATE_LOCATIONSHARING.REQUEST, { data, loading }),
  success: res => createAction(CREATE_LOCATIONSHARING.SUCCESS, { res }),
  failure: () => createAction(CREATE_LOCATIONSHARING.FAILURE),
}

const exportLocationSharingTo = {
  request: (data, loading) =>
    createAction(EXPORT_LOCATIONSHARING_TO.REQUEST, { data, loading }),
  success: res => createAction(EXPORT_LOCATIONSHARING_TO.SUCCESS, { res }),
  failure: () => createAction(EXPORT_LOCATIONSHARING_TO.FAILURE),
}

export {
  LOAD_LOCATIONSHARING_TO,
  loadLocationSharingTo,
  LOAD_LOCATIONSHARING_FROM,
  loadLocationSharingFrom,
  CREATE_LOCATIONSHARING,
  createShareLocation,
  EXPORT_LOCATIONSHARING_TO,
  exportLocationSharingTo,
  REMOVE_LISTS,
  removeLists,
}
