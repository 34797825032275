import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import OauthLogin from 'modules/oauth/components/OauthLogin'

class PrivateRoute extends Component {
  checkRout = () => {
    const { component, path, done, props, ...rest } = this.props

    if (done == true) {
      return <Route component={component} path={path} {...rest} {...props} />
    } else {
      return <Route exact path="/" component={OauthLogin} />
    }
  }

  render() {
    return this.checkRout()
  }
}

export default PrivateRoute
