import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Transfer,
  Checkbox,
  Dropdown,
  Menu,
  Icon,
} from 'antd'

import LangContext, { i18n } from 'modules/shared/context/langContext'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
  }

  componentDidMount() {
    const { loadVehicle, loadVehicleType } = this.props
    loadVehicle('', false)
    loadVehicleType()
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehicle } = this.props
    setFieldValue('vehicle_type_id', value)
    loadVehicle(value, true)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleLists } = nextProps
    const vehlist = []
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    if (e.key == 'excel') {
      setFieldValue('type_file', 'excel', false)
    } else if (e.key == 'pdf') {
      setFieldValue('type_file', 'pdf', false)
    }
    handleSubmit()
  }

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      dozeoffriskLoading,
      onSubmit,
    } = this.props
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <Spin spinning={dozeoffriskLoading}>
          <LangContext.Consumer>
            {i18n => (
              <Formik
                initialValues={{
                  vehicle_type_id: '',
                  eventdate: [],
                  list_vehicle_id: [],
                }}
                validate={values => {
                  let errors = {}
                  if (values.eventdate.length > 0) {
                    const date_start = moment(values.eventdate[0]['_d'])
                    const date_end = moment(values.eventdate[1]['_d'])
                    let day_diff = date_end.diff(date_start, 'days') + 1

                    if (day_diff > 3) {
                      errors.eventdate = `${i18n.d.dateRangeNotBeMoreThan3Days}`
                    }
                  }

                  return errors
                }}
                validationSchema={yup.object().shape({
                  eventdate: yup
                    .array()
                    .required(`${i18n.p.pleaseChooseDateRange}`),
                  list_vehicle_id: yup
                    .array()
                    .required(`${i18n.p.pleaseChooseVehicle}`),
                })}
                onSubmit={values => {
                  //console.log(values);
                  const loading = true
                  onSubmit(values, loading)
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                }) => (
                  <Row type="flex" justify="center">
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        {' '}
                        <LangContext.Consumer>
                          {i18n => i18n.d.DozeoffriskReport}
                        </LangContext.Consumer>
                      </div>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicleGroup}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.vehicle_type_id &&
                          errors.vehicle_type_id &&
                          'error'
                        }
                        help={touched.vehicle_type_id && errors.vehicle_type_id}
                      >
                        <Select
                          onChange={value =>
                            this.onVehicleTypeChange(value, setFieldValue)
                          }
                          value={values.vehicle_type_id}
                        >
                          <Option value="">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          {vehicleTypeLists.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'error'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div id="finding-btn">
                          <Transfer
                            titles={[
                              <LangContext.Consumer>
                                {i18n => i18n.i.itemLeft}
                              </LangContext.Consumer>,
                              <LangContext.Consumer>
                                {i18n => i18n.i.itemSelected}
                              </LangContext.Consumer>,
                            ]}
                            dataSource={this.state.vehicleList}
                            showSearch
                            listStyle={{
                              width: 250,
                              height: 300,
                            }}
                            selectedKeys={this.state.selectedKeys}
                            operations={['>>', '<<']}
                            targetKeys={this.state.vehicleSelect}
                            onSelectChange={this.handleSelectChange}
                            onChange={value =>
                              this.tranferChange(value, setFieldValue)
                            }
                            //onChange={this.tranferChange}
                            render={item => `${item.name}`}
                            footer={this.renderFooter}
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                          />
                        </div>
                      </FormItem>

                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.d.dateRange}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.eventdate && errors.eventdate && 'error'
                        }
                        help={touched.eventdate && errors.eventdate}
                      >
                        <RangePicker
                          disabledDate={disabledDate}
                          onChange={value => setFieldValue('eventdate', value)}
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>

                      <FormItem>
                        <Row gutter={24}>
                          <Col span={8}>
                            <Button type="defualt" block>
                              <Link to="/">
                                <LangContext.Consumer>
                                  {i18n => i18n.c.cancel}
                                </LangContext.Consumer>
                              </Link>
                            </Button>
                          </Col>
                          <Col span={8}>
                            <Dropdown
                              overlay={
                                <Menu
                                  onClick={value =>
                                    this.handleMenuClick(
                                      value,
                                      setFieldValue,
                                      handleSubmit,
                                    )
                                  }
                                >
                                  <Menu.Item key="excel">
                                    <Icon type="file-excel" />{' '}
                                    <LangContext.Consumer>
                                      {i18n => i18n.e.excel}
                                    </LangContext.Consumer>
                                  </Menu.Item>
                                  <Menu.Item key="pdf">
                                    <Icon type="file-pdf" />{' '}
                                    <LangContext.Consumer>
                                      {i18n => i18n.p.pdf}
                                    </LangContext.Consumer>
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <Button type="primary" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.r.report}
                                </LangContext.Consumer>{' '}
                                <Icon type="down" />
                              </Button>
                            </Dropdown>
                          </Col>
                        </Row>
                      </FormItem>
                    </Form>
                  </Row>
                )}
              />
            )}
          </LangContext.Consumer>
        </Spin>
      </div>
    )
  }
}
