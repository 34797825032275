import React, { Component } from "react";
import { List, Modal, Row, Col } from "antd";

import LangContext from "modules/shared/context/langContext";

export class IconDescModal extends Component {
    state = {
        data: [
            {
                desc: (
                    <Row style={{ marginTop: "-25px" }}>
                        <Col span={5}>
                            <h4>GPS</h4>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.gpsPerformanceDesGPS}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
            {
                desc: (
                    <Row style={{}}>
                        <Col span={5}>
                            <h4>Card Reader</h4>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.gpsPerformanceDesCard}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
            {
                desc: (
                    <Row style={{}}>
                        <Col span={5}>
                            <div
                                style={{
                                    width: "23px",
                                    height: "23px",
                                    backgroundColor: "#00D090",
                                    borderRadius: "6px",
                                }}
                            ></div>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.gpsPerformanceDesGreen}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
            {
                desc: (
                    <Row style={{}}>
                        <Col span={5}>
                            <div
                                style={{
                                    width: "23px",
                                    height: "23px",
                                    backgroundColor: "#F7DE57",
                                    borderRadius: "6px",
                                }}
                            ></div>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.gpsPerformanceDesYellow}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
            {
                desc: (
                    <Row style={{}}>
                        <Col span={5}>
                            <div
                                style={{
                                    width: "23px",
                                    height: "23px",
                                    backgroundColor: "#F44B51",
                                    borderRadius: "6px",
                                }}
                            ></div>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.gpsPerformanceDesRed}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
            {
                desc: (
                    <Row style={{}}>
                        <Col span={5}>
                            <h4>N/A</h4>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.gpsPerformanceDesNA}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
            {
                desc: (
                    <Row style={{}}>
                        <Col span={5}>
                            <h4>Sensor</h4>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.gpsPerformanceDesDrumSensor}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
            {
                desc: (
                    <Row style={{}}>
                        <Col span={5}>
                            <div
                                style={{
                                    width: "23px",
                                    height: "23px",
                                    backgroundColor: "#00D090",
                                    borderRadius: "6px",
                                }}
                            ></div>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.drumSensorPerformanceDesGreen}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
            {
                desc: (
                    <Row style={{}}>
                        <Col span={5}>
                            <div
                                style={{
                                    width: "23px",
                                    height: "23px",
                                    backgroundColor: "#F7DE57",
                                    borderRadius: "6px",
                                }}
                            ></div>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.drumSensorPerformanceDesYellow}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
            {
                desc: (
                    <Row style={{}}>
                        <Col span={5}>
                            <div
                                style={{
                                    width: "23px",
                                    height: "23px",
                                    backgroundColor: "#F44B51",
                                    borderRadius: "6px",
                                }}
                            ></div>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.drumSensorPerformanceDesRed}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
            {
                desc: (
                    <Row style={{}}>
                        <Col span={5}>
                            <h4>N/A</h4>
                        </Col>
                        <Col span={18} offset={1}>
                            <LangContext.Consumer>
                                {(i18n) => i18n.g.gpsPerformanceDesNA}
                            </LangContext.Consumer>
                        </Col>
                    </Row>
                ),
            },
        ],
    };

    render() {
        return (
            <Modal
                style={{ top: 20 }}
                title={
                    <LangContext.Consumer>
                        {(i18n) => i18n.i.iconDesc}
                    </LangContext.Consumer>
                }
                visible={this.props.iconDetailVisible}
                onOk={this.props.handleIconDetailCancel}
                onCancel={this.props.handleIconDetailCancel}
                cancelButtonProps={{ style: { display: "none" } }}
                okText={
                    <LangContext.Consumer>{(i18n) => i18n.o.okay}</LangContext.Consumer>
                }
            >
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.data}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={item.image}
                                title={item.title}
                                description={item.desc}
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        );
    }
}

export default IconDescModal;
