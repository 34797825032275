import {
  LOAD_ACTION_LOG,
  LOAD_ACTION_LOG_DETAIL,
  LOAD_ACTION_LOG_BY_ENTITY,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  lists: [],
  lists_by_entity: [],
  detail_lists: [],
  permiss: [],
  total: 1,
  loading: false,
  loadingDetail: false,
  draw: -1,
  check1: true,
  data_compare_before: [],
  data_compare_now: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_ACTION_LOG.REQUEST:
      return {
        ...state,
        loading: true,
        check: true,
      }

    case LOAD_ACTION_LOG.SUCCESS:
      return {
        ...state,
        lists: action.payload.lists.data,
        loading: false,
        check: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_ACTION_LOG_DETAIL.REQUEST:
      return {
        ...state,
        loadingDetail: true,
      }

    case LOAD_ACTION_LOG_DETAIL.SUCCESS:
      return {
        ...state,
        data_compare_before: action.payload.lists.data_compare_before,
        data_compare_now: action.payload.lists.data_compare_now,
        loadingDetail: false,
        check1: false,
      }

    case LOAD_ACTION_LOG_BY_ENTITY.SUCCESS:
      return {
        ...state,
        lists_by_entity: action.payload.lists.data,
      }

    default:
      return state
  }
}
