import { createTypes, createAction } from 'lib/action'
const LOAD_VEHICLE_LIST = createTypes('vehicle', 'load')
const REQUEST_CERTIFICATE = createTypes('requestcertificate', 'create')
const LOAD_REQUEST_CERTIFICATE = createTypes('requestcertificate', 'load')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadVehicleList = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLE_LIST.REQUEST, { data, loading }),
  success: Vehiclelists =>
    createAction(LOAD_VEHICLE_LIST.SUCCESS, { Vehiclelists }),
  failure: () => createAction(LOAD_VEHICLE_LIST.FAITLURE),
}

const requestCertificate = {
  request: (data, loading) =>
    createAction(REQUEST_CERTIFICATE.REQUEST, { data, loading }),
  success: res => createAction(REQUEST_CERTIFICATE.SUCCESS, { res }),
  failure: () => createAction(REQUEST_CERTIFICATE.FAITLURE),
}

const loadRequestCertificate = {
  request: (data, loading) =>
    createAction(LOAD_REQUEST_CERTIFICATE.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_REQUEST_CERTIFICATE.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_REQUEST_CERTIFICATE.FAITLURE),
}

export {
  LOAD_REQUEST_CERTIFICATE,
  loadRequestCertificate,
  LOAD_VEHICLE_LIST,
  loadVehicleList,
  REQUEST_CERTIFICATE,
  requestCertificate,
  REMOVE_LISTS,
  removeLists,
}
