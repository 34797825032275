import React, { useState, useEffect, Fragment } from 'react'
import {
  Button,
  Col,
  Spin,
  PageHeader,
  Progress,
  Table,
  DatePicker,
  Select,
} from 'antd'
import moment from 'moment'
import 'moment/locale/th'
import LangContext, { i18n } from 'modules/shared/context/langContext'

const { MonthPicker } = DatePicker
const { Option } = Select

const locale = {
  lang: {
    placeholder: 'เลือกเดือน',
  },
}
const columns = isCurrentMonthTable => [
  {
    title: (
      <div className="regular-bold-14">
        <LangContext.Consumer>{i18n => i18n.t.truck_id}</LangContext.Consumer>
      </div>
    ),
    dataIndex: 'vehicle_code',
  },
  {
    title: (
      <div className="regular-bold-14">
        <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>
      </div>
    ),
    dataIndex: 'plate_no',
  },

  {
    title: (
      <div className="regular-bold-14">
        <LangContext.Consumer>
          {i18n => i18n.m.maintenance_status}
        </LangContext.Consumer>
      </div>
    ),
    dataIndex: 'vehicle_status',
    className: 'column-text-center',
  },
  {
    title: (
      <div className="regular-bold-14">
        <LangContext.Consumer>{i18n => i18n.s.simNumber}</LangContext.Consumer>
      </div>
    ),
    dataIndex: 'sim_no',
  },
  {
    title: (
      <div className="regular-bold-14">
        <LangContext.Consumer>{i18n => i18n.b.boxnumber}</LangContext.Consumer>
      </div>
    ),
    dataIndex: 'device_code',
  },
  {
    title: (
      <div className="regular-bold-14">
        <LangContext.Consumer>{i18n => i18n.b.business}</LangContext.Consumer>
      </div>
    ),
    dataIndex: 'division_name',
  },
  {
    title: (
      <div className="regular-bold-14">
        <LangContext.Consumer>
          {i18n => i18n.c.companyPartner}
        </LangContext.Consumer>
      </div>
    ),
    dataIndex: 'company_name',
  },
  {
    title: <div className="regular-bold-14">GPS(%)</div>,
    dataIndex: 'percent_gps',
    className: 'column-money',
    render(text, record, index) {
      return {
        props: {
          style: {
            background: isCurrentMonthTable
              ? parseInt(text) == -1
                ? 'rgb(255 255 255)'
                : parseInt(text) > -1 && parseInt(text) < 95
                ? 'yellow'
                : '#7CF286'
              : parseInt(text) == -1
              ? 'rgb(255 255 255)'
              : parseInt(text) > -1 && parseInt(text) < 95
              ? '#FF9191'
              : '#7CF286',
          },
        },
        children: <div>{parseInt(text) == -1 ? 'N/A' : text}</div>,
      }
    },
  },
  {
    title: <div className="regular-bold-14">Sensor(%)</div>,
    dataIndex: 'percent_sensor',
    className: 'column-money',
    render(text, record, index) {
      return {
        props: {
          style: {
            background: isCurrentMonthTable
              ? parseInt(text) == -1
                ? 'rgb(255 255 255)'
                : parseInt(text) > -1 && parseInt(text) < 80
                ? 'yellow'
                : '#7CF286'
              : parseInt(text) == -1
              ? 'rgb(255 255 255)'
              : parseInt(text) > -1 && parseInt(text) < 80
              ? '#FF9191'
              : '#7CF286',
          },
        },
        children: <div>{parseInt(text) == -1 ? 'N/A' : text}</div>,
        // <div>{text}</div>,
      }
    },
  },
  {
    title: <div className="regular-bold-14">Card Reader</div>,
    dataIndex: 'percent_card_reader',
    className: 'column-money',
    render(text, record, index) {
      return {
        props: {
          style: {
            background: isCurrentMonthTable
              ? parseInt(text) < 95
                ? 'yellow'
                : '#7CF286'
              : parseInt(text) < 95
              ? '#FF9191'
              : '#7CF286',
          },
        },
        children: <div>{parseInt(text) == -1 ? 'N/A' : text}</div>,
      }
    },
  },
  // {
  //     title: (
  //         <div className="regular-bold-14">
  //             <LangContext.Consumer>
  //                 {i18n => i18n.s.servicecharge}
  //             </LangContext.Consumer>
  //         </div>
  //     ),
  //     dataIndex: 'paid_amout',
  //     className: 'column-money',
  //     render(text, record, index) {
  //         let isPay = (parseInt(record.percent_gps) === -1 || parseInt(record.percent_gps) >= 95) && (parseInt(record.percent_sensor) === -1 || parseInt(record.percent_sensor) >= 80)
  //         return {
  //             children: (
  //                 <div>
  //                     {isPay ? (
  //                         <LangContext.Consumer>{i18n => i18n.p.pay}</LangContext.Consumer>
  //                     ) : (
  //                         <LangContext.Consumer>
  //                             {i18n => i18n.n.notpay}
  //                         </LangContext.Consumer>
  //                     )}
  //                 </div>
  //             ),
  //         }
  //     },
  // },
]

let loadData = (
  accessToken,
  companyId,
  yearMonth,
  vendorId,
  setState,
  setLoading,
  vehicleCodeSearchStr,
  plateNoSearchStr,
  simNoSearchStr,
  deviceCodeSearchStr,
) => {
  yearMonth != '' && setLoading(true)
  fetch(
    `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get_gps_performance_dashboard`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        year_month: yearMonth,
        company_id: vendorId,
        vehicle_code: vehicleCodeSearchStr,
        plate_no: plateNoSearchStr,
        sim_no: simNoSearchStr,
        device_code: deviceCodeSearchStr,
      }),
    },
  )
    .then(response => response.json())
    .then(data => {
      setState(data)
      setLoading(false)
    })
}

function InfoRow(props) {
  const { accessToken } = props.auth
  const [rowKey, setRemoveRow] = useState(props.rowInfoKey)
  const [isCollapse, setCollapse] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedMonth, setMonth] = useState(moment().format('YYYY-MM'))
  const [selectedVendor, setVendor] = useState(props.vendorId)

  useEffect(() => {
    props.collapseAll ? setCollapse(true) : setCollapse(false)
  }, [props.collapseAll])
  useEffect(() => {
    loadData(
      accessToken,
      props.auth.profile.company_id,
      selectedMonth,
      selectedVendor,
      setData,
      setLoading,
      props.vehicleCodeSearchStr,
      props.plateNoSearchStr,
      props.simNoSearchStr,
      props.deviceCodeSearchStr,
    )
  }, [selectedVendor, selectedMonth, props.draw])

  let total_gps = 0,
    total_sensor = 0,
    total_card_reader = 0,
    total_paid = 0

  for (let index = 0; index < data.length; index++) {
    const element = data[index]
    total_gps += element.percent_gps
    total_sensor += element.percent_sensor
    total_card_reader += element.percent_card_reader
    //total_paid += element.paid_amount;

    if (data.length > 0 && index + 1 == data.length) {
      total_gps = Math.floor(total_gps / data.length)
      total_sensor = Math.floor(total_sensor / data.length)
      total_card_reader = Math.floor(total_card_reader / data.length)
      //   total_paid = Math.ceil(total_paid / data.length);
    }
  }
  function disabledDate(current) {
    return current && current > moment().endOf('day')
  }
  return (
    rowKey && (
      <Col span={24} style={{ marginBottom: '20px' }}>
        <PageHeader
          className="compact-page-header"
          style={{
            border: '1px solid rgb(235, 237, 240)',
            backgroundColor: '#F8F8F8',
            margin: '0px 0px 0px 0px',
          }}
          // onBack={() => window.history.back()}
          title={
            // props.rowIndex == 0 ? (
            //   "เดือนปัจจุบัน: " + moment().format("MM/YYYY")
            // ) : (
            <>
              <Fragment>
                <LangContext.Consumer>
                  {i18n => i18n.m.month}
                </LangContext.Consumer>
                :{' '}
                <MonthPicker
                  disabledDate={disabledDate}
                  locale={locale}
                  format="MM/YYYY"
                  style={{ width: '110px', marginRight: '10px' }}
                  size={'small'}
                  defaultValue={moment()}
                  onChange={value => {
                    props.getDateForReport(
                      value == null ? '' : moment(value['_d']),
                    )
                    setMonth(
                      value == null
                        ? ''
                        : moment(value['_d']).format('YYYY-MM'),
                    )
                  }}
                />
              </Fragment>
              {!props.auth.profile.is_gps_vendor && (
                <Fragment>
                  <LangContext.Consumer>
                    {i18n => i18n.g.gpsProvider}
                  </LangContext.Consumer>
                  :{' '}
                  <LangContext.Consumer>
                    {i18n => (
                      <Select
                        showSearch
                        defaultValue={[`${i18n.g.gpsProviderChoose}`]}
                        style={{ width: 300 }}
                        onChange={value => {
                          props.getVendorForReport(value)
                          setVendor(value)
                        }}
                      >
                        {props.vendorList.map(ele => (
                          <Option key={ele.id} value={ele.id}>
                            {ele.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </LangContext.Consumer>
                </Fragment>
              )}
            </>
          }
          extra={
            <div>
              <div
                style={{
                  margin: '5px 0px 0px 0px',
                  display: 'flex',
                  width: 'max-content',
                  justifyContent: 'flex-end',
                }}
              >
                <h4>
                  GPS{' '}
                  <Progress
                    style={{
                      marginTop: '-2px',
                      marginRight: '40px',
                      marginBottom: '-2px',
                    }}
                    percent={total_gps}
                    showInfo={true}
                    type="dashboard"
                    width={27}
                    strokeWidth={9}
                    //   strokeColor={{
                    //     "0%": "#108ee9",
                    //     "100%": "#FF0F0F",
                    //   }}
                    gapDegree={120}
                  />
                  Sensor{' '}
                  <Progress
                    style={{
                      marginTop: '-2px',
                      marginRight: '40px',
                      marginBottom: '-2px',
                    }}
                    percent={total_sensor}
                    showInfo={true}
                    type="dashboard"
                    width={27}
                    strokeWidth={9}
                    //   strokeColor={{
                    //     "0%": "#108ee9",
                    //     "100%": "#FF0F0F",
                    //   }}
                    gapDegree={120}
                  />{' '}
                  Card Reader{' '}
                  <Progress
                    style={{
                      marginTop: '-2px',
                      marginRight: '40px',
                      marginBottom: '-2px',
                    }}
                    percent={total_card_reader}
                    showInfo={true}
                    type="dashboard"
                    width={27}
                    strokeWidth={9}
                    //   strokeColor={{
                    //     "0%": "#108ee9",
                    //     "100%": "#FF0F0F",
                    //   }}
                    gapDegree={120}
                  />{' '}
                </h4>

                <Button
                  onClick={() => setCollapse(!isCollapse)}
                  style={{
                    margin: '0px 0px 0px 20px',
                  }}
                  type="primary"
                  icon={isCollapse ? 'shrink' : 'arrows-alt'}
                  size={'small'}
                />
              </div>
            </div>
          }
          footer={
            isCollapse && (
              <Spin spinning={isLoading}>
                <Table
                  rowKey={'device_id'}
                  style={{ padding: '0px 0px 10px 0px' }}
                  columns={columns(
                    selectedMonth === moment().format('YYYY-MM'),
                  )}
                  dataSource={data}
                  size="small"
                  pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                  }}
                />
              </Spin>
            )
          }
        ></PageHeader>
      </Col>
    )
  )
}

export default InfoRow
