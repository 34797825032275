import {
  LOAD_TRUCKUSAGE,
  LOAD_TRUCKUSAGE_PREVIEW,
  LOAD_VEHICLE_TRUCKUSAGE,
  LOAD_VEHICLE_TYPE_TRUCKUSAGE,
} from './actions'

const initialState = {
  truckusageUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  truckusageLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRUCKUSAGE.REQUEST:
    case LOAD_TRUCKUSAGE_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        truckusageLoading: loading,
      }
    case LOAD_TRUCKUSAGE.SUCCESS:
    case LOAD_TRUCKUSAGE_PREVIEW.SUCCESS:
      return {
        ...state,
        truckusageUrl: action.payload.data.data,
        truckusageLoading: false,
      }
    case LOAD_VEHICLE_TRUCKUSAGE.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        truckusageLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_TRUCKUSAGE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        truckusageLoading: false,
        //  loading: false
      }
    case LOAD_VEHICLE_TYPE_TRUCKUSAGE.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
