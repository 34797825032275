import React, { Component } from 'react'
import { Row, Col, Collapse, Card, Icon, Breadcrumb, Popover } from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Management extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        {/* ผู้ใช้งาน */}
        {checkMenuVisibility('', web_endpoint, 'user', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/user">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.u.user}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับจัดการข้อมูลของผู้ใช้งาน
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.u.user}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* กลุ่มผู้ใช้งาน */}
        {checkMenuVisibility('', web_endpoint, 'userGroup', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/userGroup">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.u.userGroup}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับจัดกลุ่มของผู้ใช้งาน
                          เพื่อให้มองเห็นเมนูตามที่กำหนด
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.u.userGroup}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* การมองเห็นข้อมูล */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'dataVisibility',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/datavisibility">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.dataVisibility}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับกำหนดสิทธิ์ต่างๆในการมองเห็นของข้อมูล
                          (ข้อมูลพาหนะ, ข้อมูลสถานที่)
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.dataVisibility}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* แชร์พาหนะ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'vehicleSharing',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/vehicle_sharing">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.v.vehicleSharing}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแชร์พาหนะ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.v.vehicleSharing}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* เเชร์สถานที่ */}
        {checkMenuVisibility('', web_endpoint, 'shareLocation', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/sharelocation">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.s.shareLocation}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแชร์สถานที่
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.s.shareLocation}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* partnerCar */}
        {checkMenuVisibility('', web_endpoint, 'partnerCar', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/partner">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.p.partnerCar}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* จัดรถของ ผรม. isupply */}
        {checkMenuVisibility(
          'isupply',
          web_endpoint,
          'isupplyManageVehicle',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/isupply-overview-performance-dashboard">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.i.isupplyManageVehicle}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับจัดการคิวรถ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.i.isupplyManageVehicle}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* แผนการจัดส่ง */}
        {checkMenuVisibility('', web_endpoint, 'routemaster', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/routemaster">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.r.routemaster}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Management
