import { createTypes, createAction } from 'lib/action'

const LOAD_TRUCKENGINEON = createTypes('truckengineon', 'load')
const LOAD_TRUCKENGINEON_PREVIEW = createTypes('truckengineonpreview', 'load')
const LOAD_VEHICLE_TRUCKENGINEON = createTypes('vehicle_truckengineon', 'load')
const LOAD_VEHICLE_TYPE_TRUCKENGINEON = createTypes(
  'vehicletype_truckengineon',
  'load',
)

const loadTruckengineon = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKENGINEON.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRUCKENGINEON.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCKENGINEON.FAILURE),
}

const loadTruckengineonpreview = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKENGINEON_PREVIEW.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRUCKENGINEON_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCKENGINEON_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TRUCKENGINEON.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_TRUCKENGINEON.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TRUCKENGINEON.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_TRUCKENGINEON.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_TRUCKENGINEON.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TRUCKENGINEON.FAILURE),
}

export {
  loadTruckengineon,
  LOAD_TRUCKENGINEON,
  loadTruckengineonpreview,
  LOAD_TRUCKENGINEON_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_TRUCKENGINEON,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TRUCKENGINEON,
}
