import {
  LOAD_DEPARTMENT,
  LOAD_DIVISION,
  LOAD_PLANT,
  LOAD_SECTION,
  LOAD_VEHICLE_GROUP,
  LOAD_VEHICLE,
  LOAD_TRUCK_SHARING_TYPE,
  LOAD_TRUCK_SHARING_CURRUNT_LOCATION,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT,
  UPDATE_TRUCK_SHARING_TYPE,
  UPDATE_TRUCK_SHARING_CURRUNT_LOCATION,
  UPDATE_TRUCK_SHARING_AVAILABLE,
  UPDATE_DRIVER_MOBILE_PHONE,
  UPDATE_ROW_CPAC_TRUCK_SHARING,
  UPDATE_CONTRACT_PHONE,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ_CREATE,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE_PATCH,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_HISTORY,
  SET_INITIAL_STATE_IN_REDUX,
  CLEAR_TABLE_WHEN_ONCHANGE,
  DELETE_TRUCK_SHARING,
  CLEAR_TABLE_WHEN_ONCHANGE_CONTRACTOR,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQUEST_TO_USE,
  GET_CPAC_TRUCK_SHARING_VEHICLE_TYPE,
  GET_TRUCK_SHARING_LEVEL,
} from './actions'
const initialState = {
  divisionLists: [],
  departmentLists: [],
  sectionLists: [],
  plantLists: [],
  vehicleGroupLists: [],
  vehicleLists: [],
  truckSharingType: [],
  truckSharingCurrentLocation: [],
  dataListOfVehicleAccount: [],
  truckSharingAvailableAccount: {
    total: 0,
    vehicles: [],
  },
  truckSharingAvailableAccountRequestToUse: {
    total: 0,
    vehicles: [],
  },
  loading: false,
  response: {
    status: null,
    data: null,
    msg: null,
  },
  truckSharingVehicleTypeList: [],
  truckSharinLevel: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DIVISION.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOAD_DIVISION.SUCCESS:
      return {
        ...state,
        divisionLists: action.payload.data,
        loading: false,
      }
    case LOAD_DEPARTMENT.REQUEST:
      return { ...state, loading: true }
    case LOAD_DEPARTMENT.SUCCESS:
      return { ...state, departmentLists: action.payload.data, loading: false }
    case LOAD_SECTION.REQUEST:
      return { ...state, loading: true }
    case LOAD_SECTION.SUCCESS:
      return { ...state, sectionLists: action.payload.data, loading: false }
    case LOAD_PLANT.REQUEST:
      return { ...state, loading: true }
    case LOAD_PLANT.SUCCESS:
      return { ...state, plantLists: action.payload.data, loading: false }
    case LOAD_VEHICLE_GROUP.REQUEST:
      return { ...state, loading: true }
    case LOAD_VEHICLE_GROUP.SUCCESS:
      return {
        ...state,
        vehicleGroupLists: action.payload.data,
        loading: false,
      }
    case LOAD_VEHICLE.REQUEST:
      return { ...state, loading: true }
    case LOAD_VEHICLE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data,
        loading: false,
      }
    case LOAD_TRUCK_SHARING_TYPE.REQUEST:
      return { ...state }
    case LOAD_TRUCK_SHARING_TYPE.SUCCESS:
      return {
        ...state,
        truckSharingType: action.payload.data,
      }
    case LOAD_TRUCK_SHARING_CURRUNT_LOCATION.REQUEST:
      return { ...state }
    case LOAD_TRUCK_SHARING_CURRUNT_LOCATION.SUCCESS:
      return {
        ...state,
        truckSharingCurrentLocation: action.payload.data,
      }

    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT.REQUEST:
      return { ...state, loading: true }
    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT.SUCCESS:
      return {
        ...state,
        truckSharingAvailableAccount: {
          total: action.payload.data.total,
          vehicles: action.payload.data.vehicles,
        },
        loading: false,
      }

    case UPDATE_TRUCK_SHARING_TYPE.REQUEST:
      return { ...state }
    case UPDATE_TRUCK_SHARING_TYPE.SUCCESS:
      return {
        ...state,
        response: {
          status: action.payload.data.status,
          data: action.payload.data.data,
          msg: action.payload.data.msg,
        },
      }

    case UPDATE_TRUCK_SHARING_CURRUNT_LOCATION.REQUEST:
      return { ...state }
    case UPDATE_TRUCK_SHARING_CURRUNT_LOCATION.SUCCESS:
      return {
        ...state,
        response: {
          status: action.payload.data.status,
          data: action.payload.data.data,
          msg: action.payload.data.msg,
        },
      }

    case UPDATE_TRUCK_SHARING_AVAILABLE.REQUEST:
      return { ...state }
    case UPDATE_TRUCK_SHARING_AVAILABLE.SUCCESS:
      return {
        ...state,
        response: {
          status: action.payload.data.status,
          data: action.payload.data.data,
          msg: action.payload.data.msg,
        },
      }
    case UPDATE_TRUCK_SHARING_AVAILABLE.FAILURE:
      return {
        ...state,
        response: action.payload.data.response.data,
      }

    case UPDATE_DRIVER_MOBILE_PHONE.REQUEST:
      return { ...state, loading: true }
    case UPDATE_DRIVER_MOBILE_PHONE.SUCCESS:
      return {
        ...state,
        response: {
          status: action.payload.data.status,
          data: action.payload.data.data,
          msg: action.payload.data.msg,
        },
        loading: true,
      }
    case UPDATE_DRIVER_MOBILE_PHONE.FAILURE:
      return {
        ...state,
        response: action.payload.data.response.data,
        loading: false,
      }

    case UPDATE_CONTRACT_PHONE.REQUEST:
      return { ...state, loading: true }
    case UPDATE_CONTRACT_PHONE.SUCCESS:
      return {
        ...state,
        response: {
          status: action.payload.data.status,
          data: action.payload.data.data,
          msg: action.payload.data.msg,
        },
        loading: true,
      }
    case UPDATE_CONTRACT_PHONE.FAILURE:
      return {
        ...state,
        loading: false,
        response: action.payload.data.response.data,
      }

    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ.REQUEST:
      return { ...state, loading: true }
    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ.SUCCESS:
      return {
        ...state,
        dataListOfVehicleAccount: {
          total: action.payload.data.total,
          vehicles: action.payload.data.vehicles,
        },
        loading: false,
      }

    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ_CREATE.REQUEST:
      return { ...state, loading: true }
    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ_CREATE.SUCCESS:
      return {
        ...state,
        response: {
          status: action.payload.data.status,
          data: action.payload.data.data,
          msg: action.payload.data.msg,
        },
        loading: false,
      }

    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ_CREATE.FAILURE:
      return {
        ...state,
        response: {
          status: action.payload.data.response.data.status,
          data: action.payload.data.response.data.data,
          msg: action.payload.data.response.data.msg,
        },
        loading: false,
      }

    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE.REQUEST:
      return { ...state, loading: true }
    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE.SUCCESS:
      return {
        ...state,
        truckSharingAvailableAccount: {
          total: action.payload.data.total,
          vehicles: action.payload.data.vehicles,
        },
        loading: false,
      }

    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQUEST_TO_USE.REQUEST:
      return { ...state, loading: true }
    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQUEST_TO_USE.SUCCESS:
      return {
        ...state,
        truckSharingAvailableAccountRequestToUse: {
          total: action.payload.data.total,
          vehicles: action.payload.data.vehicles,
        },
        loading: false,
      }

    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE_PATCH.REQUEST:
      return { ...state, loading: true }
    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE_PATCH.SUCCESS:
      return {
        ...state,
        response: {
          status: action.payload.data.status,
          data: action.payload.data.data,
          msg: action.payload.data.msg,
        },
        loading: false,
      }
    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE_PATCH.FAILURE:
      return {
        ...state,
        response: action.payload.data.response.data,
        loading: false,
      }

    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_HISTORY.REQUEST:
      return { ...state, loading: true }
    case LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_HISTORY.SUCCESS:
      return {
        ...state,
        dataListOfVehicleAccount: {
          total: action.payload.data.total,
          vehicles: action.payload.data.histories,
        },
        loading: false,
      }
    case SET_INITIAL_STATE_IN_REDUX.REQUEST: {
      return {
        ...initialState,
        truckSharingAvailableAccount: {
          total: 0,
          vehicles: [],
        },
        response: {
          status: null,
          data: null,
          msg: null,
        },
      }
    }

    case SET_INITIAL_STATE_IN_REDUX.SUCCESS:
      return {
        ...initialState,
        response: {
          status: null,
          data: null,
          msg: null,
        },
      }

    case CLEAR_TABLE_WHEN_ONCHANGE.REQUEST: {
      return {
        ...initialState,
        ...state,
        dataListOfVehicleAccount: {
          total: 0,
          vehicles: [],
        },
        loading: false,
      }
    }

    case CLEAR_TABLE_WHEN_ONCHANGE.SUCCESS:
      return {
        ...initialState,
        ...state,
        dataListOfVehicleAccount: {
          total: 0,
          vehicles: [],
        },
        loading: false,
      }

    case CLEAR_TABLE_WHEN_ONCHANGE_CONTRACTOR.REQUEST: {
      return {
        ...state,
        truckSharingAvailableAccount: {
          total: 0,
          vehicles: [],
        },
      }
    }

    case CLEAR_TABLE_WHEN_ONCHANGE_CONTRACTOR.SUCCESS:
      return {
        ...state,
        truckSharingAvailableAccount: {
          total: 0,
          vehicles: [],
        },
      }

    case DELETE_TRUCK_SHARING.REQUEST:
      return { ...state, loading: true }
    case DELETE_TRUCK_SHARING.SUCCESS:
      return {
        ...state,
        response: {
          status: action.payload.data.status,
          data: action.payload.data.data,
          msg: action.payload.data.msg,
        },
        loading: false,
      }
    case DELETE_TRUCK_SHARING.FAILURE:
      return {
        ...state,
        loading: false,
        response: action.payload.data.response.data,
      }

    case UPDATE_ROW_CPAC_TRUCK_SHARING.REQUEST:
      return { ...state, loading: true }
    case UPDATE_ROW_CPAC_TRUCK_SHARING.SUCCESS:
      return {
        ...state,
        response: {
          status: action.payload.data.status,
          data: action.payload.data.data,
          msg: action.payload.data.msg,
        },
        loading: false,
      }
    case UPDATE_ROW_CPAC_TRUCK_SHARING.FAILURE:
      return {
        ...state,
        response: action.payload.data.response.data,
        loading: false,
      }

    case GET_CPAC_TRUCK_SHARING_VEHICLE_TYPE.REQUEST:
      return { ...state, loading: true }
    case GET_CPAC_TRUCK_SHARING_VEHICLE_TYPE.SUCCESS:
      return {
        ...state,
        truckSharingVehicleTypeList: action.payload.data,
        loading: false,
      }
    case GET_CPAC_TRUCK_SHARING_VEHICLE_TYPE.FAILURE:
      return {
        ...state,
        truckSharingVehicleTypeList: [],
        loading: false,
      }

    case GET_TRUCK_SHARING_LEVEL.REQUEST:
      return { ...state, loading: true }
    case GET_TRUCK_SHARING_LEVEL.SUCCESS:
      return {
        ...state,
        truckSharinLevel: action.payload.data,
        loading: false,
      }
    case GET_TRUCK_SHARING_LEVEL.FAILURE:
      return {
        ...state,
        truckSharinLevel: [],
        loading: false,
      }

    default:
      return state
  }
}
