import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Index from './Index'

export default () => (
  <Route>
    <div>
      <Route exact path="/generateusertoken" component={Index} />
    </div>
  </Route>
)
