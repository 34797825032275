import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spin, Col, Button, Row, Empty } from 'antd'

import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext from 'modules/shared/context/langContext'

class Previewtable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataPreview: [],
      loadPreview: false,
      filter: '',
      header: '',
      loading: false,
    }
  }

  handleMenuClick = () => {
    let self = this
    if (self.state.filter !== '' && self.state.header !== '') {
      let data = {
        ...self.state.filter,
        data_type: 'report',
        ...self.state.header,
      }
      self.setState({ loading: true })

      fetch(
        `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/truckdeliverydistance`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            start_at: data.start_at,
            end_at: data.end_at,
            site_ids: data.site_ids,
            division_id: data.division_id,
            department_id: data.department_id,
            section_id: data.section_id,
            plant_id: data.plant_id,
            company_id: data.company_id,
            data_type: data.data_type,
            display: data.display,
          }),
        },
      )
        .then(response => response.json())
        .then(data => {
          self.setState({ loading: false })
          window.location.assign(`${data.result}`)
        })
    }
  }

  componentDidMount() {
    if (
      localStorage.getItem('distancereport-filter') != null &&
      localStorage.getItem('distancereport-header') != null
    ) {
      let self = this
      let filter = localStorage.getItem('distancereport-filter')
      let header = localStorage.getItem('distancereport-header')
      self.setState({
        filter: JSON.parse(filter),
        header: JSON.parse(header),
        loadPreview: true,
      })
      fetch(
        `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/truckdeliverydistance`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: filter,
        },
      )
        .then(response => {
          return response.json()
        })
        .then(data => {
          self.setState({
            dataPreview: data,
            loadPreview: false,
          })
        })
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('distancereport-filter')
    localStorage.removeItem('distancereport-header')
  }

  render() {
    return (
      <AuthorizeComponent {...this.props} matching_name="distancereport">
        <Spin spinning={this.state.loading}>
          <Spin spinning={this.state.loadPreview}>
            <GeneralStyledContent>
              <Row
                gutter={24}
                type="flex"
                justify="end"
                style={{ height: '38px' }}
              >
                <Col xs={9} sm={4} md={3} lg={2} xl={2}>
                  <Button
                    type="primary"
                    block
                    onClick={() => this.handleMenuClick()}
                  >
                    <LangContext.Consumer>
                      {i18n => i18n.r.report}
                    </LangContext.Consumer>
                  </Button>
                </Col>
              </Row>
              <div className="scrolling__table">
                {this.state.dataPreview.length > 0 ? (
                  <table className="tr__td__style" style={{ width: '100%' }}>
                    <thead
                      style={{
                        backgroundColor: '#ff8f8f',
                        color: 'black',
                        fontSize: '16px',
                        position: 'sticky',
                        top: 0,
                      }}
                    >
                      <tr>
                        <th className="border__right__style" rowspan="3">
                          <LangContext.Consumer>
                            {i18n => i18n.s.siteCode}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style" rowspan="3">
                          <LangContext.Consumer>
                            {i18n => i18n.s.site}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style" rowspan="3">
                          <LangContext.Consumer>
                            {i18n => i18n.p.plantsDelivery}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style" rowspan="3">
                          <LangContext.Consumer>
                            {i18n => i18n.p.production}
                          </LangContext.Consumer>
                        </th>
                        <th
                          className="border__right__bottom__style"
                          colSpan="6"
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.b.bluenetTransportDistance}
                          </LangContext.Consumer>
                        </th>
                        <th
                          className="border__right__bottom__style"
                          colSpan="7"
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.g.gpsDistance}
                          </LangContext.Consumer>
                        </th>
                        <th style={{ fontWeight: 'lighter' }} rowspan="3">
                          <LangContext.Consumer>
                            {i18n => i18n.d.distanceDifferenceKm}
                          </LangContext.Consumer>
                        </th>
                      </tr>
                      <tr>
                        <th className="border__right__style" rowspan="2">
                          SPD1
                        </th>
                        <th
                          className="border__right__bottom__style"
                          colSpan="2"
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.g.goto}
                          </LangContext.Consumer>
                        </th>
                        <th
                          className="border__right__bottom__style"
                          colSpan="2"
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.g.goback}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style" rowspan="2">
                          <LangContext.Consumer>
                            {i18n => i18n.t.totalKm}
                          </LangContext.Consumer>
                        </th>
                        <th
                          className="border__right__bottom__style"
                          colSpan="2"
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.g.goto}
                          </LangContext.Consumer>
                        </th>
                        <th
                          className="border__right__bottom__style"
                          colSpan="2"
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.g.goback}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style" rowspan="2">
                          <LangContext.Consumer>
                            {i18n => i18n.t.totalKm}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style" rowspan="2">
                          <LangContext.Consumer>
                            {i18n => i18n.t.totalDividedBy2}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style" rowspan="2">
                          <LangContext.Consumer>
                            {i18n => i18n.d.distanceOnSite}
                          </LangContext.Consumer>
                        </th>
                      </tr>
                      <tr>
                        <th className="border__right__style">
                          {' '}
                          <LangContext.Consumer>
                            {i18n => i18n.k.km}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style">
                          <LangContext.Consumer>
                            {i18n => i18n.m.min}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style">
                          <LangContext.Consumer>
                            {i18n => i18n.k.km}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style">
                          <LangContext.Consumer>
                            {i18n => i18n.m.min}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style">
                          <LangContext.Consumer>
                            {i18n => i18n.k.km}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style">
                          <LangContext.Consumer>
                            {i18n => i18n.m.min}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style">
                          <LangContext.Consumer>
                            {i18n => i18n.k.km}
                          </LangContext.Consumer>
                        </th>
                        <th className="border__right__style">
                          <LangContext.Consumer>
                            {i18n => i18n.m.min}
                          </LangContext.Consumer>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: 'white' }}>
                      {this.state.dataPreview.length > 0 &&
                        this.state.dataPreview.map(item => {
                          let plant =
                            item.plant_list.length != 0
                              ? item.plant_list[0]
                              : {
                                  plant_name: '',
                                  total_volume: '',
                                  spd1: '',
                                  site_to_plant_km: '',
                                  site_to_plant_min: '',
                                  plant_to_site_km: '',
                                  plant_to_site_min: '',
                                  total_distance: '',
                                  gps_site_to_plant_km: '',
                                  gps_site_to_plant_min: '',
                                  gps_plant_to_site_km: '',
                                  gps_plant_to_site_min: '',
                                  gps_totol_distance: '',
                                  gps_totol_distance_avg: '',
                                  site_distance: '',
                                  distance_diff: '',
                                }
                          return (
                            <>
                              <tr>
                                <td
                                  className="border__right__td__style"
                                  rowspan={item.plant_list.length}
                                >
                                  {item.site_code}
                                </td>
                                <td
                                  className="border__right__td__style"
                                  rowspan={item.plant_list.length}
                                >
                                  {item.site_name}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.plant_name}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.total_volume}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.spd1}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.plant_to_site_km}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.plant_to_site_min}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.site_to_plant_km}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.site_to_plant_min}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.total_distance}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.gps_plant_to_site_km}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.gps_plant_to_site_min}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.gps_site_to_plant_km}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.gps_site_to_plant_min}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.gps_totol_distance}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.gps_totol_distance_avg}
                                </td>
                                <td className="border__right__td__style">
                                  {plant.site_distance}
                                </td>
                                <td className="border__bottom__td__style">
                                  {plant.distance_diff}
                                </td>
                              </tr>
                              {item.plant_list.map((plant, i) => {
                                if (i == 0) {
                                  return null
                                }
                                return (
                                  <tr>
                                    <td className="border__right__td__style">
                                      {plant.plant_name}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.total_volume}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.spd1}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.site_to_plant_km}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.site_to_plant_min}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.plant_to_site_km}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.plant_to_site_min}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.total_distance}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_site_to_plant_km}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_site_to_plant_min}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_plant_to_site_km}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_plant_to_site_min}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_totol_distance}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.gps_totol_distance_avg}
                                    </td>
                                    <td className="border__right__td__style">
                                      {plant.site_distance}
                                    </td>
                                    <td className="border__bottom__td__style">
                                      {plant.distance_diff}
                                    </td>
                                  </tr>
                                )
                              })}
                            </>
                          )
                        })}
                    </tbody>
                  </table>
                ) : (
                  <div
                    style={{
                      backgroundColor: 'white',
                      height: '240px',
                      paddingTop: '60px',
                    }}
                  >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
              </div>
            </GeneralStyledContent>
          </Spin>
        </Spin>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

export default connect(mapStateToProps, null)(Previewtable)
