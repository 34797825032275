import { createTypes, createAction } from 'lib/action'

const LOAD_VEHICLEINAREA = createTypes('vehicleinarea', 'load')
const LOAD_VEHICLEINAREA_PREVIEW = createTypes('vehicleinareapreview', 'load')
const LOAD_VEHICLE_VEHICLEINAREA = createTypes('vehicle_vehicleinarea', 'load')
const LOAD_VEHICLE_TYPE_VEHICLEINAREA = createTypes(
  'vehicletype_vehicleinarea',
  'load',
)
const LOAD_LOCATION_VEHICLEINAREA = createTypes(
  'location_vehicleinarea',
  'load',
)
const loadVehicleinarea = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLEINAREA.REQUEST, { data, loading }),
  success: data => createAction(LOAD_VEHICLEINAREA.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLEINAREA.FAILURE),
}

const loadVehicleinareapreview = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLEINAREA_PREVIEW.REQUEST, { data, loading }),
  success: data => createAction(LOAD_VEHICLEINAREA_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLEINAREA_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_VEHICLEINAREA.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_VEHICLEINAREA.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_VEHICLEINAREA.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_VEHICLEINAREA.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_VEHICLEINAREA.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_VEHICLEINAREA.FAILURE),
}

const loadLocation = {
  request: () => createAction(LOAD_LOCATION_VEHICLEINAREA.REQUEST, {}),
  success: data => createAction(LOAD_LOCATION_VEHICLEINAREA.SUCCESS, { data }),
  failure: () => createAction(LOAD_LOCATION_VEHICLEINAREA.FAILURE),
}

export {
  loadVehicleinarea,
  LOAD_VEHICLEINAREA,
  loadVehicleinareapreview,
  LOAD_VEHICLEINAREA_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_VEHICLEINAREA,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_VEHICLEINAREA,
  loadLocation,
  LOAD_LOCATION_VEHICLEINAREA,
}
