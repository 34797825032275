import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'

import * as actions from './actions'

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadVehicleType.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleType.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )
  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadPlant(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const Vocation_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'location_visibility',
  )
  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplant`,
      {
        company_id: COMPANY_ID,
        location_visibility: Vocation_visibility,
        relate_company: `${this.props.auth.profile.relate_company}`,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadPlant.success(res.data))
  } catch (err) {
    yield put(actions.loadPlant.failure())
  }
}

function* exportRmcconcretetripReport(action) {
  let {
    data: {
      start_date,
      end_date,
      list_plant_id,
      list_vehicle_id,
      company_id,
      vehicle_visibility,
      accesstoken,
      selectedSite,
    },
  } = action.payload
  try {
    if (typeof list_plant_id != 'undefined' && list_plant_id)
      list_plant_id = list_plant_id.filter(ele => ele != '')

    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/rmcconcretetrip`,
      {
        date_start: start_date,
        date_end: end_date,
        type: 'vehicle',
        vehicle_list: list_vehicle_id,
        plants_list: list_plant_id,
        company_id: company_id,
        vehicle_visibility: vehicle_visibility,
        site_id: selectedSite,
        type_file: 'excel',
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.exportRmcconcretetripReport.success(res.data))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportRmcconcretetripReport.failure())
  }
}

function* exportRmcconcretetripReportpreview(action) {
  let {
    data: {
      date_start,
      date_end,
      type,
      vehicle_list,
      plants_list,
      company_id,
      vehicle_visibility,
      site_id,
      type_file,
      header,
    },
  } = action.payload
  try {
    if (typeof plants_list != 'undefined' && plants_list)
      plants_list = plants_list.filter(ele => ele != '')

    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/rmcconcretetrip`,
      {
        date_start,
        date_end,
        type,
        vehicle_list,
        plants_list,
        company_id,
        vehicle_visibility,
        site_id,
        type_file,
      },
      { headers: header },
    )

    yield put(actions.exportRmcconcretetripReport.success(res.data))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportRmcconcretetripReport.failure())
  }
}

function* loadDivision(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { division_visibility, comp } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`,
      {
        division_visibility: division_visibility,
        comp: comp,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadDivision.success(res.data))
  } catch (err) {
    yield put(actions.loadDivision.failure())
  }
}
// /getplant

export default function* watchRmcconcretetrip() {
  yield all([
    takeEvery(actions.LOAD_VEHICLE_RMCCONCRETETRIP.REQUEST, loadVehicle),
    takeEvery(actions.LOAD_VEHICLE_RMCCONCRETETRIP.REQUEST, loadVehicleType),
    takeEvery(actions.LOAD_PLANT_RMCCONCRETETRIP.REQUEST, loadPlant),
    takeEvery(
      actions.EXPORT_RMCCONCRETETRIP_REPORT.REQUEST,
      exportRmcconcretetripReport,
    ),
    takeEvery(
      actions.EXPORT_RMCCONCRETETRIP_REPORT_PREVIEW.REQUEST,
      exportRmcconcretetripReportpreview,
    ),
    takeEvery(actions.LOAD_DIVISION.REQUEST, loadDivision),
  ])
}
export {
  loadVehicleType,
  loadVehicle,
  loadPlant,
  exportRmcconcretetripReport,
  loadDivision,
  exportRmcconcretetripReportpreview,
}
