import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Fueluse from './Index'
import Preview from './Previewtable'

export default () => (
  <Switch>
    <Route exact path="/report/fueluse" component={Fueluse} />
    <Route exact path="/report/fueluse/preview" component={Preview} />
  </Switch>
)
