import React, { Component } from 'react'
import { Row, Col, Collapse, Card, Icon, Breadcrumb, Popover } from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Help extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        {/* ขอใบรับรอง */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'requestACertificate',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/cpac-requestcertificate">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.r.requestACertificate}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับส่งคำขอเอกสารรับรองการติดตั้ง GPS
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.r.requestACertificate}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* แจ้งซ่อมอุปกรณ์ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'maintenancedevice',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/maintenancedevice">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.m.maintenancedevice}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแจ้งซ่อมอุปกรณ์ GPS
                          กรณีที่ต้องการให้มีการตรวจสอบแก้ไข
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.m.maintenancedevice}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Help
