import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { WebContentStyled } from '../../../styled/common-styled'
// import Breadcrumb from '../../shared/components/Breadcrumb'
import { Row, Col, Collapse, Card } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { checkPermissions } from '../../../lib/helper'
const { Panel } = Collapse

const checkMenuVisibility = (
  web_use,
  web_endpoint,
  matching_name,
  permissions,
) => {
  //   console.log('web use', web_use)
  //   console.log('web_endpoint', web_endpoint)
  //   console.log('web matching_name', matching_name)
  //   console.log('web permissions', permissions)
  let getMatchingName = ''
  if (web_use != 'undefined' && web_use != '' && web_use == web_endpoint) {
    getMatchingName = matching_name
  } else if (web_use != 'undefined' && web_use == '') {
    getMatchingName = matching_name
  } else {
    getMatchingName = ''
  }
  let matchingNameArr = []
  matchingNameArr.push(getMatchingName)
  //   console.log('web matchingNameArr', matchingNameArr)
  if (
    typeof permissions == 'undefined' ||
    typeof permissions == 'string' ||
    permissions == []
  )
    return true

  let hideMenu = true
  for (let i = 0; i < matchingNameArr.length; i++) {
    let menu = matchingNameArr[i]

    let canAccess = permissions.find(ele => {
      return ele.matching_name == menu && ele.can_view == 1
    })

    if (typeof canAccess != 'undefined') {
      hideMenu = false
    }
  }

  return hideMenu
}

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textDashboard: true,
      textMonitoring: true,
      textReport: true,
      textManagement: true,
      textMasterData: true,
      textDelivery: true,
      textHelp: true,
      textSetting: true,
      web_endpoint: '',
      settingObj: [
        {
          matchingName: 'cpacPolicy',
          web_use: '',
        },
        {
          matchingName: 'policy',
          web_use: '',
        },
        {
          matchingName: 'linenotify',
          web_use: '',
        },
      ],
      helpObj: [
        {
          matchingName: 'requestACertificate',
          web_use: '',
        },
        {
          matchingName: 'maintenancedevice',
          web_use: '',
        },
        {
          matchingName: 'servicerepairdevice',
          web_use: '',
        },
        {
          matchingName: 'servicemovedevice',
          web_use: '',
        },
      ],
      deliveryObj: [
        {
          matchingName: 'isupplyManageQueue',
          web_use: '',
        },
        {
          matchingName: 'trip',
          web_use: '',
        },
        {
          matchingName: 'routemaster',
          web_use: '',
        },
      ],
      deliveryTripObj: [
        {
          matchingName: 'trip',
          web_use: '',
        },
        {
          matchingName: 'routemaster',
          web_use: '',
        },
      ],
      masterdataObj: [
        {
          matchingName: 'location',
          web_use: '',
        },
        {
          matchingName: 'zone',
          web_use: '',
        },
        {
          matchingName: 'vehicleBlacklist',
          web_use: 'isupply',
        },
        {
          matchingName: 'driverBlacklist',
          web_use: 'isupply',
        },
        {
          matchingName: 'nearByLocation',
          web_use: '',
        },
        {
          matchingName: 'locationGroup',
          web_use: '',
        },
        {
          matchingName: 'siteGroup',
          web_use: '',
        },
        {
          matchingName: 'vehicle_type',
          web_use: '',
        },
        {
          matchingName: 'vehicle',
          web_use: '',
        },
        {
          matchingName: 'vehicleGroup',
          web_use: '',
        },
        {
          matchingName: 'chauffeur',
          web_use: '',
        },
        {
          matchingName: 'masterDataMatrial',
          web_use: 'isupply',
        },
        {
          matchingName: 'comparativeRawMaterials',
          web_use: 'isupply',
        },
        {
          matchingName: 'requestMaterial',
          web_use: 'isupply',
        },
        {
          matchingName: 'plantMiningContract',
          web_use: 'isupply',
        },
        {
          matchingName: 'isupplySystemKey',
          web_use: 'isupply',
        },
        {
          matchingName: 'informationAboutTheCarRunWall',
          web_use: '',
        },
        {
          matchingName: 'informationAboutTheCarRunFloor',
          web_use: '',
        },
        {
          matchingName: 'product',
          web_use: '',
        },
        {
          matchingName: 'uom',
          web_use: 'undefined',
        },
        {
          matchingName: 'customer',
          web_use: '',
        },
        {
          matchingName: 'permission',
          web_use: '',
        },
        {
          matchingName: 'maintenance',
          web_use: '',
        },
        {
          matchingName: 'reFuelTitle',
          web_use: '',
        },
        {
          matchingName: 'company',
          web_use: '',
        },
        {
          matchingName: 'sendemail',
          web_use: '',
        },
      ],
      masterdataLocationObj: [
        {
          matchingName: 'location',
          web_use: '',
        },
        {
          matchingName: 'zone',
          web_use: '',
        },
        {
          matchingName: 'vehicleBlacklist',
          web_use: 'isupply',
        },
        {
          matchingName: 'driverBlacklist',
          web_use: 'isupply',
        },
        {
          matchingName: 'nearByLocation',
          web_use: '',
        },
        {
          matchingName: 'locationGroup',
          web_use: '',
        },
        {
          matchingName: 'siteGroup',
          web_use: '',
        },
      ],
      masterdataVehicleObj: [
        {
          matchingName: 'vehicle_type',
          web_use: '',
        },
        {
          matchingName: 'vehicle',
          web_use: '',
        },
        {
          matchingName: 'vehicleGroup',
          web_use: '',
        },
      ],
      masterdataChauffeurObj: [
        {
          matchingName: 'chauffeur',
          web_use: '',
        },
      ],
      masterdataPrecastObj: [
        {
          matchingName: 'informationAboutTheCarRunWall',
          web_use: '',
        },
        {
          matchingName: 'informationAboutTheCarRunFloor',
          web_use: '',
        },
      ],
      masterdataMaterialObj: [
        {
          matchingName: 'masterDataMatrial',
          web_use: 'isupply',
        },
        {
          matchingName: 'comparativeRawMaterials',
          web_use: 'isupply',
        },
        {
          matchingName: 'requestMaterial',
          web_use: 'isupply',
        },
        {
          matchingName: 'plantMiningContract',
          web_use: 'isupply',
        },
        {
          matchingName: 'isupplySystemKey',
          web_use: 'isupply',
        },
      ],
      managementObj: [
        {
          matchingName: 'user',
          web_use: '',
        },
        {
          matchingName: 'userGroup',
          web_use: '',
        },
        {
          matchingName: 'dataVisibility',
          web_use: '',
        },
        {
          matchingName: 'vehicleSharing',
          web_use: '',
        },
        {
          matchingName: 'shareLocation',
          web_use: '',
        },
        {
          matchingName: 'partnerCar',
          web_use: '',
        },
        {
          matchingName: 'routemaster',
          web_use: '',
        },
        {
          matchingName: 'isupplyManageVehicle',
          web_use: 'isupply',
        },
      ],
      dashboardObj: [
        {
          matchingName: 'concreteDeliveryManagerDashboard',
          web_use: '',
        },
        {
          matchingName: 'smartDispatching',
          web_use: '',
        },
        {
          matchingName: 'truckstatusdashboard',
          web_use: '',
        },
        {
          matchingName: 'notifyTheTruckInCorrectPoint',
          web_use: '',
        },
        {
          matchingName: 'SafetyDashboard',
          web_use: '',
        },
        {
          matchingName: 'gpsPerformanceDashboard',
          web_use: '',
        },
        {
          matchingName: 'deliveryDashboard',
          web_use: 'isupply',
        },
        {
          matchingName: 'transportationPerformanceDashboard',
          web_use: 'isupply',
        },
        {
          matchingName: 'theStatusOfVehiclesThatReceiveDailyJobs',
          web_use: 'isupply',
        },
        {
          matchingName: 'isupplyManageQueue',
          web_use: 'isupply',
        },
        {
          matchingName: 'precastDashboard',
          web_use: '',
        },
        {
          matchingName: 'multiDrop',
          web_use: '',
        },
        {
          matchingName: 'alert_alarm_dashboard',
          web_use: '',
        },
        {
          matchingName: 'eventMap',
          web_use: '',
        },
        {
          matchingName: 'driverKpiDashboard',
          web_use: '',
        },
      ],
      monitoringObj: [
        {
          matchingName: 'replay',
          web_use: '',
        },
        {
          matchingName: 'honeytoastlivestream',
          web_use: '',
        },
        {
          matchingName: 'honeytoastplayback',
          web_use: '',
        },
        {
          matchingName: 'honeytoastdownloadfile',
          web_use: '',
        },
        {
          matchingName: 'video',
          web_use: '',
        },
        {
          matchingName: 'mdvrdownloadfile',
          web_use: '',
        },
        {
          matchingName: 'summary_dashboard',
          web_use: '',
        },
        {
          matchingName: 'booking_overview_dashboard',
          web_use: '',
        },
        {
          matchingName: 'cashing_overview_dahsboar',
          web_use: '',
        },
      ],
      monitoringHoneyToastObj: [
        {
          matchingName: 'honeytoastlivestream',
          web_use: '',
        },
        {
          matchingName: 'honeytoastplayback',
          web_use: '',
        },
        {
          matchingName: 'honeytoastdownloadfile',
          web_use: '',
        },
      ],
      monitoringMDVRObj: [
        {
          matchingName: 'video',
          web_use: '',
        },
        {
          matchingName: 'mdvrdownloadfile',
          web_use: '',
        },
        {
          matchingName: 'summary_dashboard',
          web_use: '',
        },
        {
          matchingName: 'booking_overview_dashboard',
          web_use: '',
        },
        {
          matchingName: 'cashing_overview_dahsboar',
          web_use: '',
        },
      ],
      reportObj: [
        {
          matchingName: 'schedulereport',
          web_use: '',
        },
        {
          matchingName: 'TruckcurrentlocationReport',
          web_use: '',
        },
        {
          matchingName: 'NumberofdaysworkedReport',
          web_use: '',
        },
        {
          matchingName: 'DrivingdistanceReport',
          web_use: '',
        },
        {
          matchingName: 'DriverinformationReport',
          web_use: '',
        },
        {
          matchingName: 'theDriverIsIicenseReportHasExpired',
          web_use: '',
        },
        {
          matchingName: 'LoginhistoryReport',
          web_use: '',
        },
        {
          matchingName: 'GpsstatusReport',
          web_use: '',
        },
        {
          matchingName: 'TrackingdataReport',
          web_use: '',
        },
        {
          matchingName: 'dailyDrivingBehaviorReport',
          web_use: '',
        },
        {
          matchingName: 'reportCardSwipeBehaviorBeforeDrivingDaily',
          web_use: '',
        },
        {
          matchingName: 'RiskbehaviourReport',
          web_use: '',
        },
        {
          matchingName: 'DrivinglicensescanlogReport',
          web_use: '',
        },
        {
          matchingName: 'DrivinglogReport',
          web_use: '',
        },
        {
          matchingName: 'ParkingReport',
          web_use: '',
        },
        {
          matchingName: 'reportSummarizingTheNumberOfOffSiteParking',
          web_use: '',
        },
        {
          matchingName: 'VehicleinareaReport',
          web_use: '',
        },
        {
          matchingName: 'DrivingoverlimitReport',
          web_use: '',
        },
        {
          matchingName: 'TruckusageReport',
          web_use: '',
        },
        {
          matchingName: 'DozeoffriskReport',
          web_use: '',
        },
        {
          matchingName: 'dailyCarStatusReport',
          web_use: '',
        },
        {
          matchingName: 'SpeedoverlimitsummaryReport',
          web_use: '',
        },
        {
          matchingName: 'SummaryKPIReport',
          web_use: '',
        },
        {
          matchingName: 'TripbyengineoffonReport',
          web_use: '',
        },
        {
          matchingName: 'BehaviordrivingReport',
          web_use: '',
        },
        {
          matchingName: 'BehaviordrivingbycompanyReport',
          web_use: '',
        },
        {
          matchingName: 'ScoresummaryReport',
          web_use: '',
        },
        {
          matchingName: 'SafetyEventReport',
          web_use: '',
        },
        {
          matchingName: 'alarmAndAlertReportClassifiedByDailyCarRegistration',
          web_use: '',
        },
        {
          matchingName: 'DailyAlarmAndAlertReportByDriver',
          web_use: '',
        },
        {
          matchingName: 'alarmAndAlertReportClassifiedByYearlyCarRegistration',
          web_use: '',
        },
        {
          matchingName: 'reportAlarmFrequencyClassifiedByDailyDriver',
          web_use: '',
        },
        {
          matchingName: 'top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod',
          web_use: '',
        },
        {
          matchingName: 'TruckengineonReport',
          web_use: '',
        },
        {
          matchingName: 'TrucknotuseReport',
          web_use: '',
        },
        {
          matchingName: 'TruckengineoffReport',
          web_use: '',
        },
        {
          matchingName: 'VehicleperformanceconcludeReport',
          web_use: '',
        },
        {
          matchingName: 'drivingSummary',
          web_use: '',
        },
        {
          matchingName: 'concreteTrip',
          web_use: '',
        },
        {
          matchingName: 'deliveryReport',
          web_use: 'isupply',
        },
        {
          matchingName: 'weighingHistoryReport',
          web_use: 'isupply',
        },
        {
          matchingName: 'floorWallDelivery',
          web_use: '',
        },
        {
          matchingName: 'distancereport',
          web_use: '',
        },
        {
          matchingName: 'TripsummaryReport',
          web_use: '',
        },
        {
          matchingName: 'DailyReport',
          web_use: '',
        },
        {
          matchingName: 'TripsumbylocationReport',
          web_use: '',
        },
        {
          matchingName: 'TripsumbyvehReport',
          web_use: '',
        },
        {
          matchingName: 'EventReport',
          web_use: '',
        },
        {
          matchingName: 'RefuelReport',
          web_use: '',
        },
        {
          matchingName: 'fuelremainrateReport',
          web_use: '',
        },
        {
          matchingName: 'FueluseReport',
          web_use: '',
        },
        {
          matchingName: 'gpsVendorPayingSummaryReport',
          web_use: '',
        },
        {
          matchingName: 'partnerPerformReport',
          web_use: '',
        },
        {
          matchingName: 'gpsVendorPayingReport',
          web_use: '',
        },
        {
          matchingName: 'repairingReport',
          web_use: '',
        },
        {
          matchingName: 'gpsReportLostContact',
          web_use: '',
        },
        {
          matchingName: 'gpsDisconnectOnTrip',
          web_use: '',
        },
        {
          matchingName: 'distance_report_1',
          web_use: '',
        },
        {
          matchingName: 'InsuranceReport',
          web_use: '',
        },
      ],
      reportGeneralObj: [
        {
          matchingName: 'TruckcurrentlocationReport',
          web_use: '',
        },
        {
          matchingName: 'NumberofdaysworkedReport',
          web_use: '',
        },
        {
          matchingName: 'DrivingdistanceReport',
          web_use: '',
        },
        {
          matchingName: 'DriverinformationReport',
          web_use: '',
        },
        {
          matchingName: 'theDriverIsIicenseReportHasExpired',
          web_use: '',
        },
        {
          matchingName: 'LoginhistoryReport',
          web_use: '',
        },
        {
          matchingName: 'GpsstatusReport',
          web_use: '',
        },
        {
          matchingName: 'TrackingdataReport',
          web_use: '',
        },
      ],
      reportBehaviorObj: [
        {
          matchingName: 'dailyDrivingBehaviorReport',
          web_use: '',
        },
        {
          matchingName: 'reportCardSwipeBehaviorBeforeDrivingDaily',
          web_use: '',
        },
        {
          matchingName: 'RiskbehaviourReport',
          web_use: '',
        },
        {
          matchingName: 'DrivinglicensescanlogReport',
          web_use: '',
        },
        {
          matchingName: 'DrivinglogReport',
          web_use: '',
        },
        {
          matchingName: 'ParkingReport',
          web_use: '',
        },
        {
          matchingName: 'reportSummarizingTheNumberOfOffSiteParking',
          web_use: '',
        },
        {
          matchingName: 'VehicleinareaReport',
          web_use: '',
        },
        {
          matchingName: 'DrivingoverlimitReport',
          web_use: '',
        },
        {
          matchingName: 'TruckusageReport',
          web_use: '',
        },
        {
          matchingName: 'DozeoffriskReport',
          web_use: '',
        },
        {
          matchingName: 'dailyCarStatusReport',
          web_use: '',
        },
        {
          matchingName: 'SpeedoverlimitsummaryReport',
          web_use: '',
        },
        {
          matchingName: 'SummaryKPIReport',
          web_use: '',
        },
        {
          matchingName: 'TripbyengineoffonReport',
          web_use: '',
        },
      ],
      reportAlertAndAlarmObj: [
        {
          matchingName: 'BehaviordrivingReport',
          web_use: '',
        },
        {
          matchingName: 'BehaviordrivingbycompanyReport',
          web_use: '',
        },
        {
          matchingName: 'ScoresummaryReport',
          web_use: '',
        },
        {
          matchingName: 'SafetyEventReport',
          web_use: '',
        },
        {
          matchingName: 'alarmAndAlertReportClassifiedByDailyCarRegistration',
          web_use: '',
        },
        {
          matchingName: 'DailyAlarmAndAlertReportByDriver',
          web_use: '',
        },
        {
          matchingName: 'alarmAndAlertReportClassifiedByYearlyCarRegistration',
          web_use: '',
        },
        {
          matchingName: 'reportAlarmFrequencyClassifiedByDailyDriver',
          web_use: '',
        },
        {
          matchingName: 'top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod',
          web_use: '',
        },
      ],
      reportEfficiencyObj: [
        {
          matchingName: 'TruckengineonReport',
          web_use: '',
        },
        {
          matchingName: 'TrucknotuseReport',
          web_use: '',
        },
        {
          matchingName: 'TruckengineoffReport',
          web_use: '',
        },
        {
          matchingName: 'VehicleperformanceconcludeReport',
          web_use: '',
        },
        {
          matchingName: 'drivingSummary',
          web_use: '',
        },
      ],
      reportTripObj: [
        {
          matchingName: 'concreteTrip',
          web_use: '',
        },
        {
          matchingName: 'deliveryReport',
          web_use: 'isupply',
        },
        {
          matchingName: 'weighingHistoryReport',
          web_use: 'isupply',
        },
        {
          matchingName: 'floorWallDelivery',
          web_use: '',
        },
        {
          matchingName: 'distancereport',
          web_use: '',
        },
        {
          matchingName: 'TripsummaryReport',
          web_use: '',
        },
        {
          matchingName: 'DailyReport',
          web_use: '',
        },
        {
          matchingName: 'TripsumbylocationReport',
          web_use: '',
        },
        {
          matchingName: 'TripsumbyvehReport',
          web_use: '',
        },
      ],
      reportPolicyObj: [
        {
          matchingName: 'EventReport',
          web_use: '',
        },
      ],
      reportEnergyObj: [
        {
          matchingName: 'RefuelReport',
          web_use: '',
        },
        {
          matchingName: 'fuelremainrateReport',
          web_use: '',
        },
        {
          matchingName: 'FueluseReport',
          web_use: '',
        },
      ],
      reportCostObj: [
        {
          matchingName: 'gpsVendorPayingSummaryReport',
          web_use: '',
        },
        {
          matchingName: 'partnerPerformReport',
          web_use: '',
        },
        {
          matchingName: 'gpsVendorPayingReport',
          web_use: '',
        },
      ],
      reportMaintenanceDeviceObj: [
        {
          matchingName: 'repairingReport',
          web_use: '',
        },
        {
          matchingName: 'gpsReportLostContact',
          web_use: '',
        },
        {
          matchingName: 'gpsDisconnectOnTrip',
          web_use: '',
        },
        {
          matchingName: 'distance_report_1',
          web_use: '',
        },
      ],
      reportInsureObj: [
        {
          matchingName: 'InsuranceReport',
          web_use: '',
        },
      ],
    }
  }
  componentDidMount() {
    this.setState({
      web_endpoint: process.env.REACT_APP_WEB_ENDPOINT,
    })
  }

  checkSubMenuVisibility = obj => {
    // console.log('obj', obj)
    let showMenu = false
    if (obj.length != 0) {
      let canAccess = obj.map(ele => {
        return checkMenuVisibility(
          ele.web_use,
          this.state.web_endpoint,
          ele.matchingName,
          this.props.profile.permissions,
        )
      })
      if (canAccess.includes(false)) {
        showMenu = true
      }
      //   console.log('Access', canAccess)
      return showMenu
    } else {
      return showMenu
    }
  }

  handleDashboard = key => {
    if (key.length == 1) {
      this.setState({
        textDashboard: false,
      })
    } else {
      this.setState({
        textDashboard: true,
      })
    }
  }
  handleMonitoring = key => {
    if (key.length == 1) {
      this.setState({
        textMonitoring: false,
      })
    } else {
      this.setState({
        textMonitoring: true,
      })
    }
  }
  handleReport = key => {
    if (key.length == 1) {
      this.setState({
        textReport: false,
      })
    } else {
      this.setState({
        textReport: true,
      })
    }
  }
  handleManagement = key => {
    if (key.length == 1) {
      this.setState({
        textManagement: false,
      })
    } else {
      this.setState({
        textManagement: true,
      })
    }
  }
  handleMasterData = key => {
    if (key.length == 1) {
      this.setState({
        textMasterData: false,
      })
    } else {
      this.setState({
        textMasterData: true,
      })
    }
  }
  handleDelivery = key => {
    if (key.length == 1) {
      this.setState({
        textDelivery: false,
      })
    } else {
      this.setState({
        textDelivery: true,
      })
    }
  }
  handleHelp = key => {
    if (key.length == 1) {
      this.setState({
        textHelp: false,
      })
    } else {
      this.setState({
        textHelp: true,
      })
    }
  }
  handleSetting = key => {
    if (key.length == 1) {
      this.setState({
        textSetting: false,
      })
    } else {
      this.setState({
        textSetting: true,
      })
    }
  }

  render() {
    const { permissions } = this.props.profile
    return (
      <WebContentStyled>
        {/* <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: '0px 0px 14px 10px' }}
            />
          </Col>
        </Row> */}
        <div style={{ fontSize: 18, marginBottom: 10, marginLeft: 140 }}>
          Sitemap
        </div>

        <Row>
          <Col span={20} offset={2}>
            {/* Dashboard */}
            {this.checkSubMenuVisibility(this.state.dashboardObj) ? (
              <>
                {this.state.web_endpoint != 'isupply' ? (
                  <Collapse
                    onChange={this.handleDashboard}
                    expandIconPosition="right"
                    bordered={false}
                  >
                    <Panel
                      header={
                        <Row gutter={[8, 0]}>
                          <Col span={2} align="right">
                            <label style={{ fontSize: 16 }}>
                              <LangContext.Consumer>
                                {i18n => i18n.d.dashboard}
                              </LangContext.Consumer>
                              :{' '}
                            </label>
                          </Col>
                          <Col span={22}>
                            {/* การจัดส่งคอนกรีต */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'concreteDeliveryManagerDashboard',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/concrete_delivery_manager">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n =>
                                      i18n.c.concreteDeliveryManagerDashboard
                                    }
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}

                            {/* แผนบันทึกการใช้รถโม่เล็ก */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'smartDispatching',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/smartDispatching">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.s.smartDispatching}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}

                            {/* แดชบอร์ดภาพรวมการขนส่งคอนกรีต */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'truckstatusdashboard',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/truckstatusdashboard">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.t.truckstatusdashboard}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {/* แดชบอร์ดเเจ้งเตือนเข้าผิดหน่วยงาน */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'notifyTheTruckInCorrectPoint',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/truckincorrectpoint">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n =>
                                      i18n.n.notifyTheTruckInCorrectPoint
                                    }
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {/* ความปลอดภัย */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'SafetyDashboard',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/safety_dashboard">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.s.safetyDashboard}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {/* ประสิทธิภาพ GPS */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'gpsPerformanceDashboard',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/gps_performance_dashboard">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.g.gpsPerformanceDashboard}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {/* monitor gps */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'monitorGPS',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/monitor-gps">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.m.monitorGPS}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                          </Col>
                        </Row>
                      }
                      key="Dashboard"
                    >
                      <Row>
                        <Col span={22} offset={2}>
                          {/* การขนส่งวัตถุดิบ */}
                          {checkMenuVisibility(
                            'isupply',
                            this.state.web_endpoint,
                            'deliveryDashboard',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/isupply-dashboard">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.d.deliveryDashboard}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* ประสิทธิภาพการขนส่งวัตถุดิบ */}
                          {checkMenuVisibility(
                            'isupply',
                            this.state.web_endpoint,
                            'transportationPerformanceDashboard',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/isupply-overview-performance-dashboard">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n =>
                                    i18n.t.transportationPerformanceDashboard
                                  }
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* การติดตามวัตถุดิบ */}
                          {checkMenuVisibility(
                            'isupply',
                            this.state.web_endpoint,
                            'theStatusOfVehiclesThatReceiveDailyJobs',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/isupply-status-vehicles-receive-daily-jobs">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n =>
                                    i18n.t
                                      .theStatusOfVehiclesThatReceiveDailyJobs
                                  }
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* การจัดการคิวรถ */}
                          {checkMenuVisibility(
                            'isupply',
                            this.state.web_endpoint,
                            'isupplyManageQueue',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/isupply-manage-queue">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.i.isupplyManageQueue}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* สถานะขนส่งพื้น-ผนัง */}
                          {checkMenuVisibility(
                            '',
                            this.state.web_endpoint,
                            'precastDashboard',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/precast-dashboard">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.p.precastDashboard}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* ติดตามสถานะการจัดส่งทริป */}
                          {checkMenuVisibility(
                            '',
                            this.state.web_endpoint,
                            'multiDrop',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/multi_drop">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.m.multiDrop}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* <br />
                          <br /> */}
                          {/* การแจ้งเตือนการฝ่าฝืน */}
                          {checkMenuVisibility(
                            '',
                            this.state.web_endpoint,
                            'alert_alarm_dashboard',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/alert_alarm">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.a.alertAlarm}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* ภาพรวมพิกัดการฝ่าฝืน */}
                          {checkMenuVisibility(
                            '',
                            this.state.web_endpoint,
                            'eventMap',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/event_map">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.e.eventMap}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* สรุปการใช้พาหนะ */}
                          {checkMenuVisibility(
                            '',
                            this.state.web_endpoint,
                            'driverKpiDashboard',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/driver-kpi">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.d.driverKpiDashboard}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                ) : (
                  <>
                    <Card className="web__content__card__style">
                      <Row gutter={[8, 0]}>
                        <Col span={2} align="right">
                          <label style={{ fontSize: 16, color: 'black' }}>
                            <LangContext.Consumer>
                              {i18n => i18n.d.dashboard}
                            </LangContext.Consumer>
                            :{' '}
                          </label>
                        </Col>
                        <Col span={22}>
                          {/* ความปลอดภัย */}
                          {checkMenuVisibility(
                            '',
                            this.state.web_endpoint,
                            'SafetyDashboard',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/safety_dashboard">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.s.safetyDashboard}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* การขนส่งวัตถุดิบ */}
                          {checkMenuVisibility(
                            'isupply',
                            this.state.web_endpoint,
                            'deliveryDashboard',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/isupply-dashboard">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.d.deliveryDashboard}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* ประสิทธิภาพการขนส่งวัตถุดิบ */}
                          {checkMenuVisibility(
                            'isupply',
                            this.state.web_endpoint,
                            'transportationPerformanceDashboard',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/isupply-overview-performance-dashboard">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n =>
                                    i18n.t.transportationPerformanceDashboard
                                  }
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* การติดตามวัตถุดิบ */}
                          {checkMenuVisibility(
                            'isupply',
                            this.state.web_endpoint,
                            'theStatusOfVehiclesThatReceiveDailyJobs',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/isupply-status-vehicles-receive-daily-jobs">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n =>
                                    i18n.t
                                      .theStatusOfVehiclesThatReceiveDailyJobs
                                  }
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* การจัดการคิวรถ */}
                          {checkMenuVisibility(
                            'isupply',
                            this.state.web_endpoint,
                            'isupplyManageQueue',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/isupply-manage-queue">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.i.isupplyManageQueue}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            {/* Monitoring */}
            {this.checkSubMenuVisibility(this.state.monitoringObj) ? (
              <>
                {this.state.web_endpoint != 'isupply' ? (
                  <Collapse
                    onChange={this.handleMonitoring}
                    expandIconPosition="right"
                    bordered={false}
                    //   style={{ marginBottom: 10 }}
                  >
                    <Panel
                      header={
                        <Row gutter={[8, 0]}>
                          <Col span={2} align="right">
                            <label style={{ fontSize: 16 }}>
                              <LangContext.Consumer>
                                {i18n => i18n.m.monitoring}
                              </LangContext.Consumer>
                              :{' '}
                            </label>
                          </Col>
                          <Col span={22}>
                            {/* ติดตามยานพาหนะ */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'tracking',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/tracking">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.t.tracking}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {this.state.textMonitoring ? (
                              <span>
                                {/* เส้นทางเดินทางย้อนหลัง */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'replay',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Link to="/replay">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.r.replay}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                )}
                                {/* สรุปแดชบอร์ด */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'summary_dashboard',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Link to="/summary_dashboard">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.s.SafetyDashboard}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                )}
                                {/* booking_overview_dashboard */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'booking_overview_dashboard',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Link to="/booking_overview">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.b.bookingOverview}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                )}
                                {/* cashing_overview_dahsboar */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'cashing_overview_dahsboar',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Link to="/cashing_overview">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.e.earningOverview}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                )}
                                {/* Honey Toast SubMenu */}
                                {this.checkSubMenuVisibility(
                                  this.state.monitoringMDVRObj,
                                ) ? (
                                  <label className="web__content__submenu__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.h.honeyToast}
                                    </LangContext.Consumer>
                                  </label>
                                ) : (
                                  <></>
                                )}

                                {/* MDVR Submenu */}
                                {this.checkSubMenuVisibility(
                                  this.state.monitoringMDVRObj,
                                ) ? (
                                  <label className="web__content__submenu__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.m.mdvr}
                                    </LangContext.Consumer>
                                  </label>
                                ) : (
                                  <></>
                                )}
                              </span>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      }
                      key="Monitoring"
                    >
                      <Row gutter={[0, 16]}>
                        {/* เส้นทางเดินทางย้อนหลัง */}
                        {checkMenuVisibility(
                          '',
                          this.state.web_endpoint,
                          'replay',
                          permissions,
                        ) ? (
                          <></>
                        ) : (
                          <Col span={22} offset={2}>
                            <Link to="/replay">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.r.replay}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          </Col>
                        )}

                        {/* สรุปแดชบอร์ด */}
                        {checkMenuVisibility(
                          '',
                          this.state.web_endpoint,
                          'summary_dashboard',
                          permissions,
                        ) ? (
                          <></>
                        ) : (
                          <Col span={22} offset={2}>
                            <Link to="/summary_dashboard">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.s.SafetyDashboard}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          </Col>
                        )}
                        {/* booking_overview_dashboard */}
                        {checkMenuVisibility(
                          '',
                          this.state.web_endpoint,
                          'booking_overview_dashboard',
                          permissions,
                        ) ? (
                          <></>
                        ) : (
                          <Col span={22} offset={2}>
                            <Link to="/booking_overview">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.b.bookingOverview}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          </Col>
                        )}
                        {/* สรุปแดชบอร์ด */}
                        {checkMenuVisibility(
                          '',
                          this.state.web_endpoint,
                          'cashing_overview_dahsboar',
                          permissions,
                        ) ? (
                          <></>
                        ) : (
                          <Col span={22} offset={2}>
                            <Link to="/cashing_overview">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.e.earningOverview}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          </Col>
                        )}

                        {/* Honey Toast SubMenu */}

                        <Col span={22} offset={2}>
                          <span>
                            <Row gutter={[0, 16]}>
                              {this.checkSubMenuVisibility(
                                this.state.monitoringHoneyToastObj,
                              ) ? (
                                <span>
                                  <Col span={3} align="right">
                                    <label className="web__content__menuitem__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.h.honeyToast}
                                      </LangContext.Consumer>
                                      :{' '}
                                    </label>
                                  </Col>
                                  <Col span={21}>
                                    {/* วิดีโอเรียลไทม์จาก Honey Toast */}
                                    {checkMenuVisibility(
                                      '',
                                      this.state.web_endpoint,
                                      'honeytoastlivestream',
                                      permissions,
                                    ) ? (
                                      <></>
                                    ) : (
                                      <Link to="/honeytoastlivestream">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n =>
                                              i18n.h.honeytoastlivestream
                                            }
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    )}
                                  </Col>
                                  <Col span={21} offset={3}>
                                    {/* วิดีโอย้อนหลัง Honey Toast */}
                                    {checkMenuVisibility(
                                      '',
                                      this.state.web_endpoint,
                                      'honeytoastplayback',
                                      permissions,
                                    ) ? (
                                      <></>
                                    ) : (
                                      <Link to="/honeytoastplayback">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.h.honeytoastplayback}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    )}
                                  </Col>
                                  <Col span={21} offset={3}>
                                    {/* ดาวน์โหลดวิดีโอ Honey Toast */}
                                    {checkMenuVisibility(
                                      '',
                                      this.state.web_endpoint,
                                      'honeytoastdownloadfile',
                                      permissions,
                                    ) ? (
                                      <></>
                                    ) : (
                                      <Link to="/honeytoastdownloadfile">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n =>
                                              i18n.h.honeytoastdownloadfile
                                            }
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    )}
                                  </Col>
                                </span>
                              ) : (
                                <></>
                              )}
                              {this.checkSubMenuVisibility(
                                this.state.monitoringMDVRObj,
                              ) ? (
                                <span>
                                  <Col span={3} align="right">
                                    {/* MDVR Submenu */}
                                    <label className="web__content__menuitem__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.mdvr}
                                      </LangContext.Consumer>
                                      :{' '}
                                    </label>
                                  </Col>
                                  <Col span={21}>
                                    {/* วิดีโอเรียลไทม์ MDVR */}
                                    {checkMenuVisibility(
                                      '',
                                      this.state.web_endpoint,
                                      'video',
                                      permissions,
                                    ) ? (
                                      <></>
                                    ) : (
                                      <Link to="/video">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.v.video}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    )}
                                  </Col>
                                  <Col span={21} offset={3}>
                                    {/* ดาวน์โหลดวิดีโอย้อนหลัง MDVR  */}
                                    {checkMenuVisibility(
                                      '',
                                      this.state.web_endpoint,
                                      'mdvrdownloadfile',
                                      permissions,
                                    ) ? (
                                      <></>
                                    ) : (
                                      <Link to="/mdvrdownloadfile">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.d.mdvrdownloadfile}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    )}
                                  </Col>
                                </span>
                              ) : (
                                <></>
                              )}
                            </Row>
                          </span>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                ) : (
                  <>
                    <Card className="web__content__card__style">
                      <Row gutter={[8, 0]}>
                        <Col span={2} align="right">
                          <label style={{ fontSize: 16, color: 'black' }}>
                            <LangContext.Consumer>
                              {i18n => i18n.m.monitoring}
                            </LangContext.Consumer>
                            :{' '}
                          </label>
                        </Col>
                        <Col span={22}>
                          {/* ติดตามยานพาหนะ */}
                          {checkMenuVisibility(
                            '',
                            this.state.web_endpoint,
                            'tracking',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/tracking">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.t.tracking}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                          {/* เส้นทางเดินทางย้อนหลัง */}
                          {checkMenuVisibility(
                            '',
                            this.state.web_endpoint,
                            'replay',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Link to="/replay">
                              <label className="web__content__header__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.r.replay}
                                </LangContext.Consumer>
                              </label>{' '}
                            </Link>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            {/* Report */}
            {this.checkSubMenuVisibility(this.state.reportObj) ? (
              <Collapse
                onChange={this.handleReport}
                expandIconPosition="right"
                bordered={false}
                //   style={{ marginBottom: 10 }}
              >
                <Panel
                  header={
                    <Row gutter={[8, 0]}>
                      <Col span={2} align="right">
                        <label style={{ fontSize: 16 }}>
                          <LangContext.Consumer>
                            {i18n => i18n.r.report}
                          </LangContext.Consumer>
                          :{' '}
                        </label>
                      </Col>
                      <Col span={22}>
                        {/* schedulereport */}
                        {checkMenuVisibility(
                          '',
                          this.state.web_endpoint,
                          'schedulereport',
                          permissions,
                        ) ? (
                          <></>
                        ) : (
                          <Link to="/schedulereport">
                            <label className="web__content__header__style">
                              <LangContext.Consumer>
                                {i18n => i18n.s.schedulereport}
                              </LangContext.Consumer>
                            </label>{' '}
                          </Link>
                        )}
                        {this.state.textReport ? (
                          <span>
                            {/* ทั่วไป */}
                            {this.checkSubMenuVisibility(
                              this.state.reportGeneralObj,
                            ) ? (
                              <>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.g.general_report_menu}
                                  </LangContext.Consumer>
                                </label>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* พฤติกรรม */}
                            {this.checkSubMenuVisibility(
                              this.state.reportBehaviorObj,
                            ) ? (
                              <>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.b.behavior_report_menu}
                                  </LangContext.Consumer>
                                </label>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* Alert และ Alarm */}
                            {this.checkSubMenuVisibility(
                              this.state.reportAlertAndAlarmObj,
                            ) ? (
                              <>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.a.alertAndAlarm}
                                  </LangContext.Consumer>
                                </label>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* ประสิทธิภาพ */}
                            {this.checkSubMenuVisibility(
                              this.state.reportEfficiencyObj,
                            ) ? (
                              <>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.e.efficiency_report_menu}
                                  </LangContext.Consumer>
                                </label>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* ทริป */}
                            {this.checkSubMenuVisibility(
                              this.state.reportTripObj,
                            ) ? (
                              <>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.t.trip_report_menu}
                                  </LangContext.Consumer>
                                </label>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* นโยบาย */}
                            {this.checkSubMenuVisibility(
                              this.state.reportPolicyObj,
                            ) ? (
                              <>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.policy}
                                  </LangContext.Consumer>
                                </label>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* พลังงาน */}
                            {this.checkSubMenuVisibility(
                              this.state.reportEnergyObj,
                            ) ? (
                              <>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.e.energy_report_menu}
                                  </LangContext.Consumer>
                                </label>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* ค่าใช้จ่าย */}
                            {this.checkSubMenuVisibility(
                              this.state.reportCostObj,
                            ) ? (
                              <>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.t.theCost}
                                  </LangContext.Consumer>
                                </label>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* การจัดการอุปกรณ์ */}
                            {this.checkSubMenuVisibility(
                              this.state.reportMaintenanceDeviceObj,
                            ) ? (
                              <>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n =>
                                      i18n.m.maintenancedevice_report_menu
                                    }
                                  </LangContext.Consumer>
                                </label>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* ประกันภัย */}
                            {this.checkSubMenuVisibility(
                              this.state.reportInsureObj,
                            ) ? (
                              <>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.i.insure_report_menu}
                                  </LangContext.Consumer>
                                </label>
                              </>
                            ) : (
                              <></>
                            )}
                          </span>
                        ) : (
                          <>
                            {this.state.web_endpoint == 'isupply' ? (
                              <>
                                <Row>
                                  <Col span={1}>
                                    {/* ทริป */}
                                    <label className="web__content__submenu__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.trip_report_menu}
                                      </LangContext.Consumer>
                                      :
                                    </label>
                                  </Col>
                                  {/* การขนส่งวัตถุดิบ */}
                                  {checkMenuVisibility(
                                    'isupply',
                                    this.state.web_endpoint,
                                    'deliveryReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={23}>
                                      <Link to="/isupply-delivery-report">
                                        <label
                                          className="web__content__header__style"
                                          //   style={{ marginLeft: 10 }}
                                        >
                                          <LangContext.Consumer>
                                            {i18n => i18n.d.deliveryReport}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    </Col>
                                  )}
                                  <br />
                                  <br />
                                  {/* ประวัติการชั่งน้ำหนัก */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'weighingHistoryReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={23} offset={1}>
                                      <Link to="/isupply-weighing-history-report">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n =>
                                              i18n.w.weighingHistoryReport
                                            }
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    </Col>
                                  )}
                                </Row>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  }
                  key="Report"
                >
                  <Row gutter={[8, 16]}>
                    <Col span={22} offset={2}>
                      <span>
                        <Row gutter={[0, 16]}>
                          {/* ทั่วไป */}
                          {this.checkSubMenuVisibility(
                            this.state.reportGeneralObj,
                          ) ? (
                            <span>
                              <Col span={3} align="right">
                                <label className="web__content__menuitem__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.g.general_report_menu}
                                  </LangContext.Consumer>
                                  :{' '}
                                </label>
                              </Col>
                              <Col span={21}>
                                {/* ที่อยู่ปัจจุบันของพาหนะ */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'TruckcurrentlocationReport',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Link to="/report/truckcurrentlocation">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.t.TruckcurrentlocationReport
                                        }
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                )}
                              </Col>

                              {/* จำนวนวันที่รถวิ่งงาน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'NumberofdaysworkedReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/numberofdaysworked">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.n.NumberofdaysworkedReport
                                        }
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่สามารถตรวจสอบได้ว่าในแต่ละเดือนรถมีการใช้งานกี่วัน
                                        โดยระบุเงื่อนไขระยะทางขั้นต่ำต่อวัน
                                      </label>
                                    </label>{' '}
                                  </Link>{' '}
                                </Col>
                              )}

                              {/* สรุประยะทางการวิ่งประจำวัน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'DrivingdistanceReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/drivingdistance">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.d.DrivingdistanceReport}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่สามารถสรุประยะทางการวิ่งงานของรถออกมาเป็นรายงวันได้ว่าวิ่งไปทั้งหมดเท่าไหร่
                                      </label>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* ข้อมูลพนักงานขับขี่ */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'DriverinformationReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/driverinformation">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.d.DriverinformationReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>{' '}
                                </Col>
                              )}

                              {/* ใบขับขี่หมดอายุ */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'theDriverIsIicenseReportHasExpired',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/alertdriverlicense">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.t
                                            .theDriverIsIicenseReportHasExpired
                                        }
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่สามารถดึงข้อมูลที่บันทึกวันหมดอายุใบขับขี่ของคนขับมาตรวจสอบหรือแจ้งเตือนได้
                                      </label>
                                    </label>
                                  </Link>
                                </Col>
                              )}

                              {/* LoginhistoryReport */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'LoginhistoryReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/loginhistory">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.l.LoginhistoryReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* LoginhistoryReport */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'GpsstatusReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/gpsstatus">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.g.GpsstatusReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* TrackingdataReport */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'TrackingdataReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/trackingdata">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.TrackingdataReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                          {/* พฤติกรรม */}
                          {this.checkSubMenuVisibility(
                            this.state.reportBehaviorObj,
                          ) ? (
                            <span>
                              <Col span={3} align="right">
                                <label className="web__content__menuitem__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.b.behavior_report_menu}
                                  </LangContext.Consumer>
                                  :{' '}
                                </label>
                              </Col>
                              <Col span={21}>
                                {/* พฤติกรรมการขับขี่รายวัน */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'dailyDrivingBehaviorReport',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Link to="/report/drivingbehavior">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.d.dailyDrivingBehaviorReport
                                        }
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                )}
                              </Col>

                              {/* พฤติกรรมการรูดบัตรก่อนขับขี่รายวัน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'reportCardSwipeBehaviorBeforeDrivingDaily',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/drivinglicensescanbehavior">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.r
                                            .reportCardSwipeBehaviorBeforeDrivingDaily
                                        }
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่แสดงจำนวนครั้งของการรูดใบขับขี่แต่ละวันได้ว่ามีการูดเข้ามากี่ครั้ง
                                      </label>
                                    </label>
                                  </Link>{' '}
                                </Col>
                              )}

                              {/* พฤติกรรมการขับขี่ไม่ปลอดภัย */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'RiskbehaviourReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/riskbehaviour">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.r.RiskbehaviourReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* การรูดใบขับขี่ */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'DrivinglicensescanlogReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/drivinglicensescanlog">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.d.DrivinglicensescanlogReport
                                        }
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่แสดงข้อมูลว่าพนักงานรูดบัตามาชื่ออะไร
                                        เลขบัตรอะไรหรือตำแหน่งที่มีการรูดบัตรคือที่ไหน
                                      </label>
                                    </label>
                                  </Link>{' '}
                                </Col>
                              )}

                              {/* บันทึกการขับขี่ */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'DrivinglogReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/drivinglog">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.d.DrivinglogReport}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่แสดงข้อมูล Log ทั้งหมดของ
                                        GPS ที่ส่งเขาและแสดงข้อมูลในรูปแบบ Excel
                                        โดยแสดงความเร็ว,สถานที่,ค่าน้ำมันคงเหลือ
                                      </label>
                                    </label>
                                  </Link>
                                </Col>
                              )}

                              {/* จุดจอดพาหนะ */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'ParkingReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/parking">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.p.ParkingReport}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่แสดงจุดที่รถมีการจอดมากกว่า
                                        3 นาทีว่าจอดที่ไหน ระยะเวลานานเท่าไหร่
                                      </label>
                                    </label>
                                  </Link>
                                </Col>
                              )}

                              {/* จำนวนครั้งการจอดรถนอกพื้นที่ */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'reportSummarizingTheNumberOfOffSiteParking',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/parkingareaoutside">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.r
                                            .reportSummarizingTheNumberOfOffSiteParking
                                        }
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* พาหนะเข้าพื้นที่ */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'VehicleinareaReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/vehicleinarea">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.v.VehicleinareaReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}
                              {/* พาหนะวิ่งงานเกินเวลาที่กำหนด */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'DrivingoverlimitReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/drivingoverlimit">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.d.DrivingoverlimitReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}
                              {/* การใช้งานพาหนะ */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'TruckusageReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/truckusage">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.TruckusageReport}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานแสดงข้อมูลว่าแต่ละวันรถมีการติดเครื่องยนต์ครั้งแรกหรือดับเครื่องครั้งสุดท้ายของวันไปตอนไหนและแสดงระยะทางที่ใช้งานรถของวัน
                                      </label>
                                    </label>
                                  </Link>
                                </Col>
                              )}
                              {/* เสี่ยงหลับใน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'DozeoffriskReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/dozeoffrisk">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.d.DozeoffriskReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* สรุปสถานะพาหนะรายวัน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'dailyCarStatusReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/separatevehiclestatus">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.d.dailyCarStatusReport}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานแสดงข้อมูลแยกสถานะของการใช้งานรถว่ามีการติดเครื่อง,วิ่ง,หรือจอดดับเครื่อง
                                        กี่นาทีหรือชั่วโมงต่อวัน
                                      </label>
                                    </label>
                                  </Link>
                                </Col>
                              )}

                              {/* สรุปความเร็วเกินกำหนด */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'SpeedoverlimitsummaryReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/speedoverlimitsummary">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.s.SpeedoverlimitsummaryReport
                                        }
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่สามารถตรวจสอบความเร็วเกินที่ทาง
                                        CPAC
                                        กำหนดไว้ได้ว่าคนขับมีการใช้งานไปช่วงไหนบ้าง
                                      </label>
                                    </label>
                                  </Link>
                                </Col>
                              )}

                              {/* สรุปรายงาน KPI */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'SummaryKPIReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/summarykpi">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.s.SummaryKPIReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}
                              {/* สรุปการเดินทางแยกประเภทตามการเปิด-ปิดเครื่องยนต์ */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'TripbyengineoffonReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/tripbyengineoffon">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.TripbyengineoffonReport}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่แสดงข้อมูลการใช้งานรถจุดต่อจุดจากเงื่อนไขที่มีการจอดจากการดับเครื่องยนต์และรถมีการหยุดกับที่
                                      </label>
                                    </label>
                                  </Link>
                                </Col>
                              )}
                            </span>
                          ) : (
                            <></>
                          )}

                          {/* Alert และ Alarm */}
                          {this.checkSubMenuVisibility(
                            this.state.reportAlertAndAlarmObj,
                          ) ? (
                            <span>
                              <Col span={3} align="right">
                                <label className="web__content__menuitem__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.a.alertAndAlarm}
                                  </LangContext.Consumer>
                                  :{' '}
                                </label>
                              </Col>
                              <Col span={21}>
                                {/* สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'BehaviordrivingReport',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Link to="/report/behaviordriving">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.b.BehaviordrivingReport}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่สามารถดึงเรื่องของความปลอดภัยโดยแสดงข้อมูลแยกเจ้าหน้าที่บริการและจัดส่ง
                                      </label>
                                    </label>
                                  </Link>
                                )}
                              </Col>

                              {/* สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง แต่ละผู้รับเหมา */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'BehaviordrivingbycompanyReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/behaviordrivingbycompany">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.b.BehaviordrivingbycompanyReport
                                        }
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่สามารถดึงเรื่องของความปลอดภัยโดยแสดงข้อมูลแยกเจ้าหน้าที่บริการและจัดส่ง
                                        แต่ละผู้รับเหมา
                                      </label>
                                    </label>
                                  </Link>{' '}
                                </Col>
                              )}

                              {/* รูปแบบการสรุปคะแนน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'ScoresummaryReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/scoresummary">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.s.ScoresummaryReport}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่แสดงข้อมูลคะนของรายงานความปลอดภัยตามเงื่อนไขตามที่
                                        CPAC กำหนด
                                      </label>
                                    </label>
                                  </Link>
                                </Col>
                              )}

                              {/* ความปลอดภัยพาหนะ */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'SafetyEventReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/safety_event">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.e.SafetyEventReport}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่แสดงข้อมูลเรื่องของความปลอดภัยในรูปแบบ
                                        Excel เช่น ความเร็วเกิน
                                        จอดไหล่ทางหรือจอดนอกจุด
                                      </label>
                                    </label>
                                  </Link>{' '}
                                </Col>
                              )}

                              {/* Alert และ Alarm แยกตามทะเบียนรายวัน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'alarmAndAlertReportClassifiedByDailyCarRegistration',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/alarmalertbyplateno">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.a
                                            .alarmAndAlertReportClassifiedByDailyCarRegistration
                                        }
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* Alert และ Alarm แยกตามพนักงานขับขี่รายวัน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'DailyAlarmAndAlertReportByDriver',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/alarmalertbydriver">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.d
                                            .DailyAlarmAndAlertReportByDriver
                                        }
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* Alert และ Alarm แยกตามทะเบียน รายปี */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'alarmAndAlertReportClassifiedByYearlyCarRegistration',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/alarmalertbyplatenoonyear">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.a
                                            .alarmAndAlertReportClassifiedByYearlyCarRegistration
                                        }
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* ความถี่ Alarm แยกตามพนักงานขับขี่ รายวัน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'reportAlarmFrequencyClassifiedByDailyDriver',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/alarmalertfrequencybydriver">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.r
                                            .reportAlarmFrequencyClassifiedByDailyDriver
                                        }
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}
                              {/* Alert 5 อันดับแรกของแต่ละประเภทแบ่งตามช่วงเวลารายเดือน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/alarmalertbydurationtime">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.t
                                            .top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod
                                        }
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}
                            </span>
                          ) : (
                            <></>
                          )}

                          {/* ประสิทธิภาพ */}
                          {this.checkSubMenuVisibility(
                            this.state.reportEfficiencyObj,
                          ) ? (
                            <span>
                              <Col span={3} align="right">
                                <label className="web__content__menuitem__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.e.efficiency_report_menu}
                                  </LangContext.Consumer>
                                  :{' '}
                                </label>
                              </Col>
                              <Col span={21}>
                                {/* พาหนะติดเครื่อง */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'TruckengineonReport',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Link to="/report/truckengineon">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.TruckengineonReport}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่แสดงข้อมูลได้ว่ารถมีการติดเครื่องยนต์ในช่วงเวลาที่ระบุหรือไม่
                                      </label>
                                    </label>
                                  </Link>
                                )}
                              </Col>

                              {/* จำนวนวันที่พาหนะไม่ได้วิ่งงาน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'TrucknotuseReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/trucknotuse">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.TrucknotuseReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>{' '}
                                </Col>
                              )}

                              {/* จำนวนวันที่พาหนะไม่ติดเครื่อง */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'TruckengineoffReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/truckengineoff">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.TruckengineoffReport}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* สรุปประสิทธิภาพพาหนะรายคัน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'VehicleperformanceconcludeReport',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/vehicleperformanceconclude">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.v
                                            .VehicleperformanceconcludeReport
                                        }
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่แสดงข้อมูลเวลาของการใช้งานรถตามสถานะต่างๆ
                                      </label>
                                    </label>
                                  </Link>{' '}
                                </Col>
                              )}

                              {/* ภาพรวมสรุปการขับขี่รายคัน */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'drivingSummary',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/report/driving-summary">
                                    <label className="web__content__subheader__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.d.drivingSummary}
                                      </LangContext.Consumer>
                                      :<br />
                                      <label className="web__hv__content">
                                        เป็นรายงานที่แสดงภาพรวมการใช้งานรถทั้งหมดรวมเรื่องของความปลอดภัย
                                      </label>
                                    </label>
                                  </Link>
                                </Col>
                              )}
                            </span>
                          ) : (
                            <></>
                          )}

                          {/* ทริป */}
                          {this.state.web_endpoint == 'isupply' ? (
                            <></>
                          ) : (
                            <>
                              {this.checkSubMenuVisibility(
                                this.state.reportTripObj,
                              ) ? (
                                <span>
                                  <Col span={3} align="right">
                                    <label className="web__content__menuitem__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.trip_report_menu}
                                      </LangContext.Consumer>
                                      :{' '}
                                    </label>
                                  </Col>
                                  <Col span={21}>
                                    {/* การส่งคอนกรีต */}
                                    {checkMenuVisibility(
                                      '',
                                      this.state.web_endpoint,
                                      'concreteTrip',
                                      permissions,
                                    ) ? (
                                      <></>
                                    ) : (
                                      <Link to="/report/rmcconcretetrip">
                                        <label className="web__content__subheader__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.c.concreteTrip}
                                          </LangContext.Consumer>
                                          :<br />
                                          <label className="web__hv__content">
                                            เป็นรายงานสรุปการส่งคอนกรีตแยกตาม
                                            DPของแต่ละคันสามารถดูภาพรวมรายเดือนได้โดยแสดงเวลาของแต่ละสถานะและระยะทางจาก
                                            GPS
                                          </label>
                                        </label>
                                      </Link>
                                    )}
                                  </Col>

                                  {/* การขนส่งวัตถุดิบ */}
                                  {checkMenuVisibility(
                                    'isupply',
                                    this.state.web_endpoint,
                                    'deliveryReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/isupply-delivery-report">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.d.deliveryReport}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>{' '}
                                    </Col>
                                  )}

                                  {/* ประวัติการชั่งน้ำหนัก */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'weighingHistoryReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/isupply-weighing-history-report">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n =>
                                              i18n.w.weighingHistoryReport
                                            }
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    </Col>
                                  )}

                                  {/* จัดส่ง พื้น-ผนัง */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'floorWallDelivery',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/precast-report">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.f.floorWallDelivery}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>{' '}
                                    </Col>
                                  )}

                                  {/* ระยะทางการจัดส่งคอนกรีต */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'distancereport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/distancereport">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.d.distancereport}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    </Col>
                                  )}
                                  {/* สรุปการวิ่งแยกตามทริป */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'TripsummaryReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/tripsummary">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.t.TripsummaryReport}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    </Col>
                                  )}
                                  {/* สรุปการวิ่งประจำวัน */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'DailyReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/daily">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.d.DailyReport}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    </Col>
                                  )}
                                  {/* สรุปจำนวนงานตามสถานที่ */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'TripsumbylocationReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/tripsumbylocation">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n =>
                                              i18n.t.TripsumbylocationReport
                                            }
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    </Col>
                                  )}
                                  {/* สรุปการวิ่งแยกตามพาหนะ */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'TripsumbyvehReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/tripsumbyveh">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.t.TripsumbyvehReport}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    </Col>
                                  )}
                                </span>
                              ) : (
                                <></>
                              )}

                              {/* นโยบาย */}
                              {this.checkSubMenuVisibility(
                                this.state.reportPolicyObj,
                              ) ? (
                                <span>
                                  <Col span={3} align="right">
                                    <label className="web__content__menuitem__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.p.policy}
                                      </LangContext.Consumer>
                                      :{' '}
                                    </label>
                                  </Col>
                                  <Col span={21}>
                                    {/* เหตุการณ์พาหนะรายคัน */}
                                    {checkMenuVisibility(
                                      '',
                                      this.state.web_endpoint,
                                      'EventReport',
                                      permissions,
                                    ) ? (
                                      <></>
                                    ) : (
                                      <Link to="/report/event">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.e.EventReport}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    )}
                                  </Col>
                                </span>
                              ) : (
                                <></>
                              )}

                              {/* พลังงาน */}
                              {this.checkSubMenuVisibility(
                                this.state.reportEnergyObj,
                              ) ? (
                                <span>
                                  <Col span={3} align="right">
                                    <label className="web__content__menuitem__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.e.energy_report_menu}
                                      </LangContext.Consumer>
                                      :{' '}
                                    </label>
                                  </Col>
                                  <Col span={21}>
                                    {/* การเติมน้ำมัน */}
                                    {checkMenuVisibility(
                                      '',
                                      this.state.web_endpoint,
                                      'RefuelReport',
                                      permissions,
                                    ) ? (
                                      <></>
                                    ) : (
                                      <Link to="/report/refuel">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.r.RefuelReport}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    )}
                                  </Col>

                                  {/* น้ำมันคงเหลือ */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'deliveryReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/fuelremainrate">
                                        <label className="web__content__subheader__style">
                                          <LangContext.Consumer>
                                            {i18n =>
                                              i18n.f.fuelremainrateReport
                                            }
                                          </LangContext.Consumer>
                                          :<br />
                                          <label className="web__hv__content">
                                            เป็นรายงานแสดงข้อมูลน้ำมันคงเหลือตามข้อมูลที่
                                            GPS คำนวนได้ของแต่ละวัน
                                          </label>
                                        </label>
                                      </Link>{' '}
                                    </Col>
                                  )}

                                  {/* FueluseReport */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'FueluseReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/fueluse">
                                        <label className="web__content__header__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.f.FueluseReport}
                                          </LangContext.Consumer>
                                        </label>{' '}
                                      </Link>
                                    </Col>
                                  )}
                                </span>
                              ) : (
                                <></>
                              )}

                              {/* ค่าใช้จ่าย */}
                              {this.checkSubMenuVisibility(
                                this.state.reportCostObj,
                              ) ? (
                                <span>
                                  <Col span={3} align="right">
                                    <label className="web__content__menuitem__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.theCost}
                                      </LangContext.Consumer>
                                      :{' '}
                                    </label>
                                  </Col>
                                  <Col span={21}>
                                    {/* สรุปค่าบริการ GPS ทั้งหมด */}
                                    {checkMenuVisibility(
                                      '',
                                      this.state.web_endpoint,
                                      'gpsVendorPayingSummaryReport',
                                      permissions,
                                    ) ? (
                                      <></>
                                    ) : (
                                      <Link to="/report/gpsvendorpayingsummaryreport">
                                        <label className="web__content__subheader__style">
                                          <LangContext.Consumer>
                                            {i18n =>
                                              i18n.g
                                                .gpsVendorPayingSummaryReport
                                            }
                                          </LangContext.Consumer>
                                          :<br />
                                          <label className="web__hv__content">
                                            เป็นรายงานสำหรับการดูข้อมูลสรุปประสิทธิภาพของ
                                            GPS
                                            แต่ละคันเพื่อแสดงสถานะการชำระค่าบริการแต่ละเดือนตามเงื่อนไข
                                          </label>
                                        </label>
                                      </Link>
                                    )}
                                  </Col>

                                  {/* สรุปเรียกเก็บเงินตามผู้รับเหมา */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'partnerPerformReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/partnerperformreport">
                                        <label className="web__content__subheader__style">
                                          <LangContext.Consumer>
                                            {i18n =>
                                              i18n.p.partnerPerformReport
                                            }
                                          </LangContext.Consumer>
                                          :<br />
                                          <label className="web__hv__content">
                                            เป็นรายงานสำหรับสรุปเรียกเก็บเงินตามผู้รับเหมา
                                          </label>
                                        </label>
                                      </Link>{' '}
                                    </Col>
                                  )}

                                  {/* สรุปค่าบริการตาม GPS Vendor */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'gpsVendorPayingReport',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/gpsvendorpayingreport">
                                        <label className="web__content__subheader__style">
                                          <LangContext.Consumer>
                                            {i18n =>
                                              i18n.g.gpsVendorPayingReport
                                            }
                                          </LangContext.Consumer>
                                          :<br />
                                          <label className="web__hv__content">
                                            เป็นรายงานสำหรับสรุปค่าบริการของ GPS
                                            Vendor
                                          </label>
                                        </label>
                                      </Link>
                                    </Col>
                                  )}
                                </span>
                              ) : (
                                <></>
                              )}
                              {/* การจัดการอุปกรณ์ */}
                              {this.checkSubMenuVisibility(
                                this.state.reportMaintenanceDeviceObj,
                              ) ? (
                                <span>
                                  <Col span={3} align="right">
                                    <label className="web__content__menuitem__style">
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.m.maintenancedevice_report_menu
                                        }
                                      </LangContext.Consumer>
                                      :{' '}
                                    </label>
                                  </Col>
                                  <Col span={21}>
                                    {/* การแจ้งซ่อม */}
                                    {checkMenuVisibility(
                                      '',
                                      this.state.web_endpoint,
                                      'repairingReport',
                                      permissions,
                                    ) ? (
                                      <></>
                                    ) : (
                                      <Link to="/report/repairingreport">
                                        <label className="web__content__subheader__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.r.repairingReport}
                                          </LangContext.Consumer>
                                          :<br />
                                          <label className="web__hv__content">
                                            เป็นเมนูสำหรับการดูรายงานสถานะของการแจ้งซ่อมอุปกรณ์
                                            GPS
                                          </label>
                                        </label>
                                      </Link>
                                    )}
                                  </Col>

                                  {/* GPS ขาดการติดต่อ */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'gpsReportLostContact',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/gps-lost-contact">
                                        <label className="web__content__subheader__style">
                                          <LangContext.Consumer>
                                            {i18n =>
                                              i18n.g.gpsReportLostContact
                                            }
                                          </LangContext.Consumer>
                                          :<br />
                                          <label className="web__hv__content">
                                            เป็นเมนูสำหรับดูข้อมูลรายงานรถที่ขาดการติดต่อ
                                          </label>
                                        </label>
                                      </Link>{' '}
                                    </Col>
                                  )}

                                  {/* gpsDisconnectOnTrip */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'gpsDisconnectOnTrip',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="/report/gps-disconnect-on-trip">
                                        <label className="web__content__subheader__style">
                                          <LangContext.Consumer>
                                            {i18n => i18n.g.gpsDisconnectOnTrip}
                                          </LangContext.Consumer>
                                          :<br />
                                          <label className="web__hv__content">
                                            เป็นเมนูสำหรับให้ทาง GPS Vendor
                                            สามารถตรวจสอบได้ว่ารถขาดการติดต่อช่วงวันเวลาใดบ้างที่ทำให้ประสิทธิภาพของ
                                            GPS ไม่ถึงเป้าหมาย
                                          </label>
                                        </label>
                                      </Link>
                                    </Col>
                                  )}
                                  {/* gpsDisconnectOnTrip */}
                                  {checkMenuVisibility(
                                    '',
                                    this.state.web_endpoint,
                                    'distance_report_1',
                                    permissions,
                                  ) ? (
                                    <></>
                                  ) : (
                                    <Col span={21} offset={3}>
                                      <Link to="distancereport">
                                        <label className="web__content__header__style">
                                          ระยะส่ง
                                        </label>{' '}
                                      </Link>
                                    </Col>
                                  )}
                                </span>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </Row>
                      </span>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            ) : (
              <></>
            )}

            {/* Management */}
            {this.checkSubMenuVisibility(this.state.managementObj) ? (
              <Card className="web__content__card__style">
                <Row gutter={[8, 0]}>
                  <Col span={2} align="right">
                    <label style={{ fontSize: 16, color: 'black' }}>
                      <LangContext.Consumer>
                        {i18n => i18n.m.management_menu}
                      </LangContext.Consumer>
                      :{' '}
                    </label>
                  </Col>
                  <Col span={22}>
                    {/* ผู้ใช้งาน */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'user',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/user">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.u.user}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}

                    {/* กลุ่มผู้ใช้งาน */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'userGroup',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/usergroup">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.u.userGroup}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}

                    {/* การมองเห็นข้อมูล */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'dataVisibility',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/datavisibility">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.d.dataVisibility}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}
                    {/* แชร์พาหนะ */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'vehicleSharing',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/vehicle_sharing">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicleSharing}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}
                    {/* เเชร์สถานที่ */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'shareLocation',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/sharelocation">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.s.shareLocation}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}
                    {/* partnerCar */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'partnerCar',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/partner">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.p.partnerCar}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}
                    {/* จัดรถของ ผรม. isupply */}
                    {checkMenuVisibility(
                      'isupply',
                      this.state.web_endpoint,
                      'isupplyManageVehicle',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/isupply-overview-performance-dashboard">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.i.isupplyManageVehicle}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}
                    {/* แผนการจัดส่ง */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'routemaster',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/routemaster">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.r.routemaster}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}
                  </Col>
                </Row>
              </Card>
            ) : (
              <></>
            )}

            {/* MasterData */}
            {this.checkSubMenuVisibility(this.state.masterdataObj) ? (
              <Collapse
                onChange={this.handleMasterData}
                expandIconPosition="right"
                bordered={false}
              >
                <Panel
                  header={
                    <Row gutter={[8, 0]}>
                      <Col span={2} align="right">
                        <label style={{ fontSize: 16 }}>
                          <LangContext.Consumer>
                            {i18n => i18n.m.masterData}
                          </LangContext.Consumer>
                          :{' '}
                        </label>
                      </Col>
                      <Col span={22}>
                        {this.state.textMasterData ? (
                          <>
                            {/* สินค้า */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'product',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/product">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.product}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {/* หน่วยนับ (UOM) */}
                            {checkMenuVisibility(
                              'undefined',
                              this.state.web_endpoint,
                              'uom',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/uom">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.u.uom}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {/* ลูกค้า */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'customer',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/customer">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.c.customer}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {/* Permission */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'permission',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/permission">
                                <label className="web__content__header__style">
                                  Permission
                                </label>{' '}
                              </Link>
                            )}
                            {/* maintenance */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'maintenance',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/maintenance">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.m.maintenance}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {/* refuel */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'reFuelTitle',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/refuel">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.r.reFuelTitle}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}

                            {/* บริษัท */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'company',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/company">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.c.company}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {/* สัญญาการขนส่ง */}

                            {checkMenuVisibility(
                              'isupply',
                              this.state.web_endpoint,
                              'plantMiningContract',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/delivery-contract">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.plantMiningContract}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}

                            {/* Interface CDAS */}

                            {checkMenuVisibility(
                              'isupply',
                              this.state.web_endpoint,
                              'isupplySystemKey',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/interface-key-cdas">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.i.isupplySystemKey}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}

                            {/* สถานที่่ */}
                            {this.checkSubMenuVisibility(
                              this.state.masterdataLocationObj,
                            ) ? (
                              <span>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.l.location}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </span>
                            ) : (
                              <></>
                            )}
                            {/* พาหนะ */}
                            {this.checkSubMenuVisibility(
                              this.state.masterdataVehicleObj,
                            ) ? (
                              <span>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.v.vehicle}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </span>
                            ) : (
                              <></>
                            )}
                            {/* พนักงานขับขี่ */}
                            {this.checkSubMenuVisibility(
                              this.state.masterdataChauffeurObj,
                            ) ? (
                              <span>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.c.chauffeur}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </span>
                            ) : (
                              <></>
                            )}
                            {/* Precast */}
                            {this.checkSubMenuVisibility(
                              this.state.masterdataPrecastObj,
                            ) ? (
                              <span>
                                <label className="web__content__submenu__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.vehicleType.pre_cast}
                                  </LangContext.Consumer>{' '}
                                </label>{' '}
                              </span>
                            ) : (
                              <></>
                            )}

                            {/* วัตถุดิบ */}
                            {this.checkSubMenuVisibility(
                              this.state.masterdataMaterialObj,
                            ) ? (
                              <label className="web__content__submenu__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.m.material}
                                </LangContext.Consumer>
                              </label>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            {/* สินค้า */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'product',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/product">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.product}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            )}
                            {this.state.web_endpoint == 'isupply' ? (
                              <>
                                {' '}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'company',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Link to="/company">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.c.company}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {/* บริษัท */}
                          </>
                        )}
                      </Col>
                    </Row>
                  }
                  key="MasterData"
                >
                  <>
                    <Row gutter={[0, 16]}>
                      {/* หน่วยนับ (UOM) */}
                      {checkMenuVisibility(
                        'undefined',
                        this.state.web_endpoint,
                        'uom',
                        permissions,
                      ) ? (
                        <></>
                      ) : (
                        <Col span={22} offset={2}>
                          <Link to="/uom">
                            <label className="web__content__header__style">
                              <LangContext.Consumer>
                                {i18n => i18n.u.uom}
                              </LangContext.Consumer>
                            </label>{' '}
                          </Link>
                        </Col>
                      )}
                      {/* ลูกค้า */}
                      {checkMenuVisibility(
                        '',
                        this.state.web_endpoint,
                        'customer',
                        permissions,
                      ) ? (
                        <></>
                      ) : (
                        <Col span={22} offset={2}>
                          <Link to="/customer">
                            <label className="web__content__header__style">
                              <LangContext.Consumer>
                                {i18n => i18n.c.customer}
                              </LangContext.Consumer>
                            </label>{' '}
                          </Link>{' '}
                        </Col>
                      )}
                      {/* Permission */}
                      {checkMenuVisibility(
                        '',
                        this.state.web_endpoint,
                        'permission',
                        permissions,
                      ) ? (
                        <></>
                      ) : (
                        <Col span={22} offset={2}>
                          <Link to="/permission">
                            <label className="web__content__header__style">
                              Permission
                            </label>{' '}
                          </Link>
                        </Col>
                      )}
                      {/* maintenance */}
                      {checkMenuVisibility(
                        '',
                        this.state.web_endpoint,
                        'maintenance',
                        permissions,
                      ) ? (
                        <></>
                      ) : (
                        <Col span={22} offset={2}>
                          <Link to="/maintenance">
                            <label className="web__content__header__style">
                              <LangContext.Consumer>
                                {i18n => i18n.m.maintenance}
                              </LangContext.Consumer>
                            </label>{' '}
                          </Link>
                        </Col>
                      )}
                      {/* refuel */}
                      {checkMenuVisibility(
                        '',
                        this.state.web_endpoint,
                        'reFuelTitle',
                        permissions,
                      ) ? (
                        <></>
                      ) : (
                        <Col span={22} offset={2}>
                          <Link to="/refuel">
                            <label className="web__content__header__style">
                              <LangContext.Consumer>
                                {i18n => i18n.r.reFuelTitle}
                              </LangContext.Consumer>
                            </label>{' '}
                          </Link>
                        </Col>
                      )}

                      {this.state.web_endpoint == 'isupply' ? (
                        <></>
                      ) : (
                        <>
                          {/* บริษัท */}
                          {checkMenuVisibility(
                            '',
                            this.state.web_endpoint,
                            'company',
                            permissions,
                          ) ? (
                            <></>
                          ) : (
                            <Col span={22} offset={2}>
                              <Link to="/company">
                                <label className="web__content__header__style">
                                  <LangContext.Consumer>
                                    {i18n => i18n.c.company}
                                  </LangContext.Consumer>
                                </label>{' '}
                              </Link>
                            </Col>
                          )}
                        </>
                      )}

                      {/* สัญญาการขนส่ง */}
                      {checkMenuVisibility(
                        'isupply',
                        this.state.web_endpoint,
                        'plantMiningContract',
                        permissions,
                      ) ? (
                        <></>
                      ) : (
                        <Col span={22} offset={2}>
                          <Link to="/delivery-contract">
                            <label className="web__content__header__style">
                              <LangContext.Consumer>
                                {i18n => i18n.p.plantMiningContract}
                              </LangContext.Consumer>
                            </label>{' '}
                          </Link>
                        </Col>
                      )}

                      {/* Interface CDAS */}
                      {checkMenuVisibility(
                        'isupply',
                        this.state.web_endpoint,
                        'isupplySystemKey',
                        permissions,
                      ) ? (
                        <></>
                      ) : (
                        <Col span={22} offset={2}>
                          <Link to="/interface-key-cdas">
                            <label className="web__content__header__style">
                              <LangContext.Consumer>
                                {i18n => i18n.i.isupplySystemKey}
                              </LangContext.Consumer>
                            </label>{' '}
                          </Link>
                        </Col>
                      )}

                      <Col span={22} offset={2}>
                        <span>
                          <Row gutter={[0, 16]}>
                            <Col span={3} align="right">
                              {/* สถานที่่ */}
                              <label className="web__content__menuitem__style">
                                <LangContext.Consumer>
                                  {i18n => i18n.l.location}
                                </LangContext.Consumer>
                                :{' '}
                              </label>{' '}
                            </Col>

                            {/* สถานที่่ */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'location',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Col span={21}>
                                <Link to="/location">
                                  <label className="web__content__header__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.l.Location}
                                    </LangContext.Consumer>
                                  </label>{' '}
                                </Link>
                              </Col>
                            )}
                            {/* พื้นที่ */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'zone',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Col span={21} offset={3}>
                                <Link to="/zone">
                                  <label className="web__content__header__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.z.zone}
                                    </LangContext.Consumer>
                                  </label>{' '}
                                </Link>
                              </Col>
                            )}
                            {/* พาหนะห้ามเข้าพื้นที่ */}
                            {checkMenuVisibility(
                              'isupply',
                              this.state.web_endpoint,
                              'vehicleBlacklist',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Col span={21} offset={3}>
                                <Link to="/isupply-vehicle-blacklist">
                                  <label className="web__content__header__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.v.vehicleBlacklist}
                                    </LangContext.Consumer>
                                  </label>{' '}
                                </Link>
                              </Col>
                            )}
                            {/* พนักงานห้ามเข้าพื้นที่ */}
                            {checkMenuVisibility(
                              'isupply',
                              this.state.web_endpoint,
                              'driverBlacklist',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Col span={21} offset={3}>
                                <Link to="/isupply-driver-blacklist">
                                  <label className="web__content__header__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.d.driverBlacklist}
                                    </LangContext.Consumer>
                                  </label>{' '}
                                </Link>
                              </Col>
                            )}
                            {/* สถานที่ใกล้เคียง */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'nearByLocation',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Col span={21} offset={3}>
                                <Link to="/nearByLocation">
                                  <label className="web__content__header__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.n.nearByLocation}
                                    </LangContext.Consumer>
                                  </label>{' '}
                                </Link>
                              </Col>
                            )}
                            {/* กลุ่มสถานที่ */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'locationGroup',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Col span={21} offset={3}>
                                <Link to="/locationgroup">
                                  <label className="web__content__header__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.l.locationGroup}
                                    </LangContext.Consumer>
                                  </label>{' '}
                                </Link>
                              </Col>
                            )}
                            {/* กลุ่มหน่วยงาน */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'siteGroup',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Col span={21} offset={3}>
                                <Link to="/sitegroup">
                                  <label className="web__content__header__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.s.siteGroup}
                                    </LangContext.Consumer>
                                  </label>{' '}
                                </Link>
                              </Col>
                            )}
                            {this.checkSubMenuVisibility(
                              this.state.masterdataVehicleObj,
                            ) ? (
                              <span>
                                <Col span={3} align="right">
                                  {/* พาหนะ */}
                                  <label className="web__content__menuitem__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.v.vehicle}
                                    </LangContext.Consumer>
                                    :{' '}
                                  </label>{' '}
                                </Col>

                                {/* ประเภท พาหนะ */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'vehicle_type',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Col span={21}>
                                    <Link to="/vehicle-type">
                                      <label className="web__content__header__style">
                                        <LangContext.Consumer>
                                          {i18n => i18n.v.vehicle_type}
                                        </LangContext.Consumer>
                                      </label>{' '}
                                    </Link>
                                  </Col>
                                )}
                                {/* พาหนะ */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'vehicle',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Col span={21} offset={3}>
                                    <Link to="/vehicle">
                                      <label className="web__content__header__style">
                                        <LangContext.Consumer>
                                          {i18n => i18n.v.vehicle}
                                        </LangContext.Consumer>
                                      </label>{' '}
                                    </Link>
                                  </Col>
                                )}
                                {/* กลุ่มพาหนะ */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'vehicleGroup',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Col span={21} offset={3}>
                                    <Link to="/vehicleGroup">
                                      <label className="web__content__header__style">
                                        <LangContext.Consumer>
                                          {i18n => i18n.v.vehicleGroup}
                                        </LangContext.Consumer>
                                      </label>{' '}
                                    </Link>
                                  </Col>
                                )}
                              </span>
                            ) : (
                              <></>
                            )}
                            {this.checkSubMenuVisibility(
                              this.state.masterdataChauffeurObj,
                            ) ? (
                              <span>
                                <Col span={3} align="right">
                                  {/* พนักงานขับขี่ */}
                                  <label className="web__content__menuitem__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.c.chauffeur}
                                    </LangContext.Consumer>
                                    :{' '}
                                  </label>{' '}
                                </Col>

                                {/* พนักงานขับขี่ */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'chauffeur',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Col span={21}>
                                    <Link to="/deviceuser">
                                      <label className="web__content__header__style">
                                        <LangContext.Consumer>
                                          {i18n => i18n.c.chauffeur}
                                        </LangContext.Consumer>
                                      </label>{' '}
                                    </Link>
                                  </Col>
                                )}
                              </span>
                            ) : (
                              <></>
                            )}
                            {this.checkSubMenuVisibility(
                              this.state.masterdataPrecastObj,
                            ) ? (
                              <span>
                                <Col span={3} align="right">
                                  {/* Precast */}
                                  <label className="web__content__menuitem__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.vehicleType.pre_cast}
                                    </LangContext.Consumer>
                                    :{' '}
                                  </label>{' '}
                                </Col>

                                {/* ข้อมูล เเผนการวิ่งรถ (ผนัง) */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'informationAboutTheCarRunWall',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Col span={21}>
                                    <Link to="/cpac-precast-transportation-information-wall">
                                      <label className="web__content__header__style">
                                        <LangContext.Consumer>
                                          {i18n =>
                                            i18n.i.informationAboutTheCarRunWall
                                          }
                                        </LangContext.Consumer>
                                      </label>{' '}
                                    </Link>
                                  </Col>
                                )}
                                {/* ข้อมูล เเผนการวิ่งรถ (พื้น) */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'informationAboutTheCarRunFloor',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Col span={21} offset={3}>
                                    <Link to="/cpac-precast-transportation-information-floor">
                                      <label className="web__content__header__style">
                                        <LangContext.Consumer>
                                          {i18n =>
                                            i18n.i
                                              .informationAboutTheCarRunFloor
                                          }
                                        </LangContext.Consumer>
                                      </label>{' '}
                                    </Link>
                                  </Col>
                                )}
                              </span>
                            ) : (
                              <></>
                            )}

                            {/* วัตถุดิบ */}
                            {this.checkSubMenuVisibility(
                              this.state.masterdataMaterialObj,
                            ) ? (
                              <span>
                                <Col span={3} align="right">
                                  <label className="web__content__menuitem__style">
                                    <LangContext.Consumer>
                                      {i18n => i18n.m.material}
                                    </LangContext.Consumer>
                                    :{' '}
                                  </label>{' '}
                                </Col>
                                {/* วัตถุดิบ */}
                                {checkMenuVisibility(
                                  'isupply',
                                  this.state.web_endpoint,
                                  'masterDataMatrial',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Col span={21}>
                                    <Link to="/material">
                                      <label className="web__content__header__style">
                                        <LangContext.Consumer>
                                          {i18n => i18n.m.masterDataMatrial}
                                        </LangContext.Consumer>
                                      </label>{' '}
                                    </Link>
                                  </Col>
                                )}
                                {/* วัตถุดิบ เทียบ */}
                                {checkMenuVisibility(
                                  'isupply',
                                  this.state.web_endpoint,
                                  'comparativeRawMaterials',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Col span={21} offset={3}>
                                    <Link to="/sub-material">
                                      <label className="web__content__header__style">
                                        <LangContext.Consumer>
                                          {i18n =>
                                            i18n.c.comparativeRawMaterials
                                          }
                                        </LangContext.Consumer>
                                      </label>{' '}
                                    </Link>
                                  </Col>
                                )}
                                {/* รายการสั่งวัตถุดิบ */}
                                {checkMenuVisibility(
                                  'isupply',
                                  this.state.web_endpoint,
                                  'requestMaterial',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Col span={21} offset={3}>
                                    <Link to="/request-material">
                                      <label className="web__content__header__style">
                                        <LangContext.Consumer>
                                          {i18n => i18n.r.requestMaterial}
                                        </LangContext.Consumer>
                                      </label>{' '}
                                    </Link>
                                  </Col>
                                )}
                              </span>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </span>
                      </Col>
                    </Row>
                  </>
                </Panel>
              </Collapse>
            ) : (
              <></>
            )}

            {/* Delivery */}
            {this.checkSubMenuVisibility(this.state.deliveryObj) ? (
              <Collapse
                onChange={this.handleDelivery}
                expandIconPosition="right"
                bordered={false}
              >
                <Panel
                  header={
                    <Row gutter={[8, 0]}>
                      <Col span={2} align="right">
                        <label style={{ fontSize: 16 }}>
                          <LangContext.Consumer>
                            {i18n => i18n.d.deliver}
                          </LangContext.Consumer>
                          :{' '}
                        </label>
                      </Col>
                      <Col span={22}>
                        {this.state.textDelivery ? (
                          <>
                            {this.checkSubMenuVisibility(
                              this.state.deliveryTripObj,
                            ) ? (
                              <>
                                {/* แดชบอร์ดภาพรวมการขนส่งคอนกรีต */}
                                {checkMenuVisibility(
                                  '',
                                  this.state.web_endpoint,
                                  'isupplyManageQueue',
                                  permissions,
                                ) ? (
                                  <></>
                                ) : (
                                  <Link to="/isupply-manage-queue">
                                    <label className="web__content__header__style">
                                      Isupply Manage Queue
                                    </label>{' '}
                                  </Link>
                                )}
                                {this.checkSubMenuVisibility(
                                  this.state.deliveryTripObj,
                                ) ? (
                                  <>
                                    {/* ทริป */}
                                    <label className="web__content__submenu__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.trip_report_menu}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            {/* แดชบอร์ดภาพรวมการขนส่งคอนกรีต */}
                            {checkMenuVisibility(
                              '',
                              this.state.web_endpoint,
                              'isupplyManageQueue',
                              permissions,
                            ) ? (
                              <></>
                            ) : (
                              <Link to="/isupply-manage-queue">
                                <label className="web__content__header__style">
                                  Isupply Manage Queue
                                </label>{' '}
                              </Link>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  }
                  key="Delivery"
                >
                  <Row gutter={[0, 16]}>
                    <Col span={22} offset={2}>
                      <span>
                        <Row gutter={[0, 16]}>
                          <Col span={3} align="right">
                            {/* ทริป */}
                            <label className="web__content__menuitem__style">
                              <LangContext.Consumer>
                                {i18n => i18n.t.trip_report_menu}
                              </LangContext.Consumer>
                              :{' '}
                            </label>{' '}
                          </Col>

                          {/* ทริป */}
                          {this.checkSubMenuVisibility(
                            this.state.deliveryTripObj,
                          ) ? (
                            <span>
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'trip',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21}>
                                  <Link to="/trip">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.t.trip}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* แผนการจัดส่ง */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'routemaster',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/routemaster">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.r.routemaster}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}

                              {/* delivery_trip */}
                              {checkMenuVisibility(
                                '',
                                this.state.web_endpoint,
                                'delivery_trip',
                                permissions,
                              ) ? (
                                <></>
                              ) : (
                                <Col span={21} offset={3}>
                                  <Link to="/deliverytrip">
                                    <label className="web__content__header__style">
                                      <LangContext.Consumer>
                                        {i18n => i18n.d.deliverytrip}
                                      </LangContext.Consumer>
                                    </label>{' '}
                                  </Link>
                                </Col>
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </span>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            ) : (
              <></>
            )}

            {/* Help */}
            {this.checkSubMenuVisibility(this.state.helpObj) ? (
              <Card className="web__content__card__style">
                <Row gutter={[8, 0]}>
                  <Col span={2} align="right">
                    <label style={{ fontSize: 16, color: 'black' }}>
                      <LangContext.Consumer>
                        {i18n => i18n.h.help_menu}
                      </LangContext.Consumer>
                      :{' '}
                    </label>
                  </Col>
                  <Col span={22}>
                    {/* ขอใบรับรอง */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'requestACertificate',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/cpac-requestcertificate">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.r.requestACertificate}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}

                    {/* แจ้งซ่อมอุปกรณ์ */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'maintenancedevice',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/maintenancedevice">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.m.maintenancedevice}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}
                  </Col>
                </Row>
              </Card>
            ) : (
              <></>
            )}

            {/* Setting */}
            {this.checkSubMenuVisibility(this.state.settingObj) ? (
              <Card className="web__content__card__style">
                <Row gutter={[8, 0]}>
                  <Col span={2} align="right">
                    <label style={{ fontSize: 16, color: 'black' }}>
                      <LangContext.Consumer>
                        {i18n => i18n.s.setting}
                      </LangContext.Consumer>
                      :{' '}
                    </label>
                  </Col>
                  <Col span={22}>
                    {/* cpacPolicy */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'cpacPolicy',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/cpac-policy">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.c.cpacPolicy}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}

                    {/* policy */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'policy',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/policy">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.p.policy}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}

                    {/* linenotify */}
                    {checkMenuVisibility(
                      '',
                      this.state.web_endpoint,
                      'linenotify',
                      permissions,
                    ) ? (
                      <></>
                    ) : (
                      <Link to="/linenotify">
                        <label className="web__content__header__style">
                          <LangContext.Consumer>
                            {i18n => i18n.l.linenotify}
                          </LangContext.Consumer>
                        </label>{' '}
                      </Link>
                    )}
                  </Col>
                </Row>
              </Card>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </WebContentStyled>
    )
  }
}

const mapStateToProps = ({ auth: { profile } }) => ({
  profile,
})

export default connect(mapStateToProps, null)(Index)
