import { createTypes, createAction } from "lib/action";

const LOAD_LINENOTIFY = createTypes("LINENOTIFY", "load");
const CREATE_LINENOTIFY = createTypes("linenotify", "create");
const UPDATE_LINENOTIFY = createTypes("linenotify", "delete");
const GET_LINENOTIFY = createTypes("linenotify", "get");
const CLEAR_LINENOTIFY = createTypes("linenotify", "clear");

const CLEAR_RESPONSE = createTypes("response", "clear");


const loadLineNotify = {
  request: (data, loading) =>
    createAction(LOAD_LINENOTIFY.REQUEST, { data, loading }),
  success: data => createAction(LOAD_LINENOTIFY.SUCCESS, { data }),
  failure: () => createAction(LOAD_LINENOTIFY.FAILURE)
};

const createLineNotify = {
  request: (data, loading, history) =>
    createAction(CREATE_LINENOTIFY.REQUEST, {
      data,
      loading,
      history
    }),
  success: data => createAction(CREATE_LINENOTIFY.SUCCESS, { data }),
  failure: () => createAction(CREATE_LINENOTIFY.FAILURE)
};

const updateLineNotify = {
  request: (data, id, history, loading) =>
    createAction(UPDATE_LINENOTIFY.REQUEST, { data, id, history, loading }),
  success: data => createAction(UPDATE_LINENOTIFY.SUCCESS, { data }),
  failure: () => createAction(UPDATE_LINENOTIFY.FAILURE)
};


const getLineNotifyByID = {
  request: (id,token) => createAction(GET_LINENOTIFY.REQUEST, { id,token }),
  success: data => createAction(GET_LINENOTIFY.SUCCESS, { data }),
  failure: () => createAction(GET_LINENOTIFY.FAILURE)
};





const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE
  };
};

const clearLineNotify = () => {
  return {
    type: CLEAR_LINENOTIFY
  };
};

export {
  LOAD_LINENOTIFY,
  CREATE_LINENOTIFY,
  UPDATE_LINENOTIFY,
  GET_LINENOTIFY,
  loadLineNotify,
  createLineNotify,
  updateLineNotify,
  getLineNotifyByID,
  CLEAR_RESPONSE,
  clearDataResponse,
  clearLineNotify,
  CLEAR_LINENOTIFY
};
