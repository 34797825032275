import { LOAD_TRIP_FOOR, CREATE_TRIP_FOOR, REMOVE_LISTS } from './actions'

const initialState = {
  data_request_material: [],
  lists: [],
  loading: false,
  check: true,
  check_create: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_TRIP_FOOR.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        loading: loading,
      }

    case LOAD_TRIP_FOOR.SUCCESS:
      const { page, pageSize } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * pageSize
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case CREATE_TRIP_FOOR.REQUEST:
      return {
        ...state,
        check_create: true,
      }

    case CREATE_TRIP_FOOR.SUCCESS:
      return {
        ...state,
        loading: false,
        check_create: false,
      }

    default:
      return state
  }
}
