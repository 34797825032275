import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import LangContext, { i18n } from "modules/shared/context/langContext";
import {
    Button,
    Form,
    Input,
    Row,
    Col,
    Spin,
    Select,
    Modal
} from "antd";

//import { checkPermissions } from "../../../../lib/helper";
import { StyledSearchForm } from "../../../../styled/common-styled";
import FormDivider from "../../../shared/components/FormDivider";
import AddUom from "modules/master-data/uom/components/AddForms";
import axios from "axios";

const FormItem = Form.Item;
const { TextArea } = Input;
const InputGroup = Input.Group;
const Option = Select.Option;

export default class Forms extends Component {
    state = {
        name: "",
        code: "",
        description: "",
        uom_id: "",
        uomLoading: false,
        uom: [],
        visible: false
    };
    componentDidMount() {
        this.getUom();
    }



    shouldComponentUpdate(nextProps, nextState) {
        const { product } = nextProps;
        const { dataProduct } = product;

        if (
            this.props.product.check_product !==
            nextProps.product.check_product
        ) {
            this.setState({
                name: dataProduct.name,
                code: dataProduct.code,
                description: dataProduct.description,
                uom_id: dataProduct.uom_id,
            });

        }
        return true;
    }


    showModal = (type) => {
        this.setState({
            visible: true,
        });
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    };




    getUom = () => {
        let self = this;
        let company_id = this.props.auth.profile.company_id;
        let token = this.props.auth.accessToken;
        axios
            .post(
                `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getuom`,
                {
                    company_id,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then(function (response) {
                if (typeof response.data.data !== "undefined") {
                    let uom = response.data.data.map((d) => {
                        return {
                            id: d.id,
                            name: d.name,
                        };
                    });
                    self.setState({ uom });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    createUom = (values) => {

        let company_id = this.props.auth.profile.company_id;
        let token = this.props.auth.accessToken;
        const self = this;
        this.setState({ uomLoading: true });
        axios
            .post(
                `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/uom/create`,
                {
                    name: values.name,
                    description: values.description,
                    company_id
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then(function (res) {

                if (res.data.status === "success") {
                    self.getUom();
                    self.setState({
                        visible: false,
                        uom_id: res.data.id,
                    });
                } else {

                    Modal.error({
                        content: (
                            i18n[self.props.auth.lang]["f"]["fail"]
                        ),
                    });

                }

                self.setState({ uomLoading: false });

            })
            .catch(function (error) {
                console.log(error);
            });



    };



    render() {
        const { product, onSubmit, auth, id } = this.props;
        const { productLoading } = product;
        const { uom, name, description, code, uom_id } = this.state;

        return (
            <Spin spinning={productLoading}>
                <Row>
                    <Modal
                        title={
                            <LangContext.Consumer>
                                {(i18n) => i18n.u.uom}
                            </LangContext.Consumer>
                        }
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        cancelButtonProps={{ style: { display: "none" } }}
                        okButtonProps={{ style: { display: "none" } }}
                        width={1000}
                        destroyOnClose={true}
                    >
                        <AddUom
                            onSubmit={this.createUom}
                            uomLoading={this.state.uomLoading}
                            auth={auth}
                            id={""}
                        ></AddUom>
                    </Modal>
                    <StyledSearchForm>

                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                name: this.state.name,
                                code: this.state.code,
                                description: this.state.description,
                                uom_id: this.state.uom_id,
                            }}
                            validate={(values) => {

                                let errors = {};

                                return errors;
                            }}
                            validationSchema={yup.object().shape({

                                name: yup
                                    .string()
                                    .required(<LangContext.Consumer>
                                        {(i18n) => i18n.p.product_name_require}
                                    </LangContext.Consumer>)
                                    .test("checkname", <LangContext.Consumer>
                                        {(i18n) => i18n.p.product_name_duplicate_require}
                                    </LangContext.Consumer>, function (
                                        value
                                    ) {
                                        return new Promise((resolve, reject) => {
                                            fetch(
                                                `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/product/checkproductname`,
                                                {
                                                    method: "POST",
                                                    headers: {
                                                        Accept: "application/json",
                                                        "Content-Type": "application/json",
                                                        Authorization: `Bearer ${auth.accessToken}`,
                                                    },
                                                    body: JSON.stringify({
                                                        company_id: auth.profile.company_id,
                                                        name: value,
                                                        id: id
                                                    }),
                                                }
                                            )
                                                .then((response) => response.json())
                                                .then((res) => {
                                                    if (res.status === "true") {
                                                        //console.log("res", res);
                                                        resolve(false);
                                                    }
                                                    resolve(true);
                                                });
                                        });
                                    }),

                                code: yup
                                    .string()
                                    .required(<LangContext.Consumer>
                                        {(i18n) => i18n.p.product_code_require}
                                    </LangContext.Consumer>)
                                    .test("checkname", <LangContext.Consumer>
                                        {(i18n) => i18n.p.product_code_duplicate_require}
                                    </LangContext.Consumer>, function (
                                        value
                                    ) {
                                        return new Promise((resolve, reject) => {
                                            fetch(
                                                `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/product/checkproductcode`,
                                                {
                                                    method: "POST",
                                                    headers: {
                                                        Accept: "application/json",
                                                        "Content-Type": "application/json",
                                                        Authorization: `Bearer ${auth.accessToken}`,
                                                    },
                                                    body: JSON.stringify({
                                                        company_id: auth.profile.company_id,
                                                        code: value,
                                                        id: id
                                                    }),
                                                }
                                            )
                                                .then((response) => response.json())
                                                .then((res) => {
                                                    if (res.status === "true") {
                                                        //console.log("res", res);
                                                        resolve(false);
                                                    }
                                                    resolve(true);
                                                });
                                        });
                                    }),

                                uom_id: yup
                                    .string()
                                    .required(<LangContext.Consumer>
                                        {(i18n) => i18n.p.product_uom_require}
                                    </LangContext.Consumer>)

                            })}
                            onSubmit={(values, { resetForm }) => {

                                const loading = true;
                                onSubmit(values, loading);

                            }}
                            render={({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleFocus,
                                setFieldValue,
                                resetForm,
                            }) => (
                                    <Row>
                                        <LangContext.Consumer>
                                            {(i18n) => (
                                                <Form onSubmit={handleSubmit}>
                                                    <FormDivider>
                                                        <div className='semi-bold-16'>
                                                            <LangContext.Consumer>
                                                                {(i18n) => i18n.p.product}
                                                            </LangContext.Consumer>
                                                        </div>

                                                    </FormDivider>
                                                    <Row gutter={24}>
                                                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                                            <Form.Item
                                                                label={
                                                                    <LangContext.Consumer>
                                                                        {(i18n) => i18n.p.product_name}
                                                                    </LangContext.Consumer>
                                                                }

                                                                required={true}
                                                                validateStatus={
                                                                    touched.name &&
                                                                    errors.name &&
                                                                    "warning"
                                                                }
                                                                help={
                                                                    touched.name && errors.name
                                                                }
                                                            >
                                                                <Input
                                                                    type="Text"
                                                                    name="name"
                                                                    autoComplete="off"
                                                                    value={values.name}
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            "name",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        this.setState({ name: e.target.value });
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={24}>
                                                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                                            <Form.Item
                                                                label={
                                                                    <LangContext.Consumer>
                                                                        {(i18n) => i18n.p.product_code}
                                                                    </LangContext.Consumer>
                                                                }

                                                                required={true}
                                                                validateStatus={
                                                                    touched.code &&
                                                                    errors.code &&
                                                                    "warning"
                                                                }
                                                                help={
                                                                    touched.code && errors.code
                                                                }
                                                            >
                                                                <Input
                                                                    type="Text"
                                                                    name="code"
                                                                    autoComplete="off"
                                                                    value={values.code}
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            "code",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        this.setState({ code: e.target.value });
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={24}>
                                                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                                            <FormItem

                                                                label={
                                                                    <LangContext.Consumer>
                                                                        {(i18n) => i18n.p.product_description}
                                                                    </LangContext.Consumer>
                                                                }
                                                                validateStatus={
                                                                    touched.description && errors.description && "warning"
                                                                }
                                                                help={touched.description && errors.description}
                                                            >
                                                                <TextArea
                                                                    name="description"
                                                                    rows={6}
                                                                    onChange={(value) =>
                                                                        setFieldValue("description", value.target.value)
                                                                    }
                                                                    value={values.description}
                                                                    onBlur={(e) => {
                                                                        this.setState({ description: e.target.value });
                                                                    }}
                                                                />
                                                            </FormItem>
                                                        </Col>
                                                    </Row>

                                                    <Row gutter={24}>
                                                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                                            <FormItem
                                                                label={i18n.u.uom_smallest}
                                                                required={true}
                                                                validateStatus={
                                                                    touched.uom_id &&
                                                                    errors.uom_id &&
                                                                    "warning"
                                                                }
                                                                help={
                                                                    touched.uom_id &&
                                                                    errors.uom_id
                                                                }
                                                            >
                                                                <InputGroup compact>
                                                                    <Select
                                                                        showSearch
                                                                        onChange={(value) => {
                                                                            setFieldValue("uom_id", value);
                                                                            this.setState({ uom_id: value });
                                                                        }}
                                                                        value={values.uom_id}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        style={{ width: "85%" }}
                                                                    >
                                                                        <Option value="">
                                                                            {i18n.p.pleaseSelect}
                                                                        </Option>
                                                                        {uom.map((v) => (
                                                                            <Option key={v.id} value={v.id}>
                                                                                {v.name}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                    <Button
                                                                        icon="plus"
                                                                        style={{ width: "15%" }}
                                                                        onClick={() => this.showModal()}
                                                                    >
                                                                        <LangContext.Consumer>
                                                                            {(i18n) => i18n.u.uom}
                                                                        </LangContext.Consumer>
                                                                    </Button>
                                                                </InputGroup>
                                                            </FormItem>
                                                        </Col>
                                                    </Row>


                                                    <Row style={{ marginTop: "20px", marginBottom: "5px" }}>
                                                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                                            <Form.Item>
                                                                <Button
                                                                    type="defualt"
                                                                    style={{
                                                                        marginRight: "10px",
                                                                    }}
                                                                >
                                                                    <Link to="/product">{i18n.b.back}</Link>
                                                                </Button>

                                                                <Button type="primary" htmlType="submit">
                                                                    {i18n.s.save}
                                                                </Button>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </LangContext.Consumer>
                                    </Row>
                                )}
                        />
                    </StyledSearchForm>
                </Row>
            </Spin>
        );
    }
}
