import React, { Component } from 'react'
import { TrackingStatusStyledContent } from '../../../../styled/common-styled'
import {
  Row,
  Col,
  Card,
  Table,
  Select,
  Empty,
  Spin,
  Collapse,
  Button,
  Menu,
  message,
} from 'antd'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import * as moment from 'moment'
import VehicleMarker from '../../../shared/components/map-assets/Marker/VehicleMarker'
import ClusterMarker from '../../../shared/components/map-assets/ClusterMarker'
import supercluster from 'points-cluster'
import Ldm from './Ldm'
// import { fr } from 'date-fns/locale'
import { loadTruckStatusDashboard, removeLists } from '../actions'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
const { Panel } = Collapse
const MyResponsivePie = ({
  i18n,
  data,
  percentTruckReadyToUse,
  percentTruckNotReadyToUse,
}) => (
  <div style={{ height: 200 }}>
    <ResponsivePie
      data={data}
      startAngle={-56}
      margin={{ top: 20, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.3}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      colors={['#7cb305', '#ff8f8f']}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: 'color', modifiers: [['darker', '3']] }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'not punt in',
          },
          id: '',
        },
        {
          match: {
            id: 'punt in',
          },
          id: '',
        },
      ]}
      tooltip={({ id, value, color }) => (
        <div
          style={{
            color,
          }}
        >
          <strong>
            {id}:{' '}
            {id == 'รถไม่พร้อมใช้งาน'
              ? 100 - percentTruckReadyToUse.toFixed(2)
              : id == 'รถพร้อมใช้งาน'
              ? percentTruckReadyToUse.toFixed(2)
              : id == 'Truck Ready To Use'
              ? percentTruckReadyToUse.toFixed(2)
              : id == 'Truck Not Ready To Use'
              ? percentTruckNotReadyToUse.toFixed(2)
              : ''}
            {''}%
          </strong>
        </div>
      )}
    />
  </div>
)

const MyResponsiveBar_ = ({ data, selectBar /* see data tab */ }) => (
  <div style={{ height: 300 }}>
    <ResponsiveBar
      onClick={selectBar}
      data={data}
      keys={['count_vehicle_in_department']}
      indexBy="department_name"
      margin={{ top: 0, right: 0, bottom: 10, left: 150 }}
      padding={0.25}
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      valueFormat={{ format: ' >-', enabled: false }}
      colors={['#ff8f8f']}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'fries',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'sandwich',
          },
          id: 'lines',
        },
      ]}
      borderWidth={0}
      borderColor={{ from: 'color', modifiers: [['darker', '3']] }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      tooltip={function({ value, indexValue }) {
        return (
          <div>
            {indexValue}: {value} คัน
          </div>
        )
      }}
      enableGridY={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', '3']] }}
      legends={[]}
    />
  </div>
)

const concreateSizeOptions = [
  {
    value: '5',
    name: (
      <LangContext.Consumer>{i18n => i18n.l.largeTruck}</LangContext.Consumer>
    ),
  },
  {
    value: '6',
    name: (
      <LangContext.Consumer>{i18n => i18n.s.smallTruck}</LangContext.Consumer>
    ),
  },
  {
    value: 'all',
    name: (
      <LangContext.Consumer>
        {i18n => i18n.s.smallTrucKAndLargeTruck}
      </LangContext.Consumer>
    ),
  },
]
const { Option } = Select

class Index extends Component {
  state = {
    APIData: [],
    sectionData: [],
    sectionTable: [],
    carWaitingForProductionTable: [],
    truckOnTheWayTable: [],
    truckReturningTable: [],
    factoryData: null,
    concreateList: [],
    focusBoundary: null,
    truckAvailable: '',
    truckParking: '',
    truckOnsite: '',
    truckReturnToPlant: '',
    availableData: null,
    truckReadyToUse: null,
    carWaitingForProduction: null,
    truckOnTheWay: null,
    truckReturning: null,
    tableheader: '',
    sectionSelect: [],
    pathBusinessSelection: (
      <LangContext.Consumer>{i18n => i18n.a.allBusiness}</LangContext.Consumer>
    ),
    pathConcreateMixerSizeSelection: (
      <LangContext.Consumer>
        {i18n => i18n.s.smallTrucKAndLargeTruck}
      </LangContext.Consumer>
    ),
    barName: '',
    pathSectionTable: '',
    subPathSectionTable: '',
    maps: null,
    division: [],
    mapOptions: {
      center: {
        lat: 13.712495,
        lng: 100.61032333,
      },
      zoom: 3,
    },
    keepCluster: [],
    clusters: [],
    markercluster: [],
    selectBusiness: 'all',
    selectMixerSize: 'all',
    notReady: '',
    keepTrackingLatLng: [],
    trickerZoom: 3,
    timeStamp: '',
    showSecondSelectOption: false,
    loadingVehicle: false,
    trickerclearMap: false,
    tempVehicleCode: null,
  }

  loadDivision = cb => {
    let self = this
    let divisionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      divisionVisibility = orgChart.divisions.map(ele => ele.divisionNo)
    }
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        division_visibility: divisionVisibility,
        comp: [2, 3, 4, 5, 6],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        this.setState(
          {
            division: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }
  loadTracking = cb => {
    this.setState(
      {
        loadingVehicle: true,
        trickerclearMap: false,
      },
      () => {
        fetch(
          `${process.env.REACT_APP_REDIS_API_ENDPOINT}/getvehiclefortrackingmap`,
          {
            method: 'POST',
            headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
            body: JSON.stringify({
              gps_vendor_id: 0,
              section_id: 0,
              department_id: 0,
              division_id: 0,
              company_id: this.props.auth.profile.company_id,
              vehicle_visibility: this.state.keepTrackingLatLng,
              search_name: '',
              selected_tracking_columns: [
                'plate_no',
                'vehicle_code',
                'velocity',
                'vehicle_status',
                'driver_license_swiping_status',
                'latest_pos_update_at',
                'gps_active_at',
                'device_code',
                'drum_direction',
                'drum_rpm',
                'company_name',
                'plant',
                'model',
                'lastest_driver',
              ],
              vehicle_status: [
                'running',
                'stop-with-engine-on',
                'stop-with-engine-off',
                'connection-error',
              ],
              speed_range: [0, 200],
              time_range: [0, 240],
              vehicle_type: [],
              vehicle_group: [],
            }),
          },
        )
          .then(response => response.json())
          .then(data => {
            this.setState(
              {
                timeStamp: moment().format('DD/MM/YYYY HH:mm:ss'),
                markercluster: data.markers,
                loadingVehicle: false,
              },
              () => {
                this.createClusters(true)
              },
            )
          })
          .catch(err => {
            message.error('This is an error message')
          })
      },
    )
  }
  findPathBusinessSelection = id => {}
  handleSelectBusinessChange_ = id => {
    let self = this
    const setPathDivision = this.state.division.filter(item => item.id == id)
    this.setState(
      {
        selectBusiness: id,
        pathBusinessSelection:
          id == 'all' ? (
            <LangContext.Consumer>
              {i18n => i18n.a.allBusiness}
            </LangContext.Consumer>
          ) : (
            setPathDivision[0].name
          ),
        subPathSectionTable: '',
        pathSectionTable: '',
        barName: '',
        showSecondSelectOption: true,
        sectionSelect: [],
      },
      () => {
        this.props.loadTruckStatusDashboard(
          {
            vehicle_visibility: this.props.auth.profile.vehicle_visibility,
            division: this.state.selectBusiness,
            vehicle_type: this.state.selectMixerSize,
            start_date: moment().format('YYYY-MM-DD 00:00:00'),
            end_date: moment().format('YYYY-MM-DD 23:59:59'),
            company_id: this.props.auth.profile.company_id,
            accessToken: this.props.auth.accessToken,
          },
          true,
          function(res) {
            const selectBusinessArr = self.getVehicleId(
              res.TruckAvailableData,
              res.TruckOnsiteData,
              res.TruckParkingData,
              res.TruckReturnToPlantData,
            )
            self.setState(
              {
                keepTrackingLatLng: selectBusinessArr,
              },
              () => {
                self.loadTracking()
              },
            )
          },
        )
      },
    )
  }

  onSelectConcreateMixerSizeChange_ = value => {
    let self = this
    let setPathSubDivision = concreateSizeOptions.filter(
      item => item.value == value,
    )
    this.setState(
      {
        selectMixerSize: value,
        pathConcreateMixerSizeSelection:
          value == 'all' ? (
            <LangContext.Consumer>
              {i18n => i18n.s.smallTrucKAndLargeTruck}
            </LangContext.Consumer>
          ) : (
            setPathSubDivision[0].name
          ),
        subPathSectionTable: '',
        pathSectionTable: '',
        barName: '',
        sectionSelect: [],
      },
      () => {
        this.props.loadTruckStatusDashboard(
          {
            vehicle_visibility: this.props.auth.profile.vehicle_visibility,
            division: this.state.selectBusiness,
            vehicle_type: this.state.selectMixerSize,
            start_date: moment().format('YYYY-MM-DD 00:00:00'),
            end_date: moment().format('YYYY-MM-DD 23:59:59'),
            company_id: this.props.auth.profile.company_id,
            accessToken: this.props.auth.accessToken,
          },
          true,
          function(res) {
            const SelectConcreateMixerSizeArr = self.getVehicleId(
              res.TruckAvailableData,
              res.TruckOnsiteData,
              res.TruckParkingData,
              res.TruckReturnToPlantData,
            )
            self.setState(
              {
                keepTrackingLatLng: SelectConcreateMixerSizeArr,
              },
              self.loadTracking,
            )
          },
        )
      },
    )
  }
  loadData = loading => {
    let self = this
    this.props.loadTruckStatusDashboard(
      {
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        division: 'all',
        vehicle_type: 'all',
        start_date: moment().format('YYYY-MM-DD 00:00:00'),
        end_date: moment().format('YYYY-MM-DD 23:59:59'),
        company_id: this.props.auth.profile.company_id,
        accessToken: this.props.auth.accessToken,
      },
      loading,
      function(res) {
        const selectBusinessArr = self.getVehicleId(
          res.TruckAvailableData,
          res.TruckOnsiteData,
          res.TruckParkingData,
          res.TruckReturnToPlantData,
        )
        self.setState(
          {
            keepTrackingLatLng: selectBusinessArr,
          },
          self.loadTracking,
        )
      },
    )
  }

  getVehicleId = (
    TruckAvailableData,
    TruckOnsiteData,
    TruckParkingData,
    TruckReturnToPlantData,
  ) => {
    let selectBusinessArr = []
    TruckAvailableData.map(item =>
      item.department_data.map(item2 =>
        item2.section_data.map(item3 =>
          item3.plant_data.map(item4 => {
            selectBusinessArr.push(item4.vehicle_id)
          }),
        ),
      ),
    )
    TruckOnsiteData.map(item =>
      item.department_data.map(item2 =>
        item2.section_data.map(item3 =>
          item3.plant_data.map(item4 => {
            selectBusinessArr.push(item4.vehicle_id)
          }),
        ),
      ),
    )
    TruckParkingData.map(item =>
      item.department_data.map(item2 =>
        item2.section_data.map(item3 =>
          item3.plant_data.map(item4 => {
            selectBusinessArr.push(item4.vehicle_id)
          }),
        ),
      ),
    )
    TruckReturnToPlantData.map(item =>
      item.department_data.map(item2 =>
        item2.section_data.map(item3 =>
          item3.plant_data.map(item4 => {
            selectBusinessArr.push(item4.vehicle_id)
          }),
        ),
      ),
    )
    return selectBusinessArr
  }

  handleOnPinMarker = () => {
    //handle click map marker
  }

  handleGotoMarker_ = detail => {
    if (detail.length == 0) return message.error('ไม่มีข้อมูลโรงงานบนแผนที่')
    let latArr = [],
      lngArr = []
    detail.map((value, index) => {
      if (value.lat != 0.0 && value.lng != 0.0) {
        latArr.push(parseFloat(value.lat))
        lngArr.push(parseFloat(value.lng))
      }
    })

    let minLat = Math.min.apply(null, latArr)
    let minLon = Math.min.apply(null, lngArr)
    let maxLat = Math.max.apply(null, latArr)
    let maxLon = Math.max.apply(null, lngArr)
    let boundbox = { minLon, minLat, maxLon, maxLat }
    this.setState({ focusBoundary: boundbox })
  }

  handleApiLoaded = (map, maps, platform = null, mapLayer = null) => {
    if (typeof maps == 'undefined' || maps == null) window.location.reload()

    this.setState({
      maps: maps,
    })
  }

  getClusters = data => {
    const clusters = supercluster(data, {
      minZoom: 0,
      maxZoom: 11,
      radius: 40,
    })

    return clusters(this.state.mapOptions)
  }

  createClusters = shouldTricker => {
    const data = this.state.markercluster
    if (typeof data != 'undefined' && data.length > 0) {
      let clusters = this.getClusters(data).map(val => {
        return {
          cluster_lat: val.wy,
          cluster_lng: val.wx,
          numPoints: val.numPoints,
          eleId: `${val.numPoints}_${val.points[0].plate_no}`,
          points: val.points,
          markerInfo: val.points[0],
          vehicle_id: val.vehicle_id,
        }
      })

      this.setState(
        {
          clusters,
        },
        () => {
          if (shouldTricker) this.handleGotoMarker_(data)
        },
      )
    }
  }

  setMapBound = value => {
    this.setState(
      { mapOptions: { ...this.state.mapOptions, ...value } },
      () => {
        this.createClusters(false)
      },
    )
  }

  componentDidMount() {
    this.loadDivision()
  }
  componentWillUnmount() {
    this.onRemoveLists()
  }

  onRemoveLists = () => {
    this.props.removeLists()
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.TruckStatusDashboard.draw !=
      nextProps.TruckStatusDashboard.draw
    ) {
    }
    return true
  }

  render() {
    const { Lists, loading } = this.props.TruckStatusDashboard
    const expandedRowRender = record => {
      const columns = [
        {
          title: (
            <LangContext.Consumer>
              {i18n => i18n.f.factory}
            </LangContext.Consumer>
          ),
          dataIndex: 'plant_name',
          key: 'plant_name',
          className: 'test-cursor',
          render(text, record, index) {
            return {
              children: <div>{text}</div>,
            }
          },
        },
        {
          title: (
            <LangContext.Consumer>{i18n => i18n.n.number}</LangContext.Consumer>
          ),
          dataIndex: 'count_vehicle_in_plant',
          className: 'test-cursor',
        },
      ]
      let onChangeFollowUpParent = record.section_name
      return (
        <Table
          columns={columns}
          dataSource={record.section_data}
          pagination={false}
          rowKey={(record, i) => i.toString()}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                let handleOnRow = record.plant_data.map(item => item.vehicle_id)

                let ARR =
                  Array.isArray(this.state.keepTrackingLatLng) &&
                  Array.isArray(handleOnRow) &&
                  this.state.keepTrackingLatLng.length === handleOnRow.length &&
                  this.state.keepTrackingLatLng.every(
                    (val, index) => val === handleOnRow[index],
                  )
                this.setState(
                  {
                    subPathSectionTable: record.plant_name,
                    pathSectionTable: onChangeFollowUpParent,
                    keepTrackingLatLng: handleOnRow,
                    trickerclearMap: ARR ? false : true,
                  },
                  () => {
                    if (ARR) {
                      return
                    } else {
                      this.loadTracking()
                    }
                  },
                )
              },
            }
          }}
          style={{
            marginTop: '-8px',
            marginRight: '-8px',
            marginBottom: '-9px',
          }}
          bordered
          pagination={false}
        />
      )
    }

    const columns = [
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.s.section}</LangContext.Consumer>
        ),
        dataIndex: 'section_name',
        key: 'section_name',
        className: 'test-cursor',
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.n.number}</LangContext.Consumer>
        ),
        dataIndex: 'count_vehicle_in_section',
        className: 'test-cursor',
      },
    ]
    const keepArr = []
    let counntVehicleIDInDivision =
      this.props.TruckStatusDashboard.Lists.VehicleIDInDivision !== undefined
        ? this.props.TruckStatusDashboard.Lists.VehicleIDInDivision.length
        : ''
    let dataVisibility = this.props.auth.profile.vehicle_visibility
    let splitdataVisibility = dataVisibility.split(',')

    keepArr.map(
      item => {
        splitdataVisibility = splitdataVisibility.filter(word => word !== item)
      },
      () => {
        this.setState({
          notReady: splitdataVisibility.length,
        })
      },
    )
    const notReady = splitdataVisibility.length
    const percentTruckReadyToUse =
      (this.props.TruckStatusDashboard.Lists.TruckAvailable * 100) /
      counntVehicleIDInDivision
    const percentTruckNotReadyToUse =
      ((counntVehicleIDInDivision -
        this.props.TruckStatusDashboard.Lists.TruckAvailable) *
        100) /
      counntVehicleIDInDivision
    const dataPie = [
      {
        id: 'รถพร้อมใช้งาน',
        label: '%ความพร้อม',
        value: this.state.showSecondSelectOption
          ? this.props.TruckStatusDashboard.Lists.TruckAvailable
          : 0,
      },
      {
        id: 'รถไม่พร้อมใช้งาน',
        label: '%ไม่พร้อมใช้งาน',
        value: this.state.showSecondSelectOption
          ? counntVehicleIDInDivision -
            this.props.TruckStatusDashboard.Lists.TruckAvailable
          : 100,
      },
    ]

    return (
      <AuthorizeComponent {...this.props} matching_name="truckstatusdashboard">
        <TrackingStatusStyledContent>
          <Spin spinning={loading}>
            <Row gutter={24}>
              <Col span={12}>
                <div className="header__text">
                  <LangContext.Consumer>
                    {i18n => i18n.t.truckstatusdashboard}
                  </LangContext.Consumer>
                </div>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {this.state.showSecondSelectOption ? (
                  <label>Last Updated: {this.state.timeStamp}</label>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card bordered={false} style={{ textAlign: 'center' }}>
                  <Row gutter={[8, 8]}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                      <span>
                        <LangContext.Consumer>
                          {i18n => i18n.c.chooseBusiness}
                        </LangContext.Consumer>
                        :{' '}
                      </span>

                      {this.state.division != null ? (
                        <Select
                          placeholder={
                            <LangContext.Consumer>
                              {i18n => i18n.c.chooseBusinessPls}
                            </LangContext.Consumer>
                          }
                          style={{ width: 200 }}
                          onChange={this.handleSelectBusinessChange_}
                        >
                          {this.state.division.map(items => (
                            <Option key={items.id}>{items.name}</Option>
                          ))}
                        </Select>
                      ) : null}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={412} align="left">
                      <span>
                        <LangContext.Consumer>
                          {i18n => i18n.m.mixerSize}
                        </LangContext.Consumer>
                        :{' '}
                      </span>
                      <Select
                        defaultValue="all"
                        style={{ width: 150 }}
                        onChange={this.onSelectConcreateMixerSizeChange_}
                        disabled={
                          this.state.showSecondSelectOption ? false : true
                        }
                      >
                        <Option value="all" key={0}>
                          <LangContext.Consumer>
                            {i18n => i18n.l.largeMixerAndSmallMixer}
                          </LangContext.Consumer>
                        </Option>
                        <Option value="6" key={1}>
                          <LangContext.Consumer>
                            {i18n => i18n.s.smallMixer}
                          </LangContext.Consumer>
                        </Option>
                        <Option value="5" key={2}>
                          <LangContext.Consumer>
                            {i18n => i18n.l.largeMixer}
                          </LangContext.Consumer>
                        </Option>
                      </Select>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <Card
                  bordered={false}
                  style={{ textAlign: 'center', height: '200px' }}
                >
                  <div className="semi-bold-16" style={{ color: 'black' }}>
                    %
                    <LangContext.Consumer>
                      {i18n => i18n.r.readiness}
                    </LangContext.Consumer>
                  </div>
                  <LangContext.Consumer>
                    {i18n => (
                      <MyResponsivePie
                        i18n={i18n}
                        data={[
                          {
                            id: i18n.t.truckIsReadyToUse,
                            label: '%ความพร้อม',
                            value: this.state.showSecondSelectOption
                              ? this.props.TruckStatusDashboard.Lists
                                  .TruckAvailable
                              : 0,
                          },
                          {
                            id: i18n.t.truckIsNotReadyToUse,
                            label: '%ไม่พร้อมใช้งาน',
                            value: this.state.showSecondSelectOption
                              ? counntVehicleIDInDivision -
                                this.props.TruckStatusDashboard.Lists
                                  .TruckAvailable
                              : 100,
                          },
                        ]}
                        percentTruckReadyToUse={percentTruckReadyToUse}
                        percentTruckNotReadyToUse={percentTruckNotReadyToUse}
                      />
                    )}
                  </LangContext.Consumer>
                </Card>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Card
                  bordered={false}
                  style={{ textAlign: 'center', height: '200px' }}
                >
                  <div className="common-text-center">
                    <div className="semi-bold-16" style={{ color: 'black' }}>
                      <LangContext.Consumer>
                        {i18n => i18n.t.truckIsReadyToUse}
                      </LangContext.Consumer>
                    </div>
                    <h2>
                      {this.state.showSecondSelectOption
                        ? this.props.TruckStatusDashboard.Lists.TruckAvailable
                        : 0}
                    </h2>
                  </div>
                </Card>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Card
                  bordered={false}
                  style={{ textAlign: 'center', height: '200px' }}
                >
                  <div className="common-text-center">
                    <div className="semi-bold-16" style={{ color: 'black' }}>
                      <LangContext.Consumer>
                        {i18n => i18n.w.waitProduction}
                      </LangContext.Consumer>
                    </div>
                    <h2>
                      {this.state.showSecondSelectOption
                        ? this.props.TruckStatusDashboard.Lists.TruckParking
                        : 0}
                    </h2>
                  </div>
                </Card>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Card
                  bordered={false}
                  style={{ textAlign: 'center', height: '200px' }}
                >
                  <div
                    className="common-text-center"
                    style={{ color: 'black' }}
                  >
                    <div className="semi-bold-16">
                      <LangContext.Consumer>
                        {i18n => i18n.t.truckOnTheWayAndOnSite}
                      </LangContext.Consumer>
                    </div>
                    <h2>
                      {this.state.showSecondSelectOption
                        ? this.props.TruckStatusDashboard.Lists.TruckOnsite
                        : 0}
                    </h2>
                  </div>
                </Card>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Card
                  bordered={false}
                  style={{ textAlign: 'center', height: '200px' }}
                >
                  <div className="common-text-center">
                    <div className="semi-bold-16" style={{ color: 'black' }}>
                      <LangContext.Consumer>
                        {i18n => i18n.t.truckGoesBack}
                      </LangContext.Consumer>
                    </div>
                    <h2>
                      {this.state.showSecondSelectOption
                        ? this.props.TruckStatusDashboard.Lists
                            .TruckReturnToPlant
                        : 0}
                    </h2>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse accordion bordered={false}>
                      <Panel
                        disabled={
                          this.state.showSecondSelectOption ? false : true
                        }
                        header={
                          <LangContext.Consumer>
                            {i18n => i18n.n.numberOfTruckIsReadyToUse}
                          </LangContext.Consumer>
                        }
                        key="1"
                      >
                        {this.props.TruckStatusDashboard.Lists
                          .TruckAvailable !== 0 ? (
                          <MyResponsiveBar_
                            data={
                              Lists.length == 0 ? [] : Lists.TruckAvailableData
                            }
                            selectBar={e => {
                              let handleOnRow = []
                              e.data.department_data.map(item =>
                                item.section_data.map(item2 =>
                                  item2.plant_data.map(item3 => {
                                    handleOnRow.push(item3.vehicle_id)
                                  }),
                                ),
                              )

                              let ARR =
                                Array.isArray(this.state.keepTrackingLatLng) &&
                                Array.isArray(handleOnRow) &&
                                this.state.keepTrackingLatLng.length ===
                                  handleOnRow.length &&
                                this.state.keepTrackingLatLng.every(
                                  (val, index) => val === handleOnRow[index],
                                )
                              this.setState(
                                {
                                  tableheader: e.indexValue,
                                  barName: (
                                    <LangContext.Consumer>
                                      {i18n => i18n.n.numberOfTruckIsReadyToUse}
                                    </LangContext.Consumer>
                                  ),
                                  pathSectionTable: '',
                                  subPathSectionTable: '',
                                  keepTrackingLatLng: handleOnRow,
                                  trickerclearMap: ARR ? false : true,
                                },
                                () => {
                                  if (ARR) {
                                    return
                                  } else {
                                    this.loadTracking()
                                  }
                                  this.setState({
                                    sectionSelect: e.data.department_data,
                                  })
                                },
                              )
                            }}
                          />
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse accordion bordered={false}>
                      <Panel
                        disabled={
                          this.state.showSecondSelectOption ? false : true
                        }
                        header={
                          <LangContext.Consumer>
                            {i18n => i18n.n.numberOfTruckIsWaitingForProduction}
                          </LangContext.Consumer>
                        }
                        key="1"
                      >
                        {this.props.TruckStatusDashboard.Lists.TruckParking !=
                        0 ? (
                          <MyResponsiveBar_
                            data={
                              Lists.length == 0 ? [] : Lists.TruckParkingData
                            }
                            selectBar={e => {
                              let handleOnRow = []
                              e.data.department_data.map(item =>
                                item.section_data.map(item2 =>
                                  item2.plant_data.map(item3 => {
                                    handleOnRow.push(item3.vehicle_id)
                                  }),
                                ),
                              )

                              let ARR =
                                Array.isArray(this.state.keepTrackingLatLng) &&
                                Array.isArray(handleOnRow) &&
                                this.state.keepTrackingLatLng.length ===
                                  handleOnRow.length &&
                                this.state.keepTrackingLatLng.every(
                                  (val, index) => val === handleOnRow[index],
                                )
                              this.setState(
                                {
                                  tableheader: e.indexValue,
                                  barName: (
                                    <LangContext.Consumer>
                                      {i18n =>
                                        i18n.n
                                          .numberOfTruckIsWaitingForProduction
                                      }
                                    </LangContext.Consumer>
                                  ),
                                  pathSectionTable: '',
                                  subPathSectionTable: '',
                                  keepTrackingLatLng: handleOnRow,
                                  trickerclearMap: ARR ? false : true,
                                },
                                () => {
                                  if (ARR) {
                                    return
                                  } else {
                                    this.loadTracking()
                                  }
                                  this.setState({
                                    sectionSelect: e.data.department_data,
                                  })
                                },
                              )
                            }}
                          />
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse accordion bordered={false}>
                      <Panel
                        disabled={
                          this.state.showSecondSelectOption ? false : true
                        }
                        header={
                          <LangContext.Consumer>
                            {i18n => i18n.n.numberOfTruckOnTheWayAndOnSite}
                          </LangContext.Consumer>
                        }
                        key="1"
                      >
                        {this.props.TruckStatusDashboard.Lists.TruckOnsite !=
                        0 ? (
                          <MyResponsiveBar_
                            data={
                              Lists.length == 0 ? [] : Lists.TruckOnsiteData
                            }
                            selectBar={e => {
                              let handleOnRow = []
                              e.data.department_data.map(item =>
                                item.section_data.map(item2 =>
                                  item2.plant_data.map(item3 => {
                                    handleOnRow.push(item3.vehicle_id)
                                  }),
                                ),
                              )
                              let ARR =
                                Array.isArray(this.state.keepTrackingLatLng) &&
                                Array.isArray(handleOnRow) &&
                                this.state.keepTrackingLatLng.length ===
                                  handleOnRow.length &&
                                this.state.keepTrackingLatLng.every(
                                  (val, index) => val === handleOnRow[index],
                                )
                              this.setState(
                                {
                                  tableheader: e.indexValue,
                                  barName: (
                                    <LangContext.Consumer>
                                      {i18n =>
                                        i18n.n.numberOfTruckOnTheWayAndOnSite
                                      }
                                    </LangContext.Consumer>
                                  ),
                                  pathSectionTable: '',
                                  subPathSectionTable: '',
                                  keepTrackingLatLng: handleOnRow,
                                  trickerclearMap: ARR ? false : true,
                                },
                                () => {
                                  if (ARR) {
                                    return
                                  } else {
                                    this.loadTracking()
                                  }
                                  this.setState({
                                    sectionSelect: e.data.department_data,
                                  })
                                },
                              )
                            }}
                          />
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse accordion bordered={false}>
                      <Panel
                        disabled={
                          this.state.showSecondSelectOption ? false : true
                        }
                        header={
                          <LangContext.Consumer>
                            {i18n => i18n.n.numberOfTruckGoesBack}
                          </LangContext.Consumer>
                        }
                        key="1"
                      >
                        {this.props.TruckStatusDashboard.Lists
                          .TruckReturnToPlant != 0 ? (
                          <MyResponsiveBar_
                            data={
                              Lists.length == 0
                                ? []
                                : Lists.TruckReturnToPlantData
                            }
                            selectBar={e => {
                              let handleOnRow = []
                              e.data.department_data.map(item =>
                                item.section_data.map(item2 =>
                                  item2.plant_data.map(item3 => {
                                    handleOnRow.push(item3.vehicle_id)
                                  }),
                                ),
                              )

                              let ARR =
                                Array.isArray(this.state.keepTrackingLatLng) &&
                                Array.isArray(handleOnRow) &&
                                this.state.keepTrackingLatLng.length ===
                                  handleOnRow.length &&
                                this.state.keepTrackingLatLng.every(
                                  (val, index) => val === handleOnRow[index],
                                )
                              this.setState(
                                {
                                  tableheader: e.indexValue,
                                  barName: (
                                    <LangContext.Consumer>
                                      {i18n => i18n.t.truckGoesBack}
                                    </LangContext.Consumer>
                                  ),
                                  pathSectionTable: '',
                                  subPathSectionTable: '',
                                  keepTrackingLatLng: handleOnRow,
                                  trickerclearMap: ARR ? false : true,
                                },
                                () => {
                                  if (ARR) {
                                    return
                                  } else {
                                    this.loadTracking()
                                  }
                                  this.setState({
                                    sectionSelect: e.data.department_data,
                                  })
                                },
                              )
                            }}
                          />
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                <Card
                  title={
                    <div>
                      <LangContext.Consumer>
                        {i18n => i18n.d.details}
                      </LangContext.Consumer>
                      :{this.state.tableheader}
                    </div>
                  }
                  bordered={false}
                >
                  <Table
                    columns={columns}
                    expandedRowRender={expandedRowRender}
                    dataSource={
                      typeof this.state.sectionSelect[0] === 'undefined'
                        ? []
                        : this.state.sectionSelect
                    }
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          let handleOnRow = []
                          record.section_data.map(item => {
                            item.plant_data.map(item2 => {
                              handleOnRow.push(item2.vehicle_id)
                            })
                          })

                          let ARR =
                            Array.isArray(this.state.keepTrackingLatLng) &&
                            Array.isArray(handleOnRow) &&
                            this.state.keepTrackingLatLng.length ===
                              handleOnRow.length &&
                            this.state.keepTrackingLatLng.every(
                              (val, index) => val === handleOnRow[index],
                            )
                          this.setState(
                            {
                              pathSectionTable: record.section_name,
                              subPathSectionTable: '',
                              keepTrackingLatLng: handleOnRow,
                              trickerclearMap: ARR ? false : true,
                            },
                            () => {
                              if (ARR) {
                                return
                              } else {
                                this.loadTracking()
                              }
                            },
                          )
                        },
                      }
                    }}
                  />
                </Card>
              </Col>
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <Card>
                  <span>
                    {this.state.pathBusinessSelection} /{' '}
                    {this.state.pathConcreateMixerSizeSelection}
                    {this.state.barName != ''
                      ? `  / ${this.state.barName}(${this.state.tableheader})`
                      : ''}
                    {this.state.pathSectionTable != ''
                      ? `  / Section(${this.state.pathSectionTable})`
                      : ''}
                    {this.state.subPathSectionTable != ''
                      ? ` / โรงงาน(${this.state.subPathSectionTable})`
                      : ''}
                  </span>
                </Card>
                <Card bordered={false}>
                  <Spin spinning={this.state.loadingVehicle}>
                    <Ldm
                      refId={'map'}
                      mapOptions={this.state.mapOptions}
                      trickerZoom={this.state.trickerZoom}
                      auth={this.props.auth}
                      handleApiLoaded={this.handleApiLoaded}
                      setMapBound={this.setMapBound}
                      focusBoundary={this.state.focusBoundary}
                      trickerclearMap={this.state.trickerclearMap}
                      marker={this.state.clusters.map((item, index) => {
                        if (item.numPoints === 1) {
                          return (
                            <VehicleMarker
                              mapType={this.props.profile.map_type}
                              key={'vehicleMarker' + index}
                              title={item.markerInfo.vehicle_code}
                              vehicleImg="/img/map/marker/arrow_4.png"
                              stopImg="/img/map/marker/point.png"
                              lat={parseFloat(item.markerInfo.lat)}
                              lng={parseFloat(item.markerInfo.lng)}
                              engine={item.markerInfo.engine}
                              speed={item.markerInfo.velocity}
                              vehicleStatus={item.markerInfo.vehicle_status}
                              directionDegree={item.markerInfo.directionDegree}
                            />
                          )
                        }
                        return (
                          <ClusterMarker
                            key={'clusterMarker_' + index}
                            lat={item.cluster_lat}
                            lng={item.cluster_lng}
                            points={item.points}
                          />
                        )
                      })}
                    >
                      <div
                        id="map"
                        style={{
                          width: '100%',
                          height: '92.3vmin',
                        }}
                      ></div>
                    </Ldm>
                  </Spin>
                </Card>
              </Col>
            </Row>
          </Spin>
        </TrackingStatusStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ TruckStatusDashboard, auth, auth: { profile } }) => ({
    profile,
    auth,
    TruckStatusDashboard,
  }),
  {
    loadTruckStatusDashboard: loadTruckStatusDashboard.request,
    removeLists,
  },
)(Index)
