import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
//  *load ข้อมูลเพื่อนำไปแสดงหน้า month
function* loadBookingDashboardListMonth(action) {
  const {
    data: {
      is_admin,
      startDate,
      endDate,
      allDate,
      filterType,
      companies_id,
      accessToken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-booking-dashboard-month`,
      { is_admin, startDate, endDate, allDate, filterType, companies_id },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadBookingDashboardListMonth.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadBookingDashboardListMonth.failure())
  }
}
// *ดึงข้อมูลใบงานจาก ID
function* loadBookingByID(action) {
  const {
    data: { id, accessToken },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-booking-order-detail`,
      { id },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadBookingByID.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadBookingByID.failure())
  }
}
// *ดึงข้อมูลรถว่างทั้งหมดในวันที่ต้องการ
function* getVehicleByDate(action) {
  const {
    data: { is_admin, date, filterType, companies_id, accessToken },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-booking-remain-vehicle`,
      { is_admin, date, filterType, companies_id },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.getVehicleByDate.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.getVehicleByDate.failure())
  }
}
// *updateสถานะใบงานจากจองคิว -> จองรถ
function* updateBookingStatus(action) {
  const {
    data: { id, vehicle_id, driver_id, vehicle_type_id, accessToken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/update-booking-status`,
      { id, vehicle_id, driver_id, vehicle_type_id },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateBookingStatus.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateBookingStatus.failure())
  }
}
// *updateสถานะใบงานจากจองรถ -> ยืนยันการจอง
function* confirmBookingStatus(action) {
  const {
    data: { id, accessToken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/confirm-booking-status`,
      { id },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.confirmBookingStatus.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.confirmBookingStatus.failure())
  }
}
// *updateสถานะใบงานยกเลิกใบงาน
function* cancelBookingStatus(action) {
  const {
    data: { id, accessToken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/cancel-booking-status`,
      { id },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.cancelBookingStatus.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.cancelBookingStatus.failure())
  }
}
//  *load ข้อมูลเพื่อนำไปแสดงหน้า week
function* loadAllDataWeek(action) {
  const {
    data: {
      all_date,
      is_admin,
      startDate,
      endDate,
      filterType,
      filterSearch,
      companies_id,
      accessToken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-booking-dashboard-week`,
      {
        all_date,
        is_admin,
        startDate,
        endDate,
        filterType,
        filterSearch,
        companies_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadAllDataWeek.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadAllDataWeek.failure())
  }
}
//  *load ข้อมูลเพื่อนำไปแสดงหน้า  day
function* loadAllDataDay(action) {
  const {
    data: {
      date,
      is_admin,
      startDate,
      endDate,
      filterType,
      filterSearch,
      companies_id,
      accessToken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-booking-dashboard-day`,
      {
        date,
        is_admin,
        startDate,
        endDate,
        filterType,
        filterSearch,
        companies_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadAllDataDay.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadAllDataDay.failure())
  }
}
// *updateสถานะรถ เป็นออนไลน์หรือออฟไลน์
function* updateVehicleStatusOnOff(action) {
  const {
    data: { vehicles_id, status, accessToken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/update-vehicles-status-on-off`,
      { vehicles_id, status },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateVehicleStatusOnOff.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateVehicleStatusOnOff.failure())
  }
}
// *updateสถานะรถพร้อมใช้งานหรือไม่พร้อมใช้งาน
function* updateVehicleStatusbyDate(action) {
  const {
    data: { vehicles_id, status, date, accessToken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/update-vehicles-status-by-date`,
      { vehicles_id, status, date },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateVehicleStatusbyDate.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateVehicleStatusbyDate.failure())
  }
}

export default function* watchIspplyBookingDashboard() {
  yield all([
    takeEvery(
      actions.LOAD_ISUPPLY_BOOKING_DASHBOARD_LIST_MONTH.REQUEST,
      loadBookingDashboardListMonth,
    ),
    takeEvery(
      actions.LOAD_ISUPPLY_BOOKING_ORDER_BY_ID.REQUEST,
      loadBookingByID,
    ),
    takeEvery(actions.GET_ISUPPLY_VEHICLE_BY_DATE.REQUEST, getVehicleByDate),
    takeEvery(
      actions.UPDATE_ISUPPLY_BOOKING_STATUS.REQUEST,
      updateBookingStatus,
    ),
    takeEvery(
      actions.UPDATE_ISUPPLY_CONFIRM_STATUS.REQUEST,
      confirmBookingStatus,
    ),
    takeEvery(
      actions.UPDATE_ISUPPLY_CANCEL_STATUS.REQUEST,
      cancelBookingStatus,
    ),
    takeEvery(actions.LOAD_ALL_DATA_WEEK.REQUEST, loadAllDataWeek),
    takeEvery(actions.LOAD_ALL_DATA_DAY.REQUEST, loadAllDataDay),
    takeEvery(
      actions.UPDATE_VEHICLE_STATUS_ON_OFF.REQUEST,
      updateVehicleStatusOnOff,
    ),
    takeEvery(
      actions.UPDATE_VEHICLE_STATUS_BY_DATE.REQUEST,
      updateVehicleStatusbyDate,
    ),
  ])
}
export {
  loadBookingDashboardListMonth,
  loadBookingByID,
  getVehicleByDate,
  updateBookingStatus,
  confirmBookingStatus,
  cancelBookingStatus,
  loadAllDataWeek,
  loadAllDataDay,
  updateVehicleStatusOnOff,
  updateVehicleStatusbyDate,
}
