import React, { Component } from 'react'
import { connect } from 'react-redux'
import Forms from './Forms'
import { Row, Col } from 'antd'
import Breadcrumb from './../../../shared/components/Breadcrumbreport'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import moment from 'moment'

class Index extends Component {
  state = {
    vendor: [],
    loading: false,
  }

  componentDidMount() {
    this.loadVendor()
  }

  loadVendor = e => {
    let self = this
    this.setState({ loading: true })
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvendor`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ vendor: res.data, loading: false })
      })
      .catch(e => this.setState({ loading: false }))
  }

  onSubmit = value => {
    let self = this
    const { vendor_list, eventdate, sortBy } = value
    const date_start = moment(eventdate['_d'])
      .clone()
      .startOf('months')
      .format('YYYY-MM-DD 00:00:00')
    const date_end = moment(eventdate['_d'])
      .clone()
      .endOf('months')
      .format('YYYY-MM-DD 23:59:59')
    this.setState({ loading: true })
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/gpsvendorpayingsummary`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          date_start,
          date_end,
          vendor_list,
          sort_by: sortBy,
          vehicle_visibility: this.props.auth.profile.vehicle_visibility,
          type_file: 'excel',
        }),
      },
    )
      .then(response => response.json())
      .then(res => {
        self.setState({ loading: false }, () =>
          window.location.assign(
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data}`,
          ),
        )
      })
      .catch(e => this.setState({ loading: false }))
  }

  render() {
    return (
      <AuthorizeComponent
        {...this.props}
        matching_name="gpsVendorPayingSummaryReport"
      >
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              {/* <Breadcrumb
                match={this.props.match}
                sub_menu="maintenancedevice"
                style={{ margin: "0px 0px 14px 10px" }}
              /> */}
            </Col>
          </Row>
          <Forms
            loading={this.state.loading}
            onSubmit={this.onSubmit}
            vendorLists={this.state.vendor}
            loadVendor={this.loadVendor}
            onCriteriaChange={this.onCriteriaChange}
            auth={this.props.auth}
          />
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

// const mapDispatchToProps = {
//   loadAlarmAlertByPlateno: actions.loadAlarmAlertByPlateno.request
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Index);
export default connect(mapStateToProps, null)(Index)
