import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadWeightHistoryReport(action) {
  const {
    data: {
      location_visibility,
      exactly_location,
      mill_location_list,
      plant_location_list,
      material_visibility,
      filterObj,
      is_vendor,
      is_admin,
      company_id,
      accesstoken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-get-dataIsupplyWeightHistory`,
      {
        location_visibility,
        exactly_location,
        mill_location_list,
        plant_location_list,
        material_visibility,
        filterObj,
        is_vendor,
        is_admin,
        company_id,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadWeightHistoryReport.success(res.data))
  } catch (err) {
    yield put(actions.loadWeightHistoryReport.failure())
  }
}

function* exportWeightHistoryReport(action) {
  const {
    data: {
      location_visibility,
      filterObj,
      group_by_report,
      company_id,
      is_vendor,
      is_admin,
      accesstoken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-weightwistory-report`,
      {
        company_id,
        location_visibility,
        filterObj,
        group_by_report,
        is_vendor,
        is_admin,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.exportWeightHistoryReport.success(res.data))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportWeightHistoryReport.failure())
  }
}

function* loadDataByDONumber(action) {
  const {
    data: { id, accesstoken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/getfor/edit/${id}`,
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    yield put(actions.loadDataByDONumber.success(res.data))
  } catch (err) {
    yield put(actions.loadDataByDONumber.failure())
  }
}

export default function* watchIsupplyWeightHistory() {
  yield all([
    takeEvery(
      actions.LOAD_ISUPPLYWEIGHTHISTORY_REPORT.REQUEST,
      loadWeightHistoryReport,
    ),
    takeEvery(
      actions.EXPORT_ISUPPLYWEIGHTHISTOR_REPORT.REQUEST,
      exportWeightHistoryReport,
    ),
    takeEvery(
      actions.LOADDATABYDNNUMBER_ISUPPLYWEIGHTHISTOR_REPORT.REQUEST,
      loadDataByDONumber,
    ),
  ])
}

export {
  loadWeightHistoryReport,
  exportWeightHistoryReport,
  loadDataByDONumber,
}
