import React, { Component, Fragment } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Index extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        <div className="divider__web__content">
          <Divider />
        </div>
        {/* พฤติกรรมการขับขี่รายวัน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'dailyDrivingBehaviorReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/drivingbehavior">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.dailyDrivingBehaviorReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รางานนี้ใช้ได้เฉพาะ TLL
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.dailyDrivingBehaviorReport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}

        {/* การรูดใบขับขี่ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'DrivinglicensescanlogReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/drivinglicensescanlog">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.DrivinglicensescanlogReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลการรูดใบขับขี่ (ชื่อพนักงานขับขี่,
                          เลขที่ใบขับขี่, ตำแหน่งการรูดใบขับขี่)
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* บันทึกการขับขี่ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'DrivinglogReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/drivinglog">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.DrivinglogReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงบันทึกข้อมูลของ GPS ทั้งหมด (วันที่-เวลา,
                          ค่าความเร็ว, สถานที่, น้ำมันคงเหลือ)
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* จุดจอดพาหนะ */}
        {checkMenuVisibility('', web_endpoint, 'ParkingReport', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/parking">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.p.ParkingReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลตำแหน่งที่พาหนะมีการจอดมากกว่า 3 นาที
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* จำนวนครั้งการจอดรถนอกพื้นที่ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'reportSummarizingTheNumberOfOffSiteParking',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/parkingareaoutside">
              <Card className="card__web__content">
                <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.r.reportSummarizingTheNumberOfOffSiteParking}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* พาหนะเข้าพื้นที่ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'VehicleinareaReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/vehicleinarea">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.v.VehicleinareaReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* พาหนะวิ่งงานเกินเวลาที่กำหนด */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'DrivingoverlimitReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/drivingoverlimit">
              <Card className="card__web__content">
                <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.DrivingoverlimitReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* การใช้งานพาหนะ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'TruckusageReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/truckusage">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.TruckusageReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลการติดเครื่องยนต์ครั้งแรก
                          ดับเครื่องยนต์ครั้งสุดท้าย
                          และระยะทางที่ใช้งานพาหนะของแต่ละวัน
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* เสี่ยงหลับใน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'DozeoffriskReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/dozeoffrisk">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.DozeoffriskReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปสถานะพาหนะรายวัน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'dailyCarStatusReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/separatevehiclestatus">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.dailyCarStatusReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงระยะเวลาของการใช้งานพาหนะ แยกตามสถานะ
                          (ติดเครื่องยนต์, รถวิ่ง, จอดรถดับเครื่องยนต์)
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปความเร็วเกินกำหนด */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'SpeedoverlimitsummaryReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/speedoverlimitsummary">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.s.SpeedoverlimitsummaryReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลสรุปความเร็วเกินกำหนด
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปรายงาน KPI */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'SummaryKPIReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/summarykpi">
              <Card className="card__web__content">
                <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.s.SummaryKPIReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปการเดินทางแยกประเภทตามการเปิด-ปิดเครื่องยนต์ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'TripbyengineoffonReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/tripbyengineoffon">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report3__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.TripbyengineoffonReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงรายละเอียดข้อมูลของจุดจอด
                          (เวลาและตำแหน่งเริ่ม, เวลาและตำแหน่งสิ้นสุด, ระยะทาง)
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* พฤติกรรมการรูดบัตรก่อนขับขี่รายวัน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'reportCardSwipeBehaviorBeforeDrivingDaily',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/drivinglicensescanbehavior">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report3__twoline__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n =>
                          i18n.r.reportCardSwipeBehaviorBeforeDrivingDaily
                        }
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          เป็นรายงานที่แสดงจำนวนครั้งของการรูดใบขับขี่แต่ละวันได้ว่ามีการูดเข้ามากี่ครั้ง
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* พฤติกรรมการขับขี่ไม่ปลอดภัย */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'RiskbehaviourReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/riskbehaviour">
              <Card className="card__web__content">
                <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.r.RiskbehaviourReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Index
