import {
  CREATE_SALES_CLOSE,
  LOAD_MASTERDATA_PLACE,
  REMOVE_LISTS,
  CREATE_MASTERDATAPLACE,
  LOAD_DETAILMASTERDATA_PLACE,
  EXPORT_MASTERDATA_PLACE,
  UPDATE_DETAILMASTERDATA_PLACE,
  IMPORT_LOCATION,
  IMPORT_MULTIPLE_LOCATION,
} from './actions'

const initialState = {
  lists: [],
  value: [],
  total: 1,
  loading: false,
  detailMasterloading: false,
  check: true,
  check_edit: true,
  draw: -1,
  check_create: false,
}

export default (state = initialState, action) => {
  // console.log(action.type);
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_MASTERDATA_PLACE.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: true,
      }

    case LOAD_MASTERDATA_PLACE.SUCCESS:
      const { page } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case CREATE_MASTERDATAPLACE.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_MASTERDATAPLACE.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_DETAILMASTERDATA_PLACE.REQUEST:
      const { detailMasterloading } = action.payload
      return {
        ...state,
        detailMasterloading: detailMasterloading,
      }

    case LOAD_DETAILMASTERDATA_PLACE.SUCCESS:
      return {
        ...state,
        value: action.payload.value.data[0],
        detailMasterloading: false,
        check: false,
        check_edit: false,
      }

    case UPDATE_DETAILMASTERDATA_PLACE.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case CREATE_SALES_CLOSE.REQUEST:
      return {
        ...state,
        check_create: true,
      }

    case CREATE_SALES_CLOSE.SUCCESS:
      return {
        ...state,
        loading: false,
        check_create: false,
      }

    case EXPORT_MASTERDATA_PLACE.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_MASTERDATA_PLACE.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case IMPORT_LOCATION.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case IMPORT_LOCATION.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case IMPORT_MULTIPLE_LOCATION.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case IMPORT_MULTIPLE_LOCATION.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
