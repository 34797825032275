import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

import {
    ACCESSTOKEN,
    COMPANY_ID,
    USER_ID,
    getlocalstorage
} from "./../../../constants/local_storage";

function* loadDailyRequestMaterial(action) {
    const {
        data: {
          location_visibility,
          page,
          pageSize,
          filterObj
        }
    } = action.payload;

    try {
        const res = yield call(
            axios.post,
            // `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get/runquere`,
            {
                location_visibility,
                page,
                pageSize,
                filterObj
            },
            { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
          );

        yield put(actions.loadDailyRequestMaterial.success(res.data, page));
    } catch (err) {
      console.log(err.message);
        yield put(actions.loadDailyRequestMaterial.failure());
    }
}

export default function* watchDailyRequestMaterial() {
    yield all([
      takeEvery(actions.LOAD_DAILY_REQUEST_MATERIAL.REQUEST, loadDailyRequestMaterial),
    ]);
}


export {
    loadDailyRequestMaterial,
};