import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import {
    ACCESSTOKEN,
    COMPANY_ID,
    USER_ID,
} from "./../../../constants/local_storage";

// function* createSalesClose(action) {
//     const {
//         data: {
//             files,
//             user_id,
//             accessToken
//         },
//         loading
//     } = action.payload;

//     const formData = new FormData();
//     let uploadFile = null;

    
//     if (files != null) {
//         files.forEach(File => {
//           uploadFile = File;
//         });
//     }

//     formData.append("files", uploadFile);
//     formData.append("user_id", user_id);

//     try {
//         const res = yield call(
//             axios.post,
//                 `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/precast-create-sales-close`,
//                 formData,
//                 {
//                     headers: {
//                         Authorization: `Bearer ${accessToken}`
//                     }
//                 }
//         );
//         loading(res.data);
//         yield put(actions.createSalesClose.success(res.data));
//     } catch (err) {
//         console.log(err);
//         yield put(actions.createSalesClose.failure());
//     }
// }

export default function* watchCpacPrecastClose() {
    yield all([ 
        // takeEvery(actions.CREATE_SALES_CLOSE.REQUEST, createSalesClose),
    ]);
}
export {
    // createSalesClose
};