import React from 'react'
import { ResponsiveRadar } from '@nivo/radar'

const items = [
  {
    taste: 'จุดนอกจุดจอด',
    คะแนน: 39,
    ค่าเฉลี่ยของช่วงเวลาที่เลือก: 36,
  },
  {
    taste: 'ความเร็วเกินกำหนด',
    คะแนน: 83,
    ค่าเฉลี่ยของช่วงเวลาที่เลือก: 111,
  },
  {
    taste: 'ขับต่อเนื่อง > 4 ชม.',
    คะแนน: 56,
    ค่าเฉลี่ยของช่วงเวลาที่เลือก: 119,
  },
  {
    taste: 'ปฏิบัติงาน > 10 ชม./วัน',
    คะแนน: 56,
    ค่าเฉลี่ยของช่วงเวลาที่เลือก: 119,
  },
  {
    taste: 'เลี้ยวกระทันหัน/ไม่เปิดไฟเลี้ยว',
    คะแนน: 69,
    ค่าเฉลี่ยของช่วงเวลาที่เลือก: 56,
  },
  {
    taste: 'ออกตัวกระชาก/เบรคกระทันหัน',
    คะแนน: 49,
    ค่าเฉลี่ยของช่วงเวลาที่เลือก: 114,
  },
]

export default function SafetyGraph({ data }) {
  return (
    <ResponsiveRadar
      data={items}
      keys={['คะแนน', 'ค่าเฉลี่ยของช่วงเวลาที่เลือก']}
      indexBy="taste"
      maxValue="auto"
      margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
      curve="linearClosed"
      borderWidth={2}
      borderColor={{ from: 'color' }}
      gridLevels={5}
      gridShape="linear"
      gridLabelOffset={26}
      enableDots={true}
      dotSize={2}
      dotColor={{ theme: 'background' }}
      dotBorderWidth={2}
      dotBorderColor={{ from: 'color' }}
      enableDotLabel={false}
      dotLabel="value"
      dotLabelYOffset={-12}
      colors={['#00c6ff', '#f85a5a']}
      fillOpacity={0.25}
      blendMode="multiply"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      isInteractive={true}
      legends={[
        {
          anchor: 'top-left',
          direction: 'column',
          translateX: -50,
          translateY: -40,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: '#999',
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  )
}
