import React from "react";
import { Switch, Route } from "react-router-dom";
import PolicyDetail from "./Detail";
import Policy from "./Index";

export default () => (
  <Switch>
    <Route path="/policy/create/" component={PolicyDetail} />
    <Route path="/policy/detail/:id" component={PolicyDetail} />
    <Route path="/policy/edit/:id" component={PolicyDetail} />
    <Route path="/policy" component={Policy} />
  </Switch>
);
