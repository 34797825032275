import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Table,
  message,
  Tooltip,
} from 'antd'

import LangContext, { i18n } from 'modules/shared/context/langContext'
import difference from 'lodash/difference'
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
        }

        return (
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 250 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    vehicleList: [],
    vehicleSelect: [],
    gps_vendorList: [],
    vendor_id: 'all',
    selectedKeys: [],
    date_start: '',
    date_end: '',
    clickMenu: '',
  }

  leftTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <LangContext.Consumer>{i18n => i18n.i.itemLeft}</LangContext.Consumer>
      ),
      posiction: 'Left',
    },
  ]

  rightTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <LangContext.Consumer>
          {i18n => i18n.i.itemSelected}
        </LangContext.Consumer>
      ),
      posiction: 'Right',
    },
  ]

  componentDidMount() {
    const { loadVehicle, call } = this.props

    if (call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.vehicles,
        })
      }
    }
    loadVehicle('', false)
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleLists } = nextProps
    const vehlist = []
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  onGPSVendorChange = (value, vendor_id, setFieldValue) => {
    const { loadVehicle } = this.props
    setFieldValue('gps_vendor_id', value)
    this.setState({
      vehicleList: [],
      vehicleSelect: [],
      vehicleSelectedKeys: [],
      selectedKeys: [],
    })
    setFieldValue('list_vehicle_id', [])
    loadVehicle(vendor_id, true)
  }

  tranferChange = (targetKeys, setFieldValue) => {
    if (targetKeys.length > 2000) {
      message.error('เลือกรถได้ไม่เกิน 2000 คัน')
    } else {
      setFieldValue('list_vehicle_id', targetKeys)
      this.setState({ vehicleSelect: targetKeys })
    }
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleClicReportkPreview = values => {
    let vendor = 'all'
    if (values.gps_vendor_id !== '') vendor = values.gps_vendor_id

    const date_start = moment(values.eventdate[0]['_d']).format(
      'YYYY-MM-DD HH:mm:00',
    )
    const date_end = moment(values.eventdate[1]['_d']).format(
      'YYYY-MM-DD HH:mm:59',
    )

    const filter = {
      start_date: date_start,
      end_date: date_end,
      vehicles: values.list_vehicle_id,
      vendor_id: vendor.toString(),
      type_file: 'preview',
      company_id: this.props.auth.profile.company_id,
    }

    window.open('/report/gps-disconnect-on-trip/preview', '_blank')
    localStorage.setItem(
      'gpsdisconnectontripreport-filter',
      JSON.stringify(filter),
    )
  }

  //validation birthday
  disabledDate(current) {
    return current && current > moment().subtract(1, 'days')
  }

  render() {
    const { GPSVendorLists, loading, onSubmit } = this.props
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <Spin spinning={loading}>
          <Formik
            initialValues={{
              eventdate: [
                moment('00:00', 'HH:mm').subtract(1, 'days'),
                moment('23:59', 'HH:mm').subtract(1, 'days'),
              ],
              gps_vendor_id: '',
              list_vehicle_id: this.props.dataedit
                ? JSON.parse(this.props.dataedit).vehicles
                : [],
            }}
            validate={values => {
              let errors = {}
              if (values.eventdate.length > 0) {
                const date_start = moment(values.eventdate[0]['_d'])
                const date_end = moment(values.eventdate[1]['_d'])
                let day_diff = date_end.diff(date_start, 'months')
                if (day_diff > 0) {
                  errors.eventdate = (
                    <LangContext.Consumer>
                      {i18n => i18n.o.overOneMonth}
                    </LangContext.Consumer>
                  )
                }
              }

              if (values.list_vehicle_id.length < 1) {
                errors.list_vehicle_id = (
                  <LangContext.Consumer>
                    {i18n => i18n.r.require}
                  </LangContext.Consumer>
                )
              }
              if (values.list_vehicle_id.length > 2000) {
                errors.list_vehicle_id = 'เลือกรถได้ไม่เกิน 2000 คัน'
              }

              return errors
            }}
            onSubmit={values => {
              if (this.state.clickMenu === 'preview') {
                this.handleClicReportkPreview(values)
              } else {
                onSubmit(values, loading)
              }
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating,
            }) => (
              <Row type="flex" justify="center">
                <LangContext.Consumer>
                  {i18n => (
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        <LangContext.Consumer>
                          {i18n => i18n.g.gpsDisconnectOnTrip}
                        </LangContext.Consumer>
                      </div>
                      <FormItem label={i18n.g.gpsVendor}>
                        <Select
                          defaultValue=""
                          onChange={(value, key) => {
                            this.onGPSVendorChange(
                              value,
                              key.key,
                              setFieldValue,
                            )
                          }}
                          value={values.gps_vendor_id}
                        >
                          <Option key="" value="">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          {GPSVendorLists.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>

                      <FormItem
                        label={i18n.v.vehicle}
                        required={true}
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'warning'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div style={{ width: '750px' }}>
                          <TableTransfer
                            dataSource={this.state.vehicleList}
                            targetKeys={values.list_vehicle_id}
                            showSearch
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                            onSelectChange={this.handleSelectChange}
                            onChange={targetKeys => {
                              this.tranferChange(targetKeys, setFieldValue)
                            }}
                            leftColumns={[
                              {
                                dataIndex: 'name',
                                title: (
                                  <div className="regular-bold-14">
                                    <LangContext.Consumer>
                                      {i18n => i18n.i.itemLeft}
                                    </LangContext.Consumer>
                                  </div>
                                ),
                                posiction: 'Left',
                              },
                            ]}
                            rightColumns={[
                              {
                                dataIndex: 'name',
                                title: (
                                  <div className="regular-bold-14">
                                    <LangContext.Consumer>
                                      {i18n => i18n.i.itemSelected}
                                    </LangContext.Consumer>
                                  </div>
                                ),
                                posiction: 'Right',
                              },
                            ]}
                          />
                        </div>
                      </FormItem>
                      {this.props.call !== 'schedule' ? (
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.d.dateRange}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.eventdate && errors.eventdate && 'warning'
                          }
                          help={touched.eventdate && errors.eventdate}
                        >
                          <RangePicker
                            disabledDate={disabledDate}
                            showTime={{ format: 'HH:mm' }}
                            onChange={value =>
                              setFieldValue('eventdate', value)
                            }
                            format="DD/MM/YYYY HH:mm"
                            placeholder={['Start Time', 'End Time']}
                            defaultValue={[
                              moment('00:00', 'HH:mm').subtract(1, 'days'),
                              moment('23:59', 'HH:mm').subtract(1, 'days'),
                            ]}
                          />
                        </Form.Item>
                      ) : (
                        ''
                      )}
                      <br></br>
                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call == 'schedule' ? (
                            <Col span={8} style={{ marginTop: '10px' }}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            <>
                              <Col span={8}>
                                <Button type="defualt" block>
                                  <Link to="/">
                                    <LangContext.Consumer>
                                      {i18n => i18n.c.cancel}
                                    </LangContext.Consumer>
                                  </Link>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  block
                                  onClick={() =>
                                    this.setState({
                                      clickMenu: 'preview',
                                    })
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.preview}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  onClick={() => {
                                    this.setState({
                                      clickMenu: 'report',
                                    })
                                  }}
                                  block
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.r.report}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                            </>
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  )}
                </LangContext.Consumer>
              </Row>
            )}
          />
        </Spin>
      </div>
    )
  }
}
