import React, { Component } from 'react'
import { Row, Col, Collapse, Card, Icon, Breadcrumb, Popover } from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Dashboard extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        {/* การจัดส่งคอนกรีต */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'concreteDeliveryManagerDashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/concrete_delivery_manager">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.c.concreteDeliveryManagerDashboard}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับติดตามสถานะปัจจุบันของการจัดส่งคอนกรีต
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <span className="color__text__card__web__content">
                    <LangContext.Consumer>
                      {i18n => i18n.c.concreteDeliveryManagerDashboard}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* แผนบันทึกการใช้รถโม่เล็ก */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'smartDispatching',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/smartDispatching">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.s.smartDispatching}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับบันทึกพื้นที่เส้นทางการใช้รถโม่เล็กในการจัดส่งคอนกรีต
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.s.smartDispatching}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* แดชบอร์ดภาพรวมการขนส่งคอนกรีต */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'truckstatusdashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/truckstatusdashboard">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.truckstatusdashboard}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแสดงข้อมูลสรุปภาพรวมการจัดส่งคอนกรีตแยกตามสถานะ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.t.truckstatusdashboard}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* แดชบอร์ดเเจ้งเตือนเข้าผิดหน่วยงาน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'notifyTheTruckInCorrectPoint',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/truckincorrectpoint">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.n.notifyTheTruckInCorrectPoint}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแจ้งเตือนเมื่อมีการเทปูนนอกจุดหรือผิดจุด
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.n.notifyTheTruckInCorrectPoint}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* ความปลอดภัย */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'SafetyDashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/safety_dashboard">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.s.safetyDashboard}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแสดงพฤติกรรมการขับขี่ที่ไม่ปลอดภัย
                          ตามเงื่อนไขที่กำหนด
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.s.safetyDashboard}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* ประสิทธิภาพ GPS */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'gpsPerformanceDashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/gps_performance_dashboard">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.g.gpsPerformanceDashboard}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแสดงประสิทธิภาพของการใช้งาน GPS และ Sensor
                          ต่างๆ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.g.gpsPerformanceDashboard}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}

        {/* monitor gps */}
        {checkMenuVisibility('', web_endpoint, 'monitorGPS', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/monitor-gps">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.m.monitorGPS}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแสดงประสิทธิภาพของการใช้งาน GPS และ Sensor
                          ต่างๆในแต่ละวัน
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* การขนส่งวัตถุดิบ */}
        {checkMenuVisibility(
          'isupply',
          web_endpoint,
          'deliveryDashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/isupply-dashboard">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.deliveryDashboard}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับติดตามสถานะการขนส่งวัตถุดิบ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text__card__web__content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.d.deliveryDashboard}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* ประสิทธิภาพการขนส่งวัตถุดิบ */}
        {checkMenuVisibility(
          'isupply',
          web_endpoint,
          'transportationPerformanceDashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/isupply-overview-performance-dashboard">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.transportationPerformanceDashboard}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแสดงภาพรวมประสิทธิภาพการใช้งานรถของผู้รับเหมา
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.t.transportationPerformanceDashboard}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}

        {/* การติดตามวัตถุดิบ */}
        {checkMenuVisibility(
          'isupply',
          web_endpoint,
          'theStatusOfVehiclesThatReceiveDailyJobs',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/isupply-status-vehicles-receive-daily-jobs">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.theStatusOfVehiclesThatReceiveDailyJobs}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับติดตามสถานะการขนส่งวัตถุดิบตามช่วงเวลา
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text__card__web__content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.t.theStatusOfVehiclesThatReceiveDailyJobs}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* การจัดการคิวรถ */}
        {checkMenuVisibility(
          'isupply',
          web_endpoint,
          'isupplyManageQueue',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/isupply-manage-queue">
              <Card className="card__web__content">
                <p className="text__card__web__content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.i.isupplyManageQueue}
                    </LangContext.Consumer>
                  </span>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* สถานะขนส่งพื้น-ผนัง */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'precastDashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/precast-dashboard">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.p.precastDashboard}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับติดตามสถานะปัจจุบันของการขนส่งพื้น-ผนัง
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text__card__web__content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.p.precastDashboard}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* ติดตามสถานะการจัดส่งทริป */}
        {checkMenuVisibility('', web_endpoint, 'multiDrop', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/multi_drop">
              <Card className="card__web__content">
                <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.m.multiDrop}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* การแจ้งเตือนการฝ่าฝืน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'alert_alarm_dashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/alert_alarm">
              <Card className="card__web__content">
                <p className="text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.a.alertAlarm}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* ภาพรวมพิกัดการฝ่าฝืน */}
        {checkMenuVisibility('', web_endpoint, 'eventMap', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/event_map">
              <Card className="card__web__content">
                <p className="text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.e.eventMap}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปการใช้พาหนะ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'driverKpiDashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/driver-kpi">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.driverKpiDashboard}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแสดงข้อมูลสรุปการใช้งานพาหนะ
                          (การขับขี่อย่างปลอดภัย, การขับขี่อย่างประหยัด)
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <span className="color__text__card__web__content">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.d.driverKpiDashboard}
                    </LangContext.Consumer>
                  </span>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Dashboard
