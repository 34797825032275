import { Modal, Button, Row, Col, Icon } from 'antd'
import React, { useState } from 'react'
import LangContext from 'modules/shared/context/langContext'

const ModalEditAndDeleteRoute = ({
  showModal,
  onClose,
  objRoute,
  deletelocation,
  editlocation,
}) => {
  return (
    <>
      {objRoute && (
        <Modal
          title={
            <div style={{ textAlign: 'center' }}>{objRoute[0].route_name}</div>
          }
          centered
          visible={showModal}
          onCancel={() => {
            onClose()
          }}
          width={450}
          footer={false}
          className="modal-edit-delete-route-smart-dispatching"
        >
          <Row type="flex" justify="center" gutter={[4, 16]}>
            <Col span={24} align="center">
              <label style={{ color: '#F53C56', fontWeight: 600 }}>
                <LangContext.Consumer>
                  {i18n => i18n.n.note}
                </LangContext.Consumer>
                : {objRoute[0].remark}
              </label>
              <br />
              {objRoute[0].update_user_firstname ||
              objRoute[0].update_user_lastname ? (
                <label style={{ color: '#D6D6D6', fontWeight: 600 }}>
                  <LangContext.Consumer>
                    {i18n => i18n.e.editedBy}
                  </LangContext.Consumer>{' '}
                  :{' '}
                  {objRoute[0].update_user_firstname +
                    ' ' +
                    objRoute[0].update_user_lastname}
                </label>
              ) : (
                ''
              )}
            </Col>
            <Col span={12} align="right">
              <Button
                style={{
                  width: '124px',
                  height: '124px',
                  borderRadius: '10px',
                  boxShadow: '0px 3px 11px #00000029',
                }}
                onClick={() => {
                  editlocation()
                  onClose()
                }}
              >
                <Icon
                  type="edit"
                  style={{ fontSize: '50px', paddingTop: '20px' }}
                />
                <p>
                  <LangContext.Consumer>
                    {i18n => i18n.e.editRoute}
                  </LangContext.Consumer>
                </p>
              </Button>
            </Col>
            <Col span={12} align="left">
              <Button
                style={{
                  width: '124px',
                  height: '124px',
                  borderRadius: '10px',
                  boxShadow: '0px 3px 11px #00000029',
                }}
                onClick={() => {
                  deletelocation()
                  onClose()
                }}
              >
                <Icon
                  type="delete"
                  style={{ fontSize: '50px', paddingTop: '20px' }}
                />
                <p>
                  <LangContext.Consumer>
                    {i18n => i18n.d.deleteRoute}
                  </LangContext.Consumer>
                </p>
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  )
}

export default ModalEditAndDeleteRoute
