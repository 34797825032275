import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import { func } from 'prop-types'

function* loadBookingOrderList(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      company_id,
      filterObj,
      limit_export,
      type,
      file_value_pdf,
      accessToken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-booking-order-list`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        company_id,
        filterObj,
        limit_export,
        type,
        file_value_pdf,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadBookingOrderList.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadBookingOrderList.failure())
  }
}

function* createiSupplyBookingOrder(action) {
  const {
    data: {
      booking_contact_name,
      booking_contact_phone,
      customer_name,
      customer_phone_primary,
      customer_phone_secondary,
      customer_line_id,
      customer_email,
      location_id,
      province,
      address,
      landmark,
      date,
      start_at,
      end_at,
      quantity,
      vehicle_type_id,
      vehicle_id,
      driver_id,
      companies_id,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/create-booking-order`,
      {
        booking_contact_name,
        booking_contact_phone,
        customer_name,
        customer_phone_primary,
        customer_phone_secondary,
        customer_line_id,
        customer_email,
        location_id,
        province,
        address,
        landmark,
        date,
        start_at,
        end_at,
        quantity,
        vehicle_type_id,
        vehicle_id,
        driver_id,
        companies_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createiSupplyBookingOrder.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createiSupplyBookingOrder.failure())
  }
}

function* updateiSupplyBookingOrder(action) {
  const {
    data: {
      id,
      booking_contact_name,
      booking_contact_phone,
      customer_name,
      customer_phone_primary,
      customer_phone_secondary,
      customer_line_id,
      customer_email,
      location_id,
      province,
      address,
      landmark,
      date,
      start_at,
      end_at,
      quantity,
      vehicle_type_id,
      vehicle_id,
      driver_id,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/update-booking-order`,
      {
        id,
        booking_contact_name,
        booking_contact_phone,
        customer_name,
        customer_phone_primary,
        customer_phone_secondary,
        customer_line_id,
        customer_email,
        location_id,
        province,
        address,
        landmark,
        date,
        start_at,
        end_at,
        quantity,
        vehicle_type_id,
        vehicle_id,
        driver_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateiSupplyBookingOrder.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateiSupplyBookingOrder.failure())
  }
}

function* loadSupplyBookingOrderDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-booking-order-detail`,
      {
        id: id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadSupplyBookingOrderDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadSupplyBookingOrderDetail.failure())
  }
}

function* exportSupplyBookingOrder(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      company_id,
      filterObj,
      limit_export,
      type,
      file_value_pdf,
      accessToken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-booking-order-list`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        company_id,
        filterObj,
        limit_export,
        type,
        file_value_pdf,
        accessToken,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(
      actions.exportSupplyBookingOrder.success(res.data, page, pageSize),
    )
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportSupplyBookingOrder.failure())
  }
}

function* loadBookingOrderDetailForSuggestLocation(action) {
  const {
    data: { location_id, accessToken },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-booking-order-detail-for-suggest-location`,
      {
        location_id: location_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(
      actions.loadBookingOrderDetailForSuggestLocation.success(res.data),
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadBookingOrderDetailForSuggestLocation.failure())
  }
}

export default function* watchIspplyBookingOrder() {
  yield all([
    takeEvery(
      actions.LOAD_ISUPPLY_BOOKING_ORDER_LIST.REQUEST,
      loadBookingOrderList,
    ),
    takeEvery(
      actions.CREATE_ISUPPLY_BOOKING_ORDER.REQUEST,
      createiSupplyBookingOrder,
    ),
    takeEvery(
      actions.LOAD_ISUPPLY_BOOKING_ORDER_DETAIL.REQUEST,
      loadSupplyBookingOrderDetail,
    ),
    takeEvery(
      actions.UPDATE_ISUPPLY_BOOKING_ORDER.REQUEST,
      updateiSupplyBookingOrder,
    ),
    takeEvery(
      actions.EXPORT_ISUPPLY_BOOKING_ORDER.REQUEST,
      exportSupplyBookingOrder,
    ),
    takeEvery(
      actions.LOAD_ISUPPLY_BOOKING_ORDER_DETAIL_FOR_SUGGEST_LOCATION.REQUEST,
      loadBookingOrderDetailForSuggestLocation,
    ),
  ])
}
export {
  loadBookingOrderList,
  createiSupplyBookingOrder,
  loadSupplyBookingOrderDetail,
  updateiSupplyBookingOrder,
  exportSupplyBookingOrder,
  loadBookingOrderDetailForSuggestLocation,
}
