import React, { Component, Fragment } from 'react'
import { Row, Col, Collapse, Card, Icon, Breadcrumb, Divider } from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Index extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        <div className="divider__web__content">
          <Divider />
        </div>
        {/* เหตุการณ์พาหนะรายคัน */}
        {checkMenuVisibility('', web_endpoint, 'EventReport', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/event">
              <Card className="card__web__content">
                <p className="text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.e.EventReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Index
