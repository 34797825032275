import React from 'react'
import { Row, Col } from 'antd'

const VehicleMarker = ({
  vehicleImg,
  stopImg,
  title,
  lat,
  lng,
  engine,
  speed,
  vehicleStatus,
  drumDirection,
  directionDegree,
  onClick,
  transform = 'translate(-50%, -180%)',
  directionTrasform = 'translate(-50%, -50%)',
}) => {
  // find vehicle status color

  let width = '72px'
  if (typeof title != 'undefined') {
    let titleLength = title.length

    if (titleLength >= 12 && titleLength < 17) width = '80px'
    else if (titleLength >= 17 && titleLength < 22) width = '105px'
    else if (titleLength >= 22 && titleLength < 27) width = '130px'
    else if (titleLength >= 27 && titleLength < 37) width = '155px'
    else if (titleLength >= 37) width = '195px'
  }

  let color = ''
  if (drumDirection == 1) {
    color = '#5D3FD3'
  } else if (vehicleStatus == 'running') {
    color = '#00BF06'
  } else if (vehicleStatus == 'stop-with-engine-on') {
    color = '#FFBF00'
  } else if (vehicleStatus == 'stop-with-engine-off') {
    color = '#F10000'
  } else if (vehicleStatus == 'connection-error') {
    color = '#848484'
  } else {
    color = '#1692ff'
  }
  let imgUrl = ''
  let isStopImg = false
  if (
    directionDegree > -1 &&
    (speed == undefined || (speed != undefined && Number(speed) > 2.7))
  ) {
    var min = directionDegree * 35 * -1
    var max = min + 35

    imgUrl = `url('${vehicleImg}') no-repeat scroll  ${min}px 0px transparent`
    isStopImg = false
  } else {
    var min = 0
    var max = min + 25
    imgUrl = `url('${stopImg}') no-repeat scroll  ${min}px 0px transparent`
    isStopImg = true
  }

  return (
    <div vehiclecode={title}>
      <div>
        {typeof title != 'undefined' && (
          <div
            style={{
              zIndex: 50,
              minWidth: width,
              maxWidth: '100px',
              padding: '4px 2px 4px 0px',
              display: 'inline-flex',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              transform: transform,
              color: '#fff',
              backgroundColor: `${color}`,
              margin: ' 0 1em 1em 0',
              borderLeft: `11px solid ${color}`,
            }}
            onClick={onClick}
          >
            <span>
              <div>{title}</div>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* <div
          style={{
            zIndex: 50,
            width: "50px",
            padding: "0px 10px 15px 10px",
            display: "inline-flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            transform: "translate(-50%, -50%)",
          }}
        > */}
        <div
          style={{
            height: isStopImg ? '25px' : '35px',
            width: isStopImg ? '25px' : '35px',
            // padding: "0px 10px 15px 10px",
            marginLeft: '4px',
            top: '0',
            position: 'absolute',
            background: imgUrl,
            zIndex: 50,
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            transform: directionTrasform,
          }}
          onClick={onClick}
        />
        {/* </div> */}
      </div>
    </div>
  )
}

export default VehicleMarker
