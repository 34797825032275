import Axios from 'axios'

const getVehicleFilter = (vehicle_visibility, selectedVehicle) => {
  if (selectedVehicle.length !== 0) {
    return selectedVehicle
  }

  return vehicle_visibility.split(',').map(item => {
    return parseInt(item)
  })
}
const loadReportData = data => {
  return Axios.post(
    `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/unauthorizeunload`,
    {
      ...data,
    },
  )
  //   window.location = res.data.result
}

const getFilterName = (unauthorizeunload, key, values) => {
  if (values === 'all') return 'ทั้งหมด'
  let tdivision = unauthorizeunload[key].find(x => {
    return x.id === values
  })
  return tdivision.name
}

export { getVehicleFilter, loadReportData, getFilterName }
