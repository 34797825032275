import {
  Row,
  Col,
  Form,
  Select,
  Transfer,
  Table,
  DatePicker,
  Button,
} from 'antd'
import * as yup from 'yup'

import { Formik } from 'formik'
import React, { Component, Fragment } from 'react'
import LangContext from 'modules/shared/context/langContext'
import difference from 'lodash/difference'
import moment from 'moment'

const { RangePicker } = DatePicker

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <div
          onClick={() => {
            restProps.onChange(restProps.dataSource.map(item => item.key))
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
        <div
          onClick={() => {
            restProps.onChange([])
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
          //   hideDefaultSelections: true,
          //   selections: [
          //     {
          //       //   key: 'all-data',
          //       text: 'Select All Data',
          //       onSelect: (record, selected, selectedRows, nativeEvent) => {
          //         let getKey = []
          //         filteredItems.map(item => getKey.push(item.key))
          //         onItemSelectAll([...getKey], true)
          //       },
          //     },
          //   ],
        }

        return (
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 100 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

export default class Forms extends Component {
  state = {
    vehicleLists: [],
    vehicleSelect: [],
    list_vehicle_id: [],
  }

  handleFormatVehicle = arrVehi => {
    let newArrVehi = arrVehi.map(v => {
      return { key: v.id, name: v.plate_no }
    })
    return newArrVehi
  }

  createSelectOption = items => {
    return items.map(ele => (
      <Select.Option value={ele.id}>{ele.name}</Select.Option>
    ))
  }

  createSelectAll = () => {
    return (
      <Select.Option value="all">
        <LangContext.Consumer>{i18n => i18n.a.all}</LangContext.Consumer>
      </Select.Option>
    )
  }

  componentDidMount() {
    const { call } = this.props

    if (call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          list_vehicle_id: obj.vehicle_list,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  componentDidUpdate(prevProps, prevState) {
    let btn = document.getElementById('finding-btn')
    if (btn !== null) {
      let botton = btn.querySelectorAll('button')
      this.Leftbtn = botton[0]
      this.Rightbtn = botton[1]
    }
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys, list_vehicle_id: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleMenuClick = (setFieldValue, handleSubmit, typeFile) => {
    setFieldValue('file_type', typeFile, false)
    handleSubmit()
  }
  handleDateFormat = value => {
    const new_format = moment(value['_d']).format('YYYY-MM-DD HH:mm:ss')
    return new_format
  }

  handleFilterValues = (val, fliterArr) => {
    let keysArr = Object.entries(val)
    let fliteredValue = Object.fromEntries(
      keysArr.filter(([key, value]) => {
        return !fliterArr.includes(key)
      }),
    )

    return fliteredValue
  }

  handleSeachVehicle = (inputValue, item) => {
    let check = item.name.toUpperCase().indexOf(inputValue.toUpperCase())
    if (check !== -1) return check
    return item.key.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
  }

  render() {
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <Fragment>
        <Formik
          initialValues={{
            division: this.props.selectedDivision,
            department: this.props.selectedDepartment,
            section: this.props.selectedSection,
            plant: this.props.selectedPlant,
            vehicleGroup: this.props.selectedVehicleGroup,
            list_vehicle_id: this.state.list_vehicle_id,
            eventdate: [
              moment()
                .hours(0)
                .minutes(0)
                .seconds(0),
              moment()
                .hours(23)
                .minutes(59)
                .seconds(59),
            ],
          }}
          validationSchema={yup.object().shape({
            eventdate: yup.array().required('กรุณาเลือกช่วงวันที่'),
            list_vehicle_id: yup.array().required('กรุณาเลือก'),
          })}
          onSubmit={values => {
            values.date_start = this.handleDateFormat(values.eventdate[0])
            values.date_end = this.handleDateFormat(values.eventdate[1])

            this.props.onSubmit(values)
          }}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Row type="flex" justify="center">
              <LangContext.Consumer>
                {i18n => (
                  <Form onSubmit={handleSubmit}>
                    <div className="semi-bold-16">
                      <LangContext.Consumer>
                        {i18n => i18n.o.outsidePoint}
                      </LangContext.Consumer>
                    </div>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.division}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        value={values.division}
                        onSelect={v => {
                          setFieldValue('division', v)
                          setFieldValue('department', 'all')
                          setFieldValue('section', 'all')
                          setFieldValue('plant', ['all'])
                          setFieldValue('vehicleGroup', 'all')
                          setFieldValue('list_vehicle_id', [])

                          this.props.handleChangeState('division', v)
                        }}
                      >
                        {this.createSelectAll()}
                        {this.createSelectOption(this.props.division)}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.department}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        value={values.department}
                        onSelect={v => {
                          setFieldValue('department', v)
                          setFieldValue('section', 'all')
                          setFieldValue('vehicleGroup', 'all')
                          setFieldValue('plant', ['all'])
                          setFieldValue('list_vehicle_id', [])

                          this.props.handleChangeState('department', v)
                        }}
                      >
                        {this.createSelectAll()}
                        {this.createSelectOption(this.props.department)}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.s.section}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        value={values.section}
                        onSelect={v => {
                          setFieldValue('section', v)
                          setFieldValue('plant', ['all'])
                          setFieldValue('vehicleGroup', 'all')
                          setFieldValue('list_vehicle_id', [])
                          this.props.handleChangeState('section', v)
                        }}
                      >
                        {this.createSelectAll()}
                        {this.createSelectOption(this.props.section)}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.p.plants}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        value={values.plant}
                        mode="multiple"
                        onChange={e => {
                          let val = e
                          if (e.length > 1 && e.includes('all')) {
                            if (e[0] === 'all') val = e.filter(x => x !== 'all')
                            else val = e.filter(x => x === 'all')
                          }
                          if (e.length === 0) {
                            val = 'all'
                          }

                          setFieldValue('plant', val)
                          setFieldValue('vehicleGroup', 'all')
                          setFieldValue('list_vehicle_id', [])
                          this.props.handleChangeState('plant', val)
                        }}
                      >
                        {this.createSelectAll()}
                        {this.createSelectOption(this.props.plant)}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicleGroup}
                        </LangContext.Consumer>
                      }
                    >
                      <Select
                        value={values.vehicleGroup}
                        onSelect={v => {
                          setFieldValue('vehicleGroup', v)
                          this.props.handleChangeState('vehiclegroup', v)
                        }}
                      >
                        {this.createSelectAll()}
                        {this.createSelectOption(this.props.vehicleGroup)}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicle}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.list_vehicle_id &&
                        errors.list_vehicle_id &&
                        'warning'
                      }
                      help={touched.list_vehicle_id && errors.list_vehicle_id}
                    >
                      <div style={{ width: '750px' }}>
                        <TableTransfer
                          name="list_vehicle_id"
                          showSearch
                          dataSource={this.handleFormatVehicle(
                            this.props.vehicles,
                          )}
                          leftColumns={[
                            {
                              dataIndex: 'name',
                              title: (
                                <LangContext.Consumer>
                                  {i18n => i18n.i.itemLeft}
                                </LangContext.Consumer>
                              ),
                              posiction: 'Left',
                            },
                          ]}
                          rightColumns={[
                            {
                              dataIndex: 'name',
                              title: (
                                <LangContext.Consumer>
                                  {i18n => i18n.i.itemSelected}
                                </LangContext.Consumer>
                              ),
                              posiction: 'Right',
                            },
                          ]}
                          filterOption={(inputValue, item) => {
                            return (
                              item.name
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            )
                          }}
                          onChange={v => {
                            this.tranferChange(v, setFieldValue)
                          }}
                          onSelectChange={this.handleSelectChange}
                          targetKeys={this.state.list_vehicle_id}
                        />
                      </div>
                    </Form.Item>
                    {this.props.call == 'schedule' ? null : (
                      <Form.Item
                        required
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.d.dateRange}
                          </LangContext.Consumer>
                        }
                      >
                        <RangePicker
                          disabledDate={disabledDate}
                          value={values.eventdate}
                          onChange={v => {
                            setFieldValue('eventdate', v)
                          }}
                          format="DD/MM/YYYY HH:mm"
                          showTime={{ format: 'HH:mm' }}
                        ></RangePicker>
                      </Form.Item>
                    )}

                    <Row gutter={[8, 8]}>
                      {this.props.call == 'schedule' ? (
                        <Col span={8} style={{ marginTop: '10px' }}>
                          <Button type="primary" htmlType="submit" block>
                            <LangContext.Consumer>
                              {i18n => i18n.s.save}
                            </LangContext.Consumer>
                          </Button>
                        </Col>
                      ) : (
                        <>
                          <Col span={8}>
                            <Button type="defualt" block>
                              <LangContext.Consumer>
                                {i18n => i18n.c.cancel}
                              </LangContext.Consumer>
                            </Button>
                          </Col>
                          <Col span={8}>
                            <Button
                              type="primary"
                              onClick={() =>
                                this.handleMenuClick(
                                  setFieldValue,
                                  handleSubmit,
                                  'preview',
                                )
                              }
                              block
                            >
                              <LangContext.Consumer>
                                {i18n => i18n.p.preview}
                              </LangContext.Consumer>
                            </Button>
                          </Col>
                          <Col span={8}>
                            <Button
                              type="primary"
                              onClick={() =>
                                this.handleMenuClick(
                                  setFieldValue,
                                  handleSubmit,
                                  'excel',
                                )
                              }
                              block
                            >
                              <LangContext.Consumer>
                                {i18n => i18n.r.report}
                              </LangContext.Consumer>
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Form>
                )}
              </LangContext.Consumer>
            </Row>
          )}
        ></Formik>
      </Fragment>
    )
  }
}
