import { createTypes, createAction } from 'lib/action'
const LOAD_SALEORDER = createTypes('loadsaleorder', 'load')
const EXPORT_SALEORDER = createTypes('exportsaleorder', 'export')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadSaleorder = {
  request: (data, loading) =>
    createAction(LOAD_SALEORDER.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_SALEORDER.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_SALEORDER.FAITLURE),
}

const exportSaleorder = {
  request: (data, loading) =>
    createAction(EXPORT_SALEORDER.REQUEST, { data, loading }),
  success: lists => createAction(EXPORT_SALEORDER.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_SALEORDER.FAITLURE),
}
export {
  REMOVE_LISTS,
  removeLists,
  LOAD_SALEORDER,
  loadSaleorder,
  EXPORT_SALEORDER,
  exportSaleorder,
}
