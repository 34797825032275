import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Button, Form, Input, Alert, Icon, Col, Row, Tabs } from 'antd'

const Index = ({ auth, onSubmit: authFormSubmit }) => {
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        loginType: 'bluenet',
        userType: 'New',
        token: '',
      }}
      validationSchema={yup.object().shape({
        token: yup.string().required(),
      })}
      onSubmit={values => authFormSubmit(values)}
      render={({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Item
            // validateStatus={auth.msg != '' ? 'error' : null}
            // help={auth.msg != '' ? 'Token wrong or Token expired!' : null}
            validateStatus={
              auth.status != ''
                ? auth.status == 'error'
                  ? auth.status
                  : null
                : touched.token && errors.token
                ? 'warning'
                : null
            }
            help={
              auth.status != ''
                ? auth.status == 'error'
                  ? auth.msg
                  : null
                : touched.token && errors.token
                ? 'Require field'
                : null
            }
          >
            <Input
              type="text"
              name="token"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Token"
              values={values.token}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.token && errors.token ? 'invalid' : undefined}
              style={{ margin: '0px 0px 5px 0px' }}
            />
          </Form.Item>

          <Button htmlType="submit" style={{ width: '100%' }}>
            Login
          </Button>
        </Form>
      )}
    />
  )
}

export default Index
