import {
    LOAD_REQUEST_DELIVERY_REPORT,
    EXPORT_REPORT,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    total: 1,
    loading: false,
    draw: -1,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_REQUEST_DELIVERY_REPORT.REQUEST:
            const { loading } = action.payload;
                return {
                    ...state,
                    loading: loading,
                    check: true
                };

        case LOAD_REQUEST_DELIVERY_REPORT.SUCCESS:        
                return {
                    ...state,
                    lists: action.payload.lists.data,
                    check: false,
                    total: 
                            action.payload.lists.total - 1 <= 0
                            ? 1
                            : action.payload.lists.total <= 0
                            ? 1
                            : action.payload.lists.total,
                            loading: false,
                        draw: Math.floor(Math.random() * 999 + 1)
                };
        case EXPORT_REPORT.REQUEST:
            return {
                ...state,
                loading: true,
            };

        case EXPORT_REPORT.SUCCESS:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}