import { CALL_API } from 'middleware/api'
import history from 'lib/history'
import { createTypes, createAction } from 'lib/action'

const CHECK_OAUTH_LOGIN = createTypes('oauthlogin', 'check')

const BASE_ACTION = 'app/oauth'

const LOAD_CREDENTIALS = `${BASE_ACTION}/credentials/load`
const REMOVE_CREDENTIALS = `${BASE_ACTION}/credentials/remove`
const CREATE_USER_REQUEST = `${BASE_ACTION}/register/request`
const CREATE_USER_SUCCESS = `CREATE_USER_SUCCESS`
const CREATE_USER_FAILURE = `/register/failure`
const SET_CONFIG_TRACKING_COLUMNS = `${BASE_ACTION}/config/set/trackingcol`
const SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS = `${BASE_ACTION}/config/set/trackingcolregis`
const SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS = `${BASE_ACTION}/config/set/trackingcoldriver`
const SET_CONFIG_HOME_DASHBOARD_WIDGET = `${BASE_ACTION}/config/set/homedashboard/widget/position`
const SET_ORG_CHART_FILTER = `${BASE_ACTION}/config/set/org_chart_filter`
const SET_ORG_CHART_TRACKING_FILTER = `${BASE_ACTION}/config/set/org_chart_tracking_filter`
const SET_LOCATION_VISIBILITY = `${BASE_ACTION}/set/location_visibility`
const SET_VEHICLE_VISIBILITY = `${BASE_ACTION}/set/vahicle_visibility`
const SET_LOCATION_GROUP_VISIBILITY = `${BASE_ACTION}/set/location_group_visibility`
const SET_VEHICLE_GROUP_VISIBILITY = `${BASE_ACTION}/set/vehicle_group_visibility`
const SET_MATERIAL_VISIBILITY = `${BASE_ACTION}/set/material_visibility`
const CHANGE_LANG = `CHANGE_LANG`
//const LOAD_FAVORITE_MENU = `${BASE_ACTION}/favorite/load`;
const LOAD_FAVORITE_MENU = createTypes('favorite', 'load')
const CREATE_FAVORITE_MENU = createTypes('favorite', 'create')
const DELETE_FAVORITE_MENU = createTypes('favorite', 'delete')

const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value)
    })
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key)
    })
  },
}

function loadCredentials() {
  const token = localStorage.getItem('access-token')
  const profile = JSON.parse(localStorage.getItem('profile') || '{}')

  let currentTime = null
  let tokenExp = null
  let done = false
  let msg = ''

  if (JSON.stringify(profile) !== '{}') {
    done = true
    tokenExp = profile.exp

    currentTime = Math.floor(Date.now() / 1000)
    if (currentTime > tokenExp) {
      done = false
      msg = 'Token Expired'
      localStorage.setItem('count-appove-request-vehicle', 0)
    }
  }

  return {
    type: LOAD_CREDENTIALS,
    payload: { token, tokenExp, profile, done, msg },
  }
}

function removeCredentials() {
  localStorage.removeItem('access-token')
  localStorage.removeItem('profile')
  localStorage.removeItem('count-noti')
  localStorage.removeItem('collectVehicleStatus')
  localStorage.removeItem('count-appove-request-vehicle')
  localStorage.removeItem('collectSearchCriteria')
  localStorage.removeItem('collectSearchName')
  localStorage.removeItem('collectMasterDataSelectvalue')
  localStorage.removeItem('collectVehicleStatus')
  localStorage.removeItem('collectSelectedVendor')
  localStorage.removeItem('collectSpeedRange')
  localStorage.removeItem('collectTimeRange')
  document.cookie =
    'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  return {
    type: REMOVE_CREDENTIALS,
  }
}

function storeCredentials(
  token,
  profile,
  permissions,
  vehicle_list,
  location_list,
  material_list,
  exactly_location,
  mill_location_list,
  plant_location_list,
  vehicle_group_list,
  location_group_list,
  config,
  data_visibility_node,
  org_chart_vehicle_visibility,
  org_chart,
  user_history_logon_id,
  relate_company,
) {
  console.log(
    'storeCredentials',
    token,
    profile,
    permissions,
    vehicle_list,
    location_list,
    material_list,
    exactly_location,
    mill_location_list,
    plant_location_list,
    vehicle_group_list,
    location_group_list,
    config,
    data_visibility_node,
    org_chart_vehicle_visibility,
    org_chart,
    user_history_logon_id,
    relate_company,
  )
  return asyncLocalStorage.setItem('access-token', token).then(function() {
    profile = {
      ...profile,
      tokenExp: profile.exp,
      permissions,
      vehicle_visibility: vehicle_list,
      location_visibility: location_list,
      material_visibility: material_list,
      exactly_location,
      mill_location_list,
      plant_location_list,
      vehicle_group_visibility: vehicle_group_list,
      location_group_visibility: location_group_list,
      config,
      data_visibility_node,
      org_chart_vehicle_visibility,
      user_history_logon_id,
      org_chart,
      relate_company,
    }

    return asyncLocalStorage
      .setItem('profile', JSON.stringify(profile))
      .then(function(value) {
        return loadCredentials()
      })
  })

  // localStorage.setItem("access-token", token);
  // localStorage.setItem("profile", JSON.stringify(profile));
  // loadCredentials();
}

function register(credentials) {
  return {
    [CALL_API]: {
      endpoint: '/users',
      method: 'POST',
      body: credentials,
      types: [
        CREATE_USER_REQUEST,
        {
          type: CREATE_USER_SUCCESS,
          payload: async (_action, _state, res) => {
            const token = res.headers.get('Authorization')
            const { user: profile } = await res.json()

            storeCredentials(token, profile)
            history.push('/')

            return {
              token,
              profile,
            }
          },
        },
        CREATE_USER_FAILURE,
      ],
    },
  }
}

const checkOauthLogin = {
  request: payload => createAction(CHECK_OAUTH_LOGIN.REQUEST, { payload }),
  success: (
    token,
    tokenPayload,
    permissions,
    vehicle_list,
    location_list,
    material_list,
    exactly_location,
    mill_location_list,
    plant_location_list,
    vehicle_group_list,
    location_group_list,
    config,
    data_visibility_node,
    org_chart_vehicle_visibility,
    org_chart,
    user_history_logon_id,
    relate_company,
    cb,
  ) =>
    createAction(CHECK_OAUTH_LOGIN.SUCCESS, {
      token,
      tokenPayload,
      permissions,
      vehicle_list,
      location_list,
      material_list,
      exactly_location,
      mill_location_list,
      plant_location_list,
      vehicle_group_list,
      location_group_list,
      config,
      data_visibility_node,
      org_chart_vehicle_visibility,
      org_chart,
      user_history_logon_id,
      relate_company,
      cb,
    }),
  failure: (status, msg) =>
    createAction(CHECK_OAUTH_LOGIN.FAILURE, { status, msg }),
}

function changeConfigTrackingColumns(profile, columns, showMinimalMarker) {
  return {
    type: SET_CONFIG_TRACKING_COLUMNS,
    payload: { profile, columns, showMinimalMarker },
  }
}

function changeConfigTrackingTableCarRegisColumns(profile, CarRegisColumns) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({
        ...profile.config,
        menu: {
          ...profile.config.menu,
          tracking: {
            ...profile.config.menu.tracking,
            columns_car_plate_no: CarRegisColumns,
          },
        },
      }),
    }
    localStorage.setItem('profile', JSON.stringify(profile))
    return {
      type: SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS,
      payload: { profile, CarRegisColumns },
    }
  }
  return {
    type: SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS,
    payload: { profile, CarRegisColumns },
  }
}

function changeConfigTrackingTableDriverColumns(profile, DriverColumns) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({
        ...profile.config,
        menu: {
          ...profile.config.menu,
          tracking: {
            ...profile.config.menu.tracking,
            columns_driver: DriverColumns,
          },
        },
      }),
    }
    localStorage.setItem('profile', JSON.stringify(profile))
    return {
      type: SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS,
      payload: { profile, DriverColumns },
    }
  }
  return {
    type: SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS,
    payload: { profile, DriverColumns },
  }
}

function changeConfigHomeDashboardLayout(layout) {
  return {
    type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
    payload: layout,
  }
}

function changeOrgChartFilter(profile, filters) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({
        ...profile.config,
        menu: {
          ...profile.config.menu,
          concrete_dashboard: {
            ...profile.config.menu.concrete_dashboard,
            init_filter: { ...filters.menu.concrete_dashboard.init_filter },
          },
        },
      }),
    }

    localStorage.setItem('profile', JSON.stringify(profile))
  }
  return {
    type: SET_ORG_CHART_FILTER,
    payload: { filters },
  }
}

function changeOrgChartTrackingFilter(profile, filters) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    // let newState = { ...state }
    // newState['profile']['config']['menu']['tracking']['init_filter'] = {
    //   ...filters.menu.tracking.init_filter,
    // }
    // return newState
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({
        ...profile.config,
        menu: {
          ...profile.config.menu,
          tracking: {
            ...profile.config.menu.tracking,
            init_filter: { ...filters.menu.tracking.init_filter },
          },
        },
      }),
    }

    localStorage.setItem('profile', JSON.stringify(profile))
  }
  return {
    type: SET_ORG_CHART_TRACKING_FILTER,
    payload: { filters },
  }
}

function changeLocalStorageConfigHomeDashboardLayout(profile, layout) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({ ...profile.config, home_dashboard: { layout } }),
    }

    localStorage.setItem('profile', JSON.stringify(profile))

    return {
      type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
      payload: layout,
    }
  }
  return {
    type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
    payload: [],
  }
}

function changeLocalStoragematerialVisibility(profile, id) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify(profile.config),
      material_visibility: id,
    }
    localStorage.setItem('profile', JSON.stringify(profile))
    return {
      type: SET_MATERIAL_VISIBILITY,
      payload: id,
    }
  }
  return {
    type: SET_MATERIAL_VISIBILITY,
    payload: { id },
  }
}

function changeLocalStorageLocationVisibility(profile, id) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify(profile.config),
      location_visibility: id,
    }
    localStorage.setItem('profile', JSON.stringify(profile))
    return {
      type: SET_LOCATION_VISIBILITY,
      payload: id,
    }
  }
  return {
    type: SET_LOCATION_VISIBILITY,
    payload: { id },
  }
}

function changeLocalStorageVehicleVisibility(profile, id) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify(profile.config),
      vehicle_visibility: id,
    }
    localStorage.setItem('profile', JSON.stringify(profile))
    return {
      type: SET_VEHICLE_VISIBILITY,
      payload: id,
    }
  }
  return {
    type: SET_VEHICLE_VISIBILITY,
    payload: { id },
  }
}

function changeLocalStorageLocationGroupVisibility(profile, id) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify(profile.config),
      location_group_visibility: id,
    }
    localStorage.setItem('profile', JSON.stringify(profile))
    return {
      type: SET_LOCATION_GROUP_VISIBILITY,
      payload: id,
    }
  }
  return {
    type: SET_LOCATION_GROUP_VISIBILITY,
    payload: { id },
  }
}

function changeLocalStorageVehicleGroupVisibility(profile, id) {
  const token = localStorage.getItem('access-token')
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify(profile.config),
      vehicle_group_visibility: id,
    }
    localStorage.setItem('profile', JSON.stringify(profile))
    return {
      type: SET_VEHICLE_GROUP_VISIBILITY,
      payload: id,
    }
  }
  return {
    type: SET_VEHICLE_GROUP_VISIBILITY,
    payload: { id },
  }
}

function changeLang(lang) {
  return {
    type: CHANGE_LANG,
    payload: { lang },
  }
}

const loadFavoriteMenu = {
  request: data => createAction(LOAD_FAVORITE_MENU.REQUEST, { data }),
  success: lists => createAction(LOAD_FAVORITE_MENU.SUCCESS, { lists }),
  failure: () => createAction(LOAD_FAVORITE_MENU.FAILURE),
}

const createFavoriteMenu = {
  request: (data, cb) =>
    createAction(CREATE_FAVORITE_MENU.REQUEST, {
      data,
      cb,
    }),
  success: res => createAction(CREATE_FAVORITE_MENU.SUCCESS, { res }),
  failure: () => createAction(CREATE_FAVORITE_MENU.FAILURE),
}

const deleteFavoriteMenu = {
  request: (data, cb) =>
    createAction(DELETE_FAVORITE_MENU.REQUEST, {
      data,
      cb,
    }),
  success: res => createAction(DELETE_FAVORITE_MENU.SUCCESS, { res }),
  failure: () => createAction(DELETE_FAVORITE_MENU.FAILURE),
}

export {
  LOAD_CREDENTIALS,
  REMOVE_CREDENTIALS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  loadCredentials,
  storeCredentials,
  removeCredentials,
  register,
  CHECK_OAUTH_LOGIN,
  checkOauthLogin,
  SET_CONFIG_TRACKING_COLUMNS,
  changeConfigTrackingColumns,
  SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS,
  changeConfigTrackingTableCarRegisColumns,
  SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS,
  changeConfigTrackingTableDriverColumns,
  SET_CONFIG_HOME_DASHBOARD_WIDGET,
  changeConfigHomeDashboardLayout,
  changeLocalStorageConfigHomeDashboardLayout,
  CHANGE_LANG,
  changeLang,
  loadFavoriteMenu,
  createFavoriteMenu,
  deleteFavoriteMenu,
  LOAD_FAVORITE_MENU,
  CREATE_FAVORITE_MENU,
  DELETE_FAVORITE_MENU,
  SET_ORG_CHART_FILTER,
  changeOrgChartFilter,
  changeOrgChartTrackingFilter,
  SET_ORG_CHART_TRACKING_FILTER,
  changeLocalStorageLocationVisibility,
  SET_LOCATION_VISIBILITY,
  changeLocalStorageVehicleVisibility,
  SET_VEHICLE_VISIBILITY,
  changeLocalStorageLocationGroupVisibility,
  SET_LOCATION_GROUP_VISIBILITY,
  changeLocalStorageVehicleGroupVisibility,
  SET_VEHICLE_GROUP_VISIBILITY,
  changeLocalStoragematerialVisibility,
  SET_MATERIAL_VISIBILITY,
}
