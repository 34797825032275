import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col
} from "antd";

import * as actions from "../actions";
import Forms from "./Forms";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";


class Add extends Component {

    componentDidMount() {
        this.props.clearMaintenancedevice();
    }

    create = (values, loading) => {
        this.props.createMaintenancedevice(values, loading, this.props.history);
        // this.props.history.push("/pullvehiclesink")

    };



    render() {
        const { dataMaintenancedevice, maintenancedeviceLoading } = this.props.maintenancedevice;
        return (
            <GeneralStyledContent>
                <Row gutter={24}>
                    <Col span={24}>
                        <Breadcrumb
                            match={this.props.match}
                            style={{ margin: "0px 0px 14px 10px" }}
                        />
                    </Col>
                </Row>
                <Forms
                    onSubmit={this.create}
                    maintenancedevice={this.props.maintenancedevice}
                    loadVehicleMaintenancedevice={this.props.loadVehicleMaintenancedevice}
                    loadMaintenancestatus={this.props.loadMaintenancestatus}
                    loadTypeproblem={this.props.loadTypeproblem}
                    id={""}
                    auth={this.props.auth}

                />
            </GeneralStyledContent>
        );
    }
}

const mapStateToProps = ({
    maintenancedevice, auth
}) => ({
    maintenancedevice, auth
});

const mapDispatchToProps = {
    createMaintenancedevice: actions.createMaintenancedevice.request,
    loadVehicleMaintenancedevice: actions.loadVehicleMaintenancedevice.request,
    loadMaintenancestatus: actions.loadMaintenancestatus.request,
    clearMaintenancedevice: actions.clearMaintenancedevice,
    loadTypeproblem: actions.loadTypeproblem.request
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Add);
