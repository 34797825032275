import { createTypes, createAction } from 'lib/action'

const LOAD_USERGROUP = createTypes('usergroupmasterdata', 'load')
const LOAD_USERGROUP_DETAIL = createTypes('usergroupdetailmasterdata', 'load')
const CREATE_USERGROUP = createTypes('usergroupmasterdata', 'create')
const UPDATE_USERGROUP = createTypes('usergroupmasterdata', 'update')
const EXPORT_USERGROUP = createTypes('exportusergroupmasterdata', 'load')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}
const loadUsergroup = {
  request: (data, loading) =>
    createAction(LOAD_USERGROUP.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_USERGROUP.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_USERGROUP.FAILURE),
}

const loadUsergroupDetail = {
  request: data => createAction(LOAD_USERGROUP_DETAIL.REQUEST, { data }),
  success: usergroup =>
    createAction(LOAD_USERGROUP_DETAIL.SUCCESS, { usergroup }),
  failure: () => createAction(LOAD_USERGROUP_DETAIL.FAILURE),
}

const createUsergroup = {
  request: (data, cb) =>
    createAction(CREATE_USERGROUP.REQUEST, {
      data,
      cb,
    }),
  success: res => createAction(CREATE_USERGROUP.SUCCESS, { res }),
  failure: () => createAction(CREATE_USERGROUP.FAILURE),
}

const updateUsergroup = {
  request: (data, cb) =>
    createAction(UPDATE_USERGROUP.REQUEST, {
      data,
      cb,
    }),
  success: res => createAction(UPDATE_USERGROUP.SUCCESS, { res }),
  failure: () => createAction(UPDATE_USERGROUP.FAILURE),
}

const exportUsergroup = {
  request: (data, loading) =>
    createAction(EXPORT_USERGROUP.REQUEST, { data, loading }),
  success: res => createAction(EXPORT_USERGROUP.SUCCESS, { res }),
  failure: () => createAction(EXPORT_USERGROUP.FAILURE),
}

export {
  CREATE_USERGROUP,
  createUsergroup,
  loadUsergroup,
  LOAD_USERGROUP,
  REMOVE_LISTS,
  removeLists,
  LOAD_USERGROUP_DETAIL,
  loadUsergroupDetail,
  updateUsergroup,
  UPDATE_USERGROUP,
  exportUsergroup,
  EXPORT_USERGROUP,
}
