import {
  LOAD_ONGOING,
  LOAD_SUCCESS,
  LOAD_AVALIABLE,
  LOAD_AVALIABLE_FOR_EDIT,
  LOAD_ALL,
  UPDATE_DATA_TRICKET,
  LOAD_DATA_VEHICLE_FOR_VIEW,
  NEW_LOAD_DATA_DASHBORAD,
  NEW_LOAD_DATA_DASHBORAD2,
  NEW_LOAD_DATA_DASHBORAD_INTERVAL,
  RECALCULATOR_AREA_NOT_COVERED,
  CALCULATOR_DELIVERRY_OUTSIDE_THE_AREA,
  CREATE_LOG_REQUEST_CHANGE_DEST_LOCATION,
  ISUPPLY_DASHBOARD_CLOSE_WORK,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  ongoing_lists: [],
  ongoing_total: 0,
  ongoing_quantity: 0,
  success_lists: [],
  success_total: 0,
  success_quantity: 0,
  available_lists: [],
  available_total: 0,
  available_quantity: 0,

  quantity: 0,
  quantity_success: 0,
  work_total: 0,
  work_success: 0,
  no_status: 0,
  wait_for_the_queue: 0,
  receive_the_goods: 0,
  go_to: 0,
  wait_pour: 0,

  listsDataForEdit: [],
  loading: false,
  loadingForEdit: false,
  checkLoaddataforedit: true,

  listsDataForView: [],
  loadingForView: false,
  checkLoaddataforview: true,
  draw: -1,

  data_for_new_dashborad: [],
  data_for_new_dashborad_new: [],
  data_for_new_dashborad_new_total: 0,
  total_count: 0,
  count: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_ONGOING.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
      }

    case LOAD_ONGOING.SUCCESS:
      return {
        ...state,
        ongoing_lists: action.payload.lists.data,
        check: false,
        ongoing_total: action.payload.lists.total,
        ongoing_quantity: action.payload.lists.quantity,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_SUCCESS.REQUEST:
      return {
        ...state,
        // loading: loading,
      }

    case LOAD_SUCCESS.SUCCESS:
      return {
        ...state,
        success_lists: action.payload.lists.data,
        check: false,
        success_total: action.payload.lists.total,
        success_quantity: action.payload.lists.quantity,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_AVALIABLE.SUCCESS:
      return {
        ...state,
        available_lists: action.payload.lists.data,
        check: false,
        available_total: action.payload.lists.total,
        available_quantity: action.payload.lists.quantity,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_ALL.SUCCESS:
      return {
        ...state,
        quantity: action.payload.lists.quantity,
        quantity_success: action.payload.lists.quantity_success,
        work_total: action.payload.lists.work_total,
        work_success: action.payload.lists.work_success,
        no_status: action.payload.lists.no_status,
        wait_for_the_queue: action.payload.lists.wait_for_the_queue,
        receive_the_goods: action.payload.lists.receive_the_goods,
        go_to: action.payload.lists.go_to,
        wait_pour: action.payload.lists.wait_pour,
      }

    case LOAD_AVALIABLE_FOR_EDIT.REQUEST:
      return {
        ...state,
        loadingForEdit: action.payload.data.loading,
        checkLoaddataforedit: true,
      }

    case LOAD_AVALIABLE_FOR_EDIT.SUCCESS:
      return {
        ...state,
        listsDataForEdit: action.payload.lists.data,
        loadingForEdit: false,
        checkLoaddataforedit: false,
      }

    case LOAD_DATA_VEHICLE_FOR_VIEW.REQUEST:
      return {
        ...state,
        loadingForView: action.payload.data.loading,
        checkLoaddataforview: true,
      }

    case LOAD_DATA_VEHICLE_FOR_VIEW.SUCCESS:
      return {
        ...state,
        listsDataForView: action.payload.lists.data,
        loadingForView: false,
        checkLoaddataforview: false,
      }

    case UPDATE_DATA_TRICKET.REQUEST:
      return {
        ...state,
        loadingForEdit: true,
      }

    case UPDATE_DATA_TRICKET.SUCCESS:
      return {
        ...state,
        loadingForEdit: false,
      }

    case NEW_LOAD_DATA_DASHBORAD.REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
      }

    case NEW_LOAD_DATA_DASHBORAD.SUCCESS:
      return {
        ...state,
        data_for_new_dashborad: action.payload.lists,
        loading: false,
      }

    case NEW_LOAD_DATA_DASHBORAD2.REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
      }

    case NEW_LOAD_DATA_DASHBORAD2.SUCCESS:
      let new_date = []
      let count = 0
      if (action.payload.page > 0) {
        new_date = state.data_for_new_dashborad_new.concat(
          action.payload.lists.data,
        )
        count = state.count + action.payload.lists.count
      } else {
        new_date = action.payload.lists.data
        count = action.payload.lists.count
      }
      return {
        ...state,
        data_for_new_dashborad_new: new_date,
        data_for_new_dashborad_new_total: action.payload.lists.total,
        total_count: action.payload.lists.total_count,
        count: count,
        loading: false,
      }

    case NEW_LOAD_DATA_DASHBORAD_INTERVAL.REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
      }

    case NEW_LOAD_DATA_DASHBORAD_INTERVAL.SUCCESS:
      return {
        ...state,
        data_for_new_dashborad_new: action.payload.lists.data,
        data_for_new_dashborad_new_total: action.payload.lists.total,
        total_count: action.payload.lists.total_count,
        count: action.payload.lists.count,
        loading: false,
      }

    case RECALCULATOR_AREA_NOT_COVERED.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CALCULATOR_DELIVERRY_OUTSIDE_THE_AREA.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_LOG_REQUEST_CHANGE_DEST_LOCATION.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ISUPPLY_DASHBOARD_CLOSE_WORK.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ISUPPLY_DASHBOARD_CLOSE_WORK.FALSE:
      return {
        ...state,
        loading: false,
      }

    case ISUPPLY_DASHBOARD_CLOSE_WORK.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
