import {
    LOAD_DATA_OVERVIEW_PERFOMANCE,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    loading: false,
    draw: -1,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_DATA_OVERVIEW_PERFOMANCE.REQUEST:
            return {
                ...state,
                loading: true,
            };
    
        case LOAD_DATA_OVERVIEW_PERFOMANCE.SUCCESS:
            return {
                ...state,
                lists: action.payload.lists,
                loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        default:
            return state;
    }
}