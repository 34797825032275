import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'

import * as actions from '../actions'
import * as driverinformationActions from '../../driverinformation/actions'
import * as distfromlastmaintenanceActions from '../../distfromlastmaintenance/actions'
import * as eventActions from '../../event/actions'
import Forms from './Forms'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from './../../../shared/components/Breadcrumb'

class Add extends Component {
  componentDidMount() {
    this.props.clearSchedulereport()
  }

  create = (values, loading) => {
    this.props.createSchedulereport(values, loading, this.props.history)
  }

  render() {
    // console.log('add:', this.props.event)
    // const { dataSchedulereport, schedulereportLoading } = this.props.schedulereport;
    return (
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: '0px 0px 14px 10px' }}
            />
          </Col>
        </Row>
        <Forms
          onSubmit={this.create}
          schedulereport={this.props.schedulereport}
          loadReportSchedulereport={this.props.loadReportSchedulereport}
          id={''}
          auth={this.props.auth}
          vehicleLists={this.props.event.vehicleLists}
          driverLists={this.props.driverinformation.driverLists}
          policyLists={this.props.event.policyLists}
          eventLists={this.props.event.eventLists}
          vehiclebygroupLists={this.props.event.vehiclebygroupLists}
          vehiclebypolicyLists={this.props.event.vehiclebypolicyLists}
          loadDriver={this.props.loadDriver}
          loadPartType={this.props.loadPartType}
          partTypeLists={this.props.distfromlastmaintenance.partTypeLists}
          loadPolicy={this.props.loadPolicy}
          loadEvents={this.props.loadEvents}
          loadVehiclebypolicy={this.props.loadVehiclebypolicy}
          loadVehiclebygroupid={this.props.loadVehiclebygroupid}
          loadVehicle={this.props.loadVehicle}
          eventLoading={this.props.event.eventLoading}
        />
      </GeneralStyledContent>
    )
  }
}

const mapStateToProps = ({
  schedulereport,
  auth,
  driverinformation,
  distfromlastmaintenance,
  event,
}) => ({
  schedulereport,
  auth,
  driverinformation,
  distfromlastmaintenance,
  event,
})

const mapDispatchToProps = {
  createSchedulereport: actions.createSchedulereport.request,
  loadReportSchedulereport: actions.loadReportSchedulereport.request,
  clearSchedulereport: actions.clearSchedulereport,
  loadDriver: driverinformationActions.loadDriver.request,
  loadPartType: distfromlastmaintenanceActions.loadPartType.request,
  loadPolicy: eventActions.loadPolicy.request,
  loadEvents: eventActions.loadEvents.request,
  loadVehiclebypolicy: eventActions.loadVehiclebypolicy.request,
  loadVehiclebygroupid: eventActions.loadVehiclebygroupid.request,
  loadVehicle: eventActions.loadVehicle.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Add)
