import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Steps, Row, Col, Spin, Button, message } from "antd";
import {
  GeneralStyledContent,
  StyledSearchForm,
} from "../../../../styled/common-styled";
import { loadPolicyDetail, createPolicy, updatePolicy } from "./../actions";
import { autoGenKey } from "../../../../lib/helper";

import Step1Form from "./Form/Step1Form";
import VehicleForm from "./Form/VehicleForm";
import NotiConfig from "./Form/NotiConfig";

import history from "../../../../lib/history";

import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import Breadcrumb from "./../../../shared/components/Breadcrumb";

import LangContext, { i18n } from "modules/shared/context/langContext";
const { Step } = Steps;

const speedInitialValues = {
  day_yel_speed: "90",
  day_red_speed: "90",
  night_yel_speed: "70",
  night_red_speed: "70",
  raining_yel_speed: "50",
  raining_red_speed: "50",
};

const drivingHourInitialValues = {
  continuous_work_duration: "4",
  continuous_work_amount: "2",
  allow_working_start_at: "00:00:00",
  allow_working_end_at: "23:59:59",
};

const abnormalBehaviorInitialValues = {
  suddenly_break_threshold: "5",
  aggesive_acceleration_threshold: "5",
  driving_aggesive_threshold: "1",
  snooze_threshold: "1",
  using_phone_threshold: "1",
};

const prohibitedAreaInitialValues = {
  zone_id: "",
  zone_id_text: "",
  parking_duration: "immediately",
  areaType: "inside",
};

const parkingArea = {
  parkingType: "all",
  parking_duration: "immediately",
  areaType: "inside",
  plant: false,
  site: false,
  parkingLot: false,
  restArea: false,
  gasStation: false,
  carService: false,
};

const maintenance = {
  part_type_id: "",
  part_type_id_text: "",
};

const ptoDoor = {
  parking_duration: "immediately",
  areaType: "inside",
  plant: false,
  site: false,
  parkingLot: false,
  restArea: false,
  gasStation: false,
  carService: false,
};

const boom = {
  parking_duration: "immediately",
  areaType: "inside",
  plant: false,
  site: false,
  parkingLot: false,
  restArea: false,
  gasStation: false,
  carService: false,
};

const initialValue = {
  ...speedInitialValues,
  ...drivingHourInitialValues,
  ...abnormalBehaviorInitialValues,
  ...prohibitedAreaInitialValues,
  ...parkingArea,
  ...maintenance,
  ...boom,
  ...ptoDoor,
  policy_id: null,
  policy_name: "",
  policy_type: "safety",
  policy_sub_type: "",
  category: "",
  critical_level: "medium",
  open_noti_id: "alert",
  close_noti_id: "event_end",
  selectedVehicleGroup: [],
  all_vehicle: false,
  selectedVehicle: [],
  all_user: false,
  selectedUser: [],
  channel_web: false,
  channel_line: false,
  channel_email: false,
};

class Detail extends Component {
  state = {
    current: 0,
    zoneList: [],
    initValue: initialValue,
    policy_id: !(
      Object.keys(this.props.match.params).length === 0 &&
      this.props.match.params.constructor === Object
    )
      ? this.props.match.params.id
      : null,
    pageAction: !(
      Object.keys(this.props.match.params).length === 0 &&
      this.props.match.params.constructor === Object
    )
      ? "edit"
      : "create",
  };

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  changePageAction = (value) => this.setState({ pageAction: value });

  setInitValue = (value) => this.setState({ initValue: value });

  componentDidMount() {
    if (this.state.pageAction == "edit") {
      this.props.loadPolicyDetail({ id: this.state.policy_id });
      return <Redirect to="/policy" />;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.policyState.selectedPolicy.policy_id !=
      nextProps.policyState.selectedPolicy.policy_id
    ) {
      if (this.state.pageAction == "create") {
        this.setState({
          initValue: initialValue,
        });
      } else {
        this.setState({
          initValue: nextProps.policyState.selectedPolicy,
        });
      }
    }
    return true;
  }

  createPolicy = (cb) => {
    this.props.createPolicy(
      {
        ...this.state.initValue,
        company_id: this.props.auth.profile.company_id,
        created_by: this.props.auth.profile.id,
      },
      cb
    );
  };

  updatePolicy = (cb) => {
    this.props.updatePolicy(
      {
        ...this.state.initValue,
        company_id: this.props.auth.profile.company_id,
        updated_by: this.props.auth.profile.id,
      },
      cb
    );
  };

  renderRedirect = () => <Redirect to="/policy" />;

  render() {
    const steps = [
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.n.nameAndType}
          </LangContext.Consumer>
        ),
        content: (
          <Step1Form
            key={autoGenKey("policy-step")}
            current={this.state.current}
            next={this.next}
            prev={this.prev}
            loading={this.props.policyState.detailLoading}
            history={history}
            action={this.state.pageAction}
            loadPolicyDetail={this.props.loadPolicyDetail}
            setInitValue={this.setInitValue}
            changePageAction={this.changePageAction}
            renderRedirect={this.renderRedirect}
            initialValues={this.state.initValue}
            // createPolicy={this.createPolicy}
            // updatePolicy={this.updatePolicy}
            // vehicleOnSearch={this.vehicleOnSearch}
            // vehicleList={this.state.vehicleList}
            // driverOnSearch={this.driverOnSearch}
            // driverList={this.state.driverList}
            // plantOnSearch={this.plantOnSearch}
            // plantList={this.state.plantList}
            // siteOnSearch={this.siteOnSearch}
            // siteList={this.state.siteList}
          />
        ),
      },
      // {
      //   title: (
      //     <LangContext.Consumer>
      //       {i18n => i18n.d.description}
      //     </LangContext.Consumer>
      //   ),
      //   content: (
      //     <Step2Form
      //       key={autoGenKey("policy-step")}
      //       companyId={this.props.auth.profile.company_id}
      //       token={this.props.auth.accessToken}
      //       current={this.state.current}
      //       next={this.next}
      //       prev={this.prev}
      //       loading={this.props.policyState.detailLoading}
      //       history={history}
      //       action={this.state.pageAction}
      //       setInitValue={this.setInitValue}
      //       changePageAction={this.changePageAction}
      //       renderRedirect={this.renderRedirect}
      //       initialValues={this.state.initValue}
      //       // createPolicy={this.createPolicy}
      //       // updatePolicy={this.updatePolicy}
      //     />
      //   )
      // },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicle}
          </LangContext.Consumer>
        ),
        content: (
          <VehicleForm
            key={autoGenKey("policy-step")}
            companyId={this.props.auth.profile.company_id}
            token={this.props.auth.accessToken}
            current={this.state.current}
            next={this.next}
            prev={this.prev}
            history={history}
            action={this.state.pageAction}
            setInitValue={this.setInitValue}
            initialValues={this.state.initValue}
          />
        ),
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.n.notification}
          </LangContext.Consumer>
        ),
        content: (
          <NotiConfig
            key={autoGenKey("policy-step")}
            companyId={this.props.auth.profile.company_id}
            token={this.props.auth.accessToken}
            current={this.state.current}
            next={this.next}
            prev={this.prev}
            history={history}
            action={this.state.pageAction}
            setInitValue={this.setInitValue}
            initialValues={this.state.initValue}
            createPolicy={this.createPolicy}
            updatePolicy={this.updatePolicy}
          />
        ),
      },
    ];
    return (
      <AuthorizeComponent {...this.props} matching_name="policy">
        <GeneralStyledContent>
          <Spin spinning={this.props.policyState.detailLoading}>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <StyledSearchForm>
              <Row gutter={24}>
                <Col span={4} />
                <Col span={16}>
                  <Steps current={this.state.current}>
                    {steps.map((item) => (
                      <Step
                        key={autoGenKey("policy-header-step")}
                        title={item.title}
                      />
                    ))}
                  </Steps>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <div style={{ margin: "45px 0px 0px 0px" }}>
                    {steps[this.state.current].content}
                  </div>
                </Col>
              </Row>
            </StyledSearchForm>
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(({ policyState, auth }) => ({ policyState, auth }), {
  loadPolicyDetail: loadPolicyDetail.request,
  createPolicy: createPolicy.request,
  updatePolicy: updatePolicy.request,
})(Detail);
