import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
import moment from "moment";

function* loadDownloadcertificate(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const USER_ID = getlocalstorage(localStorage.getItem("profile"), "id");

  const {
    data: { page, pageSize, orderBy, orderType, filterObj }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/downloadcertificate`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadDownloadcertificate.success(res.data));
  } catch (err) {
    yield put(actions.loadDownloadcertificate.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_DOWNLOADCERTIFICATE.REQUEST, loadDownloadcertificate)
  ]);
}

export { loadDownloadcertificate };
