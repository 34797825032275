import {
    LOAD_VEHICLE_DAILY_SUMMARY_REPORT,
    LOAD_VEHICLE_TYPE_DAILY_SUMMARY_REPORT,
    EXPORT_DAILY_SUMMARY_REPORT_REPORT,
    LOAD_PLANT_DAILY_SUMMARY_REPORT,
    LOAD_VEHICLE_BY_PLANT_ID_DAILY_SUMMARY_REPORT,
    LOAD_BUSINESS_DAILY_SUMMARY_REPORT,
    LOAD_VEHICLE_BY_BUSINESS_ID_DAILY_SUMMARY_REPORT
} from "./actions";

const initialState = {
    vehicleLists: [],
    vehicleTypeLists: [],
    plantLists: [],
    businessList: [],
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case LOAD_VEHICLE_DAILY_SUMMARY_REPORT.REQUEST:
            return {
              ...state,
              loading: true
            };

        case LOAD_VEHICLE_DAILY_SUMMARY_REPORT.SUCCESS:
            return {
                ...state,
                vehicleLists: action.payload.data.data,
                loading: false
            };

        case LOAD_VEHICLE_BY_PLANT_ID_DAILY_SUMMARY_REPORT.REQUEST:
            return {
                ...state,
                loading: true
              };

        case LOAD_VEHICLE_BY_PLANT_ID_DAILY_SUMMARY_REPORT.SUCCESS:
            return {
                    ...state,
                    vehicleLists: action.payload.data.data,
                    loading: false
                };

        case LOAD_VEHICLE_BY_BUSINESS_ID_DAILY_SUMMARY_REPORT.REQUEST:
            return {
                ...state,
                loading: true
              };

        case LOAD_VEHICLE_BY_BUSINESS_ID_DAILY_SUMMARY_REPORT.SUCCESS:
            return {
                    ...state,
                    vehicleLists: action.payload.data.data,
                    loading: false
                };

        case LOAD_VEHICLE_TYPE_DAILY_SUMMARY_REPORT.REQUEST:
                return {
                  ...state,
                  loading: true
                };
            
        case LOAD_VEHICLE_TYPE_DAILY_SUMMARY_REPORT.SUCCESS:
            return {
              ...state,
              vehicleTypeLists: action.payload.data.data
            };

        case LOAD_PLANT_DAILY_SUMMARY_REPORT.REQUEST:
            return {
                ...state,
                loading: true
              };

        case LOAD_PLANT_DAILY_SUMMARY_REPORT.SUCCESS:
            return {
                ...state,
                plantLists: action.payload.data.data
            };

        case EXPORT_DAILY_SUMMARY_REPORT_REPORT.REQUEST:
            return {
                ...state,
                loading: true,
            };
            
        case EXPORT_DAILY_SUMMARY_REPORT_REPORT.SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case LOAD_BUSINESS_DAILY_SUMMARY_REPORT.REQUEST:
                return {
                  ...state,
                  loading: true
                };
            
        case LOAD_BUSINESS_DAILY_SUMMARY_REPORT.SUCCESS:
            return {
              ...state,
              businessList: action.payload.data.data
            };

        default:
            return state;
    }
}