import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
//import moment from "moment";

function* loadAlertDriverLicense(action) {
    const ACCESSTOKEN = localStorage.getItem("access-token");
    const COMPANY_ID = getlocalstorage(
        localStorage.getItem("profile"),
        "company_id"
    );

    const {
        data: { list_driver_id, alert_type_id, type_file }
    } = action.payload;
    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/alertdriverlicense`,
            {
                list_driver_id,
                company_id: COMPANY_ID,
                alert_type_id,
                type_file
            },
            { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
        );
        yield put(actions.loadAlertDriverLicense.success(res.data));
        if (type_file === "excel") {
            window.location.assign(
                `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`
            );
        } else {
            window.location = res.data.data;
        }


    } catch (err) {
        yield put(actions.loadAlertDriverLicense.failure());
    }
}

function* loadDriver(action) {
    const ACCESSTOKEN = localStorage.getItem("access-token");
    const COMPANY_ID = getlocalstorage(
        localStorage.getItem("profile"),
        "company_id"
    );
    const VUC = getlocalstorage(
        localStorage.getItem("profile"),
        "vehicle_visibility"
    );

    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdriver`,
            {
                allow_vehicles: VUC,
                company_id: COMPANY_ID
            },
            { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
        );
        yield put(actions.loadDriver.success(res.data));
    } catch (err) {
        yield put(actions.loadDriver.failure());
    }
}

export default function* watchReportPoolState() {
    yield all([
        takeEvery(actions.LOAD_ALERTDRIVERLICENSE.REQUEST, loadAlertDriverLicense),
        takeEvery(actions.LOAD_DRIVER_ALERTDRIVERLICENSE.REQUEST, loadDriver)
    ]);
}

export { loadAlertDriverLicense, loadDriver };
