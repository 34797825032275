import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
import * as actions from './actions'

function* loadVehicleList(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/cpacgetvehiclerequestcertificate`,
      {
        companyID: COMPANY_ID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicleList.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleList.failure())
  }
}

function* requestCertificate(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: { file, data },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  file.forEach(File1 => {
    File1.files.forEach(Files => {
      formData.append('files[]', Files)
    })
  })

  formData.append('fileslists', file)
  formData.append('data', JSON.stringify(data))
  formData.append('company_id', COMPANY_ID)
  formData.append('user_id', USER_ID)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/cpacrequestcertificate`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      },
    )

    loading(res.data)
    yield put(actions.requestCertificate.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.requestCertificate.failure())
  }
}

function* loadRequestCertificate(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const {
    data: { page, pageSize, orderBy, orderType, filterObj, token, companyID },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcpacrequestcertificate`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        token,
        companyID,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    yield put(actions.loadRequestCertificate.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadRequestCertificate.failure())
  }
}

export default function* watchCpacRequestCertificate() {
  yield all([
    takeEvery(actions.LOAD_VEHICLE_LIST.REQUEST, loadVehicleList),
    takeEvery(actions.REQUEST_CERTIFICATE.REQUEST, requestCertificate),
    takeEvery(actions.LOAD_REQUEST_CERTIFICATE.REQUEST, loadRequestCertificate),
  ])
}
export { loadVehicleList, requestCertificate, loadRequestCertificate }
