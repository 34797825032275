import React from "react";
import { Switch, Route } from "react-router-dom";

import Datavisibility from "./Index";

export default () => (
  <Switch>
    <Route path="/datavisibility" component={Datavisibility} />
  </Switch>
);
