import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
import * as actions from './actions'

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )
  const { gps_vendor_id } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclegpsvendor`,
      {
        companyID: COMPANY_ID,
        vendor_id: gps_vendor_id,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadGpsVendor(action) {
  const { accessToken, company_id, is_gps_vendor } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvendor`,
      {
        company_id: company_id,
        is_gps_vendor: is_gps_vendor,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    )
    yield put(actions.loadGpsVendor.success(res.data))
  } catch (err) {
    yield put(actions.loadGpsVendor.failure())
  }
}

function* exportGpsDisconnectOnTripReport(action) {
  const {
    data: {
      list_vehicle_id,
      vendor_id,
      date_start,
      date_end,
      type_file,
      company_id,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/vehicle-disconnect-on-trip`,
      //`http://localhost/reports/go/vehicle-disconnect-on-trip`,
      {
        start_date: date_start,
        end_date: date_end,
        vehicles: list_vehicle_id,
        vendor_id: vendor_id,
        type_file,
        company_id,
      },
    )
    yield put(actions.exportGpsDisconnectOnTripReport.success(res.data))
    window.location = res.data.result
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportGpsDisconnectOnTripReport.failure())
  }
}

function* exportGpsDisconnectOnTripReportpreview(action) {
  const {
    data: { vehicles, vendor_id, start_date, end_date, type_file, company_id },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/vehicle-disconnect-on-trip`,
      {
        start_date,
        end_date,
        vehicles,
        vendor_id: vendor_id,
        type_file,
        company_id,
      },
    )
    yield put(actions.exportGpsDisconnectOnTripReportpreview.success(res.data))
    window.location = res.data.result
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportGpsDisconnectOnTripReportpreview.failure())
  }
}

export default function* watchGPSDisconnectOnTrip() {
  yield all([
    takeEvery(
      actions.LOAD_VEHICLE_GPS_DISCONNECT_ON_TRIP_REPORT.REQUEST,
      loadVehicle,
    ),
    takeEvery(actions.LOAD_GPS_VENDOR.REQUEST, loadGpsVendor),
    takeEvery(
      actions.EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT.REQUEST,
      exportGpsDisconnectOnTripReport,
    ),
    takeEvery(
      actions.EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT_PREVIEW.REQUEST,
      exportGpsDisconnectOnTripReportpreview,
    ),
  ])
}
export {
  loadVehicle,
  loadGpsVendor,
  exportGpsDisconnectOnTripReport,
  exportGpsDisconnectOnTripReportpreview,
}
