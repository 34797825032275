import { createTypes, createAction } from "lib/action";
const LOAD_DATA_OVERVIEW_PERFOMANCE = createTypes("isupplyoverviewperformanec", "load");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadDataOverviewPerformanec = {
    request: (data, loading) =>
        createAction(LOAD_DATA_OVERVIEW_PERFOMANCE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_DATA_OVERVIEW_PERFOMANCE.SUCCESS, {lists}),
    failure: () => createAction(LOAD_DATA_OVERVIEW_PERFOMANCE.FAITLURE)  
};

export {
    LOAD_DATA_OVERVIEW_PERFOMANCE, loadDataOverviewPerformanec,
    REMOVE_LISTS, removeLists
}