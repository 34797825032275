import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'

import * as actions from './actions'
import moment from 'moment'

function* loadMdvrdownloadfile(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: { devidno, vehicle_plate_no, eventdate },
  } = action.payload

  const year_start = moment(eventdate[0]['_d']).format('YYYY')
  const month_start = moment(eventdate[0]['_d']).format('MM')
  const day_start = moment(eventdate[0]['_d']).format('DD')
  const time_start = moment(eventdate[0]['_d']).format('HH:mm:ss')
  const time_end = moment(eventdate[1]['_d']).format('HH:mm:ss')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/mdvrdownloadfile`,
      {
        plate_no: vehicle_plate_no,
        dev_no: devidno,
        year_start,
        month_start,
        day_start,
        time_start,
        time_end,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadMdvrdownloadfile.success(res.data))
  } catch (err) {
    yield put(actions.loadMdvrdownloadfile.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclemdvr`,
      {
        vehicle_visibility,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_MDVRDOWNLOADFILE.REQUEST, loadMdvrdownloadfile),
    takeEvery(actions.LOAD_VEHICLE_MDVRDOWNLOADFILE.REQUEST, loadVehicle),
  ])
}

export { loadMdvrdownloadfile, loadVehicle }
