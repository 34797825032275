import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn:
//     "https://2e9b55b275c84ef8a1ad6b45ab81e686@o501296.ingest.sentry.io/5680115",
// });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
