import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Honeytoastplayback from './Index'

export default () => (
  <Switch>
    <Route
      exact
      path="/honeytoastplaybackwebpage"
      component={Honeytoastplayback}
    />
  </Switch>
)
