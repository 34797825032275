import { createTypes, createAction } from 'lib/action'

const CREATE_SMART_DISPATCHING = createTypes('createSmartDispatching', 'load')
const LOAD_SMART_DISPATCHING = createTypes('loadSmartDispatching', 'load')
const LOAD_LOCATION_SMART_DISPATCHING = createTypes(
  'loadLocationSmartDispatching',
  'load',
)
const UPDATE_SMART_DISPATCHING = createTypes('updateSmartDispatching', 'load')
const GET_NEAR_BY_LOCATION_DISPATCHING = createTypes(
  'getNearByLocationSmartDispatching',
  'load',
)

const createSmartDispatching = {
  request: (data, loading) =>
    createAction(CREATE_SMART_DISPATCHING.REQUEST, { data, loading }),
  success: res => createAction(CREATE_SMART_DISPATCHING.SUCCESS, { res }),
  failure: () => createAction(CREATE_SMART_DISPATCHING.FAILURE),
}

const loadSmartDispatching = {
  request: (data, loading) =>
    createAction(LOAD_SMART_DISPATCHING.REQUEST, { data, loading }),
  success: lists => createAction(LOAD_SMART_DISPATCHING.SUCCESS, { lists }),
  failure: () => createAction(LOAD_SMART_DISPATCHING.FAITLURE),
}

const loadLocationSmartDispatching = {
  request: (data, loading) =>
    createAction(LOAD_LOCATION_SMART_DISPATCHING.REQUEST, { data, loading }),
  success: lists =>
    createAction(LOAD_LOCATION_SMART_DISPATCHING.SUCCESS, { lists }),
  failure: () => createAction(LOAD_LOCATION_SMART_DISPATCHING.FAITLURE),
}

const updateSmartDispatching = {
  request: (data, loading) =>
    createAction(UPDATE_SMART_DISPATCHING.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_SMART_DISPATCHING.SUCCESS, { res }),
  failure: () => createAction(UPDATE_SMART_DISPATCHING.FAILURE),
}

const getNearByLocationSmartDispatching = {
  request: (data, loading) =>
    createAction(GET_NEAR_BY_LOCATION_DISPATCHING.REQUEST, { data, loading }),
  success: res =>
    createAction(GET_NEAR_BY_LOCATION_DISPATCHING.SUCCESS, { res }),
  failure: () => createAction(GET_NEAR_BY_LOCATION_DISPATCHING.FAILURE),
}

export {
  CREATE_SMART_DISPATCHING,
  createSmartDispatching,
  LOAD_SMART_DISPATCHING,
  loadSmartDispatching,
  LOAD_LOCATION_SMART_DISPATCHING,
  loadLocationSmartDispatching,
  UPDATE_SMART_DISPATCHING,
  updateSmartDispatching,
  getNearByLocationSmartDispatching,
  GET_NEAR_BY_LOCATION_DISPATCHING,
}
