import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  Transfer,
  Dropdown,
  Menu,
  Icon,
  Table,
} from 'antd'
import difference from 'lodash/difference'
const FormItem = Form.Item
const Option = Select.Option
// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <div
          onClick={() => {
            restProps.onChange(restProps.dataSource.map(item => item.key))
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
        <div
          onClick={() => {
            restProps.onChange([])
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns
        // console.log('filteredItems', filteredItems)
        // console.log('rest', restProps)
        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
          //   hideDefaultSelections: true,
          //   selections: [
          //     {
          //       //   key: 'all-data',
          //       text: 'Select All Data',
          //       onSelect: (record, selected, selectedRows, nativeEvent) => {
          //         let getKey = []
          //         filteredItems.map(item => getKey.push(item.key))
          //         onItemSelectAll([...getKey], true)
          //       },
          //     },
          //   ],
        }

        return (
          <Table
            scroll={{ y: 200, x: 250 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 100 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
            className="change-table-dropdown"
          />
        )
      }}
    </Transfer>
  </div>
)
export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.leftTableColumns = [
      {
        dataIndex: 'name',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemLeft}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Left',
      },
    ]

    this.rightTableColumns = [
      {
        dataIndex: 'name',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemSelected}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Right',
      },
    ]
    this.Leftbtn = null
    this.Rightbtn = null
  }

  state = {
    driverList: [],
    driverSelect: [],
    selectedKeys: [],
    clickMenu: '',
    // vehicltypeID: ""
  }

  componentDidMount() {
    const { loadDriver } = this.props
    loadDriver()
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  componentWillReceiveProps = nextProps => {
    const { driverLists } = nextProps
    const drilist = []
    driverLists.map(item =>
      drilist.push({
        key: item.id,
        name: item.firstname + ' ' + item.lastname,
      }),
    )
    //console.log(vehlist);
    this.setState({ driverList: drilist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_driver_id', targetKeys)
    this.setState({ driverSelect: targetKeys })
  }

  handleMenuClick = (setFieldValue, handleSubmit) => {
    // if (e.key == "excel") {
    setFieldValue('type_file', 'excel', false)
    //} else if (e.key == "pdf") {
    //   setFieldValue("type_file", "pdf", false);
    // }
    handleSubmit()
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleClicReportkPreview = values => {
    let company_id = this.props.auth.company_id

    const filter = {
      list_driver_id: values.list_driver_id,
      company_id: company_id,
      alert_type_id: values.alert_type_id,
      type_file: 'preview',
    }

    window.open('/report/alertdriverlicense/preview', '_blank')
    localStorage.setItem(
      'alertdriverlicensereport-filter',
      JSON.stringify(filter),
    )
  }

  render() {
    const { alertdriverlicenseLoading, onSubmit } = this.props

    return (
      <LangContext.Consumer>
        {i18n => (
          <div>
            <Spin spinning={alertdriverlicenseLoading}>
              <Formik
                initialValues={{
                  alert_type_id: '',
                  list_driver_id: [],
                }}
                validate={values => {}}
                validationSchema={yup.object().shape({
                  list_driver_id: yup
                    .array()
                    .required(i18n.p.pleaseSelectDriver),
                  alert_type_id: yup
                    .string()
                    .required(i18n.p.pleaseSelectExpirationDate),
                })}
                onSubmit={values => {
                  const loading = true
                  if (this.state.clickMenu === 'preview') {
                    this.handleClicReportkPreview(values)
                  } else {
                    onSubmit(values, loading)
                  }
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                }) => (
                  <Row type="flex" justify="center">
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        {i18n.t.theDriverIsIicenseReportHasExpired}
                      </div>
                      <FormItem
                        required={true}
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.c.chauffeur}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_driver_id &&
                          errors.list_driver_id &&
                          'warning'
                        }
                        help={touched.list_driver_id && errors.list_driver_id}
                      >
                        {/* <div id="finding-btn">
                                                        <Transfer
                                                            titles={[
                                                                <LangContext.Consumer>
                                                                    {i18n => i18n.i.itemLeft}
                                                                </LangContext.Consumer>,
                                                                <LangContext.Consumer>
                                                                    {i18n => i18n.i.itemSelected}
                                                                </LangContext.Consumer>,
                                                            ]}
                                                            dataSource={this.state.driverList}
                                                            showSearch
                                                            listStyle={{
                                                                width: 250,
                                                                height: 300,
                                                            }}
                                                            selectedKeys={this.state.selectedKeys}
                                                            operations={['>>', '<<']}
                                                            targetKeys={this.state.driverSelect}
                                                            onChange={value =>
                                                                this.tranferChange(value, setFieldValue)
                                                            }
                                                            onSelectChange={this.handleSelectChange}
                                                            //onChange={this.tranferChange}
                                                            render={item => `${item.name}`}
                                                            footer={this.renderFooter}
                                                            filterOption={(inputValue, item) => {
                                                                return (
                                                                    item.name
                                                                        .toUpperCase()
                                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                                )
                                                            }}
                                                        />
                                                    </div> */}
                        <div>
                          <TableTransfer
                            dataSource={this.state.driverList}
                            targetKeys={this.state.driverSelect}
                            showSearch
                            onChange={value =>
                              this.tranferChange(value, setFieldValue)
                            }
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                            leftColumns={this.leftTableColumns}
                            rightColumns={this.rightTableColumns}
                            onSelectChange={this.handleSelectChange}
                          />
                        </div>
                      </FormItem>

                      <FormItem
                        required={true}
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.n.numberOfExpirationDates}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.alert_type_id &&
                          errors.alert_type_id &&
                          'warning'
                        }
                        help={touched.alert_type_id && errors.alert_type_id}
                      >
                        <Select
                          //showSearch
                          // style={{ width: 200 }}
                          onChange={value =>
                            setFieldValue('alert_type_id', value)
                          }
                          value={values.alert_type_id}
                        >
                          <Option value="">
                            <LangContext.Consumer>
                              {i18n => i18n.p.pleaseSelect}
                            </LangContext.Consumer>
                          </Option>
                          <Option value="7">
                            {`${i18n.l.less_than} 7 ${i18n.d.day}`}
                          </Option>
                          <Option value="30">
                            {`${i18n.l.less_than} 1 ${i18n.m.month}`}
                          </Option>
                          <Option value="90">
                            {`${i18n.l.less_than} 3 ${i18n.m.month}`}
                          </Option>
                          <Option value="180">
                            {`${i18n.l.less_than} 6 ${i18n.m.month}`}
                          </Option>
                        </Select>
                      </FormItem>
                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call == 'schedule' ? (
                            <Col span={8} style={{ marginTop: '10px' }}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            <div>
                              <Col span={8}>
                                <Button type="defualt" block>
                                  <Link to="/">
                                    <LangContext.Consumer>
                                      {i18n => i18n.c.cancel}
                                    </LangContext.Consumer>
                                  </Link>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  block
                                  onClick={() =>
                                    this.setState({
                                      clickMenu: 'preview',
                                    })
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.preview}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    this.setState({
                                      clickMenu: 'report',
                                    })
                                    this.handleMenuClick(
                                      setFieldValue,
                                      handleSubmit,
                                    )
                                  }}
                                  block
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.r.report}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                            </div>
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  </Row>
                )}
              />
            </Spin>
          </div>
        )}
      </LangContext.Consumer>
    )
  }
}
