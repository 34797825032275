import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Honeytoastdownloadfile from './Index'

export default () => (
  <Switch>
    <Route
      exact
      path="/honeytoastdownloadfile"
      component={Honeytoastdownloadfile}
    />
  </Switch>
)
