import {
  LOAD_MASTERDATA_MATERIAL,
  CREATE_MATERIAL,
  UPDATE_MATERIAL,
  LOAD_MATERIAL,
  DELETE_SUB_MATERIAL,
  EXPORT_MATERIAL,
  IMPORT_MATERIAL,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  Lists: [],
  lists_for_edit: [],
  EditList: [],
  total: 1,
  loading: false,
  draw: -1,
  check: true,
  daata: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_MASTERDATA_MATERIAL.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        loading: loading,
      }

    case LOAD_MASTERDATA_MATERIAL.SUCCESS:
      const { page } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10
      }
      return {
        ...state,
        Lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case CREATE_MATERIAL.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_MATERIAL.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_MATERIAL.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_MATERIAL.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_MATERIAL.REQUEST:
      return {
        ...state,
        loading: true,
        check: true,
      }

    case LOAD_MATERIAL.SUCCESS:
      return {
        ...state,
        loading: false,
        lists_for_edit: action.payload.listsEdit.data,
        check: false,
      }

    case DELETE_SUB_MATERIAL.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_MATERIAL.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_MATERIAL.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case IMPORT_MATERIAL.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case IMPORT_MATERIAL.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
