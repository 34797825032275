import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadManagementVehicleVisibilityPermission(action) {
  const {
    data: { page, pageSize, username, auth },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-visibility/permission-by-user`,
      {
        page,
        pageSize,
        username,
      },
      { headers: { Authorization: `Bearer ${auth}` } },
    )
    yield put(
      actions.loadManagementVehicleVisibilityPermission.success(res.data, page),
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadManagementVehicleVisibilityPermission.failure())
  }
}

function* loadManagementVehicleVisibilityPermissionLocation(action) {
  const {
    data: { username, auth },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-visibility/location`,
      {
        username,
      },
      { headers: { Authorization: `Bearer ${auth}` } },
    )
    yield put(
      actions.loadManagementVehicleVisibilityPermissionLocation.success(
        res.data,
      ),
    )
  } catch (err) {
    console.log(err.message)
    yield put(
      actions.loadManagementVehicleVisibilityPermissionLocation.failure(),
    )
  }
}

function* loadManagementVehicleVisibilityPermissionSearch(action) {
  const {
    data: { username, auth, vehicleCode },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-visibility/search`,
      {
        username,
        vehicleCode,
      },
      { headers: { Authorization: `Bearer ${auth}` } },
    )
    yield put(
      actions.loadManagementVehicleVisibilityPermissionSearch.success(res.data),
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadManagementVehicleVisibilityPermissionSearch.failure())
  }
}

export default function* watchVehicleVisibilityPermission() {
  yield all([
    takeEvery(
      actions.LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION.REQUEST,
      loadManagementVehicleVisibilityPermission,
    ),
    takeEvery(
      actions.LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_LOCATION.REQUEST,
      loadManagementVehicleVisibilityPermissionLocation,
    ),
    takeEvery(
      actions.LOAD_MANAGEMENT_VEHICLE_VISIBILITY_SEARCH.REQUEST,
      loadManagementVehicleVisibilityPermissionSearch,
    ),
  ])
}

export {
  loadManagementVehicleVisibilityPermission,
  loadManagementVehicleVisibilityPermissionLocation,
  loadManagementVehicleVisibilityPermissionSearch,
}
