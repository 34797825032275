import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadTruckStatusDashboard(action) {
    const {
        data: {
            accessToken,
            vehicle_visibility,
            division,
            vehicle_type,
            start_date,
            end_date,
            company_id
        },
        loading,
        cb
    } = action.payload

    try {
        const params = new URLSearchParams()
        params.append('vehicle_visibility', vehicle_visibility)
        params.append('division', division)
        params.append('vehicle_type', vehicle_type)
        params.append('start_date', start_date)
        params.append('end_date', end_date)
        params.append('company_id', company_id)
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_GO_API_ENDPOINT}/dashboard/trackingstatusdashboard`,
            params,
            {
                headers: {
                    'Content-Type': `application/x-www-form-urlencoded`,
                },
            },
        )
        yield put(actions.loadTruckStatusDashboard.success(res.data))
        cb(res.data)
    } catch (err) {
        console.log(err.message)
        yield put(actions.loadTruckStatusDashboard.failure())
    }
}
export default function* watchTruckStatusDashBoard() {
    yield all([
        takeEvery(
            actions.LOAD_TRUCK_STATUS_DASHBOARD.REQUEST,
            loadTruckStatusDashboard,
        ),

    ])
}
export {
    loadTruckStatusDashboard,
}