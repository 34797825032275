import React, { Component, Fragment } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Tooltip,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Index extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        <div className="divider__web__content">
          <Divider />
        </div>
        {/* สรุปการใช้พาหนะ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'driverKpiDashboard',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/summary-fuel-usage">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.driverKpiDashboard}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานสรุปการใช้พาหนะ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* ที่อยู่ปัจจุบันของพาหนะ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'TruckcurrentlocationReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/truckcurrentlocation">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.TruckcurrentlocationReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงที่อยู่ปัจจุบันของพาหนะ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.t.TruckcurrentlocationReport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* จำนวนวันที่รถวิ่งงาน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'NumberofdaysworkedReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/numberofdaysworked">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.n.NumberofdaysworkedReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงจำนวนวันที่พาหนะวิ่งงานในแต่ละเดือน
                          โดยระบุเงื่อนไขตามระยะทางขั้นต่ำต่อวัน
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุประยะทางการวิ่งประจำวัน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'DrivingdistanceReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/drivingdistance">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.DrivingdistanceReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานสรุประยะทางการวิ่งประจำวันของพาหนะแต่ละคัน
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* ข้อมูลพนักงานขับขี่ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'DriverinformationReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/driverinformation">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.DriverinformationReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลรายละเอียดของพนักงานขับขี่
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.DriverinformationReport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* ใบขับขี่หมดอายุ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'theDriverIsIicenseReportHasExpired',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/alertdriverlicense">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.theDriverIsIicenseReportHasExpired}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลวันหมดอายุของใบขับขี่
                          ตามเงื่อนไขที่กำหนด
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* LoginhistoryReport */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'LoginhistoryReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/loginhistory">
              <Card className="card__web__content">
                <p className="report__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.l.LoginhistoryReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* LoginhistoryReport */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'GpsstatusReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/gpsstatus">
              <Card className="card__web__content">
                <p className="report__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.g.GpsstatusReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* TrackingdataReport */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'TrackingdataReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/gpsstatus">
              <Card className="card__web__content">
                <p className="report__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.t.TrackingdataReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Index
