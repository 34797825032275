import { createTypes, createAction } from 'lib/action'

const LOAD_ALARMALERTBYDRIVER = createTypes(
  'alarmalertfrequencybydriver',
  'load',
)
const LOAD_ALARMALERTBYDRIVER_PREVIEW = createTypes(
  'alarmalertfrequencybydriver_preview',
  'load',
)
const LOAD_DRIVER = createTypes('driver', 'load')

const loadAlarmAlertFrequrncyByDriver = {
  request: (data, loading, dataAlarm) =>
    createAction(LOAD_ALARMALERTBYDRIVER.REQUEST, { data, loading, dataAlarm }),
  success: data => createAction(LOAD_ALARMALERTBYDRIVER.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARMALERTBYDRIVER.FAILURE),
}

const loadAlarmAlertFrequrncyByDriverpreview = {
  request: (data, loading, dataAlarm) =>
    createAction(LOAD_ALARMALERTBYDRIVER_PREVIEW.REQUEST, {
      data,
      loading,
      dataAlarm,
    }),
  success: data =>
    createAction(LOAD_ALARMALERTBYDRIVER_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARMALERTBYDRIVER_PREVIEW.FAILURE),
}

const loadDriver = {
  request: () => createAction(LOAD_DRIVER.REQUEST, {}),
  success: data => createAction(LOAD_DRIVER.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVER.FAILURE),
}

export {
  LOAD_ALARMALERTBYDRIVER,
  LOAD_ALARMALERTBYDRIVER_PREVIEW,
  LOAD_DRIVER,
  loadAlarmAlertFrequrncyByDriver,
  loadAlarmAlertFrequrncyByDriverpreview,
  loadDriver,
}
