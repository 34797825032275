import {
  LOAD_SERVICEREPAIRDEVICE,
  LOAD_HISTORYSERVICEREPAIRDEVICE,
  CREATE_SERVICEREPAIRDEVICE,
  LOAD_VEHICLE_SERVICEREPAIRDEVICE,
  LOAD_VEHICLETYPE_SERVICEREPAIRDEVICE,
  LOAD_PROVINCE_SERVICEREPAIRDEVICE,
  LOAD_AMPHUR_SERVICEREPAIRDEVICE,
  LOAD_PROBLEM_SERVICEREPAIRDEVICE,
  LOAD_MAINTENANCESTATUS_SERVICEREPAIRDEVICE,
  LOAD_MAINTENANCETYPE_SERVICEREPAIRDEVICE,
  LOAD_ZONE_SERVICEREPAIRDEVICE,
  CLEAR_RESPONSE_SERVICEREPAIRDEVICE

} from "./actions";

const initialState = {
  historyservicerepairdeviceLists: [],
  historyservicerepairdeviceLoading: false,
  historyservicerepairdeviceTotal: 0,
  servicerepairdeviceLists: [],
  servicerepairdeviceLoading: false,
  servicerepairdeviceTotal: 0,
  dataResponse: { status: "", msg: "", id: "", action_type: "" },
  vehicleLists: [],
  vehicletypeLists: [],
  provinceLists: [],
  amphurLists: [],
  problemLists: [],
  maintenancetypeLists: [],
  maintenancestatusLists: [],
  zoneLists: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SERVICEREPAIRDEVICE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        servicerepairdeviceLoading: loading
      };
    case LOAD_SERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        servicerepairdeviceLists: action.payload.data.data,
        servicerepairdeviceLoading: false,
        servicerepairdeviceTotal: action.payload.data.total
      };
    case LOAD_HISTORYSERVICEREPAIRDEVICE.REQUEST:
      const { loading: history_loading } = action.payload;
      return {
        ...state,
        historyservicerepairdeviceLoading: history_loading
      };
    case LOAD_HISTORYSERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        historyservicerepairdeviceLists: action.payload.data.data,
        historyservicerepairdeviceLoading: false,
        historyservicerepairdeviceTotal: action.payload.data.total
      };
    case CREATE_SERVICEREPAIRDEVICE.REQUEST:
      const { loading: loading2 } = action.payload;
      return {
        ...state,
        servicerepairdeviceLoading: loading2
      };
    case CREATE_SERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        servicerepairdeviceLoading: false
      };
    case LOAD_VEHICLE_SERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data
      };
    case LOAD_VEHICLETYPE_SERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        vehicletypeLists: action.payload.data.data
      };
    case LOAD_PROVINCE_SERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        provinceLists: action.payload.data.data
      };
    case LOAD_AMPHUR_SERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        amphurLists: action.payload.data.data
      };
    case LOAD_PROBLEM_SERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        problemLists: action.payload.data.data
      };
    case LOAD_MAINTENANCETYPE_SERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        maintenancetypeLists: action.payload.data.data
      };
    case LOAD_MAINTENANCESTATUS_SERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        maintenancestatusLists: action.payload.data.data
      };
    case LOAD_ZONE_SERVICEREPAIRDEVICE.SUCCESS:
      return {
        ...state,
        zoneLists: action.payload.data.data
      };
    case CLEAR_RESPONSE_SERVICEREPAIRDEVICE:
      return {
        ...state,
        dataResponse: { status: "", msg: "", id: "", action_type: "" }
      };

    default:
      return state;
  }
};
