import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Link, withRouter } from 'react-router-dom'
import ReactCountryFlag from 'react-country-flag'
import {
  Row,
  Col,
  Layout,
  Menu,
  Icon,
  Modal,
  Drawer,
  Button,
  Tooltip,
  Popover,
  List,
  Avatar,
  Badge,
  Empty,
} from 'antd'
import history from '../../../lib/history'
import NoticeIcon from 'ant-design-pro/lib/NoticeIcon'
import Notification from './Notification'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import * as authActions from 'modules/auth/actions'
import {
  clearFlashMessage,
  loadNotificationCount,
  removeUIState,
} from 'modules/ui/actions'
import SliderBar from './SliderBar'
import styled from 'styled-components'
import { max } from 'date-fns'
import ListNotification from './ListNotification'
import { async } from 'rxjs'

const data = [
  {
    title: 'oilNotYetCalibrated',
    minute: 40,
    link: '/fuel-notification/detail-without-calibrate',
  },
  {
    title: 'oilThereIsMalfunction',
    minute: 40,
    link: '/fuel-notification/detail-abnormality',
  },
]

//////////////////////////////////////////////////////////////
const SubMenu = Menu.SubMenu
const MenuItemGroup = Menu.ItemGroup
const { Header: H } = Layout
const StyledHearder = styled(H)`
  background: white;
  padding: 0px 0px px 0px;
  height: 45px;
`

class Header extends Component {
  state = {
    collapsed: true,
    notiCount: 0,
    haveNewNoti: false,
    inputLng: '',
    visibleNotification: false,
    dataNoti: [],
    countAppoveRequestToUseVehicle: 0,
  }

  _isMounted = false

  logoutAzure = () => {
    window.location.href =
      'https://login.microsoftonline.com/5db8bf0e-8592-4ed0-82b2-a6d4d77933d4/oauth2/logout?post_logout_redirect_uri=' +
      process.env.REACT_APP_REDIRECT_URI
  }
  logoutJ2C = () => {
    window.location.href =
      'https://test-api-cpac.scg.com/auth/logout?redirect=' +
      process.env.REACT_APP_REDIRECT_URI
  }
  logoutCpac = () => {
    let userAuthen = localStorage.getItem('userAuthen')
    switch (userAuthen) {
      case 'Azure':
        this.logoutAzure()
        break
      case 'J2C':
        this.logoutJ2C()
        break
      default:
    }
  }
  onRemoveCredentials = () => {
    if (typeof this.props.profile !== 'undefined') {
      this.updateUserHistoryLog()
    }
    this.logoutCpac()
    this.props.removeCredentials()
    this.props.removeUIState()
    localStorage.setItem('count-appove-request-vehicle', 0)
  }

  updateUserHistoryLog = () => {
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updatelogonhistory`,
        {
          id: this.props.profile.user_history_logon_id,
        },
        {
          headers: { Authorization: `Bearer ${this.props.accessToken}` },
        },
      )
      .then(res => {})
  }

  onDismiss = () => {
    this.props.clearFlashMessage()
  }

  onItemClick = (item, tabProps) => {
    // history.push(item.path);
    //window.location.href = item.path;
  }

  onClear = tabTitle => {
    // console.log(tabTitle)
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  getNoticeData = (langContext, notices) => {
    if (notices.length === 0) {
      return []
    }
    const newNotices = notices.map(notice => {
      let newNotice = {
        ...notice,
        datetime: null,
        title: (
          <Link to={notice.path}>
            {
              langContext[notice.matching_name.charAt(0).toLowerCase()][
                notice.matching_name
              ]
            }
          </Link>
        ),
        avatar:
          'https://gw.alipayobjects.com/zos/rmsportal/GvqBnKhFgObvnSGkDsje.png',
        extra: (
          <Button
            style={{ marginTop: '0px' }}
            size="small"
            icon="delete"
            onClick={() =>
              this.props.deleteFavoriteMenu(
                {
                  userId: this.props.profile.id,
                  favId: newNotice.id,
                  accessToken: this.props.accessToken,
                },
                () => false,
              )
            }
          />
        ),
      }

      // transform id to item key
      if (newNotice.id) {
        newNotice.key = newNotice.id
      }

      return newNotice
    })

    return newNotices
  }

  fetchData = async () => {
    const formData = new FormData()
    formData.append('user_id', this.props.profile.id)
    await fetch(`${process.env.REACT_APP_GO_API_ENDPOINT}/notification/get`, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          dataNoti: data.data,
          countNoti: data.count,
        })
        localStorage.setItem('count-noti', data.count)
      })
  }

  fetchCountAppoveRequestToUseVehicle = async () => {
    await fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle-share-available-account/notification`,
      {
        method: 'POST',
        body: JSON.stringify({
          vehicleVisibility: this.props.profile.vehicle_visibility,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.accessToken}`,
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          this.setState({
            countAppoveRequestToUseVehicle: data.data,
          })
          localStorage.setItem('count-appove-request-vehicle', data.data)
        } else {
          console.log('API ERRORS')
        }
      })
  }

  componentDidMount() {
    this._isMounted = true
    let self = this
    let { tokenExp } = this.props
    let timeNow = Date.now()

    this.fetchData()
    this.fetchCountAppoveRequestToUseVehicle()

    if (typeof tokenExp == 'undefined' || tokenExp == null || tokenExp == '') {
      //   console.log('Token Expired')

      setTimeout(function() {
        self.modal(
          <LangContext.Consumer>
            {i18n => i18n.n.notification}
          </LangContext.Consumer>,
          <LangContext.Consumer>
            {i18n => i18n.t.tokenExpired}
          </LangContext.Consumer>,
        )
      }, 500)
    } else if (Math.floor(timeNow / 1000) < tokenExp) {
      let diffTime = (tokenExp - Math.floor(timeNow / 1000)) * 1000
      this.tokenExpTimeout = setTimeout(function() {
        // console.log('Token Expired')
        self.modal(
          <LangContext.Consumer>
            {i18n => i18n.n.notification}
          </LangContext.Consumer>,
          <LangContext.Consumer>
            {i18n => i18n.t.tokenExpired}
          </LangContext.Consumer>,
        )
      }, diffTime)
    }

    if (typeof this.props.profile.id != 'undefined')
      this.props.loadFavoriteMenu({
        userId: this.props.profile.id,
        accessToken: this.props.accessToken,
        loading: false,
      })

    this.setState({
      inputLng: 'th',
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    clearInterval(this.tokenExpTimeout)
  }

  checkSelectLang = inputLng => {
    this.props.changeLang(inputLng)
    if (inputLng == 'en') {
      this.setState({
        inputLng: 'us',
      })
    } else {
      this.setState({
        inputLng: 'th',
      })
    }
  }

  modal = (title, content) => {
    this.onRemoveCredentials()
    Modal.warning({
      title: title,
      okText: 'Ok',
      okType: 'default',
      onOk() {
        // window.location.href = "/";
      },
      content: content,
    })
  }

  handleVisibleChange = visibleNotification => {
    this.setState({
      visibleNotification,
    })
  }

  handleUnVisibleChange = () => {
    this.setState({
      visibleNotification: false,
    })
  }

  render() {
    localStorage.length == 1 && window.location.reload()
    const { done, flash } = this.props

    const CheLan = (
      <LangContext.Consumer>{i18n => i18n.changeLanguage}</LangContext.Consumer>
    )

    let firstName = JSON.parse(localStorage.getItem('profile')).firstname
      ? JSON.parse(localStorage.getItem('profile')).firstname
      : ''
    let lastName = JSON.parse(localStorage.getItem('profile')).lastname
      ? JSON.parse(localStorage.getItem('profile')).lastname
      : ''

    let countNoti = localStorage.getItem('count-noti')
    return (
      <Fragment>
        <StyledHearder>
          <audio id="audio-alert">
            <source src="/audio/alert/serious-strike.mp3" type="audio/mpeg" />
          </audio>
          <audio id="audio-sos">
            <source src="/audio/alert/sos.mp3" type="audio/mpeg" />
          </audio>
          <audio id="audio-notification">
            <source
              src="/audio/notification/slow-spring-board.mp3"
              type="audio/mpeg"
            />
          </audio>

          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{
              lineHeight: '20px',
              padding: '0px 0px 0px 0px',
              margin: '0px 0px 0px 0px',
              borderBottom: '4px solid red',
            }}
          >
            <Col xs={{ span: 0 }} sm={3} md={1} lg={1} xl={1}>
              <Icon
                style={{
                  margin: '3px 0px 0px 15px',
                  color: '#ff0b0b',
                  fontSize: '24px',
                }}
                className="trigger"
                type="menu"
                onClick={() => {
                  this.fetchCountAppoveRequestToUseVehicle()
                  this.toggle()
                }}
              />
            </Col>
            {process.env.REACT_APP_WEB_ENDPOINT == 'isupply' ? (
              <Col xs={8} sm={1} md={1} lg={1} xl={1}>
                <Link to={`/`}>
                  <img
                    style={{ width: '42px' }}
                    src="/img/isupply.png"
                    alt=""
                  />
                </Link>
              </Col>
            ) : (
              <Col xs={8} sm={5} md={4} lg={4} xl={4}>
                <Link to={`/`}>
                  <img style={{ width: '85px' }} src="/img/2.png" alt="" />
                </Link>
              </Col>
            )}
            {process.env.REACT_APP_WEB_ENDPOINT == 'isupply' ? (
              <Col xs={8} sm={3} md={3} lg={3} xl={3} align="left">
                <p
                  style={{
                    marginBottom: '0px',
                    color: '#379aec',
                    fontSize: '25px',
                    fontWeight: 500,
                  }}
                >
                  iSupply
                </p>
              </Col>
            ) : (
              ''
            )}
            <Col sm={16} md={19} lg={19} xl={19} align="right">
              <Menu
                theme="light"
                mode="horizontal"
                style={{
                  height: '46px',
                }}
                align="right"
              >
                {/* notification */}
                <SubMenu
                  className="ant-top-menu "
                  title={
                    <Popover
                      content={
                        <div className="display-popover-noti">
                          <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <LangContext.Consumer>
                                {i18n => i18n.n.notification}
                              </LangContext.Consumer>
                            </Col>
                            <Col span={12} align="right">
                              <Link
                                to="/fuel-notification"
                                style={{ color: 'red' }}
                                onClick={() => {
                                  this.handleUnVisibleChange()
                                }}
                              >
                                <LangContext.Consumer>
                                  {i18n => i18n.s.seeAll}
                                </LangContext.Consumer>
                              </Link>
                            </Col>
                            <Col span={24}>
                              <List
                                itemLayout="horizontal"
                                dataSource={this.state.dataNoti}
                                renderItem={item => (
                                  <ListNotification
                                    item={item}
                                    profileId={this.props.profile.id}
                                    handleUnVisibleChange={
                                      this.handleUnVisibleChange
                                    }
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                        </div>
                      }
                      trigger="click"
                      visible={this.state.visibleNotification}
                      onVisibleChange={this.handleVisibleChange}
                      placement="bottomRight"
                      overlayClassName="display-fuel-notification"
                    >
                      <Badge count={countNoti}>
                        <Icon
                          type="bell"
                          style={{ color: '#ff0b0b', fontSize: '16px' }}
                        />
                      </Badge>
                    </Popover>
                  }
                ></SubMenu>

                {/* star */}
                <SubMenu
                  className="ant-top-menu "
                  title={
                    <span>
                      <LangContext.Consumer>
                        {i18n => (
                          <NoticeIcon
                            onItemClick={e => e}
                            onClear={this.onClear}
                            bell={
                              <Icon
                                type="star"
                                style={{ color: '#ff0b0b', fontSize: '16px' }}
                              />
                            }
                          >
                            <NoticeIcon.Tab
                              showClear={false}
                              list={this.getNoticeData(
                                i18n,
                                this.props.favoriteMenu,
                              )}
                              title={i18n.f.favoriteMenu}
                              emptyText={i18n.n.noData}
                              emptyImage="https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg"
                            />
                          </NoticeIcon>
                        )}
                      </LangContext.Consumer>
                    </span>
                  }
                ></SubMenu>

                <SubMenu
                  className="ant-top-menu "
                  title={
                    <span>
                      <ReactCountryFlag
                        styleProps={{
                          margin: '0px 15px 5px 0px',
                          width: '20px',
                          height: '20px',
                        }}
                        code={this.state.inputLng}
                        svg
                      />
                      <Icon type="caret-down" style={{ color: '#ff0b0b' }} />
                    </span>
                  }
                >
                  <MenuItemGroup title={CheLan}>
                    <Menu.Item key="en" selected={true}>
                      <span
                        className="nav-text"
                        onClick={() => this.checkSelectLang('en')}
                      >
                        <ReactCountryFlag
                          styleProps={{
                            margin: '0px 15px 5px 0px',
                            width: '20px',
                            height: '20px',
                          }}
                          code="us"
                          svg
                        />
                        English
                      </span>
                    </Menu.Item>
                    <Menu.Item key="th" selected={true}>
                      <span
                        className="nav-text"
                        onClick={() => this.checkSelectLang('th')}
                      >
                        <ReactCountryFlag
                          styleProps={{
                            margin: '0px 15px 5px 0px',
                            width: '20px',
                            height: '20px',
                          }}
                          code="th"
                          svg
                        />
                        ไทย
                      </span>
                    </Menu.Item>
                  </MenuItemGroup>
                </SubMenu>
                <SubMenu
                  style={{ width: '220px' }}
                  className="ant-top-menu "
                  title={
                    <div>
                      <Row style={{ height: '46px' }}>
                        <Col xs={1} sm={1} md={1} lg={2} xl={2}>
                          <Icon
                            type="user"
                            style={{
                              fontSize: '16px',
                              color: '#ff0b0b',
                            }}
                          />
                        </Col>
                        <Col
                          xs={0}
                          sm={0}
                          md={23}
                          lg={20}
                          xl={20}
                          style={{ height: '46px' }}
                        >
                          <Tooltip
                            placement="bottom"
                            title={firstName + ' ' + lastName}
                            arrowPointAtCenter
                          >
                            <Row
                              style={{
                                margin: '-11px 0px 0px 20px',
                                color: '#ff0b0b',
                              }}
                            >
                              {JSON.parse(localStorage.getItem('profile'))
                                .username.length > 20
                                ? JSON.parse(
                                    localStorage.getItem('profile'),
                                  ).username.substr(0, 19) + '...'
                                : JSON.parse(localStorage.getItem('profile'))
                                    .username}
                            </Row>
                            <Row
                              style={{
                                margin: '-27px 0px 0px 20px',
                                color: '#ff0b0b',
                              }}
                            >
                              {(firstName + ' ' + lastName).length > 20
                                ? (firstName + ' ' + lastName).substr(0, 19) +
                                  '...'
                                : firstName + ' ' + lastName}
                            </Row>
                          </Tooltip>
                        </Col>
                      </Row>
                    </div>
                  }
                >
                  <MenuItemGroup
                    title={<span className="semi-bold-16">Account</span>}
                    style={{}}
                  >
                    <Menu.Item key="setting:1" style={{ paddingLeft: '14px' }}>
                      <Icon type="home" style={{ color: 'black' }} />
                      <span style={{ fontSize: '14px', color: 'black' }}>
                        <LangContext.Consumer>
                          {i18n => i18n.h.home}
                        </LangContext.Consumer>
                      </span>
                      <Link to="/" />
                    </Menu.Item>
                    <Menu.Item key="setting:2" style={{ paddingLeft: '14px' }}>
                      <Icon type="user" style={{ color: 'black' }} />
                      <span style={{ fontSize: '14px', color: 'black' }}>
                        <LangContext.Consumer>
                          {i18n => i18n.p.profile}
                        </LangContext.Consumer>
                      </span>

                      <Link to="/profile" />
                    </Menu.Item>
                    {done ? (
                      <Menu.Item
                        key="menu:1"
                        onClick={this.onRemoveCredentials}
                        style={{ paddingLeft: '14px' }}
                      >
                        <Icon type="logout" style={{ color: 'black' }} />
                        <span style={{ fontSize: '14px', color: 'black' }}>
                          <LangContext.Consumer>
                            {i18n => i18n.l.logout}
                          </LangContext.Consumer>
                        </span>

                        <Link to="/" />
                      </Menu.Item>
                    ) : (
                      <Menu.Item key="menu:1" style={{ paddingLeft: '14px' }}>
                        <span style={{ fontSize: '14px' }}>
                          <LangContext.Consumer>
                            {i18n => i18n.l.login}
                          </LangContext.Consumer>
                        </span>

                        <Link to="/" />
                      </Menu.Item>
                    )}
                  </MenuItemGroup>
                </SubMenu>
              </Menu>
            </Col>
          </Row>
        </StyledHearder>
        <Row type="flex" justify="end" style={{ margin: '0px' }} />
        {flash && (
          <Row type="flex" justify="center" style={{ margin: '0px' }}></Row>
        )}

        <Drawer
          title={<h3 className="text-header-side-bar">MENU</h3>}
          id="test-drawer"
          placement="left"
          onClose={this.toggle}
          visible={!this.state.collapsed}
          style={{ position: 'absolute' }}
          bodyStyle={{ padding: '0px', border: '0px' }}
          width={322}
          height={max}
        >
          <SliderBar
            isOpen={this.state.collapsed}
            countAppoveRequestToUseVehicle={
              this.state.countAppoveRequestToUseVehicle
            }
          />
        </Drawer>
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  auth: { accessToken, done, profile, tokenExp, favoriteMenu },
  ui: { flash, lastestNotificationAt },
}) => ({
  accessToken,
  done,
  profile,
  flash,
  lastestNotificationAt,
  tokenExp,
  favoriteMenu,
})
const mapDispatchToProps = {
  loadFavoriteMenu: authActions.loadFavoriteMenu.request,
  removeCredentials: authActions.removeCredentials,
  removeUIState: removeUIState,
  loadCredentials: authActions.loadCredentials,
  clearFlashMessage: clearFlashMessage,
  loadNotificationCount: loadNotificationCount.request,
  deleteFavoriteMenu: authActions.deleteFavoriteMenu.request,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
