import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadMasterDataSubMaterial(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      company_id,
      filterObj,
      location_visibility,
      type,
      file_value_pdf,
      limit_export,
      material_visibility,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-sub-material-list`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        company_id,
        filterObj,
        location_visibility,
        type,
        file_value_pdf,
        limit_export,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadMasterDataSubMaterial.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterDataSubMaterial.failure())
  }
}

function* createMasterDataSubMaterial(action) {
  const {
    data: {
      code,
      name,
      isupply_material_id,
      material_type,
      locations_id,
      accessToken,
    },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/create-sub-material`,
      {
        code,
        name,
        isupply_material_id,
        material_type,
        locations_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createMasterDataSubMaterial.success(res.data))
  } catch (err) {
    yield put(actions.createMasterDataSubMaterial.failure())
  }
}

function* loadSubMaterialDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-sub-material-detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadSubMaterialDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadSubMaterialDetail.failure())
  }
}

function* updateMasterDataSubMaterial(action) {
  const {
    data: {
      id,
      code,
      name,
      isupply_material_id,
      material_type,
      locations_id,
      accessToken,
    },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-sub-material-update`,
      {
        id,
        code,
        name,
        isupply_material_id,
        material_type,
        locations_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateMasterDataSubMaterial.success(res.data))
  } catch (err) {
    yield put(actions.updateMasterDataSubMaterial.failure())
  }
}

function* exportMasterDataSubMaterial(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      company_id,
      filterObj,
      location_visibility,
      type,
      file_value_pdf,
      limit_export,
      material_visibility,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-sub-material-list`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        company_id,
        filterObj,
        location_visibility,
        type,
        file_value_pdf,
        limit_export,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportMasterDataSubMaterial.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportMasterDataSubMaterial.failure())
  }
}

function* importtSubMaterial(action) {
  const {
    data: { files, accessToken, typeImport },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('typeImport', typeImport)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdata-sub-material-import-excel`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    loading(res.data)
    yield put(actions.importtSubMaterial.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.importtSubMaterial.failure())
  }
}

export default function* watchSubMaterial() {
  yield all([
    takeEvery(
      actions.LOAD_MASTERDATA_SUB_MATERIAL.REQUEST,
      loadMasterDataSubMaterial,
    ),
    takeEvery(
      actions.CREATE_MASTERDATA_SUB_MATERIAL.REQUEST,
      createMasterDataSubMaterial,
    ),
    takeEvery(actions.LOAD_SUB_MATERIAL_DETAIL.REQUEST, loadSubMaterialDetail),
    takeEvery(
      actions.UPDATE_MASTERDATA_SUB_MATERIAL.REQUEST,
      updateMasterDataSubMaterial,
    ),
    takeEvery(
      actions.EXPORT_MASTERDATA_SUB_MATERIAL.REQUEST,
      exportMasterDataSubMaterial,
    ),
    takeEvery(actions.IMPORT_SUB_MATERIAL.REQUEST, importtSubMaterial),
  ])
}

export {
  loadMasterDataSubMaterial,
  createMasterDataSubMaterial,
  updateMasterDataSubMaterial,
  exportMasterDataSubMaterial,
  loadSubMaterialDetail,
  importtSubMaterial,
}
