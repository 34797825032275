import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadMasterDataTruckPickupCardNo(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      type,
      file_value_pdf,
      limit_export,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-trucks-pickup-card`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        accessToken,
        filterObj,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadMasterDataTruckPickupCardNo.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterDataTruckPickupCardNo.failure())
  }
}

function* exportMasterDataTruckPickupCardNo(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      type,
      file_value_pdf,
      limit_export,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-trucks-pickup-card`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        accessToken,
        filterObj,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportMasterDataTruckPickupCardNo.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportMasterDataTruckPickupCardNo.failure())
  }
}

function* createMasterDataTruckPickupCardNo(action) {
  const {
    data: {
      card_no,
      material_id,
      sub_material_id,
      vehicles_id,
      company_id,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/create-trucks-pickup-card`,
      {
        card_no,
        material_id,
        sub_material_id,
        vehicles_id,
        company_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createMasterDataTruckPickupCardNo.success(res.data))
  } catch (err) {
    yield put(actions.createMasterDataTruckPickupCardNo.failure())
  }
}

function* loadMasterDataTruckPickupCardNoDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-trucks-pickup-card/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadMasterDataTruckPickupCardNoDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterDataTruckPickupCardNoDetail.failure())
  }
}

function* updateMasterDataTruckPickupCardNo(action) {
  const {
    data: {
      id,
      card_no,
      material_id,
      sub_material_id,
      vehicles_id,
      company_id,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/update-trucks-pickup-card`,
      {
        id,
        card_no,
        material_id,
        sub_material_id,
        vehicles_id,
        company_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateMasterDataTruckPickupCardNo.success(res.data))
  } catch (err) {
    yield put(actions.updateMasterDataTruckPickupCardNo.failure())
  }
}

function* importMasterDataTruckPickupCardNo(action) {
  const {
    data: { files, accessToken, typeImport },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('typeImport', typeImport)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/trucks-pickup-card-excel`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    loading(res.data)
    yield put(actions.importMasterDataTruckPickupCardNo.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.importMasterDataTruckPickupCardNo.failure())
  }
}

export default function* watchTruckPickupCard() {
  yield all([
    takeEvery(
      actions.LOAD_TRUCKS_PICKUP_CARD_NO.REQUEST,
      loadMasterDataTruckPickupCardNo,
    ),
    takeEvery(
      actions.CREATE_TRUCKS_PICKUP_CARD_NO.REQUEST,
      createMasterDataTruckPickupCardNo,
    ),
    takeEvery(
      actions.LOAD_TRUCKS_PICKUP_CARD_NO_DETAIL.REQUEST,
      loadMasterDataTruckPickupCardNoDetail,
    ),
    takeEvery(
      actions.UPDATE_TRUCKS_PICKUP_CARD_NO.REQUEST,
      updateMasterDataTruckPickupCardNo,
    ),
    takeEvery(
      actions.EXPORT_TRUCKS_PICKUP_CARD_NO.REQUEST,
      exportMasterDataTruckPickupCardNo,
    ),
    takeEvery(
      actions.IMPORT_TRUCKS_PICKUP_CARD_NO.REQUEST,
      importMasterDataTruckPickupCardNo,
    ),
  ])
}
export {
  loadMasterDataTruckPickupCardNo,
  createMasterDataTruckPickupCardNo,
  loadMasterDataTruckPickupCardNoDetail,
  updateMasterDataTruckPickupCardNo,
  exportMasterDataTruckPickupCardNo,
  importMasterDataTruckPickupCardNo,
}
