import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Transfer,
  Checkbox,
  Dropdown,
  Menu,
  Icon
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";

const {  RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;

export default class Forms extends Component {
  state = {
    vehicleList: [],
    parttypeList: [],
    vehicleSelect: [],
    parttypeSelect: [],
    selectedKeys: [],
    selectedparttypeKeys: []
  };

  componentDidMount() {
    const { loadVehicle, loadVehicleType, loadPartType } = this.props;
    loadVehicle("", false);
    loadVehicleType();
    loadPartType();
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehicle } = this.props;
    setFieldValue("vehicle_type_id", value);
    loadVehicle(value, true);
    this.setState({ vehicleSelect: [] });
    this.setState({ selectedKeys: [] });
    setFieldValue("list_vehicle_id", []);
  };

  componentWillReceiveProps = nextProps => {
    const { vehicleLists, partTypeLists } = nextProps;
    const vehlist = [];
    const partlist = [];
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no
      })
    );


    partTypeLists.map(item =>
      partlist.push({
        key: item.id,
        name: item.name
      })
    );
    this.setState({ vehicleList: vehlist });
    this.setState({ parttypeList: partlist });
  };

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue("list_vehicle_id", targetKeys);
    this.setState({ vehicleSelect: targetKeys });
  };

  tranferPartTypeChange = (targetKeys, setFieldValue) => {
    setFieldValue("list_part_type_id", targetKeys);
    this.setState({ parttypeSelect: targetKeys });
  };


  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    });
  };

  handlePartTypeSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedparttypeKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    });
  };



  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    // console.log('click', e.key);
    if (e.key == "excel") {
      setFieldValue("type_file", "excel", false);
    } else if (e.key == "pdf") {
      setFieldValue("type_file", "pdf", false);
    }
    handleSubmit();
  };

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      partTypeLists,
      distfromlastmaintenanceLoading,
      onSubmit
    } = this.props;

    return (
      <div>
        <h3> <LangContext.Consumer>
          {i18n => i18n.d.DistfromlastmaintenanceReport}
        </LangContext.Consumer></h3>
        <Spin spinning={distfromlastmaintenanceLoading}>
          <Formik
            initialValues={{
              vehicle_type_id: "",
              list_vehicle_id: [],
              list_part_type_id: []
            }}
            validate={values => {
              let errors = {};

              return errors;
            }}
            validationSchema={yup.object().shape({
              list_vehicle_id: yup.array().required("กรุณาเลือกรถ"),
              list_part_type_id: yup.array().required("กรุณาเลือกอะไหล่รถ")
            })}
            onSubmit={values => {
              //console.log(values);
              const loading = true;
              onSubmit(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating
            }) => (
                <Row type="flex" justify="center">
                  <Form onSubmit={handleSubmit}>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.p.part_type}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.list_part_type_id &&
                        errors.list_part_type_id &&
                        "error"
                      }
                      help={touched.list_part_type_id && errors.list_part_type_id}
                    >
                      <Transfer titles={[
                  <LangContext.Consumer>
                    {i18n => i18n.i.itemLeft}
                  </LangContext.Consumer>,
                  <LangContext.Consumer>
                    {i18n => i18n.i.itemSelected}
                  </LangContext.Consumer>
                ]}
                        dataSource={this.state.parttypeList}
                        showSearch
                        listStyle={{
                          width: 250,
                          height: 300
                        }}
                        selectedKeys={this.state.selectedparttypeKeys}
                        operations={[">>", "<<"]}
                        targetKeys={this.state.parttypeSelect}
                        onSelectChange={this.handlePartTypeSelectChange}
                        onChange={value =>
                          this.tranferPartTypeChange(value, setFieldValue)
                        }
                        //onChange={this.tranferChange}
                        render={item => `${item.name}`}
                        footer={this.renderFooter}
                      />
                    </FormItem>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicleGroup}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.vehicle_type_id &&
                        errors.vehicle_type_id &&
                        "error"
                      }
                      help={touched.vehicle_type_id && errors.vehicle_type_id}
                    >
                      <Select
                        onChange={value =>
                          this.onVehicleTypeChange(value, setFieldValue)
                        }
                        value={values.vehicle_type_id}
                      >
                        <Option value="">ทั้งหมด</Option>
                        {vehicleTypeLists.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicle}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.list_vehicle_id &&
                        errors.list_vehicle_id &&
                        "error"
                      }
                      help={touched.list_vehicle_id && errors.list_vehicle_id}
                    >
                      <Transfer titles={[
                  <LangContext.Consumer>
                    {i18n => i18n.i.itemLeft}
                  </LangContext.Consumer>,
                  <LangContext.Consumer>
                    {i18n => i18n.i.itemSelected}
                  </LangContext.Consumer>
                ]}
                        dataSource={this.state.vehicleList}
                        showSearch
                        listStyle={{
                          width: 250,
                          height: 300
                        }}
                        selectedKeys={this.state.selectedKeys}
                        operations={[">>", "<<"]}
                        targetKeys={this.state.vehicleSelect}
                        onSelectChange={this.handleSelectChange}
                        onChange={value =>
                          this.tranferChange(value, setFieldValue)
                        }
                        //onChange={this.tranferChange}
                        render={item => `${item.name}`}
                        footer={this.renderFooter}
                      />
                    </FormItem>


                    <FormItem>
                      <Row gutter={24}>
                        <Col span={8}>
                          <Button type="defualt" block>
                            <Link to="/"><LangContext.Consumer>
                              {i18n => i18n.c.cancel}
                            </LangContext.Consumer></Link>
                          </Button>
                        </Col>
                        <Col span={8}>
                          <Dropdown
                            overlay={
                              <Menu
                                onClick={value =>
                                  this.handleMenuClick(
                                    value,
                                    setFieldValue,
                                    handleSubmit
                                  )
                                }
                              >
                                <Menu.Item key="excel">
                                  <Icon type="file-excel" /> <LangContext.Consumer>
                                    {i18n => i18n.e.excel}
                                  </LangContext.Consumer>
                                </Menu.Item>
                                <Menu.Item key="pdf">
                                  <Icon type="file-pdf" /> <LangContext.Consumer>
                                    {i18n => i18n.p.pdf}
                                  </LangContext.Consumer>
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <Button type="primary" block>
                              <LangContext.Consumer>
                                {i18n => i18n.r.report}
                              </LangContext.Consumer> <Icon type="down" />
                            </Button>
                          </Dropdown>
                        </Col>
                      </Row>
                    </FormItem>
                  </Form>
                </Row>
              )}
          />
        </Spin>
      </div>
    );
  }
}
