import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
//import { push } from "connected-react-router";

import * as actions from './actions'
import moment from 'moment'

function* loadScoresummary(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const {
    data: { list_vehicle_id, eventdate, type_file },
  } = action.payload
  const date_start = moment(eventdate[0]['_d'])
    .add(-7, 'hours')
    .format('YYYY-MM-DD HH:mm:00')
  const date_end = moment(eventdate[1]['_d'])
    .add(-7, 'hours')
    .format('YYYY-MM-DD HH:mm:59')
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/scoresummary`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id: COMPANY_ID,
        type_file,
      },
      // { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    )
    yield put(actions.loadScoresummary.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadScoresummary.failure())
  }
}

function* loadScoresummarypreview(action) {
  const {
    data: { list_vehicle_id, date_start, date_end, type_file, company_id },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/scoresummary`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id,
        type_file,
      },
    )
    yield put(actions.loadScoresummarypreview.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadScoresummarypreview.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_SCORESUMMARY.REQUEST, loadScoresummary),
    takeEvery(
      actions.LOAD_SCORESUMMARY_PREVIEW.REQUEST,
      loadScoresummarypreview,
    ),
    takeEvery(actions.LOAD_VEHICLE_SCORESUMMARY.REQUEST, loadVehicle),
  ])
}

export { loadScoresummary, loadVehicle, loadScoresummarypreview }
