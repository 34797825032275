import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadMasterDataSampleImage(action) {
  const {
    data: { page, pageSize, orderBy, orderType, accessToken, filterObj },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-sample-image`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadMasterDataSampleImage.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterDataSampleImage.failure())
  }
}

function* loadMasterDataSampleImageDetail(action) {
  const {
    data: { id, accessToken },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-sample-image/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.loadMasterDataSampleImageDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterDataSampleImageDetail.failure())
  }
}

function* updateMasterDataSampleImage(action) {
  const {
    data: { id, image_name, image, isEdit, accessToken },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (image != '') {
    image.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('id', id)
  formData.append('files', uploadFile)
  formData.append('image_name', image_name)
  formData.append('isEdit', isEdit)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/update-sample-image`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateMasterDataSampleImage.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateMasterDataSampleImage.failure())
  }
}

export default function* watchIsupplySampleImage() {
  yield all([
    takeEvery(
      actions.LOAD_MASTERDATA_SAMPLE_IMAGE.REQUEST,
      loadMasterDataSampleImage,
    ),
    takeEvery(
      actions.LOAD_MASTERDATA_SAMPLE_IMAGE_DETAIL.REQUEST,
      loadMasterDataSampleImageDetail,
    ),
    takeEvery(
      actions.UPDATE_MASTERDATA_SAMPLE_IMAGE.REQUEST,
      updateMasterDataSampleImage,
    ),
  ])
}
export {
  loadMasterDataSampleImage,
  loadMasterDataSampleImageDetail,
  updateMasterDataSampleImage,
}
