import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'

import * as actions from '../actions'
import Form from './Forms'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from './../../../shared/components/Breadcrumb'

class Edit extends Component {
  componentDidMount() {
    this.props.getLineNotify(
      this.props.match.params.id,
      this.props.auth.accessToken,
    )
  }

  edit = (values, loading) => {
    values.company_id = this.props.auth.profile.company_id
    values.token = this.props.auth.accessToken
    this.props.updateLineNotify(
      values,
      this.props.match.params.id,
      this.props.history,
      loading,
    )
  }

  render() {
    return (
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: '0px 0px 14px 10px' }}
            />
          </Col>
        </Row>
        <Form
          onSubmit={this.edit}
          linenotify={this.props.linenotify}
          id={this.props.match.params.id}
          auth={this.props.auth}
        />
      </GeneralStyledContent>
    )
  }
}

const mapStateToProps = ({ linenotify, auth }) => ({
  linenotify,
  auth,
})

const mapDispatchToProps = {
  updateLineNotify: actions.updateLineNotify.request,
  getLineNotify: actions.getLineNotifyByID.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
