import React from 'react'
import { Switch, Route } from 'react-router-dom'
import FuelNotification from './Index'
import FuelDetailAbnormality from './components/FuelDetailAbnormality'
import FuelDetailWithoutCalibrate from './components/FuelDetailWithoutCalibrate'

export default () => (
  <Switch>
    <Route exact path="/fuel-notification" component={FuelNotification} />
    <Route
      exact
      path="/fuel-notification/detail-abnormality"
      component={FuelDetailAbnormality}
    />
    <Route
      exact
      path="/fuel-notification/detail-without-calibrate"
      component={FuelDetailWithoutCalibrate}
    />
  </Switch>
)
