import {
  LOAD_DRIVINGDISTANCE,
  LOAD_DRIVINGDISTANCE_PREVIEW,
  LOAD_VEHICLE_DRIVINGDISTANCE,
  LOAD_VEHICLE_TYPE_DRIVINGDISTANCE,
} from './actions'

const initialState = {
  drivingdistanceUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  drivingdistanceLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DRIVINGDISTANCE.REQUEST:
    case LOAD_DRIVINGDISTANCE_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        drivingdistanceLoading: loading,
      }
    case LOAD_DRIVINGDISTANCE.SUCCESS:
    case LOAD_DRIVINGDISTANCE_PREVIEW.SUCCESS:
      return {
        ...state,
        drivingdistanceUrl: action.payload.data.data,
        drivingdistanceLoading: false,
      }
    case LOAD_VEHICLE_DRIVINGDISTANCE.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        drivingdistanceLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_DRIVINGDISTANCE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        drivingdistanceLoading: false,
      }
    case LOAD_VEHICLE_TYPE_DRIVINGDISTANCE.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
