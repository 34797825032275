import {
  LOAD_LOCATION_SMART_DISPATCHING,
  UPDATE_SMART_DISPATCHING,
  LOAD_SMART_DISPATCHING,
  CREATE_SMART_DISPATCHING,
  GET_NEAR_BY_LOCATION_DISPATCHING,
} from './actions'

const initialState = {
  Lists: [],
  ListsRoute: [],
  NearByLocationList: [],
  loading: false,
  respon: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NEAR_BY_LOCATION_DISPATCHING:
      return {
        ...state,
        loading: false,
        respon: state,
        NearByLocationList: action.payload.data,
      }

    case CREATE_SMART_DISPATCHING.SUCCESS:
      return {
        ...state,
        loading: false,
        respon: state,
      }

    case LOAD_SMART_DISPATCHING.REQUEST:
      // const { loading } = action.payload.data
      return {
        ...state,
        loading: action.payload.loading,
      }

    case LOAD_SMART_DISPATCHING.SUCCESS:
      return {
        ...state,
        ListsRoute: action.payload.lists,
        loading: false,
      }

    case LOAD_LOCATION_SMART_DISPATCHING.REQUEST:
      // const { loading } = action.payload.data
      return {
        ...state,
        loading: action.payload.loading,
      }

    case LOAD_LOCATION_SMART_DISPATCHING.SUCCESS:
      //   console.log('load location success', action.payload)
      return {
        ...state,
        Lists: action.payload.lists,
        loading: false,
      }

    case UPDATE_SMART_DISPATCHING.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
