import React, { Component, Fragment } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Index extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        <div className="divider__web__content">
          <Divider />
        </div>
        {/* พฤติกรรมการรูดบัตรก่อนขับขี่รายวัน */}
        {checkMenuVisibility('', web_endpoint, 'concreteTrip', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/rmcconcretetrip">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.c.concreteTrip}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงสรุปรายละเอียดการส่งคอนกรีตแยกตาม DP
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* การขนส่งวัตถุดิบ */}
        {checkMenuVisibility(
          'isupply',
          web_endpoint,
          'deliveryReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/isupply-delivery-report">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.deliveryReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลสถานะการขนส่งวัตถุดิบ
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.deliveryReport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* ประวัติการชั่งน้ำหนัก */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'weighingHistoryReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/isupply-weighing-history-report">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.w.weighingHistoryReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับแสดงประวัติการชั่งน้ำหนักของพาหนะแต่ละคัน จาก
                          iSupply Scale
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.w.weighingHistoryReport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* จัดส่ง พื้น-ผนัง */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'floorWallDelivery',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/precast-report">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.f.floorWallDelivery}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงสรุปรายละเอียดการจัดส่งพื้นผนัง
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text_one_line_card_web_content">
                  <LangContext.Consumer>
                    {i18n => i18n.f.floorWallDelivery}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* ระยะทางการจัดส่งคอนกรีต */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'distancereport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/distancereport">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.distancereport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานสรุประยะทางการจัดส่งคอนกรีตของ GPS เทียบกับ
                          Bluenet
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.distancereport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* เทปูนนอกจุด */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'unauthorizeunload',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/unauthorizeunload">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.u.unauthorizeunload}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานเทปูนนอกจุด
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: 'larger',
                          paddingTop: 17,
                        }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.distancereport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปการวิ่งประจำวัน */}
        {checkMenuVisibility('', web_endpoint, 'DailyReport', permissions) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/daily">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.DailyReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงสรุปรายละเอียดการส่งคอนกรีตแยกตาม DP
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.DailyReport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปการวิ่งแยกตามทริป */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'TripsummaryReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/tripsummary">
              <Card className="card__web__content">
                <p className="report__alern__alarm__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.t.TripsummaryReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}

        {/* สรุปจำนวนงานตามสถานที่ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'TripsumbylocationReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/tripsumbylocation">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.TripsumbylocationReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานสรุปจำนวนการจัดส่งคอนกรีต แยกตามสถานที่
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.t.TripsumbylocationReport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปการวิ่งแยกตามพาหนะ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'TripsumbyvehReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/tripsumbyveh">
              <Card className="card__web__content">
                <p className="report__alern__alarm__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.t.TripsumbyvehReport}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Index
