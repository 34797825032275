import { createTypes, createAction } from 'lib/action'
const LOAD_MASTERDATA_USER = createTypes('masterdatauser', 'load')
const CREATE_MASTERDATA_USER = createTypes('masterdatauser', 'create')
const LOAD_DETAILMASTERDATA_USER = createTypes('detailmasterdatauser', 'load')
const LOAD_DETAILMASTERDATA_FOR_EDIT = createTypes(
  'editdetailmasterdata',
  'load',
)
const UPDATE_MASTERDATA_USER = createTypes('masterdatauser', 'update')
const EXPORT_MASTERDATA_USER = createTypes('exportmasterdatauser', 'load')
const IMPORT_USER = createTypes('importuser', 'import')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadMasterUser = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_USER.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_MASTERDATA_USER.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_MASTERDATA_USER.FAITLURE),
}

const createMasterUser = {
  request: (data, loading) =>
    createAction(CREATE_MASTERDATA_USER.REQUEST, { data, loading }),
  success: res => createAction(CREATE_MASTERDATA_USER.SUCCESS, { res }),
  failure: () => createAction(CREATE_MASTERDATA_USER.FAILURE),
}

const loadDetailMasterUser = {
  request: (data, loading) =>
    createAction(LOAD_DETAILMASTERDATA_USER.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_DETAILMASTERDATA_USER.SUCCESS, { lists }),
  failure: () => createAction(LOAD_DETAILMASTERDATA_USER.FAITLURE),
}

const loadDetailMasterDataForEdit = {
  request: (data, loading) =>
    createAction(LOAD_DETAILMASTERDATA_FOR_EDIT.REQUEST, { data, loading }),
  success: lists =>
    createAction(LOAD_DETAILMASTERDATA_FOR_EDIT.SUCCESS, { lists }),
  failure: () => createAction(LOAD_DETAILMASTERDATA_FOR_EDIT.FAITLURE),
}

const updateMasterDataUser = {
  request: (data, loading) =>
    createAction(UPDATE_MASTERDATA_USER.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_MASTERDATA_USER.SUCCESS, { res }),
  failure: () => createAction(UPDATE_MASTERDATA_USER.FAILURE),
}

const exportMasterUser = {
  request: (data, loading) =>
    createAction(EXPORT_MASTERDATA_USER.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_MASTERDATA_USER.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_MASTERDATA_USER.FAITLURE),
}

const importUser = {
  request: (data, loading) =>
    createAction(IMPORT_USER.REQUEST, { data, loading }),
  success: res => createAction(IMPORT_USER.SUCCESS, { res }),
  failure: () => createAction(IMPORT_USER.FAITLURE),
}

export {
  LOAD_MASTERDATA_USER,
  loadMasterUser,
  createMasterUser,
  CREATE_MASTERDATA_USER,
  loadDetailMasterUser,
  LOAD_DETAILMASTERDATA_USER,
  LOAD_DETAILMASTERDATA_FOR_EDIT,
  loadDetailMasterDataForEdit,
  UPDATE_MASTERDATA_USER,
  updateMasterDataUser,
  EXPORT_MASTERDATA_USER,
  exportMasterUser,
  REMOVE_LISTS,
  importUser,
  IMPORT_USER,
  removeLists,
}
