import {
  LOAD_FUELUSE,
  LOAD_FUELUSE_PREVIEW,
  LOAD_VEHICLE_FUELUSE,
  LOAD_VEHICLE_TYPE_FUELUSE,
} from './actions'

const initialState = {
  fueluseUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  fueluseLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FUELUSE.REQUEST:
    case LOAD_FUELUSE_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        fueluseLoading: loading,
      }
    case LOAD_FUELUSE.SUCCESS:
    case LOAD_FUELUSE_PREVIEW.SUCCESS:
      return {
        ...state,
        fueluseUrl: action.payload.data.data,
        fueluseLoading: false,
      }
    case LOAD_VEHICLE_FUELUSE.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        fueluseLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_FUELUSE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        fueluseLoading: false,
        //  loading: false
      }
    case LOAD_VEHICLE_TYPE_FUELUSE.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
