import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Spin, Col, Button, Row } from 'antd'
import axios from 'axios'

import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext from 'modules/shared/context/langContext'

class Previewtable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataPreview: [],
      loadPreview: false,
      filter: '',
      header: '',
      loading: false,
    }

    this.columns = [
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.b.boxnumber}
          </LangContext.Consumer>
        ),
        dataIndex: 'device_code',
        key: 'device_code',
      },
      {
        title: 'SIM Number',
        dataIndex: 'sim_no',
        key: 'sim_no',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.v.vehicleNumber}
          </LangContext.Consumer>
        ),
        dataIndex: 'vehicle_code',
        key: 'vehicle_code',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.p.plateNoCar}
          </LangContext.Consumer>
        ),
        dataIndex: 'plate_no',
        key: 'plate_no',
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.p.plants}</LangContext.Consumer>
        ),
        dataIndex: 'plant',
        key: 'plant',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.g.gpsProvider}
          </LangContext.Consumer>
        ),
        dataIndex: 'vendor_name',
        key: 'vendor_name',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.w.working_day}
          </LangContext.Consumer>
        ),
        dataIndex: 'working_day',
        key: 'working_day',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.d.day_in_month}
          </LangContext.Consumer>
        ),
        dataIndex: 'day_in_month',
        key: 'day_in_month',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.p.percentage_working}
          </LangContext.Consumer>
        ),
        dataIndex: 'percentage_working_day',
        key: 'percentage_working_day',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.a.amount_money}
          </LangContext.Consumer>
        ),
        dataIndex: 'amount_money',
        key: 'amount_money',
      },
    ]
  }

  handleMenuClick = () => {
    let self = this
    if (self.state.filter !== '' && self.state.header !== '') {
      let data = {
        ...self.state.filter,
        type_file: 'excel',
        ...self.state.header,
      }
      self.setState({ loading: true })

      fetch(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/partnerperform`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            date_start: data.date_start,
            date_end: data.date_end,
            partner_list: data.partner_list,
            sort_by: data.sort_by,
            vehicle_visibility: data.vehicle_visibility,
            type_file: data.type_file,
          }),
        },
      )
        .then(response => response.json())
        .then(res => {
          self.setState({ loading: false }, () =>
            window.location.assign(
              `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data}`,
            ),
          )
        })
        .catch(e => this.setState({ loading: false }))
    }
  }

  componentDidMount() {
    if (
      localStorage.getItem('partnerperformreport-filter') != null &&
      localStorage.getItem('partnerperformreport-header') != null
    ) {
      let self = this
      let filter = localStorage.getItem('partnerperformreport-filter')
      let header = localStorage.getItem('partnerperformreport-header')
      self.setState({
        filter: JSON.parse(filter),
        header: JSON.parse(header),
        loadPreview: true,
      })
      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/partnerperform`,
          JSON.parse(filter),
          JSON.parse(header),
        )
        .then(function(response) {
          let data = []
          if (typeof response.data !== 'undefined') {
            for (var key in response.data) {
              data = [...data, response.data[key]]
            }
            self.setState({
              dataPreview: data,
              loadPreview: false,
            })
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('partnerperformreport-filter')
    localStorage.removeItem('partnerperformreport-header')
  }

  render() {
    const columns = this.columns.map(col => col)

    return (
      <AuthorizeComponent {...this.props} matching_name="partnerPerformReport">
        <Spin spinning={this.state.loading}>
          <Spin spinning={this.state.loadPreview}>
            <GeneralStyledContent>
              <Row
                gutter={24}
                type="flex"
                justify="end"
                style={{ height: '38px' }}
              >
                <Col xs={9} sm={4} md={3} lg={2} xl={2}>
                  <Button
                    type="primary"
                    block
                    onClick={() => this.handleMenuClick()}
                  >
                    <LangContext.Consumer>
                      {i18n => i18n.r.report}
                    </LangContext.Consumer>
                  </Button>
                </Col>
              </Row>

              <Table
                scroll={{ y: `${window.innerHeight - 300}px` }}
                dataSource={this.state.dataPreview}
                columns={columns}
                bordered
              />
            </GeneralStyledContent>
          </Spin>
        </Spin>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

export default connect(mapStateToProps, null)(Previewtable)
