import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Behaviordriving from './Index'
import Preview from './Previewtable'

export default () => (
  <Switch>
    <Route
      exact
      path="/report/behaviordrivingbycompany"
      component={Behaviordriving}
    />
    <Route
      exact
      path="/report/behaviordrivingbycompany/preview"
      component={Preview}
    />
  </Switch>
)
