import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Link } from "react-router-dom";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { StyledSearchForm } from "../../../../styled/common-styled";
import axios from "axios";
import { Button, Row, Col, Spin } from "antd";

class Detail extends Component {
  state = {
    loading: true,
    detail: {
      name: "",
      description: "",
    },
  };

  componentDidMount() {
    let self = this;
    let id = this.props.match.params.id;
    let token = this.props.auth.accessToken;
    axios
      .get(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/uom/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function(response) {
        //console.log(response.data);
        self.setState({ detail: response.data.data, loading: false });
      })
      .catch(function(error) {
        console.log(error);
      });

    // End Load User Group Premission
  }

  render() {
    return (
      <AuthorizeComponent {...this.props} matching_name="uom">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={5} lg={5} xl={5} />
            <Col sm={24} md={14} lg={14} xl={14}>
              <Spin spinning={this.state.loading}>
                <StyledSearchForm>
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col span={8} style={{ padding: "5px" }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {(i18n) => i18n.u.uom_name}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      {this.state.detail.name}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col span={8} style={{ padding: "5px" }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {(i18n) => i18n.u.uom_description}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      {this.state.detail.description}
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "17px", marginBottom: "20px" }}>
                    <Col
                      span={8}
                      style={{ padding: "5px" }}
                      align="right"
                    ></Col>
                    <Col span={16} style={{ padding: "5px" }}>
                      <Link to={`/uom`}>
                        <Button
                          type="default"
                          style={{ marginLeft: "5px", marginRight: "10px" }}
                        >
                          <LangContext.Consumer>
                            {(i18n) => i18n.b.back}
                          </LangContext.Consumer>
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </StyledSearchForm>
              </Spin>
            </Col>
            <Col sm={24} md={5} lg={5} xl={5} />
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps, null)(Detail);
