import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadVehicleGroup(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      vehicle_visibility,
      vehicle_group_visibility,
      accessToken,
      searchName,
      companyID,
      filterObj,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataVehicleGroup`,
      //  `http://booking-api/api/vehicleGroup/${id}`,
      {
        page,
        pageSize,
        vehicle_visibility,
        vehicle_group_visibility,
        orderBy,
        orderType,
        searchName,
        companyID,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadVehicleGroup.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadVehicleGroup.failure())
  }
}

function* createVehicleGroup(action) {
  const {
    data: {
      companyID,
      selected,
      vehiclegroupname,
      action_name,
      matching_name,
      accessToken,
      dataVisibilityNode,
    },
    cb,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createVehicleGroup`,
      {
        companyID,
        selected,
        vehiclegroupname,
        action_name,
        matching_name,
        dataVisibilityNode,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb(res.data)
    yield put(actions.createVehicleGroup.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createVehicleGroup.failure())
  }
}

function* loadvehicleGroupDetail(action) {
  const {
    data: {
      id,
      page,
      pageSize,
      orderBy,
      orderType,
      searchName,
      searchCode,
      accessToken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehiclegroupname/${id}`,
      {
        id,
        page,
        pageSize,
        orderBy,
        orderType,
        searchName,
        searchCode,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadvehicleGroupDetail.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadvehicleGroupDetail.failure())
  }
}

function* upDateVehicleGroup(action) {
  const {
    data: {
      vehicle_groups_id,
      companyId,
      groupname,
      selected,
      action_name,
      matching_name,
      dataVisibilityNode,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/editVehicleGroup`,
      {
        vehicle_groups_id,
        companyId,
        groupname,
        selected,
        action_name,
        matching_name,
        dataVisibilityNode,
        accessToken,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.upDateVehicleGroup.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.upDateVehicleGroup.failure())
  }
}

function* exportVehicleGroup(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      vehicle_visibility,
      vehicle_group_visibility,
      accessToken,
      searchName,
      companyID,
      filterObj,
      type,
      file_value_pdf,
      limit_export,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataVehicleGroup`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        vehicle_visibility,
        vehicle_group_visibility,
        accessToken,
        searchName,
        companyID,
        filterObj,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportVehicleGroup.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    yield put(actions.exportVehicleGroup.failure())
  }
}

function* loadVisibilityByVehicleGroup(action) {
  const { id, token } = action.payload.data
  const { callback } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatauser/getDataVisibilityByVehicleGroup/${id}`,
      { headers: { Authorization: `Bearer ${token}` } },
    )

    callback(res.data)
    yield put(actions.loadVisibilityByVehicleGroup.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.loadVisibilityByVehicleGroup.failure())
  }
}

export default function* watchDataVehicleGroup() {
  yield all([
    takeEvery(actions.LOAD_VEHICLEGROUP.REQUEST, loadVehicleGroup),
    takeEvery(actions.CREATE_VEHICLEGROUP.REQUEST, createVehicleGroup),
    takeEvery(actions.LOAD_VEHICLEGROUP_DETAIL.REQUEST, loadvehicleGroupDetail),
    takeEvery(actions.UPDATE_VEHICLEGROUP.REQUEST, upDateVehicleGroup),
    takeEvery(actions.EXPORT_VEHICLEGROUP.REQUEST, exportVehicleGroup),
    takeEvery(
      actions.LOAD_VISIBILITY_BY_VEHICLE_GROUP.REQUEST,
      loadVisibilityByVehicleGroup,
    ),
  ])
}

export {
  loadVehicleGroup,
  createVehicleGroup,
  loadvehicleGroupDetail,
  upDateVehicleGroup,
  exportVehicleGroup,
  loadVisibilityByVehicleGroup,
}
