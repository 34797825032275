import { createTypes, createAction } from 'lib/action'

const LOAD_MASTERDATA_SITEGROUP = createTypes('masterdatasitegroup', 'load')
const CREATE_MASTERDATA_SITEGROUP = createTypes('masterdatasitegroup', 'create')
const UPDATE_MASTERDATA_SITEGROUP = createTypes('masterdatasitegroup', 'update')
const EXPORT_MASTERDATA_SITEGROUP = createTypes(
  'exportmasterdatasitegroup',
  'load',
)

const loadSitegroup = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_SITEGROUP.REQUEST, { data, loading }),
  success: lists =>
    createAction(LOAD_MASTERDATA_SITEGROUP.SUCCESS, {
      lists,
    }),
  failure: () => createAction(LOAD_MASTERDATA_SITEGROUP.FAITLURE),
}

const createMasterDataSiteGroup = {
  request: (data, loading) =>
    createAction(CREATE_MASTERDATA_SITEGROUP.REQUEST, { data, loading }),
  success: res => createAction(CREATE_MASTERDATA_SITEGROUP.SUCCESS, { res }),
  failure: () => createAction(CREATE_MASTERDATA_SITEGROUP.FAILURE),
}

const updateMasterDataSiteGroup = {
  request: (data, loading) =>
    createAction(UPDATE_MASTERDATA_SITEGROUP.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_MASTERDATA_SITEGROUP.SUCCESS, { res }),
  failure: () => createAction(UPDATE_MASTERDATA_SITEGROUP.FAILURE),
}

const exportSitegroup = {
  request: (data, loading) =>
    createAction(EXPORT_MASTERDATA_SITEGROUP.REQUEST, { data, loading }),
  success: res => createAction(EXPORT_MASTERDATA_SITEGROUP.SUCCESS, { res }),
  failure: () => createAction(EXPORT_MASTERDATA_SITEGROUP.FAILURE),
}

export {
  LOAD_MASTERDATA_SITEGROUP,
  loadSitegroup,
  CREATE_MASTERDATA_SITEGROUP,
  createMasterDataSiteGroup,
  UPDATE_MASTERDATA_SITEGROUP,
  updateMasterDataSiteGroup,
  EXPORT_MASTERDATA_SITEGROUP,
  exportSitegroup,
}
