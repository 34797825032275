import {
    LOAD_REQUEST_DELIVERY,
    LOAD_DATA_FOR_EDIT,
    UPDATE_TRICKET,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    listsDataForEdit: [],
    total: 1,
    loading: false,
    loadingForEdit: false,
    checkLoaddataforedit: true,
    draw: -1,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_REQUEST_DELIVERY.REQUEST:
            const { loading } = action.payload;
                return {
                    ...state,
                    loading: loading,
                    check: true
                };
            
        case LOAD_REQUEST_DELIVERY.SUCCESS:
            const { page } = action.payload;
            
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                if (page > 1)
                  action.payload.lists.data[i].rowNumber =
                    action.payload.lists.data[i].rowNumber + (page - 1) * 10;
            }

            return {
                ...state,
                lists: action.payload.lists.data,
                check: false,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

            case LOAD_DATA_FOR_EDIT.REQUEST:
                return {
                    ...state,
                    loadingForEdit: action.payload.data.loading,
                    checkLoaddataforedit: true,
                }

            case LOAD_DATA_FOR_EDIT.SUCCESS:
                return {
                        ...state,
                        listsDataForEdit: action.payload.lists.data,
                        loadingForEdit: false,
                        checkLoaddataforedit: false,
                    };

            case UPDATE_TRICKET.REQUEST:
                return {
                    ...state,
                    loadingForEdit: true
                };

            case UPDATE_TRICKET.SUCCESS:
                return {
                    ...state,
                    loadingForEdit: false
                };
        default:
            return state;
    }
}