import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Spin,
  AutoComplete,
  Modal,
} from 'antd'

import { checkPermissions } from '../../../../lib/helper'
import { StyledSearchForm } from '../../../../styled/common-styled'
import FormDivider from '../../../shared/components/FormDivider'
import axios from 'axios'
import { phoneRegExp } from '../../../../constants/constants'

const FormItem = Form.Item
const Options = Select.Option
const { Option } = AutoComplete
const { TextArea } = Input
const { confirm } = Modal
export default class Forms extends Component {
  state = {
    platenoList: [],
    plate_no: '',
    vehicle_id: '',
    maintenance_status_id: 1,
    type_problem_id: '',
    detail_problem: '',
    contact_name: '',
    contact_phone: '',
    appointment_place: '',
    email: '',
    province_id: '',
    district_id: '',
    show_comment: false,
    comment: '',
    check_duplicate: false,
    province: [],
    district: [],
  }

  componentDidMount() {
    this.props.loadVehicleMaintenancedevice()
    this.props.loadMaintenancestatus()
    this.props.loadTypeproblem()
    this.getProvince()
  }

  getProvince = () => {
    let self = this
    let token = this.props.auth.accessToken
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getprovincemaintenance`,
        {},
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then(function(response) {
        if (typeof response.data.data !== 'undefined') {
          let province = response.data.data.map(d => {
            return {
              id: d.id,
              name: d.name,
            }
          })
          self.setState({ province })
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  getDistrict = province_id => {
    let self = this
    let token = this.props.auth.accessToken
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdistrictmaintenance`,
        {
          province_id,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then(function(response) {
        if (typeof response.data.data !== 'undefined') {
          let district = response.data.data.map(d => {
            return {
              id: d.id,
              name: d.name,
            }
          })
          self.setState({ district })
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { maintenancedevice } = nextProps

    if (
      this.props.maintenancedevice.check_maintenancedevice !==
      nextProps.maintenancedevice.check_maintenancedevice
    ) {
      this.setState({
        vehicle_id: maintenancedevice.dataMaintenancedevice.vehicles_id,
        plate_no: maintenancedevice.dataMaintenancedevice.plate_no,
        maintenance_status_id:
          maintenancedevice.dataMaintenancedevice.maintenances_status_id,
        type_problem_id:
          maintenancedevice.dataMaintenancedevice.type_problem_id,
        contact_name: maintenancedevice.dataMaintenancedevice.contact_name,
        contact_phone: maintenancedevice.dataMaintenancedevice.contact_phone,
        appointment_place:
          maintenancedevice.dataMaintenancedevice.appointment_place,
        email: maintenancedevice.dataMaintenancedevice.email,
        province_id: maintenancedevice.dataMaintenancedevice.province_id,
        district_id: maintenancedevice.dataMaintenancedevice.district_id,
        comment: maintenancedevice.dataMaintenancedevice.comment,
        show_comment:
          maintenancedevice.dataMaintenancedevice.type_problem_id === 4
            ? true
            : false,
      })

      this.getDistrict(maintenancedevice.dataMaintenancedevice.province_id)
    }
    return true
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleLists } = nextProps.maintenancedevice

    const platenolists = []
    vehicleLists.map(item =>
      platenolists.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ platenoList: platenolists })
  }

  onChangeTypeProblem = (value, setFieldValue) => {
    setFieldValue('type_problem_id', value)
    var show = false
    if (value === 4) {
      show = true
    }
    this.setState({
      type_problem_id: value,
      show_comment: show,
    })
  }

  onChangeProvince = (value, setFieldValue) => {
    setFieldValue('province_id', value)
    this.getDistrict(value)
    this.setState({
      province_id: value,
    })
  }

  onChangeDistrict = (value, setFieldValue) => {
    setFieldValue('district_id', value)
    this.setState({
      district_id: value,
    })
  }

  selectVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue('vehicle_id', value)
    setFieldValue('plate_no', option.props.children)
    this.setState({
      vehicle_id: value,
      plate_no: option.props.children,
    })
  }

  onSearchVehiclePlateno = searchText => {
    this.setState({ plate_no: searchText })
  }

  render() {
    const { maintenancedevice, onSubmit, auth, id } = this.props
    const {
      maintenancedeviceLoading,
      maintenancestatusLists,
      typeproblemLists,
    } = maintenancedevice
    const platenolists = this.state.platenoList.map(v => (
      <Option key={v.key}>{v.name}</Option>
    ))
    const self = this
    const { check_duplicate, province, district } = this.state

    return (
      <Spin spinning={maintenancedeviceLoading}>
        <Row>
          <StyledSearchForm>
            <Formik
              enableReinitialize={true}
              initialValues={{
                vehicle_id: this.state.vehicle_id,
                maintenance_status_id: this.state.maintenance_status_id,
                type_problem_id: this.state.type_problem_id,
                contact_name: this.state.contact_name,
                contact_phone: this.state.contact_phone,
                appointment_place: this.state.appointment_place,
                email: this.state.email,
                province_id: this.state.province_id,
                district_id: this.state.district_id,
                comment: this.state.comment,
              }}
              validate={values => {
                // console.log("data:", values)
                let errors = {}
                if (values.type_problem_id === 4) {
                  //อื่นๆ
                  if (values.comment === '' || values.comment === null) {
                    errors.comment = (
                      <LangContext.Consumer>
                        {i18n => i18n.maintenance.comment_require}
                      </LangContext.Consumer>
                    )
                  }
                }
                return errors
              }}
              validationSchema={yup.object().shape({
                vehicle_id: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.maintenance.vehicle_require}
                    </LangContext.Consumer>,
                  )
                  .test('checkvehicle', '', function(value) {
                    return new Promise((resolve, reject) => {
                      fetch(
                        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice/checkduplicate`,
                        {
                          method: 'POST',
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth.accessToken}`,
                          },
                          body: JSON.stringify({
                            company_id: auth.profile.company_id,
                            vehicle_id: value,
                            id,
                          }),
                        },
                      )
                        .then(response => response.json())
                        .then(res => {
                          if (res.status === 'true') {
                            //console.log("res", res);
                            //resolve(false);
                            self.setState({ check_duplicate: true })
                          } else {
                            self.setState({ check_duplicate: false })
                          }

                          resolve(true)
                        })
                    })
                  }),
                // detail_problem: yup.string().required("กรุณากรอกอาการปัญหา"),
                type_problem_id: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.maintenance.type_problem_require}
                    </LangContext.Consumer>,
                  ),
                contact_name: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.c.contact_name_require}
                    </LangContext.Consumer>,
                  ),
                appointment_place: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.maintenance.appointment_place_require}
                    </LangContext.Consumer>,
                  ),
                province_id: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.maintenance.province_require}
                    </LangContext.Consumer>,
                  ),
                district_id: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.maintenance.district_require}
                    </LangContext.Consumer>,
                  ),
                email: yup
                  .string()
                  .email(
                    <LangContext.Consumer>
                      {i18n => i18n.e.emailIsInvalid}
                    </LangContext.Consumer>,
                  ),
                contact_phone: yup
                  .string()
                  .min(
                    8,
                    <LangContext.Consumer>
                      {i18n => i18n.c.contact_phone_length_min}
                    </LangContext.Consumer>,
                  )
                  .max(
                    15,
                    <LangContext.Consumer>
                      {i18n => i18n.c.contact_phone_length_max}
                    </LangContext.Consumer>,
                  )
                  .matches(
                    phoneRegExp,
                    <LangContext.Consumer>
                      {i18n => i18n.c.contact_phone_invalid}
                    </LangContext.Consumer>,
                  )
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.c.contact_phone_require}
                    </LangContext.Consumer>,
                  ),
              })}
              onSubmit={(values, { resetForm }) => {
                //console.log(values);
                if (check_duplicate === true) {
                  confirm({
                    title: (
                      <LangContext.Consumer>
                        {i18n => i18n.v.vehicle_maintenancedevice_duplicate}
                      </LangContext.Consumer>
                    ),
                    // content: '',
                    onOk() {
                      if (values.type_problem_id !== 4) {
                        values.comment = ''
                      }
                      const loading = true
                      onSubmit(values, loading)
                    },
                    onCancel() {},
                    okText: (
                      <LangContext.Consumer>
                        {i18n => i18n.o.ok}
                      </LangContext.Consumer>
                    ),
                    cancelText: (
                      <LangContext.Consumer>
                        {i18n => i18n.c.cancel}
                      </LangContext.Consumer>
                    ),
                  })
                } else {
                  if (values.type_problem_id !== 4) {
                    values.comment = ''
                  }
                  const loading = true
                  onSubmit(values, loading)
                }
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
                resetForm,
              }) => (
                <Row>
                  <LangContext.Consumer>
                    {i18n => (
                      <Form onSubmit={handleSubmit} layout="vertical">
                        <FormDivider>
                          <LangContext.Consumer>
                            {i18n => i18n.m.maintenancedevice}
                          </LangContext.Consumer>
                        </FormDivider>
                        <Row gutter={24}>
                          <Col sm={24} md={18} lg={18} xl={18}>
                            {this.props.auth.profile.is_gps_vendor !== 1 ? (
                              <FormItem
                                required="true"
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.plateNo}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.vehicle_id &&
                                  errors.vehicle_id &&
                                  'error'
                                }
                                help={touched.vehicle_id && errors.vehicle_id}
                              >
                                <AutoComplete
                                  dataSource={platenolists}
                                  style={{ width: '80%' }}
                                  optionLabelProp="children"
                                  filterOption={true}
                                  optionFilterProp="children"
                                  // placeholder="ค้นหาทะเบียน"
                                  value={this.state.plate_no}
                                  onSearch={this.onSearchVehiclePlateno}
                                  onSelect={(value, option) =>
                                    this.selectVehiclePlateno(
                                      value,
                                      option,
                                      setFieldValue,
                                    )
                                  }
                                />
                              </FormItem>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={18} lg={18} xl={18}>
                            {this.props.auth.profile.is_gps_vendor !== 1 ? (
                              <FormItem
                                required="true"
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.c.contact_name}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.contact_name &&
                                  errors.contact_name &&
                                  'error'
                                }
                                help={
                                  touched.contact_name && errors.contact_name
                                }
                              >
                                <Input
                                  style={{ width: '80%' }}
                                  type="text"
                                  value={values.contact_name}
                                  onChange={e => {
                                    setFieldValue(
                                      'contact_name',
                                      e.target.value,
                                    )
                                    this.setState({
                                      contact_name: e.target.value,
                                    })
                                  }}
                                  onBlur={handleBlur}
                                />
                              </FormItem>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={18} lg={18} xl={18}>
                            {this.props.auth.profile.is_gps_vendor !== 1 ? (
                              <FormItem
                                required="true"
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.c.contact_phone}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.contact_phone &&
                                  errors.contact_phone &&
                                  'error'
                                }
                                help={
                                  touched.contact_phone && errors.contact_phone
                                }
                              >
                                <Input
                                  style={{ width: '80%' }}
                                  type="text"
                                  value={values.contact_phone}
                                  onChange={e => {
                                    setFieldValue(
                                      'contact_phone',
                                      e.target.value,
                                    )
                                    this.setState({
                                      contact_phone: e.target.value,
                                    })
                                  }}
                                  onBlur={handleBlur}
                                />
                              </FormItem>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={18} lg={18} xl={18}>
                            {this.props.auth.profile.is_gps_vendor !== 1 ? (
                              <FormItem
                                required="true"
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.maintenance.appointment_place}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.appointment_place &&
                                  errors.appointment_place &&
                                  'error'
                                }
                                help={
                                  touched.appointment_place &&
                                  errors.appointment_place
                                }
                              >
                                <Input
                                  style={{ width: '80%' }}
                                  type="text"
                                  value={values.appointment_place}
                                  onChange={e => {
                                    setFieldValue(
                                      'appointment_place',
                                      e.target.value,
                                    )
                                    this.setState({
                                      appointment_place: e.target.value,
                                    })
                                  }}
                                  onBlur={handleBlur}
                                />
                              </FormItem>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={18} lg={18} xl={18}>
                            {this.props.auth.profile.is_gps_vendor !== 1 ? (
                              <FormItem
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.e.email}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.email && errors.email && 'error'
                                }
                                help={touched.email && errors.email}
                              >
                                <Input
                                  style={{ width: '80%' }}
                                  type="text"
                                  value={values.email}
                                  onChange={e => {
                                    setFieldValue('email', e.target.value)
                                    this.setState({ email: e.target.value })
                                  }}
                                  onBlur={handleBlur}
                                />
                              </FormItem>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={18} lg={18} xl={18}>
                            {this.props.auth.profile.is_gps_vendor !== 1 ? (
                              <FormItem
                                required="true"
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.maintenance.province}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.province_id &&
                                  errors.province_id &&
                                  'error'
                                }
                                help={touched.province_id && errors.province_id}
                              >
                                <Select
                                  style={{ width: '80%' }}
                                  onChange={value =>
                                    this.onChangeProvince(value, setFieldValue)
                                  }
                                  value={values.province_id}
                                >
                                  <Options value="">
                                    <LangContext.Consumer>
                                      {i18n => i18n.p.pleaseSelect}
                                    </LangContext.Consumer>
                                  </Options>
                                  {province.map(item => (
                                    <Options key={item.id} value={item.id}>
                                      {item.name}
                                    </Options>
                                  ))}
                                </Select>
                              </FormItem>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={18} lg={18} xl={18}>
                            {this.props.auth.profile.is_gps_vendor !== 1 ? (
                              <FormItem
                                required="true"
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.maintenance.district}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.district_id &&
                                  errors.district_id &&
                                  'error'
                                }
                                help={touched.district_id && errors.district_id}
                              >
                                <Select
                                  style={{ width: '80%' }}
                                  onChange={value =>
                                    this.onChangeDistrict(value, setFieldValue)
                                  }
                                  value={values.district_id}
                                >
                                  <Options value="">
                                    <LangContext.Consumer>
                                      {i18n => i18n.p.pleaseSelect}
                                    </LangContext.Consumer>
                                  </Options>
                                  {district.map(item => (
                                    <Options key={item.id} value={item.id}>
                                      {item.name}
                                    </Options>
                                  ))}
                                </Select>
                              </FormItem>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={18} lg={18} xl={18}>
                            {this.props.auth.profile.is_gps_vendor !== 1 ? (
                              <FormItem
                                required="true"
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.t.type_problem}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.type_problem_id &&
                                  errors.type_problem_id &&
                                  'error'
                                }
                                help={
                                  touched.type_problem_id &&
                                  errors.type_problem_id
                                }
                              >
                                <Select
                                  style={{ width: '80%' }}
                                  onChange={value =>
                                    this.onChangeTypeProblem(
                                      value,
                                      setFieldValue,
                                    )
                                  }
                                  value={values.type_problem_id}
                                >
                                  <Options value="">
                                    <LangContext.Consumer>
                                      {i18n => i18n.p.pleaseSelect}
                                    </LangContext.Consumer>
                                  </Options>
                                  {typeproblemLists.map(item => (
                                    <Options key={item.id} value={item.id}>
                                      {item.name}
                                    </Options>
                                  ))}
                                </Select>
                              </FormItem>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col sm={24} md={18} lg={18} xl={18}>
                            {this.props.auth.profile.is_gps_vendor !== 1 &&
                            this.state.show_comment === true ? (
                              <FormItem
                                required="true"
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.c.comment}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.comment && errors.comment && 'error'
                                }
                                help={touched.comment && errors.comment}
                              >
                                <TextArea
                                  style={{ width: '80%' }}
                                  name="comment"
                                  autoSize={{ minRows: 5, maxRows: 5 }}
                                  onChange={e => {
                                    setFieldValue('comment', e.target.value)
                                    this.setState({ comment: e.target.value })
                                  }}
                                  value={values.comment}
                                />
                              </FormItem>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col sm={24} md={18} lg={18} xl={18}>
                            {this.props.auth.profile.is_gps_vendor === 1 ? (
                              <FormItem
                                required="true"
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.m.maintenance_status}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.maintenance_status_id &&
                                  errors.maintenance_status_id &&
                                  'error'
                                }
                                help={
                                  touched.maintenance_status_id &&
                                  errors.maintenance_status_id
                                }
                              >
                                <Select
                                  style={{ width: '80%' }}
                                  onChange={value =>
                                    setFieldValue(
                                      'maintenance_status_id',
                                      value,
                                    )
                                  }
                                  value={values.maintenance_status_id}
                                >
                                  <Options value="">
                                    <LangContext.Consumer>
                                      {i18n => i18n.p.pleaseSelect}
                                    </LangContext.Consumer>
                                  </Options>
                                  {maintenancestatusLists.map(item => (
                                    <Options key={item.id} value={item.id}>
                                      {item.name}
                                    </Options>
                                  ))}
                                </Select>
                              </FormItem>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>

                        <Row style={{ marginTop: '20px', marginBottom: '5px' }}>
                          <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                            <Form.Item>
                              <Button
                                type="defualt"
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                <Link to="/maintenancedevice">
                                  {i18n.b.back}
                                </Link>
                              </Button>

                              <Button type="primary" htmlType="submit">
                                {i18n.s.save}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </LangContext.Consumer>
                </Row>
              )}
            />
          </StyledSearchForm>
        </Row>
      </Spin>
    )
  }
}
