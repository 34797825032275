import {
  LOAD_MAINTENANCEDEVICE,
  CREATE_MAINTENANCEDEVICE,
  UPDATE_MAINTENANCEDEVICE,
  CLEAR_MAINTENANCEDEVICE,
  GET_MAINTENANCEDEVICE,
  LOAD_VEHICLE_MAINTENANCEDEVICE,
  CLEAR_RESPONSE,
  LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICE,
  LOAD_TYPEPROBLEM_MAINTENANCEDEVICE
} from "./actions";

const initialState = {
  maintenancedeviceLists: [],
  maintenancedeviceLoading: false,
  maintenancedeviceTotal: 0,
  maintenancestatusLists: [],
  typeproblemLists: [],
  dataResponse: { status: "", msg: "", id: "", action_type: "" },
  check_maintenancedevice: true,
  dataMaintenancedevice: {
    id: "",
    plate_no: "",
    vehicles_id: "",
    detail_problem: "",
    maintenances_status_id: "",
    type_problem_id: "",
    comment: ""
  },
  vehicleLists: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MAINTENANCEDEVICE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        maintenancedeviceLoading: loading
      };
    case LOAD_MAINTENANCEDEVICE.SUCCESS:
      return {
        ...state,
        maintenancedeviceLists: action.payload.data.data,
        maintenancedeviceLoading: false,
        maintenancedeviceTotal: action.payload.data.total
      };
    case CREATE_MAINTENANCEDEVICE.REQUEST:
      const { loading: loading2 } = action.payload;
      return {
        ...state,
        maintenancedeviceLoading: loading2
      };
    case CREATE_MAINTENANCEDEVICE.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        maintenancedeviceLoading: false
      };
    case UPDATE_MAINTENANCEDEVICE.REQUEST:
      const { loading: loading3 } = action.payload;
      return {
        ...state,
        maintenancedeviceLoading: loading3
      };
    case UPDATE_MAINTENANCEDEVICE.SUCCESS:
      return {
        ...state,
        dataResponse: { status: action.payload.data.status, msg: action.payload.data.msg, action_type: action.payload.data.action_type },
        maintenancedeviceLoading: false
      };

    case GET_MAINTENANCEDEVICE.REQUEST:
      return {
        ...state,
        check_maintenancedevice: true,
      };
    case GET_MAINTENANCEDEVICE.SUCCESS:
      return {
        ...state,
        dataMaintenancedevice: action.payload.data.data,
        maintenancedeviceLoading: false,
        check_maintenancedevice: false,
      };
    case LOAD_VEHICLE_MAINTENANCEDEVICE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        dataResponse: { status: "", msg: "", id: "", action_type: "" }
      };
    case CLEAR_MAINTENANCEDEVICE:
      return {
        ...state,
        dataMaintenancedevice: {
          id: "",
          plate_no: "",
          pull_vehicle_sink_date: ""
        }
      };
    case LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICE.SUCCESS:
      return {
        ...state,
        maintenancestatusLists: action.payload.data.data
      };
    case LOAD_TYPEPROBLEM_MAINTENANCEDEVICE.SUCCESS:
      return {
        ...state,
        typeproblemLists: action.payload.data.data
      };
    default:
      return state;
  }
};
