import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Spin, Col, Button, Row } from 'antd'
import axios from 'axios'
import * as actions from '../actions'

import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext from 'modules/shared/context/langContext'

const { Column, ColumnGroup } = Table

class Previewtable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataPreview: [],
      loadPreview: false,
      filter: '',
      header: '',
    }
  }

  handleMenuClick = () => {
    let self = this
    if (self.state.filter !== '') {
      let data = {
        ...self.state.filter,
        type_file: 'excel',
        ...self.state.header,
      }
      this.props.loadBehaviordrivingpreview(data, true)
    }
  }

  componentDidMount() {
    if (localStorage.getItem('behaviordrivingreport-filter') != null) {
      let self = this
      let filter = localStorage.getItem('behaviordrivingreport-filter')
      self.setState({
        filter: JSON.parse(filter),
        loadPreview: true,
      })
      axios
        .post(
          `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/behaviordriving`,
          JSON.parse(filter),
        )
        .then(function(response) {
          let data = response.data
          self.setState({
            dataPreview: data,
            loadPreview: false,
          })
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('behaviordrivingreport-filter')
  }

  render() {
    const { behaviordrivingLoading } = this.props.behaviordriving

    return (
      <AuthorizeComponent {...this.props} matching_name="BehaviordrivingReport">
        <Spin spinning={behaviordrivingLoading}>
          <Spin spinning={this.state.loadPreview}>
            <GeneralStyledContent>
              <Row
                gutter={24}
                type="flex"
                justify="end"
                style={{ height: '38px' }}
              >
                <Col xs={9} sm={4} md={3} lg={2} xl={2}>
                  <Button
                    type="primary"
                    block
                    onClick={() => this.handleMenuClick()}
                  >
                    <LangContext.Consumer>
                      {i18n => i18n.r.report}
                    </LangContext.Consumer>
                  </Button>
                </Col>
              </Row>

              <Table
                scroll={{ y: `${window.innerHeight - 300}px`, x: 2500 }}
                dataSource={this.state.dataPreview}
                bordered
              >
                <Column
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.n.noReport}
                    </LangContext.Consumer>
                  }
                  dataIndex="count"
                  key="count"
                />
                <Column
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.c.codejbs}
                    </LangContext.Consumer>
                  }
                  dataIndex="code"
                  key="code"
                />
                <Column
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.d.driverNameJBS}
                    </LangContext.Consumer>
                  }
                  dataIndex="driverName"
                  key="driverName"
                />
                <Column
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.t.truckNumber}
                    </LangContext.Consumer>
                  }
                  dataIndex="vehicleCode"
                  key="vehicleCode"
                />
                <Column
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.c.companyName}
                    </LangContext.Consumer>
                  }
                  dataIndex="companyName"
                  key="companyName"
                />
                <Column
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.u.underFactory}
                    </LangContext.Consumer>
                  }
                  dataIndex="locationName"
                  key="locationName"
                />
                <Column
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.u.underDepartment}
                    </LangContext.Consumer>
                  }
                  dataIndex="department"
                  key="department"
                />
                <Column
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.u.underSection}
                    </LangContext.Consumer>
                  }
                  dataIndex="section"
                  key="section"
                />
                <Column
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.u.underDivision}
                    </LangContext.Consumer>
                  }
                  dataIndex="division"
                  key="division"
                />
                <Column
                  title={
                    <>
                      {' '}
                      <LangContext.Consumer>
                        {i18n => i18n.u.underDivision}
                      </LangContext.Consumer>
                      {' Chain'}
                    </>
                  }
                  dataIndex="comp"
                  key="comp"
                />

                <ColumnGroup title="LCC Alert">
                  <Column
                    title={
                      <LangContext.Consumer>
                        {i18n => i18n.d.driveContinuouslyForMoreThan4Hours}
                      </LangContext.Consumer>
                    }
                    dataIndex="countEvent1"
                    key="countEvent1"
                  />
                  <Column
                    title={
                      <LangContext.Consumer>
                        {i18n => i18n.p.parkingOnRoadSide}
                      </LangContext.Consumer>
                    }
                    dataIndex="countEvent2"
                    key="countEvent2"
                  />
                  <Column
                    title={
                      <LangContext.Consumer>
                        {i18n => i18n.c.countEvent3}
                      </LangContext.Consumer>
                    }
                    dataIndex="countEvent3"
                    key="countEvent3"
                  />
                  <Column
                    title={
                      <LangContext.Consumer>
                        {i18n => i18n.c.countEvent4}
                      </LangContext.Consumer>
                    }
                    dataIndex="countEvent4"
                    key="countEvent4"
                  />
                  <Column
                    title={
                      <LangContext.Consumer>
                        {i18n => i18n.c.countEvent5}
                      </LangContext.Consumer>
                    }
                    dataIndex="countEvent5"
                    key="countEvent5"
                  />
                  <Column
                    title={
                      <LangContext.Consumer>
                        {i18n => i18n.c.countEvent6}
                      </LangContext.Consumer>
                    }
                    dataIndex="countEvent6"
                    key="countEvent6"
                  />
                  <Column
                    title={
                      <LangContext.Consumer>
                        {i18n => i18n.c.countEvent7}
                      </LangContext.Consumer>
                    }
                    dataIndex="countEvent7"
                    key="countEvent7"
                  />
                </ColumnGroup>
              </Table>
            </GeneralStyledContent>
          </Spin>
        </Spin>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ behaviordriving, auth: { profile } }) => ({
  behaviordriving,
  profile,
})

const mapDispatchToProps = {
  loadBehaviordrivingpreview: actions.loadBehaviordrivingpreview.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Previewtable)
