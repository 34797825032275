import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import {
  Button,
  Form,
  Input,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Menu,
  Icon,
  AutoComplete,
  Dropdown,
  Select
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";


const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = AutoComplete.Option;

export default class Forms extends Component {
  state = {
    platenoList: [],
    codeList: [],
    vehicle_id_code: "",
    vehicle_id_plate_no: "",

  };

  componentDidMount() {
    const { loadVehicle } = this.props;
    loadVehicle();
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleLists } = nextProps;
    const codelists = [];
    vehicleLists.map(item =>
      item.code != "" ? codelists.push({
        key: item.id,
        name: item.code,
        plate_no: item.plate_no
      }) : ""
    );

    const platenolists = [];
    vehicleLists.map(item =>
      item.code != "" ? platenolists.push({
        key: item.id,
        name: item.plate_no,
        code: item.code,
      }) : ""
    );

    this.setState({ codeList: codelists });
    this.setState({ platenoList: platenolists });
  };



  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    if (e.key == "excel") {
      setFieldValue("type_file", "excel", false);
    } else if (e.key == "pdf") {
      setFieldValue("type_file", "pdf", false);
    }
    handleSubmit();
  };

  changeVehicleCode = (value, option, setFieldValue) => {
    this.setState({ vehicle_id_code: value });
    setFieldValue("vehicle_code", option.props.children);
    //onsole.log("change:" + value);
  };

  selectVehicleCode = (value, option, setFieldValue) => {
    this.setState({ vehicle_id_code: value });
    this.setState({ vehicle_id_plate_no: value });
    setFieldValue("vehicle_code", option.props.children);
    setFieldValue("plate_no", option.props.label);

  };


  changeVehiclePlateno = (value, option, setFieldValue) => {
    this.setState({ vehicle_id_plate_no: value });
    setFieldValue("plate_no", option.props.children);

  };

  selectVehiclePlateno = (value, option, setFieldValue) => {
    this.setState({ vehicle_id_code: value });
    this.setState({ vehicle_id_plate_no: value });
    setFieldValue("vehicle_code", option.props.label);
    setFieldValue("plate_no", option.props.children);
    //console.log(option.props);

  };




  render() {
    const {
      vehicleLists,
      drivinghourLoading,
      onSubmit
    } = this.props;



    const codelists = this.state.codeList
      .map((v) => (
        <Option key={v.key} label={v.plate_no}>
          {v.name}
        </Option>
      ))


    const platenolists = this.state.platenoList
      .map((v) => (
        <Option key={v.key} label={v.code}>
          {v.name}
        </Option>
      ))

    return (
      <div>
        <h3> <LangContext.Consumer>
          {i18n => i18n.d.DrivinghourReport}
        </LangContext.Consumer></h3>
        <Spin spinning={drivinghourLoading}>
          <Formik
            initialValues={{
              vehicle_code: "",
              plate_no: "",
              eventdate: [moment("00:00", "HH:mm"), moment("23:59", "HH:mm")],
              group_idle: "1",
              group_speed: "5"
            }}
            validate={values => {
              let errors = {};


              return errors;
            }}
            validationSchema={yup.object().shape({
              vehicle_code: yup.string().required("กรุณาเลือกรหัสพาหนะ"),
              plate_no: yup.string().required("กรุณาเลือกทะเบียนพาหนะ"),
              eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),

            })}
            onSubmit={values => {
              const loading = true;
              onSubmit(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating
            }) => (
                <Row type="flex" justify="center">
                  <Form onSubmit={handleSubmit}>
                    <FormItem

                      label={<LangContext.Consumer>
                        {i18n => i18n.p.plateNo}
                      </LangContext.Consumer>}
                      validateStatus={
                        touched.plate_no && errors.plate_no && "error"
                      }
                      help={touched.plate_no && errors.plate_no}
                    >
                      <AutoComplete
                        dataSource={platenolists}
                        style={{ width: 200 }}
                        optionLabelProp="children"
                        filterOption={true}
                        optionFilterProp="children"
                        // placeholder="ค้นหาทะเบียน"
                        value={this.state.vehicle_id_plate_no}
                        onChange={(value, option) =>
                          this.changeVehiclePlateno(
                            value,
                            option,
                            setFieldValue
                          )
                        }
                        onSelect={(value, option) =>
                          this.selectVehiclePlateno(
                            value,
                            option,
                            setFieldValue
                          )
                        }


                      />
                    </FormItem>
                    <FormItem

                      label={<LangContext.Consumer>
                        {i18n => i18n.v.vehicleCode}
                      </LangContext.Consumer>}
                      validateStatus={
                        touched.vehicle_code && errors.vehicle_code && "error"
                      }
                      help={touched.vehicle_code && errors.vehicle_code}
                    >
                      <AutoComplete
                        dataSource={codelists}
                        style={{ width: 200 }}
                        optionLabelProp="children"
                        filterOption={true}
                        optionFilterProp="children"
                        // placeholder="ค้นหาทะเบียน"
                        value={this.state.vehicle_id_code}
                        onChange={(value, option) =>
                          this.changeVehicleCode(
                            value,
                            option,
                            setFieldValue
                          )
                        }
                        onSelect={(value, option) =>
                          this.selectVehicleCode(
                            value,
                            option,
                            setFieldValue
                          )
                        }

                      />
                    </FormItem>

                    <Form.Item
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.dateRange}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.eventdate && errors.eventdate && "error"
                      }
                      help={touched.eventdate && errors.eventdate}
                    >
                      <RangePicker
                        showTime={{ format: "HH:mm" }}
                        onChange={value => setFieldValue("eventdate", value)}
                        format="DD/MM/YYYY HH:mm"
                        placeholder={["Start Time", "End Time"]}
                        defaultValue={[
                          moment("00:00", "HH:mm"),
                          moment("23:59", "HH:mm")
                        ]}
                      />
                    </Form.Item>

                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.g.group_idle}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.group_idle &&
                        errors.group_idle &&
                        "error"
                      }
                      help={
                        touched.group_idle &&
                        errors.group_idle
                      }
                    >
                      <Select
                        onChange={value =>
                          setFieldValue("group_idle", value)
                        }
                        value={values.group_idle}
                      >

                        <Option value="1">
                          <LangContext.Consumer>
                            {i18n => i18n.m.more_1_min}
                          </LangContext.Consumer>
                        </Option>
                        <Option value="5">
                          <LangContext.Consumer>
                            {i18n => i18n.m.more_5_mins}
                          </LangContext.Consumer>
                        </Option>
                        <Option value="10">
                          <LangContext.Consumer>
                            {i18n =>
                              i18n.m.more_10_mins
                            }
                          </LangContext.Consumer>
                        </Option>

                      </Select>
                    </FormItem>


                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.g.group_speed}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.group_speed &&
                        errors.group_speed &&
                        "error"
                      }
                      help={
                        touched.group_speed &&
                        errors.group_speed
                      }
                    >
                      <Select
                        onChange={value =>
                          setFieldValue("group_speed", value)
                        }
                        value={values.group_speed}
                      >

                        <Option value="5">
                          <LangContext.Consumer>
                            {i18n => i18n.m.more_5_sec}
                          </LangContext.Consumer>
                        </Option>
                        <Option value="10">
                          <LangContext.Consumer>
                            {i18n => i18n.m.more_10_sec}
                          </LangContext.Consumer>
                        </Option>
                        <Option value="30">
                          <LangContext.Consumer>
                            {i18n =>
                              i18n.m.more_30_sec
                            }
                          </LangContext.Consumer>
                        </Option>
                        <Option value="60">
                          <LangContext.Consumer>
                            {i18n =>
                              i18n.m.more_60_sec
                            }
                          </LangContext.Consumer>
                        </Option>
                      </Select>
                    </FormItem>


                    <FormItem>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Button type="defualt" block>
                            <Link to="/"><LangContext.Consumer>
                              {i18n => i18n.c.cancel}
                            </LangContext.Consumer></Link>
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Dropdown
                            overlay={
                              <Menu
                                onClick={value =>
                                  this.handleMenuClick(
                                    value,
                                    setFieldValue,
                                    handleSubmit
                                  )
                                }
                              >
                                <Menu.Item key="excel">
                                  <Icon type="file-excel" /> <LangContext.Consumer>
                                    {i18n => i18n.e.excel}
                                  </LangContext.Consumer>
                                </Menu.Item>
                                <Menu.Item key="pdf">
                                  <Icon type="file-pdf" /> <LangContext.Consumer>
                                    {i18n => i18n.p.pdf}
                                  </LangContext.Consumer>
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <Button type="primary" block>
                              <LangContext.Consumer>
                                {i18n => i18n.r.report}
                              </LangContext.Consumer> <Icon type="down" />
                            </Button>
                          </Dropdown>
                        </Col>
                      </Row>
                    </FormItem>
                  </Form>
                </Row>
              )}
          />
        </Spin>
      </div>
    );
  }
}
