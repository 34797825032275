import {
  LOAD_DATA_FOR_MANAG_VEHICLE,
  LOAD_DATA_REQUEST_MATERIAL,
  ASSIGN_WORK_FROM_PLANT,
  ASSIGN_WORK_FROM_VEHICLE,
  MATCH_VEHICLE_TO_JOB,
  UNMATCH_VEHICLE_TO_JOB,
  LOADDATA_ASSIGNWORK_VEHICLE_STATUS_FOR_DETAIL,
  ASSIGN_WORK_SALE_ORDER_FROM_VEHICLE,
  ASSIGN_WORK_SALE_ORDER_FROM_PLANT,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  data_request_material: [],
  loading: false,
  check: true,
  lists: [],
  loadingForDetail: false,
  checkLoaddatafordetail: true,
  listsDataForDetail: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_DATA_FOR_MANAG_VEHICLE.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOAD_DATA_FOR_MANAG_VEHICLE.SUCCESS:
      return {
        ...state,
        lists: action.payload.lists,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_DATA_REQUEST_MATERIAL.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: true,
      }

    case LOAD_DATA_REQUEST_MATERIAL.SUCCESS:
      return {
        ...state,
        data_request_material: action.payload.lists,
        loading: false,
        check: false,
      }

    case ASSIGN_WORK_FROM_PLANT.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ASSIGN_WORK_FROM_PLANT.SUCCESS:
      return {
        ...state,
        // loading: false,
      }

    case ASSIGN_WORK_FROM_VEHICLE.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ASSIGN_WORK_FROM_VEHICLE.SUCCESS:
      return {
        ...state,
        // loading: false,
      }
    // *เพิ่มรถเข้ากองงาน
    case ASSIGN_WORK_SALE_ORDER_FROM_VEHICLE.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ASSIGN_WORK_SALE_ORDER_FROM_VEHICLE.SUCCESS:
      return {
        ...state,
        // loading: false,
      }
    // *เพิ่มรถเข้ากองงาน
    case ASSIGN_WORK_SALE_ORDER_FROM_PLANT.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ASSIGN_WORK_SALE_ORDER_FROM_PLANT.SUCCESS:
      return {
        ...state,
        // loading: false,
      }

    case MATCH_VEHICLE_TO_JOB.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case MATCH_VEHICLE_TO_JOB.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UNMATCH_VEHICLE_TO_JOB.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UNMATCH_VEHICLE_TO_JOB.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOADDATA_ASSIGNWORK_VEHICLE_STATUS_FOR_DETAIL.REQUEST:
      return {
        ...state,
        loadingForDetail: action.payload.data.loading,
        checkLoaddatafordetail: true,
      }

    case LOADDATA_ASSIGNWORK_VEHICLE_STATUS_FOR_DETAIL.SUCCESS:
      return {
        ...state,
        listsDataForDetail: action.payload.lists.data,
        loadingForDetail: false,
        checkLoaddatafordetail: false,
      }

    default:
      return state
  }
}
