import React, { Component } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Button, Form, Input, Row, Col, DatePicker, Select } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { checkPermissions } from '../../../../lib/helper'
import { Link } from 'react-router-dom'
import moment from 'moment'

const FormItem = Form.Item
const { RangePicker } = DatePicker
const Options = Select.Option

export default class Filter extends Component {
  render() {
    const {
      OnClose,
      onSubmit,
      maintenancestatusLists,
      typeproblemLists,
      auth,
      is_gps_vendor,
    } = this.props

    return (
      <div>
        <Formik
          initialValues={{
            plate_no: '',
            doc_number: '',
            inform_date: [
              moment()
                .subtract(3, 'months')
                .startOf('day'),
              moment().endOf('day'),
            ],
            maintenance_status_id: '',
            type_problem_id: '',
          }}
          validationSchema={yup.object().shape({
            // name: yup.string.required("กรุณาเลือกช่วงวันที่"),
            // code: yup.string.required("กรุณาเลือกรถ")
          })}
          onSubmit={values => {
            const loading = true
            onSubmit(values, loading)
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => (
            <Row gutter={[8, 8]}>
              <LangContext.Consumer>
                {i18n => (
                  <Form onSubmit={handleSubmit}>
                    <Col span={4}>
                      <FormItem
                        label={i18n.p.plateNoVehicleCode}
                        validateStatus={
                          touched.plate_no && errors.plate_no && 'error'
                        }
                        help={touched.plate_no && errors.plate_no}
                      >
                        <Input
                          placeholder={i18n.p.plateNo}
                          name="plate_no"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.plate_no}
                        />
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem
                        label={i18n.d.doc_no}
                        validateStatus={
                          touched.doc_number && errors.doc_number && 'error'
                        }
                        help={touched.doc_number && errors.doc_number}
                      >
                        <Input
                          placeholder={i18n.d.doc_no}
                          name="doc_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.doc_number}
                        />
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.t.type_problem}
                          </LangContext.Consumer>
                        }
                      >
                        <Select
                          onChange={value =>
                            setFieldValue('type_problem_id', value)
                          }
                          value={values.type_problem_id}
                        >
                          <Options value="">
                            <LangContext.Consumer>
                              {i18n => i18n.p.pleaseSelect}
                            </LangContext.Consumer>
                          </Options>
                          {typeproblemLists.map(item => (
                            <Options key={item.id} value={item.id}>
                              {item.name}
                            </Options>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>

                    <Col span={4}>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.m.maintenance_status}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.maintenance_status_id &&
                          errors.maintenance_status_id &&
                          'error'
                        }
                        help={
                          touched.maintenance_status_id &&
                          errors.maintenance_status_id
                        }
                      >
                        <Select
                          onChange={value =>
                            setFieldValue('maintenance_status_id', value)
                          }
                          value={values.maintenance_status_id}
                        >
                          <Options value="">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Options>
                          {maintenancestatusLists.map(item => (
                            <Options key={item.id} value={item.id}>
                              {item.name}
                            </Options>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>

                    <Col span={4}>
                      {' '}
                      <Form.Item
                        label={i18n.i.inform_date}
                        validateStatus={
                          touched.inform_date && errors.inform_date && 'error'
                        }
                        help={touched.inform_date && errors.inform_date}
                      >
                        <RangePicker
                          onChange={value =>
                            setFieldValue('inform_date', value)
                          }
                          format="DD/MM/YYYY"
                          placeholder={[i18n.s.startDate, i18n.e.endDate]}
                          defaultValue={values.inform_date}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4} style={{ marginTop: 39 }}>
                      <FormItem>
                        <Row gutter={24}>
                          <Col span={24}>
                            <Form.Item>
                              <Button htmlType="submit" type="primary">
                                {i18n.a.apply}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </FormItem>
                    </Col>
                  </Form>
                )}
              </LangContext.Consumer>
            </Row>
          )}
        />
      </div>
    )
  }
}
