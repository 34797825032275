import {
    LOAD_VEHICLE_GPS_LOST_CONTACT_REPORT,
    LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT,
    EXPORT_GPS_LOST_CONTACT_REPORT
} from "./actions";

const initialState = {
    vehicleLists: [],
    GPSVendorLists: [],
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        
        case LOAD_VEHICLE_GPS_LOST_CONTACT_REPORT.REQUEST:
            return {
              ...state,
              loading: true
            };

        case LOAD_VEHICLE_GPS_LOST_CONTACT_REPORT.SUCCESS:
            return {
                ...state,
                vehicleLists: action.payload.data.data,
                loading: false
            };

        case LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.REQUEST:
            return {
                ...state,
                loading: true
            };

        case LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.SUCCESS:
            return {
                ...state,
                GPSVendorLists: action.payload.data.data
            };

            
        case EXPORT_GPS_LOST_CONTACT_REPORT.REQUEST:
            return {
                ...state,
                loading: true,
            };
            
        case EXPORT_GPS_LOST_CONTACT_REPORT.SUCCESS:
            return {
                ...state,
                loading: false,
            };
    

        default:
            return state;
    }
}