import {
  LOAD_VEHICLE_SUMMARY_VEHICLE_USAGE,
  LOAD_VEHICLE_TYPE_SUMMARY_VEHICLE_USAGE,
  LOAD_PLANT_SUMMARY_VEHICLE_USAGE,
  EXPORT_SUMMARY_VEHICLE_USAGE,
  EXPORT_SUMMARY_VEHICLE_USAGE_PREVIEW,
  LOAD_DIVISION,
} from './actions'

const initialState = {
  Url: '',
  plantLists: [],
  vehicleLists: [],
  vehicleTypeLists: [],
  divisionLists: [],
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VEHICLE_SUMMARY_VEHICLE_USAGE.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        loading: true,
      }

    case LOAD_VEHICLE_SUMMARY_VEHICLE_USAGE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        loading: false,
      }

    case LOAD_VEHICLE_TYPE_SUMMARY_VEHICLE_USAGE.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }

    case LOAD_PLANT_SUMMARY_VEHICLE_USAGE.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOAD_PLANT_SUMMARY_VEHICLE_USAGE.SUCCESS:
      return {
        ...state,
        plantLists: action.payload.data.data,
        loading: false,
      }

    case EXPORT_SUMMARY_VEHICLE_USAGE.REQUEST:
    case EXPORT_SUMMARY_VEHICLE_USAGE_PREVIEW.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_SUMMARY_VEHICLE_USAGE.SUCCESS:
    case EXPORT_SUMMARY_VEHICLE_USAGE_PREVIEW.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_DIVISION.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOAD_DIVISION.SUCCESS:
      return {
        ...state,
        divisionLists: action.payload.data,
        loading: false,
      }

    default:
      return state
  }
}
