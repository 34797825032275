import { createTypes, createAction } from 'lib/action'

const LOAD_DRIVINGLICENSESCANLOG = createTypes('drivinglicensescanlog', 'load')
const LOAD_DRIVINGLICENSESCANLOG_PREVIEW = createTypes(
  'drivinglicensescanlogpreview',
  'load',
)
const LOAD_VEHICLE_DRIVINGLICENSESCANLOG = createTypes(
  'vehicle_drivinglicensescanlog',
  'load',
)
const LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANLOG = createTypes(
  'vehicletype_drivinglicensescanlog',
  'load',
)

const loadDrivinglicensescanlog = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGLICENSESCANLOG.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DRIVINGLICENSESCANLOG.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGLICENSESCANLOG.FAILURE),
}

const loadDrivinglicensescanlogpreview = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGLICENSESCANLOG_PREVIEW.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_DRIVINGLICENSESCANLOG_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGLICENSESCANLOG_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DRIVINGLICENSESCANLOG.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_DRIVINGLICENSESCANLOG.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DRIVINGLICENSESCANLOG.FAILURE),
}

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANLOG.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANLOG.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANLOG.FAILURE),
}

export {
  loadDrivinglicensescanlog,
  LOAD_DRIVINGLICENSESCANLOG,
  loadDrivinglicensescanlogpreview,
  LOAD_DRIVINGLICENSESCANLOG_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_DRIVINGLICENSESCANLOG,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_DRIVINGLICENSESCANLOG,
}
