import create from 'antd/lib/icon/IconFont'
import { createTypes, createAction } from 'lib/action'

const LOAD_DIVISION = createTypes('load_division_account_vehicle', 'load')
const LOAD_DEPARTMENT = createTypes('load_department_account_vehicle', 'load')
const LOAD_SECTION = createTypes('load_section_account_vehicle', 'load')
const LOAD_PLANT = createTypes('load_plant_account_vehicle', 'load')
const LOAD_VEHICLE_GROUP = createTypes('load_vehicle_group', 'load')
const LOAD_VEHICLE = createTypes('load_vehicle', 'load')
const LOAD_TRUCK_SHARING_TYPE = createTypes('load_truck_sharing_type', 'load')
const LOAD_TRUCK_SHARING_CURRUNT_LOCATION = createTypes(
  'load_truck_sharing_current_location',
  'load',
)
const LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT = createTypes(
  'load_truck_sharing_available_account',
  'load',
)

const LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ = createTypes(
  'load_truck_sharing_available_account_req',
  'load',
)

const UPDATE_TRUCK_SHARING_TYPE = createTypes(
  'updateTruckSharingType',
  'update',
)

const UPDATE_TRUCK_SHARING_CURRUNT_LOCATION = createTypes(
  'updateTruckSharingCurrentLocation',
  'update',
)

const UPDATE_TRUCK_SHARING_AVAILABLE = createTypes(
  'updateTruckSharingAvailable',
  'update',
)

const UPDATE_DRIVER_MOBILE_PHONE = createTypes(
  'updateDriverMobilePhone',
  'update',
)

const UPDATE_ROW_CPAC_TRUCK_SHARING = createTypes(
  'updateRowCpacTruckSharing',
  'update',
)

const UPDATE_CONTRACT_PHONE = createTypes(
  'updateTruckSharingContractPhone',
  'update',
)

const LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ_CREATE = createTypes(
  'load_truck_sharing_available_account_req_create',
  'load',
)

const LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE = createTypes(
  'load_truck_sharing_available_account_appove',
  'load',
)

const LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQUEST_TO_USE = createTypes(
  'load_truck_sharing_available_account_request_to_use',
  'load',
)

const LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE_PATCH = createTypes(
  'load_truck_sharing_available_account_appove_patch',
  'load',
)

const LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_HISTORY = createTypes(
  'load_truck_sharing_available_account_history',
  'load',
)

const SET_INITIAL_STATE_IN_REDUX = createTypes(
  'clear_initial_state_vehicle_in_redux',
  'clear',
)

const CLEAR_TABLE_WHEN_ONCHANGE = createTypes(
  'clear_table_when_onchange',
  'clear',
)

const CLEAR_TABLE_WHEN_ONCHANGE_CONTRACTOR = createTypes(
  'clear_table_when_onchange_contractor',
  'clear',
)

const DELETE_TRUCK_SHARING = createTypes('delete_truck_sharing', 'delete')

const GET_CPAC_TRUCK_SHARING_VEHICLE_TYPE = createTypes(
  'getCpacTruckSharingVehicleType',
  'get',
)

const GET_TRUCK_SHARING_LEVEL = createTypes('getTruckSharingLevel', 'get')

const clearStateInRedux = {
  request: () => {
    return createAction(SET_INITIAL_STATE_IN_REDUX.REQUEST, {})
  },
  success: () => {
    return createAction(SET_INITIAL_STATE_IN_REDUX.SUCCESS, {})
  },
  failure: () => createAction(SET_INITIAL_STATE_IN_REDUX.FAITLURE),
}

const clearTableWhenOnchange = {
  request: () => {
    return createAction(CLEAR_TABLE_WHEN_ONCHANGE.REQUEST, {})
  },
  success: () => {
    return createAction(CLEAR_TABLE_WHEN_ONCHANGE.SUCCESS, {})
  },
  failure: () => createAction(CLEAR_TABLE_WHEN_ONCHANGE.FAITLURE),
}

const clearTableWhenOnchangeContractor = {
  request: () => {
    return createAction(CLEAR_TABLE_WHEN_ONCHANGE_CONTRACTOR.REQUEST, {})
  },
  success: () => {
    return createAction(CLEAR_TABLE_WHEN_ONCHANGE_CONTRACTOR.SUCCESS, {})
  },
  failure: () => createAction(CLEAR_TABLE_WHEN_ONCHANGE_CONTRACTOR.FAITLURE),
}

const loadTruckSharingAvailableAccountHistory = {
  request: (page, pageSize, filterObj, vehicle_visibility) =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_HISTORY.REQUEST, {
      page,
      pageSize,
      filterObj,
      vehicle_visibility,
    }),
  success: data =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_HISTORY.SUCCESS, {
      data,
    }),
  failure: () =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_HISTORY.FAILURE),
}

const loadDivision = {
  request: (division_visibility, comp, company_id, successLoading) =>
    createAction(LOAD_DIVISION.REQUEST, {
      division_visibility,
      comp,
      company_id,
      successLoading,
    }),
  success: data => createAction(LOAD_DIVISION.SUCCESS, { data }),
  failure: () => createAction(LOAD_DIVISION.FAILURE),
}

const loadDepartment = {
  request: (department_visibility, division, company_id, successLoading) =>
    createAction(LOAD_DEPARTMENT.REQUEST, {
      department_visibility,
      division,
      company_id,
      successLoading,
    }),
  success: data => createAction(LOAD_DEPARTMENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_DEPARTMENT.FAILURE),
}

const loadSection = {
  request: (section_visibility, department, company_id, successLoading) =>
    createAction(LOAD_SECTION.REQUEST, {
      section_visibility,
      department,
      company_id,
      successLoading,
    }),
  success: data => createAction(LOAD_SECTION.SUCCESS, { data }),
  failure: () => createAction(LOAD_SECTION.FAILURE),
}

const loadPlant = {
  request: (
    location_visibility,
    section_visibility,
    section,
    relate_company,
    successLoading,
  ) =>
    createAction(LOAD_PLANT.REQUEST, {
      location_visibility,
      section_visibility,
      section,
      relate_company,
      successLoading,
    }),
  success: data => createAction(LOAD_PLANT.SUCCESS, { data }),
  failure: () => createAction(LOAD_PLANT.FAILURE),
}

const loadVehicleGroup = {
  request: (comp, successLoading) =>
    createAction(LOAD_VEHICLE_GROUP.REQUEST, {
      comp,
      successLoading,
    }),
  success: data => createAction(LOAD_VEHICLE_GROUP.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_GROUP.FAILURE),
}

const loadVehicle = {
  request: (
    vehicle_group,
    section_visibility,
    division,
    department,
    section,
    plant,
    vehicle_visibility,
    partner,
  ) =>
    createAction(LOAD_VEHICLE.REQUEST, {
      vehicle_group,
      section_visibility,
      division,
      department,
      section,
      plant,
      vehicle_visibility,
      partner,
    }),
  success: data => createAction(LOAD_VEHICLE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE.FAILURE),
}

const loadTruckSharingType = {
  request: (division_visibility, comp, company_id, successLoading) =>
    createAction(LOAD_TRUCK_SHARING_TYPE.REQUEST, {
      successLoading,
    }),
  success: data => createAction(LOAD_TRUCK_SHARING_TYPE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCK_SHARING_TYPE.FAILURE),
}

const loadTruckSharingCurrentLocation = {
  request: locationVisibility =>
    createAction(LOAD_TRUCK_SHARING_CURRUNT_LOCATION.REQUEST, {
      locationVisibility,
    }),
  success: data =>
    createAction(LOAD_TRUCK_SHARING_CURRUNT_LOCATION.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCK_SHARING_CURRUNT_LOCATION.FAILURE),
}

const loadTruckSharingAvailableAccount = {
  request: (page, pageSize, filterObj, vehicleVisibility) =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT.REQUEST, {
      page,
      pageSize,
      filterObj,
      vehicleVisibility,
    }),
  success: data =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT.FAILURE),
}

const loadTruckSharingAvailableAccountReq = {
  request: (page, pageSize, filterObj) =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ.REQUEST, {
      page,
      pageSize,
      filterObj,
    }),
  success: data =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ.FAILURE),
}

const loadTruckSharingAvailableAccountReqToCreate = {
  request: (
    vehicleId,
    destination,
    userName,
    contactPhone,
    dateStart,
    dateEnd,
    period,
  ) =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ_CREATE.REQUEST, {
      vehicleId,
      destination,
      userName,
      contactPhone,
      dateStart,
      dateEnd,
      period,
    }),
  success: data =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ_CREATE.SUCCESS, {
      data,
    }),
  failure: data =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ_CREATE.FAILURE, {
      data,
    }),
}

const loadTruckSharingAvailableAccountAppove = {
  request: (page, pageSize, vehicle_visibility) =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE.REQUEST, {
      page,
      pageSize,
      vehicle_visibility,
    }),
  success: data =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE.SUCCESS, {
      data,
    }),
  failure: () =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE.FAILURE),
}

const loadTruckSharingAvailableAccountRequestToUse = {
  request: (page, pageSize, vehicle_visibility) =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQUEST_TO_USE.REQUEST, {
      page,
      pageSize,
      vehicle_visibility,
    }),
  success: data =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQUEST_TO_USE.SUCCESS, {
      data,
    }),
  failure: () =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQUEST_TO_USE.FAILURE),
}

const updateTruckSharingType = {
  request: (id, type) =>
    createAction(UPDATE_TRUCK_SHARING_TYPE.REQUEST, {
      id,
      type,
    }),
  success: data => createAction(UPDATE_TRUCK_SHARING_TYPE.SUCCESS, { data }),
  failure: () => createAction(UPDATE_TRUCK_SHARING_TYPE.FAILURE),
}

const updateTruckSharingCurrentLocation = {
  request: (id, type) =>
    createAction(UPDATE_TRUCK_SHARING_CURRUNT_LOCATION.REQUEST, {
      id,
      type,
    }),
  success: data =>
    createAction(UPDATE_TRUCK_SHARING_CURRUNT_LOCATION.SUCCESS, { data }),
  failure: () => createAction(UPDATE_TRUCK_SHARING_CURRUNT_LOCATION.FAILURE),
}

const updateTruckSharingAvailable = {
  request: (id, type) =>
    createAction(UPDATE_TRUCK_SHARING_AVAILABLE.REQUEST, {
      id,
      type,
    }),
  success: data =>
    createAction(UPDATE_TRUCK_SHARING_AVAILABLE.SUCCESS, { data }),
  failure: data =>
    createAction(UPDATE_TRUCK_SHARING_AVAILABLE.FAILURE, { data }),
}

const updateDriverMobilePhone = {
  request: (id, phone) =>
    createAction(UPDATE_DRIVER_MOBILE_PHONE.REQUEST, {
      id,
      phone,
    }),
  success: data => createAction(UPDATE_DRIVER_MOBILE_PHONE.SUCCESS, { data }),
  failure: data => createAction(UPDATE_DRIVER_MOBILE_PHONE.FAILURE, { data }),
}

const updateRowCpacTruckSharing = {
  request: (id, obj) =>
    createAction(UPDATE_ROW_CPAC_TRUCK_SHARING.REQUEST, {
      id,
      obj,
    }),
  success: data =>
    createAction(UPDATE_ROW_CPAC_TRUCK_SHARING.SUCCESS, { data }),
  failure: data =>
    createAction(UPDATE_ROW_CPAC_TRUCK_SHARING.FAILURE, { data }),
}

const updateTruckSharingContractPhone = {
  request: (id, contractPhone) =>
    createAction(UPDATE_CONTRACT_PHONE.REQUEST, {
      id,
      contractPhone,
    }),
  success: data => createAction(UPDATE_CONTRACT_PHONE.SUCCESS, { data }),
  failure: data => createAction(UPDATE_CONTRACT_PHONE.FAILURE, { data }),
}

const loadTruckSharingAvailableAccountAppovePatch = {
  request: reserves =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE_PATCH.REQUEST, {
      reserves,
    }),
  success: data =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE_PATCH.SUCCESS, {
      data,
    }),
  failure: data =>
    createAction(LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE_PATCH.FAILURE, {
      data,
    }),
}

const deleteTruckSharingById = {
  request: id =>
    createAction(DELETE_TRUCK_SHARING.REQUEST, {
      id,
    }),
  success: data => createAction(DELETE_TRUCK_SHARING.SUCCESS, { data }),
  failure: data => createAction(DELETE_TRUCK_SHARING.FAILURE, { data }),
}

const getCpacTruckSharingVehicleType = {
  request: id =>
    createAction(GET_CPAC_TRUCK_SHARING_VEHICLE_TYPE.REQUEST, {
      id,
    }),
  success: data =>
    createAction(GET_CPAC_TRUCK_SHARING_VEHICLE_TYPE.SUCCESS, { data }),
  failure: data =>
    createAction(GET_CPAC_TRUCK_SHARING_VEHICLE_TYPE.FAILURE, { data }),
}

const getTruckSharingLevel = {
  request: id =>
    createAction(GET_TRUCK_SHARING_LEVEL.REQUEST, {
      id,
    }),
  success: data => createAction(GET_TRUCK_SHARING_LEVEL.SUCCESS, { data }),
  failure: data => createAction(GET_TRUCK_SHARING_LEVEL.FAILURE, { data }),
}

export {
  LOAD_DIVISION,
  loadDivision,
  LOAD_DEPARTMENT,
  loadDepartment,
  LOAD_SECTION,
  loadSection,
  LOAD_PLANT,
  loadPlant,
  LOAD_VEHICLE_GROUP,
  loadVehicleGroup,
  LOAD_VEHICLE,
  loadVehicle,
  LOAD_TRUCK_SHARING_TYPE,
  loadTruckSharingType,
  loadTruckSharingCurrentLocation,
  LOAD_TRUCK_SHARING_CURRUNT_LOCATION,
  loadTruckSharingAvailableAccount,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT,
  updateTruckSharingType,
  UPDATE_TRUCK_SHARING_TYPE,
  updateTruckSharingCurrentLocation,
  UPDATE_TRUCK_SHARING_CURRUNT_LOCATION,
  updateTruckSharingAvailable,
  UPDATE_TRUCK_SHARING_AVAILABLE,
  updateDriverMobilePhone,
  UPDATE_DRIVER_MOBILE_PHONE,
  updateTruckSharingContractPhone,
  UPDATE_CONTRACT_PHONE,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ,
  loadTruckSharingAvailableAccountReq,
  loadTruckSharingAvailableAccountReqToCreate,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQ_CREATE,
  loadTruckSharingAvailableAccountAppove,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE,
  loadTruckSharingAvailableAccountAppovePatch,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_APPOVE_PATCH,
  loadTruckSharingAvailableAccountHistory,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_HISTORY,
  SET_INITIAL_STATE_IN_REDUX,
  clearStateInRedux,
  CLEAR_TABLE_WHEN_ONCHANGE,
  clearTableWhenOnchange,
  deleteTruckSharingById,
  DELETE_TRUCK_SHARING,
  clearTableWhenOnchangeContractor,
  CLEAR_TABLE_WHEN_ONCHANGE_CONTRACTOR,
  updateRowCpacTruckSharing,
  UPDATE_ROW_CPAC_TRUCK_SHARING,
  LOAD_TRUCK_SHARING_AVAILABLE_ACCOUNT_REQUEST_TO_USE,
  loadTruckSharingAvailableAccountRequestToUse,
  GET_CPAC_TRUCK_SHARING_VEHICLE_TYPE,
  getCpacTruckSharingVehicleType,
  getTruckSharingLevel,
  GET_TRUCK_SHARING_LEVEL,
}
