import { createTypes, createAction } from 'lib/action'
const LOAD_MASTERDATA_SAMPLE_IMAGE = createTypes(
  'loadmasterdatasampleimage',
  'load',
)
const LOAD_MASTERDATA_SAMPLE_IMAGE_DETAIL = createTypes(
  'loadmasterdatasampleimageedtail',
  'load',
)
const UPDATE_MASTERDATA_SAMPLE_IMAGE = createTypes(
  'updatemasterdatasampleimage',
  'update',
)

const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadMasterDataSampleImage = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_SAMPLE_IMAGE.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_MASTERDATA_SAMPLE_IMAGE.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_MASTERDATA_SAMPLE_IMAGE.FAITLURE),
}

const loadMasterDataSampleImageDetail = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_SAMPLE_IMAGE_DETAIL.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(LOAD_MASTERDATA_SAMPLE_IMAGE_DETAIL.SUCCESS, { lists }),
  failure: () => createAction(LOAD_MASTERDATA_SAMPLE_IMAGE_DETAIL.FAITLURE),
}

const updateMasterDataSampleImage = {
  request: (data, loading) =>
    createAction(UPDATE_MASTERDATA_SAMPLE_IMAGE.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_MASTERDATA_SAMPLE_IMAGE.SUCCESS, { res }),
  failure: () => createAction(UPDATE_MASTERDATA_SAMPLE_IMAGE.FAILURE),
}

export {
  LOAD_MASTERDATA_SAMPLE_IMAGE,
  loadMasterDataSampleImage,
  LOAD_MASTERDATA_SAMPLE_IMAGE_DETAIL,
  loadMasterDataSampleImageDetail,
  UPDATE_MASTERDATA_SAMPLE_IMAGE,
  updateMasterDataSampleImage,
  REMOVE_LISTS,
  removeLists,
}
