import {
  LOAD_ALARMALERTBYPLATENO,
  LOAD_ALARMALERTBYPLATENO_PREVIEW,
  LOAD_VEHICLE_ALARMALERTBYPLATENO,
  LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO,
} from './actions'

const initialState = {
  alarmalertbyplatenoUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  alarmalertbyplatenoLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALARMALERTBYPLATENO.REQUEST:
    case LOAD_ALARMALERTBYPLATENO_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        alarmalertbyplatenoLoading: loading,
      }
    case LOAD_ALARMALERTBYPLATENO.SUCCESS:
    case LOAD_ALARMALERTBYPLATENO_PREVIEW.SUCCESS:
      return {
        ...state,
        alarmalertbyplatenoUrl: action.payload.data.data,
        alarmalertbyplatenoLoading: false,
      }
    case LOAD_VEHICLE_ALARMALERTBYPLATENO.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        alarmalertbyplatenoLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_ALARMALERTBYPLATENO.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        alarmalertbyplatenoLoading: false,
        //  loading: false
      }
    case LOAD_VEHICLE_TYPE_ALARMALERTBYPLATENO.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
