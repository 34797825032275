import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
//import { push } from "connected-react-router";

import * as actions from './actions'
import moment from 'moment'

function* loadDrivingoverlimit(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const {
    data: {
      list_vehicle_id,
      vehicle_type_id,
      eventdate,
      over_hour,
      filter_type,
      start_daily_time,
      type_file,
    },
  } = action.payload
  const date_start = moment(eventdate[0]['_d']).format('DD/MM/YYYY')
  const date_end = moment(eventdate[1]['_d']).format('DD/MM/YYYY')

  const start_time = moment(start_daily_time).format('HH:mm')
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_LOGIN}/service/report/drivingoverlimit`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id: COMPANY_ID,
        vehicle_type_id,
        user_id: USER_ID,
        type_file,
        vehicle_visibility,
        over_hour,
        filter_type,
        start_time,
      },
    )

    yield put(actions.loadDrivingoverlimit.success(res.data))
    window.location = res.data.data
  } catch (err) {
    yield put(actions.loadDrivingoverlimit.failure())
  }
}

function* loadDrivingoverlimitpreview(action) {
  const {
    data: {
      date_start,
      date_end,
      list_vehicle_id,
      company_id,
      vehicle_type_id,
      user_id,
      type_file,
      vehicle_visibility,
      over_hour,
      filter_type,
      start_time,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_LOGIN}/service/report/drivingoverlimit`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id,
        vehicle_type_id,
        user_id,
        type_file,
        vehicle_visibility,
        over_hour,
        filter_type,
        start_time,
      },
    )

    yield put(actions.loadDrivingoverlimitpreview.success(res.data))
    window.location = res.data.data
  } catch (err) {
    yield put(actions.loadDrivingoverlimitpreview.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadVehicleType.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleType.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_DRIVINGOVERLIMIT.REQUEST, loadDrivingoverlimit),
    takeEvery(
      actions.LOAD_DRIVINGOVERLIMIT_PREVIEW.REQUEST,
      loadDrivingoverlimitpreview,
    ),
    takeEvery(actions.LOAD_VEHICLE_DRIVINGOVERLIMIT.REQUEST, loadVehicle),
    takeEvery(
      actions.LOAD_VEHICLE_TYPE_DRIVINGOVERLIMIT.REQUEST,
      loadVehicleType,
    ),
  ])
}

export {
  loadDrivingoverlimit,
  loadVehicle,
  loadVehicleType,
  loadDrivingoverlimitpreview,
}
