import {
  SET_PARKING_MARKER,
  REMOVE_PARKING_MARKER,
  SET_IMAGE_VEHICLE_MARKER,
  REMOVE_IMAGE_VEHICLE_MARKER,
} from './actions'

const initialState = {
  parkingMarker: null,
  vehicleImageMarker: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PARKING_MARKER:
      return {
        ...state,
        parkingMarker: action.payload.data,
        vehicleImageMarker: null,
      }
    case REMOVE_PARKING_MARKER:
      return {
        ...state,
        parkingMarker: null,
      }
    case SET_IMAGE_VEHICLE_MARKER:
      return {
        ...state,
        vehicleImageMarker: action.payload.data,
        parkingMarker: null,
      }
    case REMOVE_IMAGE_VEHICLE_MARKER:
      return {
        ...state,
        vehicleImageMarker: null,
      }
    default:
      return state
  }
}
