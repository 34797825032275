import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadMultidrop(action) {
  const {
    data: {
      location_visibility,
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      company_id,
      filterObj,
      is_admin,
      material_visibility,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-multidrop-get`,
      {
        location_visibility,
        page,
        pageSize,
        orderBy,
        orderType,
        company_id,
        filterObj,
        is_admin,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadMultidrop.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMultidrop.failure())
  }
}

function* loadIsupplyMultidropGetIsupplyDo(action) {
  const {
    data: {
      start_date,
      end_date,
      company_id,
      location_visibility,
      material_visibility,
      filterObj,
      is_vendor,
      accessToken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-multidrop-get-isupply-do`,
      {
        start_date,
        end_date,
        company_id,
        location_visibility,
        material_visibility,
        filterObj,
        is_vendor,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadIsupplyMultidropGetIsupplyDo.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadIsupplyMultidropGetIsupplyDo.failure())
  }
}

function* createisupplyMultidrop(action) {
  const {
    data: { group, accessToken },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-multidrop-create`,
      {
        group,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createisupplyMultidrop.success(res.data))
  } catch (err) {
    yield put(actions.createisupplyMultidrop.failure())
  }
}

function* loadIsupplyMultidropGetIsupplyDoForEdit(action) {
  const {
    data: { id, accessToken },
    loading,
    cb,
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-multidrop-get/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb(res.data)
    yield put(actions.loadIsupplyMultidropGetIsupplyDoForEdit.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadIsupplyMultidropGetIsupplyDoForEdit.failure())
  }
}

function* updateIsupplyMultidrop(action) {
  const {
    data: { default_isupply_multidrop_id, group, accessToken },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-multidrop-update`,
      {
        default_isupply_multidrop_id,
        group,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateIsupplyMultidrop.success(res.data))
  } catch (err) {
    yield put(actions.updateIsupplyMultidrop.failure())
  }
}

export default function* watchIsupplyMultidrop() {
  yield all([
    takeEvery(
      actions.LOAD_ISUPPLY_MULTIDROP_GET_ISUPPLY_DO.REQUEST,
      loadIsupplyMultidropGetIsupplyDo,
    ),
    takeEvery(
      actions.CREATE_ISUPPLY_MULTIDROPL.REQUEST,
      createisupplyMultidrop,
    ),
    takeEvery(actions.LOAD_MULTIDROP.REQUEST, loadMultidrop),
    takeEvery(
      actions.LOAD_MULTIDROP_FOR_EDIT.REQUEST,
      loadIsupplyMultidropGetIsupplyDoForEdit,
    ),
    takeEvery(
      actions.UPDATE_ISUPPLY_MULTIDROPL.REQUEST,
      updateIsupplyMultidrop,
    ),
  ])
}
export {
  loadIsupplyMultidropGetIsupplyDo,
  createisupplyMultidrop,
  loadMultidrop,
  loadIsupplyMultidropGetIsupplyDoForEdit,
  updateIsupplyMultidrop,
}
