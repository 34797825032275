import { createTypes, createAction } from 'lib/action'

const LOAD_PARKINGAREAOUTSIDE = createTypes('parkingareaoutside', 'load')
const LOAD_PARKINGAREAOUTSIDE_PREVIEW = createTypes(
  'parkingareaoutside_preview',
  'load',
)
const LOAD_VEHICLE_PARKINGAREAOUTSIDE = createTypes(
  'vehicle_parkingareaoutside',
  'load',
)
const LOAD_VEHICLE_TYPE_PARKINGAREAOUTSIDE = createTypes(
  'vehicletype_parkingareaoutside',
  'load',
)

const loadParkingAreaOutside = {
  request: (data, loading) =>
    createAction(LOAD_PARKINGAREAOUTSIDE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_PARKINGAREAOUTSIDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_PARKINGAREAOUTSIDE.FAILURE),
}

const loadParkingAreaOutsidepreview = {
  request: (data, loading) =>
    createAction(LOAD_PARKINGAREAOUTSIDE_PREVIEW.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_PARKINGAREAOUTSIDE_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_PARKINGAREAOUTSIDE_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_PARKINGAREAOUTSIDE.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_PARKINGAREAOUTSIDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_PARKINGAREAOUTSIDE.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_PARKINGAREAOUTSIDE.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_PARKINGAREAOUTSIDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_PARKINGAREAOUTSIDE.FAILURE),
}

export {
  loadParkingAreaOutside,
  LOAD_PARKINGAREAOUTSIDE,
  loadParkingAreaOutsidepreview,
  LOAD_PARKINGAREAOUTSIDE_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_PARKINGAREAOUTSIDE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_PARKINGAREAOUTSIDE,
}
