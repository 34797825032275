import React, { Component } from 'react'
import { GeneralStyledContent } from '../../../styled/common-styled'
import AuthorizeComponent from './../../auth/components/AuthorizeComponent'
import Forms from './Components/Forms'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import * as actions from './actions'

class Index extends Component {
  state = {
    departmentLoading: false,
    department: [],
    section: [],
    plant: [],
    division: [],
    vehicleGroup: [],
    vehicles: [],
    selectedDivision: 'all',
    selectedDepartment: 'all',
    selectedSection: 'all',
    selectedPlant: ['all'],
    selectedVehicleGroup: 'all',
    selectedPartner: 'all',
  }

  loadSummaryVehicleUsage = (values, loading) => {
    this.props.exportSummaryVehicleUsage(
      {
        date_start: values.start_date,
        date_end: values.end_date,
        list_plant_id: values.list_plant_id,
        list_vehicle_id: values.list_vehicle_id,
        company_id: this.props.auth.profile.company_id,
        user_id: this.props.auth.profile.id,
        type_file: 'excel',
      },
      loading,
    )
  }

  loadVehicle = cb => {
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type === 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclesbydivisionbranch`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          vehicle_group: this.state.selectedVehicleGroup,
          section_visibility: sectionVisibility,
          division: this.state.selectedDivision,
          department: this.state.selectedDepartment,
          section: this.state.selectedSection,
          plant: this.state.selectedPlant,
          vehicle_visibility: this.props.auth.profile.vehicle_visibility,
          partner: this.state.selectedPartner,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            loading: false,
            vehicles: data.data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadVehicleType = () => {
    this.props.loadVehicleType()
  }

  loadDivision = cb => {
    this.setState({ loading: true })

    let self = this
    let divisionVisibility = []

    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      divisionVisibility = orgChart.divisions.map(ele => ele.divisionNo)
    }
    this.setState({ divisionLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        division_visibility: divisionVisibility,
        comp: [2, 3, 4, 5, 6],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            divisionLoading: false,
            division: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadDepartment = cb => {
    let self = this
    let departmentVisibility = []
    if (self.props.auth.profile.user_type === 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      departmentVisibility = orgChart.departments.map(ele => ele)
    }
    this.setState({ departmentLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        department_visibility: departmentVisibility,
        division: [this.state.selectedDivision],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            departmentLoading: false,
            department: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadSection = cb => {
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type === 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }

    this.setState({ sectionLoading: true })

    let departmentID = [this.state.selectedDepartment.toString()]

    if (this.state.selectedDepartment === 'all') {
      departmentID = this.state.department.map(x => x.id.toString())
    }

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        section_visibility: sectionVisibility,
        department: departmentID,
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            sectionLoading: false,
            section: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadPlant = cb => {
    let self = this
    let plantVisibility = []
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    plantVisibility = self.props.auth.profile.location_visibility
    this.setState({ plantLoading: true })

    let sectionID = [this.state.selectedSection.toString()]

    if (this.state.selectedSection === 'all') {
      sectionID = this.state.section.map(x => x.id.toString())
    }

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantforconcretedashboard`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          location_visibility: plantVisibility,
          section_visibility: sectionVisibility,
          section: sectionID,
          relate_company: `${this.props.auth.profile.relate_company}`,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            plant: data,
            plantLoading: false,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadVehicleGroup = cb => {
    let self = this

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            loading: false,
            vehicleGroup: data.data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  updateSelectDivisoin = e =>
    this.setState(
      {
        selectedDivision: e,
        selectedDepartment: 'all',
        selectedSection: 'all',
        selectedPlant: ['all'],
      },
      e => {
        this.loadDepartment(() =>
          this.loadSection(() => this.loadPlant(() => this.loadVehicle())),
        )
      },
    )

  updateSelectDepartment = e =>
    this.setState(
      { selectedDepartment: e, selectedSection: 'all', selectedPlant: ['all'] },
      e => {
        this.loadSection(() => this.loadPlant(() => this.loadVehicle()))
      },
    )

  updateSelectSection = e =>
    this.setState({ selectedSection: e, selectedPlant: ['all'] }, e => {
      this.loadPlant(() => this.loadVehicle())
    })

  updateSelectVehicleGroup = e => {
    this.setState({ selectedVehicleGroup: e }, e => {
      this.loadVehicle()
    })
  }

  updateSelectPlant = e => {
    if (e === 'all') {
      this.setState({ selectedPlant: ['all'] }, e => {
        this.loadVehicle()
      })
    } else {
      this.setState({ selectedPlant: e }, e => {
        this.loadVehicle()
      })
    }
  }

  componentDidMount() {
    this.loadVehicleGroup()
    this.loadDivision(() =>
      this.loadDepartment(() =>
        this.loadSection(() => this.loadPlant(() => this.loadVehicle())),
      ),
    )
  }

  render() {
    const { loading } = this.props.summaryVehicleUsage

    const {
      selectedDivision,
      department,
      selectedDepartment,
      section,
      selectedSection,
      plant,
      selectedPlant,
      vehicleGroup,
      selectedVehicleGroup,
    } = this.state

    return (
      <AuthorizeComponent {...this.props} matching_name="summaryFuelUsage">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}></Col>
          </Row>
          <Forms
            onSubmit={this.loadSummaryVehicleUsage}
            loading={loading}
            user_id={this.props.auth.profile.id}
            company_id={this.props.profile.company_id}
            accessToken={this.props.accessToken}
            vehicle_visibility={this.props.profile.vehicle_visibility}
            division={this.state.division}
            selectedDivision={selectedDivision}
            department={department}
            selectedDepartment={selectedDepartment}
            updateSelectDivisoin={this.updateSelectDivisoin}
            section={section}
            selectedSection={selectedSection}
            updateSelectDepartment={this.updateSelectDepartment}
            updateSelectSection={this.updateSelectSection}
            plant={plant}
            selectedPlant={selectedPlant}
            updateSelectPlant={this.updateSelectPlant}
            vehicleGroup={vehicleGroup}
            selectedVehicleGroup={selectedVehicleGroup}
            vehicles={this.state.vehicles}
            updateSelectVehicleGroup={this.updateSelectVehicleGroup}
          />
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default withRouter(
  connect(
    ({ summaryVehicleUsage, auth, auth: { profile } }) => ({
      summaryVehicleUsage,
      auth,
      profile,
    }),
    {
      exportSummaryVehicleUsage: actions.exportSummaryVehicleUsage.request,
      loadVehicleType: actions.loadVehicleType.request,
      loadVehicle: actions.loadVehicle.request,
      loadPlant: actions.loadPlant.request,
      loadDivision: actions.loadDivision.request,
    },
  )(Index),
)
