import {
  LOAD_ALARMALERTBYDRIVER,
  LOAD_ALARMALERTBYDRIVER_PREVIEW,
  LOAD_DRIVER,
} from './actions'

const initialState = {
  alarmalertfrequencybydriverUrl: '',
  driverLists: [],
  alarmalertbydriverLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALARMALERTBYDRIVER.REQUEST:
    case LOAD_ALARMALERTBYDRIVER_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        alarmalertbydriverLoading: loading,
      }
    case LOAD_ALARMALERTBYDRIVER.SUCCESS:
    case LOAD_ALARMALERTBYDRIVER_PREVIEW.SUCCESS:
      return {
        ...state,
        alarmalertfrequencybydriverUrl: action.payload.data.data,
        alarmalertbydriverLoading: false,
      }
    case LOAD_DRIVER.SUCCESS:
      return {
        ...state,
        driverLists: action.payload.data.data,
      }
    default:
      return state
  }
}
