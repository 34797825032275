import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
//import { push } from "connected-react-router";

import * as actions from './actions'
import moment from 'moment'

function* loadDrivinghour(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const {
    data: {
      vehicle_code,
      plate_no,
      eventdate,
      group_idle,
      group_speed,
      type_file,
    },
  } = action.payload

  const date_start = moment(eventdate[0]['_d']).format('DD/MM/YYYY HH:mm')
  const date_end = moment(eventdate[1]['_d']).format('DD/MM/YYYY HH:mm')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_LOGIN}/service/report/drivinghour`,
      {
        date_start,
        date_end,
        company_id: COMPANY_ID,
        user_id: USER_ID,
        vehicle_visibility,
        vehicle_code,
        plate_no,
        group_idle,
        group_speed,
        type_file,
      },
    )

    yield put(actions.loadDrivinghour.success(res.data))
    window.location = res.data.data
  } catch (err) {
    yield put(actions.loadDrivinghour.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: '',
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_DRIVINGHOUR.REQUEST, loadDrivinghour),
    takeEvery(actions.LOAD_VEHICLE_DRIVINGHOUR.REQUEST, loadVehicle),
  ])
}

export { loadDrivinghour, loadVehicle }
