import {
  LOAD_EVENT,
  LOAD_VEHICLE_EVENT,
  LOAD_VEHICLE_BY_VENDOR,
  LOAD_VEHICLE_TYPE_EVENT,
  LOAD_POLICY_EVENT,
  LOAD_EVENT_EVENT,
  LOAD_VEHICLE_BY_POLICY_EVENT,
  LOAD_VEHICLE_EVENT_BY_GROUP,
  LOAD_SAFETY_EVENT,
  LOAD_SAFETY_EVENT_PREVIEW,
  LOAD_VEHICLE_VENDOR_EVENT,
} from './actions'

const initialState = {
  eventUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  policyLists: [],
  eventLists: [],
  vehiclebypolicyLists: [],
  vehiclebygroupLists: [],
  eventLoading: false,
  vehicleVendorList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EVENT.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        eventLoading: loading,
      }
    case LOAD_EVENT.SUCCESS:
      return {
        ...state,
        eventUrl: action.payload.data.data,
        eventLoading: false,
      }
    case LOAD_SAFETY_EVENT.REQUEST:
    case LOAD_SAFETY_EVENT_PREVIEW.REQUEST:
      return {
        ...state,
        eventLoading: action.payload.loading,
      }
    case LOAD_SAFETY_EVENT.SUCCESS:
    case LOAD_SAFETY_EVENT_PREVIEW.SUCCESS:
      return {
        ...state,
        eventUrl: action.payload.data.data,
        eventLoading: false,
      }
    case LOAD_VEHICLE_EVENT.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        eventLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_EVENT.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        eventLoading: false,
      }
    case LOAD_VEHICLE_BY_VENDOR.REQUEST:
      return {
        ...state,
        eventLoading: action.payload.loading_vehicle,
      }
    case LOAD_VEHICLE_BY_VENDOR.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        eventLoading: false,
      }
    case LOAD_VEHICLE_EVENT_BY_GROUP.REQUEST:
      const { loading_vehicle2 } = action.payload
      return {
        ...state,
        eventLoading: loading_vehicle2,
      }
    case LOAD_VEHICLE_EVENT_BY_GROUP.SUCCESS:
      return {
        ...state,
        vehiclebygroupLists: action.payload.data.data,
        eventLoading: false,
      }
    case LOAD_VEHICLE_TYPE_EVENT.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    case LOAD_POLICY_EVENT.SUCCESS:
      return {
        ...state,
        policyLists: action.payload.data.data,
      }
    case LOAD_EVENT_EVENT.SUCCESS:
      return {
        ...state,
        eventLists: action.payload.data.data,
      }
    case LOAD_VEHICLE_BY_POLICY_EVENT.REQUEST:
      const { loading_vehicle1 } = action.payload
      return {
        ...state,
        eventLoading: loading_vehicle1,
      }
    case LOAD_VEHICLE_BY_POLICY_EVENT.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        eventLoading: false,
      }
    case LOAD_VEHICLE_VENDOR_EVENT.SUCCESS:
      return {
        ...state,
        vehicleVendorList: action.payload.data.data,
      }
    default:
      return state
  }
}
