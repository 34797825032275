import {
  LOAD_SUMMARYKPI,
  LOAD_VEHICLE_SUMMARYKPI,
  LOAD_VEHICLE_TYPE_SUMMARYKPI,
  LOAD_SUMMARYKPI_PREVIEW
} from "./actions";

const initialState = {
  summarykpiUrl: "",
  vehicleLists: [],
  vehicleTypeLists: [],
  summarykpiLoading: false,
  summarykpipreviewLists: [],
  summarykpipreviewLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SUMMARYKPI.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        summarykpiLoading: loading
      };
    case LOAD_SUMMARYKPI.SUCCESS:
      return {
        ...state,
        summarykpiUrl: action.payload.data.data,
        summarykpiLoading: false
      };
    case LOAD_SUMMARYKPI_PREVIEW.REQUEST:
      const { preview_loading } = action.payload;
      return {
        ...state,
        summarykpipreviewLoading: preview_loading
      };
    case LOAD_SUMMARYKPI_PREVIEW.SUCCESS:
      const result = action.payload.data.rows.map((value, i) => {
        return {
          "trip_code": value.cell[0],
          "plate_no": value.cell[1],
          "firstname": value.cell[2],
          "amount_cardreader": value.cell[3],
          "distance": value.cell[4],
          "score_safety": value.cell[5],
          "score_save": value.cell[6],
          "a": value.cell[7],
          "b": value.cell[8],
          "c": value.cell[9],
          "d": value.cell[10],
          "e": value.cell[11],
          "f": value.cell[12],
          "g": value.cell[13],
          "h": value.cell[14],
          "i": value.cell[15],
          "j": value.cell[16],
          "k": value.cell[17],
          "l": value.cell[18],
          "m": value.cell[19],
          "n": value.cell[20],
          "o": value.cell[21]

        }
      })

      return {
        ...state,
        summarykpipreviewLists: result,
        summarykpipreviewLoading: false
      };
    case LOAD_VEHICLE_SUMMARYKPI.REQUEST:
      const { loading_vehicle } = action.payload;
      return {
        ...state,
        summarykpiLoading: loading_vehicle
      };
    case LOAD_VEHICLE_SUMMARYKPI.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        summarykpiLoading: false
      };
    case LOAD_VEHICLE_TYPE_SUMMARYKPI.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data
      };
    default:
      return state;
  }
};
