import { createTypes, createAction } from 'lib/action'
const LOAD_ISUPPLY_BOOKING_ORDER_LIST = createTypes(
  'loadisupplybookingorderlist',
  'load',
)
const LOAD_ISUPPLY_BOOKING_ORDER_DETAIL = createTypes(
  'loadisupplybookingorderdetail',
  'load',
)
const CREATE_ISUPPLY_BOOKING_ORDER = createTypes(
  'createisupplybookingorder',
  'create',
)
const UPDATE_ISUPPLY_BOOKING_ORDER = createTypes(
  'updateisupplybookingorder',
  'create',
)
const EXPORT_ISUPPLY_BOOKING_ORDER = createTypes(
  'exportisupplybookingorder',
  'export',
)
const LOAD_ISUPPLY_BOOKING_ORDER_DETAIL_FOR_SUGGEST_LOCATION = createTypes(
  'loadbookingorderdetailaforsuggestlocation',
  'create',
)

const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadBookingOrderList = {
  request: (data, loading) =>
    createAction(LOAD_ISUPPLY_BOOKING_ORDER_LIST.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_ISUPPLY_BOOKING_ORDER_LIST.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_ISUPPLY_BOOKING_ORDER_LIST.FAITLURE),
}

const createiSupplyBookingOrder = {
  request: (data, loading) =>
    createAction(CREATE_ISUPPLY_BOOKING_ORDER.REQUEST, { data, loading }),
  success: res => createAction(CREATE_ISUPPLY_BOOKING_ORDER.SUCCESS, { res }),
  failure: () => createAction(CREATE_ISUPPLY_BOOKING_ORDER.FAITLURE),
}

const updateiSupplyBookingOrder = {
  request: (data, loading) =>
    createAction(UPDATE_ISUPPLY_BOOKING_ORDER.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_ISUPPLY_BOOKING_ORDER.SUCCESS, { res }),
  failure: () => createAction(UPDATE_ISUPPLY_BOOKING_ORDER.FAITLURE),
}

const loadSupplyBookingOrderDetail = {
  request: (data, loading) =>
    createAction(LOAD_ISUPPLY_BOOKING_ORDER_DETAIL.REQUEST, { data, loading }),
  success: listsEdit =>
    createAction(LOAD_ISUPPLY_BOOKING_ORDER_DETAIL.SUCCESS, { listsEdit }),
  failure: () => createAction(LOAD_ISUPPLY_BOOKING_ORDER_DETAIL.FAITLURE),
}

const exportSupplyBookingOrder = {
  request: (data, loading) =>
    createAction(EXPORT_ISUPPLY_BOOKING_ORDER.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_ISUPPLY_BOOKING_ORDER.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_ISUPPLY_BOOKING_ORDER.FAITLURE),
}

const loadBookingOrderDetailForSuggestLocation = {
  request: (data, loading) =>
    createAction(
      LOAD_ISUPPLY_BOOKING_ORDER_DETAIL_FOR_SUGGEST_LOCATION.REQUEST,
      { data, loading },
    ),
  success: listsEdit =>
    createAction(
      LOAD_ISUPPLY_BOOKING_ORDER_DETAIL_FOR_SUGGEST_LOCATION.SUCCESS,
      { listsEdit },
    ),
  failure: () =>
    createAction(
      LOAD_ISUPPLY_BOOKING_ORDER_DETAIL_FOR_SUGGEST_LOCATION.FAITLURE,
    ),
}

export {
  REMOVE_LISTS,
  removeLists,
  CREATE_ISUPPLY_BOOKING_ORDER,
  createiSupplyBookingOrder,
  LOAD_ISUPPLY_BOOKING_ORDER_LIST,
  loadBookingOrderList,
  LOAD_ISUPPLY_BOOKING_ORDER_DETAIL,
  loadSupplyBookingOrderDetail,
  UPDATE_ISUPPLY_BOOKING_ORDER,
  updateiSupplyBookingOrder,
  EXPORT_ISUPPLY_BOOKING_ORDER,
  exportSupplyBookingOrder,
  LOAD_ISUPPLY_BOOKING_ORDER_DETAIL_FOR_SUGGEST_LOCATION,
  loadBookingOrderDetailForSuggestLocation,
}
