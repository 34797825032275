import {
    LOAD_COMPANY,
    LOAD_COMPANY_DETAIL,
    CREATE_COMPANY,
    UPDATE_COMPANY,
    EXPORT_COMPANY,
    IMPORT_COMPANY,
    REMOVE_LISTS,
} from './actions'
const initialState = {
    lists: [],
    total: 1,
    loading: false,
    check: true,
    data: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState

        case LOAD_COMPANY.REQUEST:
            const { loading } = action.payload.data
            return {
                ...state,
                loading: loading,
            }

        case LOAD_COMPANY.SUCCESS:
            const { page, pageSize } = action.payload
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1
                if (page > 1)
                    action.payload.lists.data[i].rowNumber =
                    action.payload.lists.data[i].rowNumber + (page - 1) * pageSize
            }

            return {
                ...state,
                lists: action.payload.lists.data,
                total: action.payload.lists.total - 1 <= 0 ?
                    1 :
                    action.payload.lists.total <= 0 ?
                    1 :
                    action.payload.lists.total,
                loading: false,
                draw: Math.floor(Math.random() * 999 + 1),
            }

        case LOAD_COMPANY_DETAIL.REQUEST:
            return {
                ...state,
                loading: action.payload.data.loading,
            }

        case LOAD_COMPANY_DETAIL.SUCCESS:
            return {
                ...state,
                lists: action.payload.lists.data,
                check: false,
            }

        case CREATE_COMPANY.SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case UPDATE_COMPANY.SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case EXPORT_COMPANY.REQUEST:
            return {
                ...state,
                loading: true,
            }

        case EXPORT_COMPANY.SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case IMPORT_COMPANY.REQUEST:
            return {
                ...state,
                loading: true,
            }

        case IMPORT_COMPANY.SUCCESS:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}