import React, { Component } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Button, Form, Input, Row, Col, Select, Spin } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import BasicFilter from '../../../reports/basicFilterReport/Index'
const FormItem = Form.Item

export default class Filter extends Component {
  render() {
    const { OnClose, onSubmit, loading } = this.props
    return (
      <Spin spinning={loading}>
        <Formik
          initialValues={{
            name: '',
            line_token: '',
            division: this.props.selectedDivision,
            department: this.props.selectedDepartment,
            section: this.props.selectedSection,
          }}
          validationSchema={yup.object().shape({})}
          onSubmit={values => {
            const loading = true
            onSubmit(values, loading)
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => (
            <Row>
              <LangContext.Consumer>
                {i18n => (
                  <Form onSubmit={handleSubmit}>
                    <BasicFilter
                      setFieldValue={setFieldValue}
                      values={values}
                      touched={touched}
                      errors={errors}
                      division={this.props.division}
                      updateSelectDivision={e => {
                        this.props.updateSelectDivision(e)
                      }}
                      department={this.props.department}
                      updateSelectDepartment={e => {
                        this.props.updateSelectDepartment(e)
                      }}
                      section={this.props.section}
                      updateSelectSection={e => {
                        this.props.updateSelectSection(e)
                      }}
                      hasPlant={false}
                      hasVehicleGroup={false}
                    />
                    <FormItem
                      label={
                        <>
                          <LangContext.Consumer>
                            {i18n => i18n.c.code}
                          </LangContext.Consumer>

                          <LangContext.Consumer>
                            {i18n => i18n.s.section}
                          </LangContext.Consumer>
                        </>
                      }
                      validateStatus={touched.name && errors.name && 'error'}
                      help={touched.name && errors.name}
                    >
                      <Input
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                    </FormItem>

                    <FormItem
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.l.line_token}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.line_token && errors.line_token && 'error'
                      }
                      help={touched.line_token && errors.v}
                    >
                      <Input
                        name="line_token"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.line_token}
                      />
                    </FormItem>

                    <FormItem>
                      <Row gutter={24}>
                        <Col span={3}>
                          <Button onClick={OnClose}>{i18n.c.cancel}</Button>
                        </Col>
                        <Col span={1} />
                        <Col span={3}>
                          <Button htmlType="submit" type="primary">
                            {i18n.a.apply}
                          </Button>
                        </Col>
                      </Row>
                    </FormItem>
                  </Form>
                )}
              </LangContext.Consumer>
            </Row>
          )}
        />
      </Spin>
    )
  }
}
