import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

// *load table log ประเมินคุณภาพ
function* monitorGPSTableLog(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      vehicleVisibility,
      filterObj,
      accessToken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/monitor-gps/log`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        vehicleVisibility,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.monitorGPSTableLog.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.monitorGPSTableLog.failure())
  }
}

// *ดึงข้อมูลเพื่อแสดง chart
function* monitorGPSChartLog(action) {
  const {
    data: { vehicle_id, start_date, end_date, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/monitor-gps/info-log`,
      {
        vehicle_id,
        start_date,
        end_date,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.monitorGPSChartLog.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.monitorGPSChartLog.failure())
  }
}

export default function* watchMonitorGPS() {
  yield all([
    takeEvery(actions.MONITOR_GPS_TABLE_LOG.REQUEST, monitorGPSTableLog),
    takeEvery(actions.MONITOR_GPS_CHART_LOG.REQUEST, monitorGPSChartLog),
  ])
}
export { monitorGPSTableLog, monitorGPSChartLog }
