import { createTypes, createAction } from 'lib/action'
const LOAD_TRUCKS_PICKUP_CARD_NO = createTypes('loadtruckpickupcardno', 'load')
const CREATE_TRUCKS_PICKUP_CARD_NO = createTypes(
  'createtruckpickupcardno',
  'create',
)
const LOAD_TRUCKS_PICKUP_CARD_NO_DETAIL = createTypes(
  'loadtruckpickupcardnodetail',
  'load',
)
const UPDATE_TRUCKS_PICKUP_CARD_NO = createTypes(
  'updatetruckpickupcardno',
  'update',
)
const EXPORT_TRUCKS_PICKUP_CARD_NO = createTypes(
  'exporttruckpickupcardno',
  'load',
)
const IMPORT_TRUCKS_PICKUP_CARD_NO = createTypes(
  'importtruckpickupcardno',
  'create',
)

const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadMasterDataTruckPickupCardNo = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKS_PICKUP_CARD_NO.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_TRUCKS_PICKUP_CARD_NO.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_TRUCKS_PICKUP_CARD_NO.FAITLURE),
}

const createMasterDataTruckPickupCardNo = {
  request: (data, loading) =>
    createAction(CREATE_TRUCKS_PICKUP_CARD_NO.REQUEST, { data, loading }),
  success: res => createAction(CREATE_TRUCKS_PICKUP_CARD_NO.SUCCESS, { res }),
  failure: () => createAction(CREATE_TRUCKS_PICKUP_CARD_NO.FAITLURE),
}

const loadMasterDataTruckPickupCardNoDetail = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKS_PICKUP_CARD_NO_DETAIL.REQUEST, { data, loading }),
  success: res =>
    createAction(LOAD_TRUCKS_PICKUP_CARD_NO_DETAIL.SUCCESS, { res }),
  failure: () => createAction(LOAD_TRUCKS_PICKUP_CARD_NO_DETAIL.FAITLURE),
}

const updateMasterDataTruckPickupCardNo = {
  request: (data, loading) =>
    createAction(UPDATE_TRUCKS_PICKUP_CARD_NO.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_TRUCKS_PICKUP_CARD_NO.SUCCESS, { res }),
  failure: () => createAction(UPDATE_TRUCKS_PICKUP_CARD_NO.FAITLURE),
}

const exportMasterDataTruckPickupCardNo = {
  request: (data, loading) =>
    createAction(EXPORT_TRUCKS_PICKUP_CARD_NO.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_TRUCKS_PICKUP_CARD_NO.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_TRUCKS_PICKUP_CARD_NO.FAITLURE),
}

const importMasterDataTruckPickupCardNo = {
  request: (data, loading) =>
    createAction(IMPORT_TRUCKS_PICKUP_CARD_NO.REQUEST, { data, loading }),
  success: res => createAction(IMPORT_TRUCKS_PICKUP_CARD_NO.SUCCESS, { res }),
  failure: () => createAction(IMPORT_TRUCKS_PICKUP_CARD_NO.FAITLURE),
}

export {
  REMOVE_LISTS,
  removeLists,
  LOAD_TRUCKS_PICKUP_CARD_NO,
  loadMasterDataTruckPickupCardNo,
  CREATE_TRUCKS_PICKUP_CARD_NO,
  createMasterDataTruckPickupCardNo,
  LOAD_TRUCKS_PICKUP_CARD_NO_DETAIL,
  loadMasterDataTruckPickupCardNoDetail,
  UPDATE_TRUCKS_PICKUP_CARD_NO,
  updateMasterDataTruckPickupCardNo,
  EXPORT_TRUCKS_PICKUP_CARD_NO,
  exportMasterDataTruckPickupCardNo,
  IMPORT_TRUCKS_PICKUP_CARD_NO,
  importMasterDataTruckPickupCardNo,
}
