import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Spin,
  Modal,
  TimePicker,
  Checkbox,
} from 'antd'

import { StyledSearchForm } from '../../../../styled/common-styled'
import FormDivider from '../../../shared/components/FormDivider'
import { getlocalstorage } from './../../../../constants/local_storage'
import Dailyiconsiam from '../../dailyiconsiam/components/Forms'
import Summaryiconsiam from '../../summaryiconsiam/components/Forms'
import Truckcurrentlocation from '../../truckcurrentlocation/components/Forms'
import Driverinformation from '../../driverinformation/components/Forms'
import Numberofdaysworked from '../../numberofdaysworked/components/Forms'
import Distfromlastmaintenance from '../../distfromlastmaintenance/components/Forms'
import Maintenance from '../../maintenance/components/Forms'
import Temperature from '../../temperature/components/Forms'
import Drivingdistance from '../../drivingdistance/components/Forms'
import Event from '../../event/components/Forms'
import Truckusage from '../../truckusage/components/Forms'
import Parking from '../../parking/components/Forms'
import Comparevehiclescco from '../../comparevehiclescco/components/Forms'
import Drivinghour from '../../drivinghour/components/Forms'
import Dozeoffrisk from '../../dozeoffrisk/components/Forms'
import Repairingreport from '../../repairingreport/components/Forms'
import Gpsvendorpayingreport from '../../gpsvendorpayingreport/components/Forms'
import Partnerperformreport from '../../partnerperformreport/components/Forms'
import Gpsvendorpayingsummaryreport from '../../gpsvendorpayingsummaryreport/components/Forms'
import RMCconcretetrip from '../../rmcconcretetrip/componants/Forms'
import Unauthorizeunload from '../../unauthorizeunload/components/Forms'
import SummaryVehicleUsage from '../../summary-vehicle-usage/Components/Forms'
import AlertDriverLicense from '../../alertdriverlicense/components/Forms'
import DrivingLicenseScanlogReport from '../../drivinglicensescanlog/components/Forms'
import DrivingLogReport from '../../drivinglog/components/Forms'
import DailyCarStatusReport from '../../separatevehiclestatus/components/Forms'
import SpeedOverLimitSummaryReport from '../../speedoverlimitsummary/components/Forms'
import TripByEngineOffOnReport from '../../tripbyengineoffon/components/Forms'
import BehaviordrivingReport from '../../behaviordriving/components/Forms'
import BehaviordrivingbycompanyReport from '../../behaviordrivingbycompany/components/Forms'
import ScoresummaryReport from '../../scoresummary/components/Forms'
import SafetyEventReport from '../../safetyEvent/components/Forms'
import TruckengineonReport from '../../truckengineon/components/Forms'
import VehicleperformanceconcludeReport from '../../vehicleperformanceconclude/components/Forms'
import DrivingSummary from '../../daily-driving-summary-report/components/Forms'
import Distancereport from '../../distancereport/components/Form'
import FuelremainrateReport from '../../fuelremainrate/components/Forms'
import FueluseReport from '../../fueluse/components/Forms'
import GpsReportLostContact from '../../gps-lost-contact/components/Forms'
import GpsDisconnectOnTrip from '../../gpsdisconnectontrip/components/Forms'

import {
  getVehicleFilter,
  loadReportData,
  getFilterName,
} from '../../unauthorizeunload/components/helper'
import { async } from 'rxjs'
import { useState } from 'react'

const InputGroup = Input.Group
const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
const format = 'HH:mm'

const initialState = {
  selectedDivision: 'all',
  selectedDepartment: 'all',
  selectedSection: 'all',
  selectedPlant: ['all'],
  selectedVehicleGroup: 'all',
  selectedPartner: 'all',
  loadData: false,
}

export default class Forms extends Component {
  state = {
    list_report_schedules_id: '',
    schedule_type_id: 'daily',
    attachment_type: 'excel',
    email_subject: '',
    to_email: '',
    cc_email: '',
    body_email: '',
    start_time: moment('00:00', format),
    end_time: moment('23:59', format),
    previous_data: true,
    visible: false,
    matching_name: '',
    vehicleLists: [],
    vehicleTypeLists: [],
    loading: false,
    days: [],
    day: '1',
    week: '1',
    month: '1',
    time: moment('00:00', format),
    filter_report: '',
    schedule_type: 'daily',

    vendor: [],
    vehicle_vendor: [],
    vehicle_group: [],
    plantLists: [],
    partner: [],
    partnerBehaviorDriving: [],
    check_load_vehicle: false,
    partnerSafetyEvent: [],

    vehicleGPSVendorLists: [],
    GPSVendorLists: [],

    vehicleGroup: [],
    vehicles: [],
    vehicleList: [],
    divisionLoading: false,
    division: [],
    departmentLoading: false,
    department: [],
    sectionLoading: false,
    section: [],
    plant: [],
    selectedPartner: 'all',
    selectedDivision: 'all',
    selectedDepartment: 'all',
    selectedSection: 'all',
    selectedVehicleGroup: 'all',
    selectedPlant: ['all'],
    eventdate: [
      moment()
        .clone()
        .startOf('day')
        .format('YYYY-MM-DD 00:00:00'),
      moment()
        .clone()
        .endOf('day')
        .format('YYYY-MM-DD 23:59:59'),
    ],
  }

  loadPartner = () => {
    let self = this
    this.setState({ loading: true })
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpartnercompany`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
        is_gps_vendor: this.props.auth.profile.is_gps_vendor,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ partner: res.data, loading: false })
      })
      .catch(e => this.setState({ loading: false }))
  }

  loadpartnerBehaviorDrivingByCompany = () => {
    let self = this
    this.setState({ loading: true })
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpartnerreport`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        companyID: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ partnerBehaviorDriving: res.data, loading: false })
      })
      .catch(e => this.setState({ loading: false }))
  }

  loadVehicleBehaviorDrivingByCompany = (partnerID, loading) => {
    this.setState({ loading })
    let self = this

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebypartnerid`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          companyID: this.props.auth.profile.company_id,
          partnerID,
          allow_vehicles: this.props.auth.profile.vehicle_visibility,
        }),
      },
    )
      .then(response => response.json())
      .then(res => {
        self.setState({ vehicleLists: res.data, loading: false })
      })
  }

  loadVendor = e => {
    let self = this
    this.setState({ loading: true })
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvendor`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
        is_gps_vendor: this.props.auth.profile.is_gps_vendor,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ vendor: res.data, loading: false })
      })
      .catch(e => this.setState({ loading: false }))
  }

  loadVehicleWithVendor = (vehicletypeID, vendor) => {
    let self = this

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        vendor_id: vendor,
        companyID: this.props.auth.profile.company_id,
        vehicletypeID: vehicletypeID,
        allow_vehicles: this.props.auth.profile.vehicle_visibility,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({
          vehicle_vendor: res.data,
          check_load_vehicle: true,
        })
      })
  }

  loadVehicleGroup = () => {
    let self = this
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ vehicle_group: res.data })
      })
  }

  onCriteriaChange = (vehicletypeID, vendor) =>
    this.loadVehicleWithVendor(vehicletypeID, vendor)

  loadVehicle = (vehicletypeID, loading) => {
    this.setState({ loading })
    let self = this

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        companyID: this.props.auth.profile.company_id,
        vehicletypeID: vehicletypeID,
        allow_vehicles: this.props.auth.profile.vehicle_visibility,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ vehicleLists: res.data, loading: false })
      })
  }

  loadVehicleType = () => {
    let self = this

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ vehicleTypeLists: res.data })
      })
  }

  loadGpsVendor = async () => {
    await fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgps-vendor`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(res => {
        this.setState({ GPSVendorLists: res.data })
      })
  }

  loadvehicleGpsVendor = async val => {
    this.setState({
      loading: true,
    })
    const vehicle_visibility = getlocalstorage(
      localStorage.getItem('profile'),
      'vehicle_visibility',
    )
    await fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclegpsvendor`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          companyID: this.props.auth.profile.company_id,
          vendor_id: val,
          allow_vehicles: vehicle_visibility,
        }),
      },
    )
      .then(response => response.json())
      .then(res => {
        this.setState({ vehicleGPSVendorLists: res.data, loading: false })
      })
  }

  loadPlant = () => {
    let self = this
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplant`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
        location_visibility: this.props.auth.profile.location_visibility,
        relate_company: `${this.props.auth.profile.relate_company}`,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ plantLists: res.data })
      })
  }

  loadDivision = cb => {
    this.setState({ loading: true })

    let self = this
    let divisionVisibility = []

    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      divisionVisibility = orgChart.divisions.map(ele => ele.divisionNo)
    }
    this.setState({ divisionLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        division_visibility: divisionVisibility,
        comp: [2, 3, 4, 5, 6],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            divisionLoading: false,
            division: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadDepartment = cb => {
    this.setState({ loading: true })
    let self = this
    let departmentVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      departmentVisibility = orgChart.departments.map(ele => ele)
    }
    this.setState({ departmentLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        department_visibility: departmentVisibility,
        division: [this.state.selectedDivision],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            departmentLoading: false,
            department: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadSection = cb => {
    this.setState({ loading: true })
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    this.setState({ sectionLoading: true })

    let departmentID = [this.state.selectedDepartment.toString()]

    if (this.state.selectedDepartment === 'all') {
      departmentID = this.state.department.map(x => x.id.toString())
    }

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        section_visibility: sectionVisibility,
        department: departmentID,
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            sectionLoading: false,
            section: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadPlant = cb => {
    this.setState({ loading: true })
    let self = this
    let plantVisibility = []
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    plantVisibility = self.props.auth.profile.location_visibility
    this.setState({ plantLoading: true })

    let sectionID = [this.state.selectedSection.toString()]

    if (this.state.selectedSection === 'all') {
      sectionID = this.state.section.map(x => x.id.toString())
    }

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantforconcretedashboard`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          location_visibility: plantVisibility,
          section_visibility: sectionVisibility,
          section: sectionID,
          relate_company: `${this.props.auth.profile.relate_company}`,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            plant: data,
            plantLoading: false,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadVehicleGroup = cb => {
    this.setState({ loading: true })

    let self = this

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            loading: false,
            vehicleGroup: data.data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadVehicles = async cb => {
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }

    await fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclesbydivisionbranch`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          vehicle_group: this.state.selectedVehicleGroup,
          section_visibility: sectionVisibility,
          partner: this.state.selectedPartner,
          division: this.state.selectedDivision,
          department: this.state.selectedDepartment,
          section: this.state.selectedSection,
          plant: this.state.selectedPlant,
          vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        const vehlist = []
        data.data.map(item =>
          vehlist.push({
            key: item.id,
            name: item.plate_no,
          }),
        )
        self.setState(
          {
            loading: false,
            vehicles: data.data,
            vehicleList: vehlist,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadVehicleByPlantID = async val => {
    let self = this
    const ACCESSTOKEN = localStorage.getItem('access-token')
    const COMPANY_ID = getlocalstorage(
      localStorage.getItem('profile'),
      'company_id',
    )

    const vehicle_visibility = getlocalstorage(
      localStorage.getItem('profile'),
      'vehicle_visibility',
    )

    await fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebyplantid`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
        body: JSON.stringify({
          companyID: COMPANY_ID,
          plant_id: val,
          allow_vehicles: vehicle_visibility,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState({
          loading: false,
          vehicleLists: data.data,
        })
      })
  }

  loadPlantIsNotBusiness = async () => {
    let self = this
    const ACCESSTOKEN = localStorage.getItem('access-token')
    const COMPANY_ID = getlocalstorage(
      localStorage.getItem('profile'),
      'company_id',
    )
    const vehicle_visibility = getlocalstorage(
      localStorage.getItem('profile'),
      'vehicle_visibility',
    )

    const location_visibility = getlocalstorage(
      localStorage.getItem('profile'),
      'location_visibility',
    )

    await fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantbyvehicleid`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
        body: JSON.stringify({
          company_id: COMPANY_ID,
          vehicle_visibility: vehicle_visibility,
          location_visibility: location_visibility,
          relate_company: `${this.props.auth.profile.relate_company}`,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState({
          loading: false,
          plantLists: data.data,
        })
      })
  }

  loadPartnerSafetyEvent = cb => {
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpartnersafetydashboard`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          company_id: self.props.auth.profile.company_id,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            partnerSafetyEvent: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  updateSelectDivisoin = e =>
    this.setState(
      {
        selectedDivision: e,
        selectedDepartment: 'all',
        selectedSection: 'all',
        selectedPlant: ['all'],
      },
      e => {
        this.loadDepartment(() =>
          this.loadSection(() => this.loadPlant(() => this.loadVehicles())),
        )
      },
    )

  updateSelectDepartment = (e, values) => {
    this.setState(
      {
        selectedDepartment: e,
        selectedSection: 'all',
        selectedPlant: ['all'],
        eventdate: [
          moment(values.eventdate[0]).format('YYYY-MM-DD 00:00:00'),
          moment(values.eventdate[1]).format('YYYY-MM-DD 23:59:59'),
        ],
      },
      e => {
        this.loadSection(() => this.loadPlant(() => this.loadVehicles()))
      },
    )
  }

  updateSelectPlant = (e, values) =>
    this.setState(
      {
        selectedPlant: e,
        eventdate: [
          moment(values.eventdate[0]).format('YYYY-MM-DD 00:00:00'),
          moment(values.eventdate[1]).format('YYYY-MM-DD 23:59:59'),
        ],
      },
      e => {
        this.loadVehicles()
      },
    )

  updateSelectSection = (e, values) =>
    this.setState(
      {
        selectedSection: e,
        selectedPlant: ['all'],
        eventdate: [
          moment(values.eventdate[0]).format('YYYY-MM-DD 00:00:00'),
          moment(values.eventdate[1]).format('YYYY-MM-DD 23:59:59'),
        ],
      },
      e => {
        this.loadPlant(() => this.loadVehicles())
      },
    )

  updateSelectVehicleGroup = (e, values) =>
    this.setState(
      {
        selectedVehicleGroup: e,
        eventdate: [
          moment(values.eventdate[0]).format('YYYY-MM-DD 00:00:00'),
          moment(values.eventdate[1]).format('YYYY-MM-DD 23:59:59'),
        ],
      },
      e => {
        this.loadVehicles()
      },
    )

  updateSelectPartner = e => {
    this.setState({ selectedPartner: e }, e => {
      this.loadVehicles()
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = e => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {
    this.loadVendor()
    this.loadvehicleGpsVendor('')
    this.loadGpsVendor()
    this.loadPartnerSafetyEvent()
    this.props.loadReportSchedulereport()
    let days = []
    for (let i = 1; i <= 28; i++) {
      //เอา max สุดเดือนกุมภา ที่เป็นไปได้
      days.push(i)
    }
    this.setState({ days })

    this.loadVehicleGroup()
    this.loadDivision(() =>
      this.loadDepartment(() =>
        this.loadSection(() => this.loadPlant(() => this.loadVehicles())),
      ),
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { schedulereport } = nextProps
    const { dataSchedulereport } = schedulereport

    if (
      this.props.schedulereport.check_schedulereport !==
      nextProps.schedulereport.check_schedulereport
    ) {
      this.setState({
        list_report_schedules_id: dataSchedulereport.list_report_schedules_id,
        schedule_type_id: dataSchedulereport.schedule_type,
        schedule_type: dataSchedulereport.schedule_type,
        attachment_type: dataSchedulereport.attachment_type,
        email_subject: dataSchedulereport.email_subject,
        to_email: dataSchedulereport.to_email,
        cc_email:
          dataSchedulereport.cc_email === null
            ? ''
            : dataSchedulereport.cc_email,
        body_email: dataSchedulereport.body_email,
        day:
          dataSchedulereport.day == 0 || dataSchedulereport.day == null
            ? '1'
            : dataSchedulereport.day.toString(),
        week:
          dataSchedulereport.week == 0 || dataSchedulereport.week == null
            ? '1'
            : dataSchedulereport.week.toString(),
        month:
          dataSchedulereport.month == 0 || dataSchedulereport.month == null
            ? '1'
            : dataSchedulereport.month.toString(),
        time: moment(dataSchedulereport.time, format),
        start_time: moment(dataSchedulereport.start_time, format),
        end_time: moment(dataSchedulereport.end_time, format),
        previous_data: dataSchedulereport.previous_data,
        filter_report: dataSchedulereport.filter,
        matching_name: dataSchedulereport.matching_name,
      })
    }

    if (this.state.vehicles.length != nextState.vehicles.length) {
      return true
    }

    return true
  }

  onReportTypeChange = (value, option, setFieldValue) => {
    setFieldValue('list_report_schedules_id', value)
    this.setState({
      matching_name: option.props.matching_name,
      filter_report: '',
    })
    if (value !== '') {
      this.setState(
        {
          vehicles: this.state.vehicles,
        },
        () => this.showModal(),
      )
    }
  }

  onScheduleTypeChange = (value, setFieldValue) => {
    setFieldValue('schedule_type_id', value)
    this.setState({ schedule_type: value })
  }

  onSubmit = (values, loading) => {
    const user_id = getlocalstorage(localStorage.getItem('profile'), 'id')
    let event_name = undefined
    let event = undefined
    let find_address_new = undefined

    const {
      list_vehicle_id,
      vehicle_type_id,
      driver_name,
      driver_code,
      op_of_amount_date,
      amount_date,
      distance_more_than,
      list_part_type_id,
      event_id,
      event_type,
      find_address,
      vehicle_code,
      plate_no,
      group_idle,
      group_speed,
      vehicle_id,
      job_status,
      vendor,
      duration,
      vendor_list,
      sortBy,
      partner_list,
      alarmalert_type_id,
      end_date,
      start_date,
      date_end,
      date_start,
      list_plant_id,
      search_by,
      selectedSite,
      selectedSiteName,
    } = values
    if (event_id !== undefined) {
      event_name = event_id.event_id
      event = event_id.key
    }
    if (find_address !== undefined && find_address !== '') {
      find_address_new = find_address.target.checked
    }
    let json_obj = JSON.stringify({
      list_vehicle_id,
      company_id: this.props.auth.profile.company_id,
      vehicle_type_id,
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
      user_id,
      driver_name,
      driver_code,
      op_of_amount_date,
      amount_date,
      distance_more_than,
      list_part_type_id,
      event_name,
      event,
      event_type,
      find_address: find_address_new,
      vehicle_code,
      plate_no,
      group_idle,
      group_speed,
      vehicle_id,
      job_status,
      vendor,
      duration,
      vendor_list,
      sort_by: sortBy,
      partner_list,
      alarmalert_type_id,
      end_date,
      start_date,
      date_end,
      date_start,
      list_plant_id,
      search_by,
      selectedSite,
      selectedSiteName,
    })
    this.setState({ filter_report: json_obj, visible: false })
  }

  handleOnsubmitUnautorizeUnload = async values => {
    let tplant = 'ทั้งหมด'

    if (!values.plant.includes('all')) {
      tplant = values.plant
        .map(item =>
          getFilterName({ plantLists: this.state.plant }, 'plantLists', item),
        )
        .join(',')
    }

    const {
      profile: { company_id },
    } = this.props.auth
    let submitValue = {
      division_name: getFilterName(
        { divisionLists: this.state.division },
        'divisionLists',
        values.division,
      ),
      department_name: getFilterName(
        { departmentLists: this.state.department },
        'departmentLists',
        values.department,
      ),
      section_name: getFilterName(
        { sectionLists: this.state.section },
        'sectionLists',
        values.section,
      ),
      plant_name: tplant,
      date_start: values.date_start,
      date_end: values.date_end,
      company_id: company_id,
      vehicle_list: getVehicleFilter(
        this.props.auth.profile.vehicle_visibility,
        values.list_vehicle_id,
      ),
      file_type: 'excel',
    }
    this.setState({
      filter_report: JSON.stringify(submitValue),
      visible: false,
    })
  }

  handleSummitSummaryVehicleUsage = values => {
    let json_obj = JSON.stringify({
      date_start: values.start_date,
      date_end: values.end_date,
      list_vehicle_id: values.list_vehicle_id,
      company_id: this.props.auth.profile.company_id,
      user_id: this.props.auth.profile.id,
      type_file: 'excel',
    })
    this.setState({ filter_report: json_obj, visible: false })
  }

  onSubmitAlertDriverLicense = values => {
    const { list_driver_id, alert_type_id } = values
    let json_obj = JSON.stringify({
      list_driver_id,
      company_id: this.props.auth.profile.company_id,
      alert_type_id,
      type_file: 'excel',
    })
    this.setState({ filter_report: json_obj, visible: false })
  }

  onSubmitScheduleReport = obj => {
    this.setState({ filter_report: JSON.stringify(obj), visible: false })
  }

  validateEmail = value => {
    let warning
    if (!value) {
      warning = i18n[this.props.auth.lang]['e']['email_require']
    } else {
      let emails = value.value.split(',')
      let i
      for (i = 0; i < emails.length; i++) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emails[i])) {
          warning = i18n[this.props.auth.lang]['e']['email_invalid']
          break
        }
      }
    }
    return warning
  }

  validateEmailCC = value => {
    let warning

    if (value) {
      let emails = value.value.split(',')
      let i
      for (i = 0; i < emails.length; i++) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emails[i])) {
          warning = i18n[this.props.auth.lang]['e']['email_invalid']
          break
        }
      }
    }

    return warning
  }

  handleChangeState = (type, val) => {
    switch (type) {
      case 'division':
        this.setState({ ...initialState, selectedDivision: val }, () => {
          this.loadDepartment()
          this.loadSection()
          this.loadPlant(() => this.loadVehicles())
        })
        break
      case 'department':
        this.setState(
          {
            selectedDepartment: val,
            selectedSection: 'all',
            selectedPlant: ['all'],
          },
          () => {
            this.loadSection()
            this.loadPlant(() => this.loadVehicles())
          },
        )
        break
      case 'section':
        this.setState(
          {
            selectedSection: val,
            selectedPlant: ['all'],
          },
          () => {
            this.loadPlant(() => this.loadVehicles())
          },
        )
        break
      case 'plant':
        this.setState({ selectedPlant: val }, () => {
          this.loadVehicles()
        })
        break
      case 'vehiclegroup':
        this.setState({ selectedVehicleGroup: val }, () => {
          this.loadVehicles()
        })

        break
      default:
        break
    }
  }

  updateSelectDivision = (e, values) =>
    this.setState(
      {
        selectedDivision: e,
        selectedDepartment: 'all',
        selectedSection: 'all',
        selectedPlant: ['all'],
        eventdate: [
          moment(values.eventdate[0]).format('YYYY-MM-DD 00:00:00'),
          moment(values.eventdate[1]).format('YYYY-MM-DD 23:59:59'),
        ],
      },
      e => {
        this.loadDepartment(() =>
          this.loadSection(() => this.loadPlant(() => this.loadVehicles())),
        )
      },
    )

  render() {
    const { schedulereport, onSubmit, id } = this.props
    const { schedulereportLoading, reportLists } = schedulereport

    // let reportLists = [
    //   {
    //     report_name: 'ทั่วไป >> ใบขับขี่หมดอายุ',
    //     id: 41,
    //     matching_name: 'theDriverIsIicenseReportHasExpired',
    //   },
    //   {
    //     report_name: 'พฤติกรรม >> สรุปความเร็วเกินกำหนด',
    //     id: 27,
    //     matching_name: 'SpeedoverlimitsummaryReport',
    //   },
    //   {
    //     report_name: 'พฤติกรรม >> สรุปสถานะพาหนะรายวัน',
    //     id: 26,
    //     matching_name: 'dailyCarStatusReport',
    //   },
    //   {
    //     report_name: 'ทั่วไป >> รายงานจุดจอดพาหนะ',
    //     id: 12,
    //     matching_name: 'ParkingReport',
    //   },
    //   {
    //     report_name: 'พฤติกรรม >> การรูดใบขับขี่',
    //     id: 24,
    //     matching_name: 'DrivinglicensescanlogReport',
    //   },
    //   {
    //     report_name: 'พฤติกรรม >> บันทึกการขับขี่ ',
    //     id: 25,
    //     matching_name: 'DrivinglogReport',
    //   },
    //   {
    //     report_name: 'ทั่วไป >> รายงานการใช้งานพาหนะ',
    //     id: 11,
    //     matching_name: 'TruckusageReport',
    //   },
    //   {
    //     report_name: 'ทั่วไป >> รายงานระยะทางการวิ่ง',
    //     id: 9,
    //     matching_name: 'DrivingdistanceReport',
    //   },
    //   {
    //     report_name: 'ทั่วไป >> รายงานจำนวนวันที่พาหนะวิ่งงาน',
    //     id: 5,
    //     matching_name: 'NumberofdaysworkedReport',
    //   },
    //   {
    //     report_name: 'ประสิทธิภาพ >> พาหนะติดเครื่อง',
    //     id: 33,
    //     matching_name: 'TruckengineonReport',
    //   },
    //   {
    //     report_name: 'ประสิทธิภาพ >> สรุปประสิทธิภาพพาหนะรายคัน',
    //     id: 34,
    //     matching_name: 'VehicleperformanceconcludeReport',
    //   },
    //   {
    //     report_name: 'Alert เเละ Alarm >> รูปแบบการสรุปคะแนน',
    //     id: 31,
    //     matching_name: 'ScoresummaryReport',
    //   },
    //   {
    //     report_name:
    //       'Alert เเละ Alarm >> สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง',
    //     id: 29,
    //     matching_name: 'BehaviordrivingReport',
    //   },
    //   {
    //     report_name:
    //       'Alert เเละ Alarm >> สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง แต่ละผู้รับเหมา',
    //     id: 30,
    //     matching_name: 'BehaviordrivingbycompanyReport',
    //   },
    //   {
    //     report_name: 'ค่าใช้จ่าย >> สรุปค่าบริการ GPS ทั้งหมด',
    //     id: 17,
    //     matching_name: 'gpsVendorPayingSummaryReport',
    //   },
    //   {
    //     report_name: 'ค่าใช้จ่าย >> สรุปเรียกเก็บเงินตามผู้รับเหมา',
    //     id: 18,
    //     matching_name: 'partnerPerformReport',
    //   },
    //   {
    //     report_name: 'การจัดการอุปกรณ์ >> การแจ้งซ่อม',
    //     id: 16,
    //     matching_name: 'repairingReport',
    //   },
    //   {
    //     report_name: 'ค่าใช้จ่าย >> สรุปค่าบริการตาม GPS Vendor',
    //     id: 19,
    //     matching_name: 'gpsVendorPayingReport',
    //   },
    //   {
    //     report_name: 'Alert เเละ Alarm >> ความปลอดภัยพาหนะ',
    //     id: 32,
    //     matching_name: 'SafetyEventReport',
    //   },
    //   {
    //     report_name: 'พลังงาน >> น้ำมันคงเหลือ',
    //     id: 37,
    //     matching_name: 'fuelremainrateReport',
    //   },
    //   {
    //     report_name: 'พฤติกรรม >> การเดินทางแยกประเภทตามการเปิด-ปิดเครื่องยนต์',
    //     id: 28,
    //     matching_name: 'TripbyengineoffonReport',
    //   },
    //   {
    //     report_name: 'ทริป >> การส่งคอนกรีต',
    //     id: 20,
    //     matching_name: 'concreteTrip',
    //   },
    //   {
    //     report_name: 'ประสิทธิภาพ >> ภาพรวมสรุปการขับขี่รายคัน',
    //     id: 35,
    //     matching_name: 'drivingSummary',
    //   },
    //   {
    //     report_name: 'การจัดการอุปกรณ์ >> GPS ขาดการติดต่อ',
    //     id: 39,
    //     matching_name: 'gpsReportLostContact',
    //   },
    //   {
    //     report_name: 'พลังงาน >> การคำนวณปริมาณการใช้น้ำมันของพาหนะ',
    //     id: 38,
    //     matching_name: 'FueluseReport',
    //   },
    //   {
    //     report_name: 'การจัดการอุปกรณ์ >> GPS ขาดการติดต่อขณะวิ่งงาน',
    //     id: 40,
    //     matching_name: 'gpsDisconnectOnTrip',
    //   },
    //   {
    //     report_name: 'ทริป >> ระยะทางการจัดส่งคอนกรีต',
    //     id: 36,
    //     matching_name: 'distancereport',
    //   },
    //   {
    //     report_name: 'ทริป >> รายงานเทปูนนอกจุด',
    //     id: 21,
    //     matching_name: 'unauthorizeunload',
    //   },
    //   {
    //     report_name: 'ทั่วไป >> รายงานสรุปการใช้พาหนะ',
    //     id: 22,
    //     matching_name: 'summaryFuelUsage',
    //   },
    // ]

    return (
      <Spin spinning={schedulereportLoading}>
        <Row>
          <StyledSearchForm>
            <Modal
              title={
                <LangContext.Consumer>
                  {i18n => i18n.s.setting_filter}
                </LangContext.Consumer>
              }
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              cancelButtonProps={{ style: { display: 'none' } }}
              okButtonProps={{ style: { display: 'none' } }}
              width={800}
            >
              {/* {JSON.stringify(this.state.filter_report)} */}
              {this.state.matching_name === 'gpsDisconnectOnTrip' ? (
                <GpsDisconnectOnTrip
                  onSubmit={val => {
                    let vendor = 'all'
                    if (val.gps_vendor_id != '') vendor = val.gps_vendor_id
                    this.onSubmitScheduleReport({
                      start_date: moment(val.eventdate[0]['_d']).format(
                        'YYYY-MM-DD HH:mm:00',
                      ),
                      end_date: moment(val.eventdate[1]['_d']).format(
                        'YYYY-MM-DD HH:mm:59',
                      ),
                      vendor_id: vendor.toString(),
                      vehicles: val.list_vehicle_id,
                    })
                  }}
                  GPSVendorLists={this.state.vendor}
                  loadVendor={this.loadVendor}
                  dataedit={this.state.filter_report}
                  loading={this.state.loading}
                  loadVehicle={this.loadvehicleGpsVendor}
                  vehicleLists={this.state.vehicleGPSVendorLists}
                  eventdate={this.state.eventdate}
                  call="schedule"
                  auth={this.props.auth}
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'gpsReportLostContact' ? (
                <GpsReportLostContact
                  onSubmit={val => {
                    this.onSubmitScheduleReport({
                      vehicle_list: val.list_vehicle_id,
                      company_id: this.props.auth.profile.company_id,
                      vehicle_visibility: this.props.auth.profile
                        .vehicle_visibility,
                      type_file: 'excel',
                    })
                  }}
                  vehicleLists={this.state.vehicleGPSVendorLists}
                  GPSVendorLists={this.state.GPSVendorLists}
                  loading={this.state.loading}
                  loadVehicle={this.loadvehicleGpsVendor}
                  loadGpsVendor={this.loadGpsVendor}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'FueluseReport' ? (
                <FueluseReport
                  onSubmit={val => {
                    const { list_vehicle_id, vehicle_type_id, eventdate } = val
                    const vehicle_visibility = getlocalstorage(
                      localStorage.getItem('profile'),
                      'vehicle_visibility',
                    )
                    const date_start =
                      moment(eventdate[0]['_d']).format('YYYY-MM-DD') +
                      ' 00:00:00'
                    const date_end =
                      moment(eventdate[1]['_d']).format('YYYY-MM-DD') +
                      ' 23:59:00'
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )
                    const USER_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'id',
                    )

                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      vehicle_type_id: vehicle_type_id.toString(),
                      user_id: USER_ID,
                      vehicle_visibility,
                      type_file: 'excel',
                    })
                  }}
                  fueluseLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'fuelremainrateReport' ? (
                <FuelremainrateReport
                  onSubmit={val => {
                    const { list_vehicle_id, vehicle_type_id, eventdate } = val

                    const date_start =
                      moment(eventdate[0]['_d']).format('YYYY-MM-DD') +
                      ' 00:00:00'
                    const date_end =
                      moment(eventdate[1]['_d']).format('YYYY-MM-DD') +
                      ' 23:59:00'
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )

                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      vehicle_type_id: `${vehicle_type_id}`,
                      type_file: 'excel',
                    })
                  }}
                  fuelremainrateLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'distancereport' ? (
                <Distancereport
                  auth={this.props.auth}
                  onSubmit={values => {
                    this.onSubmitScheduleReport(values)
                  }}
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'drivingSummary' ? (
                <DrivingSummary
                  onSubmit={values => {
                    this.onSubmitScheduleReport({
                      search_by: values.search_by,
                      search_value:
                        values.search_by == 'vehicle_group'
                          ? values.vehicle_type_name
                          : values.search_by == 'plant'
                          ? values.plant_name
                          : values.business_name,
                      start_date: values.start_date,
                      end_date: values.end_date,
                      vehicle_group: values.vehicle_type_name,
                      vehicle_list: values.list_vehicle_id,
                      company_id: this.props.auth.profile.company_id,
                      vehicle_visibility: this.props.auth.profile
                        .vehicle_visibility,
                      type_file: 'excel',
                    })
                  }}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  plantLists={this.state.plantLists}
                  loading={this.state.loading}
                  loadVehicleIsNotBusiness={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  loadPlantIsNotBusiness={this.loadPlantIsNotBusiness}
                  loadVehicleByPlantID={this.loadVehicleByPlantID}
                  // new obj
                  businessLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name ===
              'VehicleperformanceconcludeReport' ? (
                <VehicleperformanceconcludeReport
                  onSubmit={val => {
                    const { list_vehicle_id, vehicle_type_id, eventdate } = val

                    const date_start =
                      moment(eventdate[0]['_d']).format('YYYY-MM-DD') +
                      ' 00:00:00'
                    const date_end =
                      moment(eventdate[1]['_d']).format('YYYY-MM-DD') +
                      ' 23:59:00'
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )

                    const USER_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'id',
                    )

                    const vehicle_visibility = getlocalstorage(
                      localStorage.getItem('profile'),
                      'vehicle_visibility',
                    )

                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      vehicle_type_id: `${vehicle_type_id}`,
                      user_id: USER_ID,
                      vehicle_visibility,
                      type_file: 'excel',
                    })
                  }}
                  //new filter
                  vehicleperformanceconcludeLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                  //end new filter
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'TruckengineonReport' ? (
                <TruckengineonReport
                  onSubmit={val => {
                    const {
                      list_vehicle_id,
                      vehicle_type_id,
                      eventdate,
                      engine_on_time_start_at,
                      engine_on_time_end_at,
                    } = val

                    const date_start =
                      moment(eventdate[0]['_d']).format('YYYY-MM-DD') +
                      ' 00:00:00'
                    const date_end =
                      moment(eventdate[1]['_d']).format('YYYY-MM-DD') +
                      ' 23:59:00'
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )

                    const USER_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'id',
                    )

                    const vehicle_visibility = getlocalstorage(
                      localStorage.getItem('profile'),
                      'vehicle_visibility',
                    )

                    const engine_on_time_start_at_new = moment(
                      engine_on_time_start_at,
                    ).format('HH:mm')
                    const engine_on_time_end_at_new = moment(
                      engine_on_time_end_at,
                    ).format('HH:mm')

                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      vehicle_type_id,
                      user_id: USER_ID,
                      vehicle_visibility,
                      engine_on_time_start_at: engine_on_time_start_at_new,
                      engine_on_time_end_at: engine_on_time_end_at_new,
                      max_speed: 0,
                      type_file: 'excel',
                    })
                  }}
                  truckengineonLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'SafetyEventReport' ? (
                <SafetyEventReport
                  onSubmit={val => {
                    const {
                      vendor_name,
                      list_vehicle_id,
                      vehicle_type_id,
                      eventdate,
                      event,
                      only_dp,
                      partner,
                      division,
                      department,
                      section,
                      plant,
                    } = val
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )

                    const date_start = moment(eventdate[0]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const date_end = moment(eventdate[1]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const USER_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'id',
                    )

                    const event_name = ''
                    this.onSubmitScheduleReport({
                      vendor_name,
                      date_start,
                      date_end,
                      list_vehicle_id: [],
                      company_id: COMPANY_ID,
                      vehicle_type_id,
                      user_id: USER_ID,
                      event,
                      event_name,
                      vehicle_visibility: list_vehicle_id.join(','),
                      division: division.toString(),
                      department: department.toString(),
                      section: section.toString(),
                      partner: partner.toString(),
                      plant,
                      only_dp,
                      type_file: 'excel',
                    })
                  }}
                  loading={this.state.loading}
                  initialEvent={[
                    { id: 1001, name: 'ความเร็วเกินกำหนด' },
                    { id: 7002, name: 'ขับต่อเนื่อง > 4 ชม.' },
                    { id: 700201, name: 'ปฏิบัติงานมากกว่า 10 ชม.' },
                    { id: 1002, name: 'ออกตัวกระชาก/เบรคกระทันหัน' },
                    //   { id: 2051, name: 'เลี้ยวกระทันหัน' },
                    { id: 3000, name: 'จุดนอกจุดจอด' },
                  ]}
                  vehicles={this.state.vehicles}
                  vehicleGroup={this.state.vehicleGroup}
                  partner={this.state.partnerSafetyEvent}
                  division={this.state.division}
                  department={this.state.department}
                  section={this.state.section}
                  plant={this.state.plant}
                  loadPartner={this.loadPartnerSafetyEvent}
                  loadDivision={this.loadDivision}
                  loadDepartment={this.loadDepartment}
                  loadSection={this.loadSection}
                  selectedPartner={this.state.selectedPartner}
                  selectedDivision={this.state.selectedDivision}
                  selectedDepartment={this.state.selectedDepartment}
                  selectedSection={this.state.selectedSection}
                  selectedPlant={this.state.selectedPlant}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectDivisoin={this.updateSelectDivisoin}
                  updateSelectDepartment={this.updateSelectDepartment}
                  updateSelectSection={this.updateSelectSection}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  updateSelectPartner={this.updateSelectPartner}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.profile}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'ScoresummaryReport' ? (
                <ScoresummaryReport
                  onSubmit={val => {
                    const { list_vehicle_id, eventdate } = val
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )

                    const date_start = moment(eventdate[0]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const date_end = moment(eventdate[1]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      type_file: 'excel',
                    })
                  }}
                  scoresummaryLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'BehaviordrivingbycompanyReport' ? (
                <BehaviordrivingbycompanyReport
                  onSubmit={val => {
                    const { list_vehicle_id, list_partner_id, eventdate } = val
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )

                    const date_start = moment(eventdate[0]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const date_end = moment(eventdate[1]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      partner_id: list_partner_id,
                      company_id: COMPANY_ID,
                      type_file: 'excel',
                    })
                  }}
                  vehicleLists={this.state.vehicleLists}
                  behaviordrivingLoading={this.state.loading}
                  loadVehicle={this.loadVehicleBehaviorDrivingByCompany}
                  loadPartner={this.loadpartnerBehaviorDrivingByCompany}
                  companypartnerLists={this.state.partnerBehaviorDriving}
                  auth={this.props.profile}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'BehaviordrivingReport' ? (
                <BehaviordrivingReport
                  onSubmit={val => {
                    const { list_vehicle_id, eventdate } = val
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )
                    const date_start = moment(eventdate[0]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const date_end = moment(eventdate[1]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      type_file: 'excel',
                    })
                  }}
                  behaviordrivingLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'TripbyengineoffonReport' ? (
                <TripByEngineOffOnReport
                  onSubmit={val => {
                    const { list_vehicle_id, eventdate } = val
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )

                    const date_start = moment(eventdate[0]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const date_end = moment(eventdate[1]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )

                    const vehicle_visibility = getlocalstorage(
                      localStorage.getItem('profile'),
                      'vehicle_visibility',
                    )

                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      type_file: 'excel',
                      vehicle_visibility,
                    })
                  }}
                  tripbyengineoffonLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'SpeedoverlimitsummaryReport' ? (
                <SpeedOverLimitSummaryReport
                  onSubmit={val => {
                    const {
                      list_vehicle_id,
                      vehicle_type_id,
                      eventdate,
                      only_dp,
                    } = val
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )

                    const USER_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'id',
                    )

                    const date_start = moment(eventdate[0]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const date_end = moment(eventdate[1]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      vehicle_type_id: `${vehicle_type_id}`,
                      user_id: USER_ID,
                      only_dp,
                      type_file: 'excel',
                    })
                  }}
                  speedoverlimitsummaryLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'dailyCarStatusReport' ? (
                <DailyCarStatusReport
                  onSubmit={val => {
                    const {
                      list_vehicle_id,
                      vehicle_status_id,
                      vehicle_type_id,
                      eventdate,
                    } = val
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )

                    const date_start = moment(eventdate[0]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const date_end = moment(eventdate[1]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      vehicle_type_id: `${vehicle_type_id}`,
                      vehicle_status: vehicle_status_id,
                      type_file: 'excel',
                    })
                  }}
                  separatevehiclestatusLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'DrivinglogReport' ? (
                <DrivingLogReport
                  onSubmit={val => {
                    const {
                      list_vehicle_id,
                      vehicle_type_id,
                      eventdate,
                      vehicle_status,
                      only_dp,
                    } = val
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )
                    const USER_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'id',
                    )

                    const vehicle_visibility = getlocalstorage(
                      localStorage.getItem('profile'),
                      'vehicle_visibility',
                    )

                    const date_start = moment(eventdate[0]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const date_end = moment(eventdate[1]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      vehicle_type_id: vehicle_type_id.toString(),
                      user_id: USER_ID,
                      vehicle_status,
                      type_file: 'excel',
                      vehicle_visibility,
                      only_dp,
                    })
                  }}
                  drivinglogLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name === 'DrivinglicensescanlogReport' ? (
                <DrivingLicenseScanlogReport
                  onSubmit={val => {
                    const { list_vehicle_id, vehicle_type_id, eventdate } = val
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )
                    const USER_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'id',
                    )

                    const vehicle_visibility = getlocalstorage(
                      localStorage.getItem('profile'),
                      'vehicle_visibility',
                    )

                    const date_start = moment(eventdate[0]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const date_end = moment(eventdate[1]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      vehicle_type_id,
                      user_id: USER_ID,
                      type_file: 'excel',
                      vehicle_visibility,
                    })
                  }}
                  drivinglicensescanlogLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  auth={this.props.auth}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
              {this.state.matching_name ===
              'theDriverIsIicenseReportHasExpired' ? (
                <AlertDriverLicense
                  onSubmit={this.onSubmitAlertDriverLicense}
                  alertdriverlicenseLoading={this.state.loading}
                  dataedit={this.state.filter_report}
                  driverLists={this.props.driverLists}
                  loadDriver={this.props.loadDriver}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'summaryFuelUsage' ? (
                <SummaryVehicleUsage
                  onSubmit={this.handleSummitSummaryVehicleUsage}
                  loading={this.state.loading}
                  user_id={this.props.auth.profile.id}
                  company_id={this.props.auth.profile.company_id}
                  accessToken={this.props.accessToken}
                  vehicle_visibility={
                    this.props.auth.profile.vehicle_visibility
                  }
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDivisoin={this.updateSelectDivisoin}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectDepartment={this.updateSelectDepartment}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  vehicles={this.state.vehicles}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'unauthorizeunload' ? (
                <Unauthorizeunload
                  {...this.state}
                  onSubmit={this.handleOnsubmitUnautorizeUnload}
                  division={this.state.division}
                  department={this.state.department}
                  section={this.state.section}
                  plant={this.state.plant}
                  vehicleGroup={this.state.vehicleGroup}
                  vehicles={this.state.vehicles}
                  handleChangeState={this.handleChangeState}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'concreteTrip' ? (
                <RMCconcretetrip
                  onSubmit={values => {
                    let { list_vehicle_id, list_plant_id } = values
                    if (typeof list_plant_id != 'undefined' && list_plant_id)
                      list_plant_id = list_plant_id.filter(ele => ele != '')
                    this.onSubmitScheduleReport({
                      date_start: values.start_date,
                      date_end: values.end_date,
                      type: 'vehicle',
                      vehicle_list: list_vehicle_id,
                      plants_list: list_plant_id,
                      company_id: this.props.auth.profile.company_id,
                      vehicle_visibility: this.props.auth.profile
                        .vehicle_visibility,
                      site_id: values.selectedSite,
                      type_file: 'excel',
                    })
                  }}
                  vehicles={this.state.vehicles}
                  vehicleList={this.state.vehicleList}
                  vehicleGroup={this.state.vehicleGroup}
                  dataedit={this.state.filter_report}
                  company_id={this.props.auth.profile.company_id}
                  eventdate={this.state.eventdate}
                  call="schedule"
                  division={this.state.division}
                  department={this.state.department}
                  section={this.state.section}
                  plant={this.state.plant}
                  loadPartner={this.loadPartner}
                  loadDivision={this.loadDivision}
                  loadDepartment={this.loadDepartment}
                  loadSection={this.loadSection}
                  selectedPartner={this.state.selectedPartner}
                  selectedDivision={this.state.selectedDivision}
                  selectedDepartment={this.state.selectedDepartment}
                  selectedSection={this.state.selectedSection}
                  selectedPlant={this.state.selectedPlant}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectDivisoin={this.updateSelectDivisoin}
                  updateSelectDepartment={this.updateSelectDepartment}
                  updateSelectSection={this.updateSelectSection}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  updateSelectPartner={this.updateSelectPartner}
                  updateSelectPlant={this.updateSelectPlant}
                  loading={this.state.loading}
                  report={true}
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_dailyiconsiam' ? (
                <Dailyiconsiam
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  loading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_summaryiconsiam' ? (
                <Summaryiconsiam
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  loading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_truckcurrentlocation' ? (
                <Truckcurrentlocation
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  truckcurrentlocationLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_driverinformation' ? (
                <Driverinformation
                  onSubmit={this.onSubmit}
                  driverinformationLoading={this.state.loading}
                  dataedit={this.state.filter_report}
                  driverLists={this.props.driverLists}
                  loadDriver={this.props.loadDriver}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {/* {this.state.matching_name === 'report_numberofdaysworked' ? ( */}
              {this.state.matching_name === 'NumberofdaysworkedReport' ? (
                <Numberofdaysworked
                  onSubmit={this.onSubmit}
                  numberofdaysworkedLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_distfromlastmaintenance' ? (
                <Distfromlastmaintenance
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  distfromlastmaintenanceLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  loadPartType={this.props.loadPartType}
                  partTypeLists={this.props.partTypeLists}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_maintenance' ? (
                <Maintenance
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  maintenanceLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  loadPartType={this.props.loadPartType}
                  partTypeLists={this.props.partTypeLists}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_temperature' ? (
                <Temperature
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  temperatureLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'DrivingdistanceReport' ? (
                <Drivingdistance
                  onSubmit={val => {
                    const { list_vehicle_id, eventdate } = val

                    const date_start = moment(eventdate[0]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const date_end = moment(eventdate[1]['_d']).format(
                      'YYYY-MM-DD HH:mm',
                    )
                    const COMPANY_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'company_id',
                    )
                    const USER_ID = getlocalstorage(
                      localStorage.getItem('profile'),
                      'id',
                    )

                    this.onSubmitScheduleReport({
                      date_start,
                      date_end,
                      list_vehicle_id,
                      company_id: COMPANY_ID,
                      user_id: USER_ID,
                      type_file: 'excel',
                    })
                  }}
                  drivingdistanceLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_event' ? (
                <Event
                  onSubmit={this.onSubmit}
                  vehicleLists={this.props.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  eventLoading={this.props.eventLoading}
                  policyLists={this.props.policyLists}
                  eventLists={this.props.eventLists}
                  vehiclebygroupLists={this.props.vehiclebygroupLists}
                  vehiclebypolicyLists={this.props.vehiclebypolicyLists}
                  loadVehicle={this.props.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  loadPolicy={this.props.loadPolicy}
                  loadEvents={this.props.loadEvents}
                  loadVehiclebypolicy={this.props.loadVehiclebypolicy}
                  loadVehiclebygroupid={this.props.loadVehiclebygroupid}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'TruckusageReport' ? (
                <Truckusage
                  onSubmit={this.onSubmit}
                  truckusageLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'ParkingReport' ? (
                <Parking
                  onSubmit={this.onSubmit}
                  parkingLoading={this.state.loading}
                  vehicleGroup={this.state.vehicleGroup}
                  selectedVehicleGroup={this.state.selectedVehicleGroup}
                  updateSelectVehicleGroup={this.updateSelectVehicleGroup}
                  vehicleList={this.state.vehicleList}
                  division={this.state.division}
                  selectedDivision={this.state.selectedDivision}
                  updateSelectDivision={this.updateSelectDivision}
                  department={this.state.department}
                  selectedDepartment={this.state.selectedDepartment}
                  updateSelectDepartment={this.updateSelectDepartment}
                  section={this.state.section}
                  selectedSection={this.state.selectedSection}
                  updateSelectSection={this.updateSelectSection}
                  plant={this.state.plant}
                  selectedPlant={this.state.selectedPlant}
                  updateSelectPlant={this.updateSelectPlant}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_comparevehiclescco' ? (
                <Comparevehiclescco
                  onSubmit={this.onSubmit}
                  comparevehiclesccostatusLoading={false}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_drivinghour' ? (
                <Drivinghour
                  onSubmit={this.onSubmit}
                  vehicleLists={this.props.vehicleLists}
                  drivinghourLoading={false}
                  loadVehicle={this.props.loadVehicle}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'report_dozeoffrisk' ? (
                <Dozeoffrisk
                  onSubmit={this.onSubmit}
                  vehicleLists={this.state.vehicleLists}
                  vehicleTypeLists={this.state.vehicleTypeLists}
                  dozeoffriskLoading={this.state.loading}
                  loadVehicle={this.loadVehicle}
                  loadVehicleType={this.loadVehicleType}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'repairingReport' ? (
                <Repairingreport
                  onSubmit={this.onSubmit}
                  vendorList={this.state.vendor}
                  vehicleLists={this.state.vehicle_vendor}
                  vehicleGroupLists={this.state.vehicleGroup}
                  loadVendor={this.loadVendor}
                  loadVehicle={this.loadVehicleWithVendor}
                  loadVehicleGroup={this.loadVehicleGroup}
                  onCriteriaChange={this.onCriteriaChange}
                  check_load_vehicle={this.state.check_load_vehicle}
                  dataedit={this.state.filter_report}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'gpsVendorPayingReport' ? (
                <Gpsvendorpayingreport
                  onSubmit={this.onSubmit}
                  vendorLists={this.state.vendor}
                  loadVendor={this.loadVendor}
                  dataedit={this.state.filter_report}
                  loading={this.state.loading}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'gpsVendorPayingSummaryReport' ? (
                <Gpsvendorpayingsummaryreport
                  onSubmit={this.onSubmit}
                  vendorLists={this.state.vendor}
                  loadVendor={this.loadVendor}
                  dataedit={this.state.filter_report}
                  loading={this.state.loading}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}

              {this.state.matching_name === 'partnerPerformReport' ? (
                <Partnerperformreport
                  onSubmit={this.onSubmit}
                  partnerLists={this.state.partner}
                  loadPartner={this.loadPartner}
                  dataedit={this.state.filter_report}
                  loading={this.state.loading}
                  eventdate={this.state.eventdate}
                  call="schedule"
                />
              ) : (
                ''
              )}
            </Modal>
            <Formik
              enableReinitialize={true}
              initialValues={{
                list_report_schedules_id: this.state.list_report_schedules_id,
                schedule_type_id: this.state.schedule_type_id,
                schedule_type: this.state.schedule_type_id,
                attachment_type: this.state.attachment_type,
                email_subject: this.state.email_subject,
                to_email: this.state.to_email,
                cc_email: this.state.cc_email,
                day: this.state.day,
                week: this.state.week,
                month: this.state.month,
                time: this.state.time,
                start_time: this.state.start_time,
                end_time: this.state.end_time,
                previous_data: this.state.previous_data,
                body_email: this.state.body_email,
              }}
              validate={values => {
                let errors = {}

                // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.to_email)) {
                //   errors.email = 'Invalid email address';
                // }

                return errors
              }}
              validationSchema={yup.object().shape({
                list_report_schedules_id: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.p.pleaseChooseReport}
                    </LangContext.Consumer>,
                  ),
                email_subject: yup
                  .string()
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.e.email_subject_require}
                    </LangContext.Consumer>,
                  ),
                to_email: yup
                  .string()
                  .email(this.validateEmail)
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.e.email_require}
                    </LangContext.Consumer>,
                  ),
                cc_email: yup.string().email(this.validateEmailCC),
              })}
              onSubmit={(values, { resetForm }) => {
                if (this.state.filter_report !== '') {
                  values.filter_report = this.state.filter_report
                  const loading = true
                  onSubmit(values, loading)
                } else {
                  Modal.info({
                    content: (
                      <LangContext.Consumer>
                        {i18n => i18n.a.alert_setting_filter}
                      </LangContext.Consumer>
                    ),
                    onOk() {},
                  })
                }
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
                resetForm,
              }) => (
                <Row>
                  <LangContext.Consumer>
                    {i18n => (
                      <Form onSubmit={handleSubmit}>
                        <FormDivider>
                          <div className="semi-bold-16">
                            <LangContext.Consumer>
                              {i18n => i18n.e.email}
                            </LangContext.Consumer>
                          </div>
                        </FormDivider>
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <Form.Item
                              label={
                                <LangContext.Consumer>
                                  {i18n => i18n.e.email_subject}
                                </LangContext.Consumer>
                              }
                              //  style={{ marginBottom: "0px" }}
                              required={true}
                              validateStatus={
                                touched.email_subject &&
                                errors.email_subject &&
                                'warning'
                              }
                              help={
                                touched.email_subject && errors.email_subject
                              }
                            >
                              <Input
                                type="Text"
                                name="email_subject"
                                autoComplete="off"
                                value={values.email_subject}
                                onChange={e => {
                                  setFieldValue('email_subject', e.target.value)
                                }}
                                onBlur={handleBlur}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              required="true"
                              label={
                                <LangContext.Consumer>
                                  {i18n => i18n.t.to_email}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.to_email && errors.to_email && 'warning'
                              }
                              help={touched.to_email && errors.to_email}
                            >
                              <TextArea
                                placeholder="info@gmail.com,test@gmail.com"
                                name="to_email"
                                rows={6}
                                onChange={value =>
                                  setFieldValue('to_email', value.target.value)
                                }
                                value={values.to_email}
                              />
                            </FormItem>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              // required="false"
                              label={
                                <LangContext.Consumer>
                                  {i18n => i18n.c.cc_email}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.cc_email && errors.cc_email && 'warning'
                              }
                              help={touched.cc_email && errors.cc_email}
                            >
                              <TextArea
                                placeholder="info@gmail.com,test@gmail.com"
                                name="cc_email"
                                rows={6}
                                onChange={value =>
                                  setFieldValue('cc_email', value.target.value)
                                }
                                value={values.cc_email}
                              />
                            </FormItem>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              //required="true"
                              label={
                                <LangContext.Consumer>
                                  {i18n => i18n.b.body_email}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.body_email &&
                                errors.body_email &&
                                'warning'
                              }
                              help={touched.body_email && errors.body_email}
                            >
                              <TextArea
                                name="body_email"
                                rows={4}
                                onChange={value =>
                                  setFieldValue(
                                    'body_email',
                                    value.target.value,
                                  )
                                }
                                value={values.body_email}
                              />
                            </FormItem>
                          </Col>
                        </Row>

                        <FormDivider>
                          <div className="semi-bold-16">
                            <LangContext.Consumer>
                              {i18n => i18n.r.report}
                            </LangContext.Consumer>
                          </div>
                        </FormDivider>

                        <Row gutter={24}>
                          <Col sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              label={
                                <LangContext.Consumer>
                                  {i18n => i18n.r.report}
                                </LangContext.Consumer>
                              }
                              required={true}
                              // style={{ paddingBottom: "5px" }}
                              validateStatus={
                                touched.list_report_schedules_id &&
                                errors.list_report_schedules_id &&
                                'warning'
                              }
                              help={
                                touched.list_report_schedules_id &&
                                errors.list_report_schedules_id
                              }
                            >
                              <InputGroup compact>
                                <Select
                                  // onChange={value => setFieldValue("list_report_schedules_id", value)}

                                  onChange={(value, option) => {
                                    this.loadVehicles()
                                    this.onReportTypeChange(
                                      value,
                                      option,
                                      setFieldValue,
                                    )
                                  }}
                                  value={values.list_report_schedules_id}
                                  style={{ width: '70%' }}
                                  disabled={id !== '' ? true : false}
                                >
                                  <Option value="">
                                    <LangContext.Consumer>
                                      {i18n => i18n.p.pleaseSelect}
                                    </LangContext.Consumer>
                                  </Option>
                                  {reportLists.map(v => (
                                    <Option
                                      key={v.id}
                                      value={v.id}
                                      matching_name={v.matching_name}
                                    >
                                      <LangContext.Consumer>
                                        {i18n =>
                                          i18n.scheduleReport[v.matching_name]
                                        }
                                      </LangContext.Consumer>
                                    </Option>
                                  ))}
                                </Select>
                                <Button
                                  icon="setting"
                                  style={{ width: '30%' }}
                                  onClick={() => {
                                    this.loadVehicles()
                                    this.showModal()
                                  }}
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.s.setting_filter}
                                  </LangContext.Consumer>
                                </Button>
                              </InputGroup>
                            </FormItem>
                          </Col>
                        </Row>

                        {/* <Row gutter={24}>
                          <Col sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              label={
                                <LangContext.Consumer>
                                  {i18n => i18n.a.attachment_type}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.attachment_type &&
                                errors.attachment_type &&
                                'warning'
                              }
                              help={
                                touched.attachment_type &&
                                errors.attachment_type
                              }
                            >
                              <Select
                                onChange={value =>
                                  setFieldValue('attachment_type', value)
                                }
                                value={values.attachment_type}
                              >
                                <Option value="excel">
                                  <LangContext.Consumer>
                                    {i18n => i18n.e.excel}
                                  </LangContext.Consumer>
                                </Option>
                              </Select>
                            </FormItem>
                          </Col>
                        </Row> */}

                        <Row gutter={24}>
                          <Col sm={24} md={14} lg={14} xl={14}>
                            <FormItem
                              label={
                                <LangContext.Consumer>
                                  {i18n => i18n.s.schedule_type}
                                </LangContext.Consumer>
                              }
                              validateStatus={
                                touched.schedule_type_id &&
                                errors.schedule_type_id &&
                                'warning'
                              }
                              help={
                                touched.schedule_type_id &&
                                errors.schedule_type_id
                              }
                            >
                              <InputGroup compact>
                                <Select
                                  //showSearch
                                  style={{ width: '30%' }}
                                  // onChange={value =>
                                  //   setFieldValue("schedule_type_id", value)
                                  // }
                                  onChange={value =>
                                    this.onScheduleTypeChange(
                                      value,
                                      setFieldValue,
                                    )
                                  }
                                  value={values.schedule_type_id}
                                >
                                  <Option value="daily">
                                    <LangContext.Consumer>
                                      {i18n => i18n.r.report_daily}
                                    </LangContext.Consumer>
                                  </Option>
                                  <Option value="weekly">
                                    <LangContext.Consumer>
                                      {i18n => i18n.r.report_weekly}
                                    </LangContext.Consumer>
                                  </Option>
                                  <Option value="monthly">
                                    <LangContext.Consumer>
                                      {i18n => i18n.r.report_monthly}
                                    </LangContext.Consumer>
                                  </Option>
                                  {/* <Option value="yearly">
                                                                    <LangContext.Consumer>
                                                                        {i18n =>
                                                                        i18n.r.report_yearly
                                                                        }
                                                                    </LangContext.Consumer>
                                                                    </Option> */}
                                </Select>
                                {this.state.schedule_type === 'monthly' ||
                                this.state.schedule_type === 'yearly' ? (
                                  <Select
                                    onChange={value =>
                                      setFieldValue('day', value)
                                    }
                                    value={values.day}
                                    style={{ width: '20%' }}
                                  >
                                    {this.state.days.map(v => (
                                      <Option key={v} value={v}>
                                        {v}
                                      </Option>
                                    ))}
                                  </Select>
                                ) : (
                                  ''
                                )}
                                {this.state.schedule_type === 'weekly' ? (
                                  <Select
                                    onChange={value =>
                                      setFieldValue('week', value)
                                    }
                                    value={values.week}
                                    style={{ width: '35%' }}
                                  >
                                    <Option value="1">
                                      <LangContext.Consumer>
                                        {i18n => i18n.w.w_sunday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="2">
                                      <LangContext.Consumer>
                                        {i18n => i18n.w.w_monday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="3">
                                      <LangContext.Consumer>
                                        {i18n => i18n.w.w_tuesday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="4">
                                      <LangContext.Consumer>
                                        {i18n => i18n.w.w_wednesday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="5">
                                      <LangContext.Consumer>
                                        {i18n => i18n.w.w_thursday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="6">
                                      <LangContext.Consumer>
                                        {i18n => i18n.w.w_friday}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="7">
                                      <LangContext.Consumer>
                                        {i18n => i18n.w.w_saturday}
                                      </LangContext.Consumer>
                                    </Option>
                                  </Select>
                                ) : (
                                  ''
                                )}

                                {this.state.schedule_type === 'yearly' ? (
                                  <Select
                                    onChange={value =>
                                      setFieldValue('month', value)
                                    }
                                    value={values.month}
                                    style={{ width: '35%' }}
                                  >
                                    <Option value="1">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_january}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="2">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_february}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="3">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_march}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="4">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_april}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="5">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_may}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="6">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_june}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="7">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_july}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="8">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_august}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="9">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_september}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="10">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_october}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="11">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_november}
                                      </LangContext.Consumer>
                                    </Option>
                                    <Option value="12">
                                      <LangContext.Consumer>
                                        {i18n => i18n.m.m_december}
                                      </LangContext.Consumer>
                                    </Option>
                                  </Select>
                                ) : (
                                  ''
                                )}
                                <TimePicker
                                  placeholder={i18n.n.notificationTime}
                                  value={values.time}
                                  style={{ width: '35%' }}
                                  onChange={value =>
                                    setFieldValue('time', value)
                                  }
                                  format={format}
                                />
                              </InputGroup>
                            </FormItem>
                          </Col>
                        </Row>
                        {this.state.schedule_type === 'daily' ? (
                          <Row gutter={24}>
                            <Col sm={24} md={14} lg={14} xl={14}>
                              <FormItem
                                label={
                                  <LangContext.Consumer>
                                    {i18n => i18n.d.duration_time}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.duration_time &&
                                  errors.duration_time &&
                                  'warning'
                                }
                                help={
                                  touched.duration_time && errors.duration_time
                                }
                              >
                                <InputGroup>
                                  <TimePicker
                                    value={values.start_time}
                                    style={{ width: '25%' }}
                                    onChange={value =>
                                      setFieldValue('start_time', value)
                                    }
                                    format={format}
                                  />
                                  <span>
                                    <LangContext.Consumer>
                                      {i18n => i18n.t.to}
                                    </LangContext.Consumer>
                                  </span>
                                  <TimePicker
                                    value={values.end_time}
                                    style={{ width: '25%' }}
                                    onChange={value =>
                                      setFieldValue('end_time', value)
                                    }
                                    format={format}
                                  />
                                </InputGroup>
                              </FormItem>
                            </Col>
                          </Row>
                        ) : (
                          ''
                        )}

                        {this.state.schedule_type === 'daily' ? (
                          <Row gutter={24}>
                            <Col sm={24} md={14} lg={14} xl={14}>
                              <FormItem
                                validateStatus={
                                  touched.previous_data &&
                                  errors.previous_data &&
                                  'warning'
                                }
                                help={
                                  touched.previous_data && errors.previous_data
                                }
                              >
                                <Checkbox
                                  checked={values.previous_data}
                                  onChange={value =>
                                    setFieldValue(
                                      'previous_data',
                                      value.target.checked,
                                    )
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.s.send_data_before_delivery}
                                  </LangContext.Consumer>
                                </Checkbox>
                              </FormItem>
                            </Col>
                          </Row>
                        ) : (
                          ''
                        )}

                        <Row
                          style={{
                            marginTop: '20px',
                            marginBottom: '5px',
                          }}
                        >
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <Form.Item>
                              <Button
                                type="defualt"
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                <Link to="/schedulereport">{i18n.b.back}</Link>
                              </Button>

                              <Button type="primary" htmlType="submit">
                                {i18n.s.save}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </LangContext.Consumer>
                </Row>
              )}
            />
          </StyledSearchForm>
        </Row>
      </Spin>
    )
  }
}
