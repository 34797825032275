import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadMasterVehicleType(action) {
  const {
    data: { page, pageSize, orderBy, orderType, filterObj, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-masterdata/vehicle-type`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadMasterVehicleType.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterVehicleType.failure())
  }
}

function* loadMasterVehicleTypeDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-masterdata/vehicle-type-by-id`,
      {
        id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadMasterVehicleTypeDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterVehicleTypeDetail.failure())
  }
}

function* updateMasterVehicleType(action) {
  const {
    data: {
      id,
      vehicle_types_name,
      name_key,
      max_weight,
      material_list,
      truck_type_no,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/update-masterdata/vehicle-type`,
      {
        id,
        vehicle_types_name,
        name_key,
        max_weight,
        material_list,
        truck_type_no,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateMasterVehicleType.success(res.data))
  } catch (err) {
    yield put(actions.updateMasterVehicleType.failure())
  }
}

function* exportMasterVehicleType(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      filterObj,
      accessToken,
      type,
      file_value_pdf,
      limit_export,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-masterdata/vehicle-type`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        accessToken,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportMasterVehicleType.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    yield put(actions.exportMasterVehicleType.failure())
  }
}

export default function* watchVehicleType() {
  yield all([
    takeEvery(
      actions.LOAD_MASTERDATA_VEHICLE_TYPE.REQUEST,
      loadMasterVehicleType,
    ),
    takeEvery(
      actions.LOAD_MASTERDATA_VEHICLE_TYPE_DETAIL.REQUEST,
      loadMasterVehicleTypeDetail,
    ),
    takeEvery(
      actions.UPDATE_MASTERDATA_VEHICLE_TYPE_DETAIL.REQUEST,
      updateMasterVehicleType,
    ),
    takeEvery(
      actions.EXPORT_MASTERDATA_VEHICLE_TYPE.REQUEST,
      exportMasterVehicleType,
    ),
  ])
}

export {
  loadMasterVehicleType,
  loadMasterVehicleTypeDetail,
  updateMasterVehicleType,
  exportMasterVehicleType,
}
