import React, { Component } from 'react'
import { Row, Col, Collapse, Card, Icon, Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import TripDelivery from './TripDelivery/Index'

class Delivery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tripDetail: false,
      colorSelection: '',
    }
  }

  handleTrip = () => {
    this.setState(
      {
        tripDetail: true,
        colorSelection: 'ทริป',
      },
      () => {
        this.props.getBreadcrumbSubText('ทริป')
      },
    )
  }
  render() {
    const {
      checkMenuVisibility,
      web_endpoint,
      permissions,
      checkSubMenuVisibility,
      deliveryTripObj,
    } = this.props
    return (
      <>
        {/* แดชบอร์ดภาพรวมการขนส่งคอนกรีต */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'isupplyManageQueue',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/isupply-manage-queue">
              <Card className="card__web__content">
                <p className="res_text__card__web__content">
                  Isupply Manage Queue
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* ทริป */}
        {checkSubMenuVisibility(deliveryTripObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleTrip()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'ทริป' && 'red',
                  color: this.state.colorSelection === 'ทริป' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color: this.state.colorSelection === 'ทริป' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.t.trip_report_menu}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {this.state.tripDetail ? (
          <TripDelivery
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
      </>
    )
  }
}

export default Delivery
