import { createTypes, createAction } from 'lib/action'
const CREATE_REQUEST_MATERIAL = createTypes('createrequestmaterial', 'create')
const LOAD_REQUEST_MATEL = createTypes('requestmaterial', 'load')
const EXPORT_REQUEST_MATEL = createTypes('exportrequestmaterial', 'load')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const createRequestMaterial = {
  request: (data, loading) =>
    createAction(CREATE_REQUEST_MATERIAL.REQUEST, { data, loading }),
  success: res => createAction(CREATE_REQUEST_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(CREATE_REQUEST_MATERIAL.FAITLURE),
}

const loadRequestMaterial = {
  request: (data, loading) =>
    createAction(LOAD_REQUEST_MATEL.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_REQUEST_MATEL.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_REQUEST_MATEL.FAITLURE),
}

const exportRequestMaterial = {
  request: (data, loading) =>
    createAction(EXPORT_REQUEST_MATEL.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_REQUEST_MATEL.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_REQUEST_MATEL.FAITLURE),
}

export {
  CREATE_REQUEST_MATERIAL,
  createRequestMaterial,
  LOAD_REQUEST_MATEL,
  exportRequestMaterial,
  EXPORT_REQUEST_MATEL,
  loadRequestMaterial,
  REMOVE_LISTS,
  removeLists,
}
