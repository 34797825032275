import { createTypes, createAction } from 'lib/action'
const LOAD_BOOKING_KEY_CDAS = createTypes('loadbookingkeycdas', 'load')
const CREATE_BOOKING_KEY_CDAS = createTypes('createbookingkeycdas', 'create')
const UPDATE_BOOKING_KEY_CDAS = createTypes('updatebookingkeycdas', 'create')
const LOAD_BOOKING_KEY_CDAS_DETAIL = createTypes(
  'loadbookingkeycdasdetail',
  'load',
)
const EXPORT_BOOKING_KEY_CDAS = createTypes('exportbookingkeycdas', 'load')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadBookingKeyCdas = {
  request: (data, loading) =>
    createAction(LOAD_BOOKING_KEY_CDAS.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_BOOKING_KEY_CDAS.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_BOOKING_KEY_CDAS.FAITLURE),
}

const createBookingKeyCdas = {
  request: (data, loading) =>
    createAction(CREATE_BOOKING_KEY_CDAS.REQUEST, { data, loading }),
  success: res => createAction(CREATE_BOOKING_KEY_CDAS.SUCCESS, { res }),
  failure: () => createAction(CREATE_BOOKING_KEY_CDAS.FAITLURE),
}

const loadBookingKeyCdasDetail = {
  request: (data, loading) =>
    createAction(LOAD_BOOKING_KEY_CDAS_DETAIL.REQUEST, { data, loading }),
  success: listsEdit =>
    createAction(LOAD_BOOKING_KEY_CDAS_DETAIL.SUCCESS, { listsEdit }),
  failure: () => createAction(LOAD_BOOKING_KEY_CDAS_DETAIL.FAITLURE),
}

const updateBookingKeyCdas = {
  request: (data, loading) =>
    createAction(UPDATE_BOOKING_KEY_CDAS.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_BOOKING_KEY_CDAS.SUCCESS, { res }),
  failure: () => createAction(UPDATE_BOOKING_KEY_CDAS.FAITLURE),
}

const exportBookingKeyCdas = {
  request: (data, loading) =>
    createAction(EXPORT_BOOKING_KEY_CDAS.REQUEST, { data, loading }),
  success: res => createAction(EXPORT_BOOKING_KEY_CDAS.SUCCESS, { res }),
  failure: () => createAction(EXPORT_BOOKING_KEY_CDAS.FAILURE),
}

export {
  loadBookingKeyCdas,
  LOAD_BOOKING_KEY_CDAS,
  createBookingKeyCdas,
  CREATE_BOOKING_KEY_CDAS,
  loadBookingKeyCdasDetail,
  LOAD_BOOKING_KEY_CDAS_DETAIL,
  updateBookingKeyCdas,
  UPDATE_BOOKING_KEY_CDAS,
  exportBookingKeyCdas,
  EXPORT_BOOKING_KEY_CDAS,
  removeLists,
  REMOVE_LISTS,
}
