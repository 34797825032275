import React, { Component } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import AlertAndAlarmReport from './AlertAndAlarmReport/Index'
import CostReport from './CostReport/Index'
import EfficiencyReport from './EfficiencyReport/Index'
import EnegyReport from './EnegyReport/Index'
import GeneralReport from './GeneralReport/Index'
import InsureReport from './InsureReport/Index'
import MaintenanceDeviceReport from './MaintenanceDeviceReport/Index'
import PolicyReport from './PolicyReport/Index'
import TripReport from './TripReport/Index'
import BehaviorReport from './ฺBehaviorReport/Index'

class Report extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alertAndAlarmDetail: false,
      costDetail: false,
      efficiencyDetail: false,
      enegyDetail: false,
      generalDetail: false,
      insureDetail: false,
      maintenanceDeviceDetail: false,
      policyDetail: false,
      tripDetail: false,
      behaviorDetail: false,
      colorSelection: '',
    }
  }
  handleAlertAndAlarm = () => {
    this.setState(
      {
        alertAndAlarmDetail: true,
        costDetail: false,
        efficiencyDetail: false,
        enegyDetail: false,
        generalDetail: false,
        insureDetail: false,
        maintenanceDeviceDetail: false,
        policyDetail: false,
        tripDetail: false,
        behaviorDetail: false,
        colorSelection: 'Alert และ Alarm',
      },
      () => {
        this.props.getBreadcrumbSubText('Alert และ Alarm')
      },
    )
  }
  handleCost = () => {
    this.setState(
      {
        alertAndAlarmDetail: false,
        costDetail: true,
        efficiencyDetail: false,
        enegyDetail: false,
        generalDetail: false,
        insureDetail: false,
        maintenanceDeviceDetail: false,
        policyDetail: false,
        tripDetail: false,
        behaviorDetail: false,
        colorSelection: 'ค่าใช้จ่าย',
      },
      () => {
        this.props.getBreadcrumbSubText('ค่าใช้จ่าย')
      },
    )
  }
  handleEfficiency = () => {
    this.setState(
      {
        alertAndAlarmDetail: false,
        costDetail: false,
        efficiencyDetail: true,
        enegyDetail: false,
        generalDetail: false,
        insureDetail: false,
        maintenanceDeviceDetail: false,
        policyDetail: false,
        tripDetail: false,
        behaviorDetail: false,
        colorSelection: 'ประสิทธิภาพ',
      },
      () => {
        this.props.getBreadcrumbSubText('ประสิทธิภาพ')
      },
    )
  }
  handleEnegy = () => {
    this.setState(
      {
        alertAndAlarmDetail: false,
        costDetail: false,
        efficiencyDetail: false,
        enegyDetail: true,
        generalDetail: false,
        insureDetail: false,
        maintenanceDeviceDetail: false,
        policyDetail: false,
        tripDetail: false,
        behaviorDetail: false,
        colorSelection: 'พลังงาน',
      },
      () => {
        this.props.getBreadcrumbSubText('พลังงาน')
      },
    )
  }
  handleGeneral = () => {
    this.setState(
      {
        alertAndAlarmDetail: false,
        costDetail: false,
        efficiencyDetail: false,
        enegyDetail: false,
        generalDetail: true,
        insureDetail: false,
        maintenanceDeviceDetail: false,
        policyDetail: false,
        tripDetail: false,
        behaviorDetail: false,
        colorSelection: 'ทั่วไป',
      },
      () => {
        this.props.getBreadcrumbSubText('ทั่วไป')
      },
    )
  }
  handleInsure = () => {
    this.setState(
      {
        alertAndAlarmDetail: false,
        costDetail: false,
        efficiencyDetail: false,
        enegyDetail: false,
        generalDetail: false,
        insureDetail: true,
        maintenanceDeviceDetail: false,
        policyDetail: false,
        tripDetail: false,
        behaviorDetail: false,
        colorSelection: 'ประกันภัย',
      },
      () => {
        this.props.getBreadcrumbSubText('ประกันภัย')
      },
    )
  }
  handleMaintenanceDevice = () => {
    this.setState(
      {
        alertAndAlarmDetail: false,
        costDetail: false,
        efficiencyDetail: false,
        enegyDetail: false,
        generalDetail: false,
        insureDetail: false,
        maintenanceDeviceDetail: true,
        policyDetail: false,
        tripDetail: false,
        behaviorDetail: false,
        colorSelection: 'การจัดการอุปกรณ์',
      },
      () => {
        this.props.getBreadcrumbSubText('การจัดการอุปกรณ์')
      },
    )
  }
  handlePolicy = () => {
    this.setState(
      {
        alertAndAlarmDetail: false,
        costDetail: false,
        efficiencyDetail: false,
        enegyDetail: false,
        generalDetail: false,
        insureDetail: false,
        maintenanceDeviceDetail: false,
        policyDetail: true,
        tripDetail: false,
        behaviorDetail: false,
        colorSelection: 'นโยบาย',
      },
      () => {
        this.props.getBreadcrumbSubText('นโยบาย')
      },
    )
  }
  handleTrip = () => {
    this.setState(
      {
        alertAndAlarmDetail: false,
        costDetail: false,
        efficiencyDetail: false,
        enegyDetail: false,
        generalDetail: false,
        insureDetail: false,
        maintenanceDeviceDetail: false,
        policyDetail: false,
        tripDetail: true,
        behaviorDetail: false,
        colorSelection: 'ทริป',
      },
      () => {
        this.props.getBreadcrumbSubText('ทริป')
      },
    )
  }
  handleBehavior = () => {
    this.setState(
      {
        alertAndAlarmDetail: false,
        costDetail: false,
        efficiencyDetail: false,
        enegyDetail: false,
        generalDetail: false,
        insureDetail: false,
        maintenanceDeviceDetail: false,
        policyDetail: false,
        tripDetail: false,
        behaviorDetail: true,
        colorSelection: 'พฤติกรรม',
      },
      () => {
        this.props.getBreadcrumbSubText('พฤติกรรม')
      },
    )
  }
  render() {
    const {
      checkMenuVisibility,
      web_endpoint,
      permissions,
      checkSubMenuVisibility,
      reportGeneralObj,
      reportBehaviorObj,
      reportAlertAndAlarmObj,
      reportEfficiencyObj,
      reportTripObj,
      reportPolicyObj,
      reportEnergyObj,
      reportCostObj,
      reportMaintenanceDeviceObj,
      reportInsureObj,
      getBreadcrumbSubText,
    } = this.props
    return (
      <>
        {/* กำหนดการแจ้งเตือนรายงาน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'schedulereport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/schedulereport">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.s.schedulereport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          ใช้สำหรับกำหนดการแจ้งเตือนรายงาน
                          ให้แจ้งเตือนตามเงื่อนไขที่กำหนด
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.s.schedulereport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* ทั่วไป */}
        {checkSubMenuVisibility(reportGeneralObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleGeneral()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'ทั่วไป' && 'red',
                  color: this.state.colorSelection === 'ทั่วไป' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color: this.state.colorSelection === 'ทั่วไป' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.g.general_report_menu}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* พฤติกรรม */}
        {checkSubMenuVisibility(reportBehaviorObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleBehavior()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'พฤติกรรม' && 'red',
                  color: this.state.colorSelection === 'พฤติกรรม' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color: this.state.colorSelection === 'พฤติกรรม' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.b.behavior_report_menu}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* Alert และ Alarm */}
        {checkSubMenuVisibility(reportAlertAndAlarmObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleAlertAndAlarm()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'Alert และ Alarm' && 'red',
                  color:
                    this.state.colorSelection === 'Alert และ Alarm' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color:
                      this.state.colorSelection === 'Alert และ Alarm' &&
                      'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.a.alertAndAlarm}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* ประสิทธิภาพ */}
        {checkSubMenuVisibility(reportEfficiencyObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleEfficiency()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'ประสิทธิภาพ' && 'red',
                  color: this.state.colorSelection === 'ประสิทธิภาพ' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color:
                      this.state.colorSelection === 'ประสิทธิภาพ' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.e.efficiency_report_menu}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* ทริป */}
        {checkSubMenuVisibility(reportTripObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleTrip()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'ทริป' && 'red',
                  color: this.state.colorSelection === 'ทริป' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color: this.state.colorSelection === 'ทริป' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.t.trip_report_menu}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* นโยบาย */}
        {checkSubMenuVisibility(reportPolicyObj) ? (
          <Col span={4}>
            <div onClick={() => this.handlePolicy()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'นโยบาย' && 'red',
                  color: this.state.colorSelection === 'นโยบาย' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color: this.state.colorSelection === 'นโยบาย' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.p.policy}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* พลังงาน */}
        {checkSubMenuVisibility(reportEnergyObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleEnegy()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'พลังงาน' && 'red',
                  color: this.state.colorSelection === 'พลังงาน' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color: this.state.colorSelection === 'พลังงาน' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.e.energy_report_menu}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* ค่าใช้จ่าย */}
        {checkSubMenuVisibility(reportCostObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleCost()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'ค่าใช้จ่าย' && 'red',
                  color: this.state.colorSelection === 'ค่าใช้จ่าย' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color:
                      this.state.colorSelection === 'ค่าใช้จ่าย' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.t.theCost}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* การจัดการอุปกรณ์ */}
        {checkSubMenuVisibility(reportMaintenanceDeviceObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleMaintenanceDevice()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'การจัดการอุปกรณ์' && 'red',
                  color:
                    this.state.colorSelection === 'การจัดการอุปกรณ์' && 'white',
                }}
              >
                <p
                  className="text_one_line_card_web_content"
                  style={{
                    color:
                      this.state.colorSelection === 'การจัดการอุปกรณ์' &&
                      'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.m.maintenancedevice_report_menu}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {/* ประกันภัย */}
        {checkSubMenuVisibility(reportInsureObj) ? (
          <Col span={4}>
            <div onClick={() => this.handleInsure()}>
              <Card
                className="card__web__content"
                style={{
                  backgroundColor:
                    this.state.colorSelection === 'ประกันภัย' && 'red',
                  color: this.state.colorSelection === 'ประกันภัย' && 'white',
                }}
              >
                <p
                  className="text__card__web__content"
                  style={{
                    color: this.state.colorSelection === 'ประกันภัย' && 'white',
                  }}
                >
                  <LangContext.Consumer>
                    {i18n => i18n.i.insure_report_menu}
                  </LangContext.Consumer>
                </p>
              </Card>
            </div>
          </Col>
        ) : (
          <></>
        )}
        {this.state.insureDetail ? (
          <InsureReport
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.maintenanceDeviceDetail ? (
          <MaintenanceDeviceReport
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.costDetail ? (
          <CostReport
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.enegyDetail ? (
          <EnegyReport
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.policyDetail ? (
          <PolicyReport
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.tripDetail ? (
          <TripReport
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.efficiencyDetail ? (
          <EfficiencyReport
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.alertAndAlarmDetail ? (
          <AlertAndAlarmReport
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.behaviorDetail ? (
          <BehaviorReport
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
        {this.state.generalDetail ? (
          <GeneralReport
            checkMenuVisibility={checkMenuVisibility}
            web_endpoint={web_endpoint}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
      </>
    )
  }
}

export default Report
