import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Table,
  Checkbox,
} from 'antd'
import difference from 'lodash/difference'
import { convertNodeToElement } from 'react-html-parser'
// import 'moment/locale/zh-cn';
// import locale from 'antd/es/date-picker/locale/zh_CN';

const { MonthPicker } = DatePicker

const FormItem = Form.Item
const Option = Select.Option
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <div
          onClick={() => {
            restProps.onChange(restProps.dataSource.map(item => item.key))
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
        <div
          onClick={() => {
            restProps.onChange([])
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns
        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
          //   hideDefaultSelections: true,
          //   selections: [
          //     {
          //       //   key: 'all-data',
          //       text: 'Select All Data',
          //       onSelect: (record, selected, selectedRows, nativeEvent) => {
          //         let getKey = []
          //         filteredItems.map(item => getKey.push(item.key))
          //         onItemSelectAll([...getKey], true)
          //       },
          //     },
          //   ],
        }

        return (
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 100 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)
export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],

    vehicle_type_id: '',
    eventdate: '',
    list_vehicle_id: [],
    job_status: 'all',
    duration: 'all',
    vendor: 'all',
    check_load: false,
  }

  componentDidMount() {
    const { loadVehicle, loadVehicleGroup, loadVendor, call } = this.props

    if (call === 'schedule') {
      loadVendor()
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        loadVehicle(obj.vehicle_type_id, obj.vendor)
      } else {
        loadVehicle('', '')
      }
      loadVehicleGroup()

      this.setState({ check_load: true })
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleLists, check_load_vehicle, call } = nextProps

    const vehlist = []
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    if (this.state.check_load === true) {
      if (call === 'schedule') {
        if (this.props.dataedit !== '') {
          if (this.props.check_load_vehicle !== check_load_vehicle) {
            let obj = JSON.parse(this.props.dataedit)
            this.setState({
              vendor: obj.vendor,
              list_vehicle_id: obj.list_vehicle_id,
              job_status: obj.job_status,
              duration: obj.duration,
              vehicleSelect: obj.list_vehicle_id,
              selectedKeys: obj.list_vehicle_id,
              vehicle_type_id: obj.vehicle_type_id,
              check_load: false,
              vehicleList: vehlist,
            })
          }
        } else {
          //add no edit schedule

          this.setState({ vehicleList: vehlist })
        }
      }
    } else {
      this.setState({ vehicleList: vehlist })
    }
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys, list_vehicle_id: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleClicReportkPreview = values => {
    const { list_vehicle_id, eventdate, job_status, vendor, duration } = values
    const date_start = moment(eventdate['_d'])
      .clone()
      .startOf('months')
      .format('YYYY-MM-DD 00:00:00')
    const date_end = moment(eventdate['_d'])
      .clone()
      .endOf('months')
      .format('YYYY-MM-DD 23:59:59')

    const filter = {
      date_start,
      date_end,
      job_status,
      vendor,
      duration,
      list_vehicle_id,
      type_file: 'preview',
      company_id: this.props.auth.profile.company_id,
    }

    window.open('/report/repairingreport/preview', '_blank')
    localStorage.setItem('repairingreport-filter', JSON.stringify(filter))
  }

  render() {
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }

    return (
      <div>
        <Spin spinning={this.props.loading}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              vendor: this.state.vendor,
              vehicle_type_id: this.state.vehicle_type_id,
              eventdate: this.state.eventdate,
              list_vehicle_id: this.state.list_vehicle_id,
              job_status: this.state.job_status,
              duration: this.state.duration,
              getAllData: null,
            }}
            validationSchema={yup.object().shape({
              list_vehicle_id: yup
                .array()
                .required(
                  <LangContext.Consumer>
                    {i18n => i18n.r.requiredFieldVehicle}
                  </LangContext.Consumer>,
                ),
            })}
            onSubmit={values => {
              this.props.onSubmit(values)
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating,
            }) => (
              <Row type="flex" justify="center">
                <LangContext.Consumer>
                  {i18n => (
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        <LangContext.Consumer>
                          {i18n => i18n.r.repairingReport}
                        </LangContext.Consumer>
                      </div>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.g.gpsVendor}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.vendor && errors.vendor && 'error'
                        }
                        help={touched.vendor && errors.vendor}
                      >
                        <Select
                          name="vendor"
                          onChange={value => {
                            setFieldValue('vendor', value)
                            this.setState({ vendor: value })
                            this.props.onCriteriaChange(
                              values.vehicle_type_id,
                              value,
                            )
                          }}
                          value={values.vendor}
                          style={{ width: '80%' }}
                        >
                          <Option value="all" key="all">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          {this.props.vendorList.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicleGroup}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.vehicle_type_id &&
                          errors.vehicle_type_id &&
                          'error'
                        }
                        help={touched.vehicle_type_id && errors.vehicle_type_id}
                      >
                        <Select
                          onChange={value => {
                            setFieldValue('vehicle_type_id', value)
                            this.setState({ vehicle_type_id: value })
                            this.props.onCriteriaChange(value, values.vendor)
                          }}
                          value={values.vehicle_type_id}
                          style={{ width: '80%' }}
                        >
                          <Option value="">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          {this.props.vehicleGroupLists &&
                            this.props.vehicleGroupLists.map(item => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </FormItem>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'error'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div style={{ width: '750px' }}>
                          <TableTransfer
                            dataSource={this.state.vehicleList}
                            targetKeys={this.state.vehicleSelect}
                            showSearch
                            onChange={targetKeys => {
                              this.tranferChange(targetKeys, setFieldValue)
                            }}
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                            leftColumns={[
                              {
                                dataIndex: 'name',
                                title: (
                                  <div className="regular-bold-14">
                                    <LangContext.Consumer>
                                      {i18n => i18n.i.itemLeft}
                                    </LangContext.Consumer>
                                  </div>
                                ),
                                posiction: 'Left',
                              },
                            ]}
                            rightColumns={[
                              {
                                dataIndex: 'name',
                                title: (
                                  <div className="regular-bold-14">
                                    <LangContext.Consumer>
                                      {i18n => i18n.i.itemSelected}
                                    </LangContext.Consumer>
                                  </div>
                                ),
                                posiction: 'Right',
                              },
                            ]}
                            onSelectChange={this.handleSelectChange}
                          />
                        </div>
                      </FormItem>

                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.s.status_repair}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.job_status && errors.job_status && 'error'
                        }
                        help={touched.job_status && errors.job_status}
                      >
                        <Select
                          onChange={value => {
                            setFieldValue('job_status', value)
                            this.setState({ job_status: value })
                          }}
                          value={values.job_status}
                          style={{ width: '80%' }}
                        >
                          <Option value="all">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          <Option value="not-finish">
                            <LangContext.Consumer>
                              {i18n => i18n.n.notFinished}
                            </LangContext.Consumer>
                          </Option>
                          <Option value="finish">
                            <LangContext.Consumer>
                              {i18n => i18n.f.finished}
                            </LangContext.Consumer>
                          </Option>
                        </Select>
                      </FormItem>

                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.d.duration_repair}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.duration && errors.duration && 'error'
                        }
                        help={touched.duration && errors.duration}
                      >
                        <Select
                          onChange={value => {
                            setFieldValue('duration', value)
                            this.setState({ duration: value })
                          }}
                          value={values.duration}
                          style={{ width: '80%' }}
                        >
                          <Option value="all">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          <Option value="more-than-3-day">
                            <LangContext.Consumer>
                              {i18n => i18n.o.over3Days}
                            </LangContext.Consumer>
                          </Option>
                          <Option value="less-than-3-day">
                            <LangContext.Consumer>
                              {i18n => i18n.l.lessThan3days}
                            </LangContext.Consumer>
                          </Option>
                        </Select>
                      </FormItem>
                      {this.props.call !== 'schedule' ? (
                        <>
                          <Form.Item
                            label={
                              <LangContext.Consumer>
                                {i18n => i18n.m.month}
                              </LangContext.Consumer>
                            }
                            validateStatus={
                              touched.eventdate && errors.eventdate && 'error'
                            }
                            help={touched.eventdate && errors.eventdate}
                          >
                            <MonthPicker
                              disabled={values.getAllData}
                              disabledDate={disabledDate}
                              onChange={value => {
                                setFieldValue('eventdate', value)
                                this.setState({ eventdate: value })
                              }}
                              defaultValue={moment()}
                              format="MM"
                              allowClear={false}
                              style={{ margin: '0px 10px 40px 0px' }}
                            />
                            {values.job_status === 'not-finish' && (
                              <Checkbox
                                onChange={value => {
                                  setFieldValue(
                                    'getAllData',
                                    value.target.checked,
                                  )
                                }}
                                checked={values.getAllData}
                              >
                                <LangContext.Consumer>
                                  {i18n => i18n.s.selectedEveryMonths}
                                </LangContext.Consumer>
                              </Checkbox>
                            )}
                          </Form.Item>
                        </>
                      ) : (
                        ''
                      )}
                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call !== 'schedule' ? (
                            <>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  block
                                  onClick={() =>
                                    this.handleClicReportkPreview(values)
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.preview}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button type="primary" htmlType="submit" block>
                                  <LangContext.Consumer>
                                    {i18n => i18n.r.report}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                            </>
                          ) : (
                            ''
                          )}

                          {this.props.call == 'schedule' ? (
                            <Col span={8}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            ''
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  )}
                </LangContext.Consumer>
              </Row>
            )}
          />
        </Spin>
      </div>
    )
  }
}
