import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { Button, Form, Input, Row, Col, Select } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
const FormItem = Form.Item;
const { Option } = Select;

export default class Filter extends Component {


  componentDidMount() {
    const { loadZone, loadMaintenancestatus, loadVehicletype, loadMaintenancetype } = this.props;
    loadZone();
    loadMaintenancestatus();
    loadVehicletype();
    loadMaintenancetype();

  }


  render() {
    const { OnClose, onSubmit, maintenancetypeLists, zoneLists, maintenancestatusLists, vehicletypeLists } = this.props;

    return (
      <div>
        <Formik
          initialValues={{
            plate_no: "",
            code: "",
            device_user: "",
            vehicle_type: "",
            maintenance_status: "",
            type: "",
            location_code: "",
            zone: ""
          }}
          validationSchema={yup.object().shape({
            // name: yup.string.required("กรุณาเลือกช่วงวันที่"),
            // code: yup.string.required("กรุณาเลือกรถ")
          })}
          onSubmit={values => {
            const loading = true;
            onSubmit(values, loading);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm
          }) => (
              <Row>
                <Form onSubmit={handleSubmit}>
                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.z.zone}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.zone &&
                      errors.zone &&
                      "error"
                    }
                    help={touched.zone && errors.zone}
                  >
                    <Select
                      onChange={value => setFieldValue("zone", value)}
                      value={values.zone}
                    >
                      <Option value=""><LangContext.Consumer>
                        {i18n => i18n.a.all}
                      </LangContext.Consumer></Option>
                      {zoneLists.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>

                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.v.vehicle_type}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.vehicle_type &&
                      errors.vehicle_type &&
                      "error"
                    }
                    help={touched.vehicle_type && errors.vehicle_type}
                  >
                    <Select
                      onChange={value => setFieldValue("vehicle_type", value)}
                      value={values.vehicle_type}
                    >
                      <Option value=""><LangContext.Consumer>
                        {i18n => i18n.a.all}
                      </LangContext.Consumer></Option>
                      {vehicletypeLists.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>

                  <FormItem
                    label={<LangContext.Consumer>
                      {i18n => i18n.p.plateNo}
                    </LangContext.Consumer>}
                    validateStatus={
                      touched.plate_no && errors.plate_no && "error"
                    }
                    help={touched.plate_no && errors.plate_no}
                  >
                    <Input
                      //   placeholder={<LangContext.Consumer>
                      //     {i18n => i18n.p.plateNo}
                      //   </LangContext.Consumer>}
                      name="plate_no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.plate_no}
                    />
                  </FormItem>

                  <FormItem
                    label={<LangContext.Consumer>
                      {i18n => i18n.v.vehicleCode}
                    </LangContext.Consumer>}
                    validateStatus={
                      touched.code && errors.code && "error"
                    }
                    help={touched.code && errors.code}
                  >
                    <Input
                      // placeholder={<LangContext.Consumer>
                      //   {i18n =>i18n.v.vehicleCode}
                      // </LangContext.Consumer>}
                      name="code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                    />
                  </FormItem>

                  <FormItem
                    label={<LangContext.Consumer>
                      {i18n => i18n.d.driverName}
                    </LangContext.Consumer>}
                    validateStatus={
                      touched.device_user && errors.device_user && "error"
                    }
                    help={touched.device_user && errors.device_user}
                  >
                    <Input
                      // placeholder={<LangContext.Consumer>
                      //   {i18n =>i18n.d.driverName}
                      // </LangContext.Consumer>}
                      name="device_user"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.device_user}
                    />
                  </FormItem>

                  <FormItem
                    label={<LangContext.Consumer>
                      {i18n => i18n.p.plantCode}
                    </LangContext.Consumer>}
                    validateStatus={
                      touched.location_code && errors.location_code && "error"
                    }
                    help={touched.location_code && errors.location_code}
                  >
                    <Input
                      // placeholder={<LangContext.Consumer>
                      //   {i18n => i18n.p.plantCode}
                      // </LangContext.Consumer>}
                      name="location_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.location_code}
                    />
                  </FormItem>


                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.m.maintenance_type}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.type &&
                      errors.type &&
                      "error"
                    }
                    help={touched.type && errors.type}
                  >
                    <Select
                      onChange={value => setFieldValue("type", value)}
                      value={values.type}
                    >
                      <Option value=""><LangContext.Consumer>
                        {i18n => i18n.a.all}
                      </LangContext.Consumer></Option>
                      {maintenancetypeLists.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>

                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.m.maintenance_status}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.maintenance_status &&
                      errors.maintenance_status &&
                      "error"
                    }
                    help={touched.maintenance_status && errors.maintenance_status}
                  >
                    <Select
                      onChange={value => setFieldValue("maintenance_status", value)}
                      value={values.maintenance_status}
                    >
                      <Option value=""><LangContext.Consumer>
                        {i18n => i18n.a.all}
                      </LangContext.Consumer></Option>
                      {maintenancestatusLists.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>



                  <FormItem>
                    <Row gutter={24}>
                      <Col span={6}>
                        <Button
                          // onClick={OnClose.bind(null, resetForm)}
                          onClick={OnClose}
                          style={{ paddingLeft: 5 }}
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.c.cancel}
                          </LangContext.Consumer>
                        </Button>
                      </Col>
                      <Col span={1} />
                      <Col span={6}>
                        <Button htmlType="submit" type="primary">
                          <LangContext.Consumer>
                            {i18n => i18n.a.apply}
                          </LangContext.Consumer>
                        </Button>
                      </Col>
                    </Row>
                  </FormItem>
                </Form>

              </Row>
            )}
        />
      </div>
    );
  }
}
