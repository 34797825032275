import React, { Component } from 'react'
// import { Connector } from "mqtt-react";
import { hot } from 'react-hot-loader'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import history from 'lib/history'
import configureStore from 'modules/configureStore'
import Layout from 'modules/ui/components/Layout'
import * as Sentry from '@sentry/react'
import './styled/fix-line-lost.css'

const store = configureStore()

class App extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <Router history={history}>
            <Layout />
          </Router>
        </Provider>
      </Sentry.ErrorBoundary>
    )
  }
}

export default hot(module)(App)
