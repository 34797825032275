import React from 'react'
import { Row, Col, Button } from 'antd'
const PopupMarkerSmartDispatching = ({ title, image }) => (
  <Row>
    <Col span={24}>
      {title !== '' && (
        <div
          style={{
            zIndex: 1000,
            width: '160px',
            borderRadius: '1px',
            padding: '7px 15px 7px 15px',
            // display: 'inline-flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            transform: 'translate(-50%, -180%)',
            color: 'black',
            backgroundColor: 'white',
            position: 'relative',
            bottom: 0,
            // minHeight: '35px',
            border: '2px solid white',
            fontSize: 11,
            marginLeft: '15px',
            borderRadius: '15px',
          }}
        >
          <p
            style={{
              borderBottom: '1px solid #eee',
              fontSize: '14px',
              paddingBottom: '17px',
            }}
          >
            {title}
          </p>

          <Button type="primary">
            <img src={image} /> นำทาง
          </Button>
          <div
            style={{
              position: 'absolute',
              left: 70,
              bottom: -15,
              zIndex: -2,
              width: 0,
              height: 0,
              borderLeft: '7px solid transparent',
              borderRight: '7px solid transparent',
              borderTop: '12px solid white',
              marginLeft: '15px',
            }}
          />
        </div>
      )}
    </Col>
  </Row>
)

export default PopupMarkerSmartDispatching
