import { LOAD_SALEORDER, EXPORT_SALEORDER, REMOVE_LISTS } from './actions'

const intialState = {
  lists: [],
  total: 1,
  loading: false,
  check: true,
}

export default (state = intialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return intialState

    case LOAD_SALEORDER.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        loading: loading,
      }

    case LOAD_SALEORDER.SUCCESS:
      const { page, pageSize } = action.payload
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case EXPORT_SALEORDER.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_SALEORDER.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
