import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadBookingKeyCdas(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      location_visibility,
      is_vendor,
      is_admin,
      company_id,
      accessToken,
      filterObj,
      material_visibility,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-booking-key/get/booking-key-cdas`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        is_vendor,
        is_admin,
        company_id,
        location_visibility,
        filterObj,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadBookingKeyCdas.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadBookingKeyCdas.failure())
  }
}

function* createBookingKeyCdas(action) {
  const {
    data: {
      isupply_material_id,
      isupply_sub_material_id,
      mining_id,
      contractor_id,
      contractor_name,
      supplier_no,
      subcontact_no,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-booking-key/get/create-booking-key-cdas`,
      {
        isupply_material_id,
        isupply_sub_material_id,
        mining_id,
        contractor_id,
        contractor_name,
        supplier_no,
        subcontact_no,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createBookingKeyCdas.success(res.data))
  } catch (err) {
    yield put(actions.createBookingKeyCdas.failure())
  }
}

function* loadBookingKeyCdasDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-booking-key/get/booking-key-cdas-detail`,
      {
        id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadBookingKeyCdasDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadBookingKeyCdasDetail.failure())
  }
}

function* updateBookingKeyCdas(action) {
  const {
    data: {
      id,
      isupply_material_id,
      isupply_sub_material_id,
      mining_id,
      contractor_id,
      contractor_name,
      supplier_no,
      subcontact_no,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-booking-key/get/update-booking-key-cdas`,
      {
        id,
        isupply_material_id,
        isupply_sub_material_id,
        mining_id,
        contractor_id,
        contractor_name,
        supplier_no,
        subcontact_no,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateBookingKeyCdas.success(res.data))
  } catch (err) {
    yield put(actions.updateBookingKeyCdas.failure())
  }
}

function* exportBookingKeyCdas(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      location_visibility,
      is_vendor,
      is_admin,
      company_id,
      accessToken,
      filterObj,
      type,
      file_value_pdf,
      limit_export,
      material_visibility,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-booking-key/get/booking-key-cdas`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        is_vendor,
        is_admin,
        company_id,
        location_visibility,
        filterObj,
        type,
        file_value_pdf,
        limit_export,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.exportBookingKeyCdas.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    yield put(actions.exportBookingKeyCdas.failure())
  }
}

export default function* watchIsupplyBookingKeyCdas() {
  yield all([
    takeEvery(actions.LOAD_BOOKING_KEY_CDAS.REQUEST, loadBookingKeyCdas),
    takeEvery(actions.CREATE_BOOKING_KEY_CDAS.REQUEST, createBookingKeyCdas),
    takeEvery(
      actions.LOAD_BOOKING_KEY_CDAS_DETAIL.REQUEST,
      loadBookingKeyCdasDetail,
    ),
    takeEvery(actions.UPDATE_BOOKING_KEY_CDAS.REQUEST, updateBookingKeyCdas),
    takeEvery(actions.EXPORT_BOOKING_KEY_CDAS.REQUEST, exportBookingKeyCdas),
  ])
}
export {
  loadBookingKeyCdas,
  createBookingKeyCdas,
  loadBookingKeyCdasDetail,
  updateBookingKeyCdas,
  exportBookingKeyCdas,
}
