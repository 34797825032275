import {
  LOAD_ISUPPLY_MULTIDROP_GET_ISUPPLY_DO,
  CREATE_ISUPPLY_MULTIDROPL,
  LOAD_MULTIDROP,
  LOAD_MULTIDROP_FOR_EDIT,
  UPDATE_ISUPPLY_MULTIDROPL,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  lists: [],
  DN_Lists: [],
  lists_for_edit: [],
  total: 1,
  loading_page: false,
  loading: false,
  loading_for_edit: false,
  draw: -1,
  check: true,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_MULTIDROP.REQUEST:
      return {
        ...state,
        loading: action.payload.data.loading,
      }

    case LOAD_MULTIDROP.SUCCESS:
      const { page, pageSize } = action.payload

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (action.payload.page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber +
            (action.payload.page - 1) * action.payload.pageSize
      }

      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_ISUPPLY_MULTIDROP_GET_ISUPPLY_DO.REQUEST:
      return {
        ...state,
        loading: true,
        loading_page: true,
      }

    case LOAD_ISUPPLY_MULTIDROP_GET_ISUPPLY_DO.SUCCESS:
      return {
        ...state,
        loading: false,
        loading_page: false,
        DN_Lists: action.payload.lists.data,
      }

    case CREATE_ISUPPLY_MULTIDROPL.REQUEST:
      return {
        ...state,
        loading: true,
        loading_page: true,
      }

    case CREATE_ISUPPLY_MULTIDROPL.SUCCESS:
      return {
        ...state,
        loading: false,
        loading_page: false,
      }

    case CREATE_ISUPPLY_MULTIDROPL.FAILURE:
      return {
        ...state,
        loading: false,
        loading_page: false,
      }

    case LOAD_MULTIDROP_FOR_EDIT.REQUEST:
      return {
        ...state,
        loading_page: true,
        loading_for_edit: true,
      }

    case LOAD_MULTIDROP_FOR_EDIT.SUCCESS:
      return {
        ...state,
        // loading_page: false,
        loading_for_edit: false,
        lists_for_edit: action.payload.listsEdit,
      }

    case UPDATE_ISUPPLY_MULTIDROPL.REQUEST:
      return {
        ...state,
        loading_page: true,
      }

    case UPDATE_ISUPPLY_MULTIDROPL.SUCCESS:
      return {
        ...state,
        loading_page: false,
      }

    default:
      return state
  }
}
