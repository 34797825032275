import {
  REMOVE_LISTS,
  LOAD_VEHICLEGROUP,
  CREATE_VEHICLEGROUP,
  LOAD_VEHICLEGROUP_DETAIL,
  UPDATE_VEHICLEGROUP,
  EXPORT_VEHICLEGROUP,
  LOAD_VISIBILITY_BY_VEHICLE_GROUP,
} from './actions'

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  vehiclegroupDetailloading: false,
  draw: -1,
  success: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_VEHICLEGROUP.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
      }
    case LOAD_VEHICLEGROUP.SUCCESS:
      const { page, pageSize } = action.payload

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * pageSize
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }
    case LOAD_VEHICLEGROUP_DETAIL.REQUEST:
      const { vehiclegroupDetailloading } = action.payload
      return {
        ...state,
        loading: loading,
      }

    case LOAD_VEHICLEGROUP_DETAIL.SUCCESS:
      const { detailvehiclepage } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (detailvehiclepage > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber +
            (detailvehiclepage - 1) * 10
      }
      console.log(action)
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }
    case CREATE_VEHICLEGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_VEHICLEGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case EXPORT_VEHICLEGROUP.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case EXPORT_VEHICLEGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_VISIBILITY_BY_VEHICLE_GROUP.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOAD_VISIBILITY_BY_VEHICLE_GROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
