import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, Icon, Menu } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class CustomMenuItem extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getPermissionElement = (permissions, matching_name) => {
    let permission_data = this.getPermissionData(permissions, matching_name)

    if (permission_data.length > 0) {
      let permission_url = permission_data[0].url || ''
      let permission_name = permission_data[0].name || ''
      let permission_name_en = permission_data[0].name_en || ''

      let permission_display = ''
      if (this.props.language == 'en') {
        permission_display = permission_name_en
      } else {
        permission_display = permission_name
      }
      if (permission_url && permission_display) {
        return (
          <Link to={permission_url}>
            <span className='nav-text'>{permission_display}</span>
          </Link>
        )
      } else {
        return null
      }
    }

    return null
  }

  getPermissionData = (permissions, matching_name) => {
    let data = permissions.filter(function(ele) {
      return ele.matching_name == matching_name && ele.can_view == 1
    })

    return data
  }

  render() {
    const {
      matching_name,
      permissions,

      children,
    } = this.props

    return (
      <>{this.getPermissionElement(permissions, matching_name) || children}</>
    )
  }
}

export default connect(
  ({ auth, auth: { profile }, ui: { language } }) => ({
    auth,
    profile,
    language,
  }),
  {}
)(CustomMenuItem)
