import { createTypes, createAction } from 'lib/action'

const LOAD_DRIVINGOVERLIMIT = createTypes('drivingoverlimit', 'load')
const LOAD_DRIVINGOVERLIMIT_PREVIEW = createTypes(
  'drivingoverlimitpreview',
  'load',
)
const LOAD_VEHICLE_DRIVINGOVERLIMIT = createTypes(
  'vehicle_drivingoverlimit',
  'load',
)
const LOAD_VEHICLE_TYPE_DRIVINGOVERLIMIT = createTypes(
  'vehicletype_drivingoverlimit',
  'load',
)

const loadDrivingoverlimit = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGOVERLIMIT.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DRIVINGOVERLIMIT.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGOVERLIMIT.FAILURE),
}

const loadDrivingoverlimitpreview = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGOVERLIMIT_PREVIEW.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_DRIVINGOVERLIMIT_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGOVERLIMIT_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DRIVINGOVERLIMIT.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_DRIVINGOVERLIMIT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DRIVINGOVERLIMIT.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGOVERLIMIT.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_DRIVINGOVERLIMIT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGOVERLIMIT.FAILURE),
}

export {
  loadDrivingoverlimit,
  LOAD_DRIVINGOVERLIMIT,
  loadDrivingoverlimitpreview,
  LOAD_DRIVINGOVERLIMIT_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_DRIVINGOVERLIMIT,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_DRIVINGOVERLIMIT,
}
