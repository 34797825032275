import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Table,
  message,
  Tooltip,
} from 'antd'

import difference from 'lodash/difference'

import BasicFilterReport from '../../basicFilterReport/Index'

const { RangePicker } = DatePicker

const FormItem = Form.Item
const Option = Select.Option

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
        }

        return (
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 50 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)
export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    clickMenu: '',
  }

  componentDidMount() {
    const { call } = this.props

    if (call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleList } = nextProps
    const vehlist = []
    vehicleList.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    if (targetKeys.length > 50) {
      message.error('เลือกรถได้ไม่เกิน 50 คัน')
    } else {
      setFieldValue('list_vehicle_id', targetKeys)
      this.setState({ vehicleSelect: targetKeys })
    }
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleClicReportkPreview = values => {
    const COMPANY_ID = this.props.auth.company_id

    const date_start = moment(values.eventdate[0]['_d'])
      .add(-7, 'hours')
      .format('YYYY-MM-DD HH:mm:00')
    const date_end = moment(values.eventdate[1]['_d'])
      .add(-7, 'hours')
      .format('YYYY-MM-DD HH:mm:59')

    const filter = {
      date_start,
      date_end,
      list_vehicle_id: values.list_vehicle_id,
      company_id: COMPANY_ID,
      type_file: 'preview',
    }

    window.open('/report/behaviordriving/preview', '_blank')
    localStorage.setItem('behaviordrivingreport-filter', JSON.stringify(filter))
  }

  render() {
    const { behaviordrivingLoading, onSubmit } = this.props
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <Spin spinning={behaviordrivingLoading}>
          <Formik
            initialValues={{
              eventdate: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
              list_vehicle_id: [],
              division: this.props.selectedDivision,
              department: this.props.selectedDepartment,
              section: this.props.selectedSection,
              plant: this.props.selectedPlant,
              vehicleGroup: this.props.selectedVehicleGroup,
            }}
            validate={values => {
              let errors = {}
              if (values.eventdate.length > 0) {
                const date_start = moment(values.eventdate[0]['_d'])
                const date_end = moment(values.eventdate[1]['_d'])
                let day_diff = date_end.diff(date_start, 'days') + 1
                if (day_diff > 31) {
                  errors.eventdate = (
                    <LangContext.Consumer>
                      {i18n => i18n.d.dateRangeNotBeMoreThan31Days}
                    </LangContext.Consumer>
                  )
                }
              }

              if (values.list_vehicle_id.length > 50) {
                errors.list_vehicle_id = (
                  <LangContext.Consumer>
                    {i18n => i18n.o.over50}
                  </LangContext.Consumer>
                )
              }

              return errors
            }}
            validationSchema={yup.object().shape({
              eventdate: yup
                .array()
                .required(
                  <LangContext.Consumer>
                    {i18n => i18n.p.pleaseChooseDateRange}
                  </LangContext.Consumer>,
                ),
              list_vehicle_id: yup
                .array()
                .required(
                  <LangContext.Consumer>
                    {i18n => i18n.r.requiredFieldVehicle}
                  </LangContext.Consumer>,
                ),
            })}
            onSubmit={values => {
              //console.log(values);
              const loading = true
              if (this.state.clickMenu === 'preview') {
                this.handleClicReportkPreview(values)
              } else {
                onSubmit(values, loading)
              }
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating,
            }) => (
              <Row type="flex" justify="center">
                <LangContext.Consumer>
                  {i18n => (
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        <LangContext.Consumer>
                          {i18n => i18n.b.BehaviordrivingReport}
                        </LangContext.Consumer>
                      </div>
                      <BasicFilterReport
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                        division={this.props.division}
                        updateSelectDivision={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectDivision(e, values)
                          })
                        }}
                        department={this.props.department}
                        updateSelectDepartment={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectDepartment(e, values)
                          })
                        }}
                        section={this.props.section}
                        updateSelectSection={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectSection(e, values)
                          })
                        }}
                        plant={this.props.plant}
                        updateSelectPlant={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectPlant(e, values)
                          })
                        }}
                        vehicleGroup={this.props.vehicleGroup}
                        updateSelectVehicleGroup={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectVehicleGroup(e, values)
                          })
                        }}
                      />
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        required="true"
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'error'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div style={{ width: '750px' }}>
                          <TableTransfer
                            dataSource={this.props.vehicleList}
                            targetKeys={this.state.vehicleSelect}
                            showSearch
                            onChange={targetKeys => {
                              this.tranferChange(targetKeys, setFieldValue)
                            }}
                            onSelectChange={this.handleSelectChange}
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                            leftColumns={[
                              {
                                dataIndex: 'name',
                                title: (
                                  <div className="regular-bold-14">
                                    <LangContext.Consumer>
                                      {i18n => i18n.i.itemLeft}
                                    </LangContext.Consumer>
                                  </div>
                                ),
                                posiction: 'Left',
                              },
                            ]}
                            rightColumns={[
                              {
                                dataIndex: 'name',
                                title: (
                                  <div className="regular-bold-14">
                                    <LangContext.Consumer>
                                      {i18n => i18n.i.itemSelected}
                                    </LangContext.Consumer>
                                  </div>
                                ),
                                posiction: 'Right',
                              },
                            ]}
                          />
                        </div>
                      </FormItem>
                      {this.props.call !== 'schedule' ? (
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.d.dateRange}
                            </LangContext.Consumer>
                          }
                          required="true"
                          validateStatus={
                            touched.eventdate && errors.eventdate && 'error'
                          }
                          help={touched.eventdate && errors.eventdate}
                        >
                          <RangePicker
                            disabledDate={disabledDate}
                            showTime={{ format: 'HH:mm' }}
                            onChange={value =>
                              setFieldValue('eventdate', value)
                            }
                            format="DD/MM/YYYY HH:mm"
                            placeholder={['Start Time', 'End Time']}
                            defaultValue={[
                              moment('00:00', 'HH:mm'),
                              moment('23:59', 'HH:mm'),
                            ]}
                          />
                        </Form.Item>
                      ) : (
                        ''
                      )}
                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call == 'schedule' ? (
                            <Col span={8} style={{ marginTop: '10px' }}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            <div>
                              {' '}
                              <Col span={8}>
                                <Button type="defualt" block>
                                  <Link to="/">
                                    <LangContext.Consumer>
                                      {i18n => i18n.c.cancel}
                                    </LangContext.Consumer>
                                  </Link>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  block
                                  onClick={() =>
                                    this.setState({
                                      clickMenu: 'preview',
                                    })
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.preview}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  onClick={() => {
                                    this.setState({
                                      clickMenu: 'report',
                                    })
                                  }}
                                  block
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.r.report}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                            </div>
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  )}
                </LangContext.Consumer>
              </Row>
            )}
          />
        </Spin>
      </div>
    )
  }
}
