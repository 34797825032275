import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Table,
} from 'antd'

import LangContext from 'modules/shared/context/langContext'
import difference from 'lodash/difference'
import axios from 'axios'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Options = Select.Option
const { Option } = Select

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <div
          onClick={() => {
            restProps.onChange(restProps.dataSource.map(item => item.key))
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
        <div
          onClick={() => {
            restProps.onChange([])
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
          //   hideDefaultSelections: true,
          //   selections: [
          //     {
          //       //   key: 'all-data',
          //       text: 'Select All Data',
          //       onSelect: (record, selected, selectedRows, nativeEvent) => {
          //         let getKey = []
          //         filteredItems.map(item => getKey.push(item.key))
          //         onItemSelectAll([...getKey], true)
          //       },
          //     },
          //   ],
        }

        return (
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 100 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }

  state = {
    siteList: [],
    selectedSite: '',
    selectedSiteName: '',
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    vehicleSelectedKeys: [],
    plantList: [],
    plantSelect: [],
    plantSelectedKeys: [],
    dataPreview: [],
    loadPreview: false,
    selectedKeys: [],
    report: true,
  }

  leftTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <LangContext.Consumer>{i18n => i18n.i.itemLeft}</LangContext.Consumer>
      ),
      posiction: 'Left',
    },
  ]

  rightTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <LangContext.Consumer>
          {i18n => i18n.i.itemSelected}
        </LangContext.Consumer>
      ),
      posiction: 'Right',
    },
  ]

  componentDidMount() {
    const { call } = this.props

    if (call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  componentDidUpdate() {
    let btn = document.getElementById('finding-btn')
    if (btn !== null) {
      let botton = btn.querySelectorAll('button')
      this.Leftbtn = botton[0]
      this.Rightbtn = botton[1]
    }
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehiclebygroupid } = this.props
    setFieldValue('vehicle_type_id', value)
    loadVehiclebygroupid(value, true)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicles, vehicleGroup } = nextProps
    const vehlist = []
    const vehiclelists = []
    vehicles.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    vehicles.map(item => vehiclelists.push(item.id))
    this.setState({ vehicleList: vehlist, report: false })

    const vehlistselect = []

    vehicleGroup.map(item => vehlistselect.push(item.id))

    var sameVehicle = []
    for (var i in vehlistselect) {
      if (vehiclelists.indexOf(vehlistselect[i]) > -1) {
        sameVehicle.push(vehlistselect[i])
      }
    }
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleMenuClick = (setFieldValue, handleSubmit, typeFile) => {
    setFieldValue('type_file', typeFile, false)
    handleSubmit()
  }

  handleClicReportkPreview = values => {
    let { start_date, end_date, list_vehicle_id } = values
    let company_id = this.props.company_id
    let user_id = this.props.user_id
    const filter = {
      date_start: start_date,
      date_end: end_date,
      list_vehicle_id,
      company_id: company_id,
      user_id,
      type_file: 'preview',
    }

    const header = {
      headers: {
        Authorization: `Bearer ${this.props.accessToken}`,
      },
    }
    localStorage.setItem(
      'summaryvehicleusagereport-filter',
      JSON.stringify(filter),
    )
    localStorage.setItem(
      'summaryvehicleusagereport-header',
      JSON.stringify(header),
    )

    window.open('/report/summary-fuel-usage/preview', '_blank')
  }

  render() {
    const {
      loading,
      onSubmit,
      division,
      selectedDivision,
      selectedDepartment,
      department,
      section,
      selectedSection,
      plant,
      selectedPlant,
      vehicleGroup,
      selectedVehicleGroup,
      report,
    } = this.props
    function disabledDate(current) {
      return (
        current >
        moment()
          .add(-1, 'days')
          .endOf('day')
      )
    }
    return (
      <div>
        <Spin spinning={loading}>
          <Spin spinning={this.state.loadPreview}>
            <Formik
              initialValues={{
                selectedSiteName: this.state.selectedSiteName,
                selectedSite: this.state.selectedSite,
                start_date: moment()
                  .add(-1, 'days')
                  .format('YYYY-MM-DD 00:00:00'),
                end_date: moment()
                  .add(-1, 'days')
                  .format('YYYY-MM-DD 23:59:59'),
                search_by: '-',
                vehicle_type_id: '',
                alarmalert_type_id: '',
                eventdate: [
                  moment().format('YYYY-MM-DD 00:00:00'),
                  moment().format('YYYY-MM-DD 23:59:59'),
                ],
                list_vehicle_id: [],
                list_plant_id: selectedPlant,
                division: selectedDivision,
                department: selectedDepartment,
                section: selectedSection,
                plant: selectedPlant,
                vehicleGroup: selectedVehicleGroup,
              }}
              validate={values => {
                let errors = {}
                // console.log('values', values)
                // if (values.list_vehicle_id.length > 100) {
                //   errors.list_vehicle_id = 'เลือกรถได้ไม่เกิน 100 คัน'
                // }
                if (values.eventdate.length > 0) {
                  const date_start = moment(values.eventdate[0]['_d'])
                  const date_end = moment(values.eventdate[1]['_d'])
                  let day_diff = date_end.diff(date_start, 'months')
                  //   console.log('dayy', day_diff)
                  if (values.search_by == '-') {
                    if (day_diff > 0) {
                      errors.eventdate = 'ช่วงวันที่ที่เลือกไม่ควรเกิน 1 เดือน'
                    }
                  } else {
                    if (day_diff > 31) {
                      errors.eventdate = 'ช่วงวันที่ที่เลือกไม่ควรเกิน 31 วัน'
                    }
                  }
                }

                return errors
              }}
              validationSchema={yup.object().shape({
                eventdate: yup
                  .array()
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.p.pleaseChooseDateRange}
                    </LangContext.Consumer>,
                  ),
                list_vehicle_id: yup
                  .array()
                  .required(
                    <LangContext.Consumer>
                      {i18n => i18n.r.requiredFieldVehicle}
                    </LangContext.Consumer>,
                  ),
              })}
              onSubmit={values => {
                if (values.type_file === 'preview') {
                  this.handleClicReportkPreview(values)
                } else {
                  onSubmit(values, true)
                }
              }}
              render={({
                values,
                errors,
                touched,
                handleSubmit,
                setFieldValue,
              }) => (
                <Row type="flex" justify="center">
                  <LangContext.Consumer>
                    {i18n => (
                      <Form onSubmit={handleSubmit}>
                        <div className="semi-bold-16">
                          <LangContext.Consumer>
                            {i18n => i18n.s.summaryVehicleUsage}
                          </LangContext.Consumer>
                        </div>
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.d.division}
                            </LangContext.Consumer>
                          }
                        >
                          <Select
                            name="division"
                            id="division"
                            style={{ width: '100%' }}
                            value={values.division}
                            onChange={e => {
                              this.setState({ vehicleSelect: [] }, () => {
                                setFieldValue('division', e)
                                setFieldValue('list_vehicle_id', [])
                                setFieldValue('department', 'all')
                                setFieldValue('section', 'all')
                                setFieldValue('plant', ['all'])
                                setFieldValue('list_plant_id', ['all'])
                                this.props.updateSelectDivisoin(e)
                              })
                            }}
                          >
                            <Option value="all">
                              <LangContext.Consumer>
                                {i18n => i18n.a.all}
                              </LangContext.Consumer>
                            </Option>
                            {division.map(ele => (
                              <Option value={ele.id}>{ele.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.d.department}
                            </LangContext.Consumer>
                          }
                        >
                          <Select
                            name="department"
                            id="department"
                            value={values.department}
                            onChange={e => {
                              this.setState({ vehicleSelect: [] }, () => {
                                setFieldValue('department', e)
                                setFieldValue('section', 'all')
                                setFieldValue('plant', ['all'])
                                setFieldValue('list_plant_id', ['all'])
                                setFieldValue('list_vehicle_id', [])
                                this.props.updateSelectDepartment(e, values)
                              })
                            }}
                            style={{ width: '100%' }}
                            defaultValue="all"
                          >
                            <Option value="all">
                              <LangContext.Consumer>
                                {i18n => i18n.a.all}
                              </LangContext.Consumer>
                            </Option>
                            {department.map(ele => (
                              <Option value={ele.id}>{ele.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.s.section}
                            </LangContext.Consumer>
                          }
                        >
                          <Select
                            name="section"
                            id="section"
                            value={values.section}
                            onChange={e => {
                              this.setState({ vehicleSelect: [] }, () => {
                                setFieldValue('section', e)
                                setFieldValue('list_vehicle_id', [])
                                setFieldValue('plant', ['all'])
                                setFieldValue('list_plant_id', ['all'])
                                this.props.updateSelectSection(e, values)
                              })
                            }}
                            style={{ width: '100%' }}
                            defaultValue="all"
                          >
                            <Option value="all">
                              <LangContext.Consumer>
                                {i18n => i18n.a.all}
                              </LangContext.Consumer>
                            </Option>
                            {section.map(ele => (
                              <Option value={ele.id}>{ele.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.p.plants}
                            </LangContext.Consumer>
                          }
                        >
                          <Select
                            style={{ width: '100%' }}
                            defaultValue="all"
                            mode="multiple"
                            onChange={e => {
                              let val = e
                              if (e.length > 1 && e.includes('all')) {
                                val = e.filter(
                                  (x, i) => (x === 'all' && i === 0) === false,
                                )
                              }

                              if (e.length === 0) {
                                val = 'all'
                              }

                              if (val.length > 1 && e.includes('all')) {
                                val = e.filter(x => x === 'all')
                              }

                              this.setState({ vehicleSelect: [] }, () => {
                                setFieldValue('list_vehicle_id', [])
                                setFieldValue('plant', val)
                                setFieldValue('list_plant_id', val)
                                this.props.updateSelectPlant(val, values)
                              })
                            }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.props.value
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            value={values.plant}
                          >
                            <Option value="all">
                              <LangContext.Consumer>
                                {i18n => i18n.a.all}
                              </LangContext.Consumer>
                            </Option>
                            {plant.map(ele => (
                              <Option value={`${ele.id}`} key={ele.id}>
                                {ele.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.v.vehicleGroup}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.vehicle_type_id &&
                            errors.vehicle_type_id &&
                            'error'
                          }
                          help={
                            touched.vehicle_type_id && errors.vehicle_type_id
                          }
                        >
                          <Select
                            name="vehicleGroup"
                            id="vehicleGroup"
                            onChange={e => {
                              this.setState({ vehicleSelect: [] }, () => {
                                setFieldValue('list_vehicle_id', [])
                                setFieldValue('vehicleGroup', e)
                                this.props.updateSelectVehicleGroup(e, values)
                              })
                            }}
                            defaultValue="all"
                            value={values.vehicleGroup}
                          >
                            <Options value="all">
                              <LangContext.Consumer>
                                {i18n => i18n.a.all}
                              </LangContext.Consumer>
                            </Options>
                            {vehicleGroup.map(item => (
                              <Options key={item.id} value={item.id}>
                                {item.name}
                              </Options>
                            ))}
                          </Select>
                        </FormItem>

                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.v.vehicle}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.list_vehicle_id &&
                            errors.list_vehicle_id &&
                            'warning'
                          }
                          help={
                            touched.list_vehicle_id && errors.list_vehicle_id
                          }
                        >
                          <div style={{ width: '750px' }}>
                            <TableTransfer
                              name="list_vehicle_id"
                              dataSource={
                                report && this.state.report
                                  ? this.props.vehicleList
                                  : this.state.vehicleList
                              }
                              targetKeys={this.state.vehicleSelect}
                              selectedKeys={this.state.selectedKeys}
                              showSearch
                              filterOption={(inputValue, item) => {
                                return (
                                  item.name
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                                )
                              }}
                              onSelectChange={this.handleSelectChange}
                              onChange={value =>
                                this.tranferChange(value, setFieldValue)
                              }
                              leftColumns={this.leftTableColumns}
                              rightColumns={this.rightTableColumns}
                            />
                          </div>
                        </FormItem>

                        {this.props.call !== 'schedule' ? (
                          <Form.Item
                            label={i18n.d.dateRange}
                            required={true}
                            validateStatus={
                              touched.eventdate && errors.eventdate && 'warning'
                            }
                            help={touched.eventdate && errors.eventdate}
                          >
                            <RangePicker
                              disabledDate={disabledDate}
                              showTime={{ format: 'HH:mm' }}
                              format="DD/MM/YYYY HH:mm"
                              defaultValue={[
                                moment(values.start_date, 'DD/MM/YYYY HH:mm'),
                                moment(values.end_date, 'DD/MM/YYYY HH:mm'),
                              ]}
                              value={[
                                moment(values.start_date),
                                moment(values.end_date),
                              ]}
                              onChange={value => {
                                if (value.length > 0) {
                                  setFieldValue('eventdate', value)
                                  setFieldValue(
                                    'start_date',
                                    moment(value[0]).format(
                                      'YYYY-MM-DD HH:mm:00',
                                    ),
                                  )
                                  setFieldValue(
                                    'end_date',
                                    moment(value[1]).format(
                                      'YYYY-MM-DD HH:mm:59',
                                    ),
                                  )
                                } else {
                                  setFieldValue(
                                    'start_date',
                                    moment().format('YYYY-MM-DD 00:00:00'),
                                  )
                                  setFieldValue(
                                    'end_date',
                                    moment().format('YYYY-MM-DD 23:59:59'),
                                  )
                                  setFieldValue('eventdate', [
                                    moment().format('YYYY-MM-DD 00:00:00'),
                                    moment().format('YYYY-MM-DD 23:59:59'),
                                  ])
                                }
                              }}
                            />
                          </Form.Item>
                        ) : (
                          ''
                        )}

                        <FormItem>
                          <Row gutter={24}>
                            {this.props.call == 'schedule' ? (
                              <Col span={8} style={{ marginTop: '10px' }}>
                                <Button type="primary" htmlType="submit" block>
                                  <LangContext.Consumer>
                                    {i18n => i18n.s.save}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                            ) : (
                              <div>
                                <Col span={8}>
                                  <Button type="defualt" block>
                                    <Link to="/">
                                      <LangContext.Consumer>
                                        {i18n => i18n.c.cancel}
                                      </LangContext.Consumer>
                                    </Link>
                                  </Button>
                                </Col>
                                <Col span={8} ali>
                                  <Button
                                    type="primary"
                                    onClick={() =>
                                      this.handleMenuClick(
                                        setFieldValue,
                                        handleSubmit,
                                        'preview',
                                      )
                                    }
                                    block
                                  >
                                    <LangContext.Consumer>
                                      {i18n => i18n.p.preview}
                                    </LangContext.Consumer>
                                  </Button>
                                </Col>
                                <Col span={8}>
                                  <Button
                                    type="primary"
                                    onClick={() =>
                                      this.handleMenuClick(
                                        setFieldValue,
                                        handleSubmit,
                                        'excel',
                                      )
                                    }
                                    block
                                  >
                                    <LangContext.Consumer>
                                      {i18n => i18n.r.report}
                                    </LangContext.Consumer>
                                  </Button>
                                </Col>
                              </div>
                            )}
                          </Row>
                        </FormItem>
                      </Form>
                    )}
                  </LangContext.Consumer>
                </Row>
              )}
            />
          </Spin>
        </Spin>
      </div>
    )
  }
}
