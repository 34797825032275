import { createTypes, createAction } from 'lib/action'
const LOAD_MASTERDATA_SUB_MATERIAL = createTypes(
  'loadmasterdatasubmaterial',
  'load',
)
const CREATE_MASTERDATA_SUB_MATERIAL = createTypes(
  'createmasterdatasubmaterial',
  'create',
)
const LOAD_SUB_MATERIAL_DETAIL = createTypes('loadsubmaterialdetail', 'load')
const UPDATE_MASTERDATA_SUB_MATERIAL = createTypes(
  'updatesubmaterialdetail',
  'update',
)
const EXPORT_MASTERDATA_SUB_MATERIAL = createTypes(
  'exportmasterdatasubmaterial',
  'load',
)
const IMPORT_SUB_MATERIAL = createTypes('importsubmaterial', 'create')

const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadMasterDataSubMaterial = {
  request: (data, loading) =>
    createAction(LOAD_MASTERDATA_SUB_MATERIAL.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_MASTERDATA_SUB_MATERIAL.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_MASTERDATA_SUB_MATERIAL.FAITLURE),
}

const createMasterDataSubMaterial = {
  request: (data, loading) =>
    createAction(CREATE_MASTERDATA_SUB_MATERIAL.REQUEST, { data, loading }),
  success: res => createAction(CREATE_MASTERDATA_SUB_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(CREATE_MASTERDATA_SUB_MATERIAL.FAITLURE),
}

const loadSubMaterialDetail = {
  request: (data, loading) =>
    createAction(LOAD_SUB_MATERIAL_DETAIL.REQUEST, { data, loading }),
  success: listsEdit =>
    createAction(LOAD_SUB_MATERIAL_DETAIL.SUCCESS, { listsEdit }),
  failure: () => createAction(LOAD_SUB_MATERIAL_DETAIL.FAITLURE),
}

const updateMasterDataSubMaterial = {
  request: (data, loading) =>
    createAction(UPDATE_MASTERDATA_SUB_MATERIAL.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_MASTERDATA_SUB_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(UPDATE_MASTERDATA_SUB_MATERIAL.FAITLURE),
}

const exportMasterDataSubMaterial = {
  request: (data, loading) =>
    createAction(EXPORT_MASTERDATA_SUB_MATERIAL.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_MASTERDATA_SUB_MATERIAL.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_MASTERDATA_SUB_MATERIAL.FAITLURE),
}

const importtSubMaterial = {
  request: (data, loading) =>
    createAction(IMPORT_SUB_MATERIAL.REQUEST, { data, loading }),
  success: res => createAction(IMPORT_SUB_MATERIAL.SUCCESS, { res }),
  failure: () => createAction(IMPORT_SUB_MATERIAL.FAITLURE),
}

export {
  CREATE_MASTERDATA_SUB_MATERIAL,
  createMasterDataSubMaterial,
  UPDATE_MASTERDATA_SUB_MATERIAL,
  updateMasterDataSubMaterial,
  LOAD_SUB_MATERIAL_DETAIL,
  loadSubMaterialDetail,
  LOAD_MASTERDATA_SUB_MATERIAL,
  loadMasterDataSubMaterial,
  EXPORT_MASTERDATA_SUB_MATERIAL,
  exportMasterDataSubMaterial,
  importtSubMaterial,
  IMPORT_SUB_MATERIAL,
  REMOVE_LISTS,
  removeLists,
}
