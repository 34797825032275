import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Button, Row, Col, Icon, Spin } from "antd";
import { ResponsivePie } from "@nivo/pie";
import { getVehicleVisibility } from "../../../../lib/helper";

import { StyledWidget } from "../../../../styled/common-styled";
const fakeData = [
    {
        id: "running",
        label: "running",
        value: 24,
    },
    {
        id: "stop_with_engine_on",
        label: "stop_with_engine_on",
        value: 6,
    },
    {
        id: "stop_with_engine_off",
        label: "stop_with_engine_off",
        value: 12,
    },
    {
        id: "running_with_speed_over_limit",
        label: "running_with_speed_over_limit",
        value: 3,
        color: "hsl(112, 70%, 50%)",
    },
];

const MyResponsivePie = ({ i18n, data /* see data tab */ }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={0}
        colors={["#00BF06", "#c91c1c", "#ffd619", "#848484"]}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabel={(d) => d.label}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: "color" }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        showInLegend={true}
        legends={[
            {
                anchor: "bottom",
                direction: "row",
                translateY: 56,
                itemWidth: 110,
                itemHeight: 18,
                itemTextColor: "#999",
                symbolSize: 16,
                symbolShape: "circle",
                effects: [
                    {
                        on: "hover",
                        style: {
                            itemTextColor: "#000",
                        },
                    },
                ],
            },
        ]}
    />
);

export class VehicleStatusPieChartWidget extends Component {
    state = {
        loading: false,
    };

    loadData = (loading) => {
        if (this.props.callingApi) {
            if (loading) this.setState({ loading: true });
            this.fetch(this);
        }
    };

    fetch = (self) => {
        let visibility = getVehicleVisibility(
            self.props.auth.profile.user_type,
            self.props.auth.profile.vehicle_visibility,
            self.props.auth.profile.org_chart_vehicle_visibility,
            self.props.auth.profile.data_visibility_node
        );
        let formData = new FormData();
        formData.append("user_id", self.props.auth.profile.id);
        formData.append("company_id", self.props.auth.profile.company_id);
        formData.append("vehicle_visibility", visibility);

        fetch(
            `${process.env.REACT_APP_GO_V2_API_ENDPOINT}/summary/vehicle_status`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${self.props.auth.accessToken}`,
                },
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((res) => {
                if (Array.isArray(res)) {
                    self.setState({
                        loading: false,
                    });
                    self.props.handdleParentStateChange("vehicleStatusPieData", res);
                    res.map((ele) =>
                        self.props.handdleParentStateChange(ele.label, ele.value)
                    );
                }
            })
            .catch(function () {
                self.setState({
                    loading: false,
                });
            });
    };

    componentDidMount() {
        if (!this.props.demo) {
            this.loadData(true);
            this.interval = setInterval(() => this.loadData(false), 1000 * 120);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if (this.props.demo)
            return (
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{ height: "95%", width: "100%" }}
                >
                    <h4>
                        <LangContext.Consumer>
                            {(i18n) => i18n.v.vehicleStatus}
                        </LangContext.Consumer>{" "}
                    </h4>
                    <MyResponsivePie data={fakeData} />
                </Col>
            );
        else
            return (
                <StyledWidget>
                    <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        style={{ height: "95%" }}
                    >
                        <span className='semi-bold-16'>
                            <LangContext.Consumer>
                                {(i18n) => i18n.v.vehicleStatus}
                            </LangContext.Consumer>
                        </span>

                        <Spin
                            spinning={this.state.loading}
                            style={{
                                textAlign: "center",
                                display: "block",
                                marginTop: "20%",
                                marginLeft: "auto",
                                marginRight: "auto",
                                fontSize: '14px'
                            }}
                        />
                        <LangContext.Consumer>
                            {(i18n) => (
                                <MyResponsivePie
                                    i18n={i18n}
                                    data={this.props.vehicleStatusPieData.map((ele) => {
                                        let langKey = { alp: null, name: null };
                                        switch (ele.label) {
                                            case "running":
                                                langKey = { alp: "r", name: "running" };
                                                break;
                                            case "stop_with_engine_off":
                                                langKey = { alp: "e", name: "engineOff" };
                                                break;
                                            case "stop_with_engine_on":
                                                langKey = { alp: "s", name: "stopWithEngineOn" };
                                                break;
                                            case "connection_error":
                                                langKey = { alp: "l", name: "lostConnectio" };
                                                break;

                                            default:
                                                break;
                                        }

                                        return {
                                            id: i18n[langKey.alp][langKey.name],
                                            label: i18n[langKey.alp][langKey.name],
                                            value: ele.value,
                                        };
                                    })}
                                />
                            )}
                        </LangContext.Consumer>
                    </Col>
                </StyledWidget>
            );
    }
}

export default VehicleStatusPieChartWidget;
