import {
  LOAD_VEHICLE_GPS_DISCONNECT_ON_TRIP_REPORT,
  LOAD_GPS_VENDOR,
  EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT,
  EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT_PREVIEW,
} from './actions'

const initialState = {
  vehicleLists: [],
  GPSVendorLists: [],
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VEHICLE_GPS_DISCONNECT_ON_TRIP_REPORT.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOAD_VEHICLE_GPS_DISCONNECT_ON_TRIP_REPORT.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        loading: false,
      }

    case LOAD_GPS_VENDOR.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOAD_GPS_VENDOR.SUCCESS:
      return {
        ...state,
        GPSVendorLists: action.payload.data.data,
      }

    case EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT.REQUEST:
    case EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT_PREVIEW.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT.SUCCESS:
    case EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT_PREVIEW.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
