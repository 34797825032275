import {
  LOAD_ZONE,
  LOAD_AUTOCOMPLETE,
  CREATE_MASTERDATA_ZONE,
  LOAD_DETAIL_ZONE,
  UPDATE_ZONE,
  REMOVE_LISTS,
  EXPORT_MATERDATA_ZONE,
} from './actions'

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  autoComplete: [],
  check: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_ZONE.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        loading: loading,
      }

    case LOAD_ZONE.SUCCESS:
      const { page } = action.payload

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10
      }

      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case LOAD_DETAIL_ZONE.REQUEST:
      return {
        ...state,
        loading: action.payload.data.loading,
      }

    case LOAD_DETAIL_ZONE.SUCCESS:
      return {
        ...state,
        lists: action.payload.lists.data,
        check: false,
      }

    case LOAD_AUTOCOMPLETE.SUCCESS:
      return {
        ...state,
        autoComplete: action.payload,
      }

    case CREATE_MASTERDATA_ZONE.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_ZONE.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case EXPORT_MATERDATA_ZONE.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_MATERDATA_ZONE.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
