import React, { Component } from 'react'
import {
  Table,
  Badge,
  Collapse,
  Row,
  Col,
  Button,
  Modal,
  Input,
  Form,
} from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import LangContext from 'modules/shared/context/langContext'
import { Link, Redirect } from 'react-router-dom'

const { Panel } = Collapse
const { TextArea } = Input
const FormItem = Form.Item
const genExtra = value => <h>{value}</h>

const columnsTypeNormal = [
  {
    title: (
      <span style={{ fontSize: '13px' }}>
        <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer> (
        <LangContext.Consumer>{i18n => i18n.v.vnCode}</LangContext.Consumer>)
      </span>
    ),
    dataIndex: 'plate_no',
    className: 'column-text-center',
    key: 'plate_no',
  },
  {
    title: (
      <span style={{ fontSize: '13px' }}>
        <LangContext.Consumer>{i18n => i18n.p.period}</LangContext.Consumer> (
        <LangContext.Consumer>{i18n => i18n.m.minute}</LangContext.Consumer>)
      </span>
    ),
    className: 'column-text-center',
    dataIndex: 'duration',
    key: 'duration',
    render(text, record, index) {
      return {
        children: (
          <div>
            {record.durationMinute}:{text}
          </div>
        ),
      }
    },
  },
  {
    title: (
      <span style={{ fontSize: '13px' }}>
        <LangContext.Consumer>{i18n => i18n.p.plantName}</LangContext.Consumer>
      </span>
    ),
    dataIndex: 'plantPunchInName',
    className: 'column-text-center',
    key: 'plantPunchInName',
    width: '20%',
    render: (text, record, index) => {
      return <label>{text == '' ? '-' : text}</label>
    },
  },
  {
    title: (
      <span style={{ fontSize: '13px' }}>
        <LangContext.Consumer>
          {i18n => i18n.s.siteNameSafetyDashboard}
        </LangContext.Consumer>
      </span>
    ),
    dataIndex: 'siteName',
    className: 'column-text-center',
    key: 'siteName',
    width: '20%',
    render: (text, record, index) => {
      return <label>{text == '' ? '-' : text}</label>
    },
  },
]

const columnsTypeNormal2 = [
  {
    title: (
      <span style={{ fontSize: '13px' }}>
        <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer> (
        <LangContext.Consumer>{i18n => i18n.v.vnCode}</LangContext.Consumer>)
      </span>
    ),
    dataIndex: 'plate_no',
    className: 'column-text-center',
    key: 'plate_no',
    width: '30%',
  },
  {
    title: (
      <span style={{ fontSize: '13px' }}>
        <LangContext.Consumer>{i18n => i18n.p.period}</LangContext.Consumer> (
        <LangContext.Consumer>{i18n => i18n.m.minute}</LangContext.Consumer>)
      </span>
    ),
    className: 'column-text-center',
    dataIndex: 'startAt',
    key: 'startAt',
    width: '30%',

    render(text, record, index) {
      return {
        children: <div>{text}</div>,
      }
    },
  },
  {
    title: (
      <span style={{ fontSize: '13px' }}>
        <LangContext.Consumer>{i18n => i18n.p.plantName}</LangContext.Consumer>
      </span>
    ),
    dataIndex: 'plantPunchInName',
    className: 'column-text-center',
    key: 'plantPunchInName',
    width: '20%',
    render: (text, record, index) => {
      return <label>{text == '' ? '-' : text}</label>
    },
  },
  {
    title: (
      <span style={{ fontSize: '13px' }}>
        <LangContext.Consumer>
          {i18n => i18n.s.siteNameSafetyDashboard}
        </LangContext.Consumer>
      </span>
    ),
    dataIndex: 'siteName',
    className: 'column-text-center',
    key: 'siteName',
    width: '20%',
    render: (text, record, index) => {
      return <label>{text == '' ? '-' : text}</label>
    },
  },
]

export class ExpandableTable extends Component {
  state = {
    selectedRow: '',
    visibleNote: false,
    visibleNoteSpeedOverlimite: false,
    tempNote: null,
    tempId: null,
  }
  onRowClick = (id, lat, lng) => {
    this.props.onRowClick(lat, lng)
    this.setState({ selectedRow: id })
  }

  handleSaveNote = () => {
    this.props.onSaveNote({
      note: this.state.tempNote,
      _id: this.state.tempId,
    })
  }

  columnsTypeSpeed = [
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>{' '}
          (<LangContext.Consumer>{i18n => i18n.v.vnCode}</LangContext.Consumer>)
        </span>
      ),
      dataIndex: 'plate_no',
      key: 'plate_no',
      className: 'column-text-center',
    },
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>{i18n => i18n.p.period}</LangContext.Consumer> (
          <LangContext.Consumer>{i18n => i18n.m.minute}</LangContext.Consumer>)
        </span>
      ),
      className: 'column-text-center',
      dataIndex: 'duration',
      key: 'duration',
      width: '10%',
      render(text, record, index) {
        return {
          children: (
            <div>
              {record.durationMinute}:{text}
            </div>
          ),
        }
      },
    },
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>
            {i18n => i18n.dashboard_summary.maxSpeed}
          </LangContext.Consumer>{' '}
          (<LangContext.Consumer>{i18n => i18n.k.kmH}</LangContext.Consumer>)
        </span>
      ),
      width: '10%',
      className: 'column-text-center',
      dataIndex: 'maxSpeed',
      key: 'maxSpeed',
    },
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>{i18n => i18n.t.type}</LangContext.Consumer>
        </span>
      ),
      dataIndex: 'type',
      className: 'column-text-center',
      ellipsis: true,
      key: 'type',
      width: '10%',
    },
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>
            {i18n => i18n.p.plantName}
          </LangContext.Consumer>
        </span>
      ),
      width: '10%',
      dataIndex: 'plantPunchInName',
      className: 'column-text-center',
      key: 'plantPunchInName',
      render: (text, record, index) => {
        return <label>{text == '' ? '-' : text}</label>
      },
    },
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>
            {i18n => i18n.s.siteNameSafetyDashboard}
          </LangContext.Consumer>
        </span>
      ),
      dataIndex: 'siteName',
      className: 'column-text-center',
      key: 'siteName',
      width: '23%',
      render: (text, record, index) => {
        return <label>{text == '' ? '-' : text}</label>
      },
    },
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>{i18n => i18n.n.note}</LangContext.Consumer>
        </span>
      ),
      dataIndex: 'note',
      key: 'note',
      className: 'column-text-center',
      width: '15%',
      render: (text, record, index) => {
        return (
          <Button
            onClick={() => {
              this.setState({
                visibleNoteSpeedOverlimite: true,
                tempNote: record.note,
                tempId: record.id,
              })
            }}
            size="small"
            type={record.note ? 'primary' : 'default'}
          >
            <LangContext.Consumer>
              {i18n => i18n.n.noteFill}
            </LangContext.Consumer>
          </Button>
        )
      },
    },
  ]

  columnsTypeNormals = [
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>{' '}
          (<LangContext.Consumer>{i18n => i18n.v.vnCode}</LangContext.Consumer>)
        </span>
      ),
      dataIndex: 'plate_no',
      className: 'column-text-center',
      key: 'plate_no',
      width: '20%',
    },

    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>{i18n => i18n.p.period}</LangContext.Consumer> (
          <LangContext.Consumer>{i18n => i18n.m.minute}</LangContext.Consumer>)
        </span>
      ),
      className: 'column-text-center',
      dataIndex: 'duration',
      key: 'duration',
      width: '10%',
      render(text, record, index) {
        return {
          children: (
            <div>
              {record.durationMinute}:{text}
            </div>
          ),
        }
      },
    },
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>
            {i18n => i18n.p.parkingDateTime}
          </LangContext.Consumer>
        </span>
      ),
      className: 'column-text-center',
      dataIndex: 'startAt',
      key: 'startAt',
      width: '20%',
    },
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>
            {i18n => i18n.p.plantName}
          </LangContext.Consumer>
        </span>
      ),
      dataIndex: 'plantPunchInName',
      className: 'column-text-center',
      key: 'plantPunchInName',
      width: '20%',
      render: (text, record, index) => {
        return <label>{text == '' ? '-' : text}</label>
      },
    },
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>
            {i18n => i18n.s.siteNameSafetyDashboard}
          </LangContext.Consumer>
        </span>
      ),
      dataIndex: 'siteName',
      className: 'column-text-center',
      key: 'siteName',
      width: '20%',
      render: (text, record, index) => {
        return (
          <Link to={`/location/edit/${record.siteID}`}>
            {text == '' ? '-' : text}
          </Link>
        )
      },
    },
    {
      title: (
        <span style={{ fontSize: '13px' }}>
          <LangContext.Consumer>{i18n => i18n.n.note}</LangContext.Consumer>
        </span>
      ),
      dataIndex: 'note',
      key: 'note',
      className: 'column-text-center',
      width: '20%',
      render: (text, record, index) => {
        return (
          <Button
            onClick={e => {
              this.setState({
                visibleNote: true,
                tempNote: record.note,
                tempId: record.id,
              })
            }}
            size="small"
            type={record.note ? 'primary' : 'default'}
          >
            <LangContext.Consumer>
              {i18n => i18n.n.noteFill}
            </LangContext.Consumer>
          </Button>
        )
      },
    },
  ]

  render() {
    return (
      <div>
        <Collapse
          defaultActiveKey={['1']}
          // onChange={callback}
          expandIconPosition={'right'}
        >
          {/* จุดนอกจุดจอด */}
          <Panel
            header={
              <Badge
                className="safety-badge-text"
                color="#c91c1c"
                text={
                  <>
                    <LangContext.Consumer>
                      {i18n => i18n.p.parkingOnRoadSide}
                    </LangContext.Consumer>
                  </>
                }
              />
            }
            key="1"
            extra={genExtra(
              this.props.list
                .filter(ele => ele.eventType == 3000)
                .length.toLocaleString(),
            )}
          >
            <Table
              rowKey={(record, i) => record.key + '_' + i}
              rowClassName={(record, index) =>
                record.id === this.state.selectedRow ? 'highlight-row' : ''
              }
              className="tracking-table"
              dataSource={this.props.list
                .filter(ele => ele.eventType == 3000)
                .map((ele, i) => {
                  return {
                    plate_no: ele.plateNo + ' (' + ele.vehicleCode + ')',
                    duration: moment
                      .utc(
                        moment(ele.endAt, 'YYYY-MM-DD HH:mm:ss').diff(
                          moment(ele.startAt, 'YYYY-MM-DD HH:mm:ss'),
                        ),
                      )
                      .format('ss'),
                    durationMinute: moment
                      .utc(ele.endAt, 'YYYY-MM-DD HH:mm:ss')
                      .diff(
                        moment.utc(ele.startAt, 'YYYY-MM-DD HH:mm:ss'),
                        'minutes',
                      ),
                    // moment
                    //   .utc(ele.endAt, 'YYYY-MM-DD HH:mm:ss')
                    //   .diff(moment.utc(ele.startAt, 'YYYY-MM-DD HH:mm:ss'))
                    //   .format('HH:mm:ss')
                    startAt: moment(ele.startAt).format('DD/MM/YYYY HH:mm'),
                    partner: ele.partner,
                    lat: ele.startLat,
                    lng: ele.startLng,
                    id: ele._id,
                    note: ele.note,
                    plantPunchInCode: ele.plantPunchInCode,
                    plantPunchInID: ele.plantPunchInID,
                    plantPunchInName: ele.plantPunchInName,

                    siteCode: ele.siteCode,
                    siteID: ele.siteID,
                    siteName: ele.siteName,
                  }
                })}
              //   dataSource={fakeData}
              columns={this.columnsTypeNormals}
              onRow={record => {
                return {
                  onClick: () => {
                    this.onRowClick(record.id, record.lat, record.lng)
                  },
                }
              }}
              size="small"
              scroll={{ x: false, y: false }}
              bordered
            />

            <Modal
              visible={this.state.visibleNote}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.n.noteFill}
                </LangContext.Consumer>
              }
              onCancel={e => {
                this.setState({ visibleNote: false })
              }}
              onOk={e => {
                this.handleSaveNote()
                this.setState({ visibleNote: false })
              }}
            >
              <FormItem
                label={
                  <LangContext.Consumer>
                    {i18n => i18n.d.detail}
                  </LangContext.Consumer>
                }
                required
              >
                <TextArea
                  value={this.state.tempNote}
                  onChange={e => {
                    this.setState({ tempNote: e.target.value })
                  }}
                  rows={4}
                />
              </FormItem>
            </Modal>
          </Panel>
          {/* ความเร็วเกินกำหนด */}
          <Panel
            header={
              <Badge
                className="safety-badge-text"
                color="#00BF06"
                text={
                  <LangContext.Consumer>
                    {i18n => i18n.s.speedOverLimit}
                  </LangContext.Consumer>
                }
              />
            }
            key="2"
            extra={genExtra(
              this.props.list
                .filter(ele => ele.eventType == 1001)
                .length.toLocaleString(),
            )}
          >
            <Table
              rowKey={(record, i) => record.key + '_' + i}
              rowClassName={(record, index) =>
                record.id === this.state.selectedRow ? 'highlight-row' : ''
              }
              className="tracking-table"
              dataSource={this.props.list
                .filter(ele => ele.eventType == 1001)
                .map((ele, i) => {
                  let type = '-'
                  if (ele.alarmType == 1001001) type = 'พื้นที่ทั่วไป'
                  if (ele.alarmType == 1001002) type = 'พื้นที่ชุมชน'
                  if (ele.alarmType == 1001003) type = 'โค้งอันตราย'
                  if (ele.alarmType == 1001004) type = 'โค้งทั่วไป'
                  return {
                    plate_no: ele.plateNo + ' (' + ele.vehicleCode + ')',
                    duration: moment
                      .utc(
                        moment(ele.endAt, 'YYYY-MM-DD HH:mm:ss').diff(
                          moment(ele.startAt, 'YYYY-MM-DD HH:mm:ss'),
                        ),
                      )
                      .format('ss'),
                    durationMinute: moment
                      .utc(ele.endAt, 'YYYY-MM-DD HH:mm:ss')
                      .diff(
                        moment.utc(ele.startAt, 'YYYY-MM-DD HH:mm:ss'),
                        'minutes',
                      ),
                    type: type,
                    maxSpeed: Math.ceil(ele.avgValue),
                    partner: ele.partner,
                    lat: ele.startLat,
                    lng: ele.startLng,
                    id: ele._id,
                    note: ele.note,
                    plantPunchInCode: ele.plantPunchInCode,
                    plantPunchInID: ele.plantPunchInID,
                    plantPunchInName: ele.plantPunchInName,
                    siteCode: ele.siteCode,
                    siteID: ele.siteID,
                    siteName: ele.siteName,
                  }
                })}
              //   dataSource={fakeData}
              columns={this.columnsTypeSpeed}
              size="small"
              onRow={record => {
                return {
                  onClick: () => {
                    this.onRowClick(record.id, record.lat, record.lng)
                  },
                }
              }}
              scroll={{ x: false, y: false }}
              bordered
            />
            <Modal
              visible={this.state.visibleNoteSpeedOverlimite}
              title={
                <LangContext.Consumer>
                  {i18n => i18n.n.noteFill}
                </LangContext.Consumer>
              }
              onCancel={e => {
                this.setState({ visibleNoteSpeedOverlimite: false })
              }}
              onOk={e => {
                this.handleSaveNote()
                this.setState({ visibleNoteSpeedOverlimite: false })
              }}
            >
              <FormItem
                label={
                  <LangContext.Consumer>
                    {i18n => i18n.d.detail}
                  </LangContext.Consumer>
                }
                required
              >
                <TextArea
                  value={this.state.tempNote}
                  onChange={e => {
                    this.setState({ tempNote: e.target.value })
                  }}
                  rows={4}
                />
              </FormItem>
            </Modal>
          </Panel>
          {/* ขับต่อเนื่องมากกว่า 4 ชม */}
          <Panel
            header={
              <Badge
                className="safety-badge-text"
                color="#ffd619"
                text={
                  <LangContext.Consumer>
                    {i18n => i18n.d.driveContinuouslyForMoreThan4Hours}
                  </LangContext.Consumer>
                }
              />
            }
            key="3"
            extra={genExtra(
              this.props.list
                .filter(
                  ele => ele.eventType == 7002 && ele.alarmType == 'Continuous',
                )
                .length.toLocaleString(),
            )}
          >
            <Table
              rowKey={(record, i) => record.key + '_' + i}
              rowClassName={(record, index) =>
                record.id === this.state.selectedRow ? 'highlight-row' : ''
              }
              className="tracking-table"
              dataSource={this.props.list
                .filter(
                  ele => ele.eventType == 7002 && ele.alarmType == 'Continuous',
                )
                .map((ele, i) => {
                  return {
                    plate_no: ele.plateNo + ' (' + ele.vehicleCode + ')',
                    duration: moment
                      .utc(
                        moment(ele.endAt, 'YYYY-MM-DD HH:mm:ss').diff(
                          moment(ele.startAt, 'YYYY-MM-DD HH:mm:ss'),
                        ),
                      )
                      .format('ss'),
                    durationMinute: moment
                      .utc(ele.endAt, 'YYYY-MM-DD HH:mm:ss')
                      .diff(
                        moment.utc(ele.startAt, 'YYYY-MM-DD HH:mm:ss'),
                        'minutes',
                      ),
                    maxSpeed: '-',
                    partner: ele.partner,
                    lat: ele.startLat,
                    lng: ele.startLng,
                    id: ele._id,
                    plantPunchInCode: ele.plantPunchInCode,
                    plantPunchInID: ele.plantPunchInID,
                    plantPunchInName: ele.plantPunchInName,
                    siteCode: ele.siteCode,
                    siteID: ele.siteID,
                    siteName: ele.siteName,
                  }
                })}
              columns={columnsTypeNormal}
              size="small"
              onRow={record => {
                return {
                  onClick: () => {
                    this.onRowClick(record.id, record.lat, record.lng)
                  },
                }
              }}
              scroll={{ x: false, y: false }}
              bordered
            />
          </Panel>
          {/* ปฎิบัติงานมากกว่า 10 ชมต่อวัน */}
          <Panel
            header={
              <Badge
                className="safety-badge-text"
                color="#848484"
                text={
                  <LangContext.Consumer>
                    {i18n => i18n.d.driveContinuouslyForMoreThan10HoursDay}
                  </LangContext.Consumer>
                }
              />
            }
            key="4"
            extra={genExtra(
              this.props.list
                .filter(
                  ele => ele.eventType == 7002 && ele.alarmType == 'Accumulate',
                )
                .length.toLocaleString(),
            )}
          >
            <Table
              rowKey={(record, i) => record.key + '_' + i}
              rowClassName={(record, index) =>
                record.id === this.state.selectedRow ? 'highlight-row' : ''
              }
              className="tracking-table"
              dataSource={this.props.list
                .filter(
                  ele => ele.eventType == 7002 && ele.alarmType == 'Accumulate',
                )
                .map((ele, i) => {
                  return {
                    plate_no: ele.plateNo + ' (' + ele.vehicleCode + ')',
                    duration: moment
                      .utc(
                        moment(ele.endAt, 'YYYY-MM-DD HH:mm:ss').diff(
                          moment(ele.startAt, 'YYYY-MM-DD HH:mm:ss'),
                        ),
                      )
                      .format('ss'),
                    durationMinute: moment
                      .utc(ele.endAt, 'YYYY-MM-DD HH:mm:ss')
                      .diff(
                        moment.utc(ele.startAt, 'YYYY-MM-DD HH:mm:ss'),
                        'minutes',
                      ),

                    maxSpeed: '-',
                    partner: ele.partner,
                    lat: ele.startLat,
                    lng: ele.startLng,
                    id: ele._id,
                    plantPunchInCode: ele.plantPunchInCode,
                    plantPunchInID: ele.plantPunchInID,
                    plantPunchInName: ele.plantPunchInName,
                    siteCode: ele.siteCode,
                    siteID: ele.siteID,
                    siteName: ele.siteName,
                  }
                })}
              columns={columnsTypeNormal}
              size="small"
              onRow={record => {
                return {
                  onClick: () => {
                    this.onRowClick(record.id, record.lat, record.lng)
                  },
                }
              }}
              scroll={{ x: false, y: false }}
              bordered
            />
          </Panel>
          {/* ออกตัวกระชาก และเบรคกระทันหัน */}
          <Panel
            header={
              <Badge
                className="safety-badge-text"
                color="purple"
                text={
                  <LangContext.Consumer>
                    {i18n => i18n.a.aggressiveAccelerationAndImmediatelyBreak}
                  </LangContext.Consumer>
                }
              />
            }
            key="6"
            extra={genExtra(
              this.props.list
                .filter(ele => ele.eventType == 1002 || ele.eventType == 2050)
                .length.toLocaleString(),
            )}
          >
            <Table
              rowKey={(record, i) => record.key + '_' + i}
              rowClassName={(record, index) =>
                record.id === this.state.selectedRow ? 'highlight-row' : ''
              }
              className="tracking-table"
              dataSource={this.props.list
                .filter(ele => ele.eventType == 1002 || ele.eventType == 2050)
                .map((ele, i) => {
                  return {
                    plate_no: ele.plateNo + ' (' + ele.vehicleCode + ')',
                    duration: moment
                      .utc(
                        moment(ele.endAt, 'YYYY-MM-DD HH:mm:ss').diff(
                          moment(ele.startAt, 'YYYY-MM-DD HH:mm:ss'),
                        ),
                      )
                      .format('ss'),
                    maxSpeed: '-',
                    startAt: moment(ele.startAt).format('DD/MM/YYYY HH:mm'),
                    partner: ele.partner,
                    lat: ele.startLat,
                    lng: ele.startLng,
                    id: ele._id,
                    plantPunchInCode: ele.plantPunchInCode,
                    plantPunchInID: ele.plantPunchInID,
                    plantPunchInName: ele.plantPunchInName,
                    siteCode: ele.siteCode,
                    siteID: ele.siteID,
                    siteName: ele.siteName,
                  }
                })}
              columns={columnsTypeNormal2}
              onRow={record => {
                return {
                  onClick: () => {
                    this.onRowClick(record.id, record.lat, record.lng)
                  },
                }
              }}
              scroll={{ x: false, y: false }}
              bordered
            />
          </Panel>
        </Collapse>
      </div>
    )
  }
}

export default ExpandableTable
