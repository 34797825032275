import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Button, Form, Row, Col, Spin } from "antd";

const FormItem = Form.Item;

export default class Forms extends Component {
  render() {
    const { comparevehiclesccostatusLoading, onSubmit } = this.props;

    return (
      <div>
        <h3>
          <LangContext.Consumer>
            {i18n => i18n.c.CompareVehicleSccoReport}
          </LangContext.Consumer>
        </h3>

        <Spin spinning={comparevehiclesccostatusLoading}>
          <Formik
            initialValues={{}}
            validationSchema={yup.object().shape({})}
            onSubmit={values => {
              //console.log(values);
              const loading = true;
              onSubmit(values, loading);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating
            }) => (
                <Row type="flex" justify="center" align="middle">
                  <Form onSubmit={handleSubmit}>
                    <FormItem>
                      <Row gutter={24} />
                    </FormItem>
                    <FormItem>
                      <Row gutter={24} />
                    </FormItem>
                    <FormItem>
                      <Row gutter={24} />
                    </FormItem>
                    <FormItem>
                      <Row gutter={24} />
                    </FormItem>
                    <FormItem>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Button type="defualt" block>
                            <Link to="/">
                              <LangContext.Consumer>
                                {i18n => i18n.c.cancel}
                              </LangContext.Consumer>
                            </Link>
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button type="primary" htmlType="submit" block>
                            <LangContext.Consumer>
                              {i18n => i18n.r.report}
                            </LangContext.Consumer>
                          </Button>
                        </Col>
                      </Row>
                    </FormItem>
                  </Form>
                </Row>
              )}
          />
        </Spin>
      </div>
    );
  }
}
