import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
import { timeZone } from '../../../lib/helper'
import moment from 'moment'

import * as actions from './actions'

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadVehicleType.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleType.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )
  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadPlant(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const Vocation_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'location_visibility',
  )
  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplant`,
      {
        company_id: COMPANY_ID,
        location_visibility: Vocation_visibility,
        relate_company: `${this.props.auth.profile.relate_company}`,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadPlant.success(res.data))
  } catch (err) {
    yield put(actions.loadPlant.failure())
  }
}

function* exportSummaryVehicleUsage(action) {
  let {
    data: {
      company_id,
      date_start,
      date_end,
      list_vehicle_id,
      user_id,
      type_file,
    },
  } = action.payload

  const start_date = timeZone(
    moment(date_start).format('YYYY-MM-DD HH:mm'),
    'start',
  )
  const end_date = timeZone(moment(date_end).format('YYYY-MM-DD HH:mm'), 'end')
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/new-truckusage`,
      {
        date_start: start_date,
        date_end: end_date,
        list_vehicle_id,
        user_id,
        type_file,
        company_id,
      },
    )

    yield put(actions.exportSummaryVehicleUsage.success(res.data))
    window.location.assign(res.data.result)
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportSummaryVehicleUsage.failure())
  }
}

function* exportSummaryVehicleUsagePreview(action) {
  let {
    data: {
      date_start,
      date_end,
      type,
      vehicle_list,
      plants_list,
      company_id,
      vehicle_visibility,
      site_id,
      type_file,
      header,
    },
  } = action.payload
  try {
    if (typeof plants_list != 'undefined' && plants_list)
      plants_list = plants_list.filter(ele => ele != '')

    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/rmcconcretetrip`,
      {
        date_start,
        date_end,
        type,
        vehicle_list,
        plants_list,
        company_id,
        vehicle_visibility,
        site_id,
        type_file,
      },
      { headers: header },
    )

    yield put(actions.exportSummaryVehicleUsage.success(res.data))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportSummaryVehicleUsage.failure())
  }
}

function* loadDivision(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { division_visibility, comp } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`,
      {
        division_visibility: division_visibility,
        comp: comp,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadDivision.success(res.data))
  } catch (err) {
    yield put(actions.loadDivision.failure())
  }
}
// /getplant

export default function* watchRmcconcretetrip() {
  yield all([
    takeEvery(actions.LOAD_VEHICLE_SUMMARY_VEHICLE_USAGE.REQUEST, loadVehicle),
    takeEvery(
      actions.LOAD_VEHICLE_SUMMARY_VEHICLE_USAGE.REQUEST,
      loadVehicleType,
    ),
    takeEvery(actions.LOAD_PLANT_SUMMARY_VEHICLE_USAGE.REQUEST, loadPlant),
    takeEvery(
      actions.EXPORT_SUMMARY_VEHICLE_USAGE.REQUEST,
      exportSummaryVehicleUsage,
    ),
    takeEvery(
      actions.EXPORT_SUMMARY_VEHICLE_USAGE_PREVIEW.REQUEST,
      exportSummaryVehicleUsagePreview,
    ),
    takeEvery(actions.LOAD_DIVISION.REQUEST, loadDivision),
  ])
}
export {
  loadVehicleType,
  loadVehicle,
  loadPlant,
  exportSummaryVehicleUsage,
  loadDivision,
  exportSummaryVehicleUsagePreview,
}
