import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Menu, Icon, Badge } from 'antd'
import LangContext from 'modules/shared/context/langContext'
import { AutorizeMenu } from '../../shared/components/CustomMenu'
import CustomMenuItem from '../../shared/components/CustomMenuItem'

import { autoGenKey } from '../../../lib/helper'

const rootSubmenuKeys = [
  'dashboard-sub-menu',
  'report-sub-menu',
  'monitoring-sub-menu',
  'management_submenu',
  'masterData_submenu',
  'deliver_submenu',
  'internalOperation_submenu',
  'help_menu_submenu',
  'setting_submenu',
]
class SliderBar extends Component {
  state = {
    openKeys: [],
    isActive: '',
    web_endpoint: '',
  }

  componentDidMount() {
    this.setState({
      web_endpoint: process.env.REACT_APP_WEB_ENDPOINT,
    })
  }

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(
      key => this.state.openKeys.indexOf(key) === -1,
    )

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      if (openKeys.length > 2) {
        const result = openKeys.filter((x, i) => {
          return i !== 1 && x
        })
        this.setState({ openKeys: result })
      } else {
        this.setState({ openKeys })
      }
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      })
    }
  }

  handleActiveMenu = key => {
    this.setState({
      isActive: key,
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.countAppoveRequestToUseVehicle) {
      return true
    }
    return true
  }

  render() {
    const { collapsed } = this.props

    const { permissions } = this.props.profile

    let checkPermissionShowCountNoti = permissions.find(
      item => item.matching_name == 'appoveToUseVehicle',
    )

    let countAppoveRequest = localStorage.getItem(
      'count-appove-request-vehicle',
    )

    console.log(
      'countAppoveRequestToUseVehicle',
      this.props.countAppoveRequestToUseVehicle,
    )

    return (
      <Menu
        mode="inline"
        theme="light"
        // inlineIndent={0}
        className="ant-side-menu"
        selectable={false}
      >
        {/* เเดชบอร์ด*/}
        <AutorizeMenu
          isActive={this.state.isActive}
          handleActiveMenu={this.handleActiveMenu}
          permissions={permissions}
          title={
            <span>
              <Icon
                type="dot-chart"
                style={{ color: 'red', fontSize: 'larger' }}
              />
              <span>
                {' '}
                <LangContext.Consumer>
                  {i18n => i18n.d.dashboard}
                </LangContext.Consumer>
              </span>
              {/* </Badge> */}
            </span>
          }
          subkey={'dashboard-sub-menu'}
          handleonOpenChange={this.onOpenChange}
          openKeysMenu={this.state.openKeys}
        >
          {/* การจัดส่งคอนกรีต */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="concreteDeliveryManagerDashboard"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'concreteDeliveryManagerDashboard' &&
                '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="concreteDeliveryManagerDashboard"
              permissions={permissions}
            >
              <Link to="/concrete_delivery_manager">
                <span className="nav-text font_menu_item_slider_bar">
                  <LangContext.Consumer>
                    {i18n => i18n.c.concreteDeliveryManagerDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* แผนบันทึกการใช้รถโม่เล็ก */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="smartDispatching"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'smartDispatching' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="smartDispatching"
              permissions={permissions}
            >
              <Link to="/smartDispatching">
                <span className="nav-text font_menu_item_slider_bar">
                  <LangContext.Consumer>
                    {i18n => i18n.s.smartDispatching}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
          {/* แดชบอร์ดภาพรวมการขนส่งคอนกรีต */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="truckstatusdashboard"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'truckstatusdashboard' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="truckstatusdashboard"
              permissions={permissions}
            >
              <Link to="/truckstatusdashboard">
                <span className="nav-text font_menu_item_slider_bar">
                  <LangContext.Consumer>
                    {i18n => i18n.t.truckstatusdashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
          {/* แดชบอร์ดเเจ้งเตือนเข้าผิดหน่วยงาน */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="notifyTheTruckInCorrectPoint"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'notifyTheTruckInCorrectPoint' &&
                '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="notifyTheTruckInCorrectPoint"
              permissions={permissions}
            >
              <Link to="/truckincorrectpoint">
                <span className="nav-text font_menu_item_slider_bar">
                  <LangContext.Consumer>
                    {i18n => i18n.n.notifyTheTruckInCorrectPoint}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
          {/* ความปลอดภัย */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="SafetyDashboard"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'SafetyDashboard' && '#ff000070',
              color: this.state.isActive === 'SafetyDashboard' && 'white',
            }}
          >
            <CustomMenuItem
              matching_name="SafetyDashboard"
              permissions={permissions}
            >
              <Link to="/safety_dashboard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.s.safetyDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* ประสิทธิภาพ GPS */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="gpsPerformanceDashboard"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'gpsPerformanceDashboard' &&
                '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="gpsPerformanceDashboard"
              permissions={permissions}
            >
              <Link to="/gps_performance_dashboard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.g.gpsPerformanceDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* monitor gps */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="monitorGPS"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'monitorGPS' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="monitorGPS"
              permissions={permissions}
            >
              <Link to="/monitor-gps">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.m.monitorGPS}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* การขนส่งวัตถุดิบ */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="deliveryDashboard"
            web_endpoint={this.state.web_endpoint} // fleetlink
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'deliveryDashboard' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="deliveryDashboard"
              permissions={permissions}
            >
              <Link to="/isupply-dashboard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.d.deliveryDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* ประสิทธิภาพการขนส่งวัตถุดิบ */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            className={collapsed ? '' : 'menu-item-wrap'}
            matching_name="transportationPerformanceDashboard"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'transportationPerformanceDashboard' &&
                '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="transportationPerformanceDashboard"
              permissions={permissions}
            >
              <Link to="/isupply-overview-performance-dashboard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.t.transportationPerformanceDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* การติดตามวัตถุดิบ */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="theStatusOfVehiclesThatReceiveDailyJobs"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            className="slider_bar_effect"
            style={{
              backgroundColor:
                this.state.isActive ===
                  'theStatusOfVehiclesThatReceiveDailyJobs' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="theStatusOfVehiclesThatReceiveDailyJobs"
              permissions={permissions}
            >
              <Link to="/isupply-status-vehicles-receive-daily-jobs">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.t.theStatusOfVehiclesThatReceiveDailyJobs}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* การจัดการคิวรถ */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="isupplyManageQueue"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'isupplyManageQueue' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="isupplyManageQueue"
              permissions={permissions}
            >
              <Link to="/isupply-manage-queue">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.i.isupplyManageQueue}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* สถานะขนส่งพื้น-ผนัง */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="precastDashboard"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'precastDashboard' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="precastDashboard"
              permissions={permissions}
            >
              <Link to="/precast-dashboard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.p.precastDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* ติดตามสถานะการจัดส่งทริป */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="multiDrop"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'multiDrop' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="multiDrop" permissions={permissions}>
              <Link to="/multi_drop">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.m.multiDrop}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* การแจ้งเตือนการฝ่าฝืน */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="alert_alarm_dashboard"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'alert_alarm_dashboard' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="alert_alarm_dashboard"
              permissions={permissions}
            >
              <Link to="/alert_alarm">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.a.alertAlarm}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* ภาพรวมพิกัดการฝ่าฝืน */}
          <Menu.Item
            className={collapsed ? '' : 'menu-item-wrap'}
            key={autoGenKey('slider-sub-menu')}
            matching_name="eventMap"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'eventMap' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="eventMap" permissions={permissions}>
              <Link to="/event_map">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.e.eventMap}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* สรุปการใช้พาหนะ */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="driverKpiDashboard"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'driverKpiDashboard' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="driverKpiDashboard"
              permissions={permissions}
            >
              <Link to="/driver-kpi">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.d.driverKpiDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
        </AutorizeMenu>
        {/* end เเดชบอร์ด */}

        {/* ระบบจัดการกองรถ */}
        <AutorizeMenu
          isActive={this.state.isActive}
          handleActiveMenu={this.handleActiveMenu}
          permissions={permissions}
          title={
            <span>
              <Icon
                type="dot-chart"
                style={{ color: 'red', fontSize: 'larger' }}
              />
              <span>
                {' '}
                <LangContext.Consumer>
                  {i18n => i18n.f.fleetManagement}
                </LangContext.Consumer>
              </span>
            </span>
          }
          subkey={'fleetmanagement-sub-menu'}
          handleonOpenChange={this.onOpenChange}
          openKeysMenu={this.state.openKeys}
        >
          {/* isupply booking order */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="isupplyBookingOrder"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'isupplyBookingOrder' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="isupplyBookingOrder"
              permissions={permissions}
            >
              <Link to="/booking-order">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.i.isupplyBookingOrder}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* isupply booking dashboard */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="isupplyBookingDashboard"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'isupplyBookingDashboard' &&
                '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="isupplyBookingDashboard"
              permissions={permissions}
            >
              <Link to="/isupply-booking-scheduler/month">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.i.isupplyBookingDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
        </AutorizeMenu>

        {/* ระบบติดตาม */}
        <AutorizeMenu
          isActive={this.state.isActive}
          handleActiveMenu={this.handleActiveMenu}
          permissions={permissions}
          title={
            <span>
              <Icon type="car" style={{ color: 'red', fontSize: 'larger' }} />
              <span>
                {' '}
                <LangContext.Consumer>
                  {i18n => i18n.m.monitoring}
                </LangContext.Consumer>
              </span>
            </span>
          }
          subkey={'monitoring-sub-menu'}
          handleonOpenChange={this.onOpenChange}
          openKeysMenu={this.state.openKeys}
        >
          {/* ติดตามยานพาหนะ */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="tracking"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'tracking' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="tracking" permissions={permissions}>
              <Link to="/tracking">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.t.tracking}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* เส้นทางเดินทางย้อนหลัง */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="replay"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor: this.state.isActive === 'replay' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="replay" permissions={permissions}>
              <Link to="/replay">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.r.replay}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* Honey Toast */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'honeyToast_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.h.honeyToast}
              </LangContext.Consumer>
            }
          >
            {/* วิดีโอเรียลไทม์จาก Honey Toast */}
            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="honeytoastlivestream"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'honeytoastlivestream' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="honeytoastlivestream"
                permissions={permissions}
              >
                <Link to="/honeytoastlivestream">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.h.honeytoastlivestream}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* วิดีโอย้อนหลัง Honey Toast */}
            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="honeytoastplayback"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'honeytoastplayback' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="honeytoastplayback"
                permissions={permissions}
              >
                <Link to="/honeytoastplaybackwebpage">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.h.honeytoastplayback}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* ดาวน์โหลดวิดีโอ Honey Toast */}
            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="honeytoastdownloadfile"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'honeytoastdownloadfile' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="honeytoastdownloadfile"
                permissions={permissions}
              >
                <Link to="/honeytoastdownloadfilewebpage">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.h.honeytoastdownloadfile}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* mdvr */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'mdvr_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>{i18n => i18n.m.mdvr}</LangContext.Consumer>
            }
          >
            {/* วิดีโอเรียลไทม์ MDVR */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="video"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor: this.state.isActive === 'video' && '#ff000070',
              }}
            >
              <CustomMenuItem matching_name="video" permissions={permissions}>
                <Link to="/video">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.v.video}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* ดาวน์โหลดวิดีโอย้อนหลัง MDVR  */}
            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="mdvrdownloadfile"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'mdvrdownloadfile' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="mdvrdownloadfile"
                permissions={permissions}
              >
                <Link to="/mdvrdownloadfile">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.mdvrdownloadfile}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="summary_dashboard"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'summary_dashboard' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="summary_dashboard"
              permissions={permissions}
            >
              <Link to="/summary_dashboard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.s.SafetyDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="booking_overview_dashboard"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'booking_overview_dashboard' &&
                '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="booking_overview_dashboard"
              permissions={permissions}
            >
              <Link to="/booking_overview">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.b.bookingOverview}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="cashing_overview_dahsboar"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'cashing_overview_dahsboar' &&
                '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="cashing_overview_dahsboar"
              permissions={permissions}
            >
              <Link to="/cashing_overview">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.e.earningOverview}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
        </AutorizeMenu>
        {/* end ระบบติดตาม */}

        {/* รายงาน */}
        <AutorizeMenu
          isActive={this.state.isActive}
          handleActiveMenu={this.handleActiveMenu}
          permissions={permissions}
          title={
            <span>
              <Icon
                type="exception"
                style={{ color: 'red', fontSize: 'larger' }}
              />
              <span>
                {' '}
                <LangContext.Consumer>
                  {i18n => i18n.r.report}
                </LangContext.Consumer>
              </span>
            </span>
          }
          subkey={'report-sub-menu'}
          handleonOpenChange={this.onOpenChange}
          openKeysMenu={this.state.openKeys}
        >
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="schedulereport"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            className={collapsed ? '' : 'menu-item-wrap'}
            style={{
              backgroundColor:
                this.state.isActive === 'schedulereport' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="schedulereport"
              permissions={permissions}
            >
              <Link to="/schedulereport">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.s.schedulereport}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* ทั่วไป */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'item_report_2'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.g.general_report_menu}
              </LangContext.Consumer>
            }
          >
            {/* ที่อยู่ปัจจุบันของพาหนะ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="TruckcurrentlocationReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'TruckcurrentlocationReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="TruckcurrentlocationReport"
                permissions={permissions}
              >
                <Link to="/report/truckcurrentlocation">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TruckcurrentlocationReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* จำนวนวันที่รถวิ่งงาน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="NumberofdaysworkedReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'NumberofdaysworkedReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="NumberofdaysworkedReport"
                permissions={permissions}
              >
                <Link to="/report/numberofdaysworked">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.n.NumberofdaysworkedReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุประยะทางการวิ่งประจำวัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="DrivingdistanceReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'DrivingdistanceReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="DrivingdistanceReport"
                permissions={permissions}
              >
                <Link to="/report/drivingdistance">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DrivingdistanceReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปการใช้พาหนะ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="summaryFuelUsage"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'summaryFuelUsage' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="summaryFuelUsage"
                permissions={permissions}
              >
                <Link to="/report/summary-fuel-usage">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.s.summaryVehicleUsage}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* ข้อมูลพนักงานขับขี่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="DriverinformationReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'DriverinformationReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="DriverinformationReport"
                permissions={permissions}
              >
                <Link to="/report/driverinformation">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DriverinformationReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* ใบขับขี่หมดอายุ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="theDriverIsIicenseReportHasExpired"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive ===
                    'theDriverIsIicenseReportHasExpired' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="theDriverIsIicenseReportHasExpired"
                permissions={permissions}
              >
                <Link to="/report/alertdriverlicense">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.theDriverIsIicenseReportHasExpired}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="LoginhistoryReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'LoginhistoryReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="LoginhistoryReport"
                permissions={permissions}
              >
                <Link to="/report/loginhistory">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.l.LoginhistoryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="GpsstatusReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'GpsstatusReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="GpsstatusReport"
                permissions={permissions}
              >
                <Link to="/report/gpsstatus">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.g.GpsstatusReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="TrackingdataReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'TrackingdataReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="TrackingdataReport"
                permissions={permissions}
              >
                <Link to="/report/trackingdata">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TrackingdataReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* พฤติกรรม */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'behavior_report_menu_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.b.behavior_report_menu}
              </LangContext.Consumer>
            }
          >
            {/* พฤติกรรมการขับขี่รายวัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="dailyDrivingBehaviorReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'dailyDrivingBehaviorReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="dailyDrivingBehaviorReport"
                permissions={permissions}
              >
                <Link to="/report/drivingbehavior">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.dailyDrivingBehaviorReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* พฤติกรรมการรูดบัตรก่อนขับขี่รายวัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="reportCardSwipeBehaviorBeforeDrivingDaily"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive ===
                    'reportCardSwipeBehaviorBeforeDrivingDaily' && '#ff000070',
              }}
              className={collapsed ? '' : 'menu-item-wrap'}
            >
              <CustomMenuItem
                matching_name="reportCardSwipeBehaviorBeforeDrivingDaily"
                permissions={permissions}
              >
                <Link to="/report/drivinglicensescanbehavior">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.r.reportCardSwipeBehaviorBeforeDrivingDaily}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* พฤติกรรมการขับขี่ไม่ปลอดภัย */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="RiskbehaviourReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'RiskbehaviourReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="RiskbehaviourReport"
                permissions={permissions}
              >
                <Link to="/report/riskbehaviour">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.r.RiskbehaviourReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* การรูดใบขับขี่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="DrivinglicensescanlogReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'DrivinglicensescanlogReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="DrivinglicensescanlogReport"
                permissions={permissions}
              >
                <Link to="/report/drivinglicensescanlog">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DrivinglicensescanlogReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* บันทึกการขับขี่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="DrivinglogReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'DrivinglogReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="DrivinglogReport"
                permissions={permissions}
              >
                <Link to="/report/drivinglog">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DrivinglogReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* จุดจอดพาหนะ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="ParkingReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'ParkingReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="ParkingReport"
                permissions={permissions}
              >
                <Link to="/report/parking">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.p.ParkingReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* จำนวนครั้งการจอดรถนอกพื้นที่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="reportSummarizingTheNumberOfOffSiteParking"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive ===
                    'reportSummarizingTheNumberOfOffSiteParking' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="reportSummarizingTheNumberOfOffSiteParking"
                permissions={permissions}
              >
                <Link to="/report/parkingareaoutside">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n =>
                        i18n.r.reportSummarizingTheNumberOfOffSiteParking
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* พาหนะเข้าพื้นที่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="VehicleinareaReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'VehicleinareaReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="VehicleinareaReport"
                permissions={permissions}
              >
                <Link to="/report/vehicleinarea">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.v.VehicleinareaReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* พาหนะวิ่งงานเกินเวลาที่กำหนด */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="DrivingoverlimitReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'DrivingoverlimitReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="DrivingoverlimitReport"
                permissions={permissions}
              >
                <Link to="/report/drivingoverlimit">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DrivingoverlimitReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* การใช้งานพาหนะ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="TruckusageReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'TruckusageReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="TruckusageReport"
                permissions={permissions}
              >
                <Link to="/report/truckusage">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TruckusageReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* เสี่ยงหลับใน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="DozeoffriskReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'DozeoffriskReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="DozeoffriskReport"
                permissions={permissions}
              >
                <Link to="/report/dozeoffrisk">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DozeoffriskReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปสถานะพาหนะรายวัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="dailyCarStatusReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'dailyCarStatusReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="dailyCarStatusReport"
                permissions={permissions}
              >
                <Link to="/report/separatevehiclestatus">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.dailyCarStatusReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปความเร็วเกินกำหนด */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="SpeedoverlimitsummaryReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'SpeedoverlimitsummaryReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="SpeedoverlimitsummaryReport"
                permissions={permissions}
              >
                <Link to="/report/speedoverlimitsummary">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.s.SpeedoverlimitsummaryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปรายงาน KPI */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="SummaryKPIReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'SummaryKPIReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="SummaryKPIReport"
                permissions={permissions}
              >
                <Link to="/report/summarykpi">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.s.SummaryKPIReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปการเดินทางแยกประเภทตามการเปิด-ปิดเครื่องยนต์ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="TripbyengineoffonReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'TripbyengineoffonReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="TripbyengineoffonReport"
                permissions={permissions}
              >
                <Link to="/report/tripbyengineoffon">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TripbyengineoffonReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* Alert และ Alarm */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'alertAndAlarm_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.a.alertAndAlarm}
              </LangContext.Consumer>
            }
          >
            {/* สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="BehaviordrivingReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'BehaviordrivingReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="BehaviordrivingReport"
                permissions={permissions}
              >
                <Link to="/report/behaviordriving">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.b.BehaviordrivingReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง แต่ละผู้รับเหมา */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="BehaviordrivingbycompanyReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'BehaviordrivingbycompanyReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="BehaviordrivingbycompanyReport"
                permissions={permissions}
              >
                <Link to="/report/behaviordrivingbycompany">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.b.BehaviordrivingbycompanyReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* รูปแบบการสรุปคะแนน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="ScoresummaryReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'ScoresummaryReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="ScoresummaryReport"
                permissions={permissions}
              >
                <Link to="/report/scoresummary">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.s.ScoresummaryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* ความปลอดภัยพาหนะ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="SafetyEventReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'SafetyEventReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="SafetyEventReport"
                permissions={permissions}
              >
                <Link to="/report/safety_event">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.e.SafetyEventReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* Alert และ Alarm แยกตามทะเบียนรายวัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="alarmAndAlertReportClassifiedByDailyCarRegistration"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive ===
                    'alarmAndAlertReportClassifiedByDailyCarRegistration' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="alarmAndAlertReportClassifiedByDailyCarRegistration"
                permissions={permissions}
              >
                <Link to="/report/alarmalertbyplateno">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n =>
                        i18n.a
                          .alarmAndAlertReportClassifiedByDailyCarRegistration
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* Alert และ Alarm แยกตามพนักงานขับขี่รายวัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="DailyAlarmAndAlertReportByDriver"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'DailyAlarmAndAlertReportByDriver' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="DailyAlarmAndAlertReportByDriver"
                permissions={permissions}
              >
                <Link to="/report/alarmalertbydriver">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DailyAlarmAndAlertReportByDriver}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* Alert และ Alarm แยกตามทะเบียน รายปี */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="alarmAndAlertReportClassifiedByYearlyCarRegistration"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive ===
                    'alarmAndAlertReportClassifiedByYearlyCarRegistration' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="alarmAndAlertReportClassifiedByYearlyCarRegistration"
                permissions={permissions}
              >
                <Link to="/report/alarmalertbyplatenoonyear">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n =>
                        i18n.a
                          .alarmAndAlertReportClassifiedByYearlyCarRegistration
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* ความถี่ Alarm แยกตามพนักงานขับขี่ รายวัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="reportAlarmFrequencyClassifiedByDailyDriver"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive ===
                    'reportAlarmFrequencyClassifiedByDailyDriver' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="reportAlarmFrequencyClassifiedByDailyDriver"
                permissions={permissions}
              >
                <Link to="/report/alarmalertfrequencybydriver">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n =>
                        i18n.r.reportAlarmFrequencyClassifiedByDailyDriver
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* Alert 5 อันดับแรกของแต่ละประเภทแบ่งตามช่วงเวลารายเดือน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive ===
                    'top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod"
                permissions={permissions}
              >
                <Link to="/report/alarmalertbydurationtime">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n =>
                        i18n.t
                          .top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* ประสิทธิภาพ */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'efficiency_report_menu_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.e.efficiency_report_menu}
              </LangContext.Consumer>
            }
          >
            {/* พาหนะติดเครื่อง */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="TruckengineonReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'TruckengineonReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="TruckengineonReport"
                permissions={permissions}
              >
                <Link to="/report/truckengineon">
                  <span className="nav-text">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.t.TruckengineonReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* จำนวนวันที่พาหนะไม่ได้วิ่งง���น */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="TrucknotuseReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'TrucknotuseReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="TrucknotuseReport"
                permissions={permissions}
              >
                <Link to="/report/trucknotuse">
                  <span className="nav-text">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.t.TrucknotuseReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* จำนวนวันที่พาหนะไม่ติดเครื่อง */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="TruckengineoffReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'TruckengineoffReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="TruckengineoffReport"
                permissions={permissions}
              >
                <Link to="/report/truckengineoff">
                  <span className="nav-text">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.t.TruckengineoffReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปประสิทธิภาพพาหนะรายคัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="VehicleperformanceconcludeReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'VehicleperformanceconcludeReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="VehicleperformanceconcludeReport"
                permissions={permissions}
              >
                <Link to="/report/vehicleperformanceconclude">
                  <span className="nav-text">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.v.VehicleperformanceconcludeReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* ภาพรวมสรุปการขับขี่รายคัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="drivingSummary"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'drivingSummary' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="drivingSummary"
                permissions={permissions}
              >
                <Link to="/report/driving-summary">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.drivingSummary}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* ทริป */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'trip_report_menu_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.t.trip_report_menu}
              </LangContext.Consumer>
            }
          >
            {/* รายงานย้ายจุดอัตโนมัติ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="automovingsite-report"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'automovingsite-report' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="automovingsite-report"
                permissions={permissions}
              >
                <Link to="/report/auto-move-site">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.a.autoMoveSite}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* การส่งคอนกรีต */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="concreteTrip"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'concreteTrip' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="concreteTrip"
                permissions={permissions}
              >
                <Link to="/report/rmcconcretetrip">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.c.concreteTrip}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* การขนส่งวัตถุดิบ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="deliveryReport"
              web_endpoint={this.state.web_endpoint}
              web_use="isupply"
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'deliveryReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="deliveryReport"
                permissions={permissions}
              >
                <Link to="/isupply-delivery-report">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.deliveryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* การขนส่งวัตถุดิบ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="weighingHistoryReport"
              web_endpoint={this.state.web_endpoint}
              web_use="isupply"
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'weighingHistoryReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="weighingHistoryReport"
                permissions={permissions}
              >
                <Link to="/isupply-weighing-history-report">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.w.weighingHistoryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* จัดส่ง พื้น-ผนัง */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="floorWallDelivery"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'floorWallDelivery' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="floorWallDelivery"
                permissions={permissions}
              >
                <Link to="/precast-report">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.f.floorWallDelivery}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* ระยะทางการจัดส่งคอนกรีต */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="distancereport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'distancereport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="distancereport"
                permissions={permissions}
              >
                <Link to="/report/distancereport">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.distancereport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* รายงานเทปูนนอกจุด */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="unauthorizeunload"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'unauthorizeunload' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="unauthorizeunload"
                permissions={permissions}
              >
                <Link to="/report/unauthorizeunload">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.u.unauthorizeunload}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปการวิ่งแยกตามทริป */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="TripsummaryReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'TripsummaryReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="TripsummaryReport"
                permissions={permissions}
              >
                <Link to="/report/tripsummary">
                  <span className="nav-text">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.t.TripsummaryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปการวิ่งประจำวัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="DailyReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'DailyReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="DailyReport"
                permissions={permissions}
              >
                <Link to="/report/daily">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.DailyReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปจำนวนงานตามสถานที่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="TripsumbylocationReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'TripsumbylocationReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="TripsumbylocationReport"
                permissions={permissions}
              >
                <Link to="/report/tripsumbylocation">
                  <span className="nav-text">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.t.TripsumbylocationReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปการวิ่งแยกตามพาหนะ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="TripsumbyvehReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'TripsumbyvehReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="TripsumbyvehReport"
                permissions={permissions}
              >
                <Link to="/report/tripsumbyveh">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.TripsumbyvehReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* นโยบาย */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'policy_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.p.policy}
              </LangContext.Consumer>
            }
          >
            {/* เหตุการณ์พาหนะรายคัน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="EventReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'EventReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="EventReport"
                permissions={permissions}
              >
                <Link to="/report/event">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.e.EventReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* พลังงาน */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'energy_report_menu_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.e.energy_report_menu}
              </LangContext.Consumer>
            }
          >
            {/* การเติมน้ำมัน */}
            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="RefuelReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'RefuelReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="RefuelReport"
                permissions={permissions}
              >
                <Link to="/report/refuel">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.r.RefuelReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* น้ำมันคงเหลือ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="fuelremainrateReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'fuelremainrateReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="fuelremainrateReport"
                permissions={permissions}
              >
                <Link to="/report/fuelremainrate">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.f.fuelremainrateReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="FueluseReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'FueluseReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="FueluseReport"
                permissions={permissions}
              >
                <Link to="/report/fueluse">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.f.FueluseReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* ค่าใช้จ่าย */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'theCost_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.t.theCost}
              </LangContext.Consumer>
            }
          >
            {/* สรุปค่าบริการ GPS ทั้งหมด */}
            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="gpsVendorPayingSummaryReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'gpsVendorPayingSummaryReport' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="gpsVendorPayingSummaryReport"
                permissions={permissions}
              >
                <Link to="/report/gpsvendorpayingsummaryreport">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.g.gpsVendorPayingSummaryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปเรียกเก็บเงินตามผู้รับเหมา */}
            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="partnerPerformReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'partnerPerformReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="partnerPerformReport"
                permissions={permissions}
              >
                <Link to="/report/partnerperformreport">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.p.partnerPerformReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สรุปค่าบริการตาม GPS Vendor */}
            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="gpsVendorPayingReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'gpsVendorPayingReport' &&
                  '#ff000070',
              }}
              // report_repairing
            >
              <CustomMenuItem
                matching_name="gpsVendorPayingReport"
                permissions={permissions}
              >
                <Link to="/report/gpsvendorpayingreport">
                  <span className="nav-text">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.g.gpsVendorPayingReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* การจัดการอุปกรณ์ */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'maintenancedevice_report_menu_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.m.maintenancedevice_report_menu}
              </LangContext.Consumer>
            }
          >
            {/* การแจ้งซ่อม */}
            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="repairingReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'repairingReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="repairingReport"
                permissions={permissions}
              >
                <Link to="/report/repairingreport">
                  <span className="nav-text">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.r.repairingReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* GPS ขาดการติดต่อ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="gpsReportLostContact"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'gpsReportLostContact' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="gpsReportLostContact"
                permissions={permissions}
              >
                <Link to="/report/gps-lost-contact">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.g.gpsReportLostContact}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="gpsDisconnectOnTrip"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'gpsDisconnectOnTrip' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="gpsDisconnectOnTrip"
                permissions={permissions}
              >
                <Link to="/report/gps-disconnect-on-trip">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.g.gpsDisconnectOnTrip}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              className={collapsed ? '' : 'menu-item-wrap'}
              matching_name="distance_report_1"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'distance_report_1' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="distance_report_1"
                permissions={permissions}
              >
                <Link to="/distancereport">
                  <span className="nav-text">ระยะส่ง</span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* ประกันภัย */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'insure_report_menu_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.i.insure_report_menu}
              </LangContext.Consumer>
            }
          >
            <Menu.Item
              className={collapsed ? '' : 'menu-item-wrap'}
              key={autoGenKey('slider-sub-menu')}
              matching_name="InsuranceReport"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'InsuranceReport' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="InsuranceReport"
                permissions={permissions}
              >
                <Link to="/report/insurance">
                  <span className="nav-text">
                    {' '}
                    <LangContext.Consumer>
                      {i18n => i18n.i.InsuranceReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>
        </AutorizeMenu>

        {/* การจัดการ */}
        <AutorizeMenu
          isActive={this.state.isActive}
          handleActiveMenu={this.handleActiveMenu}
          permissions={permissions}
          title={
            <span>
              <Icon
                type="appstore"
                style={{ color: 'red', fontSize: 'larger' }}
              />
              <span>
                {' '}
                <LangContext.Consumer>
                  {i18n => i18n.m.management_menu}
                </LangContext.Consumer>
              </span>
            </span>
          }
          subkey={'management_submenu'}
          handleonOpenChange={this.onOpenChange}
          openKeysMenu={this.state.openKeys}
        >
          {/* vehicleVisibilityPermissionGeneralData */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="vehicleVisibilityPermissionGeneralData"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive ===
                  'vehicleVisibilityPermissionGeneralData' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="vehicleVisibilityPermissionGeneralData"
              permissions={permissions}
            >
              <Link to="/vehicleVisibilityPermissionGeneralData">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n =>
                      i18n.vehicleVisibilityPermission
                        .vehicleVisibilityPermissionHeader
                    }
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* vehicleVisibilityPermission */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="vehicleVisibilityPermission"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'vehicleVisibilityPermission' &&
                '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="vehicleVisibilityPermission"
              permissions={permissions}
            >
              <Link to="/vehicleVisibilityPermission">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.vehicleVisibilityPermission.generalData}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* ผู้ใช้งาน */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="user"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor: this.state.isActive === 'user' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="user" permissions={permissions}>
              <Link to="/user">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.u.user}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* กลุ่มผู้ใช้งาน */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="userGroup"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'userGroup' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="userGroup" permissions={permissions}>
              <Link to="/usergroup">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.u.userGroup}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* การมองเห็นข้อมูล */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="dataVisibility"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'dataVisibility' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="dataVisibility"
              permissions={permissions}
            >
              <Link to="/datavisibility">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.d.dataVisibility}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* แชร์พาหนะ */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="vehicleSharing"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'vehicleSharing' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="vehicleSharing"
              permissions={permissions}
            >
              <Link to="/vehicle_sharing">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.v.vehicleSharing}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* เเชร์สถานที่ */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="shareLocation"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'shareLocation' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="shareLocation"
              permissions={permissions}
            >
              <Link to="/sharelocation">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.s.shareLocation}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="partnerCar"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'partnerCar' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="partnerCar"
              permissions={permissions}
            >
              <Link to="/partner">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.p.partnerCar}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* แผนการจัดส่ง */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="routemaster"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'routemaster' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="routemaster"
              permissions={permissions}
            >
              <Link to="/routemaster">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.r.routemaster}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* Generate Token Account*/}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="generateToken"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'generateToken' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="generateToken"
              permissions={permissions}
            >
              <Link to="/generateusertoken">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.g.generateTokenAccount}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* จัดรถของ ผรม. isupply */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="isupplyManageVehicle"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'isupplyManageVehicle' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="isupplyManageVehicle"
              permissions={permissions}
            >
              <Link to="/isupply-manage-vehicle">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.i.isupplyManageVehicle}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* isupply multidrop */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="isupplyMultidrop"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'isupplyMultidrop' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="isupplyMultidrop"
              permissions={permissions}
            >
              <Link to="/multidrop">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.i.isupplyMultidrop}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
        </AutorizeMenu>

        {/* ข้อมูลหลัก */}
        <AutorizeMenu
          isActive={this.state.isActive}
          handleActiveMenu={this.handleActiveMenu}
          permissions={permissions}
          title={
            <span>
              <Icon
                type="folder"
                style={{ color: 'red', fontSize: 'larger' }}
              />
              <span>
                {' '}
                <LangContext.Consumer>
                  {i18n => i18n.m.masterData}
                </LangContext.Consumer>
              </span>
              {localStorage.getItem('count-appove-request-vehicle') != 0 &&
                checkPermissionShowCountNoti != undefined &&
                (checkPermissionShowCountNoti.can_view == 1 ? (
                  <sup
                    style={{
                      color: 'white',
                      margin: 5,
                      backgroundColor: '#ff0b0b',
                      borderRadius: 12,
                    }}
                  >
                    &nbsp; {countAppoveRequest} &nbsp;
                  </sup>
                ) : null)}
            </span>
          }
          subkey={'masterData_submenu'}
          handleonOpenChange={this.onOpenChange}
          openKeysMenu={this.state.openKeys}
        >
          {/* สถานที่่ */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            //key={autoGenKey(`sub-menu`)}
            key="location_1"
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.l.location}
              </LangContext.Consumer>
            }
          >
            {/* สถานที่่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="location"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'location' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="location"
                permissions={permissions}
              >
                <Link to="/location">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.l.Location}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* พื้นที่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="zone"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor: this.state.isActive === 'zone' && '#ff000070',
              }}
            >
              <CustomMenuItem matching_name="zone" permissions={permissions}>
                <Link to="/zone">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.z.zone}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* พาหนะห้ามเข้าพื้นที่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="vehicleBlacklist"
              web_endpoint={this.state.web_endpoint}
              web_use="isupply"
              style={{
                backgroundColor:
                  this.state.isActive === 'vehicleBlacklist' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="vehicleBlacklist"
                permissions={permissions}
              >
                <Link to="/isupply-vehicle-blacklist">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.v.vehicleBlacklist}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* พนักงานห้ามเข้าพื้นที่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="driverBlacklist"
              web_endpoint={this.state.web_endpoint}
              web_use="isupply"
              style={{
                backgroundColor:
                  this.state.isActive === 'driverBlacklist' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="driverBlacklist"
                permissions={permissions}
              >
                <Link to="/isupply-driver-blacklist">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.d.driverBlacklist}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สถานที่ใกล้เคียง */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="nearByLocation"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'nearByLocation' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="nearByLocation"
                permissions={permissions}
              >
                <Link to="/nearbylocation">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.n.nearByLocation}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* กลุ่มสถานที่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="locationGroup"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'locationGroup' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="locationGroup"
                permissions={permissions}
              >
                <Link to="/locationgroup">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.l.locationGroup}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* กลุ่มหน่วยงาน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="siteGroup"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'siteGroup' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="siteGroup"
                permissions={permissions}
              >
                <Link to="/sitegroup">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.s.siteGroup}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* พาหนะ */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'vehicle_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.v.vehicle}
              </LangContext.Consumer>
            }
          >
            {/* ประเภท พาหนะ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="vehicle_type"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'vehicle_type' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="vehicle_type"
                permissions={permissions}
              >
                <Link to="/vehicle-type">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.v.vehicle_type}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
            {/* พาหนะ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="vehicle"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'vehicle' && '#ff000070',
              }}
            >
              <CustomMenuItem matching_name="vehicle" permissions={permissions}>
                <Link to="/vehicle">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.v.vehicle}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สัญญาพาหนะ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="vehicleContract"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'vehicleContract' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="vehicleContract"
                permissions={permissions}
              >
                <Link to="/vehicle-contract">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.vehicleContract.vehicleContract}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* กลุ่มพาหนะ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="vehicleGroup"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'vehicleGroup' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="vehicleGroup"
                permissions={permissions}
              >
                <Link to="/vehiclegroup">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.v.vehicleGroup}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* ขอใช้งานพาหนะ */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'request_to_use_vehicle'}
            permissions={permissions}
            title={
              <>
                <LangContext.Consumer>
                  {i18n => i18n.r.requestToUseVehicle}
                </LangContext.Consumer>
                {localStorage.getItem('count-appove-request-vehicle') != 0 &&
                  checkPermissionShowCountNoti != undefined &&
                  (checkPermissionShowCountNoti.can_view == 1 ? (
                    <sup
                      style={{
                        color: 'white',
                        margin: 5,
                        backgroundColor: '#ff0b0b',
                        borderRadius: 12,
                      }}
                    >
                      &nbsp; {countAppoveRequest} &nbsp;
                    </sup>
                  ) : null)}
              </>
            }
          >
            {/* รายการพาหนะบัญชี 2 */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="accountVehicle2"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'accountVehicle2' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="accountVehicle2"
                permissions={permissions}
              >
                <Link to="/list-of-vehicle-account">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.a.accountVehicle2}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* อนุมัติการขอใช้งานรถ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="appoveToUseVehicle"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'appoveToUseVehicle' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="appoveToUseVehicle"
                permissions={permissions}
              >
                <Link to="/approve-request-to-use-vehicle">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.a.appoveToUseVehicle}
                    </LangContext.Consumer>
                    {localStorage.getItem('count-appove-request-vehicle') !=
                      0 &&
                      checkPermissionShowCountNoti != undefined &&
                      (checkPermissionShowCountNoti.can_view == 1 ? (
                        <sup
                          style={{
                            color: 'white',
                            margin: 5,
                            backgroundColor: '#ff0b0b',
                            borderRadius: 12,
                          }}
                        >
                          &nbsp; {countAppoveRequest} &nbsp;
                        </sup>
                      ) : null)}
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* การจัดการรถของผู้รับเหมา */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="contractorVehicleManagement"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'contractorVehicleManagement' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="contractorVehicleManagement"
                permissions={permissions}
              >
                <Link to="/contractor-vehicle-management">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.c.contractorVehicleManagement}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* ประวัติรายการพาหนะบัญชี 2 */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="accountVehicleListHistory"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'accountVehicleListHistory' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="accountVehicleListHistory"
                permissions={permissions}
              >
                <Link to="/account-vehicle-list-history">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.a.accountVehicleListHistory}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* พนักงานขับขี่ */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'chauffeur_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.c.chauffeur}
              </LangContext.Consumer>
            }
          >
            {/* พนักงานขับขี่ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="chauffeur"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'chauffeur' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="chauffeur"
                permissions={permissions}
              >
                <Link to="/deviceuser">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.c.chauffeur}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* วัตถุดิบ */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'material_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.m.material}
              </LangContext.Consumer>
            }
          >
            {/* วัตถุดิบ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="masterDataMatrial"
              web_endpoint={this.state.web_endpoint}
              web_use="isupply"
              style={{
                backgroundColor:
                  this.state.isActive === 'masterDataMatrial' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="masterDataMatrial"
                permissions={permissions}
              >
                <Link to="/material">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.m.masterDataMatrial}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* วัตถุดิบ เทียบ*/}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="comparativeRawMaterials"
              web_endpoint={this.state.web_endpoint}
              web_use="isupply"
              style={{
                backgroundColor:
                  this.state.isActive === 'comparativeRawMaterials' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="comparativeRawMaterials"
                permissions={permissions}
              >
                <Link to="/sub-material">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.c.comparativeRawMaterials}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* รายการสั่งวัตถุดิบ */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="requestMaterial"
              web_endpoint={this.state.web_endpoint}
              web_use="isupply"
              style={{
                backgroundColor:
                  this.state.isActive === 'requestMaterial' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="requestMaterial"
                permissions={permissions}
              >
                <Link to="/request-material">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.r.requestMaterial}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* สร้างกองงาน */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="isupplySaleOrder"
              web_endpoint={this.state.web_endpoint}
              web_use="isupply"
              style={{
                backgroundColor:
                  this.state.isActive === 'isupplySaleOrder' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="isupplySaleOrder"
                permissions={permissions}
              >
                <Link to="/isupply-saleorder">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.i.isupplySaleOrder}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* สัญญาขนส่ง */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="plantMiningContract"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'plantMiningContract' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="plantMiningContract"
              permissions={permissions}
            >
              <Link to="/delivery-contract">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.p.plantMiningContract}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* บัตรแข็ง */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="truckPickupCard"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'truckPickupCard' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="truckPickupCard"
              permissions={permissions}
            >
              <Link to="/truck-pickup-card">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.t.truckPickupCard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* ภาพตัวอย่าง */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="sampleImages"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'sampleImages' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="sampleImages"
              permissions={permissions}
            >
              <Link to="/sample-image">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.s.sampleImages}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* กุญเเจเชื่อมระบบ */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="isupplySystemKey"
            web_endpoint={this.state.web_endpoint}
            web_use="isupply"
            style={{
              backgroundColor:
                this.state.isActive === 'isupplySystemKey' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="isupplySystemKey"
              permissions={permissions}
            >
              <Link to="/interface-key-cdas">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.i.isupplySystemKey}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* Precast */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'pre_cast_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.vehicleType.pre_cast}
              </LangContext.Consumer>
            }
          >
            {/* ข้อมูล เเผนการวิ่งรถ (ผนัง) */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="informationAboutTheCarRunWall"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'informationAboutTheCarRunWall' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="informationAboutTheCarRunWall"
                permissions={permissions}
              >
                <Link to="/cpac-precast-transportation-information-wall">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.i.informationAboutTheCarRunWall}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* ข้อมูล เเผนการวิ่งรถ (พื้น) */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="informationAboutTheCarRunFloor"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              className={collapsed ? '' : 'menu-item-wrap'}
              style={{
                backgroundColor:
                  this.state.isActive === 'informationAboutTheCarRunFloor' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="informationAboutTheCarRunFloor"
                permissions={permissions}
              >
                <Link to="/cpac-precast-transportation-information-floor">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.i.informationAboutTheCarRunFloor}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          {/* สินค้า */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="product"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor: this.state.isActive === 'product' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="product" permissions={permissions}>
              <Link to="/product">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.p.product}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* หน่วยนับ (UOM) */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="uom"
            style={{
              backgroundColor: this.state.isActive === 'uom' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="uom" permissions={permissions}>
              <Link to="/uom">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.u.uom}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* ลูกค้า */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="customer"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'customer' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="customer" permissions={permissions}>
              <Link to="/customer">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.c.customer}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* Permission */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="permission"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'permission' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="permission"
              permissions={permissions}
            >
              <Link to="/permission">
                <span className="nav-text">Permission</span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="maintenance"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'maintenance' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="maintenance"
              permissions={permissions}
            >
              <Link to="/maintenance">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.m.maintenance}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="reFuelTitle"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'reFuelTitle' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="reFuelTitle"
              permissions={permissions}
            >
              <Link to="/refuel">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.r.reFuelTitle}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* เชื้อเพลิง */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="company"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor: this.state.isActive === 'company' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="company" permissions={permissions}>
              <Link to="/company">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.c.company}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
          {/* บริษัท */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="sendemail"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'sendemail' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="sendemail" permissions={permissions}>
              <Link to="/verifyemail">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.a.accountVerification}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
        </AutorizeMenu>

        {/* การจัดส่ง */}
        <AutorizeMenu
          isActive={this.state.isActive}
          handleActiveMenu={this.handleActiveMenu}
          permissions={permissions}
          title={
            <span>
              <Icon
                type="retweet"
                style={{ color: 'red', fontSize: 'larger' }}
              />
              <span>
                {' '}
                <LangContext.Consumer>
                  {i18n => i18n.d.deliver}
                </LangContext.Consumer>
              </span>
            </span>
          }
          subkey={'deliver_submenu'}
          handleonOpenChange={this.onOpenChange}
          openKeysMenu={this.state.openKeys}
        >
          {/* ทริป */}
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'trip_report_menu_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.t.trip_report_menu}
              </LangContext.Consumer>
            }
          >
            {/* ทริป */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="trip"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor: this.state.isActive === 'trip' && '#ff000070',
              }}
            >
              <CustomMenuItem matching_name="trip" permissions={permissions}>
                <Link to="/trip">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.t.trip}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>

            {/* แผนการจัดส่ง */}
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              matching_name="routemaster"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'routemaster' && '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="routemaster"
                permissions={permissions}
              >
                <Link to="/routemaster">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.r.routemaster}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="delivery_trip"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'delivery_trip' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="delivery_trip"
              permissions={permissions}
            >
              <Link to="/deliverytrip">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.d.deliverytrip}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* <Menu.Item
                    key={autoGenKey("slider-sub-menu")}
                    matching_name="isupply-delivery"
                >
    <CustomMenuItem
      matching_name='isupply-delivery'
      permissions={permissions}
    >
                    <Link to="/isupply-delivery">
                        <span className="nav-text">
                        Isupply Delivery
                        </span>
                    </Link>
                </CustomMenuItem></Menu.Item> */}

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="isupplyManageQueue"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'isupplyManageQueue' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="isupplyManageQueue"
              permissions={permissions}
            >
              <Link to="/isupply-manage-queue">
                <span className="nav-text">
                  {/* <LangContext.Consumer>
                        {(i18n) => i18n.c.concreteDeliveryManagerDashboard}
                    </LangContext.Consumer> */}
                  Isupply Manage Queue
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
        </AutorizeMenu>

        {/* การจัดการภายใน */}
        <AutorizeMenu
          isActive={this.state.isActive}
          handleActiveMenu={this.handleActiveMenu}
          permissions={permissions}
          title={
            <span>
              <Icon type="audit" style={{ color: 'red', fontSize: 'larger' }} />
              <span>
                {' '}
                <LangContext.Consumer>
                  {i18n => i18n.i.internalOperation}
                </LangContext.Consumer>
              </span>
            </span>
          }
          subkey={'internalOperation_submenu'}
          handleonOpenChange={this.onOpenChange}
          openKeysMenu={this.state.openKeys}
        >
          <Menu.SubMenu
            popupClassName="popup-menu-custom scroll-style"
            key={'announcement_1'}
            permissions={permissions}
            title={
              <LangContext.Consumer>
                {i18n => i18n.a.announcement}
              </LangContext.Consumer>
            }
          >
            <Menu.Item
              key={autoGenKey('slider-sub-menu')}
              className={collapsed ? '' : 'menu-item-wrap'}
              matching_name="announcement_broadcast"
              web_endpoint={this.state.web_endpoint}
              web_use=""
              style={{
                backgroundColor:
                  this.state.isActive === 'announcement_broadcast' &&
                  '#ff000070',
              }}
            >
              <CustomMenuItem
                matching_name="announcement_broadcast"
                permissions={permissions}
              >
                <Link to="/announcement_broadcast">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {i18n => i18n.a.announcementBroadcast}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </CustomMenuItem>
            </Menu.Item>
          </Menu.SubMenu>
        </AutorizeMenu>

        {/* ช่วยเหลือ */}
        <AutorizeMenu
          isActive={this.state.isActive}
          handleActiveMenu={this.handleActiveMenu}
          permissions={permissions}
          title={
            <span>
              <Icon
                type="safety-certificate"
                style={{ color: 'red', fontSize: 'larger' }}
              />
              <span>
                {' '}
                <LangContext.Consumer>
                  {i18n => i18n.h.help_menu}
                </LangContext.Consumer>
              </span>
            </span>
          }
          handleonOpenChange={this.onOpenChange}
          openKeysMenu={this.state.openKeys}
          subkey={'help_menu_submenu'}
        >
          {/* ขอใบรับรอง */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            // key={'item_help_menu_1'}
            matching_name="requestACertificate"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'requestACertificate' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="requestACertificate"
              permissions={permissions}
            >
              <Link to="/cpac-requestcertificate">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.r.requestACertificate}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* แจ้งซ่อมอุปกรณ์ */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="maintenancedevice"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'maintenancedevice' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="maintenancedevice"
              permissions={permissions}
            >
              <Link to="/maintenancedevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.m.maintenancedevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* อัพเดท imei */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="updateimei"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'updateimei' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="updateimei"
              permissions={permissions}
            >
              <Link to="/updateimei">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.u.updateimei}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* update vehicle */}
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="updatevehicle"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'updatevehicle' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="updatevehicle"
              permissions={permissions}
            >
              <Link to="/updatevehicle">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.u.updatevehicle}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="viewuser_perm"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'viewuserpermission' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="viewuser_perm"
              permissions={permissions}
            >
              <Link to="/viewuserpermission">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.d.dataVisibility}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="servicerepairdevice"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'servicerepairdevice' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="servicerepairdevice"
              permissions={permissions}
            >
              <Link to="/servicerepairdevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.s.Servicerepairdevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="servicemovedevice"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'servicemovedevice' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="servicemovedevice"
              permissions={permissions}
            >
              <Link to="/servicemovedevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.m.moveDevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          {/* <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                // matching_name="/cpac-sales-close-information"
                matching_name="requestMaterial"
                >
    <CustomMenuItem
      matching_name='requestMaterial'
      permissions={permissions}
    >
                <Link to="/cpac-sales-close-information">
                    <span className="nav-text">
                    {"อัพโหลดปิดข้อมูล. การขาย (จุดส่งของ)(พื้น)"}
                    </span>
                </Link>
                </CustomMenuItem></Menu.Item> */}
        </AutorizeMenu>

        {/* ตั้งค่า  */}
        <AutorizeMenu
          isActive={this.state.isActive}
          handleActiveMenu={this.handleActiveMenu}
          permissions={permissions}
          title={
            <span>
              <Icon
                type="setting"
                style={{ color: 'red', fontSize: 'larger' }}
              />
              <span>
                {' '}
                <LangContext.Consumer>
                  {i18n => i18n.s.setting}
                </LangContext.Consumer>
              </span>
            </span>
          }
          handleonOpenChange={this.onOpenChange}
          openKeysMenu={this.state.openKeys}
          subkey={'setting_submenu'}
        >
          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="cpacPolicy"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'cpacPolicy' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="cpacPolicy"
              permissions={permissions}
            >
              <Link to="/cpac-policy">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.c.cpacPolicy}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="policy"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor: this.state.isActive === 'policy' && '#ff000070',
            }}
          >
            <CustomMenuItem matching_name="policy" permissions={permissions}>
              <Link to="/policy">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.p.policy}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>

          <Menu.Item
            key={autoGenKey('slider-sub-menu')}
            matching_name="linenotify"
            web_endpoint={this.state.web_endpoint}
            web_use=""
            style={{
              backgroundColor:
                this.state.isActive === 'linenotify' && '#ff000070',
            }}
          >
            <CustomMenuItem
              matching_name="linenotify"
              permissions={permissions}
            >
              <Link to="/linenotify">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.l.linenotify}
                  </LangContext.Consumer>
                </span>
              </Link>
            </CustomMenuItem>
          </Menu.Item>
        </AutorizeMenu>
      </Menu>
    )
  }
}

// export default SliderBar;
const mapStateToProps = ({ auth: { profile } }) => ({
  profile,
})

export default connect(mapStateToProps, null)(SliderBar)
