import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
import moment from "moment";
import { push } from "connected-react-router";

function* loadServicerepairdevice(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );
  const {
    data: { page, pageSize, searchName, orderBy, orderType, filterObj }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/servicerepairdevice`,
      {
        page,
        pageSize,
        searchName,
        orderBy,
        orderType,
        filterObj,
        company_id: COMPANY_ID,
        vehicle_visibility
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadServicerepairdevice.success(res.data));
  } catch (err) {
    yield put(actions.loadServicerepairdevice.failure());
  }
}

function* loadHistoryservicerepairdevice(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );
  const {
    data: { page, pageSize, searchName, orderBy, orderType, filterObj }
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/servicerepairdevice/history`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id: COMPANY_ID,
        vehicle_visibility
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadHistoryservicerepairdevice.success(res.data));
  } catch (err) {
    yield put(actions.loadHistoryservicerepairdevice.failure());
  }
}

function* createServicerepairdevice(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const user_id = getlocalstorage(localStorage.getItem("profile"), "id");

  const {
    data: {
      type,
      vehicles_id,
      contact_name,
      contact_telno,
      submit_name,
      submit_telno,
      appointment_place,
      province,
      amphur,
      confirm_appointment_place,
      type_problem,
      detail_problem,
      comment
    },
    loading,
    history,
    company_id
  } = action.payload;

  let confirm_appointment_place_new = "";
  if (confirm_appointment_place != "") {
    confirm_appointment_place_new = confirm_appointment_place.target.checked;
  }
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/servicerepairdevice/create`,
      {
        type,
        vehicles_id,
        contact_name,
        contact_telno,
        submit_name,
        submit_telno,
        appointment_place,
        province,
        amphur,
        confirm_appointment_place: confirm_appointment_place_new,
        type_problem,
        comment,
        detail_problem,
        company_id,
        user_id
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.createServicerepairdevice.success(res.data));
    history.push("/servicerepairdevice");

    yield put(push("/servicerepairdevice"));
  } catch (err) {
    yield put(actions.createServicerepairdevice.failure());
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: "",
        allow_vehicles: vehicle_visibility
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicle.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicle.failure());
  }
}

function* loadVehicletype(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicletype`,
      {
        companyID: COMPANY_ID,
        allow_vehicles: vehicle_visibility
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadVehicletype.success(res.data));
  } catch (err) {
    yield put(actions.loadVehicletype.failure());
  }
}

function* loadProvince(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getprovince`,
      {},
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadProvince.success(res.data));
  } catch (err) {
    yield put(actions.loadProvince.failure());
  }
}

function* loadAmphur(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { province_id } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getamphur`,
      {
        province_id
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadAmphur.success(res.data));
  } catch (err) {
    yield put(actions.loadAmphur.failure());
  }
}

function* loadProblem(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getproblem`,
      {},
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadProblem.success(res.data));
  } catch (err) {
    yield put(actions.loadProblem.failure());
  }
}

function* loadMaintenancetype(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getmaintenancetype`,
      {},
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` }
      }
    );
    yield put(actions.loadMaintenancetype.success(res.data));
  } catch (err) {
    yield put(actions.loadMaintenancetype.failure());
  }
}

function* loadMaintenancestatus(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getmaintenancestatus`,
      {},
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` }
      }
    );
    yield put(actions.loadMaintenancestatus.success(res.data));
  } catch (err) {
    yield put(actions.loadMaintenancestatus.failure());
  }
}

function* loadZone(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getzonemaintenance`,
      {
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadZone.success(res.data));
  } catch (err) {
    yield put(actions.loadZone.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_SERVICEREPAIRDEVICE.REQUEST,
      loadServicerepairdevice
    ),
    takeEvery(
      actions.LOAD_HISTORYSERVICEREPAIRDEVICE.REQUEST,
      loadHistoryservicerepairdevice
    ),
    takeEvery(
      actions.CREATE_SERVICEREPAIRDEVICE.REQUEST,
      createServicerepairdevice
    ),
    takeEvery(actions.LOAD_VEHICLE_SERVICEREPAIRDEVICE.REQUEST, loadVehicle),
    takeEvery(
      actions.LOAD_VEHICLETYPE_SERVICEREPAIRDEVICE.REQUEST,
      loadVehicletype
    ),
    takeEvery(actions.LOAD_PROVINCE_SERVICEREPAIRDEVICE.REQUEST, loadProvince),
    takeEvery(actions.LOAD_AMPHUR_SERVICEREPAIRDEVICE.REQUEST, loadAmphur),
    takeEvery(actions.LOAD_PROBLEM_SERVICEREPAIRDEVICE.REQUEST, loadProblem),
    takeEvery(
      actions.LOAD_MAINTENANCETYPE_SERVICEREPAIRDEVICE.REQUEST,
      loadMaintenancetype
    ),
    takeEvery(
      actions.LOAD_MAINTENANCESTATUS_SERVICEREPAIRDEVICE.REQUEST,
      loadMaintenancestatus
    ),
    takeEvery(actions.LOAD_ZONE_SERVICEREPAIRDEVICE.REQUEST, loadZone)
  ]);
}

export {
  loadServicerepairdevice,
  loadHistoryservicerepairdevice,
  createServicerepairdevice,
  loadVehicle,
  loadVehicletype,
  loadProvince,
  loadAmphur,
  loadProblem,
  loadMaintenancetype,
  loadMaintenancestatus,
  loadZone
};
