import React from 'react'
import { Switch, Route } from 'react-router-dom'
import WebContent from './Index'
import WebContentNew from './Index_new'

export default () => (
  <Switch>
    <Route exact path="/WebContent" component={WebContentNew} />
  </Switch>
)
