import { createTypes, createAction } from 'lib/action'

const LOAD_TRUCKUSAGE = createTypes('truckusage', 'load')
const LOAD_TRUCKUSAGE_PREVIEW = createTypes('truckusagepreview', 'load')
const LOAD_VEHICLE_TRUCKUSAGE = createTypes('vehicle_truckusage', 'load')
const LOAD_VEHICLE_TYPE_TRUCKUSAGE = createTypes(
  'vehicletype_truckusage',
  'load',
)

const loadTruckusage = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKUSAGE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRUCKUSAGE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCKUSAGE.FAILURE),
}

const loadTruckusagepreview = {
  request: (data, loading) =>
    createAction(LOAD_TRUCKUSAGE_PREVIEW.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRUCKUSAGE_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRUCKUSAGE_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TRUCKUSAGE.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_TRUCKUSAGE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TRUCKUSAGE.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_TRUCKUSAGE.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_TRUCKUSAGE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TRUCKUSAGE.FAILURE),
}

export {
  loadTruckusage,
  LOAD_TRUCKUSAGE,
  loadTruckusagepreview,
  LOAD_TRUCKUSAGE_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_TRUCKUSAGE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TRUCKUSAGE,
}
