import {
  CREATE_REQUEST_MATERIAL,
  LOAD_REQUEST_MATEL,
  EXPORT_REQUEST_MATEL,
  REMOVE_LISTS,
} from './actions'

const intialState = {
  lists: [],
  total: 1,
  loading: false,
  check: true,
  check_create: false,
  autoComplete: [],
}

export default (state = intialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return intialState

    case LOAD_REQUEST_MATEL.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        loading: loading,
      }

    case LOAD_REQUEST_MATEL.SUCCESS:
      const { page, pageSize } = action.payload

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * pageSize
      }

      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case CREATE_REQUEST_MATERIAL.REQUEST:
      return {
        ...state,
        check_create: true,
      }

    case CREATE_REQUEST_MATERIAL.SUCCESS:
      return {
        ...state,
        loading: false,
        check_create: false,
      }

    case EXPORT_REQUEST_MATEL.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_REQUEST_MATEL.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
