import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Button, Row, Col, Icon, Table, Spin, Radio, Select } from 'antd'

import { StyledWidget } from '../../../../styled/common-styled'

const ButtonGroup = Button.Group

const { Option } = Select

const columns = [
  {
    width: '65%',
    title: (
      <LangContext.Consumer>{i18n => i18n.d.driverName}</LangContext.Consumer>
    ),
    dataIndex: 'name',
    render(text, record, index) {
      return <div style={{ fontSize: '14px' }}>{text}</div>
    },
  },
  {
    title: <LangContext.Consumer>{i18n => i18n.a.amount}</LangContext.Consumer>,
    dataIndex: 'amount',
    align: 'right',
    render(text, record, index) {
      return <div style={{ fontSize: '11px' }}>{text}</div>
    },
  },
]
const fakeData = [
  {
    key: '1',
    name: 'อนุสิทธิ์...',
    vehicle_group: 'Plant A',
    amount: 32,
    fine: 'ใบเตือน',
  },
  {
    key: '2',
    name: 'ประดิษฐ์...',
    vehicle_group: 'Plant A',
    amount: 26,
    fine: 'ใบเตือน',
  },
  {
    key: '3',
    name: 'ศุภชัย...',
    vehicle_group: ' Plant B',
    amount: 12,
    fine: 'ใบเตือน',
  },
  {
    key: '4',
    name: 'ธวัชชัย...',
    vehicle_group: 'est Plant C',
    amount: 11,
    fine: '1500',
  },
  {
    key: '5',
    name: 'สายยัน...',
    vehicle_group: 'Plant G',
    amount: 8,
    fine: '1000',
  },
  {
    key: '6',
    name: 'วาริน...',
    vehicle_group: 'Plant G',
    amount: 8,
    fine: '1000',
  },
  {
    key: '7',
    name: 'อะรุงกุ๊งกุ๊ง...',
    vehicle_group: 'Plant H',
    amount: 6,
    fine: '500',
  },
]

export class DriverSpeedOverLimitWidget extends Component {
  state = {
    rawData: false,
    chartType: 'daily',
    sortType: 'DESC',
    loading: false,
    data: [],
    dpType: 'none-dp',
  }

  onChartTypeChange = e => {
    this.setState({ chartType: e.target.value })

    this.loadData(
      true,
      e.target.value,
      this.state.sortType,
      this.state.rawData,
      this.state.dpType,
    )
  }

  onSortTypeChange = val => {
    this.setState({ sortType: val })

    this.loadData(
      true,
      this.state.chartType,
      val,
      this.state.rawData,
      this.state.dpType,
    )
  }

  onSortDpChange = val => {
    this.setState(
      {
        dpType: val,
        data: [],
      },
      () => {
        this.loadData(
          true,
          this.state.chartType,
          this.state.sortType,
          this.state.rawData,
          val,
        )
      },
    )
  }

  loadData = (loading, chartType, sortType, rawData, dpType) => {
    if (loading) this.setState({ loading: true })
    this.fetch(this, chartType, sortType, rawData, dpType)
  }

  fetch = (self, chartType, sortType, rawData, dpType) => {
    let keepDp = true
    if (dpType === 'none-dp') {
      keepDp = false
    }
    let formData = new FormData()
    formData.append('only_has_dp', keepDp)
    formData.append('chart_type', chartType)
    formData.append('sort_by', sortType)
    formData.append('company_id', self.props.auth.profile.company_id)
    formData.append(
      'vehicle_visibility',
      self.props.auth.profile.vehicle_visibility,
    )
    fetch(
      `${process.env.REACT_APP_GO_V2_API_ENDPOINT}/summary/speed_over_limit/driver`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          //   "Content-Type": "application/json",
          Authorization: `Bearer ${self.props.auth.accessToken}`,
        },
        body: formData,
      },
    )
      .then(response => response.json())
      .then(res => {
        if (Array.isArray(res)) {
          self.setState({
            loading: false,
            data: res,
          })
        }
      })
      .catch(function() {
        self.setState({
          loading: false,
        })
      })
  }
  componentDidMount() {
    if (!this.props.demo) {
      this.loadData(
        true,
        this.state.chartType,
        this.state.sortType,
        this.state.rawData,
        this.state.dpType,
      )
      this.interval = setInterval(
        () =>
          this.loadData(
            false,
            this.state.chartType,
            this.state.sortType,
            this.state.rawData,
            this.state.dpType,
          ),
        1000 * 60 * 60,
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    if (this.props.demo)
      return (
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ height: '90%', width: '100%' }}
        >
          <span className="semi-bold-16">
            <LangContext.Consumer>
              {i18n => i18n.s.speedOverLimitByDriver}
            </LangContext.Consumer>{' '}
          </span>

          <Table
            className="tracking-table"
            columns={columns}
            dataSource={fakeData}
            size="small"
            pagination={false}
            scroll={{ y: '140px' }}
          />
        </Col>
      )
    else
      return (
        <StyledWidget>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ height: '95%' }}
          >
            {' '}
            <Spin spinning={this.state.loading}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={9}>
                  <span className="semi-bold-16">
                    <LangContext.Consumer>
                      {i18n => i18n.s.speedOverLimitByDriver}
                    </LangContext.Consumer>{' '}
                  </span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={15} align="right">
                  <div>
                    <Select
                      size="small"
                      defaultValue="none-dp"
                      style={{ width: 110, marginRight: '8px' }}
                      onChange={this.onSortDpChange}
                    >
                      <Option
                        value="none-dp"
                        style={{ backgroundColor: 'white' }}
                        // className='hover-red'
                      >
                        <small>
                          <LangContext.Consumer>
                            {i18n => i18n.d.dpNotCounting}
                          </LangContext.Consumer>
                        </small>
                      </Option>
                      <Option value="dp" style={{ backgroundColor: 'white' }}>
                        <small>
                          <LangContext.Consumer>
                            {i18n => i18n.d.dpCounting}
                          </LangContext.Consumer>
                        </small>
                      </Option>
                    </Select>
                    <Select
                      size="small"
                      defaultValue="DESC"
                      style={{ width: 132, marginRight: '8px' }}
                      onChange={this.onSortTypeChange}
                    >
                      <Option value="DESC" style={{ backgroundColor: 'white' }}>
                        <small>
                          <LangContext.Consumer>
                            {i18n => i18n.s.sortAmountDesc}
                          </LangContext.Consumer>
                        </small>
                      </Option>
                      <Option value="ASC" style={{ backgroundColor: 'white' }}>
                        <small>
                          <LangContext.Consumer>
                            {i18n => i18n.s.sortAmountAsc}
                          </LangContext.Consumer>
                        </small>
                      </Option>
                    </Select>
                    <Radio.Group
                      onChange={this.onChartTypeChange}
                      defaultValue="daily"
                      size="small"
                    >
                      <Radio.Button value="daily">
                        <small>
                          <LangContext.Consumer>
                            {i18n => i18n.t.today}
                          </LangContext.Consumer>
                        </small>
                      </Radio.Button>
                      <Radio.Button value="monthly">
                        <small>
                          <LangContext.Consumer>
                            {i18n => i18n.m.monthly}
                          </LangContext.Consumer>
                        </small>
                      </Radio.Button>
                      <Radio.Button value="yearly">
                        <small>
                          <LangContext.Consumer>
                            {i18n => i18n.y.yearly}
                          </LangContext.Consumer>
                        </small>
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Table
                    className="tracking-table"
                    rowKey={record => record.key + record.vehicle_group}
                    columns={columns}
                    dataSource={this.state.data}
                    size="small"
                    pagination={false}
                    scroll={{ y: '170px' }}
                  />
                </Col>
              </Row>
            </Spin>
          </Col>
        </StyledWidget>
      )
  }
}

export default DriverSpeedOverLimitWidget
