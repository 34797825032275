import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Index from './Index'
import Preview from './Previewtable'

export default () => (
  <Switch>
    <Route
      exact
      path="/report/gpsvendorpayingsummaryreport"
      component={Index}
    />
    <Route
      exact
      path="/report/gpsvendorpayingsummaryreport/preview"
      component={Preview}
    />
  </Switch>
)
