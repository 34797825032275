import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Link } from 'react-router-dom'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from '../../../shared/components/Breadcrumb'
import { StyledSearchForm } from '../../../../styled/common-styled'
import axios from 'axios'
import { Button, Row, Col, Spin } from 'antd'

class Detail extends Component {
  state = {
    loading: true,
    detail: {
      section_name: '',
      section_code: '',
      division_code: '',
      division_name: '',
      department_code: '',
      department_name: '',
      notify_type: [],
      notify_type_name: [],
      line_token: [],
    },
    data_notify_type: [],
  }

  componentDidMount() {
    let self = this
    let id = this.props.match.params.id
    let token = this.props.auth.accessToken
    axios
      .get(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/linenotify/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function(response) {
        self.setState({
          detail: response.data.data,
          loading: false,
        })
      })
      .catch(function(error) {
        console.log(error)
      })

    // End Load User Group Premission
  }

  render() {
    return (
      <AuthorizeComponent matching_name="linenotify" {...this.props}>
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: '0px 0px 14px 10px' }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={5} lg={5} xl={5} />
            <Col sm={24} md={14} lg={14} xl={14}>
              <Spin spinning={this.state.loading}>
                <StyledSearchForm>
                  <Row style={{ marginTop: '17px', marginBottom: '20px' }}>
                    <Col span={8} style={{ padding: '5px' }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {i18n => i18n.s.section}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={16} style={{ padding: '5px' }}>
                      {this.state.detail.section_name}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '17px', marginBottom: '20px' }}>
                    <Col span={8} style={{ padding: '5px' }} align="right">
                      <b>
                        <LangContext.Consumer>
                          {i18n => i18n.s.section_code}
                        </LangContext.Consumer>
                        :
                      </b>
                    </Col>
                    <Col span={8} style={{ padding: '5px' }}>
                      {this.state.detail.section_code}
                    </Col>
                  </Row>

                  <Row>
                    {this.state.detail.line_token.map((v, i) => (
                      <Row>
                        {i === 0 ? (
                          <Col
                            span={8}
                            style={{ padding: '5px' }}
                            align="right"
                          >
                            <b>
                              <LangContext.Consumer>
                                {i18n => i18n.n.notify_type}
                              </LangContext.Consumer>
                              :
                            </b>
                          </Col>
                        ) : (
                          <Col
                            span={8}
                            style={{ padding: '5px' }}
                            align="right"
                          ></Col>
                        )}
                        <Col span={8} style={{ padding: '5px' }} align="left">
                          <LangContext.Consumer>
                            {i18n => i18n.notifytype[v.name_key]}
                          </LangContext.Consumer>
                          {': '}
                          {v.value}
                        </Col>
                      </Row>
                    ))}
                  </Row>

                  <Row style={{ marginTop: '17px', marginBottom: '20px' }}>
                    <Col
                      span={8}
                      style={{ padding: '5px' }}
                      align="right"
                    ></Col>
                    <Col span={16} style={{ padding: '5px' }}>
                      <Link to={`/linenotify`}>
                        <Button
                          type="default"
                          style={{ marginLeft: '5px', marginRight: '10px' }}
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.b.back}
                          </LangContext.Consumer>
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </StyledSearchForm>
              </Spin>
            </Col>
            <Col sm={24} md={5} lg={5} xl={5} />
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}
const mapStateToProps = ({ auth }) => ({
  auth,
})

export default connect(mapStateToProps, null)(Detail)
