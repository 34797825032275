import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import history from '../../../../../lib/history'
import { Formik } from 'formik'
import * as yup from 'yup'
import * as moment from 'moment'
import { debounce } from 'debounce'
import {
  Row,
  Col,
  Avatar,
  Icon,
  Button,
  Divider,
  DatePicker,
  Checkbox,
  Form,
  Input,
  Select,
  Spin,
  Tooltip,
  Skeleton,
  message,
} from 'antd'
import { Link, BrowserRouter as Router, withRouter } from 'react-router-dom'

import LangContext, { i18n } from 'modules/shared/context/langContext'
import { autoGenKey } from '../../../../../lib/helper'
import ConditionForm from './ConditionForm'
import FormDivider from '../../../../shared/components/FormDivider'

const InputGroup = Input.Group
const { Item } = Form
const FormItem = Item
const Option = Select.Option

const jsonDetailSkeleton = {
  area_location: {
    trigger: true,
    area_id: [],
  },
  speed_limit: {
    trigger: true,
    range: [90, 120],
  },
  duration: {
    trigger: true,
    range: [1, 60],
    unit: 'min',
  },
  day_time: {
    trigger: true,
    startAt: '00:00:00',
    endAt: '23:59:59',
    monday: false,
    tuesday: false,
    saturday: false,
    wendnesday: false,
    thursday: false,
    friday: false,
    sunday: false,
  },
}

class Step1Form extends Component {
  state = {
    initialValues: {
      ...jsonDetailSkeleton.area_location,
      policy_type: 'area_location',
    },
    conditionList: [],
    zoneList: [],
    has_duration_type: false,
    has_speed_limit_type: false,
    has_day_time_type: false,
  }

  componentDidMount() {
    let self = this
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dropdownlist/getzone`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          company_id: this.props.auth.profile.company_id,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        self.setState({ zoneList: data })
      })
  }

  handlePropsChange = obj => {
    this.setState(obj)
  }

  handleAddCondition = e => {
    this.setState({
      conditionList: [
        ...this.state.conditionList,
        {
          key: autoGenKey('condition-form'),
          initialValues: this.state.initialValues,
          handlePropsChange: this.handlePropsChange,
          removeCondition: this.removeCondition,
          policy_type: this.state.policy_type,
        },
      ],
    })
  }

  removeCondition = formId => {
    let { conditionList } = this.state

    this.setState({
      conditionList: conditionList.filter(ele => ele.key != formId),
    })
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={this.props.initialValues}
        validationSchema={yup.lazy(value => {
          switch (value.policy_type) {
            case 'maintenance':
              return yup.object().shape({
                policy_name: yup.string().required(),
                // policy_type: yup.string().required()
                // policy_sub_type: yup.string().required(),
                // category: yup.string().required()
              })
              break

            default:
              return yup.object().shape({
                policy_name: yup.string().required(),
                // policy_type: yup.string().required(),
                // policy_sub_type: yup.string().required(),
                // category: yup.string().required()
              })
              break
          }
        })}
        onSubmit={(values, actions) => {
          this.props.setInitValue({
            ...this.props.initialValues,
            policy_name: values.policy_name,
            policy_type: values.policy_type,
            policy_sub_type: values.policy_sub_type,
            category: values.category,
          })
          this.props.next(this.props.current)
        }}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <LangContext.Consumer>
            {i18n => {
              if (
                this.props.action == 'create' ||
                (this.props.action == 'edit' && values.policy_id != null)
              )
                return (
                  <Form onSubmit={handleSubmit}>
                    <FormDivider>
                      <LangContext.Consumer>
                        {i18n => i18n.p.policy}
                      </LangContext.Consumer>
                    </FormDivider>

                    <Row>
                      <Col xs={24} sm={18} md={13} lg={13} xl={10}>
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.n.name}
                            </LangContext.Consumer>
                          }
                          required={true}
                          validateStatus={
                            touched.policy_name && errors.policy_name
                              ? 'warning'
                              : undefined
                          }
                          help={
                            touched.policy_name && errors.policy_name
                              ? errors.policy_name.substr(
                                  errors.policy_name.indexOf(' ') + 1,
                                )
                              : undefined
                          }
                        >
                          <InputGroup compact>
                            <Input
                              style={{ width: '100%' }}
                              name="policy_name"
                              value={values.policy_name}
                              onChange={e => {
                                setFieldValue('policy_name', e.target.value)
                              }}
                              onBlur={handleBlur}
                            />
                          </InputGroup>
                        </FormItem>
                      </Col>
                    </Row>
                    <FormDivider>
                      <LangContext.Consumer>
                        {i18n => i18n.c.criteria}
                      </LangContext.Consumer>
                    </FormDivider>

                    <Row>
                      {this.state.conditionList.map(ele => (
                        <ConditionForm
                          {...ele}
                          formId={ele.key}
                          jsonDetailSkeleton={jsonDetailSkeleton}
                          handlePropsChange={this.handlePropsChange}
                          conditionList={this.state.conditionList}
                          has_duration_type={this.state.has_duration_type}
                          has_speed_limit_type={this.state.has_speed_limit_type}
                          has_day_time_type={this.state.has_day_time_type}
                          zoneList={this.state.zoneList}
                        />
                      ))}
                    </Row>
                    <Row>
                      <Col
                        span={20}
                        align="center"
                        style={{ margin: '40px 0px 0px 0px' }}
                      >
                        <Button
                          type="primary"
                          shape="round"
                          icon="plus"
                          onClick={this.handleAddCondition}
                        >
                          Add Condition
                        </Button>
                      </Col>
                    </Row>
                    {this.state.conditionList.length > 0 && (
                      <Fragment>
                        <FormDivider>
                          <LangContext.Consumer>
                            {i18n => 'สรุป'}
                          </LangContext.Consumer>
                        </FormDivider>

                        <Row style={{ margin: '40px 0px 0px 0px' }}>
                          <Col span={24}>
                            <FormItem
                              colon={false}
                              label={
                                <LangContext.Consumer>
                                  {i18n => 'เงื่อนไขทั้งหมด'}
                                </LangContext.Consumer>
                              }
                            >
                              <Row
                                style={{
                                  margin: '10px 0px 0px 0px',
                                }}
                              >
                                {this.state.conditionList.map((ele, i) => (
                                  <Col span={2}>
                                    <Avatar
                                      style={{
                                        // backgroundColor: "#f56a00",
                                        verticalAlign: 'middle',
                                      }}
                                      size="large"
                                    >
                                      {(i + 1 + 9).toString(36).toUpperCase()}
                                    </Avatar>
                                    <div
                                      style={{
                                        display:
                                          i ==
                                          this.state.conditionList.length - 1
                                            ? 'none'
                                            : 'block',
                                        margin: '0px 0px 0px 50px',
                                        fontWeight: '500',
                                      }}
                                    >
                                      And
                                    </div>
                                  </Col>
                                ))}

                                <Col span={5} offset={1}>
                                  <Form.Item
                                    label={
                                      <span>
                                        <LangContext.Consumer>
                                          {i18n => 'ความถี่'}
                                        </LangContext.Consumer>
                                        &nbsp;
                                        <Tooltip
                                          title={
                                            <LangContext.Consumer>
                                              {i18n =>
                                                'ความถี่ในการทริกเกอร์การแจ้งเตือน เมื่อเงื่อนไขทั้งหมดเป็นจริง'
                                              }
                                            </LangContext.Consumer>
                                          }
                                        >
                                          <Icon type="question-circle-o" />
                                        </Tooltip>
                                      </span>
                                    }
                                  >
                                    <Select
                                      key={autoGenKey('trigger-select')}
                                      // name="policy_type"
                                      defaultValue="1_min"
                                      // value={values.policy_type.toString()}
                                      // onChange={e => {
                                      //   this.handleInputChange(
                                      //     e,
                                      //     "policy_type"
                                      //   );
                                      //   this.disablePolicyTypeSelection(
                                      //     values.policy_type,
                                      //     e
                                      //   );
                                      // }}
                                    >
                                      <Option
                                        key="1_min"
                                        disabled={
                                          this.props.has_speed_limit_type
                                        }
                                      >
                                        <LangContext.Consumer>
                                          {i18n => 'ทุก 1 นาที'}
                                        </LangContext.Consumer>
                                      </Option>
                                      <Option
                                        key="5_min"
                                        disabled={this.props.has_duration_type}
                                      >
                                        <LangContext.Consumer>
                                          {i18n => 'ทุก 5 นาที'}
                                        </LangContext.Consumer>
                                      </Option>
                                      <Option
                                        key="15_min"
                                        disabled={this.props.has_duration_type}
                                      >
                                        <LangContext.Consumer>
                                          {i18n => 'ทุก 15 นาที'}
                                        </LangContext.Consumer>
                                      </Option>
                                      <Option
                                        key="30_min"
                                        disabled={this.props.has_day_time_type}
                                      >
                                        <LangContext.Consumer>
                                          {i18n => 'ทุก 30 นาที'}
                                        </LangContext.Consumer>
                                      </Option>
                                      <Option key="1_hour">
                                        <LangContext.Consumer>
                                          {i18n => 'ทุก 1 ชั่วโมง'}
                                        </LangContext.Consumer>
                                      </Option>
                                      <Option key="4_hour">
                                        <LangContext.Consumer>
                                          {i18n => 'ทุก 4 ชั่วโมง'}
                                        </LangContext.Consumer>
                                      </Option>

                                      <Option key="1_day">
                                        <LangContext.Consumer>
                                          {i18n => 'ทุก 1 วัน'}
                                        </LangContext.Consumer>
                                      </Option>
                                      <Option key="until_false">
                                        <LangContext.Consumer>
                                          {i18n => 'จนกว่าเงื่อนไขจะเป็นเท็จ'}
                                        </LangContext.Consumer>
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </FormItem>
                          </Col>
                        </Row>
                      </Fragment>
                    )}

                    <div style={{ margin: '40px 0px 0px 0px' }}>
                      <Button
                        htmlType="submit"
                        type="primary"
                        // onClick={() => this.props.next(this.props.current)}
                      >
                        <LangContext.Consumer>
                          {i18n => i18n.s.saveNext}
                        </LangContext.Consumer>
                      </Button>
                    </div>
                  </Form>
                )
              else return <Skeleton active paragraph={{ rows: 14 }} />
            }}
          </LangContext.Consumer>
        )}
      />
    )
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {},
)(Step1Form)
