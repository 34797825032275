import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Spin, Button, Modal } from 'antd'
import ReactPlayer from 'react-player'
import Timeline from 'react-visjs-timeline'
import moment from 'moment'
import Filter from './Filter'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import * as actions from '../actions'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from './../../../shared/components/Breadcrumb'
import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import axios from 'axios'

const columnStyle = { fontSize: '12px', fontWeight: '400' }

class Index extends Component {
  state = {
    filedownloading: false,
    idprocess: 'web' + new Date().getTime(),
    idconnect: '',
    height: window.innerHeight - 250,
    customTime: { marker: moment().format('YYYY-MM-DD') + ' 12:00:00' },
    items: [],
    date_min: moment().format('YYYY-MM-DD') + ' 00:00:00',
    date_max: moment().format('YYYY-MM-DD') + ' 23:59:59',
    time_cursor: '',
    datetime_select: '',
    link_streaming: null,
    camera_potion: '',
    playedSeconds: 0,
    duration: 0,
    playing: false,
    visible_button_refresh: false,
    check_load_vihicle: false,
    change_camera: false,
    time_playing: '',
  }

  ws = new WebSocket(
    `wss://${process.env.REACT_APP_WEBSOCKET}/ws?idprocess=` +
      this.state.idprocess,
  )

  getVehicleList = () => {
    const url =
      `https://${process.env.REACT_APP_WEBSOCKET}/getAllConnection?idprocess=` +
      this.state.idprocess
    //const url = "http://localhost:8001/getAllConnection?idprocess=web1667466579431"
    axios.get(url).then(res => {
      if (res.data.message !== '') {
        this.loadVehicle(res.data.message.split(','))
      }
    })
  }

  componentDidMount() {
    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      // console.log("connected");
      this.getVehicleList()
    }

    this.ws.onmessage = evt => {
      // listen to data sent from the websocket server
      // const message = JSON.parse(evt.data)
      // console.log("response message:", evt.data);
      var messages = evt.data.split('\n')

      for (var i = 0; i < messages.length; i++) {
        let obj = JSON.parse(messages[i])

        if (obj.Idprocess == '' && obj.Idconnect == '') {
          //   console.log('allconneciton', obj.Allconnection)
          //   if (this.state.check_load_vihicle === false) {
          //     this.loadVehicle(obj.Allconnection)
          //     this.setState({ check_load_vihicle: true })
          //   }
        } else {
          //console.log("data:", JSON.parse(obj.Data))
          let data = JSON.parse(obj.Data)
          if (data.type == 'get_list_playback_video') {
            let newlistFiles = []
            let d = data.duration_time
            let start_time = ''
            // console.log("length:", d.length);
            for (let i = 0; i < d.length; i++) {
              if (i == 0) start_time = String(d[i].date + ' ' + d[i].start_time) //" 12:00:00"
              newlistFiles.push({
                id: i + 1,
                start: String(d[i].date + ' ' + d[i].start_time),
                end: String(d[i].date + ' ' + d[i].end_time),
              })
            }
            // console.log("customTime:", start_time);
            // console.log("items:", newlistFiles);
            if (this.state.change_camera === true) {
              this.setState({
                items: newlistFiles,
                // customTime: { marker: start_time },
                date_min: moment(start_time).format('YYYY-MM-DD') + ' 00:00:00',
                date_max: moment(start_time).format('YYYY-MM-DD') + ' 23:59:59',
              })
            } else {
              this.setState({
                filedownloading: false,
                items: newlistFiles,
                customTime: { marker: start_time },
                date_min: moment(start_time).format('YYYY-MM-DD') + ' 00:00:00',
                date_max: moment(start_time).format('YYYY-MM-DD') + ' 23:59:59',
              })
            }

            if (this.state.change_camera === true) {
              let timeCurrent = moment(this.state.datetime_select)
                .add(this.state.playedSeconds, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss')
              // console.log(
              //     "timecurChangeCamera",
              //     this.state.datetime_select,
              //     timeCurrent
              // );
              this.setState({
                change_camera: false,
                customTime: { marker: timeCurrent },
                datetime_select: timeCurrent,
              })
              const data = JSON.stringify({
                type: 'playback_video',
                camera_source: this.state.camera_potion,
                datetime_select: timeCurrent,
              })
              const message = {
                Idconnect: this.state.idconnect,
                Idprocess: this.state.idprocess,
                Data: data,
              }
              // console.log("change camera:", JSON.stringify(message));
              this.ws.send(JSON.stringify(message))
            }
          } else if (data.type == 'playback_video') {
            var v = moment().format('YYYYMMDDh:mm:ss.SSS')
            // console.log('link_streaming:', data.link_streaming, data)
            if (
              data.link_streaming != 'No video streaming' &&
              data.link_streaming != undefined
            ) {
              // console.log(
              //     "https://stream.terminusfleet.com/hnt/" +
              //     obj.Idprocess +
              //     "/playlist.m3u8?" +
              //     v
              // );
              this.setState({
                filedownloading: false,
                idconnect: obj.Idprocess,
                link_streaming:
                  'https://stream.terminusfleet.com/hnt/' +
                  obj.Idprocess +
                  '/playlist.m3u8?' +
                  v, //data.link_streaming,
              })
            } else {
              if (data.link_streaming != undefined) {
                this.setState({
                  filedownloading: false,
                  playing: false,
                  link_streaming: null,
                })
                this.fileNotStream()
              } else {
                // this.setState({
                //     link_streaming:
                //   "https://stream.terminusfleet.com/hnt/" +
                //   obj.Idprocess +
                //   "/playlist.m3u8?" +v,
                //   playing: false,
                //   });
              }
            }
          }
        }
      }
    }

    this.ws.onclose = () => {
      // automatically try to reconnect on connection loss
      // console.log("disconnected");
    }
  }

  fileNotStream = () => {
    Modal.warning({
      content: (
        <LangContext.Consumer>
          {i18n => i18n.n.nofilevideo}
        </LangContext.Consumer>
      ),
    })
  }

  filterHoneytoastplayback = values => {
    const { imei, date, camera_position } = values
    const date_select = moment(date).format('YYYY-MM-DD')
    let change_camera = false
    if (this.state.camera_potion !== '' && this.state.idconnect === imei) {
      //เปลี่ยนกล้องทะเบียนเดิม
      if (this.state.camera_potion !== camera_position) {
        change_camera = true
      }
    }
    // console.log(
    //     this.state.idconnect,
    //     imei,
    //     this.state.camera_potion,
    //     camera_position,
    //     change_camera
    // );
    this.setState({
      filedownloading: true,
      idconnect: imei,
      camera_potion: camera_position,
      change_camera,
      playing: false,
      // link_streaming: null
    })
    const data = JSON.stringify({
      type: 'get_list_playback_video',
      date_select: date_select,
      camera_source: camera_position,
    })
    const message = {
      Idconnect: imei,
      Idprocess: this.state.idprocess,
      Data: data,
    }
    // console.log(JSON.stringify(message));
    this.ws.send(JSON.stringify(message))
  }

  loadVehicle = allimei => {
    const { loadVehicle } = this.props
    loadVehicle(allimei)
  }

  loadVideo = (e, file_name, imei) => {
    e.preventDefault()
    // console.log("filename", file_name, imei);
    this.setState({ filedownloading: true })
    const data = JSON.stringify({ type: 'download_video', name: file_name })
    const message = {
      Idconnect: imei,
      Idprocess: this.state.idprocess,
      Data: data,
    }
    // console.log(JSON.stringify(message));
    this.ws.send(JSON.stringify(message))
  }

  handleDuration = duration => {
    // console.log("onDuration", duration);
    this.setState({ duration })
  }

  // handleSeekChange = e => {
  //   console.log('seek:', parseFloat(e.target.value))
  //   this.setState({ played: parseFloat(e.target.value) })
  // }

  handleProgress = state => {
    // console.log("onProgress", state);
    //console.log("playing:", this.state.playing)
    let timeCurrent = moment(this.state.datetime_select)
      .add(state.playedSeconds, 'seconds')
      .format('HH:mm:ss')
    this.setState({
      playedSeconds: parseInt(state.playedSeconds),
      time_playing: timeCurrent,
    })
  }

  onErrorPlayer = e => {
    // console.log("error", e);
    let timeCurrent = moment(this.state.datetime_select)
      .add(this.state.playedSeconds, 'seconds')
      .format('YYYY-MM-DD HH:mm:ss')
    // console.log("timecur", timeCurrent);
    let timePlaying = new Date(timeCurrent)
    // console.log("timeplay", timePlaying);
    let items = this.state.items
    //if(e==="hlsError")
    //{
    // console.log("Have error",this.state.playing)
    // var v = moment().format('YYYYMMDDh:mm:ss.SSS')
    // this.setState({
    //     link_streaming:
    //     "https://stream.terminusfleet.com/hnt/" +
    //     this.state.idconnect +
    //     "/playlist.m3u8?" +v
    //   });
    // }else{
    if (this.state.playing == true) {
      for (let i = 0; i < items.length; i++) {
        let timeStart = new Date(items[i].start)
        let timeEnd = new Date(items[i].end)
        //console.log("item:", timeStart, timeEnd)
        if (
          timeStart.getTime() <= timePlaying.getTime() &&
          timePlaying.getTime() <= timeEnd.getTime()
        ) {
          // console.log("timeEnd:", timeEnd);

          let timePlayingM = moment(timeCurrent)
          let timeEndM = moment(items[i].end)
          let diffSeconds = timeEndM.diff(timePlayingM, 'seconds')
          if (diffSeconds < 10) {
            //this.setState({ playing: false })
            // console.log("diffSeconds :", diffSeconds);

            //////เล่นไฟล์ต่อไป/////////
            if (i !== items.length - 1) {
              this.setState({
                filedownloading: true,
                playing: false,
                visible_button_refresh: false,
                datetime_select: items[i + 1].start,
              })
              const data = JSON.stringify({
                type: 'playback_video',
                camera_source: this.state.camera_potion,
                datetime_select: items[i + 1].start,
              })
              const message = {
                Idconnect: this.state.idconnect,
                Idprocess: this.state.idprocess,
                Data: data,
              }
              // console.log(JSON.stringify(message));
              this.ws.send(JSON.stringify(message))
            }
          }
        }
      } //end for
    } //end if
    //}
  }

  handleEnded = () => {
    // console.log("onEnded");
  }
  // clickHandler = (props) => {
  //   const { group } = props
  //   console.log("ddd", props)
  //   this.setState({
  //     customTime: { marker: '2020-03-6 15:40:14' }
  //   });

  // }

  timechangeHandler = props => {
    const { group } = props
    // console.log("timechange", moment(props.time).format("H:mm:ss"));
    this.setState({
      time_cursor: moment(props.time).format('H:mm:ss'),
      customTime: { marker: moment(props.time).format('YYYY-MM-DD H:mm:ss') },
    })
  }

  timechangedHandler = props => {
    const { group } = props

    // console.log("timechanged", props);
    this.setState({
      filedownloading: true,
      visible_button_refresh: false,
      datetime_select: moment(props.time).format('YYYY-MM-DD HH:mm:ss'),
    })
    const data = JSON.stringify({
      type: 'playback_video',
      camera_source: this.state.camera_potion,
      datetime_select: moment(props.time).format('YYYY-MM-DD HH:mm:ss'),
    })
    const message = {
      Idconnect: this.state.idconnect,
      Idprocess: this.state.idprocess,
      Data: data,
    }
    // console.log(JSON.stringify(message));
    this.ws.send(JSON.stringify(message))
  }

  componentWillUnmount() {
    this.ws.close()
  }

  render() {
    //console.log("new items", this.state.items)
    const options = {
      width: '100%',
      height: '80px',
      stack: false,
      showMajorLabels: false,
      showCurrentTime: false,
      zoomMin: 1000 * 60,
      zoomMax: 1000 * 60 * 60 * 24,
      min: this.state.date_min,
      max: this.state.date_max,
      type: 'background',
      format: {
        minorLabels: {
          second: 's',
        },
      },
    }

    return (
      <AuthorizeComponent {...this.props} matching_name="honeytoastplayback">
        <GeneralStyledContent>
          <Spin spinning={this.state.filedownloading}>
            <Row gutter={24}>
              <Col span={24}>
                {/* <Breadcrumb
                  match={this.props.match}
                  style={{ margin: '0px 0px 14px 10px' }}
                /> */}
                <div className="semi-bold-16" style={{ color: 'black' }}>
                  <LangContext.Consumer>
                    {i18n => i18n.h.honeytoastplayback}
                  </LangContext.Consumer>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {
                  <Filter
                    OnClose={this.onCloseSearch}
                    onSubmit={this.filterHoneytoastplayback}
                    vehicleLists={this.props.honeytoastplayback.vehicleLists}
                  />
                }
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <ReactPlayer
                url={this.state.link_streaming}
                width="50%"
                height="30%"
                controls={true}
                ref={p => {
                  this.p = p
                }}
                //light="true"
                // style={{ borderStyle: "solid" }}
                onReady={() => this.setState({ playing: true })}
                onStart={() => this.setState({ visible_button_refresh: true })}
                onSeek={e => {
                  //   console.log('onSeek', e)
                }}
                onEnded={() => {
                  //   console.log('end')
                }}
                onDuration={this.handleDuration}
                onProgress={this.handleProgress}
                onBuffer={() => {
                  //   console.log('onBuffer')
                }}
                onError={e => this.onErrorPlayer(e)}
                allowFullScreen
                playing={this.state.playing}
              />
              <div style={{ paddingLeft: '10px' }}>
                {this.state.visible_button_refresh == true ? (
                  <Button type="primary" onClick={() => this.p.seekTo(0)}>
                    Refresh Stream
                  </Button>
                ) : (
                  ''
                )}
              </div>
              {/* <Button type="primary" onClick={() => this.setState({ link_streaming: "https://stream.terminusfleet.com/hnt/" + this.state.idconnect + "/playlist.m3u8?" + v })}>Refresh Stream</Button> */}
              {/* <Button type="primary" onClick={() => this.p.seekTo(0)}>Refresh Stream</Button> */}
            </Row>

            <Row style={{ paddingTop: '10px' }}>
              <Timeline
                options={options}
                items={this.state.items}
                // clickHandler={this.clickHandler}
                customTimes={this.state.customTime}
                timechangeHandler={this.timechangeHandler}
                timechangedHandler={this.timechangedHandler}
                // rangchangeHandler={this.rangchangeHandler.bind(this)}
              />
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: 'red',
                  fontSize: '18px',
                }}
              >
                <span className="regular-bold-14">
                  <LangContext.Consumer>
                    {i18n => i18n.s.selectTime}
                  </LangContext.Consumer>{' '}
                  : {this.state.time_cursor}(
                  <LangContext.Consumer>
                    {i18n => i18n.s.selectPlay}
                  </LangContext.Consumer>{' '}
                  : {this.state.time_playing})
                </span>
              </Col>
            </Row>
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ honeytoastplayback, auth: { profile } }) => ({
  honeytoastplayback,
  profile,
})

const mapDispatchToProps = {
  loadVehicle: actions.loadVehicle.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
