import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
} from './../../../constants/local_storage'

function* loadDataForManagVehicle(action) {
  const {
    data: {
      start_date,
      end_date,
      vehicle_visibility,
      company_id,
      filterObj,
      accesstoken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-manage-vehicle/get-data`,
      {
        start_date,
        end_date,
        vehicle_visibility,
        company_id,
        filterObj,
        accesstoken,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadDataForManagVehicle.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDataForManagVehicle.failure())
  }
}

function* loadRequestMaterial(action) {
  const {
    data: { date },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-manage-vehicle/get-request-material`,
      {
        company_id: COMPANY_ID,
        date,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.loadRequestMaterial.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadRequestMaterial.failure())
  }
}

function* assignWorkFormPlant(action) {
  const {
    data: { data_is_select, accesstoken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-assign-work-form-plant`,
      {
        data_is_select,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.assignWorkFormPlant.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.assignWorkFormPlant.failure())
  }
}

function* assignWorkFormVehicle(action) {
  const {
    data: {
      isupply_id,
      vehicle_id,
      driver_id,
      delivery_at,
      delivery_start_at,
      delivery_end_at,
      accesstoken,
    },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-assign-work-form-vehicle`,
      {
        isupply_id,
        vehicle_id,
        driver_id,
        delivery_at,
        delivery_start_at,
        delivery_end_at,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.assignWorkFormVehicle.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.assignWorkFormVehicle.failure())
  }
}
// * เพิ่มรถเข้ากองงาน
function* assignWorkSaleOrderFormVehicle(action) {
  const {
    data: { saleorder_lists, vehicle_id, driver_id, accesstoken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-assign-work-sale-order-form-vehicle`,
      {
        saleorder_lists,
        vehicle_id,
        driver_id,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.assignWorkFormVehicle.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.assignWorkFormVehicle.failure())
  }
}
// * เพิ่มรถเข้ากองงาน
function* assignWorkSaleOrderFormPlant(action) {
  const {
    data: { vehicles_driver_lists, saleorder_list_id, driver_id, accesstoken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-assign-work-sale-order-form-plant`,
      {
        vehicles_driver_lists,
        saleorder_list_id,
        driver_id,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.assignWorkSaleOrderFormPlant.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.assignWorkSaleOrderFormPlant.failure())
  }
}

function* matchVehicletoJob(action) {
  const {
    data: {
      isupply_id,
      vehicle_id,
      driver_id,
      delivery_at,
      config_json,
      accesstoken,
    },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-manage-vehicle/match-vehicle-job`,
      {
        isupply_id,
        vehicle_id,
        driver_id,
        delivery_at,
        config_json,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.matchVehicletoJob.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.matchVehicletoJob.failure())
  }
}

function* unMatchVehicletoJob(action) {
  const {
    data: { isupply_id, accesstoken },
    loading,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-cancel-assign`,
      {
        isupply_id,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    loading(res.data)
    yield put(actions.unMatchVehicletoJob.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.unMatchVehicletoJob.failure())
  }
}

function* loadAssignWorkVehicleStatusForDetail(action) {
  const {
    data: { id, accesstoken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardstatusreceivedailyJob/getfor/detail/${id}`,
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    yield put(actions.loadAssignWorkVehicleStatusForDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadAssignWorkVehicleStatusForDetail.failure())
  }
}

export default function* watchIsupplyManagVehicle() {
  yield all([
    takeEvery(
      actions.LOAD_DATA_FOR_MANAG_VEHICLE.REQUEST,
      loadDataForManagVehicle,
    ),
    takeEvery(actions.LOAD_DATA_REQUEST_MATERIAL.REQUEST, loadRequestMaterial),
    takeEvery(actions.MATCH_VEHICLE_TO_JOB.REQUEST, matchVehicletoJob),
    takeEvery(actions.UNMATCH_VEHICLE_TO_JOB.REQUEST, unMatchVehicletoJob),
    takeEvery(actions.ASSIGN_WORK_FROM_PLANT.REQUEST, assignWorkFormPlant),
    takeEvery(actions.ASSIGN_WORK_FROM_VEHICLE.REQUEST, assignWorkFormVehicle),
    takeEvery(
      actions.LOADDATA_ASSIGNWORK_VEHICLE_STATUS_FOR_DETAIL.REQUEST,
      loadAssignWorkVehicleStatusForDetail,
    ),
    takeEvery(
      actions.ASSIGN_WORK_SALE_ORDER_FROM_VEHICLE.REQUEST,
      assignWorkSaleOrderFormVehicle,
    ),
    takeEvery(
      actions.ASSIGN_WORK_SALE_ORDER_FROM_PLANT.REQUEST,
      assignWorkSaleOrderFormPlant,
    ),
  ])
}
export {
  loadDataForManagVehicle,
  loadRequestMaterial,
  matchVehicletoJob,
  unMatchVehicletoJob,
  assignWorkFormPlant,
  assignWorkFormVehicle,
  loadAssignWorkVehicleStatusForDetail,
  assignWorkSaleOrderFormVehicle,
  assignWorkSaleOrderFormPlant,
}
