import React, { useEffect, useState } from 'react'
import { FuelNotification } from '../../../../src/styled/common-styled'
import { Row, Col, Icon, Badge, Spin, Empty } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import SubListNotification from './components/SubListNotification'
import { connect } from 'react-redux'
import { Redirect, Link, withRouter } from 'react-router-dom'
const Index = props => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [count, setCount] = useState(0)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('user_id', props.profile.id)
    await fetch(`${process.env.REACT_APP_GO_API_ENDPOINT}/notification/get`, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        setData(data.data)
        setCount(data.count)
        localStorage.setItem('count-noti', data.count)
      })
    setLoading(false)
  }

  return (
    <Spin spinning={loading}>
      <FuelNotification>
        <Row gutter={[8, 8]}>
          <Col span={24} align="left">
            <p style={{ fontSize: '1.2rem', color: '#172b4d' }}>
              <LangContext.Consumer>
                {i18n => i18n.n.notification}
              </LangContext.Consumer>
            </p>
          </Col>
          <Col span={24}>
            <div className="content-fuel-noti">
              <Row gutter={[16, 16]}>
                {data &&
                  data.map(item => (
                    <SubListNotification
                      mainSub={item.data.main_subject}
                      subSubject={item.data.sub_subject}
                      type={item.type}
                      createdAt={item.created_at}
                      readAt={item.read_at}
                      data={item}
                      count={count}
                      fetchData={fetchData}
                      cardId={item.id}
                      profileId={props.profile.id}
                    />
                  ))}
                {data.length == 0 && (
                  <Col
                    span={24}
                    style={{
                      paddingTop: 20,
                      paddingLeft: 20,
                    }}
                  >
                    <Empty />
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </FuelNotification>
    </Spin>
  )
}
export default withRouter(
  connect(
    ({ auth, auth: { profile } }) => ({
      auth,
      profile,
    }),
    {},
  )(Index),
)
