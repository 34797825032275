import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Table,
  message,
  Tooltip,
} from 'antd'

import LangContext, { i18n } from 'modules/shared/context/langContext'
import difference from 'lodash/difference'
import BasicFilterReport from '../../basicFilterReport/Index'

const { RangePicker } = DatePicker
const FormItem = Form.Item

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
        <Tooltip
          title={
            <LangContext.Consumer>
              {i18n =>
                i18n.t.ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported
              }
            </LangContext.Consumer>
          }
        >
          <div className="disable-select-all-tranfers-data">
            <LangContext.Consumer>
              {i18n => i18n.c.chooseAll}
            </LangContext.Consumer>
          </div>
        </Tooltip>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
        }

        return (
          <Table
            scroll={{ y: 200, x: 250 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 100 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      event_date_start: '',
      event_date_end: '',
      vehicleList: [],
      vehicleSelect: [],
      selectedKeys: [],
      vehicle_type_id: '',
      initVehicleSelect: [],
      clickMenu: '',
    }
    this.leftTableColumns = [
      {
        dataIndex: 'name',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemLeft}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Left',
      },
    ]

    this.rightTableColumns = [
      {
        dataIndex: 'name',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemSelected}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Right',
      },
    ]
    this.Leftbtn = null
    this.Rightbtn = null
  }

  componentDidMount() {
    const { call } = this.props

    if (call == 'schedule') {
      if (this.props.dataedit != '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
          vehicle_type_id: obj.vehicle_type_id,
          initVehicleSelect: obj.list_vehicle_id,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    setFieldValue('vehicle_type_id', value)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleList } = nextProps
    const vehlist = []
    vehicleList.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleMenuClick = (setFieldValue, handleSubmit) => {
    setFieldValue('type_file', 'excel', false)
    handleSubmit()
  }

  handleClicReportkPreview = values => {
    const COMPANY_ID = this.props.auth.company_id
    const USER_ID = this.props.auth.id
    const vehicle_visibility = this.props.auth.vehicle_visibility

    const date_start = moment(values.eventdate[0]).format('YYYY-MM-DD HH:mm:00')
    const date_end = moment(values.eventdate[1]).format('YYYY-MM-DD HH:mm:59')

    const filter = {
      date_start,
      date_end,
      list_vehicle_id: values.list_vehicle_id,
      company_id: COMPANY_ID,
      vehicle_type_id: values.vehicle_type_id,
      user_id: USER_ID,
      type_file: 'preview',
      vehicle_visibility,
    }

    window.open('/report/drivingdistance/preview', '_blank')
    localStorage.setItem('drivingdistancereport-filter', JSON.stringify(filter))
  }

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      drivingdistanceLoading,
      onSubmit,
    } = this.props

    let validationShape = {
      eventdate: yup.array().required('กรุณาเลือกช่วงวันที่'),
      list_vehicle_id: yup.array().required('กรุณาเลือกรถ'),
    }

    if (this.props.call === 'schedule') {
      validationShape = {
        list_vehicle_id: yup.array().required('กรุณาเลือกรถ'),
      }
    }
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <Spin spinning={drivingdistanceLoading}>
          <LangContext.Consumer>
            {i18n => (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  vehicle_type_id: this.state.vehicle_type_id,
                  eventdate: [this.props.eventdate[0], this.props.eventdate[1]],
                  list_vehicle_id: this.state.initVehicleSelect,
                  division: this.props.selectedDivision,
                  department: this.props.selectedDepartment,
                  section: this.props.selectedSection,
                  plant: this.props.selectedPlant,
                  vehicleGroup: this.props.selectedVehicleGroup,
                }}
                validate={values => {
                  let errors = {}
                  if (values.eventdate.length > 0) {
                    const date_start = moment(values.eventdate[0]['_d'])
                    const date_end = moment(values.eventdate[1]['_d'])
                    let day_diff = date_end.diff(date_start, 'months')
                    if (day_diff > 0) {
                      errors.eventdate = (
                        <LangContext.Consumer>
                          {i18n => i18n.o.overOneMonth}
                        </LangContext.Consumer>
                      )
                    }
                  }

                  if (values.list_vehicle_id.length > 100) {
                    errors.list_vehicle_id = (
                      <LangContext.Consumer>
                        {i18n => i18n.o.over100}
                      </LangContext.Consumer>
                    )
                  }

                  return errors
                }}
                validationSchema={yup.object().shape(
                  this.props.call === 'schedule'
                    ? {
                        list_vehicle_id: yup
                          .array()
                          .required(`${i18n.p.pleaseChooseVehicle}`),
                      }
                    : {
                        eventdate: yup
                          .array()
                          .required(`${i18n.p.pleaseChooseDateRange}`),
                        list_vehicle_id: yup
                          .array()
                          .required(`${i18n.p.pleaseChooseVehicle}`),
                      },
                )}
                onSubmit={values => {
                  const loading = true
                  if (this.state.clickMenu === 'preview') {
                    this.handleClicReportkPreview(values)
                  } else {
                    onSubmit(values, loading)
                  }
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                }) => (
                  <Row type="flex" justify="center">
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        {' '}
                        <LangContext.Consumer>
                          {i18n => i18n.d.DrivingdistanceReport}
                        </LangContext.Consumer>
                      </div>
                      <BasicFilterReport
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                        division={this.props.division}
                        updateSelectDivision={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectDivision(e, values)
                          })
                        }}
                        department={this.props.department}
                        updateSelectDepartment={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectDepartment(e, values)
                          })
                        }}
                        section={this.props.section}
                        updateSelectSection={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectSection(e, values)
                          })
                        }}
                        plant={this.props.plant}
                        updateSelectPlant={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectPlant(e, values)
                          })
                        }}
                        vehicleGroup={this.props.vehicleGroup}
                        updateSelectVehicleGroup={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectVehicleGroup(e, values)
                          })
                        }}
                      />
                      <FormItem
                        required={true}
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'warning'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div>
                          <TableTransfer
                            dataSource={this.props.vehicleList}
                            targetKeys={values.list_vehicle_id}
                            showSearch
                            onChange={targetKeys => {
                              if (targetKeys.length > 100) {
                                message.error('เลือกรถได้ไม่เกิน 100 คัน')
                              } else {
                                setFieldValue('list_vehicle_id', targetKeys)
                              }
                            }}
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                            leftColumns={this.leftTableColumns}
                            rightColumns={this.rightTableColumns}
                            onSelectChange={this.handleSelectChange}
                          />
                        </div>
                      </FormItem>
                      {this.props.call !== 'schedule' ? (
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.d.dateRange}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.eventdate && errors.eventdate && 'warning'
                          }
                          help={touched.eventdate && errors.eventdate}
                        >
                          <RangePicker
                            disabledDate={disabledDate}
                            showTime={{ format: 'HH:mm' }}
                            onChange={value =>
                              setFieldValue('eventdate', value)
                            }
                            format="DD/MM/YYYY HH:mm"
                            placeholder={['Start Time', 'End Time']}
                            defaultValue={[
                              moment('00:00', 'HH:mm'),
                              moment('23:59', 'HH:mm'),
                            ]}
                          />
                        </Form.Item>
                      ) : (
                        ''
                      )}
                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call !== 'schedule' ? (
                            <Col span={8}>
                              <Button type="defualt" block>
                                <Link to="/">
                                  <LangContext.Consumer>
                                    {i18n => i18n.c.cancel}
                                  </LangContext.Consumer>
                                </Link>
                              </Button>
                            </Col>
                          ) : (
                            ''
                          )}

                          {this.props.call !== 'schedule' ? (
                            <Col span={8}>
                              <Button
                                type="primary"
                                htmlType="submit"
                                block
                                onClick={() =>
                                  this.setState({
                                    clickMenu: 'preview',
                                  })
                                }
                              >
                                <LangContext.Consumer>
                                  {i18n => i18n.p.preview}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            ''
                          )}

                          {this.props.call !== 'schedule' ? (
                            <Col span={8}>
                              <Button
                                type="primary"
                                onClick={() => {
                                  this.setState({
                                    clickMenu: 'report',
                                  })
                                  this.handleMenuClick(
                                    setFieldValue,
                                    handleSubmit,
                                  )
                                }}
                                block
                              >
                                <LangContext.Consumer>
                                  {i18n => i18n.r.report}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            ''
                          )}

                          {this.props.call == 'schedule' ? (
                            <Col span={8}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            ''
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  </Row>
                )}
              />
            )}
          </LangContext.Consumer>
        </Spin>
      </div>
    )
  }
}
