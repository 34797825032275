import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
//import { push } from "connected-react-router";

import * as actions from './actions'
import moment from 'moment'

function* tempLoadLoginhistory(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')
  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const {
    data: { type, eventdate, type_file },
  } = action.payload
  const date_start = moment(eventdate[0]['_d']).format('DD/MM/YYYY')
  const date_end = moment(eventdate[1]['_d']).format('DD/MM/YYYY')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_LOGIN}/service/report/loginhistory`,
      {
        date_start,
        date_end,
        type,
        company_id: COMPANY_ID,
        user_id: USER_ID,
        type_file,
        vehicle_visibility,
      },
    )

    yield put(actions.loadLoginhistory.success(res.data))
    window.location = res.data.data
  } catch (err) {
    yield put(actions.loadLoginhistory.failure())
  }
}

function* loadLoginhistory(action) {
  const {
    data: { eventdate, detail, company_id },
  } = action.payload

  const date_start = moment(eventdate[0]['_d']).format('DD/MM/YYYY')
  const date_end = moment(eventdate[1]['_d']).format('DD/MM/YYYY')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/loginhistory`,
      {
        date_start,
        date_end,
        detail,
        company_id,
      },
    )

    yield put(actions.loadLoginhistory.success(res.data))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_ENDPOINT}/${res.data.data}`,
    )
  } catch (error) {
    yield put(actions.loadLoginhistory.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([takeEvery(actions.LOAD_LOGINHISTORY.REQUEST, loadLoginhistory)])
}

export { loadLoginhistory }
