import {
  CREATE_USERGROUP,
  UPDATE_USERGROUP,
  LOAD_USERGROUP,
  REMOVE_LISTS,
  LOAD_USERGROUP_DETAIL,
  EXPORT_USERGROUP,
} from './actions'

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  detailLoading: false,
  draw: -1,
  selectedUsergroup: {
    usergroup_id: null,
    name: '',
    company: [],
    user: [],
    user_befor_edit: '',
    is_base: false,
    active: true,
    permission: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState
    case LOAD_USERGROUP.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        loading: loading,
      }
    case LOAD_USERGROUP.SUCCESS:
      const { page, pageSize } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * pageSize
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }
    case LOAD_USERGROUP_DETAIL.REQUEST:
      return {
        ...state,
        selectedUsergroup: { ...state.selectedUsergroup, usergroup_id: null },
        detailLoading: true,
      }
    case LOAD_USERGROUP_DETAIL.SUCCESS:
      console.log(
        'LOAD_USERGROUP_DETAIL.SUCCESS',
        action.payload.usergroup.data,
      )
      return {
        ...state,
        selectedUsergroup: action.payload.usergroup.data,
        detailLoading: false,
      }
    case CREATE_USERGROUP.SUCCESS:
      console.log('CREATE_USERGROUP.SUCCESS')
      const { res } = action.payload
      return {
        ...state,
        selectedUsergroup: { ...state.selectedUsergroup, usergroup_id: res.id },
        usergroup_id: res.id,
        detailLoading: false,
      }
    case UPDATE_USERGROUP.REQUEST:
      return {
        ...state,
        detailLoading: true,
      }
    case UPDATE_USERGROUP.SUCCESS:
      console.log('UPDATE_USERGROUP.SUCCESS')
      if (action.payload.res.status == 'fail')
        return {
          ...state,
          detailLoading: false,
        }
      return {
        ...state,
        detailLoading: false,
        selectedUsergroup: initialState.selectedUsergroup,
      }

    case EXPORT_USERGROUP.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case EXPORT_USERGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
