import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { COMPANY_ID, USER_ID } from './../../../constants/local_storage'
import * as actions from './actions'

function* loadCustomer(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/customer`,
      // `http://booking-api/api/customer`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadCustomer.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadCustomer.failure())
  }
}

function* loadCustomerDetail(action) {
  const {
    data: { id, accessToken },
    cb,
  } = action.payload
  // const company_id =
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/customer/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    // cb(res.data[0]);
    console.log(res)
    yield put(actions.loadCustomerDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadCustomerDetail.failure())
  }
}

function* createCustomer(action) {
  const {
    data: {
      customer_type,
      title,
      firstname,
      lastname,
      nationality,
      phone_number,
      phone_number_2,
      accessToken,
    },
    cb,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/customer/create`,
      {
        company_id: COMPANY_ID,
        customer_type,
        title,
        firstname,
        lastname: lastname == null ? '' : lastname,
        nationality,
        phone_number,
        phone_number_2,
        created_by: USER_ID,
        action_name: 'add',
        matching_name: 'customer',
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb()
    yield put(actions.createCustomer.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createCustomer.failure())
  }
}

function* updateCustomer(action) {
  const {
    data: {
      customer_id,
      customer_type,
      title,
      firstname,
      lastname,
      nationality,
      phone_number,
      phone_number_2,
      accessToken,
    },
    cb,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/customer/update`,
      {
        company_id: COMPANY_ID,
        customer_id,
        customer_type,
        title,
        firstname,
        lastname: lastname == null ? '' : lastname,
        nationality,
        phone_number,
        phone_number_2,
        updated_by: USER_ID,
        action_name: 'edit',
        matching_name: 'customer',
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb()

    yield put(actions.updateCustomer.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateCustomer.failure())
  }
}

export default function* watchCustomerMasterDataState() {
  yield all([
    takeEvery(actions.CREATE_CUSTOMER.REQUEST, createCustomer),
    takeEvery(actions.LOAD_CUSTOMER.REQUEST, loadCustomer),
    takeEvery(actions.LOAD_CUSTOMER_DETAIL.REQUEST, loadCustomerDetail),
    takeEvery(actions.UPDATE_CUSTOMER.REQUEST, updateCustomer),
  ])
}

export { createCustomer, loadCustomer, loadCustomerDetail, updateCustomer }
