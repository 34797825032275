import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadLocationSmartDispatching(action) {
  // console.log('Payload', action.payload)
  const {
    data: {
      get_plant,
      get_small_truck_route,
      get_no_small_truck_route,
      get_small_truck_agency,
      get_big_truck_agency,
      filter_location_name,
      location_visibility,
      companies_id,
      bounds,

      get_big_truck_route,
      get_gps_route
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/small-truck-route/locations`, // API URL
      {
        get_plant,
        get_small_truck_route,
        get_no_small_truck_route,
        get_small_truck_agency,
        get_big_truck_agency,
        filter_location_name,
        location_visibility,
        companies_id,
        bounds,

        get_big_truck_route,
        get_gps_route
      },
    )
    yield put(actions.loadLocationSmartDispatching.success(res.data))
  } catch (err) {
    // console.log('err', err)
    console.log(err.message)
    yield put(actions.loadLocationSmartDispatching.failure())
  }
}

function* loadSmartDispatching(action) {
  // console.log('PayloadData', action.payload)
  const {
    data: { accessToken, bounds, limits, companies_id },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/small-truck-route/route`, // API URL
      {
        bounds,
        limits,
        companies_id,
      },
    )
    // console.log('loadRoute', res.data)
    yield put(actions.loadSmartDispatching.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadSmartDispatching.failure())
  }
}

function* createSmartDispatching(action) {
  // console.log('PayloadCreate', action.payload)
  const {
    data: {
      accessToken,
      companies_id,
      created_user_id,
      small_truck_route,
      geofence,
      lat,
      lng,
      remark,
      name,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/small-truck-route/create`, // API URL
      {
        companies_id,
        created_user_id,
        small_truck_route,
        geofence,
        lat,
        lng,
        remark,
        name,
      },
    )
    // console.log('resData', res.data)
    yield put(actions.createSmartDispatching.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createSmartDispatching.failure())
  }
}

function* updateSmartDispatching(action) {
  const {
    data: {
      accessToken,
      geofence,
      user_id,
      companies_id,
      small_truck_route,
      name,
      lat,
      lng,
      id,
      remark,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.put,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/small-truck-route/edit`, //API URL
      {
        id,
        geofence,
        user_id,
        companies_id,
        small_truck_route,
        name,
        lat,
        lng,
        remark,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateSmartDispatching.success(res.data))
  } catch (err) {
    yield put(actions.updateSmartDispatching.failure())
  }
}

function* getNearByLocationSmartDispatching(action) {
  const {
    data: { company_id, lat, lng, location_visibility },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/small-truck-route/nearbylocations`, // API URL
      {
        company_id,
        lat,
        lng,
        location_visibility,
      },
    )
    // console.log('loadRoute', res.data)
    yield put(actions.getNearByLocationSmartDispatching.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.getNearByLocationSmartDispatching.failure())
  }
}

export default function* watchSmartDispatching() {
  yield all([
    takeEvery(actions.CREATE_SMART_DISPATCHING.REQUEST, createSmartDispatching),
    takeEvery(actions.LOAD_SMART_DISPATCHING.REQUEST, loadSmartDispatching),
    takeEvery(
      actions.LOAD_LOCATION_SMART_DISPATCHING.REQUEST,
      loadLocationSmartDispatching,
    ),
    takeEvery(actions.UPDATE_SMART_DISPATCHING.REQUEST, updateSmartDispatching),
    takeEvery(
      actions.GET_NEAR_BY_LOCATION_DISPATCHING.REQUEST,
      getNearByLocationSmartDispatching,
    ),
  ])
}
export {
  loadLocationSmartDispatching,
  updateSmartDispatching,
  loadSmartDispatching,
  getNearByLocationSmartDispatching,
}
