import { createTypes, createAction } from 'lib/action'
const LOAD_VEHICLE_SUMMARY_VEHICLE_USAGE = createTypes(
  'vehicle_summary_vehicle_usage',
  'load',
)
const LOAD_VEHICLE_TYPE_SUMMARY_VEHICLE_USAGE = createTypes(
  'vehicle_type_summary_vehicle_usage',
  'load',
)
const LOAD_PLANT_SUMMARY_VEHICLE_USAGE = createTypes(
  'plant_summary_vehicle_usage',
  'load',
)
const EXPORT_SUMMARY_VEHICLE_USAGE = createTypes(
  'exportsummaryvehicleusage',
  'load',
)
const EXPORT_SUMMARY_VEHICLE_USAGE_PREVIEW = createTypes(
  'exportsummaryvehicleusage_preview',
  'load',
)
const LOAD_DIVISION = createTypes('load_division', 'load')

const loadDivision = {
  request: (division_visibility, comp) =>
    createAction(LOAD_DIVISION.REQUEST, {
      division_visibility,
      comp,
    }),
  success: data => createAction(LOAD_DIVISION.SUCCESS, { data }),
  failure: () => createAction(LOAD_DIVISION.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_SUMMARY_VEHICLE_USAGE.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_SUMMARY_VEHICLE_USAGE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_SUMMARY_VEHICLE_USAGE.FAILURE),
}

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_SUMMARY_VEHICLE_USAGE.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_SUMMARY_VEHICLE_USAGE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_SUMMARY_VEHICLE_USAGE.FAILURE),
}

const loadPlant = {
  request: () => createAction(LOAD_PLANT_SUMMARY_VEHICLE_USAGE.REQUEST, {}),
  success: data =>
    createAction(LOAD_PLANT_SUMMARY_VEHICLE_USAGE.SUCCESS, { data }),
  failure: () => createAction(LOAD_PLANT_SUMMARY_VEHICLE_USAGE.FAILURE),
}

const exportSummaryVehicleUsage = {
  request: (data, loading) =>
    createAction(EXPORT_SUMMARY_VEHICLE_USAGE.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_SUMMARY_VEHICLE_USAGE.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_SUMMARY_VEHICLE_USAGE.FAITLURE),
}

const exportSummaryVehicleUsagePreview = {
  request: (data, loading) =>
    createAction(EXPORT_SUMMARY_VEHICLE_USAGE_PREVIEW.REQUEST, {
      data,
      loading,
    }),
  success: (lists, page) =>
    createAction(EXPORT_SUMMARY_VEHICLE_USAGE_PREVIEW.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_SUMMARY_VEHICLE_USAGE_PREVIEW.FAITLURE),
}

export {
  LOAD_VEHICLE_SUMMARY_VEHICLE_USAGE,
  LOAD_VEHICLE_TYPE_SUMMARY_VEHICLE_USAGE,
  loadVehicleType,
  loadVehicle,
  LOAD_PLANT_SUMMARY_VEHICLE_USAGE,
  loadPlant,
  EXPORT_SUMMARY_VEHICLE_USAGE,
  exportSummaryVehicleUsage,
  EXPORT_SUMMARY_VEHICLE_USAGE_PREVIEW,
  exportSummaryVehicleUsagePreview,
  LOAD_DIVISION,
  loadDivision,
}
