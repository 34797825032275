import {
    LOAD_POLICY,
    LOAD_POLICY_TYPE,
    LOAD_VEHICLE,
    CREATE_POLICY,
    UPDATE_POLICY,
    REMOVE_LISTS,
} from "./actions";

const initialState = {
    Lists: [],
    Policylists: [],
    PolicyTypelists: [],
    Vehiclelists:[],
    total: 1,
    loading: false,
    draw: -1,
    ckeck: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {

        case REMOVE_LISTS:
            return initialState;

        case LOAD_POLICY.REQUEST:
            const { loading } = action.payload.data;
            return {
                ...state,
                loading: loading,
                ckeck: 0
            }

        case LOAD_POLICY.SUCCESS:
            const { page } = action.payload;

            for (let i = 0; i < action.payload.Policylists.data.length; i++) {
                action.payload.Policylists.data[i].rowNumber = i + 1;
                if (page > 1)
                  action.payload.Policylists.data[i].rowNumber =
                    action.payload.Policylists.data[i].rowNumber + (page - 1) * 10;
            }
            return {
                ...state,
                Policylists: action.payload.Policylists.data,
                ckeck: 1,
                total: 
                    action.payload.Policylists.total - 1 <= 0
                    ? 1
                    : action.payload.Policylists.total <= 0
                    ? 1
                    : action.payload.Policylists.total,
                    loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case LOAD_POLICY_TYPE.SUCCESS:
            return {
                ...state,
                PolicyTypelists: action.payload.PolicyTypelists.data,
            };

        case LOAD_VEHICLE.SUCCESS:
            return {
                ...state,
                Vehiclelists: action.payload.Vehiclelists.data,
            }

        case CREATE_POLICY.SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case UPDATE_POLICY.SUCCESS:
            return {
                ...state,
                loading: false,
            }

        default:
            return state;
    }
}
  