import { createTypes, createAction } from "lib/action";
const LOAD_DATA_FOR_MANAG_QUEUE = createTypes("managqueue", "load");
const LOAD_DATA_FOR_UNCOMING_QUEUE = createTypes("upcomingqueue", "load");  

const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadDataForManagQueue = {
    request: (data, loading) =>
        createAction(LOAD_DATA_FOR_MANAG_QUEUE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_DATA_FOR_MANAG_QUEUE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_DATA_FOR_MANAG_QUEUE.FAITLURE)  
};

const loadDataForUnComingQueue = {
    request: (data, loading) =>
        createAction(LOAD_DATA_FOR_UNCOMING_QUEUE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_DATA_FOR_UNCOMING_QUEUE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_DATA_FOR_UNCOMING_QUEUE.FAITLURE)  
};


export {
    LOAD_DATA_FOR_MANAG_QUEUE, loadDataForManagQueue,
    LOAD_DATA_FOR_UNCOMING_QUEUE, loadDataForUnComingQueue,
    REMOVE_LISTS, removeLists
};