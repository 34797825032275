import { createTypes, createAction } from 'lib/action'

const LOAD_VEHICLEPERFORMANCECONCLUDE = createTypes(
  'vehicleperformanceconclude',
  'load',
)
const LOAD_VEHICLEPERFORMANCECONCLUDE_PREVIEW = createTypes(
  'vehicleperformanceconcludepreview',
  'load',
)

const LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE = createTypes(
  'vehicle_vehicleperformanceconclude',
  'load',
)
const LOAD_VEHICLE_TYPE_VEHICLEPERFORMANCECONCLUDE = createTypes(
  'vehicletype_vehicleperformanceconclude',
  'load',
)

const loadVehicleperformanceconclude = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLEPERFORMANCECONCLUDE.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_VEHICLEPERFORMANCECONCLUDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLEPERFORMANCECONCLUDE.FAILURE),
}

const loadVehicleperformanceconcludepreview = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLEPERFORMANCECONCLUDE_PREVIEW.REQUEST, {
      data,
      loading,
    }),
  success: data =>
    createAction(LOAD_VEHICLEPERFORMANCECONCLUDE_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLEPERFORMANCECONCLUDE_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE.FAILURE),
}

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_VEHICLEPERFORMANCECONCLUDE.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_VEHICLEPERFORMANCECONCLUDE.SUCCESS, {
      data,
    }),
  failure: () =>
    createAction(LOAD_VEHICLE_TYPE_VEHICLEPERFORMANCECONCLUDE.FAILURE),
}

export {
  loadVehicleperformanceconclude,
  LOAD_VEHICLEPERFORMANCECONCLUDE,
  loadVehicleperformanceconcludepreview,
  LOAD_VEHICLEPERFORMANCECONCLUDE_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_VEHICLEPERFORMANCECONCLUDE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_VEHICLEPERFORMANCECONCLUDE,
}
