import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Row,
  Col,
  Spin,
  Modal,
  Select,
  Icon,
  Empty,
  Badge,
  PageHeader,
} from 'antd'

import moment from 'moment'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import LangContext, { i18n } from 'modules/shared/context/langContext'

import { autoGenKey, getVehicleVisibility } from '../../../../lib/helper'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import FilterDrawer from './FilterDrawer'
import InfoRow from './InfoRow'
import IconDescModal from './IconDescModal'
import { checkPermissions } from '../../../../lib/helper'

const { Option } = Select
const initialSelectedEvent = {
  event_id: null,
  plate_no: null,
  action: null,
  lat: 13.604403,
  lng: 101.359439,
}

export class Index extends Component {
  static defaultProps = {
    center: {
      lat: 13.873479,
      lng: 100.922861,
    },
    zoom: 5.8,
  }

  state = {
    draw: -1,
    iconDetailVisible: false,
    filterVisible: false,
    collapseAll: true,
    infoRow: [autoGenKey('info-row')],
    loading: false,
    detailHeight: window.innerHeight / 2.9,
    panelWidth: 575,
    latest_config_change: new Date().getTime(),
    map: null,
    maps: null,
    mapAPI: { trafficLayer: null },
    selectedEvent: initialSelectedEvent,
    allPartner: [],
    partner: 'all',
    zone: 'all',
    location: 'all',
    start_at: moment().format('YYYY-MM-DD 00:00:00'),
    end_at: moment().format('YYYY-MM-DD 23:59:59'),
    isParkingSideRoadCheck: true,
    isSpeedOverCheck: true,
    isDrivingMoreThan4Check: true,
    isDrivingMoreThan10Check: true,
    isAggressiveTurnWithoutLightCheck: true,
    isAggressiveAccelerationAndImmediatelyBreakCheck: true,
    vehicleCodeSearchStr: '',
    plateNoSearchStr: '',
    simNoSearchStr: '',
    deviceCodeSearchStr: '',
    loadExportReport: false,
    vendor: [],
    date_start: moment()
      .clone()
      .startOf('months')
      .format('YYYY-MM-DD 00:00:00'),
    date_end: moment()
      .clone()
      .endOf('months')
      .format('YYYY-MM-DD 23:59:59'),
    vendor_id: this.props.auth.profile.company_id,
  }

  loadPartner = cb => {
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpartnersafetydashboard`,
      {
        //fetch(`http://booking-api-88:8080/api/getconcreteshippinginfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({}),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            allPartner: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  onTimeRangeChange = e => {
    let start_at = moment().format('YYYY-MM-DD 00:00:00')
    let end_at = moment().format('YYYY-MM-DD 23:59:59')
    if (e == 'past7day') {
      start_at = moment()
        .subtract(7, 'd')
        .format('YYYY-MM-DD 00:00:00')
      end_at = moment().format('YYYY-MM-DD 23:59:59')
    }
    if (e == 'month') {
      start_at = moment()
        .subtract(7, 'd')
        .format('YYYY-MM-01 00:00:00')
      end_at = moment().format('YYYY-MM-DD 23:59:59')
    }
    this.setState({ start_at: start_at, end_at: end_at })
  }

  handdleCheck = (key, value) => this.setState({ [key]: value })

  handleApiLoaded = (map, maps) => {
    if (typeof maps == 'undefined' || maps == null) window.location.reload()

    this.setState({
      map: map,
      maps: maps,
      mapAPI: { ...this.state.mapAPI, trafficLayer: new maps.TrafficLayer() },
    })
  }

  loadData = () => {
    this.props.loadSafetyInfo({
      partner: this.state.partner,
      zone: this.state.zone,
      location: this.state.location,
      start_at: this.state.start_at,
      end_at: this.state.end_at,
      vehicle_visibility: getVehicleVisibility(
        this.props.auth.profile.user_type,
        this.props.auth.profile.vehicle_visibility,
        this.props.auth.profile.org_chart_vehicle_visibility,
        this.props.auth.profile.data_visibility_node,
      ),
      cb: () => false,
    })
  }

  showFilterDrawer = () => {
    this.setState({
      filterVisible: true,
    })
  }

  onFilterDrawerClose = () => {
    this.setState({
      filterVisible: false,
    })
  }

  showIconDescModal = () => {
    this.setState({
      iconDetailVisible: true,
    })
  }

  handleIconDetailCancel = () => {
    this.setState({
      iconDetailVisible: false,
    })
  }

  handdleVehicleCodeChange = e =>
    this.setState({ vehicleCodeSearchStr: e.target.value })

  handdlePlateNoChange = e =>
    this.setState({ plateNoSearchStr: e.target.value })

  handdleSimNoChange = e => this.setState({ simNoSearchStr: e.target.value })

  handdleDeviceCodeChange = e =>
    this.setState({ deviceCodeSearchStr: e.target.value })

  componentDidMount() {
    this.loadVendor()
  }

  removeRow = rowInfoKey =>
    this.setState({
      infoRow: this.state.infoRow.filter(ele => ele != rowInfoKey),
    })

  componentWillUnmount() {}

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.monitoringState.loadedDateTime !==
        nextProps.monitoringState.loadedDateTime ||
      this.props.monitoringState.loading !==
        nextProps.monitoringState.loading ||
      this.state.FilterVisible != nextState.FilterVisible
    ) {
      return true
    }
    return true
  }

  loadVendor = e => {
    let self = this
    this.setState({ loadExportReport: true })
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvendor`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
        is_gps_vendor: this.props.auth.profile.is_gps_vendor,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ vendor: res.data, loadExportReport: false })
      })
      .catch(e => this.setState({ loadExportReport: false }))
  }

  getDateForReport = month => {
    if (month !== '') {
      let date_start = moment(month)
        .clone()
        .startOf('months')
        .format('YYYY-MM-DD')
      let date_end = moment(month)
        .clone()
        .endOf('months')
        .format('YYYY-MM-DD')
      this.setState({
        date_start,
        date_end,
      })
    } else {
      this.setState({
        date_start: '',
        date_end: '',
      })
    }
  }

  getVendorForReport = id => {
    this.setState({
      vendor_id: id,
    })
  }

  handdleExportReport = () => {
    const self = this

    self.setState({ loadExportReport: true })

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/gpsvendorpaying`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          date_start: this.state.date_start,
          date_end: this.state.date_end,
          vendor_list: [this.state.vendor_id],
          sort_by: 'device_code',
          vehicle_visibility: this.props.auth.profile.vehicle_visibility,
          type_file: 'excel',
        }),
      },
    )
      .then(response => response.json())
      .then(res => {
        self.setState({ loadExportReport: false }, () =>
          window.location.assign(
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data}`,
          ),
        )
      })
      .catch(e => this.setState({ loadExportReport: false }))
  }

  handdleExportGpsDisconnectOnTripReport = () => {
    const self = this

    self.setState({ loadExportReport: true })
    // console.log(this.state.date_start)
    fetch(
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/vehicle-disconnect-on-trip`,
      // `http://localhost/reports/go/vehicle-disconnect-on-trip`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          start_date: this.state.date_start,
          end_date: this.state.date_end,
          vehicles: this.props.auth.profile.vehicle_visibility
            .split(',')
            .map(ele => Number(ele)),
          vendor_id: this.state.vendor_id.toString(),
        }),
      },
    )
      .then(response => response.json())
      .then(res => {
        self.setState(
          { loadExportReport: false },
          () => (window.location = res.result),
        )
      })
      .catch(e => this.setState({ loadExportReport: false }))
  }

  render() {
    let windowHeight = 400
    if (
      typeof window.innerHeight != 'undefined' ||
      window.innerHeight != null
    ) {
      windowHeight = window.innerHeight
    }

    return (
      <AuthorizeComponent
        {...this.props}
        matching_name="gpsPerformanceDashboard"
      >
        <GeneralStyledContent>
          <Spin spinning={this.state.loadExportReport}>
            <Spin spinning={false}>
              <PageHeader
                style={{ paddingLeft: '0px' }}
                title={
                  <div className="header__text">
                    <LangContext.Consumer>
                      {i18n => i18n.g.gpsPerformanceDashboard}
                    </LangContext.Consumer>
                  </div>
                }
              />
              <Row>
                <Col span={24} style={{ marginBottom: '20px' }}>
                  <Spin spinning={false}>
                    <Col span={10}>
                      <Button
                        type="primary"
                        className="edge-element"
                        size="small"
                        onClick={e => this.showIconDescModal()}
                      >
                        <Icon type="dot-chart" />{' '}
                        <LangContext.Consumer>
                          {i18n => i18n.i.iconDesc}
                        </LangContext.Consumer>
                      </Button>
                    </Col>
                    <Col
                      span={14}
                      style={{ marginBottom: '10px' }}
                      align="right"
                    >
                      <Badge dot={this.state.isFilter} align="right">
                        <Button
                          icon="filter"
                          size="small"
                          align="right"
                          onClick={this.showFilterDrawer}
                        >
                          <span></span>
                          <LangContext.Consumer>
                            {i18n => i18n.f.filter}
                          </LangContext.Consumer>
                        </Button>
                      </Badge>
                    </Col>

                    {this.state.infoRow.map((ele, index) => (
                      <InfoRow
                        collapseAll={this.state.collapseAll}
                        auth={this.props.auth}
                        rowIndex={index}
                        rowInfoKey={ele}
                        removeRow={this.removeRow}
                        draw={this.state.draw}
                        vehicleCodeSearchStr={this.state.vehicleCodeSearchStr}
                        plateNoSearchStr={this.state.plateNoSearchStr}
                        simNoSearchStr={this.state.simNoSearchStr}
                        deviceCodeSearchStr={this.state.deviceCodeSearchStr}
                        getDateForReport={this.getDateForReport}
                        getVendorForReport={this.getVendorForReport}
                        vendorList={this.state.vendor}
                        vendorId={this.state.vendor_id}
                      ></InfoRow>
                    ))}
                    <FilterDrawer
                      showFilterDrawer={this.state.filterVisible}
                      handdleVehicleCodeChange={this.handdleVehicleCodeChange}
                      handdlePlateNoChange={this.handdlePlateNoChange}
                      handdleSimNoChange={this.handdleSimNoChange}
                      handdleDeviceCodeChange={this.handdleDeviceCodeChange}
                      applySearchButtonClick={() => {
                        this.setState({
                          draw: Math.floor(Math.random() * 1000) + 1,
                          isFilter:
                            this.state.vehicleCodeSearchStr != '' ||
                            this.state.plateNoSearchStr != '' ||
                            this.state.simNoSearchStr != '' ||
                            this.state.deviceCodeSearchStr != '',
                          filterVisible: false,
                        })
                      }}
                      onClose={this.onFilterDrawerClose}
                    />
                  </Spin>
                </Col>
              </Row>
              {this.state.date_start && this.state.date_start ? (
                <Row>
                  <Col align="right">
                    {checkPermissions(
                      this.props.auth.profile.permissions,
                      'gpsDisconnectOnTrip',
                      'can_view',
                    ) && (
                      <Button
                        type="primary"
                        style={{ marginRight: '5px' }}
                        onClick={() =>
                          this.handdleExportGpsDisconnectOnTripReport()
                        }
                      >
                        <Icon type="download" />{' '}
                        <LangContext.Consumer>
                          {i18n => i18n.g.gpsDisconnectOnTrip}
                        </LangContext.Consumer>
                      </Button>
                    )}{' '}
                    <Button
                      type="primary"
                      style={{ marginRight: '5px' }}
                      onClick={() => this.handdleExportReport()}
                    >
                      <Icon type="download" />{' '}
                      <LangContext.Consumer>
                        {i18n => i18n.g.gpsVendorPayingReport}
                      </LangContext.Consumer>
                    </Button>
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </Spin>
            <IconDescModal
              iconDetailVisible={this.state.iconDetailVisible}
              handleIconDetailCancel={this.handleIconDetailCancel}
            />
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ monitoringState, auth, auth: { profile } }) => ({
    monitoringState,
    auth,
    profile,
  }),
  null,
)(Index)
