import {
  CREATE_ISUPPLY_BOOKING_ORDER,
  LOAD_ISUPPLY_BOOKING_ORDER_LIST,
  LOAD_ISUPPLY_BOOKING_ORDER_DETAIL,
  UPDATE_ISUPPLY_BOOKING_ORDER,
  EXPORT_ISUPPLY_BOOKING_ORDER,
  LOAD_ISUPPLY_BOOKING_ORDER_DETAIL_FOR_SUGGEST_LOCATION,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  Lists: [],
  total: 1,
  loading: false,
  check: true,
  lists_for_edit: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_ISUPPLY_BOOKING_ORDER_LIST.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        loading: loading,
      }

    case LOAD_ISUPPLY_BOOKING_ORDER_LIST.SUCCESS:
      const { page, pageSize } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * pageSize
      }
      return {
        ...state,
        Lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case CREATE_ISUPPLY_BOOKING_ORDER.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_ISUPPLY_BOOKING_ORDER.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_ISUPPLY_BOOKING_ORDER.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_ISUPPLY_BOOKING_ORDER.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_ISUPPLY_BOOKING_ORDER_DETAIL.REQUEST:
      return {
        ...state,
        loading: true,
        check: true,
      }

    case LOAD_ISUPPLY_BOOKING_ORDER_DETAIL.SUCCESS:
      return {
        ...state,
        lists_for_edit: action.payload.listsEdit.data,
        check: false,
        loading: false,
      }

    case EXPORT_ISUPPLY_BOOKING_ORDER.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_ISUPPLY_BOOKING_ORDER.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_ISUPPLY_BOOKING_ORDER_DETAIL_FOR_SUGGEST_LOCATION.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOAD_ISUPPLY_BOOKING_ORDER_DETAIL_FOR_SUGGEST_LOCATION.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
