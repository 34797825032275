import { createTypes, createAction } from 'lib/action'

const LOAD_ALARMALERTBYDRIVER = createTypes('alarmalertbydriver', 'load')
const LOAD_ALARMALERTBYDRIVER_PREVIEW = createTypes(
  'alarmalertbydriver_preview',
  'load',
)
const LOAD_DRIVER_ALARMALERTBYDRIVER = createTypes(
  'driver_alarmalertbydriver',
  'load',
)

const loadAlarmAlertByDriver = {
  request: (data, loading) =>
    createAction(LOAD_ALARMALERTBYDRIVER.REQUEST, { data, loading }),
  success: data => createAction(LOAD_ALARMALERTBYDRIVER.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARMALERTBYDRIVER.FAILURE),
}

const loadAlarmAlertByDriverpreview = {
  request: (data, loading) =>
    createAction(LOAD_ALARMALERTBYDRIVER_PREVIEW.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_ALARMALERTBYDRIVER_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARMALERTBYDRIVER_PREVIEW.FAILURE),
}

const loadDriver = {
  request: () => createAction(LOAD_DRIVER_ALARMALERTBYDRIVER.REQUEST, {}),
  success: data =>
    createAction(LOAD_DRIVER_ALARMALERTBYDRIVER.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVER_ALARMALERTBYDRIVER.FAILURE),
}

export {
  LOAD_ALARMALERTBYDRIVER,
  LOAD_ALARMALERTBYDRIVER_PREVIEW,
  LOAD_DRIVER_ALARMALERTBYDRIVER,
  loadAlarmAlertByDriver,
  loadAlarmAlertByDriverpreview,
  loadDriver,
}
