import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Transfer,
  Checkbox,
  Dropdown,
  Menu,
  Icon,
  Table,
} from 'antd'
import difference from 'lodash/difference'
import LangContext, { i18n } from 'modules/shared/context/langContext'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <div
          onClick={() => {
            restProps.onChange(restProps.dataSource.map(item => item.key))
            console.log(
              'test',
              restProps.dataSource.map(item => item.key),
            )
          }}
        >
          choose
        </div>,
        <div
          onClick={() => {
            restProps.onChange([])
            console.log('test', restProps)
          }}
        >
          Target
        </div>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns
        // console.log('filteredItems', filteredItems)
        // console.log('rest', restProps)
        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
          hideDefaultSelections: true,
          selections: [
            {
              //   key: 'all-data',
              text: 'Select All Data',
              onSelect: (record, selected, selectedRows, nativeEvent) => {
                let getKey = []
                filteredItems.map(item => getKey.push(item.key))
                onItemSelectAll([...getKey], true)
              },
            },
          ],
        }
        return (
          <Table
            rowSelection={rowSelection}
            scroll={{ y: 200, x: 250 }}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 100 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.leftTableColumns = [
      {
        dataIndex: 'name',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemLeft}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Left',
      },
    ]

    this.rightTableColumns = [
      {
        dataIndex: 'name',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemSelected}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Right',
      },
    ]
    this.Leftbtn = null
    this.Rightbtn = null
  }

  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    clickMenu: '',
  }

  componentDidMount() {
    const { loadVehicle, loadVehicleType } = this.props
    loadVehicle('', false)
    loadVehicleType()
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
    // console.log('ddd')
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehicle } = this.props
    setFieldValue('vehicle_type_id', value)
    loadVehicle(value, true)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleLists } = nextProps
    const vehlist = []
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log('handleSelectChange', sourceSelectedKeys, targetSelectedKeys)
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          console.log('Leftbtn')
          this.Leftbtn.click()
        } else {
          console.log('Rightbtn')
          this.Rightbtn.click()
        }
      },
    )
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    // console.log('click', e.key);
    if (e.key == 'excel') {
      setFieldValue('type_file', 'excel', false)
    } else if (e.key == 'pdf') {
      setFieldValue('type_file', 'pdf', false)
    }
    handleSubmit()
  }

  handleClicReportkPreview = values => {
    const COMPANY_ID = this.props.auth.company_id
    const USER_ID = this.props.auth.id
    const vehicle_visibility = this.props.auth.vehicle_visibility

    const filter = {
      list_vehicle_id: values.list_vehicle_id,
      company_id: COMPANY_ID,
      vehicle_type_id: values.vehicle_type_id,
      user_id: USER_ID,
      type_file: 'preview',
      vehicle_visibility,
    }

    window.open('/report/truckcurrentlocation/preview', '_blank')
    localStorage.setItem(
      'truckcurrentlocationreport-filter',
      JSON.stringify(filter),
    )
  }

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      truckcurrentlocationLoading,
      onSubmit,
    } = this.props

    return (
      <div>
        <Spin spinning={truckcurrentlocationLoading}>
          <LangContext.Consumer>
            {i18n => (
              <Formik
                initialValues={{
                  vehicle_type_id: '',
                  list_vehicle_id: [],
                }}
                validate={values => {
                  let errors = {}

                  return errors
                }}
                validationSchema={yup.object().shape({
                  list_vehicle_id: yup
                    .array()
                    .required(`${i18n.p.pleaseChooseVehicle}`),
                })}
                onSubmit={values => {
                  const loading = true
                  if (this.state.clickMenu === 'preview') {
                    this.handleClicReportkPreview(values)
                  } else {
                    onSubmit(values, loading)
                  }
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                }) => (
                  <Row type="flex" justify="center">
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        <LangContext.Consumer>
                          {i18n => i18n.t.TruckcurrentlocationReport}
                        </LangContext.Consumer>
                      </div>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicleGroup}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.vehicle_type_id &&
                          errors.vehicle_type_id &&
                          'error'
                        }
                        help={touched.vehicle_type_id && errors.vehicle_type_id}
                      >
                        <Select
                          onChange={value =>
                            this.onVehicleTypeChange(value, setFieldValue)
                          }
                          value={values.vehicle_type_id}
                        >
                          <Option value="">{i18n.a.all}</Option>
                          {vehicleTypeLists.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'error'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div>
                          <TableTransfer
                            dataSource={this.state.vehicleList}
                            targetKeys={values.list_vehicle_id}
                            showSearch
                            onChange={targetKeys => {
                              console.log(targetKeys)
                              setFieldValue('list_vehicle_id', targetKeys)
                            }}
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                            leftColumns={this.leftTableColumns}
                            rightColumns={this.rightTableColumns}
                            onSelectChange={this.handleSelectChange}
                          />
                        </div>
                      </FormItem>

                      <FormItem>
                        <Row gutter={24}>
                          <Col span={8}>
                            <Button type="defualt" block>
                              <Link to="/">
                                <LangContext.Consumer>
                                  {i18n => i18n.c.cancel}
                                </LangContext.Consumer>
                              </Link>
                            </Button>
                          </Col>
                          <Col span={8}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              block
                              onClick={() =>
                                this.setState({
                                  clickMenu: 'preview',
                                })
                              }
                            >
                              <LangContext.Consumer>
                                {i18n => i18n.p.preview}
                              </LangContext.Consumer>
                            </Button>
                          </Col>
                          <Col span={8}>
                            <Dropdown
                              overlay={
                                <Menu
                                  onClick={value => {
                                    this.setState({
                                      clickMenu: 'report',
                                    })
                                    this.handleMenuClick(
                                      value,
                                      setFieldValue,
                                      handleSubmit,
                                    )
                                  }}
                                >
                                  <Menu.Item key="excel">
                                    <Icon type="file-excel" />{' '}
                                    <LangContext.Consumer>
                                      {i18n => i18n.e.excel}
                                    </LangContext.Consumer>
                                  </Menu.Item>
                                  <Menu.Item key="pdf">
                                    <Icon type="file-pdf" />{' '}
                                    <LangContext.Consumer>
                                      {i18n => i18n.p.pdf}
                                    </LangContext.Consumer>
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <Button type="primary" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.r.report}
                                </LangContext.Consumer>{' '}
                                <Icon type="down" />
                              </Button>
                            </Dropdown>
                          </Col>
                        </Row>
                      </FormItem>
                    </Form>
                  </Row>
                )}
              />
            )}
          </LangContext.Consumer>
        </Spin>
      </div>
    )
  }
}
