import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb as AntBreadcrumb, Icon } from "antd";

const Breadcrumb = ({ match, ...props }) => (
  <AntBreadcrumb {...props}>
    <AntBreadcrumb.Item key="breadcrumb-home">
      <Link to={`/`}>
        <Icon type="home" />
      </Link>
    </AntBreadcrumb.Item>
    {match.path
      .replace("_", " ")
      .replace("/", "")
      .split("/")
      .filter(val => val.indexOf(":") <= -1)
      .map((ele, i) => {
        let isLastest =
          i + 1 !=
          match.path
            .replace("/", "")
            .split("/")
            .filter(val => val != "")
            .filter(val => val.indexOf(":") <= -1).length;

        return isLastest ? (
          <AntBreadcrumb.Item key={`breadcrumb-${ele}`}>
            <Link to={`/${ele.replace(" ", "_")}`}>
              {ele.replace("_", " ")}
            </Link>
          </AntBreadcrumb.Item>
        ) : (
          <AntBreadcrumb.Item key={`breadcrumb-${ele}`}>
            {ele.replace("_", " ")}
          </AntBreadcrumb.Item>
        );
      })}
  </AntBreadcrumb>
);
export default Breadcrumb;
