import React, { Component, Fragment } from "react";
import {
    Table,
    Row,
} from "antd";
import axios from "axios";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { GeneralStyledContent } from "../../../../styled/common-styled";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
class TableShowDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 10,
            orderBy: "seq",
            orderType: "asc",
            detailLists: [],
            detailTotal: 0,
            skuLoading: false,

        };


        this.columns = [
            {
                title: <div className='semi-bold-16'>#</div>,
                dataIndex: "row_number",
                className: 'column-text-center',
                width: 60,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div className='regular-bold-14'>{text}</div>,
                    };
                },
            },
            {
                title: (
                    <div className='semi-bold-16'>
                        <LangContext.Consumer>
                            {(i18n) => i18n.u.uom}
                        </LangContext.Consumer>
                    </div>

                ),
                dataIndex: "uom_name",
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: (
                            <div className='regular-bold-14'>
                                {text}
                            </div>
                        ),
                    };
                },
            },
            {
                title: (
                    <div className='semi-bold-16'>
                        <LangContext.Consumer>
                            {(i18n) => i18n.d.description}
                        </LangContext.Consumer>
                    </div>

                ),
                dataIndex: "description",
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div className='regular-bold-14'>{text}</div>,
                    };
                },
            },
            {
                title: (
                    <div className='semi-bold-16'>
                        <LangContext.Consumer>
                            {(i18n) => i18n.sku.weight}
                        </LangContext.Consumer>
                    </div>

                ),
                dataIndex: "weight",
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div className='regular-bold-14'>{text}</div>,
                    };
                },
            },
            {
                title: (
                    <div className='semi-bold-16'>
                        <LangContext.Consumer>
                            {(i18n) => i18n.weight_uom.weight_uom}
                        </LangContext.Consumer>
                    </div>

                ),
                dataIndex: "weight_uom",
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div className='regular-bold-14'> <LangContext.Consumer>
                            {(i18n) => i18n.weight_uom[text]}
                        </LangContext.Consumer></div>,
                    };
                },
            },
            {
                title: (
                    <div className='semi-bold-16'>
                        <LangContext.Consumer>
                            {(i18n) => i18n.sku.quality}
                        </LangContext.Consumer>
                    </div>

                ),
                dataIndex: "quality",
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div className='regular-bold-14'>{text}</div>,
                    };
                },
            },
            {
                title: (
                    <Fragment>
                        <div className='semi-bold-16'>
                            <LangContext.Consumer>
                                {(i18n) => i18n.sku.from_uom}
                            </LangContext.Consumer><span style={{ color: "red" }}>*</span>
                        </div>

                    </Fragment>
                ),
                dataIndex: "from_uom_name",
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div className='regular-bold-14'>{text}</div>,
                    };
                },
            },
        ];
    }

    componentDidMount() {
        this.loadSku();
    }



    loadSku = () => {
        let self = this;
        let token = this.props.auth.accessToken;
        let product_id = this.props.product_id;
        this.setState({ skuLoading: true })
        axios
            .post(
                `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/sku`,
                {
                    product_id
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then(function (response) {
                if (typeof response.data.data !== "undefined") {
                    let detailLists = response.data.data;
                    self.setState({ detailLists });
                }
                self.setState({ skuLoading: false })
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        const columns = this.columns.map((col) => col);

        return (
            <GeneralStyledContent>
                <Row>
                    <Table
                        bordered
                        size="small"
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={this.state.detailLists}
                        // pagination={{
                        //   pageSize: this.state.pageSize,
                        //   total: this.state.deliverTotal,
                        //   current: this.state.page
                        // }}
                        pagination={false}
                        loading={this.state.skuLoading}
                    // onChange={this.handleTableChange}
                    />
                </Row>

            </GeneralStyledContent>
        );
    }
}

export default TableShowDetail;
