import axios from 'axios'
import { all, put, call, takeEvery } from 'redux-saga/effects'
import * as actions from './actions'
import { getErrorMsg } from 'lib/fetch'

function* loadDivision(action) {
    const ACCESSTOKEN = localStorage.getItem('access-token')
    const {
        division_visibility,
        comp,
        company_id,
        successLoading,
    } = action.payload

    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`,
            {
                division_visibility: division_visibility,
                comp: comp,
                company_id: company_id,
            },
            { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
        )
        // console.log('divisionLists', res.data)
        yield put(actions.loadDivision.success(res.data))
        successLoading()
    } catch (err) {
        alert(getErrorMsg(err))
        yield put(actions.loadDivision.failure())
    }
}

function* loadDepartment(action) {
    const ACCESSTOKEN = localStorage.getItem('access-token')
    //const company_id = JSON.parse(localStorage.getItem('profile')).company_id
    const { department_visibility, division, company_id, successLoading } = action.payload

    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`,
            JSON.stringify({
                department_visibility: department_visibility,
                division: [division],
                company_id,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${ACCESSTOKEN}`,
                },
            },
        )
        // console.log('departmentList', res.data)
        yield put(actions.loadDepartment.success(res.data))
        successLoading()
    } catch (err) {
        alert(getErrorMsg(err))
        yield put(actions.loadDepartment.failure())
    }
}

function* loadSection(action) {
    const ACCESSTOKEN = localStorage.getItem('access-token')
    //const company_id = JSON.parse(localStorage.getItem('profile')).company_id

    const { section_visibility, department, company_id, successLoading } = action.payload

    try {
        // console.log('inputdepartment', section_visibility, department)

        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsection`,
            JSON.stringify({
                section_visibility: section_visibility,
                department: department,
                company_id,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${ACCESSTOKEN}`,
                },
            },
        )
        // console.log('sectionList', res.data)
        yield put(actions.loadSection.success(res.data))
        successLoading()
    } catch (err) {
        alert(getErrorMsg(err))
        yield put(actions.loadSection.failure())
    }
}

function* loadPlant(action) {
    const ACCESSTOKEN = localStorage.getItem('access-token')

    const {
        location_visibility,
        section_visibility,
        section,
        relate_company,
        successLoading,
    } = action.payload

    try {
        // console.log(
        //   'inputloadPlant',
        //   location_visibility,
        //   section_visibility,
        //   section,
        //   relate_company,
        // )

        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantforconcretedashboard`,
            JSON.stringify({
                location_visibility: location_visibility,
                section_visibility: section_visibility,
                section: section,
                relate_company: relate_company,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${ACCESSTOKEN}`,
                },
            },
        )
        // console.log('plantList', res.data)
        yield put(actions.loadPlant.success(res.data))
        successLoading()
    } catch (err) {
        alert(getErrorMsg(err))
        yield put(actions.loadPlant.failure())
    }
}
function* loadVehicleGroup(action) {
    const ACCESSTOKEN = localStorage.getItem('access-token')

    const { comp, successLoading } = action.payload

    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
            JSON.stringify({
                company_id: comp,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${ACCESSTOKEN}`,
                },
            },
        )
        // console.log('vehicleGropLists', res.data.data)
        yield put(actions.loadVehicleGroup.success(res.data.data))
        successLoading()
    } catch (err) {
        yield put(actions.loadVehicleGroup.failure())
    }
}

function* loadVehicle(action) {
    const ACCESSTOKEN = localStorage.getItem('access-token')
    const {
        vehicle_group,
        section_visibility,
        division,
        department,
        section,
        plant,
        vehicle_visibility,
        partner,
    } = action.payload

    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclesbydivisionbranch`,
            JSON.stringify({
                vehicle_group: vehicle_group,
                section_visibility: section_visibility,
                division: division,
                department: department,
                section: section,
                plant: plant,
                vehicle_visibility: vehicle_visibility,
                partner: partner,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${ACCESSTOKEN}`,
                },
            },
        )
        // console.log('vehicleLists', res.data.data)
        yield put(actions.loadVehicle.success(res.data.data))
    } catch (err) {
        alert(getErrorMsg(err))
        yield put(actions.loadVehicle.failure())
    }
}

export default function* watchunauthorizeunload() {
    yield all([takeEvery(actions.LOAD_DIVISION.REQUEST, loadDivision)])
    yield all([takeEvery(actions.LOAD_DEPARTMENT.REQUEST, loadDepartment)])
    yield all([takeEvery(actions.LOAD_SECTION.REQUEST, loadSection)])
    yield all([takeEvery(actions.LOAD_PLANT.REQUEST, loadPlant)])
    yield all([takeEvery(actions.LOAD_VEHICLE_GROUP.REQUEST, loadVehicleGroup)])
    yield all([takeEvery(actions.LOAD_VEHICLE.REQUEST, loadVehicle)])
}
export {
    loadDivision,
    loadDepartment,
    loadSection,
    loadPlant,
    loadVehicleGroup,
    loadVehicle,
}
