import React, { Component } from "react";
import {
  Modal,
  Button,
  Form,
  Table,
  Row,
  Col,
  AutoComplete,
  InputNumber,
  Popconfirm,
  Tooltip,
  Icon
} from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import moment from "moment";

import LangContext, { i18n } from "modules/shared/context/langContext";
import history from "../../../../lib/history";
//import { GeneralStyledContent } from "../../../../styled/common-styled";

const FormItem = Form.Item;
const OptionAuto = AutoComplete.Option;
const columnStyle = { fontSize: "12px", fontWeight: "400" };

class FormProduct extends Component {
  state = {
    productList: [],
    // producttableList: [],
    product: "",
    product_name: "",
    quality: 1,

    popup: false,
    page: 1,
    pageSize: 10,
    orderBy: "id",
    orderType: "asc",
    productLoading: false
  };

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.deliver_id != this.props.deliver_id) {
    //   //console.log(this.props.producttableList);
    //   this.setState({ producttableList: this.props.producttableList });
    // }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.productLists.length != nextProps.productLists.length) {
      const { productLists } = nextProps;
      const productlists = [];
      productLists.map(item =>
        productlists.push({
          key: item.id,
          name: item.name
        })
      );
      this.setState({ productList: productlists });
    }
  };

  // handleClick = () => {
  //   this.props.onOk(this.state.producttableList, this.props.deliver_id);

  // };

  handleSubmitClick = (value, setFieldValue, handleSubmit) => {
    setFieldValue("product", this.state.product, false);
    setFieldValue("product_name", this.state.product_name, false);
    setFieldValue("quality", this.state.quality, false);

    const { deliver_id } = this.props;
    setFieldValue("deliver_id", deliver_id, false);
    handleSubmit();
  };

  selectProduct = (value, option, setFieldValue) => {
    this.setState({ product_name: option.props.children });
    this.setState({ product: value });
  };

  onSearchProduct = searchText => {
    if (searchText != "") {
      const { loadProduct } = this.props;
      loadProduct(searchText);
    }
    this.setState({ product_name: searchText });
  };

  handleCancelClick = () => {
    this.setState({
      product: "",
      product_name: "",
      quality: 1
      //producttableList: []
    });

    this.props.onCancel();
  };

  changeQuality = value => {
    this.setState({ quality: value });
  };

  render() {
    const { visible, onOk, onCancel } = this.props;

    const productlists = this.state.productList.map(v => (
      <OptionAuto key={v.key}>{v.name}</OptionAuto>
    ));

    const formItemLayout = {
      // labelCol: { span: 6 },
      // wrapperCol: { span: 14 }
    };

    this.columns = [
      {
        title: "#",
        dataIndex: "rowNumber",
        width: 60,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.p.product_name}
          </LangContext.Consumer>
        ),
        dataIndex: "product_name",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.n.number}</LangContext.Consumer>
        ),
        dataIndex: "quality",
        sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle
            },
            children: <div>{text}</div>
          };
        }
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.a.action}</LangContext.Consumer>
        ),
        key: "action_btn",
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {i18n => i18n.d.delete}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Popconfirm
                  title={
                    <LangContext.Consumer>
                      {i18n => i18n.d.doYouWantToDeleteTheseItems}
                    </LangContext.Consumer>
                  }
                  onConfirm={() => {
                    this.props.deleteProduct(record.id);
                  }}
                >
                  <a
                    href="javascript:;"
                    style={{ padding: "0px 15px 0px 5px" }}
                  >
                    <Icon type="close" />
                  </a>
                </Popconfirm>
              </Tooltip>
            </div>
          );
        }
      }
    ];

    const columns = this.columns.map(col => col);
    return (
      <div>
        <div>
          <Modal
            style={{ top: 20 }}
            title={
              <LangContext.Consumer>
                {i18n => i18n.m.manage_product}
              </LangContext.Consumer>
            }
            visible={visible}
            //onOk={this.handleClick}
            onCancel={this.handleCancelClick}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            okText={
              <LangContext.Consumer>{i18n => i18n.o.okay}</LangContext.Consumer>
            }
            //cancelText={<LangContext.Consumer>{i18n => i18n.c.cancel}</LangContext.Consumer>}
            width={800}
            // closable={false}
          >
            <div>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  product: this.state.product,
                  quality: this.state.quality
                }}
                validate={values => {
                  let errors = {};
                  if (values.quality == 0 || values.quality == null) {
                    console.log("erro");
                    errors.quality = "กรุณากรอกจำนวน";
                  }
                  return errors;
                }}
                validationSchema={yup.object().shape({
                  product: yup.string().required("กรุณาเลือกสินค้า")
                  // quality: yup.string().required("กรุณากรอกจำนวน")
                })}
                onSubmit={values => {
                  //const loading = true;
                  // console.log(values);
                  this.props.saveTableProductList(values);
                  this.setState({
                    product: "",
                    product_name: "",
                    quality: 1
                  });
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                  resetForm
                }) => (
                  <Form onSubmit={handleSubmit} layout="inline">
                    <FormItem
                      {...formItemLayout}
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.p.product_name}
                        </LangContext.Consumer>
                      }
                      required={true}
                      validateStatus={
                        touched.product && errors.product && "error"
                      }
                      help={touched.product && errors.product}
                    >
                      <AutoComplete
                        dataSource={productlists}
                        style={{ width: 200 }}
                        optionLabelProp="children"
                        filterOption={true}
                        optionFilterProp="children"
                        value={this.state.product_name}
                        onSelect={(value, option) =>
                          this.selectProduct(value, option, setFieldValue)
                        }
                        onSearch={this.onSearchProduct}
                      />
                    </FormItem>
                    <FormItem
                      required={true}
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.n.number}
                        </LangContext.Consumer>
                      }
                      validateStatus={
                        touched.quality && errors.quality && "error"
                      }
                      help={touched.quality && errors.quality}
                    >
                      <InputNumber
                        min={1}
                        type="number"
                        value={this.state.quality}
                        onChange={value => this.changeQuality(value)}
                      />
                    </FormItem>

                    <FormItem>
                      <Button
                        type="primary"
                        onClick={value =>
                          this.handleSubmitClick(
                            value,
                            setFieldValue,
                            handleSubmit
                          )
                        }
                      >
                        <LangContext.Consumer>
                          {i18n => i18n.s.save}
                        </LangContext.Consumer>
                      </Button>
                    </FormItem>
                  </Form>
                )}
              />
            </div>
            <br />
            <div>
              <Table
                bordered
                size="small"
                columns={columns}
                rowKey={record => record.id}
                dataSource={this.props.producttableList}
                // pagination={{
                //   pageSize: this.state.pageSize,
                //   total: this.state.deliverTotal,
                //   current: this.state.page
                // }}
                pagination={false}
                loading={this.props.loading}
                // onChange={this.handleTableChange}
              />
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default FormProduct;
