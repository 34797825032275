import React, { Component } from 'react'
import { connect } from 'react-redux'
import LangContext from 'modules/shared/context/langContext'
import * as actions from '../actions'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import { helper_convertDateformat } from '../../../../lib/helper'
import { checkPermissions } from '../../../../lib/helper'
import { getlocalstorage } from './../../../../constants/local_storage'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import {
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
  Icon,
  Tooltip,
  message,
  Modal,
  Tag,
  Spin,
} from 'antd'
import moment from 'moment'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Filter from './Filter'
import RemarkModal from './RemarkModal'

const columnStyle = { fontSize: '12px', fontWeight: '400' }
const { confirm } = Modal

class Index extends Component {
  state = {
    page: 1,
    pageSize: 10,
    orderBy: 'm.id',
    orderType: 'desc',
    visibleModalRemark: false,
    loading: [],
    expandedData: [],
    expandedRowKeys: [],
    selectedRowKeys: [], // Check here to configure the default column
    EditRemarkID: '',
    EditRemarkValue: '',
    filterObj: {
      plate_no: '',
      doc_number: '',
      maintenance_status_id: '',
      inform_date: '',
      date_start: moment()
        .startOf('day')
        .subtract(3, 'months')
        .format('YYYY-MM-DD HH:mm:ss'),
      date_end: moment()
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      type_problem_id: '',
    },
  }

  filterMaintenancedevice = values => {
    const {
      plate_no,
      doc_number,
      maintenance_status_id,
      inform_date,
      type_problem_id,
    } = values
    const date_start =
      inform_date == ''
        ? ''
        : moment(inform_date[0]['_d'])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')

    const date_end =
      inform_date == ''
        ? ''
        : moment(inform_date[1]['_d'])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')

    this.setState({
      page: 1,
      filterObj: {
        plate_no,
        date_start,
        date_end,
        doc_number,
        maintenance_status_id,
        type_problem_id,
        inform_date: '',
      },
    })
    this.props.loadMaintenancedevice(
      {
        page: 1,
        pageSize: this.state.pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: this.state.filterObj,
      },
      true,
    )
  }

  loadPageData = () => {
    this.props.loadMaintenancedevice(this.state, true)
    this.props.loadMaintenancestatus()
    this.props.loadTypeproblem()
  }

  componentDidMount() {
    this.loadPageData()
  }

  componentDidUpdate() {
    const { dataResponse } = this.props.maintenancedevice

    if (
      dataResponse.action_type == 'create' ||
      dataResponse.action_type == 'update'
    ) {
      if (dataResponse.status == 'success') {
        message.info('สำเร็จ')
      } else if (dataResponse.status == 'fail') {
        message.info(dataResponse.msg)
      }
      this.props.clearDataResponse()
    }
  }

  handleTableChange = (pagination, _, sorter) => {
    this.setState({
      page: pagination.current,
      pageSize: pagination.pageSize,
      loading: [],
      expandedData: [],
      expandedRowKeys: [],
    })

    let orderBy = ''
    let orderType = ''

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      this.setState({
        orderBy: 'm.id',
        orderType: 'desc',
      })
      orderBy = 'm.id'
      orderType = 'desc'
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == 'descend' ? 'desc' : 'asc',
      })
      orderBy = sorter.columnKey
      orderType = sorter.order == 'descend' ? 'desc' : 'asc'
    }

    this.props.loadMaintenancedevice(
      {
        page: pagination.current,
        pageSize: pagination.pageSize,
        searchName: '',
        orderBy: orderBy,
        orderType: orderType,
        filterObj: this.state.filterObj,
      },
      true,
    )
  }

  deleteMaintenancedevice = id => {
    let self = this

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function() {
        self.props.loadMaintenancedevice(
          {
            page: 1,
            pageSize: self.state.pageSize,
            orderBy: self.state.orderBy,
            orderType: self.state.orderType,
            filterObj: {
              plate_no: self.state.filterObj.plate_no,
              date_start: self.state.filterObj.date_start,
              date_end: self.state.filterObj.date_end,
              doc_number: self.state.filterObj.doc_number,
              maintenance_status_id: self.state.filterObj.maintenance_status_id,
              type_problem_id: self.state.filterObj.type_problem_id,
            },
          },
          true,
        )
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  checkVehicleInTerminus = (record, vehicle_code, i18n) => {
    let self = this
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice/checkvehicleinterminus`,
        {
          vehicle_code,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function(response) {
        if (response.data.vehicle_id == null) {
          self.haveCarInTerminus(record, i18n, response.data.vehicle_id)
        } else {
          self.changeStatusMaintenancedevice(
            record.id,
            2,
            record.type_problem_id,
            record.gps_vendor_id,
            true,
          )
        }
      })
  }

  haveCarInTerminus = (record, i18n, vehicle_id) => {
    let self = this
    confirm({
      title: i18n.f.failedToOpenTicketAtTerminus,
      content: i18n.t.theVehicleWasNotFoundInTheTERMINUSSystem,
      okText: i18n.o.openATicketAtTheCpacSystem,
      cancelText: i18n.c.cancel,
      onOk() {
        self.changeStatusMaintenancedevice(
          record.id,
          2,
          record.type_problem_id,
          record.gps_vendor_id,
          false,
        )
      },
      onCancel() {},
    })
  }

  changeStatusMaintenancedevice = (
    id,
    maintenance_status_id,
    type_problem_id,
    gps_vendor_id,
    have_car_in_terminus,
  ) => {
    let self = this
    const user_id = getlocalstorage(localStorage.getItem('profile'), 'id')

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice/updatestatus`,
        {
          maintenance_status_id,
          type_problem_id,
          user_id,
          id,
          gps_vendor_id,
          have_car_in_terminus,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function(response) {
        self.props.loadMaintenancedevice(
          {
            page: self.state.page,
            pageSize: self.state.pageSize,
            orderBy: self.state.orderBy,
            orderType: self.state.orderType,
            filterObj: {
              plate_no: self.state.filterObj.plate_no,
              date_start: self.state.filterObj.date_start,
              date_end: self.state.filterObj.date_end,
              doc_number: self.state.filterObj.doc_number,
              maintenance_status_id: self.state.filterObj.maintenance_status_id,
              type_problem_id: self.state.filterObj.type_problem_id,
            },
          },
          true,
        )
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  handleDisplayRemarkModal = (value, id, remark) => () => {
    this.setState({
      visibleModalRemark: value,
      EditRemarkID: id,
      EditRemarkValue: remark,
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys })
  }

  showConfirm = i18n => {
    if (this.state.selectedRowKeys.length > 0) {
      const user_id = getlocalstorage(localStorage.getItem('profile'), 'id')
      let self = this
      confirm({
        content: i18n.c.confirmToChangeStatus,
        okText: i18n.o.ok,
        cancelText: i18n.c.cancel,
        onOk() {
          axios
            .post(
              `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice/updatestatusmulti`,
              {
                user_id,
                id: self.state.selectedRowKeys,
              },
              {
                headers: {
                  Authorization: `Bearer ${self.props.auth.accessToken}`,
                },
              },
            )
            .then(function(response) {
              self.setState({ selectedRowKeys: [] })
              self.props.loadMaintenancedevice(
                {
                  page: self.state.page,
                  pageSize: self.state.pageSize,
                  orderBy: self.state.orderBy,
                  orderType: self.state.orderType,
                  filterObj: {
                    plate_no: self.state.filterObj.plate_no,
                    date_start: self.state.filterObj.date_start,
                    date_end: self.state.filterObj.date_end,
                    doc_number: self.state.filterObj.doc_number,
                    maintenance_status_id:
                      self.state.filterObj.maintenance_status_id,
                    type_problem_id: self.state.filterObj.type_problem_id,
                  },
                },
                true,
              )
            })
            .catch(function(error) {
              console.log(error)
            })
        },
        onCancel() {},
      })
    } else {
      message.error(i18n.p.pleaseSelectWorksheetMaintenance)
    }
  }

  filterObject = (obj, predicate) =>
    Object.fromEntries(Object.entries(obj).filter(predicate))

  handleExpand = async (expanded, record) => {
    const { loading, expandedRowKeys } = this.state
    if (expanded) {
      this.setState({
        loading: { ...loading, [record.id]: true },
        expandedRowKeys: [...expandedRowKeys, record.id],
      })

      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice/dp-list/${record.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.auth.accessToken}`,
          },
        },
      )

      this.setState(prevState => ({
        expandedData: {
          ...prevState.expandedData,
          [record.id]: data,
        },
        loading: { ...prevState.loading, [record.id]: false },
      }))
    } else {
      this.setState(prevState => ({
        expandedRowKeys: this.state.expandedRowKeys.filter(
          key => key !== record.id,
        ),
        expandedData: {
          ...prevState.expandedData,
          [record.id]: null,
        },
        loading: { ...prevState.loading, [record.id]: true },
      }))
    }
  }

  render() {
    const { selectedRowKeys } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    }
    const columns = [
      {
        title: <div className="regular-bold-14">#</div>,
        className: 'column-text-center',
        dataIndex: 'row_number',
        width: '3%',
        render(text) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>{i18n => i18n.d.doc_no}</LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'document_number',
        sorter: true,
        key: 'document_number',
        width: '7%',
        render(text) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.p.plateNo}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'plate_no',
        sorter: true,
        key: 'plate_no',
        width: '9%',
        render(text) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.c.connection}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'gps_active_at',
        key: 'gps_active_at',
        render(text) {
          let gps_active_at = text
          if (text != '-' && text != '') {
            gps_active_at =
              moment
                .utc()
                .local()
                .diff(
                  moment(text, 'YYYY-MM-DD HH:mm:ss')
                    .utc()
                    .local(),
                  'minutes',
                ) <= 30
                ? 'now'
                : moment(text).format('YYYY-MM-DD HH:mm:ss')
          }
          return {
            props: {
              style: columnStyle,
            },
            children:
              gps_active_at == 'now' ? (
                <div style={{ fontSize: '9px' }}>
                  <LangContext.Consumer>
                    {i18n => i18n.n.now}
                  </LangContext.Consumer>
                </div>
              ) : (
                <div style={{ fontSize: '9px', color: 'red' }}>
                  {helper_convertDateformat(
                    gps_active_at,
                    'YYYY-MM-DD HH:mm',
                    'DD/MM/YY HH:mm',
                  )}
                </div>
              ),
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.p.primary_location}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'primary_location',
        sorter: true,
        key: 'primary_location',
        width: '7%',
        render(text) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.s.submit_name}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'submit_name',
        sorter: true,
        key: 'submit_name',
        width: '8%',
        render(text) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.c.contact_name}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'contact_name',
        sorter: true,
        key: 'contact_name',
        width: '8%',
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.d.detail_problem}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'type_problem',
        sorter: false,
        width: '8%',
        render(text) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.c.comment}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'comment',
        sorter: false,
        width: '9%',
        render(text) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.inform_date}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'inform_date',
        sorter: true,
        key: 'inform_date',
        render(text) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {helper_convertDateformat(
                  text,
                  'YYYY-MM-DD HH:mm',
                  'DD/MM/YYYY HH:mm',
                )}
              </div>
            ),
          }
        },
        width: '8%',
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.r.receive_date}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'receive_date',
        sorter: true,
        key: 'receive_date',
        render(text) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {helper_convertDateformat(
                  text,
                  'YYYY-MM-DD HH:mm',
                  'DD/MM/YYYY HH:mm',
                )}
              </div>
            ),
          }
        },
        width: '7%',
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.c.complete_date}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'finish_date',
        key: 'finish_date',
        sorter: true,
        render(text) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {helper_convertDateformat(
                  text,
                  'YYYY-MM-DD HH:mm',
                  'DD/MM/YYYY HH:mm',
                )}
              </div>
            ),
          }
        },
        width: '7%',
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.r.remarkVendor}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'vendor_remark',
        key: 'vendor_remark',
        width: '6%',
        render: (_, record) => {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                <Button
                  type="link"
                  icon="edit"
                  onClick={this.handleDisplayRemarkModal(
                    true,
                    record.id,
                    record.vendor_remark,
                  )}
                />
                {record.vendor_remark}
              </div>
            ),
          }
        },
      },
      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.m.maintenance_status}
            </LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'maintenance_status',
        sorter: false,
        width: '5%',
        render(text, record) {
          let color_message = ''
          if (record.maintenances_status_id == 1) {
            color_message = 'red'
          } else if (record.maintenances_status_id == 2) {
            color_message = 'orange'
          } else if (record.maintenances_status_id == 3) {
            color_message = 'green'
          }
          return {
            props: {
              style: columnStyle,
            },
            children: <div style={{ color: color_message }}>{text}</div>,
          }
        },
      },

      {
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>{i18n => i18n.a.action}</LangContext.Consumer>
          </div>
        ),
        className: 'column-text-center',
        dataIndex: 'action',
        key: 'action',
        sorter: false,
        width: '20%',
        render: (_, record) => {
          var btAction = ''
          if (record.maintenances_status_id == 1) {
            btAction = (
              <LangContext.Consumer>
                {i18n => (
                  <Tooltip
                    placement="left"
                    title={i18n.r.receive}
                    arrowPointAtCenter
                  >
                    <Popconfirm
                      title={i18n.c.confirmToReceiveStatus}
                      onConfirm={() => {
                        let self = this
                        if (record.gps_vendor_id == 1) {
                          self.checkVehicleInTerminus(record, record.code, i18n)
                        } else {
                          self.changeStatusMaintenancedevice(
                            record.id,
                            2,
                            record.type_problem_id,
                            record.gps_vendor_id,
                            false,
                          )
                        }
                      }}
                      cancelText={i18n.c.cancel}
                      okText={i18n.o.ok}
                    >
                      <a
                        href="javascript:;"
                        style={{ padding: '0px 15px 0px 5px' }}
                      >
                        {i18n.r.receive}
                      </a>
                    </Popconfirm>
                  </Tooltip>
                )}
              </LangContext.Consumer>
            )
          }
          if (record.maintenances_status_id == 2) {
            btAction = (
              <LangContext.Consumer>
                {i18n => (
                  <Tooltip
                    placement="left"
                    title={i18n.c.complete}
                    arrowPointAtCenter
                  >
                    <Popconfirm
                      title={i18n.c.confirmToCloseStatus}
                      onConfirm={() => {
                        let self = this
                        self.changeStatusMaintenancedevice(
                          record.id,
                          3,
                          record.type_problem_id,
                          record.gps_vendor_id,
                          false,
                        )
                      }}
                      cancelText={i18n.c.cancel}
                      okText={i18n.o.ok}
                    >
                      <a
                        href="javascript:;"
                        style={{ padding: '0px 15px 0px 5px' }}
                      >
                        {i18n.c.complete}
                      </a>
                    </Popconfirm>
                  </Tooltip>
                )}
              </LangContext.Consumer>
            )
          }
          return (
            <div>
              {checkPermissions(
                this.props.auth.profile.permissions,
                'maintenancedevice',
                'can_delete',
              ) === true && this.props.auth.profile.is_gps_vendor !== 1 ? (
                <LangContext.Consumer>
                  {i18n => (
                    <Tooltip
                      placement="left"
                      title={i18n.d.delete}
                      arrowPointAtCenter
                    >
                      <Popconfirm
                        title={i18n.c.confirmToDeleteThisItem}
                        onConfirm={() => {
                          let self = this
                          self.deleteMaintenancedevice(record.id)
                        }}
                        cancelText={i18n.c.cancel}
                        okText={i18n.o.ok}
                      >
                        <a
                          href="javascript:;"
                          style={{ padding: '0px 15px 0px 5px' }}
                        >
                          <Icon type="close" />
                        </a>
                      </Popconfirm>
                    </Tooltip>
                  )}
                </LangContext.Consumer>
              ) : (
                ''
              )}

              {checkPermissions(
                this.props.auth.profile.permissions,
                'maintenancedevice',
                'can_edit',
              ) === true && this.props.auth.profile.is_gps_vendor !== 1 ? (
                <Tooltip placement="left" title="แก้ไข" arrowPointAtCenter>
                  <Link to={`/maintenancedevice/edit/${record.id}`}>
                    <Icon type="edit" />
                  </Link>
                </Tooltip>
              ) : (
                ''
              )}

              {this.props.auth.profile.is_gps_vendor === 1 ? btAction : ''}
            </div>
          )
        },
      },
    ]

    const expandedRowRender = record => {
      if (
        this.state.loading[record.id] == undefined ||
        this.state.loading[record.id]
      ) {
        return (
          <Spin
            style={{ width: '100%', height: '50px', alignContent: 'center' }}
          />
        )
      } else {
        const columns = [
          {
            title: (
              <div className="regular-bold-14">
                <LangContext.Consumer>
                  {i18n => i18n.t.ticketNumber}
                </LangContext.Consumer>
              </div>
            ),
            className: 'column-text-center',
            dataIndex: 'dp_code',
            key: 'dp_code',
            align: 'center',
            render(text) {
              return {
                children: <div>{text}</div>,
              }
            },
          },
          {
            title: (
              <div className="regular-bold-14">
                <LangContext.Consumer>
                  {i18n => i18n.d.dpReleaseDate}
                </LangContext.Consumer>
              </div>
            ),
            dataIndex: 'print_dp_at',
            key: 'print_dp_at',
            align: 'center',
            render(text, record, index) {
              return {
                children: <div>{text}</div>,
              }
            },
          },
        ]

        const data = this.state.expandedData[record.id]

        return (
          <div style={{ padding: '20px 10px' }}>
            {record.maintenances_status_id != 3 && (
              <p>
                <Tag color="green">
                  <LangContext.Consumer>
                    {i18n => i18n.a.allDP}
                  </LangContext.Consumer>{' '}
                  : {!!data.dp_all_count ? data.dp_all_count : 0}
                </Tag>{' '}
                <Tag color="red">
                  <LangContext.Consumer>
                    {i18n => i18n.b.brokenDP}
                  </LangContext.Consumer>{' '}
                  : {!!data.dp_broken_count ? data.dp_broken_count : 0}
                </Tag>
              </p>
            )}
            <Table
              size="small"
              columns={columns}
              dataSource={data.dplist}
              pagination={false}
              rowKey={record => record.dp_id}
            />
          </div>
        )
      }
    }

    return (
      <div>
        <AuthorizeComponent {...this.props} matching_name="maintenancedevice">
          <RemarkModal
            visible={this.state.visibleModalRemark}
            handleCancel={this.handleDisplayRemarkModal(false, '', '')}
            Id={this.state.EditRemarkID}
            value={this.state.EditRemarkValue}
            reLoad={this.loadPageData}
            accessToken={this.props.auth.accessToken}
          />
          <GeneralStyledContent>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="header__text">
                  <LangContext.Consumer>
                    {i18n => i18n.m.maintenancedevice}
                  </LangContext.Consumer>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ height: 90 }}>
                <Filter
                  OnClose={this.onCloseSearch}
                  onSubmit={this.filterMaintenancedevice}
                  maintenancestatusLists={
                    this.props.maintenancedevice.maintenancestatusLists
                  }
                  typeproblemLists={
                    this.props.maintenancedevice.typeproblemLists
                  }
                  auth={this.props.auth.profile.permissions}
                  is_gps_vendor={this.props.auth.profile.is_gps_vendor}
                />
              </Col>
              <Col span={24} align="right">
                {checkPermissions(
                  this.props.auth.profile.permissions,
                  'maintenancedevice',
                  'can_add',
                ) === true && this.props.auth.profile.is_gps_vendor !== 1 ? (
                  <Link to="/maintenancedevice/create/">
                    <Button type="primary" icon="plus" size="small">
                      <span></span>
                      <LangContext.Consumer>
                        {i18n => i18n.n.new}
                      </LangContext.Consumer>
                    </Button>
                  </Link>
                ) : (
                  <LangContext.Consumer>
                    {i18n => (
                      <Button
                        size="small"
                        onClick={() => this.showConfirm(i18n)}
                      >
                        <LangContext.Consumer>
                          {i18n => i18n.r.receive_complete}
                        </LangContext.Consumer>
                      </Button>
                    )}
                  </LangContext.Consumer>
                )}
              </Col>

              <Col span={24}>
                <Table
                  bordered
                  size="small"
                  rowSelection={
                    this.props.auth.profile.is_gps_vendor === 1
                      ? rowSelection
                      : ''
                  }
                  columns={columns}
                  expandedRowRender={expandedRowRender}
                  onExpand={this.handleExpand}
                  expandedRowKeys={this.state.expandedRowKeys}
                  rowKey={record => record.id}
                  dataSource={
                    this.props.maintenancedevice.maintenancedeviceLists
                  }
                  pagination={{
                    pageSize: this.state.pageSize,
                    total: this.props.maintenancedevice.maintenancedeviceTotal,
                    current: this.state.page,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200'],
                  }}
                  loading={
                    this.props.maintenancedevice.maintenancedeviceLoading
                  }
                  onChange={this.handleTableChange}
                  expandIcon={({ expanded, record, onExpand }) => (
                    <Button
                      type="link"
                      disabled={!record.has_child}
                      onClick={e => onExpand(record, e)}
                    >
                      <Icon type={expanded ? 'up' : 'down'} />
                    </Button>
                  )}
                />
              </Col>
            </Row>
          </GeneralStyledContent>
        </AuthorizeComponent>
      </div>
    )
  }
}

const mapStateToProps = ({ maintenancedevice, auth }) => ({
  maintenancedevice,
  auth,
})

const mapDispatchToProps = {
  loadMaintenancedevice: actions.loadMaintenancedevice.request,
  loadTypeproblem: actions.loadTypeproblem.request,
  loadMaintenancestatus: actions.loadMaintenancestatus.request,
  clearDataResponse: actions.clearDataResponse,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
