import React from 'react'
import { List, Avatar, Badge } from 'antd'
import * as moment from 'moment'

const ListNotification = ({ item, profileId, handleUnVisibleChange }) => {
  let date = ''
  let date_now = moment().format('YYYY-MM-DD HH:mm:ss')
  let creatDate = moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')
  let ms = moment(date_now, 'YYYY-MM-DD HH:mm:ss').diff(
    moment(creatDate, 'YYYY-MM-DD HH:mm:ss'),
  )
  let d = moment.duration(ms)
  let last_day = parseInt(d.asDays())
  let last_hourt = parseInt(d.asHours())
  let last_min = parseInt(d.asMinutes())
  if (last_day != 0) {
    date = `${last_day} วันที่แล้ว`
  } else if (last_day == 0 && last_hourt > 0) {
    date = `${last_hourt} ชั่วโมงที่แล้ว`
  } else if (last_day == 0 && last_hourt == 0) {
    date = `${last_min} นาทีที่แล้ว`
  }

  const readData = async id => {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('user_id', profileId)

    await fetch(`${process.env.REACT_APP_GO_API_ENDPOINT}/notification/read`, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('count-noti', data.count)
      })
    window.location.href =
      item.type == 'OilNotCalibrated'
        ? '/fuel-notification/detail-without-calibrate'
        : '/fuel-notification/detail-abnormality'
  }
  return (
    <List.Item actions={[<label style={{ color: 'gray' }}>{date}</label>]}>
      <List.Item.Meta
        avatar={<Avatar size="large" icon="notification" />}
        title={
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleUnVisibleChange()
              readData(item.id)
              localStorage.setItem('oil-not-calibrated', JSON.stringify(item))
            }}
          >
            {item.read_at == '' ? (
              <Badge
                dot
                style={{
                  marginTop: '-4px',
                  padding: '4px',
                  marginRight: '-4px',
                }}
              >
                {item.data.main_subject}
              </Badge>
            ) : (
              item.data.main_subject
            )}
          </div>
        }
        description={item.data.sub_subject}
      />
    </List.Item>
  )
}

export default ListNotification
