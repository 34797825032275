import { createTypes, createAction } from 'lib/action'

const LOAD_EVENT = createTypes('event', 'load')
const LOAD_SAFETY_EVENT = createTypes('safetyevent', 'load')
const LOAD_SAFETY_EVENT_PREVIEW = createTypes('safetyeventpreview', 'load')
const LOAD_VEHICLE_EVENT = createTypes('vehicle_event', 'load')
const LOAD_VEHICLE_EVENT_BY_VENDOR = createTypes(
  'vehicle_event_by_vendor',
  'load',
)
const LOAD_VEHICLE_TYPE_EVENT = createTypes('vehicletype_event', 'load')
const LOAD_POLICY_EVENT = createTypes('policy_event', 'load')
const LOAD_EVENT_EVENT = createTypes('event_event', 'load')
const LOAD_VEHICLE_BY_POLICY_EVENT = createTypes(
  'vehicle_by_policy_event',
  'load',
)
const LOAD_VEHICLE_EVENT_BY_GROUP = createTypes(
  'vehicle_event_by_group',
  'load',
)

const LOAD_VEHICLE_VENDOR_EVENT = createTypes('vehiclevendorevent', 'load')
const LOAD_VEHICLE_BY_VENDOR = createTypes('vehiclevendor', 'load')

const loadEvent = {
  request: (data, loading) =>
    createAction(LOAD_EVENT.REQUEST, { data, loading }),
  success: data => createAction(LOAD_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_EVENT.FAILURE),
}

const loadSafetyEvent = {
  request: (data, loading) =>
    createAction(LOAD_SAFETY_EVENT.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SAFETY_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_SAFETY_EVENT.FAILURE),
}

const loadSafetyEventpreview = {
  request: (data, loading) =>
    createAction(LOAD_SAFETY_EVENT_PREVIEW.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SAFETY_EVENT_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_SAFETY_EVENT_PREVIEW.FAILURE),
}

const loadPolicy = {
  request: () => createAction(LOAD_POLICY_EVENT.REQUEST, {}),
  success: data => createAction(LOAD_POLICY_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_POLICY_EVENT.FAILURE),
}

const loadEvents = {
  request: () => createAction(LOAD_EVENT_EVENT.REQUEST, {}),
  success: data => createAction(LOAD_EVENT_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_EVENT_EVENT.FAILURE),
}

const loadVehiclebypolicy = {
  request: (policyID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_BY_POLICY_EVENT.REQUEST, {
      policyID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_BY_POLICY_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_BY_POLICY_EVENT.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_EVENT.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_EVENT.FAILURE),
}

const loadVehicleByVendorID = {
  request: (vehicletypeID, vendor, loading_vehicle) =>
    createAction(LOAD_VEHICLE_BY_VENDOR.REQUEST, {
      vehicletypeID,
      vendor,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_BY_VENDOR.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_BY_VENDOR.FAILURE),
}

const loadVehiclebygroupid = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_EVENT_BY_GROUP.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_EVENT_BY_GROUP.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_EVENT_BY_GROUP.FAILURE),
}

const loadVehicleVendor = {
  request: () => createAction(LOAD_VEHICLE_VENDOR_EVENT.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_VENDOR_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_VENDOR_EVENT.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_EVENT.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_TYPE_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_EVENT.FAILURE),
}

export {
  loadEvent,
  LOAD_EVENT,
  loadVehicle,
  LOAD_VEHICLE_EVENT,
  LOAD_VEHICLE_EVENT_BY_VENDOR,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_EVENT,
  loadPolicy,
  LOAD_POLICY_EVENT,
  loadEvents,
  LOAD_EVENT_EVENT,
  loadVehiclebypolicy,
  LOAD_VEHICLE_BY_POLICY_EVENT,
  loadVehiclebygroupid,
  LOAD_VEHICLE_EVENT_BY_GROUP,
  loadSafetyEvent,
  LOAD_SAFETY_EVENT,
  loadSafetyEventpreview,
  LOAD_SAFETY_EVENT_PREVIEW,
  loadVehicleVendor,
  LOAD_VEHICLE_VENDOR_EVENT,
  loadVehicleByVendorID,
  LOAD_VEHICLE_BY_VENDOR,
}
