import { createTypes, createAction } from 'lib/action'
const LOAD_VEHICLE_RMCCONCRETETRIP = createTypes('vehicle_rmcconcrete', 'load')
const LOAD_VEHICLE_TYPE_RMCCONCRETETRIP = createTypes(
  'vehicle_type_rmcconcrete',
  'load',
)
const LOAD_PLANT_RMCCONCRETETRIP = createTypes('plant_rmcconcrete', 'load')
const EXPORT_RMCCONCRETETRIP_REPORT = createTypes(
  'exportrmcconcretetripreport',
  'load',
)
const EXPORT_RMCCONCRETETRIP_REPORT_PREVIEW = createTypes(
  'exportrmcconcretetripreport_preview',
  'load',
)
const LOAD_DIVISION = createTypes('load_division', 'load')

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_RMCCONCRETETRIP.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_RMCCONCRETETRIP.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_RMCCONCRETETRIP.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_RMCCONCRETETRIP.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_RMCCONCRETETRIP.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_RMCCONCRETETRIP.FAILURE),
}

const loadPlant = {
  request: () => createAction(LOAD_PLANT_RMCCONCRETETRIP.REQUEST, {}),
  success: data => createAction(LOAD_PLANT_RMCCONCRETETRIP.SUCCESS, { data }),
  failure: () => createAction(LOAD_PLANT_RMCCONCRETETRIP.FAILURE),
}

const exportRmcconcretetripReport = {
  request: (data, loading) =>
    createAction(EXPORT_RMCCONCRETETRIP_REPORT.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(EXPORT_RMCCONCRETETRIP_REPORT.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_RMCCONCRETETRIP_REPORT.FAITLURE),
}

const exportRmcconcretetripReportpreview = {
  request: (data, loading) =>
    createAction(EXPORT_RMCCONCRETETRIP_REPORT_PREVIEW.REQUEST, {
      data,
      loading,
    }),
  success: (lists, page) =>
    createAction(EXPORT_RMCCONCRETETRIP_REPORT_PREVIEW.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_RMCCONCRETETRIP_REPORT_PREVIEW.FAITLURE),
}

const loadDivision = {
  request: (division_visibility, comp) =>
    createAction(LOAD_DIVISION.REQUEST, {
      division_visibility,
      comp,
    }),
  success: data => createAction(LOAD_DIVISION.SUCCESS, { data }),
  failure: () => createAction(LOAD_DIVISION.FAILURE),
}

export {
  LOAD_VEHICLE_TYPE_RMCCONCRETETRIP,
  loadVehicleType,
  LOAD_VEHICLE_RMCCONCRETETRIP,
  loadVehicle,
  LOAD_PLANT_RMCCONCRETETRIP,
  loadPlant,
  EXPORT_RMCCONCRETETRIP_REPORT,
  exportRmcconcretetripReport,
  EXPORT_RMCCONCRETETRIP_REPORT_PREVIEW,
  exportRmcconcretetripReportpreview,
  LOAD_DIVISION,
  loadDivision,
}
