import React from 'react'
import { Row, Col, Tooltip } from 'antd'
import LangContext from 'modules/shared/context/langContext'

const IconMarkerSmartDispatching = ({ image }) => (
  <Row>
    <Col span={24}>
      <div
        style={{ position: 'relative', textAlign: 'center', color: 'white' }}
      >
        <img
          src={image}
          style={{
            zIndex: -2,
            marginRight: 10,
            width: '40px',
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '45%',
            left: '40%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <span className="my-location-pin-point"></span>
        </div>
      </div>
    </Col>
  </Row>
)

export default IconMarkerSmartDispatching
