import { createTypes, createAction } from 'lib/action'
const LOAD_ISUPPLY_BOOKING_DASHBOARD_LIST_MONTH = createTypes(
  'loadisupplybookingdashboardlistmonth',
  'load',
)
const LOAD_ISUPPLY_BOOKING_ORDER_BY_ID = createTypes(
  'loadisupplybookingbyid',
  'load',
)
const GET_ISUPPLY_VEHICLE_BY_DATE = createTypes(
  'getisupplyvehiclebydate',
  'load',
)
const UPDATE_ISUPPLY_BOOKING_STATUS = createTypes(
  'updateisupplybookingstatus',
  'create',
)
const UPDATE_ISUPPLY_CONFIRM_STATUS = createTypes(
  'updateisupplyconfirmstatus',
  'create',
)
const UPDATE_ISUPPLY_CANCEL_STATUS = createTypes(
  'updateisupplycancelstatus',
  'create',
)
const UPDATE_VEHICLE_STATUS_ON_OFF = createTypes(
  'updatevehiclestatusonoff',
  'create',
)
const UPDATE_VEHICLE_STATUS_BY_DATE = createTypes(
  'updatevehiclestatusbydate',
  'create',
)

const LOAD_ALL_DATA_WEEK = createTypes('loadalldataweek', 'load')
const LOAD_ALL_DATA_DAY = createTypes('loadalldataday', 'load')

const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}
// *load ข้อมูลสำหรับแสดงผล calendar month
const loadBookingDashboardListMonth = {
  request: (data, loading) =>
    createAction(LOAD_ISUPPLY_BOOKING_DASHBOARD_LIST_MONTH.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(LOAD_ISUPPLY_BOOKING_DASHBOARD_LIST_MONTH.SUCCESS, { lists }),
  failure: () =>
    createAction(LOAD_ISUPPLY_BOOKING_DASHBOARD_LIST_MONTH.FAITLURE),
}
// *ดึงข้อมูลใบงานจาก ID
const loadBookingByID = {
  request: data =>
    createAction(LOAD_ISUPPLY_BOOKING_ORDER_BY_ID.REQUEST, {
      data,
    }),
  success: lists =>
    createAction(LOAD_ISUPPLY_BOOKING_ORDER_BY_ID.SUCCESS, { lists }),
  failure: () => createAction(LOAD_ISUPPLY_BOOKING_ORDER_BY_ID.FAITLURE),
}
// *ดีงข้อมูลรถว่างทั้งหมดในวันที่ต้องการ
const getVehicleByDate = {
  request: data =>
    createAction(GET_ISUPPLY_VEHICLE_BY_DATE.REQUEST, {
      data,
    }),
  success: lists =>
    createAction(GET_ISUPPLY_VEHICLE_BY_DATE.SUCCESS, { lists }),
  failure: () => createAction(GET_ISUPPLY_VEHICLE_BY_DATE.FAITLURE),
}
// *Update สถานะใบงานจองคิวเป็นจองรถ
const updateBookingStatus = {
  request: (data, loading) =>
    createAction(UPDATE_ISUPPLY_BOOKING_STATUS.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(UPDATE_ISUPPLY_BOOKING_STATUS.SUCCESS, { lists }),
  failure: () => createAction(UPDATE_ISUPPLY_BOOKING_STATUS.FAITLURE),
}
// *Update สถานะใบงานจองรถเป็นยืนยันการจอง
const confirmBookingStatus = {
  request: (data, loading) =>
    createAction(UPDATE_ISUPPLY_CONFIRM_STATUS.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(UPDATE_ISUPPLY_CONFIRM_STATUS.SUCCESS, { lists }),
  failure: () => createAction(UPDATE_ISUPPLY_CONFIRM_STATUS.FAITLURE),
}
// *Update สถานะใบงานยกเลิกใบงาน
const cancelBookingStatus = {
  request: (data, loading) =>
    createAction(UPDATE_ISUPPLY_CANCEL_STATUS.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(UPDATE_ISUPPLY_CANCEL_STATUS.SUCCESS, { lists }),
  failure: () => createAction(UPDATE_ISUPPLY_CANCEL_STATUS.FAITLURE),
}
// *load ข้อมูลสำหรับแสดงผล calendar week
const loadAllDataWeek = {
  request: (data, loading) =>
    createAction(LOAD_ALL_DATA_WEEK.REQUEST, {
      data,
      loading,
    }),
  success: lists => createAction(LOAD_ALL_DATA_WEEK.SUCCESS, { lists }),
  failure: () => createAction(LOAD_ALL_DATA_WEEK.FAITLURE),
}
// *load ข้อมูลสำหรับแสดงผล calendar day
const loadAllDataDay = {
  request: (data, loading) =>
    createAction(LOAD_ALL_DATA_DAY.REQUEST, {
      data,
      loading,
    }),
  success: lists => createAction(LOAD_ALL_DATA_DAY.SUCCESS, { lists }),
  failure: () => createAction(LOAD_ALL_DATA_DAY.FAITLURE),
}
// * update สถานะรถว่าออนไลน์หรือออฟไลน์
const updateVehicleStatusOnOff = {
  request: (data, loading) =>
    createAction(UPDATE_VEHICLE_STATUS_ON_OFF.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(UPDATE_VEHICLE_STATUS_ON_OFF.SUCCESS, { lists }),
  failure: () => createAction(UPDATE_VEHICLE_STATUS_ON_OFF.FAITLURE),
}
// * update สถานะรถว่าพร้อมใช้งานหรือไม่พร้อมใช้งาน
const updateVehicleStatusbyDate = {
  request: (data, loading) =>
    createAction(UPDATE_VEHICLE_STATUS_BY_DATE.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(UPDATE_VEHICLE_STATUS_BY_DATE.SUCCESS, { lists }),
  failure: () => createAction(UPDATE_VEHICLE_STATUS_BY_DATE.FAITLURE),
}
export {
  LOAD_ISUPPLY_BOOKING_DASHBOARD_LIST_MONTH,
  loadBookingDashboardListMonth,
  LOAD_ISUPPLY_BOOKING_ORDER_BY_ID,
  loadBookingByID,
  GET_ISUPPLY_VEHICLE_BY_DATE,
  getVehicleByDate,
  UPDATE_ISUPPLY_BOOKING_STATUS,
  updateBookingStatus,
  UPDATE_ISUPPLY_CONFIRM_STATUS,
  confirmBookingStatus,
  UPDATE_ISUPPLY_CANCEL_STATUS,
  cancelBookingStatus,
  LOAD_ALL_DATA_WEEK,
  loadAllDataWeek,
  LOAD_ALL_DATA_DAY,
  loadAllDataDay,
  UPDATE_VEHICLE_STATUS_ON_OFF,
  updateVehicleStatusOnOff,
  UPDATE_VEHICLE_STATUS_BY_DATE,
  updateVehicleStatusbyDate,
  REMOVE_LISTS,
  removeLists,
}
