import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import { getlocalstorage } from './../../../constants/local_storage'

function* loadServiceInstallDevice(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      vehicle_visibility,
      m_user_id,
      company_id,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/serviceinstalldevice/get`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        vehicle_visibility,
        m_user_id,
        company_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadServiceInstallDevice.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadServiceInstallDevice.failure())
  }
}

function* loadProvince(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getprovince`,
      {},
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadProvince.success(res.data))
  } catch (err) {
    yield put(actions.loadProvince.failure())
  }
}

function* loadAmphur(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const province_id = action.payload.data
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getamphur`,
      {
        province_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadAmphur.success(res.data))
  } catch (err) {
    yield put(actions.loadAmphur.failure())
  }
}

function* create(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const {
    data: {
      company_id,
      body_no,
      plate_no,
      contact_name,
      contact_telno,
      submit_name,
      submit_telno,
      appointment_place,
      location_id,
      province,
      amphur,
      confirm_appointment_place,
      comment,
      m_user_id,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/serviceinstalldevice/create`,
      {
        company_id,
        body_no,
        plate_no,
        contact_name,
        contact_telno,
        submit_name,
        submit_telno,
        appointment_place,
        location_id,
        province,
        amphur,
        confirm_appointment_place,
        comment,
        m_user_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    loading(res.data)
    yield put(actions.create.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.create.failure())
  }
}

export default function* watchServiceInstallDevice() {
  yield all([
    takeEvery(
      actions.LOAD_SERVICEINSTALLDEVICE.REQUEST,
      loadServiceInstallDevice,
    ),
    takeEvery(actions.LOAD_PROVINCE.REQUEST, loadProvince),
    takeEvery(actions.LOAD_AMPHUR.REQUEST, loadAmphur),
    takeEvery(actions.CREATE.REQUEST, create),
  ])
}
export { loadServiceInstallDevice, create }
