import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions'
import Forms from './Forms'
import { Row, Col } from 'antd'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'

class Index extends Component {
  loadBehaviordriving = (values, loading) => {
    values = {
      ...values,
      type_file: 'excel',
    }
    this.props.loadBehaviordriving(values, loading)
  }

  constructor(props) {
    super(props)
  }
  state = {
    loading: false,
    vehicle_group: [],
    partner: [],
    vehicleGroup: [],
    vehicles: [],
    vehicleList: [],
    divisionLoading: false,
    division: [],
    departmentLoading: false,
    department: [],
    sectionLoading: false,
    section: [],
    plant: [],
    selectedPartner: 'all',
    selectedDivision: 'all',
    selectedDepartment: 'all',
    selectedSection: 'all',
    selectedVehicleGroup: 'all',
    selectedPlant: ['all'],
  }
  componentDidMount() {
    this.loadVehicleGroup()
    this.loadDivision(() =>
      this.loadDepartment(() =>
        this.loadSection(() => this.loadPlant(() => this.loadVehicles())),
      ),
    )
  }

  loadVehicleGroup = cb => {
    this.setState({ loading: true })
    let self = this

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            vehicleGroup: data.data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadDivision = cb => {
    this.setState({ loading: true })
    let self = this
    let divisionVisibility = []

    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      divisionVisibility = orgChart.divisions.map(ele => ele.divisionNo)
    }
    this.setState({ divisionLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdivision`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        division_visibility: divisionVisibility,
        comp: [2, 3, 4, 5, 6],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            divisionLoading: false,
            division: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadDepartment = cb => {
    this.setState({ loading: true })
    let self = this
    let departmentVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      departmentVisibility = orgChart.departments.map(ele => ele)
    }
    this.setState({ departmentLoading: true })

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdepartment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        department_visibility: departmentVisibility,
        division: [this.state.selectedDivision],
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            departmentLoading: false,
            department: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadSection = cb => {
    this.setState({ loading: true })
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    this.setState({ sectionLoading: true })

    let departmentID = [this.state.selectedDepartment.toString()]

    if (this.state.selectedDepartment === 'all') {
      departmentID = this.state.department.map(x => x.id.toString())
    }

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getsection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        section_visibility: sectionVisibility,
        department: departmentID,
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            sectionLoading: false,
            section: data,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadPlant = cb => {
    this.setState({ loading: true })
    let self = this
    let plantVisibility = []
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }
    plantVisibility = self.props.auth.profile.location_visibility
    this.setState({ plantLoading: true })

    let sectionID = [this.state.selectedSection.toString()]

    if (this.state.selectedSection === 'all') {
      sectionID = this.state.section.map(x => x.id.toString())
    }

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantforconcretedashboard`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          location_visibility: plantVisibility,
          section_visibility: sectionVisibility,
          section: sectionID,
          relate_company: `${this.props.auth.profile.relate_company}`,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        self.setState(
          {
            plant: data,
            plantLoading: false,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  loadVehicles = cb => {
    this.setState({ loading: true })
    let self = this
    let sectionVisibility = []
    if (self.props.auth.profile.user_type == 2) {
      let orgChart = JSON.parse(self.props.auth.profile.org_chart)
      sectionVisibility = orgChart.sections.map(ele => ele)
    }

    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclesbydivisionbranch`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          vehicle_group: this.state.selectedVehicleGroup,
          section_visibility: sectionVisibility,
          partner: this.state.selectedPartner,
          division: this.state.selectedDivision,
          department: this.state.selectedDepartment,
          section: this.state.selectedSection,
          plant: this.state.selectedPlant,
          vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        }),
      },
    )
      .then(response => response.json())
      .then(data => {
        const vehlist = []
        data.data.map(item =>
          vehlist.push({
            key: item.id,
            name: item.plate_no,
          }),
        )
        self.setState(
          {
            loading: false,
            vehicles: data.data,
            vehicleList: vehlist,
          },
          () => typeof cb != 'undefined' && cb(),
        )
      })
  }

  updateSelectDivision = e =>
    this.setState(
      {
        selectedDivision: e,
        selectedDepartment: 'all',
        selectedSection: 'all',
        selectedPlant: ['all'],
      },
      e => {
        this.loadDepartment(() =>
          this.loadSection(() => this.loadPlant(() => this.loadVehicles())),
        )
      },
    )

  updateSelectDepartment = e =>
    this.setState(
      {
        selectedDepartment: e,
        selectedSection: 'all',
        selectedPlant: ['all'],
      },
      e => {
        this.loadSection(() => this.loadPlant(() => this.loadVehicles()))
      },
    )

  updateSelectSection = e =>
    this.setState({ selectedSection: e, selectedPlant: ['all'] }, e => {
      this.loadPlant(() => this.loadVehicles())
    })

  updateSelectPlant = e =>
    this.setState({ selectedPlant: e }, e => {
      this.loadVehicles()
    })

  updateSelectVehicleGroup = e =>
    this.setState({ selectedVehicleGroup: e }, e => {
      this.loadVehicles()
    })

  render() {
    return (
      <AuthorizeComponent {...this.props} matching_name="BehaviordrivingReport">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}></Col>
          </Row>
          <Forms
            onSubmit={this.loadBehaviordriving}
            behaviordrivingLoading={
              this.state.loading ||
              this.props.behaviordriving.behaviordrivingLoading
            }
            vehicleGroup={this.state.vehicleGroup}
            selectedVehicleGroup={this.state.selectedVehicleGroup}
            updateSelectVehicleGroup={this.updateSelectVehicleGroup}
            vehicleList={this.state.vehicleList}
            division={this.state.division}
            selectedDivision={this.state.selectedDivision}
            updateSelectDivision={this.updateSelectDivision}
            department={this.state.department}
            selectedDepartment={this.state.selectedDepartment}
            updateSelectDepartment={this.updateSelectDepartment}
            section={this.state.section}
            selectedSection={this.state.selectedSection}
            updateSelectSection={this.updateSelectSection}
            plant={this.state.plant}
            selectedPlant={this.state.selectedPlant}
            updateSelectPlant={this.updateSelectPlant}
            auth={this.props.auth}
          />
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ behaviordriving, auth: { profile }, auth }) => ({
  behaviordriving,
  profile,
  auth,
})

const mapDispatchToProps = {
  loadBehaviordriving: actions.loadBehaviordriving.request,
  loadVehicle: actions.loadVehicle.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
