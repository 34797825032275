import { createTypes, createAction } from 'lib/action'

const LOAD_VEHICLESHARING_FROM = createTypes(
  'vehiclesharingfrommasterdata',
  'load',
)
const LOAD_VEHICLESHARING_TO = createTypes('vehiclesharingtomasterdata', 'load')
const LOAD_VEHICLESHARING_TO_DETAIL = createTypes(
  'vehiclesharingdetailtomasterdata',
  'load',
)
const CREATE_VEHICLESHARING = createTypes('vehiclesharingmasterdata', 'create')
const EXPORT_VEHICLESHARING_TO = createTypes(
  'exportvehiclesharingtomasterdata',
  'load',
)
const EXPORT_VEHICLESHARING_FROM = createTypes(
  'exportvehiclesharingfrommasterdata',
  'load',
)

const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadVehicleSharingTo = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLESHARING_TO.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_VEHICLESHARING_TO.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_VEHICLESHARING_TO.FAILURE),
}

const loadVehicleSharingFrom = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLESHARING_FROM.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_VEHICLESHARING_FROM.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_VEHICLESHARING_FROM.FAILURE),
}

const loadVehicleSharingToDetail = {
  request: data =>
    createAction(LOAD_VEHICLESHARING_TO_DETAIL.REQUEST, { data }),
  success: vehiclesharing =>
    createAction(LOAD_VEHICLESHARING_TO_DETAIL.SUCCESS, { vehiclesharing }),
  failure: () => createAction(LOAD_VEHICLESHARING_TO_DETAIL.FAILURE),
}

const createVehicleSharing = {
  request: (data, cb) =>
    createAction(CREATE_VEHICLESHARING.REQUEST, {
      data,
      cb,
    }),
  success: res => createAction(CREATE_VEHICLESHARING.SUCCESS, { res }),
  failure: () => createAction(CREATE_VEHICLESHARING.FAILURE),
}

const exportVehicleSharingTo = {
  request: (data, loading) =>
    createAction(EXPORT_VEHICLESHARING_TO.REQUEST, { data, loading }),
  success: res => createAction(EXPORT_VEHICLESHARING_TO.SUCCESS, { res }),
  failure: () => createAction(EXPORT_VEHICLESHARING_TO.FAILURE),
}

const exportVehicleSharingFrom = {
  request: (data, loading) =>
    createAction(EXPORT_VEHICLESHARING_FROM.REQUEST, { data, loading }),
  success: res => createAction(EXPORT_VEHICLESHARING_FROM.SUCCESS, { res }),
  failure: () => createAction(EXPORT_VEHICLESHARING_FROM.FAILURE),
}

export {
  CREATE_VEHICLESHARING,
  createVehicleSharing,
  loadVehicleSharingTo,
  LOAD_VEHICLESHARING_TO,
  REMOVE_LISTS,
  removeLists,
  LOAD_VEHICLESHARING_TO_DETAIL,
  loadVehicleSharingToDetail,
  loadVehicleSharingFrom,
  LOAD_VEHICLESHARING_FROM,
  exportVehicleSharingTo,
  EXPORT_VEHICLESHARING_TO,
  exportVehicleSharingFrom,
  EXPORT_VEHICLESHARING_FROM,
}
