import React, { useEffect, useState } from 'react'
import { FuelNotification } from '../../../../../src/styled/common-styled'
import { Row, Col, Icon, Spin, Button, Card, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { data } from './FakeData'
import LangContext, { i18n } from 'modules/shared/context/langContext'

const FuelDetailAbnormality = props => {
  const [vehicleList, setVehicleList] = useState([])
  const [vehicleNotification, setVehicleNotification] = useState([])
  const [loading, setLoading] = useState(false)
  const [fuelCostDate, setFuelCostDate] = useState('')
  const [updatedAt, setUpdatedAt] = useState('')

  const fetchVehicleAbnormality = async () => {
    setLoading(true)
    setVehicleList(data.vehicleList)
    setVehicleNotification(data.vehicleNotification)
    setUpdatedAt(data.updateAt)
    setFuelCostDate(data.fuelCostDate)
    // await fetch(
    //   `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/fuelnotificationvehiclelist`,
    //   {
    //     method: 'GET',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${props.auth.accessToken}`,
    //     },
    //   },
    // )
    //   .then(response => response.json())
    //   .then(res => {
    //     setVehicleList(res)
    //     setLoading(false)
    //   })
    setLoading(false)
  }

  useEffect(() => {
    fetchVehicleAbnormality()
  }, [])

  return (
    <Spin spinning={loading}>
      <FuelNotification>
        <Row gutter={[8, 8]}>
          <Col span={1} align="center">
            <span className="left__button__web__content">
              <Link to="/fuel-notification">
                <Button shape="circle" size="large">
                  <Icon type="left" style={{ color: 'black', paddingTop: 6 }} />
                </Button>
              </Link>
            </span>
          </Col>
          <Col span={18}>
            <span style={{ fontSize: '1.2rem', color: '#172b4d' }}>
              <LangContext.Consumer>
                {i18n => i18n.o.oilThereIsMalfunction}
              </LangContext.Consumer>
            </span>
            <p>
              <LangContext.Consumer>
                {i18n => i18n.l.lastUpdatedOn}
              </LangContext.Consumer>{' '}
              : {updatedAt}
            </p>
            <p>
              <LangContext.Consumer>
                {i18n => i18n.n.notifyVehicleRegistration}
              </LangContext.Consumer>{' '}
              :{' '}
              {vehicleNotification.map((item, index) => {
                return `${item.vehicle_plate_no} (${item.vehicle_code})${
                  vehicleNotification.length != index + 1 ? ', ' : ''
                }`
              })}
            </p>
            <p>
              <LangContext.Consumer>
                {i18n => i18n.d.dateFuelCost}
              </LangContext.Consumer>{' '}
              : {fuelCostDate}{' '}
              <LangContext.Consumer>
                {i18n => i18n.a.abnormal}
              </LangContext.Consumer>
            </p>
            <p>
              <LangContext.Consumer>
                {i18n => i18n.y.YouCanCheckTheAbnormalityOfTheOilOn}
              </LangContext.Consumer>{' '}
              <label style={{ fontWeight: 'bold', color: '#4489ec' }}>
                Replay
              </label>
            </p>
            <div
              style={{ height: window.innerHeight - 200, overflow: 'scroll' }}
            >
              <Row gutter={[16, 16]}>
                {vehicleList.map((item, index) => {
                  return (
                    <Tooltip
                      title={`${item.vehicle_plate_no} (${item.vehicle_code})`}
                    >
                      <Col span={4}>
                        <div
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: 180,
                            whiteSpace: 'nowrap',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            padding: '15px 20px',
                          }}
                          className="list-vehicle-card-fuel-notification"
                          onClick={() =>
                            window.open(
                              `/replay/vehicle/${item.dataforreplay}/${item.vehicle_plate_no} (${item.vehicle_code})/${fuelCostDate}`,
                            )
                          }
                        >
                          <img src="/img/Smalltruck-final-fuel.png" />{' '}
                          <label style={{ cursor: 'pointer' }}>
                            {item.vehicle_plate_no} ({item.vehicle_code})
                          </label>
                        </div>
                        {/* <Card
                        style={{
                          width: 160,
                          textAlign: 'center',
                          borderRadius: '10px',
                          cursor: 'pointer',
                        }}
                        className="list-vehicle-card-fuel-notification"
                        onClick={() =>
                          window.open(
                            `/replay/vehicle/${item.dataforreplay}/${item.vehicle_plate_no} (${item.vehicle_code})/${fuelCostDate}`,
                          )
                        }
                      >
                        <img src="/img/Smalltruck-final-fuel.png" />{' '}
                        <label style={{ cursor: 'pointer' }}>
                          {item.vehicle_plate_no} ({item.vehicle_code})
                        </label>
                      </Card> */}
                      </Col>
                    </Tooltip>
                  )
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </FuelNotification>
    </Spin>
  )
}

export default FuelDetailAbnormality
