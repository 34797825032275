import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadLineNotify(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      filterObj,
      token,
      company_id,
      selectedDivision,
      selectedDepartment,
      selectedSection,
    },
  } = action.payload
  try {
    let newFilterObj = {
      name: filterObj.name,
      line_token: filterObj.line_token,
      division: selectedDivision,
      department: selectedDepartment,
      section: selectedSection,
    }
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/linenotify`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj: newFilterObj,
        company_id,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    yield put(actions.loadLineNotify.success(res.data))
  } catch (err) {
    yield put(actions.loadLineNotify.failure())
  }
}

function* createLineNotify(action) {
  const {
    data: { line_token, notify_type, token, company_id },
    history,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/linenotify/create`,
      {
        line_token,
        notify_type,
        company_id,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    yield put(actions.createLineNotify.success(res.data))
    history.push('/linenotify')
  } catch (err) {
    yield put(actions.createLineNotify.failure())
  }
}

function* updateLineNotify(action) {
  const {
    data: { line_token, notify_type, token, company_id },
    id,
    history,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/linenotify/update`,
      {
        line_token,
        notify_type,
        company_id,
        id,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    yield put(actions.updateLineNotify.success(res.data))
    history.push('/linenotify')
  } catch (err) {
    yield put(actions.updateLineNotify.failure())
  }
}

function* getLineNotifyByID(action) {
  const { id, token } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/linenotify/${id}`,
      { headers: { Authorization: `Bearer ${token}` } },
    )

    yield put(actions.getLineNotifyByID.success(res.data))
  } catch (err) {
    yield put(actions.getLineNotifyByID.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_LINENOTIFY.REQUEST, loadLineNotify),
    takeEvery(actions.CREATE_LINENOTIFY.REQUEST, createLineNotify),
    takeEvery(actions.UPDATE_LINENOTIFY.REQUEST, updateLineNotify),
    takeEvery(actions.GET_LINENOTIFY.REQUEST, getLineNotifyByID),
  ])
}

export { loadLineNotify, createLineNotify, updateLineNotify, getLineNotifyByID }
