import {
    CREATE_SALES_CLOSE,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    data_request_material: [],
    loading: false,
    check: true,
    check_create: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case CREATE_SALES_CLOSE.REQUEST:
                return {
                    ...state,
                    check_create: true
                };
    
        case CREATE_SALES_CLOSE.SUCCESS:
                return {
                    ...state,
                    loading: false,
                    check_create: false
                };

        default:
            return state;
    }
}