import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Spin, Col, Button, Row } from 'antd'

import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext from 'modules/shared/context/langContext'

class Previewtable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataPreview: [],
      loadPreview: false,
      filter: '',
      header: '',
      loading: false,
    }

    this.columns = [
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.d.date}</LangContext.Consumer>
        ),
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.s.status}</LangContext.Consumer>
        ),
        dataIndex: 'ma_status_name',
        key: 'ma_status_name',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.p.plateNoCar}
          </LangContext.Consumer>
        ),
        dataIndex: 'plate_no',
        key: 'plate_no',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.v.vehicleNumber}
          </LangContext.Consumer>
        ),
        dataIndex: 'vehicle_code',
        key: 'vehicle_code',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.c.companyPartner}
          </LangContext.Consumer>
        ),
        dataIndex: 'company_partner',
        key: 'company_partner',
      },
      {
        title: 'GPS Vendor',
        dataIndex: 'vendor_name',
        key: 'vendor_name',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.p.problemType}
          </LangContext.Consumer>
        ),
        dataIndex: 'problem_name',
        key: 'problem_name',
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.c.comment}</LangContext.Consumer>
        ),
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.i.informer}</LangContext.Consumer>
        ),
        dataIndex: 'inform_user',
        key: 'inform_user',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.r.repairNoticeDate}
          </LangContext.Consumer>
        ),
        dataIndex: 'inform_date',
        key: 'inform_date',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.r.receive_date}
          </LangContext.Consumer>
        ),
        dataIndex: 'receive_date',
        key: 'receive_date',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.f.finish_date}
          </LangContext.Consumer>
        ),
        dataIndex: 'finish_date',
        key: 'finish_date',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.d.diff_date}
          </LangContext.Consumer>
        ),
        dataIndex: 'diff_date',
        key: 'diff_date',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.d.document_number}
          </LangContext.Consumer>
        ),
        dataIndex: 'document_number',
        key: 'document_number',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.r.remarkVendor}
          </LangContext.Consumer>
        ),
        dataIndex: 'vendor_remark',
        key: 'vendor_remark',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.p.primary_location}
          </LangContext.Consumer>
        ),
        dataIndex: 'primary_location',
        key: 'primary_location',
      },
    ]
  }

  handleMenuClick = () => {
    let self = this
    if (self.state.filter !== '') {
      let data = {
        ...self.state.filter,
        type_file: 'excel',
      }
      self.setState({ loading: true })

      fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/repairing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          company_id: this.props.auth.profile.company_id,
          date_start: data.date_start,
          date_end: data.date_end,
          job_status: data.job_status,
          vendor: data.vendor,
          duration: data.duration,
          list_vehicle_id: data.list_vehicle_id,
          type_file: data.type_file,
        }),
      })
        .then(response => response.json())
        .then(res => {
          self.setState({ loading: false })

          window.location.assign(
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data}`,
          )
        })
        .catch(e => this.setState({ loading: false }))
    }
  }

  componentDidMount() {
    if (localStorage.getItem('repairingreport-filter') != null) {
      let self = this
      let filter = localStorage.getItem('repairingreport-filter')
      self.setState({
        filter: JSON.parse(filter),
        loadPreview: true,
      })
      fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/repairing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: filter,
      })
        .then(response => response.json())
        .then(res =>
          self.setState({
            dataPreview: res,
            loadPreview: false,
          }),
        )
        .catch(e => this.setState({ loadPreview: false }))
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('repairingreport-filter')
  }

  render() {
    const columns = this.columns.map(col => col)

    return (
      <AuthorizeComponent {...this.props} matching_name="repairingReport">
        <Spin spinning={this.state.loading}>
          <Spin spinning={this.state.loadPreview}>
            <GeneralStyledContent>
              <Row
                gutter={24}
                type="flex"
                justify="end"
                style={{ height: '38px' }}
              >
                <Col xs={9} sm={4} md={3} lg={2} xl={2}>
                  <Button
                    type="primary"
                    block
                    onClick={() => this.handleMenuClick()}
                  >
                    <LangContext.Consumer>
                      {i18n => i18n.r.report}
                    </LangContext.Consumer>
                  </Button>
                </Col>
              </Row>

              <Table
                scroll={{ y: `${window.innerHeight - 300}px` }}
                dataSource={this.state.dataPreview}
                columns={columns}
                bordered
              />
            </GeneralStyledContent>
          </Spin>
        </Spin>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

export default connect(mapStateToProps, null)(Previewtable)
