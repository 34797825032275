import {
  REMOVE_CREDENTIALS,
  CHECK_LOGIN,
  removeCredentials,
  LOAD_CREDENTIALS,
  loadCredentials,
  SET_CONFIG_TRACKING_COLUMNS,
  SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS,
  SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS,
  SET_CONFIG_HOME_DASHBOARD_WIDGET,
  SET_LOCATION_VISIBILITY,
  SET_VEHICLE_VISIBILITY,
  SET_LOCATION_GROUP_VISIBILITY,
  SET_VEHICLE_GROUP_VISIBILITY,
  SET_MATERIAL_VISIBILITY,
  CHANGE_LANG,
  LOAD_FAVORITE_MENU,
  CREATE_FAVORITE_MENU,
  DELETE_FAVORITE_MENU,
  SET_ORG_CHART_FILTER,
  SET_ORG_CHART_TRACKING_FILTER,
} from './actions'

const initialState = {
  tokenExp: null,
  accessToken: null,
  profile: null,
  done: false,
  status: '',
  msg: '',
  loading: false,
  favMenuLoading: false,
  lang: 'th',
  favoriteMenu: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CREDENTIALS:
      const {
        payload: { token, tokenExp, profile, done, msg },
      } = action
      let {
        company_id,
        company_name,
        id,
        is_admin,
        is_super,
        is_vendor,
        username,
        firstname,
        lastname,
        phone,
        vehicle_visibility,
        location_visibility,
        vehicle_group_visibility,
        location_group_visibility,
        permissions,
        config,
        google_map_key,
        longdo_map_key,
        data_visibility_node,
        org_chart_vehicle_visibility,
        org_chart,
        relate_company,
      } = profile

      permissions = JSON.parse(permissions || '{}')
      config = JSON.parse(config || '{}')

      return {
        ...initialState,
        tokenExp: tokenExp,
        accessToken: token,
        profile: {
          ...profile,
          permissions,
          config,
          data_visibility_node,
          org_chart_vehicle_visibility,
          org_chart,
          relate_company,
        },
        done: done,
        msg: msg,
      }
    case REMOVE_CREDENTIALS:
      removeCredentials()
      return initialState
    case CHECK_LOGIN.REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case CHECK_LOGIN.SUCCESS: {
      let {
        payload: {
          token,
          tokenPayload,
          permissions,
          config,
          vehicle_list,
          location_list,
          material_list,
          exactly_location,
          mill_location_list,
          plant_location_list,
          vehicle_group_list,
          location_group_list,
          status,
          msg,
          data_visibility_node,
          org_chart_vehicle_visibility,
          org_chart,
          user_history_logon_id,
          relate_company,
          cb,
        },
      } = action

      let {
        exp,
        company_id,
        company_name,
        id,
        is_admin,
        is_super,
        is_vendor,
        is_gps_vendor,
        username,
        firstname,
        lastname,
        phone,
        // vehicle_visibility,
        // location_visibility,
        // permissions,
        // config,
        google_map_key,
        longdo_map_key,
      } = tokenPayload

      permissions = JSON.parse(permissions || '{}')
      config = JSON.parse(config || '{}')
      typeof cb != 'undefined' && cb()
      const url = new URL(window.location.href)

      return {
        ...initialState,
        lang: 'th',
        accessToken: token,
        tokenExp: exp,
        profile: {
          ...tokenPayload,
          vehicle_visibility: vehicle_list,
          location_visibility: location_list,
          material_visibility: material_list,
          exactly_location,
          mill_location_list,
          plant_location_list,
          vehicle_group_visibility: vehicle_group_list,
          location_group_visibility: location_group_list,
          permissions,
          config,
          data_visibility_node,
          org_chart_vehicle_visibility,
          org_chart,
          relate_company,
          user_history_logon_id,
          is_gps_vendor,
        },
        done: true,
        msg: msg,
        status: status,
        loading: false,
      }
    }
    case CHECK_LOGIN.FAILURE: {
      const {
        payload: { status, msg },
      } = action

      return {
        ...initialState,
        msg: msg,
        status: status,
        loading: false,
      }
    }

    case SET_CONFIG_TRACKING_COLUMNS:
      const {
        payload: { columns, showMinimalMarker },
      } = action

      let getShowminimalMarker = showMinimalMarker
      if (typeof showMinimalMarker == 'undefined' || showMinimalMarker == null)
        getShowminimalMarker = false

      const updatedConfig = {
        ...state.profile.config,
        menu: {
          ...state.profile.config.menu,
          tracking: {
            ...state.profile.config.menu.tracking,
            columns,
            show_minimal_marker: getShowminimalMarker,
          },
        },
      }

      localStorage.setItem(
        'profile',
        JSON.stringify({
          ...state.profile,
          permissions: JSON.stringify(state.profile.permissions),
          config: JSON.stringify(updatedConfig),
        }),
      )

      return {
        ...state,
        profile: {
          ...state.profile,
          config: updatedConfig,
        },
      }

    case SET_CONFIG_TRACKING_TABLE_CAR_REGIS_COLUMNS:
      const {
        payload: { CarRegisColumns },
      } = action
      localStorage.setItem(
        'profile',
        JSON.stringify({
          ...state.profile,
          permissions: JSON.stringify(state.profile.permissions),
          config: JSON.stringify({
            ...state.profile.config,
            menu: {
              ...state.profile.config.menu,
              tracking: {
                ...state.profile.config.menu.tracking,
                columns_car_plate_no: CarRegisColumns,
              },
            },
          }),
        }),
      )
      return {
        ...state,
        profile: {
          ...state.profile,
          config: {
            ...state.profile.config,
            menu: {
              ...state.profile.config.menu,
              tracking: {
                ...state.profile.config.menu.tracking,
                columns_car_plate_no: CarRegisColumns,
              },
            },
          },
        },
      }

    case SET_CONFIG_TRACKING_TABLE_DRIVER_COLUMNS:
      const {
        payload: { DriverColumns },
      } = action
      localStorage.setItem(
        'profile',
        JSON.stringify({
          ...state.profile,
          permissions: JSON.stringify(state.profile.permissions),
          config: JSON.stringify({
            ...state.profile.config,
            menu: {
              ...state.profile.config.menu,
              tracking: {
                ...state.profile.config.menu.tracking,
                columns_driver: DriverColumns,
              },
            },
          }),
        }),
      )
      return {
        ...state,
        profile: {
          ...state.profile,
          config: {
            ...state.profile.config,
            menu: {
              ...state.profile.config.menu,
              tracking: {
                ...state.profile.config.menu.tracking,
                columns_driver: DriverColumns,
              },
            },
          },
        },
      }

    case SET_CONFIG_HOME_DASHBOARD_WIDGET:
      const { payload: layout } = action
      config = JSON.parse(config || '{}')

      if (state.done)
        return {
          ...state,
          profile: {
            ...state.profile,
            config: {
              ...state.profile.config,
              home_dashboard: {
                ...state.profile.config.home_dashboard,
                layout,
              },
            },
          },
        }
      else return initialState

    case SET_ORG_CHART_FILTER: {
      const {
        payload: { filters },
      } = action
      //   console.log(filters.menu.concrete_dashboard.init_filter);
      config = JSON.parse(config || '{}')
      let newState = { ...state }
      newState['profile']['config']['menu']['concrete_dashboard'][
        'init_filter'
      ] = { ...filters.menu.concrete_dashboard.init_filter }
      return newState
    }

    case SET_ORG_CHART_TRACKING_FILTER: {
      const {
        payload: { filters },
      } = action
      //   console.log(filters.menu.concrete_dashboard.init_filter);
      config = JSON.parse(config || '{}')
      let newState = { ...state }
      newState['profile']['config']['menu']['tracking']['init_filter'] = {
        ...filters.menu.tracking.init_filter,
      }
      return newState
    }
    case CHANGE_LANG:
      return {
        ...state,
        lang: action.payload.lang,
      }
    case LOAD_FAVORITE_MENU.REQUEST:
      return {
        ...state,
        favMenuLoading: true,
      }
    case LOAD_FAVORITE_MENU.SUCCESS:
      return {
        ...state,
        favoriteMenu: action.payload.lists,
        favMenuLoading: false,
      }
    case CREATE_FAVORITE_MENU.REQUEST:
      return {
        ...state,
        favMenuLoading: true,
      }
    case CREATE_FAVORITE_MENU.SUCCESS:
      return {
        ...state,
        favoriteMenu: action.payload.res,
        favMenuLoading: false,
      }
    case DELETE_FAVORITE_MENU.REQUEST:
      return {
        ...state,
        favMenuLoading: true,
      }
    case DELETE_FAVORITE_MENU.SUCCESS:
      return {
        ...state,
        favoriteMenu: action.payload.res,
        favMenuLoading: false,
      }

    // add location_visibility in session
    case SET_LOCATION_VISIBILITY: {
      let location_visibility_new = action.payload
      if (state.done)
        return {
          ...state,
          profile: {
            ...state.profile,
            location_visibility: location_visibility_new,
          },
        }
      else {
        return initialState
      }
    }

    // add vahicle_visibility in session
    case SET_VEHICLE_VISIBILITY: {
      let vehicle_visibility_new = action.payload
      if (state.done)
        return {
          ...state,
          profile: {
            ...state.profile,
            vehicle_visibility: vehicle_visibility_new,
          },
        }
      else {
        return initialState
      }
    }

    // add vehicle_group_visibility in session
    case SET_VEHICLE_GROUP_VISIBILITY: {
      let vehicle_group_visibility_new = action.payload
      if (state.done) {
        return {
          ...state,
          profile: {
            ...state.profile,
            vehicle_group_visibility: vehicle_group_visibility_new,
          },
        }
      } else {
        return initialState
      }
    }

    // add vehicle_group_visibility in session
    case SET_LOCATION_GROUP_VISIBILITY: {
      let location_group_visibility_new = action.payload
      if (state.done) {
        return {
          ...state,
          profile: {
            ...state.profile,
            location_group_visibility: location_group_visibility_new,
          },
        }
      } else {
        return initialState
      }
    }

    case SET_MATERIAL_VISIBILITY: {
      let material_visibility_new = action.payload
      if (state.done) {
        return {
          ...state,
          profile: {
            ...state.profile,
            material_visibility: material_visibility_new,
          },
        }
      } else {
        return initialState
      }
    }

    default:
      return state
  }
}
