import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'

import * as actions from './actions'
import moment from 'moment'
import { push } from 'connected-react-router'

function* loadVehicleMaintenancedevice(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: '',
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicleMaintenancedevice.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleMaintenancedevice.failure())
  }
}

function* loadMaintenancedevice(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const is_gps_vendor = getlocalstorage(
    localStorage.getItem('profile'),
    'is_gps_vendor',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const {
    data: { page, pageSize, orderBy, orderType, filterObj },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id: COMPANY_ID,
        vehicle_visibility,
        is_gps_vendor,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadMaintenancedevice.success(res.data))
  } catch (err) {
    yield put(actions.loadMaintenancedevice.failure())
  }
}

function* createMaintenancedevice(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const firstname = getlocalstorage(
    localStorage.getItem('profile'),
    'firstname',
  )

  const lastname = getlocalstorage(localStorage.getItem('profile'), 'lastname')

  const phone = getlocalstorage(localStorage.getItem('profile'), 'phone')

  const user_id = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: {
      vehicle_id,
      maintenance_status_id,
      comment,
      type_problem_id,
      contact_name,
      contact_phone,
      appointment_place,
      email,
      province_id,
      district_id,
    },
    history,
  } = action.payload

  //const date = moment(pull_vehicle_sink_date["_d"]).format("YYYY-MM-DD");
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice/create`,
      {
        vehicle_id,
        maintenance_status_id,
        comment,
        type_problem_id,
        company_id: COMPANY_ID,
        firstname,
        lastname,
        user_id,
        phone,
        contact_name,
        contact_phone,
        appointment_place,
        email,
        province_id,
        district_id,
        matching_name: 'maintenance-device',
        action_name: 'add',
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.createMaintenancedevice.success(res.data))
    history.push('/maintenancedevice')
  } catch (err) {
    yield put(actions.createMaintenancedevice.failure())
  }
}

function* updateMaintenancedevice(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const user_id = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: {
      vehicle_id,
      maintenance_status_id,
      comment,
      type_problem_id,
      contact_name,
      contact_phone,
      appointment_place,
      email,
      province_id,
      district_id,
    },
    id,
    history,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice/update`,
      {
        vehicle_id,
        maintenance_status_id,
        comment,
        type_problem_id,
        contact_name,
        contact_phone,
        appointment_place,
        email,
        province_id,
        district_id,
        company_id: COMPANY_ID,
        user_id,
        id,
        matching_name: 'maintenance-device',
        action_name: 'edit',
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.updateMaintenancedevice.success(res.data))
    history.push('/maintenancedevice')
  } catch (err) {
    yield put(actions.updateMaintenancedevice.failure())
  }
}

function* getMaintenancedeviceByID(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/maintenancedevice/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.getMaintenancedeviceByID.success(res.data))
  } catch (err) {
    yield put(actions.getMaintenancedeviceByID.failure())
  }
}

function* loadMaintenancestatus(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getmaintenancestatuscpac`,
      {},
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadMaintenancestatus.success(res.data))
  } catch (err) {
    yield put(actions.loadMaintenancestatus.failure())
  }
}

function* loadTypeproblem(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/gettypeproblem`,
      {},
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      },
    )
    yield put(actions.loadTypeproblem.success(res.data))
  } catch (err) {
    yield put(actions.loadTypeproblem.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_MAINTENANCEDEVICE.REQUEST, loadMaintenancedevice),
    takeEvery(
      actions.CREATE_MAINTENANCEDEVICE.REQUEST,
      createMaintenancedevice,
    ),
    takeEvery(
      actions.UPDATE_MAINTENANCEDEVICE.REQUEST,
      updateMaintenancedevice,
    ),
    takeEvery(actions.GET_MAINTENANCEDEVICE.REQUEST, getMaintenancedeviceByID),
    takeEvery(
      actions.LOAD_VEHICLE_MAINTENANCEDEVICE.REQUEST,
      loadVehicleMaintenancedevice,
    ),
    takeEvery(
      actions.LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICE.REQUEST,
      loadMaintenancestatus,
    ),
    takeEvery(
      actions.LOAD_TYPEPROBLEM_MAINTENANCEDEVICE.REQUEST,
      loadTypeproblem,
    ),
  ])
}

export {
  loadMaintenancedevice,
  createMaintenancedevice,
  updateMaintenancedevice,
  getMaintenancedeviceByID,
  loadVehicleMaintenancedevice,
}
