import styled from 'styled-components'
import { Layout, Row, Col } from 'antd'
const { Sider } = Layout

const StyledImageHeader = styled.h2`
  padding: 7px 0px 0px 12px;
  height: 45px;
  width: 270px;
  background-color: white;
  line-height: 15px;
`

const StyledHeader1 = styled.p`
  margin-top: 0;
  margin-bottom: 0em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 150;
  display: block;
  font-size: 3em;
`

const StyledHeader2 = styled.p`
  margin-top: 0;
  margin-bottom: 0em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 150;
  display: block;
  font-size: 2em;
`

const StyledButton = styled.button`
  color: red;
  background-color: white;
`

const StyledSearchForm = styled.div`
  margin: 0px 0px 24px 0px;
  padding: 10px 10px 20px 20px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`

const StyledSearchFormMark = styled.div`
  margin: 0px 0px 24px 0px;
  padding: 24px;
  border-radius: 6px;
`

const StyledSearchForm3 = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 16px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`

const StyledSearchForm2 = styled.div`
  margin: 15px 15px 15px 15px;
  padding: 15px;
  border: 1px solid #000000;
  border-radius: 6px;
`

const StyledWidget = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 20px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  box-shadow: 0 0.46875rem 2.1875rem rgba(58, 196, 125, 0.03),
    0 0.9375rem 1.40625rem rgba(58, 196, 125, 0.03),
    0 0.25rem 0.53125rem rgba(58, 196, 125, 0.05),
    0 0.125rem 0.1875rem rgba(58, 196, 125, 0.03);
`

const StyledWidgetShadowOnly = styled.div`
  box-shadow: 0 0.46875rem 2.1875rem rgba(58, 196, 125, 0.03),
    0 0.9375rem 1.40625rem rgba(58, 196, 125, 0.03),
    0 0.25rem 0.53125rem rgba(58, 196, 125, 0.05),
    0 0.125rem 0.1875rem rgba(58, 196, 125, 0.03);
`

const GeneralStyledContent = styled(Layout.Content)`
  background: white;
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
`

const CommonLayout = styled(Layout.Content)`
  background: white;
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
  height: calc(100vh - 55px);
`
const FuelNotification = styled(Layout.Content)`
  background: #f1f3f9;
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
  min-height: calc(100vh - 55px);
`

const SmartDispatchLayout = styled(Layout.Content)`
  background: white;
  margin: 5px 0px 0px 0px;
  padding: 0px 15px 0px 15px;
`

const WebContentStyled = styled(Layout.Content)`
  // background: white;
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
`

const TrackingStatusStyledContent = styled(Layout.Content)`
  background: ececec;
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
`

const StyledTableVahicle = styled(Layout.Content)`
  background: white;
  margin: 0px;
  padding: 0px;
`

const StyleContentVehicle = styled(Layout.Content)`
  background: #ffffff;
  margin: 6px 0px 0px 0px;
  padding: 20px 20px 10px 20px;
`

const StyleContentVehicleSecondHeader = styled(Layout.Content)`
  background: #ececec;
  margin: 20px 20px 0px 20px;
  padding: 20px 20px 10px 20px;
`
const StyleVehicle = styled(Layout.Content)`
  //   background: #ececec;
  margin: 20px 20px 0px 20px;
  padding: 20px 20px 10px 20px;
`

const PureBackgroundStyledContent = styled(Layout.Content)`
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
  min-height: ${window.innerHeight - 46}px;
`

const TrackingStyledContent = styled(Layout.Content)`
  background: white;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  min-height: ${window.innerHeight - 48}px;
`
const CpacPolicyContent = styled(Layout.Content)`
  background: white;
  margin: 5px 0px 0px 0px;
  padding: 20px 20px 10px 20px;
  min-height: ${window.innerHeight - 48}px;
`

const ReplayStyledContent = styled(Layout.Content)`
  background: white;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
`

const SafetyDashboardStyledContent = styled(Layout.Content)`
  background: #ececec;
  margin: 6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  min-height: ${window.innerHeight - 46}px;
`

const StyledSider = styled(Sider)`
  padding: 0;
  maxwidth: 270;
  minwidth: 270;
  width: 270;
`
const StyledHeader = styled.h2`
  padding: 13px 0px 0px 14px;
  height: 45px;
  width: 270px;
  background-color: white;
  line-height: 15px;
`

const StyledLogoiSupply = styled.img`
  max-height: 100% !important;
  margin-bottom: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 37%;
`
const StyleTextCenter = styled.span`
  text-align: center !important;
`

const StyledLogo = styled.img`
  max-height: 100% !important;
  margin-bottom: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
`
const StyledIconDownload = styled.img`
  max-height: 100% !important;
  margin-bottom: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  height: auto;
`

const StyledLogoVerifyEmail = styled.img`
  width: 270px !important;
  max-height: 100% !important;
`

const StyledDiv = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
`

const StyledRow = styled(Row)`
  margin: 5px 0px 20px 0px;
`

const StyledCol = styled(Col)`
  padding-top: ${process.env.REACT_APP_WEB_ENDPOINT == 'isupply'
    ? `20px`
    : `120px`};
`

const SafetyPanel = styled.div`
  height: ${props => props.height}px;
  margin: 15px 10px 5px 0px;
  padding: 4px 5px 10px 15px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 1;
  border-radius: 4px;
  background-color: white;
  line-height: 15px;
`

export {
  StyledLogoVerifyEmail,
  StyledIconDownload,
  StyleTextCenter,
  StyledLogo,
  StyledLogoiSupply,
  StyledDiv,
  StyledRow,
  StyledCol,
  StyledButton,
  StyledSearchForm,
  StyledSearchForm2,
  StyledSearchForm3,
  StyledSearchFormMark,
  StyledHeader1,
  StyledHeader2,
  GeneralStyledContent,
  WebContentStyled,
  StyleContentVehicle,
  StyleContentVehicleSecondHeader,
  StyleVehicle,
  StyledTableVahicle,
  PureBackgroundStyledContent,
  TrackingStyledContent,
  ReplayStyledContent,
  SafetyDashboardStyledContent,
  StyledSider,
  StyledHeader,
  StyledImageHeader,
  StyledWidget,
  StyledWidgetShadowOnly,
  SafetyPanel,
  TrackingStatusStyledContent,
  CpacPolicyContent,
  SmartDispatchLayout,
  CommonLayout,
  FuelNotification,
}
