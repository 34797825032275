import {
  LOAD_TRUCKS_PICKUP_CARD_NO,
  CREATE_TRUCKS_PICKUP_CARD_NO,
  LOAD_TRUCKS_PICKUP_CARD_NO_DETAIL,
  UPDATE_TRUCKS_PICKUP_CARD_NO,
  EXPORT_TRUCKS_PICKUP_CARD_NO,
  IMPORT_TRUCKS_PICKUP_CARD_NO,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  Lists: [],
  lists_for_edit: '',
  total: 1,
  loading: false,
  draw: -1,
  check: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_TRUCKS_PICKUP_CARD_NO.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        loading: loading,
      }

    case LOAD_TRUCKS_PICKUP_CARD_NO.SUCCESS:
      const { page } = action.payload
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10
      }
      return {
        ...state,
        Lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case CREATE_TRUCKS_PICKUP_CARD_NO.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_TRUCKS_PICKUP_CARD_NO.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_TRUCKS_PICKUP_CARD_NO_DETAIL.REQUEST:
      return {
        ...state,
        loading: true,
        check: true,
      }

    case LOAD_TRUCKS_PICKUP_CARD_NO_DETAIL.SUCCESS:
      return {
        ...state,
        loading: false,
        lists_for_edit: action.payload.res.data,
        check: false,
      }

    case UPDATE_TRUCKS_PICKUP_CARD_NO.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_TRUCKS_PICKUP_CARD_NO.SUCCESS:
      return {
        ...state,
        loading: false,
        lists_for_edit: action.payload.res.data,
      }

    case EXPORT_TRUCKS_PICKUP_CARD_NO.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case EXPORT_TRUCKS_PICKUP_CARD_NO.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case IMPORT_TRUCKS_PICKUP_CARD_NO.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case IMPORT_TRUCKS_PICKUP_CARD_NO.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
