import React from "react";
import { Switch, Route } from "react-router-dom";
import LineNotify from "./Index";
// import Add from "./Add";
import Edit from "./Edit";
import Detail from "./Detail";
export default () => (
  <Switch>
    <Route exact path="/linenotify" component={LineNotify} />
    {/* <Route path="/linenotify/create" component={Add} /> */}
    <Route path="/linenotify/edit/:id" component={Edit} />
    <Route path="/linenotify/detail/:id" component={Detail} />
  </Switch>
);
