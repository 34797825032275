import React, { useEffect, useState } from 'react'
import { FuelNotification } from '../../../../../src/styled/common-styled'
import { Row, Col, Icon, Spin, Button, Card, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { data } from './FakeData'
import * as moment from 'moment'
import LangContext, { i18n } from 'modules/shared/context/langContext'

const FuelDetailWithoutCalibrate = () => {
  const [vehicleList, setVehicleList] = useState([])
  const [loading, setLoading] = useState(false)
  const [updatedAt, setUpdatedAt] = useState('')
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')

  const fetchVehicleFuelWithoutCalibrate = async () => {
    setLoading(true)
    const oilNotCalibrated = await JSON.parse(
      localStorage.getItem('oil-not-calibrated'),
    )
    setHeader(oilNotCalibrated.data.main_subject)
    setMsg(oilNotCalibrated.data.body.msg)
    setVehicleList(oilNotCalibrated.data.body.lists)
    setUpdatedAt(oilNotCalibrated.notify_date)
    setLoading(false)
  }

  useEffect(() => {
    fetchVehicleFuelWithoutCalibrate()
  }, [])

  return (
    <Spin spinning={loading}>
      <FuelNotification>
        <Row gutter={[8, 8]}>
          <Col span={1} align="center">
            <span className="left__button__web__content">
              <Link to="/fuel-notification">
                <Button shape="circle" size="large">
                  <Icon type="left" style={{ color: 'black', paddingTop: 6 }} />
                </Button>
              </Link>
            </span>
          </Col>
          <Col span={18}>
            <span style={{ fontSize: '1.2rem', color: '#172b4d' }}>
              {header}
            </span>
            <p>
              <LangContext.Consumer>
                {i18n => i18n.l.lastUpdatedOn}
              </LangContext.Consumer>{' '}
              : {updatedAt}
            </p>
            <p>
              {msg}{' '}
              <label style={{ fontWeight: 'bold', color: '#4489ec' }}>
                Replay
              </label>
            </p>
            <div
              style={{ height: window.innerHeight - 200, overflow: 'scroll' }}
            >
              <Row gutter={[16, 16]}>
                {vehicleList.map((item, index) => {
                  return (
                    <Tooltip title={`${item.plate_no} (${item.code})`}>
                      <Col span={4}>
                        <div
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: 180,
                            whiteSpace: 'nowrap',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            padding: '15px 20px',
                          }}
                          className="list-vehicle-card-fuel-notification"
                          onClick={() =>
                            window.open(
                              `/replay/vehicle/${item.dataforreplay}/${
                                item.plate_no
                              } (${item.code})/${moment(
                                item.event_at,
                                'YYYY-MM-DD HH:mm:ss',
                              ).format('DD-MM-YYYY')}`,
                            )
                          }
                        >
                          <img src="/img/Smalltruck-final-fuel.png" />{' '}
                          {item.plate_no} ({item.code})
                        </div>
                        {/* <Card
                        style={{
                          width: 180,
                          textAlign: 'center',
                          borderRadius: '10px',
                          cursor: 'pointer',
                        }}
                        className="list-vehicle-card-fuel-notification"
                        onClick={() =>
                          window.open(
                            `/replay/vehicle/${item.dataforreplay}/${
                              item.plate_no
                            } (${item.code})/${moment(
                              item.event_at,
                              'YYYY-MM-DD HH:mm:ss',
                            ).format('DD-MM-YYYY')}`,
                          )
                        }
                      >
                        <div
                          style={{
                            cursor: 'pointer',
                            // textOverflow: 'ellipsis',
                            // overflow: 'hidden',
                            // width: 100,
                            // whiteSpace: 'nowrap',
                          }}
                        >
                          <img src="/img/Smalltruck-final-fuel.png" />{' '}
                          {item.plate_no} ({item.code})
                        </div>
                      </Card> */}
                      </Col>
                    </Tooltip>
                  )
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </FuelNotification>
    </Spin>
  )
}

export default FuelDetailWithoutCalibrate
