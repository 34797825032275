import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import history from '../../lib/history'

const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value)
    })
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key)
    })
  },
}

function* checkOauthLogin(action) {
  const { payload } = action.payload
  try {
    let url = ''
    let body = null
    // if (action.payload.token != '') {
    //   url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/auth/login-by-token`
    //   body = { token: action.payload.token }
    // } else {
    url = `${process.env.REACT_APP_OAUTH_SERVICE_GO}/cpacauthorize`
    body = payload

    // }
    const res = yield call(axios.post, url, body)
    let {
      data: {
        token,
        permissions,
        vehicle_list,
        location_list,
        material_list,
        exactly_location,
        mill_location_list,
        plant_location_list,
        vehicle_group_list,
        location_group_list,
        config,
        data_visibility_node,
        org_chart_vehicle_visibility,
        org_chart,
        user_history_logon_id,
        relate_company,
      },
    } = res.data
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let deToken = JSON.parse(window.atob(base64))
    console.log('Before storeCredentials')
    yield call(() =>
      actions.storeCredentials(
        token,
        deToken,
        permissions,
        vehicle_list,
        location_list,
        material_list,
        exactly_location,
        mill_location_list,
        plant_location_list,
        vehicle_group_list,
        location_group_list,
        config,
        data_visibility_node,
        org_chart_vehicle_visibility,
        org_chart,
        user_history_logon_id,
        relate_company,
      ),
    )
    yield put(
      actions.checkOauthLogin.success(
        token,
        deToken,
        permissions,
        vehicle_list,
        location_list,
        material_list,
        exactly_location,
        mill_location_list,
        plant_location_list,
        vehicle_group_list,
        location_group_list,
        config,
        data_visibility_node,
        org_chart_vehicle_visibility,
        org_chart,
        user_history_logon_id,
        relate_company,
        // cb,
      ),
    )

    // loginType == "bluenet-via-url" && cb();
  } catch (err) {
    if (err.message.indexOf('400') !== -1)
      yield put(
        actions.checkOauthLogin.failure(
          'error',
          'Invalid username or password!',
        ),
      )
    else if (err.message.indexOf('401') !== -1)
      yield put(actions.checkOauthLogin.failure('error', 'Account expired!'))
    else if (err.message.indexOf('503') !== -1)
      yield put(
        actions.checkOauthLogin.failure('error', 'Service Unavailable!'),
      )
    else if (err.message.indexOf('502') !== -1)
      yield put(actions.checkOauthLogin.failure('error', 'Bad Gateway!'))
    else if (err.message.indexOf('504') !== -1)
      yield put(actions.checkOauthLogin.failure('error', 'Gateway Timeout!'))
    else if (err.message.indexOf('505') !== -1)
      yield put(
        actions.checkOauthLogin.failure(
          'error',
          'Token wrong or Token expired!',
        ),
      )
    else
      yield put(
        actions.checkOauthLogin.failure('error', 'Something went wrong!'),
      )
  }
}

export default function* watchOauthLogin() {
  yield all([takeEvery(actions.CHECK_OAUTH_LOGIN.REQUEST, checkOauthLogin)])
}

export { checkOauthLogin }
