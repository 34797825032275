import React, { useState } from 'react'
import { Card, Input, Col, Row, Icon, Button } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'

const SearchStartEndLocation = ({
  onClose,
  startLocation,
  endLocation,
  disabled,
  handleShowNearestModal,
  handleSwitchLocation,
  switchDestinationMode
}) => {
  return (
    <div style={{ zIndex: '13', position: "absolute" }}>
    <LangContext.Consumer>
      {i18n => (
        <Card
          title={<div style={{ padding: '8px' }}>{i18n.s.searchYourRoute}</div>}
          extra={
            <Icon
              type="close"
              style={{ color: '#E24D5B' }}
              onClick={() => onClose()}
            />
          }
          style={{ width: 420, borderRadius: '10px' }}
          size="small"
        >
          <Row type='flex' align='middle' justify='space-around' gutter={16}>
            <Col span={21}>
              {/* ต้นทาง */}
              <Row>
                <Col span={3}>
                  <div>
                    <div className="semi-bold-16">
                      <Col span={24} align="center">
                        <img
                          src="/img/Location 1.png"
                          alt=""
                          style={{ height: '22px', marginTop: '7px' }}
                        />
                      </Col>
                    </div>
                  </div>
                </Col>
                <Col span={21}>
                  <div
                    className="semi-bold-16"
                    style={{
                      marginLeft: '5px',
                      marginTop: '2px',
                      color: '#172B4D',
                    }}
                  >
                    <Col
                      span={24}
                      align="left"
                      style={{ fontSize: '14px', fontWeight: 600 }}
                    >
                      <Input
                        value={switchDestinationMode ? endLocation : startLocation}
                        style={{ width: 300, color: '#D6D6D6', height: 40 }}
                        readOnly
                      />
                    </Col>
                  </div>
                </Col>
              </Row>

              {/* เส้น */}
              <Row>
                <Col span={3}>
                  <div
                    style={{
                      minHeight: '14px' /* ความสูงของเส้น */,
                      height: 'auto',
                      width: '4px' /* ความหนาของเส้น */,
                      backgroundColor: '#DDDDDD' /* สีของเส้น */,
                      marginLeft: '46%',
                    }}
                  ></div>
                </Col>
                <Col span={21}>
                  <div
                    style={{
                      marginLeft: '40px',
                    }}
                  >
                    <Col span={24} align="left">
                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: '400',
                          marginBottom: '0px',
                          marginTop: '5px',
                        }}
                      ></p>
                    </Col>
                  </div>
                </Col>
              </Row>

              {/* ปลายทาง */}
              <Row>
                <Col span={3}>
                  <div>
                    <div className="semi-bold-16">
                      <Col span={24} align="center">
                        <img
                          src="/img/Location pin.png"
                          alt=""
                          style={{ height: '27px', marginTop: '6px' }}
                        />
                      </Col>
                    </div>
                  </div>
                </Col>
                <Col span={21}>
                  <div className="semi-bold-16">
                    <Col
                      span={24}
                      align="left"
                      style={{
                        marginLeft: '5px',
                        marginTop: '4px',
                        fontSize: '14px',
                        width: '98%',
                        color: '#172B4D',
                        paddingBottom: '15px',
                        fontWeight: 600,
                      }}
                    >
                      <Input
                        value={switchDestinationMode ? startLocation : endLocation}
                        style={{ width: 300, cursor: 'pointer', height: 40 }}
                        readOnly
                        onClick={handleShowNearestModal}
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={3} align="center">
              <Button shape="circle" icon="swap" onClick={handleSwitchLocation} />
            </Col>
          </Row>

          <Row gutter={[0, 16]} justify="space-between" align="bottom">
            <Col span={24} align="center">
              <Button
                type="primary"
                style={{
                  width: 300,
                  backgroundColor: '#F53C56',
                  color: 'white',
                  borderRadius: '10px',
                  height: 40,
                }}
                onClick={() => onClose()}
              >
                {i18n.c.cancelRouting}
              </Button>
            </Col>
          </Row>

        </Card>
      )}
    </LangContext.Consumer>
    </div>
  )
}

export default SearchStartEndLocation
