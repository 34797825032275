import React from "react";
import { Switch, Route } from "react-router-dom";
import GpsPerformanceDashboard from "./Index";

export default () => (
  <Switch>
    <Route
      path="/gps_performance_dashboard"
      component={GpsPerformanceDashboard}
    />
  </Switch>
);
