import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Tooltip,
  Icon,
  Table,
} from 'antd'

const { MonthPicker } = DatePicker

const FormItem = Form.Item
const Option = Select.Option

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],

    vendor_list: [],
    sortBy: 'device_code',
    data: [],
    loadDataPreview: false,
    selectedKeys: [],
  }

  componentDidMount() {
    const { loadVendor, call } = this.props

    if (call === 'schedule') {
      loadVendor()
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vendor_list: obj.vendor_list,
          sortBy: obj.sort_by,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }
  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleClicReportkPreview = values => {
    const { vendor_list, eventdate, sortBy } = values
    const date_start = moment(eventdate['_d'])
      .clone()
      .startOf('months')
      .format('YYYY-MM-DD 00:00:00')
    const date_end = moment(eventdate['_d'])
      .clone()
      .endOf('months')
      .format('YYYY-MM-DD 23:59:59')

    if (vendor_list.length > 0) {
      const filter = {
        date_start,
        date_end,
        vendor_list,
        sort_by: sortBy,
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        type_file: 'preview',
      }

      const header = {
        headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
      }

      window.open('/report/gpsvendorpayingsummaryreport/preview', '_blank')
      localStorage.setItem(
        'gpsvendorpayingsummaryreport-filter',
        JSON.stringify(filter),
      )
      localStorage.setItem(
        'gpsvendorpayingsummaryreport-header',
        JSON.stringify(header),
      )
    }
  }

  render() {
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <Spin spinning={this.props.loading}>
          <Spin spinning={this.state.loadDataPreview}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                eventdate: [],
                vendor_list: this.state.vendor_list,
                sortBy: this.state.sortBy,
              }}
              validationSchema={yup.object().shape({
                vendor_list: yup.array().required('กรุณาเลือก'),
              })}
              onSubmit={values => {
                this.props.onSubmit(values)
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue,
                isValidating,
              }) => (
                <Row type="flex" justify="center">
                  <LangContext.Consumer>
                    {i18n => (
                      <Form onSubmit={handleSubmit}>
                        <div className="semi-bold-16">
                          <LangContext.Consumer>
                            {i18n => i18n.g.gpsVendorPayingSummaryReport}
                          </LangContext.Consumer>
                        </div>
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.g.gpsVendor}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.vendor_list &&
                            errors.vendor_list &&
                            'warning'
                          }
                          help={touched.vendor_list && errors.vendor_list}
                        >
                          <div id="finding-btn">
                            <Transfer
                              titles={[
                                <LangContext.Consumer>
                                  {i18n => i18n.i.itemLeft}
                                </LangContext.Consumer>,
                                <LangContext.Consumer>
                                  {i18n => i18n.i.itemSelected}
                                </LangContext.Consumer>,
                              ]}
                              dataSource={
                                this.props.vendorLists &&
                                this.props.vendorLists.map(ele => {
                                  return {
                                    key: ele.id,
                                    name: ele.name,
                                  }
                                })
                              }
                              showSearch
                              listStyle={{
                                width: 250,
                                height: 300,
                              }}
                              selectedKeys={this.state.selectedKeys}
                              operations={['>>', '<<']}
                              targetKeys={values.vendor_list}
                              onChange={value =>
                                setFieldValue('vendor_list', value)
                              }
                              onSelectChange={this.handleSelectChange}
                              render={item => `${item.name}`}
                              footer={this.renderFooter}
                              filterOption={(inputValue, item) => {
                                return (
                                  item.name
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                                )
                              }}
                            />
                          </div>
                        </FormItem>
                        <FormItem
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.r.reportsSortedColumns}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.sortBy && errors.sortBy && 'warning'
                          }
                          help={touched.sortBy && errors.sortBy}
                        >
                          <Select
                            onChange={value => {
                              setFieldValue('sortBy', value)
                            }}
                            value={values.sortBy}
                            style={{ width: '80%' }}
                          >
                            <Option value="device_code">
                              <LangContext.Consumer>
                                {i18n => i18n.b.boxnumber}
                              </LangContext.Consumer>
                            </Option>
                            <Option value="vehicle_code">
                              <LangContext.Consumer>
                                {i18n => i18n.v.vehicleNumber}
                              </LangContext.Consumer>
                            </Option>
                            <Option value="plate_no">
                              <LangContext.Consumer>
                                {i18n => i18n.p.plateNoCar}
                              </LangContext.Consumer>
                            </Option>
                            <Option value="plant">
                              <LangContext.Consumer>
                                {i18n => i18n.p.plants}
                              </LangContext.Consumer>
                            </Option>
                            <Option value="company_partner">
                              <LangContext.Consumer>
                                {i18n => i18n.b.businesspartner}
                              </LangContext.Consumer>
                            </Option>
                          </Select>
                        </FormItem>

                        {this.props.call !== 'schedule' ? (
                          <Form.Item
                            label={
                              <LangContext.Consumer>
                                {i18n => i18n.m.month}
                              </LangContext.Consumer>
                            }
                            validateStatus={
                              touched.eventdate && errors.eventdate && 'warning'
                            }
                            help={touched.eventdate && errors.eventdate}
                          >
                            <MonthPicker
                              disabledDate={disabledDate}
                              onChange={value =>
                                setFieldValue('eventdate', value)
                              }
                              defaultValue={moment()}
                              format="MM"
                              allowClear={false}
                              style={{ margin: '0px 0px 40px 0px' }}
                            />
                          </Form.Item>
                        ) : (
                          ''
                        )}

                        {this.props.call !== 'schedule' ? (
                          <FormItem>
                            <Row gutter={24}>
                              <Col span={8}>
                                <Button type="defualt" block>
                                  <Link to="/">{i18n.c.cancel}</Link>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  block
                                  onClick={() =>
                                    this.handleClicReportkPreview(values)
                                  }
                                >
                                  {i18n.p.preview}
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button type="primary" htmlType="submit" block>
                                  {i18n.r.report}
                                </Button>
                              </Col>
                            </Row>
                          </FormItem>
                        ) : (
                          ''
                        )}

                        {this.props.call === 'schedule' ? (
                          <Col span={8}>
                            <Button type="primary" htmlType="submit" block>
                              <LangContext.Consumer>
                                {i18n => i18n.s.save}
                              </LangContext.Consumer>
                            </Button>
                          </Col>
                        ) : (
                          ''
                        )}
                      </Form>
                    )}
                  </LangContext.Consumer>
                </Row>
              )}
            />
          </Spin>
        </Spin>
      </div>
    )
  }
}
