import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Spin, Col, Button, Row } from 'antd'
import axios from 'axios'
import * as actions from '../actions'

import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext from 'modules/shared/context/langContext'

class Previewtable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataPreview: [],
      loadPreview: false,
      filter: '',
      header: '',
    }

    this.columns = [
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.n.noReport}</LangContext.Consumer>
        ),
        dataIndex: 'Row',
        key: 'Row',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.p.plateNoCar}
          </LangContext.Consumer>
        ),
        dataIndex: 'PlateNo',
        key: 'PlateNo',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.v.vehicleNumber}
          </LangContext.Consumer>
        ),
        dataIndex: 'VehicleCode',
        key: 'VehicleCode',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.c.contractors}
          </LangContext.Consumer>
        ),
        dataIndex: 'Contractor',
        key: 'Contractor',
      },
      {
        title: 'GPS Vendor',
        dataIndex: 'GPSVendor',
        key: 'GPSVendor',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.t.totalDistance}
          </LangContext.Consumer>
        ),
        dataIndex: 'TotalDistance',
        key: 'TotalDistance',
      },
      {
        title: (
          <LangContext.Consumer>
            {i18n => i18n.t.TotalFuel}
          </LangContext.Consumer>
        ),
        dataIndex: 'TotalFuel',
        key: 'TotalFuel',
      },
      {
        title: (
          <LangContext.Consumer>{i18n => i18n.d.date}</LangContext.Consumer>
        ),
        dataIndex: 'LogDate',
        key: 'LogDate',
      },
    ]
  }

  handleMenuClick = () => {
    let self = this
    if (self.state.filter !== '') {
      let data = {
        ...self.state.filter,
        type_file: 'excel',
      }
      this.props.loadFuelusepreview(data, true)
    }
  }

  componentDidMount() {
    if (localStorage.getItem('fuelusereport-filter') != null) {
      let self = this
      let filter = localStorage.getItem('fuelusereport-filter')
      self.setState({
        filter: JSON.parse(filter),
        loadPreview: true,
      })
      axios
        .post(
          `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/fueluse`,
          JSON.parse(filter),
        )
        .then(function(response) {
          let data = response.data
          self.setState({
            dataPreview: data,
            loadPreview: false,
          })
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('fuelusereport-filter')
  }

  render() {
    const { fueluseLoading } = this.props.fueluse

    const columns = this.columns.map(col => col)

    return (
      <AuthorizeComponent {...this.props} matching_name="FueluseReport">
        <Spin spinning={fueluseLoading}>
          <Spin spinning={this.state.loadPreview}>
            <GeneralStyledContent>
              <Row
                gutter={24}
                type="flex"
                justify="end"
                style={{ height: '38px' }}
              >
                <Col xs={9} sm={4} md={3} lg={2} xl={2}>
                  <Button
                    type="primary"
                    block
                    onClick={() => this.handleMenuClick()}
                  >
                    <LangContext.Consumer>
                      {i18n => i18n.r.report}
                    </LangContext.Consumer>
                  </Button>
                </Col>
              </Row>

              <Table
                scroll={{ y: `${window.innerHeight - 300}px` }}
                dataSource={this.state.dataPreview}
                columns={columns}
                bordered
              />
            </GeneralStyledContent>
          </Spin>
        </Spin>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ fueluse, auth: { profile } }) => ({
  fueluse,
  profile,
})

const mapDispatchToProps = {
  loadFuelusepreview: actions.loadFuelusepreview.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Previewtable)
