import { createTypes, createAction } from 'lib/action'
const REMOVE_LISTS = `REMOVE_LISTS`
const LOAD_ONGOING = createTypes('isupplyongoing', 'load')
const LOAD_SUCCESS = createTypes('isupplysuccess', 'load')
const LOAD_AVALIABLE = createTypes('isupplyavailable', 'load')
const LOAD_ALL = createTypes('isupplyall', 'load')
const LOAD_AVALIABLE_FOR_EDIT = createTypes('isupplyavailableforedit', 'load')
const UPDATE_DATA_TRICKET = createTypes('isupplydeliveryedittricket', 'update')
const LOAD_DATA_VEHICLE_FOR_VIEW = createTypes(
  'isupplydashboardvehicle',
  'load',
)
const NEW_LOAD_DATA_DASHBORAD = createTypes('newisupplydashboard', 'load')
const NEW_LOAD_DATA_DASHBORAD2 = createTypes('newisupplydashboard2', 'load')
const NEW_LOAD_DATA_DASHBORAD_INTERVAL = createTypes(
  'newisupplydashboardinterval',
  'load',
)
const RECALCULATOR_AREA_NOT_COVERED = createTypes(
  'recalculatorareanotcovered',
  'update',
)
const CALCULATOR_DELIVERRY_OUTSIDE_THE_AREA = createTypes(
  'calculatordeliveryoutsidethearea',
  'update',
)
const CREATE_LOG_REQUEST_CHANGE_DEST_LOCATION = createTypes(
  'createlogrequestchangedestlocation',
  'create',
)
const ISUPPLY_DASHBOARD_CLOSE_WORK = createTypes(
  'isupplydashboardclosework',
  'create',
)

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadDataDashborad = {
  request: (data, loading) =>
    createAction(NEW_LOAD_DATA_DASHBORAD.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(NEW_LOAD_DATA_DASHBORAD.SUCCESS, { lists }),
  failure: () => createAction(NEW_LOAD_DATA_DASHBORAD.FAITLURE),
}

const loadDataDashborad2 = {
  request: (data, loading) =>
    createAction(NEW_LOAD_DATA_DASHBORAD2.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(NEW_LOAD_DATA_DASHBORAD2.SUCCESS, { lists, page }),
  failure: () => createAction(NEW_LOAD_DATA_DASHBORAD2.FAITLURE),
}

const loadDataDashboradInterval = {
  request: (data, loading) =>
    createAction(NEW_LOAD_DATA_DASHBORAD_INTERVAL.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(NEW_LOAD_DATA_DASHBORAD_INTERVAL.SUCCESS, { lists }),
  failure: () => createAction(NEW_LOAD_DATA_DASHBORAD_INTERVAL.FAITLURE),
}

const loadOngoing = {
  request: (data, loading) =>
    createAction(LOAD_ONGOING.REQUEST, { data, loading }),
  success: (lists, page) => createAction(LOAD_ONGOING.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_ONGOING.FAITLURE),
}

const loadSuccess = {
  request: (data, loading) =>
    createAction(LOAD_SUCCESS.REQUEST, { data, loading }),
  success: (lists, page) => createAction(LOAD_SUCCESS.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_SUCCESS.FAITLURE),
}

const loadAvailable = {
  request: (data, loading) =>
    createAction(LOAD_AVALIABLE.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_AVALIABLE.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_AVALIABLE.FAITLURE),
}

const loadWorkALL = {
  request: (data, loading) => createAction(LOAD_ALL.REQUEST, { data, loading }),
  success: (lists, page) => createAction(LOAD_ALL.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_ALL.FAITLURE),
}

const loadAvailableForEdit = {
  request: (data, loading) =>
    createAction(LOAD_AVALIABLE_FOR_EDIT.REQUEST, { data, loading }),
  success: lists => createAction(LOAD_AVALIABLE_FOR_EDIT.SUCCESS, { lists }),
  failure: () => createAction(LOAD_AVALIABLE_FOR_EDIT.FAILURE),
}

const updateDataTricket = {
  request: (data, loading) =>
    createAction(UPDATE_DATA_TRICKET.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_DATA_TRICKET.SUCCESS, { res }),
  failure: () => createAction(UPDATE_DATA_TRICKET.FAILURE),
}

const loadDataVehicleForView = {
  request: (data, loading) =>
    createAction(LOAD_DATA_VEHICLE_FOR_VIEW.REQUEST, { data, loading }),
  success: lists => createAction(LOAD_DATA_VEHICLE_FOR_VIEW.SUCCESS, { lists }),
  failure: () => createAction(LOAD_DATA_VEHICLE_FOR_VIEW.FAILURE),
}

const reCalculatorAreaNotCovered = {
  request: (data, loading) =>
    createAction(RECALCULATOR_AREA_NOT_COVERED.REQUEST, { data, loading }),
  success: res => createAction(RECALCULATOR_AREA_NOT_COVERED.SUCCESS, { res }),
  failure: () => createAction(RECALCULATOR_AREA_NOT_COVERED.FAILURE),
}

const calculatorDeliveryOutsideTheArea = {
  request: (data, loading) =>
    createAction(CALCULATOR_DELIVERRY_OUTSIDE_THE_AREA.REQUEST, {
      data,
      loading,
    }),
  success: res =>
    createAction(CALCULATOR_DELIVERRY_OUTSIDE_THE_AREA.SUCCESS, { res }),
  failure: () => createAction(CALCULATOR_DELIVERRY_OUTSIDE_THE_AREA.FAILURE),
}

const createLogRequestChangeDestLocation = {
  request: (data, loading) =>
    createAction(CREATE_LOG_REQUEST_CHANGE_DEST_LOCATION.REQUEST, {
      data,
      loading,
    }),
  success: res =>
    createAction(CREATE_LOG_REQUEST_CHANGE_DEST_LOCATION.SUCCESS, { res }),
  failure: () => createAction(CREATE_LOG_REQUEST_CHANGE_DEST_LOCATION.FAILURE),
}

const isupplyDashboardCloseWork = {
  request: (data, loading) =>
    createAction(ISUPPLY_DASHBOARD_CLOSE_WORK.REQUEST, {
      data,
      loading,
    }),
  success: res => createAction(ISUPPLY_DASHBOARD_CLOSE_WORK.SUCCESS, { res }),
  failure: () => createAction(ISUPPLY_DASHBOARD_CLOSE_WORK.FAILURE),
}

export {
  NEW_LOAD_DATA_DASHBORAD,
  loadDataDashborad,
  NEW_LOAD_DATA_DASHBORAD2,
  loadDataDashborad2,
  NEW_LOAD_DATA_DASHBORAD_INTERVAL,
  loadDataDashboradInterval,
  LOAD_ONGOING,
  loadOngoing,
  LOAD_SUCCESS,
  loadSuccess,
  LOAD_AVALIABLE,
  loadAvailable,
  LOAD_ALL,
  loadWorkALL,
  LOAD_AVALIABLE_FOR_EDIT,
  loadAvailableForEdit,
  UPDATE_DATA_TRICKET,
  updateDataTricket,
  LOAD_DATA_VEHICLE_FOR_VIEW,
  loadDataVehicleForView,
  reCalculatorAreaNotCovered,
  RECALCULATOR_AREA_NOT_COVERED,
  calculatorDeliveryOutsideTheArea,
  CALCULATOR_DELIVERRY_OUTSIDE_THE_AREA,
  createLogRequestChangeDestLocation,
  CREATE_LOG_REQUEST_CHANGE_DEST_LOCATION,
  isupplyDashboardCloseWork,
  ISUPPLY_DASHBOARD_CLOSE_WORK,
  REMOVE_LISTS,
  removeLists,
}
