import {
  LOAD_DRIVINGOVERLIMIT,
  LOAD_DRIVINGOVERLIMIT_PREVIEW,
  LOAD_VEHICLE_DRIVINGOVERLIMIT,
  LOAD_VEHICLE_TYPE_DRIVINGOVERLIMIT,
} from './actions'

const initialState = {
  drivingoverlimitkUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  drivingoverlimitLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DRIVINGOVERLIMIT.REQUEST:
    case LOAD_DRIVINGOVERLIMIT_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        drivingoverlimitLoading: loading,
      }
    case LOAD_DRIVINGOVERLIMIT.SUCCESS:
    case LOAD_DRIVINGOVERLIMIT_PREVIEW.SUCCESS:
      return {
        ...state,
        drivingoverlimitkUrl: action.payload.data.data,
        drivingoverlimitLoading: false,
      }
    case LOAD_VEHICLE_DRIVINGOVERLIMIT.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        drivingoverlimitLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_DRIVINGOVERLIMIT.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        drivingoverlimitLoading: false,
      }
    case LOAD_VEHICLE_TYPE_DRIVINGOVERLIMIT.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
