import { createTypes, createAction } from 'lib/action'

const LOAD_SEPARATEVEHICLESTATUS = createTypes('separatevehiclestatus', 'load')
const LOAD_SEPARATEVEHICLESTATUS_PREVIEW = createTypes(
  'separatevehiclestatuspreview',
  'load',
)
const LOAD_VEHICLE_SEPARATEVEHICLESTATUS = createTypes(
  'vehicle_separatevehiclestatus',
  'load',
)
const LOAD_VEHICLE_TYPE_SEPARATEVEHICLESTATUS = createTypes(
  'vehicletype_separatevehiclestatus',
  'load',
)

const loadSeparateVehicleStatus = {
  request: (data, loading) =>
    createAction(LOAD_SEPARATEVEHICLESTATUS.REQUEST, { data, loading }),
  success: data => createAction(LOAD_SEPARATEVEHICLESTATUS.SUCCESS, { data }),
  failure: () => createAction(LOAD_SEPARATEVEHICLESTATUS.FAILURE),
}

const loadSeparateVehicleStatuspreview = {
  request: (data, loading) =>
    createAction(LOAD_SEPARATEVEHICLESTATUS_PREVIEW.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_SEPARATEVEHICLESTATUS_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_SEPARATEVEHICLESTATUS_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_SEPARATEVEHICLESTATUS.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_SEPARATEVEHICLESTATUS.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_SEPARATEVEHICLESTATUS.FAILURE),
}

const loadVehicleType = {
  request: () =>
    createAction(LOAD_VEHICLE_TYPE_SEPARATEVEHICLESTATUS.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_SEPARATEVEHICLESTATUS.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_SEPARATEVEHICLESTATUS.FAILURE),
}

export {
  loadSeparateVehicleStatus,
  LOAD_SEPARATEVEHICLESTATUS,
  loadSeparateVehicleStatuspreview,
  LOAD_SEPARATEVEHICLESTATUS_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_SEPARATEVEHICLESTATUS,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_SEPARATEVEHICLESTATUS,
}
