import { createTypes, createAction } from 'lib/action'
const LOAD_ISUPPLYWEIGHTHISTORY_REPORT = createTypes(
  'isupplyweighthistoryreport',
  'load',
)
const EXPORT_ISUPPLYWEIGHTHISTOR_REPORT = createTypes(
  'exportweighthistoryreport',
  'load',
)
const LOADDATABYDNNUMBER_ISUPPLYWEIGHTHISTOR_REPORT = createTypes(
  'loadDataByDONumberweighthistoryreport',
  'load',
)
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadWeightHistoryReport = {
  request: (data, loading) =>
    createAction(LOAD_ISUPPLYWEIGHTHISTORY_REPORT.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_ISUPPLYWEIGHTHISTORY_REPORT.SUCCESS, { lists }),
  failure: () => createAction(LOAD_ISUPPLYWEIGHTHISTORY_REPORT.FAITLURE),
}

const exportWeightHistoryReport = {
  request: (data, loading) =>
    createAction(EXPORT_ISUPPLYWEIGHTHISTOR_REPORT.REQUEST, { data, loading }),
  success: lists =>
    createAction(EXPORT_ISUPPLYWEIGHTHISTOR_REPORT.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_ISUPPLYWEIGHTHISTOR_REPORT.FAITLURE),
}

const loadDataByDONumber = {
  request: (data, loading) =>
    createAction(LOADDATABYDNNUMBER_ISUPPLYWEIGHTHISTOR_REPORT.REQUEST, {
      data,
      loading,
    }),
  success: lists =>
    createAction(LOADDATABYDNNUMBER_ISUPPLYWEIGHTHISTOR_REPORT.SUCCESS, {
      lists,
    }),
  failure: () =>
    createAction(LOADDATABYDNNUMBER_ISUPPLYWEIGHTHISTOR_REPORT.FAILURE),
}

export {
  LOADDATABYDNNUMBER_ISUPPLYWEIGHTHISTOR_REPORT,
  loadDataByDONumber,
  EXPORT_ISUPPLYWEIGHTHISTOR_REPORT,
  exportWeightHistoryReport,
  LOAD_ISUPPLYWEIGHTHISTORY_REPORT,
  loadWeightHistoryReport,
  REMOVE_LISTS,
  removeLists,
}
