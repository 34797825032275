import {
  LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION,
  LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_LOCATION,
  LOAD_MANAGEMENT_VEHICLE_VISIBILITY_SEARCH,
} from './actions'

const initialState = {
  vehicle: [],
  location: [],
  vehicleNumber: [],
  total: 1,
  loading: false,
  loadingVehicle: false,
  loadingSearch: false,
  status: '',
  msg: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION.REQUEST: {
      const { loading } = action.payload.data
      return {
        ...state,
        loadingVehicle: 'undefined',
        vehicleNumber: [],
      }
    }

    case LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION.SUCCESS:
      return {
        ...state,
        vehicle: action.payload.data.data.vehicle,
        loadingVehicle: false,
        total: action.payload.data.data.total,
        status: action.payload.data.status,
        msg: action.payload.data.msg,
      }

    case LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_LOCATION.REQUEST: {
      const { loading } = action.payload.data
      return {
        ...state,
        loading: 'undefined',
      }
    }

    case LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_LOCATION.SUCCESS:
      return {
        ...state,
        location: action.payload.data.data,
        loading: false,
        status: action.payload.data.status,
        msg: action.payload.data.msg,
      }

    case LOAD_MANAGEMENT_VEHICLE_VISIBILITY_SEARCH.REQUEST: {
      const { loading } = action.payload.data
      return {
        ...state,
        loadingSearch: 'undefined',
      }
    }

    case LOAD_MANAGEMENT_VEHICLE_VISIBILITY_SEARCH.SUCCESS:
      return {
        ...state,
        vehicleNumber: action.payload.data.data,
        loadingSearch: false,
        status: action.payload.data.status,
        msg: action.payload.data.msg,
      }

    default:
      return state
  }
}
