import { createTypes, createAction } from "lib/action";
const LOAD_DATA_FOR_VEHICLE_STATUS_DAILY_JOBE = createTypes("loaddataforvrhiclestatusdailyjobe", "load");
const LOADDATA_VEHICLE_STATUS_FOR_DETAIL = createTypes("loaddatavehiclestatusfordetail", "load");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadVehiclestatusdailyjob = {
    request: (data, loading) =>
        createAction(LOAD_DATA_FOR_VEHICLE_STATUS_DAILY_JOBE.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(LOAD_DATA_FOR_VEHICLE_STATUS_DAILY_JOBE.SUCCESS, {lists}),
    failure: () => createAction(LOAD_DATA_FOR_VEHICLE_STATUS_DAILY_JOBE.FAITLURE)  
};

const loadVehicleStatusForDetail = {
    request: (data, loading) =>
        createAction(LOADDATA_VEHICLE_STATUS_FOR_DETAIL.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(LOADDATA_VEHICLE_STATUS_FOR_DETAIL.SUCCESS, {lists}),
    failure: () => createAction(LOADDATA_VEHICLE_STATUS_FOR_DETAIL.FAILURE)
};

export {
    LOAD_DATA_FOR_VEHICLE_STATUS_DAILY_JOBE, loadVehiclestatusdailyjob,
    LOADDATA_VEHICLE_STATUS_FOR_DETAIL,loadVehicleStatusForDetail,
    REMOVE_LISTS, removeLists
};
