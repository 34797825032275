import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { debounce } from 'debounce'
import { checkPermissions } from '../../../../lib/helper'
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  getVisibleNodeCount,
  getFlatDataFromTree,
  getDepth,
  getTreeFromFlatData,
  find,
} from 'fixed-react-sortable-tree'
import {
  Button,
  Form,
  Divider,
  Row,
  Col,
  Input,
  Transfer,
  Tag,
  Table,
  Tabs,
  Spin,
  Modal,
  Popconfirm,
  message,
  Icon,
  Card,
} from 'antd'
import 'fixed-react-sortable-tree/style.css'

import LangContext, { i18n } from 'modules/shared/context/langContext'
import {
  GeneralStyledContent,
  StyledSearchForm,
} from '../../../../styled/common-styled'
import axios from 'axios'
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import FromLocationTransfer from './FromLocationTransfer'

import {
  loadDatavisibilitySelectedVehicleGroup,
  loadDatavisibilitySelectedVehicle,
  loadDatavisibilitySelectedLocationGroup,
  loadDatavisibilitySelectedLocation,
  loadDatavisibilitySelectedUser,
  loadDatavisibilitySelectedMaterial,
  changeSelectedVehicleGroup,
  changeSelectedVehicle,
  changeSelectedLocationGroup,
  changeSelectedLocation,
  changeSelectedUser,
  changeSelectedMaterial,
} from '../actions'
import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import Breadcrumb from './../../../shared/components/Breadcrumb'
import { TOURSTEPS } from './../constants/datavisibility'
import difference from 'lodash/difference'

const { TabPane } = Tabs

const getNodeKey = ({ node: object, treeIndex: number }) => {
  return number
}

const listStyle = {
  height: 300,
  marginLeft: '30px',
}

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns

      const rowSelection = {
        getCheckboxProps: item => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key)
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys)
          onItemSelectAll(diffKeys, selected)
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected)
        },
        selectedRowKeys: listSelectedKeys,
      }

      return (
        <Table
          scroll={{ y: 340 }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          pagination={{ defaultPageSize: 100 }}
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return
              onItemSelect(key, !listSelectedKeys.includes(key))
            },
          })}
        />
      )
    }}
  </Transfer>
)

class Index extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
    this.Leftbtn2 = null
    this.Rightbtn2 = null
    this.Leftbtn3 = null
    this.Rightbtn3 = null
    this.Leftbtn4 = null
    this.Rightbtn4 = null
    this.Leftbtn5 = null
    this.Rightbtn5 = null
    this.Leftbtn6 = null
    this.Rightbtn6 = null
    this.state = {
      searchNodeQ: '',
      loading: false,
      allVehicleGroup: [],
      allVehicle: [],
      allLocationGroup: [],
      allLocation: [],
      allLocationTotal: 0,
      allUser: [],
      allMaterial: [],
      treeData: [],
      searchString: null,
      originalTreeData: [],
      removedTreeData: [],
      name: '',
      selectedRowInfo: null,

      selectedVehicleGroup: [],
      updatedVehicleGroup: [],
      removedSelectedVehicleGroup: [],

      selectedVehicle: [],
      updatedVehicle: [],
      removedSelectedVehicle: [],

      selectedLocationGroup: [],
      updatedLocationGroup: [],
      removedSelectedLocationGroup: [],

      selectedLocation: [],
      updatedLocation: [],
      removedSelectedLocation: [],

      selectedMaterial: [],
      updatedMaterial: [],
      removedSelectedMaterial: [],

      Location_currentPageLeft: 1,
      Location_pageLeft: 1,
      Location_pageSizeLeft: 200,
      Location_totalLeft: 0,
      Location_keywordLeft: '',
      Location_keywordRight: '',
      Location_loadingLeft: false,
      Location_loadingRight: false,
      Location_is_selected: [],
      back_up_id_Location_is_selected: [],
      default_Location_is_selected: [],
      Location_search_first: true,

      selectedUser: [],
      updatedUser: [],
      removedSelectedUser: [],

      visible: false,
      isTourOpen: false,
      isShowingMore: false,
    }

    this.leftTableColumns = [
      {
        dataIndex: 'title',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemLeft}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Left',
      },
    ]

    this.rightTableColumns = [
      {
        dataIndex: 'title',
        title: (
          <div className="regular-bold-14">
            <LangContext.Consumer>
              {i18n => i18n.i.itemSelected}
            </LangContext.Consumer>
          </div>
        ),
        posiction: 'Right',
      },
    ]
  }

  disableBody = target => disableBodyScroll(target)
  enableBody = target => enableBodyScroll(target)

  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.isShowingMore,
    }))
  }

  closeTour = () => {
    this.setState({ isTourOpen: false })
  }

  openTour = () => {
    this.setState({ isTourOpen: true })
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
      name: '',
      selectedRowInfo: null,
    })
  }

  onNodeNameChange = e => {
    this.setState({ name: e.target.value })
  }

  treeSelected = rowInfo => {
    // for vehicle group
    let { selectedVehicleGroup } = this.props.datavisibilityMasterDataState
    let filterSelectedVehiclegroup = selectedVehicleGroup.filter(
      ele => ele.data_visibility_node_id == rowInfo.node.key,
    )

    // for vehicle
    let { selectedVehicle } = this.props.datavisibilityMasterDataState
    let filterSelectedVehicle = selectedVehicle.filter(
      ele => ele.data_visibility_node_id == rowInfo.node.key,
    )

    // for location group
    let { selectedLocationGroup } = this.props.datavisibilityMasterDataState
    let filterSelectedLocationGroup = selectedLocationGroup.filter(
      ele => ele.data_visibility_node_id == rowInfo.node.key,
    )

    // for location
    let { selectedLocation } = this.props.datavisibilityMasterDataState // Location ทั้งหมด ที่ บริษัทนี้มี
    let filterSelectedLocation = selectedLocation.filter(
      ele => ele.data_visibility_node_id == rowInfo.node.key,
    )

    // for user
    let { selectedUser } = this.props.datavisibilityMasterDataState
    let filterSelectedUser = selectedUser.filter(
      ele => ele.data_visibility_node_id == rowInfo.node.key,
    )

    let { selectedMaterial } = this.props.datavisibilityMasterDataState
    let filterSelectedMaterial = selectedMaterial.filter(
      ele => ele.data_visibility_node_id == rowInfo.node.key,
    )

    this.setState({
      selectedRowInfo: rowInfo,
      selectedVehicleGroup: filterSelectedVehiclegroup.map(
        ele => ele.vehicle_group_id,
      ),
      selectedVehicle: filterSelectedVehicle.map(ele => ele.vehicle_id),
      selectedLocationGroup: filterSelectedLocationGroup.map(
        ele => ele.location_group_id,
      ),
      selectedLocation: filterSelectedLocation.map(ele => ele.location_id),
      selectedUser: filterSelectedUser.map(ele => ele.user_id),
      selectedMaterial: filterSelectedMaterial.map(ele => ele.material_id),
    })
  }

  treeAdd = rowInfo => {
    this.setState({ selectedRowInfo: rowInfo })
    this.showModal()
  }

  treeRemove = rowInfo => {
    let newTree = removeNodeAtPath({
      treeData: this.state.treeData,
      originalTreeData: this.state.treeData,
      path: rowInfo.path,
      getNodeKey,
      ignoreCollapsed: true,
    })
    this.setState({
      treeData: newTree,
      originalTreeData: newTree,
      name: '',
      selectedRowInfo: null,
      removedTreeData: [...this.state.removedTreeData, rowInfo.node],
    })
  }

  handleTransferChange = (
    targetKeys,
    direction,
    moveKeys,
    objKeyName,
    propsFnName,
    selectedKeyName,
    stateRemoveName,
    stateUpdateName,
  ) => {
    let { selectedRowInfo } = this.state

    let filterArr = this.props.datavisibilityMasterDataState[
      selectedKeyName
    ].filter(
      ele => ele.data_visibility_node_id != this.state.selectedRowInfo.node.key,
    )

    let newArr = targetKeys.map(ele => ({
      id: `new:${ele}-${this.state.selectedRowInfo.node.key}`,
      [objKeyName]: ele,
      data_visibility_node_id: this.state.selectedRowInfo.node.key,
    }))
    this.props[propsFnName]([...newArr, ...filterArr])
    this.setState({
      [selectedKeyName]: targetKeys,
    })

    // remove from selected in tranfer
    if (direction == 'left') {
      let removeList = moveKeys.map(ele => ({
        node_id: selectedRowInfo.node.key,
        [objKeyName]: ele,
      }))

      let updateList = this.state[stateUpdateName].filter(ele => {
        if (ele.node_id != selectedRowInfo.node.key) {
          if (moveKeys.includes(ele[objKeyName])) return false
          else return true
        } else {
          return true
        }
      })

      this.setState({
        [stateRemoveName]: [...this.state[stateRemoveName], ...removeList],
        [stateUpdateName]: [...updateList],
      })
    } // select from list in tranfer
    else {
      let removeList = this.state[stateRemoveName].filter(ele => {
        if (ele.node_id == selectedRowInfo.node.key) {
          if (moveKeys.includes(ele[objKeyName])) return false
          else return true
        } else {
          return true
        }
      })

      let updateList = moveKeys.map(ele => ({
        node_id: selectedRowInfo.node.key,
        [objKeyName]: ele,
      }))

      this.setState({
        [stateRemoveName]: [...removeList],
        [stateUpdateName]: [...this.state[stateUpdateName], ...updateList],
      })
    }
  }

  handleTableTransferChange = (
    targetKeys,
    direction,
    moveKeys,
    objKeyName,
    propsFnName,
    selectedKeyName,
    stateRemoveName,
    stateUpdateName,
  ) => {
    this.setState({ Location_loadingLeft: true })

    let data_location_is_selected = []
    let final_value = []
    let { selectedRowInfo } = this.state

    if (targetKeys.length == 0) {
      // uselect ทั้งหมด
      this.state.Location_is_selected.splice(
        0,
        this.state.Location_is_selected.length,
      )
      this.state[selectedKeyName].splice(0, this.state[selectedKeyName].length)
    } else {
      targetKeys.map(select => {
        let value = this.state.allLocation.find(ele => {
          return ele.key == select
        })

        if (value == '') {
        } else if (typeof value == 'undefined') {
        } else {
          data_location_is_selected.push(value)
        }
      })
    }

    let new_is_select_location = this.state.default_Location_is_selected.concat(
      data_location_is_selected,
    )
    this.state[selectedKeyName].splice(0, this.state[selectedKeyName].length)

    targetKeys.map(select => {
      let dat = new_is_select_location.find(ele1 => {
        return ele1.key == select
      })

      if (dat == '') {
      } else if (typeof dat == 'undefined') {
      } else {
        final_value.push(dat)
        this.state[selectedKeyName].push(dat.key)
      }
    })

    this.state.Location_is_selected.splice(
      0,
      this.state.Location_is_selected.length,
    )
    this.state.default_Location_is_selected.splice(
      0,
      this.state.default_Location_is_selected.length,
    )
    this.setState({
      Location_is_selected: final_value,
      default_Location_is_selected: final_value,
    })

    // remove from selected in tranfer
    if (direction == 'left') {
      let removeList = moveKeys.map(ele => ({
        node_id: selectedRowInfo.node.key,
        [objKeyName]: ele,
      }))

      let updateList = this.state[stateUpdateName].filter(ele => {
        if (ele.node_id != selectedRowInfo.node.key) {
          if (moveKeys.includes(ele[objKeyName])) return false
          else return true
        } else {
          return true
        }
      })

      this.setState(
        {
          [stateRemoveName]: [...this.state[stateRemoveName], ...removeList],
          [stateUpdateName]: [...updateList],
        },
        () => {
          this.loadDetailDataForTransfer('getlocation', 'allLocation')
        },
      )
    } else {
      // select from list in tranfer
      let removeList = this.state[stateRemoveName].filter(ele => {
        if (ele.node_id == selectedRowInfo.node.key) {
          if (moveKeys.includes(ele[objKeyName])) return false
          else return true
        } else {
          return true
        }
      })

      let updateList = moveKeys.map(ele => ({
        node_id: selectedRowInfo.node.key,
        [objKeyName]: ele,
      }))

      this.setState(
        {
          [stateRemoveName]: [...removeList],
          [stateUpdateName]: [...this.state[stateUpdateName], ...updateList],
        },
        () => {
          this.loadDetailDataForTransfer('getlocation', 'allLocation')
        },
      )
    }
  }

  handleSubmit = () => {
    let self = this
    let node = getFlatDataFromTree({
      treeData: this.state.treeData,
      getNodeKey,
      ignoreCollapsed: false,
    })

    self.setState({ loading: true })
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/update`,
        {
          company_id: this.props.auth.profile.company_id,
          removedTreeData: this.state.removedTreeData,
          treeData: node,
          removedSelectedVehicleGroup: this.state.removedSelectedVehicleGroup,
          updatedVehicleGroup: this.state.updatedVehicleGroup,
          removedSelectedVehicle: this.state.removedSelectedVehicle,
          updatedVehicle: this.state.updatedVehicle,
          removedSelectedLocationGroup: this.state.removedSelectedLocationGroup,
          updatedLocationGroup: this.state.updatedLocationGroup,
          removedSelectedLocation: this.state.removedSelectedLocation,
          updatedLocation: this.state.updatedLocation,
          removedSelectedUser: this.state.removedSelectedUser,
          updatedUser: this.state.updatedUser,
          removedSelectedMaterial: this.state.removedSelectedMaterial,
          updatedMaterial: this.state.updatedMaterial,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function(response) {
        self.setState({
          removedTreeData: [],
          selectedRowInfo: null,
          selectedVehicleGroup: [],
          selectedVehicle: [],
          selectedLocationGroup: [],
          selectedLocation: [],
          selectedUser: [],
          selectedMaterial: [],

          updatedVehicleGroup: [],
          removedSelectedVehicleGroup: [],

          updatedVehicle: [],
          removedSelectedVehicle: [],

          updatedLocationGroup: [],
          removedSelectedLocationGroup: [],

          updatedLocation: [],
          removedSelectedLocation: [],

          updatedUser: [],
          removedSelectedUser: [],

          updatedMaterial: [],
          removedSelectedMaterial: [],
        })

        self.loadTreeData(false)
        self.loadNodeSelectedData()
        message.success('Update success')
      })
      .catch(function(error) {
        message.error('Update fail')
        console.log(error)
      })
  }

  loadTreeData = setLoading => {
    let self = this
    setLoading && self.setState({ loading: setLoading })
    return axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/gettree/${this.props.auth.profile.company_id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function(response) {
        let convertedData = getTreeFromFlatData({
          flatData: response.data,
          getKey: node => node.key,
          getParentKey: node => node.parentId,
          rootKey: null,
        })

        self.setState({
          treeData: convertedData,
          originalTreeData: convertedData,
          loading: false,
        })
        return response
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  loadDetailData = (path, stateKeyName) => {
    let self = this
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/${path}/${this.props.auth.profile.company_id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function(response) {
        self.setState({ [stateKeyName]: response.data })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  loadDetailDataForTransfer = (path, stateKeyName) => {
    let self = this
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/for-transfer/${path}`,
        {
          company_id: this.props.auth.profile.company_id,
          page: this.state.Location_pageLeft,
          pageSize: this.state.Location_pageSizeLeft,
          Location_is_select: this.state.selectedLocation,
          Location_keywordLeft: this.state.Location_keywordLeft,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function(response) {
        self.setState({
          [stateKeyName]: response.data.data,
          allLocationTotal:
            response.data.total - 1 <= 0
              ? 1
              : response.data.total <= 0
              ? 1
              : response.data.total,
          Location_loadingLeft: false,
        })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  loadNodeAllData = () => {
    let self = this
    new Promise(function(resolve, reject) {
      resolve(self.loadTreeData(true))
    })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(self.loadDetailData('getvehiclegroup', 'allVehicleGroup'))
        }).then(function(result) {
          return self.props.loadDatavisibilitySelectedVehicleGroup({
            id: self.props.auth.profile.company_id,
            accessToken: self.props.auth.accessToken,
          })
        })
      })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(self.loadDetailData('getvehicle', 'allVehicle'))
        }).then(function(result) {
          return self.props.loadDatavisibilitySelectedVehicle({
            id: self.props.auth.profile.company_id,
            accessToken: self.props.auth.accessToken,
          })
        })
      })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(self.loadDetailData('getlocationgroup', 'allLocationGroup'))
        }).then(function(result) {
          return self.props.loadDatavisibilitySelectedLocationGroup({
            id: self.props.auth.profile.company_id,
            accessToken: self.props.auth.accessToken,
          })
        })
      })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(self.loadDetailData('getlocation', 'allLocation'))
        }).then(function(result) {
          return self.props.loadDatavisibilitySelectedLocation({
            id: self.props.auth.profile.company_id,
            accessToken: self.props.auth.accessToken,
          })
        })
      })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(self.loadDetailData('getuser', 'allUser'))
        }).then(function(result) {
          return self.props.loadDatavisibilitySelectedUser({
            id: self.props.auth.profile.company_id,
            accessToken: self.props.auth.accessToken,
          })
        })
      })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(self.loadDetailData('getmaterial', 'allMaterial'))
        }).then(function(result) {
          return self.props.loadDatavisibilitySelectedMaterial({
            id: self.props.auth.profile.company_id,
            accessToken: self.props.auth.accessToken,
          })
        })
      })
  }

  loadNodeSelectedData = () => {
    let self = this
    new Promise(function(resolve, reject) {
      resolve(self.loadTreeData(true))
    })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(
            self.props.loadDatavisibilitySelectedVehicleGroup({
              id: self.props.auth.profile.company_id,
              accessToken: self.props.auth.accessToken,
            }),
          )
        })
      })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(
            self.props.loadDatavisibilitySelectedVehicle({
              id: self.props.auth.profile.company_id,
              accessToken: self.props.auth.accessToken,
            }),
          )
        })
      })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(
            self.props.loadDatavisibilitySelectedLocationGroup({
              id: self.props.auth.profile.company_id,
              accessToken: self.props.auth.accessToken,
            }),
          )
        })
      })

      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(
            self.props.loadDatavisibilitySelectedLocation({
              id: self.props.auth.profile.company_id,
              accessToken: self.props.auth.accessToken,
            }),
          )
        })
      })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(
            self.props.loadDatavisibilitySelectedUser({
              id: self.props.auth.profile.company_id,
              accessToken: self.props.auth.accessToken,
            }),
          )
        })
      })
      .then(function(result) {
        return new Promise(function(resolve, reject) {
          resolve(
            self.props.loadDatavisibilitySelectedMaterial({
              id: self.props.auth.profile.company_id,
              accessToken: self.props.auth.accessToken,
            }),
          )
        })
      })
  }

  filterOption = (inputValue, option) =>
    option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1

  componentDidMount() {
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
    this.loadNodeAllData()
  }

  componentDidUpdate() {
    if (document.getElementById('finding-btn2') !== null) {
      let btn2 = document
        .getElementById('finding-btn2')
        .querySelectorAll('button')
      this.Leftbtn2 = btn2[0]
      this.Rightbtn2 = btn2[1]
    }

    if (document.getElementById('finding-btn3') !== null) {
      let btn3 = document
        .getElementById('finding-btn3')
        .querySelectorAll('button')
      this.Leftbtn3 = btn3[0]
      this.Rightbtn3 = btn3[1]
    }

    if (document.getElementById('finding-btn4') !== null) {
      let btn4 = document
        .getElementById('finding-btn4')
        .querySelectorAll('button')
      this.Leftbtn4 = btn4[0]
      this.Rightbtn4 = btn4[1]
    }

    if (document.getElementById('finding-btn5') !== null) {
      let btn5 = document
        .getElementById('finding-btn5')
        .querySelectorAll('button')
      this.Leftbtn5 = btn5[0]
      this.Rightbtn5 = btn5[1]
    }

    if (document.getElementById('finding-btn6') !== null) {
      let btn6 = document
        .getElementById('finding-btn6')
        .querySelectorAll('button')
      this.Leftbtn6 = btn6[0]
      this.Rightbtn6 = btn6[1]
    }
  }
  // PageChange
  handlePageChange = (page, pageSize) => {
    this.setState({
      Location_pageLeft: page,
      Location_currentPageLeft: page,
      Location_loadingLeft: true,
    })

    let self = this

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/for-transfer/getlocation`,
        {
          company_id: this.props.auth.profile.company_id,
          page: page,
          pageSize: this.state.Location_pageSizeLeft,
          Location_is_select: this.state.selectedLocation,
          Location_keywordLeft: this.state.Location_keywordLeft,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )
      .then(function(response) {
        self.setState({
          ['allLocation']: response.data.data,
          allLocationTotal:
            response.data.total - 1 <= 0
              ? 1
              : response.data.total <= 0
              ? 1
              : response.data.total,
          Location_loadingLeft: false,
        })
      })
      .catch(function(error) {
        console.log(error)
      })
  }
  // End PageChange

  // Search
  handleSearch = (side, value) => {
    if (side === 'left') {
      this.handleSearchDataLeft(value)
    }

    if (side === 'right') {
      this.handleSearchDataRight(value)

      if (
        this.state.back_up_id_Location_is_selected.length == 0 &&
        value != ''
      ) {
        let location_is_selected = this.state.selectedLocation
        this.setState({
          back_up_id_Location_is_selected: location_is_selected,
        })
      }

      if (
        this.state.back_up_id_Location_is_selected.length > 0 &&
        value == ''
      ) {
        let back_up_id_Location_is_selected = this.state
          .back_up_id_Location_is_selected
        this.setState({
          selectedLocation: back_up_id_Location_is_selected,
        })
      }
    }
  }

  handleSearchDataLeft = debounce(async value => {
    this.setState(
      { Location_keywordLeft: value, Location_loadingLeft: true },
      this.getDataSearchDataLeft,
    )
  })

  getDataSearchDataLeft = async () => {
    try {
      this.state.allLocation.splice(0, this.state.allLocation.length)
      const res = await axios.post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/datavisibility/for-transfer/getlocation`,
        {
          company_id: this.props.auth.profile.company_id,
          page: this.state.Location_pageLeft,
          pageSize: this.state.Location_pageSizeLeft,
          Location_is_select: this.state.selectedLocation,
          Location_keywordLeft: this.state.Location_keywordLeft,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        },
      )

      this.setState({
        ['allLocation']: res.data.data,
        allLocationTotal:
          res.data.total - 1 <= 0
            ? 1
            : res.data.total <= 0
            ? 1
            : res.data.total,
        Location_loadingLeft: false,
      })
    } catch (error) {
      console.error(error)
    }
  }

  handleSearchDataRight = debounce(async value => {
    this.setState(
      { Location_keywordRight: value, Location_loadingRight: true },
      this.getDataSearchDataRight,
    )
  }, 750)

  getDataSearchDataRight = async () => {
    let data_search = []

    if (this.state.Location_is_selected.length > 0) {
      if (
        this.state.Location_keywordRight != '' &&
        this.state.Location_search_first
      ) {
        this.state.Location_is_selected.map(
          data => {
            if (
              data.title
                .toLowerCase()
                .indexOf(this.state.Location_keywordRight.toLowerCase()) >= 0
            ) {
              data_search.push(data)
            }
          },
          this.setState({
            Location_loadingRight: false,
            Location_is_selected: data_search,
            Location_search_first: false,
          }),
        )
      } else {
        this.state.back_up_id_Location_is_selected.map(
          data => {
            if (
              data.title
                .toLowerCase()
                .indexOf(this.state.Location_keywordRight.toLowerCase()) >= 0
            ) {
              data_search.push(data)
            }
          },
          () => {
            this.setState({
              Location_loadingRight: false,
              Location_is_selected: data_search,
              Location_search_first: false,
            })
          },
        )
      }
    }
  }
  // End Search
  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleSelectChange2 = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn2.click()
        } else {
          this.Rightbtn2.click()
        }
      },
    )
  }

  handleSelectChange3 = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn3.click()
        } else {
          this.Rightbtn3.click()
        }
      },
    )
  }
  handleSelectChange4 = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn4.click()
        } else {
          this.Rightbtn4.click()
        }
      },
    )
  }
  handleSelectChange5 = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn5.click()
        } else {
          this.Rightbtn5.click()
        }
      },
    )
  }

  handleSelectChange6 = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn6.click()
        } else {
          this.Rightbtn6.click()
        }
      },
    )
  }

  customSearchMethod = ({ node, searchQuery }) => {
    return (
      searchQuery &&
      node.title
        .trim()
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1
    )
  }

  customSearch = ({ search }) => {
    const tree = this.state.originalTreeData.filter(ot =>
      ot.title.includes(search),
    )
  }

  filter = (v, match) => {
    if (v.hasOwnProperty('children')) {
      if (match.filter(m => m.id === v.id).length) return true
      return v.children.filter(c => this.filter(c, match)).length ? true : false
    } else {
      return match.filter(m => m.id === v.id).length ? true : false
    }
  }

  searchFinishCallback = match => {
    match = match.map((v, key) => v.node)

    match = getFlatDataFromTree({
      treeData: match,
      getNodeKey,
      ignoreCollapsed: true,
    })

    match = match.map(m => m.node)

    let filter = this.state.treeData.filter(v => this.filter(v, match))

    return filter
  }

  render() {
    let companyLength = this.props.auth.profile.company_name.length

    return (
      <AuthorizeComponent {...this.props} matching_name="dataVisibility">
        <GeneralStyledContent className="index-step">
          <Spin spinning={this.state.loading}>
            <Tour
              steps={TOURSTEPS}
              isOpen={this.state.isTourOpen}
              onRequestClose={this.closeTour}
              className="helper"
              rounded={5}
            />
            <Row gutter={24} type="flex">
              <Col span={23}>
                {/* <Breadcrumb
                  match={this.props.match}
                  style={{ margin: '0px 0px 14px 10px' }}
                /> */}
                <div className="header__text">
                  <LangContext.Consumer>
                    {i18n => i18n.d.dataVisibility}
                  </LangContext.Consumer>
                </div>
              </Col>
              <Col span={1}>
                <Button
                  type="dashed"
                  shape="circle"
                  icon="info"
                  size="small"
                  onClick={this.openTour}
                />
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="tree-section-step" span={11}>
                <div style={{ height: `${window.innerHeight - 300}px` }}>
                  <Row style={{ margin: '4px 0px 10px' }} gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form
                        labelCol={{ xs: { span: 24 }, sm: { span: 5 } }}
                        wrapperCol={{ xs: { span: 24 }, sm: { span: 19 } }}
                      >
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.f.filterNode}
                            </LangContext.Consumer>
                          }
                        >
                          <Input
                            value={this.state.searchString}
                            onChange={e => {
                              let findRes = find({
                                treeData: this.state.originalTreeData,
                                getNodeKey,
                                searchQuery: e.target.value
                                  .trim()
                                  .toLowerCase(),
                                expandAllMatchPaths: true,
                                searchMethod: ({ node, searchQuery }) => {
                                  return node.title
                                    .trim()
                                    .toLowerCase()
                                    .includes(searchQuery.trim().toLowerCase())
                                },
                                searchFocusOffset: 0,
                                expandAllMatchPaths: false,
                                expandFocusMatchPaths: true,
                              })

                              this.searchFinishCallback(findRes.matches)
                              let filter = this.searchFinishCallback(
                                findRes.matches,
                              )

                              if (e.target.value.trim() === '') {
                                this.setState({
                                  treeData: this.state.originalTreeData,
                                  name: '',
                                  selectedRowInfo: null,
                                })
                              } else {
                                this.setState({
                                  treeData: filter,
                                  name: '',
                                  selectedRowInfo: null,
                                })
                              }

                              this.setState({
                                searchString: e.target.value,
                              })
                            }}
                          />
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                  <Col span={24}>
                    <Row>
                      <Row
                        style={{
                          padding: ' 8px 5px 0 10px',
                          width: ' 50%',
                          height: companyLength > 15 ? '62px' : '42px',

                          zIndex: '1',
                        }}
                      >
                        <Card style={{ width: 400 }}>
                          <Row style={{ opacity: '1' }}>
                            <Col span={22}>
                              {this.props.auth.profile.company_name}
                            </Col>

                            <Col span={2}>
                              <Button
                                className="root-add-step"
                                onClick={e => {
                                  this.closeTour()
                                  this.treeAdd({
                                    treeIndex: null,
                                    parentNode: null,
                                    path: null,
                                    node: {
                                      id: null,
                                      key: null,
                                      parentId: null,
                                      title: '',
                                      children: [],
                                    },
                                  })
                                }}
                                size="small"
                                icon="plus"
                              ></Button>
                            </Col>
                          </Row>
                        </Card>
                      </Row>
                    </Row>
                  </Col>
                  {this.state.treeData.length > 0 && (
                    <Col
                      style={{
                        position: 'relative',
                        borderLeft: '0.7px solid black',
                        height: '80px',
                        left: '22px',
                        top: '22px',
                        zIndex: '0',
                      }}
                    ></Col>
                  )}
                  <SortableTree
                    maxDepth={9}
                    treeData={this.state.treeData}
                    onChange={treeData => {
                      this.setState({ treeData })
                    }}
                    onlyExpandSearchedNodes={true}
                    searchMethod={this.customSearchMethod}
                    searchQuery={this.state.searchString}
                    expandFocusMatchPaths={true}
                    expandAllMatchPaths={true}
                    generateNodeProps={rowInfo => ({
                      style:
                        this.state.selectedRowInfo != null
                          ? this.state.selectedRowInfo.node.key ==
                            rowInfo.node.key
                            ? {
                                color: '#1890ff',
                              }
                            : {}
                          : {},
                      buttons:
                        rowInfo.node.key != this.props.auth.profile.company_id
                          ? [
                              <Popconfirm
                                title="Are you sure delete this?"
                                onConfirm={e => this.treeRemove(rowInfo)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button
                                  className="remove-step"
                                  type="default"
                                  size="small"
                                  icon="close"
                                  style={{
                                    marginRight: '5px',
                                  }}
                                ></Button>
                              </Popconfirm>,
                              rowInfo.path.length < 9 ? (
                                <Button
                                  className="add-step"
                                  type="default"
                                  size="small"
                                  icon="plus"
                                  style={{
                                    marginRight: '5px',
                                  }}
                                  onClick={() => {
                                    this.treeAdd(rowInfo)
                                  }}
                                ></Button>
                              ) : (
                                <div />
                              ),
                              <Button
                                className="select-step"
                                type="default"
                                size="small"
                                icon="double-right"
                                style={{
                                  marginRight: '5px',
                                }}
                                onClick={() => {
                                  this.treeSelected(rowInfo)
                                }}
                              ></Button>,
                            ]
                          : [],
                    })}
                  />
                </div>
              </Col>
              <Divider
                style={{ height: `${window.innerHeight - 150}px` }}
                type="vertical"
              />
              <Col className="detail-section-step" span={11}>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form className="detail-name-step" layout="inline">
                      <Row gutter={24} style={{ margin: '10px 0px 12px 0px' }}>
                        <Col span={22}>
                          <Form.Item
                            label={
                              <LangContext.Consumer>
                                {i18n => i18n.n.nodeName}
                              </LangContext.Consumer>
                            }
                          >
                            {this.state.selectedRowInfo != null ? (
                              <h3>{this.state.selectedRowInfo.node.title}</h3>
                            ) : (
                              ''
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={2} />
                      </Row>
                    </Form>
                  </Col>
                </Row>

                <Tabs
                  defaultActiveKey="data-visibilit-tab-1"
                  className="select-detail-step"
                >
                  <TabPane
                    tab={
                      <div className="regular-bold-14">
                        <LangContext.Consumer>
                          {i18n => i18n.u.user}
                        </LangContext.Consumer>
                      </div>
                    }
                    key={'data-visibilit-tab-1'}
                    onChange={() => {
                      this.setState({
                        test: true,
                      })
                    }}
                  >
                    <Row gutter={24}>
                      <Divider orientation="left">
                        <small>
                          {' '}
                          <LangContext.Consumer>
                            {i18n => i18n.u.user}
                          </LangContext.Consumer>
                        </small>
                      </Divider>
                      <div id="finding-btn">
                        <TableTransfer
                          titles={[
                            <LangContext.Consumer>
                              {i18n => i18n.i.itemLeft}
                            </LangContext.Consumer>,
                            <LangContext.Consumer>
                              {i18n => i18n.i.itemSelected}
                            </LangContext.Consumer>,
                          ]}
                          disabled={this.state.selectedRowInfo == null}
                          dataSource={this.state.allUser}
                          showSearch
                          filterOption={this.filterOption}
                          targetKeys={this.state.selectedUser}
                          onChange={(targetKeys, direction, moveKeys) =>
                            this.handleTransferChange(
                              targetKeys,
                              direction,
                              moveKeys,
                              'user_id',
                              'changeSelectedUser',
                              'selectedUser',
                              'removedSelectedUser',
                              'updatedUser',
                            )
                          }
                          onSelectChange={this.handleSelectChange}
                          leftColumns={this.leftTableColumns}
                          rightColumns={this.rightTableColumns}
                        />
                        {/* <TableTransfer
                                                    dataSource={this.state.allVehicleGroup}
                                                    targetKeys={this.state.selectedVehicleGroup}
                                                    disabled={this.state.selectedRowInfo == null}
                                                    showSearch
                                                    onChange={(targetKeys, direction, moveKeys) =>
                                                        this.handleTransferChange(
                                                            targetKeys,
                                                            direction,
                                                            moveKeys,
                                                            'vehicle_group_id',
                                                            'changeSelectedVehicleGroup',
                                                            'selectedVehicleGroup',
                                                            'removedSelectedVehicleGroup',
                                                            'updatedVehicleGroup',
                                                        )
                                                    }
                                                    onSelectChange={this.handleSelectChange2}
                                                    filterOption={(inputValue, item) =>
                                                        item.title.indexOf(inputValue) !== -1
                                                    }
                                                    leftColumns={this.leftTableColumns}
                                                    rightColumns={this.rightTableColumns}
                                                /> */}
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane
                    tab={
                      <div>
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicle}
                        </LangContext.Consumer>
                        {' & '}
                        <LangContext.Consumer>
                          {i18n => i18n.v.vehicleGroup}
                        </LangContext.Consumer>
                      </div>
                    }
                    key={'data-visibilit-tab-2'}
                  >
                    <Row gutter={24}>
                      <Divider orientation="left">
                        <small>
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicleGroup}
                          </LangContext.Consumer>
                        </small>
                      </Divider>
                      <div id="finding-btn2">
                        <TableTransfer
                          dataSource={this.state.allVehicleGroup}
                          targetKeys={this.state.selectedVehicleGroup}
                          disabled={this.state.selectedRowInfo == null}
                          showSearch
                          onChange={(targetKeys, direction, moveKeys) =>
                            this.handleTransferChange(
                              targetKeys,
                              direction,
                              moveKeys,
                              'vehicle_group_id',
                              'changeSelectedVehicleGroup',
                              'selectedVehicleGroup',
                              'removedSelectedVehicleGroup',
                              'updatedVehicleGroup',
                            )
                          }
                          onSelectChange={this.handleSelectChange2}
                          filterOption={(inputValue, item) =>
                            item.title
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          leftColumns={this.leftTableColumns}
                          rightColumns={this.rightTableColumns}
                        />
                      </div>
                    </Row>

                    <Row gutter={24}>
                      <Divider orientation="left">
                        <small>
                          {' '}
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        </small>
                      </Divider>
                      <div id="finding-btn3">
                        <TableTransfer
                          dataSource={this.state.allVehicle}
                          targetKeys={this.state.selectedVehicle}
                          disabled={this.state.selectedRowInfo == null}
                          showSearch
                          onChange={(targetKeys, direction, moveKeys) =>
                            this.handleTransferChange(
                              targetKeys,
                              direction,
                              moveKeys,
                              'vehicle_id',
                              'changeSelectedVehicle',
                              'selectedVehicle',
                              'removedSelectedVehicle',
                              'updatedVehicle',
                            )
                          }
                          onSelectChange={this.handleSelectChange3}
                          filterOption={(inputValue, item) =>
                            item.title
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          leftColumns={this.leftTableColumns}
                          rightColumns={this.rightTableColumns}
                        />
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane
                    tab={
                      <div>
                        <LangContext.Consumer>
                          {i18n => i18n.l.location}
                        </LangContext.Consumer>
                        {' & '}
                        <LangContext.Consumer>
                          {i18n => i18n.l.locationGroup}
                        </LangContext.Consumer>
                      </div>
                    }
                    key={'data-visibilit-tab-3'}
                  >
                    <Row gutter={24}>
                      <Divider orientation="left">
                        <small>
                          {' '}
                          <LangContext.Consumer>
                            {i18n => i18n.l.locationGroup}
                          </LangContext.Consumer>
                        </small>
                      </Divider>
                      <div id="finding-btn4">
                        <TableTransfer
                          dataSource={this.state.allLocationGroup}
                          targetKeys={this.state.selectedLocationGroup}
                          disabled={this.state.selectedRowInfo == null}
                          showSearch
                          onChange={(targetKeys, direction, moveKeys) =>
                            this.handleTransferChange(
                              targetKeys,
                              direction,
                              moveKeys,
                              'location_group_id',
                              'changeSelectedLocationGroup',
                              'selectedLocationGroup',
                              'removedSelectedLocationGroup',
                              'updatedLocationGroup',
                            )
                          }
                          onSelectChange={this.handleSelectChange4}
                          filterOption={(inputValue, item) =>
                            item.title
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          leftColumns={this.leftTableColumns}
                          rightColumns={this.rightTableColumns}
                        />
                      </div>
                    </Row>

                    <Row gutter={24}>
                      <Divider orientation="left">
                        <small>
                          {' '}
                          <LangContext.Consumer>
                            {i18n => i18n.l.location}
                          </LangContext.Consumer>
                        </small>
                      </Divider>
                      <div id="finding-btn5">
                        <TableTransfer
                          dataSource={this.state.allLocation}
                          targetKeys={this.state.selectedLocation}
                          disabled={this.state.selectedRowInfo == null}
                          showSearch
                          onChange={(targetKeys, direction, moveKeys) =>
                            this.handleTransferChange(
                              targetKeys,
                              direction,
                              moveKeys,
                              'location_id',
                              'changeSelectedLocation',
                              'selectedLocation',
                              'removedSelectedLocation',
                              'updatedLocation',
                            )
                          }
                          onSelectChange={this.handleSelectChange5}
                          filterOption={(inputValue, item) =>
                            item.title
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          leftColumns={this.leftTableColumns}
                          rightColumns={this.rightTableColumns}
                        />
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane
                    tab={
                      <div>
                        <LangContext.Consumer>
                          {i18n => i18n.m.masterDataMatrial}
                        </LangContext.Consumer>
                      </div>
                    }
                    key={'data-visibilit-tab-4'}
                  >
                    <Row gutter={24}>
                      <Divider orientation="left">
                        <small>
                          {' '}
                          <LangContext.Consumer>
                            {i18n => i18n.m.masterDataMatrial}
                          </LangContext.Consumer>
                        </small>
                      </Divider>
                      <div id="finding-btn6">
                        <TableTransfer
                          dataSource={this.state.allMaterial}
                          targetKeys={this.state.selectedMaterial}
                          disabled={this.state.selectedRowInfo == null}
                          showSearch
                          onChange={(targetKeys, direction, moveKeys) =>
                            this.handleTransferChange(
                              targetKeys,
                              direction,
                              moveKeys,
                              'material_id',
                              'changeSelectedMaterial',
                              'selectedMaterial',
                              'removedSelectedMaterial',
                              'updatedMaterial',
                            )
                          }
                          onSelectChange={this.handleSelectChange6}
                          filterOption={(inputValue, item) =>
                            item.title
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          leftColumns={this.leftTableColumns}
                          rightColumns={this.rightTableColumns}
                        />
                      </div>
                    </Row>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
            <Row gutter={24} type="flex">
              <Col span={24}>
                <Link to="/">
                  <Button type="default" style={{ margin: '0px 8px 0px 0px' }}>
                    <LangContext.Consumer>
                      {i18n => i18n.b.back}
                    </LangContext.Consumer>
                  </Button>
                </Link>
                {checkPermissions(
                  this.props.auth.profile.permissions,
                  'dataVisibility',
                  'can_add',
                ) == true ? (
                  <Button
                    type="primary"
                    className="submit-step"
                    onClick={this.handleSubmit}
                  >
                    <LangContext.Consumer>
                      {i18n => i18n.s.submit}
                    </LangContext.Consumer>
                  </Button>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Spin>
        </GeneralStyledContent>

        <Modal
          title="Datavisibility Node."
          visible={this.state.visible}
          onOk={() => {
            if (this.state.name.trim() != '') {
              let rowInfo = this.state.selectedRowInfo

              let nodeAmount =
                getVisibleNodeCount({ treeData: this.state.treeData }) + 1
              let newTree = addNodeUnderParent({
                treeData: this.state.treeData,
                newNode: {
                  key: 'new:' + nodeAmount,
                  title: this.state.name,
                  expanded: true,
                },
                parentKey:
                  rowInfo.path == null
                    ? null
                    : rowInfo.path[rowInfo.path.length - 1],
                getNodeKey,
                ignoreCollapsed: true,
                expandParent: true,
                addAsFirstChild: false,
              })

              this.setState({
                treeData: newTree.treeData,
                originalTreeData: newTree.treeData,
              })
              this.hideModal()
            }
          }}
          onCancel={this.hideModal}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form.Item
            label="Name"
            validateStatus={this.state.name.trim() != '' ? '' : 'warning'}
            help={this.state.name.trim() != '' ? '' : 'Require Field.'}
          >
            <Input
              style={{ margin: '5px 0px 0px 0px' }}
              name="warning"
              value={this.state.name}
              onChange={this.onNodeNameChange}
            />
          </Form.Item>
        </Modal>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ datavisibilityMasterDataState, auth }) => ({
    datavisibilityMasterDataState,
    auth,
  }),
  {
    loadDatavisibilitySelectedVehicleGroup:
      loadDatavisibilitySelectedVehicleGroup.request,
    loadDatavisibilitySelectedVehicle:
      loadDatavisibilitySelectedVehicle.request,
    loadDatavisibilitySelectedLocationGroup:
      loadDatavisibilitySelectedLocationGroup.request,
    loadDatavisibilitySelectedLocation:
      loadDatavisibilitySelectedLocation.request,
    loadDatavisibilitySelectedUser: loadDatavisibilitySelectedUser.request,
    loadDatavisibilitySelectedMaterial:
      loadDatavisibilitySelectedMaterial.request,
    changeSelectedVehicleGroup,
    changeSelectedVehicle,
    changeSelectedLocationGroup,
    changeSelectedLocation,
    changeSelectedUser,
    changeSelectedMaterial,
  },
)(Index)
