import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
} from './../../../constants/local_storage'

function* loadTripsWill(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      company_id,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-trips-will`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadTripsWill.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadTripsWill.failure())
  }
}

function* createTripWill(action) {
  const {
    data: { files, request_date, user_id, accessToken },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('request_date', request_date)
  formData.append('user_id', user_id)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/precast-create-will`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    loading(res.data)
    yield put(actions.createTripWill.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.createTripWill.failure())
  }
}

export default function* watchCpacPrecastWall() {
  yield all([
    takeEvery(actions.LOAD_TRIP_WILL.REQUEST, loadTripsWill),
    takeEvery(actions.CREATE_TRIP_WILL.REQUEST, createTripWill),
  ])
}
export { loadTripsWill, createTripWill }
