import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'

import * as actions from './actions'
import moment from 'moment'

function* loadReportSchedulereport(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const user_id = getlocalstorage(localStorage.getItem('profile'), 'id')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/schedulereport/getlistreports`,
      {
        user_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadReportSchedulereport.success(res.data))
  } catch (err) {
    yield put(actions.loadReportSchedulereport.failure())
  }
}

function* loadSchedulereport(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const user_id = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: { page, pageSize, orderBy, orderType, filterObj },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/schedulereport`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        user_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadSchedulereport.success(res.data))
  } catch (err) {
    yield put(actions.loadSchedulereport.failure())
  }
}

function* createSchedulereport(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  const user_id = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: {
      list_report_schedules_id,
      schedule_type_id,
      attachment_type,
      email_subject,
      to_email,
      cc_email,
      day,
      week,
      month,
      time,
      start_time,
      end_time,
      body_email,
      previous_data,
      filter_report,
    },
    history,
  } = action.payload
  const new_time = moment(time).format('HH:mm')
  const new_start_time = moment(start_time).format('HH:mm:00')
  const new_end_time = moment(end_time).format('HH:mm:59')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/schedulereport/create`,
      {
        list_report_schedules_id,
        schedule_type_id,
        attachment_type,
        email_subject,
        to_email,
        cc_email,
        day,
        week,
        month,
        time: new_time,
        start_time: new_start_time,
        end_time: new_end_time,
        body_email,
        previous_data,
        filter_report,
        user_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.createSchedulereport.success(res.data))
    history.push('/schedulereport')
  } catch (err) {
    yield put(actions.createSchedulereport.failure())
  }
}

function* updateSchedulereport(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  const {
    data: {
      list_report_schedules_id,
      schedule_type_id,
      attachment_type,
      email_subject,
      to_email,
      cc_email,
      day,
      week,
      month,
      time,
      start_time,
      end_time,
      body_email,
      previous_data,
      filter_report,
    },
    id,
    history,
  } = action.payload
  const new_time = moment(time).format('HH:mm')
  const new_start_time = moment(start_time).format('HH:mm:00')
  const new_end_time = moment(end_time).format('HH:mm:59')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/schedulereport/update`,
      {
        list_report_schedules_id,
        schedule_type_id,
        attachment_type,
        email_subject,
        to_email,
        cc_email,
        day,
        week,
        month,
        time: new_time,
        start_time: new_start_time,
        end_time: new_end_time,
        body_email,
        previous_data,
        filter_report,
        id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.updateSchedulereport.success(res.data))
    history.push('/schedulereport')
  } catch (err) {
    yield put(actions.updateSchedulereport.failure())
  }
}

function* getSchedulereportByID(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const { id } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/schedulereport/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.getSchedulereportByID.success(res.data))
  } catch (err) {
    yield put(actions.getSchedulereportByID.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_SCHEDULEREPORT.REQUEST, loadSchedulereport),
    takeEvery(actions.CREATE_SCHEDULEREPORT.REQUEST, createSchedulereport),
    takeEvery(actions.UPDATE_SCHEDULEREPORT.REQUEST, updateSchedulereport),
    takeEvery(actions.GET_SCHEDULEREPORT.REQUEST, getSchedulereportByID),
    takeEvery(
      actions.LOAD_REPORT_SCHEDULEREPORT.REQUEST,
      loadReportSchedulereport,
    ),
  ])
}

export {
  loadSchedulereport,
  createSchedulereport,
  updateSchedulereport,
  getSchedulereportByID,
  loadReportSchedulereport,
}
