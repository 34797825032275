import { createTypes, createAction } from "lib/action";

const LOAD_MAINTENANCEDEVICE = createTypes("maintenancedevice", "load");
const CREATE_MAINTENANCEDEVICE = createTypes("maintenancedevice", "create");
const UPDATE_MAINTENANCEDEVICE = createTypes("maintenancedevice", "delete");
const GET_MAINTENANCEDEVICE = createTypes("maintenancedevice", "get");
const CLEAR_MAINTENANCEDEVICE = createTypes("maintenancedevice", "clear");
const LOAD_VEHICLE_MAINTENANCEDEVICE = createTypes(
  "vehicle_maintenancedevice",
  "load"
);
const CLEAR_RESPONSE = createTypes("response", "clear");
const LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICE = createTypes("maintenancestatus_maintenancedevice", "load");
const LOAD_TYPEPROBLEM_MAINTENANCEDEVICE = createTypes("typeproblem_maintenancedevice", "load");

const loadVehicleMaintenancedevice = {
  request: () =>
    createAction(LOAD_VEHICLE_MAINTENANCEDEVICE.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_MAINTENANCEDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_MAINTENANCEDEVICE.FAILURE)
};

const loadMaintenancedevice = {
  request: (data, loading) =>
    createAction(LOAD_MAINTENANCEDEVICE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_MAINTENANCEDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_MAINTENANCEDEVICE.FAILURE)
};

const createMaintenancedevice = {
  request: (data, loading, history) =>
    createAction(CREATE_MAINTENANCEDEVICE.REQUEST, {
      data,
      loading,
      history
    }),
  success: data => createAction(CREATE_MAINTENANCEDEVICE.SUCCESS, { data }),
  failure: () => createAction(CREATE_MAINTENANCEDEVICE.FAILURE)
};

const updateMaintenancedevice = {
  request: (data, id, history, loading) =>
    createAction(UPDATE_MAINTENANCEDEVICE.REQUEST, { data, id, history, loading }),
  success: data => createAction(UPDATE_MAINTENANCEDEVICE.SUCCESS, { data }),
  failure: () => createAction(UPDATE_MAINTENANCEDEVICE.FAILURE)
};


const getMaintenancedeviceByID = {
  request: id => createAction(GET_MAINTENANCEDEVICE.REQUEST, { id }),
  success: data => createAction(GET_MAINTENANCEDEVICE.SUCCESS, { data }),
  failure: () => createAction(GET_MAINTENANCEDEVICE.FAILURE)
};


const loadMaintenancestatus = {
  request: () => createAction(LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICE.REQUEST, {}),
  success: data => createAction(LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICE.FAILURE)
};

const loadTypeproblem = {
  request: () => createAction(LOAD_TYPEPROBLEM_MAINTENANCEDEVICE.REQUEST, {}),
  success: data => createAction(LOAD_TYPEPROBLEM_MAINTENANCEDEVICE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TYPEPROBLEM_MAINTENANCEDEVICE.FAILURE)
};


const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE
  };
};

const clearMaintenancedevice = () => {
  return {
    type: CLEAR_MAINTENANCEDEVICE
  };
};

export {
  LOAD_MAINTENANCEDEVICE,
  CREATE_MAINTENANCEDEVICE,
  UPDATE_MAINTENANCEDEVICE,
  GET_MAINTENANCEDEVICE,
  loadMaintenancedevice,
  createMaintenancedevice,
  updateMaintenancedevice,
  getMaintenancedeviceByID,
  loadVehicleMaintenancedevice,
  LOAD_VEHICLE_MAINTENANCEDEVICE,
  LOAD_MAINTENANCESTATUS_MAINTENANCEDEVICE,
  loadMaintenancestatus,
  CLEAR_RESPONSE,
  clearDataResponse,
  clearMaintenancedevice,
  CLEAR_MAINTENANCEDEVICE,
  LOAD_TYPEPROBLEM_MAINTENANCEDEVICE,
  loadTypeproblem
};
