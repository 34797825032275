import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Button, Row, Col, Icon } from 'antd'
import { getVehicleVisibility } from '../../../../lib/helper'

import { StyledWidgetShadowOnly } from '../../../../styled/common-styled'

export class VehicleStatusWidget extends Component {
  state = { visible: false, loading: false }

  loadData = loading => {
    if (this.props.callingApi) {
      if (loading) this.setState({ loading: true })
      this.fetch(this)
    }
  }

  fetch = self => {
    let visibility = getVehicleVisibility(
      self.props.auth.profile.user_type,
      self.props.auth.profile.vehicle_visibility,
      self.props.auth.profile.org_chart_vehicle_visibility,
      self.props.auth.profile.data_visibility_node,
    )

    let formData = new FormData()
    formData.append('user_id', self.props.auth.profile.id)
    formData.append('company_id', self.props.auth.profile.company_id)
    formData.append('vehicle_visibility', visibility)

    fetch(
      `${process.env.REACT_APP_GO_V2_API_ENDPOINT}/summary/vehicle_status`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${self.props.auth.accessToken}`,
        },
        body: formData,
      },
    )
      .then(response => response.json())
      .then(res => {
        if (Array.isArray(res)) {
          self.setState({
            loading: false,
          })
          self.props.handdleParentStateChange('vehicleStatusPieData', res)
          res.map(ele =>
            self.props.handdleParentStateChange(ele.label, ele.value),
          )
        } else {
          console.log('fetch errors')
        }
      })
  }
  componentDidMount() {
    if (!this.props.demo) {
      this.loadData(true)
      this.interval = setInterval(() => this.loadData(false), 1000 * 120)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return (
      <Row
        style={{
          backgroundColor: this.props.color,
          borderRadius: '.25rem',
          boxShadow:
            '0 0.46875rem 2.1875rem rgba(58, 196, 125, 0.03), 0 0.9375rem 1.40625rem rgba(58, 196, 125, 0.03)',
          display: 'block',
          marginBottom: '20px',
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            padding: '14px',
            color: '#fff',
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.5',
          }}
        >
          <span
            style={{
              fontSize: '2rem',
              fontWeight: '600',
              color: '#fff',
            }}
          >
            {this.props.qty}
          </span>

          <p>{this.props.widgetText}</p>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            padding: '14px',
            color: '#fff',
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.5',
          }}
          align="right"
        >
          <Icon
            type="link"
            onClick={() => {
              if (this.props.layoutChange) {
                window.open(
                  `/tracking/dashboardTypeTracking/${this.props.widgetType}`,
                )
              }
            }}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="icon semi-bold-16"
        >
          <i className="ion ion-bag"></i>
        </Col>
      </Row>
    )
  }
}

export default connect(
  ({ auth, auth: { profile } }) => ({
    auth,
    profile,
  }),
  {},
)(VehicleStatusWidget)
