import React, { Component, Fragment } from 'react'

export class AuthorizeWidgetComponent extends Component {
  componentDidMount() {}

  render() {
    const { permission } = this.props
    let canAccess = permission.find(ele => {
      return ele.matching_name == this.props.matching_name && ele.can_view == 1
    })

    if (this.props.matching_name == '') {
      canAccess = true
    } else if (typeof canAccess == 'undefined') {
      canAccess = false
    } else {
      canAccess = true
    }

    if (!canAccess) {
      return <div></div>
    }
    return <div {...this.props}>{this.props.children}</div>
  }
}
export default AuthorizeWidgetComponent
