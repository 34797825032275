import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
} from './../../../constants/local_storage'

function* loadPlantMiningContract(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      company_id,
      is_admin,
      is_vendor,
      location_visibility,
      filterObj,
      limit_export,
      type,
      file_value_pdf,
      exactly_location,
      mill_location_list,
      plant_location_list,
      material_visibility,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get/plant-mining-contract`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        company_id,
        is_admin,
        is_vendor,
        location_visibility,
        filterObj,
        limit_export,
        type,
        file_value_pdf,
        exactly_location,
        mill_location_list,
        plant_location_list,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadPlantMiningContract.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadPlantMiningContract.failure())
  }
}

function* exportPlantMiningContract(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      company_id,
      is_admin,
      is_vendor,
      location_visibility,
      filterObj,
      limit_export,
      type,
      file_value_pdf,
      exactly_location,
      mill_location_list,
      plant_location_list,
      material_visibility,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get/plant-mining-contract`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        company_id,
        is_admin,
        is_vendor,
        location_visibility,
        filterObj,
        limit_export,
        type,
        file_value_pdf,
        exactly_location,
        mill_location_list,
        plant_location_list,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(
      actions.exportPlantMiningContract.success(res.data, page, pageSize),
    )
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportPlantMiningContract.failure())
  }
}

function* createPlantMiningContract(action) {
  const {
    data: {
      contract_overlaps_id,
      plant_id,
      mining_id,
      material_id,
      distance,
      duration,
      vendor_id,
      contract_shipping_rate,
      manpower_rate,
      tons_per_trip,
      is_bluenet,
      integrated_with,
      sync_cdas,
      config_in_plant_area,
      config_dn_switching,
      contract_number,
      start_at,
      expired_at,
      weighing_format, //รูปเเบบการชั่ง เช่น M, P, MP,
      weighing_random_conditions, // เงื่อนไขการสุ่มชั่งน้ำหนัก
      config_weight1_source,
      config_weight1_weight_in,
      config_weight1_weight_out,
      config_weight2_source,
      config_weight2_weight_in,
      config_weight2_weight_out,
      config_delivery_area,
      config_gr_delivery_area,
      origin_sample_photo_pattern_id,
      dest_sample_photo_pattern_id,
      interface_gr,
      weigh_per_trip,
      weigh_unit,
      origin_sample_photo_pattern_required,
      dest_sample_photo_pattern_required,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/create/plant-mining-contract`,
      {
        contract_overlaps_id,
        plant_id,
        mining_id,
        material_id,
        distance,
        duration,
        vendor_id,
        contract_shipping_rate,
        manpower_rate,
        tons_per_trip,
        is_bluenet,
        integrated_with,
        sync_cdas,
        config_in_plant_area,
        config_dn_switching,
        contract_number,
        start_at,
        expired_at,
        weighing_format, //รูปเเบบการชั่ง เช่น M, P, MP,
        weighing_random_conditions, // เงื่อนไขการสุ่มชั่งน้ำหนัก
        config_weight1_source,
        config_weight1_weight_in,
        config_weight1_weight_out,
        config_weight2_source,
        config_weight2_weight_in,
        config_weight2_weight_out,
        config_delivery_area,
        config_gr_delivery_area,
        origin_sample_photo_pattern_id,
        dest_sample_photo_pattern_id,
        interface_gr,
        weigh_per_trip,
        weigh_unit,
        origin_sample_photo_pattern_required,
        dest_sample_photo_pattern_required,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    loading(res.data)
    yield put(actions.createPlantMiningContract.success(res.data))
  } catch (err) {
    yield put(actions.createPlantMiningContract.failure())
  }
}

function* loadPlantMiningContractDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get-detail/plant-mining-contract/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadPlantMiningContractDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadPlantMiningContractDetail.failure())
  }
}

function* updatePlantMiningContractDetail(action) {
  const {
    data: {
      contract_overlaps_id,
      id,
      plant_id,
      mining_id,
      material_id,
      distance,
      duration,
      vendor_id,
      contract_shipping_rate,
      manpower_rate,
      shipping_rate,
      tons_per_trip,
      is_bluenet,
      integrated_with,
      sync_cdas,
      isupply_plant_mining_contract_config_id,
      config_in_plant_area,
      config_dn_switching,
      contract_number,
      start_at,
      expired_at,
      weighing_format, //รูปเเบบการชั่ง เช่น M, P, MP,
      weighing_random_conditions, // เงื่อนไขการสุ่มชั่งน้ำหนัก
      config_weight1_source,
      config_weight1_weight_in,
      config_weight1_weight_out,
      config_weight2_source,
      config_weight2_weight_in,
      config_weight2_weight_out,
      config_delivery_area,
      config_gr_delivery_area,
      origin_sample_photo_pattern_id,
      dest_sample_photo_pattern_id,
      interface_gr,
      weigh_per_trip,
      weigh_unit,
      origin_sample_photo_pattern_required,
      dest_sample_photo_pattern_required,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/update/plant-mining-contract`,
      {
        contract_overlaps_id,
        id,
        plant_id,
        mining_id,
        material_id,
        distance,
        duration,
        vendor_id,
        contract_shipping_rate,
        manpower_rate,
        shipping_rate,
        tons_per_trip,
        is_bluenet,
        integrated_with,
        sync_cdas,
        isupply_plant_mining_contract_config_id,
        config_in_plant_area,
        config_dn_switching,
        contract_number,
        start_at,
        expired_at,
        weighing_format, //รูปเเบบการชั่ง เช่น M, P, MP,
        weighing_random_conditions, // เงื่อนไขการสุ่มชั่งน้ำหนัก
        config_weight1_source,
        config_weight1_weight_in,
        config_weight1_weight_out,
        config_weight2_source,
        config_weight2_weight_in,
        config_weight2_weight_out,
        config_delivery_area,
        config_gr_delivery_area,
        origin_sample_photo_pattern_id,
        dest_sample_photo_pattern_id,
        interface_gr,
        weigh_per_trip,
        weigh_unit,
        origin_sample_photo_pattern_required,
        dest_sample_photo_pattern_required,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    loading(res.data)
    yield put(actions.updatePlantMiningContractDetail.success(res.data))
  } catch (err) {
    yield put(actions.updatePlantMiningContractDetail.failure())
  }
}

function* uploadPlantMiningContract(action) {
  const {
    data: {
      files,
      accessToken,
      is_bluenet,
      integrated_with,
      sync_cdas,
      config_dn_switching,
      weighing_format,
      weighing_random_conditions,
      config_weight1_source,
      config_weight1_weight_in,
      config_weight1_weight_out,
      config_weight2_source,
      config_weight2_weight_in,
      config_weight2_weight_out,
      config_delivery_area,
      config_gr_delivery_area,
      origin_sample_photo_pattern_id,
      dest_sample_photo_pattern_id,
      interface_gr,
      typeImport,
      origin_sample_photo_pattern_required,
      dest_sample_photo_pattern_required,
    },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('is_bluenet', is_bluenet)
  formData.append('integrated_with', integrated_with)
  formData.append('sync_cdas', sync_cdas)
  formData.append('config_dn_switching', config_dn_switching)
  formData.append('weighing_format', weighing_format)
  formData.append('weighing_random_conditions', weighing_random_conditions)
  formData.append('config_weight1_source', config_weight1_source)
  formData.append('config_weight1_weight_in', config_weight1_weight_in)
  formData.append('config_weight1_weight_out', config_weight1_weight_out)
  formData.append('config_weight2_source', config_weight2_source)
  formData.append('config_weight2_weight_in', config_weight2_weight_in)
  formData.append('config_weight2_weight_out', config_weight2_weight_out)
  formData.append('config_delivery_area', config_delivery_area)
  formData.append('config_gr_delivery_area', config_gr_delivery_area)
  formData.append(
    'origin_sample_photo_pattern_id',
    origin_sample_photo_pattern_id,
  )
  formData.append('dest_sample_photo_pattern_id', dest_sample_photo_pattern_id)
  formData.append('interface_gr', interface_gr)
  formData.append('typeImport', typeImport)
  formData.append(
    'origin_sample_photo_pattern_required',
    origin_sample_photo_pattern_required,
  )
  formData.append(
    'dest_sample_photo_pattern_required',
    dest_sample_photo_pattern_required,
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/import/plant-mining-contract`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    loading(res.data)
    yield put(actions.uploadPlantMiningContract.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.uploadPlantMiningContract.failure())
  }
}

export default function* watchPlantMiningContract() {
  yield all([
    takeEvery(
      actions.LOAD_PLANT_MINING_CONTRACT.REQUEST,
      loadPlantMiningContract,
    ),
    takeEvery(
      actions.CREATE_PLANT_MINING_CONTRACT.REQUEST,
      createPlantMiningContract,
    ),
    takeEvery(
      actions.LOAD_PLANT_MINING_CONTRACT_DETAIL.REQUEST,
      loadPlantMiningContractDetail,
    ),
    takeEvery(
      actions.UPDATE_PLANT_MINING_CONTRACT.REQUEST,
      updatePlantMiningContractDetail,
    ),
    takeEvery(
      actions.UPLOAD_PLANT_MINING_CONTRACT.REQUEST,
      uploadPlantMiningContract,
    ),
    takeEvery(
      actions.EXPORT_PLANT_MINING_CONTRACT.REQUEST,
      exportPlantMiningContract,
    ),
  ])
}
export {
  loadPlantMiningContract,
  createPlantMiningContract,
  updatePlantMiningContractDetail,
  loadPlantMiningContractDetail,
  uploadPlantMiningContract,
  exportPlantMiningContract,
}
