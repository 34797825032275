import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
  getlocalstorage,
} from './../../../constants/local_storage'

function* loadDataForManagQueue(action) {
  const {
    data: { location_visibility, page, pageSize, filterObj },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get/runquere`,
      {
        location_visibility,
        page,
        pageSize,
        filterObj,
        relate_company: `${this.props.auth.profile.relate_company}`,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.loadDataForManagQueue.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDataForManagQueue.failure())
  }
}

function* loadDataForUnComingQueue(action) {
  const {
    data: { location_visibility, page, pageSize, filterObj },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get/upcoming-queue`,
      {
        location_visibility,
        page,
        pageSize,
        filterObj,
        relate_company: `${this.props.auth.profile.relate_company}`,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.loadDataForUnComingQueue.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDataForUnComingQueue.failure())
  }
}

export default function* watchManagQueue() {
  yield all([
    takeEvery(actions.LOAD_DATA_FOR_MANAG_QUEUE.REQUEST, loadDataForManagQueue),
    takeEvery(
      actions.LOAD_DATA_FOR_UNCOMING_QUEUE.REQUEST,
      loadDataForUnComingQueue,
    ),
  ])
}

export { loadDataForManagQueue, loadDataForUnComingQueue }
