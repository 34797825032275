import {
    LOAD_TRUCK_STATUS_DASHBOARD,
    REMOVE_LISTS
} from './actions'

const initialState = {
    Lists: [],
    loading: false,
    draw: 0
}


export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState
        case LOAD_TRUCK_STATUS_DASHBOARD.REQUEST:
            const { loading } = action.payload.data
            return {
                ...state,
                loading: action.payload.loading,
            }

        case LOAD_TRUCK_STATUS_DASHBOARD.SUCCESS:
            return {
                ...state,
                Lists: action.payload.lists,
                loading: false,
                draw: Math.floor(Math.random() * 999 + 1),
            }

        default:
            return state
    }
}
