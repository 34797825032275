import { createTypes, createAction } from 'lib/action'

const LOAD_PARTNER = createTypes('loadpartner', 'load')
const LOAD_AUTOCOMPLETE = createTypes('loadautocomplete', 'load')
const CREATE_PARTNER = createTypes('createpartner', 'create')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadPartner = {
  request: (data, loading) =>
    createAction(LOAD_PARTNER.REQUEST, { data, loading }),
  success: (lists, page) => createAction(LOAD_PARTNER.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_PARTNER.FAITLURE),
}

const loadAutocomplete = {
  request: (url, companyID, accessToken, queryString) =>
    createAction(LOAD_AUTOCOMPLETE.REQUEST, {
      url,
      companyID,
      accessToken,
      queryString,
    }),
  success: lists => createAction(LOAD_AUTOCOMPLETE.SUCCESS, { lists }),
  failure: () => createAction(LOAD_AUTOCOMPLETE.FAILURE),
}

const createPartner = {
  request: (data, loading) => {
    return createAction(CREATE_PARTNER.REQUEST, { data, loading })
  },
  success: res => createAction(CREATE_PARTNER.SUCCESS, { res }),
  failure: () => createAction(CREATE_PARTNER.FAILURE),
}

export {
  LOAD_PARTNER,
  loadPartner,
  LOAD_AUTOCOMPLETE,
  loadAutocomplete,
  CREATE_PARTNER,
  createPartner,
  REMOVE_LISTS,
  removeLists,
}
