import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const set = [
   [5],
   [9],
   [4],
]
const createItems = () => {
    let items = []
    var today = new Date();
    var dd = today.getDate();

    for(var i=1; i<=31; i++) {
        if(i >= dd) {
            items.push({
                date: i,
                จอดไม่ดับเครื่อง: 0,
            })
        } else {
            let ran = Math.floor(Math.random() * 2)
            items.push({
                date: i,
                จอดไม่ดับเครื่อง: set[ran][0],
            })
        }
    }

    return items
}
  
const ParkingGraph = ({ data }) =>  (
    <ResponsiveBar
      data={createItems()}
      keys={["จอดไม่ดับเครื่อง"]}
      indexBy="date"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      maxValue={24}
      colors={['#f2bf33']}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "วันที่",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "เวลา",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );

export default ParkingGraph;
