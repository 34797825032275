import {
  LOAD_DRIVINGLOG,
  LOAD_DRIVINGLOG_PREVIEW,
  LOAD_VEHICLE_DRIVINGLOG,
  LOAD_VEHICLE_TYPE_DRIVINGLOG,
} from './actions'

const initialState = {
  drivinglogUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  drivinglogLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DRIVINGLOG.REQUEST:
    case LOAD_DRIVINGLOG_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        drivinglogLoading: loading,
      }
    case LOAD_DRIVINGLOG.SUCCESS:
    case LOAD_DRIVINGLOG_PREVIEW.SUCCESS:
      return {
        ...state,
        drivinglogUrl: action.payload.data.data,
        drivinglogLoading: false,
      }
    case LOAD_VEHICLE_DRIVINGLOG.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        drivinglogLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_DRIVINGLOG.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        drivinglogLoading: false,
        //  loading: false
      }
    case LOAD_VEHICLE_TYPE_DRIVINGLOG.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
