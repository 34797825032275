import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
    Button,
    Table,
    Row,
    Col,
    Spin,
    Modal,
    Icon,
    Drawer,
    Tooltip,
} from 'antd'

import Filter from './Filter'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import * as actions from '../actions'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import Breadcrumb from './../../../shared/components/Breadcrumb'
import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import axios from 'axios'

const columnStyle = { fontSize: '12px', fontWeight: '400' }

class Index extends Component {
    state = {
        visible: false,
        listfiles: [],
        downloading: false,
        filedownloading: false,
        idprocess: 'web' + new Date().getTime(),
        plate_no: '',
        progress: '',
        check_load_vihicle: false,
    }

    ws = new WebSocket(
        `wss://${process.env.REACT_APP_WEBSOCKET}/ws?idprocess=` +
        this.state.idprocess,
    )

    getVehicleList = () => {
        const url =
            `https://${process.env.REACT_APP_WEBSOCKET}/getAllConnection?idprocess=` +
            this.state.idprocess
        //const url = "http://localhost:8001/getAllConnection?idprocess=web1667466579431"
        axios.get(url).then(res => {
            if (res.data.message !== '') {
                this.loadVehicle(res.data.message.split(','))
            }
        })
    }

    componentDidMount() {
        this.ws.onopen = () => {
            // on connecting, do nothing but log it to the console
            //   console.log('connected')
        }

        this.ws.onmessage = evt => {
            // listen to data sent from the websocket server
            // const message = JSON.parse(evt.data)

            //   console.log('response message:', evt.data)
            var messages = evt.data.split('\n')

            for (var i = 0; i < messages.length; i++) {
                let obj = JSON.parse(messages[i])

                if (obj.Idprocess == '' && obj.Idconnect == '') {
                    //console.log("allconneciton", obj.Allconnection)
                    //   if (this.state.check_load_vihicle === false) {
                    //     this.loadVehicle(obj.Allconnection)
                    //     this.setState({ check_load_vihicle: true })
                    //   }
                } else {
                    //console.log("data:", JSON.parse(obj.Data))
                    let data = JSON.parse(obj.Data)
                    if (data.type == 'get_list_video') {
                        let newlistFiles = []
                        let d = data.files
                        for (let i = 0; i < d.length; i++) {
                            let size = d[i].size / 1024 / 1024
                            let date_time = moment(d[i].create_date).format(
                                'DD/MM/YYYY HH:mm:ss',
                            )
                            var camera = ''
                            // console.log("camera:", d[i].camera_source)
                            if (d[i].camera_source == 'front') {
                                camera = (
                                    <LangContext.Consumer>
                                        {i18n => i18n.f.front}
                                    </LangContext.Consumer>
                                )
                            } else if (d[i].camera_source == 'back') {
                                camera = (
                                    <LangContext.Consumer>
                                        {i18n => i18n.c.camera_back}
                                    </LangContext.Consumer>
                                )
                            } else if (d[i].camera_source == 'extra') {
                                camera = (
                                    <LangContext.Consumer>
                                        {i18n => i18n.c.camera_extra}
                                    </LangContext.Consumer>
                                )
                            }
                            newlistFiles.push({
                                id: i + 1,
                                row_number: i + 1,
                                plate_no: this.state.plate_no,
                                time: date_time,
                                size: size.toFixed(2) + ' MB',
                                file_name: d[i].name,
                                imei: obj.Idprocess,
                                camera_source: camera,
                                camera_source_txt: d[i].camera_source,
                            })
                        }

                        this.setState({
                            downloading: false,
                            listfiles: newlistFiles,
                        })
                    } else if (data.type == 'download_video') {
                        if (data.status == 'PROGRESS') {
                            this.setState({ progress: data.progress + '%' })
                        } else if (data.status == 'COMPLETE') {
                            this.setState({ filedownloading: false, progress: '' })
                            //console.log("path", data.path_download)
                            let pathFile = data.path_download.split(
                                'hntdevice.terminusfleet.com/',
                            )
                            //   console.log(
                            //     `https://${process.env.REACT_APP_WEBSOCKET}/downloadvideo?path_file=` +
                            //       `http://localhost/` +
                            //       pathFile[1],
                            //   )
                            window.open(
                                `https://${process.env.REACT_APP_WEBSOCKET}/downloadvideo?path_file=` +
                                `http://localhost/` +
                                pathFile[1],
                            )
                        } else if (data.status == 'NOT READY') {
                            this.setState({ filedownloading: false, progress: '' })
                            this.fileNotReady()
                        }
                    }
                }
            }
        }

        this.ws.onclose = () => {
            // automatically try to reconnect on connection loss
            //   console.log('disconnected')
        }
    }

    componentWillUnmount() {
        //console.log("ddddd");
        this.ws.close()
    }

    fileNotReady = () => {
        Modal.warning({
            // title: <LangContext.Consumer>{i18n => i18n.s.status_download_file}</LangContext.Consumer>,
            content: (
                <LangContext.Consumer>
                    {i18n => i18n.f.filenotreadydownload}
                </LangContext.Consumer>
            ),
        })
    }

    showDrawer = () => {
        this.setState(
            {
                visible: true,
            },
            this.getVehicleList,
        )
    }

    onCloseSearch = resetForm => {
        this.setState({
            visible: false,
        })
    }

    filterHoneytoastdownloadfile = values => {
        const { imei, eventdate, plate_no, camera_position } = values
        const date_start = moment(eventdate[0]['_d']).format('YYYY-MM-DD HH:mm')
        const date_end = moment(eventdate[1]['_d']).format('YYYY-MM-DD HH:mm')

        this.setState({ downloading: true, listfile: [], plate_no: plate_no })
        const data = JSON.stringify({
            type: 'get_list_video',
            date_start: date_start,
            date_end: date_end,
            camera_source: camera_position,
            file_size: 'small',
        })
        const message = {
            Idconnect: imei,
            Idprocess: this.state.idprocess,
            Data: data,
        }
        // console.log(JSON.stringify(message))
        this.ws.send(JSON.stringify(message))
    }

    loadVehicle = allimei => {
        const { loadVehicle } = this.props
        loadVehicle(allimei)
    }

    loadVideo = (e, file_name, imei, source) => {
        e.preventDefault()
        // console.log('filename', file_name, imei)
        this.setState({ filedownloading: true, progress: '0%' })
        const data = JSON.stringify({
            type: 'download_video',
            name: file_name,
            file_size: 'small',
            camera_source: source,
        })
        const message = {
            Idconnect: imei,
            Idprocess: this.state.idprocess,
            Data: data,
        }
        // console.log(JSON.stringify(message))
        this.ws.send(JSON.stringify(message))
    }

    render() {
        const columns = [
            {
                title: <div className="regular-bold-14">#</div>,
                dataIndex: 'row_number',
                width: '4%',
                className: 'column-text-center',
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    }
                },
            },
            {
                title: (
                    <div className="regular-bold-14">
                        <LangContext.Consumer>
                            {i18n => i18n.p.plateNo}
                        </LangContext.Consumer>
                    </div>
                ),
                dataIndex: 'plate_no',
                sorter: false,
                // render: name => `${ name.first } ${ name.last }`,
                width: '20%',
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    }
                },
            },
            {
                title: (
                    <div className="regular-bold-14">
                        <LangContext.Consumer>
                            {i18n => i18n.d.date_time}
                        </LangContext.Consumer>
                    </div>
                ),
                dataIndex: 'time',
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    }
                },
                className: 'column-text-center',
                width: '15%',
            },
            {
                title: (
                    <div className="regular-bold-14">
                        <LangContext.Consumer>
                            {i18n => i18n.c.camera_position}
                        </LangContext.Consumer>
                    </div>
                ),
                className: 'column-text-center',
                dataIndex: 'camera_source',
                sorter: false,
                width: '15%',
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    }
                },
            },
            {
                title: (
                    <div className="regular-bold-14">
                        <LangContext.Consumer>
                            {i18n => i18n.f.file_size}
                        </LangContext.Consumer>
                    </div>
                ),
                className: 'column-text-center',
                dataIndex: 'size',
                sorter: false,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle,
                        },
                        children: <div>{text}</div>,
                    }
                },
                width: '10%',
            },
            {
                title: (
                    <div className="regular-bold-14">
                        <LangContext.Consumer>
                            {i18n => i18n.d.download}
                        </LangContext.Consumer>
                    </div>
                ),
                className: 'column-text-center',
                dataIndex: '',
                sorter: false,
                render: (text, record, index) => {
                    return (
                        <div>
                            <a
                                href="#"
                                onClick={e =>
                                    this.loadVideo(
                                        e,
                                        record.file_name,
                                        record.imei,
                                        record.camera_source_txt,
                                    )
                                }
                                style={{ padding: '0px 15px 0px 5px' }}
                            >
                                <Icon type="download" />
                            </a>
                        </div>
                    )
                },
                width: '10%',
            },
        ]

        return (
            <AuthorizeComponent
                {...this.props}
                matching_name="honeytoastdownloadfile"
            >
                <GeneralStyledContent>
                    <Spin
                        tip={this.state.progress}
                        style={{ fontSize: '28px' }}
                        spinning={this.state.filedownloading}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                {/* <Breadcrumb
                                    match={this.props.match}
                                    style={{ margin: "0px 0px 14px 10px" }}
                                /> */}
                                <div className="semi-bold-16" style={{ color: 'black' }}>
                                    <LangContext.Consumer>
                                        {i18n => i18n.h.honeytoastdownloadfile}
                                    </LangContext.Consumer>
                                </div>
                            </Col>
                            <Col span={24} align="center">
                                <div style={{ color: 'red', fontSize: 20, fontWeight: 'bold' }}>
                                    <LangContext.Consumer>
                                        {i18n => i18n.n.notiDownloadVideo}
                                    </LangContext.Consumer>
                                </div>
                            </Col>
                        </Row>
                        <Row
                            gutter={24}
                            type="flex"
                            justify="end"
                            style={{ height: '38px' }}
                        >
                            <Col span={22}></Col>

                            <Col span={2}>
                                <Tooltip
                                    placement="right"
                                    title={
                                        <LangContext.Consumer>
                                            {i18n => i18n.o.openFilter}
                                        </LangContext.Consumer>
                                    }
                                >
                                    <Button size="small" onClick={this.showDrawer}>
                                        <Icon type="filter" style={{ marginRight: '4px' }} />
                                        <LangContext.Consumer>
                                            {i18n => i18n.f.filter}
                                        </LangContext.Consumer>
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        <Table
                            bordered
                            size="small"
                            columns={columns}
                            rowKey={record => record.row_number}
                            dataSource={this.state.listfiles}
                            pagination={false}
                            loading={this.state.downloading}
                        // onChange={this.handleTableChange}
                        />

                        <div>
                            <LangContext.Consumer>
                                {i18n => (
                                    <Drawer
                                        title={i18n.s.search}
                                        width={400}
                                        onClose={this.onCloseSearch}
                                        visible={this.state.visible}
                                    >
                                        <Filter
                                            OnClose={this.onCloseSearch}
                                            onSubmit={this.filterHoneytoastdownloadfile}
                                            vehicleLists={
                                                this.props.honeytoastdownloadfile.vehicleLists
                                            }
                                        />
                                    </Drawer>
                                )}
                            </LangContext.Consumer>
                        </div>
                    </Spin>
                </GeneralStyledContent>
            </AuthorizeComponent>
        )
    }
}

const mapStateToProps = ({ honeytoastdownloadfile, auth: { profile } }) => ({
    honeytoastdownloadfile,
    profile,
})

const mapDispatchToProps = {
    loadVehicle: actions.loadVehicle.request,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
