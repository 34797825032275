import { createTypes, createAction } from "lib/action";
const LOAD_VEHICLE_DAILY_SUMMARY_REPORT = createTypes("vehicle_daily_driving_summary_report","load");
const LOAD_VEHICLE_BY_PLANT_ID_DAILY_SUMMARY_REPORT = createTypes("vehicle_by_plant_id_daily_driving_summary_report","load");
const LOAD_VEHICLE_TYPE_DAILY_SUMMARY_REPORT = createTypes("vehicle_type_daily_driving_summary_report","load");
const LOAD_PLANT_DAILY_SUMMARY_REPORT = createTypes("plant_daily_driving_summary_report","load");
const LOAD_BUSINESS_DAILY_SUMMARY_REPORT = createTypes("business_daily_driving_summary_report","load");
const EXPORT_DAILY_SUMMARY_REPORT_REPORT = createTypes("exportdailysummalyreport", "load");
const LOAD_VEHICLE_BY_BUSINESS_ID_DAILY_SUMMARY_REPORT = createTypes("vehicle_by_business_id_daily_driving_summary_report","load");


const exportDailySummalyreport = {
    request: (data, loading) =>
        createAction(EXPORT_DAILY_SUMMARY_REPORT_REPORT.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(EXPORT_DAILY_SUMMARY_REPORT_REPORT.SUCCESS, {lists}),
    failure: () => createAction(EXPORT_DAILY_SUMMARY_REPORT_REPORT.FAITLURE)  
};

const loadVehicle = {
    request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DAILY_SUMMARY_REPORT.REQUEST, {
        vehicletypeID,
        loading_vehicle
        }),
    success: data => createAction(LOAD_VEHICLE_DAILY_SUMMARY_REPORT.SUCCESS, { data }),
    failure: () => createAction(LOAD_VEHICLE_DAILY_SUMMARY_REPORT.FAILURE)
};

const loadVehicleByPlantID = {
    request: (plantID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_BY_PLANT_ID_DAILY_SUMMARY_REPORT.REQUEST, {
        plantID,
        loading_vehicle
        }),
    success: data => createAction(LOAD_VEHICLE_BY_PLANT_ID_DAILY_SUMMARY_REPORT.SUCCESS, { data }),
    failure: () => createAction(LOAD_VEHICLE_BY_PLANT_ID_DAILY_SUMMARY_REPORT.FAILURE)
};

const loadVehicleByBusinessID = {
    request: (businessID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_BY_BUSINESS_ID_DAILY_SUMMARY_REPORT.REQUEST, {
        businessID,
        loading_vehicle
        }),
    success: data => createAction(LOAD_VEHICLE_BY_BUSINESS_ID_DAILY_SUMMARY_REPORT.SUCCESS, { data }),
    failure: () => createAction(LOAD_VEHICLE_BY_BUSINESS_ID_DAILY_SUMMARY_REPORT.FAILURE)
};

const loadVehicleType = {
    request: () => createAction(LOAD_VEHICLE_TYPE_DAILY_SUMMARY_REPORT.REQUEST, {}),
    success: data =>
      createAction(LOAD_VEHICLE_TYPE_DAILY_SUMMARY_REPORT.SUCCESS, { data }),
    failure: () => createAction(LOAD_VEHICLE_TYPE_DAILY_SUMMARY_REPORT.FAILURE)
};

const loadPlant = {
    request: () => createAction(LOAD_PLANT_DAILY_SUMMARY_REPORT.REQUEST, {}),
    success: data =>
      createAction(LOAD_PLANT_DAILY_SUMMARY_REPORT.SUCCESS, { data }),
    failure: () => createAction(LOAD_PLANT_DAILY_SUMMARY_REPORT.FAILURE)
};

const loadBusiness = {
    request: () => createAction(LOAD_BUSINESS_DAILY_SUMMARY_REPORT.REQUEST, {}),
    success: data =>
      createAction(LOAD_BUSINESS_DAILY_SUMMARY_REPORT.SUCCESS, { data }),
    failure: () => createAction(LOAD_BUSINESS_DAILY_SUMMARY_REPORT.FAILURE)
};

export {
    LOAD_VEHICLE_DAILY_SUMMARY_REPORT, loadVehicle,
    LOAD_VEHICLE_TYPE_DAILY_SUMMARY_REPORT, loadVehicleType,
    EXPORT_DAILY_SUMMARY_REPORT_REPORT, exportDailySummalyreport,
    LOAD_PLANT_DAILY_SUMMARY_REPORT, loadPlant,
    LOAD_VEHICLE_BY_PLANT_ID_DAILY_SUMMARY_REPORT, loadVehicleByPlantID,
    LOAD_BUSINESS_DAILY_SUMMARY_REPORT, loadBusiness,
    LOAD_VEHICLE_BY_BUSINESS_ID_DAILY_SUMMARY_REPORT, loadVehicleByBusinessID
};