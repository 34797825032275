import React from 'react'
import { Row, Col, Icon, Badge } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import * as moment from 'moment'

const SubListNotification = ({
  mainSub,
  subSubject,
  type,
  createdAt,
  readAt,
  data,
  fetchData,
  cardId,
  profileId,
  count,
}) => {
  let date = ''
  let date_now = moment().format('YYYY-MM-DD HH:mm:ss')
  let creatDate = moment(createdAt).format('YYYY-MM-DD HH:mm:ss')
  let ms = moment(date_now, 'YYYY-MM-DD HH:mm:ss').diff(
    moment(creatDate, 'YYYY-MM-DD HH:mm:ss'),
  )
  let d = moment.duration(ms)
  let last_day = parseInt(d.asDays())
  let last_hourt = parseInt(d.asHours())
  let last_min = parseInt(d.asMinutes())
  if (last_day != 0) {
    date = `${last_day} วันที่แล้ว`
  } else if (last_day == 0 && last_hourt > 0) {
    date = `${last_hourt} ชั่วโมงที่แล้ว`
  } else if (last_day == 0 && last_hourt == 0) {
    date = `${last_min} นาทีที่แล้ว`
  }
  const readData = async id => {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('user_id', profileId)

    await fetch(`${process.env.REACT_APP_GO_API_ENDPOINT}/notification/read`, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('count-noti', data.count)
      })
    window.location.href =
      type == 'OilNotCalibrated'
        ? '/fuel-notification/detail-without-calibrate'
        : '/fuel-notification/detail-abnormality'
  }
  return (
    <div
      className="sub-content-fuel-noti"
      onClick={() => {
        readData(cardId)
        localStorage.setItem('oil-not-calibrated', JSON.stringify(data))
      }}
    >
      <Col
        span={24}
        style={{
          paddingTop: 20,
          paddingLeft: 20,
          borderBottom: '1px solid #dadce0',
        }}
      >
        <Row type="flex" justify="space-around" align="middle">
          <Col span={20}>
            <label
              style={{
                fontSize: '1.1rem',
                color: '#172b4d',
              }}
            >
              {readAt == '' ? (
                <Badge
                  dot
                  style={{
                    marginTop: '-4px',
                    padding: '4px',
                    marginRight: '-4px',
                  }}
                >
                  {/* <LangContext.Consumer>
                  {i18n => i18n.o.oilNotYetCalibrated}
                </LangContext.Consumer> */}
                  {mainSub}
                </Badge>
              ) : (
                mainSub
              )}
            </label>{' '}
            <label
              style={{
                fontSize: '0.9rem',
                color: '#172B4D',
                opacity: 0.41,
              }}
            >
              {date}{' '}
              {/* <LangContext.Consumer>
                {i18n => i18n.m.minutesAgo}
              </LangContext.Consumer> */}
            </label>
            <p
              style={{
                fontSize: '0.9rem',
                color: '#172B4D',
                opacity: 0.41,
                marginTop: 10,
              }}
            >
              {/* <LangContext.Consumer>
                {i18n => i18n.o.oilAbnormalityWarning}
              </LangContext.Consumer> */}
              {subSubject}
            </p>
          </Col>
          <Col span={4} align="right">
            <Icon
              type="right"
              style={{
                fontSize: '1rem',
                color: '#4489ec',
                paddingRight: 10,
              }}
            />
          </Col>
        </Row>
      </Col>
    </div>
  )
}

export default SubListNotification
