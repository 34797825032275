import { createTypes, createAction } from 'lib/action'

const LOAD_VEHICLEGROUP = createTypes('vehiclegroup', 'load')
const LOAD_VEHICLEGROUP_DETAIL = createTypes('vehiclegroupdetail', 'load')
const CREATE_VEHICLEGROUP = createTypes('vehiclegroup', 'create')
const UPDATE_VEHICLEGROUP = createTypes('vehiclegroup', 'update')
const EXPORT_VEHICLEGROUP = createTypes('exportvehiclegroup', 'load')
const LOAD_VISIBILITY_BY_VEHICLE_GROUP = createTypes(
  'loadVisibilityByVehicleGroup',
  'load',
)

const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadVehicleGroup = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLEGROUP.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_VEHICLEGROUP.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_VEHICLEGROUP.FAITLURE),
}

const createVehicleGroup = {
  request: (data, cb) =>
    createAction(CREATE_VEHICLEGROUP.REQUEST, { data, cb }),
  success: res => createAction(CREATE_VEHICLEGROUP.SUCCESS, { res }),
  failure: () => createAction(CREATE_VEHICLEGROUP.FAILURE),
}

const loadvehicleGroupDetail = {
  request: (data, loading) =>
    createAction(LOAD_VEHICLEGROUP_DETAIL.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_VEHICLEGROUP_DETAIL.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_VEHICLEGROUP_DETAIL.FAITLURE),
}

const upDateVehicleGroup = {
  request: (data, loading) =>
    createAction(UPDATE_VEHICLEGROUP.REQUEST, { data, loading }),
  success: res => createAction(UPDATE_VEHICLEGROUP.SUCCESS, { res }),
  failure: () => createAction(UPDATE_VEHICLEGROUP.FAILURE),
}

const exportVehicleGroup = {
  request: (data, loading) =>
    createAction(EXPORT_VEHICLEGROUP.REQUEST, { data, loading }),
  success: res => createAction(EXPORT_VEHICLEGROUP.SUCCESS, { res }),
  failure: () => createAction(EXPORT_VEHICLEGROUP.FAILURE),
}

const loadVisibilityByVehicleGroup = {
  request: (data, callback) =>
    createAction(LOAD_VISIBILITY_BY_VEHICLE_GROUP.REQUEST, { data, callback }),
  success: data =>
    createAction(LOAD_VISIBILITY_BY_VEHICLE_GROUP.SUCCESS, { data }),
  failure: () => createAction(LOAD_VISIBILITY_BY_VEHICLE_GROUP.FAITLURE),
}

export {
  loadVehicleGroup,
  LOAD_VEHICLEGROUP,
  loadvehicleGroupDetail,
  LOAD_VEHICLEGROUP_DETAIL,
  createVehicleGroup,
  CREATE_VEHICLEGROUP,
  upDateVehicleGroup,
  UPDATE_VEHICLEGROUP,
  exportVehicleGroup,
  EXPORT_VEHICLEGROUP,
  loadVisibilityByVehicleGroup,
  LOAD_VISIBILITY_BY_VEHICLE_GROUP,
  REMOVE_LISTS,
  removeLists,
}
