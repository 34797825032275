import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadActionLog(action) {
    const {
        data: {
            accessToken,
            filterObj
        }
    } = action.payload;

    try {
        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-action-log`,
            {
                filterObj
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );

        yield put(actions.loadActionLog.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadActionLog.failure());
    }
}

function* loadActionLogDetail(action) {
    const {
      data: { id, accessToken }
    } = action.payload;
  
    try {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-action-log-detail/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
  
      yield put(actions.loadActionLogDetail.success(res.data));
    } catch (err) {
      console.log(err.message);
      yield put(actions.loadActionLogDetail.failure());
    }
}

function* loadActionLogByEntity(action) {
    const {
      data: { id, accessToken }
    } = action.payload;
  
    try {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-action-log-for-entity/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
  
      yield put(actions.loadActionLogByEntity.success(res.data));
    } catch (err) {
      console.log(err.message);
      yield put(actions.loadActionLogByEntity.failure());
    }
}


export default function* watchActionLog() {
    yield all([
      takeEvery(actions.LOAD_ACTION_LOG.REQUEST, loadActionLog),
      takeEvery(actions.LOAD_ACTION_LOG_DETAIL.REQUEST, loadActionLogDetail),
      takeEvery(actions.LOAD_ACTION_LOG_BY_ENTITY.REQUEST, loadActionLogByEntity),
    ]);
}
export {
    loadActionLog,
    loadActionLogDetail,
    loadActionLogByEntity
}