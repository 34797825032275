import React, { Component } from 'react'
import { connect } from 'react-redux'
import Forms from './Forms'
import { Row, Col } from 'antd'
import Breadcrumb from './../../../shared/components/Breadcrumbreport'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import AuthorizeComponent from './../../../auth/components/AuthorizeComponent'
import moment from 'moment'

class Index extends Component {
  state = {
    vendor: [],
    vehicle: [],
    vehicle_group: [],
    loading: false,
  }

  componentDidMount() {
    this.loadVendor()
    this.loadVehicleGroup()
    this.loadVehicle('', '')
  }

  loadVendor = e => {
    let self = this
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvendor`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ vendor: res.data })
      })
  }

  loadVehicle = (vehicletypeID, vendor) => {
    let self = this
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        vendor_id: vendor,
        companyID: this.props.auth.profile.company_id,
        vehicletypeID: vehicletypeID,
        allow_vehicles: this.props.auth.profile.vehicle_visibility,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ vehicle: res.data })
      })
  }

  loadVehicleGroup = () => {
    let self = this
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        company_id: this.props.auth.profile.company_id,
      }),
    })
      .then(response => response.json())
      .then(res => {
        self.setState({ vehicle_group: res.data })
      })
  }

  onCriteriaChange = (vehicletypeID, vendor) =>
    this.loadVehicle(vehicletypeID, vendor)

  onSubmit = value => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const {
          list_vehicle_id,
          eventdate,
          job_status,
          vendor,
          duration,
        } = value
        const date_start = value.getAllData
          ? null
          : moment(eventdate['_d'])
              .clone()
              .startOf('months')
              .format('YYYY-MM-DD 00:00:00')
        const date_end = value.getAllData
          ? null
          : moment(eventdate['_d'])
              .clone()
              .endOf('months')
              .format('YYYY-MM-DD 23:59:59')

        fetch(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/report/repairing`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.props.auth.accessToken}`,
            },
            body: JSON.stringify({
              company_id: this.props.auth.profile.company_id,
              date_start,
              date_end,
              job_status,
              vendor,
              duration,
              list_vehicle_id,
              type_file: 'excel',
            }),
          },
        )
          .then(response => response.json())
          .then(res => {
            window.location.assign(
              `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data}`,
            )
            this.setState({ loading: false })
          })
      },
    )
  }

  render() {
    return (
      <AuthorizeComponent {...this.props} matching_name="repairingReport">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}></Col>
          </Row>
          <Forms
            loading={this.state.loading}
            onSubmit={this.onSubmit}
            vendorList={this.state.vendor}
            vehicleLists={this.state.vehicle}
            vehicleGroupLists={this.state.vehicle_group}
            loadVehicle={this.loadVehicle}
            loadVehicleGroup={this.loadVehicleGroup}
            onCriteriaChange={this.onCriteriaChange}
            auth={this.props.auth}
          />
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

// const mapDispatchToProps = {
//   loadAlarmAlertByPlateno: actions.loadAlarmAlertByPlateno.request
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Index);
export default connect(mapStateToProps, null)(Index)
