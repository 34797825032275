import { createTypes, createAction } from 'lib/action'
const LOAD_VEHICLE_GPS_DISCONNECT_ON_TRIP_REPORT = createTypes(
  'vehicle_gps_disconnect_on_trip_report',
  'load',
)
const LOAD_GPS_VENDOR = createTypes('gps_vendor_report', 'load')
const EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT = createTypes(
  'exportgpsdisconnectontripreport',
  'load',
)
const EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT_PREVIEW = createTypes(
  'exportgpsdisconnectontripreportpreview',
  'load',
)

const exportGpsDisconnectOnTripReport = {
  request: (data, loading) =>
    createAction(EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT.REQUEST, {
      data,
      loading,
    }),
  success: (lists, page) =>
    createAction(EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT.SUCCESS, { lists }),
  failure: () => createAction(EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT.FAITLURE),
}

const exportGpsDisconnectOnTripReportpreview = {
  request: (data, loading) =>
    createAction(EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT_PREVIEW.REQUEST, {
      data,
      loading,
    }),
  success: (lists, page) =>
    createAction(EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT_PREVIEW.SUCCESS, {
      lists,
    }),
  failure: () =>
    createAction(EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT_PREVIEW.FAITLURE),
}

const loadVehicle = {
  request: (gps_vendor_id, loading_vehicle) =>
    createAction(LOAD_VEHICLE_GPS_DISCONNECT_ON_TRIP_REPORT.REQUEST, {
      gps_vendor_id,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_GPS_DISCONNECT_ON_TRIP_REPORT.SUCCESS, { data }),
  failure: () =>
    createAction(LOAD_VEHICLE_GPS_DISCONNECT_ON_TRIP_REPORT.FAILURE),
}

const loadGpsVendor = {
  request: (accessToken, company_id, is_gps_vendor) =>
    createAction(LOAD_GPS_VENDOR.REQUEST, {
      accessToken,
      company_id,
      is_gps_vendor,
    }),
  success: data => createAction(LOAD_GPS_VENDOR.SUCCESS, { data }),
  failure: () => createAction(LOAD_GPS_VENDOR.FAILURE),
}

export {
  LOAD_VEHICLE_GPS_DISCONNECT_ON_TRIP_REPORT,
  loadVehicle,
  LOAD_GPS_VENDOR,
  loadGpsVendor,
  EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT,
  exportGpsDisconnectOnTripReport,
  EXPORT_GPS_DISCONNECT_ON_TRIP_REPORT_PREVIEW,
  exportGpsDisconnectOnTripReportpreview,
}
