import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import { getlocalstorage } from "./../../../constants/local_storage";

function* loadInsurance(action) {
  const {
    data: {
      page,
      pageSize,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj
    }
  } = action.payload;

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/insurancs/get`,
      {
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        vehicle_visibility
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadInsurance.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadInsurance.failure());
  }
}

function* loadAutocompleteVehiclePlateNo(action) {
  const { url, companyID, accessToken, queryString } = action.payload;

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteVehiclePlateNo.success([]));
    } else {
      const res = yield call(
        axios.post,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}`,
        {
          vehicle_visibility,
          company_id: companyID,
          plate_no: queryString
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteVehiclePlateNo.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteVehiclePlateNo.failure());
  }
}

function* loadAutocompleteVehicleCode(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  const vehicle_visibility = getlocalstorage(
    localStorage.getItem("profile"),
    "vehicle_visibility"
  );

  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteVehicleCode.success([]));
    } else {
      const res = yield call(
        axios.post,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}`,
        {
          vehicle_visibility,
          company_id: companyID,
          code: queryString
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteVehicleCode.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteVehicleCode.failure());
  }
}

function* createInsurance(action) {
  const {
    data: {
      companyID,
      vehicle_plate_no,
      vehicle_code,
      vehicle_id,
      renewal_date,
      expiry_date,
      remind_day,
      description,
      insurance_id,
      type,
      accessToken
    },
    loading
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/insurancs/create`,
      {
        companyID,
        vehicle_plate_no,
        vehicle_code,
        vehicle_id,
        renewal_date,
        expiry_date,
        remind_day,
        description,
        insurance_id,
        type
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.createInsurance.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createInsurance.failure());
  }
}

function* updateInsurance(action) {
  const {
    data: {
      companyID,
      vehicle_plate_no,
      vehicle_code,
      vehicle_id,
      renewal_date,
      expiry_date,
      remind_day,
      description,
      insurance_id,
      type,
      insurances_id,
      accessToken
    },
    loading
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/insurancs/update`,
      {
        companyID,
        vehicle_plate_no,
        vehicle_code,
        vehicle_id,
        renewal_date,
        expiry_date,
        remind_day,
        description,
        insurance_id,
        type,
        insurances_id
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.updateInsurance.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateInsurance.failure());
  }
}

export default function* watchExpenseInsurance() {
  yield all([
    takeEvery(actions.LOAD_INSURANCE.REQUEST, loadInsurance),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_PLATENO.REQUEST,
      loadAutocompleteVehiclePlateNo
    ),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_CODE.REQUEST,
      loadAutocompleteVehicleCode
    ),
    takeEvery(actions.CREATE_INSURANCE.REQUEST, createInsurance),
    takeEvery(actions.UPDATE_INSURANCE.REQUEST, updateInsurance)
  ]);
}
export {
  loadInsurance,
  loadAutocompleteVehicleCode,
  loadAutocompleteVehiclePlateNo,
  updateInsurance,
  createInsurance
};
