import { createTypes, createAction } from "lib/action";
const LOAD_DAILY_REQUEST_MATERIAL = createTypes("queuerequestmaterial", "load");

const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadDailyRequestMaterial = {
    request: (data, loading) =>
        createAction(LOAD_DAILY_REQUEST_MATERIAL.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_DAILY_REQUEST_MATERIAL.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_DAILY_REQUEST_MATERIAL.FAITLURE)  
};

export {
    LOAD_DAILY_REQUEST_MATERIAL, loadDailyRequestMaterial,
    REMOVE_LISTS, removeLists
};