import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Table,
} from 'antd'

import LangContext from 'modules/shared/context/langContext'
import difference from 'lodash/difference'
import BasicFilterReport from '../../basicFilterReport/Index'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <div
          onClick={() => {
            restProps.onChange(restProps.dataSource.map(item => item.key))
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
        <div
          onClick={() => {
            restProps.onChange([])
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
          //   hideDefaultSelections: true,
          //   selections: [
          //     {
          //       //   key: 'all-data',
          //       text: 'Select All Data',
          //       onSelect: (record, selected, selectedRows, nativeEvent) => {
          //         let getKey = []
          //         filteredItems.map(item => getKey.push(item.key))
          //         onItemSelectAll([...getKey], true)
          //       },
          //     },
          //   ],
        }

        return (
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 100 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

export default class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    clickMenu: '',
  }

  componentDidMount() {
    if (this.props.call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.list_vehicle_id,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    setFieldValue('vehicle_type_id', value)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleList } = nextProps
    const vehlist = []
    vehicleList.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleMenuClick = (setFieldValue, handleSubmit) => {
    setFieldValue('type_file', 'excel', false)
    handleSubmit()
  }

  handleClicReportkPreview = values => {
    const COMPANY_ID = this.props.auth.profile.company_id
    const USER_ID = this.props.auth.profile.id
    const vehicle_visibility = this.props.auth.vehicle_visibility

    const date_start = moment(values.eventdate[0]['_d']).format(
      'YYYY-MM-DD HH:mm:00',
    )
    const date_end = moment(values.eventdate[1]['_d']).format(
      'YYYY-MM-DD HH:mm:59',
    )
    const filter = {
      date_start,
      date_end,
      list_vehicle_id: values.list_vehicle_id,
      company_id: COMPANY_ID,
      vehicle_type_id: values.vehicle_type_id,
      user_id: USER_ID,
      type_file: 'preview',
      vehicle_visibility,
    }

    window.open('/report/drivinglicensescanlog/preview', '_blank')
    localStorage.setItem(
      'drivinglicensescanlogreport-filter',
      JSON.stringify(filter),
    )
  }

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      drivinglicensescanlogLoading,
      onSubmit,
    } = this.props
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <LangContext.Consumer>
        {i18n => (
          <div>
            <Spin spinning={drivinglicensescanlogLoading}>
              <Formik
                initialValues={{
                  vehicle_type_id: '',
                  eventdate: [
                    moment('00:00', 'HH:mm'),
                    moment('23:59', 'HH:mm'),
                  ],
                  list_vehicle_id: [],
                  division: this.props.selectedDivision,
                  department: this.props.selectedDepartment,
                  section: this.props.selectedSection,
                  plant: this.props.selectedPlant,
                  vehicleGroup: this.props.selectedVehicleGroup,
                }}
                validate={values => {
                  let errors = {}
                  if (values.eventdate.length > 0) {
                    const date_start = moment(values.eventdate[0]['_d'])
                    const date_end = moment(values.eventdate[1]['_d'])
                    let day_diff = date_end.diff(date_start, 'months')
                    if (day_diff > 0) {
                      errors.eventdate = i18n.o.overOneMonth
                    }
                  }

                  return errors
                }}
                validationSchema={yup.object().shape({
                  eventdate: yup.array().required('กรุณาเลือกช่วงวันที่'),
                  list_vehicle_id: yup
                    .array()
                    .required(i18n.p.pleaseChooseVehicle),
                })}
                onSubmit={values => {
                  //console.log(values);
                  const loading = true
                  if (this.state.clickMenu === 'preview') {
                    this.handleClicReportkPreview(values)
                  } else {
                    onSubmit(values, loading)
                  }
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                }) => (
                  <Row type="flex" justify="center">
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        {i18n.d.DrivinglicensescanlogReport}
                      </div>
                      <BasicFilterReport
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                        division={this.props.division}
                        updateSelectDivision={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectDivision(e, values)
                          })
                        }}
                        department={this.props.department}
                        updateSelectDepartment={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectDepartment(e, values)
                          })
                        }}
                        section={this.props.section}
                        updateSelectSection={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectSection(e, values)
                          })
                        }}
                        plant={this.props.plant}
                        updateSelectPlant={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectPlant(e, values)
                          })
                        }}
                        vehicleGroup={this.props.vehicleGroup}
                        updateSelectVehicleGroup={e => {
                          this.setState({ vehicleSelect: [] }, () => {
                            setFieldValue('list_vehicle_id', [])
                            this.props.updateSelectVehicleGroup(e, values)
                          })
                        }}
                      />
                      <FormItem
                        required={true}
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'warning'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div style={{ width: '750px' }}>
                          <TableTransfer
                            dataSource={this.props.vehicleList}
                            targetKeys={this.state.vehicleSelect}
                            showSearch
                            onChange={targetKeys => {
                              this.tranferChange(targetKeys, setFieldValue)
                            }}
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                            leftColumns={[
                              {
                                dataIndex: 'name',
                                title: (
                                  <div className="regular-bold-14">
                                    <LangContext.Consumer>
                                      {i18n => i18n.i.itemLeft}
                                    </LangContext.Consumer>
                                  </div>
                                ),
                                posiction: 'Left',
                              },
                            ]}
                            rightColumns={[
                              {
                                dataIndex: 'name',
                                title: (
                                  <div className="regular-bold-14">
                                    <LangContext.Consumer>
                                      {i18n => i18n.i.itemSelected}
                                    </LangContext.Consumer>
                                  </div>
                                ),
                                posiction: 'Right',
                              },
                            ]}
                            onSelectChange={this.handleSelectChange}
                          />
                        </div>
                      </FormItem>
                      {this.props.call !== 'schedule' ? (
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.d.dateRange}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.eventdate && errors.eventdate && 'warning'
                          }
                          help={touched.eventdate && errors.eventdate}
                        >
                          <RangePicker
                            disabledDate={disabledDate}
                            showTime={{ format: 'HH:mm' }}
                            onChange={value =>
                              setFieldValue('eventdate', value)
                            }
                            format="DD/MM/YYYY HH:mm"
                            placeholder={['Start Time', 'End Time']}
                            defaultValue={[
                              moment('00:00', 'HH:mm'),
                              moment('23:59', 'HH:mm'),
                            ]}
                          />
                        </Form.Item>
                      ) : (
                        ''
                      )}
                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call == 'schedule' ? (
                            <Col span={8} style={{ marginTop: '10px' }}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            <div>
                              {' '}
                              <Col span={8}>
                                <Button type="defualt" block>
                                  <Link to="/">
                                    <LangContext.Consumer>
                                      {i18n => i18n.c.cancel}
                                    </LangContext.Consumer>
                                  </Link>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  block
                                  onClick={() =>
                                    this.setState({
                                      clickMenu: 'preview',
                                    })
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.preview}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    this.setState({
                                      clickMenu: 'report',
                                    })
                                    this.handleMenuClick(
                                      setFieldValue,
                                      handleSubmit,
                                    )
                                  }}
                                  block
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.r.report}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                            </div>
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  </Row>
                )}
              />
            </Spin>
          </div>
        )}
      </LangContext.Consumer>
    )
  }
}
