import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadMasterDataPlace(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      is_admin,
      orderType,
      accessToken,
      location_visibility,
      filterObj,
      type,
      file_value_pdf,
      relate_company,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataPlace`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        is_admin,
        orderBy,
        orderType,
        location_visibility,
        filterObj,
        type,
        file_value_pdf,
        relate_company,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadMasterDataPlace.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadMasterDataPlace.failure())
  }
}

function* exportMasterDataPlace(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      is_admin,
      orderType,
      accessToken,
      location_visibility,
      filterObj,
      type,
      file_value_pdf,
      limit_export,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataPlace`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        is_admin,
        orderBy,
        orderType,
        location_visibility,
        filterObj,
        type,
        file_value_pdf,
        limit_export,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportMasterDataPlace.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportMasterDataPlace.failure())
  }
}

function* createMasterDataPlace(action) {
  const {
    data: {
      companyID,
      type,
      facillityName1,
      facillityName2,
      facillityName3,
      facillityCode,
      resultLat,
      resultLng,
      geoFene,
      rad,
      address,
      provinces,
      district,
      subdistrict,
      postal_code,
      description,
      sendSMS,
      vender_id,
      run_gueue,
      upload_point,
      isupply_interface_active,
      action_name,
      matching_name,
      allow_interface_active,
      interface_with,
      business,
      product,
      phone,
      dataVisibilityNode,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createLocation`,
      {
        companyID,
        type,
        facillityName1,
        facillityName2,
        facillityName3,
        facillityCode,
        resultLat,
        resultLng,
        geoFene,
        rad,
        address,
        provinces,
        district,
        subdistrict,
        postal_code,
        description,
        sendSMS,
        vender_id,
        run_gueue,
        upload_point,
        isupply_interface_active,
        action_name,
        matching_name,
        allow_interface_active,
        interface_with,
        business,
        product,
        phone,
        dataVisibilityNode,
        accessToken,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createMasterDataPlace.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createMasterDataPlace.failure())
  }
}

function* loadDetailMasterDataPlace(action) {
  const {
    data: { id, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/detailMasterDataPlace/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )

    yield put(actions.loadDetailMasterDataPlace.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDetailMasterDataPlace.failure())
  }
}

function* upDateDetailMasterDataPlace(action) {
  const {
    data: {
      location_id,
      companyID,
      type,
      facillityName1,
      facillityName2,
      facillityName3,
      facillityCode,
      resultLat,
      resultLng,
      geoFene,
      rad,
      address,
      provinces,
      district,
      subdistrict,
      postal_code,
      description,
      sendSMS,
      run_queue,
      vender_id,
      isupply_interface_active,
      action_name,
      matching_name,
      allow_interface_active,
      interface_with,
      business,
      product,
      phone,
      dataVisibilityNode,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/editMasterDataPlace`,
      {
        location_id,
        companyID,
        type,
        facillityName1,
        facillityName2,
        facillityName3,
        facillityCode,
        resultLat,
        resultLng,
        geoFene,
        rad,
        address,
        provinces,
        district,
        subdistrict,
        postal_code,
        description,
        run_queue,
        sendSMS,
        vender_id,
        isupply_interface_active,
        action_name,
        matching_name,
        allow_interface_active,
        interface_with,
        business,
        product,
        dataVisibilityNode,
        phone,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.upDateDetailMasterDataPlace.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.upDateDetailMasterDataPlace.failure())
  }
}

function* createSalesClose(action) {
  const {
    data: { files, user_id, accessToken },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('user_id', user_id)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/precast-create-sales-close`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    loading(res.data)
    yield put(actions.createSalesClose.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.createSalesClose.failure())
  }
}

function* importLocation(action) {
  const {
    data: {
      files,
      typeLocation,
      companyID,
      accessToken,
      typeImport,
      allow_interface_active,
      interface_with,
      isupply_interface_active,
      business,
      product,
      run_gueue,
    },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  console.log('location uploadFile : ', uploadFile)

  formData.append('files', uploadFile)
  formData.append('location_type', typeLocation)
  formData.append('companyID', companyID)
  formData.append('typeImport', typeImport)
  formData.append('allow_interface_active', allow_interface_active)
  formData.append('interface_with', interface_with)
  formData.append('isupply_interface_active', isupply_interface_active)
  formData.append('business', business)
  formData.append('product', product)
  formData.append('run_gueue', run_gueue)
  console.log('formData loaction', formData)
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/location/import`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    loading(res.data)
    yield put(actions.importLocation.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.importLocation.failure())
  }
}

function* importMultipleLocation(action) {
  const {
    data: {
      files,
      typeLocation,
      companyID,
      accessToken,
      typeImport,
      allow_interface_active,
      interface_with,
      isupply_interface_active,
      business,
      product,
      run_gueue,
    },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('location_type', typeLocation)
  formData.append('companyID', companyID)
  formData.append('typeImport', typeImport)
  formData.append('allow_interface_active', allow_interface_active)
  formData.append('interface_with', interface_with)
  formData.append('isupply_interface_active', isupply_interface_active)
  formData.append('business', business)
  formData.append('product', product)
  formData.append('run_gueue', run_gueue)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/location/import-multiple`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    loading(res.data)

    yield put(actions.importMultipleLocation.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.importMultipleLocation.failure())
  }
}

export default function* watchPlaceMasterData() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_PLACE.REQUEST, loadMasterDataPlace),
    takeEvery(actions.CREATE_MASTERDATAPLACE.REQUEST, createMasterDataPlace),
    takeEvery(
      actions.LOAD_DETAILMASTERDATA_PLACE.REQUEST,
      loadDetailMasterDataPlace,
    ),
    takeEvery(
      actions.UPDATE_DETAILMASTERDATA_PLACE.REQUEST,
      upDateDetailMasterDataPlace,
    ),
    takeEvery(actions.CREATE_SALES_CLOSE.REQUEST, createSalesClose),
    takeEvery(actions.EXPORT_MASTERDATA_PLACE.REQUEST, exportMasterDataPlace),
    takeEvery(actions.IMPORT_LOCATION.REQUEST, importLocation),
    takeEvery(actions.IMPORT_MULTIPLE_LOCATION.REQUEST, importMultipleLocation),
  ])
}
export {
  loadMasterDataPlace,
  createMasterDataPlace,
  loadDetailMasterDataPlace,
  upDateDetailMasterDataPlace,
  createSalesClose,
  exportMasterDataPlace,
  importLocation,
  importMultipleLocation,
}
