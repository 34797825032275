import { createTypes, createAction } from "lib/action";
const LOAD_REQUEST_DELIVERY = createTypes("isupplydelivery", "load");
const LOAD_DATA_FOR_EDIT = createTypes("isupplydeliveryedit", "load");
const UPDATE_TRICKET = createTypes("isupplydeliveryedit", "update");

const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}

const loadRequestDelivery = {
    request: (data, loading) =>
        createAction(LOAD_REQUEST_DELIVERY.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_REQUEST_DELIVERY.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_REQUEST_DELIVERY.FAITLURE)  
};

const loadDataForEdit = {
    request: (data, loading) =>
        createAction(LOAD_DATA_FOR_EDIT.REQUEST, { data, loading}),
    success: (lists) =>
        createAction(LOAD_DATA_FOR_EDIT.SUCCESS, {lists}),
    failure: () => createAction(LOAD_DATA_FOR_EDIT.FAILURE)
};

const updateTricket = {
    request: (data, loading) =>
        createAction(UPDATE_TRICKET.REQUEST, { data, loading}),
    success: res => createAction(UPDATE_TRICKET.SUCCESS, { res }),
        failure: () => createAction(UPDATE_TRICKET.FAILURE)
  }

export {
    LOAD_REQUEST_DELIVERY, loadRequestDelivery,
    LOAD_DATA_FOR_EDIT, loadDataForEdit,
    UPDATE_TRICKET, updateTricket,
    REMOVE_LISTS, removeLists
};