import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, DatePicker, Typography, Divider } from "antd";
import moment from "moment";

import { GeneralStyledContent } from "../../../../styled/common-styled";
import EngineSummaryGraph from "./EngineSummaryGraph";
import SafetyGraph from "./SafetyGraph";
import ParkingGraph from "./ParkingGraph";
import PieChart from "./PieChart"

const { RangePicker } = DatePicker;
const { Title } = Typography;

const dateFormat = "YYYY/MM/DD";

const genHeader = (title, value) => {
  return (
    <Fragment>
      <Row>
        <Col>{title}</Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "right" }}>{value}</Col>
      </Row>
    </Fragment>
  );
};

export class Layout extends Component {
  render() {
    return (
      <GeneralStyledContent>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row gutter={[16, 8]}>
              <Col span={8}>
                <Title level={4}>สรุป ณ วันที่ 01/10/2020 ถึง 10/10/2020</Title>
              </Col>
              <Col span={12} offset={4}>
                <Row gutter={[16, 8]}>
                  <Col offset={6} span={6}>
                    <Title level={4}>กรุณาเลือกช่วงวัน</Title>
                  </Col>
                  <Col span={12}>
                    <RangePicker
                      defaultValue={[
                        moment("2020/01/01", dateFormat),
                        moment("2020/01/01", dateFormat),
                      ]}
                      format={dateFormat}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card
              size="small"
              title="การใช้ประโยชน์รถ (413 คัน)"
              style={{ width: "100%" }}
            >
              <Row gutter={[8, 8]}>
                <Col span={24} style={{ height: "400px" }}>
                  <EngineSummaryGraph />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              size="small"
              title="สรุปการขับขี่แบบปลอดภัย"
              style={{ width: "100%" }}
            >
              <Row gutter={[8, 8]}>
                <Col span={24} style={{ height: "400px" }}>
                  <SafetyGraph />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              size="small"
              title="สรุปการจอดรถไม่ดับเครื่อง"
              style={{ width: "100%" }}
            >
              <Row gutter={[8, 8]}>
                <Col span={24} style={{ height: "400px" }}>
                  <ParkingGraph />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>


        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              size="small"
              title="ส่งตรงเวลา"
              style={{ width: "100%" }}
            >
              <Row gutter={[8, 8]}>
                <Col span={24} style={{ height: "400px" }}>
                  <PieChart value={1} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              size="small"
              title="เทปูนนอกพื้นที่"
              style={{ width: "100%" }}
            >
              <Row gutter={[8, 8]}>
                <Col span={24} style={{ height: "400px" }}>
                  <PieChart value={2}/>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card
              size="small"
              title={genHeader("อัตราการใช้น้ำมันเฉลี่ย", "3.39 กม./ลิตร")}
              style={{ width: "100%" }}
            >
              <Row gutter={[16, 8]}>
                <Col span={12}>ระยะทางรวม</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  140,984.4 กม.
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={12}>ปริมาณการใช้น้ำมันรวม</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  41,575.1 ลิตร
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              size="small"
              title={genHeader("จอดไม่ดับเครื่องรวม", "14,523 ชม. 0 นาที")}
              style={{ width: "100%" }}
            >
              <Row gutter={[16, 8]}>
                <Col span={12}>น้ำมันใช้ไป</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  11,492.3 ลิตร
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={12}>คิดเป็นเงิน (B10)</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  213,642 บาท
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              size="small"
              title={genHeader("ความเร็วเกินค่าปลอดภัย", "25 คัน")}
              style={{ width: "100%" }}
            >
              <Row gutter={[16, 8]}>
                <Col span={12}>ความเร็วเกิน(รวม)</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  13 ชม. 18 นาที
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={12}>ความเร็วสูงสุด</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  254 กม./ชม.
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </GeneralStyledContent>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
