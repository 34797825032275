import { createTypes, createAction } from 'lib/action'
const LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION = createTypes(
  'vehiclevisibilitypermission',
  'load',
)

const LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_LOCATION = createTypes(
  'vehiclevisibilitypermissionlocation',
  'load',
)

const LOAD_MANAGEMENT_VEHICLE_VISIBILITY_SEARCH = createTypes(
  'vehiclevisibilitypermissionsearch',
  'search',
)

const loadManagementVehicleVisibilityPermission = {
  request: (data, loading) => {
    return createAction(LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION.REQUEST, {
      data,
      loading,
    })
  },
  success: (data, page) => {
    return createAction(LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION.SUCCESS, {
      data,
      page,
    })
  },
  failure: () =>
    createAction(LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION.FAITLURE),
}

const loadManagementVehicleVisibilityPermissionLocation = {
  request: (data, loading) => {
    return createAction(
      LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_LOCATION.REQUEST,
      {
        data,
        loading,
      },
    )
  },
  success: (data, page) => {
    return createAction(
      LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_LOCATION.SUCCESS,
      {
        data,
        page,
      },
    )
  },
  failure: () =>
    createAction(
      LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_LOCATION.FAITLURE,
    ),
}

const loadManagementVehicleVisibilityPermissionSearch = {
  request: (data, loading) => {
    return createAction(LOAD_MANAGEMENT_VEHICLE_VISIBILITY_SEARCH.REQUEST, {
      data,
      loading,
    })
  },
  success: (data, page) => {
    return createAction(LOAD_MANAGEMENT_VEHICLE_VISIBILITY_SEARCH.SUCCESS, {
      data,
      page,
    })
  },
  failure: () =>
    createAction(LOAD_MANAGEMENT_VEHICLE_VISIBILITY_SEARCH.FAITLURE),
}

export {
  loadManagementVehicleVisibilityPermission,
  LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION,
  loadManagementVehicleVisibilityPermissionLocation,
  LOAD_MANAGEMENT_VEHICLE_VISIBILITY_PERMISSION_LOCATION,
  loadManagementVehicleVisibilityPermissionSearch,
  LOAD_MANAGEMENT_VEHICLE_VISIBILITY_SEARCH,
}
