import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import history from '../../../../../lib/history'
import { Formik } from 'formik'
import * as yup from 'yup'
import * as moment from 'moment'
import { debounce } from 'debounce'
import {
  Row,
  Col,
  Radio,
  AutoComplete,
  Button,
  Divider,
  TimePicker,
  Checkbox,
  Form,
  Input,
  Select,
  Spin,
  Icon,
  Switch,
  Slider,
  Tag,
  message,
} from 'antd'
import { FaMapMarkerAlt, FaMap } from 'react-icons/fa'
import { Link, BrowserRouter as Router, withRouter } from 'react-router-dom'

import LangContext, { i18n } from 'modules/shared/context/langContext'
import { autoGenKey } from '../../../../../lib/helper'
//import AreaAndLocation from "./AreaAndLocation"
const ButtonGroup = Button.Group
const InputGroup = Input.Group
const { Item } = Form
const FormItem = Item
const { Option, OptGroup } = Select
const speedMarks = {
  5: '5 km/h',
  40: '40 km/h',
  90: '90 km/h',
  // 120: "120 km/h",
  140: '140 km/h',
  200: '200 km/h',
}
const durationMarks = {
  // 20: "20 min",
  30: '30m',
  60: '1hr',
  120: '2hr',
  180: '3hr',
  240: '4hr',
  300: '5hr',
  360: '6hr',
  420: '7hr',
  480: '8hr',
}

const durationZoomMarks = {
  // 20: "20 min",
  1: '1 sec',
  15: '15 sec',
  30: '30 sec',
  45: '45 sec',
  55: '55 sec',
}

const eventDetail = {
  engine: { toggle: true },
  driving_distance: { toggle: false, unit: 'metre' },
  speed: { toggle: false, unit: 'km/h' },
  id_card_swiping: { toggle: true },
  fuel: { toggle: false, unit: 'litre' },
  temperature: { toggle: false, unit: 'celsius' },
  pto: { toggle: true },
  boom: { toggle: true },
  sos_driver: { toggle: true },
  sos_passenger: { toggle: true },
  taxi_meter: { toggle: true },
}

const selectBefore = disabled => (
  <Select defaultValue=">" style={{ width: 130 }} disabled={disabled}>
    <Option value=">">{'>'} </Option>
    <Option value="<">{'<'} </Option>
    <Option value="=">{'='} </Option>
    <Option value="undefiend">อ่านค่าไม่ได้</Option>
  </Select>
)
const selectAfter = value => (
  <Select value={value} style={{ width: 80 }} readonly={true}>
    <Option value="metre">metre</Option>
    <Option value="km/h">km/h</Option>
    <Option value="celsius">°C</Option>
    <Option value="litre">Litre</Option>
  </Select>
)

class ConditionForm extends Component {
  state = {
    duration_range: [1, 60],
    duration_zoom_range: [15, 45],
    duration_unit: 'minute',
  }

  componentDidMount() {}

  disablePolicyTypeSelection = (previousTypeName, typeName) => {
    if (previousTypeName == typeName) {
      if (previousTypeName == 'speed_limit')
        this.props.handlePropsChange({
          has_speed_limit_type: !this.props.has_speed_limit_type,
        })

      if (previousTypeName == 'duration')
        this.props.handlePropsChange({
          has_duration_type: !this.props.has_duration_type,
        })

      if (previousTypeName == 'day_time')
        this.props.handlePropsChange({
          has_day_time_type: !this.props.has_day_time_type,
        })
    } else {
      if (typeName == 'speed_limit')
        this.props.handlePropsChange({
          has_speed_limit_type: true,
        })

      if (previousTypeName == 'speed_limit')
        this.props.handlePropsChange({
          has_speed_limit_type: false,
        })
      if (typeName == 'duration')
        this.props.handlePropsChange({
          has_duration_type: true,
        })
      if (previousTypeName == 'duration')
        this.props.handlePropsChange({
          has_duration_type: false,
        })
      if (typeName == 'day_time')
        this.props.handlePropsChange({
          has_day_time_type: true,
        })
      if (previousTypeName == 'day_time')
        this.props.handlePropsChange({
          has_day_time_type: false,
        })
    }
  }

  handlePolicyTypeChange = (value, keyName) => {
    // setFieldValue("trigger", e);
    let result = this.props.conditionList.map(ele => {
      if (ele.key == this.props.formId) {
        return {
          ...ele,
          initialValues: {
            ...this.props.jsonDetailSkeleton[value],
            [keyName]: value,
          },
        }
      } else {
        return ele
      }
    })

    this.props.handlePropsChange({
      conditionList: result,
    })
  }

  handleInputChange = (value, keyName) => {
    // setFieldValue("trigger", e);
    let result = this.props.conditionList.map(ele => {
      if (ele.key == this.props.formId) {
        return {
          ...ele,
          initialValues: {
            ...ele.initialValues,
            [keyName]: value,
          },
        }
      } else {
        return ele
      }
    })

    this.props.handlePropsChange({
      conditionList: result,
    })
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...this.props.initialValues,
          event: 'engine',
          duration_unit: 'minute',
        }}
        validationSchema={yup.lazy(value => {})}
        onSubmit={(values, actions) => {}}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <div>
            <Row>
              <Col span={24}>
                {this.props.conditionList.length >= 1 &&
                  this.props.conditionList[0].key != this.props.formId && (
                    <Divider>
                      <Radio.Group defaultValue={'and'}>
                        <Radio.Button value="and">AND</Radio.Button>
                        {/* <Radio.Button value="or">OR</Radio.Button> */}
                      </Radio.Group>
                    </Divider>
                  )}

                <div className="policy_form_delete_button">
                  <Button
                    type="danger"
                    size="small"
                    shape="circle"
                    onClick={e => {
                      this.disablePolicyTypeSelection(
                        values.policy_type,
                        values.policy_type,
                      )
                      this.props.removeCondition(this.props.formId)
                    }}
                  >
                    <Icon type="delete" />
                  </Button>
                </div>
                <div className="font_bg_big">
                  {(
                    this.props.conditionList
                      .map(ele => ele.key)
                      .indexOf(this.props.formId) +
                    1 +
                    9
                  )
                    .toString(36)
                    .toUpperCase()}
                </div>

                <Col span={5}>
                  <Form.Item label="ประเภท">
                    <Select
                      key={autoGenKey('condition-select')}
                      name="policy_type"
                      defaultValue={values.policy_type}
                      value={values.policy_type.toString()}
                      onChange={e => {
                        this.handlePolicyTypeChange(e, 'policy_type')
                        this.disablePolicyTypeSelection(values.policy_type, e)
                      }}
                    >
                      <Option key="area_location">
                        <LangContext.Consumer>
                          {i18n => 'พื้นที่'}
                        </LangContext.Consumer>
                      </Option>
                      <Option
                        key="speed_limit"
                        disabled={this.props.has_speed_limit_type}
                      >
                        <LangContext.Consumer>
                          {i18n => 'ความเร็ว'}
                        </LangContext.Consumer>
                      </Option>
                      <Option
                        key="duration"
                        disabled={this.props.has_duration_type}
                      >
                        <LangContext.Consumer>
                          {i18n => 'ระยะเวลา'}
                        </LangContext.Consumer>
                      </Option>
                      <Option
                        key="day_time"
                        disabled={this.props.has_day_time_type}
                      >
                        <LangContext.Consumer>
                          {i18n => 'วัน/เวลา'}
                        </LangContext.Consumer>
                      </Option>
                      <Option key="input">
                        <LangContext.Consumer>
                          {i18n => 'เหตุการณ์ & เซนเซอร์'}
                        </LangContext.Consumer>
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                {values.policy_type == 'area_location' ? (
                  <Fragment>
                    <Col span={3} offset={1}>
                      <Form.Item label="ทริกเกอร์เมื่อ">
                        <Switch
                          checkedChildren="อยู่ใน"
                          unCheckedChildren="อยู่นอก"
                          defaultChecked
                          onChange={e => this.handleInputChange(e, 'trigger')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} offset={1}>
                      <Form.Item label="พื้นที่">
                        <Select
                          mode="multiple"
                          style={{ minWidth: '140px' }}
                          placeholder="Please select"
                          // defaultValue={["a10", "c12"]}
                          onChange={e => this.handleInputChange(e, 'area_id')}
                          maxTagCount={10}
                        >
                          <OptGroup label="ชนิดสถานที่">
                            <Option key={'lt_Plant'}>
                              <FaMapMarkerAlt /> จุดโหลดของ
                            </Option>
                            <Option key={'lt_Site'}>
                              <FaMapMarkerAlt /> จุดส่งของ
                            </Option>
                            <Option key={'lt_ParkingLot'}>
                              <FaMapMarkerAlt /> ลานจอดรถ
                            </Option>
                            <Option key={'lt_RestArea'}>
                              <FaMapMarkerAlt /> จุดพักรถ
                            </Option>
                            <Option key={'lt_GasStation'}>
                              <FaMapMarkerAlt /> ปั๊มน้ำมัน
                            </Option>
                            <Option key={'lt_CarService'}>
                              <FaMapMarkerAlt /> ศูนย์บริการ
                            </Option>
                            <Option key={'lt_BS'}>
                              <FaMapMarkerAlt /> Bus Stop Point
                            </Option>
                            {/* <Option key={"BS1"}>
                              <FaMapMarkerAlt /> Bus Stop 1
                            </Option>
                            <Option key={"BS2"}>
                              <FaMapMarkerAlt /> Bus Stop 2
                            </Option> */}
                            <Option key={'lt_BS1'}>
                              <FaMapMarkerAlt /> สถานที่ชั่วคราว
                            </Option>
                            <Option key={'lt_NoParking'}>
                              <FaMapMarkerAlt /> จุดห้ามจอด
                            </Option>
                          </OptGroup>
                          <OptGroup label="พื้นที่">
                            {this.props.zoneList.map(ele => (
                              <Option key={ele.id}>
                                {' '}
                                <FaMap /> {ele.name}
                              </Option>
                            ))}
                          </OptGroup>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Fragment>
                ) : values.policy_type == 'speed_limit' ? (
                  <Fragment>
                    <Col span={3} offset={1}>
                      <Form.Item label="ทริกเกอร์เมื่อ">
                        <Switch
                          checkedChildren="อยู่ในช่วง"
                          unCheckedChildren="อยู่นอกช่วง"
                          defaultChecked
                          onChange={e => this.handleInputChange(e, 'trigger')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={13} offset={1}>
                      <Form.Item label="ความเร็ว">
                        <Slider
                          marks={speedMarks}
                          range={true}
                          defaultValue={[90, 120]}
                          max={250}
                          min={1}
                          onChange={debounce(e => {
                            this.handleInputChange(e, 'range')
                          }, 500)}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                ) : values.policy_type == 'duration' ? (
                  <Fragment>
                    <Col span={3} offset={1}>
                      <Form.Item label={'ทริกเกอร์เมื่อ'}>
                        <Switch
                          checkedChildren="อยู่ในช่วง"
                          unCheckedChildren="อยู่นอกช่วง"
                          defaultChecked
                          onChange={e => this.handleInputChange(e, 'trigger')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={13} offset={1}>
                      <Form.Item
                        label={
                          <Fragment>
                            <Button
                              size="small"
                              shape="circle"
                              icon="search"
                              onClick={e => {
                                this.setState({
                                  duration_unit:
                                    this.state.duration_unit == 'minute'
                                      ? 'second'
                                      : 'minute',
                                })
                                setFieldValue(
                                  'duration_unit',
                                  values.duration_unit == 'minute'
                                    ? 'second'
                                    : 'minute',
                                )
                              }}
                            />{' '}
                            ระยะเวลา
                          </Fragment>
                        }
                      >
                        <Slider
                          marks={
                            this.state.duration_unit == 'minute'
                              ? durationMarks
                              : durationZoomMarks
                          }
                          range={true}
                          onChange={debounce(e => {
                            this.handleInputChange(e, 'range')
                            this.setState({ duration_range: e })
                          }, 500)}
                          defaultValue={
                            this.state.duration_unit == 'minute'
                              ? this.state.duration_range
                              : this.state.duration_zoom_range
                          }
                          // value={
                          //   values.duration_unit == "minute"
                          //     ? this.state.duration_range
                          //     : this.state.duration_zoom_range
                          // }
                          max={this.state.duration_unit == 'minute' ? 540 : 59}
                          min={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="จะเริ่มนับระยะเวลาใหม่เมื่อเกิดเหตุการณ์">
                        <Select
                          key={autoGenKey('condition-select')}
                          // name="policy_type"
                          defaultValue={'-'}
                          // value={values.policy_type.toString()}
                          // onChange={e => {
                          //   setFieldValue("policy_type", e);
                          // }}
                          disabled={this.state.duration_unit == 'second'}
                        >
                          <Option key="-">
                            <LangContext.Consumer>
                              {i18n => '-'}
                            </LangContext.Consumer>
                          </Option>
                          <Option
                            key="rest-15-min"
                            disabled={this.state.duration_range[1] <= 15}
                          >
                            <LangContext.Consumer>
                              {i18n => 'จอดพาหนะมากกว่า 15 นาที'}
                            </LangContext.Consumer>
                          </Option>
                          <Option
                            key="rest-30-min"
                            disabled={this.state.duration_range[1] <= 30}
                          >
                            <LangContext.Consumer>
                              {i18n => 'จอดพาหนะมากกว่า 30 นาที'}
                            </LangContext.Consumer>
                          </Option>
                          <Option
                            key="rest-1-hour"
                            disabled={this.state.duration_range[1] <= 60}
                          >
                            <LangContext.Consumer>
                              {i18n => 'จอดพาหนะมากกว่า 1 ชั่วโมง'}
                            </LangContext.Consumer>
                          </Option>
                          <Option
                            key="rest-2-hour"
                            disabled={this.state.duration_range[1] <= 120}
                          >
                            <LangContext.Consumer>
                              {i18n => 'จอดพาหนะมากกว่า 2 ชั่วโมง'}
                            </LangContext.Consumer>
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Fragment>
                ) : values.policy_type == 'day_time' ? (
                  <Fragment>
                    <Col span={17} offset={1}>
                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {i18n => 'วันที่อนุญาต'}
                          </LangContext.Consumer>
                        }
                      >
                        <Checkbox
                          defaultChecked={false}
                          // checked={values.plant}
                          onChange={e => {
                            this.handleInputChange(e.target.checked, 'monday')
                            // setFieldValue("plant", e.target.checked);
                            // this.props.setInitValue({
                            //   ...this.props.initialValues,
                            //   plant: e.target.checked
                            // });
                          }}
                        >
                          จันทร์
                        </Checkbox>

                        <Checkbox
                          defaultChecked={false}
                          // checked={values.site}
                          onChange={e => {
                            this.handleInputChange(e.target.checked, 'tuesday')
                          }}
                        >
                          อังคาร
                        </Checkbox>

                        <Checkbox
                          defaultChecked={false}
                          // checked={values.parkingLot}
                          onChange={e => {
                            this.handleInputChange(
                              e.target.checked,
                              'wendnesday',
                            )
                          }}
                        >
                          พุธ
                        </Checkbox>

                        <Checkbox
                          defaultChecked={false}
                          // checked={values.restArea}
                          onChange={e => {
                            this.handleInputChange(e.target.checked, 'thursday')
                          }}
                        >
                          พฤหัสบดี
                        </Checkbox>

                        <Checkbox
                          defaultChecked={false}
                          // checked={values.gasStation}
                          onChange={e => {
                            this.handleInputChange(e.target.checked, 'friday')
                          }}
                        >
                          ศุกร์
                        </Checkbox>

                        <Checkbox
                          defaultChecked={false}
                          // checked={values.carService}
                          onChange={e => {
                            this.handleInputChange(e.target.checked, 'saturday')
                          }}
                        >
                          เสาร์
                        </Checkbox>
                        <Checkbox
                          defaultChecked={false}
                          // checked={values.carService}
                          onChange={e => {
                            this.handleInputChange(e.target.checked, 'sunday')
                          }}
                        >
                          อาทิตย์
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item label="ทริกเกอร์เมื่อ">
                        <Switch
                          checkedChildren="อยู่ในช่วง"
                          unCheckedChildren="อยู่นอกช่วง"
                          defaultChecked
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {i18n => 'เวลาเริ่ม'}
                          </LangContext.Consumer>
                        }
                      >
                        <TimePicker
                          defaultValue={moment('00:00:00', 'HH:mm:ss')}
                          defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                          onChange={(e, timeStr) =>
                            this.handleInputChange(timeStr, 'startAt')
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {i18n => 'เวลาสิ้นสุด'}
                          </LangContext.Consumer>
                        }
                      >
                        <TimePicker
                          defaultValue={moment('23:59:59', 'HH:mm:ss')}
                          defaultOpenValue={moment('00:59:59', 'HH:mm:ss')}
                          onChange={(e, timeStr) =>
                            this.handleInputChange(timeStr, 'endAt')
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                ) : values.policy_type == 'input' ? (
                  <Fragment>
                    <Col span={5} offset={1}>
                      <Form.Item label="เหตุการณ์">
                        <Select
                          key={autoGenKey('condition-select')}
                          // name="policy_type"
                          defaultValue={values.event}
                          // value={values.policy_type.toString()}
                          onChange={e => {
                            setFieldValue('event', e)
                          }}
                        >
                          <OptGroup label="เหตุการณ์">
                            <Option key="engine">
                              <LangContext.Consumer>
                                {i18n => 'สถานะเครื่องยนต์'}
                              </LangContext.Consumer>
                            </Option>
                            <Option key="id_card_swiping">
                              <LangContext.Consumer>
                                {i18n => 'สถานะการรูดใบขับขี่'}
                              </LangContext.Consumer>
                            </Option>
                            <Option key="taxi_meter">
                              <LangContext.Consumer>
                                {i18n => 'สถานะมิเตอร์(Taxi)'}
                              </LangContext.Consumer>
                            </Option>
                            <Option key="sos_driver">
                              <LangContext.Consumer>
                                {i18n => 'SOS พนักงานขับขี่'}
                              </LangContext.Consumer>
                            </Option>
                            <Option key="sos_passenger">
                              <LangContext.Consumer>
                                {i18n => 'SOS ผู้โดยสาร'}
                              </LangContext.Consumer>
                            </Option>
                          </OptGroup>
                          <OptGroup label="เซนเซอร์">
                            <Option key="driving_distance">
                              <LangContext.Consumer>
                                {i18n => 'เลขไมล์'}
                              </LangContext.Consumer>
                            </Option>
                            <Option key="fuel">
                              <LangContext.Consumer>
                                {i18n => 'น้ำมัน'}
                              </LangContext.Consumer>
                            </Option>
                            <Option key="temperature">
                              <LangContext.Consumer>
                                {i18n => 'อุณภูมิ'}
                              </LangContext.Consumer>
                            </Option>
                            <Option key="pto">
                              <LangContext.Consumer>
                                {i18n => 'PTO'}
                              </LangContext.Consumer>
                            </Option>
                            <Option key="boom">
                              <LangContext.Consumer>
                                {i18n => 'BOOM'}
                              </LangContext.Consumer>
                            </Option>
                          </OptGroup>

                          {/* <Option key="speed">
                            <LangContext.Consumer>
                              {i18n => "ความเร็ว"}
                            </LangContext.Consumer>
                          </Option> */}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      span={2}
                      offset={1}
                      style={{
                        display: eventDetail[values.event]['toggle']
                          ? ''
                          : 'none',
                      }}
                    >
                      <Form.Item label="Toggle">
                        <Switch
                          checkedChildren="เปิด"
                          unCheckedChildren="ปิด"
                          defaultChecked
                          disabled={!eventDetail[values.event]['toggle']}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      offset={1}
                      style={{
                        display: !eventDetail[values.event]['toggle']
                          ? ''
                          : 'none',
                      }}
                    >
                      <Form.Item label="ค่าตัวแปร">
                        <Input
                          addonBefore={selectBefore(
                            eventDetail[values.event]['toggle'],
                          )}
                          addonAfter={selectAfter(
                            eventDetail[values.event]['unit'],
                          )}
                          defaultValue=""
                          disabled={eventDetail[values.event]['toggle']}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                ) : (
                  <Fragment></Fragment>
                )}
              </Col>
            </Row>
          </div>
        )}
      />
    )
  }
}
export default connect(
  ({ policyState, auth }) => ({ policyState, auth }),
  {},
)(ConditionForm)
