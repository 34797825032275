import { createTypes, createAction } from 'lib/action'

const LOAD_DRIVINGDISTANCE = createTypes('drivingdistance', 'load')
const LOAD_DRIVINGDISTANCE_PREVIEW = createTypes(
  'drivingdistancepreview',
  'load',
)
const LOAD_VEHICLE_DRIVINGDISTANCE = createTypes(
  'vehicle_drivingdistance',
  'load',
)
const LOAD_VEHICLE_TYPE_DRIVINGDISTANCE = createTypes(
  'vehicletype_drivingdistance',
  'load',
)

const loadDrivingdistance = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGDISTANCE.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DRIVINGDISTANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGDISTANCE.FAILURE),
}

const loadDrivingdistancepreview = {
  request: (data, loading) =>
    createAction(LOAD_DRIVINGDISTANCE_PREVIEW.REQUEST, { data, loading }),
  success: data => createAction(LOAD_DRIVINGDISTANCE_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_DRIVINGDISTANCE_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_DRIVINGDISTANCE.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data => createAction(LOAD_VEHICLE_DRIVINGDISTANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_DRIVINGDISTANCE.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGDISTANCE.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_DRIVINGDISTANCE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_DRIVINGDISTANCE.FAILURE),
}

export {
  loadDrivingdistance,
  LOAD_DRIVINGDISTANCE,
  loadDrivingdistancepreview,
  LOAD_DRIVINGDISTANCE_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_DRIVINGDISTANCE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_DRIVINGDISTANCE,
}
