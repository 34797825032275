import React from 'react'
import { Icon } from 'antd'
import axios from 'axios'

const FavoriteFloatingIcon = ({ ...props }) => {
  let {
    match: { path },
    auth: { favMenuLoading },
  } = props

  let showIcon = true
  path = path.toLowerCase()
  if (
    path.includes('/add') ||
    path.includes('/create') ||
    path.includes('/edit') ||
    path.includes('/detail') ||
    path.includes('/view') ||
    path.includes('/profile')
  )
    showIcon = false

  if (showIcon)
    return (
      <div class="sticky-button">
        <a
          href="javascript::void(0)"
          class="sticky-a"
          style={{ padding: '0px 5px' }}
          onClick={e => {
            if (typeof props.auth != 'undefined') {
              if (
                props.auth.favoriteMenu.find(
                  ele => ele.matching_name === props.matching_name,
                ) === undefined
              ) {
                if (!favMenuLoading)
                  props.createFavoriteMenu(
                    {
                      accessToken: props.auth.accessToken,
                      matchingName: props.matching_name,
                      path: props.match.path,
                      userId: props.auth.profile.id,
                      loading: true,
                    },
                    () => false,
                  )
              }
            }
          }}
        >
          {typeof props.auth.favoriteMenu.find(
            ele => ele.matching_name === props.matching_name,
          ) != 'undefined' ? (
            <Icon type="star" theme="filled" style={{ color: '#EEDD16' }} />
          ) : (
            <Icon type="star" style={{ color: '#EEDD16' }} />
          )}
        </a>
      </div>
    )
  else return <div />
}
export default FavoriteFloatingIcon
