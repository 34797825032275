import { createTypes, createAction } from 'lib/action'
const LOAD_TRIP_FOOR = createTypes('loadtripsfoor', 'load')
const CREATE_TRIP_FOOR = createTypes('createtripfoor', 'create')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadTripsFoor = {
  request: (data, loading) =>
    createAction(LOAD_TRIP_FOOR.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_TRIP_FOOR.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_TRIP_FOOR.FAITLURE),
}

const createTripFoor = {
  request: (data, loading) =>
    createAction(CREATE_TRIP_FOOR.REQUEST, { data, loading }),
  success: res => createAction(CREATE_TRIP_FOOR.SUCCESS, { res }),
  failure: () => createAction(CREATE_TRIP_FOOR.FAITLURE),
}

export {
  LOAD_TRIP_FOOR,
  loadTripsFoor,
  CREATE_TRIP_FOOR,
  createTripFoor,
  REMOVE_LISTS,
  removeLists,
}
