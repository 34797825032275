import {
  LOAD_PARKING,
  LOAD_PARKING_PREVIEW,
  LOAD_VEHICLE_PARKING,
  LOAD_VEHICLE_TYPE_PARKING,
} from './actions'

const initialState = {
  parkingUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  parkingLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PARKING.REQUEST:
    case LOAD_PARKING_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        parkingLoading: loading,
      }
    case LOAD_PARKING.SUCCESS:
    case LOAD_PARKING_PREVIEW.SUCCESS:
      return {
        ...state,
        parkingUrl: action.payload.data.data,
        parkingLoading: false,
      }
    case LOAD_VEHICLE_PARKING.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        parkingLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_PARKING.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        parkingLoading: false,
        //  loading: false
      }
    case LOAD_VEHICLE_TYPE_PARKING.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
