import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import {
    ACCESSTOKEN,
    COMPANY_ID,
    USER_ID,
} from "./../../../constants/local_storage";


function* loadPolicy(action) {
    const {
      data: { companyID, page, pageSize, orderBy, orderType, filterObj, bounds }
    } = action.payload;
    try {
      const res = yield call(
        axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcpacpolicy`,
            {
                companyID,
                page,
                pageSize,
                orderBy,
                orderType,
                filterObj,
                bounds
            },
            { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
        );
        yield put(actions.loadPolicy.success(res.data, page));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadPolicy.failure());
    }
}

function* loadPolicyType(action) {

    try {
      const res = yield call(
        axios.get,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcpacpolicytype`,
            { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
        );
        yield put(actions.loadPolicyType.success(res.data));
    } catch (err) {
        console.log(err.message);
        yield put(actions.loadPolicyType.failure());
    }
}

function* loadVehicle(action) {
    const {
        data: { vehicle_visibility}
    } = action.payload;
    try {
      const res = yield call(
        axios.post,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcpacpolicyvehicle`,
        {
          companyID: COMPANY_ID,
          vehicle_visibility
        },
        { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
      );
      yield put(actions.loadVehicle.success(res.data));
    } catch (err) {
      yield put(actions.loadVehicle.failure());
    }
}


function* createPolicy(action){
    const {
        data: { 
                name,
                type,
                description,
                latitude,
                longitude,
                geoFene,
                radius,
                vehicleSelect,
                type_location,
                condition,
                created_by
            },
            loading
    } = action.payload;

    try {

        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createcpacpolicy`,
            {
                name,
                type,
                description,
                latitude,
                longitude,
                geoFene,
                radius,
                vehicleSelect,
                type_location,
                condition,
                created_by
            },
            { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
        );
        loading(res.data);
        yield put(actions.createPolicy.success(res.data));

    } catch (err) {
      yield put(actions.createPolicy.failure());
    }
}

function* updatePolicy(action){
    const {
        data: { 
                cpac_condition,
                cpac_policy_id,
                name,
                type,
                description,
                latitude,
                longitude,
                geoFene,
                radius,
                vehicleSelect,
                type_location,
                condition,
            },
            loading
    } = action.payload;

    try {

        const res = yield call(
            axios.post,
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updatecpacpolicy`,
            {
                cpac_condition,
                cpac_policy_id,
                name,
                type,
                description,
                latitude,
                longitude,
                geoFene,
                radius,
                vehicleSelect,
                type_location,
                condition,
            },
            { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
        );
        loading(res.data);
        yield put(actions.updatePolicy.success(res.data));

    } catch (err) {
      yield put(actions.updatePolicy.failure());
    }
}

export default function* watchCpacPolicy() {
    yield all([
        takeEvery(actions.LOAD_POLICY.REQUEST, loadPolicy),
        takeEvery(actions.LOAD_POLICY_TYPE.REQUEST,loadPolicyType),
        takeEvery(actions.LOAD_VEHICLE.REQUEST, loadVehicle),
        takeEvery(actions.CREATE_POLICY.REQUEST, createPolicy),
        takeEvery(actions.UPDATE_POLICY.REQUEST, updatePolicy)
    ]);
}
export {
    loadPolicy,
    loadPolicyType,
    loadVehicle,
    createPolicy,
    updatePolicy
};