import React from "react";
import { ResponsivePie } from "@nivo/pie";

const data = [
  {
    id: "ตรงเวลา",
    label: "ตรงเวลา",
    value: 90,
  },
  {
    id: "ไม่ตรงเวลา",
    label: "ไม่ตรงเวลา",
    value: 10,
  },
];


const data2 = [
    {
      id: "ในพื้นที่",
      label: "ในพื้นที่",
      value: 78,
    },
    {
      id: "นอกพื้นที่",
      label: "นอกพื้นที่",
      value: 22,
    },
  ];


const PieChart = ({ value }) => {
  return (
    <ResponsivePie
      data={value === 1 ? data : data2}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={["#57df4e", "#ff3b30"]}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: "color" }}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      sliceLabel={d => `${d.value}%`}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          translateY: 56,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default PieChart;
