import { createTypes, createAction } from 'lib/action'
const LOAD_TRIP_WILL = createTypes('loadtripswill', 'load')
const CREATE_TRIP_WILL = createTypes('createtripwill', 'create')
const REMOVE_LISTS = `REMOVE_LISTS`

function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

const loadTripsWill = {
  request: (data, loading) =>
    createAction(LOAD_TRIP_WILL.REQUEST, { data, loading }),
  success: (lists, page, pageSize) =>
    createAction(LOAD_TRIP_WILL.SUCCESS, { lists, page, pageSize }),
  failure: () => createAction(LOAD_TRIP_WILL.FAITLURE),
}

const createTripWill = {
  request: (data, loading) =>
    createAction(CREATE_TRIP_WILL.REQUEST, { data, loading }),
  success: res => createAction(CREATE_TRIP_WILL.SUCCESS, { res }),
  failure: () => createAction(CREATE_TRIP_WILL.FAITLURE),
}

export {
  LOAD_TRIP_WILL,
  loadTripsWill,
  CREATE_TRIP_WILL,
  createTripWill,
  REMOVE_LISTS,
  removeLists,
}
