import { createTypes, createAction } from "lib/action";
const LOAD_RRECAST_REPORT = createTypes("precastreport", "load");
const EXPORT_PRECAST_REPORT = createTypes("exportprecastreport", "load");
const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
    return {
        type: REMOVE_LISTS
    };
}
const loadPrecastReport = {
    request: (data, loading) =>
        createAction(LOAD_RRECAST_REPORT.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_RRECAST_REPORT.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_RRECAST_REPORT.FAITLURE)  
};

const exportPrecastReport = {
    request: (data, loading) =>
        createAction(EXPORT_PRECAST_REPORT.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(EXPORT_PRECAST_REPORT.SUCCESS, {lists}),
    failure: () => createAction(EXPORT_PRECAST_REPORT.FAITLURE)  
};

export {
    LOAD_RRECAST_REPORT, loadPrecastReport,
    EXPORT_PRECAST_REPORT, exportPrecastReport,
    REMOVE_LISTS, removeLists
};
