import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

import {
    ACCESSTOKEN,
    COMPANY_ID,
    USER_ID,
    getlocalstorage
  } from "./../../../constants/local_storage";

function* loadRequestDelivery(action) {
    const {
      data: {
        location_visibility,
        is_vendor,
        page,
        pageSize,
        filterObj
      }
    } = action.payload;
  
    try {
      const res = yield call(
        axios.post,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get/delivery`,
        {
          location_visibility,
          company_id: COMPANY_ID,
          is_vendor,
          page,
          pageSize,
          filterObj
        },
        { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
      );
  
      yield put(actions.loadRequestDelivery.success(res.data, page));
    } catch (err) {
      console.log(err.message);
      yield put(actions.loadRequestDelivery.failure());
    }
}

function* loadDataForEdit(action) {
  const {
    data: { id }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/getfor/edit/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadDataForEdit.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDataForEdit.failure());
  }
}

function* updateTricket(action) {
  const {
    data: {
      id,
      do_number,
      delivery_date,
      queue_number,
      queue_at,
      material_id,
      isupply_material_name,
      quantity,
      driver_id,
      driver_name,
      vehicles_id,
      plate_no,
      vehicle_type_name,
      origin_locations_id,
      origin_locations_name,
      dest_locations_id,
      dest_locations_name,
      weight_in,
      weight_in_at,
      weight_out,
      weight_out_at,
      seal_at,
      depart_at,
      deliver_at,
      arrive_at,
    },
    loading
  } = action.payload;

  try {

    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/edit/ticket`,
      {
        id,
        do_number,
        delivery_date,
        queue_number,
        queue_at,
        material_id,
        isupply_material_name,
        quantity,
        driver_id,
        driver_name,
        vehicles_id,
        plate_no,
        vehicle_type_name,
        origin_locations_id,
        origin_locations_name,
        dest_locations_id,
        dest_locations_name,
        weight_in,
        weight_in_at,
        weight_out,
        weight_out_at,
        seal_at,
        depart_at,
        deliver_at,
        arrive_at,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    loading(res.data);
    yield put(actions.updateTricket.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateTricket.failure());
  }

}

export default function* watchIsupplyDelivery() {
    yield all([
      takeEvery(actions.LOAD_REQUEST_DELIVERY.REQUEST, loadRequestDelivery),
      takeEvery(actions.LOAD_DATA_FOR_EDIT.REQUEST, loadDataForEdit),
      takeEvery(actions.UPDATE_TRICKET.REQUEST, updateTricket)
    ]);
}

export {
    loadRequestDelivery,
    updateTricket,
    loadDataForEdit
  };
  