import React, { Component } from 'react'
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  AutoComplete,
  DatePicker,
  Input,
  Select,
} from 'antd'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import moment from 'moment'

import LangContext, { i18n } from 'modules/shared/context/langContext'

const FormItem = Form.Item
const OptionAuto = AutoComplete.Option
const Option = Select.Option
const { RangePicker } = DatePicker

class FormDetail extends Component {
  state = {
    locationList: [],
    locationcodeList: [],
    location_name: '',
    location_code: '',
    cv_code: '',
    invoice_number: '',
    cv_code: '',
    invoice_date: '',
    confirm_deliver_type: '3',
    dest_location: '',
    eventdate: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
    popup: false,
  }

  componentDidMount() {
    //  const { loadLocation,loadLocationcode } = this.props;
    //  loadLocation();
    //  loadLocationcode();
  }

  componentDidUpdate(prevProps, prevState) {
    const { deliver_id, deliverLists, edit_popup, setPopupEdit } = this.props

    if (edit_popup) {
      const data_initial = deliverLists.find(item => {
        return item.id == deliver_id
      })

      this.setState({
        location_name: data_initial.location,
        location_code: data_initial.location_code,
        cv_code: data_initial.cv_code,
        invoice_number: data_initial.invoice_number,
        invoice_date: moment(data_initial.invoice_date, 'DD/MM/YYYY'),
        confirm_deliver_type: data_initial.confirm_deliver_type,
        dest_location: data_initial.dest_location,
        code_dest_location: data_initial.dest_location,
        eventdate: [
          moment(data_initial.date_start, 'DD/MM/YYYY HH:mm'),
          moment(data_initial.date_end, 'DD/MM/YYYY HH:mm'),
        ],
      })

      setPopupEdit()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { deliverDetails, trip_id } = nextProps
    if (this.props.check_deliver_detail != nextProps.check_deliver_detail) {
      if (trip_id != '') {
        this.setState({
          location_name: deliverDetails.location_name,
          location_code: deliverDetails.location_code,
          cv_code: deliverDetails.cv_code,
          invoice_number: deliverDetails.invoice_number,
          invoice_date: moment(deliverDetails.invoice_date, 'DD/MM/YYYY'),
          confirm_deliver_type: deliverDetails.confirm_deliver_type.toString(),
          dest_location: deliverDetails.dest_location,
          code_dest_location: deliverDetails.dest_location,
          eventdate: [
            moment(deliverDetails.date_start, 'DD/MM/YYYY HH:mm'),
            moment(deliverDetails.date_end, 'DD/MM/YYYY HH:mm'),
          ],
        })
      }
    }
    return true
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.locationLists.length != nextProps.locationLists.length) {
      const { locationLists, locationcodeLists } = nextProps
      const locationlists = []
      locationLists.map(item =>
        locationlists.push({
          key: item.id,
          name: item.name,
          code: item.code,
          cv_code: item.cv_code,
        }),
      )

      const locationcodelists = []
      locationcodeLists.map(item =>
        locationcodelists.push({
          key: item.id,
          name: item.code,
          location_name: item.name,
          cv_code: item.cv_code,
        }),
      )

      this.setState({ locationList: locationlists })
      this.setState({ locationcodeList: locationcodelists })
    }
  }

  handleClick = (value, setFieldValue, handleSubmit) => {
    setFieldValue('cv_code', this.state.cv_code, false)
    setFieldValue('invoice_number', this.state.invoice_number, false)
    setFieldValue('invoice_date', this.state.invoice_date, false)
    setFieldValue('eventdate', this.state.eventdate, false)
    setFieldValue(
      'confirm_deliver_type',
      this.state.confirm_deliver_type,
      false,
    )
    setFieldValue('dest_location', this.state.dest_location, false)
    setFieldValue('code_dest_location', this.state.dest_location, false)
    setFieldValue('location_name', this.state.location_name, false)
    setFieldValue('location_code', this.state.location_code, false)

    const { deliver_id } = this.props
    setFieldValue('deliver_id', deliver_id, false)
    handleSubmit()
  }

  selectLocation = (value, option, setFieldValue) => {
    this.setState({ location_code: option.props.label })
    this.setState({ location_name: option.props.children })
    this.setState({ dest_location: value })
    //setFieldValue("location_name", option.props.children, false);
  }

  onSearchLocation = searchText => {
    if (searchText != '') {
      const { loadLocation } = this.props
      loadLocation(searchText)
    }
    this.setState({ location_name: searchText })
  }

  selectLocationcode = (value, option, setFieldValue) => {
    this.setState({ location_code: value })
    this.setState({ location_name: option.props.label })
    this.setState({ dest_location: value })

    // setFieldValue("dest_location", value);
    // setFieldValue("location_name", option.props.label, false);
  }

  onSearchLocationcode = searchText => {
    if (searchText != '') {
      const { loadLocationcode } = this.props
      loadLocationcode(searchText)
    }
    this.setState({ location_code: searchText })
  }

  changeCvcode = value => {
    this.setState({ cv_code: value })
  }

  changeInvoiceNumber = value => {
    this.setState({ invoice_number: value })
  }

  changeConfirmType = value => {
    this.setState({ confirm_deliver_type: value })
  }

  changeInvoicedate = value => {
    this.setState({ invoice_date: value })
  }

  changeRangdate = value => {
    this.setState({ eventdate: value })
  }

  handleCancelClick = () => {
    this.setState({
      location_name: '',
      location_code: '',
      cv_code: '',
      invoice_number: '',
      cv_code: '',
      invoice_date: '',
      confirm_deliver_type: '3',
      dest_location: '',
      code_dest_location: '',
      eventdate: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
    })

    this.props.onCancel()
  }

  render() {
    const { visible, onOk, onCancel, deliver_id } = this.props

    const loclists = this.state.locationList.map(v => (
      <OptionAuto key={v.key} label={v.code} cvcode={v.cv_code}>
        {v.name}
      </OptionAuto>
    ))

    const loccodelists = this.state.locationcodeList.map(v => (
      <OptionAuto key={v.key} label={v.location_name} cvcode={v.cv_code}>
        {v.name}
      </OptionAuto>
    ))

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    }

    let form_name = (
      <LangContext.Consumer>{i18n => i18n.a.add}</LangContext.Consumer>
    )
    if (deliver_id != 0) {
      form_name = (
        <LangContext.Consumer>{i18n => i18n.e.edit}</LangContext.Consumer>
      )
    }

    return (
      <div>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              dest_location: this.state.dest_location,
              code_dest_location: this.state.code_dest_location,
              invoice_number: this.state.invoice_number,
              cv_code: this.state.cv_code,
              invoice_date: this.state.invoice_date,
              confirm_deliver_type: this.state.confirm_deliver_type,
              eventdate: this.state.eventdate,
            }}
            validate={values => {
              let errors = {}

              return errors
            }}
            validationSchema={yup.object().shape({
              dest_location: yup.string().required('กรุณาเลือกสถานที่ปลายทาง'),
              invoice_number: yup.string().required('กรุณากรอกเลข Invoice'),
              invoice_date: yup.string().required('กรุณาเลือกวันที่ Invoice'),
            })}
            onSubmit={values => {
              const loading = true
              onOk(values, loading)

              this.setState({
                location_name: '',
                location_code: '',
                cv_code: '',
                invoice_number: '',
                cv_code: '',
                invoice_date: '',
                confirm_deliver_type: '3',
                dest_location: '',
                code_dest_location: '',
                eventdate: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
              })
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating,
              resetForm,
            }) => (
              <Modal
                style={{ top: 20 }}
                title={form_name}
                visible={visible}
                onOk={value =>
                  this.handleClick(value, setFieldValue, handleSubmit)
                }
                onCancel={this.handleCancelClick}
                okText={
                  <LangContext.Consumer>
                    {i18n => i18n.o.okay}
                  </LangContext.Consumer>
                }
                cancelText={
                  <LangContext.Consumer>
                    {i18n => i18n.c.cancel}
                  </LangContext.Consumer>
                }
                width={600}
              >
                <Form onSubmit={handleSubmit} layout="horizontal">
                  <FormItem
                    {...formItemLayout}
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.d.destinationLocation}
                      </LangContext.Consumer>
                    }
                    required={true}
                    validateStatus={
                      touched.dest_location && errors.dest_location && 'error'
                    }
                    help={touched.dest_location && errors.dest_location}
                  >
                    <AutoComplete
                      dataSource={loclists}
                      style={{ width: 200 }}
                      optionLabelProp="children"
                      filterOption={true}
                      optionFilterProp="children"
                      value={this.state.location_name}
                      onSelect={(value, option) =>
                        this.selectLocation(value, option, setFieldValue)
                      }
                      onSearch={this.onSearchLocation}
                    />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.d.destinationLocationcode}
                      </LangContext.Consumer>
                    }
                    required={true}
                    validateStatus={
                      touched.code_dest_location &&
                      errors.code_dest_location &&
                      'error'
                    }
                    help={
                      touched.code_dest_location && errors.code_dest_location
                    }
                  >
                    <AutoComplete
                      dataSource={loccodelists}
                      style={{ width: 200 }}
                      optionLabelProp="children"
                      filterOption={true}
                      optionFilterProp="children"
                      value={this.state.location_code}
                      onSelect={(value, option) =>
                        this.selectLocationcode(value, option, setFieldValue)
                      }
                      onSearch={this.onSearchLocationcode}
                    />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Cv code"
                    //required={true}
                    validateStatus={
                      touched.cv_code && errors.cv_code && 'error'
                    }
                    help={touched.cv_code && errors.cv_code}
                  >
                    <Input
                      name="cv_code"
                      onChange={e => this.changeCvcode(e.target.value)}
                      onBlur={handleBlur}
                      value={this.state.cv_code}
                    />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.i.invoice_number}
                      </LangContext.Consumer>
                    }
                    required={true}
                    validateStatus={
                      touched.invoice_number && errors.invoice_number && 'error'
                    }
                    help={touched.invoice_number && errors.invoice_number}
                  >
                    <Input
                      name="invoice_number"
                      onChange={e => this.changeInvoiceNumber(e.target.value)}
                      onBlur={handleBlur}
                      value={values.invoice_number}
                    />
                  </FormItem>

                  <Form.Item
                    {...formItemLayout}
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.i.invoice_date}
                      </LangContext.Consumer>
                    }
                    required={true}
                    validateStatus={
                      touched.invoice_date && errors.invoice_date && 'error'
                    }
                    help={touched.invoice_date && errors.invoice_date}
                  >
                    <DatePicker
                      // showTime={{ format: 'HH:mm' }}
                      format="DD/MM/YYYY"
                      //onChange={value => setFieldValue("invoice_date", value)}
                      onChange={value => this.changeInvoicedate(value)}
                      value={this.state.invoice_date}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.t.trip_duration}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.eventdate && errors.eventdate && 'error'
                    }
                    help={touched.eventdate && errors.eventdate}
                  >
                    <RangePicker
                      showTime={{ format: 'HH:mm' }}
                      onChange={value => this.changeRangdate(value)}
                      onOk={value => this.changeRangdate(value)}
                      format="DD/MM/YYYY HH:mm"
                      placeholder={['Start Time', 'End Time']}
                      value={this.state.eventdate}
                    />
                  </Form.Item>

                  <FormItem
                    {...formItemLayout}
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.c.confirm_type}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.confirm_deliver_type &&
                      errors.confirm_deliver_type &&
                      'error'
                    }
                    help={
                      touched.confirm_deliver_type &&
                      errors.confirm_deliver_type
                    }
                  >
                    <Select
                      //showSearch
                      // style={{ width: 200 }}

                      onChange={value => this.changeConfirmType(value)}
                      value={this.state.confirm_deliver_type}
                    >
                      <Option value="1">
                        <LangContext.Consumer>
                          {i18n => i18n.m.move_in}
                        </LangContext.Consumer>
                      </Option>
                      <Option value="2">
                        <LangContext.Consumer>
                          {i18n => i18n.m.move_out}
                        </LangContext.Consumer>
                      </Option>
                      <Option value="3">
                        <LangContext.Consumer>
                          {i18n => i18n.d.device}
                        </LangContext.Consumer>
                      </Option>
                      <Option value="4">PTO</Option>
                    </Select>
                  </FormItem>
                </Form>
              </Modal>
            )}
          />
        </div>
      </div>
    )
  }
}

export default FormDetail
