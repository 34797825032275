import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Button, Form, Input, Alert, Icon, Col, Row, Tabs } from 'antd'
import {
  StyledLogo,
  StyledIconDownload,
  StyleTextCenter,
} from '../../../styled/common-styled'
import OAuth2Login from 'react-simple-oauth2-login'

const handleRedirect = () => {
  localStorage.setItem('userAuthen', 'Fleetlink')
  window.location.href = '/logincpac'
}
const { TabPane } = Tabs

const onFailure = response => console.error(response)
export default ({ auth, onSubmit: authFormSubmit }) => (
  <Formik
    initialValues={{
      username: '',
      password: '',
      loginType: 'bluenet',
      userType: 'New',
      token: '',
    }}
    validationSchema={yup.object().shape({
      username: yup.string().required(),
      password: yup.string().required(),
      //   loginType: yup.string(),
      //   userType: yup.string(),
    })}
    onSubmit={values => authFormSubmit(values)}
    render={({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
    }) => (
      <Form onSubmit={handleSubmit}>
        {auth.status == '' && auth.msg != '' && (
          <Alert
            message={auth.msg}
            type="warning"
            showIcon
            closable
            style={{ margin: '25px 0px 15px 0px' }}
          />
        )}
        <OAuth2Login
          authorizationUrl={process.env.REACT_APP_AZURE_AUTHORIZE_URI}
          responseType="code"
          clientId={process.env.REACT_APP_AZURE_CLIENT_ID}
          redirectUri={process.env.REACT_APP_REDIRECT_URI}
          onSuccess={response => {
            localStorage.setItem('userAuthen', 'Azure')
            authFormSubmit({ ...response, type: 'Azure' })
          }}
          onFailure={onFailure}
          scope="api://98ace971-37bb-45b2-a404-8503890e5dbf/api.access"
          render={v => {
            return (
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  width: '100%',
                  backgroundColor: '#3479B6',
                  border: 'none',
                  margin: '10px 0px 0px 0px',
                }}
                onClick={v.onClick}
              >
                <Icon type="windows" theme="filled" /> พนักงานประจำ CPAC หรือ
                SCG
              </Button>
            )
          }}
        ></OAuth2Login>
        <OAuth2Login
          authorizationUrl={process.env.REACT_APP_J2C_AUTHORIZE_URI}
          responseType="code"
          clientId={process.env.REACT_APP_J2C_CLIENT_ID}
          redirectUri={process.env.REACT_APP_REDIRECT_URI}
          onSuccess={response => {
            localStorage.setItem('userAuthen', 'J2C')
            authFormSubmit({ ...response, type: 'J2C' })
          }}
          onFailure={onFailure}
          render={v => {
            return (
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  width: '100%',
                  backgroundColor: '#2CADD0',
                  border: 'none',
                  margin: '10px 0px',
                }}
                onClick={v.onClick}
              >
                <Icon type="team" /> พนักงานจ้างเหมา หรือ บุคคลภายนอก
              </Button>
            )
          }}
        ></OAuth2Login>

        <Button
          onClick={handleRedirect}
          style={{
            width: '100%',
            textAlign: 'center',
            color: 'red',
          }}
        >
          บัญชีผู้ใช้งานของ Fleetlink
        </Button>
      </Form>
    )}
  />
)
