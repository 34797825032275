import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadVehiclestatusdailyjob(action) {
  const {
    data: {
      start_date,
      end_date,
      location_visibility,
      exactly_location,
      mill_location_list,
      plant_location_list,
      is_vendor,
      is_admin,
      company_id,
      group_by,
      filterObj,
      material_visibility,
      accesstoken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getData-for-dashboardstatusreceivedailyJob`,
      {
        start_date,
        end_date,
        location_visibility,
        exactly_location,
        mill_location_list,
        plant_location_list,
        is_vendor,
        is_admin,
        company_id,
        group_by,
        filterObj,
        material_visibility,
        accesstoken,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadVehiclestatusdailyjob.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadVehiclestatusdailyjob.failure())
  }
}

function* loadVehicleStatusForDetail(action) {
  const {
    data: { id, accesstoken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardstatusreceivedailyJob/getfor/detail/${id}`,
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )
    yield put(actions.loadVehicleStatusForDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadVehicleStatusForDetail.failure())
  }
}

export default function* watchIsupplyVehicleStatusDailyJob() {
  yield all([
    takeEvery(
      actions.LOAD_DATA_FOR_VEHICLE_STATUS_DAILY_JOBE.REQUEST,
      loadVehiclestatusdailyjob,
    ),
    takeEvery(
      actions.LOADDATA_VEHICLE_STATUS_FOR_DETAIL.REQUEST,
      loadVehicleStatusForDetail,
    ),
  ])
}
export { loadVehiclestatusdailyjob, loadVehicleStatusForDetail }
