import React, { Component, Fragment } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Index extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        <div className="divider__web__content">
          <Divider />
        </div>
        {/* รูปแบบการสรุปคะแนน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'ScoresummaryReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/scoresummary">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.s.ScoresummaryReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงคะแนนความปลอดภัย ตามเงื่อนไขที่ CPAC กำหนด
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* ความปลอดภัยพาหนะ */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'SafetyEventReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/safety_event">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.e.SafetyEventReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงรายละเอียดข้อมูลความปลอดภัยของพาหนะ
                          (ความเร็วเกิน, จุดจอด, จุดจอดนอกพื้นที่)
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* Alert และ Alarm แยกตามทะเบียนรายวัน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'alarmAndAlertReportClassifiedByDailyCarRegistration',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/alarmalertbyplateno">
              <Card className="card__web__content">
                <p className="alert__alarm__twoline__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n =>
                      i18n.a.alarmAndAlertReportClassifiedByDailyCarRegistration
                    }
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* Alert และ Alarm แยกตามพนักงานขับขี่รายวัน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'DailyAlarmAndAlertReportByDriver',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/alarmalertbydriver">
              <Card className="card__web__content">
                <p className="alert__alarm__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.d.DailyAlarmAndAlertReportByDriver}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* Alert และ Alarm แยกตามทะเบียน รายปี */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'alarmAndAlertReportClassifiedByYearlyCarRegistration',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/alarmalertbyplatenoonyear">
              <Card className="card__web__content">
                <p className="alert__alarm__twoline__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n =>
                      i18n.a
                        .alarmAndAlertReportClassifiedByYearlyCarRegistration
                    }
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* ความถี่ Alarm แยกตามพนักงานขับขี่ รายวัน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'reportAlarmFrequencyClassifiedByDailyDriver',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/alarmalertfrequencybydriver">
              <Card className="card__web__content">
                <p className="alert__alarm__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.r.reportAlarmFrequencyClassifiedByDailyDriver}
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* Alert 5 อันดับแรกของแต่ละประเภทแบ่งตามช่วงเวลารายเดือน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/alarmalertbydurationtime">
              <Card className="card__web__content">
                <p className="alert__alarm__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n =>
                      i18n.t
                        .top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod
                    }
                  </LangContext.Consumer>
                </p>
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'BehaviordrivingReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/behaviordriving">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__info__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.b.BehaviordrivingReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงสรุปข้อมูลความปลอดภัย
                          แยกตามเจ้าหน้าที่บริการและจัดส่ง
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* สรุปพฤติกรรมการขับรถของ เจ้าหน้าที่บริการและจัดส่ง แต่ละผู้รับเหมา */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'BehaviordrivingbycompanyReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/behaviordrivingbycompany">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__alern__alarm__info__text__card__web__content">
                      <Popover
                        content={
                          <LangContext.Consumer>
                            {i18n => i18n.b.BehaviordrivingbycompanyReport}
                          </LangContext.Consumer>
                        }
                        placement="bottom"
                      >
                        <span className="three__line__web__content">
                          <LangContext.Consumer>
                            {i18n => i18n.b.BehaviordrivingbycompanyReport}
                          </LangContext.Consumer>
                        </span>
                      </Popover>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงสรุปข้อมูลความปลอดภัย
                          แยกตามเจ้าหน้าที่บริการและจัดส่งแต่ละผู้รับเหมา
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Index
