import {
    LOAD_DATA_PRECAST,
    LOAD_DATA_VEHICLE_FOR_VIEW,
    REMOVE_LISTS
} from "./actions";


const initialState = {
    ongoing_lists: [],
    success_lists: [],
    available_lists: [],

    loading: false,
    listsDataForView: [],
    loadingForView: false,
    checkLoaddataforview: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_DATA_PRECAST.REQUEST:
            const { loading } = action.payload;
                return {
                    ...state,
                    loading: loading,
                };

        case LOAD_DATA_PRECAST.SUCCESS:
            return {
                ...state,
                ongoing_lists:      action.payload.lists.Ongoing,
                success_lists:      action.payload.lists.Success,
                available_lists:    action.payload.lists.Availble,
                loading: false,
                draw: Math.floor(Math.random() * 999 + 1)
            };

        case LOAD_DATA_VEHICLE_FOR_VIEW.REQUEST:
            return {
                ...state,
                loadingForView: action.payload.data.loading,
                checkLoaddataforview: true,
            }
    
        case LOAD_DATA_VEHICLE_FOR_VIEW.SUCCESS:
            return {
                ...state,
                listsDataForView: action.payload.lists.data,
                loadingForView: false,
                checkLoaddataforview: false,
            };
        
        default:
            return state;
    }
}