import {
  LOAD_SEPARATEVEHICLESTATUS,
  LOAD_SEPARATEVEHICLESTATUS_PREVIEW,
  LOAD_VEHICLE_SEPARATEVEHICLESTATUS,
  LOAD_VEHICLE_TYPE_SEPARATEVEHICLESTATUS,
} from './actions'

const initialState = {
  alarmalertbyplatenoUrl: '',
  vehicleLists: [],
  vehicleTypeLists: [],
  separatevehiclestatusLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SEPARATEVEHICLESTATUS.REQUEST:
    case LOAD_SEPARATEVEHICLESTATUS_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        separatevehiclestatusLoading: loading,
      }
    case LOAD_SEPARATEVEHICLESTATUS.SUCCESS:
    case LOAD_SEPARATEVEHICLESTATUS_PREVIEW.SUCCESS:
      return {
        ...state,
        alarmalertbyplatenoUrl: action.payload.data.data,
        separatevehiclestatusLoading: false,
      }
    case LOAD_VEHICLE_SEPARATEVEHICLESTATUS.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        separatevehiclestatusLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_SEPARATEVEHICLESTATUS.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        separatevehiclestatusLoading: false,
        //  loading: false
      }
    case LOAD_VEHICLE_TYPE_SEPARATEVEHICLESTATUS.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
