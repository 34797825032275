import React, { Component, Fragment } from 'react'
import {
  Row,
  Col,
  Collapse,
  Card,
  Icon,
  Breadcrumb,
  Divider,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import LangContext, { i18n } from 'modules/shared/context/langContext'

class Index extends Component {
  render() {
    const { checkMenuVisibility, web_endpoint, permissions } = this.props
    return (
      <>
        <div className="divider__web__content">
          <Divider />
        </div>
        {/* พาหนะติดเครื่อง */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'TruckengineonReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/truckengineon">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.TruckengineonReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลการติดเครื่องยนต์ ตามช่วงเวลาที่เลือก
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* จำนวนวันที่พาหนะไม่ติดเครื่อง */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'TruckengineoffReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/truckengineoff">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report2__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.TruckengineoffReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงข้อมูลการติดเครื่องยนต์ ตามช่วงเวลาที่เลือก
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="report2__text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.t.TruckengineoffReport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}
        {/* จำนวนวันที่พาหนะไม่ได้วิ่งงาน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'TrucknotuseReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/trucknotuse">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.t.TrucknotuseReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงจำนวนวันที่พาหนะไม่ได้วิ่งงาน
                          ตามเงื่อนไขที่กำหนด
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
                {/* <p className="res_text__card__web__content">
                  <LangContext.Consumer>
                    {i18n => i18n.t.TrucknotuseReport}
                  </LangContext.Consumer>
                </p> */}
              </Card>
            </Link>
          </Col>
        )}

        {/* สรุปประสิทธิภาพพาหนะรายคัน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'VehicleperformanceconcludeReport',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/vehicleperformanceconclude">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.v.VehicleperformanceconcludeReport}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงประสิทธิภาพการใช้งานของพาหนะรายคัน
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
        {/* ภาพรวมสรุปการขับขี่รายคัน */}
        {checkMenuVisibility(
          '',
          web_endpoint,
          'drivingSummary',
          permissions,
        ) ? (
          <></>
        ) : (
          <Col span={4}>
            <Link to="/report/driving-summary">
              <Card className="card__web__content">
                <Row>
                  <Col span={19}>
                    <p className="report__text__card__web__content">
                      <LangContext.Consumer>
                        {i18n => i18n.d.drivingSummary}
                      </LangContext.Consumer>
                    </p>
                  </Col>
                  <Col span={5} align="center">
                    <Popover
                      placement="bottom"
                      content={
                        <div className="popover__web__content">
                          รายงานแสดงสรุปภาพรวมการขับขี่อย่างปลอดภัย
                        </div>
                      }
                    >
                      <Icon
                        type="info-circle"
                        style={{ fontSize: 'larger', paddingTop: 17 }}
                        className="icon__web__content"
                      />
                    </Popover>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        )}
      </>
    )
  }
}

export default Index
