const en = {
  a: {
    allDP: 'All DP',
    accountVehicleListHistory: 'Account Vehicle List History 2',
    appoveToUseVehicle: 'Appove To Use Vehicle',
    accountVehicle2: 'Truck sharing board',
    accountVehicle: 'Account Vehicle',
    affiliatedFactories: 'Affiliated Factories',
    agencyCode: 'Agency Code',
    agencyName: 'Agency Name',
    agencyType: 'Agency Type',
    autoMoveSite: 'Auto Move Site',
    abnormal: 'abnormal',
    areaSiteIsNotGeofence: 'Site Is Not Geofence',
    addDetail: 'Add Detail',
    addLocation: 'Add Location',
    allBusiness: 'All Business',
    affiliateUnPlaces: 'Unaffiliated place',
    affiliatePlaces: 'Affiliated Places',
    affiliateType: 'Affiliate Type',
    allTruck: 'All Truck',
    AppropriateImageFileSizeNoMoreThan512KB:
      'Appropriate image file size, no more than 512KB.',
    alert_type: 'alert type',
    accidentScene: 'Accident Scene',
    avgPerDistanceTravel: 'avg/distance travel',
    avgPerTrip: 'avg/trip',
    average_distance: 'average distance (km) (Calculate at the end of the day)',
    amount_poured: 'amount poured (cue)',
    actual_quantity: 'actual quantity (litre)',
    after_litre: 'after (litre)',
    amount_money: 'total amount',
    amountOilUsed: 'amount of oil used (Lite)',
    averageFleet: 'Fleet Average',
    allData: 'All Data',
    approveYes: 'Approve',
    approve: 'Approve By',
    approveAt: 'Approve At',
    accessory: 'Accessory',
    account: 'Account',
    accountName: 'Account Name',
    ACCUMULATEEARNINGSTATISTICS: 'ACCUMULATE EARNING STATISTICS',
    action: 'Action',
    actionBy: 'Action By',
    acceptancePoint: 'Acceptance Point',
    actionRequired: 'Action Required',
    actionDataTime: 'Action DateTime',
    actionTime: 'Action Time',
    active: 'Active',
    activeStatusImage: 'Active Status Image',
    actualTransitDistance: 'Actual Transit Distance',
    actualArrivalTime: 'Actual Arrival Time',
    actualReleaseTime: 'Actual Release Time',
    add: 'Add',
    addARow: 'Add A Row',
    addDrivinglicense: 'Add Driving License',
    addFromRouteMaster: 'Add From Route Master',
    addInformation: 'Add Information',
    address: 'Address',
    addVehicleGroup: 'Add Vehicle Group',
    additionalVehicleInformation: 'Additional Vehicle Information',
    additionalDriverInformation: 'Additional Driver Information',
    adjustTheTable: 'Adjust The Table',
    administrator: 'Administrators',
    affiliation: 'Affiliation',
    african: 'African',
    after1day: 'After 1 Day',
    after1hour: 'After 1 Hour',
    after30min: 'After 30 Min.',
    after4hour: 'After 4 Hour',
    after5min: 'After 5 Min.',
    after8hour: 'After 8 Hour',
    agencyNumber: 'Agency number',
    agency: 'Agency',
    aggressiveAccelerationAndImmediatelyBreak:
      'Aggressive Acceleration or Break',
    aggressiveTurnWithoutLight: 'Aggressive Turn',
    aggressiveTurnChangeDirectionBeyondTheSpeedOfTheSpecifiedArea:
      'Aggressive Turn Change Direction Beyond The Speed Of The Specified Area',
    alarm: 'Alarm',
    alarmAndAlertReportClassifiedByDailyCarRegistration:
      'Alarm And Alert Report Classified By Daily Car Registration',
    alarmAndAlertReportClassifiedByYearlyCarRegistration:
      'Alarm And Alert Report Classified By Yearly Car Registration',
    alert: 'Alert',
    alert_setting_filter: 'Please set filter setting report',
    alertAlarm: 'Alert Alarm',
    alertNoti: 'Alert Only',
    aliasTableCompany: 'Alias',
    all: 'All',
    allowParkingInside: 'Allow Parking Inside Only',
    allowParkingOutside: 'Allow Parking Outside Only',
    allowPTODoorInside: 'Allow Open Inside Only',
    allowPTODoorOutside: 'Allow Open Outside Only',
    allowSharedPartnerEdit: 'Partner can Edit',
    allowWorkingTime: 'Allow Working Time',
    allStatusDashCameras: 'all',
    amount: 'Amount',
    amountPlant: 'Amount Plant',
    amountPlantIsRequired: ' Amount Plant is Required',
    amphur: 'District',
    and: 'AND',
    announcement: 'Announcement',
    announcementBroadcast: 'Announcement Broadcast',
    apply: 'Apply',
    appointment_date: 'Appointment Date',
    appointment_place: 'Appointment place',
    appointmentLocation: 'Appointment Location',
    arab: 'Arab',
    arriveAtDestination: 'Arrive At Destination',
    arriveAtTheOrigin: 'Arrive at The Origin',
    assignAt: 'Assign at',
    assignFirmware: 'Assign Firmware',
    assignTo: 'Assign To',
    assignment: 'Assignment',
    assignmentDate: 'Assignment Date',
    attachment_type: 'Attachment Type',
    available: 'Available',
    avgEarningJob: 'Avg. Earning/Job',
    additionalInformation: 'Additional Information',
    arriveArriveOnSite: 'On Site',
    arriveAtfactory: 'Arrive',
    actual: 'Actual',
    alertAndAlarm: 'Alert and Alarm',
    arrangeACar: 'Arrange a Car',
    autoDNSwitching: 'Auto DN Switching',
    afterSuckingLatex: 'After sucking latex',
    allItems: 'All items',
    anotherProblemType: 'Another Problem Type',
    allDp: 'All Ticket',
    allowAccessToInformation: 'Allow access to information',
    accessToInformationIsNotAllowed: 'Access to information is not allowed.',
    aSystemThatCanRetrieveData: 'A system that can retrieve data',
    amountBaht: 'amount(baht)',
    attachFile: 'attach file',
    allVehicles: 'All vehicles',
    assigned: 'Assigned',
  },
  b: {
    brokenDP: 'Broken DP',
    bluenetAccessToInformation: 'Bluenet Allow Access',
    bluenetNotAccessToInformation: 'Bluenet Not Allow Access',
    bluenetTransportDistance: 'Bluenet transport distance',
    building_time: 'building time',
    before_litre: 'before (litre)',
    boxnumber: 'Device No.',
    businesspartner: 'Business partner',
    bluenet_other: 'Other',
    is_bluenet_car: 'From Bluenet',
    back: 'Back',
    base: 'Base',
    baseTemplate: 'Base Template',
    behavior: 'Behavior',
    behavior_report_menu: 'Behavior',
    BehaviordrivingbycompanyReport: 'Behavior Driving By Company Report',
    BehaviordrivingReport: 'Behavior Driving Report',
    boatDeliveryDashboard: 'Boat Delivery Status',
    boatDeliveryReport: 'Boat Delivery Report',
    body_email: 'Body',
    bodyNo: 'Body No.',
    bodyNoOfInstall: 'Body no of install',
    bookedDate: 'Booked Date',
    booking: 'Booking',
    bookingDate: 'Booking Date',
    bookingOverview: 'Booking Overview',
    bookingPool: 'Booking Pool',
    bookingRemark: 'Booking Remark',
    BOOKINGSTATISTICS: 'BOOKING STATISTICS',
    BOOM: 'BOOM',
    boxID: 'Device ID',
    boxNumber: 'Device No.',
    brand: 'Brand',
    broken: 'broken',
    busStop1: 'Bus Stop 1',
    busStop2: 'Bus Stop 2',
    busStopPoint: 'Bus Stop Point',
    button: 'Search',
    baht_defult: 'Baht',
    baht: '(Baht)',
    baht_km: '(Baht/km)',
    bahtPerkm: '(Baht/km)',
    baht_trip: '(Baht/trip)',
    baht_ton_km: '(Baht/Ton/km)',
    baht_unit_km: '(Baht/Unit/km)',
    baht_litre_km: '(Baht/litre/km)',
    baht_litre: '(Baht/litre)',
    business: 'Business',
    bookAnAppointmentWithCDAS: 'Book an appointment with CDAS',
    bookAnAppointment: 'Booked',
    behindTheCar: 'Behind The Car',
    besideTheCar: 'Beside The Car',
    beforeSuckingTheLatex: 'Before sucking the latex',
    business_group: 'Business Group',
    bookingDate: 'Booking date',
    bookingNo: 'Booking No.',
    bookingContactName: 'Booking contact name',
    bookingContactNameIsRequired: 'Booking contact name is required',
    bookingContactPhone: 'Booking contact phone',
    bookingContactPhoneIsRequired: 'Booking contact phone is required',
    bookAcar: 'Assigned',
    bookingDetails: 'Booking Details',
  },
  c: {
    carType: 'Vehicle Type',
    chooseAll: 'Select All',
    coordinatorPhoneNumber: 'Coordinator Phone Number',
    chauffeurphoneNumber: 'Chauffeur Phone Number',
    contractorVehicleManagement: 'Contractor Vehicle Management',
    contractorNames: 'Contractor Name',
    countUnload: 'Count Unload',
    copyToken: 'Copy Token',
    cancelRouting: 'Cancel Routing',
    cancelByUser: 'Cancel By User',
    cancelBySystem: 'Cancel By System',
    chooseYourRoute: 'Choose Your Route',
    chooseRouteType: 'Choose a route type',
    commentList: 'Comment List',
    comment_text: 'Comment',
    commentVehicleList: 'Comment Vehicle List',
    carParkedOutsideTheDesignatedArea:
      'Truck Parked Outside The Designated Area',
    concreteMixerFinished: 'Concrete Mixer Finished',
    countIncorrectAvg: 'Count Incorrect Avg',
    countEvent3: 'driving too fast (General area) 60 km/hr.',
    countEvent4: 'driving too fast (General area) 45 km/hr.',
    countEvent5:
      'Driving over speed, sharp bends, dangerous curves, curves with frequent accidents, 20 km / h.',
    countEvent6: 'driving too fast (General area) 30 km/hr.',
    countEvent7: 'driving too fast (General area) 10 km/hr.',
    companyName: 'under the car dealership',
    codejbs: 'code',
    closeTimeFormat: 'Stop the engine (day-time)',
    count_spee_over_policy: 'Speed exceeds policy (times)',
    cement_time: 'cement time (hours)',
    contractors: 'contractors',
    concreteOutsideSpecifiedPoint: 'Concrete outside specified point',
    concreteOutsideSpecifiedPointDetail:
      'In the case of plastering outside the specified point',
    concretePointOverlap: 'Concrete at the point of overlap',
    concretePointOverlapDetail:
      'In the event that the car enters the point, but the area of that point overlaps with other agencies',
    chooseList: 'Please Choose the list',
    chooseReportType: 'Please Choose a report type',
    chooseDriverCodePls: 'Please Choose Driver Code',
    chooseDriverNamePls: 'Please Choose Driver Name',
    chooseBusinessPls: 'Please Choose Business',
    chooseSitePls: 'Please Choose Site',
    chooseDatePls: 'Please Choose Date',
    chooseDepartmentPls: 'Please Choose Department',
    chooseSectionPls: 'Please Choose Section',
    choosePlantPls: 'Please Choose Plant',
    chooseRadius: 'Please Choose Radius',
    chooseSitePls: 'Please Choose Site',
    cements: 'Cement',
    calculatedeliveryArea: 'Calculate Delivery Area',
    createaNewTicket: 'Create a new Ticket',
    canDeliverOutsideTheArea: 'Can Deliver Outside The Area',
    canChangeDestination: 'Can Change Destination',
    cmd: 'cmd',
    chooseBusiness: 'Choose Business',
    chooseDepartment: 'Choose Department',
    chooseSection: 'Choose Section',
    chooseSite: 'Choose Site',
    callQueue: 'Call Queue',
    camera_back: 'Back',
    camera_extra: 'Extra',
    camera_position: 'Camera Postion',
    cancel: 'Cancel',
    cancelAt: 'Cancel At',
    cancelled: 'Cancelled',
    cancelReason: 'Reason Of Cancellation',
    carNotOnline: 'Car not online',
    carService: 'Car Service',
    carTax: 'Car tax',
    category: 'Category',
    cc_email: 'Cc',
    certificateNumber: 'Certificate Number',
    certificateRequestDate: 'Certificate Request Date',
    changeLanguage: 'Change Language',
    changePassword: 'Change Password',
    changeProduct: 'Change Product',
    channel: ['Channel', 'Channel'],
    chasisSerialNumber: 'Chasis Serial Number',
    chauffeur: 'Driver',
    chauffeurData: 'Chauffeur Data',
    chineseKoreanJapanese: 'Chinese/Korean/Japanese',
    clear: 'Clear',
    clearStatus: 'Clear Status',
    clearSearch: 'Clear Search',
    clearUp: 'Clear Up',
    close: 'Close',
    closed: 'Closed',
    closeFixed: 'Close:Fixed',
    closeNotAvailable: 'Close : Not available',
    cmd: 'CMD',
    collapse: 'Collapse',
    collapseExpand: 'Collapse/Expand',
    Color: 'Color',
    comment: 'Description',
    communityArea: 'Community Area',
    company: 'Company',
    company_partner: 'Partner',
    companyBilling: 'Company Billing',
    companyCode: 'Company Code',
    companyPartner: 'Company Partner',
    CompareVehicleSccoReport: 'Compare Vehicle SCCO Report',
    complete: 'Complete',
    completeIsupply: 'Complete',
    complete_date: 'Complete Date',
    compulsory: 'Compulsory',
    concreteDeliveryDashboard: 'Concrete Delivery Status',
    concreteDeliveryManagerDashboard: 'Concrete Delivery',
    concretePlant: 'Concrete Plant',
    configuration: 'Configuration',
    confirm: 'Confirm',
    confirm_appointment_place: 'Confirm appointment place',
    confirm_type: 'Confirm type',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirm Password',
    confirmSendingBy: 'Confirm Sending',
    confirmToAddThisItem: 'Confirm To Add This Item.',
    confirmToCloseStatus: 'Confirm To Change Status Complete This Item',
    confirmToDeleteThisItem: 'Confirm To Delete This Item.',
    confirmToEditThisItem: 'Confirm To Edit This Item.',
    confirmToReceiveStatus: 'Confirm To Change Status Receive This Item',
    confirmToSendTheseItems: 'Confirm To Send These Items.',
    confirmToCancelThisItem: 'Confirm To Cancel This Item.',
    connection: 'Connection',
    connectionLost: 'Connection lost',
    contact: 'Contact',
    contact_name: 'Contact Name',
    contact_telno: 'Contact telno',
    Contactmanagement: 'Contact Management',
    contactName: 'Contact Name',
    contactSupportForUserThisMenu: 'Please Contact Support To Use This Feature',
    contactTelno: 'Contact Tel No',
    continuationOfEvents: 'Continuation of events (min)',
    continuousDrive: 'Continuously Drive',
    continuousDriveInADay: 'Continuously Drive In a Day',
    continuousDriving: 'Continuous Driving',
    continuousDrivingLongTime: 'Continuous Driving Long Time (Time)',
    continuousDrivingLongTimeDate: 'Continuous Driving Long Time (Date)',
    contractor: 'Contractor',
    contractorName: 'Contractor Name',
    coordinates: 'Coordinates',
    coordinatesGPS: 'Coordinates GPS',
    confirmToCloseThisItem: 'Confirm To Close This Item.',
    couldNotDisplayDirections:
      'Could not display directions due to: ZERO_RESULTS',
    courseName: 'Course Name',
    cpacPolicy: 'CPAC Policy',
    create_trip: 'Create trip',
    created_by: 'Created by',
    createdAt: 'Created At',
    createdBy: 'Created By',
    createFrimware: 'Create Frimware',
    criteria: 'Criteria',
    criterionAndDeductMarksForTripSuccess:
      'Criterion and Deduct Marks for Trip Success',
    criticality: 'Criticality',
    criticalLevel: 'Critical Level',
    crossroad: 'Junction',
    crushingPlant: 'Crushing plant',
    currentAddress: 'Current Address',
    currentLocation: 'Current Location',
    currentQueue: 'Current Queue',
    currentVehicle: 'Current Vehicle',
    custom: 'Custom',
    customer: 'Passenger',
    customerDetail: 'Passenger Detail',
    customerID: 'Customer ID',
    customerName: 'Passenger Name',
    customerType: 'Customer Type',
    cycleOfDrumRotation: 'Cycle of drum rotation',
    contractShippingRate: 'Contract Shipping Rate',
    concreteTrip: 'Concrete Trip',
    confirmed: 'Confirmed',
    cement: 'Cement',
    changeDestination: 'Change Destination',
    changeHistoryOfData: 'Change History of Information.',
    changeHistoryOfTheLatestInformation:
      'Change History Of The Latest Information.',
    changeHistoryOfTheAllInformation: 'Change History Of The All Information.',
    changeHistoryOfPreviousInformation:
      'Change History Of Previous Information.',
    contact_phone: 'Contact Phone/Phone Driver',
    contact_phone_require: 'Contact Phone/Phone Driver is require',
    contact_phone_length_max: 'Contact Phone/Phone Driver is max 15 character',
    contact_phone_length_min: 'Contact Phone/Phone Driver is min 8 character',
    contact_phone_invalid: 'Contact Phone/Phone Driver is invalid',
    contact_name_require: 'Contact Name is require',
    clearTicketStatus: 'Clear ticket status.',
    contract: 'Contract',
    confirmToChangeStatus: 'Confirm, Do you change status?',
    csv: 'CSV',
    changeOriginLocation: 'Change Origin Location',
    changeDestinationLocation: 'Change Destination Location',
    contractNumber: 'Contract Number',
    contractValidityPeriod: 'Contract Validity Period',
    conditionsForAcceptingWork: 'Conditions For Accepting Work',
    close: 'Close',
    conditionsForSubmittingWork: 'Conditions For Submitting Work',
    changeDestinationByDriver: 'Change Destination By Driver',
    condition: 'Condition',
    cancelALocationFromAnotherSystem: 'Cancel A Location From Another System',
    cdas: 'CDAS',
    codeComparativeRawMaterials: 'Code Comparative Raw Materials',
    closeWork: 'Close Work',
    comparativeRawMaterials: 'Comparative Raw Materials',
    comparativeRawMaterialsRequired: 'Comparative Raw Materials Required',
    count_plant_mining_contract: 'Count Plant Mining Contract',
    count_vehicle: 'Count Vehicle',
    contractStartDate: 'Contract Start Date',
    contractExpirationDate: 'Contract Expiration Date',
    cer: 'Cer.',
    closeBy: 'Close By',
    confirmWantToCloseThisItem: 'Confirm, Want To Close This Item.',
    cannotFix: "Can't fix",
    contractPeriod: 'Contract Period',
    contractStatus: 'Contract Status',
    contractActive: 'Active',
    contractInactive: 'Inactive',
    cardNumber: 'Card No.',
    cardNoIsRequired: 'Card No. is required',
    createBookingOrder: 'Create booking order',
    confirmationOfBooking: 'Confirmed',
    currentJobStatus: 'Current job status',
    chooseTheDateYouWantToSend: 'Choose the date you want to send',
    cancelBooking: 'cancel booking',
    customTime: 'custom',
    countAll: 'Total number of tickets',
    countActive: 'Number of tickets for distance',
  },
  d: {
    destinationTo: 'destination',
    dataOverlapOccurs: 'Data Overlap Occurs',
    desiredFactory: 'Desired Factory',
    desiredDateRange: 'Desired Date Range',
    desiredTime: 'Desired Time',
    dateForDP: 'Date For DP',
    distanceOldNew: 'Distance Old New (Mater)',
    dateFuelCost: 'Date Fuel Cost',
    dpNotCounting: 'DP Not Counting',
    dpCounting: 'DP Counting',
    direction: 'guide',
    drawSmallTruckRoute: 'Draw Small Truck Route',
    smallTruckRouteFromGps: 'Small Truck GPS',
    drawSmallTruckRouteDoNotEnter: 'Draw Small Truck Route Do Not Enter',
    detail: 'Detail',
    destinationPoint: 'Destination',
    dpNumber: 'DP Number',
    drivingTime: 'driving time',
    distance_speed_over_policy: 'Speed exceeding the policy (km.)',
    duration_speed_over_policy: 'Speed over policy (min)',
    diffTime: 'Duration (hours:minutes)',
    distanceOnSite: 'Distance On Site',
    distanceDifferenceKm: 'Distance difference (km) User/GPS',
    distance_return_plant:
      'distance return plant (km) (Calculate at the end of the day)',
    distance_from_plant_to_Site:
      'Distance from plant to job site (km) (Calculate at the end of the day)',
    day_in_month: 'total number of working days',
    document_number: 'document number',
    diff_date: 'Total correction time (days:hours)',
    disconnected_total: 'disconnected total (minute)',
    disconnected_begin: 'disconnected begin',
    disconnected_end: 'disconnected end',
    durationDisconnected: 'duration disconnected',
    dpReleaseDate: 'Dp Release Date',
    downloadTemplate: 'Download Template',
    drivingSafety: 'Driving Safety',
    drivingDetail: 'Driving details',
    deleteRoute: 'Delete route',
    departure: 'departure',
    datetimeloadingcement: 'Date/Time loading cement',
    dashboarddelivery: 'Dashboard delivery',
    defaultfilter: 'Default Filter',
    department: 'Department',
    department: 'Department code',
    division: 'Division',
    division_code: 'Division code',
    distancereport: 'Distance Reports',
    details: 'Details',
    departmentPart: 'department/section',
    distanceByPoint: 'Distance Point to Point',
    driverKpiDashboard: 'Driver KPI Dashboard',
    daily: 'Daily',
    DailyAlarmAndAlertReportByDriver: 'Daily Alarm and Alert Report by Driver',
    dailyCarStatusReport: 'Daily Car Status Report',
    dailyDrivingBehaviorReport: 'Daily Driving Behavior Report',
    DailyiconsiamReport: 'Shuttle Boat – Shuttle Bus Service Daily Report',
    DailyReport: 'Trip Daily Report',
    dangerous: 'Dangerous',
    dashboard: ['Dashboard', 'Dashboard'],
    dashCamera: 'Dash Camera',
    data: 'Data',
    dataForDLT: 'Data For DLT',
    dataSource: 'Data Source',
    dataType: 'Type',
    dataVisibility: 'Data Visibility',
    date: 'Date',
    dateIsRequired: 'Date is required',
    date_entry: 'Entry date',
    date_out: 'Out date',
    date_time: 'Date Time',
    date_time_entry: 'Date-time entry',
    date_time_out: 'Date-time out',
    dateEnd: 'Date End',
    dateOfBirth: 'Date of Birth',
    dateofRawMaterialRequirement: 'Date of Raw Material Requirement.',
    dateOfSubmission: 'Request Date',
    dateRange: 'Date Range',
    dateRangeNotBeMoreThan31Days:
      'The selected date range should not be more than 31 days',
    dateRangeNotBeMoreThan3Days:
      'The selected date range should not be more than 3 days',
    dateStart: 'Date Start',
    dateSubmit: 'Delivery Date',
    dateTime: 'Date/Time',
    day: 'Day',
    daYouWantToReActive: 'Do You Want To Re-Active?',
    daYouWantToReTicketStatus: 'Do You Want To ReTicket Status?',
    daysBeforeNotification: 'Remind Day',
    dayTime: 'Day Time',
    ddHhMm: '(day:hour:min)',
    delivery: 'Delivery',
    deliveryDistance: 'Delivery Distance',
    deduct: 'Deduct',
    default: 'Default',
    defaultImageBylocationType: 'Default Image By Location Type',
    defaultLang: 'Default lang',
    defaultLatitude: 'Default latitude',
    defaultLongitude: 'Default longitude',
    delete: 'Delete',
    deletedAt: 'Deleted At',
    deletedBy: 'Deleted By',
    deleteInformation: 'Delete Information',
    deleteReason: 'Delete Reason',
    deliveryPoint: 'Delivery Point',
    deliver: 'Deliver',
    deliver_duration: 'Start at - End at',
    deliver_status: 'Deliver status',
    Delivered: 'Delivered',
    deliverPoint: 'Deliver Point',
    deliveryAddress: 'Delivery Address',
    deliveryItem: 'Delivery Item',
    deliveryDashboard: 'Delivery Dashboard',
    deliveryContractWasNotFound: 'Delivery contract was not found.',
    deliveryReport: 'Delivery Report',
    deliveryItemDeleteHistoryPool:
      'Delivery Item Delete History Pool (EZY GPS)',
    deliveryItemPool: 'Delivery Item Pool (EZY GPS)',
    DeliveryReport: 'Trip Delivery Report',
    deliveryStatus: 'Delivery Status',
    deliveryStatusIsupply: 'Delivery Status',
    deliveryTime: 'Delivery Time',
    deliveryLocation: 'Delivery Location',
    discontinuedVehiclesShouldNotExceed:
      'Discontinued vehicles should not exceed ',
    deliverytrip: 'Delivery Trip',
    deliveryOutsideTheArea: 'Delivery outside the area',
    deliveryBillNo: 'Delivery Bill No.',
    descrip: 'Description',
    description: 'Description',
    destination: 'Destination',
    destinationIsupply: 'Destination',
    destinationDetail: 'Destination Detail',
    destinationLocation: 'Destination',
    destinationLocationcode: 'Destination code',
    detail: 'Detail',
    detail_problem: 'Detail problem (other)',
    detailOfConcreteTruck: 'Detail Of Concrete Truck',
    destinationLocation_require: 'Destination Location require',
    detailproblem: 'Detail Problem',
    detailProblemServiceMoveDevice: 'Detail Problem',
    device: 'Device',
    deviceName: 'Device Name',
    devicePhone: 'Device Phone',
    deviceType: 'Device Type',
    diff: 'Diff',
    dischargeDenominator: 'Discharge Denominator',
    disconnected: 'Disconnected',
    displayTime: 'Display Time',
    distance: 'Distance',
    distance_less_than: 'Distance less than',
    distance_more_than: 'Total distance is considered work/day (km)',
    distanceWithKm: 'Distance With Km',
    distanceDPWithKm: 'DP Distance With Km',
    DistfromlastmaintenanceReport: 'Distance from Last Maintenance Report',
    doc_no: 'Document No.',
    document: 'Document',
    done: 'Done',
    doNotInstall: 'Do not install',
    doNotParkBesideTheRoad: 'No Parking On Sideways',
    doNotSwipeCardsOrDamageCards: 'Do Not Swipe Cards Or Damage Cards',
    doNumber: 'Do Number',
    download: 'Download',
    downloadSampleFiles: 'Download Sample Files',
    downloadcertificate: 'Download Certificate',
    doYouNeedToRebootTheseItems: 'Do You Need to Reboot These Items?',
    doYouWantToAddTheseItems: 'Do You Want To Add These Items?',
    doYouWantToCancel: 'Do You Want To Cancel?',
    doYouWantToConfirmSend: 'Do You Want To Confirm Send?',
    doYouWantToConfirm: 'You want to verify information ?',
    doYouWantToDeleteTheseItems: 'Do You Want To Delete These Items?',
    doYouWantToEditTheseItems: 'Do you want to edit these items?',
    doYouWanttoReActive: 'Do You Want To Reactivate?',
    doYouWantToSendAnEmailToUsersToVerifyTheirIdentity:
      'Do you want to send an email to users to verify their identity?',
    doYouWantToSendTheseItemsToTheGpsVendor:
      'Do you want to send these items to the gps vendor?',
    DozeoffriskReport: ['Doze Off Risk Report', 'Doze off risk Report'],
    draft: 'Draft',
    draw: 'Draw',
    driveContinuouslyForMoreThan10HoursDay: 'Driving Time > 10 hrs/day',
    driveContinuouslyForMoreThan4Hours: 'Driving Time > 4 hrs',
    driveContinuouslyForMoreThan4HourWithoutRestingFor30Minute:
      'Driving Time > 4 hrs without restting for 30 minute',
    driveMoreThanTheSpeedLimitInTheBend:
      'Drive more than the speed limit in the bend',
    driveOverTheSpeedLimitForDangerousCurves:
      'Drive over the speed limit for dangerous curves',
    driveOverTheSpeedLimitInTheCrossroads:
      'Drive Over The Speed Limit In The Crossroads',
    driver: 'Driver',
    driverTracking: 'Driver',
    driver1: 'Driver 1',
    driver2: 'Driver 2',
    driverBlacklist: 'Blacklist : Driver',
    driverCode: 'Driver Code',
    driverHistory: 'Diver History',
    DriverinformationReport: 'Driverinformation Report',
    driverNameJBS: 'Driver Name',
    driverName: 'Driver Name',
    driverPhoneNumber: 'Phone Number',
    driveTooFastInTheCommunity: 'Drive Too Fast In The Community',
    driveTooFastInTheNormalRoute: 'Drive Too Fast In The Normal Route',
    driving: 'Driving',
    drivingLogDescription:
      'The fuel values shown are from the initial system settings. If you want accuracy, please let me know.',
    drivingLogDescription1: '1. Tank capacity',
    drivingLogDescription2: '2. The day when the tank is full',
    drivingLogDescription3:
      '3. The number of liters added to the service provider to reset the oil.',
    DrivingdistanceReport: 'Driving Distance Report',
    DrivinghourReport: 'Driving Hour Report',
    drivingIiceneType: 'Driving License Type',
    drivingIicenseNumber: 'Driving License Number',
    drivinglicense: 'Driving License',
    drivingLicenseNumberMustBeNumber:
      ' Driving License Number Must Be a Number',
    DrivinglicensescanlogReport: 'Driving License Scan Log Report',
    DrivingNamelicensescanlogReport: 'Driving Name License Scan Log Report',
    DrivingAddresslicensescanlogReport:
      'Driving Address License Scan Log Report',
    DrivinglogReport: 'Driving Log Report',
    DrivinglogReport2: 'Driving Log Report 2',
    DrivinglogspecialReport: 'Driving Log Report (New)',
    drivinglog: 'Driving Log',
    drivingOver10HrsDay: 'driving over 10 hrs/day',
    drivingOver4Hrs: 'Driving over 4 hrs',
    DrivingoverlimitReport: [
      'Driving Over Limit Report',
      'Driving over limit Report',
    ],
    drivingOverSpeedLimitInNormalRoute:
      'Driving over speed limit in normal route',
    drivingOverSpeedLimitInUrbanArea: 'Driving over speed limit in urban area',
    drivingPeriodRange: 'Driving Period Range (min)',
    drivingTime: 'Driving Time',
    drumCapacity: 'Drum Capacity',
    duration: ['Duration', 'Duration'],
    duration_time: 'Duration Time',
    durationOfPTOGate1On: 'Duration of PTO/Gate1 On',
    duringDelivery: 'During Delivery',
    mdvrdownloadfile: 'Download Video MDVR',
    deliveryAvailable: 'Available',
    deliverySuccess: 'Success',
    drivingSummary: 'Driving Summary',
    duration_repair: 'Duration Repair',
    drumRpm: 'Drum RPM.',
    dataTimeGR: 'Date time GR',
    dashboard: 'Dashboard',
    doYouWantToChangeVehicleStatus: 'Do you want to change vehicle status?',
    driverPhone: 'Phone Number',
    dispatch_name: 'Dispatch Name',
    dispatch_code: 'Dispatch Code',
    dispatch_name_code: 'Code/Name Dispatch',
    duplicateRawMaterialCode: 'Duplicate Raw Material Code',
    deleteSuccess: 'Delete Success',
    dontBookAnAppointment: "Don't Book an appointment",
    deliveryBeyondTheSpecifiedTime: 'Delivery beyond the specified time',
    destinationChangeStatus: 'Destination Change Status',
    deliveredInThePickupArea: 'Delivered In The Pickup Area',
    donArrangeACar: "Don't Arrange A Car",
    duplicateRawMaterialAndSourceCode: 'Duplicate Raw Material And Source Code',
    district: 'District',
    doYouWantToChangeBookingOrderStatus:
      'Do you want to change booking order status?',
    doYouWantToCancelBookingOrder: 'Do you want to cancel booking order?',
    destinationView: 'Destination view',
    distance_from_site: 'Distance Form Site (km) :',
    deviceCode: 'Device code',
    driver_code: 'Driver code',
    deletedPolygon: 'Draw cover point which want to remove',
  },
  dashboard_summary: {
    title: 'Summary as of',
    pick_date: 'Please Select Date',
    enginePanel: 'Vehicle Utilization',
    safetyPanel: 'Safety Driving Summary',
    savePanel: 'ECO Driving Summary',
    fuelAvgPanel: 'Avg. Fuel Consumption',
    fuelAvgPanelUsage: 'oil consumption (km/liter)',
    parkingPanel: 'Total Idling Time',
    speedPanel: 'Over Safety Speed',
    parking: 'ldling',
    lost: 'Offline',
    close: 'lgn OFF',
    start: 'Driving',
    point: 'Score',
    rangeAvg: 'Avg. Company Score',
    distance: 'Total Driving Distance',
    fuelSummary: 'Total Fuel Usage',
    fuelUse: 'Idling Fuel Usage',
    fuelToBath: 'Idling Fuel Cost (B10)',
    speedOver: 'Total Over Speed',
    maxSpeed: 'Highest Speed',
    to: 'to',
    date: 'Date',
    lite: 'L',
    hours: 'h',
    hour: 'hour',
    minute: 'min',
    unit: 'unit',
    ticket_status: 'Ticket status',
  },
  e: {
    enterAccountToGenerateToken: 'Enter an account to generate token',
    editedBy: 'Edited By',
    editTable: 'Edit Table',
    exampleData: 'Example Data',
    exampleForData: 'Show example for data',
    editRoute: 'Edit route',
    earningOverview: 'Earning Overview',
    EarningReceivedSTATISTICS: 'Earning/Received STATISTICS',
    earningType: 'Earning Type',
    edit: 'Edit',
    editPassword: 'Edit Password',
    editStatus: 'Edit Status',
    efficiency_report_menu: 'Efficiency',
    email: 'Email',
    email_invalid: 'Email is invalid',
    email_require: 'Email is required',
    email_subject: 'Email Subject',
    email_subject_require: 'Email subject is required',
    emailAE: 'Email AE',
    emailIsInvalid: ' Email is invalid',
    emailVerification: 'Pending',
    emailVerificationStatus: 'Email Verification Status',
    emailVerificationSuccessful: 'Verification Successful',
    emergencyCase: 'Emergency Case',
    emergencyStatus: 'Emergency Status',
    employeeID: 'Employee ID',
    employeeInformation: 'Employee Information',
    employee: 'Employee',
    endAt: 'End at',
    endDate: 'End Date',
    endDestinationCode: 'End Location Code',
    endDestinationName: 'End Location Name',
    endLocationCode: 'End Location Code',
    endLocationName: 'End Location Name',
    endValidDate: 'End Valid Date',
    energy_report_menu: 'Energy',
    engine: 'Engine',
    engine_off_time_end_at: 'Engine off time end at',
    engine_off_time_start_at: 'Engine off time start at',
    engine_on_time_end_at: 'Engine on time end at',
    engine_on_time_start_at: 'Engine on time start at',
    engineOff: 'Engine Off',
    engineOffDesc: 'GPS sending position while engine off.',
    engineOn: 'Engine On',
    engineOnCombine: 'Engine On Combine',
    engineOnDesc: 'GPS sending position while engine on.',
    engineOnDuration: 'Engine-on duration',
    engineOnWithImg: 'Engine On with Image',
    engineOnWithImgDesc:
      'GPS sending position while engine on with Dash camera snapshot images.',
    engineStatus: 'Engine Status',
    estFare: 'Est. Fare',
    estimatedDeliveryTime: 'Estimated Delivery Time',
    estTransitDistance: 'Travel Distance (km)',
    estTransitDuration: 'Travel Duration (hr)',
    etc: 'Etc.',
    europeAmerican: 'Europe/American',
    event: 'Event',
    eventAlarm: 'Event Alarm',
    eventEnd: 'Event End',
    eventMap: 'Event Map',
    EventReport: 'Event Report',
    SafetyEventReport: 'Safety Event Report',
    export: 'export',
    excel: 'Excel',
    expand: 'Expand',
    expense: 'Expense',
    expenseBaht: 'Expense (Baht)',
    expireDate: 'Expire Date',
    expiryDate: 'Expiry Date',
    expiredAt: 'Expired At',
    export_date_at: 'Issue date',
    extra: 'Extra',
    editProfile: 'Edit profile',
    enterEmailToReceiveCredentials: 'Enter email to receive credentials.',
    exportUpTo50000RowsAtaTime: 'Export up to 50000 rows at a time',
    editItemsOnly: 'Edit items only',
    end: 'End',
    emptyCar: 'Available',
    endTime: 'end time',
    end_the_concrete_into_the_truck: 'End the concrete into the truck.',
  },
  event: {
    eventSetting: 'Event Setting',
    acc: 'ACC',
    approaching_point: 'Approaching point',
    approaching_point_but_late: 'Approaching point but late',
    arrival_but_late_departure: 'Arrival but late departure',
    arrival_point: 'Arrival point',
    blackbox_restart: 'Blackbox Restart',
    boom_ready: 'Boom ready',
    discharge_concrete: 'Discharge concrete',
    driver_rest_lower_than_10_hours: 'Driver rest lower than 10 hours',
    engineoff: 'EngineOFF',
    engineon: 'EngineON',
    fuel_anomaly: 'Fuel anomaly',
    hard_brake: 'Hard brake',
    hard_turn: 'Hard turn',
    high_beam_off: 'High beam off',
    high_beam_on: 'High beam on',
    high_rpm: 'HiGH RPM',
    hit_the_brake: 'Hit the brake',
    honk: 'Honk',
    into_and_out_of_specific_areas: 'Into and out of specific areas',
    late: 'Late',
    long_idle_time: 'Long idle time',
    low_beam_off: 'Low beam off',
    low_beam_on: 'Low beam on',
    low_speed_more_than_10_minute: 'Low speed more than 10 minute',
    mixing_concrete: 'Mixing concrete',
    move_in_point_late: 'Move in point late',
    move_out_load_point_with_out_ticket: 'Move out load point with out ticket',
    move_out_point_late: 'Move out point late',
    off_route: 'Off route',
    open_door: 'Open door',
    open_door_outside_point: 'Open door outside point',
    over_driving_hour_limit: 'Over driving hour limit',
    poweroff: 'PowerOFF',
    poweron: 'PowerON',
    pto_off: 'PTO Off',
    pto_on: 'PTO On',
    pump_on: 'Pump on',
    refuel: 'Refuel',
    refuel_full: 'Refuel (full)',
    refuel_not_full: 'Refuel (not full)',
    release_the_brake: 'Release the brake',
    reversing_light_off: 'Reversing light off',
    reversing_light_on: 'Reversing light on',
    shock: 'Shock',
    speed_anomaly: 'Speed anomaly',
    stop_honk: 'Stop honk',
    stop_in_area: 'Stop in area',
    stop_mixing_concrete: 'Stop mixing concrete',
    summary_idle_limit: 'Summary idle limit',
    summary_low_speed_more_than_10_minute:
      'Summary low speed more than 10 minute',
    summary_over_driving_hour_limit: 'Summary over driving hour limit',
    temperature_anomaly: 'Temperature anomaly',
    truck_stay_in_plant_more_than_limit: 'Truck stay in plant more than limit',
    turn_signal_off: 'Turn signal off',
    turn_signal_on: 'Turn signal on',
    unauthorized_discharge: 'Unauthorized discharge',
    windshield_wipers_off: 'Windshield wipers off',
    windshield_wipers_on: 'Windshield wipers on',
    work_longer_than_the_time_limit: 'Work longer than the time limit',
    working_distance_less_than_the_rule: 'Working distance less than the rule',
    working_over_limit: 'Working over limit',
    working_within_a_given_period: 'Working within a given period',
  },
  f: {
    factoryWhereVehicleParkedWithinRadius:
      'Factory Where Vehicle Parked Within Radius (Km.)',
    factoryWhereVehicleParked: 'Factory Where Vehicle Parked',
    fuelConsumption: 'Fuel Consumption',
    fillYourNote: 'Fill Your Note',
    filterIcons: 'Filter Icons',
    fast: 'fast',
    freeChoice: 'Free Choice',
    findTheDesiredLocation: 'Find the desired location',
    fillLatLng: 'Please enter: latitude, longitude',
    finished: 'Finished',
    findCarThisArea: 'Find Car This Area',
    findThisArea: 'Find This Area',
    filterNode: 'Filter Node',
    fullname: 'fullname',
    fleetName: 'Fleet Name',
    finish_date: 'finish date',
    finishedPouring: 'finished',
    finishedPouringConcreteDashboard: 'finished',
    filterthelastdepartment: 'Filter the last department',
    filterlatest: 'Use the filter latest',
    factory: 'Factory',
    FactoryWantToUseVehicle: 'Factory',
    factoryCode: 'Factory Code',
    favoriteMenu: 'Favorite Menu',
    facilityName: 'Location Name',
    facilityName2: 'Location Name2',
    facilityName3: 'Location Name3',
    factory: 'Factory',
    fail: 'Fail',
    fare: 'Fare (฿)',
    file: 'File',
    file_location: 'File Location',
    file_size: 'File Size',
    fileapetition: 'File a petition',
    fileMustSmallerThan2MB: 'File must smaller than 2MB!',
    filenotreadydownload: 'Cannot download at this time',
    fillInfomation: 'Fill Infomation',
    fillInForm: ' Fill in Form',
    fillInFrom: 'Fill In From',
    fillPosition: 'Fill Position',
    filter: 'Filter',
    filter_by: 'Filter by',
    filter_type: 'Filter type',
    filter_type1: 'First move out plant to last move in plant',
    filter_type2: 'First engine on to last engine off',
    findCustomer: 'Search passenger',
    findDistance: 'Find Distance',
    findRoute: 'Find Route',
    findSpace: 'Find Space',
    fine: 'Fine',
    finish: 'Finish',
    finished: 'Finished',
    finishRefuel: 'Finish Refuel',
    firmware: 'FirmWare',
    firmwareNumber: 'Firmware Number',
    firmwareVersion: 'Firmware Version',
    first_dest: 'Start Position',
    firstMaintenanceConditions: 'First maintenance conditions',
    firstName: 'First Name',
    firstNameisCharacters: ' First Name must be characters',
    fixed: 'Fixed',
    flateNumber: 'Flate Number',
    flatRate: 'Flat-Rate',
    forceUpdate: 'Force Update',
    forgot_password: 'Forgot Password',
    front: 'Front',
    fuel: 'Fuel',
    fuelA: 'Fuel A',
    fuelB: 'Fuel B',
    fuelC: 'Fuel C',
    fuelFull: 'Fuel Full Report',
    fuelRate: 'Fuel Rate (lite)',
    fuelremainrateReport: 'Fuel Remain Rate Report',
    fuelTankSize: 'Fuel Tank Size',
    fuelType: 'Fuel Type',
    fuelUsage: 'Fuel Usage',
    Fullscreen: ' Fullscreen',
    fullTank: 'Full Tank',
    fullScreen: 'Full Screen',
    fullScreenExit: 'Exit Full Screen',
    from: 'From',
    floor: 'Floor',
    factory: 'Factory',
    FueluseReport: 'Fuel Use Report',
    failed: 'Failed',
    fleetStatus: 'Fleet Status',
    fleetEfficiency: 'Fleet Efficiency',
    floorWallDelivery: 'Floor-Wall Delivery',
    fromAnotherSystem: 'From Another System',
    failedToOpenTicketAtTerminus: 'Failed to open ticket at TERMINUS',
    forceToTakePicturesSeal: 'Force to take pictures seal',
    forceToTakePicturesSendWork: 'Force to take pictures send work',
    fleetManagement: 'Fleet Management',
  },
  g: {
    generate: 'Generate',
    generateTokenAccount: 'Generate Token Account',
    generateTokenResult: 'Generate Token Result',
    generateToken: 'Generate Token',
    gpsWorking: 'GPS Working',
    gpsNotWorking: 'GPS Not Working',
    gpsUpdateAt: 'GPS was last updated ',
    gpsVendorPayingReport: 'Gps Vendor Paying Report',
    gpsDistance: 'GPS distance',
    grade: 'Grade',
    gpsProviderChoose: 'Please Choose GPS provider',
    gpsProvider: 'GPS provider',
    goback: 'Go Back',
    goto: 'Go to',
    gallery: 'Gallery',
    gallery_name: 'Name',
    gasStation: 'Gas Station',
    gate1: 'Door 1',
    gate2: 'Door 2',
    gate3: 'Door 3',
    general: 'General',
    general_report_menu: 'General',
    generalCurve: 'General Curve',
    generalLocation: 'General Location',
    generateMap: 'Generate Map',
    getVehicles: 'Get Vehicles!',
    goBackToUseImagesByLocationType: 'Go Back To Use Images By Location Type',
    googleMapApiKey: 'Google Map Api Key',
    gps_update: 'GPS Update',
    gpsCertification: 'GPS Certification',
    gpsCertRequest: 'GPS Cert. Request (EZY GPS)',
    gpsCertRequestHistory: 'GPS Cert. Request History (EZY GPS)',
    gpsPerformanceDashboard: 'GPS Performance Dashboard',
    gpsNotFixed: 'GPS Not Fixed',
    gpsNotFixedDesc: 'Position Inaccurate.',
    gpsStatus: 'GPS Status',
    GpsstatusReport: ['GPS Status Report', 'GPS Status Report'],
    gpsVendor: 'GPS Vendor',
    gpsVendorPayingeport: 'Gps Vendor Paying Report',
    gpsVendorPayingSummaryReport: 'Gps Vendor Paying Summary Report',
    gradeAtoF: 'Grade (A to F)',
    greater_than: 'Greater than',
    greaterOrEqual: 'Greater or equal',
    group: 'Group',
    group_by: 'Group by',
    group_data: 'Group Data',
    group_idle: 'Only Idle',
    group_speed: 'Only Overspeed',
    groupByLocation: 'Group By Location',
    groupVehicleType: 'Vehicle Group/Type',
    gpsReportLostContact: 'GPS Report Lost Contact',
    gpsDisconnectOnTrip: 'GPS Report Lost Connection During Trip',
    gpsPerformanceDesGPS:
      'Calculated from usage during the running time of each ticket. by deducting the time before notifying the repair In the event of a loss of contact, it is not possible to time the work. The working time for each ticket will be calculated from the time of production + 3 hours.',
    gpsPerformanceDesDrumSensor:
      'Number of tickets that have been poured Compared to tickets with entry to the job site',
    gpsPerformanceDesCard:
      'Number of days to swipe the card compared to the number of days running',
    gpsPerformanceDesRed: '< 95% (for the past month)',
    gpsPerformanceDesYellow: '< 95% (for the current month)',
    gpsPerformanceDesGreen: '>= 95%',
    drumSensorPerformanceDesRed: '< 80% (for the past month)',
    drumSensorPerformanceDesYellow: '< 80% (for the current month)',
    drumSensorPerformanceDesGreen: '>= 80%',
    gpsPerformanceDesNA: 'Unable to assess due to insufficient data',
    generateqrCodeBy: 'Generate QR Code By',
    generateqrCodeInArea: 'Generate QR Code In Area',
    grConditions: 'GR Conditions',
    gpsActive: 'GPS active',
    gpsNotActive: 'GPS not active',
    gpsTime: 'GPS time',
    groupName: 'Group name',
  },
  h: {
    humus: 'Humus',
    hideLocation: 'hide location',
    hardwareID: 'Hardware ID',
    header: 'Header',
    height: 'Height',
    help_menu: 'Helps',
    high: 'High',
    history_servicerepairdevice: 'Historys',
    home: 'Home',
    honeytoastdownloadfile: 'Download Video Honey Toast',
    honeytoastlivestream: 'Honey Toast Live Stream',
    honeytoastplayback: 'Playback Video Honey Toast',
    horsePower: 'Horse Power',
    hotel: 'Hotel',
    hour: 'Hours',
    hours: 'Hours',
    half: 'Half',
    honeyToast: 'Honey Toast',
  },
  i: {
    idCardNotMatch: 'Id Card Not Match',
    is_pay: 'payment of services',
    is_pay_gps: 'payment of GPS services',
    is_pay_sensor: 'payment of drum sensor services',
    is_geofence: 'Area of Geofence',
    informer: 'informer',
    timestampissueticket: 'ticketing time',
    issueticket: 'Issue ticket',
    iconDesc: 'Icon Desc.',
    iconStatus: 'Icon Status',
    id: 'ID',
    idleFuelRate: 'Idle Fuel Rate',
    ignoredAlert: 'Ignored',
    imageFromDashCamera: 'Dash Camera',
    imageFromTheGallery: 'Image From The Gallery',
    imageMustSmallerThan5MB: 'Image Must Smaller Than 5MB!',
    imagesNotUpdate: 'Images not update',
    immediatelyBreak: 'Immediately Breaak (Time)',
    immediatelyAcess: 'Immediately',
    include_engine_off: 'Include engine off data',
    indexFilterOption_All: 'All',
    indexFilterOption_Cancel: 'Cancel',
    indexFilterOption_Confirm: 'Confirm',
    indexFilterOption_Finish: 'Finish',
    indexFilterOption_Unassign: 'Unassign',
    indexFilterOption_Waiting: 'Waiting Confirm',
    indian: 'Indian',
    inEvery: 'in Every',
    infomation: 'Information',
    inform_date: 'Inform Date',
    informationFor: 'Information For',
    inside: 'Inside',
    install: 'Install',
    install_at: 'Install at',
    installDevice: 'Install Device',
    installed: 'Installed',
    installStatus: 'Install Status',
    insurance: 'Insurance',
    insurance_report_menu: 'Insurance',
    insuranceID: 'Insrance ID',
    InsuranceReport: 'Insurance Report',
    insure_report_menu: 'Insurance',
    internalOperation: 'Internal Operation',
    invoice_date: 'Invoice Date',
    invoice_number: 'Invoice number',
    issuedDate: 'Issued Date',
    itemLeft: 'Not Select',
    itemSelected: 'Select',
    insertSuccess: 'Insert success',
    insertFail: 'Insert fail',
    idCard: 'ID_Card',
    isupplyManageVehicle: 'Isupply Manage Vehicle',
    inMaintenance: 'In Maintenance',
    isupplyManageQueue: 'Isupply Manage Queue',
    informationAboutTheCarRunWall: 'Information about the Car Run (Wall)',
    informationAboutTheCarRunFloor: 'Information about the Car Run (Floor)',
    import: 'Import',
    importMutipleCompany: 'Import (Multiple Company)',
    importUpTo1000RowsAtATime: 'Import up to 1000 rows at a time',
    isupplySource: 'Mill',
    isupplyPlant: 'Plant',
    interfaceGR: 'Interface GR',
    isupplyQR: 'QR',
    isupplyCDASE: 'CDAS',
    isupplyFIXED: 'FIXED',
    isupplyMANUAL: 'MANUAL',
    isupplySeal: 'Seal',
    issupplyDP: 'DP',
    inTheSystem: 'In The System',
    isupplySourceCodeRequest: 'Mill is Request.',
    isupplySystemKey: 'Interface CDAS',
    isupplyProduct: 'Product',
    inFrontOfTheCarYouCanSeeTheRegistrationPlateAndTheDropOffPoint:
      'In front of the car, you can see the registration plate and the drop off point.',
    InProcessOfDeliveryTheTicketGroupCannotBeEdited:
      'In process of delivery The ticket group cannot be edited.',
    isupplyMultidrop: 'iSupply Multidrop',
    imageTitle: 'Image Title',
    isupplyBookingOrder: 'Booking order',
    isupplyCustomerName: 'Customer name',
    isupplyCustomerNameIsRequired: 'Customer name is required',
    isupplyCustomerPhone: 'Customer phone',
    isupplyCustomerPhoneIsRequired: 'Customer phone is required',
    isupplyCustomerInformation: 'Customer information',
    informationMustBeUnique: 'Information must be unique.',
    isupplyBookingDashboard: 'Booking Scheduler',
    isupplySaleOrder: 'iSupply SaleOrder',
  },
  j: {
    job: 'Job',
    jobDetail: 'Job Detail',
    jobId: 'Job ID',
    jobStartAt: 'Job Start At',
    jobStatus: 'Job Status',
    jobType: 'Job Type',
    jobTypeSTATISTICS: 'Job Type STATISTICS',
    joinDate: 'Join Date',
    jobSiteGroup: 'Job Site Group',
    jobInformation: 'Job information',
    jobSiteSurvey: 'job site survey data',
  },
  k: {
    keywordAtleast3character: 'Keyword At Least 2 Character',
    kind: 'Kind',
    km: 'km.',
    kmPerLite: 'km./lite',
    kmH: 'km/h',
    kpiDateTime: 'Date/Time',
    kpiEvent: 'Event',
    kg: 'kg.',
  },
  l: {
    lastUpdatedData: 'Last update was ',
    linkForDisplayMovingPoint: 'Link For Display Moving Point',
    loginByToken: 'Login By Token',
    lastUpdatedOn: 'Last Updated On',
    LeavingPlantLateCheckThePlantGeofence:
      'Leaving Plant late, check the plant geofence.',
    LateToTheAgencyCheckTheAgencyGeofence:
      "Late to the agency, check the agency's geofence.",
    largeTruck: 'Large Truck',
    lessThan3days: 'Less Than 3 Days',
    load_amount: 'loaded quantity (cue)',
    load_time: 'load duration (hours)',
    load_end: 'load end',
    load_start: 'load start',
    largeMixer: 'Large Mixer',
    largeMixerAndSmallMixer: 'Large Mixer And Small Mixer',
    lastest_signal: 'Lastest Pos',
    lastmaintenanceAt: 'Last Maintenance At',
    lastName: 'Last Name',
    LastNameisCharacters: ' Last Name must be characters',
    lastQueue: 'Last Queue',
    lastRequestOn: 'Latest Request At',
    late: 'Late',
    latePoint: 'Late Point Criteria',
    latestDriver: 'Latest Driver',
    latitude: 'Latitude',
    latlng: 'Lat,Lng',
    latitudeIsRequired: ' Latitude is Required',
    latitudeIsRequiredAndCantBeEmpty:
      " Latitude is required and can't be empty",
    lazada: 'Lazada',
    leaveTheOriginLocation: 'Leave The Origin Location',
    length: 'Length',
    less_than: 'Less than',
    lessOrEqual: 'Less or equal',
    lightStatus: 'Light Status',
    limit10Driver: ' Limit 10 driver',
    limitAggesiveAccelerationThreshold: 'Aggesive Acceleration Threshold',
    limitDrivingAggesiveThreshold: 'Driving Aggesive Threshold',
    limitDrivingHourTime: 'Limit driving hour time (minutes)',
    limitGoInsideArea: 'Limit Inside Area Duration',
    limitGoOutsideArea: 'Limit Outside Area Duration',
    limitOpenDuration: 'Limit Duration',
    limitParking: 'Limit Parking',
    limitParkingDuration: 'Limit Duration',
    limitParkingInsideLocation: 'Limit Parking Inside Location',
    limitParkingOutsideLocation: 'Limit Parking Outside Location',
    limitSnoozeThreshold: 'Snooze Threshold',
    limitSuddenlyBreakThreshold: 'Suddenly Break Threshold',
    limitUsingPhoneThreshold: 'Using Phone Threshold',
    link: 'Link',
    liveStream: 'Live Stream',
    load: 'Load',
    loadAmount: 'Load Amount',
    loadpoint: 'Crushing plan/Load Point',
    loadStartAt: 'Load Start At',
    location: 'Location',
    Location: 'Location',
    locationAndRoute: 'Location and Route',
    locationBlacklist: 'Locations Blacklist',
    locationCode: 'Location Code',
    locationCodeIsRequired: " Location Code is required and can't be empty",
    locationGroup: 'Location Group',
    locationGroupName: 'Location Group Name',
    locationGroupNameIsRequired: ' Location Group Name is Required',
    locationGroupTypeIsRequired: ' Location Group Type is Required',
    locationInformation: 'Location Information',
    LocationInSystem: 'Location in system',
    locationName1IsRequired: " Location Name1 is required and can't be empty",
    LocationOfDeviceUser: 'Affiliation Location of Driver',
    LocationOfVehicle: 'Affiliation Location of Vehicle',
    locationShareFrom: 'Share From',
    locationShareTo: 'Share To',
    locationTemporary: 'Location Temporary',
    locationType: 'Location Type',
    login: 'Login',
    login_fail: 'Login Fail',
    login_success: 'Login success',
    LoginhistoryReport: 'Login History Report',
    logout: 'Logout',
    longdoMapApiKey: 'Longdo Map Api Key',
    longitude: 'Longitude',
    longitudeIsRequired: ' Longitude is Required',
    LongitudeIsRequiredAndCantBeEmpty:
      " Longitude is required and can't be empty",
    lookingForSomething: 'Looking For Something?',
    lostConnectio: 'Disconnected',
    low: 'Low',
    linenotify: 'Line Notify Setting',
    line_token: 'Line Token',
    line_token_require: 'Line Token is require',
    litre: 'Litre',
    litrePerTrip: 'Litre/Trip',
    line: 'Line',
    localDelivery: 'Local delivery',
    localDeliveryOnly: 'Local Delivery Only',
    locationSharingStatus: 'Location Sharing Status',
    lineId: 'Line ID',
    landmark: 'Landmark',
    locale: 'en-US',
    load_outsidepoint_time: 'load outsidepoint time',
    load_outsidepoint_duration: 'load outsidepoint duration',
    load_outsidepoint_location: 'load outsidepoint location',
    load_outsidepoint_speed: 'load outsidepoint speed',
    loading: 'Loading',
  },
  locationType: {
    All: 'All',
    BS: 'Bus Stop Point',
    BS1: 'Bus Stop 1',
    BS2: 'Bus Stop 2',
    CarService: 'Car Service',
    GasStation: 'Gas Station',
    GasCost: 'Gas Cost',
    GasCostBath: 'Gas Cost/km.',
    NoParking: 'No Parking',
    OnSite: 'On Site',
    ParkingLot: 'Parking Lot',
    Plant: 'Crushing plant/Load point',
    PreparePoint: 'Prepare Point',
    RestArea: 'Rest Area',
    Site: 'Deliver point',
    Temporary: 'Location Temporary',
    Mill: 'Mill',
    MaterialPickUpPoint: 'Material Pick Up Point',
    Unload: 'Unload Point',
  },
  m: {
    maxUsedSpeed: 'Max used speed',
    minutesAgo: ' minutes ago',
    monthCount: 'month count',
    moveTime: 'start moving (date-time, place)',
    mapview: 'Map View',
    map: 'Map',
    mixerSize: 'Mixer Size',
    m: 'm.',
    m_april: 'April',
    meterConcreteDashboard: 'm.',
    m_august: 'August',
    m_december: 'December',
    m_february: 'February',
    m_january: 'January',
    m_july: 'July',
    m_june: 'June',
    m_march: 'March',
    m_may: 'May',
    m_november: 'November',
    m_october: 'October',
    m_september: 'September',
    machineSerialNumber: 'Machine Serial Number',
    maintenance: 'Maintenance',
    maintenance_status: 'Status',
    maintenance_type: 'Maintenance Type',
    maintenancedevice: 'Maintenance Device',
    maintenanceConditions: 'Maintenance Conditions',
    maintenancedevice_report_menu: 'Maintenance Devices',
    MaintenancedeviceconcludeReport: 'Maintenance Device Report',
    MaintenanceReport: 'Maintenance Report',
    manage_product: 'Manage products',
    management_menu: 'Management',
    managementdevice_report_menu: 'Management Devices',
    map: 'Map',
    mapType: 'Map Type',
    mapView: 'Map View',
    masterData: 'Master data',
    match: 'Match',
    materialRequest: 'Date Material Request',
    masterDataMatrial: 'Material',
    material: 'Material',
    materialCode: 'Material Code',
    materialCodeRequest: 'Material Code is Request.',
    materialName: 'Material Name',
    materialNameRequest: 'Material Name is Request.',
    max_speed: 'Max speed (km./h)',
    maxDrumCapacity: 'Max Drum Capacity',
    mdvrdownloadfile: 'Download Video MDVR',
    medium: 'Medium',
    message: 'Message',
    meter: ['Meter', 'Meter'],
    meterDashCameras: 'meter',
    meterSN: 'Meter S/N',
    meterStatus: 'Meter Status',
    materialWasNotFound: 'Material was not found.',
    mileNo: 'Mile No.',
    min: 'Min',
    Minimal: 'Minimal',
    minute: 'Minute',
    miss: 'Miss',
    mixingDrum: 'Drum',
    model: 'Model',
    moisture: 'Humidity',
    monitoring: 'Monitoring',
    month: 'Month',
    monthly: 'Monthly',
    more_10_mins: '> 10 mins',
    more_10_sec: '> 10 secs',
    more_1_min: '> 1 min',
    more_30_sec: '> 30 secs',
    more_5_mins: '> 5 mins',
    more_5_sec: '> 5 secs',
    more_60_sec: '> 60 secs',
    move: 'Move',
    move_in: 'Move in',
    move_out: 'Move out',
    moveDevice: 'Move Device',
    mr: 'Mr.',
    mrs: 'Mrs.',
    manpowerRate: 'Manpower Rate',
    materialGroupType: 'Material Group Type',
    multiDrop: 'Multi Drop',
    mdvr: 'MDVR',
    menu: 'Menu',
    maxOverLimitTime: 'Max Over Limit Time (hour:min:sec)',
    multiDropDescIncomingTrip: 'entering the point',
    multiDropDescIncomingTripDesc:
      'The vehicle is traveling to the specified destination.',
    multiDropDescIncomingLateTrip: 'Entering the point (Late)',
    multiDropDescIncomingLateTripDesc:
      'The vehicle is traveling to the specified destination. and later than the planned time',
    multiDropDescAlreadyVisitTrip: 'Entering the point',
    multiDropDescAlreadyVisitTripDesc:
      'Vehicle arrives at destination (on time)',

    multiDropDescVisited: 'not yet reached the point',
    multiDropDescVisitedDesc: 'The vehicle has not reached its destination.',
    multiDropDescVisitedConfirm: 'pending confirmation',
    multiDropDescVisitedConfirmDesc:
      'The vehicle has reached the destination and is in the process of confirming the entry point. (Stay in place for more than 3 minutes)',
    multiDropDescVisitedLate: 'late entry',
    multiDropDescVisitedLateDesc: 'Vehicle arrived later than planned time.',
    multiDropDescVisitedOnTime: 'Entering the point',
    multiDropDescVisitedOnTimeDesc: 'Vehicle arrives at destination (on time)',

    multiDropDescLeave: 'still not out of point',
    multiDropDescLeaveDesc: 'The vehicle has not left the destination.',
    multiDropDescLeaveConfirm: 'pending confirmation',
    multiDropDescLeaveConfirmDesc:
      'The vehicle leaves the destination and is in the process of confirming the departure point. (outing for more than 3 minutes)',
    multiDropDescLeaveLate: 'Delay point',
    multiDropDescLeaveLateDesc:
      'The vehicle leaves the destination later than the planned time.',
    multiDropDescLeaveOnTime: 'Leave on time',
    multiDropDescLeaveOnTimeDesc: 'Vehicle leaves destination (on time)',
    materialPickUpPoint: 'Material pick up point.',
    moreInformation: 'More Information',
    multidropKey: 'Multidrop Key',
    materialCodeIsRequired: 'Material code is required',
    mode: 'Mode',
    monitorGPS: 'Monitor %GPS',
    monitorGPSLog: 'Monitor GPS log',
    material_code: 'Material code',
    material_description: 'Material name',
    move_during_day: 'Process within day',
    move_endof_day: 'Process end of day',
  },
  n: {
    notSpecified: 'Not Specified',
    notiDownloadVideo:
      'You can download video from the past no more than 3 days for vehicle that are running normally',
    notApprove: 'Reject',
    NewSitePoint: 'New Site Point (Lat,Long)',
    numberOfTimesRunningOverSpeed: 'Number of times running over speed',
    notifyVehicleRegistration: 'Notify vehicle registration',
    nearestPlant: 'Nearest Plant',
    notInstallGPS: 'Not Install GPS',
    neighborhoodInformation: 'Neighborhood Information',
    notFinished: 'Not Finished',
    notFoundIdCard: 'ID Card Number Not Found',
    noDriverName: 'No Driver Name',
    numberSpeedsExceeded: 'number of speeds exceeded',
    noTruckInWrongSpot:
      'There is no truck in the wrong spot / pouring mortar outside the spot.',
    numberOfTruckIsReadyToUse: 'Number Of Truck Is Ready To Use',
    numberOfTruckIsWaitingForProduction:
      'Number Of Truck Is Waiting For Production',
    numberOfTruckOnTheWayAndOnSite: 'Number Of Truck On The Way And On Site',
    numberOfTruckGoesBack: 'Number Of Truck Goes Back',
    name: 'Fullname',
    nameAE: 'Name AE',
    nameAndType: 'Name & Type',
    nameTableCompany: 'Name',
    nameTitle: 'Name Title',
    nationality: 'Nationality',
    nationalityZone: 'Nationality Zone',
    nearByLocation: 'Nearby Location',
    nearbyPlants: 'Nearby Plants',
    nearPlants: 'Near Plants',
    new: 'New',
    newCustomer: 'New Customer',
    newJob: 'New Job',
    newLocation: 'New Location',
    newPassword: 'New Password',
    newRoute: 'New Route',
    newTicket: 'New Ticket',
    newUser: 'New User',
    newUserGroup: 'New User Group',
    netWeight: 'Net Weight',
    netWeightTon: 'Net Weight',
    next: 'Next',
    nextMaintenanceConditions: 'Next maintenance conditions',
    nextQueue: 'Next Queue',
    nextRegisterDate: 'Next Register Date',
    nightTime: 'Night Time',
    no: '#',
    no_filter: 'No filter',
    node: 'Node',
    noData: 'No Data',
    nodeName: 'Node Name',
    noEntry: 'No Entry',
    noExit: 'No Exit',
    nofilevideo: 'Unable to stream video at the selected time',
    none: 'None',
    noteFill: 'Specify Note',
    noParking: 'No Parking',
    normalStatusImage: ['Normal Status Image', 'Normal Status Image'],
    notAvailable: 'Not Available',
    notification: 'Notification',
    notificationEndWhen: 'Notification Close When',
    notificationTime: 'Notification Time',
    notificationTracking: 'Notification Tracking',
    notificationType: 'Notification Type',
    notificationTypeEvent: 'Notification Type (Event Type)',
    notReadyForWork: 'Not Ready For Work',
    notReady: 'Not Ready',
    nounsCar: 'Cars',
    noVehicleSelect: 'No Selection',
    now: 'Now',
    noStatus: 'No status',
    notYetShipped: 'Not Yet Shipped',
    number: 'Quantity',
    number_of_days: 'Number of days',
    numberOfAlarmsPerDay: 'Number of Alarms Per Day',
    numberOfCardSwipeTimes: 'Number of Card Swipe Times',
    numberOfContinuousAlarmDay: 'Number of Continuous Alarm Days',
    NumberofdaysworkedReport: 'Number of Days Worked Report',
    numberofEntryLatePoint: 'Number of Entry Late Point (Point)',
    numberOfExpirationDates: 'Number Of Expiration Dates',
    numberVehiclegroup: 'Quantity',
    note: 'Note',
    notpay: 'Not Pay',
    numberOfTimes: 'Amount (times)',
    noReport: 'No.',
    numberOfTrips: 'Number of trips',
    notify_type: 'Notify Type',
    notify_type_require: 'Notify Type is require',
    netPayload: 'Net Payload',
    netPayloadIsRequire: 'Net Payload is require',
    newDestinationLocation: 'New Destination Location',
    newTicketNumber: 'New Ticket Number',
    notSubmittingWork: 'Not Submitting Work',
    noQueuesOrReservations: 'No Queues Or Reservations',
    notifyTheTruckInCorrectPoint: 'Notify The Truck Incorrect Point',
    nameComparativeRawMaterials: 'Name Comparative Raw Materials',
    newItemsOnly: 'New items only',
    noProblemWeCanDoItItForYouButWeNeedToClearYourTicketGroupFirst:
      'No problem! We can do it it for you, but we need to clear your ticket group first.',
    numberOfBookings: 'Number of bookings',
    numberOfVehicles: 'Number of vehicles',
    numberOfVehiclesIsReadyToUse: 'Number of vehicles is ready to use',
  },
  o: {
    OriginSitePoint: 'Origin Site Point (Lat,Long)',
    orderSize: 'Order Size',
    oilThereIsMalfunction: 'Fuel there is malfunction',
    oilNotYetCalibrated: 'fuel (not yet calibrated)',
    oilAbnormalityWarning: 'Fuel Abnormality Warning',
    orderOfEvents: 'Order Of Events',
    ocher: 'Ocher',
    OnlyMaximum1000RowsDataCanBeImportedAtTime: 'Only a maximum of 1,000 rows',
    openTime: 'Turn on the engine (day-time)',
    on_time: 'on time',
    oilPriceB10: 'Oil Price (B10)',
    oilConsumptionRate: 'Oil Consumption Rate',
    oilUsage: 'Oil Usage',
    oilUsageDetails: 'Oil Usage Details',
    outsidePoint: 'concrete outside the point',
    outsidePointNumber: 'Number of concrete outside the point',
    obd: 'OBD',
    off: 'off',
    offFilter: 'Off',
    offFilterMeter: 'Off',
    offline: 'Offline',
    ok: 'Yes',
    okay: 'OK',
    on: 'on',
    onDevelop: 'On Develop',
    onFilter: 'On',
    onFilterMeter: 'On',
    online: 'Online',
    onProcess: 'On Process',
    onProcessing: 'On Process',
    open: 'Open',
    opened: 'Opened',
    openFilter: 'Open Filter',
    operation: 'Operation',
    operator: 'Osperator',
    or: 'OR',
    orderCode: 'Order Code',
    ordinal_numbers: 'Ordinal No.',
    originLocation: 'Origin Location',
    originToDestination: 'Origin-Destination',
    other: 'Other',
    outOfOriginlocation: 'Out Of Originlocation',
    outOfFactory: 'Departure',
    outside: 'Outside',
    over100: 'Limit 100 vehicles',
    over2000: 'Limit 2000 vehicles',
    over12Month: 'Limit 12 month',
    over1Month: 'Limit 1 month',
    over7Day: 'Limit 7 day',
    over20: 'Limit 20 vehicles',
    over15: 'Limit 15 vehicles',
    over30: 'Limit 30 vehicles',
    over32Month: 'Limit 3 years',
    over1day: 'Limit 1 day',
    over_hour: 'Driving over hour',
    over3Days: 'Over 3 Days',
    overdueSpeedInTheCommunityArea: 'Speed Limited (Community Area)',
    overOneMonth: 'Limit 1 month',
    overSpeedInNormalRoutes: 'Speed Limited (General Route)',
    overSpeedInTheJunction: 'Speed Limited (Junction)',
    overview: 'Overview',
    overviewNotificationTypeHeatMap: 'Overview Notification Type (Heat Map)',
    ownerView: 'Owner View',
    vehicleOffline: 'Vehicle is offline',
    onlineonly: 'Online only',
    ongoing: 'Ongoing',
    OnSite: 'On Site',
    other: 'Other',
    onTheMove: 'On the move',
    outOfTimeLimit: 'Out of time limit',
    over1Day: 'Limit 1 day',
    otherRawMaterialCodes: 'Other Raw Material Codes',
    only_dp: 'Only DP',
    only_dps: 'Only DP',
    open: 'Open',
    openATicketAtTheCpacSystem: 'Open a ticket at the CPAC system.',
    orderNo: 'Order no.',
    order: 'Order',
  },
  p: {
    pleaseDoubleCheck:
      "Please double check your information before clicking 'Confirm'.",
    pleaseZoomInToSearch: 'Please Zoom In To Searching ',
    pleaseFillYourRouteName: 'Please Fill Your Route Name',
    pleaseFillRouteName: 'Please Fill Route Name',
    pouringConcreteOutSideThePointList: 'Pouring Concrete Outside Point List',
    plantName: 'Plant Name',
    pinDoesNotExistInSystem: 'Pin Does Not Exist In System',
    precastConcrete: 'Precast Concrete',
    production: 'production',
    percentage_working: '% working',
    percentage_working_day: 'GPS efficiency(%)',
    primary_location: 'primary location',
    parkingTime: 'Parking Time',
    parkingDateTime: 'Parking Date Time',
    pendingdistrubutetask: 'Pending Distribute Task',
    pendingconfirmation: 'Pending Confirmation',
    parkingDistance: 'Distance (km)',
    parkingDuration: 'Parking Duration (hh:mm:ss)',
    parking: 'Parking',
    parkingLot: 'Parking Lot',
    parkingLocation: 'Parking Location',
    parkingEngineOn: 'Parking Engine On',
    parkingOnRoadSide: 'Parking On Road Side',
    parkingOnTheRoadShoulder: 'Parking on the road shoulder',
    ParkingReport: 'Parking Report',
    parkingSumDistance: 'Parking Sum Distance (km)',
    part_type: 'Part',
    partgroup: 'Part Group',
    partiallyDamagedCard: 'Card Can Not Be Used',
    partner: 'Partner',
    partnerCar: 'Partner',
    partnerPerformReport: 'Partner Performance Report',
    partners: 'Partner',
    partSetting: 'Part Setting',
    partType: 'Part Type',
    passenger: 'Passenger',
    password: 'Password',
    passwordMustBeAtLeast6: 'Password Must be At Least 6 Characters',
    passwordMustMatch: 'Confirm Password Not Match',
    passwordsMustMatch: ' Passwords must match',
    past7day: 'Latest 7 Day',
    payPoint: 'Pay Point',
    pdf: 'PDF',
    pdfFilesUpTo5MBInSize: 'PDF Files Up To 5MB In Size',
    pending: 'Pending',
    period: 'Period',
    periodOfInsuranceYear: 'Period Of Insurance (Year)',
    permission: 'Permission',
    personal: 'Personal',
    personalID: 'Personal ID',
    personalIDMustBeNumber: ' Personal ID Must Be a Number',
    personalIDTooLong: ' Too Long! Personal ID Must Be 13 Digits',
    personalIDTooShort: ' Too Short!, Personal ID Must Be 13 Digits',
    personalInformation: 'Personal Information',
    phoneNumber: 'Phone Number',
    phoneNumberMustBeNumber: ' Phone Number Must Be a Number',
    photo: 'Photo',
    pickup: 'Pick Up',
    pickupData: 'Pick Up Date',
    pickUpDateTime: 'Pick Up At',
    pickUpDetail: 'Pick Up Detail',
    pickUpLocation: 'Pick Up Location',
    pickUpTheQueue: 'Pick Up The Queue',
    pickUpTime: 'Pick Up Time',
    picture: 'Picture',
    pictureUpdate: 'Picture Update',
    pinDown: 'Pinned',
    pinDownGG: 'Pin Google street view',
    plan: 'Plan',
    plan_start_deliver_at: 'Plan start deliver at',
    plant: 'Plant',
    plants: 'Plant',
    plantConcrete: 'Concrete Plants',
    plantsDelivery: 'Plant Delivery',
    plant_name: 'Plant name',
    plantArrivingTime: 'Plant Arriving Time',
    plantCode: 'Plant Code',
    plantFactory: 'Plant/Factory',
    plantMiningContract: 'Delivery Contract',
    plateNo: 'Plate No.',
    plateNoOrCode: 'Plate No./Vehicle code',
    plantWasNotFound: 'Plant was not found.',
    plateNoCar: 'Vehicle registration',
    plateNoOfInstall: 'Plate no of install',
    plateNoOfUninstall: 'Plate no of uninstall',
    pleaseChooseDateRange: ' Please choose date range',
    pleaseChooseEvent: ' Please choose event',
    pleaseChooseReport: ' Please Choose Report',
    pleaseChooseVehicle: ' Please choose vehicle',
    ChooseVehicle: 'Choose Vehicle',
    PleaseFillVehicleFareHours: 'Please Fill Vehicle Fare (Hours)',
    pleaseEnterEmailAddress: ' Please enter e-mail address',
    pleaseEnterEmailSubject: ' Please enter e-mail subject',
    pleaseSelect: 'Please Select',
    pleaseSelectDriver: ' Please select a driver',
    pleaseSelectExpirationDate: ' Please select the expiration date',
    pleaseSelectLocation: ' Please select location',
    pleaseSelectPicture: 'Please select a picture.',
    pleaseSelectReportType: ' Please select report type',
    pleaseSelectTheReport: ' Please select the report',
    pleaseSelectVehicleCode: ' Please select vehicle code',
    pleaseSelectVehiclePlateNo: ' Please select plate no.',
    pleaseSelectVehicleRegistration: ' Please select plate no.',
    pleaseUploadACarRegistrationBookFileForGPSVendor:
      'Please Upload A Car Registration Book File For GPS Vendor.',
    po: 'PO',
    point: 'Point',
    policy: 'Policy',
    policy_report_menu: 'Policy Report',
    polyGonRadius: 'Polygon Radius',
    PONumber: 'P/O Number',
    poNumberIsrequired: ' PO Number is required',
    position: 'Position',
    precastDashboard: 'Precast Dashboard',
    preventReDrawLocationConfirm: 'Location will lost when re draw?',
    preventRePinLocationConfirm: 'Location will lost when re pin?',
    preview: 'Preview',
    previous: 'Previous',
    primary_location: 'Affiliation plant',
    privilegegroup: 'Privilege Group',
    processing: 'Processing',
    product_name: 'Product name',
    product_type: 'Product type',
    productType: 'Product Type',
    product: 'Product',
    projectName: 'Project Name',
    profile: 'Profile',
    province: 'Province',
    pto: 'PTO',
    publicKey: 'Public key',
    pullvehiclesink: 'Pulled Sink Vehicle History',
    purchaseChannel: 'Purchase Channel',
    pleaseSpecifyVehicle: 'Please specify vehicle.',
    PleaseSelectStartingPoint: 'Please Select Starting Point',
    PleaseSelectEndPoint: 'Please Select End Point',
    pleaseSpecifyTicket: 'Please specify ticket.',
    pleaseSpecifyDeliveryTimeAndWork: 'Please specify delivery time and work.',
    pleaseSpecifyDeliveryTime: 'Please specify delivery time.',
    pleaseSpecifyTheNumberOfVehiclesNotMoreThanTheNumberOfTasks:
      'Please specify the number of vehicles. Not more than the number of tasks',
    plateNoVehicleCode: 'Plate No, Vehicle Code',
    plateNoVehicleCodeOnline: 'Plate No, Vehicle Code(Online only)',
    PleaseEnterInitialStartTime: 'Please enter the initial start time',
    PleaseEnterInitialEndTime: 'Please enter the initial end time',
    product_description: 'Description',
    product_code: 'Code',
    product_name_require: 'Name is require',
    product_name_duplicate_require: 'Name is duplicate',
    product_code_require: 'Code is require',
    product_code_duplicate_require: 'Code is duplicate',
    product_uom_require: 'Smallest Uom is require',
    patternThatAcceptsWeightData: 'Pattern that accepts weight data',
    pending: 'Pending',
    problem: 'Problem',
    pay: 'Pay',
    performance: 'performance',
    phonenumberisNotValid: 'Phone Number is not valid.',
    phonenumberIsRequired: 'Phone Number is required.',
    pleaseEnterAnEmailToReceiveTheCertificate:
      'Please enter an email to receive the certificate.',
    pleaseSpecifyTheNumberOfWorksNotExceedingTheNumberOfCars:
      'Please specify the number of works not exceeding the number of cars.',
    pinCode: 'PIN Code',
    pleaseProvideCompleteInformation: 'Please Provide Complete Information.',
    pleaseSelectWorksheetMaintenance:
      'Please select worksheet do you change status.',
    pleaseProvideCompleteInformationInTheFile:
      'Please provide complete information in the file.',
    perTrip: '/Trip',
    problemType: 'Problem type',
    perDay: '/Day',
    pendingApproval: 'Pending Approval',
    pickUpPoint: 'Pick Up Point',
    positionForPhotographingSeal: 'Position For Photographing Seal',
    photoLocationDuringDelivery: 'Photo Location During Delivery',
    photoShoot: 'Photo Shoot',
    previousLocation: 'Previous Location',
    previousTicket: 'Previous Ticket',
    provideCompleteInformationOfRawMaterialsForComparison:
      'Provide Complete Information Of Raw Materials For Comparison',
    produceLocation: 'Product',
    primarily: 'Main',
    phone: 'Phone',
    perTrip: '/Trip',
    pleaseSpecifyNodeName: 'Please specify node name.',
    pleaseSpecifyVisibility: 'Please specify visibility',
    pleaseSpecifyCompany: 'Please specify company',
    pleaseSelectFile: 'Please select a file',
    pleaseSetUpCompany: 'Please set up a company.',
    postalCode: 'Postal code',
    pleaseSpecifySampleImageName: 'Please specify sample image name',
    plateNoIsRequired: 'plate no is required',
    payment: 'Payment',
    paymentType: 'Payment type',
    periodIsrequired: 'Period is required',
    pause: 'Pause',
    pleaseProvideCompleteInformation: 'Please provide complete information.',
    pleaseSpecifyDestination: 'Please specify destination',
    pleaseSpecifyDate: 'Please specify date',
    pleaseSpecifyDeliveryTime: 'Please specify delivery time.',
    permissionName: 'Permission name',
  },
  product_types: {
    animal_food: 'Animal Food',
    bag_cement: 'Bag Cement',
    bulk_cement: 'Bulk Cement',
    chill: 'Chill',
    concrete_pump: 'Concrete pump',
    container: 'Container',
    fcr: 'FCR',
    frozen: 'Frozen',
    generic: 'Generic',
    plate: 'Plate',
    readymixconcrete: 'ReadyMixConcrete',
    room_temp: 'Room Temp',
    spool: 'Spool',
    valuable: 'Valuable',
  },
  q: {
    quantity: 'Quantity',
    quantityIsRequired: 'Quantity is required',
    quantity_liter: 'Quantity (liter)',
    quantity_tons: 'Quantity (tons)',
    queue: 'Queue',
    queueTotal: 'Number of queue',
    queueQuantity: 'Queue Quantity',
    quarter: 'Quarter',
    qrCodeType: 'QR Code Type',
    queueSystemIsNotInUse: 'Queue system is not in use.',
    quantityPerTrip: 'Quantity per trip',
    quantityPerDay: 'Quantity per day',
  },
  r: {
    readyToUseStatus: 'Status Ready',
    requestToUseVehicle: 'Truck sharing',
    requestStatus: 'Request Status',
    ready: 'Ready',
    requestedNames: 'Requested Names',
    requestedPhoneNumbers: 'Requested Phone Numbers',
    requestedFactory: 'Requested Factory',
    requestedName: 'Requested Name',
    requestedPhoneNumber: 'Requested Phone Number',
    roundTripDistance: 'Trip Distance And Time',
    redundantEvents: 'Redundant Events',
    ready_time_for_the_next_job: 'ready time for the next job',
    return_time: 'Return time (hours)',
    remaining_quantity: 'remaining quantity (cue)',
    refuel_position: 'refuel position',
    repairNoticeDate: 'inform date',
    rateFuelUsagePast6MonthsKmLitre:
      'The rate of fuel usage in the past 6 months, km/litre',
    rail: 'Rail',
    readiness: 'Ready',
    radiuns: 'Radius',
    radiunsM: 'Radius (m.)',
    rainingTime: 'Raining Time',
    rack: 'Rack-U',
    reachTheDestination: 'Reach The Destination',
    reActivate: 'Re-Activate',
    receive: 'Receive',
    receive_complete: 'Receive or Complete',
    receive_date: 'Receive Date',
    received: 'Received',
    receiveDate: 'Receive Date',
    receiveTheGoods: 'Receive the goods',
    refuel: 'Refuel',
    refuelAt: 'Refuel At',
    RefuelReport: 'Refuel Report',
    reFuelTitle: 'Refuel',
    returnDistance: 'Return Distance',
    remainDay: 'Remain (day)',
    remainingDevice: 'Remaining Device',
    remainingRequest: 'Remaining Request',
    remark: 'Comment',
    remark1: 'Remark 1',
    remark2: 'Remark 2',
    remarkVendor: 'Remark (Vendor)',
    remarkBooking: 'Remark Booking',
    remindDate: 'Remind Date',
    reminderData: 'Reminder Date',
    reminderTime: 'Reminder Time',
    renewalDate: 'Renewal Date',
    RepairDevice: 'Repair Device',
    repairingReport: 'Repairing Report',
    replay: 'Replay',
    replayDetail: 'Replay Detail',
    replayNew: 'Replay (New) ',
    report: 'Report',
    replayTracking: 'Replay',
    report_daily: 'Daily',
    report_monthly: 'Monthly',
    report_name: 'Report Name',
    report_taxifare: 'Taxi Fare',
    report_weekly: 'Weekly',
    report_yearly: 'Yearly',
    reportsSortedColumns: 'Reports sorted columns',
    reportAlarmFrequencyClassifiedByDailyDriver:
      'Report Alarm Frequency Classified by daily driver',
    reportCardSwipeBehaviorBeforeDrivingDaily:
      'Report Card Swipe Behavior Before Driving Daily',
    ReportAlarmFrequencyByDriverDaily:
      'Report alarm frequency by driver, daily',
    reportSummarizingTheNumberOfOffSiteParking:
      'The Number of Off-Site Parking Report',
    reportType: 'Report Type',
    requestMaterial: 'Request Material',
    requestACertificate: 'Request a certificate',
    requestList: 'Request List',
    require: 'Require',
    requiredFieldCompany: ' Company is required',
    requiredFieldConfirmPassword: ' Confirm Password is required',
    requiredFieldDischargeDenominator: ' Discharge Denominator is Required',
    requiredFieldEmail: ' Email is required',
    requiredFieldFinishRefuel: ' Finish Refuel is Required',
    requiredFieldFirstName: ' First Name is required',
    requiredFieldFuelTankSize: ' Fuel Tank Size is Required',
    requiredFieldLastName: ' Last Name is required',
    requiredFieldLimitDrivingHourTime: ' Limit Driving Hour Time is Required',
    requiredNumberOnly: 'Fill Number Only And Maximun number is ten',
    requiredFieldName: ' Name Title is required',
    requiredFieldPartType: ' Part Type is Required',
    requiredFieldPassword: ' Password is required',
    requiredFieldPersonalID: ' Personal ID is Required',
    requiredFieldPhoneNumber: ' Phone Number is invalid',
    requiredFieldPlateNo: ' Plate No. is Required',
    requiredFieldQuantity: ' Quantity is Required',
    requiredFieldStartRefuel: ' Start Refuel is Required',
    requiredFieldTotalPrice: ' Total Price is Required',
    requiredFieldUsername: ' Username is required',
    requiredFieldVehicle: ' Vehicle is Required',
    requiredFieldVehicleCode: ' Vehicle Code is Required',
    requiredFieldVehicleGroupName: ' Vehicle Group Name is Required',
    requiredFieldVehicleType: ' Vehicle Type is Required',
    requiredAccountVehicle: 'Account Vehicle is Required',
    requiredCompanyPartner: 'Company Partner is Required',
    resendEmail: 'Resend Email',
    reset: 'Reset',
    restArea: 'Rest Area',
    rfid: 'RFID',
    risk: 'Risk',
    RiskbehaviourReport: 'Risk Behaviour Report',
    round: 'Round',
    route: 'Route',
    route_master_name: 'Route master name',
    routemaster: 'Route Master',
    routeName: 'Route Name',
    routeRadius: 'Route Radius',
    routeRadiusM: 'Route Radius (m.)',
    rpm: 'RPM',
    running: 'Running',
    reachTheOnSite: 'Reach The on Site',
    requiredSite: 'Site is Required',
    resetPinCode: 'Reset PIN Code',
    rawMaterialCategory: 'Raw Material Category',
    routemaster_name_require: 'Route master name require',
    rawMaterialCategoryIsRequest: 'Raw Material Category is Request',
    rawMaterialsThatCanBeCarried: 'Raw Materials That Can Be Carried',
    requestToChangeDestination: 'Request To Change Destination',
    requesttoChangeDestination: 'Request To Change Destination',
    recalculateDeliveryArea: 'Re Calculate Delivery Area',
    requestBy: 'Request By',
    reject: 'Reject',
    requestAt: 'Request At',
    randomlyWeighedEvery10Ticket: 'Randomly Weighed Every 10 Ticket.',
    runDNNumbersAutomatically: 'Run DN Numbers Automatically',
    receive_ticket_at: 'Ticket Received At',
  },
  s: {
    selectedEveryMonths: 'Retrieve all data regardless of date range',
    second_clean_at: 'Found Pouring Concrete',
    SiteRadius: 'Agency Radius (Mater)',
    start: 'start',
    summaryVehicleUsage: 'Summary Vehicle Usage Report',
    statusAndCapacity: 'Status & Capacity (used/total)',
    seeAll: 'See All',
    searchYourRoute: 'Search Your Route',
    showMorePlantInformation: 'Show more plant information',
    specifyName: 'Specify Name',
    siteLargeTruck: 'Site Large Truck',
    siteSmallTruck: 'Site Small Truck',
    smallTruckRoute: 'Small Truck Route',
    smallTruckRouteDoNotEnter: 'Small Truck Route Do Not Enter',
    showCpacPlant: 'Show Cpac Plant',
    slow: 'slow',
    startingAtOverSpeedBrakingSuddenlyWhichCausedTheSpeedToDecreaseRapidly:
      'Starting At Over Speed Braking Suddenly Which Caused The Speed To Decrease Rapidly',
    servicecharge: 'Service Charge',
    safetyEventReport: 'Safety Event Report',
    smallTruck: 'Small Truck',
    smallsTruck: 'Small Truck',
    smallTrucKAndLargeTruck: 'Small TrucK And Large Truck',
    system: 'System',
    ShowMarkerInSimpleWay: 'Show Marker in a simple way',
    skip: 'skip',
    selectPlay: 'Select Play',
    seeall: 'See all',
    sumTotalDistance: 'Sum Total Distance',
    second: 'Second',
    stopTime: 'stop moving (date-time, place)',
    setType: 'Set Type',
    safeDriving: 'Safe Driving',
    speed60kmH: '60 km/h',
    speed80kmH: '80 km/h',
    speed100kmH: '100 km/h',
    speed120kmH: '120 km/h',
    speedOver: 'Speed Over',
    siteName: 'Site name',
    siteNameSafetyDashboard: 'Site Name Working (dp)',
    startPouring: 'Start',
    startproduction: 'Start production',
    saveTableSize: 'Save Table Size',
    section: 'Section',
    smartDispatching: 'Smart Dispatching',
    smallMixer: 'Small mixer',
    safety: 'Safety',
    SafetyEventReport: 'Safety Event Report',
    safetyDashboard: 'Safety Dashboard',
    salesChannels: 'Sales Channels',
    salesMan: 'Salesman',
    satellite: 'Satellite',
    save: 'Save',
    saveNext: 'Save & Next',
    sand: 'Sand',
    scan_date_at: 'Uploaded Date',
    SCCOCarComparisonReport: 'SCCO car comparison report',
    schedule_time: 'Schedule time',
    schedule_type: 'Schedule Type',
    scheduled: 'Scheduled',
    scheduled_day: 'Schedule day',
    schedulereport: 'Schedule Report',
    scoreEconomy: 'Economy Score (Score)',
    scorePlanAchievement: 'Plan Achievement Score (Score)',
    scoreSafety: 'Safety Score (Score)',
    ScoresummaryReport: ['Score Summary Report'],
    Scoresummary: 'Score summary',
    scoreTotal: 'Total Score (Score)',
    sealAt: 'Seal at',
    sealNO: 'Seal no.',
    searchNormal: 'Search',
    search: ['search', 'search..'],
    searchDashCameras: 'Search Text from Plate No ...',
    searchDirections: 'Search Directions',
    searchPlants: 'Search',
    searchText: 'Search Text',
    searchTextFromPlateNo: 'Search Text From Plate No ...',
    second_dest: 'End Position',
    secondaryPhoneNumber: '2nd Phone Number',
    seenAll: 'See All',
    seeMap: 'See Map',
    select_event: 'select event',
    selectAssignTo: 'Select Assign To',
    selectCriticalityLevel: 'Select Criticality Level',
    selectDate: 'Select date',
    selected: 'Selected',
    selectedDevice: 'Selected Device',
    selectedVehicleAlready: 'Selected Vehicle Already',
    selectFile: 'Select File',
    selectNotificationChannel: 'Select Notification Channel',
    selectOne: 'Select One',
    selectPlateNo: 'Select Vehicle',
    selectPlateNoOrVehicleCode: 'Select Vehicle',
    selectStatus: 'Select Status',
    selectTime: 'Select time',
    selectVehicle: 'Select Vehicle',
    selectVehicleGroup: 'Select Vehicle Group',
    send: 'Send',
    send_data_before_delivery: 'Use data from previous day',
    send_email: ' Send email',
    sendAsAGroupOfVehicles: 'By Vehicle Group',
    sender: 'Sender',
    sendFailure: 'Send Failure',
    sendRequest: 'Send Request',
    sendSelectedVehicles: 'By Selected Vehicle',
    sendWork: 'Send Work',
    sentBy: 'Sent By',
    sentSuccessfully: 'Sent Successfully',
    serviceinstalldevice: 'Service Install Device',
    servicemovedevice: 'Service Move Device',
    Servicerepairdevice: 'Repair Inform',
    setTheRoute: 'Set The Route',
    setting: 'Setting',
    setting_filter: ' Filter Setting',
    setup: 'Set Up',
    shaft: 'Shaft',
    sharedAt: 'Shared At',
    shareFrom: 'Share From',
    shareLocation: 'Share Location',
    shareTo: 'Share To',
    sharpBend: 'Sharp Bend',
    ship_to: 'Ship to',
    site_name: 'site name',
    shippingInfo: 'Shipping Information',
    shippingInformation: 'Shipping Information',
    shipTo: 'Ship To',
    shipping: 'Shipping',
    shopee: 'Shopee',
    show: 'Show',
    showAll: 'Show All',
    showTraffic: 'Show Traffic',
    showlocation: 'show location',
    scheduleWork: 'Schedule work',
    simNumber: 'ICCID',
    site: 'Site',
    siteCode: 'Site Code',
    sizeAndWeight: 'Size And Weight',
    slideWrongCardType: 'Invalid Card Type',
    slidingCard: 'Card Swipe',
    smallTruck: 'Small Truck',
    smsNotifyTo: 'SMS Notify To',
    sort: 'Sort',
    someparts: 'Some Parts',
    sos: 'sos',
    sortAmountAsc: 'Sort By Asc.',
    sortAmountDesc: 'Sort By Desc.',
    southeastAsia: 'Southeast Asia',
    specialPrivilege: 'Special Privilege',
    speed: 'Speed',
    speedAveg: 'Average Speed (km./h)',
    speedKmPerHr: 'Speed (km./h)',
    speedLimited: 'Speed Limited',
    speedOverlimit60: 'Speed Over 60 km./h',
    SpeedNotOver: 'Speed ​​not over (km/h)',
    speedOverLimit: 'Speed Over Limit',
    speedOverLimitTime: 'Speed Over Limit (Time)',
    speedOverLimitDate: 'Speed Over Limit (Date)',
    speedOverLimitByDriver: 'Speed Over Limit By Driver',
    speedOverLimitByVehicleGroup: 'Speed Over Limit By Vehicle Group',
    speedOverLimitByVehicleType: 'Speed Over Limit By Vehicle Type',
    SpeedoverlimitsummaryReport: 'Speed Over Limit Summary Report',
    speedRange: 'Speed Range (km./h)',
    speedRangeNoKm: 'Speed Range',
    start_daily_time: 'Daily start time',
    start_deliver_at: 'Start Deliver At',
    startAndEndValidDateDesc: 'Vehicles Using Period',
    startAt: ' Start at',
    startDate: 'Start Date',
    startAtDrivinglicensescanlog: 'start at driving license scan log',
    startDeliveryAt: 'Start Deliver At',
    startDeliveryDay: 'Start Delivery Day',
    startDestinationCode: 'Start Location Code',
    startDestinationName: 'Start Location Name',
    startLocationCode: 'Start Location Code',
    startLocationName: 'Start Location Name',
    startRefuel: 'Start Refuel',
    startTrip: 'Start Trip',
    startValidDate: 'Start Valid Date',
    state: 'State',
    status: 'Status',
    status_download_file: 'Status Download File',
    statusCurrent: 'status',
    status_engineoff: 'Engine off',
    status_moving: 'Moving',
    status_parked: 'Parked',
    status_vehicle: 'Vehicle status',
    statusVehicleVideo: 'Status',
    stopWithEngineOff: 'Stop/Engine Off',
    stopWithEngineOn: 'Stop/Engine On',
    stone: 'Stone',
    submit: 'Save',
    submit_name: 'Submit name',
    submit_telno: 'Submit telno',
    submitName: 'Submit Name',
    submitTelno: 'Submit Tel No',
    subType: 'Sub Type',
    success: 'Sucess',
    SummaryiconsiamReport:
      'Shuttle Boat – Shuttle Bus Service Average Summary Report',
    summaryInfo: 'Summary Information',
    SummaryKPIByDriverReport: 'KPI by Driver Report',
    SummaryKPIReport: 'Summary KPI Report',
    support: 'Support',
    surplus: 'Surplus',
    so: 'SO',
    summaryDashboard: 'Summary Dashboard',
    successfullyDelivered: 'Successfully Delivered',
    shippingRate: 'Shipping Rate',
    salary: 'Salary',
    sentSuccessfully: 'Sent Successfully',
    specifyNumberDescription:
      'specify number of times Greater than or equal to the number of alarms per day that require highlighting.',
    searchBy: 'Search By',
    status_repair: 'Status Repair',
    statusGR: 'Status GR',
    sumOverLimitTime: 'Sum Total Over Limit Time (hour:min:sec)',
    setPermissions: 'Set Permissions',
    sendInPlantArea: 'Send in Plant Area',
    seal: 'Seal',
    sideSeal: 'Side Seal',
    stackStockAfterDumpIsDone: 'Stack stock after dump is done.',
    shareFrom: 'Share from',
    systemKey: 'System Key',
    smartScale: 'SmartScale',
    siteGroup: 'Site Group',
    site: 'Site',
    siteGroupName: 'Site Group Name',
    siteGroupNameIsRequired: 'Site Group Name is Required',
    supplierNo: 'Supplier No.',
    subcontactNo: 'Subcontact No.',
    supplierNoRequest: 'Supplier No. Request',
    subcontactNoRequest: 'Subcontact No. Request',
    scaleBeforeSuckingTheSolution: 'Scale before sucking the solution',
    scaleAfterSuckingTheSolution: 'Scale after sucking the solution',
    stockPickingPoint: 'Stock Picking Point',
    stoneTransportTruckPlate: 'Stone Transport Truck Plate',
    samplingBagWithInformationOnTheFrontOfTheBag:
      'Sampling Bag With Information On The Front Of The Bag',
    sendWrongDestination: 'Send Wrong Destination',
    setVariable: 'Set Variable',
    section_name: 'section name',
    section_code: 'section code',
    secondary: 'Secondary',
    subdistrict: 'Sub District',
    sampleImages: 'Sample Images',
    subMaterialCodeIsRequired: 'Sub material code is required',
    siteCode: 'Site code',
    siteCodeIsRequired: 'Site code is required',
    siteInformation: 'Site information',
    siteLocation: 'Site location',
    subDistrictSubDistrict: 'Sub-district/Sub-district',
    start: 'Start',
    saleOrderNo: 'Sale order no.',
    startWork: 'start date',
    startTime: 'start time',
    saleOrder: 'Sale Order',
    selectOrder: 'Select order',
    showOnlyGPSLessThan100: 'Show only data less than 100%',
    start_dropping_off_truck: 'Start dropping off truck.',
    structure: 'Structure',
    showEnabledOnly: 'Show enabled only',
  },
  scheduleReport: {
    excel: 'Excel',
    pdf: 'PDF',
    theDriverIsIicenseReportHasExpired:
      'General >> The Driver Is License Report Has Expired',
    SpeedoverlimitsummaryReport: 'Behavior >> Speed Over Limit Summary',
    dailyCarStatusReport: 'Behavior >> Daily Car Status Report',
    DrivinglicensescanlogReport: 'Behavior >> Driving License Scan Log Report',
    DrivinglogReport: 'Behavior >> Driving Log Report',
    summaryFuelUsage: 'General >> Summary Vehicle Usage Report',
    FueluseReport: 'Energy >> Fuel Use Report',
    distancereport: 'Trip >> Distance Report',
    gpsDisconnectOnTrip: 'Maintenance Devices >> GPS Disconnect On Trip',
    gpsReportLostContact: 'Maintenance Devices >> GPS Report Lost Contact',
    drivingSummary: 'Efficiency >> Driving Summary',
    SafetyEventReport: 'Alert & Alarm >> Safety Event Report',
    fuelremainrateReport: 'Energy >> Fuel Remain Rate Report',
    TripbyengineoffonReport: 'Behavior >> Trip By Engine Off On Report',
    TruckengineonReport: 'Efficiency >> Truck Engine On Report',
    VehicleperformanceconcludeReport:
      'Efficiency >> Vehicle Performance Conclude Report',
    BehaviordrivingReport: 'Alert & Alarm >> Behavior Driving Report',
    BehaviordrivingbycompanyReport:
      'Alert & Alarm >> Behavior Driving By Company Report',
    report_comparevehiclescco: 'General >> Compare Vehicle SCCO Report',
    report_dailyiconsiam:
      'General >> Shuttle Boat – Shuttle Bus Service Daily Report',
    report_distfromlastmaintenance: 'General >> Distfromlastmaintenance Report',
    report_dozeoffrisk: 'General >> Doze off risk Report',
    report_driverinformation: 'General >> Driverinformation Report',
    report_drivingdistance: 'General >> Driving Distance Report',
    report_drivinghour: 'General >> Driving hour Report',
    report_event: 'General >> Event Report',
    report_maintenance: 'General >> Maintenance Report',
    report_numberofdaysworked: 'General >> Number of days worked Report',
    report_parking: 'General >> Parking Report',
    report_summaryiconsiam:
      'General >> Shuttle Boat – Shuttle Bus Service Average Summary Report',
    report_temperature: 'General >> Temperature Report',
    report_truckcurrentlocation: 'General >> Current Address Report',
    report_truckusage: 'General >> Truck Usage Report',
    serviceinstalldevice: 'Service Install Device',
    sizeAndWeight: 'Size And Weight',
    sharpBend: 'Sharp Bend',
    SpeedNotOver: 'Speed ​​not over (km/h)',
    saftyDashboard: 'Safty Dashboard',
    ScoresummaryReport: 'Score Summary Report',
    selectVehicle: 'Select Vehicle',
    sendRequest: 'Send Request',
    success: 'Success',

    report_repairingreport: 'Maintenance Device >> Repairing Report',
    gpsVendorPayingReportCost: 'The Cost >> Gps Vendor Paying Report',
    gpsVendorPayingSummaryReport:
      'The Cost >> Gps Vendor Paying Summary Report',
    partnerPerformReport: 'The Cost >> Partner Performance Report',
    NumberofdaysworkedReport: 'General >> Number of days worked Report',
    concreteTrip: 'Trips >> Concrete Trip',
    DrivingdistanceReport: 'General >> Driving Distance Report',
    TruckusageReport: 'General >> Truck Usage Report',
    ParkingReport: 'General >> Parking Report',
    repairingReport: 'Maintenance Device >> Repairing Report',
    unauthorizeunload: 'Trip >> Unauthorizeunload Report',
    gpsVendorPayingReport: 'The Cost >> gpsVendorPayingReport',
  },
  t: {
    ThisReportHasLimitOnTheNumberOfVehiclesThatCanBeReported:
      'This Report Has Limit On The Number Of Vehicles That Can Be Reported',
    timeToDriveAtMaximumSpeed: 'Time to drive at maximum speed',
    tokenActive: 'Active',
    token: 'Token',
    tokenExpireds: 'Token Expired',
    tooMuchZoom: 'Too Much Zoom',
    timeToArrive: 'Time To Arrive',
    timeStamp: 'Time stamp',
    ticketNumber: 'Ticket Number',
    timeMinute: 'Time/Minutes',
    timeSpitConcrete: 'Spitter Concrete',
    tourStep1:
      'Welcome to data visibility, this menu contain two main section.',
    tourStep2: 'First, tree section.',
    tourStep3: 'Second, detail section.',
    tourStep4: 'This is root node.',
    tourStep5: 'Click to add child node.',
    tourStep6: 'Children node here.',
    tourStep7: 'You can drag here to change node path.',
    tourStep8: 'Click to delete this node and children.',
    tourStep9: 'Click to add child node.',
    tourStep10: 'Click to select this node.',
    tourStep11: 'Name of selected node, This will show after you click',
    tourStep12: 'You can select detail for selected node.',
    tourStep13: 'Click to save changes.',
    totalDividedBy2: 'total divided by 2',
    totalKm: 'Total (km)',
    take_time_to_clean_the_car: 'take time to clean the car (minute)',
    ticket_cancellation_code: 'ticket cancellation code',
    total_duration: 'total duration (hours)',
    time_to_leave_job_site: 'time to leave job site',
    time_end_cement: 'time end cement',
    time_start_cement: 'time start cement',
    time_before_start_cement: 'time before start cement (hours)',
    time_to_visit_job_site_according_to_plan:
      'time to visit job site according to plan',
    time_to_arrive_job_site: 'time to arrive job site',
    time_from_plant_to_Site: 'Time from plant to site (hours)',
    time_to_leave_plant: 'time to leave plant',
    time_to_return_plant: 'time to return plant',
    truckNumber: 'TruckNumber',
    total: 'Total',
    toSection: 'arrive',
    turnOnTurnOffEngine: '(Turn on/Turn off) Engine',
    truck_id: 'Truck id',
    trip: 'Trip',
    typeoftruck: 'Type of truck',
    truckGoesBack: 'Truck Goes Back',
    truckOnTheWayAndOnSite: 'Truck On The Way And On Site',
    truckIsReadyToUse: 'Truck Ready To Use',
    truckIsNotReadyToUse: 'Truck Not Ready To Use',
    truckStatusDashboardMain: 'Status Dashboard',
    truckstatusdashboard: 'Truck Status Dashboard',
    tagID: 'Tag ID',
    taxiMeter: 'Taxi Meter',
    taxNo: 'Tax No.',
    technician: 'Technician',
    telnoAE: 'Telno AE',
    temperature: 'Temperature',
    temperature1: 'Temp. 1',
    temperature2: 'Temp. 2',
    TemperatureReport: 'Temperature Report',
    theStatusOfVehiclesThatReceiveDailyJobs:
      'The status of vehicles that receive daily jobs',
    theWaitingQueue: 'The waiting queue.',
    theDayThatTheCarPulled: 'The Day That The Car Pulled',
    theDriverIsIicenseReportHasExpired:
      "The Driver's License Report Has Expired",
    theNumberOfWorkCompleted: 'The number of work completed',
    theRoute: 'Route',
    TheUsualRoute: 'The Usual Route',
    thisItemHasBeenDiscontinued: 'This item has been discontinued.',
    thisLocationCodeIsAlreadyInTheSystem:
      'This location code is already in the system.',
    thisNationalIDNumberIsAlreadyInTheSystem:
      'This national ID number is already in the system.',
    thisMonth: 'This Month',
    thisYear: 'This Year',
    ticket: 'Ticket',
    ticketNumber: 'Ticket number',
    timeRange: 'Time Range',
    timeDate: 'Time Range',
    time: 'Time',
    time_1440: '24 hrs',
    time_15: '15 mins',
    time_180: '3 hrs',
    time_30: '30 mins',
    time_300: '5 hrs',
    time_420: '7 hrs',
    time_60: '1 hr',
    time_720: '12 hrs',
    time_entry: 'Entry time',
    time_out: 'Out time',
    time_period: 'Time Period',
    time_travel: 'Travel time',
    timeGoToLocation: 'Time Go To Location',
    time_use_in_location: 'Time in location',
    times: 'Times',
    timeThatCanBeUsed: 'Time That Can Be Used.',
    timeZone: 'Time Zone',
    tire: 'Tire',
    title: 'Title',
    titleAccount: 'Account',
    to: ' To ',
    to_email: 'To',
    today: 'Today',
    toggleHeatMap: 'Toggle Heat Map',
    tokenExpired: 'Session Expired',
    tool: 'Tool',
    tools: 'Tools',
    top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod:
      'Top 5 Alert Reports of Each Type Divided by Monthly Time Period',
    topCustomer: 'Top Customer',
    topEarning: 'Top Earning',
    totalTime: 'Total time',
    totalTimeHHMMSS: 'Total time (HH/MM/SS)',
    totalTimeHHMM: 'Total time (HH/MM)',
    toSiteDistance: 'To Site Distance',
    total: 'Total',
    TotalFuel: 'Total oil volume (liters)',
    totalDistance: 'Total Distance (km/h)',
    totalDuration: 'Total Duration (Day:Hour:Minutes)',
    totalDurationFuelUsage: 'Total Duration',
    totalEmergencyCase: 'Total Emergency Case',
    totalNumberofMinutesEnteredSlowly:
      'Total Number of Minutes Entered Slowly (Min)',
    totalExpenses: 'Total expenses (Baht)',
    totalPrice: 'Total Price',
    totalPrice_baht: 'Total Price (Baht)',
    tracking: 'Tracking',
    TrackingdataReport: 'Tracking Data Report',
    trackingNo: 'Tracking No.',
    trainer: 'Trainer',
    trainingDate: 'Training Date',
    traininglist: 'Training List',
    trip: 'Trip',
    trip_menu: 'Trip',
    tripIsupply: 'Trip',
    tripInfoDateTime: 'Trip information, date/time',
    trip_date: 'Duration Date Trip',
    trip_duration: 'Start at - End at',
    trip_report_menu: 'Trip',
    TripbyengineoffonReport: 'Trip By Engine On - Off Report',
    tripCode: 'Trip Code',
    tripInfo: 'Trip Information Date/Time',
    tripStartAt: 'Trip Start At',
    tripStatus: 'Trip Status',
    TripsumbylocationReport: 'Trip Summary by Location Report',
    TripsumbyvehReport: 'Trip Summary Seperated by Vehicle Report',
    TripsummaryReport: 'Trip Summary Report',
    TriptemperatureReport: 'Trip Temperature Report',
    truckCategory: 'Truck Category',
    TruckcurrentlocationReport: 'Current Address Report',
    truckDriverMatching: 'Truck Driver Matching',
    TruckengineoffReport: 'Truck Engine Off Report',
    TruckengineonReport: 'Truck Engine On Report',
    TrucknotuseReport: 'Truck Not Use Report',
    truckType: 'Truck Type',
    TruckusageReport: 'Truck Usage Report',
    travelingTooLong: 'Traveling too long',
    transportContractor: 'Transport Contractor',
    type: 'Type',
    type_duration: 'By Duration',
    type_person: 'By Person',
    type_problem: 'Type Problem',
    type_report: 'Type Report',
    type_trip: 'By Trip',
    typeLocation: 'Type Location',
    typeMaintenance: 'Type',
    transportationPerformanceDashboard: 'Transportation Performance Dashboard',
    traveling: 'Traveling',
    truckAvailable: 'Truck Available',
    totalFleet: 'Total fleet',
    theCost: 'The Cost',
    totalFilesMustBeSmallerThan20MB: 'Total files must be smaller than 20MB!',
    totalUsageTime: 'Total usage time (day:hour:min)',
    totalParkingTime: 'Total parking time (day:hour:min)',
    totalIdleTime: 'Total idle time (day:hour:min)',
    totalRunningTime: 'Total running time (day:hour:min)',
    tonsPerTrip: 'Tons/Trip',
    thisDoNumberExistsinTheSystem: 'This Do Number exists in the system',
    tooShort: 'Too Short!',
    tooLong: 'Too Long!',
    turnLeft: 'Turn Left.',
    turnRight: 'Turn Right.',
    thereIsaMaterialCodeAlreadyInTheSystem:
      'There is a material code already in the system.',
    timeToWeigh: 'Time To Weigh.',
    thisSubMaterialIsAlreadyInTheSystem:
      'This Sub Material Is Already In The System.',
    typesOfVehiclesThatCanBeCarried: 'Types of Vehicles That Can Be Carried',
    typesOfMaterialThatCanBeCarried: 'Types of Material That Can Be Carried',
    truckTypeno: 'TruckType No.',
    theDestinationAreaIsNotCovered: 'The Destination Area Is Not Covered',
    takePhotosOutsideTheArea: 'Take Photos Outside The Area',
    ticketCreationDate: 'Ticket Creation Date',
    theFrontOfTheCarSawTheLicensePlate:
      'The ront of the car saw the license plate.',
    takeAPhotoForward: 'Take a photo forward',
    takeAPhotoOnTheRight: 'Take A Photo On The Right',
    takeAPhotoNextToTheCar: 'take A pPhoto Next To The Car',
    testResult: 'Test Result',
    takePhotosForWorkOutsideTheArea: 'Take Photos For Work Outside The Area',
    thisIsCorrectAlreadyUsedAt: 'this is correct, already used at',
    thereIsInformationInTheSystem: 'There Is Information In The System.',
    typesOfVehiclesThatCanBeCarried: 'Types of vehicles that can be carried',
    thisComparativeMaterialCodeAlreadyExistsInTheSystem:
      'This comparative material code already exists in the system.',
    thisContractOfCarriageAlreadyInTheSystem:
      'This contract of carriage already in the system.',
    theVehicleWasNotFoundInTheTERMINUSSystem:
      'The vehicle was not found in the TERMINUS system.',
    truckPickupCard: 'Truck pickup card',
    thisCardNumberIsAlreadyInTheSystem:
      'This card number is already in the system.',
    totalVehicle: 'Total number of vehicles',
    telno: 'Tel.',
    theEndTimeMustBeGreaterThanTheStartTime:
      'The end time must be greater than the start time.',
    typeSite: 'Type Site',
    ticketTime: 'Ticket time',
    ticketOrder: 'Ticket order',
    ticketPlantName: 'Plant name',
  },
  u: {
    unload_date: 'unload date (Mater)',
    UnloadPoint: 'Unload Point (Lat,Long)',
    useAllFuel: 'Use All Fuel',
    userThatCreatedTheToken: 'User That Created The Token',
    underFactory: 'under the factory',
    underDepartment: 'under the department',
    underSection: 'under the section',
    underDivision: 'under the division',
    unassign: 'Unassign',
    unBroken: 'Unbroken',
    uninstall: 'Uninstall',
    unitPrice: 'Unit Price (Baht)',
    unknown: 'Unknown',
    unMatch: 'Un Match',
    unloadOutsideTheArea: 'Unload Outside The Area',
    upcomingQueue: 'Upcoming Queue',
    updatedAt: 'Updated At',
    updated: 'updated',
    updatedBy: 'Modified By',
    updateLocation: 'Update A Location',
    updateOnlyTheInformationEntered: 'Update Only The Information Entered',
    updatePictures: 'Update A Photo',
    upload: 'Upload',
    uploadYourFile: 'Upload Your File',
    uploaderIsRequired: ' Uploader is a required',
    unLoadCount: 'UnLoad Count',
    uploadFile: 'Upload File',
    uploadFileOfRegistrationPage: 'Upload File of Registration Page',
    unload_finished_at: 'Unload Finished At',
    use_account_name: 'Account name',
    use_date_time: 'Action date time',
    user: 'User',
    userClose: 'User Closed',
    userExpire: 'User Expire',
    userExpireAt: 'User Expire At',
    userGroup: 'User Group',
    userGroupPremission: 'User Group Permission',
    userName: 'Username',
    userProfile: 'User Profile',
    unloadPoint: 'Unload Point',
    unloadPointName: 'Unload Point Name',
    unloadPointCode: 'Unload Point Code',
    uom_name: 'Name',
    uom_description: 'Description',
    uom: 'UOM',
    uom_name_require: 'Name is require',
    uom_name_duplicate_require: 'Name is duplicate',
    uom_smallest: 'Smallest Uom',
    updatetSuccess: 'Updatet Success',
    updateFail: 'Update Fail',
    unit: 'Unit',
    updateimei: 'Update Imei',
    updatevehicle: 'Update Vehicle',
    useTheQueueSystem: 'Use the queue system',
    usernameMustContain: 'Username Must Contain',
    unauthorizeunload: 'Unauthorizeunload Report',
    unload_when_speed_morethan_10: 'Unload When Speed > 10 km/hr.',
    unload_method: 'Unload method',
  },
  v: {
    VehicleNumberVehicleRegistration: 'เบอร์พาหนะ / ทะเบียนพาหนะ',
    vehicleStatus: 'Vehicle Status',
    vehicleNumber: 'Vehicle Number',
    validDate: 'Valid Date',
    valueIsRequired: " Value is required and can't be empty",
    vehicle: 'Vehicle',
    vehicle_type: 'Vehicle Type',
    vehicle_typeIsRequired: 'Vehicle Type is required',
    vehicle_type_code: 'Vehicle Type Code',
    vehicleAndDriver: ['Vehicle & Driver', 'Vehicle and Driver'],
    vehicleBlacklist: 'Blacklist : Vehicle',
    vehicleCode: 'Vehicle Code',
    vehicleColor: 'Vehicle Color',
    vehicleDetail: 'Vehicle Detail',
    vehicleGroup: 'Vehicle Group',
    vehicleGroupName: 'Vehicle Group Name',
    vehicleID: 'Vehicle ID',
    VehicleinareaReport: 'Vehicle in Area Report',
    VehicleinformationdltReport: 'Vehicle Information for DLT',
    vehicleModel: 'Vehicle Model',
    VehicleperformanceconcludeReport: 'Vehicle Performance Conclude Report',
    vehicleRegisterType: 'Vehicle Register Type',
    vehicleRegistrationBookFile: 'Vehicle Registration Book File',
    vehicleSharing: 'Vehicle Sharing',
    vehicleStatus: 'Vehicle Status',
    vehicleType: 'Vehicle Type',
    VelocitysummaryReport: 'Velocity Summary Report',
    vendor: 'Vendor',
    vendorIsupply: 'Vendor',
    verifyYourEmailAddress: 'Verify Your Email Address',
    version: 'Version',
    video: 'MDVR Live Stream',
    video_type: 'Video Type',
    videoDownload: 'Video Download',
    view: 'View',
    vnCode: 'VN Code',
    voluntary: 'Voluntary',
    volumn: 'Volumn',
    vehicle_maintenancedevice_duplicate:
      'This Plate No./Vehicle Code has already been informed in the system',
    visibilityOfThisUser: 'Visibility of this user',
    visibilityOfThis: 'Visibility of this',
    vendorCodeIsRequired: 'Vendor code is required',
    vehicleInformation: 'Vehicle Information',
    vehicleUsageData: 'Vehicle Usage Data',
    vehicleLists: 'Vehicle lists',
    vehicleView: 'Vehicle view',
    vehicleIsNotAvailable: 'Vehicle is not available',
    vehiclePlateNo: 'Plate No.',
    vehicleData: 'Vehicle data',
  },
  variable: {
    abnormal_behavior: 'Abnormal Behavior',
    boom: 'BOOM',
    compulsory_motor_insurance: 'Compulsory Motor Insurance',
    driving: 'Driving',
    driving_hour: 'Driving Hour',
    engine_oil: 'Engine Oil',
    event: 'Event',
    fuel: 'Fuel',
    high: 'High',
    insurance: 'Insurance',
    invalidCredentials: 'invalid Credentials',
    invalidOldPassword: 'invalid Password',
    low: 'Low',
    maintenance_vehicle: 'Vehicle',
    medium: 'Medium',
    No: 'No',
    no_entry: 'No Entry',
    no_exit: 'No Exit',
    off: 'off',
    on: 'on',
    parking: 'Parking',
    parking_area: 'Parking Area',
    Plant: 'Plant',
    prohibited_area: 'Prohibited Area',
    pto_door: 'PTO/Doors',
    safety: 'Safety',
    sensor: 'Sensor',
    Site: 'Site',
    sos: 'SOS',
    speed: 'Speed',
    speed_limit: 'Speed Limit',
    speed_limit_area: 'Speed Limit In Area',
    stop: 'Stop',
    stop_engine_on: 'Stop With Engine On',
    stop_engine_on_with_area: 'Stop Engine On with Area',
    stop_engine_on_long_time: 'Stop Engine On Long Time (Time)',
    stop_engine_on_long_date: 'Stop Engine On Long Time (Date)',
    stop_with_area: 'Stop Inside Area',
    stop_with_engine_off: 'Stop With Engine Off',
    stop_with_engine_off_long_time: 'Stop With Engine Off Long time (time)',
    success: 'Success',
    suspension: 'Suspension',
    taxi: 'Taxi',
    temperature: 'Temperature',
    timeline_aggressive_acceleration_time: 'Aggressive acceleration (Time)',
    timeline_aggressive_acceleration: 'Aggressive acceleration',
    timeline_speed_over: 'Speed over',
    timeline_suddenly_break: 'Suddenly break',
    timeline_vehicle_stop_with_engine_on: 'Vehicle stop with engine on',
    tire: 'Tire',
    voluntary_motor_insurance: 'Voluntary Motor Insurance',
    Yes: 'Yes',
  },
  vehicleType: {
    backhoe: 'Backhoe',
    bulk_cargo: 'Bulk Cargo',
    bus: 'Bus',
    car_6wheelers_trailer: '6 Wheelers Drive Trailer',
    caravan_10wheelers: 'Caravan 10 Wheelers',
    cargo_ship: 'Cargo Ship',
    cash_transporter_cmc01: 'CASH Transporter - CMC 01',
    cash_transporter_cmc02: 'CASH Transporter - CMC 02',
    cash_transporter_g45: 'CASH Transporter - G4S',
    cash_transporter_lrd130: 'CASH Transporter - LRD130',
    cash_transporter_test01: 'CASH Transporter - Test 01',
    cement_bags_transporter_10wheelers: 'Cement Bags Transporter 10 Wheelers',
    cement_powder_banana_shaped_trailer: 'Cement Powder Banana Shaped Trailer',
    cement_powder_full_trailer: 'Cement Powder Full Trailer',
    concrete_pump: 'Concrete pump',
    container_truck_10wheelers: 'Container Truck 10 Wheelers',
    container_truck_6wheelers: 'Container Truck 6 Wheelers',
    drive_10wheelers: '10 Wheelers Drive',
    drive_12wheelers: '12 Wheelers Drive',
    drive_18wheelers: '18 Wheelers Drive',
    drive_3wheelers: '3 Wheelers Drive',
    drive_6wheelers: '6 Wheelers Drive',
    dump_truck: 'Dump Truck',
    express_4wheelers: 'Express 4 wheelers',
    fjsldk: 'fjsldk',
    flatbed_car: 'Flatbed Car',
    forage_transporter_10wheelers: 'Forage Transporter 10 Wheelers',
    forage_transporter_12wheelers: 'Forage Transporter 12 Wheelers',
    forage_transporter_18wheelers: 'Forage Transporter 18 Wheelers',
    forage_transporter_22wheelers: 'Forage Transporter 22 Wheelers',
    forage_transporter_trailer: 'Forage Transporter Trailer',
    forklift: 'Forklift',
    freeze_truck_4wheelers: 'Freeze Truck 4 Wheelers',
    freeze_truck_6wheelers: 'Freeze Truck 6 Wheelers',
    harvestar: 'Harvestar',
    hazardous_materials_10wheelers: 'Hazardous Materials 10 Wheelers',
    hazardous_materials_trailer: 'Hazardous Materials Trailer',
    high_4wheelers: 'High 4 Wheelers Drive',
    isuzu: 'Isuzu',
    jumbo_4wheelers: 'Jumbo 4 Wheelers Drive',
    mini_4wheelers: 'Mini 4 Wheelers Drive',
    mix_trailer: 'Mix Trailer',
    motorcycle: 'Motorcycle',
    ngv_10wheelers: 'NGV 10 Wheelers',
    pickup_4wheelers: 'Pickup 4 wheelers',
    rmx_truck_10wheelers: 'RMX Truck 10 Wheelers',
    rmx_truck_4wheelers: 'RMX Truck 4 Wheelers',
    rmx_truck_6wheelers: 'RMX Truck 6 Wheelers',
    sedan: 'Sedan',
    tail: 'Tail',
    taxi: 'Taxi',
    tractor: 'Tractor',
    type_test: 'type_test',
    van: 'Van',
    van_dont_send_department: 'Van(Do not send department)',
    van_tpt: 'Van TPT',
    pickup_truck_6wheelers: 'Pickup Truck 6 Wheelers',
    pickup_truck_10wheelers: 'Pickup Truck 10 Wheelers',
    semi_trailer: 'Semi Trailer',
    trailer_18wheelers: 'Trailer 18 Wheelers',
    trailer_22wheelers: 'Trailer 22 Wheelers',
    pickup_truck_12wheelers: 'Pickup Truck 12 Wheelers',
    pickup_truck_14wheelers: 'Pickup Truck 14 Wheelers',
    trailer_24wheelers: 'Trailer 24 Wheelers',
    semi_trailer_24wheelers: 'Semi Trailer 24 Wheelers',
    semi_trailer_18wheelers: 'Semi Trailer 18 Wheelers',
    trailer_20wheelers: 'Trailer 20 Wheelers',
    pickup_truck_4bigwheelers: 'Pickup Truck 4 Big Wheelers',
    pickup_truck_4wheelers: 'Pickup Truck 4 Wheelers',
    pickup_truck_4wheelers25: 'Pickup Truck 4 Wheelers(2.5)',
    semi_trailer_22wheelers_less_than_6m: 'Semi Trailer 22 Wheelers < 6m',
    semi_trailer_22wheelers_less_than_7m: 'Semi Trailer 22 Wheelers < 7m',
    semi_trailer_22wheelers_less_than_8m: 'Semi Trailer 22 Wheelers < 8m',
    semi_trailer_22wheelers_over_8m: 'Semi Trailer 22 Wheelers > 8m',
    semi_trailer_24wheelers: 'Semi Trailer 24 Wheelers',
    semi_trailer_20wheelers: 'Semi Trailer 20 Wheelers',
    truck_4wheel: '4 Wheel Truck',
    truck_6wheel: '6 Wheel Truck',
    truck_10wheel: '10 Wheel Truck',
    truck_12wheel: '12 Wheel Truck',
    truck_14wheel: '14 Wheel Truck',
    pre_cast: 'PreCast',
    pump: 'Pump',
    banana_shaped_trailer: 'Banana Shaped Trailer',
    tao_pune_car: 'Tao Pune Car',
    tao_pune_trailer_car: 'Tao Pune Trailer Car',
    cranes50: 'Cranes (50)',
    hydraulicWheelCrane: 'Hydraulic Wheel Crane',
    crawlerWheelCranes: 'Crawler Wheel Cranes',
    craneBoat: 'Crane Boat',
    bigTruck: 'Big Truck (60)',
    togo: 'TOGO',
    boom_pump: 'Boom pump',
    concret_epump: 'Concrete pump',
    easy_pump: 'Easy pump',
  },
  w: {
    waiting_for_ticket: 'Waiting Ticket',
    finished_ticket: 'Finished Ticket',
    carry_out_production: 'Carry Out Production',
    out_of_plant: 'Out Of Plant',
    waiting_for_the_next_job: 'waiting for the next job (Minute)',
    work_order_number: 'work order number',
    working_day: 'number of running days',
    working_days: 'Total working hours',
    working_hour: 'Hours GPS Available',
    wrongPoint: 'Wrong point',
    wrongPointDetail:
      'In the event that the vehicle enters the wrong agency or passes through a point that is not its own for a period of 1 minute',
    waitProduction: 'Waiting For Production',
    wait: 'wait',
    w_friday: 'Friday',
    w_monday: 'Monday',
    w_saturday: 'Saturday',
    w_sunday: 'Sunday',
    w_thursday: 'Thursday',
    w_tuesday: 'Tuesday',
    w_wednesday: 'Wednesday',
    waitingForDelivery: 'Waiting For Delivery',
    waitingForUnloading: 'Waiting for unloading',
    waitAssessment: 'Wait Assessment',
    waitForEmailChecking: 'Wait For Email Checking',
    waitingConfirm: 'Waiting Confirm',
    waitingForJob: 'Waiting',
    warrantyEnd: 'Warranty End',
    week: 'Week',
    weekly: 'Weekly',
    weightIn: 'Weight in',
    weightInStatus: 'Weight in',
    weightOutStatus: 'Weight out',
    weightInAt: 'Weight in at',
    weightOut: 'Weight out',
    weightOutAt: 'Weight out at',
    weWillContactYouAtTheTelephoneNumberProvided:
      'We will contact you at the telephone number provided.',
    wheel: 'Wheel',
    whichMayHaveToContactTheAppointmentLocationAgain:
      'Which may have to contact the appointment location again.',
    wigth: 'Wigth',
    workNow: 'Current work',
    working: 'Working',
    workingTime: 'Working Time',
    workingTime_dd_hr__mm: 'Working Time (days:hrs:minutes)',
    workingTimeActual_dd_hr__mm: 'Actual Working Time (days:hrs:minutes)',
    workingTimeActual: 'Actual Working Time',
    workOverTime: 'Overtime',
    wall: 'Wall',
    withinTimeLimit: 'Within time limit',
    weight: 'Weight (tons)',
    wheretheQRCodeIsScanned: 'Where The QR Code Is Scanned',
    weighingHistoryReport: 'Weighing History Report',
    wouldYouLikeToRejectThisItem: 'Would You Like To Reject This Item',
    wouldYouLikeToApproveThisItem: 'Would You Like To Approve This Item',
    weightGainType: 'Weight Gain Type',
    weightMode: 'Weight Mode',
    weightSource: 'Weight Source',
    weighingFormat: 'Weighing Format',
    weighingRandomConditions: 'Weighing Random Conditions',
    weighedAt: 'Weighed At',
    website: 'website',
    wouldYouLikeToCloseTheseItems: 'Would You Like To Close These Items?',
    weightAccordingToWorksheet: 'Weight according to worksheet',
    weightInImgAt: 'weight in img at',
    weightOutImgAt: 'weight out img at',
    wouldYouLikeToConfirmTheseBookings:
      'Would you like to confirm these bookings?',
    workSchedule: 'Scheduler',
  },
  x: {},
  y: {
    YourInformationHasBeenSentToTheContractorIsReady:
      'Your information has been sent to The contractor is ready.',
    YourInformationHasBeenSentToTheCpacIsReady:
      'ข้อมูลของท่านถูกส่งไปยัง เจ้าหน้าที่ CPAC เรียบร้อย',
    youStatusHasChanged: 'Your Status Has Changed',
    YouCanCheckTheAbnormalityOfTheOilOn:
      'You Can You can check the abnormality of the oil on',
    yourLocation: 'Your Location',
    year: 'Year',
    yearly: 'Yearly',
    yes: 'Yes',
    you: 'You',
    youCanOonlyUploadExcelFile: 'You Can Only Upload EXCEL File!',
    youCanOonlyUploadPdfFile: 'You Can Only Upload PDF File!',
    youCanOnlyUploadPNGorPDFfiles:
      'You Can Only Upload PNG, JPG, JPEG, PDF files',
  },
  z: {
    zone: 'Zone',
    zoneName: 'Zone Name',
    zoneNameIsRequired: 'Zone Name is Required',
    zoomIn: 'Zoom in',
    zoomInForMoreInfo: 'Zoom in for use Feature',
    zoomOut: 'Zoom out',
  },

  weight_uom: {
    g: 'Gram',
    kg: 'Kilogram',
    weight_uom: 'Weight Uom',
    weight_uom_require: 'Weight Uom is require',
  },

  weight_mode: {
    in: 'Weight In',
    out: 'Weight Out',
    total: 'Weight Total',
  },

  sku: {
    from_uom: 'Uom',
    weight: 'Weight',
    quality: 'Quality',
    from_uom_require: 'Uom is require',
    weight_require: 'Weight is require',
    quality_require: 'Quality is require',
    uom_require: 'Uom is require',
  },

  trip_import: {
    TripCodeAlreadyExistsSystem: 'Trip Code Already Exists System',
    error_import: 'Something went wrong',
    trip_code_require: 'Trip code is require',
    vehicle_code_require: 'Vehicle is require',
    date_start_trip_at_require: 'Start Deliver Date is require',
    time_start_trip_at_require: 'Start Deliver Time is require',
    do_number_require: 'Invoice number is require',
    location_code_require: 'Location Code is require',
    location_name_require: 'Location Name is require',
    product_code_require: 'Product Code is require',
    product_name_require: 'Product Name is require',
    quality_require: 'Quality is require',
    sku_require: 'UOM is require',
    date_arrive_at_require: 'Arrive Date is require',
    time_arrive_at_require: 'Arrive Time is require',
    date_out_at_require: 'Move Out Date is require',
    time_out_at_require: 'Move OUt Time is require',
    confirm_send_require: 'Sending Confirm By is require',
    vehicle_code_not_find: 'Vehicle Code is not in system :',
    vehicle_code_not_install: 'Vehicle Code is not install :',
    location_code_not_find: 'Location Code is not found :',
    product_code_not_find: 'Product Code is not in system :',
    sku_not_find: 'Uom is not in system :',

    date_start_trip_at_format_incorrect:
      'Start Deliver Date is format incorrect :',
    time_start_trip_at_format_incorrect:
      'Start Deliver Time is format incorrect :',
    date_arrive_at_format_incorrect: 'Arrive Date is format incorrect :',
    time_arrive_at_format_incorrect: 'Arrive Time is format incorrect :',
    date_out_at_format_incorrect: 'Move Out Date is format incorrect :',
    time_out_at_format_incorrect: 'Move OUt Time is format incorrect :',

    remark_more_than_50: 'Remark must not exceed 50 characters',
  },

  notifytype: {
    speed_over_limit: 'Speed Over Limit',
    driving_time_4_hrs: 'Dirving Time > 4 hrs',
    working_time_10_hrs: 'Working Time > 10 hrs / day',
    aggressive_turn: 'Aggressive Turn',
    aggressive_acceleration_break: 'Aggressive Acceleration Break',
    parking_on_road_side: 'Parking On Road Side',
    no_parking_engine_on: 'No Parking(Egine On)',
    no_parking_engine_off: 'No Parking(Egine Off)',
    unload_outside_location: 'Unload Outside Location',
    delivery_over_3_hrs: 'Delivery Over 3 hrs',
  },

  maintenance: {
    appointment_place: 'Appointment Place/Plant',
    appointment_place_require: 'Appointment Place/Plant is require',
    type_problem_require: 'Type Problem is require',

    comment_require: 'Comment is require',
    province: 'Province',
    province_require: 'Province is require',
    district_require: 'District is require',
    district: 'District',
    vehicle_require: 'Vehicle is require',
  },
  quantityUnit: {
    litre: 'Litre',
    tonne: 'Tonne',
    job: 'Job',
    hour: 'Hour',
    kg: 'kg.',
  },
  visibility: {
    vehicle: 'Vehicle',
    vehicle_group: 'Vehicle Group',
    location_group: 'Location Group',
    location: 'Location',
    user: 'User',
  },
  replayVehicleStatus: {
    จอดไม่ดับเครื่อง: 'Stop/Engine On',
    วิ่ง: 'Run',
    คายคอนกรีต: 'Concrete Spit',
    ดับเครื่อง: 'Engine Off',
  },
  sdcardStatus: {
    green: 'Normal',
    red: 'Broken',
    gray: '-',
  },
  vehicleContract: {
    vehicleOwnerCompany: 'Vehicle Owner Company',
    companyGroup: 'Company Group',
    phoneNumberSim: 'Phone Number Sim',
    vehicleBrand: 'Vehicle Brand',
    cuscNumber: 'CusC Number',
    plateNo: 'plate No.',
    province: 'Province',
    vehicleNumber: 'Vehicle Number',
    vehicleNumberFilter: 'Vehicle Number',
    gpsVendor: 'GPS Vendor',
    exPlateNo: 'Ex Plate No.',
    contactInstall: 'Contact Install',
    phoneNumber: 'Phone Number',
    installationLocation: 'Installation Location',
    installationDeviceType: 'Installation Device Type',
    installDriversLicenseMachine: 'Install Drivers License Machine',
    attachSensor: 'Attach Sensor',
    requestCertificate: 'Request Certificate',
    otherDevice: 'Other Device',
    installationDate: 'Installation Date',
    expirationDate: 'Expiration Date',
    charge: 'Charge',
    installationStatus: 'Installation Status',
    usageStatus: 'Usage Status',
    remark: 'Remark',
    isBluenet: 'Data From Blubnet',
    expired: 'expired',
    expiringOneMonth: 'expiring in one month',
    expiringThreeMonth: 'expiring in three month',
    default: 'default',
    vehicleContract: 'Vehicle Contract',
    vehicleNumberCpac: 'vehicle Number',
    index: 'Index of Excel',
    errorResponse: 'Error Response',
    msg_install_status: 'Install Status Error',
    msg_is_install_drum_sensor: 'Install Drum Sensor Error',
    msg_is_install_swipe_card: 'Install Swipe Card Error',
    msg_not_found_file: 'Not Found File',
    msg_not_found_vehicle: 'Not Found Vehicle',
    msg_working_status: 'Working Status Error',
    msg_request_cert: 'Request Certificate Error',
    templateReport: 'Template Report',
    import: 'Import',
    filter: 'Filter',
    changeStatus: 'Click For Change Status',
    editRemark: 'Edit Remark',
    vehicleContractDetail: 'Vehicle Contract Detail',
    vehicleContractEdit: 'Vehicle Contract Edit',
    bath: '(Bath)',
    msg_vehicle_visibility:
      'There is no right to manage because the GPS Vendor does not match.',
  },
  vehicleVisibilityPermission: {
    generalData: 'User Rights and Affiliation',
    dataFrom: 'Data From',
    default: 'Default',
    vehicleVisibilityPermissionHeader: 'Check Car Information',
    fleetlinkVehicleNumber: 'Vehicle Number - Fleetlink',
    fleetlinkVehiclePlateNo: 'Plate No. - Fleetlink',
    terminusVehicleNumber: 'Vehicle Number - TERMINUS',
    terminusVehiclePlateNo: 'Plate No. - TERMINUS',
    event: 'Event',
    searchBy: 'Search By',
    case1: 'There is a car number in Fleetlink but not in TERMINUS',
    case2: 'There is a car number in TERMINUS but not in Fleetlink',
    case3: 'There are duplicate vehicle numbers in Fleetlink',
    case4: 'Duplicate vehicle registration in Fleetlink',
    case5:
      'car number match But the registration is not the same between Fleetlink and TERMINUS',
    selectEvent: 'Select Event',
    vehicleVisibilityPermissionAndVehicleSharing: 'Vehicle Visibility',
    userAfiliation: 'User Afiliation',
    fillUser: 'Please Fill Username',
    fillVehicleNumber: 'Please Fill Vehicle Number',
    dataCorrect: 'Data Correct',
    dataIncorrect: 'Data Incorrect',
  },
  vehicleSharingSystemAvailableAccount: {
    NOT_FOUND_VEHICLE: 'Not Found Vehicle',
    NOT_FOUND_DEVICE_USER: 'Not Found Device User',
    PLEACE_CHECK_VEHICLE_STATUS_TO_INACTIVE_FIRST:
      'Please Check Vehicle Status To Inactive First',
    NOT_FOUND_TRUCK_SHARING_CURRENT_LOCATION:
      'NOT FOUND TRUCK SHARING CURRENT LOCATION',
    INVALID_TRUCK_SHARING_IS_AVALIABLE: 'INVALID TRUCK SHARING IS AVALIABLE',
    NOT_FOUND_TRUCK_SHARING_CONTACT_PHONE_OF_VEHICLE:
      'NOT FOUND TRUCK SHARING CONTACT PHONE OF VEHICLE',
    NOT_FOUND_MOBILE_PHONE_OF_DRIVERS: 'NOT FOUND MOBILE PHONE OF DRIVERS',
    NOT_FOUND_CONTACT_PHONE: 'NOT FOUND CONTACT PHONE',
    NOT_FOUND_DRIVER_MOBILE_PHONE: 'NOT FOUND DRIVER MOBILE PHONE',
  },
  listOfVehicleAccount: {
    START_DATE_CANNOT_BE_IN_THE_PAST: 'Please change date to current date',
  },
}
export { en }
