import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'
//import { push } from "connected-react-router";

import * as actions from './actions'
import moment from 'moment'

function* loadBehaviordrivingbycompany(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const {
    data: { list_vehicle_id, list_partner_id, eventdate, type_file },
  } = action.payload

  const date_start = moment(eventdate[0]['_d'])
    .add(-7, 'hours')
    .format('YYYY-MM-DD HH:mm:00')
  const date_end = moment(eventdate[1]['_d'])
    .add(-7, 'hours')
    .format('YYYY-MM-DD HH:mm:59')
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/behaviordrivingbycompany`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        partner_id: list_partner_id,
        company_id: COMPANY_ID,
        type_file,
      },
      // { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    )
    yield put(actions.loadBehaviordrivingbycompany.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadBehaviordrivingbycompany.failure())
  }
}

function* loadBehaviordrivingbycompanypreview(action) {
  const {
    data: {
      list_vehicle_id,
      partner_id,
      date_start,
      date_end,
      company_id,
      type_file,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG}/reports/go/behaviordrivingbycompany`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        partner_id,
        company_id,
        type_file,
      },
    )
    yield put(actions.loadBehaviordrivingbycompanypreview.success(res.data))
    window.location = res.data.result
  } catch (err) {
    yield put(actions.loadBehaviordrivingbycompanypreview.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  const { partnerID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebypartnerid`,
      {
        companyID: COMPANY_ID,
        partnerID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadPartner(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpartnerreport`,
      {
        companyID: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadPartner.success(res.data))
  } catch (err) {
    yield put(actions.loadPartner.failure())
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(
      actions.LOAD_BEHAVIORDRIVINGBYCOMPANY.REQUEST,
      loadBehaviordrivingbycompany,
    ),
    takeEvery(
      actions.LOAD_BEHAVIORDRIVINGBYCOMPANY_PREVIEW.REQUEST,
      loadBehaviordrivingbycompanypreview,
    ),
    takeEvery(
      actions.LOAD_VEHICLE_BEHAVIORDRIVINGBYCOMPANY.REQUEST,
      loadVehicle,
    ),
    takeEvery(actions.LOAD_COMPANY_PARTNER.REQUEST, loadPartner),
  ])
}

export {
  loadBehaviordrivingbycompany,
  loadVehicle,
  loadPartner,
  loadBehaviordrivingbycompanypreview,
}
