import {
    LOAD_DATA_FOR_MANAG_QUEUE,
    LOAD_DATA_FOR_UNCOMING_QUEUE,
    REMOVE_LISTS
} from "./actions";

const initialState = {
    lists: [],
    lists_uncoming: [],
    total: 1,
    total_uncoming: 1,
    loading: false,
    loading_uncoming: false,
    draw: -1,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_LISTS:
            return initialState;

        case LOAD_DATA_FOR_MANAG_QUEUE.REQUEST:
            const { loading } = action.payload;
                return {
                    ...state,
                    loading: loading,
                    check: true
                };

        case LOAD_DATA_FOR_MANAG_QUEUE.SUCCESS:
            const { page } = action.payload;
                    
            for (let i = 0; i < action.payload.lists.data.length; i++) {
                action.payload.lists.data[i].rowNumber = i + 1;
                    if (page > 1)
                        action.payload.lists.data[i].rowNumber =
                            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
                }
        
            return {
                ...state,
                lists: action.payload.lists.data,
                check: false,
                total: 
                    action.payload.lists.total - 1 <= 0
                    ? 1
                    : action.payload.lists.total <= 0
                    ? 1
                    : action.payload.lists.total,
                    loading: false,
                    draw: Math.floor(Math.random() * 999 + 1)
            };

        case LOAD_DATA_FOR_UNCOMING_QUEUE.REQUEST:
                return {
                    ...state,
                    loading_uncoming: action.payload.loading,
                    check_uncoming: true
                };

        case LOAD_DATA_FOR_UNCOMING_QUEUE.SUCCESS:                                
                for (let i = 0; i < action.payload.lists.data.length; i++) {
                    action.payload.lists.data[i].rowNumber = i + 1;
                        if (action.payload.page > 1)
                            action.payload.lists.data[i].rowNumber =
                                action.payload.lists.data[i].rowNumber + (action.payload.page - 1) * 10;
                }
                    
                return {
                    ...state,
                    lists_uncoming: action.payload.lists.data,
                    check_uncoming: false,
                    total_uncoming: 
                        action.payload.lists.total - 1 <= 0
                        ? 1
                        : action.payload.lists.total <= 0
                        ? 1
                        : action.payload.lists.total,
                    loading_uncoming: false,
                    draw: Math.floor(Math.random() * 999 + 1)
                };

        default:
            return state;
    }
}