import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
  getlocalstorage,
} from './../../../constants/local_storage'
import * as actions from './actions'
import moment from 'moment'

function* loadTrip(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      vehicle_visibility,
      filterObj,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        vehicle_visibility,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.loadTrip.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadTrip.failure())
  }
}

function* loadDeliverorder(action) {
  const { data } = action.payload
  //console.log("load", data)
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/deliveryorder`,
      {
        trip_id: data,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.loadDeliverorder.success(res.data))
  } catch (err) {
    yield put(actions.loadDeliverorder.failure())
  }
}

function* loadProductDeliverorder(action) {
  const { data } = action.payload
  console.log('load', data)
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/productdeliver`,
      {
        deliver_id: data,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.loadProductDeliverorder.success(res.data))
  } catch (err) {
    yield put(actions.loadProductDeliverorder.failure())
  }
}

function* loadTripDetail(action) {
  const { id } = action.payload
  //console.log("loaddetail", id)

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/detail/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadTripDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadTripDetail.failure())
  }
}

function* loadDeliverDetail(action) {
  const { id } = action.payload
  //console.log("loaddetail", id)

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/deliverdetail/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadDeliverDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadDeliverDetail.failure())
  }
}

function* createTrip(action) {
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: { vehicle_id, driver_id, product_type, trip_code, deliverList },
    loading,
    history,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/create`,
      {
        vehicle_id,
        driver_id,
        product_type,
        trip_code,
        deliverList,
        created_by: USER_ID,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    history.push('/deliverytrip')
    yield put(actions.createTrip.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createTrip.failure())
  }
}

function* createDeliver(action) {
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: {
      cv_code,
      confirm_deliver_type,
      location_code,
      dest_location,
      invoice_date,
      eventdate,
      invoice_number,
    },
    trip_id,
  } = action.payload

  const date_start = moment(eventdate[0]['_d']).format('YYYY-MM-DD HH:mm')
  const date_end = moment(eventdate[1]['_d']).format('YYYY-MM-DD HH:mm')
  const new_invoice_date = moment(invoice_date['_d']).format('YYYY-MM-DD')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/createdeliver`,
      {
        cv_code,
        confirm_deliver_type,
        location_code,
        dest_location,
        date_start,
        date_end,
        invoice_date: new_invoice_date,
        invoice_number,
        trip_id,
        created_by: USER_ID,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.createDeliver.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createDeliver.failure())
  }
}

function* createProductDeliver(action) {
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: { product, quality },
    deliver_id,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/createproductdeliver`,
      {
        product,
        quality,
        deliver_id,
        created_by: USER_ID,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.createProductDeliver.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createProductDeliver.failure())
  }
}

function* updateDeliver(action) {
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')

  const {
    data: {
      cv_code,
      confirm_deliver_type,
      location_code,
      dest_location,
      invoice_date,
      eventdate,
      invoice_number,
    },
    deliver_id,
    trip_id,
  } = action.payload

  const date_start = moment(eventdate[0]['_d']).format('YYYY-MM-DD HH:mm')
  const date_end = moment(eventdate[1]['_d']).format('YYYY-MM-DD HH:mm')
  const new_invoice_date = moment(invoice_date['_d']).format('YYYY-MM-DD')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/updatedeliver`,
      {
        cv_code,
        confirm_deliver_type,
        location_code,
        dest_location,
        date_start,
        date_end,
        invoice_date: new_invoice_date,
        invoice_number,
        deliver_id,
        trip_id,
        created_by: USER_ID,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )

    yield put(actions.updateDeliver.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateDeliver.failure())
  }
}

function* updateTrip(action) {
  const {
    data: { vehicle_id, driver_id, product_type, trip_code, deliverList },
    trip_id,
    loading,
    history,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deliverytrip/update`,
      {
        vehicle_id,
        driver_id,
        product_type,
        trip_code,
        trip_id,
        deliverList,
        update_by: USER_ID,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    history.push('/deliverytrip')
    yield put(actions.updateTrip.success(res.data))
  } catch (err) {
    yield put(actions.updateTrip.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: '',
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadProducttype(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getproducttype`,
      {},
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadProducttype.success(res.data))
  } catch (err) {
    yield put(actions.loadProducttype.failure())
  }
}

function* loadLocation(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const location_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'location_visibility',
  )

  const { data } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`,
      {
        company_id: COMPANY_ID,
        location_visibility: location_visibility,
        name: data,
        relate_company: `${this.props.auth.profile.relate_company}`,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadLocation.success(res.data))
  } catch (err) {
    yield put(actions.loadLocation.failure())
  }
}

function* loadLocationcode(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const location_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'location_visibility',
  )

  const { data } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`,
      {
        company_id: COMPANY_ID,
        location_visibility: location_visibility,
        code: data,
        relate_company: `${this.props.auth.profile.relate_company}`,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadLocationcode.success(res.data))
  } catch (err) {
    yield put(actions.loadLocationcode.failure())
  }
}

function* loadProduct(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )

  const { data } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getproduct`,
      {
        company_id: COMPANY_ID,
        name: data,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadProduct.success(res.data))
  } catch (err) {
    yield put(actions.loadProduct.failure())
  }
}

function* loadDriver(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id',
  )
  const VUC = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility',
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdriver`,
      {
        allow_vehicles: VUC,
        company_id: COMPANY_ID,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } },
    )
    yield put(actions.loadDriver.success(res.data))
  } catch (err) {
    yield put(actions.loadDriver.failure())
  }
}

export default function* watchTripState() {
  yield all([
    takeEvery(actions.CREATE_TRIP_DELIVERY.REQUEST, createTrip),
    takeEvery(actions.LOAD_TRIP_DELIVERY.REQUEST, loadTrip),
    takeEvery(actions.LOAD_TRIP_DETAIL_DELIVERY.REQUEST, loadTripDetail),
    takeEvery(actions.UPDATE_TRIP_DELIVERY.REQUEST, updateTrip),
    takeEvery(actions.LOAD_VEHICLE_DELIVERY.REQUEST, loadVehicle),
    takeEvery(actions.LOAD_PRODUCTTYPE_DELIVERY.REQUEST, loadProducttype),
    takeEvery(actions.LOAD_DELIVER_DELIVERY.REQUEST, loadDeliverorder),
    takeEvery(actions.LOAD_LOCATION_DELIVERY.REQUEST, loadLocation),
    takeEvery(actions.LOAD_LOCATIONCODE_DELIVERY.REQUEST, loadLocationcode),
    takeEvery(actions.LOAD_PRODUCT_DELIVERY.REQUEST, loadProduct),
    takeEvery(actions.LOAD_DRIVER_DELIVERY.REQUEST, loadDriver),
    takeEvery(actions.CREATE_DELIVER_DELIVERY.REQUEST, createDeliver),
    takeEvery(actions.LOAD_DELIVER_DETAIL_DELIVERY.REQUEST, loadDeliverDetail),
    takeEvery(actions.UPDATE_DELIVER_DELIVERY.REQUEST, updateDeliver),
    takeEvery(
      actions.LOAD_PRODUCTDELIVER_DELIVERY.REQUEST,
      loadProductDeliverorder,
    ),
    takeEvery(
      actions.CREATE_PRODUCTDELIVER_DELIVERY.REQUEST,
      createProductDeliver,
    ),
  ])
}

export {
  createTrip,
  loadTrip,
  loadTripDetail,
  updateTrip,
  loadVehicle,
  loadProducttype,
  loadDeliverorder,
  loadLocation,
  loadLocationcode,
  loadProduct,
  loadDriver,
  createDeliver,
  loadDeliverDetail,
  updateDeliver,
  loadProductDeliverorder,
  createProductDeliver,
}
