import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
  Transfer,
  Checkbox,
  Dropdown,
  Menu,
  Icon,
} from 'antd'

import LangContext, { i18n } from 'modules/shared/context/langContext'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option

export default class Forms extends Component {
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
  }

  componentDidMount() {
    const { loadVehicle, loadVehicleType } = this.props
    loadVehicle('', false)
    loadVehicleType()
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehicle } = this.props
    setFieldValue('vehicle_type_id', value)
    loadVehicle(value, true)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  componentWillReceiveProps = nextProps => {
    const { vehicleLists } = nextProps
    const vehlist = []
    vehicleLists.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    this.setState({ vehicleList: vehlist })
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    })
  }

  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    // console.log('click', e.key);
    if (e.key == 'excel') {
      setFieldValue('type_file', 'excel', false)
    } else if (e.key == 'pdf') {
      setFieldValue('type_file', 'pdf', false)
    }
    handleSubmit()
  }

  render() {
    const {
      vehicleLists,
      vehicleTypeLists,
      temperatureLoading,
      onSubmit,
    } = this.props

    return (
      <div>
        <h3>
          {' '}
          <LangContext.Consumer>
            {i18n => i18n.t.TemperatureReport}
          </LangContext.Consumer>
        </h3>
        <Spin spinning={temperatureLoading}>
          <Formik
            initialValues={{
              vehicle_type_id: '',
              eventdate: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
              list_vehicle_id: [],
            }}
            validate={values => {
              let errors = {}
              if (values.eventdate.length > 0) {
                const date_start = moment(values.eventdate[0]['_d'])
                const date_end = moment(values.eventdate[1]['_d'])
                let day_diff = date_end.diff(date_start, 'days') + 1
                if (day_diff > 3) {
                  errors.eventdate = 'ช่วงวันที่ที่เลือกไม่ควรเกิน 3 วัน'
                }
              }

              return errors
            }}
            validationSchema={yup.object().shape({
              eventdate: yup.array().required('กรุณาเลือกช่วงวันที่'),
              list_vehicle_id: yup.array().required('กรุณาเลือกรถ'),
            })}
            onSubmit={values => {
              //console.log(values);
              const loading = true
              onSubmit(values, loading)
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating,
            }) => (
              <Row type="flex" justify="center">
                <Form onSubmit={handleSubmit}>
                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.v.vehicleGroup}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.vehicle_type_id &&
                      errors.vehicle_type_id &&
                      'error'
                    }
                    help={touched.vehicle_type_id && errors.vehicle_type_id}
                  >
                    <Select
                      onChange={value =>
                        this.onVehicleTypeChange(value, setFieldValue)
                      }
                      value={values.vehicle_type_id}
                    >
                      <Option value="">ทั้งหมด</Option>
                      {vehicleTypeLists.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.v.vehicle}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.list_vehicle_id &&
                      errors.list_vehicle_id &&
                      'error'
                    }
                    help={touched.list_vehicle_id && errors.list_vehicle_id}
                  >
                    <Transfer
                      titles={[
                        <LangContext.Consumer>
                          {i18n => i18n.i.itemLeft}
                        </LangContext.Consumer>,
                        <LangContext.Consumer>
                          {i18n => i18n.i.itemSelected}
                        </LangContext.Consumer>,
                      ]}
                      dataSource={this.state.vehicleList}
                      showSearch
                      listStyle={{
                        width: 250,
                        height: 300,
                      }}
                      selectedKeys={this.state.selectedKeys}
                      operations={['>>', '<<']}
                      targetKeys={this.state.vehicleSelect}
                      onSelectChange={this.handleSelectChange}
                      onChange={value =>
                        this.tranferChange(value, setFieldValue)
                      }
                      //onChange={this.tranferChange}
                      render={item => `${item.name}`}
                      footer={this.renderFooter}
                    />
                  </FormItem>

                  <Form.Item
                    label={
                      <LangContext.Consumer>
                        {i18n => i18n.d.dateRange}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.eventdate && errors.eventdate && 'error'
                    }
                    help={touched.eventdate && errors.eventdate}
                  >
                    <RangePicker
                      showTime={{ format: 'HH:mm' }}
                      onChange={value => setFieldValue('eventdate', value)}
                      format="DD/MM/YYYY HH:mm"
                      placeholder={['Start Time', 'End Time']}
                      defaultValue={[
                        moment('00:00', 'HH:mm'),
                        moment('23:59', 'HH:mm'),
                      ]}
                    />
                  </Form.Item>

                  <FormItem>
                    <Row gutter={24}>
                      <Col span={8}>
                        <Button type="defualt" block>
                          <Link to="/">
                            <LangContext.Consumer>
                              {i18n => i18n.c.cancel}
                            </LangContext.Consumer>
                          </Link>
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Dropdown
                          overlay={
                            <Menu
                              onClick={value =>
                                this.handleMenuClick(
                                  value,
                                  setFieldValue,
                                  handleSubmit,
                                )
                              }
                            >
                              <Menu.Item key="excel">
                                <Icon type="file-excel" />{' '}
                                <LangContext.Consumer>
                                  {i18n => i18n.e.excel}
                                </LangContext.Consumer>
                              </Menu.Item>
                              <Menu.Item key="pdf">
                                <Icon type="file-pdf" />{' '}
                                <LangContext.Consumer>
                                  {i18n => i18n.p.pdf}
                                </LangContext.Consumer>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Button type="primary" block>
                            <LangContext.Consumer>
                              {i18n => i18n.r.report}
                            </LangContext.Consumer>{' '}
                            <Icon type="down" />
                          </Button>
                        </Dropdown>
                      </Col>
                    </Row>
                  </FormItem>
                </Form>
              </Row>
            )}
          />
        </Spin>
      </div>
    )
  }
}
