import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import difference from 'lodash/difference'
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
  Transfer,
  Checkbox,
  Table,
} from 'antd'

import LangContext from 'modules/shared/context/langContext'

const { RangePicker } = DatePicker
const FormItem = Form.Item
const Options = Select.Option
const { Option } = Select

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <div id="finding-btn">
    <Transfer
      {...restProps}
      showSelectAll={false}
      titles={[
        <div
          onClick={() => {
            restProps.onChange(restProps.dataSource.map(item => item.key))
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
        <div
          onClick={() => {
            restProps.onChange([])
          }}
          className="select-all-tranfers-data"
        >
          <LangContext.Consumer>
            {i18n => i18n.c.chooseAll}
          </LangContext.Consumer>
        </div>,
      ]}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: item => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
          //   hideDefaultSelections: true,
          //   selections: [
          //     {
          //       //   key: 'all-data',
          //       text: 'Select All Data',
          //       onSelect: (record, selected, selectedRows, nativeEvent) => {
          //         let getKey = []
          //         filteredItems.map(item => getKey.push(item.key))
          //         onItemSelectAll([...getKey], true)
          //       },
          //     },
          //   ],
        }

        return (
          <Table
            scroll={{ y: 340 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            pagination={{ defaultPageSize: 100 }}
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  </div>
)

class Forms extends Component {
  constructor(props) {
    super(props)
    this.Leftbtn = null
    this.Rightbtn = null
  }
  state = {
    event_date_start: '',
    event_date_end: '',
    vehicleList: [],
    vehicleSelect: [],
    selectedKeys: [],
    event: 0,
    eventdate: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
  }

  leftTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <div className="regular-bold-14">
          <LangContext.Consumer>{i18n => i18n.i.itemLeft}</LangContext.Consumer>
        </div>
      ),
      posiction: 'Left',
    },
  ]

  rightTableColumns = [
    {
      dataIndex: 'name',
      title: (
        <div className="regular-bold-14">
          <LangContext.Consumer>
            {i18n => i18n.i.itemSelected}
          </LangContext.Consumer>
        </div>
      ),
      posiction: 'Right',
    },
  ]

  componentDidMount() {
    const { call } = this.props

    if (call === 'schedule') {
      if (this.props.dataedit !== '') {
        let obj = JSON.parse(this.props.dataedit)
        this.setState({
          vehicleSelect: obj.vehicle_visibility,
        })
      }
    }
    let btn = document.getElementById('finding-btn').querySelectorAll('button')
    this.Leftbtn = btn[0]
    this.Rightbtn = btn[1]
  }

  onVehicleTypeChange = (value, setFieldValue) => {
    const { loadVehiclebygroupid } = this.props
    setFieldValue('vehicle_type_id', value)
    loadVehiclebygroupid(value, true)
    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    setFieldValue('list_vehicle_id', [])
  }

  onPolicyChange = (value, e, setFieldValue) => {
    setFieldValue('event_id', value)

    this.setState({ vehicleSelect: [] })
    this.setState({ selectedKeys: [] })
    this.setState({ eventType: e.props.type })
  }

  componentWillReceiveProps = nextProps => {
    const { vehicles, vehicleGroup } = nextProps

    const vehlist = []
    const vehiclelists = []
    vehicles.map(item =>
      vehlist.push({
        key: item.id,
        name: item.plate_no,
      }),
    )

    vehicles.map(item => vehiclelists.push(item.id))
    this.setState({ vehicleList: vehlist })

    const vehlistselect = []
    vehicleGroup.map(item => vehlistselect.push(item.id))

    var sameVehicle = []
    for (var i in vehlistselect) {
      if (vehiclelists.indexOf(vehlistselect[i]) > -1) {
        sameVehicle.push(vehlistselect[i])
      }
    }

    // this.setState({ vehicleSelect: sameVehicle });
  }

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue('list_vehicle_id', targetKeys)
    this.setState({ vehicleSelect: targetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState(
      {
        selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
      },
      () => {
        if (targetSelectedKeys.length === 0) {
          this.Leftbtn.click()
        } else {
          this.Rightbtn.click()
        }
      },
    )
  }

  handleMenuClick = (e, setFieldValue, handleSubmit) => {
    // console.log('click', e.key);
    if (e.key == 'excel') {
      setFieldValue('type_file', 'excel', false)
    } else if (e.key == 'pdf') {
      setFieldValue('type_file', 'pdf', false)
    }
    setFieldValue('list_vehicle_id', this.state.vehicleSelect, false)
    setFieldValue('event_type', this.state.eventType, false)
    handleSubmit()
  }

  handleClicReportkPreview = values => {
    const COMPANY_ID = this.props.auth.company_id
    const USER_ID = this.props.auth.id

    const date_start = moment(values.eventdate[0]['_d']).format(
      'YYYY-MM-DD HH:mm:00',
    )
    const date_end = moment(values.eventdate[1]['_d']).format(
      'YYYY-MM-DD HH:mm:59',
    )

    const filter = {
      vendor_name: values.vendor_name,
      date_start,
      date_end,
      list_vehicle_id: [],
      company_id: COMPANY_ID,
      vehicle_type_id: values.vehicle_type_id,
      user_id: USER_ID,
      type_file: 'preview',
      event: values.event,
      event_name: '',
      vehicle_visibility: values.list_vehicle_id.join(','),
      division: values.division.toString(),
      department: values.department.toString(),
      section: values.section.toString(),
      partner: values.partner.toString(),
      plant: values.plant,
      only_dp: values.only_dp,
    }

    window.open('/report/safety_event/preview', '_blank')
    localStorage.setItem('safety_eventreport-filter', JSON.stringify(filter))
  }

  render() {
    const {
      loading,
      onSubmit,
      initialEvent,
      partner,
      division,
      department,
      section,
      plant,
      vehicleGroup,
      selectedPartner,
      selectedDivision,
      selectedDepartment,
      selectedSection,
      selectedVehicleGroup,
      selectedPlant,
      eventdate,
    } = this.props
    function disabledDate(current) {
      return current && current > moment().endOf('day')
    }
    return (
      <div>
        <Spin spinning={loading}>
          <LangContext.Consumer>
            {i18n => (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  vendor_name: '',
                  vendor_id: '',
                  vehicle_type_id: '',
                  eventdate: this.state.eventdate,
                  list_vehicle_id: this.state.vehicleSelect,
                  event: this.state.event,
                  partner: selectedPartner,
                  division: selectedDivision,
                  department: selectedDepartment,
                  section: selectedSection,
                  vehicleGroup: selectedVehicleGroup,
                  only_dp: false,
                  plant: selectedPlant,
                }}
                validationSchema={yup.object().shape({
                  eventdate: yup
                    .array()
                    .required(`${i18n.p.pleaseChooseDateRange}`),
                  list_vehicle_id: yup
                    .array()
                    .min(0, `${i18n.p.pleaseChooseVehicle}`)
                    .required(`${i18n.p.pleaseChooseVehicle}`),
                })}
                onSubmit={(values, { resetForm }) => {
                  const loading = true
                  //console.log(values)
                  if (this.state.clickMenu === 'preview') {
                    this.handleClicReportkPreview(values)
                  } else {
                    onSubmit(values, loading)
                  }
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                  resetForm,
                }) => (
                  <Row type="flex" justify="center">
                    <Form onSubmit={handleSubmit}>
                      <div className="semi-bold-16">
                        <LangContext.Consumer>
                          {i18n => i18n.e.SafetyEventReport}
                        </LangContext.Consumer>
                      </div>
                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.e.event}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.event_id && errors.event_id && 'error'
                        }
                        help={touched.event_id && errors.event_id}
                      >
                        <Select
                          name="event"
                          id="event"
                          defaultValue={0}
                          onChange={(value, e) => {
                            setFieldValue('event', Number(value))
                            this.setState({ event: Number(value) })
                          }}
                          value={values.event}
                        >
                          <Options value={0}>
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Options>
                          {initialEvent.map((event, i) => (
                            <Option
                              key={'event_' + event.id}
                              type="feed"
                              value={event.id}
                            >
                              {event.name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>

                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vendorIsupply}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.vendor_id && errors.vendor_id && 'error'
                        }
                        help={touched.vendor_id && errors.vendor_id}
                      >
                        <Select
                          showSearch={true}
                          id="partner"
                          name="partner"
                          value={values.partner}
                          defaultValue="all"
                          onChange={(e, obj) => {
                            // console.log(e, obj)
                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('list_vehicle_id', [])
                              setFieldValue('vendor_name', obj.props.children)
                              setFieldValue('vendor_id', e)
                              setFieldValue('partner', e)
                              this.props.updateSelectPartner(e)
                            })
                          }}
                          filterOption={(val, options) => {
                            const { children } = options.props
                            if (typeof children != 'string') return false
                            if (
                              children
                                .toLowerCase()
                                .indexOf(val.toLowerCase()) >= 0
                            )
                              return (
                                children
                                  .toLowerCase()
                                  .indexOf(val.toLowerCase()) >= 0
                              )
                          }}
                        >
                          <Options value="all">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Options>
                          {partner.map(item => {
                            return (
                              <Options value={item.id}>{item.name}</Options>
                            )
                          })}
                        </Select>
                      </FormItem>

                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.d.division}
                          </LangContext.Consumer>
                        }
                      >
                        <Select
                          name="division"
                          id="division"
                          style={{ width: '100%' }}
                          value={values.division}
                          onChange={e => {
                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('division', e)
                              setFieldValue('list_vehicle_id', [])
                              setFieldValue('department', 'all')
                              setFieldValue('section', 'all')
                              setFieldValue('plant', ['all'])
                              this.props.updateSelectDivisoin(e)
                            })
                          }}
                        >
                          <Option value="all">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          {division.map(ele => (
                            <Option value={ele.id}>{ele.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.d.department}
                          </LangContext.Consumer>
                        }
                      >
                        <Select
                          name="department"
                          id="department"
                          value={values.department}
                          onChange={e => {
                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('department', e)
                              setFieldValue('section', 'all')
                              setFieldValue('plant', ['all'])
                              setFieldValue('list_vehicle_id', [])
                              this.props.updateSelectDepartment(e, values)
                            })
                          }}
                          style={{ width: '100%' }}
                          defaultValue="all"
                        >
                          <Option value="all">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          {department.map(ele => (
                            <Option value={ele.id}>{ele.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.s.section}
                          </LangContext.Consumer>
                        }
                      >
                        <Select
                          name="section"
                          id="section"
                          value={values.section}
                          onChange={e => {
                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('section', e)
                              setFieldValue('list_vehicle_id', [])
                              setFieldValue('plant', ['all'])
                              this.props.updateSelectSection(e, values)
                            })
                          }}
                          style={{ width: '100%' }}
                          defaultValue="all"
                        >
                          <Option value="all">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          {section.map(ele => (
                            <Option value={ele.id}>{ele.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.p.plants}
                          </LangContext.Consumer>
                        }
                      >
                        <Select
                          style={{ width: '100%' }}
                          defaultValue="all"
                          mode="multiple"
                          onChange={e => {
                            let val = e
                            if (e.length > 1 && e.includes('all')) {
                              val = e.filter(
                                (x, i) => (x === 'all' && i === 0) === false,
                              )
                            }

                            if (e.length === 0) {
                              val = 'all'
                            }

                            if (val.length > 1 && e.includes('all')) {
                              val = e.filter(x => x === 'all')
                            }

                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('list_vehicle_id', [])
                              setFieldValue('plant', val)
                              this.props.updateSelectPlant(val, values)
                            })
                          }}
                          value={values.plant}
                        >
                          <Option value="all">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Option>
                          {plant.map(ele => (
                            <Option value={`${ele.id}`} key={ele.id}>
                              {ele.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicleGroup}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.vehicle_type_id &&
                          errors.vehicle_type_id &&
                          'error'
                        }
                        help={touched.vehicle_type_id && errors.vehicle_type_id}
                      >
                        <Select
                          name="vehicleGroup"
                          id="vehicleGroup"
                          onChange={e => {
                            this.setState({ vehicleSelect: [] }, () => {
                              setFieldValue('list_vehicle_id', [])
                              this.props.updateSelectVehicleGroup(e, values)
                            })
                          }}
                          defaultValue="all"
                          value={values.vehicleGroup}
                        >
                          <Options value="all">
                            <LangContext.Consumer>
                              {i18n => i18n.a.all}
                            </LangContext.Consumer>
                          </Options>
                          {vehicleGroup.map(item => (
                            <Options key={item.id} value={item.id}>
                              {item.name}
                            </Options>
                          ))}
                        </Select>
                      </FormItem>

                      <FormItem
                        label={
                          <LangContext.Consumer>
                            {i18n => i18n.v.vehicle}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.list_vehicle_id &&
                          errors.list_vehicle_id &&
                          'error'
                        }
                        help={touched.list_vehicle_id && errors.list_vehicle_id}
                      >
                        <div style={{ width: '750px' }}>
                          <TableTransfer
                            name="list_vehicle_id"
                            dataSource={this.state.vehicleList}
                            targetKeys={this.state.vehicleSelect}
                            selectedKeys={this.state.selectedKeys}
                            showSearch
                            filterOption={(inputValue, item) => {
                              return (
                                item.name
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              )
                            }}
                            onSelectChange={this.handleSelectChange}
                            onChange={value =>
                              this.tranferChange(value, setFieldValue)
                            }
                            leftColumns={this.leftTableColumns}
                            rightColumns={this.rightTableColumns}
                          />
                        </div>
                      </FormItem>
                      {this.props.call !== 'schedule' ? (
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {i18n => i18n.d.dateRange}
                            </LangContext.Consumer>
                          }
                          validateStatus={
                            touched.eventdate && errors.eventdate && 'error'
                          }
                          help={touched.eventdate && errors.eventdate}
                        >
                          <RangePicker
                            disabledDate={disabledDate}
                            value={values.eventdate}
                            showTime={{ format: 'HH:mm' }}
                            onChange={value => {
                              setFieldValue('eventdate', value)
                              this.setState({ eventdate: value })
                            }}
                            format="DD/MM/YYYY HH:mm"
                            placeholder={['Start Time', 'End Time']}
                            defaultValue={[
                              moment('00:00', 'HH:mm'),
                              moment('23:59', 'HH:mm'),
                            ]}
                          />
                        </Form.Item>
                      ) : (
                        ''
                      )}

                      <FormItem
                        validateStatus={
                          touched.only_dp && errors.only_dp && 'error'
                        }
                        help={touched.only_dp && errors.only_dp}
                      >
                        <Checkbox
                          onChange={value =>
                            setFieldValue('only_dp', value.target.checked)
                          }
                        >
                          <LangContext.Consumer>
                            {i18n => i18n.o.only_dp}
                          </LangContext.Consumer>
                        </Checkbox>
                      </FormItem>

                      <FormItem>
                        <Row gutter={24}>
                          {this.props.call == 'schedule' ? (
                            <Col span={8} style={{ marginTop: '10px' }}>
                              <Button type="primary" htmlType="submit" block>
                                <LangContext.Consumer>
                                  {i18n => i18n.s.save}
                                </LangContext.Consumer>
                              </Button>
                            </Col>
                          ) : (
                            <>
                              {' '}
                              <Col span={8}>
                                <Button type="defualt" block>
                                  <Link to="/">
                                    <LangContext.Consumer>
                                      {i18n => i18n.c.cancel}
                                    </LangContext.Consumer>
                                  </Link>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  block
                                  onClick={() =>
                                    this.setState({
                                      clickMenu: 'preview',
                                    })
                                  }
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.preview}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                              <Col span={8}>
                                <Button
                                  type="primary"
                                  block
                                  onClick={value => {
                                    this.setState({
                                      clickMenu: 'report',
                                    })
                                    this.handleMenuClick(
                                      'excel',
                                      setFieldValue,
                                      handleSubmit,
                                    )
                                  }}
                                >
                                  <LangContext.Consumer>
                                    {i18n => i18n.r.report}
                                  </LangContext.Consumer>
                                </Button>
                              </Col>
                            </>
                          )}
                        </Row>
                      </FormItem>
                    </Form>
                  </Row>
                )}
              />
            )}
          </LangContext.Consumer>
        </Spin>
      </div>
    )
  }
}

export default Forms
