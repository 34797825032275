import {
  LOAD_REQUEST_CERTIFICATE,
  REQUEST_CERTIFICATE,
  LOAD_VEHICLE_LIST,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  lists: [],
  total: 1,
  Vehiclelists: [],
  Vehicleloading: false,
  loading: false,
  requestcertificate: false,
  draw: -1,
  ckeck: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState

    case LOAD_REQUEST_CERTIFICATE.REQUEST:
      // const { loading } = action.payload.data;
      return {
        ...state,
        loading: action.payload.data.loading,
      }

    case LOAD_REQUEST_CERTIFICATE.SUCCESS:
      const { page, pageSize } = action.payload

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * pageSize
      }

      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      }

    case REQUEST_CERTIFICATE.REQUEST:
      return {
        ...state,
        requestcertificate: true,
      }

    case REQUEST_CERTIFICATE.SUCCESS:
      return {
        ...state,
        requestcertificate: false,
      }

    case LOAD_VEHICLE_LIST.REQUEST:
      const { loading } = action.payload.data
      return {
        ...state,
        Vehicleloading: loading,
        ckeck: 0,
      }

    case LOAD_VEHICLE_LIST.SUCCESS:
      return {
        ...state,
        Vehiclelists: action.payload.Vehiclelists.data,
        ckeck: 1,
        Vehicleloading: false,
      }

    default:
      return state
  }
}
