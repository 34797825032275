import {
  LOAD_TRIPBYENGINEOFFON,
  LOAD_TRIPBYENGINEOFFON_PREVIEW,
  LOAD_VEHICLE_TRIPBYENGINEOFFON,
  LOAD_VEHICLE_TYPE_TRIPBYENGINEOFFON,
} from './actions'

const initialState = {
  vehicleLists: [],
  vehicleTypeLists: [],
  tripbyengineoffonLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRIPBYENGINEOFFON.REQUEST:
    case LOAD_TRIPBYENGINEOFFON_PREVIEW.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        tripbyengineoffonLoading: loading,
      }
    case LOAD_TRIPBYENGINEOFFON.SUCCESS:
    case LOAD_TRIPBYENGINEOFFON_PREVIEW.SUCCESS:
      return {
        ...state,
        tripbyengineoffonLoading: false,
      }
    case LOAD_VEHICLE_TRIPBYENGINEOFFON.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        tripbyengineoffonLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_TRIPBYENGINEOFFON.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        tripbyengineoffonLoading: false,
      }
    case LOAD_VEHICLE_TYPE_TRIPBYENGINEOFFON.SUCCESS:
      // console.log(action.payload.data.data);
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
