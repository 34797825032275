import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { USER_ID } from './../../../constants/local_storage'
import * as actions from './actions'

function* loadUsergroup(action) {
  const {
    data: {
      page,
      pageSize,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/usergroup`,
      {
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
        },
      },
    )

    yield put(actions.loadUsergroup.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadUsergroup.failure())
  }
}

function* loadUsergroupDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload
  // const company_id =
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/usergroup/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    // cb(res.data[0]);
    // console.log(res.data.data);
    yield put(actions.loadUsergroupDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadUsergroupDetail.failure())
  }
}

function* createUsergroup(action) {
  const {
    data: {
      name,
      is_base,
      company,
      user,
      permission,
      active,
      matching_name,
      action_name,
      accessToken,
    },
    cb,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/usergroup/create`,
      {
        name,
        is_base,
        active,
        company,
        user,
        permission,
        matching_name,
        action_name,
        created_by: USER_ID,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb(res.data)
    yield put(actions.createUsergroup.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createUsergroup.failure())
  }
}

function* updateUsergroup(action) {
  const {
    data: {
      usergroup_id,
      name,
      company,
      user,
      user_befor_edit,
      permission,
      is_base,
      active,
      matching_name,
      action_name,
      accessToken,
    },
    cb,
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/usergroup/update`,
      {
        usergroup_id,
        name,
        company,
        user,
        user_befor_edit,
        permission,
        is_base,
        active,
        matching_name,
        action_name,
        updated_by: USER_ID,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    cb(res.data)

    yield put(actions.updateUsergroup.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateUsergroup.failure())
  }
}

function* exportUsergroup(action) {
  const {
    data: {
      page,
      pageSize,
      companyID,
      orderBy,
      orderType,
      accessToken,
      type,
      file_value_pdf,
      limit_export,
      filterObj,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/usergroup`,
      {
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        type,
        file_value_pdf,
        limit_export,
        filterObj,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
        },
      },
    )

    yield put(actions.exportUsergroup.success(res.data, page, pageSize))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportUsergroup.failure())
  }
}

export default function* watchUsergroupMasterDataState() {
  yield all([
    takeEvery(actions.CREATE_USERGROUP.REQUEST, createUsergroup),
    takeEvery(actions.LOAD_USERGROUP.REQUEST, loadUsergroup),
    takeEvery(actions.LOAD_USERGROUP_DETAIL.REQUEST, loadUsergroupDetail),
    takeEvery(actions.UPDATE_USERGROUP.REQUEST, updateUsergroup),
    takeEvery(actions.EXPORT_USERGROUP.REQUEST, exportUsergroup),
  ])
}

export { createUsergroup, loadUsergroup, loadUsergroupDetail, updateUsergroup }
