import { createTypes, createAction } from 'lib/action'

const LOAD_TRIPBYENGINEOFFON = createTypes('tripbyengineoffon', 'load')
const LOAD_TRIPBYENGINEOFFON_PREVIEW = createTypes(
  'tripbyengineoffonpreview',
  'load',
)
const LOAD_VEHICLE_TRIPBYENGINEOFFON = createTypes(
  'vehicle_tripbyengineoffon',
  'load',
)
const LOAD_VEHICLE_TYPE_TRIPBYENGINEOFFON = createTypes(
  'vehicletype_tripbyengineoffon',
  'load',
)

const loadTripbyengineoffon = {
  request: (data, loading) =>
    createAction(LOAD_TRIPBYENGINEOFFON.REQUEST, { data, loading }),
  success: data => createAction(LOAD_TRIPBYENGINEOFFON.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRIPBYENGINEOFFON.FAILURE),
}

const loadTripbyengineoffonpreview = {
  request: (data, loading) =>
    createAction(LOAD_TRIPBYENGINEOFFON_PREVIEW.REQUEST, { data, loading }),
  success: data =>
    createAction(LOAD_TRIPBYENGINEOFFON_PREVIEW.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRIPBYENGINEOFFON_PREVIEW.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TRIPBYENGINEOFFON.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: data =>
    createAction(LOAD_VEHICLE_TRIPBYENGINEOFFON.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TRIPBYENGINEOFFON.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_TRIPBYENGINEOFFON.REQUEST, {}),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_TRIPBYENGINEOFFON.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TRIPBYENGINEOFFON.FAILURE),
}

export {
  loadTripbyengineoffon,
  LOAD_TRIPBYENGINEOFFON,
  loadTripbyengineoffonpreview,
  LOAD_TRIPBYENGINEOFFON_PREVIEW,
  loadVehicle,
  LOAD_VEHICLE_TRIPBYENGINEOFFON,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TRIPBYENGINEOFFON,
}
