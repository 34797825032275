import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import { getlocalstorage } from './../../../constants/local_storage'

const COMPANY_ID = getlocalstorage(
  localStorage.getItem('profile'),
  'company_id',
)
const USER_ID = getlocalstorage(localStorage.getItem('profile'), 'id')
const vehicle_visibility = getlocalstorage(
  localStorage.getItem('profile'),
  'vehicle_visibility',
)

const ACCESSTOKEN = localStorage.getItem('access-token')

function* loadRequestDeliveryReport(action) {
  const {
    data: {
      location_visibility,
      exactly_location,
      mill_location_list,
      plant_location_list,
      material_visibility,
      page,
      pageSize,
      filterObj,
      is_vendor,
      is_admin,
      company_id,
      accesstoken,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply/get/delivery-report`,
      {
        company_id,
        location_visibility,
        exactly_location,
        mill_location_list,
        plant_location_list,
        material_visibility,
        is_admin,
        page,
        pageSize,
        filterObj,
        is_vendor,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.loadRequestDeliveryReport.success(res.data, page))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadRequestDeliveryReport.failure())
  }
}

function* exportReport(action) {
  const {
    data: {
      location_visibility,
      exactly_location,
      mill_location_list,
      plant_location_list,
      material_visibility,
      filterObj,
      group_by_report,
      company_id,
      is_admin,
      is_vendor,
      dataSelectedDivision,
      dataSelectedDepartment,
      dataSelectedSection,
      accesstoken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-delivery-report`,
      {
        company_id,
        location_visibility,
        exactly_location,
        mill_location_list,
        plant_location_list,
        material_visibility,
        filterObj,
        group_by_report,
        is_admin,
        is_vendor,
        dataSelectedDivision,
        dataSelectedDepartment,
        dataSelectedSection,
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } },
    )

    yield put(actions.exportReport.success(res.data))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportReport.failure())
  }
}

export default function* watchIsupplyDeliveryReport() {
  yield all([
    takeEvery(
      actions.LOAD_REQUEST_DELIVERY_REPORT.REQUEST,
      loadRequestDeliveryReport,
    ),
    takeEvery(actions.EXPORT_REPORT.REQUEST, exportReport),
  ])
}

export { loadRequestDeliveryReport, exportReport }
