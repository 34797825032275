import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col
} from "antd";

import * as actions from "../actions";
import Forms from "./Forms";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";


class Add extends Component {

    componentDidMount() {
        this.props.clearRoutemaster();
    }

    create = (values, loading) => {
        this.props.createRoutemaster(values, loading, this.props.history);

    };



    render() {

        return (
            <GeneralStyledContent>
                <Row gutter={24}>
                    <Col span={24}>
                        <Breadcrumb
                            match={this.props.match}
                            style={{ margin: "0px 0px 14px 10px" }}
                        />
                    </Col>
                </Row>
                <Forms
                    onSubmit={this.create}
                    routemaster={this.props.routemaster}
                    id={""}
                    auth={this.props.auth}

                />
            </GeneralStyledContent>
        );
    }
}

const mapStateToProps = ({
    routemaster, auth
}) => ({
    routemaster, auth
});

const mapDispatchToProps = {
    createRoutemaster: actions.createRoutemaster.request,
    clearRoutemaster: actions.clearRoutemaster,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Add);
