import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

function* loadRequestMaterial(action) {
  const {
    data: {
      location_visibility,
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      is_admin,
      is_vendor,
      company_id,
      filterObj,
      type,
      limit_export,
      file_value_pdf,
      exactly_location,
      mill_location_list,
      plant_location_list,
      material_visibility,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get/new/requestmaterial`,
      {
        location_visibility,
        page,
        pageSize,
        orderBy,
        orderType,
        is_admin,
        is_vendor,
        company_id,
        filterObj,
        type,
        limit_export,
        file_value_pdf,
        exactly_location,
        mill_location_list,
        plant_location_list,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadRequestMaterial.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadRequestMaterial.failure())
  }
}

function* exportRequestMaterial(action) {
  const {
    data: {
      location_visibility,
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      is_admin,
      is_vendor,
      company_id,
      filterObj,
      type,
      limit_export,
      file_value_pdf,
      exactly_location,
      mill_location_list,
      plant_location_list,
      material_visibility,
    },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get/new/requestmaterial`,
      {
        location_visibility,
        page,
        pageSize,
        orderBy,
        orderType,
        accessToken,
        is_admin,
        is_vendor,
        company_id,
        filterObj,
        type,
        limit_export,
        file_value_pdf,
        exactly_location,
        mill_location_list,
        plant_location_list,
        material_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportRequestMaterial.success(res.data, page))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportRequestMaterial.failure())
  }
}

function* createRequestMaterial(action) {
  const {
    data: { files, request_date, checked, running_dn_number, accessToken },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('request_date', request_date)
  formData.append('checked', checked)
  formData.append('running_dn_number', running_dn_number)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/new-createrequestmaterial`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    loading(res.data)
    yield put(actions.createRequestMaterial.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.createRequestMaterial.failure())
  }
}

export default function* watchRequestMaterial() {
  yield all([
    takeEvery(actions.CREATE_REQUEST_MATERIAL.REQUEST, createRequestMaterial),
    takeEvery(actions.LOAD_REQUEST_MATEL.REQUEST, loadRequestMaterial),
    takeEvery(actions.EXPORT_REQUEST_MATEL.REQUEST, exportRequestMaterial),
  ])
}

export { loadRequestMaterial, createRequestMaterial, exportRequestMaterial }
