import { createTypes, createAction } from "lib/action";
const LOAD_VEHICLE_GPS_LOST_CONTACT_REPORT = createTypes("vehicle_gps_lost_contact_report","load");
const LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT = createTypes("gps_vendor_gps_lost_contact_report","load");
const EXPORT_GPS_LOST_CONTACT_REPORT = createTypes("exportgpslostcontractreport", "load");


const exportGPSLostConTracteport = {
    request: (data, loading) =>
        createAction(EXPORT_GPS_LOST_CONTACT_REPORT.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(EXPORT_GPS_LOST_CONTACT_REPORT.SUCCESS, {lists}),
    failure: () => createAction(EXPORT_GPS_LOST_CONTACT_REPORT.FAITLURE)  
};

const loadVehicle = {
    request: (gps_vendor_id, loading_vehicle) =>
    createAction(LOAD_VEHICLE_GPS_LOST_CONTACT_REPORT.REQUEST, {
        gps_vendor_id,
        loading_vehicle
        }),
    success: data => createAction(LOAD_VEHICLE_GPS_LOST_CONTACT_REPORT.SUCCESS, { data }),
    failure: () => createAction(LOAD_VEHICLE_GPS_LOST_CONTACT_REPORT.FAILURE)
};

const loadGpsVendor = {
    request: () => createAction(LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.REQUEST, {}),
    success: data =>
      createAction(LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.SUCCESS, { data }),
    failure: () => createAction(LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT.FAILURE)
};

export {
    LOAD_VEHICLE_GPS_LOST_CONTACT_REPORT, loadVehicle,
    LOAD_QPS_VENDOR_GPS_LOST_CONTACT_REPORT, loadGpsVendor,
    EXPORT_GPS_LOST_CONTACT_REPORT, exportGPSLostConTracteport
}