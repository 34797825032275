import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { phoneRegExp } from "../../../../constants/constants";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Button,
  Row,
  Col,
  Modal,
  DatePicker,
  Tag,
  Input,
  Form,
  Select,
  message,
  Alert,
  Spin,
} from "antd";
import axios from "axios";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { helper_convertDateformat } from "../../../../lib/helper";
import LangContext, { i18n } from "modules/shared/context/langContext";

const InputGroup = Input.Group;
const { confirm } = Modal;
const { MonthPicker, RangePicker } = DatePicker;
const { Option } = Select;
const { Item } = Form;
const FormItem = Item;
class Index extends Component {
  state = {
    data: { oldPassword: "", password: "", confirmPassword: "" },
    data_profile: {company_name: "", username: "", employee_code: "", firstname: "", lastname: "", phone: "", email: "", expired_at: ""},
    ModalText: "Content of the modal",
    disable: true,
    visible: false,
    confirmLoading: false,
    alertMessage: "",
    alertType: "",
    loading: false
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  disableFalse = () => {
    this.setState({
        disable: false,
      });
  }

  handleOk = (values, actions) => {
    let self = this;
    this.setState({
      confirmLoading: true,
    });
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/change-password`, {
      // fetch(`http://booking-api-88:88/api/change-password`, {
      method: "POST",
      mode: "cors",
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${self.props.auth.accessToken}`,
      },
      body: JSON.stringify({
        old_password: values.oldPassword,
        new_password: values.password,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({
          //   visible: false,
          confirmLoading: false,
          alertType: res.status,
          alertMessage: res.msg,
        });
        actions.resetForm({});
      })
      .catch(function() {});
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
        this.setState({loading: true}, ()=> {this.loadDataProfile()});
  }

  loadDataProfile(){
    let self = this;
    axios
        .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/get-data-profile`,
        {
            headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
        }
        )
        .then(function(response) {
            self.setState({
                data_profile: {
                    ...self.state.data_profile,
                    company_name:   self.props.auth.profile.company_name, 
                    username:       response.data.data.username, 
                    employee_code:  response.data.data.code,  
                    firstname:      response.data.data.firstname,  
                    lastname:       response.data.data.lastname,   
                    phone:          response.data.data.phone,
                    email:          response.data.data.email,
                    expired_at:     response.data.data.expired_at,
                },
                loading: false,
                disable: true,
            });
        })
        .catch(function(error) {
            console.log(error);
        });
  }
  
  componentWillUnmount() {}

  showConfirm = values => {
    let self = this;
    confirm({
      title: (
        <LangContext.Consumer>
          {i18n => i18n.d.doYouWantToEditTheseItems}
        </LangContext.Consumer>
      ),
      content: (
        <LangContext.Consumer>
          {i18n => i18n.c.confirmToEditThisItem}
        </LangContext.Consumer>
      ),
      okText: <LangContext.Consumer>{i18n => i18n.o.ok}</LangContext.Consumer>,
      cancelText: (
        <LangContext.Consumer>{i18n => i18n.c.cancel}</LangContext.Consumer>
      ),
      onOk() {
        self.handleSubmit(values);
      },
      onCancel() {}
    });
  };

  handleSubmit = values => {
    let self = this;

    this.setState({loading: true});
        
    axios
        .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/change-profile`,
        {
            username:       values.username,
            employee_code:  values.employee_code,
            firstname:      values.firstname,
            lastname:       values.lastname,
            phone:          values.phone,
            email:          values.email,
        },
        {
            headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
        }
        )
        .then(function(response) {
            if (response.data.status == "fail" && response.data.errors) {
                message.error("edit fail");
                self.setState({loading: false});
              }else if( response.data.status ==  "fail_username"){
                  Modal.warning({
                      title: response.data.msg,
                  });
                  self.setState({loading: false});
              } else {
                message.success("edit success");
                self.loadDataProfile();
              }
        })
        .catch(function(error) {
            console.log(error);
        });
  }

  handleCancelEditProfile = () => {
    this.setState({
        disable: true,
        data_profile: {
            ...this.state.data_profile,
            company_name:   this.props.auth.profile.company_name, 
            username:       this.props.auth.profile.username, 
            employee_code:  this.props.auth.profile.employee_code,  
            firstname:      this.props.auth.profile.firstname,  
            lastname:       this.props.auth.profile.lastname,   
            phone:          this.props.auth.profile.phone,
            email:          this.props.auth.profile.email,
            expired_at:     this.props.auth.profile.expired_at,
        },
        loading: true
    }, ()=> {this.loadDataProfile()})
  }
  render() {

    let user_type          = this.props.auth.profile.user_type;
    let dataVisibilityNode = this.props.auth.profile.data_visibility_node;
        dataVisibilityNode = JSON.parse(dataVisibilityNode);

    if (dataVisibilityNode.length == 0)
            dataVisibilityNode = [{ name: this.props.auth.profile.company_name }];
    return (
      <Fragment>
            <LangContext.Consumer>
                {(i18n) => (
                    <Formik
                        enableReinitialize={true}
                        initialValues={this.state.data_profile}
                        validationSchema={yup.object().shape({
                            username: yup.string().required(" Username is required"),
                            firstname: yup.string().required(" First Name is required"),
                            firstname: yup.string().required(" Last Name is required"),
                            phone: yup
                                .string()
                                .min(8, " Too Short!")
                                .max(20, " Too Long!")
                                .matches(phoneRegExp, " # Phone Number is not valid")
                                .required(" Phone Number is invalid"),
                        })}
                        onSubmit={(values, actions) => {
                            this.showConfirm(values, actions);
                        }}

                        render={({
                            values,
                            errors,
                            status,
                            handleBlur,
                            touched,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <Spin spinning={this.state.loading}>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                        <Form.Item
                                        label={
                                            <LangContext.Consumer>
                                            {(i18n) => i18n.c.company}
                                            </LangContext.Consumer>
                                        }
                                        style={{ marginBottom: "0px" }}
                                        >
                                        <Input
                                            type="Text"
                                            name="company"
                                            value={values.company_name}
                                            disabled={true}
                                        />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                        <Form.Item
                                            label={
                                                <LangContext.Consumer>
                                                {(i18n) => i18n.u.userName}
                                                </LangContext.Consumer>
                                            }
                                            required={true}
                                            validateStatus={
                                                touched.username && errors.username
                                                ? "warning"
                                                : undefined
                                            }
                                            help={
                                                touched.username && errors.username
                                                ? errors.username.substr(
                                                    errors.username.indexOf(" ") + 1
                                                    )
                                                : undefined
                                            }
                                            style={{ marginBottom: "0px" }}
                                        >
                                        <Input
                                            type="Text"
                                            name="username"
                                            value={values.username}
                                            disabled={this.state.disable}
                                            onChange={e => {
                                                setFieldValue("username", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <FormItem
                                            label={
                                                <LangContext.Consumer>
                                                {(i18n) => i18n.e.employeeID}
                                                </LangContext.Consumer>
                                            }
                                            style={{ marginBottom: "0px" }}
                                        >
                                        <Input
                                            type="Text"
                                            name="employee_ID"
                                            autoComplete="off"
                                            value={values.employee_code}
                                            disabled={this.state.disable}
                                            onChange={e => {
                                                setFieldValue("employee_code", e.target.value);
                                            }}
                                        />
                                        </FormItem>
                                    </Col>

                                    <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <Form.Item
                                            label={
                                                <LangContext.Consumer>
                                                {(i18n) => i18n.f.firstName}
                                                </LangContext.Consumer>
                                            }
                                            style={{ marginBottom: "0px" }}
                                            required={true}
                                            validateStatus={
                                                touched.firstname && errors.firstname
                                                ? "warning"
                                                : undefined
                                            }
                                            help={
                                                touched.firstname && errors.firstname
                                                ? errors.firstname.substr(
                                                    errors.firstname.indexOf(" ") + 1
                                                    )
                                                : undefined
                                            }
                                        >
                                        <Input
                                            type="Text"
                                            name="firstname"
                                            autoComplete="off"
                                            value={values.firstname}
                                            disabled={this.state.disable}
                                            onChange={e => {
                                                setFieldValue("firstname", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <Form.Item
                                            label={
                                                <LangContext.Consumer>
                                                {(i18n) => i18n.l.lastName}
                                                </LangContext.Consumer>
                                            }
                                            style={{ marginBottom: "0px" }}
                                            required={true}
                                            validateStatus={
                                                touched.lastname && errors.lastname
                                                ? "warning"
                                                : undefined
                                            }
                                            help={
                                                touched.lastname && errors.lastname
                                                ? errors.lastname.substr(
                                                    errors.lastname.indexOf(" ") + 1
                                                    )
                                                : undefined
                                            }
                                        >
                                        <Input
                                            type="Text"
                                            name="lastname"
                                            autoComplete="off"
                                            value={values.lastname}
                                            disabled={this.state.disable}
                                            onChange={e => {
                                                setFieldValue("lastname", e.target.value);
                                            }}
                                        />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <FormItem
                                            label={
                                                <LangContext.Consumer>
                                                {(i18n) => i18n.p.phoneNumber}
                                                </LangContext.Consumer>
                                            }
                                            style={{ marginBottom: "0px" }}
                                            required={true}
                                            validateStatus={
                                                touched.phone && errors.phone
                                                ? "warning"
                                                : undefined
                                            }
                                            help={
                                                touched.phone && errors.phone
                                                ? errors.phone.substr(
                                                    errors.phone.indexOf(" ") + 1
                                                    )
                                                : undefined
                                            }
                                        >
                                        <Input
                                            type="Text"
                                            name="tel"
                                            value={values.phone}
                                            disabled={this.state.disable}
                                            onChange={e => {
                                                setFieldValue("phone", e.target.value);
                                            }}
                                        />
                                        </FormItem>
                                    </Col>

                                    <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>

                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <FormItem
                                        label={
                                            <LangContext.Consumer>
                                            {(i18n) => i18n.e.email}
                                            </LangContext.Consumer>
                                        }
                                        style={{ marginBottom: "0px" }}
                                        >
                                        <Input
                                            type="Text"
                                            name="email"
                                            autoComplete="off"
                                            value={values.email}
                                            // readOnly={true}
                                            disabled={this.state.disable}
                                            onChange={e => {
                                                setFieldValue("email", e.target.value);
                                            }}
                                        />
                                        </FormItem>
                                    </Col>
                                    <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <Form.Item
                                        label={
                                            <LangContext.Consumer>
                                            {(i18n) => i18n.u.userExpireAt}
                                            </LangContext.Consumer>
                                        }
                                        >
                                        <Input
                                            value={helper_convertDateformat(
                                                values.expired_at,
                                                "YYYY-MM-DD HH:mm:ss",
                                                "DD/MM/YYYY HH:mm"
                                            )}
                                            // readOnly={true}
                                            disabled={true}
                                        />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <Form.Item
                                            label={
                                                <LangContext.Consumer>
                                                {(i18n) => i18n.d.dataVisibility}
                                                </LangContext.Consumer>
                                            }
                                        >
                                            {dataVisibilityNode.map((ele) => (
                                                <Tag>{ele.name}</Tag>
                                            ))}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <br />
                                {
                                    user_type == 1 && this.state.disable == true?
                                        <Row>
                                            <Col sm={24} md={6} lg={6} xl={6}>
                                                <Form.Item>
                                                    <Button type="primary"  onClick={this.disableFalse}>
                                                        <LangContext.Consumer>
                                                            {(i18n) => i18n.e.editProfile}
                                                        </LangContext.Consumer>
                                                    </Button>
                                                    <Button style={{marginLeft: "5px"}} type="primary" onClick={this.showModal}>
                                                        <LangContext.Consumer>
                                                            {(i18n) => i18n.c.changePassword}
                                                        </LangContext.Consumer>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    : ""
                                }
                                {
                                    user_type == 1 && this.state.disable == false?
                                        <Row>
                                            <Col sm={24} md={6} lg={6} xl={6}>
                                                <Form.Item>
                                                    <Button onClick={this.handleCancelEditProfile}>
                                                        <LangContext.Consumer>
                                                            {(i18n) => i18n.c.cancel}
                                                        </LangContext.Consumer>
                                                    </Button>
                                                    <Button style={{marginLeft: "5px"}} type="primary" htmlType="submit">
                                                        <LangContext.Consumer>
                                                            {i18n => i18n.s.save}
                                                        </LangContext.Consumer>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    : ""
                                }
                            </Form>
                            </Spin>
                        )}
                    />
                )}
            </LangContext.Consumer>

        <LangContext.Consumer>
          {(i18n) => (
            <Formik
              enableReinitialize={true}
              initialValues={this.state.data}
              validationSchema={yup.object().shape({
                oldPassword: yup.string().required(" " + i18n.r.require),
                password: yup
                  .string()
                  .min(6, " " + i18n.p.passwordMustBeAtLeast6)
                  .required(" " + i18n.r.require),
                confirmPassword: yup
                  .string()
                  .oneOf(
                    [yup.ref("password"), null],
                    " " + i18n.p.passwordMustMatch
                  )
                  .required(" " + i18n.r.require),
              })}
              onSubmit={(values, actions) => {
                this.handleOk(values, actions);
              }}
              render={({
                values,
                errors,
                status,
                handleBlur,
                touched,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form>
                  <Modal
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.changePassword}
                      </LangContext.Consumer>
                    }
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={handleSubmit}
                    okText={
                      <LangContext.Consumer>
                        {(i18n) => i18n.s.submit}
                      </LangContext.Consumer>
                    }
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                    cancelText={
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.cancel}
                      </LangContext.Consumer>
                    }
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {this.state.alertType != "" && (
                          <Alert
                            message={
                              this.state.alertType != "error" ? (
                                <LangContext.Consumer>
                                  {(i18n) =>
                                    i18n.variable[this.state.alertMessage]
                                  }
                                </LangContext.Consumer>
                              ) : (
                                this.state.alertMessage
                              )
                            }
                            type={this.state.alertType}
                            closable
                            //   afterClose={this.handleClose}
                          />
                        )}
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.p.password}
                            </LangContext.Consumer>
                          }
                          style={{ marginBottom: "0px" }}
                          required={true}
                          validateStatus={
                            touched.oldPassword && errors.oldPassword
                              ? "warning"
                              : undefined
                          }
                          help={
                            touched.oldPassword && errors.oldPassword
                              ? errors.oldPassword.substr(
                                  errors.oldPassword.indexOf(" ") + 1
                                )
                              : undefined
                          }
                        >
                          <Input.Password
                            name="oldPassword"
                            value={values.oldPassword}
                            onChange={(e) => {
                              setFieldValue("oldPassword", e.target.value);
                            }}
                            onBlur={handleBlur}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.n.newPassword}
                            </LangContext.Consumer>
                          }
                          style={{ marginBottom: "0px" }}
                          required={true}
                          validateStatus={
                            touched.password && errors.password
                              ? "warning"
                              : undefined
                          }
                          help={
                            touched.password && errors.password
                              ? errors.password.substr(
                                  errors.password.indexOf(" ") + 1
                                )
                              : undefined
                          }
                        >
                          <Input.Password
                            name="password"
                            value={values.password}
                            onChange={(e) => {
                              setFieldValue("password", e.target.value);
                            }}
                            onBlur={handleBlur}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.c.confirmNewPassword}
                            </LangContext.Consumer>
                          }
                          style={{ marginBottom: "0px" }}
                          required={true}
                          validateStatus={
                            touched.confirmPassword && errors.confirmPassword
                              ? "warning"
                              : undefined
                          }
                          help={
                            touched.confirmPassword && errors.confirmPassword
                              ? errors.confirmPassword.substr(
                                  errors.confirmPassword.indexOf(" ") + 1
                                )
                              : undefined
                          }
                        >
                          <Input.Password
                            type="hidden"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            onChange={(e) => {
                              setFieldValue("confirmPassword", e.target.value);
                            }}
                            onBlur={handleBlur}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Modal>
                </Form>
              )}
            />
          )}
        </LangContext.Consumer>
      </Fragment>
    );
  }
}

export default connect(
  ({ auth }) => ({
    auth,
  }),
  {}
)(Index);
