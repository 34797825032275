import { createTypes, createAction } from "lib/action";

const LOAD_ROUTEMASTER = createTypes("routemaster", "load");
const CREATE_ROUTEMASTER = createTypes("routemaster", "create");
const UPDATE_ROUTEMASTER = createTypes("routemaster", "update");
const GET_ROUTEMASTER = createTypes("routemaster", "get");
const CLEAR_ROUTEMASTER = createTypes("routemaster", "clear");
const CLEAR_RESPONSE = createTypes("response", "clear");

const LOAD_LOCATION_ROUTEMASTER = createTypes("location_routemaster", "load");
const LOAD_LOCATIONCODE_ROUTEMASTER = createTypes("locationcode_routemaster", "load");


const LOAD_ROUTEMASTERDETAIL = createTypes("routemasterdetail", "load");
const CREATE_ROUTEMASTERDETAIL = createTypes("routemasterdetail", "create");
const UPDATE_ROUTEMASTERDETAIL = createTypes("routemasterdetail", "update");
const GET_ROUTEMASTERDETAIL = createTypes("routemasterdetail", "get");

const LOAD_VEHICLE_ROUTEMASTER = createTypes("vehicle_routemaster", "load");

const loadRoutemaster = {
  request: (data, loading) =>
    createAction(LOAD_ROUTEMASTER.REQUEST, { data, loading }),
  success: data => createAction(LOAD_ROUTEMASTER.SUCCESS, { data }),
  failure: () => createAction(LOAD_ROUTEMASTER.FAILURE)
};

const createRoutemaster = {
  request: (data, loading, history) =>
    createAction(CREATE_ROUTEMASTER.REQUEST, {
      data,
      loading,
      history
    }),
  success: data => createAction(CREATE_ROUTEMASTER.SUCCESS, { data }),
  failure: () => createAction(CREATE_ROUTEMASTER.FAILURE)
};

const updateRoutemaster = {
  request: (data, id, history, loading) =>
    createAction(UPDATE_ROUTEMASTER.REQUEST, { data, id, history, loading }),
  success: data => createAction(UPDATE_ROUTEMASTER.SUCCESS, { data }),
  failure: () => createAction(UPDATE_ROUTEMASTER.FAILURE)
};


const getRoutemasterByID = {
  request: id => createAction(GET_ROUTEMASTER.REQUEST, { id }),
  success: data => createAction(GET_ROUTEMASTER.SUCCESS, { data }),
  failure: () => createAction(GET_ROUTEMASTER.FAILURE)
};


const loadRoutemasterdetail = {
  request: (id, loading) =>
    createAction(LOAD_ROUTEMASTERDETAIL.REQUEST, { id, loading }),
  success: data => createAction(LOAD_ROUTEMASTERDETAIL.SUCCESS, { data }),
  failure: () => createAction(LOAD_ROUTEMASTERDETAIL.FAILURE)
};

const createRoutemasterdetail = {
  request: (data, route_master_id) =>
    createAction(CREATE_ROUTEMASTERDETAIL.REQUEST, {
      data, route_master_id
    }),
  success: data => createAction(CREATE_ROUTEMASTERDETAIL.SUCCESS, { data }),
  failure: () => createAction(CREATE_ROUTEMASTERDETAIL.FAILURE)
};


const updateRoutemasterdetail = {
  request: (data, route_master_detail_id, route_master_id) =>
    createAction(UPDATE_ROUTEMASTERDETAIL.REQUEST, { data, route_master_detail_id, route_master_id }),
  success: data => createAction(UPDATE_ROUTEMASTERDETAIL.SUCCESS, { data }),
  failure: () => createAction(UPDATE_ROUTEMASTERDETAIL.FAILURE)
};


const getRoutemasterdetailByID = {
  request: (id, loading) => createAction(GET_ROUTEMASTERDETAIL.REQUEST, { id, loading }),
  success: data => createAction(GET_ROUTEMASTERDETAIL.SUCCESS, { data }),
  failure: () => createAction(GET_ROUTEMASTERDETAIL.FAILURE)
};


const loadLocation = {
  request: data => createAction(LOAD_LOCATION_ROUTEMASTER.REQUEST, { data }),
  success: data => createAction(LOAD_LOCATION_ROUTEMASTER.SUCCESS, { data }),
  failure: () => createAction(LOAD_LOCATION_ROUTEMASTER.FAILURE)
};

const loadLocationcode = {
  request: data => createAction(LOAD_LOCATIONCODE_ROUTEMASTER.REQUEST, { data }),
  success: data => createAction(LOAD_LOCATIONCODE_ROUTEMASTER.SUCCESS, { data }),
  failure: () => createAction(LOAD_LOCATIONCODE_ROUTEMASTER.FAILURE)
};


const loadVehicle = {
  request: () => createAction(LOAD_VEHICLE_ROUTEMASTER.REQUEST, {}),
  success: data => createAction(LOAD_VEHICLE_ROUTEMASTER.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_ROUTEMASTER.FAILURE)
};


const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE
  };
};

const clearRoutemaster = () => {
  return {
    type: CLEAR_ROUTEMASTER
  };
};

export {
  LOAD_ROUTEMASTER,
  CREATE_ROUTEMASTER,
  UPDATE_ROUTEMASTER,
  GET_ROUTEMASTER,
  loadRoutemaster,
  createRoutemaster,
  updateRoutemaster,
  getRoutemasterByID,
  LOAD_ROUTEMASTERDETAIL,
  CREATE_ROUTEMASTERDETAIL,
  UPDATE_ROUTEMASTERDETAIL,
  GET_ROUTEMASTERDETAIL,
  loadRoutemasterdetail,
  createRoutemasterdetail,
  updateRoutemasterdetail,
  getRoutemasterdetailByID,
  CLEAR_RESPONSE,
  clearDataResponse,
  clearRoutemaster,
  LOAD_LOCATION_ROUTEMASTER,
  loadLocation,
  LOAD_LOCATIONCODE_ROUTEMASTER,
  loadLocationcode,
  CLEAR_ROUTEMASTER,
  LOAD_VEHICLE_ROUTEMASTER,
  loadVehicle,
};
