import React, { Component } from 'react'
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  AutoComplete,
  InputNumber,
  Input,
  TimePicker,
  Spin,
} from 'antd'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import moment from 'moment'

import LangContext, { i18n } from 'modules/shared/context/langContext'

const FormItem = Form.Item
const OptionAuto = AutoComplete.Option
const InputGroup = Input.Group
const format = 'HH:mm'

class FormDetail extends Component {
  state = {
    location_name: '',
    location_code: '',
    dest_location: '',
    code_dest_location: '',
    date_entry: 0,
    date_out: 0,
    time_entry: moment('00:00', format),
    time_out: moment('00:00', format),
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { routemasterDetails } = nextProps
    if (
      this.props.check_routemasterdetail !== nextProps.check_routemasterdetail
    ) {
      this.setState({
        dest_location: routemasterDetails.location_id,
        code_dest_location: routemasterDetails.location_id,
        location_name: routemasterDetails.location_name,
        location_code: routemasterDetails.location_code,
        date_entry: routemasterDetails.date_entry,
        date_out: routemasterDetails.date_out,
        time_entry: moment(routemasterDetails.time_entry, format),
        time_out: moment(routemasterDetails.time_out, format),
      })
    }
    return true
  }

  handleClick = (value, setFieldValue, handleSubmit) => {
    setFieldValue('dest_location', this.state.dest_location, false)
    setFieldValue('code_dest_location', this.state.dest_location, false)
    handleSubmit()
  }

  selectLocation = (value, option, setFieldValue) => {
    this.setState({ location_code: option.props.label })
    this.setState({ location_name: option.props.children })
    this.setState({ dest_location: value })
    this.setState({ code_dest_location: value })
  }

  onSearchLocation = searchText => {
    if (searchText.length > 1) {
      const { loadLocation } = this.props
      loadLocation(searchText)
    }
    this.setState({ location_name: searchText })
  }

  selectLocationcode = (value, option, setFieldValue) => {
    this.setState({ location_code: value })
    this.setState({ location_name: option.props.label })
    this.setState({ dest_location: value })
    this.setState({ code_dest_location: value })
  }

  onChangeDateEntry = (value, setFieldValue) => {
    this.setState({ date_entry: value })
    setFieldValue('date_entry', value)
  }

  onChangeDateOut = (value, setFieldValue) => {
    this.setState({ date_out: value })
    setFieldValue('date_out', value)
  }

  onChangeTimeEntry = (value, setFieldValue) => {
    this.setState({ time_entry: value })
    setFieldValue('time_entry', value)
  }

  onChangeTimeOut = (value, setFieldValue) => {
    this.setState({ time_out: value })
    setFieldValue('time_out', value)
  }

  onSearchLocationcode = searchText => {
    if (searchText.length > 1) {
      const { loadLocationcode } = this.props
      loadLocationcode(searchText)
    }
    this.setState({ location_code: searchText })
  }

  handleCancelClick = () => {
    this.setState({
      location_name: '',
      location_code: '',
      date_entry: 0,
      date_out: 0,
      time_entry: moment('00:00', format),
      time_out: moment('00:00', format),
      dest_location: '',
      code_dest_location: '',
    })

    this.props.onCancel()
  }

  render() {
    const {
      visible,
      onOk,
      onCancel,
      route_master_detail_id,
      auth,
      route_master_id,
    } = this.props
    const { dest_location } = this.state

    const loclists = this.props.locationLists.map(v => (
      <OptionAuto key={v.id} label={v.code}>
        {v.name}
      </OptionAuto>
    ))

    const loccodelists = this.props.locationcodeLists.map(v => (
      <OptionAuto key={v.id} label={v.name}>
        {v.code}
      </OptionAuto>
    ))

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    }

    let form_name = (
      <LangContext.Consumer>{i18n => i18n.a.add}</LangContext.Consumer>
    )
    if (route_master_detail_id != 0) {
      form_name = (
        <LangContext.Consumer>{i18n => i18n.e.edit}</LangContext.Consumer>
      )
    }

    return (
      <div>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              dest_location: this.state.dest_location,
              code_dest_location: this.state.code_dest_location,
              location_name: this.state.location_name,
              location_code: this.state.location_code,
              date_entry: this.state.date_entry,
              date_out: this.state.date_out,
              time_entry: this.state.time_entry,
              time_out: this.state.time_out,
            }}
            validate={values => {
              let errors = {}

              return errors
            }}
            validationSchema={yup.object().shape({
              dest_location: yup
                .string()
                .required(
                  <LangContext.Consumer>
                    {i18n => i18n.d.destinationLocation_require}
                  </LangContext.Consumer>,
                ),
              //   .test("checkname", <LangContext.Consumer>
              //   {(i18n) => i18n.d.destinationLocation_duplicate_require}
              // </LangContext.Consumer>, function(
              //     value
              //   ) {
              //     return new Promise((resolve, reject) => {
              //       fetch(
              //         `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/routemaster/checkdestination`,
              //         {
              //           method: "POST",
              //           headers: {
              //             Accept: "application/json",
              //             "Content-Type": "application/json",
              //             Authorization: `Bearer ${auth.accessToken}`,
              //           },
              //           body: JSON.stringify({
              //             route_master_id,
              //             route_master_detail_id,
              //             dest_location:value,

              //           }),
              //         }
              //       )
              //         .then((response) => response.json())
              //         .then((res) => {
              //           if (res.status === "true") {
              //             //console.log("res", res);
              //             resolve(false);
              //           }
              //           resolve(true);
              //         });
              //     });
              //   }),
            })}
            onSubmit={values => {
              const loading = true

              onOk(values, loading)

              this.setState({
                location_name: '',
                location_code: '',
                date_entry: 0,
                date_out: 0,
                time_entry: moment('00:00', format),
                time_out: moment('00:00', format),
                dest_location: '',
                code_dest_location: '',
              })
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
              setFieldValue,
              isValidating,
              resetForm,
            }) => (
              <Modal
                style={{ top: 20 }}
                title={form_name}
                visible={visible}
                onOk={value =>
                  this.handleClick(value, setFieldValue, handleSubmit)
                }
                onCancel={this.handleCancelClick}
                okText={
                  <LangContext.Consumer>
                    {i18n => i18n.o.okay}
                  </LangContext.Consumer>
                }
                cancelText={
                  <LangContext.Consumer>
                    {i18n => i18n.c.cancel}
                  </LangContext.Consumer>
                }
                width={600}
              >
                <Spin spinning={this.props.loadingEditDetail}>
                  <Form onSubmit={handleSubmit} layout="horizontal">
                    <FormItem
                      {...formItemLayout}
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.destinationLocation}
                        </LangContext.Consumer>
                      }
                      required={true}
                      validateStatus={
                        touched.dest_location && errors.dest_location && 'error'
                      }
                      help={touched.dest_location && errors.dest_location}
                    >
                      <AutoComplete
                        dataSource={loclists}
                        style={{ width: 200 }}
                        optionLabelProp="children"
                        filterOption={true}
                        autoComplete="off"
                        optionFilterProp="children"
                        value={this.state.location_name}
                        onSelect={(value, option) =>
                          this.selectLocation(value, option, setFieldValue)
                        }
                        onSearch={this.onSearchLocation}
                        notFoundContent={
                          this.props.loadingLocationName ? (
                            <Spin size="small" />
                          ) : null
                        }
                        placeholder={
                          <LangContext.Consumer>
                            {i18n => i18n.k.keywordAtleast3character}
                          </LangContext.Consumer>
                        }
                      />
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.destinationLocationcode}
                        </LangContext.Consumer>
                      }
                      required={true}
                      validateStatus={
                        touched.code_dest_location &&
                        errors.code_dest_location &&
                        'error'
                      }
                      help={
                        touched.code_dest_location && errors.code_dest_location
                      }
                    >
                      <AutoComplete
                        dataSource={loccodelists}
                        style={{ width: 200 }}
                        optionLabelProp="children"
                        filterOption={true}
                        autoComplete="off"
                        optionFilterProp="children"
                        value={this.state.location_code}
                        onSelect={(value, option) =>
                          this.selectLocationcode(value, option, setFieldValue)
                        }
                        onSearch={this.onSearchLocationcode}
                        notFoundContent={
                          this.props.loadingLocationCode ? (
                            <Spin size="small" />
                          ) : null
                        }
                        placeholder={
                          <LangContext.Consumer>
                            {i18n => i18n.k.keywordAtleast3character}
                          </LangContext.Consumer>
                        }
                      />
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.date_time_entry}
                        </LangContext.Consumer>
                      }
                      //required={true}
                      validateStatus={
                        touched.date_entry && errors.date_entry && 'error'
                      }
                      help={touched.date_entry && errors.date_entry}
                    >
                      <InputGroup compact>
                        <InputNumber
                          min={0}
                          type="number"
                          value={values.date_entry}
                          onChange={value =>
                            this.onChangeDateEntry(value, setFieldValue)
                          }
                          style={{ width: '25%', marginRight: 20 }}
                        />
                        <TimePicker
                          onChange={value =>
                            this.onChangeTimeEntry(value, setFieldValue)
                          }
                          value={values.time_entry}
                          format={format}
                        />
                      </InputGroup>
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label={
                        <LangContext.Consumer>
                          {i18n => i18n.d.date_time_out}
                        </LangContext.Consumer>
                      }
                      //required={true}
                      validateStatus={
                        touched.date_out && errors.date_out && 'error'
                      }
                      help={touched.date_out && errors.date_out}
                    >
                      <InputGroup compact>
                        <InputNumber
                          min={0}
                          type="number"
                          value={values.date_out}
                          //onChange={value => setFieldValue("date_out", value)}
                          onChange={value =>
                            this.onChangeDateOut(value, setFieldValue)
                          }
                          style={{ width: '25%', marginRight: 20 }}
                        />
                        <TimePicker
                          onChange={value =>
                            this.onChangeTimeOut(value, setFieldValue)
                          }
                          value={values.time_out}
                          format={format}
                        />
                      </InputGroup>
                    </FormItem>
                  </Form>
                </Spin>
              </Modal>
            )}
          />
        </div>
      </div>
    )
  }
}

export default FormDetail
