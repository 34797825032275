import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'
import { getlocalstorage } from './../../../constants/local_storage'

function* loadCompany(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      vehicle_visibility,
      accessToken,
      filterObj,
      type,
      file_value_pdf,
      limit_export,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company/get`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        type,
        file_value_pdf,
        limit_export,
        vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadCompany.success(res.data, page, pageSize))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadCompany.failure())
  }
}

function* exportCompany(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      vehicle_visibility,
      filterObj,
      type,
      file_value_pdf,
      limit_export,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company/get`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        type,
        file_value_pdf,
        limit_export,
        vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.exportCompany.success(res.data))
    window.location.assign(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/download/${res.data.data}`,
    )
  } catch (err) {
    console.log(err.message)
    yield put(actions.exportCompany.failure())
  }
}

function* loadCompanyDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company/detail/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    yield put(actions.loadCompanyDetail.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.loadCompanyDetail.failure())
  }
}

function* createCompany(action) {
  const {
    data: {
      name,
      alias,
      company_code,
      tax_no,
      address,
      phone,
      email,
      map_type,
      timezones_id,
      default_lang,
      default_lat,
      default_lng,
      name_ae,
      telno_ae,
      email_ae,
      vendor,
      google_map_key,
      longdo_map_key,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createconpany`,
      {
        name,
        alias,
        company_code,
        tax_no,
        address,
        phone,
        email,
        map_type,
        timezones_id,
        default_lang,
        default_lat,
        default_lng,
        name_ae,
        telno_ae,
        email_ae,
        google_map_key,
        longdo_map_key,
        vendor,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.createCompany.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.createCompany.failure())
  }
}

function* updateCompany(action) {
  const {
    data: {
      company_id,
      name,
      alias,
      company_code,
      tax_no,
      address,
      phone,
      email,
      map_type,
      timezones_id,
      default_lang,
      default_lat,
      default_lng,
      name_ae,
      telno_ae,
      email_ae,
      vendor,
      google_map_key,
      longdo_map_key,
      accessToken,
    },
    loading,
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company/update`,
      {
        company_id,
        name,
        alias,
        company_code,
        tax_no,
        address,
        phone,
        email,
        map_type,
        timezones_id,
        default_lang,
        default_lat,
        default_lng,
        name_ae,
        telno_ae,
        email_ae,
        google_map_key,
        longdo_map_key,
        vendor,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    )
    loading(res.data)
    yield put(actions.updateCompany.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.updateCompany.failure())
  }
}

function* importCompany(action) {
  const {
    data: { files, accessToken, typeImport },
    loading,
  } = action.payload

  const formData = new FormData()
  let uploadFile = null

  if (files != null) {
    files.forEach(File => {
      uploadFile = File
    })
  }

  formData.append('files', uploadFile)
  formData.append('typeImport', typeImport)

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company/import`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    loading(res.data)
    yield put(actions.importCompany.success(res.data))
  } catch (err) {
    console.log(err)
    yield put(actions.importCompany.failure())
  }
}

export default function* watchCompany() {
  yield all([
    takeEvery(actions.LOAD_COMPANY.REQUEST, loadCompany),
    takeEvery(actions.CREATE_COMPANY.REQUEST, createCompany),
    takeEvery(actions.LOAD_COMPANY_DETAIL.REQUEST, loadCompanyDetail),
    takeEvery(actions.UPDATE_COMPANY.REQUEST, updateCompany),
    takeEvery(actions.EXPORT_COMPANY.REQUEST, exportCompany),
    takeEvery(actions.IMPORT_COMPANY.REQUEST, importCompany),
  ])
}
export {
  createCompany,
  updateCompany,
  loadCompanyDetail,
  loadCompany,
  exportCompany,
  importCompany,
}
