import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadDataOverviewPerformanec(action){
    const {
        data: {
            start_date,
            end_date,
            is_vendor,
            company_id,
            vehicle_visibility,
            accesstoken
        }
      } = action.payload;
    try {
        const res = yield call(
          axios.post,
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/isupply-overview-performance-dashboard`,
          {
            start_date,
            end_date,
            is_vendor,
            company_id,
            vehicle_visibility
          },
          { headers: { Authorization: `Bearer ${accesstoken}` } }
        );
    
        yield put(actions.loadDataOverviewPerformanec.success(res.data));
    } catch (err) {
        yield put(actions.loadDataOverviewPerformanec.failure());
    }
}

export default function* watchIsupplyOverviewPerformance() {
    yield all([
      takeEvery(actions.LOAD_DATA_OVERVIEW_PERFOMANCE.REQUEST, loadDataOverviewPerformanec),
    ]);
}

export {
    loadDataOverviewPerformanec
}