import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DistanceReport from './Index'
import Preview from './Previewtable'

import Index_new from './Index_new'

export default () => (
  <Switch>
    <Route exact path="/report/distancereport" component={Index_new} />
    <Route exact path="/report/distancereport/preview" component={Preview} />
  </Switch>
)
